import React, { useState, useEffect, useMemo } from 'react';
import PatientListView from './patientListView';
import PaginationContainer from '../../../../../../components/PaginationComponent/indexContainer';
import { getListPatientService } from '../../../../../../service/patient-service';
import { RootState } from '../../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import { removePatientDetailAction } from '../../../../../../store/patient/patientDetailsReducer';
import { rightNavigationPage } from '../../../../../../utils/constant';

let PageSize = 5;

const PatientListContainer = (props: any) => {
    const { searchValue, tabID } = props;
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [listPatient, setLisPatient] = useState<any>();
    const { StatusListPatient, DataListPatient } = useSelector((state: RootState) => state.listPatient);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const dispatch = useDispatch();
    const getPatientList = async (data: any = '') => {
        let payload = {
            serviceId: DataServiceDetail.serviceId,
            type: data.tabID !== undefined ? data.tabID : 1,
            searchValue: data.SearchValue !== undefined ? data.SearchValue : '',
            FilterBy: data.FilterBy !== undefined ? data.FilterBy : '',
            sortBy: data.SortBy !== undefined ? data.SortBy : '',
            sortOrder: data.SortOrder !== undefined ? data.SortOrder : '',
            page: data.page !== undefined ? data.page : '',
            pageSize: PageSize
        };
        await getListPatientService(payload);
    };

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: page,
                tabID: tabID
            };
            getPatientList(data);
        } else {
            let data = {
                page: page,
                tabID: tabID
            };
            getPatientList(data);
        }
    };

    useEffect(() => {
        if (StatusListPatient === true) {
            setTotalCount(DataListPatient.totalCount);
            setLisPatient(DataListPatient.data);
        }
    }, [StatusListPatient, DataListPatient, totalCount]);
    useEffect(() => {
        async function getPatient() {
            let data = {
                page: currentPage,
                tabID: tabID
            };
            await getPatientList(data);
        }
        getPatient();
    }, [tabID]);
    useEffect(() => {
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: currentPage,
                tabID: tabID
            };
            getPatientList(data);
        } else {
            let data = {
                page: currentPage,
                tabID: tabID
            };
            getPatientList(data);
        }
    }, [searchValue]);

    const handleClick = (data: any, navType: any) => {
        let obj: any = {
            ShowClass: true,
            FormName: navType,
            Data: data,
            ShowToggle:false
        };
        if (navType === rightNavigationPage.Reserved) {
            dispatch(removePatientDetailAction());
        }
        if (navType === rightNavigationPage.Occupied) {
            dispatch(removePatientDetailAction());
        }
        dispatch(setNavigationAction(obj));
    };
    return (
        <div>
            {listPatient !== undefined &&
                listPatient !== null &&
                listPatient.length > 0 &&
                listPatient.map((data: any, index: any) => {
                    return <PatientListView key={index} dataPatient={data} accordID={index} tabID={props.tabID} handleClick={handleClick} />;
                })}
            {currentPage > 0 && totalCount > 0 && PageSize > 0 && (
                <PaginationContainer currentPage={currentPage} totalCount={totalCount} pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
            )}
        </div>
    );
};

export default PatientListContainer;
