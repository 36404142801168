import React from 'react';
import TableFilterView from './TableFilterView';

const TableFilterContainer = (props: any) => {
    return (
        <div>
            <TableFilterView changePayloadHandler={props.changePayloadHandler} changeDatePayloadHandler={props.changeDatePayloadHandler} invoiceListPayloadFilter ={props.invoiceListPayloadFilter} />
        </div>
    );
};

export default TableFilterContainer;