import React, { useEffect, useState } from 'react';
import TaskView from './TaskView';
import { getTaskSummaryService, getListTaskService } from '../../../../../service/task-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

let PageSize = 10;

const TaskContainer = () => {

    const [taskSummary, setTaskSummary] = useState<any>();
    const [taskList, setTaskList] = useState<any>();
    const [taskCount, setTaskCount] = useState<any>(0);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusPendingActivities, DataPendingActivities } = useSelector((state: RootState) => state.pendingActivities);
    const { StatusListTask, DataListTask } = useSelector((state: RootState) => state.listTask);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [typeTask, setTypeTask] = useState<any>(1);

    const getTaskSummary = async (serviceID: any) => {
        const payload: any = { serviceId: serviceID };
        const result = await getTaskSummaryService(payload);
        if(result.data !== null && result.data !== undefined) {
            setTaskSummary(result.data)
        } else {
            setTaskSummary(undefined)
        }
    };

    const getTaskList = async (id: any, data: any) => {
        const payload: any = {
            serviceId: DataServiceDetail.serviceId,
            taskTypeId: id,
            searchValue: "",
            sortBy: "",
            sortOrder: "",
            page: data.page !== undefined ? data.page : '',
            pageSize: PageSize
          };
        await getListTaskService(payload);
        // if(result.data !== null && result.data !== undefined) {
        //     setTaskList(result.data)
        // } else {
        //     setTaskList(undefined)
        // }
    };

    useEffect(() => {
        if (StatusListTask === true) {
            setTotalCount(DataListTask.totalCount);
            setTaskList(DataListTask.data);
        }
    }, [StatusListTask, DataListTask, totalCount]);

    const onClickTaskTypeHandler = (id: any) => {
        let data = {
            page: 1
        };
        setTypeTask(id);
        getTaskList(id, data)
    }

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        let data = {
            page: page
        };
        getTaskList(typeTask, data);
    };

    useEffect(() => {
        if(StatusPendingActivities === true) {
            setTaskCount(DataPendingActivities.data?.task)
        }        
    }, [StatusPendingActivities, DataPendingActivities]);

    useEffect(() => {
        getTaskSummary(DataServiceDetail.serviceId);
        let data = {
            page: 1
        };
        getTaskList(typeTask, data);
    }, [DataServiceDetail]);

    // useEffect(() => {        
    //     getTaskSummary(DataServiceDetail.serviceId);
    //     let data = {
    //         page: 1
    //     };
    //     getTaskList(typeTask, data);
    // }, [])
    return (
        <div>
            <TaskView taskCount={taskCount} taskSummary={taskSummary} taskList={taskList} onClickTaskTypeHandler={onClickTaskTypeHandler} currentPage={currentPage} totalCount={totalCount} pageSize={PageSize} getCurrentPage={(page: any) => getCurrentPage(page)} />
        </div>
    );
};

export default TaskContainer;