import React, { useEffect, useState } from 'react';
import VacantView from './vacantView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { getCityData, getFunderData, getPatientTypeData } from '../../../../../service/dashboard-service';
import eventEmitter from '../../../../../utils/eventEmitter';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png'
import { setSplitFundingAction } from '../../../../../store/splitFunding/splitFundingReducer';

const VacantContainer = () => {
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    const [editedFormVacant, setEditedFormVacant] = useState<any>(false)
    const [formAction, setFormAction] = useState<any>('')
    const dispatch = useDispatch();
    const { TempDataSplitFunding,StatusTempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    
    useEffect(()=>{
        if(TempDataSplitFunding[0]?.planId !== 0){
            if(DataSplitFunding.length === 0){
                dispatch(setSplitFundingAction(TempDataSplitFunding));
            }
            
        }
        
       },[TempDataSplitFunding])

       useEffect(()=>{
         
       },[Data])

       
    const closeSideMenu = (btnValue: any) => {
        if (btnValue !== true && editedFormVacant === true && formAction === 'admit') {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle:false
                    };
                    dispatch(setNavigationAction(obj));
                }
            });
        } else if(btnValue !== true && editedFormVacant === true && formAction === 'reserve') {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle:false
                    };
                    dispatch(setNavigationAction(obj));
                }
            });
        } else if(btnValue !== true && editedFormVacant === true && formAction === 'maintenance') {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: ''
                    };
                    dispatch(setNavigationAction(obj));
                }
            });
        } else {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        }
    };

    useEffect(() => {
        async function getDropdowns() {
            await getCityData();
            await getPatientTypeData();
            // await getFunderData(0);
        }
        getDropdowns();
    }, []);

    const eventCloseVacantSideMenu = async() => {
        eventEmitter.emit('eventCloseEditSideMenu');
        setEditedFormVacant(true)
        
    }

    const eventCloseVacantSideMenuOff = async() => {
        eventEmitter.emit('eventCloseEditSideMenuOff');
        setEditedFormVacant(false)
        setFormAction('')
    }

    useEffect(() => {
        eventEmitter.on('eventCloseVacantSideMenu', eventCloseVacantSideMenu);
        eventEmitter.on('eventCloseVacantSideMenuOff', eventCloseVacantSideMenuOff);
        return () => {
            eventEmitter.off('eventCloseVacantSideMenu', eventCloseVacantSideMenu)
            eventEmitter.off('eventCloseVacantSideMenuOff', eventCloseVacantSideMenuOff)
        }
    });
    
    return (
        <div>
            <VacantView closeSideMenu={(btnValue: any) => closeSideMenu(btnValue)} ShowClass={ShowClass}  Data={Data} setFormAction={(action: any) => {setFormAction(action);setEditedFormVacant(false);}} />
        </div>
    );
};

export default VacantContainer;
