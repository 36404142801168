import React from 'react';

const AssignmentView = (props:any) => {
    const assignmentStatusDetails = props?.assignment?.referralLogList != undefined ? props?.assignment?.referralLogList[props.assignment?.referralLogList?.length - 1]: null;
    return (
        <div>
            <div className='status-box p-3'>                
                <p><i>{assignmentStatusDetails?.logMessage}</i></p>
            </div>
        </div>
    );
};

export default AssignmentView;