import React from 'react';
 import { Doughnut } from 'react-chartjs-2'; 



const DoughnutChartView = (props:any) => {
    return (
        <div>
            <div className="chart-container ">
              <Doughnut 
              data={props.data} 
              options={props.options}
            
               />
            </div>
        </div>
    );
};

export default DoughnutChartView;