import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TempSplitFundingModel } from '../../utils/model';

const initialState: TempSplitFundingModel = {
    TempDataSplitFunding: [],
    StatusTempDataSplitFunding : false
};

export const tempSplitFundingSlice = createSlice({
    name: 'tempSplitFunding',
    initialState,
    reducers: {
        setTempSplitFundingAction: (state, { payload }: PayloadAction) => {
            
            state.TempDataSplitFunding = payload;
            state.StatusTempDataSplitFunding = true;
        },
        removeTempSplitFundingAction: (state, { payload }: PayloadAction) => {
            state.TempDataSplitFunding = [];
            state.StatusTempDataSplitFunding = false;
        }
    },
    extraReducers: {}
});

export const { setTempSplitFundingAction, removeTempSplitFundingAction } = tempSplitFundingSlice.actions;

export default tempSplitFundingSlice.reducer;
