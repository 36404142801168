import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  PatientTypeInformationModel } from '../../utils/model';

const initialState: PatientTypeInformationModel = {
    StatusPatientInfo: false,
    DataPatientInfo: '',
    MessagePatientInfo: '',
    ErrorPatientInfo: ''
};

export const patientInformationSlice = createSlice({
    name: 'patientInformation',
    initialState,
    reducers: {
        setPatientInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientInfo = true;
            state.DataPatientInfo = payload;
            state.MessagePatientInfo = 'Success';
        },
        errorPatientInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientInfo = false;
            state.DataPatientInfo = [];
            state.MessagePatientInfo = 'Failed';
            state.ErrorPatientInfo = payload;
        },
        removePatientInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientInfo = false;
            state.DataPatientInfo = '';
            state.MessagePatientInfo = '';
            state.ErrorPatientInfo = '';
        }
    },
    extraReducers: {}
});

export const { setPatientInformationAction, errorPatientInformationAction, removePatientInformationAction } = patientInformationSlice.actions;

export default patientInformationSlice.reducer;
