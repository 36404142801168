import React, { useEffect } from 'react';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import CQC from '../../../../../../assets/images/CQC.png'
import { useTranslation } from 'react-i18next';

const AccreditationsView = (prop: any) => {
    useEffect(() => {
        AOS.init();
    }, [])
    const { t } = useTranslation();
    return (
        <div className="">
            <div className="row">
                <h4 className="small-title indigo-text bolder mb-3" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                    {t("Accreditations.Accreditations")}
                </h4>

                <ul className="list-unstyled  accreditations-list" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                    <li>
                        <div className="coordinator-details-box p-3 pb-5 position-relative">
                            <img
                                src={CQC}
                                className="img-fluid mb-1"
                                alt="list-image"
                                width={100}
                            />
                            <p className="lead-text bolder d-flex flex-column mb-2 ">

                                <span className="pt-1 pb-2 bold border-bottom d-flex align-items-center">
                                    <span className={`${prop && prop.cqcRating ?
                                        (
                                            prop.cqcRating.toLowerCase() === 'requires improvement' ? 'dot-round-orange' :
                                                (prop.cqcRating.toLowerCase() === 'inadequate' ? 'dot-round-red' :
                                                    (prop.cqcRating.toLowerCase() === 'good' ? 'dot-round' : 'dot-round'))
                                        )
                                        : ''
                                        } me-1`}></span><span className="underline">{t("Accreditations.Overall")} : {prop?.cqcRating} </span>
                                </span>
                            </p>
                            <div className="accreditations-info">
                                <p className="mb-1">
                                    <span className="bold">{t("Accreditations.Latest_Inspection")} :</span>
                                    {prop?.cqcLatestInspection}
                                </p>
                                <p className="mb-1">
                                    <span className="bold">{t("Accreditations.Report_Published")} :</span>
                                    {prop?.cqcReportPublished}
                                </p>
                                {/* <p>
                                    <span className="bold">{t("Accreditations.Latest_Review")} :</span>
                                    {prop?.cqcLatestReview}
                                </p> */}
                            </div>
                            <a aria-labelledby="Click Text" className="click-btn" href={`${process.env.REACT_APP_CQC_URL}${prop?.cqcLocationId}`} target='_blank'><i className="fa-solid fa-plus"></i></a>
                        </div>
                    </li>

                </ul>


            </div>
        </div>
    );
};

export default AccreditationsView;