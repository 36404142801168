import React, {useEffect} from 'react';
import ReferralManagementView from './ReferralManagementView';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { GetNotificationsCount } from '../../service/notifications-services';

const ReferralManagementContainer = () => {

    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const dispatch = useDispatch();

    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
          GetNotificationCount();
      },[])
      const GetNotificationCount = async () => {
        await GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0 )
    }
    return (
        <div>
            <ReferralManagementView />
        </div>
    );
};

export default ReferralManagementContainer;