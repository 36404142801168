import NotesModalView from './indexView';

const NotesContainer = (props: any) => {
    return (
        <div>
            <NotesModalView {...props} />
        </div>
    );
};

export default NotesContainer;