import React from 'react';
import WardPage from './components/Ward-Section/wardContainer';
import BedInformationContainer from './components/BedInformation/bedInformationContainer';

const DashboardView = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="middle_content" id="middle_content">
                            <BedInformationContainer />
                            <WardPage />
                            {/* <LoaderContainer /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardView;
