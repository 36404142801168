import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssessmentDetailsModel } from '../../utils/model';

const initialState: AssessmentDetailsModel = {
    StatusAssessmentDetails: false,
    DataAssessmentDetails: '',
    MessageAssessmentDetails: '',
    ErrorAssessmentDetails: ''
};

export const assessmentDetailsSlice = createSlice({
    name: 'assessmentDetails',
    initialState,
    reducers: {
        setAssessmentDetailsAction: (state, { payload }: PayloadAction) => {
            state.StatusAssessmentDetails = true;
            state.DataAssessmentDetails = payload;
            state.MessageAssessmentDetails = 'Success';
        },
        errorAssessmentDetailsAction: (state, { payload }: PayloadAction) => {
            state.StatusAssessmentDetails = false;
            state.DataAssessmentDetails = [];
            state.MessageAssessmentDetails = 'Failed';
            state.ErrorAssessmentDetails = payload;
        },
       removeAssessmentDetailsAction: (state, { payload }: PayloadAction) => {
            state.StatusAssessmentDetails = false;
            state.DataAssessmentDetails = '';
            state.MessageAssessmentDetails = '';
            state.ErrorAssessmentDetails = '';
        }
    },
    extraReducers: {}
});

export const { setAssessmentDetailsAction, errorAssessmentDetailsAction, removeAssessmentDetailsAction } = assessmentDetailsSlice.actions;

export default assessmentDetailsSlice.reducer;
