import React from 'react';
import StrengthAssessmentView from './StrengthAssessmentView';

const StrengthAssessmentContainer = (props: any) => {
    return (
        <div>
            <StrengthAssessmentView orgName={props.orgName} strengthAssessmentFormValid={props.strengthAssessmentFormValid} strengthAssessmentDataChange={props.strengthAssessmentDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default StrengthAssessmentContainer;