import React, { useEffect } from 'react';
import AdmissionContainer from './Admission/admissionContainer';
import DraftContainer from './Draft/draftContainer';
import OccupiedContainer from './Occupied/occupiedContainer';
import VacantContainer from './Vacant/vacantContainer';
import ReservedPatientContainer from './Reserved/reservedPatientContainer';
import MaintenanceDetailsContainer from './Maintenance/MaintenanceDetails/MaintenanceDetailsContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { rightNavigationPage } from '../../../../utils/constant';
import MaintenanceListContainer from './Maintenance/MaintenanceList/MaintenanceListContainer';
import TaskContainer from './Task/TaskContainer';
import CreateLeadContainer from '../../../ReferralManagementPage/ReferralManager/ReferralSidebar/CreateLead/CreateLeadContainer';
import ReferralStagesContainer from '../../../ReferralManagementPage/ReferralManager/ReferralSidebar/ReferralStages/ReferralStagesContainer';
import PoolingContainer from '../../../ReferralManagementPage/ServiceManager/ServiceReferralSidebar/Pooling/PoolingContainer';
import ServiceReferralSidebarContainer from '../../../ReferralManagementPage/ServiceManager/ServiceReferralSidebar/ServiceReferralSidebarContainer';
import MessagesContainer from '../../../ReferralManagementPage/Messages/MessagesContainer';

import ReferralListContainer from './ReferralList/ReferralListContainer';
import NotificationsContainer from '../../../ReferralManagementPage/Notifications/NotificationsContainer';




const RightsidebarDetailView = () => {
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    return (
        <>
            <div>
                {/* <VacantContainer /> */}
                {FormName === rightNavigationPage.Vacant && <VacantContainer />}
                {/* <OccupiedContainer /> */}
                {/* {`{${FormName === rightNavigationPage.Vacant} ? <VacantContainer />}`} */}
                {/* {`${FormName === rightNavigationPage.Admission} ? <AdmissionContainer />`} */}
                {/* <AdmissionContainer /> */}

                {FormName === rightNavigationPage.Admission && <AdmissionContainer />}
                {FormName === rightNavigationPage.Draft && <DraftContainer />}
                {FormName === rightNavigationPage.Task && <TaskContainer />}
                {FormName === rightNavigationPage.Occupied && <OccupiedContainer />}
                {FormName === rightNavigationPage.Reserved && <ReservedPatientContainer />}
                {FormName === rightNavigationPage.Maintenance && <MaintenanceDetailsContainer />}
                {FormName === rightNavigationPage.MaintenanceList && <MaintenanceListContainer />}
                {FormName === rightNavigationPage.CreateLead && <CreateLeadContainer />}
                {FormName === rightNavigationPage.ReferralStages && <ReferralStagesContainer />}
                {FormName === rightNavigationPage.ServiceSidebar && <ServiceReferralSidebarContainer />}
                {FormName === rightNavigationPage.Messages && <MessagesContainer />}
                {FormName === rightNavigationPage.Notifications && <NotificationsContainer />}
                {FormName === rightNavigationPage.ReferralList && <ReferralListContainer />}
            </div>
        </>
    );
};

export default RightsidebarDetailView;
