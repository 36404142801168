import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListMaintenanceBedsModel } from '../../utils/model';

const initialState: ListMaintenanceBedsModel = {
    StatusListMaintenanceBeds: false,
    DataListMaintenanceBeds: '',
    MessageListMaintenanceBeds: '',
    ErrorListMaintenanceBeds: ''
};

export const listMaintenanceBedsSlice = createSlice({
    name: 'listMaintenanceBeds',
    initialState,
    reducers: {
        setListMaintenanceBedsAction: (state, { payload }: PayloadAction) => {
            state.StatusListMaintenanceBeds = true;
            state.DataListMaintenanceBeds = payload;
            state.MessageListMaintenanceBeds = 'Success';
        },
        errorListMaintenanceBedsAction: (state, { payload }: PayloadAction) => {
            state.StatusListMaintenanceBeds = false;
            state.DataListMaintenanceBeds = [];
            state.MessageListMaintenanceBeds = 'Failed';
            state.ErrorListMaintenanceBeds = payload;
        },
        removeListMaintenanceBedsAction: (state, { payload }: PayloadAction) => {
            state.StatusListMaintenanceBeds = false;
            state.DataListMaintenanceBeds = '';
            state.MessageListMaintenanceBeds = '';
            state.ErrorListMaintenanceBeds = '';
        }
    },
    extraReducers: {}
});

export const { setListMaintenanceBedsAction, errorListMaintenanceBedsAction, removeListMaintenanceBedsAction } = listMaintenanceBedsSlice.actions;

export default listMaintenanceBedsSlice.reducer;
