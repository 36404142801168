import React, { useState, useEffect, useMemo } from 'react';
import MaintenanceBedListView from './MaintenanceBedListView';
import PaginationContainer from '../../../../../../../components/PaginationComponent/indexContainer';
import { getMaintenanceDetailListService } from '../../../../../../../service/maintenance-service';
import { RootState } from '../../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { rightNavigationPage } from '../../../../../../../utils/constant';
import { setNavigationAction } from '../../../../../../../store/dashboard/navigationReducer';
import swal from 'sweetalert';
import warning from '../../../../../../../assets/images/warning.png'
import eventEmitter from '../../../../../../../utils/eventEmitter';
import { removeListMaintenanceBedsAction } from '../../../../../../../store/maintenance/listMaintenanceBedsReducer';

let PageSize = 5;

const MaintenanceBedListContainer = (props: any) => {
    const { searchValue } = props;
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [listMaintenanceBed, setLisMaintenanceBed] = useState<any>();
    const dispatch = useDispatch();
    const { StatusListMaintenanceBeds, DataListMaintenanceBeds } = useSelector((state: RootState) => state.listMaintenanceBeds);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const getMaintenanceBedList = async (data: any = '') => {
        let payload = {
            serviceId: DataServiceDetail.serviceId,
            SearchValue: data.SearchValue !== undefined ? data.SearchValue : '',
            SortBy: data.SortBy !== undefined ? data.SortBy : '',
            SortOrder: data.SortOrder !== undefined ? data.SortOrder : '',
            Page: data.page !== undefined ? data.page : '',
            PageSize: PageSize
        };
        await getMaintenanceDetailListService(payload);
    };

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: page
            };
            getMaintenanceBedList(data);
        } else {
            let data = {
                page: page
            };
            getMaintenanceBedList(data);
        }
    };
    const eventGetMaintenanceBedList = async() => {
        setCurrentPage(1);
        let data = {
            page: '1'
        };
        getMaintenanceBedList(data);
    }


    useEffect(() => {
        eventEmitter.on('eventGetMaintenanceBedList', eventGetMaintenanceBedList);
        return () => {
            eventEmitter.off('eventGetMaintenanceBedList', eventGetMaintenanceBedList)
        }
    });

    useEffect(() => {
        if (StatusListMaintenanceBeds === true) {
            
            setTotalCount(DataListMaintenanceBeds.totalCount);
            setLisMaintenanceBed(DataListMaintenanceBeds.data);
        }
    }, [StatusListMaintenanceBeds, DataListMaintenanceBeds, totalCount]);
    useEffect(() => {
        async function getPatient() {
            let data = {
                page: currentPage
            };
            await getMaintenanceBedList(data);
        }
        getPatient();
    }, []);
    useEffect(() => {
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: currentPage
            };
            getMaintenanceBedList(data);
        } else {
            let data = {
                page: currentPage
            };
            getMaintenanceBedList(data);
        }
    }, [searchValue]);

    const handleContinue = (data: any) => {
        dispatch(removeListMaintenanceBedsAction());
        let obj: any = {
            ShowClass: true,
            FormName: rightNavigationPage.Maintenance,
            Data: data,
            ShowToggle:false
        };
        dispatch(setNavigationAction(obj));
    };
    // 
  
    return (
        <div>
            {listMaintenanceBed !== undefined &&
                listMaintenanceBed !== null &&
                listMaintenanceBed.length > 0 &&
                listMaintenanceBed.map((data: any, index: any) => {
                    return <MaintenanceBedListView key={index}  dataMaintenanceBed={data} accordID={index} handleContinue={handleContinue} />;
                })}
            {currentPage > 0 && totalCount > 0 && PageSize > 0 && (
                <PaginationContainer currentPage={currentPage} totalCount={totalCount}  pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
            )}
        </div>
    );
};

export default MaintenanceBedListContainer;
