import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IntegrationTopbarContainer from './IntegrationTopbar/IntegrationTopbarContainer';
import IntegrationBottomContainer from './IntegrationBottom/IntegrationBottomContainer';
import { rightNavigationPage } from '../../utils/constant';
import { setPageNavigationAction } from '../../store/general/pageNavigationReducer';
import { RootState } from '../../store';

const IntegrationInterfaceView = (props: any) => {
    const dispatch = useDispatch();
    const [integration, setintegrationdata] = useState<any>(null);
    const [clearintegration, setclearintegration] = useState<Boolean>(false);
    const { PageName, Data } = useSelector((state: RootState) => state.pageNavigation);



    const getintegrationdata = (data: any) => {
        setclearintegration(false);
        setintegrationdata({ ...data });
    };
    const clearIntegrationData = () => {
        setclearintegration(true);
        setintegrationdata(null);
    };


    return (
        <div>
            <IntegrationTopbarContainer getintegrationdata={getintegrationdata} clearIntegrationData={clearIntegrationData} />
            <IntegrationBottomContainer integrationdata={integration} clearintegrationdata={clearintegration} />
        </div>
    );
};

export default IntegrationInterfaceView;
