import React,{useEffect, useState} from 'react';
import ServiceReferralLessContainer from './ServiceReferralLess/ServiceReferralLessContainer';
import ServiceReferralMoreContainer from './ServiceReferralMore/ServiceReferralMoreContainer';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

const ServiceReferralDetailsView = (props: any) => {

    let [SelectedStatus, setSelectedStatus] = useState('');
    let [SelectedFrequency, setSelectedFrequency] = useState(1);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { DataOrganizationDetail } = useSelector((state: RootState) => state.organizationDetail);
    
    const handlerSelectedStatus = (val:any) =>{        
        setSelectedStatus(val);
    }

    const handlerSelectedFrequency = (val:any) =>{        
        setSelectedFrequency(val);
    }

    useEffect(()=>{
       setSelectedStatus('');
    },[DataServiceDetail.serviceName,DataOrganizationDetail.organizationName])
          
    return (
        <div>
             {props.moreInformation === false ? (
            <div className='position-relative'>
                <ServiceReferralLessContainer referralOverView={props.referralOverView} 
                                       SelectedStatus={handlerSelectedStatus} CurrentStatus={SelectedStatus}/>
                {/* <p className="see-more" onClick={props.handleShowMoreInfo}>
                    <label id="moreless">More Details</label>
                    <span className="upicon downicon ms-1"></span>
                </p> */}
            </div>
             ) : (
            <div className='position-relative more-info'>
                <ServiceReferralMoreContainer referralOverView={props.referralOverView} 
                                        SelectedStatus={handlerSelectedStatus} CurrentStatus={SelectedStatus}
                                        SelectedFrequency={handlerSelectedFrequency} Frequency={SelectedFrequency}/>
                {/* <p className="see-more" onClick={props.handleHideMoreInfo}>
                        <label id="moreless">Less Details</label>
                        <span className="upicon upicon ms-1"></span>
                    </p> */}
            </div>
             )}
           
        </div>
    );
};

export default ServiceReferralDetailsView;