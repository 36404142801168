import React, { useState, useEffect } from 'react';
import Routing from './navigation/routes';
import IdleTimeOutHandler from './components/IdleTimeOutHandler';
import LoaderContainer from './components/Loader/loaderContainer';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import eventEmitter from './utils/eventEmitter';
const router = createBrowserRouter(Routing);
export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
    const [isActive, setIsActive] = useState<any>(true);
    const [isLoader, setIsLoader] = useState<any>(false);
    const eventSetLoader = async(loader: any) => {
        setIsLoader(loader)
    }
    useEffect(() => {
        eventEmitter.on('eventSetLoader', eventSetLoader);
        return () => {
            eventEmitter.off('eventSetLoader', eventSetLoader)
        }
    });
    return (
        <>
            <IdleTimeOutHandler
                onActive={() => {
                    setIsActive(true);
                }}
                onIdle={() => {
                    setIsActive(false);
                }}
            />
            <RouterProvider router={router} />
            {isLoader && <LoaderContainer /> }
        </>
    );
};

export default Application;
