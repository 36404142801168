import React from 'react';
import SearchInvoiceView from './SearchInvoiceView';

const SearchInvoiceContainer = (props: any) => {
    return (
        <div>
            <SearchInvoiceView searchValue={props.searchValue} />
        </div>
    );
};

export default SearchInvoiceContainer;