import React from 'react';
import { useTranslation } from 'react-i18next';

const DownloadInvoiceView = (props: any) => {
    const { t } = useTranslation();
    return (
        <div>
            <a href="#" onClick={props.onClickHandlerDownloadInvoices} className='btn btn-white bold me-2'><i className="fa-sharp fa-solid fa-download"></i> {t('TableAction.Download')}</a>
        </div>
    );
};

export default DownloadInvoiceView;