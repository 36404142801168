import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleSelectedModel } from '../../utils/model';

const initialState: RoleSelectedModel = {
    StatusRoleSelected: false,
    DataRoleSelected: {},
    MessageRoleSelected: '',
    ErrorRoleSelected: ''
};

export const roleSelectedSlice = createSlice({
    name: 'roleSelected',
    initialState,
    reducers: {
        setRoleSelectedAction: (state, { payload }: PayloadAction) => {
            state.StatusRoleSelected = true;
            state.DataRoleSelected = payload;
            state.MessageRoleSelected = 'Success';
        },
        removeRoleSelectedAction: (state, { payload }: PayloadAction) => {
            state.StatusRoleSelected = false;
            state.DataRoleSelected = {};
            state.MessageRoleSelected = '';
            state.ErrorRoleSelected = '';
        },
        errorRoleSelectedAction: (state, { payload }: PayloadAction) => {
            state.StatusRoleSelected = false;
            state.DataRoleSelected = {};
            state.MessageRoleSelected = 'Failed';
            state.ErrorRoleSelected = payload;
        }
    },
    extraReducers: {}
});

export const { setRoleSelectedAction, removeRoleSelectedAction, errorRoleSelectedAction } = roleSelectedSlice.actions;

export default roleSelectedSlice.reducer;
