import React, { useEffect, useMemo, useState } from 'react';
import NHSView from './NHSView';
import { ServicesListsType } from './HomeList/ListBox/types';
import { ApiResponse } from '../../utils/model';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CountryExist, GetAllServiceListService, GetDefaultLocationData, GetUserLatLongList, GetLocationWiseLatLongData as GetLocationLatLongData, GetAdvancedFilterCount, GetLocationWiseLatLongData } from '../../service/serviceProvider-services';
import { setServiceListsAction } from '../../store/general/serviceListsReducer';
import { setCityWiseLatLngListAction } from '../../store/serviceProvider/cityWiseLatLngListReducer';
import eventEmitter from '../../utils/eventEmitter';
import { setAppliedFilterAction } from '../../store/filter/appliedFilterReducer';
import { format } from 'date-fns';
import { findDateRange } from '../../utils/dateRange';
import FilterListContainer from '../../components/FilterList/FilterListContainer';
import { useLocation } from 'react-router-dom';
import { AdvancedFilterCountResponse } from '../../layout/NHSHeader/ServicetypeHeader/SearchFilter/types';
import { setFilterListsAction } from '../../store/filter/advanceFilterReducer';
import swal from 'sweetalert';

const NHSContainer = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const { AppliedFilterData, TopFilterData } = useSelector((state: RootState) => state.appliedFilter);

  const { StatusUserLocation, DataUserLocation } = useSelector((state: RootState) => state.userLocation);

  const { FilterMetaData } = useSelector((state: RootState) => state.metaFilter);
  
  const [isDateChanged, setIsDateChanged] = useState<boolean>(false);
  const [isSwalShown, setIsSwalShown] = useState(false);
  const [isLoader, setIsLoader] = useState<any>(false);

  const checkIfCountryExist = async (payload: any) => {
    return await CountryExist(payload);
  };

  const isDrawerOpen = useMemo(() => {
    return FilterMetaData.drawerOpen;
  }, [FilterMetaData.drawerOpen]);

  useEffect(() => {
    eventEmitter.on("isDateChangedAction", (data) => setIsDateChanged(data));
    return () => {
      eventEmitter.off("isDateChangedAction", (data) => setIsDateChanged(data));
    }
  }, []);

  const getAllServiceList = async () => {
    setIsLoader(true);
    const cityName = '';

    let countApiPayload: any = { ...AppliedFilterData, isShowOutOfArea: 1 };
    const datesRange = findDateRange({ ...AppliedFilterData, ...TopFilterData });

    countApiPayload = {
      ...countApiPayload,
      cityName,
      location: AppliedFilterData?.location ? AppliedFilterData?.location : null,
      searchValue: AppliedFilterData?.searchValue ? AppliedFilterData?.searchValue : null,
      dateRange: TopFilterData.isDateChanged ? datesRange.map((item: any) => ({
        fromDate: format(item.startDate, 'yyyy-MM-dd'),
        toDate: format(item.endDate, 'yyyy-MM-dd'),
      })) : null,
      Amenities: AppliedFilterData.amenities?.map((am) => am.id),
    };

    delete countApiPayload.amenities;

    const resultOfCount: ApiResponse<AdvancedFilterCountResponse> = await GetAdvancedFilterCount(countApiPayload);
    if (resultOfCount?.status === true) {
      dispatch(setFilterListsAction(resultOfCount?.data));
    }

    const availableIds = resultOfCount?.data?.availabilityService?.map((availableService) => availableService.serviceId)?.flat();
    const occupiedIds = resultOfCount?.data?.occupiedService?.map((occupiedService) => occupiedService.serviceId)?.flat();

    const isAvailbleSelected = AppliedFilterData?.serviceStatus?.includes("Available");
    const isOccupiedSelected = AppliedFilterData?.serviceStatus?.includes("Occupied");

    let serviceId;
    if (isAvailbleSelected && isOccupiedSelected) {
      serviceId = availableIds?.concat(occupiedIds as number[])
    } else if (isAvailbleSelected) {
      serviceId = availableIds;
    } else if (isOccupiedSelected) {
      serviceId = occupiedIds;
    }

    const payload: any = {
      ...AppliedFilterData,
      location: AppliedFilterData.location ? AppliedFilterData.location : null,
      dateRange: isDateChanged ? findDateRange({ ...AppliedFilterData, ...TopFilterData })?.map((item: any) => {
        return {
          fromDate: format(item.startDate, 'yyyy-MM-dd'),
          toDate: format(item.endDate, 'yyyy-MM-dd'),
        }
      }) : null,
      cityName,
      serviceId: 0,
      isShowOutOfArea: 1,
      cqcRating: AppliedFilterData.cqcRating.length > 0 ? AppliedFilterData.cqcRating : null,
      serviceStatus: AppliedFilterData.serviceStatus.length > 0 ? AppliedFilterData.serviceStatus : null,
      searchValue: AppliedFilterData.searchValue ? AppliedFilterData.searchValue : null,
      amenities: AppliedFilterData.amenities?.map((am) => am.id),
      serviceTypeList: serviceId || [],
    }

    const result: ApiResponse<ServicesListsType[]> = await GetAllServiceListService(payload);
    if (result?.status === true) {
      dispatch(setServiceListsAction(result?.data));
    } else {
      dispatch(setServiceListsAction([]));
    }

    setIsLoader(false);
  };

  useEffect(() => {
    getAllServiceList();
  }, [AppliedFilterData, TopFilterData, DataUserLocation]);

  useEffect(() => {
    const element = window.document.getElementById("map");
    if (isDrawerOpen) {
      if (element !== null) {
        element.classList.add("overlay-map");
      }
    } else {
      element?.classList.remove("overlay-map");
    }

    return () => {
      element?.classList.remove("overlay-map");
    };
  }, [isDrawerOpen]);

  return (
    <div>
      <div className="row  m-0">
        <div className="p-0 filter-anim" id="map">
          <NHSView
            isDrawerOpen={isDrawerOpen}
          />
        </div>

        {/* <div className={`p-0 filter filter-anim ${isDrawerOpen ? "open-filter" : ""}`}>
          <FilterListContainer />
        </div> */}
        {/* {isDrawerOpen &&<div className={`p-0 filter filter-anim ${isDrawerOpen ? "open-filter" : ""} ${(location.pathname.includes('referral-manager')) ? 'RM-filter-height' : ''}`}>
          <FilterListContainer />
        </div>} */}
        <div className={`p-0 filter ${isDrawerOpen ? "filter-anim open-filter" : ""} ${(location.pathname.includes('referral-manager')) ? 'RM-filter-height' : ''}`}>
          <FilterListContainer />
        </div>
      </div>
    </div>
  );
};

export default NHSContainer;
