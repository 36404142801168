import React, { createRef, useEffect, useRef, useState } from 'react';
import ContractView from './contractView';
import FunderListContainer from '../FunderList/funderListContainer';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { RootState } from '../../../../../../store';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { getFunderData } from '../../../../../../service/dashboard-service';

var listdata: any[] = [];
const ContractContainer = (props: any) => {
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    let [contractData, setcontractData] = useState<any>('');
    let [commissionerData, setcommissionerData] = useState<any>('');
    let [ListData, setlistdata] = useState<any>([]);
    let [FunderData,setFunderData]=useState<any>([]);
    let [CityData,setCityData]=useState<any>([]);
    let [isContracterror,setContracterror]=useState<any>(false);
    let [isCheck,setCheck]=useState<any>(true);
    let [fundertype, setfundertype] = useState<any>('');
    let [changetype, setchangetype] = useState<any>(false);
    let [isDisplay, setDisplay] = useState(true);
    let [isBack,setBack] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    useEffect(() => {
                props.contractdata({  ListData });
    }, [ ListData]);

    useEffect(() =>{
          if(DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0){
            setFunderData(DataFunderInfo.data);
          }
    },[DataFunderInfo]);

    useEffect(() =>{
        if(DataCityInfo.data !== undefined && DataCityInfo.data.length > 0){
          setCityData(DataCityInfo.data);
        }
  },[DataCityInfo]);

  useEffect(()=>{
    if(DataLeadInfo !== '' && DataLeadInfo?.data?.leadFunderDetailList?.length > 0 && DataFunderInfo !== ""){
         let tempdata = [];
         let obj;
        for(let i=0; i<DataLeadInfo?.data?.leadFunderDetailList?.length;i++){
            const fundername = DataFunderInfo?.data?.filter((val:any)=>val.key == DataLeadInfo?.data?.leadFunderDetailList[i]?.funderId);
            const funderarr = DataLeadInfo?.data?.leadFunderDetailList[i]?.isSelfFunded === 1 ?[] :DataFunderInfo?.data?.filter((val:any)=>val?.funderTypeId === fundername[0]?.funderTypeId);
           let contractData = {
            fAddress1: DataLeadInfo?.data?.leadFunderDetailList[i]?.funderAddressLine1,
            fCity: DataLeadInfo?.data?.leadFunderDetailList[i]?.funderCountyId?.toString(),
            fContactNo: DataLeadInfo?.data?.leadFunderDetailList[i]?.funderPhoneNo,
            fEmailId: DataLeadInfo?.data?.leadFunderDetailList[i]?.funderEmail,
            fFunder: DataLeadInfo?.data?.leadFunderDetailList[i]?.isSelfFunded === 1 ? DataLeadInfo?.data?.leadFunderDetailList[i]?.funderName : DataLeadInfo?.data?.leadFunderDetailList[i]?.funderId.toString(),
            fPoBox:DataLeadInfo?.data?.leadFunderDetailList[i]?.funderPostalCode,
            fFunderVal:DataLeadInfo?.data?.leadFunderDetailList[i]?.isSelfFunded === 1 ? DataLeadInfo?.data?.leadFunderDetailList[i]?.funderName : fundername[0]?.value,
            filepath:DataLeadInfo?.data?.leadFunderDetailList[i]?.documentPath,
            ftype:DataLeadInfo?.data?.leadFunderDetailList[i]?.isSelfFunded === 1 ? '4' : fundername[0].funderTypeId.toString(),
            filename:DataLeadInfo?.data?.leadFunderDetailList[i]?.documentName,
            isFilePond : DataLeadInfo?.data?.leadFunderDetailList[i]?.documentName === null ? true : false,
            farray:funderarr,
            fsuid: DataLeadInfo?.data?.leadFunderDetailList[i]?.suid,
            fnote: DataLeadInfo?.data?.leadFunderDetailList[i]?.note,
            fpo:DataLeadInfo?.data?.leadFunderDetailList[i]?.pO_Reference,
           }
           let commissionerData ={
            cAddress1: DataLeadInfo?.data?.leadFunderDetailList[i]?.commissionerAddressLine1,
            cCity: DataLeadInfo?.data?.leadFunderDetailList[i]?.commissionerCountyId?.toString(),
            cContactNo: DataLeadInfo?.data?.leadFunderDetailList[i]?.commissionerPhoneNo,
            cEmailId: DataLeadInfo?.data?.leadFunderDetailList[i]?.commissionerEmail,
            cFunder: DataLeadInfo?.data?.leadFunderDetailList[i]?.commissionerName,
            cPoBox:DataLeadInfo?.data?.leadFunderDetailList[i]?.commissionerPostalCode,

            sAddress1: DataLeadInfo?.data?.leadFunderDetailList[i]?.socialWorkerAddressLine1,
            sCity: DataLeadInfo?.data?.leadFunderDetailList[i]?.socialWorkerCountyId?.toString(),
            sContactNo: DataLeadInfo?.data?.leadFunderDetailList[i]?.socialWorkerPhoneNo,
            sEmailId: DataLeadInfo?.data?.leadFunderDetailList[i]?.socialWorkerEmail            ,
            sFunder: DataLeadInfo?.data?.leadFunderDetailList[i]?.socialWorkerName,
            sPoBox: DataLeadInfo?.data?.leadFunderDetailList[i]?.socialWorkerPostalCode
           }
           obj = {
            funderData: contractData,
            commisionerData: commissionerData,
        };
          tempdata.push(obj);
         
         
        }
        if(DataLeadInfo?.data?.leadFunderDetailList?.length === tempdata.length){
            setlistdata(tempdata);
            
            setDisplay(false);
          }
       
    }
    else{
    setlistdata([]);
    setcommissionerData('');
    setcontractData('');
    }

},[DataLeadInfo])

    const updateData = (e: any) => {
        eventEmitter.emit("eventCloseEditLeadSideMenu");
        eventEmitter.emit('eventCloseVacantSideMenu');
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                setcontractData({
                    ...contractData,
                });
               
                if(fundertype !== '4'){
                    const fundername = FunderData.filter((val:any)=>val.key == contractData?.fFunder);
                    setcommissionerData({
                        cEmailId: contractData?.fEmailId || '',
                        cFunder: fundername[0].value || '',
                        cContactNo: contractData?.fContactNo || '',
                        cAddress1: contractData?.fAddress1 || '' ,
                        cCity: contractData?.fCity || '0',
                        cPoBox: contractData?.fPoBox || ''
                    });
                }
                else if(fundertype === '4'){
                    
                    setcommissionerData({
                        cEmailId: contractData?.fEmailId || '',
                        cFunder: contractData?.fFunderVal || '',
                        cContactNo: contractData?.fContactNo || '',
                        cAddress1: contractData?.fAddress1 || '' ,
                        cCity: contractData?.fCity || '0',
                        cPoBox: contractData?.fPoBox || ''
                    });
                }
               
            } else {
                setcontractData({
                    ...contractData,
                    [e.target.id]: e.target.checked
                });
                setcommissionerData({});
            }
        } else if (e.target.type === 'radio') {
                setchangetype(false);
                setTimeout(() => {
                    setchangetype(true);
                }, 200);
                setCheck(true);
                setTimeout(() => {
                    setCheck(false);
                }, 100);
               setcontractData({
                ...contractData,
                ftype:e.target.value
               })
           
        } 
        
        else {
            let isexists : any = "";
            if(e.target.id == 'Funder'){
            isexists = [...ListData].some((val: any) => val.funderData.fFunder === e.target.value);
            }
            
            
            if (isexists) {
                    swal('Funder is already added', {
                        icon: 'warning',
                        dangerMode: true
                    });
             
            }
            else{
                if(e.target.id == 'Funder'){
                    const fundername = FunderData.filter((val:any)=>val.key == e.target.value);
                    setfundertype(fundername[0].funderTypeId);
                    setcontractData({
                        ...contractData,
                        [e.target.name]: e.target.value,
                        ['fFunderVal'] : fundername[0].value,
                        ['fEmailId']: fundername[0].funderEmail || '', 
                        ['fContactNo']: fundername[0].funderPhoneNo || '', 
                        ['fAddress1']: fundername[0].funderAddressLine1 || '', 
                        ['fCity']: fundername[0].funderCityId?.toString() || '0', 
                        ['fPoBox']: fundername[0].funderPobox || '', 
                        ['ftype'] : fundername[0].funderTypeId?.toString(),
                        ['farray'] : DataFunderInfo?.data,
                    });  
                    if(Object.keys(commissionerData).length > 0){
                        setcommissionerData({});
                        setCheck(true);
                        setTimeout(() => {
                            setCheck(false);
                        }, 100);
                        
                    }
                }
                else{
                    setcontractData({
                        ...contractData,
                        [e.target.name]: e.target.value
                    })
                }
            }
          
           
        }
    };
    useEffect(() =>{
       setfundertype(contractData?.ftype);
    },[contractData]);

    useEffect(()=>{
        if(fundertype){
            getFilterFunder();
        if(fundertype === '4'){
            setcontractData({
                ...contractData,
                ['fFunderVal'] : props.selfData?.name || '',
                ['fEmailId']: props.selfData?.EmailId || '',
                ['fFunder']:props.selfData?.name ||'',
                ['fContactNo']: props.selfData?.ContactNo || '', 
                ['fAddress1']: props.selfData?.Address1 || '', 
                ['fCity']: props.selfData?.City || '0', 
                ['fPoBox']: props.selfData?.PoBox || '', 
            })
        } 
        }
        async function getFilterFunder() {
            await getFunderData(fundertype);
        }
       
    },[fundertype,props.selfData]);

    const updateCData = (e: any) => {
        setcommissionerData({
            ...commissionerData,
            [e.target.name]: e.target.value
        });
    };
    const updateSData = (e: any) => {
        setcommissionerData({
            ...commissionerData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(()=>{
    //  
    },[commissionerData])

    const updateFData = (e:any) =>{
        
            setcontractData({
                ...contractData,
                ['file'] : e.file,
                ['filepath']: e.filedata.filePath,
                ['filename']: e.filedata.fileName,
                ['isFilePond']:false,
            });
    }

    const updatefilepond = () =>{
        setcontractData({
            ...contractData,
            ['file'] : "",
            ['filepath']: "",
            ['filename']: "",
            ['isFilePond']:"",
        });
    }


    const addFunder = () => {
        // 
        let obj = {
            funderData: contractData,
            commisionerData: commissionerData
        };
        const isexists = [...ListData].some((val:any)=> val.funderData.fFunder === contractData.fFunder);
        if(isexists){
            swal("Funder is already added", {
                icon: 'error',
                dangerMode: true
            });
        }else{
            setlistdata([...ListData, obj]);
            setDisplay(false);
            setcommissionerData('');
            setcontractData('');
            props.getcontracterror(isContracterror);
        }
    };

    const hideform = (val:any) =>{
        setDisplay(false);
        props.getcontracterror(true);
    }

    const isadd = (isadd: boolean) => {
        setDisplay(isadd);

        if(ListData?.length >=  1){
            setBack(true);
        }
        else{
            setBack(false);
        }
        if(!isadd){
            props.getcontracterror(true);
        }
        if(isadd){
            props.getcontracterror(false);
        }
            
    };

    const updatedfunderlist = (updatedList: any) => {
        
            setlistdata(updatedList);
    };

    const getcontracterror = (iserror:boolean) =>{
        setContracterror(iserror);
    }

    const deletefunderlist = (data:any) =>{
        
    props.deletefunderlist(data);
    }

    return (
        <>
            {ListData === '' && (
                <>
                    <div>
                        <ContractView condata={contractData?.fFunderVal} patientname={props.patientname} updatefilepond={updatefilepond} updateData={updateData} commissionerData={commissionerData} updateCData={updateCData} updateSData={updateSData} citylist={DataCityInfo} updateFData={updateFData} funderlist={DataFunderInfo} getcontracterror={getcontracterror}   addFunder={addFunder} isdisplay={isDisplay} isCheck={isCheck} fundertype={fundertype}
                        changetype={changetype} selfData={props.selfData}  hideform={hideform} isback={isBack} selectedfunder={ListData}/>
                    </div>
                    <div>
                        <FunderListContainer patientname={props.patientname} citylist={DataCityInfo} funderdlist={DataFunderInfo} funderList={ListData} updatedfunderlist={updatedfunderlist} getfundelisterror={props.getfundelisterror} isadd={isadd} deletefunderlist={deletefunderlist} selfData={props.selfData}/>
                    </div>
                </>
            )}
            {ListData !== '' && (
                <>
                    <div>
                        <FunderListContainer patientname={props.patientname} citylist={DataCityInfo} funderdlist={DataFunderInfo} funderList={ListData} getfundelisterror={props.getfundelisterror} updatedfunderlist={updatedfunderlist} isadd={isadd} deletefunderlist={deletefunderlist} selfData={props.selfData}/>
                    </div>
                    <div>
                        <ContractView condata={contractData?.fFunderVal} patientname={props.patientname} updatefilepond={updatefilepond}  updateData={updateData} updateFData={updateFData} funderlist={DataFunderInfo} commissionerData={commissionerData} updateCData={updateCData} updateSData={updateSData} citylist={DataCityInfo} getcontracterror={getcontracterror}  addFunder={addFunder} isdisplay={isDisplay} isCheck={isCheck} fundertype={fundertype} changetype={changetype} selfData={props.selfData}  selectedfunder={ListData} hideform={hideform} isback={isBack}/>
                    </div>
                </>
            )}
        </>
    );
};

export default ContractContainer;
