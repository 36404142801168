import axios from 'axios';
import { useSelector } from 'react-redux';
import { store } from '../store';
const instance = axios.create();
instance.interceptors.request.use(config => {
  const state = store.getState();
  const token = state.login.DataLogin.jwtToken;
  if (token) {
    config.headers['accept'] = '*/*'
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
},
error => {
  Promise.reject(error)
});

export default instance;
