import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import General_Info from '../../../assets/images/general-info-white.svg';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import moment from 'moment';
import { AssessmentStatus } from '../../../utils/constant';

const GeneralInfoView = (props: any) => {
    const { t } = useTranslation();

    const { assessmentDetails } = props;

    let [supportedPersonDateOfBirth, setSupportedPersonDateOfBirth] = useState<any>(null);
    let [assessmentDetailsGeneralView, setassessmentDetailsGeneralView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        relationshipWithReferedPerson: Yup.string().required(),
        telephoneNo: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        email: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        fullName: Yup.string().required(),
        title: Yup.string().required(),
        preferredName: Yup.string().required(),
        currentAddress: Yup.string().required(),
        telephoneNoHome: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        telephoneNoOther: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        supportedPersonEmail: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        supportedPersonGender: Yup.string().strict(true).nonNullable().required('Gender is required.'),
        supportedPersonDateOfBirth: Yup.date().required('Date of birth is required.'),
        supportedPersonNhsnumber: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|\b\d{3}\s?\d{3}\s?\d{4}\b$/)
            .required(),
        preferredMethodOfCommunication: Yup.string().required(),
        nextOfKinRelationship: Yup.string().required(),
        nextOfKinAddress: Yup.string().required(),
        nextOfKinEmail: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        nextOfKinTelephoneNoHome: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        nextOfKinTelephoneNoOther: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        ifNoNextOfKinRelationship: Yup.string().required(),
        ifNoNextOfKinAddress: Yup.string().required(),
        ifNoNextOfKinEmail: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        ifNoNextOfKinTelephoneNoHome: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        ifNoNextOfKinTelephoneNoOther: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        socialWorkerAddress: Yup.string().required(),
        socialWorkerEmail: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        socialWorkerTelephone: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        gpaddress: Yup.string().required(),
        gpemail: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        gptelephone: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        anAssesmentFromLocalAuthorityOrOther: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        otherProfessionalName: Yup.string().required(),
        otherProfessionalWorkBase: Yup.string().required(),
        otherProfessionalEmail: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        otherProfessionalTelephone: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .required(),
        fundingLocalAuthority: Yup.string().required(),
        fundingChc: Yup.string().required(),
        fundingPersonalBudget: Yup.string().required(),
        isThisJointyFundingPackage: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        riskFectors: Yup.string().required(),
        doesThePersonHavePrimaryDiagnosis: Yup.string().required(),
        areThereAnyOtherHealthCondition: Yup.string().required(),
        positiveBehaviourSupport: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        currentPlan: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        isPbsinvolvementRequiredForAssessment: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        personConcentTotheAssessment: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        personLacksCapicity: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        howHaveYouHeardOf: Yup.string().required(),
        nameOfPersonCompletingGeneralInformation: Yup.string().required()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    const watchAllFields = watch();
    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsGeneralView(props?.assessmentDetails?.generalInformation?.formData);

            setValue('name', props?.assessmentDetails?.generalInformation?.formData?.name ? props?.assessmentDetails?.generalInformation?.formData?.name : '', { shouldValidate: true });
            setValue(
                'relationshipWithReferedPerson',
                props?.assessmentDetails?.generalInformation?.formData?.relationshipWithReferedPerson ? props?.assessmentDetails?.generalInformation?.formData?.relationshipWithReferedPerson : '',
                { shouldValidate: true }
            );
            setValue('telephoneNo', props?.assessmentDetails?.generalInformation?.formData?.telephoneNo ? props?.assessmentDetails?.generalInformation?.formData?.telephoneNo : '', {
                shouldValidate: true
            });
            setValue('email', props?.assessmentDetails?.generalInformation?.formData?.email ? props?.assessmentDetails?.generalInformation?.formData?.email : '', { shouldValidate: true });
            setValue('fullName', props?.assessmentDetails?.generalInformation?.formData?.fullName ? props?.assessmentDetails?.generalInformation?.formData?.fullName : '', { shouldValidate: true });
            setValue('title', props?.assessmentDetails?.generalInformation?.formData?.title ? props?.assessmentDetails?.generalInformation?.formData?.title : '', { shouldValidate: true });
            setValue('preferredName', props?.assessmentDetails?.generalInformation?.formData?.preferredName ? props?.assessmentDetails?.generalInformation?.formData?.preferredName : '', {
                shouldValidate: true
            });
            setValue('currentAddress', props?.assessmentDetails?.generalInformation?.formData?.currentAddress ? props?.assessmentDetails?.generalInformation?.formData?.currentAddress : '', {
                shouldValidate: true
            });
            setValue('telephoneNoHome', props?.assessmentDetails?.generalInformation?.formData?.telephoneNoHome ? props?.assessmentDetails?.generalInformation?.formData?.telephoneNoHome : '', {
                shouldValidate: true
            });
            setValue('telephoneNoOther', props?.assessmentDetails?.generalInformation?.formData?.telephoneNoOther ? props?.assessmentDetails?.generalInformation?.formData?.telephoneNoOther : '', {
                shouldValidate: true
            });
            setValue(
                'supportedPersonEmail',
                props?.assessmentDetails?.generalInformation?.formData?.supportedPersonEmail ? props?.assessmentDetails?.generalInformation?.formData?.supportedPersonEmail : '',
                { shouldValidate: true }
            );
            setValue(
                'supportedPersonGender',
                props?.assessmentDetails?.generalInformation?.formData?.supportedPersonGender ? props?.assessmentDetails?.generalInformation?.formData?.supportedPersonGender?.toString() : null,
                { shouldValidate: true }
            );
            setValue(
                'supportedPersonDateOfBirth',
                props?.assessmentDetails?.generalInformation?.formData?.supportedPersonDateOfBirth
                    ? window.$utils.dateFormat(props?.assessmentDetails?.generalInformation?.formData?.supportedPersonDateOfBirth)
                    : '',
                { shouldValidate: true }
            );
            let duedate = moment(props?.assessmentDetails?.generalInformation?.formData?.supportedPersonDateOfBirth, 'DD-MM-YYYY');
            setSupportedPersonDateOfBirth(props?.assessmentDetails?.generalInformation?.formData?.supportedPersonDateOfBirth ? dayjs(new Date(duedate.toDate())) : '');
            setValue(
                'supportedPersonNhsnumber',
                props?.assessmentDetails?.generalInformation?.formData?.supportedPersonNhsnumber ? props?.assessmentDetails?.generalInformation?.formData?.supportedPersonNhsnumber : '',
                { shouldValidate: true }
            );
            setValue(
                'preferredMethodOfCommunication',
                props?.assessmentDetails?.generalInformation?.formData?.preferredMethodOfCommunication ? props?.assessmentDetails?.generalInformation?.formData?.preferredMethodOfCommunication : '',
                { shouldValidate: true }
            );
            setValue(
                'nextOfKinRelationship',
                props?.assessmentDetails?.generalInformation?.formData?.nextOfKinRelationship ? props?.assessmentDetails?.generalInformation?.formData?.nextOfKinRelationship : '',
                { shouldValidate: true }
            );
            setValue('nextOfKinAddress', props?.assessmentDetails?.generalInformation?.formData?.nextOfKinAddress ? props?.assessmentDetails?.generalInformation?.formData?.nextOfKinAddress : '', {
                shouldValidate: true
            });
            setValue('nextOfKinEmail', props?.assessmentDetails?.generalInformation?.formData?.nextOfKinEmail ? props?.assessmentDetails?.generalInformation?.formData?.nextOfKinEmail : '', {
                shouldValidate: true
            });
            setValue(
                'nextOfKinTelephoneNoHome',
                props?.assessmentDetails?.generalInformation?.formData?.nextOfKinTelephoneNoHome ? props?.assessmentDetails?.generalInformation?.formData?.nextOfKinTelephoneNoHome : '',
                { shouldValidate: true }
            );
            setValue(
                'nextOfKinTelephoneNoOther',
                props?.assessmentDetails?.generalInformation?.formData?.nextOfKinTelephoneNoOther ? props?.assessmentDetails?.generalInformation?.formData?.nextOfKinTelephoneNoOther : '',
                { shouldValidate: true }
            );
            setValue(
                'ifNoNextOfKinRelationship',
                props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinRelationship ? props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinRelationship : '',
                { shouldValidate: true }
            );
            setValue(
                'ifNoNextOfKinAddress',
                props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinAddress ? props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinAddress : '',
                { shouldValidate: true }
            );
            setValue(
                'ifNoNextOfKinEmail',
                props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinEmail ? props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinEmail : '',
                { shouldValidate: true }
            );
            setValue(
                'ifNoNextOfKinTelephoneNoHome',
                props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinTelephoneNoHome ? props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinTelephoneNoHome : '',
                { shouldValidate: true }
            );
            setValue(
                'ifNoNextOfKinTelephoneNoOther',
                props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinTelephoneNoOther ? props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinTelephoneNoOther : '',
                { shouldValidate: true }
            );
            setValue(
                'socialWorkerAddress',
                props?.assessmentDetails?.generalInformation?.formData?.socialWorkerAddress ? props?.assessmentDetails?.generalInformation?.formData?.socialWorkerAddress : '',
                { shouldValidate: true }
            );
            setValue('socialWorkerEmail', props?.assessmentDetails?.generalInformation?.formData?.socialWorkerEmail ? props?.assessmentDetails?.generalInformation?.formData?.socialWorkerEmail : '', {
                shouldValidate: true
            });
            setValue(
                'socialWorkerTelephone',
                props?.assessmentDetails?.generalInformation?.formData?.socialWorkerTelephone ? props?.assessmentDetails?.generalInformation?.formData?.socialWorkerTelephone : '',
                { shouldValidate: true }
            );
            setValue('gpaddress', props?.assessmentDetails?.generalInformation?.formData?.gpaddress ? props?.assessmentDetails?.generalInformation?.formData?.gpaddress : '', { shouldValidate: true });
            setValue('gpemail', props?.assessmentDetails?.generalInformation?.formData?.gpemail ? props?.assessmentDetails?.generalInformation?.formData?.gpemail : '', { shouldValidate: true });
            setValue('gptelephone', props?.assessmentDetails?.generalInformation?.formData?.gptelephone ? props?.assessmentDetails?.generalInformation?.formData?.gptelephone : '', {
                shouldValidate: true
            });
            setValue('anAssesmentFromLocalAuthorityOrOther', props?.assessmentDetails?.generalInformation?.formData?.anAssesmentFromLocalAuthorityOrOther == 1 ? '1' : '0', { shouldValidate: true });
            setValue(
                'otherProfessionalName',
                props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalName ? props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalName : '',
                { shouldValidate: true }
            );
            setValue(
                'otherProfessionalWorkBase',
                props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalWorkBase ? props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalWorkBase : '',
                { shouldValidate: true }
            );
            setValue(
                'otherProfessionalEmail',
                props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalEmail ? props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalEmail : '',
                { shouldValidate: true }
            );
            setValue(
                'otherProfessionalTelephone',
                props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalTelephone ? props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalTelephone : '',
                { shouldValidate: true }
            );
            setValue(
                'fundingLocalAuthority',
                props?.assessmentDetails?.generalInformation?.formData?.fundingLocalAuthority ? props?.assessmentDetails?.generalInformation?.formData?.fundingLocalAuthority : '',
                { shouldValidate: true }
            );
            setValue('fundingChc', props?.assessmentDetails?.generalInformation?.formData?.fundingChc ? props?.assessmentDetails?.generalInformation?.formData?.fundingChc : '', {
                shouldValidate: true
            });
            setValue(
                'fundingPersonalBudget',
                props?.assessmentDetails?.generalInformation?.formData?.fundingPersonalBudget ? props?.assessmentDetails?.generalInformation?.formData?.fundingPersonalBudget : '',
                { shouldValidate: true }
            );
            setValue('isThisJointyFundingPackage', props?.assessmentDetails?.generalInformation?.formData?.isThisJointyFundingPackage === 1 ? '1' : '0', { shouldValidate: true });
            setValue('riskFectors', props?.assessmentDetails?.generalInformation?.formData?.riskFectors ? props?.assessmentDetails?.generalInformation?.formData?.riskFectors : '', {
                shouldValidate: true
            });
            setValue(
                'doesThePersonHavePrimaryDiagnosis',
                props?.assessmentDetails?.generalInformation?.formData?.doesThePersonHavePrimaryDiagnosis
                    ? props?.assessmentDetails?.generalInformation?.formData?.doesThePersonHavePrimaryDiagnosis
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyOtherHealthCondition',
                props?.assessmentDetails?.generalInformation?.formData?.areThereAnyOtherHealthCondition ? props?.assessmentDetails?.generalInformation?.formData?.areThereAnyOtherHealthCondition : '',
                { shouldValidate: true }
            );
            setValue('positiveBehaviourSupport', props?.assessmentDetails?.generalInformation?.formData?.positiveBehaviourSupport === 1 ? '1' : '0', { shouldValidate: true });
            setValue('currentPlan', props?.assessmentDetails?.generalInformation?.formData?.currentPlan === 1 ? '1' : '0', { shouldValidate: true });
            setValue('isPbsinvolvementRequiredForAssessment', props?.assessmentDetails?.generalInformation?.formData?.isPbsinvolvementRequiredForAssessment === 1 ? '1' : '0', {
                shouldValidate: true
            });
            setValue('personConcentTotheAssessment', props?.assessmentDetails?.generalInformation?.formData?.personConcentTotheAssessment === 1 ? '1' : '0', { shouldValidate: true });
            setValue('personLacksCapicity', props?.assessmentDetails?.generalInformation?.formData?.personLacksCapicity === 1 ? '1' : '0', { shouldValidate: true });
            setValue('howHaveYouHeardOf', props?.assessmentDetails?.generalInformation?.formData?.howHaveYouHeardOf ? props?.assessmentDetails?.generalInformation?.formData?.howHaveYouHeardOf : '', {
                shouldValidate: true
            });
            setValue(
                'nameOfPersonCompletingGeneralInformation',
                props?.assessmentDetails?.generalInformation?.formData?.nameOfPersonCompletingGeneralInformation
                    ? props?.assessmentDetails?.generalInformation?.formData?.nameOfPersonCompletingGeneralInformation
                    : '',
                { shouldValidate: true }
            );
            // setValue('duedate', props.invoicedata?.invoiceModel?.dueDate ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.dueDate) : '', { shouldValidate: true });
        }
    }, [assessmentDetails]);
    useEffect(() => {
        props.generalInfoFormValid(formState.isValid);
        props.generalInfoDataChange(watchAllFields);
        // if (formState.isValid) {
        //     props.generalInfoDataChange(watchAllFields)
        // }
    }, [formState]);

    return (
        <div id="general-info">
            <div className="modal-title-header mb-2">
                <h3>
                    <img src={General_Info} alt="" width={20} className="me-2" />
                    {t('GeneralInfo.General_Information')}
                </h3>
            </div>
            <p className="purple-text bold mb-2 mt-3">{t('GeneralInfo.Referrer_Details')}</p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('name', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'name' as const}
                            className={`form-control white ${errors.name ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.name}</p>}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Relationship_Person')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('relationshipWithReferedPerson', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'relationshipWithReferedPerson' as const}
                            className={`form-control white ${errors.relationshipWithReferedPerson ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.relationshipWithReferedPerson}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('telephoneNo', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'telephoneNo' as const}
                            className={`form-control white ${errors.telephoneNo ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.telephoneNo}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('email', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'email' as const}
                            className={`form-control white ${errors.email ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.email}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mb-2 mt-1">{t('GeneralInfo.Supported_Details')}</p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Full_Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('fullName', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fullName' as const}
                            className={`form-control white ${errors.fullName ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.fullName}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Title')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('title', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'title' as const}
                            className={`form-control white ${errors.title ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.title}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Preferred_Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('preferredName', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'preferredName' as const}
                            className={`form-control white ${errors.preferredName ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.preferredName}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Current_Address')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('currentAddress', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'currentAddress' as const}
                            className={`form-control white ${errors.currentAddress ? 'is-invalid' : ''}`}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.currentAddress}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number_H')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('telephoneNoHome', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'telephoneNoHome' as const}
                            className={`form-control white ${errors.telephoneNoHome ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.telephoneNoHome}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number_O')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('telephoneNoOther', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'telephoneNoOther' as const}
                            className={`form-control white ${errors.telephoneNoOther ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.telephoneNoOther}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('supportedPersonEmail', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'supportedPersonEmail' as const}
                            className={`form-control white ${errors.supportedPersonEmail ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.supportedPersonEmail}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Gender')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <select
                            {...register('supportedPersonGender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'supportedPersonGender' as const}
                            //@ts-ignore
                            className={`form-control white ${errors.supportedPersonGender ? 'is-invalid' : ''}`}
                            id="supportedPersonGender"
                            defaultValue={1}
                        >
                            <option value={1}>Male</option>
                            <option value={2}>Female</option>
                            <option value={3}>Other</option>
                        </select>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.supportedPersonGender === 1 ? 'Male' : assessmentDetailsGeneralView.supportedPersonGender === 2 ? 'Female' : 'Other'}</p>}
                    {/* <p className='bold mb-2'>Male</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Date_Birth')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <div className="input-group date datepicker-input-css" id="datepicker">
                            {/* <DatePicker className="form-control white" format="DD/MM/YYYY" /> */}
                            <Controller
                                control={control}
                                name="supportedPersonDateOfBirth"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control white ${error ? 'is-invalid' : ''}`}
                                            value={supportedPersonDateOfBirth}
                                            format="DD/MM/YYYY"
                                            {...fieldProps}
                                            onChange={(date: any) => {
                                                fieldProps.onChange(date);
                                                // props.getDate(date);
                                            }}
                                            disableFuture
                                            views={['year', 'month', 'day']}
                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                        />
                                    );
                                }}
                            />
                        </div>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsGeneralView.supportedPersonDateOfBirth))}</p>}
                    {/* <p className='bold mb-2'>DD/MM/YYYY</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.NHS_Number')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('supportedPersonNhsnumber', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'supportedPersonNhsnumber' as const}
                            className={`form-control white ${errors.supportedPersonNhsnumber ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.supportedPersonNhsnumber}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Preferred_Communication')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('preferredMethodOfCommunication', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'preferredMethodOfCommunication' as const}
                            className={`form-control white ${errors.preferredMethodOfCommunication ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.preferredMethodOfCommunication}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mb-2 mt-1">{t('GeneralInfo.Next_Kin')}</p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Relationship')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('nextOfKinRelationship', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'nextOfKinRelationship' as const}
                            className={`form-control white ${errors.nextOfKinRelationship ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.nextOfKinRelationship}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Address')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('nextOfKinAddress', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'nextOfKinAddress' as const}
                            className={`form-control white ${errors.nextOfKinAddress ? 'is-invalid' : ''}`}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.nextOfKinAddress}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('nextOfKinEmail', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'nextOfKinEmail' as const}
                            className={`form-control white ${errors.nextOfKinEmail ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.nextOfKinEmail}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number_H')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('nextOfKinTelephoneNoHome', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'nextOfKinTelephoneNoHome' as const}
                            className={`form-control white ${errors.nextOfKinTelephoneNoHome ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.nextOfKinTelephoneNoHome}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number_O')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('nextOfKinTelephoneNoOther', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'nextOfKinTelephoneNoOther' as const}
                            className={`form-control white ${errors.nextOfKinTelephoneNoOther ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.nextOfKinTelephoneNoOther}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mb-2 mt-1">{t('GeneralInfo.Who_not_kin')}</p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Relationship')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('ifNoNextOfKinRelationship', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'ifNoNextOfKinRelationship' as const}
                            className={`form-control white ${errors.ifNoNextOfKinRelationship ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.ifNoNextOfKinRelationship}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Address')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('ifNoNextOfKinAddress', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'ifNoNextOfKinAddress' as const}
                            className={`form-control white ${errors.ifNoNextOfKinAddress ? 'is-invalid' : ''}`}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.ifNoNextOfKinAddress}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('ifNoNextOfKinEmail', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'ifNoNextOfKinEmail' as const}
                            className={`form-control white ${errors.ifNoNextOfKinEmail ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.ifNoNextOfKinEmail}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number_H')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('ifNoNextOfKinTelephoneNoHome', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'ifNoNextOfKinTelephoneNoHome' as const}
                            className={`form-control white ${errors.ifNoNextOfKinTelephoneNoHome ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.ifNoNextOfKinTelephoneNoHome}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone_Number_O')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('ifNoNextOfKinTelephoneNoOther', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'ifNoNextOfKinTelephoneNoOther' as const}
                            className={`form-control white ${errors.ifNoNextOfKinTelephoneNoOther ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.ifNoNextOfKinTelephoneNoOther}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mb-2 mt-1">{t('GeneralInfo.Care_Manager_Social_Worker')}</p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Address')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('socialWorkerAddress', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'socialWorkerAddress' as const}
                            className={`form-control white ${errors.socialWorkerAddress ? 'is-invalid' : ''}`}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.socialWorkerAddress}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('socialWorkerEmail', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'socialWorkerEmail' as const}
                            className={`form-control white ${errors.socialWorkerEmail ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.socialWorkerEmail}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('socialWorkerTelephone', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'socialWorkerTelephone' as const}
                            className={`form-control white ${errors.socialWorkerTelephone ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.socialWorkerTelephone}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mb-2 mt-1">{t('GeneralInfo.Name_GP')}</p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Address')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('gpaddress', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gpaddress' as const}
                            className={`form-control white ${errors.gpaddress ? 'is-invalid' : ''}`}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.gpaddress}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('gpemail', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gpemail' as const}
                            className={`form-control white ${errors.gpemail ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.gpemail}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('gptelephone', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gptelephone' as const}
                            className={`form-control white ${errors.gptelephone ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.gptelephone}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mb-2 mt-1">{t('GeneralInfo.Assessment_Detail')}</p>
            <div className="d-flex">
                <label>{t('GeneralInfo.Is_authority_other')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('anAssesmentFromLocalAuthorityOrOther', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'anAssesmentFromLocalAuthorityOrOther' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('anAssesmentFromLocalAuthorityOrOther', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'anAssesmentFromLocalAuthorityOrOther' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.anAssesmentFromLocalAuthorityOrOther === 1 ? 'Yes' : 'No'}</p>}
            </div>
            <p className="purple-text">
                <i>{t('GeneralInfo.Office_use_only')}</i>
            </p>

            <p className="purple-text bold mb-2 mt-4">
                {t('GeneralInfo.Other_Professionals')}{' '}
                <span className="font-light">
                    <i>{t('GeneralInfo.Other_Professionals_span')} </i>
                </span>
            </p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('otherProfessionalName', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'otherProfessionalName' as const}
                            className={`form-control white ${errors.otherProfessionalName ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.otherProfessionalName}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Work_Base')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('otherProfessionalWorkBase', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'otherProfessionalWorkBase' as const}
                            className={`form-control white ${errors.otherProfessionalWorkBase ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.otherProfessionalWorkBase}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Email')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('otherProfessionalEmail', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'otherProfessionalEmail' as const}
                            className={`form-control white ${errors.otherProfessionalEmail ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.otherProfessionalEmail}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Telephone')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('otherProfessionalTelephone', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'otherProfessionalTelephone' as const}
                            className={`form-control white ${errors.otherProfessionalTelephone ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.otherProfessionalTelephone}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <p className="purple-text bold mb-2 mt-1">
                {t('GeneralInfo.Funding')}{' '}
                <span className="font-light">
                    <i> {t('GeneralInfo.Funding_span')}</i>
                </span>
            </p>
            <div className="row">
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Local_Authority')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('fundingLocalAuthority', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fundingLocalAuthority' as const}
                            className={`form-control white ${errors.fundingLocalAuthority ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.fundingLocalAuthority}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.CHC')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('fundingChc', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fundingChc' as const}
                            className={`form-control white ${errors.fundingChc ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.fundingChc}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-lg-4 mb-2">
                    <label className="mb-2">{t('GeneralInfo.Personal_Budget')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('fundingPersonalBudget', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fundingPersonalBudget' as const}
                            className={`form-control white ${errors.fundingPersonalBudget ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.fundingPersonalBudget}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <div className="d-flex mt-2">
                <label>{t('GeneralInfo.Is_funded_package')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('isThisJointyFundingPackage', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'isThisJointyFundingPackage' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('isThisJointyFundingPackage', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'isThisJointyFundingPackage' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.isThisJointyFundingPackage === 1 ? 'Yes' : 'No'}</p>}
            </div>

            <p className="purple-text bold mb-2 mt-3">
                {t('GeneralInfo.risk_factors_current_home')}{' '}
                <span className="font-light">
                    <i> {t('GeneralInfo.risk_factors_current_home_span')} </i>
                </span>
            </p>
            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                <textarea
                    {...register('riskFectors', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'riskFectors' as const}
                    className={`form-control white ${errors.riskFectors ? 'is-invalid' : ''}`}
                    rows={2}
                ></textarea>
            )}
            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.riskFectors}</p>}

            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}

            <p className="purple-text bold mb-2 mt-3">
                {t('GeneralInfo.Does_primary_diagnosis')}{' '}
                <span className="font-light">
                    <i> {t('GeneralInfo.Does_primary_diagnosis_span')}</i>
                </span>
            </p>
            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                <textarea
                    {...register('doesThePersonHavePrimaryDiagnosis', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'doesThePersonHavePrimaryDiagnosis' as const}
                    className={`form-control white ${errors.doesThePersonHavePrimaryDiagnosis ? 'is-invalid' : ''}`}
                    rows={2}
                ></textarea>
            )}
            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.doesThePersonHavePrimaryDiagnosis}</p>}

            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}

            <p className="purple-text bold mb-2 mt-3">
                {t('GeneralInfo.Are_health_conditions')}{' '}
                <span className="font-light">
                    <i> {t('GeneralInfo.Are_health_conditions_span')} </i>
                </span>
            </p>
            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                <textarea
                    {...register('areThereAnyOtherHealthCondition', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'areThereAnyOtherHealthCondition' as const}
                    className={`form-control white ${errors.areThereAnyOtherHealthCondition ? 'is-invalid' : ''}`}
                    rows={2}
                ></textarea>
            )}
            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.areThereAnyOtherHealthCondition}</p>}

            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}

            <p className="purple-text bold mb-2 mt-3">{t('GeneralInfo.Positive_Support')} </p>
            <div className="d-flex mt-2">
                <label>{t('GeneralInfo.Does_Positive_Behaviour_Support')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('positiveBehaviourSupport', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'positiveBehaviourSupport' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('positiveBehaviourSupport', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'positiveBehaviourSupport' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.positiveBehaviourSupport === 1 ? 'Yes' : 'No'}</p>}
            </div>
            <div className="d-flex mt-2">
                <label>{t('GeneralInfo.Does_current_PBS_Plan')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('currentPlan', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'currentPlan' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('currentPlan', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'currentPlan' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.currentPlan === 1 ? 'Yes' : 'No'}</p>}
            </div>
            <div className="d-flex mt-2">
                <label>{t('GeneralInfo.PBS_involvement_assessment')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('isPbsinvolvementRequiredForAssessment', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'isPbsinvolvementRequiredForAssessment' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('isPbsinvolvementRequiredForAssessment', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'isPbsinvolvementRequiredForAssessment' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.isPbsinvolvementRequiredForAssessment === 1 ? 'Yes' : 'No'}</p>}
            </div>

            <p className="purple-text bold mb-2 mt-3">{t('GeneralInfo.Consent_Interest')}</p>
            <div className="d-flex mt-2">
                <label>{t('GeneralInfo.accommodation')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('personConcentTotheAssessment', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'personConcentTotheAssessment' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('personConcentTotheAssessment', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'personConcentTotheAssessment' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.personConcentTotheAssessment === 1 ? 'Yes' : 'No'}</p>}
            </div>
            <div className="d-flex mt-2">
                <label>{t('GeneralInfo.Interest_Decision')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                            <input
                                type="radio"
                                {...register('personLacksCapicity', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'personLacksCapicity' as const}
                                value={1}
                                defaultChecked={true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                            <input
                                type="radio"
                                {...register('personLacksCapicity', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'personLacksCapicity' as const}
                                value={0}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{': '}{assessmentDetailsGeneralView.personLacksCapicity === 1 ? 'Yes' : 'No'}</p>}
            </div>
            <p className="purple-text mt-2">
                <i>{t('GeneralInfo.Office_use_only')}</i>
            </p>

            <p className="purple-text bold mb-2 mt-3">{t('GeneralInfo.heard_Valorum_Care_Group', {orgName: props.orgName})}</p>
            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                <textarea
                    {...register('howHaveYouHeardOf', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'howHaveYouHeardOf' as const}
                    className={`form-control white ${errors.howHaveYouHeardOf ? 'is-invalid' : ''}`}
                    rows={2}
                ></textarea>
            )}
            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.howHaveYouHeardOf}</p>}

            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}

            <p className="purple-text bold mb-2 mt-3">{t('GeneralInfo.Name_Information_Form')}</p>
            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                <input
                    type="text"
                    {...register('nameOfPersonCompletingGeneralInformation', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'nameOfPersonCompletingGeneralInformation' as const}
                    className={`form-control white ${errors.nameOfPersonCompletingGeneralInformation ? 'is-invalid' : ''}`}
                />
            )}
            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsGeneralView.nameOfPersonCompletingGeneralInformation}</p>}

            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
        </div>
    );
};

export default GeneralInfoView;
