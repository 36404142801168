import React from 'react';
import AboutUsView from './AboutUsView';

const AboutUsContainer = (props:any) => {
    return (
        <div>
            <AboutUsView serviceDescription={props?.serviceDescription}/>
        </div>
    );
};

export default AboutUsContainer;