import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceDetailModel } from '../../utils/model';

const initialState: ServiceDetailModel = {
    StatusServiceDetail: false,
    DataServiceDetail: {},
    MessageServiceDetail: '',
    ErrorServiceDetail: ''
};

export const serviceDetailSlice = createSlice({
    name: 'serviceDetail',
    initialState,
    reducers: {
        setServiceDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusServiceDetail = true;
            state.DataServiceDetail = payload;
            state.MessageServiceDetail = 'Success';
        },
        removeServiceDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusServiceDetail = false;
            state.DataServiceDetail = {};
            state.MessageServiceDetail = '';
            state.ErrorServiceDetail = '';
        },
        errorServiceDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusServiceDetail = false;
            state.DataServiceDetail = {};
            state.MessageServiceDetail = 'Failed';
            state.ErrorServiceDetail = payload;
        }
    },
    extraReducers: {}
});

export const { setServiceDetailAction, removeServiceDetailAction, errorServiceDetailAction } = serviceDetailSlice.actions;

export default serviceDetailSlice.reducer;
