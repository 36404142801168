// import Font Awesome Icons.
import './styles/all.min.css';
// import Bootstrap css.
import 'bootstrap/dist/css/bootstrap.min.css';
// import Style css.
import './styles/style.css';

// import NHS css
// import './styles/nhs-style.css';
import './styles/public_web.css';
import './styles/nhs-responsive.css';

import 'bootstrap/dist/js/bootstrap';



import React from 'react';
import ReactDOM from 'react-dom';
import App from './Application';
// import i18n
import './i18n';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
// import { store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import utils from './utils/utils';
import crypto from './utils/aesCrypto';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GenericErrorComponent from './components/GenericErrorComponent';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'react-dropdown-tree-select/dist/styles.css'
import NoInternetConnection from './NoInternetConnection'
declare global {
    interface Window {
        $utils?: any;
        $crypto?: any;
    }
}
window.$utils = utils;
window.$crypto = crypto;
let persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <NoInternetConnection>
        <ErrorBoundary FallbackComponent={GenericErrorComponent}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <App />
                        <ToastContainer />
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
        </NoInternetConnection>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
