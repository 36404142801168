import React, { Component, useState } from 'react';
import LoginView from './indexView';
import { siginService } from '../../service/auth-service';
import { useDispatch, useSelector } from 'react-redux';
import { setIsBellIconSwingable } from '../../store/Notifications/notificationBellSwingReducer';
// import { actionCreator, useAppDispatch, useAppSelector } from '../../redux';
// import { bindActionCreators } from 'redux';
// import { useNavigate } from 'react-router-dom';

interface IProps {}

interface LoginState {
    email: any;
    password: any;
}

let LoginPage: React.FC<IProps> = () => {
    const dispatch = useDispatch();

    const handleSubmit = async (data: any) => {
        const payload: any = {
            username: data.email,
            password: window.$crypto.OpenSSLEncrypt(data.password)
        };
        const rememberme: any = {
            rememberme: data.rememberme,
            email: data.email,
            password: window.$crypto.OpenSSLEncrypt(data.password)
        };
        dispatch(setIsBellIconSwingable({
            isBellIconSwingable: true
        }))
        await siginService(payload, rememberme);
    };

    return <LoginView handleSubmit={handleSubmit} />;
};

export default LoginPage;
