import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageNavigationModel } from '../../utils/model';

const initialState: PageNavigationModel = {
    ShowClass: false,
    PageName: '',
    Data: '',
    ShowToggle:false
};

export const pageNavigationSlice = createSlice({
    name: 'pageNavigation',
    initialState,
    reducers: {
        setPageNavigationAction: (state, { payload }: any) => {
            const { ShowClass, PageName, Data,ShowToggle } = payload;
            state.ShowClass = ShowClass;
            state.PageName = PageName;
            state.Data = Data;
            state.ShowToggle = ShowToggle
        },
        removePageNavigationAction: (state, { payload }: PayloadAction) => {
            state.ShowClass = false;
            state.PageName = '';
            state.Data = '';
            state.ShowToggle = false;
        }
    },
    extraReducers: {}
});

export const { setPageNavigationAction, removePageNavigationAction } = pageNavigationSlice.actions;

export default pageNavigationSlice.reducer;
