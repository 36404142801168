import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { store } from '../store';
import instance from './axiosinstance';

import { setLoginAction, errorLoginAction, removeErrorLoginAction } from '../store/Auth/loginReducer';
import { setRememberMeAction, removeRememberMeAction } from '../store/Auth/rememberMeReducer';
import { removeRoleSelectedAction } from '../store/Auth/roleSelectedReducer';
import { removeServiceDetailAction } from '../store/Auth/serviceDetailReducer';
import { removeOrganizationDetailAction } from '../store/Auth/organizationDetailReducer';
import eventEmitter from '../utils/eventEmitter';
import { setIsBellIconSwingable } from '../store/Notifications/notificationBellSwingReducer';

export const siginService = async (payload: any, data: any) => {
    eventEmitter.emit('eventSetLoader', true);
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.DevLogin}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data;
            store.dispatch(setLoginAction(resultData));
            if (data.rememberme) {
                store.dispatch(setRememberMeAction(data));
            } else {
                store.dispatch(removeRememberMeAction(data));
            }
            eventEmitter.emit('eventSetLoader', false);
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: error.message,
                    TotalCount: 0
                };
                const errorData: any = errorResponse;
                store.dispatch(errorLoginAction(errorData));
                eventEmitter.emit('eventSetLoader', false);
                return errorData;
            } else {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: 'Something went wrong!',
                    TotalCount: 0
                };
                const errorData: any = error.response ? error.response.data : errorResponse;
                store.dispatch(errorLoginAction(errorData));
                eventEmitter.emit('eventSetLoader', false);
                return errorData;
            }
        });
};


export const switchRoleService = async (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true);
    return await instance
        .get(`${BASEURLDEV}${API_ENDPOINT.SwitchRole}?roleId=${payload}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data;
            store.dispatch(removeErrorLoginAction());
            store.dispatch(removeOrganizationDetailAction());
            store.dispatch(removeServiceDetailAction());
            store.dispatch(removeRoleSelectedAction());
            store.dispatch(setLoginAction(resultData));
            store.dispatch(setIsBellIconSwingable({
                isBellIconSwingable: true
            }))
            window.sessionStorage.setItem("notificationKey", '0');
            // eventEmitter.emit('eventSetLoader', false);
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: error.message,
                    TotalCount: 0
                };
                const errorData: any = errorResponse;
                store.dispatch(errorLoginAction(errorData));
                // eventEmitter.emit('eventSetLoader', false);
                return errorData;
            } else {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: 'Something went wrong!',
                    TotalCount: 0
                };
                const errorData: any = error.response ? error.response.data : errorResponse;
                store.dispatch(errorLoginAction(errorData));
                // eventEmitter.emit('eventSetLoader', false);
                return errorData;
            }
        });
};

export const validateCurrentPasswordService = async (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true);
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ValidateCurrentPassword}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data;
            // eventEmitter.emit('eventSetLoader', false);
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: error.message,
                    TotalCount: 0
                };
                const errorData: any = errorResponse;
                // eventEmitter.emit('eventSetLoader', false);
                return errorData;
            } else {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: 'Something went wrong!',
                    TotalCount: 0
                };
                const errorData: any = error.response ? error.response.data : errorResponse;
                // eventEmitter.emit('eventSetLoader', false);
                return errorData;
            }
        });
};

export const changePasswordService = async (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true);
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ChangePassword}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data;
            // eventEmitter.emit('eventSetLoader', false);
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: error.message,
                    TotalCount: 0
                };
                const errorData: any = errorResponse;
                // eventEmitter.emit('eventSetLoader', false);
                return errorData;
            } else {
                const errorResponse = {
                    Status: false,
                    Data: {},
                    Message: 'Something went wrong!',
                    TotalCount: 0
                };
                const errorData: any = error.response ? error.response.data : errorResponse;
                // eventEmitter.emit('eventSetLoader', false);
                return errorData;
            }
        });
};