import React from 'react';
import UserDetailView from './UserDetailView';

const UserDetailContainer = () => {
    return (
        <div>
            <UserDetailView />
        </div>
    );
};

export default UserDetailContainer;