import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceStatisticsModel } from '../../utils/model';

const initialState: InvoiceStatisticsModel = {
    StatusInvoiceStatistics: false,
    DataInvoiceStatistics: '',
    MessageInvoiceStatistics: '',
    ErrorInvoiceStatistics: ''
};

export const invoiceStatisticsSlice = createSlice({
    name: 'invoiceStatistics',
    initialState,
    reducers: {
        setInvoiceStatisticsAction: (state, { payload }: PayloadAction) => {
            state.StatusInvoiceStatistics = true;
            state.DataInvoiceStatistics = payload;
            state.MessageInvoiceStatistics = 'Success';
        },
        errorInvoiceStatisticsAction: (state, { payload }: PayloadAction) => {
            state.StatusInvoiceStatistics = false;
            state.DataInvoiceStatistics = [];
            state.MessageInvoiceStatistics = 'Failed';
            state.ErrorInvoiceStatistics = payload;
        },
        removeInvoiceStatisticsAction: (state, { payload }: PayloadAction) => {
            state.StatusInvoiceStatistics = false;
            state.DataInvoiceStatistics = '';
            state.MessageInvoiceStatistics = '';
            state.ErrorInvoiceStatistics = '';
        }
    },
    extraReducers: {}
});

export const { setInvoiceStatisticsAction, errorInvoiceStatisticsAction, removeInvoiceStatisticsAction } = invoiceStatisticsSlice.actions;

export default invoiceStatisticsSlice.reducer;
