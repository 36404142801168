import { useEffect, useState } from "react";
import ApplicableView from "./applicableView"
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { coreFeesType } from "../../../../../../../utils/constant";
import eventEmitter from "../../../../../../../utils/eventEmitter";
import swal from 'sweetalert';
import warning from '../../../../../../../assets/images/warning.png'
import { ServiceTypeIconName } from "../../../../../../../utils/constant";

const ApplicableContainer = (props: any) => {
    let [applicableData, setapplicableData] = useState<any>({DailyOrWeekly: false,In1DailyOrWeekly: false,In2DailyOrWeekly: false,In3DailyOrWeekly: false,In4DailyOrWeekly: false,isSplitFunding:false,DailyOrWeeklyContinenceCareFee: false,DailyOrWeeklyFNCCareFee: false,FNCInclude : false,ContinenceInclude: false,Inclusion1included: false,Inclusion2included: false, Inclusion3included: false, Inclusion4included: false,totalAdditionalfee:0.00});
    let [dailyOrWeeklyToggle, setDailyOrWeeklyToggle] = useState<any>(false);
    let [In1dailyOrWeekly, setIn1DailyOrWeeklyToggle] = useState<any>(false);
    let [In2dailyOrWeekly, setIn2DailyOrWeeklyToggle] = useState<any>(false);
    let [In3dailyOrWeekly, setIn3DailyOrWeeklyToggle] = useState<any>(false);
    let [In4dailyOrWeekly, setIn4DailyOrWeeklyToggle] = useState<any>(false);

    let [TotalDailyOrWeekly,setTotalDailyOrWeekly] = useState<any>(true);
    let [TotalFeeDailyOrWeekly,setTotalFeeDailyOrWeekly] = useState<any>(true);

    let [AdditionalFeeH1,setAdditionalFeeH1] = useState<any>("");
    let [AdditionalFeeH2,setAdditionalFeeH2] = useState<any>("");
    let [AdditionalFeeH3,setAdditionalFeeH3] = useState<any>("");
    let [AdditionalFeeH4,setAdditionalFeeH4] = useState<any>("");

    let [Inclusion1,setInclusion1] = useState<any>("");
    let [Inclusion2,setInclusion2] = useState<any>("");
    let [Inclusion3,setInclusion3] = useState<any>("");
    let [Inclusion4,setInclusion4] = useState<any>("");

    let [Inclusion1included,setInclusion1included] = useState<boolean>(false);
    let [Inclusion2included,setInclusion2included] = useState<boolean>(false);
    let [Inclusion3included,setInclusion3included] = useState<boolean>(false);
    let [Inclusion4included,setInclusion4included] = useState<boolean>(false);

    let [totalAdditionalfee,settotalAdditionalfee] = useState<any>(0.00);

    
    let [dailyOrWeeklyContinenceCareFeeToggle, setdailyOrWeeklyContinenceCareFeeToggle] = useState<any>(false);
    let [dailyOrWeeklyFNCFeeToggle, setdailyOrWeeklyFNCFeeToggle] = useState<any>(false);
    let [FNCInclude, setFNCInclude] = useState<any>(false);
    let [ContinenceInclude, setContinenceInclude] = useState<any>(false);
    let [isSplitFunding,setisSplitFunding] = useState<any>(false);
    let [dailyfee,setdailyfee] = useState<any>("");
    let [totaldailyfee,settotaldailyfee] = useState<any>("");
    let [FNCfee,setFNCfee] = useState<any>("");
    let [Continencefee,setContinencefee] = useState<any>("");
    let [dailyweek,setdailyweek] = useState<any>(false);
    let [iseditable,seteditable] = useState<boolean>(false);
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    useEffect(() => {
        if (applicableData !== '') {
                
                props.applicabledata({ applicableData });
        }
    }, [applicableData]);

  

    // const splitfalse = () =>{
    //     setapplicableData({
    //         ...applicableData,
    //         isSplitFunding:false
    //     });
    //     if(isSplitFunding === false){
    //         props.splitchange(false)
    //     } else{
    //         props.splitchange(true)
    //     }
        
    // }

    // useEffect(() => {
    //     eventEmitter.on('setSplitfalse', splitfalse);
    //     return () => {
    //         eventEmitter.off('setSplitfalse', splitfalse);
    //     };
    // });

    useEffect(() => {
        if (StatusServiceDetail === true && DataPatientDraftInfo?.data?.admissionDetail == undefined) {
            if(DataServiceDetail.iconName === ServiceTypeIconName.NursingCare) {
                setDailyOrWeeklyToggle(true)
                setIn1DailyOrWeeklyToggle(true)
                setIn2DailyOrWeeklyToggle(true)
                setIn3DailyOrWeeklyToggle(true)
                setIn4DailyOrWeeklyToggle(true)
                setdailyOrWeeklyContinenceCareFeeToggle(true)
                setdailyOrWeeklyFNCFeeToggle(true)
                setdailyweek(true)
                setapplicableData({
                    ...applicableData,
                    'DailyOrWeekly': true,
                    'In1DailyOrWeekly': true,
                    'In2DailyOrWeekly': true,
                    'In3DailyOrWeekly': true,
                    'In4DailyOrWeekly': true,
                    'DailyOrWeeklyContinenceCareFee': true,
                    'DailyOrWeeklyFNCCareFee': true,
                    'FNCInclude': false,
                    'ContinenceInclude':false
                });
            } else {
                setDailyOrWeeklyToggle(false)
                setIn1DailyOrWeeklyToggle(false)
                setIn2DailyOrWeeklyToggle(false)
                setIn3DailyOrWeeklyToggle(false)
                setIn4DailyOrWeeklyToggle(false)
                setdailyOrWeeklyContinenceCareFeeToggle(false)
                setdailyOrWeeklyFNCFeeToggle(false)
                setdailyweek(false)
                setapplicableData({
                    ...applicableData,
                    'DailyOrWeekly': false,
                    'In1DailyOrWeekly': false,
                    'In2DailyOrWeekly': false,
                    'In3DailyOrWeekly': false,
                    'In4DailyOrWeekly': false,
                    'DailyOrWeeklyContinenceCareFee': false,
                    'DailyOrWeeklyFNCCareFee': false,
                    'FNCInclude': false,
                    'ContinenceInclude':false
                });
            }
        }
    }, [StatusServiceDetail, DataServiceDetail]);

    useEffect(()=>{
        if(TempDataSplitFunding.length > 0){
            if(TempDataSplitFunding[0].enddate !== undefined && TempDataSplitFunding[0].enddate !== null && TempDataSplitFunding[0].enddate !== ""){
                seteditable(true);
              }
        }
     
      },[TempDataSplitFunding])

    useEffect(()=>{

         if(DataPatientDraftInfo?.data?.admissionDetail !== undefined){
            let obj = {
                
                ...applicableData,
                'DailyOrWeekly': DataPatientDraftInfo?.data?.admissionDetail.coreFeesType === coreFeesType.Daily ? false : true,
                'In1DailyOrWeekly': DataPatientDraftInfo?.data?.admissionDetail.inclusionHoursType1 === coreFeesType.Daily ? false : true,
                'In2DailyOrWeekly': DataPatientDraftInfo?.data?.admissionDetail.inclusionHoursType2 === coreFeesType.Daily ? false : true,
                'In3DailyOrWeekly': DataPatientDraftInfo?.data?.admissionDetail.inclusionHoursType3 === coreFeesType.Daily ? false : true,
                'In4DailyOrWeekly': DataPatientDraftInfo?.data?.admissionDetail.inclusionHoursType4 === coreFeesType.Daily ? false : true,
                'DailyFee': DataPatientDraftInfo?.data?.admissionDetail.dailyCoreFee?.toString() || '',
                'AdditionalFeeH1': DataPatientDraftInfo?.data?.admissionDetail.hourlyFee1?.toString() || '',
                'AdditionalFeeH2': DataPatientDraftInfo?.data?.admissionDetail.hourlyFee2?.toString() || '',
                'AdditionalFeeH3': DataPatientDraftInfo?.data?.admissionDetail.hourlyFee3?.toString() || '', 
                'AdditionalFeeH4': DataPatientDraftInfo?.data?.admissionDetail.hourlyFee4?.toString() || '',
                'Inclusion1': DataPatientDraftInfo?.data?.admissionDetail.inclusionHours1?.toString() || '',
                'Inclusion2': DataPatientDraftInfo?.data?.admissionDetail.inclusionHours2?.toString() || '',
                'Inclusion3': DataPatientDraftInfo?.data?.admissionDetail.inclusionHours3?.toString() || '',
                'Inclusion4': DataPatientDraftInfo?.data?.admissionDetail.inclusionHours4?.toString() || '',
                'Socialfunding': DataPatientDraftInfo?.data?.admissionDetail.socialFunding?.toString() || '',
                'Healthfunding': DataPatientDraftInfo?.data?.admissionDetail.healthFunding?.toString() || '',
                'DailyOrWeeklyContinenceCareFee': DataPatientDraftInfo?.data?.admissionDetail.continenceCareFeeType === coreFeesType.Daily ? false : true,
                'ContinenceCareFee': DataPatientDraftInfo?.data?.admissionDetail.continenceCareFee?.toString() || '',
                'DailyOrWeeklyFNCCareFee':DataPatientDraftInfo?.data?.admissionDetail?.fncFeeType === coreFeesType.Daily ? false : true,
                'FNCFee':DataPatientDraftInfo?.data?.admissionDetail?.fncFee?.toString() || '',
                'ContinenceInclude':DataPatientDraftInfo?.data?.admissionDetail?.isContinenceFeeIncluded,
                'FNCInclude':DataPatientDraftInfo?.data?.admissionDetail?.isFncFeeIncluded,
                'isSplitFunding' : DataPatientDraftInfo?.data?.admissionDetail.isSplitFundingOninvoice === 1 ? true : false,
                'Inclusion1included':DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours1Included,
                'Inclusion2included':DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours2Included,
                'Inclusion3included':DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours3Included,
                'Inclusion4included':DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours4Included
            }
            setInclusion1included(DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours1Included);
            setInclusion2included(DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours2Included);
            setInclusion3included(DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours3Included);
            setInclusion4included(DataPatientDraftInfo?.data?.admissionDetail?.isInclusionHours4Included);

            setAdditionalFeeH1(DataPatientDraftInfo?.data?.admissionDetail.hourlyFee1?.toString() || '');
            setAdditionalFeeH2(DataPatientDraftInfo?.data?.admissionDetail.hourlyFee2?.toString() || '');
            setAdditionalFeeH3(DataPatientDraftInfo?.data?.admissionDetail.hourlyFee3?.toString() || '');
            setAdditionalFeeH4(DataPatientDraftInfo?.data?.admissionDetail.hourlyFee4?.toString() || '');

            setInclusion1(DataPatientDraftInfo?.data?.admissionDetail.inclusionHours1?.toString() || '');
            setInclusion2(DataPatientDraftInfo?.data?.admissionDetail.inclusionHours2?.toString() || '');
            setInclusion3(DataPatientDraftInfo?.data?.admissionDetail.inclusionHours3?.toString() || '');
            setInclusion4(DataPatientDraftInfo?.data?.admissionDetail.inclusionHours4?.toString() || '');

            setdailyfee(DataPatientDraftInfo?.data?.admissionDetail.dailyCoreFee?.toString() || '');
            setdailyweek(DataPatientDraftInfo?.data?.admissionDetail.coreFeesType === coreFeesType.Daily ? false : true)
            setContinencefee(DataPatientDraftInfo?.data?.admissionDetail?.continenceCareFee);
            setdailyOrWeeklyContinenceCareFeeToggle(DataPatientDraftInfo?.data?.admissionDetail?.continenceCareFeeType === coreFeesType.Daily ? false : true);
            setContinenceInclude(DataPatientDraftInfo?.data?.admissionDetail?.isContinenceFeeIncluded);

            setFNCfee(DataPatientDraftInfo?.data?.admissionDetail?.fncFee);
            setdailyOrWeeklyFNCFeeToggle(DataPatientDraftInfo?.data?.admissionDetail?.fncFeeType === coreFeesType.Daily ? false : true);
            setFNCInclude(DataPatientDraftInfo?.data?.admissionDetail?.isFncFeeIncluded);

            setIn1DailyOrWeeklyToggle(DataPatientDraftInfo?.data?.admissionDetail?.inclusionHoursType1 === coreFeesType.Daily ? false : true)
            setIn2DailyOrWeeklyToggle(DataPatientDraftInfo?.data?.admissionDetail?.inclusionHoursType2 === coreFeesType.Daily ? false : true)
            setIn3DailyOrWeeklyToggle(DataPatientDraftInfo?.data?.admissionDetail?.inclusionHoursType3 === coreFeesType.Daily ? false : true)
            setIn4DailyOrWeeklyToggle(DataPatientDraftInfo?.data?.admissionDetail?.inclusionHoursType4 === coreFeesType.Daily ? false : true)
            setapplicableData(obj);
            props.applicabledata({ applicableData : obj });
            if(DataPatientDraftInfo?.data?.admissionDetail.coreFeesType === coreFeesType.Daily) {
                setDailyOrWeeklyToggle(false)                
            } else {
                setDailyOrWeeklyToggle(true)                
            }
            
            DataPatientDraftInfo?.data?.admissionDetail.continenceCareFeeType === coreFeesType.Daily ? setdailyOrWeeklyContinenceCareFeeToggle(false) : setdailyOrWeeklyContinenceCareFeeToggle(true);
         }
    },[DataPatientDraftInfo])

    const updateadditionalfee = (value:any) =>{
        setapplicableData({
            ...applicableData,
            ['totalAdditionalfee']: Number(value).toFixed(2)
        });
    }

    const updateAData = (e: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        if(e.target.name === 'DailyOrWeekly') {
            setdailyweek(e.target.checked);
            setDailyOrWeeklyToggle(e.target.checked)            
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'In1DailyOrWeekly') {
            setIn1DailyOrWeeklyToggle(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'In2DailyOrWeekly') {
            setIn2DailyOrWeeklyToggle(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'In3DailyOrWeekly') {
            setIn3DailyOrWeeklyToggle(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'In4DailyOrWeekly') {
            setIn4DailyOrWeeklyToggle(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'isSplitFunding') {
            setisSplitFunding(e.target.checked);
            props.splitchange(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'DailyOrWeeklyContinenceCareFee') { 
            setdailyOrWeeklyContinenceCareFeeToggle(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'DailyOrWeeklyFNCCareFee'){
            setdailyOrWeeklyFNCFeeToggle(e.target.checked);
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'ContinenceInclude'){
            setContinenceInclude(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === 'FNCInclude'){
            setFNCInclude(e.target.checked)
            setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.checked
            });
        }
        else if(e.target.name === "TotalDailyOrWeekly"){
            setTotalDailyOrWeekly(e.target.checked);
        }
        else if(e.target.name === "TotalFeeDailyOrWeekly"){
            setTotalFeeDailyOrWeekly(e.target.checked);
        }
        else {
            if(e.target.name === "AdditionalFeeH1"){
                setAdditionalFeeH1(e.target.value);
            } else if(e.target.name === "AdditionalFeeH2"){
                setAdditionalFeeH2(e.target.value);
            } else if(e.target.name === "AdditionalFeeH3"){
                setAdditionalFeeH3(e.target.value);
            } else if(e.target.name === "AdditionalFeeH4"){
                setAdditionalFeeH4(e.target.value);
            } else if(e.target.name === "Inclusion1"){
                setInclusion1(e.target.value);
            } else if(e.target.name === "Inclusion2"){
                setInclusion2(e.target.value);
            } else if(e.target.name === "Inclusion3"){
                setInclusion3(e.target.value);
            } else if(e.target.name === "Inclusion4"){
                setInclusion4(e.target.value);
            } else if(e.target.name === "Inclusion1included"){
                setInclusion1included(e.target.checked);
            } else if(e.target.name === "Inclusion2included"){
                setInclusion2included(e.target.checked);
            } else if(e.target.name === "Inclusion3included"){
                setInclusion3included(e.target.checked);
            } else if(e.target.name === "Inclusion4included"){
                setInclusion4included(e.target.checked);
            }
            if(e.target.name === "Inclusion1included" || e.target.name === "Inclusion2included" || e.target.name === "Inclusion3included" || e.target.name === "Inclusion4included"){
                setapplicableData({
                    ...applicableData,
                    [e.target.name]: e.target.checked
                });
            }
            else{
                setapplicableData({
                    ...applicableData,
                    [e.target.name]: e.target.value
                });
            }
           

            if(e.target.name === 'DailyFee'){
               setdailyfee(e.target.value);
               setapplicableData({
                ...applicableData,
                [e.target.name]: e.target.value
            });
            }
            else if(e.target.name === "TotalDailyFee"){
                settotaldailyfee(e.target.value);
                setapplicableData({
                    ...applicableData,
                    [e.target.name]: e.target.value
                });
            }
            else if(e.target.name === "ContinenceCareFee"){
                setContinencefee(e.target.value);
                setapplicableData({
                    ...applicableData,
                    [e.target.name]: e.target.value
                });
            }
            else if(e.target.name === "FNCFee"){
                setFNCfee(e.target.value);
                setapplicableData({
                    ...applicableData,
                    [e.target.name]: e.target.value
                });
            }
        }
        
    };

    useEffect(()=>{
      if(applicableData?.Healthfunding !== "" && applicableData?.Socialfunding !== ""){
        if((Number(applicableData?.Healthfunding) + Number(applicableData?.Socialfunding)) > applicableData?.DailyFee){
            swal({
                title: '',
                text: "Sum of Health Funding and Social Funding values cannot be more than the Core Fees. Please adjust the values",
                icon: warning,
                dangerMode: true,
                }).then(async (confirm: any) => {
                                
                });
        }
      }
    },[applicableData]);

    useEffect(()=>{
        if(props.funderlist?.ListData?.length > 0){
            
            if(props.funderlist?.ListData[0]?.isinvoicecreated === 1){
                seteditable(false);
            }
            else{
                seteditable(true);
            }
        }
      
    }, [props.funderlist]);

    const resetprops = () =>{
        setAdditionalFeeH1("");
        setAdditionalFeeH2("");
        setAdditionalFeeH3("");
        setAdditionalFeeH4("");

        setInclusion1("");
        setInclusion2("");
        setInclusion3("");
        setInclusion4("");

        setInclusion1included(false);
        setInclusion2included(false);
        setInclusion3included(false);
        setInclusion4included(false);

        setdailyOrWeeklyFNCFeeToggle(false);
    setFNCInclude(false);
    setFNCfee("");
    setdailyOrWeeklyContinenceCareFeeToggle(false);
    setContinenceInclude(false);
    setContinencefee("");

    }

    const splitchange = (val:boolean) =>{
        props.splitchange(val);
        // setisSplitFunding(val);
        // applicableData.isSplitFunding = val;
    }

    const seteditflag = (val:boolean) => {
        seteditable(val);
    }

    const getfinalcorefee = (val:any) =>{
        settotaldailyfee(val);
        setapplicableData({
            ...applicableData,
            ["TotalDailyFee"]: Number(val)
        });
    }

    const getupdatecheckbox = (param:any,val:any) =>{
    if(param === "Continence"){
        setapplicableData({
            ...applicableData,
            ['ContinenceInclude']: false
        });
    } else if(param === "FNC"){
        setapplicableData({
            ...applicableData,
            ['FNCInclude']: false
        });
    }
    }

    const setsplitoninvoice = (val:boolean) =>{
        setapplicableData({
            ...applicableData,
            ['isSplitFunding']: val
        });
}

    return <ApplicableView  updateAData={updateAData} draftData={DataPatientDraftInfo?.data?.admissionDetail} getapplicableerror={props.getapplicableerror} dailyOrWeeklyToggle={dailyOrWeeklyToggle} In1dailyOrWeeklyToggle={In1dailyOrWeekly} In2dailyOrWeeklyToggle={In2dailyOrWeekly} In3dailyOrWeeklyToggle={In3dailyOrWeekly} In4dailyOrWeeklyToggle={In4dailyOrWeekly} funderlist={props.funderlist} totaldailyfee={totaldailyfee} dailyfee={dailyfee} dailyweek={dailyweek} admissiondate={props.admissiondate} isAction ={props.isAction} splitchange={splitchange} iseditable={iseditable} seteditflag={seteditflag} dailyOrWeeklyContinenceCareFeeToggle={dailyOrWeeklyContinenceCareFeeToggle} dailyOrWeeklyFNCFeeToggle={dailyOrWeeklyFNCFeeToggle} FNCInclude={FNCInclude} ContinenceInclude={ContinenceInclude} FNCfee={FNCfee} Continencefee={Continencefee} finalcorefee={getfinalcorefee} getupdatecheckbox={getupdatecheckbox} AdditionalFeeH1={AdditionalFeeH1} AdditionalFeeH2={AdditionalFeeH2} AdditionalFeeH3={AdditionalFeeH3} AdditionalFeeH4={AdditionalFeeH4} Inclusion1={Inclusion1} Inclusion2={Inclusion2} Inclusion3={Inclusion3} Inclusion4={Inclusion4} Inclusion1included={Inclusion1included} Inclusion2included={Inclusion2included} Inclusion3included={Inclusion3included} Inclusion4included={Inclusion4included} updateadditionalfee={updateadditionalfee} resetprops={resetprops} TotalDailyOrWeekly={TotalDailyOrWeekly} TotalFeeDailyOrWeekly={TotalFeeDailyOrWeekly} splitingoninvoice={setsplitoninvoice}/>
}


export default ApplicableContainer