import BedView from './bedView';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { rightNavigationPage } from '../../../../../utils/constant';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { removePatientDetailAction } from '../../../../../store/patient/patientDetailsReducer';
import { removePatientDraftAction } from '../../../../../store/dashboard/draftPatientData';
import { removeSplitFundingAction } from '../../../../../store/splitFunding/splitFundingReducer';
import { removeTempSplitFundingAction } from '../../../../../store/splitFunding/tempSplitFundingReducer';
import { removeFunderInformationAction } from '../../../../../store/dashboard/getFunderReducer';
import eventEmitter from '../../../../../utils/eventEmitter';

const BedPage = (props: any) => {
    let [wardBedData, setWardBedData] = useState<any>('');
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const { StatusWardBedInfo, DataWardBedInfo, MessageWardBedInfo, ErrorWardBedInfo } = useSelector((state: RootState) => state.wardBedInformation);
    const dispatch = useDispatch();
    useEffect(() => {
        if (StatusWardBedInfo === true) {
            setWardBedData(DataWardBedInfo);
            setTotalCount(DataWardBedInfo.totalCount);
        }
    }, [StatusWardBedInfo, DataWardBedInfo, MessageWardBedInfo, ErrorWardBedInfo, wardBedData, totalCount]);

    const getCurrentPage = (res: any) => {
        setCurrentPage(res);
        props.childData(res);
    };

    const handleClick = (beds: any, navType: any) => {
        eventEmitter.emit('eventClearFormArray');
        eventEmitter.emit('eventSmartSearch');
        dispatch(removePatientDraftAction());
        dispatch(removeSplitFundingAction());
        dispatch(removeTempSplitFundingAction());
        dispatch(removeFunderInformationAction());
        let data = {...beds, bedSection: true}
        let obj: any = {
            ShowClass: true,
            FormName: navType,
            Data: data,
            ShowToggle:false
        };
            dispatch(setNavigationAction(obj));
        
          if (navType === rightNavigationPage.Reserved) {
            dispatch(removePatientDetailAction());
        }
         if (navType === rightNavigationPage.Occupied) {
            dispatch(removePatientDetailAction());
        }
        
        
    };
    return <BedView handleClick={handleClick} getCurrentPage={getCurrentPage} totalCount={totalCount} wardBedData={wardBedData} currentPage={currentPage} />;
};

export default BedPage;
