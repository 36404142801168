import React, { useState } from 'react';

const PackagesDetailsView = (props: any) => {
    const [openBox, SetOpenBox] = useState(false)

    const handleBox = (event: any) => {
        event.preventDefault();
        SetOpenBox((prevState) => !prevState);
    }

    return (
        <div>

            {openBox &&
                <div className='servicesmore-box'>
                    <div className='servicemore-data'>
                        <h2 className='bolder mb-3 package-price d-flex justify-content-end align-items-end'><i className="fa fa-gbp me-2" aria-hidden="true"></i> {props?.packageDetails?.price}</h2>
                        <ul>
                            {props?.packageDetails?.packageDetails && props?.packageDetails?.packageDetails?.length > 0 &&
                                props?.packageDetails?.packageDetails?.map((pd: any, index: any) => {
                                    return (
                                        <li className='lead-text mb-1' key={index}><i className="fa fa-caret-right me-2 servicelist-righticon" aria-hidden="true"></i>{pd?.packageDetailName}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {/* <div className="d-flex justify-content-end">
                        <a href="#" onClick={handleBox} aria-labelledby="Click Text" className="white-click-btn black-click-btn">
                            <i className="fa-solid fa-close"></i>
                        </a>
                    </div> */}
                </div>
            }
            <div className={`white-btn-box ${openBox ? "open" : ""} `}>
                <a href="#" onClick={handleBox} aria-labelledby="Click Text" className={`white-click-btn ${openBox ? "rotate" : ""}`}>
                    <i className="fa-solid fa-plus"></i>
                </a>
            </div>
        </div>
    );
};

export default PackagesDetailsView;