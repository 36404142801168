import React from 'react';
import ReferralDetailsLessView from './ReferralDetailsLessView';

const ReferralDetailsLessContainer = (props: any) => {
    return (
        <div>
            <ReferralDetailsLessView referralOverView={props.referralOverView} SelectedStatus={props.SelectedStatus} CurrentStatus={props.CurrentStatus}/>
        </div>
    );
};

export default ReferralDetailsLessContainer;