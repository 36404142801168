import React from 'react';
import { useTranslation } from 'react-i18next';
import PaginationContainer from '../../../../../../components/PaginationComponent/indexContainer';
import { useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../../../../../../navigation/RoutesConstants';
import { useDispatch } from 'react-redux';
import { rightNavigationPage } from '../../../../../../utils/constant';
import { setPageNavigationAction } from '../../../../../../store/general/pageNavigationReducer';

const ConfirmationView = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showTaskDetailHandler = (data: any) => {
        let obj: any = {
            ShowClass: false,
            PageName: rightNavigationPage.InvoiceDetailPage,
            Data: data,
            ShowToggle:false
        };
        dispatch(setPageNavigationAction(obj));
        navigate(ROUTES_DATA.BILLINGMANAGEMENT);
        props.closeSideMenu();
    }
    return (
        <div className="gray-box mt-2">
            <p className='purple-text lead-text bold mb-3'>
                {props?.totalCount > 99 ? props?.totalCount : "" }
                <span className='ms-1'>{t('Task.Confirmation_Title')}</span>
            </p>
            {props?.taskList === null && (
                <p>No Data!</p>
            )}
                {props?.taskList?.length > 0 && (<ul className="list-unstyled task-list">
                    {props.taskList.map((data: any, index: any) => {
                        return (
                            <li key={data?.taskId}>
                                <a className="cursor-pointer" onClick={() => showTaskDetailHandler(data)}>
                                    {data?.message}
                                </a>
                            </li>
                        );
                    })}
                </ul>)}

                {props.currentPage > 0 && props.totalCount > 0 && props.pageSize > 0 && (
                <PaginationContainer currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} onPageChange={(page: any) => props.getCurrentPage(page)} />
            )}
        </div>
    );
};

export default ConfirmationView;