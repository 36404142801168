import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SaveForLaterServicesListModel } from '../../utils/model';

const initialState: SaveForLaterServicesListModel = {
    StatusSaveForLaterServices: false,
    SaveForLaterServicesList: '',
    MessageSaveForLaterServices: '',
    ErrorSaveForLaterServices: ''
};

export const saveForLaterServicesListSlice = createSlice({
    name: 'saveForLaterServicesList',
    initialState,
    reducers: {
        setSaveForLaterServicesListAction: (state, { payload }: PayloadAction) => {
            state.StatusSaveForLaterServices = true;
            state.SaveForLaterServicesList = payload;
            state.MessageSaveForLaterServices = 'Success';
        },
        removeSaveForLaterServicesListAction: (state, { payload }: PayloadAction) => {
            state.StatusSaveForLaterServices = false;
            state.SaveForLaterServicesList = '';
            state.MessageSaveForLaterServices = '';
            state.ErrorSaveForLaterServices = '';
        },
        errorSaveForLaterServicesListAction: (state, { payload }: PayloadAction) => {
            state.StatusSaveForLaterServices = false;
            state.SaveForLaterServicesList = [];
            state.MessageSaveForLaterServices = 'Failed';
            state.ErrorSaveForLaterServices = payload;
        }
    },
    extraReducers: {}
});

export const { setSaveForLaterServicesListAction, removeSaveForLaterServicesListAction, errorSaveForLaterServicesListAction } = saveForLaterServicesListSlice.actions;

export default saveForLaterServicesListSlice.reducer;
