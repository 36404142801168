import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PendingActivitiesModel } from '../../utils/model';

const initialState: PendingActivitiesModel = {
    StatusPendingActivities: false,
    DataPendingActivities: '',
    MessagePendingActivities: '',
    ErrorPendingActivities: ''
};

export const pendingActivitiesSlice = createSlice({
    name: 'pendingActivities',
    initialState,
    reducers: {
        setPendingActivitiesAction: (state, { payload }: PayloadAction) => {
            state.StatusPendingActivities = true;
            state.DataPendingActivities = payload;
            state.MessagePendingActivities = 'Success';
        },
        errorPendingActivitiesAction: (state, { payload }: PayloadAction) => {
            state.StatusPendingActivities = false;
            state.DataPendingActivities = [];
            state.MessagePendingActivities = 'Failed';
            state.ErrorPendingActivities = payload;
        },
        removePendingActivitiesAction: (state, { payload }: PayloadAction) => {
            state.StatusPendingActivities = false;
            state.DataPendingActivities = '';
            state.MessagePendingActivities = '';
            state.ErrorPendingActivities = '';
        }
    },
    extraReducers: {}
});

export const { setPendingActivitiesAction, errorPendingActivitiesAction, removePendingActivitiesAction } = pendingActivitiesSlice.actions;

export default pendingActivitiesSlice.reducer;
