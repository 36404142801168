export const BASEURL = process.env.REACT_APP_API_ENDPOINT;
export const BASEURLDEV = process.env.REACT_APP_API_DEVELOPMENT_ENDPOINT;

export const API_ENDPOINT = {
    Login: 'Login',
    DevLogin: 'api/auth/Login',
    GetBedInformation: 'api/bed/getbedinformation',
    GetBedsDetails: 'api/bed/ward/getbeddetails',
    GetWardDetails: 'api/bed/ward/getwarddetails',
    GetPendingActivities: 'api/admission/getpendingactivity',
    GetCityDetails: 'api/admission/getcitylist',
    GetFunderDetails: 'api/admission/getfunderlist',
    GetPatientTypeList: 'api/admission/getpatienttypelist',
    SaveAdmission:'api/admission/saveadmissiondetail',
    SaveDraft:'api/admission/savedraftPatientdetail',
    GetPatientList: 'api/admission/admissiondetail/getadmissiondetail',
    GetDraftPatientList: 'api/admission/draftdetail/getdraftdetail',
    GetPatientDetails: 'api/admission/patient/getpatientdetails/',
    GetReasons: 'api/admission/discharge/getreasons/',
    DischargePatient: 'api/admission/discharge/dischargepatient',
    TransferPatient: 'api/admission/transfer/transferpatient',
    GetListVacantbeds: 'api/bed/ward/getvacantbeds/',
    GetPatientDraftData:'api/admission/getdraftpatientbyid',
    ReleaseReservedBed:'api/admission/patient/releasereservedbed',
    DeleteDraftPatient:'api/admission/draftdetail/deletedraftpatient',
    GetReservedPatientDetails:'api/admission/getpatientdetailbyid',
    ContractFilePath:'api/admission/downloadcontractdocument',
    DeleteContractFile:'api/admission/deletecontractdocument',
    GetContractByPatientid:'api/admission/getcontractbypatientid',
    DownloadContractDocument:'api/admission/downloadcontractdocument',
    GetRelationshipDetails:'api/admission/patient/getrelationshiplist',
    UploadDocument:'api/admission/uploadcontractdocument',
    SaveBedMaintenance:'api/bed/savebedmaintenance',
    GetBedMaintenanceDetailById:'api/bed/getbedmaintenancedetailbyid',
    GetMaintenanceDetailList:'api/bed/getmaintenancedetail',
    ReleaseBedMaintenance:'/api/bed/releasebedmaintenance',
    GetPlanDetails:'/api/admission/getcurrentplan',
    GetAllPlans:'/api/admission/getplans',
    GetInvoiceList:'api/invoice/invoicelist/getinvoicelist',
    UpdateManagedColumn:'api/invoice/invoicelist/updatemanagedcolumn',
    GetManagedColumn:'api/invoice/invoicelist/getmanagedcolumn',
    UpdateInvoiceStatus:'api/invoice/invoicelist/updateinvoicestatus',
    DownloadInvoices:'api/invoice/invoicelist/downloadinvoice',
    GetAdmittedPatients : 'api/invoice/invoicedetails/getaddmittedPatient',
    GetPatientByNhs:'api/invoice/invoicedetails/getpatientdetailsbynhsid',
    GetFundersByPatient:'api/invoice/invoicedetails/getfunderdetailsbypatientid',
    GetProductLines:'api/invoice/productmaster/getproductmaster',
    GetUom:'api/invoice/productmaster/getuom',
    GetInvoiceData:'api/invoice/getinvoicebyid',
    SaveInvoice:'api/invoice/saveinvoice',
    UpdateInvoice:'api/invoice/updateinvoicedetail',
    CancelInvoice:'api/invoice/cancelinvoice',
    SplitInvoice:'api/invoice/confirmsplitinvoice',
    CheckSplitFunding:'api/invoice/validateinvoice',
    SaveSplitFunding:'api/admission/saveplandetail',
    InvoicePatientDetail:'api/invoice/getpatientdetailid',
    UploadInvoiceDocument:'api/invoice/uploadinvoicedocument',
    DownloadInvoiceDocument:'api/invoice/downloadinvoicedocument',
    GetTaskSummary:'api/invoice/task/gettasksummary',
    GetTaskList:'api/invoice/task/gettasklist',
    GetInvoiceStatistics:'api/invoice/invoicelist/getinvoicelistcount',
    GetAllVideos:'api/invoice/onlinehelp/getallvideos',
    GetLastInvoiceDate:'api/invoice/getpreviousinvoicedate',
    GetLeadList:'api/referral/getLeadlist',
    GetReferralOverView:'api/referral/getreferraloverview',    
    AddLeads:'api/referral/Referral',    
    ViewLead:'api/referral/Lead/View',
    ServiceList:'api/referral/getserviceTypelist',
    UploadPenDoc:'api/referral/uploadpenpicturedocument',
    UploadAssessmentdoc:'api/referral/assessment/uploadassessmentdocument',
    OpenPenDoc:'api/referral/downloadpenpicturedocument',
    GetFacility:'api/referral/getservicelist',
    PoolLead:'api/referral/Pooling',
    SwitchRole:'api/auth/Login/SwitchRole',
    ValidateCurrentPassword:'api/auth/Login/validatecurrentpassword',
    ChangePassword:'api/auth/login/changepassword',
    GetServiceAdd:'api/admission/getserviceaddress',
    DeleteLead:'api/referral/Delete',
    DeletePictureDocument:'api/referral/deletepenpicturedocument',
    ViewCosting:'api/referral/viewcosting',
    SaveCosting:'api/referral/savecosting',
    UserOrganizationList:'api/auth/Login/UserOrganizationList',
    GetPoolRejectionReason:'api/referral/getrejectionreasons',
    GetDDLPoolRejectionReason:'api/referral/getddlrejectionreasons',
    AcceptRejectLead:'api/referral/updatePoolingStatus',
    RMRejectsCosting:'api/referral/rejectcosting',
    AcceptCosting:'api/referral/acceptcosting',
    CancelCosting:'api/referral/cancelcosting',
    MoveToAssignment:'api/referral/sendleadforassignment',
    MoveToContract:'api/referral/sendleadforcontract',
    MoveToCosting:'api/referral/sendleadforcosting',
    UpdateLeadToAssessment:'api/referral/assessment/updateleadtoassessment',
    GetAssessmentDropdown:'api/referral/assessment/getassessmentdropdown',
    SaveAssessmentDetail:'api/referral/assessment/saveassessmentdetail',
    GetAssessmentDetails:'api/referral/assessment/getassessmentdetails',
    GetMessages:'api/referral/getmessages',
    MarkAsReadMessage:'api/referral/markasread',
    SaveContract:'api/referral/digitalcontract/savedigitalcontract',
    AddUpdateFunder:'api/referral/updateleadfunder',
    GetContractType:'api/referral/digitalcontract/getcontracttype',
    DeleteFunder:'api/referral/DeleteFunder',
    GetContractData:'api/referral/digitalcontract/getdigitalcontractdetails',
    GetContractPreview:'api/referral/digitalcontract/getcontractforpreview',
    ContractConfirmSend:'api/referral/digitalcontract/confirmdigitalcontract',
    GetContractSignUrl:'api/referral/digitalcontract/getdocusignsignurl',
    UpdateDocuSign:'api/referral/digitalcontract/getcontractfromdocusign',
    ContractAddAttachment:'/api/referral/digitalcontract/uploadcontractdocument',
    ConteactGetAttachment:'/api/referral/digitalcontract/downloadcontractdocument',
    ContractDeleteAttachment:'/api/referral/digitalcontract/deletecontractdocument',
    ContractCancelReason:'api/referral/digitalcontract/getcancellationreason',
    ContractCancelDDLReason:'api/referral/digitalcontract/getddlcancellationreason',
    ContractCancel:'api/referral/digitalcontract/cancelcontract',
    GetAdmitPatientByNhs:'api/admission/getpatientdetailsbynhsid',
    CompleteContractingStage:'api/referral/digitalcontract/completecontractingstage',
    GetPendingReferral:'api/admission/getpendingreferral',
    GetPendingReferralDetails:'api/admission/getpendingreferraldetails',
    SaveLeadDraft:'api/admission/saveleaddraft',
    UploadDigiContractDocument:'api/referral/digitalcontract/uploadcontractdocument',
    DownloadDigiContractDocument:'api/referral/digitalcontract/downloadcontractdocument',
    DeleteDigiContractFile:'api/referral/digitalcontract/deletecontractdocument',
    GetContractDataAdmission:'api/admission/getcontractdetails',
    GetLocalityData : 'api/referral/digitalcontract/getlocality',
    GetContactReferralList:'api/referral/getreferralresourcelist',
    GetCostingRecipients : 'api/referral/getcostingrecipients',
    GetCostingemailDetails : 'api/referral/getcostingemaildetails',
    SendCostingEmail : 'api/referral/SendEmail',
    GetScheduleAssessmentEmailDetails: 'api/referral/getscheduleassessmentemaildetails',
    SendScheduleAssessmentEmails: "api/referral/sendscheduledassessmentemails",
    GetAssessmentScheduleRecipients: 'api/referral/getAssessmentScheduleRecipients',
    SaveFrontendErrorLog : 'api/bed/frontenderrorlog/savefrontenderrorlog',
    GetDDLAddmittedPatient : 'api/invoice/invoicedetails/getddladdmittedPatient',
    GetDDLFunderDetailsByPatientID : 'api/invoice/invoicedetails/getddlfunderdetailsbypatientid',
    GetDDLCityList : 'api/admission/getddlcitylist',
    GetDDLRelationshipList : 'api/admission/patient/getddlrelationshiplist',
    GetContactsList:'api/referral/getddlreferralresourcelist',
    GetDDLpatienttypelist : 'api/admission/getddlpatienttypelist',
    GetDDLReasons : 'api/admission/discharge/getddlreasons',
    GetDDLFunderList : 'api/admission/getddlfunderlist',
    GetDDLuom : 'api/invoice/productmaster/getddluom',
    GetDDLRejectionReasons : 'api/referral/getddlrejectionreasons',
    GetDDLServiceTypeList : 'api/referral/getddlserviceTypelist',
    GetInvoiceRecipients : 'api/invoice/invoicedetails/getinvoicerecipients',
    GetInvoiceEmailDetails : 'api/invoice/invoicedetails/getinvoiceemaildetails',
    SendInvoiceEmail : 'api/invoice/invoicedetails/sendinvoiceemail',
    GetInternalLocalIdConfig : 'api/admission/getinternallocalidconfig',
    RemoveFunderFromPlan:'api/admission/removevoidedcancelfunderfromplan',
    ExportAdmission : 'api/bed/exportbedlist',
    GetLeadClosedStatus:'api/referral/getleadclosebutton',
    CloseReferral:'api/referral/savecloselead',
    // GetBedsDetails: 'GetBedsDetails',
    // GetWardDetails: 'GetWardDetails'
    SaveNote: 'api/referral/SaveNoteList',
    GetNotesByLeadId: 'api/referral/GetNoteListByLeadId',
    DeleteNote: 'api/referral/DeleteNote',
    ReadNotes: 'api/referral/ReadMultipleMarkNotes',
    UnreadCount: 'api/referral/GetTotalUnreadCountOfNotes',
    GetPatientInquiry: 'api/referral/patientinquery/GetPatientInquiry',
    GetIntegrationDetailslist : 'api/invoice/odoointegration/getIntegrationDetailslist',
    ExportOdooIntegration : 'api/invoice/odoointegration/exportodoointegrationdetails',
    ReprocessIntegrationTransactions:'api/invoice/odoointegration/reprocessOdooFailedTransactions',
    GetMessageCode:'api/invoice/odoointegration/getddlmessagecode',
    GetIntegrationStatus:'api/invoice/odoointegration/getddlintegrationstatus',
    GetOdooReprocessStatus : 'api/invoice/odoointegration/getOdooReprocessStatus',
    ViewAssessmentDoc:'api/referral/assessment/downloadassessmentdocument',
    SkipAssessment:'api/referral/assessment/skipAssessment',
    DeleteAssessmentDocument:'api/referral/assessment/deleteassessmentdocument',
    ExportReferrals:'api/referral/exportleadlist',
    GetAdditionalFields:'api/referral/getCostingTypeDdl?limit=0&skip=0',
    GetServiceCoordinatorDetails : 'api/bed/ServiceDetails/getServiceCoordinatorDetails',
    GetServiceProvierEmails : "api/bed/ServiceDetails/getServiceProviderEmails",
    SendServiceCoordinatorEmails : "api/bed/ServiceDetails/SendServiceCoordinatorEmails",
    GetServiceDetails : "api/bed/ServiceDetails/getservicedetailsbyid",
    GetAmmenitiesServiceLists : "api/bed/ServiceDetails/getammenitiesservicelists",
    GetServicePackageLists : "api/bed/ServiceDetails/getProviderPackageSection",
    GetServiceLocationDetailsList : "api/bed/ServiceDetails/getservicelocationDetails",
    GetProviderServiceSectionList : "api/bed/ServiceDetails/getProviderServiceSection",
    GetProviderServiceSectionDetails:"api/bed/ServiceDetails/getProviderServiceSectionDetails",
    GetLowerSectionAvailability : "api/bed/service/getLowerSectionAvailability",
    NHSAcceptRejectPoolingService: '/api/referral/patientinquery/nhsacceptrejectpooling',
    SaveForLater:"api/bed/saveforlater/saveforlaterlist",
    GetPatientListForSaveLater:"api/bed/saveforlater/getsaveforlaterpendinglist",
    GetSaveForLaterFolderList : "api/bed/saveforlater/getsaveforlaterlist",
    DeleteFolder : "api/bed/saveforlater/deletesaveforlaterfolder",
    GetWishListServices : "api/bed/saveforlater/GetSaveForLaterServiceList",
    DeleteServiceWishList : "api/bed/saveforlater/deletesaveforlaterServices",
    UpdateServiceWishlist : "api/bed/saveforlater/editsaveforlaterservicelist",
    GetServiceTypeListForUser : 'api/bed/servicetype/getservicetypelistforuser',
    GetAllServiceListForUser : 'api/bed/service/getallservicelistforuser',
    GetUserLatLongList : 'api/bed/service/getuserlatlonglist',
    NHSRejectsCosting : `api/referral/patientinquery/nhsrejectcosting`,
    GetNHSRejectionReason:'api/referral/patientinquery/getddlnhsrejectcostingreasons',
    AcceptNhsCosting : 'api/referral/patientinquery/nhsacceptcosting',
    GetLocationList: 'api/bed/service/getddllocationlist',
    GetSearviceSearchList : 'api/bed/service/getddlServiceList',
    GetAdvancedFilterCount: 'api/bed/serviceDetails/getadvancedfiltercount',
    CountryExist: 'api/bed/service/checkifcountryexists',
    GetLocationWiseLatLongList: 'api/bed/service/getlocationwiselatlonglist',
    GetDefaultLocation: 'api/bed/service/getdefaultlocation',
    Getserviceimagesbyserviceslug : "api/bed/ServiceDetails/getserviceimagesbyserviceslag", 
    IsInclusionDisplay:'api/admission/getServiceMonetaryFieldsConfiguration',
    ShareWithWhatsApp : 'https://web.whatsapp.com/send?text=',
    ShareWithEmail : 'mailto:?body=',
    ShareWithFacebook:'https://www.facebook.com/sharer/sharer.php?u=',
    ShareWithTwitterX : 'https://twitter.com/intent/tweet?url=',
    GetNotifications:'api/referral/getnotifications',
    MarkNotificationsAsRead : 'api/referral/markNotificationAsRead',
    GetNotificationsCount : 'api/referral/getnotificationcount',
    GetIntegrationSource:'api/invoice/odoointegration/getddlintegrationsource',
};
