import React from 'react';
import ServicesView from './ServicesView';

const ServicesContainer = () => {
    return (
        <div>
            <ServicesView />
        </div>
    );
};

export default ServicesContainer;