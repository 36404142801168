import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntegrationGridFilterStateModel } from '../../utils/model';

const initialState: IntegrationGridFilterStateModel = {
    StatusIntegrationGridFilterState: false,
    MessageIntegrationGridFilterState: '',
    DataIntegrationGridFilterState: '',
    ErrorIntegrationGridFilterState: ''
};

export const intigrationgridFilterStateSlice = createSlice({
    name: 'integrationgridFilterState',
    initialState,
    reducers: {
        setIntigrationGridFilterStateAction: (state, { payload }: PayloadAction) => {            
            state.StatusIntegrationGridFilterState = true;
            state.MessageIntegrationGridFilterState = 'Success';
            state.DataIntegrationGridFilterState = payload;
            state.ErrorIntegrationGridFilterState = ''
        },
        removeIntigrationGridFilterStateAction: (state, { payload }: PayloadAction) => {
            state.StatusIntegrationGridFilterState = false;
            state.MessageIntegrationGridFilterState = '';
            state.DataIntegrationGridFilterState = '';
            state.ErrorIntegrationGridFilterState = '';
        },
        errorIntigrationGridFilterStateAction: (state, { payload }: PayloadAction) => {
            state.StatusIntegrationGridFilterState = false;
            state.DataIntegrationGridFilterState = '';
            state.MessageIntegrationGridFilterState = 'Failed';
            state.ErrorIntegrationGridFilterState = payload;
        }
    },
    extraReducers: {}
});

export const { setIntigrationGridFilterStateAction, removeIntigrationGridFilterStateAction, errorIntigrationGridFilterStateAction } = intigrationgridFilterStateSlice.actions;

export default intigrationgridFilterStateSlice.reducer;
