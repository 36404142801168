import React from 'react';
import ExportIntegrationView from './ExportIntegrationView';

const ExportIntegrationContainer = (props:any) => {
    return (
        <div>
            <ExportIntegrationView {...props}/>
        </div>
    );
};

export default ExportIntegrationContainer;