import React from 'react';
import ReservedContractListContainer from './ReservedContractList/reservedContractListContainer';

const ContracrTabView = (props: any) => {
    return (
        <div>
            <div className="accordion accordion-flush" id="admissiondetail1">
                <ReservedContractListContainer patientData={props.patientData} />
            </div>
        </div>
    );
};

export default ContracrTabView;
