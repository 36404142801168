import { NHSLogin, NHSLoginPayload} from "../../utils/model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: NHSLogin = {
    isNHSReferralTracker: false,
}

export const nhsLoginSlice = createSlice({
    name: "nhsLogin",
    initialState,
    reducers: {
        setIsNhsReferralTracker: (state, { payload }: NHSLoginPayload) => {
            state.isNHSReferralTracker = payload.isNHSReferralTracker;
        },
        resetIsNhsReferralTracker: (state, { payload }: PayloadAction) => {
            state.isNHSReferralTracker = false;
        }
    }
})

export const { setIsNhsReferralTracker,resetIsNhsReferralTracker } = nhsLoginSlice.actions;

export default nhsLoginSlice.reducer;
