import React, { useState } from 'react';
import DoughnutChartContainer from '../../../../components/DoughnutChart/DoughnutChartContainer';
import BarChartContainer from '../../../../components/BarChart/BarChartContainer';
import { InvoiceStatisticsFrequency } from '../../../../utils/constant';
import eventEmitter from '../../../../utils/eventEmitter';



const BillingDetailsMoreView = (props:any) => {

    const [selectedFrequency, setSelectedFrequency] = useState<any>(1);

    const paid = props.invoiceStatistics?.paymentStatus?.paidCount || 0
    const inPaymentCount = props.invoiceStatistics?.paymentStatus?.inPaymentCount || 0
    const unPaidCount = props.invoiceStatistics?.paymentStatus?.unPaidCount || 0
    const paymentLabels = ['Paid', 'In Payment', 'Unpaid']
    const paymentValues = [paid, inPaymentCount, unPaidCount]
    const paymentColor = ['#2ed883', '#eef064', '#f37b74']


    const draft = props.invoiceStatistics?.invoiceStatus?.draftCount || 0
    const cancelled = props.invoiceStatistics?.invoiceStatus?.cancelledCount || 0
    const posted = props.invoiceStatistics?.invoiceStatus?.postedCount || 0
    const invoiceStatuslabels = ['Posted', 'Draft', 'Cancelled']
    const invoiceStatusValues = [posted, draft, cancelled]
    const invoiceStatusColor = ['#2ed883', '#A3A3EE', '#999898']


    const onChangeFrequency = (selected: any) => {        
        setSelectedFrequency(selected)
        eventEmitter.emit('eventChangeFrequency', selected)
    }
    
    return (
        <div>
            <div className='row'>
                <div className='col-lg-3 col-md-6 col-6 mb-3 mb-lg-0'>
                    <div className='box-shadow p-0 pb-0 h-100'>
                        <h3 className="d-block box-title mb-2 ps-3 pe-3 pt-3">Payment Status</h3>
                        <DoughnutChartContainer labels={paymentLabels} data={paymentValues} statuscolor={paymentColor} />
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-6 mb-3 mb-lg-0'>
                    <div className='box-shadow p-0  pb-0 h-100'>
                        <h3 className="d-block box-title ps-3 pe-3 mb-2 pt-3">Invoice Status</h3>
                        <DoughnutChartContainer labels={invoiceStatuslabels} data={invoiceStatusValues}  statuscolor={invoiceStatusColor} />
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 col-12'>
                <div className='box-shadow p-3 pb-1 h-100'>
                        <div className='d-flex justify-content-between'>
                            <h3 className="d-block box-title">Payment Details</h3>
                            <ul className="navbar-nav">
                                    <div className="nav-item dropdown year-dropdown">
                                        <a className="nav-link dropdown-toggle service-dropdown" href="#" id="navbarDropdownMenuLink1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-calendar-days me-2"></i>
                                        <span className='me-2'>{selectedFrequency === InvoiceStatisticsFrequency.CurrentYear ? `This Year` : selectedFrequency === InvoiceStatisticsFrequency.LastYear ? `Last Year` : selectedFrequency === InvoiceStatisticsFrequency.CurrentMonth ? `This Month` : selectedFrequency === InvoiceStatisticsFrequency.LastMonth ? `Last Month` : '' }</span>
                                        </a>
                                        <div className="dropdown-menu service-dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.CurrentYear)}>This Year</a></li>
                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.LastYear)}>Last Year</a></li>
                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.CurrentMonth)}>This Month</a></li>
                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.LastMonth)}>Last Month</a></li>
                                        </div>
                                    </div>
                                </ul>
                        </div>
                        <BarChartContainer invoiceStatistics={props.invoiceStatistics} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingDetailsMoreView;