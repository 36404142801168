import React from 'react';
import ContractingView from '../../../ReferralManagementPage/ReferralManager/ReferralSidebar/ReferralStages/Contracting/ContractingView';

const NHSContractingContainer = () => {
    return (
       <div>
          <ContractingView/>
       </div>
    );
};

export default NHSContractingContainer;