import React, {useState} from 'react';
import InvoiceLinesView from './InvoiceLinesView';

const InvoiceLinesContainer = (props:any) => {
    return (
        <div>
            <InvoiceLinesView duplicateStatus={props.duplicateStatus} invoiceDraftStatus={props.invoiceDraftStatus} getInvoicelines={props.getInvoicelines} getInvoicelineerror={props.getInvoicelineerror} invoicedata={props.invoicedata} deletedlines={props.deletedlines} admissionId={props.admissionId} invoicefields={props.invoicefields}/>
        </div>
    );
};

export default InvoiceLinesContainer;