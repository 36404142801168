import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FilterListContainer from '../../../../components/FilterList/FilterListContainer';
import { AdvancedFilterCountResponse } from '../../../../layout/NHSHeader/ServicetypeHeader/SearchFilter/types';
import { CountryExist, GetAdvancedFilterCount, GetAllServiceListService } from '../../../../service/serviceProvider-services';
import { RootState } from '../../../../store';
import { setFilterListsAction } from '../../../../store/filter/advanceFilterReducer';
import { setServiceListsAction } from '../../../../store/general/serviceListsReducer';
import { findDateRange } from '../../../../utils/dateRange';
import { ApiResponse } from '../../../../utils/model';
import { ServicesListsType } from '../../../NHSPage/HomeList/ListBox/types';
import MapViewView from './MapViewView';

const MapViewContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const { AppliedFilterData, TopFilterData } = useSelector((state: RootState) => state.appliedFilter);

    const { StatusUserLocation, DataUserLocation } = useSelector((state: RootState) => state.userLocation);
    const [isSwalShown, setIsSwalShown] = useState(false);

    const { DataCityWiseLatLngList } = useSelector((state: RootState) => state.cityWiseLatLngList);
    const { FilterMetaData } = useSelector((state: RootState) => state.metaFilter);

    const isDrawerOpen = useMemo(() => {
        return FilterMetaData.drawerOpen;
    }, [FilterMetaData.drawerOpen]);

    const checkIfCountryExist = async (payload: any) => {
        return await CountryExist(payload);
    };

    const getAllServiceList = async () => {
        setIsLoader(true);
        const cityName = '';

        let countApiPayload: any = { ...AppliedFilterData, isShowOutOfArea: 1 };
        const datesRange = findDateRange({ ...AppliedFilterData, ...TopFilterData });

        countApiPayload = {
            ...countApiPayload,
            cityName,
            location: AppliedFilterData?.location ? AppliedFilterData?.location : null,
            searchValue: AppliedFilterData?.searchValue ? AppliedFilterData?.searchValue : null,
            dateRange: TopFilterData.isDateChanged ? datesRange.map((item: any) => ({
                fromDate: format(item.startDate, 'yyyy-MM-dd'),
                toDate: format(item.endDate, 'yyyy-MM-dd'),
            })) : null,
        };

        const resultOfCount: ApiResponse<AdvancedFilterCountResponse> = await GetAdvancedFilterCount(countApiPayload);
        if (resultOfCount?.status === true) {
            dispatch(setFilterListsAction(resultOfCount?.data));
        }

        const availableIds = resultOfCount?.data?.availabilityService?.map((availableService) => availableService.serviceId)?.flat();
        const occupiedIds = resultOfCount?.data?.occupiedService?.map((occupiedService) => occupiedService.serviceId)?.flat();

        const isAvailbleSelected = AppliedFilterData?.serviceStatus?.includes("Available");
        const isOccupiedSelected = AppliedFilterData?.serviceStatus?.includes("Occupied");

        let serviceId;
        if (isAvailbleSelected && isOccupiedSelected) {
            serviceId = availableIds?.concat(occupiedIds as number[])
        } else if (isAvailbleSelected) {
            serviceId = availableIds;
        } else if (isOccupiedSelected) {
            serviceId = occupiedIds;
        }

        const payload: any = {
            ...AppliedFilterData,
            location: null,
            dateRange: null,
            cityName,
            serviceId: 0,
            isShowOutOfArea: 1,
            cqcRating: AppliedFilterData.cqcRating.length > 0 ? AppliedFilterData.cqcRating : null,
            serviceStatus: AppliedFilterData.serviceStatus.length > 0 ? AppliedFilterData.serviceStatus : null,
            searchValue: null,
            serviceTypeList: serviceId || [],
        }

        const result: ApiResponse<ServicesListsType[]> = await GetAllServiceListService(payload);
        if (result?.status === true) {
            dispatch(setServiceListsAction(result?.data));
        } else {
            dispatch(setServiceListsAction([]));
        }

        setIsLoader(false);
    };

    useEffect(() => {
        getAllServiceList();
    }, [AppliedFilterData, TopFilterData, DataUserLocation]);

    return (
        <div>
            <div className="row  m-0 nhs-body">
                <div className="p-0 filter-anim" id="map">
                    <MapViewView />
                </div>
                
                <div className={`p-0 filter ${isDrawerOpen ? "filter-anim open-filter" : ""} ${(location.pathname.includes('referral-manager')) ? 'RM-filter-height' : ''}`}>
                    <FilterListContainer />
                </div>
            </div>
        </div>
    );
};

export default MapViewContainer;
