import React, { useEffect, useRef, useState } from "react";
import service_image from "../../../../../../assets/images/service-1.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PackagesDetailsContainer from '../PackagesDetails/PackagesDetailsContainer';
import ClientPortal from "../../../../../../components/ClientPortal";
import { useTranslation } from "react-i18next";
import package_image1 from "../../../../../../assets/images/image_part1.png";
import package_image2 from "../../../../../../assets/images/image_part2.png";
import package_image3 from "../../../../../../assets/images/image_part3.png";
import package_image4 from "../../../../../../assets/images/image_part4.png";
import package_image5 from "../../../../../../assets/images/image_part5.png";
import package_image6 from "../../../../../../assets/images/image_part6.png";
import { useParams } from "react-router-dom";
import { GetServicePackageList } from "../../../../../../service/service-details-service";

const PackagesSliderView = (props: any) => {

    const imgArr = [package_image1, package_image2, package_image3, package_image4, package_image5, package_image6];

    const [showPortal, setShowPortal] = useState(false);
    const [packageDetails, SetPackageDetails] = useState<any>(null);
    const params = useParams();
    const { t } = useTranslation();
    const handleModal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };
    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);

    const [stagePadding, setStagePadding] = useState(0);

    const targetElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const calculateSpace = () => {
            const targetElement = targetElementRef.current;
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                const spaceToLeft = rect.left;
                console.log("Space to the left of the target element:", spaceToLeft);
                return spaceToLeft;
            }
            return 0;
        };

        const calculatedPadding = calculateSpace();
        setStagePadding(calculatedPadding);
    }, []);

    useEffect(() => {
        GetServiceDetailsList()
    }, [])

    const GetServiceDetailsList = async () => {
        const result = await GetServicePackageList(params?.serviceSlug)
        SetPackageDetails(result?.data)
    }

    return (
        <div>
            <div className="service-section-title">
                <div className="container-fluid container-fluid-service p-0">
                    <div className="row" ref={targetElementRef}>
                        <div className="col-lg-12 p-0">
                            <div>
                                <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                    {t(packageDetails?.packageHeader)}
                                </h4>
                                <p className="section-sub-title text-center mb-4 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                                    {t("ServiceDetail.Packages")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid service-slider" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600" data-aos-once="true">
                <div className="row">
                    <div className="col-lg-12 p-0">
                        <div>
                            {props?.packageDetails &&
                                <OwlCarousel
                                    className="owl-theme all-owl owl-carousel"
                                    dots={false}
                                    margin={20}
                                    mouseDrag={true}
                                    touchDrag={true}
                                    nav={true}
                                    smartSpeed={800}
                                    // responsive={{
                                    //     "0": { items: 1 },
                                    //     "768": { items: 2 },
                                    //     "1200": { items: 3 },
                                    // }}
                                >
                                    {props?.packageDetails && props?.packageDetails?.length > 0 && props?.packageDetails?.map((pd: any, index: any) => {
                                        return (
                                            <div key={index} className="service-image item">
                                                <img
                                                    src={imgArr[index % 5]}
                                                    className="img-fluid"
                                                    alt="list-image"
                                                />
                                                <span className="overlay-details"></span>
                                                <div className="services-details">
                                                    <div className="service-content service-content-black">
                                                        <h5>{pd?.packageName}</h5>
                                                        <p>
                                                            {pd?.description}.
                                                        </p>
                                                        <span className="package-category">{pd?.departmentTag} </span>
                                                    </div>
                                                </div>
                                                <PackagesDetailsContainer packageDetails={pd} />
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>
                            }

                            <ClientPortal selector="myportal" show={showPortal}>
                                <div className="overlay" onClick={handleModal}></div>
                                <div className="modal">
                                    <div className="header mb-3">
                                        <h4 className="mb-0">Acquired Brain Injury</h4>
                                        <a onClick={handleModal} className="removeButton">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="1em"
                                                viewBox="0 0 384 512"
                                            >
                                                <path
                                                    fill="#ffffff"
                                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="body">
                                        <div
                                            className="tab-content amenities-pill-conent"
                                            id="pills-tabContent"
                                        >
                                            <div>
                                                <ul className="list-unstyled amenities-modal-list">
                                                    <li>Medical Assessment Monitoring</li>
                                                    <li>Counselling</li>
                                                    <li>Therapy</li>
                                                    <li>Family Therapy</li>
                                                    <li>Body Image</li>
                                                    <li>Workshops</li>
                                                    <li>Medical Assessment Monitoring</li>
                                                    <li>Counselling</li>
                                                    <li>Therapy</li>
                                                    <li>Family Therapy</li>
                                                    <li>Body Image</li>
                                                    <li>Workshops</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ClientPortal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackagesSliderView;