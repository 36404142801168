import React, { useState, useEffect, useRef } from 'react';
import FileInput from '../FileInput/FileInput';

interface DropZoneProps {
 onDropFiles: any,
 files: any[]
}

const getFilesFromEvent = (
  event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
): Array<File | DataTransferItem> => {
  let items = null

  if ('dataTransfer' in event) {
    const dt = event.dataTransfer

    // NOTE: Only the 'drop' event has access to DataTransfer.files, otherwise it will always be empty
    if ('files' in dt && dt.files.length) {
      items = dt.files
    } else if (dt.items && dt.items.length) {
      items = dt.items
    }
  } else if (event.target && event.target.files) {
    items = event.target.files
  }

  return Array.prototype.slice.call(items)
}

const DropZone: React.FC<DropZoneProps> = ({ children, onDropFiles, files}) => {
const [dragging,setDragging] = useState(false);
const [dragCounter, setDragCounter] = useState(0);
const divElemant = useRef(null);

  const handleFileEvent = async (e: any) =>{
    const chosenFiles = await getFilesFromEvent(e);
    onDropFiles(chosenFiles);
  }


 return (<div
        className="drop-zone d-inline-block"
        ref={divElemant}
      >
       <FileInput onFiles={handleFileEvent} hasFiles={files.length !== 0} />
        {children}

      </div>);
};

export default DropZone;