import React from 'react';
import ReferralListView from './ReferralListView';

const ReferralListContainer = () => {
    
    return (
        <div>
            <ReferralListView />
        </div>
    );
};

export default ReferralListContainer;