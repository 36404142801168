import React from 'react';
import loader_img from '../../assets/logos/logo-icon.png';
import loader_white_purpleimg from '../../assets/logos/loader.svg';
import loader_purpleimg from '../../assets/logos/loader-purple.png';
import loader from '../../assets/logos/loader.svg';

const LoaderView = () => {
    return (
        <div className="position-relative ontop" >
            <div className="loader-overlay"></div>
            {/* <img src={loader_white_purpleimg} alt="loader" className="img-fluid loader" /> */}
            <img src={loader} alt="loader" className="img-fluid loader" />
        </div>
    );
};

export default LoaderView;
