import React from 'react';

const HelpView = (props: any) => {
    return (
        <div className="container">
            <div className="row">
                {props.videoList?.length > 0 &&
                    props.videoList.map((val: any) => {
                        return (
                            <div key={val.videoTypeId} className="col-lg-12">
                                <div className='bg-white'>
                                <h2 className='section-title bold'>{val.videoTypeName}</h2>
                                <div className="row">
                                    {val.videoUrls.map((data: any) => {
                                        return (
                                            <div key={data.videoUrlId} className="col-lg-4 mb-3">
                                                <iframe allow="fullscreen;" src={data.url} width="100%"></iframe>
                                            </div>
                                        );
                                    })}
                                </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {props.videoList?.length === 0 && <p>No Data!</p>}
        </div>
    );
};

export default HelpView;
