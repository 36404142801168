import React, { useEffect, useState} from 'react';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import eventEmitter from  '../../../../../utils/eventEmitter';

const ServiceReferralTableSearchView = (props: any) => {

    let [leadSmartSearch, setLeadSmartSearch] = useState<any>('');
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.searchValue !== '') {
                setLeadSmartSearch(DataGridFilterState?.searchValue)
            } else {
                setLeadSmartSearch('')
            }
        }
    },[StatusGridFilterState, DataGridFilterState])

    const RemoveClickHandler = (event:any) => {        
        eventEmitter.emit('onClearServiceLeadSearchLessView', "");
        eventEmitter.emit('onClearServiceLeadSearchMoreView', "");        
        setLeadSmartSearch('');
        event.target.name = 'leadSmartSearch';
        event.target.value = '';
        props.searchValue(event);
    }

    return (
        <div className='me-2 position-relative'>
            <input type="text" placeholder='Search' name="leadSmartSearch" value={leadSmartSearch} onChange={(event: any) => {props.searchValue(event); setLeadSmartSearch(event.target.value);}}  className='form-control white search-invoice' />
            <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
            {leadSmartSearch !== undefined && leadSmartSearch !== "" && leadSmartSearch.toString().length >= 1 && 
                    <a href="#" onClick={(event: any) => {RemoveClickHandler(event);}} className='clear-filter'><i className="fa-solid fa-xmark"></i></a>
             }
        </div>
    );
};

export default ServiceReferralTableSearchView;