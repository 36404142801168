import React from 'react';
import ServicetypeHeaderView from './ServicetypeHeaderView';

const ServicetypeHeaderContainer = (props: any) => {
    return (
        <div>
            <ServicetypeHeaderView {...props} />
        </div>
    );
};

export default ServicetypeHeaderContainer;