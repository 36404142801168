import { AxiosError, AxiosResponse } from "axios";
import instance from "./axiosinstance";
import { DataResponseModel } from "../utils/model";
import { API_ENDPOINT, BASEURLDEV } from "./ApiConstants";

export const NHSAcceptRejectPooledService = async (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.NHSAcceptRejectPoolingService}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};
