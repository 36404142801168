import React from 'react';
import ContractingView from './ContractingView';
import { getFunderData } from '../../../../../service/dashboard-service';

const ContractingContainer = (props:any) => {
    getFunderData(0);
    return (
        <div>
            <ContractingView referralId={props.referralId}/>
        </div>
    );
};

export default ContractingContainer;