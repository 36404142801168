import React, { useEffect , useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BillingView from './BillingView';
import { useDispatch, useSelector } from 'react-redux';
import { rightNavigationPage } from '../../utils/constant';
import { setPageNavigationAction } from '../../store/general/pageNavigationReducer';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { RootState } from '../../store';
import { GetNotificationsCount } from '../../service/notifications-services';

const BillingContainer = () => {
    
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
          GetNotificationCount()

      },[])
    // 
    const GetNotificationCount = async () => {
        await GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0 )
    }
    useEffect(() => {
        if((searchParams.get("opt_type") !== null && searchParams.get("opt_type") !== undefined && searchParams.get("opt_type") === "confirminvoice") && (searchParams.get("invhid") !== null && searchParams.get("invhid") !== undefined)) {
            let data = {
                invoiceHeaderId: window.$crypto.OpenSSLDecrypt(window.$crypto.base64Decode(searchParams.get("invhid")))
            }
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceDetailPage,
                Data: data,
                ShowToggle:false
            };
            dispatch(setPageNavigationAction(obj));
        }
    }, [searchParams])

    return (
        <div>
            <BillingView />
        </div>
    );
};

export default BillingContainer;