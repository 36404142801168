import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import instance from './axiosinstance';
import { store } from '../store';
import { setSaveForLaterServicesListAction } from '../store/SaveForLater/saveForLaterServicesList';

export const SaveForLater = (payload: any) => { 
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveForLater}?name=${payload.name}&folderName=${payload.folderName}&serviceId=${payload.serviceId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GetPatientListForSaveLater = (payload: any) => { 
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPatientListForSaveLater}?searchValue=${payload.searchValue}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GetSaveForLaterFolderList = (payload: any) => { 
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetSaveForLaterFolderList}?searchValue=${payload.searchValue}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const DeleteFolder = (folderId: any) => { 
    return instance
        .delete(`${BASEURLDEV}${API_ENDPOINT.DeleteFolder}?folderId=${folderId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GeWishListService = (payload: any) => { 
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetWishListServices}?folderId=${payload.folderId}&searchValue=${payload.searchValue}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            store.dispatch(setSaveForLaterServicesListAction(resultData));
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const DeleteServiceWishList = (SaveForLaterFolderDetailsId: any) => { 
    return instance
        .delete(`${BASEURLDEV}${API_ENDPOINT.DeleteServiceWishList}?SaveForLaterFolderDetailsId=${SaveForLaterFolderDetailsId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const UpdateServiceWishList = (payload: any) => { 
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.UpdateServiceWishlist}?saveForLaterFolderDetailId=${payload.saveForLaterFolderDetailId}&name=${payload.wishlistName}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};