import React from 'react';
import ServiceDetailHeaderView from './ServiceDetailHeaderView';

const ServiceDetailHeaderContainer = () => {
    return (
        <div>
            <ServiceDetailHeaderView />
        </div>
    );
};

export default ServiceDetailHeaderContainer;