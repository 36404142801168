import Pagination from '@mui/material/Pagination';

const PaginationView = (props: any) => {
    const {currentPage, totalPages, handleChange} = props

    return (
          <Pagination 
          count={totalPages} 
          siblingCount={1} 
          boundaryCount={1} 
          page={currentPage}
          onChange={handleChange}
          color="primary"
          variant="outlined"
          />
      );
}

export default PaginationView