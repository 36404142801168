import { createSlice } from "@reduxjs/toolkit"
import { MetaFilterModal, SetFilterMetaPayload } from "../../utils/model";

const initialState: MetaFilterModal = {
    FilterMetaStatus: false,
    FilterMetaData: {
        selectedFilterCount: 0,
        drawerOpen: false
    },
    FilterMetaMessage: '',
}

export const filterMetaSlice = createSlice({
    name: "filterMeta",
    initialState,
    reducers: {
        setFilterMeta: (state, { payload }: SetFilterMetaPayload) => {
            state.FilterMetaData.drawerOpen = payload.drawerOpen;
            state.FilterMetaData.selectedFilterCount = payload.selectedFilterCount;
        }
    }
})

export const { setFilterMeta } = filterMetaSlice.actions;

export default filterMetaSlice.reducer;