import React from 'react';
import ConfirmationView from './ConfirmationView';

const ConfirmationContainer = (props: any) => {
    return (
        <div>
            <ConfirmationView closeSideMenu={props.closeSideMenu} taskList={props.taskList} currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} getCurrentPage={(page: any) => props.getCurrentPage(page)} />
        </div>
    );
};

export default ConfirmationContainer;