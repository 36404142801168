import { SaveForLaterDrawerModal,  SaveForLaterDrawerPayload } from "../../utils/model";
import { createSlice } from "@reduxjs/toolkit"

const initialState: SaveForLaterDrawerModal = {
    saveForLaterDrawerOpen: false,
}

export const saveForLaterDrawerSlice = createSlice({
    name: "saveForLater",
    initialState,
    reducers: {
        setSaveForLater: (state, { payload }: SaveForLaterDrawerPayload) => {
            state.saveForLaterDrawerOpen = payload.saveForLaterDrawerOpen;
        }
    }
})

export const { setSaveForLater } = saveForLaterDrawerSlice.actions;

export default saveForLaterDrawerSlice.reducer;
