import React, { useEffect, useState } from 'react';
import service_img from '../../../../src/assets/images/megnahouse.png'

const PrintInvoiceView = (props:any) => {
    
    let [funderName,setFunderName] = useState<string>("")
    let [funderAddressLine1,setFunderAddressLine1] = useState<string>("")
    let [funderCityName,setFunderCityName] = useState<string>("")
    let [funderPobox,setFunderPobox] = useState<string>("")

    useEffect(()=>{
      if(props.invoicedata?.contractDetails !== undefined && 
         props.invoicedata?.contractDetails !== null &&
         props.invoicedata?.contractDetails?.funderId !== null &&
         props.invoicedata?.invoiceModel?.funderId !== null){
         const contractDetail = props?.invoicedata?.contractDetails.filter((val:any) => val.funderId === props.invoicedata?.invoiceModel?.funderId) 
         if(contractDetail && contractDetail.length > 0){
            setFunderName(contractDetail[0].funderName)
            setFunderAddressLine1(contractDetail[0].funderAddressLine1)
            setFunderCityName(contractDetail[0].funderCityName)
            setFunderPobox(contractDetail[0].funderPobox)
         }
      }
    },[])
    
    return (
        <div className='yourClassName invoice-padding'>
             {/* <style type="text/css" media="print">{"@page { size: landscape; }"}</style> */}


             <table className='table-invoice ' width="100%" cellPadding="0" cellSpacing="0">
                <tr>
                    <td>
                        <table className='margin-bottom-10' width="100%" cellPadding="0" cellSpacing="0" >
                            <tr>
                                <td width="80%"></td>
                                <td width="20%"><img src={props.invoicedata?.serviceModel?.logoName} alt="" className='service-logo mb-3' /></td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr className='invoice-section'>
                                <td width="65%" valign='top'>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <td valign='top' width="60%">
                                                <div className='invoice-heading'>Invoice</div>
                                                <div className='margin-bottom-5'>
                                                    <span className='bold'>Funder Name </span><br /> <span>{funderName}</span>
                                                </div>
                                                <div className='margin-bottom-5'>
                                                    <span className='bold'>Funder Address </span> <br /> 
                                                    <div>
                                                        <p>{funderAddressLine1}</p>
                                                        <p>{funderCityName}</p>
                                                        <p>{funderPobox}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td valign='top' width="40%">

                                            <div className='margin-bottom-5'>
                                                    <span className='bold'>Invoice Number </span><br /> <span>{props.invoicedata?.invoiceModel?.invoicePattern}</span>
                                                </div>
                                                <div className='margin-bottom-5'>
                                                    <span className='bold'>Invoice Date </span> <br />  <span>{window.$utils.datedashFormat(props.invoicedata?.invoiceModel?.invoiceDate)}</span>
                                                </div>
                                                {/* <div className='margin-bottom-5'>
                                                    <span className='bold'>Patient Name </span> <br /> <span>{props.invoicedata?.patientDetail?.patientName}</span>
                                                </div> */}
                                                <div className='margin-bottom-5'>
                                                    <span className='bold'>NHS ID / Client ID </span> <br />  <span>{props.invoicedata?.patientDetail?.nhsId !== null ? props.invoicedata?.patientDetail?.nhsId : ""}/{props.invoicedata?.patientDetail?.localId !== null ? props.invoicedata?.patientDetail?.localId : ""}</span>
                                                </div>
                                                <div className='margin-bottom-5'>
                                                    <span className='bold'>Payment Reference </span> <br /> <span>{props.invoicedata?.invoiceModel?.paymentReference !== null ? props.invoicedata?.invoiceModel?.paymentReference : ''}</span>
                                                </div>
                                                {/* <div className='margin-bottom-10'>
                                                    <p className='bold'>Patient Address</p>
                                                    <p >{props.invoicedata?.patientDetail?.addressLine1} </p>
                                                   <p> {props.invoicedata?.patientDetail?.addressLine2} </p>
                                                   <p> {props.invoicedata?.patientDetail?.cityName} </p>
                                                   <p> {props.invoicedata?.patientDetail?.pobox} </p>
                                                </div> */}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td width="35%" valign='top'>
                                    <div>
                                    <p className='bold'>{props.invoicedata?.serviceModel?.accountName}</p>
                                        <p>{props.invoicedata?.serviceModel?.addressLine1}</p>
                                        <p>{props.invoicedata?.serviceModel?.addressLine2}</p>
                                        <p>{props.invoicedata?.serviceModel?.postalCode}</p>
                                        <p>{props.invoicedata?.serviceModel?.countryName}</p>
                                        {/* <p>United Kingdom</p> */}
                                        <p>{props.invoicedata?.serviceModel?.emailAddress}</p>
                                        <p>{props.invoicedata?.serviceModel?.phoneNo}</p>

                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td><div style={{ height: '30px' }}></div></td>
                </tr>
                <tr>
                    <td>
                        <table className='border-tr' width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className='bold uppercase pb-2' style={{'fontSize':'12px'}}>Description</td>
                                <td className='bold uppercase pb-2 text-right' style={{'fontSize':'12px'}} width={'14%'}>Quantity</td>
                                <td className='bold uppercase pb-2 text-right' style={{'fontSize':'12px'}} width={'14%'}>Unit Price</td>
                                <td className='bold uppercase pb-2 text-right' style={{'fontSize':'12px'}} width={'14%'}>Amount</td>
                            </tr>
                            {props.invoicedata?.invoiceModel?.invoiceLineModel?.length > 0 &&
                             props.invoicedata?.invoiceModel?.invoiceLineModel.map((data:any)=>{
                                return(
                                    <tr>
                                    <td className='pb-3'>{`${data?.productId === 1 ? data?.productDescription +" for period:"+props.invoicedata?.invoiceModel?.periodFrom +'-' +props.invoicedata?.invoiceModel?.periodTo: data?.productDescription}`}  </td>
                                    <td className='pb-3 text-right'>{data?.quantity}</td>
                                    <td className='pb-3 text-right'>£ {data?.price?.toFixed(2)}</td>
                                    <td className='pb-3 text-right'>£ {data?.subTotal?.toFixed(2)}</td>
                                    </tr>
                                )
                             })
                            }
                            <tr>
                            </tr>

                        </table>
                    </td>
                </tr>
                <tr>
                    <td><div style={{ height: '20px' }}></div></td>
                </tr>
                <tr>
                    <td>
                        <table className='table-right-align' width="50%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className='text-right'>Subtotal</td>
                                <td className='text-right pe-1'>£ <span>{props.invoicedata?.invoiceModel?.total.toFixed(2)}</span></td>
                            </tr>
                            <tr>
                                <td><div style={{ height: '5px' }}></div></td>
                            </tr>
                            {props.invoicedata?.invoiceModel?.discountAmount > 0 && 
                            <>
                            <tr>
                                <td className='text-right'>Discount</td>
                                <td className='text-right pe-1'>£ <span>{props.invoicedata?.invoiceModel?.discountAmount.toFixed(2)}</span></td>
                            </tr>
                            <tr>
                                <td><div style={{ height: '5px' }}></div></td>
                            </tr>    
                            </>}                    
                            <tr>
                                <td className='text-right'>Tax Amount</td>
                                <td className='text-right pe-1'>£ <span>{props.invoicedata?.invoiceModel?.taxedAmount.toFixed(2)}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><div style={{ height: '5px' }}></div></td>
                            </tr>
                            <tr><td colSpan={2}><div className='border-bottom'></div></td></tr>
                            <tr>
                                <td colSpan={2}><div style={{ height: '5px' }}></div></td>
                            </tr>
                            <tr>
                                <td className='text-right bold'>Amount Due</td>
                                <td className='text-right pe-1'>£ <span>{props.invoicedata?.invoiceModel?.total.toFixed(2)}</span></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td><div style={{ height: '20px' }}></div></td>
                </tr>
                <tr>
                    <td>
                        <div className='mb-3'>Due Date: <span>{window.$utils.datedashFormat(props.invoicedata?.invoiceModel?.dueDate)}</span></div>
                        <div className='mb-1'>Account Name: <span className='bold'>{props.invoicedata?.serviceModel?.accountName}</span></div>
                        <div className='mb-1'>Account Number: <span className='bold'>{props.invoicedata?.serviceModel?.accountNo}</span></div>
                        <div className='mb-1'>Sort Code: <span className='bold'>{props.invoicedata?.serviceModel?.serviceCode}</span></div>
                        <div>Please be aware  that 8% interest may be charged upon late payment.</div>
                    </td>
                </tr>
                <tr>
                    <td><div style={{ height: '20px' }}></div></td>
                </tr>
                <tr>
                    <td className='border-bottom-dashed'></td>
                </tr>
                <tr>
                    <td><div style={{ height: '10px' }}></div></td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="50%" valign='top'>
                                    <div className='title'>Payment Advice</div>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <td valign='top' width="5%">To:</td>
                                            <td valign='top' width="95%">{props.invoicedata?.serviceModel?.accountName} <br />
                                                {props.invoicedata?.serviceModel?.addressLine1} <br />
                                            {props.invoicedata?.serviceModel?.addressLine2} <br />
                                            {props.invoicedata?.serviceModel?.postalCode} <br />
                                            {props.invoicedata?.serviceModel?.countryName} <br />
                                            {/* {props.invoicedata?.serviceModel?.emailAddress} <br /> */}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td width="50%" valign='top'>
                                    <table className='table-right-align' width="100%" cellPadding="0" cellSpacing="0" style={{marginTop:'12px'}}>
                                        <tr>
                                            <td className='bold'>Invoice Number</td>
                                            <td className='text-right pe-1'><span>{props.invoicedata?.invoiceModel?.invoicePattern}</span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><div style={{ height: '6px' }}></div></td>
                                        </tr>
                                        <tr><td colSpan={2}><div className='border-bottom'></div></td></tr>
                                        <tr>
                                            <td colSpan={2}><div style={{ height: '6px' }}></div></td>
                                        </tr>

                                        <tr>
                                            <td className='bold'>Amount Due</td>
                                            <td className='text-right pe-1'>£ <span>{props.invoicedata?.invoiceModel?.total.toFixed(2)}</span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><div style={{ height: '6px' }}></div></td>
                                        </tr>
                                        <tr>
                                            <td className='bold'>Due Date</td>
                                            <td className='text-right pe-1'><span>{window.$utils.datedashFormat(props.invoicedata?.invoiceModel?.dueDate)}</span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><div style={{ height: '6px' }}></div></td>
                                        </tr>
                                        <tr><td colSpan={2}><div className='border-bottom'></div></td></tr>
                                        <tr>
                                            <td colSpan={2}><div style={{ height: '6px' }}></div></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className='bold'>Amount Enclosed</td>
                                        </tr>
                                        <tr>
                                        <td></td>
                                        </tr>
                                    </table>
                                    <div className='text-right pe-1 d-flex mt-3' style={{display:'inline-block'}}>
                                        <div className='width-20' ></div>
                                        <div className='width-80'>
                                        <div className='mb-3 border-tr'></div>
                                        <div>Enter the amount you are paying above</div>
                                        </div>
                                        
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td><div style={{ height: '10px' }}></div></td>
                </tr>

                <tr>
                    <td>
                        <div className='footer'>
                            <label style={{ margin: '0 20px 0 0' }}>FAX: <span className='bold'>{props.invoicedata?.serviceModel?.fax}</span></label>
                            <label style={{ margin: '0 20px 0 0' }}>VAT: <span className='bold'>{props.invoicedata?.serviceModel?.vat}</span></label>
                            <label style={{ margin: '0 20px 0 0' }}>Registered Office:{props.invoicedata?.serviceModel?.addressLine1}, {props.invoicedata?.serviceModel?.addressLine2}, {props.invoicedata?.serviceModel?.postalCode}   {props.invoicedata?.serviceModel?.countryName !== null ? "," : ""  } {props.invoicedata?.serviceModel?.countryName}</label>
                        </div>
                    </td>
                </tr>

             </table>
        </div>
    );
};

export default PrintInvoiceView;