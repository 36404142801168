import React from 'react';
import PoolingView from './PoolingView';

const PoolingContainer = (props:any) => {
    return (
        <div>
            <PoolingView referralid={props.referralid} />
        </div>
    );
};

export default PoolingContainer;