import React,{useState} from 'react';
import DoughnutChartView from './DoughnutChartView';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DoughnutChartContainer = (props: any) => {
  Chart.register(ChartDataLabels);

 

    const data = {
      labels: props.labels,
      datasets: [
        {
          data: props.data, // The values for each segment
          backgroundColor: props.statuscolor, 
        },
      ],
    };
  
    const options = {
      //cutoutPercentage: 100,
      responsive: true,
      aspectRatio: 1,
      hover: {mode: null},
      cutout: '53%',
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                // labels: {
                //   usePointStyle: true,
                //   boxWidth: 6,
                //   boxHeight:6
                // },
                labels: {
                  usePointStyle: true,
                  boxWidth: 5,
                  boxHeight: 5,
                  padding:8,
                  // Custom legend callback to include percentages
                  generateLabels: (chart:any) => {
                    const data = chart.data.datasets[0].data;
                    const total = data.reduce((acc:any, val:any) => acc + val, 0);
          
                    return data.map((value:any, index:any) => {
                      const percentage =  ((value / total) * 100).toFixed(2);
                      const color = chart.data.datasets[0].backgroundColor[index];
                      return {
                        text: `${chart.data.labels[index]} (${percentage}%)`,
                        fillStyle: color,
                        lineWidth :0,
                        pointBorderColor: color, // Set pointBorderColor to the same color as the fillStyle
                      hidden: false,
                      index: index,
                      };
                    });
                  },
                },
              },
              
          datalabels: {
            display: false,
          },

          
          tooltip: {
            callbacks: {
              label: (context:any) => {
                let sum = 0;
                let dataArr = context.chart.data.datasets[0].data;
                dataArr.forEach((data:any) => {
                  sum += data;
                });
                let percentage =
                ((context.dataset.data[context.dataIndex] * 100) / sum).toFixed(2) +
                  '%';
                return percentage;
              },
            },
           
           },
         
        },
      };
   
  
    return(
      <div>
        <DoughnutChartView data={data} options={options} />
       
      </div>
    )
  };
  
  

export default DoughnutChartContainer;