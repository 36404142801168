import React from 'react';
import classNames from 'classnames';

const PaginationView = (props: any) => {
    return (
        <div>
            <nav aria-label="Page navigation example" className="pagination-list">
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                        <a className={classNames('page-link')} onClick={() => props.onPageChangeContainer(1)} aria-label="First">
                            <span aria-hidden="true">
                                <i className="fa-solid fa-angles-left"></i>
                            </span>
                        </a>
                    </li>
                    <li className="page-item">
                        <a className={classNames('page-link', { disabled: props.currentPage === 1 })} onClick={props.onPrevious} aria-label="Previous">
                            <span aria-hidden="true">
                                <i className="fa-solid fa-angle-left"></i>
                            </span>
                        </a>
                    </li>
                    {props.paginationRange !== undefined &&
                        props.paginationRange.length > 0 &&
                        props.paginationRange.map((pageNumber: any) => {
                            if (pageNumber === props.DOTS) {
                                return (
                                    <li className="page-item" key={pageNumber}>
                                        <a className="page-link disabled">&#8230;</a>
                                    </li>
                                );
                            }
                            return (
                                <li className={classNames('page-item', { active: props.currentPage === pageNumber })} key={pageNumber}>
                                    <a className="page-link" onClick={() => props.onPageChangeContainer(pageNumber)}>
                                        {pageNumber}
                                    </a>
                                </li>
                            );
                        })}
                    <li className="page-item">
                        <a className={classNames('page-link', { disabled: props.currentPage === props.lastPage })} onClick={props.onNext} aria-label="Next">
                            <span aria-hidden="true">
                                {' '}
                                <i className="fa-solid fa-angle-right"></i>
                            </span>
                        </a>
                    </li>
                    <li className="page-item">
                        <a className={classNames('page-link')} onClick={() => props.onPageChangeContainer(props.lastPage)} aria-label="Last">
                            <span aria-hidden="true">
                                {' '}
                                <i className="fa-solid fa-angles-right"></i>
                            </span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default PaginationView;
