import React from 'react';
import ServiceTabView from './ServiceTabView';

const ServiceTabContainer = () => {
    return (
        <div>
            <ServiceTabView />
        </div>
    );
};

export default ServiceTabContainer;