import React, { useEffect } from 'react';
import ReferralManagementView from '../ReferralManagementView';
import ReferralManagerView from './ReferralManagerView';
import { getCityData } from '../../../service/dashboard-service';
import { getServiceList } from '../../../service/leads';

const ReferralManagerContainer = () => {

    useEffect(() => {
        async function getDropdowns() {
            await getCityData();
            await getServiceList();
        }
        getDropdowns();
    }, []);
    return (
        <div>
            <ReferralManagerView />
        </div>
    );
};

export default ReferralManagerContainer;