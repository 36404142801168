import React,{useEffect,useState} from 'react';
import IPAContainer from './IPA/IPAContainer';
import { useTranslation } from 'react-i18next';
import TenancyContainer from './Tenancy/TenancyContainer';
import { Tooltip } from 'react-tooltip';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import { CompleteContractingStage } from '../../../../../service/contract-service';
import { getLeaddata } from '../../../../../service/leads';
import { useSelector } from 'react-redux';
import { RootState} from '../../../../../store';
import eventEmitter from './../../../../../utils/eventEmitter';
import { ServiceTypeIconName } from '../../../../../utils/constant';
const ContractingView = (props: any) => {
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);  
    let [isDisplay, setDisplay] = useState(false);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [disableSave, setdisableSave] = useState(false);
    const [isCompleted, setisCompleted] = useState(false);
    useEffect(() => {                            
        setDisplay(DataLeadInfo?.data?.contract?.every((e: any) => e.status === 'Completed' || e.status === 'Cancelled' || e.status === 'Voided'|| (e.status === '' && e.attachments.length>0)));        
        setisCompleted(DataLeadInfo?.data?.contract?.filter((e: any) => e.status === 'Completed' || (e.status === '' && e.attachments.length>0)).length > 0);         
    }, [DataLeadInfo]);
    const { t } = useTranslation();
    const savecontractingstage = () => {
        swal({
            title: '',
            text: `Are you sure you want to move the lead ${props.referralId} for admission/ reservation?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {                
                const response = await CompleteContractingStage(DataLeadInfo?.data?.leadPoolingId);                
                if (response.status == true) {
                    swal(response.message, {
                        icon: 'success'
                    });
                    eventEmitter.emit('onAddNewLeadSM');
                    getLeaddata(DataLeadInfo?.data?.leadId, 6);
                } else {
                    swal(response.message, {
                        icon: 'error'
                    });
                }
            } else {
                setdisableSave(false);
            }
        });
    }

    const handleClick = (event: any) => {
        event.currentTarget.disabled = true;
        setdisableSave(true);
    };
    return (
        <div>

            <div className="accordion" id="accordioncontract">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            IPA
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordioncontract">
                        <div className="accordion-body">
                            <IPAContainer referralId={props.referralId}/>
                        </div>
                    </div>
                </div>
              
                
            </div>
            <Tooltip id="my-tooltip-admit" className="my-tooltip" />
            <div className="d-flex justify-content-center  right_content_btn">
                {isDisplay && isCompleted && DataLeadInfo?.data?.leadStatus !== 'Closed' && DataLeadInfo?.data?.rmCancellationMessage == '' && <button className="btn btn-primary" data-tooltip-id="my-tooltip-admit" data-tooltip-content="Move for Admission/Reservation" disabled={(disableSave || DataLeadInfo?.data?.referralCloseLog !== null)}
                    onClick={(e) => { savecontractingstage();handleClick(e)}}>Admit/Reserve</button>
                    }                                               
            </div>                        
        </div>
    );
};

export default ContractingView;
