import React, { useEffect, useState } from "react";
import PackagesSliderContainer from './PackagesSlider/PackagesSliderContainer';
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";
import { GetServicePackageList } from "../../../../../service/service-details-service";
import { useTranslation } from "react-i18next";

const PackagesView = () => {
    const [packageDetails, SetPackageDetails] = useState<any>(null)
    const params = useParams();
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        GetServiceDetailsList()
    }, [])

    const GetServiceDetailsList = async () => {
        const result = await GetServicePackageList(params?.serviceSlug)
        SetPackageDetails(result?.data)
    }
    return (
        <div className="service-detail-padding section-padding bg-gray text-center package-section">
            <PackagesSliderContainer packageDetails={packageDetails?.services} />
        </div>
    );
};

export default PackagesView;