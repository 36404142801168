import { createSlice } from '@reduxjs/toolkit';
import { ServiceListsModel, SetServiceListActionPayload } from '../../utils/model';
import { AxiosError } from 'axios';

const initialState: ServiceListsModel = {
    ServiceListStatus: false,
    ServiceListsData: [],
    ServiceListsMessage: '',
    ErrorServiceLists: null
};

export const serviceListSlice = createSlice({
    name: 'serviceLists',
    initialState,
    reducers: {
        setServiceListsAction: (state, { payload }: SetServiceListActionPayload) => {
            state.ServiceListStatus = true;
            state.ServiceListsMessage = 'Success';
            state.ServiceListsData = payload;
            state.ErrorServiceLists = null
        },
        removeServiceListsAction: (state, { payload }: SetServiceListActionPayload) => {
            state.ServiceListStatus = false;
            state.ServiceListsMessage = '';
            state.ServiceListsData = payload;
            state.ErrorServiceLists = null;
        },
        errorServiceListsAction: (state, { payload }: { payload: AxiosError }) => {
            state.ServiceListStatus = false;
            state.ServiceListsData = [];
            state.ServiceListsMessage = 'Failed';
            state.ErrorServiceLists = payload;
        }
    }
});

export const { setServiceListsAction, removeServiceListsAction, errorServiceListsAction } = serviceListSlice.actions;

export default serviceListSlice.reducer;
