import { SaveForLaterModal,  SaveForLaterModalPayload } from "../../utils/model";
import { createSlice } from "@reduxjs/toolkit"

const initialState: SaveForLaterModal = {
    saveForLaterModelOpen: false,
    serviceName : "",
    serviceId : 0
}

export const saveForLaterDrawerSlice = createSlice({
    name: "saveForLaterModal",
    initialState,
    reducers: {
        saveForLaterModal: (state, { payload }: SaveForLaterModalPayload) => {
            state.saveForLaterModelOpen = payload.saveForLaterModelOpen;
            state.serviceId = payload.serviceId;
            state.serviceName = payload.serviceName
        }
    }
})

export const { saveForLaterModal } = saveForLaterDrawerSlice.actions;

export default saveForLaterDrawerSlice.reducer;
