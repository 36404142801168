import React, {useState,useEffect} from 'react';
import ContactDetailsView from './ContactDetailsView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { getContactReferralList } from '../../../../../../service/leads';

const ContactDetailsContainer = (props:any) => {
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);    
    const [contactdata, setcontactdata] = useState<any>();
    const [cRefList, setcRefList] = useState<any>();

    const[clearcontact,setclearcontact]=useState<any>(undefined);
	
	useEffect(()=>{
        if(DataLeadInfo?.data?.referralContactDetailModel !== undefined){
            let obj = {
               
                referralSourceId:DataLeadInfo?.data?.referralContactDetailModel?.referralSourceId,               
                referralSourceDescription:DataLeadInfo?.data?.referralContactDetailModel?.referralSourceDescription
            }            
            props.getcontactdata(obj);
            setcontactdata(obj);
          }   
    },[DataLeadInfo])
	
	useEffect(() => {     
        async function getcontactReferralList() {
            const res = await getContactReferralList();            
            if (res.status) {
                setcRefList(res.data);
            }
        }
        getcontactReferralList();
    }, []);
	
 const updateData = (e: any) => {
    if(e.target.name === "referralSourceId"){
      setclearcontact('yes');
    }
    setcontactdata({
                ...contactdata,
                [e.target.name]: e.target.value
            });
            eventEmitter.emit("eventCloseEditLeadSideMenu");
    };
	
	  useEffect(()=>{        
     props.getcontactdata(contactdata);
    },[contactdata])
    return (
        <div>
            <ContactDetailsView updateData={updateData} leaddata={DataLeadInfo} iscontacterror={props.getcontactdataerror} referrancelist={cRefList} clearcontact={clearcontact}/>
        </div>
    );
};

export default ContactDetailsContainer;