import { AppliedFilterModal, SetAppliedFilterPayload, SetTopFilterPayload } from "../../utils/model";
import { createSlice } from "@reduxjs/toolkit"

const initialState: AppliedFilterModal = {
    AppliedFilterStatus: false,
    AppliedFilterData: {
        serviceTypeId: 0,
        cqcRating: [],
        serviceStatus: ["Available"],
        searchValue: ""
    },
    TopFilterData: {
        dateFilterVariableType: 0,
        selectedMonthsFromSlider: 0,
        flexibleMainType: "",
        flexibleMonth: [],
        dateType: "",
        isDateChanged: false,
    },
    FilterListMessage: '',
    ErrorFilterList: null
};

export const appliedFilterSlice = createSlice({
    name: "appliedFilter",
    initialState,
    reducers: {
        setAppliedFilterAction: (state, { payload }: SetAppliedFilterPayload) => {
            state.AppliedFilterStatus = true;
            state.FilterListMessage = 'Success';
            state.AppliedFilterData = payload;
            state.ErrorFilterList = null
        },
        setTopFilterAction: (state, { payload }: SetTopFilterPayload) => {
            state.TopFilterData = payload;
        }
    }
})

export const { setAppliedFilterAction, setTopFilterAction } = appliedFilterSlice.actions;

export default appliedFilterSlice.reducer;
