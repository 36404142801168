import React, { useEffect } from 'react';
import AmenitiesSliderContainer from './AmenitiesSlider/AmenitiesSliderContainer';



const AmenitiesView = () => {
    return (
        <div className='service-detail-padding amenities-section section-padding bg-gray' id='amenities-section'>
            <AmenitiesSliderContainer />
        </div>
    );
};

export default AmenitiesView;