import React from 'react';
import ContractListContainer from './ContractList/contractListContainer';
const ContractTabDetail = (props: any) => {
    return (
        <div>
            <div className="accordion accordion-flush" id="admissiondetail1">
                <ContractListContainer patientData={props.patientData} />
            </div>
        </div>
    );
};

export default ContractTabDetail;
