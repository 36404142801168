import React from 'react';
import PrintInvoiceView from './PrintInvoiceView';

const PrintInvoiceContainer = (props:any) => {
    return (
        <div>
            <PrintInvoiceView invoicedata={props.invoicedata}/>
        </div>
    );
};

export default PrintInvoiceContainer;