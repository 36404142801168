import React from 'react';
import AccreditationsView from './AccreditationsView';

const AccreditationsContainer = (props:any) => {
    return (
        <div>
            <AccreditationsView serviceAccreditation={props?.serviceAccreditation} cqcRating={props?.cqcRating} cqcLocationId={props?.cqcLocationId} cqcLatestInspection={props?.cqcLatestInspection} cqcReportPublished={props?.cqcReportPublished} cqcLatestReview={props?.cqcLatestReview}/>
        </div>
    );
};

export default AccreditationsContainer;