import { Assignment } from '@mui/icons-material';
import React from 'react';
import AssignmentView from './AssignmentView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const AssignmentContainer = () => {
    const dispatch = useDispatch();    
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);    
    const assignmentDetails = DataLeadInfo?.data;
    
    return (
        <div>
            <AssignmentView assignment={assignmentDetails} />
        </div>
    );
};

export default AssignmentContainer;