import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import swal from 'sweetalert';
import eventEmitter from '../../../../../../../utils/eventEmitter';
import warning from '../../../../../../assets/images/warning.png';

const MaintenanceBedListView = (props: any) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const toggle = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className={classNames('accordion-button', { collapsed: props.accordID !== 0 })}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${props.accordID}`}
                        aria-expanded={props.accordID !== 0 ? 'false' : 'true'}
                        aria-controls={`flush-collapse${props.accordID}`}
                    >
                        <p className="patient-name"> {props.dataMaintenanceBed?.wardBedName || ''}</p>
                    </button>
                </h2>
                <div
                    id={`flush-collapse${props.accordID}`}
                    className={classNames('accordion-collapse collapse', { show: props.accordID === 0 })}
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#draftpatient"
                >
                    <div className="accordion-body pb-0">
                        <p className="mb-1">
                            <label>Maintenance Start Date:</label> <span className="bold">{props.dataMaintenanceBed?.maintenanceStartDate || ''}</span>
                        </p>
                        <p className="mb-1">
                            <label>Est. Release Date:</label> <span className="bold">{props.dataMaintenanceBed?.estMaintenanceReleaseDate || ''}</span>
                        </p>
                        <p className="mb-1">
                            <label>Reason for Maintenance:</label> <span className="bold">{props.dataMaintenanceBed?.reasonDescription || ''}</span>
                        </p>
                        <p className="mb-1">
                            <label>Comments:</label> <span className="bold">{props.dataMaintenanceBed?.comments || ''}</span>
                        </p>
                        <div className="mt-2 text-center">
                            <button onClick={() => props.handleContinue(props.dataMaintenanceBed)} className="btn btn-sm btn-primary me-2">
                                View Detail
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceBedListView;
