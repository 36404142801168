import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import eventEmitter from '../../utils/eventEmitter';
// @ts-ignore
import isEqual from 'lodash/isEqual'

export default class HOC extends Component {
    constructor(props: any) {
        super(props);
        this.state = { 
            data: props.data 
        };
    }

    prepareData = (data: any) => {
        if(data.length > 0) {
            // optional: you can skip cloning if you don't mind mutating original data
            var cloned = data.slice(0);

            // insert special select all node
            cloned.splice(0, 0, {
                label: 'Select All',
                value: 'selectAll',
                className: 'select-all',
                type: 'selectall'
            });

            return cloned;
        }        
    };

    getAllServiceIds = () => {
        const { data }: any = this.state;
        let listServices: any = [];
        if (data !== undefined && data.length > 0) {
            data.map((orgData: any) => {
                if (orgData.children !== undefined && orgData.children !== null && orgData.children.length > 0) {
                    orgData.children.map((serviceTypeData: any) => {
                        if (serviceTypeData.children !== undefined && serviceTypeData.children.length > 0) {
                            serviceTypeData.children.map((serviceData: any) => {
                                if (serviceData.label !== '' && serviceData.value !== '') {
                                    listServices.push(serviceData.value);
                                }
                            });
                        }
                    });
                }
            });
        }
        eventEmitter.emit(
            'onChangeOrganizationList',
            // @ts-ignore
            listServices.filter((value, index, array) => array.indexOf(value) === index)
        );
    };

    getServiceIds = (selectedNode: any) => {
        
        const { data }: any = this.state;
        let listServices: any = [];
        if (selectedNode.length > 0) {
            for (var i = 0; i < selectedNode.length; i++) {
                if (selectedNode[i].type !== 'selectall') {
                    if (selectedNode[i].type === 'ServiceType') {
                        if (data !== undefined && data.length > 0) {
                            data.map((orgData: any) => {
                                if (orgData.children !== undefined && orgData.children !== null && orgData.children.length > 0) {
                                    orgData.children.map((serviceTypeData: any) => {
                                        
                                        
                                        
                                        
                                        if (selectedNode.some((item: any) => ((item.label === serviceTypeData.label) && (item.value === serviceTypeData.value) && (item.usrId === serviceTypeData.usrId)))) {
                                            if (serviceTypeData.children !== undefined && serviceTypeData.children.length > 0) {
                                                serviceTypeData.children.map((serviceData: any) => {
                                                    if (serviceData.label !== '' && serviceData.value !== '') {
                                                        listServices.push(serviceData.value);
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    } else if (selectedNode[i].type === 'Service') {
                        if (data !== undefined && data.length > 0) {
                            data.map((orgData: any) => {
                                if (orgData.children !== undefined && orgData.children !== null && orgData.children.length > 0) {
                                    orgData.children.map((serviceTypeData: any) => {
                                        if (serviceTypeData.children !== undefined && serviceTypeData.children.length > 0) {
                                            serviceTypeData.children.map((serviceData: any) => {
                                                if (selectedNode.some((item: any) => item.label === serviceData.label)) {
                                                    if (serviceData.label !== '' && serviceData.value !== '') {
                                                        listServices.push(serviceData.value);
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    } else {
                        if (data !== undefined && data.length > 0) {
                            data.map((orgData: any) => {
                                if (selectedNode.some((item: any) => item.label === orgData.label)) {
                                    if (orgData.children !== undefined && orgData.children !== null && orgData.children.length > 0) {
                                        orgData.children.map((serviceTypeData: any) => {
                                            if (serviceTypeData.children !== undefined && serviceTypeData.children.length > 0) {
                                                serviceTypeData.children.map((serviceData: any) => {
                                                    if (serviceData.label !== '' && serviceData.value !== '') {
                                                        listServices.push(serviceData.value);
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
        eventEmitter.emit(
            'onChangeOrganizationList',
            // @ts-ignore
            listServices.filter((value, index, array) => array.indexOf(value) === index)
        );
    };

    toggleAll = (checked: any) => {
        const { data }: any = this.state;
        for (var i = 1; i < data.length; i++) {
            data[i].checked = checked;
        }      
        this.setState({ data });
        this.getAllServiceIds();
    };

    handleChange = (currentNode: any, selectedNodes: any) => {
        if (currentNode.value === 'selectAll') this.toggleAll(currentNode.checked);
        if (currentNode.value !== 'selectAll') {
            this.getServiceIds(selectedNodes);
        }
    };

    // @ts-ignore
    componentWillReceiveProps = (nextProps) => {        
        // @ts-ignore
        if (!isEqual(nextProps.data, this.state.data)) {
            this.setState({ data: nextProps.data });
        }
    };

    // @ts-ignore
    shouldComponentUpdate = (nextProps) => {
        // @ts-ignore
        return !isEqual(nextProps.data, this.state.data);
    };

    render() {
        return (
            <div>
                <DropdownTreeSelect
                    inlineSearchInput={true}
                    // @ts-ignore
                    data={this.state.data}
                    onChange={this.handleChange}
                    texts={{ placeholder: 'Select Services' }}
                />
            </div>
        );
    }
}
