import React, { useCallback, useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  PolylineF,
  PolygonF,
  MarkerF,
  OverlayViewF,
  OVERLAY_MOUSE_TARGET,
  MARKER_LAYER,
  useGoogleMap,
  MarkerClustererF,
  MarkerClustererProps
} from "@react-google-maps/api";
import { BASE_URL, BedAvailability, optionMapStyles } from "../../utils/constant";

import GoogleMapMarkerBoxComponent from "../GoogleMapMarkerBoxComponent/GoogleMapMarkerBoxComponent";
import eventEmitter from "../../utils/eventEmitter";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

const ListenerComponent = forwardRef((props: any, ref: any) => {
  const mapHook = useGoogleMap();

  const getVisibleMarkers = useCallback((ne: google.maps.LatLng, sw: google.maps.LatLng) => {
    const visibleBounds = new window.google.maps.LatLngBounds(sw, ne);

    return props?.markerPoint?.filter((marker: any) => {
      const {
        latitude,
        longitude
      } = marker;
      const markerPosition = new window.google.maps.LatLng(latitude, longitude);

      return visibleBounds.contains(markerPosition);
    });
  }, [props?.markerPoint]);

  const changeHandler = () => {
    const bounds = mapHook?.getBounds();
    const ne = bounds?.getNorthEast();
    const sw = bounds?.getSouthWest();

    if (ne && sw) {
      const visibleMarkers = getVisibleMarkers(ne, sw);
      return visibleMarkers;
    }
    return [];
  }

  useImperativeHandle(ref, () => ({
    handler: () => changeHandler()
  }), [changeHandler]);

  return null;
})

const GoogleMapComponent = (props: any) => {
  const [isDragging, setIsDragging] = useState(false);

  const [mapInstance, setMapInstance] = useState(null);
  const [zoom, setZoom] = useState(11)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  /************** Poly Line and Polygon Code Start ***************/
  let lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 1,
    strokeWeight: 3,
  };

  const POLYLINE_OPTIONS = {
    strokeColor: "#EA4335",
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "10px",
      },
    ],
  };

  const outerCoords = [
    { lat: 85, lng: 180 },
    { lat: 85, lng: 90 },
    { lat: 85, lng: 0 },
    { lat: 85, lng: -90 },
    { lat: 85, lng: -180 },
    { lat: 0, lng: -180 },
    { lat: -85, lng: -180 },
    { lat: -85, lng: -90 },
    { lat: -85, lng: 0 },
    { lat: -85, lng: 90 },
    { lat: -85, lng: 180 },
    { lat: 0, lng: 180 },
    { lat: 85, lng: 180 },
  ];
  // console.log("props.borderPoint: ", props.borderPoint)
  const innerCoords = props.borderPoint;

  const BOTH_COORDS = [outerCoords, innerCoords];

  const sfPolygonOptions = {
    strokeColor: "#ffffff",
    strokeOpacity: 0,
    strokeWeight: 2,
    fillColor: "#000000",
    fillOpacity: 0.35,
    paths: BOTH_COORDS,
  };

  const [polylineOptions, setPolylineOptions] = React.useState(
    JSON.stringify(POLYLINE_OPTIONS)
  );

  const po = React.useMemo(() => {
    try {
      return JSON.parse(polylineOptions);
    } catch (e) {
      return POLYLINE_OPTIONS;
    }
  }, [polylineOptions]);
  /************** Poly Line and Polygon Code End ***************/

  const [overlayPane, setOverlayPane] = useState(OVERLAY_MOUSE_TARGET);

  const [insideBoundaryPoints, setInsideBoundaryPoints] = useState<any>([]);
  const [markerPoints, setMarkerPoints] = useState<any>([]);

  const [activeMarker, setActiveMarker] = useState(null);

  const ref = useRef<any>(null);

  const onLoad = useCallback((map: any) => {
    eventEmitter.emit("filterList", markerPoints);
    setMapInstance(map);
  }, []);

  const handleActiveMarker = (val: any) => {
    if (val.serviceId === activeMarker) {
      return;
    }
    eventEmitter.emit("PoolServiceActionFromMap", val);
    setActiveMarker(val.serviceId);
  };

  const closeOverLay = () => {
    if (isDragging) return;
    setActiveMarker(null);
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  const stopWheelClickPropagation = (e:any) => {
    if (e.button === 1) {
      e.stopPropagation();
    }
  };

  function centerOverlayView(
    width: number,
    height: number
  ): { x: number; y: number } {
    return {
      x: -(width / 2),
      y: -(height / 2),
    };
  }

  const contentStyles = {
    background: `white`,
    border: `1px solid #CCC`,
    padding: 15,
  };

  const loadCallback = useCallback((overlayView: any) => {
    // console.log("OverlayView onLoad: ", overlayView);
  }, []);

  const unmountCallback = useCallback((overlayView: any) => {
    // console.log("OverlayView onUnmount", overlayView);
  }, []);

  const { hoveredServiceId } = useSelector((state: RootState) => state.hoveredService)
  useEffect(()=>{
    if(hoveredServiceId){
      closeOverLay();
    }
  }, [hoveredServiceId])

  const clickHandler = useCallback(() => {
    alert("You clicked overlay view");
  }, []);

  const isInsideBoundary = (serviceLat: any, serviceLong: any) => {
    let isOutside = true;
    if (props.borderPoint) {
      for (let i = 0, j = props.borderPoint.length - 1; i < props.borderPoint.length; j = i++) {
        const lat1 = parseFloat(props.borderPoint[i].lat);
        const long1 = parseFloat(props.borderPoint[i].lng);
        const lat2 = parseFloat(props.borderPoint[j].lat);
        const long2 = parseFloat(props.borderPoint[j].lng);

        if (
          ((long1 > serviceLong) !== (long2 > serviceLong)) &&
          (serviceLat < (lat2 - lat1) * (serviceLong - long1) / (long2 - long1) + lat1)
        ) {
          isOutside = !isOutside;
        }
      }
    }

    return isOutside;
  };

  useEffect(() => {
    if (props.markerPoint) {
      const servicesOutsideBoundary = props.markerPoint.filter((service: any) =>
        !isInsideBoundary(Number(service.latitude), Number(service.longitude))
      );
      setInsideBoundaryPoints(servicesOutsideBoundary)
    }
    else {
      setInsideBoundaryPoints([])
    }

  }, [props.markerPoint, props.borderPoint])

  useEffect(() => {
    setMarkerPoints(props.showOutBorderServices ? props.markerPoint : insideBoundaryPoints)
  }, [props.showOutBorderServices, props.markerPoint, props.borderPoint])

  useEffect(()=>{
    setZoom(props?.zoom)
    //@ts-ignore
    mapInstance?.setZoom(props?.zoom)
  }, [props.markerPoint])

  const changeHandler = () => {
    const markersFromHandler = ref.current?.handler();
    setMarkerPoints(markersFromHandler);
    // if (isLoaded) {
    //   eventEmitter.emit("filterList", markersFromHandler);
    // }
  }

  console.log(markerPoints, "<<<markerPoints");

  let { zoom: extractZoom } = props;
  useEffect(() => {
    setZoom(props?.zoom)
    //@ts-ignore
    mapInstance?.setZoom(props?.zoom)
  }, [extractZoom])

  //@ts-ignore
  const clusterOptions: MarkerClustererProps = {
    maxZoom: 11,
    gridSize: 30,
    minimumClusterSize: 5,
    styles: [
      {
        url: `${BASE_URL}/marker-cluster-bg.svg`,
        height: 60,
        width: 130,
        textColor: '#333333',
        textSize: 14,
        fontWeight: '550',
        fontFamily: 'Arial, Helvetica, sans-serif',
        anchorText: [-3, 2]
      }
    ],
    calculator: (markers) => {
      const count = markers?.length!;
      return {
        text: `${count} services`,
        index: Math.min(count, 1)
      };
    }
  };

  return isLoaded && props?.centerPoint !== null ? (
    <GoogleMap
      options={{
        // zoom: 11,
        // maxZoom: 17,
        minZoom: 2,
        styles: optionMapStyles
      }}
      mapContainerStyle={props?.containerStyle}
      center={props?.centerPoint}
      zoom={zoom}
      onLoad={onLoad}
      onBoundsChanged={changeHandler}
      onZoomChanged={() => {
        setTimeout(() => {
          eventEmitter.emit("filterList", markerPoints);
        }, 100);
      }}
      onDragStart={() => {
        setIsDragging(true);
      }}
      onDragEnd={() => {
        setTimeout(() => {
          setIsDragging(false);
          eventEmitter.emit("filterList", markerPoints);
        }, 100);
      }}
    >
      <>
        <ListenerComponent {...props} ref={ref} />
        {/* {props.showOutOfBorder === false && (<PolygonF paths={BOTH_COORDS} options={sfPolygonOptions} />)} */}
        {/* {props.borderPoint?.length > 0 && <PolylineF path={props.borderPoint} options={po} />} */}
        {props.showOutOfBorder === true && (<PolygonF paths={BOTH_COORDS} options={sfPolygonOptions} />)}
        {props.borderPoint?.length > 1 && <PolylineF path={props.borderPoint} options={po} />}

        <MarkerClustererF options={clusterOptions}>
          {
            (clusterer) => <>
              {markerPoints?.length > 0 &&
                markerPoints.map((val: any, index: any) => {
                  let cord = {
                    lat: Number(val.latitude),
                    lng: Number(val.longitude),
                  };
                  return (
                    <div key={val.serviceId}>
                      {val.bedAvailability === BedAvailability.Available && (
                        <MarkerF
                          clusterer={clusterer}
                          clickable={activeMarker === null}
                          position={cord}
                          options={{
                            icon: {
                              url: `${BASE_URL}/available-pin.svg`,
                              scaledSize: hoveredServiceId === val.serviceId ? new google.maps.Size(70, 70) : new google.maps.Size(35, 35),
                              anchor: hoveredServiceId === val.serviceId ? new google.maps.Point(Math.floor(70 / 2), 65) : new google.maps.Point(Math.floor(35 / 2), 35)
                            }
                          }}
                          onClick={() => handleActiveMarker(val)}
                        />
                      )}
                      {val.bedAvailability === BedAvailability.Occupied && (
                        <MarkerF
                          clusterer={clusterer}
                          clickable={activeMarker === null}
                          position={cord}
                          options={{
                            icon: {
                              url: `${BASE_URL}/occupied-pin.svg`,
                              scaledSize: hoveredServiceId === val.serviceId ? new google.maps.Size(70, 70) : new google.maps.Size(35, 35),
                              anchor: hoveredServiceId === val.serviceId ? new google.maps.Point(Math.floor(70 / 2), 65) : new google.maps.Point(Math.floor(35 / 2), 35)
                            }
                          }}
                          onClick={() => handleActiveMarker(val)}
                        />
                      )}
                      {val.bedAvailability === BedAvailability.Filling_Fast && (
                        <MarkerF
                          clusterer={clusterer}
                          clickable={activeMarker === null}
                          position={cord}
                          options={{
                            icon: {
                              url: `${BASE_URL}/available-pin.svg`,
                              scaledSize: hoveredServiceId === val.serviceId ? new google.maps.Size(70, 70) : new google.maps.Size(35, 35),
                              anchor: hoveredServiceId === val.serviceId ? new google.maps.Point(Math.floor(70 / 2), 65) : new google.maps.Point(Math.floor(35 / 2), 35)
                            }
                          }}
                          onClick={() => handleActiveMarker(val)}
                        />
                      )}
                      {activeMarker === val.serviceId ? (
                        <OverlayViewF
                          position={cord}
                          mapPaneName={overlayPane}
                          onLoad={loadCallback}
                          onUnmount={unmountCallback}
                          getPixelPositionOffset={centerOverlayView}
                        >
                          {/*
                          <button type="button" onClick={closeOverLay}>
                            Close
                          </button>
                          <button type="button" style={contentStyles} onClick={clickHandler}>
                            <h1>{`${val.serviceName}`}</h1>
                          </button> 
                          */}
                          <div
                            onTouchStart={stopPropagation}
                            onClick={stopPropagation}
                            onDoubleClick={stopPropagation}
                            onMouseDown={(e) => { stopPropagation(e); stopWheelClickPropagation(e); }}
                            onMouseUp={(e) => { stopPropagation(e); stopWheelClickPropagation(e); }}
                            onMouseOver={stopPropagation}
                            onMouseOut={stopPropagation}
                            onWheel={stopPropagation}
                          >
                            <GoogleMapMarkerBoxComponent
                              data={val}
                              closeOverLay={closeOverLay}
                            />
                          </div>
                        </OverlayViewF>
                      ) : null}
                    </div>
                  );
                })}
            </>
          }
        </MarkerClustererF>
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMapComponent;
