import React, { useEffect, useMemo, useState } from 'react';
import closeVectorIcon from "../../assets/images/closeVector.svg"
import locationIcon from "../../assets/images/location-available.svg"
import locationIconGreen from "../../assets/images/location-green.svg"
import locationIconRed from "../../assets/images/location-red.svg"
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { RootState } from '../../store';
import { setFilterMeta } from '../../store/filter/filterMetaDataReducer';
import { ApiResponse, AppliedFilterDataType } from '../../utils/model';
import { AdvancedFilterCountResponse, AmenitiesCountResponseType, AmenitiesListType } from '../../layout/NHSHeader/ServicetypeHeader/SearchFilter/types';
import { CountryExist, GetAdvancedFilterCount, GetAllServiceListService, GetDefaultLocationData, GetLocationWiseLatLongData } from '../../service/serviceProvider-services';
import { setFilterListsAction } from '../../store/filter/advanceFilterReducer';
import { setAppliedFilterAction } from '../../store/filter/appliedFilterReducer';
import { GetAllServicesPayloadType } from '../../pages/NHSPage/HomeMap/types';
import { findDateRange } from '../../utils/dateRange';
import { ServicesListsType } from '../../pages/NHSPage/HomeList/ListBox/types';
import { setServiceListsAction } from '../../store/general/serviceListsReducer';
import { setCityWiseLatLngListAction } from '../../store/serviceProvider/cityWiseLatLngListReducer';
import eventEmitter from '../../utils/eventEmitter';
import ClientPortal from "../../components/ClientPortal";
import { AmenitiesPopup } from './AmenitiesFilter/AmenitiesPopup';

const FilterListView = () => {
    const dispatch = useDispatch();
    const { FilterListData } = useSelector((state: RootState) => state.filterList);
    const { AppliedFilterData, TopFilterData } = useSelector((state: RootState) => state.appliedFilter);
    const { DataUserLocation } = useSelector((state: RootState) => state.userLocation);
    const { FilterMetaData } = useSelector((state: RootState) => state.metaFilter);
    const [appliedFilterCount, setAppliedFilterCount] = useState<number>(0);

    const [amenitiesTotalCount, setAmenitiesTotalCount] = useState<number>(0);

    const [localAppliedFilter, setLocalAppliedFilters] = useState<AppliedFilterDataType>({
        cqcRating: [],
        serviceStatus: [],
        serviceTypeId: 0,
        amenities: [],
    });

    const handleModalAction = () => {
        dispatch(setFilterMeta({ drawerOpen: false, selectedFilterCount: FilterMetaData.selectedFilterCount }))
    }

    const removeDupicate = (newArr: string[]) => {
        newArr = newArr.filter((item, index, inputArray) => {
            return inputArray.indexOf(item) == index;
        });
    }

    useEffect(() => {
        setLocalAppliedFilters(AppliedFilterData);
    }, [AppliedFilterData]);

    const advancedFilterCountApiCall = async (payload?: any) => {
        let lpayload = { ...payload, isShowOutOfArea: 1 };
        const cityName = '';
        const datesRange = findDateRange({ ...AppliedFilterData, ...TopFilterData });

        lpayload = {
            Amenities: localAppliedFilter?.amenities?.map((am) => am.id),
            ...lpayload,
            serviceId: 0,
            cityName,
            location: AppliedFilterData?.location ? AppliedFilterData?.location : null,
            searchValue: AppliedFilterData?.searchValue ? AppliedFilterData?.searchValue : null,
            dateRange: TopFilterData.isDateChanged ? datesRange.map((item: any) => ({
                fromDate: format(item.startDate, 'yyyy-MM-dd'),
                toDate: format(item.endDate, 'yyyy-MM-dd'),
            })) : null,
        };

        const result: ApiResponse<AdvancedFilterCountResponse> = await GetAdvancedFilterCount(lpayload);
        setAmenitiesTotalCount(result.totalCount);
        if (result?.status === true) {
            dispatch(setFilterListsAction(result?.data));
        }
    }

    // useEffect(() => {
    //     advancedFilterCountApiCall();
    // }, []);

    useEffect(() => {
        const totalCQC = AppliedFilterData.cqcRating?.length || 0,
            serviceStatus = AppliedFilterData.serviceStatus?.length || 0;

        if (totalCQC > 0 || serviceStatus > 0) {
            setAppliedFilterCount(serviceStatus + totalCQC);
        }
    }, [AppliedFilterData.cqcRating, AppliedFilterData.serviceStatus]);

    useEffect(() => {
        eventEmitter.emit("appliedFilterCountAction", appliedFilterCount);
    }, [appliedFilterCount]);

    const filterTypeChange = async (serviceTypeId: number) => {
        setLocalAppliedFilters({ ...localAppliedFilter, serviceTypeId });
        const payload = {
            ...localAppliedFilter,
            serviceTypeId,
        }
        delete payload.amenities;
        advancedFilterCountApiCall(payload);
    }

    const handleServiceStatusChange = async (newServiceStatus: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const currentChecked = e.target?.checked;
        let newArr = [...localAppliedFilter.serviceStatus, newServiceStatus] || [];
        removeDupicate(newArr);

        let payload = {
            serviceTypeId: localAppliedFilter.serviceTypeId,
            cqcRating: localAppliedFilter.cqcRating,
            serviceStatus: newArr
        }

        if (currentChecked) {
            setLocalAppliedFilters({ ...localAppliedFilter, ...payload });
            setAppliedFilterCount(localAppliedFilter.cqcRating.length + newArr?.length);
        } else {
            const uniqueArr = newArr?.filter(e => e !== newServiceStatus) || [];
            payload = { ...payload, serviceStatus: uniqueArr }
            setLocalAppliedFilters({ ...localAppliedFilter, serviceStatus: uniqueArr });
            setAppliedFilterCount(localAppliedFilter.cqcRating?.length + uniqueArr.length);
        }

        advancedFilterCountApiCall(payload);
    };

    const handleCQCStatusChange = async (cqc: { cqcRating: string; count: number; }, e: React.ChangeEvent<HTMLInputElement>) => {
        const newCQCRating = cqc.cqcRating;
        const currentChecked = e.target?.checked;
        let newArr = [...localAppliedFilter.cqcRating, newCQCRating] || [];
        removeDupicate(newArr);

        let payload = {
            serviceTypeId: localAppliedFilter.serviceTypeId,
            serviceStatus: localAppliedFilter.serviceStatus,
            cqcRating: newArr,
        }

        if (currentChecked) {
            setLocalAppliedFilters({ ...localAppliedFilter, ...payload });
            setAppliedFilterCount(localAppliedFilter.serviceStatus?.length + newArr.length);
        } else {
            const uniqueArr = newArr.filter(e => e !== newCQCRating);
            payload = { ...payload, cqcRating: uniqueArr }
            setLocalAppliedFilters({ ...localAppliedFilter, cqcRating: uniqueArr });
            setAppliedFilterCount(localAppliedFilter.serviceStatus?.length + uniqueArr.length);
        }

        advancedFilterCountApiCall(payload);
    };

    const handleFilterApplied = async () => {
        eventEmitter.emit("handelServiceStatusFilter", localAppliedFilter)

        dispatch(setFilterMeta({
            ...FilterMetaData,
            drawerOpen: false,
        }));

        dispatch(setAppliedFilterAction({ ...AppliedFilterData, ...localAppliedFilter }));

        dispatch(setFilterMeta({ ...FilterMetaData, selectedFilterCount: appliedFilterCount, drawerOpen: false }));
    }

    const handleCloseServiceChip = () => {
        const payload = { ...localAppliedFilter, serviceStatus: [] }
        setLocalAppliedFilters(payload);
        setAppliedFilterCount(0 + localAppliedFilter.cqcRating?.length);
        delete payload.amenities;
        advancedFilterCountApiCall(payload);
    }

    const handleCloseCQCRating = () => {
        const payload = { ...localAppliedFilter, cqcRating: [] };
        setLocalAppliedFilters(payload);
        setAppliedFilterCount(0 + localAppliedFilter.cqcRating?.length);
        delete payload.amenities;
        advancedFilterCountApiCall(payload);
    }

    const handleResetFilter = async () => {
        dispatch(setFilterMeta({
            ...FilterMetaData,
            drawerOpen: false,
            selectedFilterCount: 0
        }));

        dispatch(setAppliedFilterAction({
            ...AppliedFilterData,
            serviceTypeId: 0,
            cqcRating: [],
            amenities: [],
            serviceStatus: ["Available"],
            searchValue: AppliedFilterData.searchValue
        }));
        setLocalAppliedFilters({
            ...localAppliedFilter,
            serviceTypeId: 0,
            cqcRating: [],
            amenities: [],
            serviceStatus: ["Available"],
        });
        setAppliedFilterCount(0);
        advancedFilterCountApiCall();
    }

    const serviceTypeAllOption = FilterListData?.serviceTypeCount?.filter((fd) => fd.serviceTypeName === "All");

    const allTotalCount = serviceTypeAllOption?.[0].count || 0;

    const totalItems = useMemo(() => {
        let count = 0;
        if (amenitiesTotalCount > 0) {
            count = amenitiesTotalCount;
        } else {
            if (localAppliedFilter.cqcRating?.length === 0 && localAppliedFilter.serviceStatus?.length === 0) {
                if (localAppliedFilter.serviceTypeId === 0) {
                    count = allTotalCount;
                } else {
                    count = FilterListData?.serviceTypeCount.find(v => v.serviceTypeId === localAppliedFilter.serviceTypeId)?.count ?? 0;
                }
            } else if (localAppliedFilter.cqcRating?.length === 0) {
                if (localAppliedFilter.serviceStatus?.includes("Available")) {
                    count += FilterListData?.availableCount ?? 0;
                }
                if (localAppliedFilter.serviceStatus?.includes("Occupied")) {
                    count += FilterListData?.occupiedCount ?? 0;
                }
            } else if (localAppliedFilter.cqcRating?.length > 0) {
                localAppliedFilter.cqcRating?.forEach(v => {
                    count += FilterListData?.cqcRatingCount.find(vv => vv.cqcRating === v)?.count ?? 0;
                });
            }
        }
        return count;
    }, [localAppliedFilter, FilterListData, amenitiesTotalCount]);

    const [showPortal, setShowPortal] = useState(false);
    const handleModal = (event: any) => {
        event.preventDefault();
        // setSelectedTab(id)
        setShowPortal((prevState) => !prevState);
    };

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showPortal]);

    const handleRemoveAmenities = () => {
        setLocalAppliedFilters({ ...localAppliedFilter, amenities: [] });
        const payload = {
            ...localAppliedFilter,
            serviceTypeId: localAppliedFilter.serviceTypeId,
            cqcRating: localAppliedFilter.cqcRating,
            serviceStatus: localAppliedFilter.serviceStatus,
            Amenities: [],
        }
        delete payload.amenities;
        advancedFilterCountApiCall(payload);
    }

    return (
        <div>
            <div className="d-flex justify-content-between filterList-view-header">
                <p>Filters</p>
                <div onClick={handleModalAction}>
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                </div>
            </div>

            <div className='filterList-view-container'>
                {FilterListData ? (
                    <>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className='fs-6 fw-bolder mt-2'>Service Type</p>
                        </div>
                        <div className='horizontal-line'></div>

                        {FilterListData?.serviceTypeCount?.map((service, index) => (
                            <div className='d-flex justify-content-between my-2' key={index}>
                                <div className='form-check' onClick={() => filterTypeChange(service.serviceTypeId)}>
                                    <input className="form-check-input filter-input" type="radio" name="service-select" id={service.serviceTypeName} checked={localAppliedFilter.serviceTypeId === service.serviceTypeId} />
                                    <label className={"form-check-label fw-normal ms-2"} style={{ fontSize: "14px" }} htmlFor={service.serviceTypeName}>
                                        {service.serviceTypeName}
                                    </label>
                                </div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {service.count}
                                    <div className="ms-2">
                                        <img src={locationIcon} height={15} alt={'closeicon-vector'} />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className='d-flex mt-3 align-items-center justify-content-between'>
                            <p className='fs-6 fw-bolder'>Service Status</p>
                            {localAppliedFilter.serviceStatus?.length > 0 ? (
                                <div className='chip' onClick={handleCloseServiceChip}>
                                    {localAppliedFilter.serviceStatus?.length + " Selected"}
                                    <div className="d-inline ms-2" style={{ cursor: "pointer" }}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='horizontal-line'></div>

                        <div className='d-flex justify-content-between my-2'>
                            <div className='form-check'>
                                <input className="form-check-input filter-input" type="checkbox" name="available" id="available" checked={localAppliedFilter.serviceStatus?.includes("Available")} onChange={(e) => handleServiceStatusChange("Available", e)} />
                                <label className={"form-check-label fw-normal ms-2"} style={{ fontSize: "14px" }} htmlFor="available">
                                    Available
                                </label>
                            </div>
                            <div className='d-flex align-items-center justify-content-center'>
                                {FilterListData?.availableCount}
                                <div className="ms-2">
                                    <img src={locationIconGreen} height={15} alt={'closeicon-vector'} />
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-between my-2'>
                            <div className='form-check'>
                                <input className="form-check-input filter-input" type="checkbox" name="Occupied" id="Occupied" checked={localAppliedFilter.serviceStatus?.includes("Occupied")} onChange={(e) => handleServiceStatusChange("Occupied", e)} />
                                <label className={"form-check-label fw-normal ms-2"} style={{ fontSize: "14px" }} htmlFor="Occupied">
                                    Occupied
                                </label>
                            </div>
                            <div className='d-flex align-items-center justify-content-center'>
                                {FilterListData?.occupiedCount}
                                <div className="ms-2">
                                    <img src={locationIconRed} height={15} alt={'closeicon-vector'} />
                                </div>
                            </div>
                        </div>

                        <div className='d-flex mt-3 align-items-center justify-content-between'>
                            <p className='fs-6 fw-bolder'>CQC Rating</p>
                            {localAppliedFilter.cqcRating.length > 0 ? (
                                <div className='chip' onClick={handleCloseCQCRating}>
                                    {localAppliedFilter.cqcRating?.length + " Selected"}
                                    <div className="d-inline ms-2" style={{ cursor: "pointer" }}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='horizontal-line'></div>

                        {FilterListData?.cqcRatingCount?.map((cqc, i) => (
                            <div className='d-flex justify-content-between my-2' key={i}>
                                <div className='form-check'>
                                    <input className="form-check-input filter-input" type="checkbox" name={cqc.cqcRating} id={cqc.cqcRating} checked={localAppliedFilter.cqcRating?.includes(cqc.cqcRating)} onChange={(e) => handleCQCStatusChange(cqc, e)} />
                                    <label className={"form-check-label fw-normal ms-2"} style={{ fontSize: "14px" }} htmlFor={cqc.cqcRating}>
                                        {cqc.cqcRating}
                                    </label>
                                </div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {cqc.count}
                                    <div className="ms-2">
                                        <img src={locationIcon} height={15} alt={'closeicon-vector'} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='d-flex mt-3 align-items-center justify-content-between'>
                            <p className='fs-6 fw-bolder filter-amenities-title' onClick={handleModal} >Amenities</p>
                            {localAppliedFilter?.amenities && localAppliedFilter?.amenities?.length > 0 ? (
                                <div className='chip' onClick={handleRemoveAmenities}>
                                    {localAppliedFilter?.amenities?.length + " Selected"}
                                    <div className="d-inline ms-2" style={{ cursor: "pointer" }}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>

            <div className="filterType-footer d-flex">
                <button className="col filterType-footer-button" onClick={handleFilterApplied}>
                    <p className='bold filter-apply'>Shows {totalItems} Sites</p>
                </button>
                <button className="col filterType-footer-button" onClick={handleResetFilter}>
                    <p className='bold text-danger'>Reset</p>
                </button>
            </div>

            <ClientPortal selector="myportal" show={showPortal}>
                <AmenitiesPopup
                    handleModal={handleModal}
                    setShowPortal={setShowPortal}
                    localAppliedFilter={localAppliedFilter}
                    setLocalAppliedFilters={setLocalAppliedFilters}
                    advancedFilterCountApiCall={advancedFilterCountApiCall}
                />
            </ClientPortal>
        </div>
    );
};

export default FilterListView;