import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AdvancedFilterCountResponse } from './types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from "lodash/debounce";
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store';
import { setFilterMeta } from '../../../../store/filter/filterMetaDataReducer';
import { ApiResponse } from '../../../../utils/model';
import { setFilterListsAction } from '../../../../store/filter/advanceFilterReducer';
import { setAppliedFilterAction } from '../../../../store/filter/appliedFilterReducer';
import { GetAllServicesPayloadType } from '../../../../pages/NHSPage/HomeMap/types';
import { findDateRange } from '../../../../utils/dateRange';
import { ServicesListsType } from '../../../../pages/NHSPage/HomeList/ListBox/types';
import { CountryExist, GetAdvancedFilterCount, GetAllServiceListService, GetDefaultLocationData, GetLocationWiseLatLongData } from '../../../../service/serviceProvider-services';
import { setServiceListsAction } from '../../../../store/general/serviceListsReducer';
import { setCityWiseLatLngListAction } from '../../../../store/serviceProvider/cityWiseLatLngListReducer';
import eventEmitter from '../../../../utils/eventEmitter';
import { Roles } from '../../../../utils/constant';

const SearchFilterView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { DataLogin } = useSelector((state: RootState) => state.login);
  const { FilterMetaData } = useSelector((state: RootState) => state.metaFilter);
  const { AppliedFilterData, TopFilterData } = useSelector((state: RootState) => state.appliedFilter);
  const { FilterListData } = useSelector((state: RootState) => state.filterList);
  const { DataUserLocation } = useSelector((state: RootState) => state.userLocation);
  const [appliedFilterCount, setAppliedFilterCount] = useState<number>(0);
  const { ServiceListsData } = useSelector(
    (state: RootState) => state.serviceLists
  );

  const [statusAvailableService, setStatusAvailableService] = useState(true);

  const handleModalAction = useCallback(async () => {
    dispatch(setFilterMeta({
      drawerOpen: !FilterMetaData.drawerOpen,
      selectedFilterCount: FilterMetaData.selectedFilterCount
    }))
  }, [setFilterListsAction, FilterMetaData]);

  const isDrawerOpen = useMemo(() => {
    const isAnyFilterApplied = AppliedFilterData.cqcRating.length > 0 || AppliedFilterData.serviceStatus.length > 0 || AppliedFilterData.serviceTypeId !== 0;
    return FilterMetaData.drawerOpen || isAnyFilterApplied;
  }, [FilterMetaData.drawerOpen]);

  useEffect(() => {
    dispatch(setFilterMeta({
      drawerOpen: false,
      selectedFilterCount: FilterMetaData.selectedFilterCount
    }))
  }, []);

  useEffect(() => {
    const totalCQC = AppliedFilterData.cqcRating?.length || 0,
      serviceStatus = AppliedFilterData.serviceStatus?.length || 0;

    if (totalCQC > 0 || serviceStatus > 0) {
      setAppliedFilterCount(serviceStatus + totalCQC);
    }
  }, [AppliedFilterData.cqcRating, AppliedFilterData.serviceStatus]);

  useEffect(() => {
    eventEmitter.on("appliedFilterCountAction", (data) => setAppliedFilterCount(data));
    return () => {
      eventEmitter.off("appliedFilterCountAction", (data) => setAppliedFilterCount(data));
    }
  }, [eventEmitter.on, eventEmitter.off]);

  const isShowAvailableChecked = useMemo(() => {
    return AppliedFilterData?.serviceStatus?.length > 0 && AppliedFilterData?.serviceStatus[0] === "Available" && AppliedFilterData?.serviceStatus?.length < 2;
  }, [AppliedFilterData]);

  const onClickHandlerShowAvailableService = (e: any) => {
    if (!isShowAvailableChecked) {
      dispatch(setAppliedFilterAction({
        ...AppliedFilterData,
        serviceStatus: ["Available"]
      }));
    } else {
      dispatch(setAppliedFilterAction({
        ...AppliedFilterData,
        serviceStatus: ["Available", "Occupied"]
      }));
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     eventEmitter.emit("handleServiceStatus", true)
  //   }, 1000)
  // }, [])

  // const handelServiceStatusFilter = (data: any) => {
  //   if(data?.serviceStatus?.length > 0 && data?.serviceStatus[0] === "Available" && data?.serviceStatus?.length < 2) {
  //     setStatusAvailableService(true)
  //   } else {
  //     setStatusAvailableService(false)
  //   }
  // }

  // useEffect(() => {
  //   eventEmitter.on("handelServiceStatusFilter", handelServiceStatusFilter);
  //   return () => {
  //     eventEmitter.off("handelServiceStatusFilter", handelServiceStatusFilter);
  //   }
  // }, [eventEmitter.on, eventEmitter.off]);

  // useEffect(() => {
  //   eventEmitter.emit("showAvailableService", statusAvailableService, ServiceListsData);
  // }, [statusAvailableService]);

  useEffect(() => {
    if (FilterMetaData?.selectedFilterCount && FilterMetaData?.selectedFilterCount > 0) {
      setAppliedFilterCount(FilterMetaData?.selectedFilterCount);
    }
  }, [FilterMetaData?.selectedFilterCount]);

  return (
    <div className={`d-flex justify-content-end switchbox-right`}>
      <div className="d-flex align-items-center">
        {DataLogin?.userModel?.userRoleId === Roles.NHS ? <div className="switch-tax">
          Show available services
          {/* <label className="switch ms-2">
            <input type="checkbox" defaultChecked />
            <div className="slider">
              <div className="circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  className="checkmark"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                    data-original="#000000"
                  ></path>
                </svg>
              </div>
            </div>
          </label> */}
          {/* <div className="toggle-button">
		<input type="checkbox" id="checkbox-a" checked/>
		<label htmlFor="checkbox-a"><span className="knobs"></span></label>
	</div> */}
     <input className='ms-2' type="checkbox" checked={isShowAvailableChecked} onChange={(e: any) => onClickHandlerShowAvailableService(e)} id="switch" /><label htmlFor="switch">Toggle</label>
        </div> : null}

        <div
          onClick={handleModalAction}
          className={`btn filter-btn ${appliedFilterCount > 0 || isDrawerOpen ? 'btn-active' : ''} btn-white bold`}
        >
          <svg
            className="me-1"
            version="1.1"
            id="Layer_2"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            width="14"
            y="0px"
            viewBox="0 0 384 384"
            xmlSpace="preserve"
          >
            <style>{`"enable-background:new 0 0 384 384;"`}</style>
            <g>
              <path
                d="M119.2,54.4c31.3,0,62.5,0,93.8,0.1c2.8,0,4-0.6,4.8-3.6c5.4-21,24.9-35.9,46.7-35.8c22,0,41.3,14.9,46.8,36.2
    c0.7,2.6,1.8,3.3,4.3,3.3c14.4-0.1,28.8-0.1,43.1,0c6,0,9.7,2.8,10.4,7.6c0.8,5.5-3,10-8.8,10.2c-5.2,0.2-10.5,0.1-15.8,0.1
    c-9.8,0-19.5,0.1-29.3,0c-2.3,0-3.2,0.8-3.8,3c-6,22.1-24.6,36.5-46.9,36.5c-22.2,0-40.9-14.6-46.8-36.6c-0.7-2.8-2.3-2.8-4.3-2.8
    c-26.6,0-53.3,0-79.9,0c-35.4,0-70.8,0-106.2,0c-1.7,0-3.5,0-5.2-0.3c-4.3-0.8-7.2-4.6-7.1-8.9c0.1-4.3,3.2-7.9,7.5-8.5
    c1.7-0.2,3.5-0.2,5.2-0.2C58.2,54.4,88.7,54.4,119.2,54.4z M294.8,63.5c0.1-16.8-13.4-30.5-30-30.6c-16.9-0.1-30.7,13.5-30.8,30.3
    c-0.1,16.7,13.4,30.4,30.1,30.5C281.1,93.8,294.7,80.4,294.8,63.5z"
              />
              <path
                d="M313.6,329.6c-3.8,7.2-6.3,14.5-10.8,20.3c-11.9,15.6-28.1,21.9-47.3,18.2c-19.1-3.7-31.8-15.5-37.4-34.3
    c-1.1-3.5-2.6-4.2-5.9-4.2c-61.4,0.1-122.8,0.1-184.2,0.1c-1.7,0-3.5,0-5.2-0.2c-4.5-0.6-7.7-4.2-7.8-8.6c-0.1-4.4,3-8.2,7.5-8.9
    c1.7-0.3,3.5-0.2,5.2-0.2c61.4,0,122.8,0,184.2,0.1c3.4,0,5.1-0.5,6.2-4.4c5.9-21.2,25.1-35.3,47.2-35.1c21.7,0.3,40.5,15,46,36.2
    c0.7,2.7,1.9,3.2,4.4,3.2c14.4-0.1,28.8-0.1,43.1,0c6.1,0,9.9,3.1,10.3,8.1c0.4,5-2.8,9.2-7.8,9.6c-4.1,0.3-8.2,0.2-12.4,0.2
    C336.7,329.6,324.6,329.6,313.6,329.6z M294.9,320.9c0.2-16.8-13.1-30.6-29.8-30.7c-17-0.2-30.8,13.2-31,30
    c-0.2,16.6,13.4,30.6,29.9,30.8C280.9,351.2,294.6,337.9,294.9,320.9z"
              />
              <path
                d="M191.8,201c-6.9,28-29.1,39.6-48.1,39.4c-20.9-0.2-41.6-13.8-47.4-39.4c-1.2,0-2.6,0-3.9,0c-21.9,0-43.8,0-65.6,0
    c-1.2,0-2.5,0.1-3.7-0.1c-4.6-0.6-8-4.4-8-9c0-4.5,3.5-8.3,8.1-8.9c1.2-0.1,2.5-0.1,3.7-0.1c21.8,0,43.5,0,65.3,0
    c1.4,0,2.7,0,4.3,0c2.8-12.9,9.3-23.2,20-30.7c8.3-5.8,17.7-8.7,27.8-8.7c17.4,0,40.3,10.1,47.7,39.4c1.4,0,2.9,0,4.4,0
    c53.6,0,107.3,0,160.9,0c7.7,0,11.8,3.1,11.9,8.8c0.1,5.9-4.2,9.2-12,9.2c-53.6,0-107.3,0-160.9,0C194.6,201,193.2,201,191.8,201z
     M174.4,192.3c0.4-16.5-13-30.3-29.9-30.8c-16.6-0.4-30.4,12.8-31,29.7c-0.6,16.6,13,30.8,29.9,31.1
    C160.2,222.7,173.9,209.4,174.4,192.3z"
              />
            </g>
          </svg>{" "}
          {t("Header.Filter_btn")}
          {appliedFilterCount > 0 ? (
            <span className='ms-1 filter-label'>{appliedFilterCount}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchFilterView;