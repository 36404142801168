import React from 'react';
import PreviewContractView from './PreviewContractView';

const PreviewContractContainer = (props: any) => {
    return (
        <div>
            <PreviewContractView previewdata={props.previewdata} onClose={props.onClose}  title={props.title} className={props.className} content={props.content} isContractFile={props.isContractFile} printFileName={props.printFileName}/>
        </div>
    );
};

export default PreviewContractContainer;