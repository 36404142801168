import React, { useEffect, useMemo } from 'react';
import HomeMapView from '../../../NHSPage/HomeMap/HomeMapView';
import MapViewService from './MapViewService';
import { ServicesListsType } from '../../../NHSPage/HomeList/ListBox/types';
import { ApiResponse } from '../../../../utils/model';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllServiceListService, GetUserLatLongList } from '../../../../service/serviceProvider-services';
import { setServiceListsAction } from '../../../../store/general/serviceListsReducer';
import { setCityWiseLatLngListAction } from '../../../../store/serviceProvider/cityWiseLatLngListReducer';
import eventEmitter from '../../../../utils/eventEmitter';
import { setAppliedFilterAction } from '../../../../store/filter/appliedFilterReducer';
import { format } from 'date-fns';
import { findDateRange } from '../../../../utils/dateRange';

const MapViewServiceContainer = () => {
    const dispatch = useDispatch();
    const { AppliedFilterData, TopFilterData } = useSelector((state: RootState) => state.appliedFilter);

    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const { StatusUserLocation, DataUserLocation } = useSelector((state: RootState) => state.userLocation);

    const { DataCityWiseLatLngList } = useSelector((state: RootState) => state.cityWiseLatLngList);

    useEffect(() => {
        dispatch(
            setAppliedFilterAction({
                ...AppliedFilterData,
                searchValue: ''
            })
        );
    }, []);

    const GetAllServiceList = async ({ serviceTypeId = 0, cityName = '', isShowOutOfArea = 1, cqcRating = [] as string[], serviceId = [] as number[] } = {}) => {
        let payload = {
            ...AppliedFilterData,
            dateRange: findDateRange(
                {
                    ...AppliedFilterData,
                    ...TopFilterData
                },
            ).map((item: any) => ({
                fromDate: format(item.startDate, 'yyyy-MM-dd'),
                toDate: format(item.endDate, 'yyyy-MM-dd')
            })),
            serviceTypeId: serviceTypeId,
            cityName: "",
            sortOrder: null,
            sortBy: null,
            location: null,
            isShowOutOfArea: 1,
            cqcRating,
            serviceId: DataServiceDetail?.serviceId ? DataServiceDetail?.serviceId : 0,
            serviceStatus: [],
            bedCount: 0,
            searchValue: AppliedFilterData.searchValue === "" ? null : AppliedFilterData.searchValue
        };
        const result: ApiResponse<ServicesListsType[]> = await GetAllServiceListService(payload);
        if (result?.status === true) {
            dispatch(setServiceListsAction(result?.data));
        } else {
            dispatch(setServiceListsAction([]));
        }
    };

    const GetLocationWiseLatLongData = async () => {
        console.log("DataServiceDetail: ", DataServiceDetail)
        const result = await GetUserLatLongList();
        if (result?.status === true) {
            let data = result?.data;
            let latlnglist: any = [];
            data?.latLongList?.map((val: any) => {
                let obj = {
                    lat: Number(val.latitude),
                    lng: Number(val.longitude)
                };
                latlnglist.push(obj);
            });
            data.defaultLocation = false;
            data.latLongList = latlnglist;
            dispatch(setCityWiseLatLngListAction(data));
        }
    };

    useEffect(() => {
        const executeAllAPI = async () => {
            GetLocationWiseLatLongData();
            let obj = {
                serviceId: []
            };
            GetAllServiceList(obj);
        };
        executeAllAPI();
    }, [AppliedFilterData]);

    return (
        <div>
            <MapViewService />
        </div>
    );
};

export default MapViewServiceContainer;
