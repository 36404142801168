import React from 'react';
import logo from "../assets/logos/logo.png"
import abstract from "../assets/images/abstract.png"

const ContractSignedSuccess = () => {
    return (
        <div className="error-bg ">
                    <div className="abstarct1">
                    <img src={abstract} alt="" />
                </div>
                <div>
                    <a href="#" className="error-logo"><img src={logo} alt="" /></a>
                </div>
                <div className="error-page success-page text-center">
                    <h1 className="success-text"><i className="fa-solid fa-circle-check"></i></h1>
                    <h2 className="mb-3 bold mt-3">Successfully Signed!</h2>
                    <p>You have successfully signed the contract. You can close this tab and navigate to Locate Care.</p>
                    {/* <a href="/login" className="btn btn-primary">Login</a> */}
                </div>
               
            </div>
    );
};

export default ContractSignedSuccess;