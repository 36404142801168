import React from 'react';
import ServicesDetailsView from './ServicesDetailsView';

const ServicesDetailsContainer = (props:any) => {
    return (
        <>
            <ServicesDetailsView serviceDetails={props}/>
        </>
    );
};

export default ServicesDetailsContainer;