import React from 'react';
import { useTranslation } from 'react-i18next';

const MaintenanceDetailsTabView = (props: any) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapse-applicable-rates"
                        aria-expanded="false"
                        aria-controls="flush-collapse-applicablerates"
                    >
                        {t('RightSidebarMaintenanceDetails.Maintenance_Detail_Accor')}
                    </button>
                </h2>
                <div
                    id="flush-collapse-applicable-rates"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-collapse-applicable-rates"
                    data-bs-parent="#admissiondetail1"
                >
                    <div className="accordion-body pb-0">
                        <p className="mb-1">
                            <label>{t('RightSidebarMaintenanceDetails.Maintenance_Start_Date')}:</label> <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.bedDetailData?.bedMaintenanceDetail?.maintenanceStartDate) || ''}</span>
                        </p>
                        <p className="mb-1">
                            <label>{t('RightSidebarMaintenanceDetails.Est_Release_Date')}:</label> <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.bedDetailData?.bedMaintenanceDetail?.estMaintenanceReleaseDate) || ''}</span>
                        </p>
                        <p className="mb-1">
                            <label>{t('RightSidebarMaintenanceDetails.Reason_for_Maintenance')}:</label> <span className="bold">{props.bedDetailData?.bedMaintenanceDetail?.reason}</span>
                        </p>
                        <p className="mb-1">
                            <label>{t('RightSidebarMaintenanceDetails.Comments')}:</label> <span className="bold">{props.bedDetailData?.bedMaintenanceDetail?.comments}</span>
                        </p>
                        <p className="mb-1">
                            <label>{t('RightSidebarMaintenanceDetails.Placed_in_Maintenance_By')}:</label> <span className="bold">{props.bedDetailData?.bedMaintenanceDetail?.placedInMaintenanceBy}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceDetailsTabView;
