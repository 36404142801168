import React from 'react';
import PieChartView from './PieChartView';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChartContainer = (props: any) => {
    Chart.register(ChartDataLabels);
    // Chart data
    const data = {
      labels: props.labels,
      datasets: [
        {
          data: props.data, // The values for each segment
          backgroundColor: props.statuscolor, 
        },
      ],
    };
  
    const options = {
      //cutoutPercentage: 100,
      responsive: true,
      aspectRatio: 1,
      hover: {mode: null},
      cutout: '0',
        plugins: {
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'legend-container',
          },
            legend: {
                display: false,
                position: "bottom",
                // labels: {
                //   usePointStyle: true,
                //   boxWidth: 6,
                //   boxHeight:6
                // },
                labels: {
                  usePointStyle: true,
                  boxWidth: 5,
                  boxHeight: 5,
                  padding:8,
                  // Custom legend callback to include percentages
                  generateLabels: (chart:any) => {
                    const data = chart.data.datasets[0].data;
                    const total = data.reduce((acc:any, val:any) => acc + val, 0);
          
                    return data.map((value:any, index:any) => {
                      const percentage =  value ;
                      const color = chart.data.datasets[0].backgroundColor[index];
                      return {
                        text: `${chart.data.labels[index]} (${percentage})`,
                        fillStyle: color,
                        lineWidth :0,
                        pointBorderColor: color, // Set pointBorderColor to the same color as the fillStyle
                      hidden: false,
                      index: index,
                      };
                    });
                  },
                },
              },
              
          datalabels: {
            display: false,
          },
          
          tooltip: {
            callbacks: {
              label: (context:any) => {
                let sum = 0;
                let dataArr = context.chart.data.datasets[0].data;
                dataArr.forEach((data:any) => {
                  sum += data;
                });
                let percentage =
                context.dataset.data[context.dataIndex] 
                return percentage;
              },
            },
           
           },
         
        },
      };
    return (
        <div>
            <PieChartView data={data} options={options} />
            <div className="legend-container" id="legend-container">
              {data.datasets[0].data.map((value:any, index:any) => (
                <div key={index} className="legend-item">
                  <div
                    className="legend-color"
                    style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                  ></div>
                  <div className="legend-label">
                    {data.labels[index]} ({value})
                  </div>
                </div>
              ))}
            </div>

        </div>
    );
};

export default PieChartContainer;