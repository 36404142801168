import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WardBedInformationModel } from '../../utils/model';

const initialState: WardBedInformationModel = {
    StatusWardBedInfo: false,
    DataWardBedInfo: '',
    MessageWardBedInfo: '',
    ErrorWardBedInfo: ''
};

export const wardBedInformationSlice = createSlice({
    name: 'wardBedInformation',
    initialState,
    reducers: {
        setWardBedInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusWardBedInfo = true;
            state.DataWardBedInfo = payload;
            state.MessageWardBedInfo = 'Success';
        },
        errorWardBedInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusWardBedInfo = false;
            state.DataWardBedInfo = [];
            state.MessageWardBedInfo = 'Failed';
            state.ErrorWardBedInfo = payload;
        },
        removeWardBedInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusWardBedInfo = false;
            state.DataWardBedInfo = '';
            state.MessageWardBedInfo = '';
            state.ErrorWardBedInfo = '';
        }
    },
    extraReducers: {}
});

export const { setWardBedInformationAction, errorWardBedInformationAction, removeWardBedInformationAction } = wardBedInformationSlice.actions;

export default wardBedInformationSlice.reducer;
