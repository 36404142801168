import { AxiosError, AxiosResponse } from 'axios';
import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';
import { GetAllServicesPayloadType } from '../pages/NHSPage/HomeMap/types';
import { GetAdvancedFilterCountType } from '../layout/NHSHeader/ServicetypeHeader/SearchFilter/types';
import eventEmitter from '../utils/eventEmitter';


export const GetAllServiceListService = async (payload: GetAllServicesPayloadType) => {
    eventEmitter.emit('eventSetLoader', true);
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetAllServiceListForUser}`, payload)
        .then((response) => {
            eventEmitter.emit('eventSetLoader', true);
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        }).finally (() => {
            eventEmitter.emit('eventSetLoader', false);
        })
};


export const GetUserLatLongList = async () => {
    return await instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetUserLatLongList}`)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
};

export const GetAdvancedFilterCount = async (payload?: GetAdvancedFilterCountType) => {
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetAdvancedFilterCount}`, payload)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
};

export const CountryExist = async (payload: any) => {
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.CountryExist}`, payload)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
};

export const GetLocationWiseLatLongData = async (payload: any) => {
    const result = await GetLocationWiseLatLongList(payload);

    if (result?.status === true) {
        let data = result?.data;
        let latlnglist: any = [];
        data?.latLongList?.map((val: any) => {
            let obj = {
                lat: Number(val.latitude),
                lng: Number(val.longitude),
            };
            latlnglist.push(obj);
        });
        data.defaultLocation = false;
        data.latLongList = latlnglist;
        return data;
    }
};

export const GetLocationWiseLatLongList = async (payload: any) => {
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetLocationWiseLatLongList}`, payload)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
};

export const GetDefaultLocation = async (payload: any) => {
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetDefaultLocation}`, payload)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
};

export const GetDefaultLocationData = async (payload: any) => {
    const result = await GetDefaultLocation(payload);

    if (result?.status === true) {
        let data = result?.data;
        let latlnglist: any = [];
        data?.latLongList?.map((val: any) => {
            let obj = {
                lat: Number(val.latitude),
                lng: Number(val.longitude),
            };
            latlnglist.push(obj);
        });
        data.defaultLocation = true;
        data.latLongList = latlnglist;
        return data;
    }
};

export const Getserviceimagesbyserviceslug = (slugName: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.Getserviceimagesbyserviceslug}?serviceSlug=${slugName}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};