import React, { useEffect, useState } from 'react';
import logopurple from '../../../assets/logos/locate-care-new-white.png';
import { setSaveForLater } from '../../../store/SaveForLater/saveForLaterDrawerReducer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { Modules, Roles } from '../../../utils/constant';
import { setRoleSelectedAction } from '../../../store/Auth/roleSelectedReducer';
import { setNavigationAction } from '../../../store/dashboard/navigationReducer';
import { switchRoleService } from '../../../service/auth-service';
import locationIcon from '../../../assets/images/locationIcon.svg';
import { addDays } from 'date-fns';
//@ts-ignore
// import { DateRangePicker } from 'react-date-range';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import CircularSlider from "@fseehawer/react-circular-slider";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
import logo from '../../../assets/logos/logo-white-website.png';
import logoIcon from '../../../assets/logos/logo-icon.png';
import { resetIsNhsReferralTracker } from '../../../store/NHS/nhsLoginReducer';
import ChangePasswordModalContainer from '../../../components/ChangePasswordModal/ChangePasswordModalContainer';
import ClientPortal from '../../../components/ClientPortal';

const SearchHeaderView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);

    const [userName, setUserName] = useState<any>('');
    const [showPortal, setShowPortal] = useState(false);


    const ChangePassword = () => {
        navigate(ROUTES_DATA.CHANGEPASSWORD);
    };
    const handleModal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };

    const logout = () => {
        window.$utils.signOutUser();
        navigate(ROUTES_DATA.LOGIN);
    };

    const handleSaveForLaterDrawer = () => {
        dispatch(setSaveForLater({
            saveForLaterDrawerOpen: true
        }))
    }

    useEffect(() => {
        setUserName(DataLogin.userModel.userName);
        if (StatusLogin === true && StatusRoleSelected === false) {
            if (DataLogin.userModel.roles.length > 0) {
                let roleObj: any = {
                    roleId: '',
                    roleName: '',
                    isDefaultRole: '',
                    modules: ''
                };
                let rolesData = DataLogin.userModel.roles.map((val: any) => {
                    if (val.isDefaultRole === true) {
                        roleObj.roleId = val.roleId;
                        roleObj.roleName = val.roleName;
                        roleObj.isDefaultRole = val.isDefaultRole;
                        roleObj.modules = DataLogin.userModel.modules;
                    }
                });
                //
                dispatch(setRoleSelectedAction(roleObj));
            }
        }
    }, [DataLogin.userModel.userRoleId]);

    var string = userName;
    string = string
        .split(' ')
        .map((part: any[]) => part[0])
        .join('')
        .toUpperCase();

    const changeModule = () => {
        navigate(ROUTES_DATA.NHS);
        dispatch(resetIsNhsReferralTracker());
    }

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showPortal]);

    return (
        <div className="nhs-referral-header">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-6 order-1 ">
                        <a href="#" aria-label="Home redirection">
                            <img src={logo} className="logo-nhs-header mb-0" alt="logo" />
                        </a>
                    </div>

                    <div className="col-lg-6 col-6 order-2 order-lg-3 d-flex align-items-center justify-content-end">
                        <div className='me-2'>
                            <span className="white-text hand-cursor" onClick={() => changeModule()}>
                                View Map
                            </span>
                        </div>

                        <div className="user me-2 float-end">
                            <ul className="navbar-nav">
                                <div className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle nhs-user_img" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="user_img">{string}</div>
                                    </a>
                                    <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="#">
                                            <i className="fa-solid fa-user "></i>
                                            <span className="ms-2">{userName || ''}</span>
                                        </a>
                                        {/* <a className="dropdown-item" onClick={handleSaveForLaterDrawer}>
                                            <i className="fa-regular fa-heart"></i>
                                            <span className="ms-1">Save for later</span>
                                        </a> */}
                                        <a className="dropdown-item" onClick={(e) => handleModal(e)}>
                                            <i className="fa-solid fa-lock"></i>
                                            <span className="ms-1">Change Password</span>
                                        </a>
                                        <a className="dropdown-item" href="#" onClick={logout}>
                                            <i className="fa-solid fa-right-from-bracket "></i>
                                            <span className="ms-1">Log Out</span>
                                        </a>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <ClientPortal 
                selector="myportal"
                show={showPortal}
                onClose={() => { setShowPortal((prevState) => !prevState); }}
            >
                <ChangePasswordModalContainer />
            </ClientPortal>
        </div>
    );
};

export default SearchHeaderView;
