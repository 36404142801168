import React, { useEffect, useState } from 'react';
import servicelogo from '../../../../assets/images/service-logo.png';
import availablebed from '../../../../assets/images/available-bed.svg';
import outlinebed from '../../../../assets/images/outline-bed.svg';
import occupiedbed from '../../../../assets/images/occupied-bed.svg';
import fillingfastbed from '../../../../assets/images/fillingfast-bed.svg';
import { useParams } from 'react-router-dom';
import { GetServiceDetails } from '../../../../service/service-details-service';
import { useTranslation } from 'react-i18next';
import { saveForLaterModal } from '../../../../store/SaveForLater/saveForLaterModalReducer';
import SaveForLaterModalContainer from '../../HomeList/ListBox/SaveForLaterModal/SaveForLaterModalContainer';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import ClientPortal from '../../../../components/ClientPortal';
import ShareOptionsContainer from '../../../../components/ShareOptions/ShareOptionsContainer';
import { ROUTES_DATA } from '../../../../navigation/RoutesConstants';
import { Roles } from '../../../../utils/constant';
import { useLocation } from 'react-router-dom';

const ServiceDetailHeaderView = (props: any) => {
    const params = useParams();
    const { t } = useTranslation();
    const { saveForLaterModelOpen } = useSelector((state: RootState) => state.saveForLaterModal);
    const dispatch = useDispatch();
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const location = useLocation();

    const [serviceDetails, setServiceDetails] = useState<any>(null);
    useEffect(() => {
        GetServiceDetailsList();
    }, []);

    const GetServiceDetailsList = async () => {
        const result = await GetServiceDetails(params?.serviceSlug);
        setServiceDetails(result);
    };

    const copyHandler = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href);
    };

    const handleClickModal = (e: any) => {
        e.stopPropagation();
        dispatch(
            saveForLaterModal({
                saveForLaterModelOpen: true,
                serviceId: serviceDetails?.data?.serviceId,
                serviceName: serviceDetails?.data?.serviceName
            })
        );
    };
    const handleOpenShare = () => {
        setShowShareModal(true)
    }
    const handleModal = (event: any) => {
        event.preventDefault();
        setShowShareModal((prevState) => !prevState);
    };
    useEffect(() => {
        if (showShareModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showShareModal]);
    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === 'Escape') {
                handleModal(event);
            }
        };

        if (showShareModal) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showShareModal, handleModal]);

   
    return (
        <div className="service-detail-padding  service-detail-top-section">
            <div className="container-fluid mt-3 mb-3 p-0">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-8">
                        <div>
                            <p className="service-title">{serviceDetails?.data?.serviceName}</p>
                            <div className="mt-2 d-flex">
                            {serviceDetails?.data?.serviceType?.split(',')?.map((service:any,key:any)=>{
                        return(
                            <span className="service-type ms-1" key={key}>{service.trim()}</span>
                        )
                    })}
                                {/* Add more services like below */}
                                {/* <span className="service-type ms-2">Nursing</span> */}
                                {/* <p className="lead-text">
                    {serviceDetails?.data?.address}
                  </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-4">
                        <div className="d-flex flex-column align-items-end">
                            <div className="d-flex align-items-center">
                                <div className="mb-3 d-flex align-items-center">
                                    <div className="text-center me-3">
                                        <img src={outlinebed} alt="Outline Bed" width={30} /> <span className="bold lead-text vertical-middle">{serviceDetails?.data?.totalBedCount}</span>
                                        <p className="purple-text bold">Total Beds</p>
                                    </div>
                                </div>
                                {serviceDetails?.data?.availability === 'Occupied' && (
                                    <p className="occupied occupied-bg occupied-border avail-bed-detail d-flex align-items-center ">
                                        <img src={occupiedbed} alt="Occupied Bed" className="me-2" width={25} />
                                        {t(serviceDetails?.data?.availability)}
                                    </p>
                                )}

                                {serviceDetails?.data?.availability === 'Available' && (
                                    <p className="avail-bed-detail d-flex align-items-center ">
                                        {serviceDetails?.data?.availableBedCount > 0 && <span className="purple-text bold vertical-middle me-1">{serviceDetails?.data?.availableBedCount}</span>}
                                        <img src={availablebed} alt="Available Bed" className="me-2" width={25} />
                                        {t(serviceDetails?.data?.availability)}
                                    </p>
                                )}

                                {serviceDetails?.data?.availability === 'Filling Fast' && (
                                    <p className="filing-fast filing-fast-bg filing-fast-border filling-fast-bed-detail d-flex align-items-center">
                                        <img src={fillingfastbed} alt="Filling Fast Bed" className="me-2" width={25} />
                                        {t(serviceDetails?.data?.availability)}
                                    </p>
                                )}
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                {DataLogin?.userModel?.userRoleId === Roles.NHS && (
                                    <span className="hand-cursor me-3 heart-icon" onClick={handleClickModal}>
                                        <svg
                                            version="1.1"
                                            id="Layer_2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            width={23}
                                            y="0px"
                                            viewBox="0 0 3154.5 2836.4"
                                            xmlSpace="preserve"
                                        >
                                            <style>{`"enable-background:new 0 0 3154.5 2836.4;"`} </style>
                                            <g className="st0" style={{ opacity: 1 }}>
                                                <path
                                                    className="st1"
                                                    fill="#FFFFFF"
                                                    d="M923.6,159c-283.4,12.6-757,222.5-777.8,853.7c-7.4,224.7,166.8,903.5,1426.1,1678.2
C2828.3,1918,3017,1238.1,3011.6,1012.7c-15.2-631.5-493.1-841.1-777.8-853.7c-466.2-20.7-655.2,321.5-655.2,321.5
S1389.9,138.3,923.6,159z"
                                                />
                                            </g>
                                            <g>
                                                <path
                                                    className="st2"
                                                    fill="#070044"
                                                    d="M1571.8,2788.4l-43.5-26.8C107.2,1887.3,58.2,1149.1,62.8,1010c23.4-709.7,571-921.3,857.1-934l3.7,83l-3.7-83
c352.6-15.2,558.3,152.4,658.7,265.6C1679,228.5,1886.3,60.5,2237.5,76c288.9,12.8,839.9,224.7,857.1,934.7
c2.1,84.8-21.9,854.3-1479.3,1750.9L1571.8,2788.4z M963.8,241.2c-12,0-24.2,0.2-36.5,0.8c-233.1,10.4-679.2,185.3-698.5,773.6
c-2.6,74.6,14.6,746.3,1342.9,1577.6c1083.5-678.5,1363.4-1309.7,1356.7-1578.4C2914.4,427.4,2465.4,252.4,2230,242
c-399.7-16.1-571.9,266.8-578.9,279L1578,650.3l-72-129.7C1499.4,509.2,1343.2,241.2,963.8,241.2z"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                )}

                                <a onClick={handleOpenShare} className="indigo-text  lead-text hover-gray-btn cursor-pointer">
                                    <i className="fa-solid lead-text fa-share-nodes me-2"></i>
                                    <span className="underline" data-tooltip-id="my-tooltip" data-tooltip-content="Share">{t("ServiceDetail.Share")}</span>
                                </a>
                                {/* <a
                                    href="#"
                                    className="indigo-text ms-3  lead-text hover-gray-btn"
                                >
                                    <i className="fa-solid fa-heart me-1"></i> 3.8
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Tooltip className="my-tooltip" id="my-tooltip" />
                <ClientPortal selector="myportal" show={showShareModal}>
                    <div className="overlay" onClick={handleModal}></div>
                    <div className="modal website-small-modal">
                        <div className="header mb-3">
                            <h4 className="mb-0">{t("ServiceDetail.Share_this_Service")}</h4>
                            <a onClick={handleModal} className="removeButton">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                        fill="#ffffff"
                                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div className="body message-popup-share">
                            <ShareOptionsContainer serviceDetails={serviceDetails} url={window.location.href} />
                        </div>
                    </div>
                </ClientPortal>
            </div>
            {saveForLaterModelOpen && <SaveForLaterModalContainer />}
        </div>
    );
};

export default ServiceDetailHeaderView;
