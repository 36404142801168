import React from 'react';
import SearchFilterView from './SearchFilterView';

const SearchFilterContainer = () => {
    return (
        <div>
            <SearchFilterView />
        </div>
    );
};

export default SearchFilterContainer;