import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { rightNavigationPage } from '../../../../../../../utils/constant';
import { getContractFunderFileService } from '../../../../../../../service/contract-service';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import NoteContainer from '../../../../../../../components/NoteModal/indexContainer';
import note from '../../../../../../../assets/images/note.svg';

const ReservedContractListView = (props: any) => {
    const { t } = useTranslation();
    const { tabID } = props;
    const [showModal, setShowModal] = useState(false);
    const [funderNote, setFunderNote] = useState<any>('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const toggle = () => {
        setShowModal(false);
    };

    const downloadPDF = async(file: any) => {
        const payload: any = { name: file};
        const response = await getContractFunderFileService(payload)
        if(response.status === true) {
            let alink = document.createElement('a')
            alink.target = "_blank"
            alink.href = response.data
            alink.click()
        }
    }
    const handleNoteClickModal = (noteval:any) => {                
        setFunderNote(noteval);
        setShowNoteModal(true);
    }
    const toggleNote = () => {       
       setShowNoteModal(false);         
    };
    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className={classNames('accordion-button', { collapsed: props.accordID !== 0 })}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${props.accordID}`}
                        aria-expanded={props.accordID !== 0 ? 'false' : 'true'}
                        aria-controls={`flush-collapse${props.accordID}`}
                    >
                        {props.dataContract.funderName}
                    </button>
                </h2>
                <div
                    id={`flush-collapse${props.accordID}`}
                    className={classNames('accordion-collapse collapse', { show: props.accordID === 0 })}
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#admissiondetail1"
                >
                    <div className="accordion-body pb-0">
                        {/* {props.dataContract.funderName !== undefined && props.dataContract.funderName !== '' && props.dataContract.funderName !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_name')}:</label> <span className="bold">{props.dataContract.funderName}</span>
                            </p>
                        )} */}
                        {props.dataContract.funderEmail !== undefined && props.dataContract.funderEmail !== '' && props.dataContract.funderEmail !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_email')}:</label> <span className="bold">{props.dataContract.funderEmail}</span>
                            </p>
                        )}
                        {props.dataContract.funderPhoneNo !== undefined && props.dataContract.funderPhoneNo !== '' && props.dataContract.funderPhoneNo !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_phone')}:</label> <span className="bold">{props.dataContract.funderPhoneNo}</span>
                            </p>
                        )}
                        {props.dataContract.funderAddressLine1 !== undefined && props.dataContract.funderAddressLine1 !== '' && props.dataContract.funderAddressLine1 !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_address')}:</label>{' '}
                                <span className="bold">
                                    {props.dataContract.funderAddressLine1 || ''} {props.dataContract.funderCityName || ''}
                                </span>
                            </p>
                        )}
                        {props.dataContract?.suid !== undefined && props.dataContract?.suid !== '' && props.dataContract?.suid !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.suid')}:</label> <span className="bold">{props.dataContract?.suid}</span>
                            </p>
                        )}
                          {props.dataContract?.pO_Reference !== undefined && props.dataContract?.pO_Reference !== '' && props.dataContract?.pO_Reference !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.po_reference')}:</label> <span className="bold">{props.dataContract?.pO_Reference}</span>
                            </p>
                        )}
                        {props.dataContract?.note !== undefined && props.dataContract?.note !== '' && props.dataContract?.note !== null && (
                        <div className='d-flex mt-2 justify-content-end' ><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(props.dataContract?.note)}></img><p>{t('RightSidebarContraclList.notes')}</p></div>
                        )}
                        {props.dataContract.documentPath !== undefined && props.dataContract.documentPath !== null && 
                        <div className="mt-2 text-center">
                            <button onClick={() => downloadPDF(props.dataContract.documentPath)} className="btn btn-sm btn-primary me-2">
                                {t('RightSidebarContraclList.funder_View_Placement_Agreement')}
                            </button>
                        </div>
                        }

{ ((props.dataContract.socialWorkerName !== "" && props.dataContract.socialWorkerName !== null) || (props.dataContract.socialWorkerEmailId !== "" && props.dataContract.socialWorkerEmailId !== null) || (props.dataContract.socialWorkerContactNo !== "" && props.dataContract.socialWorkerContactNo !== null) || (props.dataContract.socialWorkerAddress !== "" && props.dataContract.socialWorkerAddress !== null))  &&
                        <>
                        <p className='purple-text mt-3 mb-2 bold'> {t('RightSidebarContraclList.social_detail')}</p>
                        {(props.dataContract.socialWorkerName !== "" && props.dataContract.socialWorkerName !== null) && 
                        <p className="mb-1">
                                <label>{t('RightSidebarContraclList.social_name')}:</label>{' '}
                                <span className="bold">
                                    {props.dataContract.socialWorkerName || ''}
                                </span>
                            </p>
                            }
                            {(props.dataContract.socialWorkerEmailId !== "" && props.dataContract.socialWorkerEmailId !== null) && 
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.social_email')}:</label>{' '}
                                <span className="bold">
                                    {props.dataContract.socialWorkerEmailId || ''}
                                </span>
                            </p>
                            }
                            {(props.dataContract.socialWorkerContactNo !== "" && props.dataContract.socialWorkerContactNo !== null) && 
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.social_phone')}:</label>{' '}
                                <span className="bold">
                                    {props.dataContract.socialWorkerContactNo || ''}
                                </span>
                            </p>
                            }
                            {(props.dataContract.socialWorkerAddress !== "" && props.dataContract.socialWorkerAddress !== null) && 
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.social_address')}:</label>{' '}
                                <span className="bold">
                                    {props.dataContract.socialWorkerAddress || ''}
                                </span>
                            </p>
                            }
                            </>
                            }
                    </div>
                </div>
            </div>
            <RenderModalContainer>
                 {showNoteModal && <NoteContainer  onClose={toggleNote} title={props.dataContract.funderName != null ?props.dataContract.funderName+"'s Notes" : t('RightSidebarContraclList.funder_note')} setFunderNote={funderNote} isReadonly={true}/> }
             </RenderModalContainer>
        </div>
    );
};

export default ReservedContractListView;
