import React from 'react';
import SupportWorkerView from './SupportWorkerView';

const SupportWorkerContainer = (props: any) => {
    return (
        <div>
            <SupportWorkerView orgName={props.orgName} supportWorkerFormValid={props.supportWorkerFormValid} supportWorkerDataChange={props.supportWorkerDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default SupportWorkerContainer;