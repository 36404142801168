import React from 'react';
import ServiceReferralSidebarView from './ServiceReferralSidebarView';

const ServiceReferralSidebarContainer = () => {
    return (
        <div>
            <ServiceReferralSidebarView />
        </div>
    );
};

export default ServiceReferralSidebarContainer;