import React, { useEffect, useRef, useState } from 'react';
import AdmitView from './admitreserveView';
import { RootState, store } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientReservedData, saveAdmissionData, saveDraftData } from '../../../../../../service/dashboard-service';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import swal from 'sweetalert';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { getListVacantBeds } from '../../../../../../service/transfer-service';
import warning from '../../../../../../assets/images/warning.png';
import { coreFeesType } from '../../../../../../utils/constant';
import { removePatientDraftAction } from '../../../../../../store/dashboard/draftPatientData';
import { removeSplitFundingAction } from '../../../../../../store/splitFunding/splitFundingReducer';
import { removeFunderInformationAction } from '../../../../../../store/dashboard/getFunderReducer';
import { saveLeadDraft } from '../../../../../../service/lead-patient';

const AdmitContainer = (props: any) => {
    var funderlist: any = [];
    var planlist: any = [];
    var fundersdata: any = [];
    const [isValid, setisValid] = useState(false);
    const [isValidDraft, setisValidDraft] = useState(false);
    const [serviceError, setserviceError] = useState<any>('');
    const [admissionError, setadmissionError] = useState<any>('');
    const [servicesData, setserviceData] = useState<any>('');
    const [admissionData, setadmissionData] = useState<any>('');
    const [applicableerror, setapplicableerror] = useState<any>('');
    const [applicabledata, setapplicabledata] = useState<any>('');
    const [contractdata, setcontractdata] = useState<any>('');
    const [contracterror, setcontracterror] = useState<any>('');
    const [contractlisterror, setcontractlisterror] = useState<any>('');
    const [deletedfunder, setdeletedfunder] = useState<any>([]);
    const [issplitdone, setsplitdone] = useState<boolean>(false); // Janak had changed defalut value from true to false
    const [deleteddocs, setdeleteddocs] = useState<any>([]);
    const [isvoid, setisvoid] = useState<boolean>(false);
    const [contractfunder, setcontractfunder] = useState<any>();
    const dispatch = useDispatch();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusVacantBedReasons, DataVacantBedReasons } = useSelector((state: RootState) => state.vacantBed);
    const [listVacantBeds, setListVacantBeds] = useState<any>([]);
    const [bed, setBed] = useState<any>();
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { StatusPatientDetail, DataPatientDetail } = useSelector((state: RootState) => state.patientDetails);
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [bedName, setbedName] = useState('');
    const [validateSelfFunder, setvalidateSelfFunder] = useState<any>();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    useEffect(() => {
        setbedName(props.bedName);
        setBed(props.bedId);
    }, [props.bedId]);

    useEffect(() => {
        if (bed === undefined) {
            setBed(DataPatientDraftInfo?.data?.admissionDetail?.bedId);
        }
    }, [bed]);

    useEffect(() => {
        
        if (contractfunder === undefined) {
            if (DataSplitFunding?.length > 0 && contractdata?.ListData?.length > 0) {
                for (let i = 0; i < DataSplitFunding?.length; i++) {
                    if (DataSplitFunding[i]?.funderlist?.length === contractdata?.ListData?.length) {
                        for (let j = 0; j < DataSplitFunding[i]?.funderlist?.length; j++) {
                            let fid = contractdata?.ListData[j].funderData.ftype === '4' ? null : Number(contractdata?.ListData[j].funderData.fFunder);
                            let tfid = DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist[j]?.funderid === null ? null : Number(DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist[j]?.funderid);
                            if (fid === tfid || (fid === null && tfid === null)) {
                                //  if(DataSplitFunding[i]?.funderlist[j]?.funderid === null ? null :Number(DataSplitFunding[i]?.funderlist[j]?.funderid) !== contractdata?.ListData[j]?.funderData?.fFunder === null ? null : Number(contractdata?.ListData[j]?.funderData?.fFunder)){
                            } else {
                                setsplitdone(false);
                                if (props.isAction !== 'reserve' && DataSplitFunding?.length > 1 && issplitdone) {
                                    swal({
                                        title: '',
                                        text: (DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null)  ? "Please define split funding again. Contract Details have been updated, please re-generate the contract accordingly." : "Please define split funding again",
                                        icon: warning,
                                        dangerMode: true
                                    }).then(async (confirm: any) => {});
                                }
                                break;
                            }
                        }
                    } else {
                        if (DataPatientDraftInfo?.data?.contractDetails.length !== contractdata?.ListData?.length) {
                            if (contractdata?.ListData?.length === 1) {
                                // dispatch(removeSplitFundingAction());
                                setsplitdone(false);
                                if (props.isAction !== 'reserve' && issplitdone) {
                                swal({
                                    title: '',
                                    text: (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null) ? 'Full amount is applied to the single funder. Contract Details have been updated, please re-generate the contract accordingly.' : 'Full amount is applied to the single funder',
                                    icon: warning,
                                    dangerMode: true
                                }).then(async (confirm: any) => {});
                            }
                            } else {
                                setsplitdone(false);
                                if (props.isAction !== 'reserve' && issplitdone) {
                                    swal({
                                        title: '',
                                        text:  (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null) ? "Please define split funding again. Contract Details have been updated, please re-generate the contract accordingly." : "Please define split funding again",
                                        icon: warning,
                                        dangerMode: true
                                    }).then(async (confirm: any) => {});
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if (DataSplitFunding?.length > 0 && contractfunder?.ListData?.length > 0) {
                for (let i = 0; i < DataSplitFunding?.length; i++) {
                    if (DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist?.length === contractfunder?.ListData?.filter((val: any) => val.status !== 'Voided' && val.status !== 'Cancelled').length) {
                        console.log('in 1');
                        for (let j = 0; j < DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist?.length; j++) {
                            let fid = contractfunder?.ListData[j].funderData.ftype === '4' ? null : Number(contractfunder?.ListData[j].funderData.fFunder);
                            let tfid = DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist[j]?.funderid === null ? null : Number(DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist[j]?.funderid);
                            if (fid === tfid || (fid === null && tfid === null)) {
                                //  if(DataSplitFunding[i]?.funderlist[j]?.funderid === null ? null :Number(DataSplitFunding[i]?.funderlist[j]?.funderid) !== contractdata?.ListData[j]?.funderData?.fFunder === null ? null : Number(contractdata?.ListData[j]?.funderData?.fFunder)){
                                // setsplitdone(true);
                            } else {
                                setsplitdone(false);
                                if (props.isAction !== 'reserve' && DataSplitFunding?.length > 1 && issplitdone && DataSplitFunding[1]?.funderlist?.length > 1) {
                                    console.log('Please define split funding again');
                                    swal({
                                        title: '',
                                        text:  (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null) ? "Please define split funding again. Contract Details have been updated, please re-generate the contract accordingly." : "Please define split funding again",
                                        icon: warning,
                                        dangerMode: true
                                    }).then(async (confirm: any) => {});
                                }
                                break;
                            }
                        }
                    } else {
                        if (DataPatientDraftInfo?.data?.contractDetails.length !== contractfunder?.ListData?.length) {
                            if (contractfunder?.ListData?.length === 1) {
                                // dispatch(removeSplitFundingAction());
                                setsplitdone(false);
                                if (props.isAction !== 'reserve' && issplitdone) {
                                swal({
                                    title: '',
                                    text: (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null) ? 'Full amount is applied to the single funder. Contract Details have been updated, please re-generate the contract accordingly.' : 'Full amount is applied to the single funder',
                                    icon: warning,
                                    dangerMode: true
                                }).then(async (confirm: any) => {});
                            }
                            } else {
                                setsplitdone(false);
                                if (props.isAction !== 'reserve' && issplitdone) {
                                    
                                    swal({
                                        title: '',
                                        text: (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null)  ? "Please define split funding again. Contract Details have been updated, please re-generate the contract accordingly." : "Please define split funding again",
                                        icon: warning,
                                        dangerMode: true
                                    }).then(async (confirm: any) => {});
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [contractdata, DataSplitFunding, contractfunder]);
   
    useEffect(() => {
        if (!isvoid) {
            if (serviceError && admissionError && contracterror && applicableerror && contractdata !== '' && contractlisterror === 0 && bed !== undefined && bed !== 0) {
                if (props.isAction === 'admit') {
                    if (contractdata?.ListData?.filter((val: any) => val.status !== 'Voided' && val.status !== 'Cancelled').length >= 2) {
                        if (DataSplitFunding.length > 0) {
                            for (let j = 0; j < DataSplitFunding.length; j++) {
                                let corefee: number = 0;
                                corefee = applicabledata?.applicableData?.TotalDailyFee;
                                // if(applicabledata?.applicableData?.DailyOrWeekly === false){
                                //     corefee = Number(applicabledata?.applicableData?.DailyFee) * 7;
                                // }
                                // else{
                                //     corefee = Number(applicabledata?.applicableData?.DailyFee);
                                // }
                                if (Number(corefee) === Number(DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1].totalFees === undefined ? DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1].corefee : DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1].totalFees)) {
                                    if (DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1]?.funderlist?.length === contractdata?.ListData?.filter((val: any) => val.status !== 'Voided' && val.status !== 'Cancelled').length && issplitdone && DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1].planId !== -1) {
                                        setisValid(true);
                                    } else {
                                        /* old code */
                                        // if(applicabledata?.applicableData?.isSplitFunding){

                                        if (applicabledata?.applicableData?.isSplitFunding === false) {
                                            //   setisValid(false);
                                            //     if(DataSplitFunding[j].planId !== -1){
                                            if (issplitdone) {
                                                setisValid(true);
                                            } else {
                                                setisValid(false);
                                                setsplitdone(false);
                                                if (isValid) {
                                                    if (props.isAction !== 'reserve') {
                                                        swal({
                                                            title: '',
                                                            text: (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null) ? "Please define split funding again. Contract Details have been updated, please re-generate the contract accordingly." : "Please define split funding again",
                                                            icon: warning,
                                                            dangerMode: true
                                                        }).then(async (confirm: any) => {});
                                                    }
                                                }

                                            }
                                            // }
                                            // else{
                                            //     setisValid(true);
                                            //     // if(issplitdone){
                                            //     //     setisValid(true);
                                            //     // }else{
                                            //     //     setisValid(false);
                                            //     // }
                                            // }
                                        } else {
                                            setisValid(true);
                                        }

                                        break;
                                    }
                                }
                                // } else  if (Number(corefee) === Number(DataSplitFunding[DataSplitFunding.length === 1 ? 0 : 1].dailyCoreFee)) {
                                //     if (DataSplitFunding[j]?.funderlist?.length === contractdata?.ListData?.length && issplitdone && DataSplitFunding[j].planId !== -1) {
                                //         
                                //         setisValid(true);
                                //     } else {
                                //         
                                //         
                                //         
                                //         /* old code */
                                //         // if(applicabledata?.applicableData?.isSplitFunding){

                                //         if (applicabledata?.applicableData?.isSplitFunding === false) {
                                //             //   setisValid(false);
                                //             //     if(DataSplitFunding[j].planId !== -1){
                                //             if (issplitdone) {
                                //                 setisValid(true);
                                //             } else {
                                //                 setisValid(false);
                                //             }
                                //             // }
                                //             // else{
                                //             //     setisValid(true);
                                //             //     // if(issplitdone){
                                //             //     //     setisValid(true);
                                //             //     // }else{
                                //             //     //     setisValid(false);
                                //             //     // }
                                //             // }
                                //         } else {
                                //             setisValid(true);
                                //         }

                                //         break;
                                //     }
                                // } else if(Number(corefee) === applicabledata?.applicableData?.DailyOrWeekly === false ? Number(Number(applicabledata?.applicableData?.DailyFee) * 7) : Number(applicabledata?.applicableData?.DailyFee)){
                                //     if (DataSplitFunding[j]?.funderlist?.length === contractdata?.ListData?.length && issplitdone && DataSplitFunding[j].planId !== -1) {
                                //         
                                //         setisValid(true);
                                //     } else {
                                //         
                                //         
                                //         
                                //         /* old code */
                                //         // if(applicabledata?.applicableData?.isSplitFunding){

                                //         if (applicabledata?.applicableData?.isSplitFunding === false) {
                                //             //   setisValid(false);
                                //             //     if(DataSplitFunding[j].planId !== -1){
                                //             if (issplitdone) {
                                //                 setisValid(true);
                                //             } else {
                                //                 setisValid(false);
                                //             }
                                //             // }
                                //             // else{
                                //             //     setisValid(true);
                                //             //     // if(issplitdone){
                                //             //     //     setisValid(true);
                                //             //     // }else{
                                //             //     //     setisValid(false);
                                //             //     // }
                                //             // }
                                //         } else {
                                //             setisValid(true);
                                //         }

                                //         break;
                                //     }
                                // } 
                                else {
                                    /* old code */
                                    if (applicabledata?.applicableData?.isSplitFunding) {
                                        // if(issplitdone){
                                        setisValid(true);
                                    }  else {
                                        setisValid(false);
                                        setsplitdone(false);
                                        if (isValid && issplitdone) {
                                            if (props.isAction !== 'reserve') {
                                                swal({
                                                    title: '',
                                                    text: (DataLeadInfo?.data?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId !== 0 && DataLeadInfo?.data !== null) ? "Please define split funding again. Contract Details have been updated, please re-generate the contract accordingly." : "Please define split funding again",
                                                    icon: warning,
                                                    dangerMode: true
                                                }).then(async (confirm: any) => {});
                                            } 
                                        }
                                    }
                                }
                            }
                        } else {
                            /* old code */
                            if (applicabledata?.applicableData?.isSplitFunding) {
                                // if(issplitdone){
                                setisValid(true);
                            } else {
                                setisValid(false);
                            }
                        }
                    } else {
                        setisValid(true);
                    }
                } else {
                    setisValid(true);
                }
            } else {
                setisValid(false);
            }
        } else {
            setisValid(false);
        }
    }, [serviceError, admissionError, applicableerror, contractdata, contractlisterror, bed, contracterror, DataSplitFunding, applicabledata, issplitdone, isvoid]);
    
    
    useEffect(()=>{
       },[isvoid])
       useEffect(()=>{
       },[contractdata])
    //    useEffect(()=>{
    //     console.log("Ashish1234",contractdata);
    //    },[contractdata])
    //    useEffect(()=>{
    //     console.log("Ashish1234",contractdata);
    //    },[contractdata])
    //    useEffect(()=>{
    //     console.log("Ashish1234",contractdata);
    //    },[contractdata])
    useEffect(() => {
        async function getPatientDetails() {
            const payload: any = { id: DataServiceDetail.serviceId };
            await getListVacantBeds(payload);
        }
        getPatientDetails();
    }, [DataLogin]);

    useEffect(() => {
        if (StatusVacantBedReasons === true) {
            setListVacantBeds(DataVacantBedReasons.data);
        }
    }, [StatusVacantBedReasons, DataVacantBedReasons]);

    useEffect(() => {
        if (servicesData.FullName && (servicesData.NhsId || servicesData.LocalId)) {
            setisValidDraft(true);
        } else {
            setisValidDraft(false);
        }
    }, [servicesData]);

    const serviceData = (service: any) => {
        
        setserviceData(service);
    };
    const getAdmissionData = (admission: any) => {
        setadmissionData(admission);
    };
    const getContarctdata = (contract: any) => {
        setcontractdata(contract);
    };

    const getallvoid = (isvoid: any) => {
        
        setisvoid(isvoid);
    };

    const getReserveAdmissionData = (reserveadmission: any) => {};
    const serviceerrordata = (errors: any) => {
        setserviceError(errors);
    };
    const getadmissionerror = (admissionerror: any) => {
        setadmissionError(admissionerror);
    };
    const getapplicabledata = (applicabledata: any) => {
        setapplicabledata(applicabledata);
    };

    const getapplicableerror = (error: any) => {
        setapplicableerror(error);
    };

    const getcontracterror = (cerror: any) => {
        setcontracterror(cerror);
    };

    const getfundelisterror = (error: any) => {
        setcontractlisterror(Object.keys(error).length);
    };

    const getsplitchange = (data: boolean) => {
        setsplitdone(data);
    };

    const deletefunderlist = (deletedlist: any) => {
        setdeletedfunder([...deletedfunder, deletedlist]);
    };

    const deleteddoc = (docs: any) => {
        setdeleteddocs([...deleteddocs, docs]);
    };

    const getcontractfunder = (data: any) => {
        setcontractfunder(data);
    };
    const checkSelfFunder = (e: any) => {
        setvalidateSelfFunder(e);
    };

    useEffect(() => {}, [deletedfunder]);

    const handlesubmit = () => {
        alertHandler(
            props.isAction === 'admit' ? `Are you sure you want to admit the patient to Bed ${bedName}?` : `Are you sure you want to reserve the patient to Bed ${bedName}?`,
            props.isAction + 'add',
            false
        );
    };

    const handleupdate = () => {
        alertHandler(
            props.isAction === 'admit'
                ? `${
                      DataPatientDraftInfo?.data?.isAllInvoiceCancelled === 1 ? 'Manual invoices will have to be created against the cancelled invoices.' : ''
                  } Are you sure you want to update the patient admission information in Bed ${bedName}?`
                : '',
            props.isAction + 'add',
            true
        );
    };

    async function getpatientdata(pid: any) {
        const payload: any = { id: pid };
        await getPatientReservedData(payload);
    }
    const handlediscard = () => {
        swal({
            title: '',
            text: 'Data/Information entered will be lost. Are you sure you want to discard the changes done?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                dispatch(removePatientDraftAction());
                getpatientdata(DataPatientDetail?.data?.serviceUserDetails?.patientId).then((res: any) => {
                    swal('Changes discarded successfully.', {
                        icon: 'success',
                        dangerMode: true
                    });
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    dispatch(setNavigationAction(obj));
                });
            }
        });
    };

    const updateAdmission = () => {
        funderlist = [];
        if (contractdata !== '' && contractdata.ListData.length > 0) {
            let aId = DataPatientDraftInfo?.data?.contractDetails || '';
            if (deletedfunder.length > 0) {
                
                
                aId = [];
                // for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails.length; i++) {
                //     for (let j = 0; j < deletedfunder.length; j++) {
                //         if (DataPatientDraftInfo?.data?.contractDetails[i].acdid !== deletedfunder[j].acdid) {
                //             aId.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                //         }
                //     }
                for (let i = 0; i < contractdata.ListData.length; i++) {
                    if (contractdata.ListData[i].funderData.ftype === '4') {
                        let funderexists = DataPatientDraftInfo?.data?.contractDetails?.find((val: any) => {
                            if (val.acdid === contractdata.ListData[i].funderData?.acdid) {
                                return val;
                            }
                        });
                        aId.push(funderexists);
                    } else {
                        let funderexists = DataPatientDraftInfo?.data?.contractDetails?.find((val: any) => {
                            if (val.acdid === Number(contractdata.ListData[i].funderData.acdid)) {
                                return val;
                            }
                        });
                        aId.push(funderexists);
                    }
                }
            }
            for (let i = 0; i < contractdata.ListData.length; i++) {
                let data: any = {};
                data.acdid = aId[i]?.acdid || 0;
                data.funderId = contractdata.ListData[i].funderData.ftype === '4' ? null : Number(contractdata.ListData[i].funderData.fFunder);
                data.funderCountryCode = '+44';
                data.funderPhoneNo = contractdata.ListData[i].funderData.fContactNo;
                data.funderEmail = contractdata.ListData[i].funderData.fEmailId;
                data.funderAddressLine1 = contractdata.ListData[i].funderData.fAddress1;
                data.funderCountryId = 0;
                data.funderCityId = Number(contractdata.ListData[i].funderData.fCity);
                data.funderPobox = contractdata.ListData[i].funderData.fPoBox;
                data.commissionerName = contractdata.ListData[i].commisionerData.cFunder;
                data.commissionerCountryCode = '+44';
                data.commissionerPhonNo = contractdata.ListData[i].commisionerData.cContactNo;
                data.commissionerEmail = contractdata.ListData[i].commisionerData.cEmailId;
                data.commissionerAddressLine1 = contractdata.ListData[i].commisionerData.cAddress1;
                data.commissionerCountryId = 0;
                data.commissionerCityId = contractdata.ListData[i].commisionerData.cCity !== '' ? Number(contractdata.ListData[i].commisionerData.cCity) : null;
                data.commissionerPobox = contractdata.ListData[i].commisionerData.cPoBox;
                data.documentPath = contractdata.ListData[i].funderData.filepath;
                data.documentName = contractdata.ListData[i].funderData.filename;
                data.isDelete = 0;
                data.isSelfFunded = contractdata.ListData[i].funderData.ftype === '4' ? 1 : 0;
                data.socialWorkerName = contractdata.ListData[i].commisionerData.sFunder;
                data.socialWorkerEmailId = contractdata.ListData[i].commisionerData.sEmailId;
                data.socialWorkerContactNo = contractdata.ListData[i].commisionerData.sContactNo;
                data.socialWorkerAddress = contractdata.ListData[i].commisionerData.sAddress1;
                data.socialWorkerCityId = contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null;
                data.socialWorkerCountryId = null;
                data.socialWorkerPobox = contractdata.ListData[i].commisionerData.sPoBox;

                data.suid = contractdata.ListData[i]?.funderData?.fsuid;
                data.note = contractdata.ListData[i]?.funderData?.fnote;
                data.pO_Reference = contractdata.ListData[i]?.funderData?.fpo;
                let docfind = deleteddocs?.find((val: any) => {
                    if (val.pindex === i) {
                        return val;
                    }
                });
                if (docfind !== undefined) {
                    let temparr = [];
                    for (let k = 0; k < deleteddocs.length; k++) {
                        if (deleteddocs[k].pindex === i) {
                            let data = {
                                contractDocumentId: deleteddocs[k].contractDocumentId,
                                filePath: deleteddocs[k].filePath,
                                fileName: deleteddocs[k].fileName,
                                attachmentType: 1,
                                isDelete: 1
                            };
                            temparr.push(data);
                        }
                    }
                    data.contractAttachmentModels = temparr;
                } else {
                    data.contractAttachmentModels = contractdata.ListData[i].funderData?.contractAttachmentModels;
                }
                funderlist.push(data);
            }
        }

        if (deletedfunder.length > 0) {
            for (let i = 0; i < deletedfunder.length; i++) {
                let data = {
                    acdid: deletedfunder[i]?.acdid || 0,
                    funderId: deletedfunder[i]?.isSelfFunded === 1 ? null : Number(deletedfunder[i]?.funderId),
                    funderCountryCode: '+44',
                    funderPhoneNo: deletedfunder[i]?.funderPhoneNo,
                    funderEmail: deletedfunder[i]?.funderEmail,
                    funderAddressLine1: deletedfunder[i]?.funderAddressLine1,
                    funderCountryId: 0,
                    funderCityId: Number(deletedfunder[i]?.funderCityId),
                    funderPobox: deletedfunder[i]?.funderPobox,
                    commissionerName: deletedfunder[i]?.commissionerName,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: deletedfunder[i]?.commissionerPhonNo,
                    commissionerEmail: deletedfunder[i]?.commissionerEmail,
                    commissionerAddressLine1: deletedfunder[i]?.commissionerAddressLine1,
                    commissionerCountryId: 0,
                    commissionerCityId: deletedfunder[i]?.commissionerCityId !== '' ? Number(deletedfunder[i]?.commissionerCityId) : null,
                    commissionerPobox: deletedfunder[i]?.commissionerPobox,
                    documentPath: deletedfunder[i]?.documentPath,
                    documentName: deletedfunder[i]?.documentName,
                    isDelete: 1,
                    isSelfFunded: deletedfunder[i]?.isSelfFunded === 1 ? 1 : 0,
                    socialWorkerName: deletedfunder[i]?.socialWorkerName,
                    socialWorkerEmailId: deletedfunder[i]?.socialWorkerEmailId,
                    socialWorkerContactNo: deletedfunder[i]?.socialWorkerContactNo,
                    socialWorkerAddress: deletedfunder[i]?.socialWorkerAddress,
                    socialWorkerCityId: deletedfunder[i]?.socialWorkerCityId !== '' ? Number(deletedfunder[i]?.socialWorkerCityId) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: deletedfunder[i]?.socialWorkerPobox
                };
                if (data.acdid !== 0) {
                    funderlist.push(data);
                }
            }
        }

        if (DataSplitFunding.length > 0) {
            for (let i = 0; i < DataSplitFunding.length; i++) {
                let obj: any = {};
                fundersdata = [];
                obj.planId = DataSplitFunding[i].planId;
                obj.weeklyCharge = DataSplitFunding[i].corefee;
                obj.StartDate = DataSplitFunding[i].admissiondate ? window.$utils.dateFormatDDMMYYYY(DataSplitFunding[i].admissiondate) : '';
                obj.endDate = DataSplitFunding[i].enddate ? window.$utils.dateFormatDDMMYYYY(DataSplitFunding[i].enddate) : '';
                obj.dailyCoreFee = Number(applicabledata?.applicableData?.DailyFee);
                obj.dailyCoreFeeType = !applicabledata?.applicableData?.DailyOrWeekly ? 1 : 2;
                obj.continenceCareFeeType = !applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee ? 1 : 2;
                
                // if invoice is not created then calculate continence and fnc fee
                if(DataPatientDraftInfo?.data?.isInvoiceCreated === 0){
                    obj.continenceCareFee = DataSplitFunding[i].continenceCareFeeType !== undefined ? (DataSplitFunding[i].continenceCareFee === undefined || DataSplitFunding[i].continenceCareFee === "NaN") ? null : DataSplitFunding[i].continenceCareFeeType === 1 ? Number((Number(DataSplitFunding[i].continenceCareFee) * 7) / 7).toFixed(2) : Number(DataSplitFunding[i].continenceCareFee).toFixed(2) : (DataSplitFunding[i].continenceCareFee === undefined || DataSplitFunding[i].continenceCareFee === "NaN") ? null : applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee === false ? Number(Number(DataSplitFunding[i].continenceCareFee)  / 7).toFixed(2) : Number(DataSplitFunding[i].continenceCareFee).toFixed(2);
                
                    obj.fncFee = DataSplitFunding[i].fncFeeType !== undefined ? (DataSplitFunding[i].fncFee === undefined || DataSplitFunding[i].fncFee === "NaN") ? null : DataSplitFunding[i].fncFeeType === 1 ? Number((Number(DataSplitFunding[i].fncFee) * 7) / 7).toFixed(2) : Number(DataSplitFunding[i].fncFee).toFixed(2) : (DataSplitFunding[i].fncFee === undefined || DataSplitFunding[i].fncFee === "NaN") ? null : applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee === false ? Number(Number(DataSplitFunding[i].fncFee)  / 7).toFixed(2) : Number(DataSplitFunding[i].fncFee).toFixed(2);
                } 
                // if invoice is created then calculate continence and fnc fee
                else{
                     
                    // if actual continence fee is same as state continence fee and actual continenece fee is not undefined
                    if(Number(DataSplitFunding[i].continenceCareFee) === Number(DataSplitFunding[i].acontinenceCareFee) && DataSplitFunding[i].acontinenceCareFee !== undefined){
                        obj.continenceCareFee = (DataSplitFunding[i].continenceCareFee === undefined || DataSplitFunding[i].continenceCareFee === "NaN") ? null : DataSplitFunding[i].continenceCareFee;
                    }
                    // if actual continence fee is not same as state continence fee and actual continenece fee is not undefined
                    else if(Number(DataSplitFunding[i].continenceCareFee) !== Number(DataSplitFunding[i].acontinenceCareFee) && DataSplitFunding[i].acontinenceCareFee !== undefined){
                        obj.continenceCareFee = (DataSplitFunding[i].continenceCareFee === undefined || DataSplitFunding[i].continenceCareFee === "NaN") ? null :  (DataSplitFunding[i].continenceCareFeeType === 1) ? Number(Number(DataSplitFunding[i].continenceCareFee) / 7).toFixed(2) : Number(DataSplitFunding[i].continenceCareFee).toFixed(2);
                    } 
                    // if weekly continence fee is available
                    else{
                        obj.continenceCareFee = (DataSplitFunding[i].continenceCareFee === undefined || DataSplitFunding[i].continenceCareFee === "NaN") ? null :  (DataSplitFunding[i].continenceCareFeeType === 1) ? Number(Number(DataSplitFunding[i].continenceCareFee) / 7).toFixed(2) : Number(DataSplitFunding[i].continenceCareFee).toFixed(2)
                    }
                    
                      // if actual fnc fee is same as state fnc fee and actual fnc fee is not undefined
                    if(Number(DataSplitFunding[i].fncFee) === Number(DataSplitFunding[i].afncFee) && DataSplitFunding[i].afncFee !== undefined){
                        obj.fncFee =(DataSplitFunding[i].fncFee === undefined || DataSplitFunding[i].fncFee === "NaN") ? null : DataSplitFunding[i].fncFee;
                    } 
                      // if actual fnc fee is not same as state fnc fee and actual fnc fee is not undefined
                    else if(Number(DataSplitFunding[i].fncFee) !== Number(DataSplitFunding[i].afncFee) && DataSplitFunding[i].afncFee !== undefined){
                        obj.fncFee =(DataSplitFunding[i].fncFee === undefined || DataSplitFunding[i].fncFee === "NaN") ? null : DataSplitFunding[i].fncFeeType === 1 ? Number(Number(DataSplitFunding[i].fncFee) / 7).toFixed(2) : Number(DataSplitFunding[i].fncFee).toFixed(2);
                    } 
                    // if weekly fnc fee is available
                    else{
                        obj.fncFee =(DataSplitFunding[i].fncFee === undefined || DataSplitFunding[i].fncFee === "NaN") ? null : DataSplitFunding[i].fncFeeType === 1 ? Number(Number(DataSplitFunding[i].fncFee) / 7).toFixed(2) : Number(DataSplitFunding[i].fncFee).toFixed(2);
                    }
                
                   
                }
               
                obj.totalAdditionalServiceFee=Number(DataSplitFunding[i].totalAdditionalServiceFee);
                obj.fncFeeType = !applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee ? 1 : 2;

                for (let j = 0; j < DataSplitFunding[i].funderlist.length; j++) {
                    fundersdata.push({
                        planDetailId: DataSplitFunding[i].funderlist[j].planDetailId,
                        funderid: DataSplitFunding[i].funderlist[j].funderid === null ? null : Number(DataSplitFunding[i].funderlist[j].funderid),
                        amountType: DataSplitFunding[i].funderlist[j].ispercentage ? 2 : 1,
                        proportion: Number(DataSplitFunding[i].funderlist[j].proportion),
                        proportionValue: Number(DataSplitFunding[i].funderlist[j].proportionvalue),
                        isApplicable: DataSplitFunding[i].funderlist[j].isapplicable ? 0 : 1,
                        isAutoPay: DataSplitFunding[i].funderlist[j].isautopay ? 0 : 1
                    });
                }

                obj.planDetails = fundersdata;
                planlist = [...planlist, obj];
            }
        }

        const updatepayload = {
            patientDetail: {
                serviceId: DataServiceDetail.serviceId,
                draftPatientId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.patientDetail?.draftPatientId) : 0,
                patientId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.patientDetail?.patientId) : 0,
                patientName: servicesData && servicesData.FullName ? servicesData.FullName : '',
                patientTypeId: admissionData.length > 0 && admissionData[2].patientType !== '' ? Number(admissionData[2].patientType) : 0,
                dateOfBirth: servicesData && servicesData.DOB ? window.$utils.dateFormatDDMMYYYY(servicesData.DOB) : '',
                gender: servicesData && servicesData.gender && Number(servicesData.gender) > 0 ? Number(servicesData.gender) : 1,
                nhsId: servicesData && servicesData.NhsId ? servicesData.NhsId : '',
                localId: servicesData && servicesData.LocalId ? servicesData.LocalId : '',
                contactNo: servicesData && servicesData.ContactNo ? servicesData.ContactNo : '',
                email: servicesData && servicesData.EmailId ? servicesData.EmailId : '',
                addressLine1: servicesData && servicesData.Address1 ? servicesData.Address1 : '',
                addressLine2: servicesData && servicesData.Address2 ? servicesData.Address2 : '',
                countryId: 0,
                cityId: servicesData && servicesData.City ? Number(servicesData.City) : null,
                pobox: servicesData && servicesData.PoBox ? servicesData.PoBox : '',
                kinName: servicesData && servicesData.kFullName ? servicesData.kFullName : '',
                kinGender: servicesData && servicesData.kgender ? Number(servicesData.kgender) : 0,
                kinRelationshipId: servicesData && servicesData.kRelationship ? Number(servicesData.kRelationship) : null,
                kinContactNo: servicesData && servicesData.kContactNo ? servicesData.kContactNo : '',
                kinAddress: servicesData && servicesData.kAddress ? servicesData.kAddress : '',
                sameAsServiceAddress: servicesData && servicesData.sameasservice ? 1 : 0
            },
            admissionDetail: {
                admissionId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.admissionDetail?.admissionId) : 0,
                bedId: Number(bed),
                estStartDate: props.isAction === 'reserve' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

                admissionDate: props.isAction === 'admit' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

                estDischargeDate: admissionData.length > 0 && admissionData[1].dischargeDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[1].dischargeDate) : '',

                endDate: '',
                isReserved: props.isAction === 'admit' ? 0 : 1,
                isReleased: 0,
                isTransfered: 0,
                coreFeesType: applicabledata?.applicableData?.DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                dailyCoreFee: applicabledata?.applicableData?.DailyFee ? Number(applicabledata.applicableData.DailyFee) : null,
                hourlyFee1: applicabledata?.applicableData?.AdditionalFeeH1 ? Number(applicabledata.applicableData.AdditionalFeeH1) : null,
                hourlyFee2: applicabledata?.applicableData?.AdditionalFeeH2 ? Number(applicabledata.applicableData.AdditionalFeeH2) : null,
                hourlyFee3: applicabledata?.applicableData?.AdditionalFeeH3 ? Number(applicabledata.applicableData.AdditionalFeeH3) : null,
                hourlyFee4: applicabledata?.applicableData?.AdditionalFeeH4 ? Number(applicabledata.applicableData.AdditionalFeeH4) : null,

                healthFunding: applicabledata?.applicableData?.Healthfunding ? Number(applicabledata.applicableData.Healthfunding) : 0,
                socialFunding: applicabledata?.applicableData?.Socialfunding ? Number(applicabledata.applicableData.Socialfunding) : 0,

                inclusionHours1: applicabledata?.applicableData?.Inclusion1 ? Number(applicabledata.applicableData.Inclusion1) : null,
                inclusionHours2: applicabledata?.applicableData?.Inclusion2 ? Number(applicabledata.applicableData.Inclusion2) : null,
                inclusionHours3: applicabledata?.applicableData?.Inclusion3 ? Number(applicabledata.applicableData.Inclusion3) : null,
                inclusionHours4: applicabledata?.applicableData?.Inclusion4 ? Number(applicabledata.applicableData.Inclusion4) : null,
                inclusionHoursType1: applicabledata?.applicableData?.In1DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType2: applicabledata?.applicableData?.In2DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType3: applicabledata?.applicableData?.In3DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType4: applicabledata?.applicableData?.In4DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                isInclusionHours1Included: applicabledata?.applicableData?.Inclusion1included,
                isInclusionHours2Included: applicabledata?.applicableData?.Inclusion2included,
                isInclusionHours3Included: applicabledata?.applicableData?.Inclusion3included,
                isInclusionHours4Included: applicabledata?.applicableData?.Inclusion4included,
                isSplitFundingOninvoice:  (props.isAction === 'admit' && applicabledata?.applicableData?.isSplitFunding !== undefined) ? applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1 : 0,

                continenceCareFeeType: applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee === false ? coreFeesType.Daily : coreFeesType.Weekly,
                continenceCareFee: applicabledata?.applicableData?.ContinenceCareFee ? Number(applicabledata.applicableData.ContinenceCareFee) : null,
                isContinenceFeeIncluded: applicabledata?.applicableData?.ContinenceInclude,
                fncFee: Number(applicabledata?.applicableData?.FNCFee),
                fncFeeType: !applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee ? 1 : 2,
                isFncFeeIncluded: applicabledata?.applicableData?.FNCInclude,
                totalFee: Number(applicabledata?.applicableData?.TotalDailyFee),
                totalAdditionalServiceFee:Number(applicabledata?.applicableData?.totalAdditionalfee)
            },
            planList: planlist || [],
            contractDetails: funderlist,
            isDraft: 0,
            isAdmissionUpdate: 1,
            // IsContractDetailModified : issplitdone ? 0 : 1,
            // IsContractDetailModified : props.isAction === 'admit' ? applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1 : 0,
            IsContractDetailModified:
            (props.isAction === 'admit' && applicabledata?.applicableData?.isSplitFunding !== undefined) ? applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1 : 0,
            // isSplitFunding:
            //     props.isAction === 'admit' ? (DataSplitFunding?.length === 1 && DataSplitFunding[0].funderlist.length === 1 ? 0 : applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1) : 0
        };
        console.log(DataSplitFunding);
        console.log(applicabledata);
        console.log(updatepayload);
        saveadmissionData(updatepayload);
    };

    const submit = () => {
        funderlist = [];
        planlist = [];
        if (contractdata !== '' && contractdata.ListData.length > 0) {
            let aId = DataPatientDraftInfo?.data?.contractDetails || '';

            if (deletedfunder.length > 0) {
                aId = [];
                for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails.length; i++) {
                    for (let j = 0; j < deletedfunder.length; j++) {
                        if (DataPatientDraftInfo?.data?.contractDetails[i]?.acdid !== deletedfunder[j]?.acdid) {
                            aId.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                        }
                    }
                }
            }
            for (let i = 0; i < contractdata.ListData.length; i++) {
                let data = {
                    acdid: aId[i]?.acdid || 0,
                    funderId: contractdata.ListData[i].funderData.ftype === '4' ? null : Number(contractdata.ListData[i].funderData.fFunder),
                    funderCountryCode: '+44',
                    funderPhoneNo: contractdata.ListData[i].funderData.fContactNo,
                    funderEmail: contractdata.ListData[i].funderData.fEmailId,
                    funderAddressLine1: contractdata.ListData[i].funderData.fAddress1,
                    funderCountryId: 0,
                    funderCityId: Number(contractdata.ListData[i].funderData.fCity),
                    funderPobox: contractdata.ListData[i].funderData.fPoBox,
                    commissionerName: contractdata.ListData[i].commisionerData.cFunder,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: contractdata.ListData[i].commisionerData.cContactNo,
                    commissionerEmail: contractdata.ListData[i].commisionerData.cEmailId,
                    commissionerAddressLine1: contractdata.ListData[i].commisionerData.cAddress1,
                    commissionerCountryId: 0,
                    commissionerCityId: contractdata.ListData[i].commisionerData.cCity !== '' ? Number(contractdata.ListData[i].commisionerData.cCity) : null,
                    commissionerPobox: contractdata.ListData[i].commisionerData.cPoBox,
                    documentPath: contractdata.ListData[i].funderData.filepath,
                    documentName: contractdata.ListData[i].funderData.filename,
                    isDelete: 0,
                    isSelfFunded: contractdata.ListData[i].funderData.ftype === '4' ? 1 : 0,

                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmailId: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerContactNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddress: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCityId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: contractdata.ListData[i].commisionerData.sPoBox,
                    contractAttachmentModels: contractdata.ListData[i].funderData?.contractAttachmentModels,
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                    pO_Reference: contractdata.ListData[i]?.funderData?.fpo
                };

                funderlist.push(data);
            }
        }

        if (deletedfunder.length > 0) {
            for (let i = 0; i < deletedfunder.length; i++) {
                let data = {
                    acdid: deletedfunder[i]?.acdid || 0,
                    funderId: Number(deletedfunder[i]?.funderId),
                    funderCountryCode: '+44',
                    funderPhoneNo: deletedfunder[i]?.funderPhoneNo,
                    funderEmail: deletedfunder[i]?.funderEmail,
                    funderAddressLine1: deletedfunder[i]?.funderAddressLine1,
                    funderCountryId: 0,
                    funderCityId: Number(deletedfunder[i]?.funderCityId),
                    funderPobox: deletedfunder[i]?.funderPobox,
                    commissionerName: deletedfunder[i]?.commissionerName,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: deletedfunder[i]?.commissionerPhonNo,
                    commissionerEmail: deletedfunder[i]?.commissionerEmail,
                    commissionerAddressLine1: deletedfunder[i]?.commissionerAddressLine1,
                    commissionerCountryId: 0,
                    commissionerCityId: deletedfunder[i]?.commissionerCityId !== '' ? Number(deletedfunder[i]?.commissionerCityId) : null,
                    commissionerPobox: deletedfunder[i]?.commissionerPobox,
                    documentPath: deletedfunder[i]?.documentPath,
                    documentName: deletedfunder[i]?.documentName,
                    isDelete: 1,
                    isSelfFunded: deletedfunder[i]?.isSelfFunded === 1 ? 1 : 0,
                    socialWorkerName: deletedfunder[i]?.socialWorkerName,
                    socialWorkerEmailId: deletedfunder[i]?.socialWorkerEmailId,
                    socialWorkerContactNo: deletedfunder[i]?.socialWorkerContactNo,
                    socialWorkerAddress: deletedfunder[i]?.socialWorkerAddress,
                    socialWorkerCityId: deletedfunder[i]?.socialWorkerCityId !== '' ? Number(deletedfunder[i]?.socialWorkerCityId) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: deletedfunder[i]?.socialWorkerPobox
                };
                if (data.acdid !== 0) {
                    funderlist.push(data);
                }
            }
        }

        if (DataSplitFunding.length > 0) {
            
            
            for (let i = 0; i < DataSplitFunding.length; i++) {
                let obj: any = {};
                fundersdata = [];
                obj.planId = DataSplitFunding[i].planId;
                obj.weeklyCharge = Number(DataSplitFunding[i].corefee);
                obj.StartDate = DataSplitFunding[i].admissiondate ? window.$utils.dateFormatDDMMYYYY(DataSplitFunding[i].admissiondate) : '';
                obj.endDate = DataSplitFunding[i].enddate ? window.$utils.dateFormatDDMMYYYY(DataSplitFunding[i].enddate) : '';
                obj.dailyCoreFee = Number(applicabledata?.applicableData?.DailyFee);
                obj.dailyCoreFeeType = !applicabledata?.applicableData?.DailyOrWeekly ? 1 : 2;
                obj.continenceCareFeeType = !applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee ? 1 : 2;
                obj.fncFeeType = !applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee ? 1 : 2;
                obj.continenceCareFee = (DataSplitFunding[i].continenceCareFee === undefined || DataSplitFunding[i].continenceCareFee === "NaN") ? null : applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee === false ? Number(Number(DataSplitFunding[i].continenceCareFee) / 7).toFixed(2) : Number(DataSplitFunding[i].continenceCareFee).toFixed(2);
                
                obj.fncFee = (DataSplitFunding[i].fncFee === undefined || DataSplitFunding[i].fncFee === "NaN") ? null : applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee === false ? Number(Number(DataSplitFunding[i].fncFee) / 7).toFixed(2) : Number(DataSplitFunding[i].fncFee).toFixed(2);
                obj.totalAdditionalServiceFee=Number(DataSplitFunding[i].totalAdditionalServiceFee);
                for (let j = 0; j < DataSplitFunding[i].funderlist.length; j++) {
                    fundersdata.push({
                        planDetailId: DataSplitFunding[i].funderlist[j].planDetailId,
                        funderid: DataSplitFunding[i].funderlist[j].funderid === null ? null : Number(DataSplitFunding[i].funderlist[j].funderid),
                        amountType: DataSplitFunding[i].funderlist[j].ispercentage ? 2 : 1,
                        proportion: Number(DataSplitFunding[i].funderlist[j].proportion),
                        proportionValue: Number(DataSplitFunding[i].funderlist[j].proportionvalue),
                        isApplicable: DataSplitFunding[i].funderlist[j].isapplicable ? 0 : 1,
                        isAutoPay: DataSplitFunding[i].funderlist[j].isautopay ? 0 : 1
                    });
                }

                obj.planDetails = fundersdata;
                planlist = [...planlist, obj];
            }
        }

        const submitpayload = {
            patientDetail: {
                serviceId: DataServiceDetail.serviceId,
                draftPatientId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.patientDetail?.draftPatientId) : 0,
                patientId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.patientDetail?.patientId) : 0,
                patientName: servicesData && servicesData.FullName ? servicesData.FullName : '',
                patientTypeId: admissionData.length > 0 && admissionData[2].patientType !== '' ? Number(admissionData[2].patientType) : 0,
                dateOfBirth: servicesData && servicesData.DOB ? window.$utils.dateFormatDDMMYYYY(servicesData.DOB) : '',
                gender: servicesData && servicesData.gender && Number(servicesData.gender) > 0 ? Number(servicesData.gender) : 1,
                nhsId: servicesData && servicesData.NhsId ? servicesData.NhsId : '',
                localId: servicesData && servicesData.LocalId ? servicesData.LocalId : '',
                contactNo: servicesData && servicesData.ContactNo ? servicesData.ContactNo : '',
                email: servicesData && servicesData.EmailId ? servicesData.EmailId : '',
                addressLine1: servicesData && servicesData.Address1 ? servicesData.Address1 : '',
                addressLine2: servicesData && servicesData.Address2 ? servicesData.Address2 : '',
                countryId: 0,
                cityId: servicesData && servicesData.City ? Number(servicesData.City) : null,
                pobox: servicesData && servicesData.PoBox ? servicesData.PoBox : '',
                kinName: servicesData && servicesData.kFullName ? servicesData.kFullName : '',
                kinGender: servicesData && servicesData.kgender ? Number(servicesData.kgender) : 0,
                kinRelationshipId: servicesData && servicesData.kRelationship ? Number(servicesData.kRelationship) : null,
                kinContactNo: servicesData && servicesData.kContactNo ? servicesData.kContactNo : '',
                kinAddress: servicesData && servicesData.kAddress ? servicesData.kAddress : '',
                sameAsServiceAddress: servicesData && servicesData.sameasservice ? 1 : 0
            },
            admissionDetail: {
                admissionId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.admissionDetail?.admissionId) : 0,
                bedId: Number(bed),
                estStartDate: props.isAction === 'reserve' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

                admissionDate: props.isAction === 'admit' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

                estDischargeDate: admissionData.length > 0 && admissionData[1].dischargeDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[1].dischargeDate) : '',

                endDate: '',
                isReserved: props.isAction === 'admit' ? 0 : 1,
                isReleased: 0,
                isTransfered: 0,
                coreFeesType: applicabledata?.applicableData?.DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                dailyCoreFee: applicabledata?.applicableData?.DailyFee ? Number(applicabledata.applicableData.DailyFee) : null,
                hourlyFee1: applicabledata?.applicableData?.AdditionalFeeH1 ? Number(applicabledata.applicableData.AdditionalFeeH1) : null,
                hourlyFee2: applicabledata?.applicableData?.AdditionalFeeH2 ? Number(applicabledata.applicableData.AdditionalFeeH2) : null,
                hourlyFee3: applicabledata?.applicableData?.AdditionalFeeH3 ? Number(applicabledata.applicableData.AdditionalFeeH3) : null,
                hourlyFee4: applicabledata?.applicableData?.AdditionalFeeH4 ? Number(applicabledata.applicableData.AdditionalFeeH4) : null,

                healthFunding: applicabledata?.applicableData?.Healthfunding ? Number(applicabledata.applicableData.Healthfunding) : 0,
                socialFunding: applicabledata?.applicableData?.Socialfunding ? Number(applicabledata.applicableData.Socialfunding) : 0,

                inclusionHours1: applicabledata?.applicableData?.Inclusion1 ? Number(applicabledata.applicableData.Inclusion1) : null,
                inclusionHours2: applicabledata?.applicableData?.Inclusion2 ? Number(applicabledata.applicableData.Inclusion2) : null,
                inclusionHours3: applicabledata?.applicableData?.Inclusion3 ? Number(applicabledata.applicableData.Inclusion3) : null,
                inclusionHours4: applicabledata?.applicableData?.Inclusion4 ? Number(applicabledata.applicableData.Inclusion4) : null,
                inclusionHoursType1: applicabledata?.applicableData?.In1DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType2: applicabledata?.applicableData?.In2DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType3: applicabledata?.applicableData?.In3DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType4: applicabledata?.applicableData?.In4DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                isInclusionHours1Included: applicabledata?.applicableData?.Inclusion1included,
                isInclusionHours2Included: applicabledata?.applicableData?.Inclusion2included,
                isInclusionHours3Included: applicabledata?.applicableData?.Inclusion3included,
                isInclusionHours4Included: applicabledata?.applicableData?.Inclusion4included,
                isSplitFundingOninvoice:  (props.isAction === 'admit' && applicabledata?.applicableData?.isSplitFunding !== undefined) ? applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1 : 0,
                leadPoolingId: DataPatientDraftInfo?.data?.leadPoolingId ?? 0,
                isFromLeadPooling: DataPatientDraftInfo?.data?.leadPoolingId > 0 ? true : false,

                continenceCareFeeType: applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee === false ? coreFeesType.Daily : coreFeesType.Weekly,
                continenceCareFee: applicabledata?.applicableData?.ContinenceCareFee ? Number(applicabledata.applicableData.ContinenceCareFee) : null,
                isContinenceFeeIncluded: applicabledata?.applicableData?.ContinenceInclude,
                fncFee: Number(applicabledata?.applicableData?.FNCFee),
                fncFeeType: !applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee ? 1 : 2,
                isFncFeeIncluded: applicabledata?.applicableData?.FNCInclude,
                totalFee: Number(applicabledata?.applicableData?.TotalDailyFee),
                totalAdditionalServiceFee:Number(applicabledata?.applicableData?.totalAdditionalfee)
            },
            planList: props.isAction === 'admit' ? planlist : [],
            contractDetails: funderlist,
            isDraft: 0,
            isAdmissionUpdate: 0,
            IsContractDetailModified:
            (props.isAction === 'admit' && applicabledata?.applicableData?.isSplitFunding !== undefined) ? applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1 : 0,
            // isSplitFunding:
            //     props.isAction === 'admit' ? (DataSplitFunding?.length === 1 && DataSplitFunding[0].funderlist.length === 1 ? 0 : applicabledata?.applicableData?.isSplitFunding === false ? 0 : 1) : 0,
            leadPoolingId: DataPatientDraftInfo?.data?.leadPoolingId ?? 0,
            isFromLeadPooling: DataPatientDraftInfo?.data?.leadPoolingId > 0 ? true : false
        };
        
        saveadmissionData(submitpayload);
    };

    const handledraft = () => {
        alertHandler(props.isAction === 'admit' ? 'Are you sure you want to save as draft?' : 'Are you sure you want to save as draft?', props.isAction + 'draft', false);
    };

    const draft = () => {
        funderlist = [];
        let dId = DataPatientDraftInfo?.data?.contractDetails || '';
        if (contractdata !== '' && contractdata.ListData.length > 0) {
            // let did=  DataPatientDraftInfo?.data?.contractDetails.filter((val:any)=>val.dacdid !== deletedfunder)
            if (deletedfunder.length > 0) {
                dId = [];
                for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails.length; i++) {
                    for (let j = 0; j < deletedfunder.length; j++) {
                        if (DataPatientDraftInfo?.data?.contractDetails[i].dacdId !== deletedfunder[j].dacdId) {
                            dId.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                        }
                    }
                }
            }
            for (let i = 0; i < contractdata.ListData.length; i++) {
                let data = {
                    dacdid: dId[i]?.dacdId || 0,
                    dpatientId: 0,
                    funderId: contractdata.ListData[i].funderData.ftype === '4' ? null : Number(contractdata.ListData[i].funderData.fFunder),
                    funderCountryCode: '+44',
                    funderPhoneNo: contractdata.ListData[i].funderData.fContactNo,
                    funderEmail: contractdata.ListData[i].funderData.fEmailId,
                    funderAddressLine1: contractdata.ListData[i].funderData.fAddress1,
                    funderCountryId: 0,
                    funderCityId: Number(contractdata.ListData[i].funderData.fCity),
                    funderPobox: contractdata.ListData[i].funderData.fPoBox,
                    commissionerName: contractdata.ListData[i].commisionerData.cFunder,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: contractdata.ListData[i].commisionerData.cContactNo,
                    commissionerEmail: contractdata.ListData[i].commisionerData.cEmailId,
                    commissionerAddressLine1: contractdata.ListData[i].commisionerData.cAddress1,
                    commissionerCountryId: 0,
                    commissionerCityId: contractdata.ListData[i].commisionerData.cCity !== '' ? Number(contractdata.ListData[i].commisionerData.cCity) : null,
                    commissionerPobox: contractdata.ListData[i].commisionerData.cPoBox,
                    documentPath: contractdata.ListData[i].funderData.filepath,
                    documentName: contractdata.ListData[i].funderData.filename,
                    isDelete: 0,
                    isSelfFunded: contractdata.ListData[i].funderData.ftype === '4' ? 1 : 0,
                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmailId: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerContactNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddress: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCityId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: contractdata.ListData[i].commisionerData.sPoBox,
                    draftContractAttachmentModels: contractdata.ListData[0].funderData?.contractAttachmentModels != undefined ? contractdata.ListData[i].funderData?.contractAttachmentModels : [],
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                    pO_Reference: contractdata.ListData[i]?.funderData?.fpo
                };
                funderlist.push(data);
            }
        }
        if (deletedfunder.length > 0) {
            for (let i = 0; i < deletedfunder.length; i++) {
                let data = {
                    dacdid: deletedfunder[i]?.dacdId || 0,
                    dpatientId: 0,
                    funderId: Number(deletedfunder[i]?.funderId),
                    funderCountryCode: '+44',
                    funderPhoneNo: deletedfunder[i]?.funderPhoneNo,
                    funderEmail: deletedfunder[i]?.funderEmail,
                    funderAddressLine1: deletedfunder[i]?.funderAddressLine1,
                    funderCountryId: 0,
                    funderCityId: Number(deletedfunder[i]?.funderCityId),
                    funderPobox: deletedfunder[i]?.funderPobox,
                    commissionerName: deletedfunder[i]?.commissionerName,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: deletedfunder[i]?.commissionerPhonNo,
                    commissionerEmail: deletedfunder[i]?.commissionerEmail,
                    commissionerAddressLine1: deletedfunder[i]?.commissionerAddressLine1,
                    commissionerCountryId: 0,
                    commissionerCityId: deletedfunder[i]?.commissionerCityId !== '' ? Number(deletedfunder[i]?.commissionerCityId) : null,
                    commissionerPobox: deletedfunder[i]?.commissionerPobox,
                    documentPath: deletedfunder[i]?.documentPath,
                    documentName: deletedfunder[i]?.documentName,
                    isDelete: 1,
                    isSelfFunded: deletedfunder[i]?.isSelfFunded === 1 ? 1 : 0,
                    socialWorkerName: contractdata.ListData[i].commisionerData.socialWorkerName,
                    socialWorkerEmailId: contractdata.ListData[i].commisionerData.socialWorkerEmailId,
                    socialWorkerContactNo: contractdata.ListData[i].commisionerData.socialWorkerContactNo,
                    socialWorkerAddress: contractdata.ListData[i].commisionerData.socialWorkerAddress,
                    socialWorkerCityId: contractdata.ListData[i].commisionerData.socialWorkerCityId !== '' ? Number(contractdata.ListData[i].commisionerData.socialWorkerCityId) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: contractdata.ListData[i].commisionerData.socialWorkerPobox
                };
                if (data.dacdid !== 0) {
                    funderlist.push(data);
                }
            }
        }

        let draftdata = {
            draftPatientDetail: {
                dpatientId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.patientDetail?.draftPatientId) : 0,
                patientName: servicesData && servicesData.FullName ? servicesData.FullName : '',
                patientTypeId: admissionData.length > 0 && admissionData[2].patientType !== '' ? Number(admissionData[2].patientType) : 0,
                serviceId: DataServiceDetail.serviceId || 0,
                dateOfBirth: servicesData && servicesData.DOB ? window.$utils.dateFormatDDMMYYYY(servicesData.DOB) : '',
                gender: servicesData && servicesData.gender && Number(servicesData.gender) > 0 ? Number(servicesData.gender) : 1,
                nhsId: servicesData && servicesData.NhsId ? servicesData.NhsId : '',
                localId: servicesData && servicesData.LocalId ? servicesData.LocalId : '',
                contactNo: servicesData && servicesData.ContactNo ? servicesData.ContactNo : '',
                email: servicesData && servicesData.EmailId ? servicesData.EmailId : '',
                addressLine1: servicesData && servicesData.Address1 ? servicesData.Address1 : '',
                addressLine2: servicesData && servicesData.Address2 ? servicesData.Address2 : '',
                countryId: 0,
                cityId: servicesData && servicesData.City ? Number(servicesData.City) : null,
                pobox: servicesData && servicesData.PoBox ? servicesData.PoBox : '',

                estStartDate: props.isAction === 'reserve' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

                admissionDate: props.isAction === 'admit' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

                estDischargeDate: admissionData.length > 0 && admissionData[1].dischargeDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[1].dischargeDate) : '',

                endDate: '',

                isPatientCreated: 0,
                coreFeesType: applicabledata?.applicableData?.DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                dailyCoreFee: applicabledata?.applicableData?.DailyFee ? Number(applicabledata.applicableData.DailyFee) : null,
                hourlyFee1: applicabledata?.applicableData?.AdditionalFeeH1 ? Number(applicabledata.applicableData.AdditionalFeeH1) : null,
                hourlyFee2: applicabledata?.applicableData?.AdditionalFeeH2 ? Number(applicabledata.applicableData.AdditionalFeeH2) : null,
                hourlyFee3: applicabledata?.applicableData?.AdditionalFeeH3 ? Number(applicabledata.applicableData.AdditionalFeeH3) : null,
                hourlyFee4: applicabledata?.applicableData?.AdditionalFeeH4 ? Number(applicabledata.applicableData.AdditionalFeeH4) : null,

                healthFunding: applicabledata?.applicableData?.Healthfunding ? Number(applicabledata.applicableData.Healthfunding) : 0,
                socialFunding: applicabledata?.applicableData?.Socialfunding ? Number(applicabledata.applicableData.Socialfunding) : 0,

                inclusionHours1: applicabledata?.applicableData?.Inclusion1 ? Number(applicabledata.applicableData.Inclusion1) : null,
                inclusionHours2: applicabledata?.applicableData?.Inclusion2 ? Number(applicabledata.applicableData.Inclusion2) : null,
                inclusionHours3: applicabledata?.applicableData?.Inclusion3 ? Number(applicabledata.applicableData.Inclusion3) : null,
                inclusionHours4: applicabledata?.applicableData?.Inclusion4 ? Number(applicabledata.applicableData.Inclusion4) : null,
                inclusionHoursType1: applicabledata?.applicableData?.In1DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType2: applicabledata?.applicableData?.In2DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType3: applicabledata?.applicableData?.In3DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                inclusionHoursType4: applicabledata?.applicableData?.In4DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
                isInclusionHours1Included: applicabledata?.applicableData?.Inclusion1included,
                isInclusionHours2Included: applicabledata?.applicableData?.Inclusion2included,
                isInclusionHours3Included: applicabledata?.applicableData?.Inclusion3included,
                isInclusionHours4Included: applicabledata?.applicableData?.Inclusion4included,
                kinName: servicesData && servicesData.kFullName ? servicesData.kFullName : '',
                kinGender: servicesData && servicesData.kgender ? Number(servicesData.kgender) : 0,
                kinRelationshipId: servicesData && servicesData.kRelationship ? Number(servicesData.kRelationship) : null,
                kinContactNo: servicesData && servicesData.kContactNo ? servicesData.kContactNo : '',
                kinAddress: servicesData && servicesData.kAddress ? servicesData.kAddress : '',
                sameAsServiceAddress: servicesData && servicesData.sameasservice ? 1 : 0,

                continenceCareFeeType: applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee === false ? coreFeesType.Daily : coreFeesType.Weekly,
                continenceCareFee: applicabledata?.applicableData?.ContinenceCareFee ? Number(applicabledata.applicableData.ContinenceCareFee) : null,
                fncFee: Number(applicabledata?.applicableData?.FNCFee),

                fncFeeType: !applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee ? 1 : 2,
                isFncFeeIncluded: applicabledata?.applicableData?.FNCInclude,
                totalFee: Number(applicabledata?.applicableData?.TotalDailyFee),
                totalAdditionalServiceFee:Number(applicabledata?.applicableData?.totalAdditionalfee)
            },
            draftContractDetail: funderlist
        };
        savedraftData(draftdata);
    };

    async function saveadmissionData(payload: any) {
        const res = await saveAdmissionData(payload);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            dispatch(removeSplitFundingAction());
            // dispatch(removeFunderInformationAction());
            eventEmitter.emit('eventGetBedInformation');
            eventEmitter.emit('eventGetWardInformation');
            eventEmitter.emit('onAdmitReservePatient');
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    async function savedraftData(payload: any) {
        const res = await saveDraftData(payload);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('eventGetBedInformation');
            eventEmitter.emit('eventGetWardInformation');
            eventEmitter.emit('onAdmitReservePatient');
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    const alertHandler = (text: any, operation: any, isUpdate: boolean) => {
        swal({
            title: '',
            text: text,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                if (operation === props.isAction + 'add') {
                    if (isUpdate) {
                        updateAdmission();
                    } else {
                        submit();
                    }
                } else {
                    draft();
                }
            } else {
            }
        });
    };

    const saveHandler = (text: any, operation: any, isUpdate: boolean) => {
        swal({
            title: '',
            text: 'Are you sure you want to save the patient?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                savepatientdraft();
            }
        });
    };
    const setData = (data: any) => {
        setBed(data.bedId);
        props.selectedbed(data);
    };

    const savepatientdraft = () => {
        funderlist = [];
        let dId = DataPatientDraftInfo?.data?.contractDetails || '';
        if (contractdata !== '' && contractdata.ListData.length > 0) {
            if (deletedfunder.length > 0) {
                dId = [];
                for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails.length; i++) {
                    for (let j = 0; j < deletedfunder.length; j++) {
                        if (DataPatientDraftInfo?.data?.contractDetails[i].dacdId !== deletedfunder[j].dacdId) {
                            dId.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                        }
                    }
                }
            }
            for (let i = 0; i < contractdata.ListData.length; i++) {
                let data = {
                    loadPoolingId: contractdata.ListData[i].funderData?.fLeadPoolingDetailsId,
                    dacdid: dId[i]?.dacdId || 0,
                    dpatientId: 0,
                    leadFunderId: contractdata.ListData[i].funderData.fleadFunderId,
                    funderId: contractdata.ListData[i].funderData.ftype === '4' ? null : Number(contractdata.ListData[i].funderData.fFunder),
                    funderCountryCode: '+44',
                    funderPhoneNo: contractdata.ListData[i].funderData.fContactNo,
                    funderEmail: contractdata.ListData[i].funderData.fEmailId,
                    funderAddressLine1: contractdata.ListData[i].funderData.fAddress1,
                    funderCountryId: 0,
                    funderCityId: Number(contractdata.ListData[i].funderData.fCity),
                    funderPobox: contractdata.ListData[i].funderData.fPoBox,
                    commissionerName: contractdata.ListData[i].commisionerData.cFunder,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: contractdata.ListData[i].commisionerData.cContactNo,
                    commissionerEmail: contractdata.ListData[i].commisionerData.cEmailId,
                    commissionerAddressLine1: contractdata.ListData[i].commisionerData.cAddress1,
                    commissionerCountryId: 0,
                    commissionerCityId: contractdata.ListData[i].commisionerData.cCity !== '' ? Number(contractdata.ListData[i].commisionerData.cCity) : null,
                    commissionerPobox: contractdata.ListData[i].commisionerData.cPoBox,
                    documentPath: contractdata.ListData[i].funderData.filepath,
                    documentName: contractdata.ListData[i].funderData.filename,
                    isDelete: 0,
                    isSelfFunded: contractdata.ListData[i].funderData.ftype === '4' ? 1 : 0,
                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmailId: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerContactNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddress: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCityId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: contractdata.ListData[i].commisionerData.sPoBox,
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                    pO_Reference: contractdata.ListData[i]?.funderData?.fpo
                };
                funderlist.push(data);
            }
        }
        if (deletedfunder.length > 0) {
            for (let i = 0; i < deletedfunder.length; i++) {
                let data = {
                    dacdid: deletedfunder[i]?.dacdId || 0,
                    patientId: 0,
                    leadFunderId: deletedfunder[i]?.leadFunderId,
                    funderId: Number(deletedfunder[i]?.funderId),
                    funderCountryCode: '+44',
                    funderPhoneNo: deletedfunder[i]?.funderPhoneNo,
                    funderEmail: deletedfunder[i]?.funderEmail,
                    funderAddressLine1: deletedfunder[i]?.funderAddressLine1,
                    funderCountryId: 0,
                    funderCityId: Number(deletedfunder[i]?.funderCityId),
                    funderPobox: deletedfunder[i]?.funderPobox,
                    commissionerName: deletedfunder[i]?.commissionerName,
                    commissionerCountryCode: '+44',
                    commissionerPhonNo: deletedfunder[i]?.commissionerPhonNo,
                    commissionerEmail: deletedfunder[i]?.commissionerEmail,
                    commissionerAddressLine1: deletedfunder[i]?.commissionerAddressLine1,
                    commissionerCountryId: 0,
                    commissionerCityId: deletedfunder[i]?.commissionerCityId !== '' ? Number(deletedfunder[i]?.commissionerCityId) : null,
                    commissionerPobox: deletedfunder[i]?.commissionerPobox,
                    documentPath: deletedfunder[i]?.documentPath,
                    documentName: deletedfunder[i]?.documentName,
                    isDelete: 1,
                    isSelfFunded: deletedfunder[i]?.isSelfFunded === 1 ? 1 : 0,
                    socialWorkerName: contractdata.ListData[i].commisionerData.socialWorkerName,
                    socialWorkerEmailId: contractdata.ListData[i].commisionerData.socialWorkerEmailId,
                    socialWorkerContactNo: contractdata.ListData[i].commisionerData.socialWorkerContactNo,
                    socialWorkerAddress: contractdata.ListData[i].commisionerData.socialWorkerAddress,
                    socialWorkerCityId: contractdata.ListData[i].commisionerData.socialWorkerCityId !== '' ? Number(contractdata.ListData[i].commisionerData.socialWorkerCityId) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPobox: contractdata.ListData[i].commisionerData.socialWorkerPobox
                };
                if (data.dacdid !== 0) {
                    funderlist.push(data);
                }
            }
        }
        let draftdata = {
            draftLeadId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.draftLeadId) : 0,
            loadPoolingId: props.leadPoolingId > 0 ? props.leadPoolingId : 0,
            patientId: DataPatientDraftInfo?.data ? Number(DataPatientDraftInfo?.data?.patientDetail?.draftPatientId) : 0,
            patientName: servicesData && servicesData.FullName ? servicesData.FullName : '',
            patientTypeId: admissionData.length > 0 && admissionData[2].patientType !== '' ? Number(admissionData[2].patientType) : 0,
            serviceId: DataServiceDetail.serviceId || 0,
            dateOfBirth: servicesData && servicesData.DOB ? window.$utils.dateFormatDDMMYYYY(servicesData.DOB) : '',
            gender: servicesData && servicesData.gender && Number(servicesData.gender) > 0 ? Number(servicesData.gender) : 1,
            nhsId: servicesData && servicesData.NhsId ? servicesData.NhsId : '',
            localId: servicesData && servicesData.LocalId ? servicesData.LocalId : '',
            contactNo: servicesData && servicesData.ContactNo ? servicesData.ContactNo : '',
            email: servicesData && servicesData.EmailId ? servicesData.EmailId : '',
            addressLine1: servicesData && servicesData.Address1 ? servicesData.Address1 : '',
            addressLine2: servicesData && servicesData.Address2 ? servicesData.Address2 : '',
            cityId: servicesData && servicesData.City ? Number(servicesData.City) : null,
            pobox: servicesData && servicesData.PoBox ? servicesData.PoBox : '',

            estStartDate: props.isAction === 'reserve' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

            admissionDate: props.isAction === 'admit' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[0].admissionDate) : '',

            estDischargeDate: admissionData.length > 0 && admissionData[1].dischargeDate !== '' ? window.$utils.dateFormatDDMMYYYY(admissionData[1].dischargeDate) : '',

            endDate: '',

            isPatientCreated: 0,
            coreFeesType: applicabledata?.applicableData?.DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
            dailyCoreFee: applicabledata?.applicableData?.DailyFee ? Number(applicabledata.applicableData.DailyFee) : null,
            hourlyFee1: applicabledata?.applicableData?.AdditionalFeeH1 ? Number(applicabledata.applicableData.AdditionalFeeH1) : null,
            hourlyFee2: applicabledata?.applicableData?.AdditionalFeeH2 ? Number(applicabledata.applicableData.AdditionalFeeH2) : null,
            hourlyFee3: applicabledata?.applicableData?.AdditionalFeeH3 ? Number(applicabledata.applicableData.AdditionalFeeH3) : null,
            hourlyFee4: applicabledata?.applicableData?.AdditionalFeeH4 ? Number(applicabledata.applicableData.AdditionalFeeH4) : null,

            healthFunding: applicabledata?.applicableData?.Healthfunding ? Number(applicabledata.applicableData.Healthfunding) : 0,
            socialFunding: applicabledata?.applicableData?.Socialfunding ? Number(applicabledata.applicableData.Socialfunding) : 0,

            inclusionHours1: applicabledata?.applicableData?.Inclusion1 ? Number(applicabledata.applicableData.Inclusion1) : null,
            inclusionHours2: applicabledata?.applicableData?.Inclusion2 ? Number(applicabledata.applicableData.Inclusion2) : null,
            inclusionHours3: applicabledata?.applicableData?.Inclusion3 ? Number(applicabledata.applicableData.Inclusion3) : null,
            inclusionHours4: applicabledata?.applicableData?.Inclusion4 ? Number(applicabledata.applicableData.Inclusion4) : null,
            inclusionHoursType1: applicabledata?.applicableData?.In1DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
            inclusionHoursType2: applicabledata?.applicableData?.In2DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
            inclusionHoursType3: applicabledata?.applicableData?.In3DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
            inclusionHoursType4: applicabledata?.applicableData?.In4DailyOrWeekly === false ? coreFeesType.Daily : coreFeesType.Weekly,
            isInclusionHours1Included: applicabledata?.applicableData?.Inclusion1included,
            isInclusionHours2Included: applicabledata?.applicableData?.Inclusion2included,
            isInclusionHours3Included: applicabledata?.applicableData?.Inclusion3included,
            isInclusionHours4Included: applicabledata?.applicableData?.Inclusion4included,
            kinName: servicesData && servicesData.kFullName ? servicesData.kFullName : '',
            kinGender: servicesData && servicesData.kgender ? Number(servicesData.kgender) : 0,
            kinRelationshipId: servicesData && servicesData.kRelationship ? Number(servicesData.kRelationship) : null,
            kinContactNo: servicesData && servicesData.kContactNo ? servicesData.kContactNo : '',
            kinAddress: servicesData && servicesData.kAddress ? servicesData.kAddress : '',
            sameAsServiceAddress: servicesData && servicesData.sameasservice ? 1 : 0,

            continenceCareFeeType: applicabledata?.applicableData?.DailyOrWeeklyContinenceCareFee === false ? coreFeesType.Daily : coreFeesType.Weekly,
            continenceCareFee: applicabledata?.applicableData?.ContinenceCareFee ? Number(applicabledata.applicableData.ContinenceCareFee) : null,
            isContinenceFeeIncluded: applicabledata?.applicableData?.ContinenceInclude,
            fncFee: Number(applicabledata?.applicableData?.FNCFee),
            fncFeeType: !applicabledata?.applicableData?.DailyOrWeeklyFNCCareFee ? 1 : 2,
            isFncFeeIncluded: applicabledata?.applicableData?.FNCInclude,
            totalFee: Number(applicabledata?.applicableData?.TotalDailyFee),
            totalAdditionalServiceFee:Number(applicabledata?.applicableData?.totalAdditionalfee),

            contractDetails: funderlist
        };
        savepatientdraftData(draftdata);
    };

    async function savepatientdraftData(payload: any) {
        const res = await saveLeadDraft(payload);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('eventGetBedInformation');
            eventEmitter.emit('eventGetWardInformation');
            eventEmitter.emit('onAdmitReservePatient');
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }
    return (
        <div>
            <AdmitView
                getServiceData={serviceData}
                isAction={props.isAction}
                getAdmissionData={getAdmissionData}
                getContarctdata={getContarctdata}
                isallvoid={getallvoid}
                getReserveAdmissionData={getReserveAdmissionData}
                serviceerrordata={serviceerrordata}
                isValid={isValid}
                getadmissionerror={getadmissionerror}
                getapplicabledata={getapplicabledata}
                getapplicableerror={getapplicableerror}
                getcontracterror={getcontracterror}
                getfundelisterror={getfundelisterror}
                onSubmit={handlesubmit}
                onUpdate={handleupdate}
                onDiscard={handlediscard}
                isValidDraft={isValidDraft}
                onDraft={handledraft}
                isDraft={props.isDraft}
                listVacantBeds={listVacantBeds}
                setData={setData}
                patientname={servicesData.FullName}
                funderlist={contractdata}
                admissiondate={props.isAction === 'admit' && admissionData.length > 0 && admissionData[0].admissionDate !== '' ? admissionData[0].admissionDate : ''}
                selfData={servicesData}
                deletefunderlist={deletefunderlist}
                section={props.section}
                splitchange={getsplitchange}
                onSave={saveHandler}
                isReferralAdd={props.isReferralAdd}
                leadPoolingId={props.leadPoolingId}
                deleteddoc={deleteddoc}
                leadnhsid={props.leadnhsid}
                leadlocalid={props.leadlocalid}
                bed={bed}
                serviceError={serviceError}
                admissionError={admissionError}
                applicableerror={applicableerror}
                contracterror={contracterror}
                contractlisterror={contractlisterror}
                getcontractfunder={getcontractfunder}
                isDraftListAdd={props.isDraftListAdd}
                checkSelfFunder={checkSelfFunder}
                validateSelfFunder={validateSelfFunder}
            />
        </div>
    );
};

export default AdmitContainer;
