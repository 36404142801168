import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type Props = {
    className: string;
    value: number;
};

const Progresschart = (props: Props) => {
    return (
        <div>
            <CircularProgressbar
                value={props.value}
                text={`${props.value}%`}
                strokeWidth={15}
                className={props.className}
                styles={{
                    root: {},
                    path: {
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt'
                    },
                    text: {
                        fill: '#4e4851',
                        fontSize: '1.34rem',
                        fontWeight: '600'
                    }
                }}
            />
        </div>
    );
};

export default Progresschart;
