import React, { useCallback, useState, useEffect } from 'react';
import { ListBoxContainerPropsType } from '../HomeList/ListBox/types';
import { useTranslation } from 'react-i18next';
import eventEmitter from '../../../utils/eventEmitter';
import { EventKey } from '../../../utils/constant';
import GoogleMapComponent from '../../../components/GoogleMapComponent/GoogleMapComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Roles } from '../../../utils/constant';
import { BedAvailability } from '../../../utils/constant';
import { geocode, RequestType } from 'react-geocode';

const HomeMapView = ({ props, fromMapView }: ListBoxContainerPropsType) => {
    const { t } = useTranslation();

    const { StatusUserLocation, DataUserLocation } = useSelector((state: RootState) => state.userLocation);
    const { ServiceListsData } = useSelector((state: RootState) => state.serviceLists);
    const { DataCityWiseLatLngList } = useSelector((state: RootState) => state.cityWiseLatLngList);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const [statusAvailableService, setStatusAvailableService] = useState(true);
    const [searchCityHeader, setSearchCityHeader] = useState<any>("");
    const { AppliedFilterData, TopFilterData } = useSelector((state: RootState) => state.appliedFilter);

    const [containerStyle, setContainerStyle] = useState<any>({
        width: `${window.innerWidth - 55}px`,
        height: '650px'
    });
    const [centerPoint, setCenterPoint] = useState<any>(null);
    const [showOutOfBorder, setShowOutOfBourder] = useState<boolean>(false);
    const [zoom, setZoom] = useState<any>(11);
    const [markerPoint, setMarkerPoint] = useState<any>([]);
    useEffect(() => {
        if (StatusUserLocation && searchCityHeader === "") {
            let centerpoint = {
                lat: DataUserLocation?.user_coords?.latitude,
                lng: DataUserLocation?.user_coords?.longitude
            };
            // let centerpoint = { lat: 52.6111, lng: -1.0983 };
            setCenterPoint(centerpoint);
        }
        if (DataCityWiseLatLngList?.latLongList?.length > 0 && searchCityHeader === "") {
            setCenterPoint(DataCityWiseLatLngList?.latLongList[0]);
        }
        if (DataCityWiseLatLngList === '' && ServiceListsData?.length > 0 && searchCityHeader === "") {
            let centerpoint = {
                lat: Number(ServiceListsData[0]?.latitude),
                lng: Number(ServiceListsData[0]?.longitude)
            };
            setCenterPoint(centerpoint);
        }

        if(AppliedFilterData?.location !== "") {
            searchLocationFromWhereTop(AppliedFilterData?.location)
        }
    }, [StatusUserLocation, DataCityWiseLatLngList, ServiceListsData]);

    const handleMapLayoutChange = useCallback(() => {
        props.setIsMapInfullView(!props.isMapInfullView);
        eventEmitter.emit(EventKey.MapLayoutChange, false);
    }, [props.isMapInfullView, props.setIsMapInfullView]);

    const handleListLayoutChange = useCallback(() => {
        props.setIsListInfullView(!props.isListInfullView);
        eventEmitter.emit(EventKey.ListLayoutChange, !props.isListInfullView);
    }, [eventEmitter, props]);

    const eventShowOutOfServiceProviderMap = () => {
        setShowOutOfBourder((prevState) => !prevState);
    };

    useEffect(() => {
        eventEmitter.on('eventShowOutOfServiceProviderMap', eventShowOutOfServiceProviderMap);
        return () => {
            eventEmitter.off('eventShowOutOfServiceProviderMap', eventShowOutOfServiceProviderMap);
        };
    });

    const eventSetWidthMap = (content: any) => {
        setContainerStyle({
            width: `${content.main}px`,
            height: '650px'
        });
    };

    useEffect(() => {
        eventEmitter.on('eventSetWidthMap', eventSetWidthMap);
        return () => {
            eventEmitter.off('eventSetWidthMap', eventSetWidthMap);
        };
    });

    const searchLocationFromWhereTop = (searchValue: any) => {
        setSearchCityHeader(searchValue);
        if (searchValue) {
            let config: any = {
                key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                language: 'en',
                region: 'us'
            };
            geocode(RequestType.ADDRESS, `${searchValue}`, config)
                .then(({ results }) => {
                    const { lat, lng } = results[0].geometry.location;
                    if (lat !== '' && lng !== '') {
                        let centerpoint = {
                            lat: lat,
                            lng: lng
                        };
                        setCenterPoint(centerpoint);
                        setZoom(11);
                    }
                })
                .catch(console.error);
        } else {
            if (DataCityWiseLatLngList?.latLongList?.length > 0) {
                setCenterPoint(DataCityWiseLatLngList?.latLongList[0]);
                setZoom(11);
            }
            if (DataCityWiseLatLngList === '' && ServiceListsData?.length > 0) {
                let centerpoint = {
                    lat: Number(ServiceListsData[0]?.latitude),
                    lng: Number(ServiceListsData[0]?.longitude)
                };
                setCenterPoint(centerpoint);
                setZoom(11);
            }
        }
    };

    useEffect(() => {
        eventEmitter.on('searchLocationFromWhereTop', searchLocationFromWhereTop);
        return () => {
            eventEmitter.off('searchLocationFromWhereTop', searchLocationFromWhereTop);
        };
    }, []);

    // const showAvailableService = (status: any, serviceLists: any) => {
    //     if (status) {
    //         setMarkerPoint(serviceLists.filter((item: any) => item.bedAvailability === BedAvailability.Available));
    //     } else {
    //         setMarkerPoint(serviceLists);
    //     }
    // };

    useEffect(() => {
        setTimeout(() => {
            eventEmitter.emit("ServiceListFromCheckbox", markerPoint);
        }, 1000);
        if (AppliedFilterData?.searchValue !== '') {
            let location = {
                lat: parseFloat(markerPoint[0]?.latitude!),
                lng: parseFloat(markerPoint[0]?.longitude!)
            };
            console.log(location);
            setCenterPoint(location);
        }
        setZoom(11);
    }, [markerPoint]);

    // useEffect(() => {
    //     showAvailableService(statusAvailableService, ServiceListsData);
    // }, [statusAvailableService, ServiceListsData]);

    // useEffect(() => {
    //     eventEmitter.on('showAvailableService', (isChecked) => setStatusAvailableService(isChecked));
    //     eventEmitter.setMaxListeners(20)
    //     return () => {
    //         eventEmitter.off('showAvailableService', (isChecked) => setStatusAvailableService(isChecked));
    //     };
    // }, [ServiceListsData]);

    useEffect(() => {
        setMarkerPoint(ServiceListsData);
      }, [ServiceListsData])

    return (
        <div>
            <div>
                <div className="position-relative map-list-view">
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <GoogleMapComponent
                            containerStyle={containerStyle}
                            centerPoint={centerPoint}
                            zoom={zoom}
                            markerPoint={markerPoint}
                            borderPoint={DataCityWiseLatLngList.latLongList}
                            showOutOfBorder={showOutOfBorder}
                            showOutBorderServices={true}
                        />
                    </div>
                    {DataRoleSelected?.roleId !== Roles.ServiceManager &&
                        (!props.isMapInfullView ? (
                            <div className="map-close" onClick={handleListLayoutChange}>
                                <button className="btn">
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.72761 5.3627L2.46362 0.264292C2.09979 -0.0880975 1.51146 -0.0880975 1.1515 0.264292L0.276746 1.11153C-0.0870876 1.46392 -0.0870876 2.03374 0.276746 2.38238L4.00411 6L0.272875 9.61387C-0.0909586 9.96626 -0.0909586 10.5361 0.272875 10.8847L1.14763 11.7357C1.51146 12.0881 2.09979 12.0881 2.45975 11.7357L7.72374 6.6373C8.09144 6.28491 8.09144 5.71509 7.72761 5.3627Z"
                                            fill="#4E4851"
                                        />
                                    </svg>
                                </button>
                            </div>
                        ) : (
                            <div
                                className="map-button"
                                
                            >
                                <button className="btn btn-nhs primary-btn pill-btn d-flex align-items-center" onClick={handleMapLayoutChange}>
                                    {t('NHSHome.Show_List_btn')}
                                    <svg className="ms-2" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 22">
                                        <g clip-path="url(#clip0_2827_10435)">
                                            <path
                                                d="M28.9982 11.2842C28.8628 11.5311 28.7532 11.7975 28.5791 12.0119C28.2954 12.3498 27.8957 12.4602 27.4573 12.4602C26.6256 12.4602 25.7939 12.4602 24.9686 12.4602C19.4175 12.4602 13.8728 12.4602 8.32167 12.4602C7.6447 12.4602 7.12247 12.2198 6.83879 11.5766C6.43906 10.6865 7.01931 9.67288 7.98641 9.55592C8.14759 9.53643 8.30878 9.53643 8.46351 9.53643C14.6981 9.53643 20.9391 9.54293 27.1736 9.52344C28.0762 9.52344 28.7081 9.80932 28.9982 10.706V11.2777V11.2842Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M28.9987 2.15298C28.96 2.23744 28.9213 2.32191 28.8891 2.40637C28.6376 2.95865 28.2186 3.26402 27.6125 3.29651C27.5352 3.29651 27.4642 3.29651 27.3869 3.29651C21.0363 3.29651 14.6857 3.29651 8.33506 3.29651C7.93533 3.29651 7.56783 3.23154 7.25191 2.97164C6.78771 2.5883 6.60718 1.97105 6.78771 1.40578C6.97468 0.821022 7.47757 0.424684 8.09006 0.3857C8.21256 0.379203 8.33506 0.3857 8.45756 0.3857C14.7115 0.3857 20.9718 0.3857 27.2257 0.3857C28.2573 0.3857 28.6119 0.626102 29.0051 1.58121V2.15298H28.9987Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M28.9998 20.4223C28.8064 21.0395 28.4453 21.4749 27.7877 21.5918C27.6652 21.6113 27.5427 21.6178 27.4202 21.6178C21.0438 21.6178 14.661 21.6178 8.28459 21.6178C7.1821 21.6178 6.46644 20.6887 6.79526 19.6946C6.98868 19.1163 7.50446 18.7265 8.11051 18.7005C8.23301 18.7005 8.35551 18.7005 8.478 18.7005C14.7061 18.7005 20.9342 18.7135 27.1623 18.6875C28.0649 18.6875 28.6968 18.9669 28.9998 19.8505V20.4223Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M1.90228 3.91804C0.838471 3.89855 -0.019021 3.00192 0.000320901 1.91686C0.0196628 0.857793 0.902944 -0.0128511 1.95386 0.000143601C3.02411 0.0131383 3.8945 0.903274 3.8816 1.97534C3.86871 3.06689 2.97898 3.94403 1.89583 3.91804H1.90228Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M0 20.0352C0 18.9631 0.883281 18.0795 1.94709 18.086C3.01089 18.086 3.88773 18.9826 3.88773 20.0547C3.88773 21.1267 2.998 22.0169 1.93419 22.0104C0.870387 21.9974 0 21.1137 0 20.0352Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M1.90855 12.9454C0.838295 12.9324 -0.01275 12.0488 0.000144612 10.9637C0.0130392 9.88518 0.889873 9.03403 1.97302 9.04702C3.04327 9.06002 3.91366 9.96315 3.88787 11.0287C3.86208 12.1203 2.9917 12.9649 1.915 12.9454H1.90855Z"
                                                fill="white"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2827_10435">
                                                <rect width="29" height="22" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default HomeMapView;
