import React from 'react';

interface ModalProps {
    onClose: any;
    title: string;
    content: any;
    footer: any;
    className: any;
}

const ModalWrapperView = (props: ModalProps) => {
    return (
        <div>
            <div className="overlay" onClick={props.onClose}></div>
            <div className={props.className}>
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title">{props.title}</p>
                        <a href="#" onClick={props.onClose} className='close-modal'>
                            <i className="fa fa-xmark"></i>
                        </a>
                    </div>
                    <div className="modal-body">{props.content}</div>
                    <div className="modal-footer justify-content-center">{props.footer}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalWrapperView;
