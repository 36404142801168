import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TempSplitFundingModel1 } from '../../utils/model';

const initialState: TempSplitFundingModel1 = {
    TempDataSplitFunding1: [],
};

export const tempSplitFundingSlice1 = createSlice({
    name: 'tempSplitFunding1',
    initialState,
    reducers: {
        setTempSplitFundingAction1: (state, { payload }: PayloadAction) => {
            
            state.TempDataSplitFunding1 = payload;
        },
        removeTempSplitFundingAction1: (state, { payload }: PayloadAction) => {
            state.TempDataSplitFunding1 = [];
        }
    },
    extraReducers: {}
});

export const { setTempSplitFundingAction1, removeTempSplitFundingAction1 } = tempSplitFundingSlice1.actions;

export default tempSplitFundingSlice1.reducer;
