import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import SplitFundingContainer from './SplitFunding/SplitFundingContainer';
import InvoiceContainer from './Invoice/InvoiceContainer';
import ConfirmationContainer from './Confirmation/ConfirmationContainer';
import AdmissionContainer from './Admission/AdmissionContainer';

const TaskView = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const closeSideMenu = () => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle:false
        };
        dispatch(setNavigationAction(obj));
    };
    return (
        <div>
             <button onClick={closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>{' '}
            </button>

            <h3 className="mb-2">
            {t('Task.Title')} -
                <span className="ms-1 purple-text">{ props.taskCount || 0 }</span>
            </h3>

            <div className="right_content-height-tab mt-4">
                <ul className="nav nav-tabs custom-tab task-tab" id="myTab1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active"
                            id="admitted-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Split-Funding"
                            type="button"
                            role="tab"
                            aria-controls="Split-Funding"
                            aria-selected="true"
                            onClick={() => props.onClickTaskTypeHandler(props.taskSummary?.splitFunding?.id)}
                        >
                        <svg version="1.1" id="Layer_1"
                        fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width={19}
                        viewBox="0 0 408.95 408.57"
                        xmlSpace="preserve">
                            <g>
                                 <style>{`"enable-background:new 0 0 408.95 408.57;"`}</style>
                                <path d="M267.62,408.57c-7.24-1.54-14.62-2.09-21.85-3.88c-20.91-5.19-39.39-14.9-55.47-29.1c-22.02-19.45-36.15-43.74-41.9-72.46
                                    c-8.5-42.47,1.56-80.47,29.6-113.5c19.16-22.56,43.72-36.4,72.46-42.62c16.07-3.48,32.41-3.67,48.69-0.79
                                    c30.93,5.47,56.92,19.89,77.52,43.59c16.65,19.15,26.93,41.37,30.75,66.56c0.47,3.09,0.15,6.35,1.53,9.3c0,6.39,0,12.78,0,19.16
                                    c-0.75,1.2-0.61,2.56-0.71,3.87c-0.73,9.67-2.76,19.08-5.81,28.25c-6.34,19.02-16.27,35.92-30.17,50.47
                                    c-16.11,16.87-35.27,28.68-57.54,35.6c-9.39,2.92-19.09,4.05-28.73,5.56C279.87,408.57,273.74,408.57,267.62,408.57z M246.31,288
                                    c-2.64,8.06-8.85,15.81-16.47,20.56c-3.3,2.06-6.35,4.38-9.05,7.14c-3.69,3.77-4.35,9.96-1.69,14.3c2.83,4.62,8.14,6.68,13.41,5.29
                                    c3.6-0.95,6.22-3.51,9.1-5.58c3.13-2.24,6.48-3.4,10.33-3.59c7.76-0.39,13.64,3.18,19.15,8.18c17.47,15.84,45.62,14.99,62.13-1.71
                                    c6.86-6.94,4.11-17.35-5.27-20.09c-4.8-1.4-8.41,0.64-11.96,3.54c-9.61,7.88-20.27,7.94-29.63-0.03
                                    c-5.6-4.77-11.34-9.2-18.49-11.37c-1.83-0.55-1.77-1.51-1.05-2.9c1.77-3.42,3.12-7.04,3.59-10.84c0.34-2.72,1.75-2.97,3.99-2.95
                                    c8.51,0.09,17.03,0.06,25.54,0.03c7.61-0.03,13.08-5.05,13.17-12c0.09-6.72-5.63-11.89-13.27-11.92c-8.65-0.04-17.3-0.2-25.93,0.09
                                    c-3.16,0.11-4.09-1.23-4.58-3.84c-1.05-5.57-2.06-11.16-3.36-16.68c-2.06-8.75,1.13-15.78,7.4-21.36
                                    c7.5-6.67,16.34-8.14,25.56-3.95c9.01,4.09,13.67,11.39,14.18,21.33c0.45,8.8,7.93,14.21,15.9,11.57c5.66-1.87,8.7-7.53,7.99-14.85
                                    c-2.73-28.08-27.04-47.36-55.13-43.46c-28.57,3.97-44.18,33.24-39.17,54.89c1.22,5.26,2.05,10.62,3.12,16.26
                                    c-5.21,0-10.24-0.03-15.28,0.01c-7.66,0.06-13.2,5.09-13.21,11.93c-0.01,6.86,5.56,11.97,13.14,12.01
                                    C235.64,288.03,240.82,288,246.31,288z"/>
                                <path d="M133.94,96.44c-30.7-0.64-60.68-2.71-89.82-11.33c-10.71-3.17-21.05-7.28-30.18-13.91C-4.56,57.75-4.41,38.55,14.22,25.39
                                    C26.34,16.82,40.08,12.05,54.3,8.61c31.42-7.62,63.34-9.54,95.57-8.22c24.84,1.02,49.37,3.83,73.15,11.4
                                    c11.8,3.76,23.29,8.37,32.51,17.02c13.99,13.14,12.69,30.28-2.87,41.75c-12.77,9.42-27.49,14.15-42.63,17.78
                                    C184.84,94.37,159.17,95.95,133.94,96.44z"/>
                                <path d="M265.2,76.74c0,7.18,0.42,14.39-0.11,21.52c-0.66,8.79-5.89,15.17-12.7,20.27c-12.59,9.43-27.23,14.19-42.28,17.63
                                    c-30.86,7.07-62.17,9.03-93.77,7.56c-24.3-1.14-48.36-3.71-71.76-10.68c-12.64-3.76-24.86-8.51-34.87-17.53
                                    c-6.33-5.7-9.87-12.67-9.63-21.39c0.13-5.02,0.02-10.04,0.02-15.76C2.58,80.43,2.73,83,4.02,84.9c4.3,6.34,10.13,10.87,16.71,14.49
                                    c14.55,8.02,30.42,12.2,46.59,15.17c24.1,4.43,48.49,6.14,72.97,5.58c24.27-0.55,48.44-2.52,72.09-8.55
                                    c14.07-3.59,27.73-8.1,39.66-16.78C258.43,90.16,262.77,84.15,265.2,76.74z"/>
                                <path d="M163.73,383.34c-5.82,0.37-10.56,0.83-15.3,0.96c-32.62,0.91-64.98-1.07-96.71-9.27c-12.87-3.32-25.31-7.78-36.41-15.37
                                    c-10.1-6.91-16.14-15.89-15.22-28.7c0.3-4.17,0.05-8.37,0.41-12.67c2.44,4.11,4.42,8.35,7.91,11.68
                                    c10.21,9.74,22.91,14.69,36.03,18.64c18.59,5.59,37.67,8.53,57.03,9.73c13.92,0.86,27.82,2.29,41.78,0.94
                                    c1.59-0.15,2.79,0.2,3.72,1.64C151.86,368.49,157.01,375.88,163.73,383.34z"/>
                                <path d="M0.11,126.32c5.53,13.25,16.79,19.59,28.77,25.01c8.82,3.99,18.1,6.7,27.51,8.85c29.03,6.64,58.52,8.31,88.18,7.83
                                    c7.23-0.12,14.46-0.75,22.41-1.19c-5.16,5.66-10.59,10.43-13.87,16.55c-4.21,7.89-10.27,9.22-18.51,8.99
                                    c-30.41-0.86-60.65-2.65-90.02-11.39c-12.63-3.76-24.83-8.52-34.85-17.55c-6.34-5.71-9.86-12.67-9.66-21.37
                                    C0.21,136.82,0.11,131.57,0.11,126.32z"/>
                                <path d="M0.11,175.08c2.93,3.93,5.07,8.1,8.61,11.41c10.81,10.12,24.21,15.09,38.02,19.06c17.88,5.15,36.2,7.76,54.75,9.13
                                    c9.95,0.73,19.91,1.02,29.87,1.43c1.99,0.08,2.63,0.45,1.82,2.49c-2.5,6.28-4.68,12.67-6.15,19.27c-0.61,2.72-2.61,2.25-4.38,2.12
                                    c-10.72-0.81-21.48-1.04-32.18-2.28c-18.05-2.1-35.87-5.16-53.07-11.18c-10.33-3.62-20.17-8.16-28.31-15.77
                                    c-6.07-5.67-9.07-12.6-8.98-20.91C0.16,184.92,0.11,180,0.11,175.08z"/>
                                <path d="M133.61,336c-13.94-0.45-27.17-1.07-40.41-2.3c-16.53-1.54-32.79-4.31-48.67-9.05c-12.62-3.77-24.84-8.5-34.84-17.54
                                    c-6.32-5.72-9.82-12.69-9.6-21.39c0.13-5.24,0.02-10.49,0.02-16.54c9.46,20.62,28.17,26.43,46.66,32.14
                                    c14.94,4.62,30.43,6.69,45.99,8.41c10.05,1.11,20.14,1.2,30.19,2.01c2.24,0.18,3.15,0.86,3.69,3.06
                                    C128.38,321.83,130.82,328.66,133.61,336z"/>
                                <path d="M0.11,221.45c10.3,21.58,30.35,27.31,50.1,32.85c21.39,6,43.42,8.24,65.61,9.12c7.78,0.31,6.93-0.54,6.15,7.17
                                    c-0.49,4.83-0.39,9.55,0.2,14.3c0.26,2.1-0.06,3.16-2.64,2.99c-18.81-1.21-37.63-2.43-56.09-6.39c-17.9-3.83-35.63-8.4-50.7-19.69
                                    c-8.56-6.42-13.16-14.69-12.67-25.61C0.29,231.48,0.11,226.75,0.11,221.45z"/>
                            </g>
                            </svg>
                            {t('Task.Split_Fund')}
                            <p className='task-count'>
                                {/* {props.taskSummary?.splitFunding?.count || 0} */}

                                {props.taskSummary?.splitFunding?.count  > 99 ? "99+" : props.taskSummary?.splitFunding?.count || 0}
                            </p>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="reserves-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Invoice"
                            type="button"
                            role="tab"
                            aria-controls="Invoice"
                            aria-selected="false"
                            onClick={() => props.onClickTaskTypeHandler(props.taskSummary?.invoiceNotCreated?.id)}
                        >
                            <svg version="1.1" id="Layer_1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink"
                                 x="0px" y="0px"
                                 fill='#ffffff'
                                 width={15}
                                viewBox="0 0 309.72 403.57"
                                xmlSpace="preserve">
                                 <style>{`"enable-background:new 0 0 309.72 403.57;"`} </style>
                            <g>
                                <path d="M309.72,403.57c-103.18,0-206.37,0-309.55,0c-0.04-0.66-0.12-1.31-0.12-1.97c0-130.27,0-260.53,0.02-390.8
                                    c0-1.32-0.4-2.8,0.64-4.14c12.12,8.22,24.08,16.33,36.05,24.42c5.92,4,8.69,3.98,14.72,0.01C66.63,21.1,81.8,11.16,96.88,1.08
                                    c2.07-1.38,3.46-1.47,5.55-0.07c14.94,10.05,29.98,19.95,44.96,29.94c5.78,3.86,8.75,3.99,14.42,0.24
                                    c14.9-9.87,29.86-19.65,44.6-29.78c3.01-2.07,4.91-1.66,7.58,0.14c14.93,10.06,29.98,19.94,44.92,29.99
                                    c4.76,3.2,9.15,3.09,13.8-0.2c6.33-4.48,12.8-8.75,19.22-13.1c5.72-3.88,11.44-7.74,17.74-12c0,2.02,0,3.28,0,4.55
                                    c0,44.87-0.02,89.74-0.01,134.61C309.67,231.46,309.7,317.51,309.72,403.57z M154.74,73.2C91.52,73.12,37.33,123.52,37.5,190.82
                                    c0.16,64.82,51.67,116.77,117.08,116.89c67.06,0.12,117.52-54.39,117.45-117.44C271.97,125.47,219.76,73.31,154.74,73.2z
                                    M154.54,332.91c0-0.02,0-0.03,0-0.05c-25.39,0-50.78-0.01-76.18,0.01c-2.23,0-4.49,0.03-6.69,0.36c-5.33,0.79-8.9,6.02-7.82,11.22
                                    c1.23,5.94,4.89,8.54,12.21,8.55c52.49,0.02,104.99,0.02,157.48,0.03c1.85,0,3.66-0.09,5.45-0.69c4.59-1.54,7.27-5.67,6.84-10.69
                                    c-0.39-4.53-3.65-7.88-8.42-8.46c-2.08-0.25-4.2-0.28-6.3-0.28C205.59,332.9,180.07,332.91,154.54,332.91z"/>
                                <path d="M154.67,287.63c-53.42,0.53-97.3-44.14-97.17-97.52c0.14-54.11,45.35-98.74,100.54-96.95
                                    c51.98,1.69,94.74,45.03,94.05,98.7C251.39,245.69,206.68,288.29,154.67,287.63z M145.96,231.23c3.28-8.26,4.14-16.3,4.19-24.45
                                    c0.01-2.16,1.09-2.24,2.71-2.22c5,0.06,10,0.11,15,0c5.94-0.13,10.48-4.59,10.49-10.1c0.01-5.41-4.27-9.58-10.29-9.75
                                    c-5.39-0.15-10.8-0.18-16.18,0.03c-2.74,0.11-3.67-0.99-4.04-3.51c-0.48-3.22-1.4-6.37-2.03-9.57c-1.01-5.16-1.93-10.43-0.76-15.61
                                    c2.51-11.14,14.11-16.51,25.93-12.49c5.71,1.95,7.95,6.29,9.39,11.63c1.58,5.87,6.93,8.88,12.44,7.4
                                    c5.15-1.38,8.04-6.82,6.69-12.48c-3.74-15.64-13.55-24.41-29.42-27.18c-25.89-4.52-47.77,15.45-45.42,41.61
                                    c0.53,5.91,1.65,11.66,2.96,17.39c0.52,2.28-0.03,2.8-2.16,2.76c-4.6-0.08-9.21-0.1-13.82,0c-6.15,0.13-10.52,4.41-10.4,9.99
                                    c0.13,5.56,4.66,9.79,10.68,9.86c4.87,0.06,9.75,0.18,14.6-0.05c2.91-0.13,3.85,0.66,3.75,3.71c-0.33,9.3-2.44,17.87-8.54,25.21
                                    c-1.57,1.89-3.15,3.84-4.09,6.23c-2.74,6.96,2.66,14.47,10.08,13.8c3.13-0.28,6.24-0.79,9.36-1.26c9.06-1.36,17.72-1.45,26.45,2.82
                                    c12.67,6.19,25.78,4.52,38.56-0.45c4.18-1.63,6.27-5.23,6.08-9.66c-0.19-4.38-2.7-7.43-7.01-8.99c-4.02-1.46-7.47,0.56-11.06,1.54
                                    c-6.54,1.79-12.98,2.08-19.2-0.94C163.21,232.77,155.19,230.68,145.96,231.23z"/>
                            </g>
                            </svg>
                            {t('Task.Invoice')}
                            <p className='task-count'>
                                {/* {props.taskSummary?.invoiceNotCreated?.count || 0} */}
                                {props.taskSummary?.invoiceNotCreated?.count > 99 ? "99+" : props.taskSummary?.invoiceNotCreated?.count || 0}
                            </p>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link confirmation-tab"
                            id="reserves-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#confirmation"
                            type="button"
                            role="tab"
                            aria-controls="confirmation"
                            aria-selected="false"
                            onClick={() => props.onClickTaskTypeHandler(props.taskSummary?.pendingConfirmation?.id)}
                        >
                             <svg version="1.1" id="Layer_1"
                           fill="#ffffff"
                           xmlns="http://www.w3.org/2000/svg"
                           xmlnsXlink="http://www.w3.org/1999/xlink"
                             x="0px" y="0px"
                             width={25}
                                viewBox="0 0 1080 1080"
                                xmlSpace="preserve">
                            <path d="M459.98,534.92c21.37-25.69,42-51.3,63.49-76.18c78.2-90.56,163.18-173.96,260.07-244.66c33.75-24.62,33.77-24.6,75.42-24.6
                                c12.38,0,24.77,0,37.15,0.01c7.82,0,13.49,3.43,16.45,10.76c2.88,7.15,0.86,13.35-4.55,18.36
                                C775.11,341.57,672.48,488.61,574.18,638.96c-32.72,50.04-63.01,101.67-94.33,152.62c-8.51,13.84-22.71,14.84-30.85,1.58
                                c-60.79-99.01-129.4-192.64-197.24-286.8c-1.15-1.6-2.36-3.15-3.41-4.82c-4.65-7.43-4.05-14.63,2.01-20.78
                                c14.62-14.83,29.38-29.52,44.36-43.98c7.37-7.11,14.52-6.95,24.36-0.1c39.43,27.46,78.82,55.01,118.22,82.51
                                C444.89,524.48,452.5,529.73,459.98,534.92z"/>
                                <style>{`"enable-background:new 0 0 1080 1080;"`} </style>
                            <g>
                                <path d="M178.09,709.96c7.21,0.19,13.28,6.65,12.99,13.82c-0.3,7.21-6.89,13.25-13.99,12.83c-6.79-0.41-12.67-6.66-12.62-13.41
                                    C164.51,716.07,170.99,709.77,178.09,709.96z"/>
                                <path d="M540.7,865.57c7.11-0.12,13.53,6.21,13.51,13.34c-0.02,6.91-5.75,12.89-12.69,13.23c-7.2,0.36-13.68-5.6-13.95-12.83
                                    C527.31,872.26,533.58,865.69,540.7,865.57z"/>
                                <path d="M191.08,359.78c0.23,7.22-5.85,13.58-13.09,13.71c-7.14,0.13-13.53-6.17-13.53-13.32c0-6.77,5.91-12.95,12.73-13.31
                                    C184.29,346.48,190.85,352.59,191.08,359.78z"/>
                                <path d="M372.02,878.09c0.46-7.19,7.24-13.1,14.29-12.46c6.86,0.62,12.58,6.97,12.34,13.68c-0.26,7.17-6.8,13.18-13.97,12.84
                                    C377.37,891.8,371.56,885.34,372.02,878.09z"/>
                                <path d="M748.53,554.41c7.22,0.07,13.42,6.46,13.19,13.59c-0.21,6.81-6.27,12.84-13.05,13c-7.14,0.17-13.48-6.06-13.51-13.28
                                    C735.13,560.37,741.19,554.34,748.53,554.41z"/>
                                <path d="M761.74,879.28c-0.24,7.17-6.77,13.2-13.93,12.88c-7.32-0.33-13.09-6.74-12.66-14.03c0.41-6.9,6.53-12.64,13.38-12.54
                                    C755.66,865.67,761.97,872.23,761.74,879.28z"/>
                                <path d="M709.75,878.68c0.12,7.36-5.87,13.45-13.21,13.47c-7.22,0.01-13.48-6.29-13.35-13.44c0.12-6.82,6.07-12.87,12.91-13.13
                                    C703.25,865.3,709.63,871.43,709.75,878.68z"/>
                                <path d="M177.43,840.09c-7.41-0.17-13.32-6.39-12.96-13.63c0.34-6.95,6.23-12.5,13.27-12.52c7.41-0.02,13.46,5.98,13.35,13.25
                                    C190.98,834.55,184.93,840.27,177.43,840.09z"/>
                                <path d="M191.08,464.27c-0.22,7.37-6.33,12.98-13.84,12.71c-7.37-0.26-13.24-6.62-12.75-13.83c0.47-7.01,6.33-12.36,13.45-12.3
                                    C185.38,450.93,191.3,456.96,191.08,464.27z"/>
                                <path d="M191.09,619.65c-0.07,7.28-6.23,13.15-13.63,12.97c-6.99-0.16-12.82-5.91-13-12.81c-0.19-7.25,5.82-13.3,13.25-13.34
                                    C185.2,606.43,191.15,612.3,191.09,619.65z"/>
                                <path d="M761.72,723.05c0.14,7.08-6.22,13.56-13.31,13.56c-6.89,0-12.9-5.75-13.25-12.68c-0.37-7.25,5.53-13.7,12.79-13.98
                                    C755.01,709.68,761.58,715.92,761.72,723.05z"/>
                                <path d="M554.21,307.9c0.23,7.3-5.68,13.39-13.11,13.5c-7.43,0.1-13.53-5.8-13.54-13.09c0-6.86,5.78-12.85,12.72-13.16
                                    C547.58,294.82,553.98,300.68,554.21,307.9z"/>
                                <path d="M644.37,865.56c7.26-0.18,13.3,5.82,13.34,13.26c0.04,7.17-5.3,12.93-12.35,13.33c-7.31,0.41-13.54-5.36-13.78-12.75
                                    C631.33,871.96,637.08,865.74,644.37,865.56z"/>
                                <path d="M489.35,865.54c7.26,0.19,13.05,6.46,12.8,13.87c-0.25,7.4-6.48,13.14-13.82,12.73c-6.95-0.39-12.49-6.31-12.46-13.33
                                    C475.9,871.56,482.21,865.35,489.35,865.54z"/>
                                <path d="M761.76,463.77c0.09,7.31-5.92,13.23-13.41,13.21c-7.13-0.02-12.88-5.46-13.19-12.5c-0.33-7.35,5.45-13.46,12.88-13.63
                                    C755.51,450.69,761.67,456.49,761.76,463.77z"/>
                                <path d="M191.08,515.92c-0.09,7.32-6.27,13.27-13.62,13.09c-7.27-0.18-13.32-6.59-12.99-13.76c0.32-6.83,6.43-12.73,13.24-12.77
                                    C184.87,502.44,191.17,508.76,191.08,515.92z"/>
                                <path d="M437.53,892.15c-7.4,0.21-13.36-5.59-13.45-13.08c-0.09-7.46,5.8-13.54,13.1-13.52c6.89,0.02,12.79,5.78,13.08,12.76
                                    C450.57,885.7,444.82,891.94,437.53,892.15z"/>
                                <path d="M385.35,321.4c-7.48,0.02-13.42-5.87-13.34-13.23c0.08-7.27,6.29-13.2,13.64-13.01c6.97,0.18,12.83,6,12.99,12.89
                                    C398.81,315.29,392.75,321.37,385.35,321.4z"/>
                                <path d="M243.03,308.56c-0.15,7.26-6.39,13.07-13.8,12.85c-7.45-0.22-13.07-6.3-12.72-13.76c0.33-7.02,6.14-12.53,13.2-12.51
                                    C237.02,295.16,243.18,301.36,243.03,308.56z"/>
                                <path d="M177.93,684.53c-7.42,0.07-13.52-5.9-13.48-13.18c0.04-7.17,6.4-13.3,13.63-13.15c6.94,0.15,12.82,5.97,12.99,12.87
                                    C191.26,678.41,185.35,684.46,177.93,684.53z"/>
                                <path d="M294.6,878.68c0.11,7.45-5.73,13.49-13.05,13.5c-7.32,0.01-13.15-6.01-13.06-13.48c0.09-7.11,5.62-12.86,12.63-13.12
                                    C288.37,865.3,294.49,871.25,294.6,878.68z"/>
                                <path d="M164.44,774.85c0.18-7.29,6.42-13.06,13.85-12.82c7.4,0.24,13.18,6.49,12.77,13.81c-0.38,6.9-6.42,12.58-13.34,12.54
                                    C170.51,788.35,164.26,781.98,164.44,774.85z"/>
                                <path d="M761.77,827.22c-0.13,7.32-6.26,13.06-13.75,12.87c-7.44-0.18-13.19-6.3-12.85-13.66c0.32-7,6.13-12.49,13.21-12.5
                                    C755.76,813.93,761.89,820.02,761.77,827.22z"/>
                                <path d="M761.75,619.2c0.21,7.21-5.86,13.37-13.24,13.44c-7.06,0.06-12.94-5.39-13.34-12.37c-0.42-7.36,5.27-13.54,12.7-13.79
                                    C755.38,606.23,761.53,611.87,761.75,619.2z"/>
                                <path d="M748.04,529.01c-7.38-0.21-13.19-6.42-12.88-13.78c0.29-6.92,6.27-12.7,13.17-12.75c7.12-0.06,13.5,6.35,13.41,13.46
                                    C761.65,523.16,755.31,529.22,748.04,529.01z"/>
                                <path d="M346.62,308.33c-0.02,7.44-5.93,13.18-13.44,13.06c-7.39-0.12-13.34-6.28-13.06-13.53c0.27-6.99,6.07-12.62,13.12-12.71
                                    C340.61,295.05,346.64,300.98,346.62,308.33z"/>
                                <path d="M242.98,878.82c0.04,7.2-6.23,13.42-13.44,13.33c-7.35-0.08-13.21-6.19-13.03-13.59c0.17-7,5.93-12.83,12.87-13.01
                                    C236.47,865.38,242.93,871.68,242.98,878.82z"/>
                                <path d="M606.03,878.8c0.06,7.21-6.17,13.43-13.38,13.36c-7.36-0.07-13.18-6.14-13.02-13.57c0.15-7.02,5.87-12.84,12.81-13.04
                                    C599.56,865.35,605.98,871.6,606.03,878.8z"/>
                                <path d="M488.68,321.4c-7.45-0.18-13.11-6.22-12.79-13.66c0.3-7.13,5.96-12.58,13.1-12.62c7.39-0.05,13.24,5.87,13.17,13.33
                                    C502.09,315.92,496.19,321.58,488.68,321.4z"/>
                                <path d="M593.04,295.15c7.39,0.15,13.31,6.36,13,13.65c-0.31,7.34-6.52,12.94-13.97,12.6c-7.08-0.32-12.48-6.02-12.45-13.15
                                    C579.65,300.83,585.64,294.99,593.04,295.15z"/>
                                <path d="M761.71,775.69c-0.35,7.25-6.87,13.12-14.07,12.69c-7.25-0.44-13.01-7-12.46-14.2c0.54-7.03,6.41-12.3,13.55-12.14
                                    C756.07,762.2,762.06,768.5,761.71,775.69z"/>
                                <path d="M761.73,671.25c0.09,7.23-6.02,13.3-13.38,13.28c-7.43-0.02-13.33-5.99-13.2-13.36c0.12-6.92,5.92-12.78,12.86-12.98
                                    C755.23,657.97,761.64,664.08,761.73,671.25z"/>
                                <path d="M191.08,567.57c0.06,7.16-6.27,13.5-13.41,13.43c-6.73-0.06-12.9-6.07-13.2-12.84c-0.31-7.13,5.81-13.59,13.03-13.75
                                    C184.81,554.25,191.02,560.27,191.08,567.57z"/>
                                <path d="M177.63,398.9c7.41-0.09,13.46,5.85,13.45,13.18c-0.02,7.19-6.36,13.38-13.55,13.23c-6.85-0.14-12.84-6.06-13.06-12.9
                                    C164.25,405.2,170.3,398.99,177.63,398.9z"/>
                                <path d="M436.73,321.41c-7.46-0.21-12.95-6.17-12.64-13.74c0.3-7.1,5.98-12.57,13.06-12.56c7.43,0.01,13.28,5.97,13.16,13.42
                                    C450.21,315.93,444.21,321.62,436.73,321.41z"/>
                                <path d="M333.14,892.15c-7.22-0.15-13.32-6.59-13.01-13.74c0.29-6.83,6.37-12.76,13.16-12.83c7.23-0.08,13.36,6.09,13.32,13.39
                                    C346.56,886.36,340.49,892.3,333.14,892.15z"/>
                                <path d="M281.48,321.42c-7.51-0.03-13.13-5.83-12.99-13.39c0.13-7.23,5.53-12.71,12.7-12.9c7.46-0.2,13.35,5.51,13.42,13.03
                                    C294.69,315.68,288.98,321.46,281.48,321.42z"/>
                                <path d="M178.04,321.4c-7.43,0.13-13.57-5.76-13.59-13.05c-0.02-6.85,5.77-12.88,12.69-13.2c7.34-0.34,13.7,5.45,13.94,12.71
                                    C191.33,315.19,185.47,321.28,178.04,321.4z"/>
                                <path d="M177.62,892.15c-7.23-0.1-13.39-6.49-13.15-13.63c0.23-6.82,6.28-12.8,13.09-12.94c7.15-0.14,13.52,6.12,13.52,13.3
                                    C191.09,886.17,184.92,892.25,177.62,892.15z"/>
                            </g>
                            </svg>
                            {t('Task.Confirmation')}
                            <p className='task-count'>
                                {/* {props.taskSummary?.pendingConfirmation?.count || 0} */}
                                {props.taskSummary?.pendingConfirmation?.count > 99 ? "99+" : props.taskSummary?.pendingConfirmation?.count || 0}
                            </p>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="reserves-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#admission"
                            type="button"
                            role="tab"
                            aria-controls="admission"
                            aria-selected="false"
                            onClick={() => props.onClickTaskTypeHandler(props.taskSummary?.pendingAdmission?.id)}
                        >
                             <svg
                                fill="#ffffff"
                                width={8}
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 157.11 383.31"
                                xmlSpace="preserve"
                            >
                                <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                <g>
                                    <path
                                        d="M98.78,383.31c-0.3-0.2-0.57-0.49-0.91-0.6c-10.89-3.49-16.05-10.43-16.06-21.94c-0.03-39.8-0.01-79.59-0.02-119.39
                                  c0-1.37,0.31-2.93-0.24-4.06c-0.55-1.13-1.99-2.52-3.03-2.51c-1.04,0.01-2.46,1.42-3,2.55c-0.54,1.13-0.24,2.69-0.24,4.06
                                  c-0.01,39.55,0,79.09-0.01,118.64c-0.01,11.05-5.38,18.95-14.74,21.81c-12.93,3.96-26.16-5.31-26.85-18.81
                                  c-0.07-1.37-0.03-2.74-0.03-4.12c0-70.36,0.01-140.72-0.03-211.08c0-2.69,1.07-6.62-3.19-6.7c-4.23-0.07-3.4,3.81-3.41,6.55
                                  c-0.03,29.07,0.02,58.13-0.05,87.2c-0.02,9.48-7.83,15.72-16.73,13.61c-6.11-1.45-10.18-6.9-10.22-13.93
                                  c-0.06-12.1-0.02-24.2-0.02-36.3c0-23.95-0.01-47.9,0-71.86c0.02-21.5,14.14-35.71,35.57-35.73c28.7-0.03,57.4-0.03,86.09,0
                                  c21.2,0.02,35.4,14.27,35.41,35.51c0.02,35.93,0.02,71.86,0,107.78c-0.01,9.81-7.06,16.24-15.95,14.67
                                  c-6.31-1.11-10.85-6.25-10.98-12.79c-0.14-7.48-0.05-14.97-0.05-22.45c0-21.96,0-43.91,0-65.87c0-1.12,0.37-2.51-0.15-3.31
                                  c-0.83-1.28-2.16-2.77-3.48-3c-0.8-0.14-2.28,1.8-2.89,3.07c-0.49,1.02-0.12,2.45-0.12,3.7c0,69.86,0,139.72,0,209.58
                                  c0,15.16-3.42,20.27-17.2,25.7C103.77,383.31,101.27,383.31,98.78,383.31z"
                                    />
                                    <path
                                        d="M78.54,75.66c-20.9,0-37.88-16.95-37.88-37.81C40.66,17.04,57.71,0,78.55,0c20.85,0,37.86,17,37.88,37.85
                                  C116.45,58.68,99.43,75.67,78.54,75.66z"
                                    />
                                </g>
                            </svg>
                            {t('Task.Admission')}

                            <p className='task-count'>
                                {/* {props.taskSummary?.pendingAdmission?.count || 0} */}
                                {props.taskSummary?.pendingAdmission?.count > 99 ? "99+" : props.taskSummary?.pendingAdmission?.count || 0}
                            </p>
                        </button>
                    </li>

                </ul>

                <div className="tab-content tab-custom-height-task mt-3" id="myTabContent">
                    <div className="tab-pane fade show active" id="Split-Funding" role="tabpanel" aria-labelledby="admitted-tab">
                       <SplitFundingContainer closeSideMenu={closeSideMenu} taskList={props.taskList} currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} getCurrentPage={(page: any) => props.getCurrentPage(page)} />
                    </div>

                    <div className="tab-pane fade" id="Invoice" role="tabpanel" aria-labelledby="reserved-tab">
                        <InvoiceContainer closeSideMenu={closeSideMenu} taskList={props.taskList} currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} getCurrentPage={(page: any) => props.getCurrentPage(page)} />
                    </div>

                    <div className="tab-pane fade" id="confirmation" role="tabpanel" aria-labelledby="reserved-tab">
                        <ConfirmationContainer closeSideMenu={closeSideMenu} taskList={props.taskList} currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} getCurrentPage={(page: any) => props.getCurrentPage(page)} />
                    </div>

                    <div className="tab-pane fade" id="admission" role="tabpanel" aria-labelledby="reserved-tab">
                        <AdmissionContainer closeSideMenu={closeSideMenu} taskList={props.taskList} currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} getCurrentPage={(page: any) => props.getCurrentPage(page)} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TaskView;