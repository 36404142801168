import React, { useEffect, useState } from 'react';
import ServicetypeSliderView from './ServicetypeSliderView';
import { GetServiceTypeListService } from '../../../../service/serviceTypeNHS-services';

const ServicetypeSliderContainer = () => {
    const [serviceTypeList, setServiceTypeList] = useState<any>([]);
    const GetServiceTypeList = async () => {
        const result = await GetServiceTypeListService();
        if (result?.data?.length > 0) {
            setServiceTypeList(result?.data);
        }
    };
    useEffect(() => {
        const executeAPI = async () => {
            await GetServiceTypeList();
        };
        executeAPI();
    }, []);
    return <div>{serviceTypeList?.length > 0 && <ServicetypeSliderView serviceTypeList={serviceTypeList} />}</div>;
};

export default ServicetypeSliderContainer;
