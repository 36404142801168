import React, { useEffect , useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DashboardView from './indexView';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientReservedData } from '../../service/dashboard-service';
import { removePatientDraftAction } from '../../store/dashboard/draftPatientData';
import { rightNavigationPage } from '../../utils/constant';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { RootState } from '../../store';
import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';
import RenderModalContainer from '../../components/RenderModal/indexContainer';
import SplitFundingModalContainer from '../../components/SplitFundingModal/indexContainer';
import { getPlanData } from '../../service/dashboard-service';
import { setTempSplitFundingAction } from '../../store/splitFunding/tempSplitFundingReducer';
import { saveSplitFunding } from '../../service/invoicedetail-service';
import { GetNotificationsCount } from '../../service/notifications-services';

const DashboardPage = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showModal, setShowModal] = useState(false);
    const [admissionid, setadmissionid] = useState<any>();
    const [isaddplan, setisaddplan] = useState<boolean>(false);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    async function getpatientdata(pid:any) {
        const payload: any = {id:pid};
        await getPatientReservedData(payload);
    }

    let plans: any = [];
    let funders: any = [];
    var planlist:any=[];

    const showTaskDetailHandler = (data: any) => {
        setadmissionid(data.admissionId)
        getplandata(data.admissionId)
    }

    const addPlan = () => {};

    const savedata = () => {
        savesplitfunding();        
    };

    async function savesplitfunding() {
        let fundersdata =[];
        planlist = [];
          if(TempDataSplitFunding.length > 0){
              
              for(let i=0;i<TempDataSplitFunding.length;i++){
                  let obj : any ={};
                  fundersdata=[];
                  obj.planId = TempDataSplitFunding[i].planId;
                  obj.weeklyCharge = TempDataSplitFunding[i].corefee;
                  obj.StartDate = TempDataSplitFunding[i].admissiondate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].admissiondate) : '';
                  obj.endDate = TempDataSplitFunding[i].enddate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].enddate) : '';
  
                  for(let j=0;j<TempDataSplitFunding[i].funderlist.length;j++){
                      fundersdata.push({planDetailId : TempDataSplitFunding[i].funderlist[j].planDetailId,funderid : TempDataSplitFunding[i].funderlist[j].funderid === null ? null : Number(TempDataSplitFunding[i].funderlist[j].funderid),amountType:TempDataSplitFunding[i].funderlist[j].ispercentage ? 2 : 1,proportion: Number(TempDataSplitFunding[i].funderlist[j].proportion),proportionValue:Number(TempDataSplitFunding[i].funderlist[j].proportionvalue),isApplicable:TempDataSplitFunding[i].funderlist[j].isapplicable ? 0 : 1})
                  }
  
                  obj.planDetails = fundersdata;
                  planlist = [...planlist,obj];
              }
              let data={
                  admissionId : admissionid,
                  plans:planlist
              }
             const result =  await saveSplitFunding(data);
             if (result.status) {
              swal(result?.message, {
                  icon: 'success'
              }).then(()=>{
                setShowModal(false);
              });
          } else {
              swal(result?.message, {
                  icon: 'error'
              });
          }
          }
      }

    async function getplandata(aid: any) {
        const response = await getPlanData(aid);
        if (response.status == true) {
            setShowModal(true);
            plans = [];
            for (let j = 0; j < response.data.length; j++) {
                if (response.data[j].planType == 0) {
                    let obj: any = {};
                    funders = [];
                    obj.corefee = response?.data[j]?.weeklyCharge;
                    obj.admissiondate = response?.data[j]?.startDate?.toString();
                    obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                    obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                    obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                    obj.planId = response?.data[j]?.planId;
                    for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                        funders.push({
                            planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                            funderid: response?.data[j]?.planDetails[i].funderId,
                            fundername: response?.data[j]?.planDetails[i].funderName,
                            fundertype:
                                response?.data[j]?.planDetails[i].funderType === 1
                                    ? 'ICB'
                                    : response?.data[j]?.planDetails[i].funderType === 2
                                    ? 'LA'
                                    : response?.data[j]?.planDetails[i].funderType === 3
                                    ? 'Charity'
                                    : 'Self',
                            proportion: response?.data[j]?.planDetails[i].proportion,
                            proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                            isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                            ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                        });

                        obj.funderlist = funders;
                    }
                    plans = [...plans, obj];
                    dispatch(setTempSplitFundingAction(plans));
                }
            }
        }
    }

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
            }
        });
    };

    useEffect(() => {
        if((searchParams.get("opt_type") !== null && searchParams.get("opt_type") !== undefined && searchParams.get("opt_type") === "admission") && (searchParams.get("pid") !== null && searchParams.get("pid") !== undefined)) {
            dispatch(removePatientDraftAction());
            getpatientdata(window.$crypto.OpenSSLDecrypt(window.$crypto.base64Decode(searchParams.get("pid")))).then((res:any)=>{
                const data={
                    isDraftAdd : "reserved"
                }
                let obj: any = {
                    ShowClass: true,
                    FormName: rightNavigationPage.Vacant,
                    Data: data,
                    ShowToggle:false
                };
                dispatch(setNavigationAction(obj));
            })
        } else if((searchParams.get("opt_type") !== null && searchParams.get("opt_type") !== undefined && searchParams.get("opt_type") === "splitfund") && (searchParams.get("aid") !== null && searchParams.get("aid") !== undefined)) {
            let data = {
                admissionId: window.$crypto.OpenSSLDecrypt(window.$crypto.base64Decode(searchParams.get("aid")))
            }
            showTaskDetailHandler(data)
        }
    }, [searchParams])

    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
          GetNotificationCount();
      },[])
      const GetNotificationCount = async () => {
        await GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0 )
    }
    return <>
    <DashboardView />
    <RenderModalContainer>{showModal && <SplitFundingModalContainer onAddplan={addPlan} onSave={savedata} onClose={toggle} isaddplan={isaddplan} />}</RenderModalContainer>
    </>;
};

export default DashboardPage;
