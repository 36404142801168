
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";

export const EventKey = {
    MapLayoutChange: "mapLayoutChange",
    ListLayoutChange: "listLayoutChange"
} as const;

export enum BedAvailability {
    Available = "Available",
    Occupied = "Occupied",
    Filling_Fast = "Filling Fast",
}

export enum rightNavigationPage {
    Vacant = 'Vacant',
    Admission = 'Admission',
    Draft = 'Draft',
    Occupied = 'Occupied',
    Reserved = 'Reserved',
    Maintenance = 'Maintenance',
    MaintenanceList = 'MaintenanceList',
    InvoiceDetailPage = 'InvoiceDetailPage',
    BillingDetailsPage = 'BillingDetailsPage',
    InvoiceTablePage = 'InvoiceTablePage',
    Task = 'Task',
    CreateLead = 'CreateLead',
    ReferralStages = 'ReferralStages',
    ServiceSidebar = 'ServiceSidebar',
    Messages= 'Messages',
    Notifications='Notifications',
    ReferralList = 'ReferralList',
    ServiceReferralSidebar = "ServiceReferralSidebar",    
}

export enum InvoiceStatisticsFrequency {
    CurrentYear = 1,
    LastYear = 2,
    CurrentMonth = 3,
    LastMonth = 4
}

export enum AdmissionList {
    Admitted = 1,
    Reserved = 2
}

export enum ReasonList {
    Discharge =1, 
    Transfer =2, 
    Release = 3,
    Maintenance = 4,
    Close = 5
}

export enum coreFeesType {
    Daily =1, 
    Weekly =2
}

export enum InvoiceFilterInvoiceType {
    Interim = 1, 
    Discharge = 2,
    Reserve = 3
}

export enum InvoiceType {
    Interim = 1, 
    Discharge = 2,
    Reserve = 3
}

export enum InvoiceFilterInvoiceStatus {
    Draft = 1, 
    PostedOrConfirm = 2,
    Cancelled = 3
}

export enum InvoiceStatus {
    Draft = 1, 
    PostedOrConfirm = 2,
    Cancelled = 3
}

export enum InvoiceFilterPaymentStatus {
    Paid = 1, 
    InPayment = 2,
    Unpaid = 3,
    Overdue = 4
}

export enum ServiceTypeIconName {
    Hospital = "Hospital",
    ResidentialCare = "ResidentialCare",
    SupportedLiving = "SupportedLiving",
    NursingCare = "NursingCare"
}


export enum ServiceType {
    Hospital = "Hospital",
    ResidentialCare = "Residential Care",
    SupportedLiving = "Supported Living",
    NursingCare = "Nursing Care"
}
export enum ServiceUserType {
    Acute = "Acute",
    Respite = "Respite",
    LongTerm = "Long Term",
    D2A = "D2A"
}
export enum LeadStatus {
    Lead = "Lead",
    Pooling = "Pooling",
    Assessment = "Assessment",
    Costing = "Costing",
    Assignment = "Assignment",
    Contract = "Contract"
}

export enum LeadFilterReferralStatus {
    Open = 1, 
    Closed = 2
}

export enum LeadFilterServiceType {
    Hospital = 1,
    ResidentialCare = 2,
    SupportedLiving = 3, 
    NursingCare = 4,    
}

export enum LeadFilterServiceUserType {
    Respite = 1, 
    LongTerm = 2,
    D2A = 3
}
export enum Roles {
    ReferralManager = 1, 
    ServiceManager = 2,
    NHS = 3
}

export const RolesArray = [1, 2, 3]

export enum Modules {
    ReferralManagement = "referralManagement",
    BedManagement = "bedManagement",
    BillingManagement = "billingManagement",
    OnlineHelp = "onlineHelp",
    Administrator = "administrator",
    ViewMap = "viewMap"
}

export const ModulesAccessRights = {
    ReferralManagement : {
        moduleName: "referralManagement",
        userAccess: {
            accessCode: "SNDPRNTRTCOST",
            accessName: "send&PrintRTCosting"
        },
        exportAccess: {
            accessCode: "RM1003",
            accessName: "exportReferralDetails"
        }
    },
    BillingManagement : {
        moduleName: "billingManagement",
        editPostedInvoiceuserAccess: {
            accessCode: "BM1001",
            accessName: "editPostedInvoice"           
        },
        sendandPrintInvoiceuserAccess: {            
            accessCode: "SNDPRNTINV",
            accessName: "send&PrintInvoice"
        }
    },
    BedManagement : {
        moduleName: "bedManagement",
        exportAccess: {
            accessCode: "BM1002",
            accessName: "exportBedDetails"
        }
    },
    Administrator : {
        moduleName: "administrator",
        viewAccess: {
            accessCode: "AM1001",
            accessName: "viewIntegrationInterface"
        },
        userManagementAccess: {
            accessCode: "AM1002",
            accessName: "viewIntegrationInterface"
        }
    },
}

export enum StatisticsFrequency {
    CurrentYear = 1,
    LastYear = 2,
    CurrentMonth = 3,
    LastMonth = 4
}

export enum AssessmentStatus {
    InProgress = 1,
    Submitted = 2,
    Skipped = 3
}

export enum Source {
    Integration = "integration",
    Admission = "admission",
    Referral = "referral"
}

export const DEFAULT_PAGE_SIZE = 18

export const optionMapStyles = [
    {
        "stylers": [
            {
                "hue": "#bae3c5"
            },
            {
                "saturation": 0,
                "lightness": 33
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#222222"
            },
            {
                weight: 0.7
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#333333"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#b3e6f4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#1a8cff"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#C3C0BF"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8c8c8c"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]
export const DigitalContractEnum =   {
    Charity: 0,
    ICB: 1,
    LA: 2,
    Self: 3,
    LA_ICB: 4,
    LA_Self: 5,
    ICB_Self: 6,
    ICB_LA_Self: 7,
    ICB_Charity: 8,
    LA_Charity: 9,
    Charity_Self: 10,
    ICB_LA_Charity: 11,
    ICB_Charity_Self: 12,
    LA_Charity_Self: 13,
    ICB_LA_Charity_Self: 14,
}

export const combination = [
    { funders: ["ICB", "Charity"] },
    { funders: ["LA", "Charity"] },
    { funders: ["Charity", "Self"] },
    { funders: ["ICB", "LA", "Charity"] },
    { funders: ["ICB", "Charity", "Self"] },
    { funders: ["LA", "Charity", "Self"] },
    { funders: ["ICB", "LA", "Charity", "Self"] }
]


export enum IntegrationSource {
    All = 0, 
    LocateCareToOdoo = 1,
    IQVIAToLocateCare = 2,
    LocateCareToIQVIA = 3
}
