import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealeaseReasonsModel } from '../../utils/model';

const initialState: RealeaseReasonsModel = {
    StatusListReasons: false,
    DataListReasons: '',
    MessageListReasons: '',
    ErrorListReasons: ''
};

export const listRealeaseReasonsSlice = createSlice({
    name: 'listRealeaseReasons',
    initialState,
    reducers: {
        setListRealeaseReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = true;
            state.DataListReasons = payload;
            state.MessageListReasons = 'Success';
        },
        errorListRealeaseReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = [];
            state.MessageListReasons = 'Failed';
            state.ErrorListReasons = payload;
        },
        removeListRealeaseReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = '';
            state.MessageListReasons = '';
            state.ErrorListReasons = '';
        }
    },
    extraReducers: {}
});

export const { setListRealeaseReasonsAction, errorListRealeaseReasonsAction, removeListRealeaseReasonsAction } = listRealeaseReasonsSlice.actions;

export default listRealeaseReasonsSlice.reducer;
