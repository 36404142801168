import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VacantBedModel } from '../../utils/model';

const initialState: VacantBedModel = {
    StatusVacantBedReasons: false,
    DataVacantBedReasons: '',
    MessageVacantBedReasons: '',
    ErrorVacantBedReasons: ''
};

export const listVacantBedSlice = createSlice({
    name: 'listVacantBed',
    initialState,
    reducers: {
        setVacantBedAction: (state, { payload }: PayloadAction) => {
            state.StatusVacantBedReasons = true;
            state.DataVacantBedReasons = payload;
            state.MessageVacantBedReasons = 'Success';
        },
        errorVacantBedAction: (state, { payload }: PayloadAction) => {
            state.StatusVacantBedReasons = false;
            state.DataVacantBedReasons = [];
            state.MessageVacantBedReasons = 'Failed';
            state.ErrorVacantBedReasons = payload;
        },
        removeVacantBedAction: (state, { payload }: PayloadAction) => {
            state.StatusVacantBedReasons = false;
            state.DataVacantBedReasons = '';
            state.MessageVacantBedReasons = '';
            state.ErrorVacantBedReasons = '';
        }
    },
    extraReducers: {}
});

export const { setVacantBedAction, errorVacantBedAction, removeVacantBedAction } = listVacantBedSlice.actions;

export default listVacantBedSlice.reducer;
