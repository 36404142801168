import React, { useState, useEffect, useMemo } from 'react';
import ReservedContractListView from './reservedContractListView';
import ReservedApplicableRatesView from './applicableRatesView';
import { getListContractService } from '../../../../../../../service/contract-service';
import { RootState } from '../../../../../../../store';
import { useSelector, useDispatch } from 'react-redux';

let PageSize = 5;

const ReservedContractListContainer = (props: any) => {
    const [listContract, setLisContract] = useState<any>();
    const { StatusListContract, DataListContract } = useSelector((state: RootState) => state.listContract);
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    const { admissionId } = props.patientData;

    useEffect(() => {
        async function getContractList() {
            const payload: any = { id: admissionId};
            await getListContractService(payload);
        }
        getContractList();
    },[admissionId])
    useEffect(() => {
        if (StatusListContract === true) {
            setLisContract(DataListContract.data)
        }
    },[StatusListContract, DataListContract])
    return (
        <div>
            {listContract !== undefined &&
                listContract !== null &&
                listContract.contractDetails.length > 0 &&
                listContract.contractDetails.map((data: any, index: any) => {
                    return <ReservedContractListView key={index} dataContract={data} accordID={index} />;
                })}

{listContract !== undefined &&
                listContract !== null &&
                listContract.applicableRates !== '' &&
                <ReservedApplicableRatesView dataApplicableRates={listContract.applicableRates} />}
        </div>
    );
};

export default ReservedContractListContainer;
