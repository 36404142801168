import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../../../../store';
import { ServiceTypeIconName } from '../../../../../../../utils/constant';
import { deleteContactDocument, getContactDocument } from '../../../../../../../service/dashboard-service';
import swal from 'sweetalert';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import NoteContainer from '../../../../../../../components/NoteModal/indexContainer';
import warning from '../../../../../../../assets/images/warning.png';
import note from '../../../../../../../assets/images/note.svg';

const AddFunderView = (props: any) => {
    const { t } = useTranslation();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    const validationSchema = Yup.object().shape({
        fEmailId: Yup.string()
            .trim()
            .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        fFunder: Yup.string().trim().required(),
        fContactNo: Yup.string()
            .trim()
            .matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
            .nonNullable()
            .required(),
        fAddress1: Yup.string().trim().required(),
        fCity: Yup.string().required(),
        fPoBox: Yup.string()
            .trim()
            .matches(/^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
            .nonNullable()
            .required(),
        // funderContract: Yup.string().required(),
        fCheck: Yup.boolean().notRequired(),
        ftype: Yup.string().nonNullable().notRequired(),
        fpo: Yup.string().notRequired(),
        cEmailId: Yup.string().when('ftype', {
            is: '4',
            then : (schema: any) => schema.nonNullable().notRequired(),
            otherwise:  (schema: any) => schema.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required('Email is required')}),
        cFunder:  Yup.string().when('ftype', {
            is: '4',
            then : (schema: any) => schema.nonNullable().notRequired(),
            otherwise:  (schema: any) =>schema.required()}),
        cContactNo:  Yup.string().when('ftype', {
            is: '4',
            then : (schema: any) => schema.nonNullable().notRequired(),
            otherwise:  (schema: any) => schema.matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
            .nonNullable()
            .required('contact is required')}),
        cAddress1: Yup.string().trim().notRequired(),
        cCity: Yup.string().trim().notRequired(),
        cPoBox: Yup.string()
            .trim()
            .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
            .nonNullable()
            .notRequired(),
        sEmailId: Yup.string().when('ftype', {
            is: (val:any) => (StatusServiceDetail && DataServiceDetail.iconName == ServiceTypeIconName.Hospital) || (StatusServiceDetail && DataServiceDetail.iconName !== ServiceTypeIconName.Hospital && (val === '4' || val ==='1')),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) =>
                schema
                    .trim()
                    .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                    .email('Email is Invalid')
                    .required()
        }),
        sFunder: Yup.string().when('ftype', {
            is: (val:any) => (StatusServiceDetail && DataServiceDetail.iconName == ServiceTypeIconName.Hospital) || (StatusServiceDetail && DataServiceDetail.iconName !== ServiceTypeIconName.Hospital && (val === '4' || val === '1')),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) => schema.required()
        }),
        sContactNo: Yup.string().when('ftype', {
            is: (val:any) => (StatusServiceDetail && DataServiceDetail.iconName == ServiceTypeIconName.Hospital) || (StatusServiceDetail && DataServiceDetail.iconName !== ServiceTypeIconName.Hospital && (val === '4' || val === '1')),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) =>
                schema
                    .trim()
                    .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                    .nonNullable()
                    .required()
        }),
        sAddress1: Yup.string().trim().notRequired(),
        sCity: Yup.string().trim().notRequired(),
        sPoBox: Yup.string()
            .trim()
            .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
            .nonNullable()
            .notRequired()
    });

    const { register, control, handleSubmit, reset, getValues, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    

    useEffect(() => {
        setValue('fFunder', '');
        setValue('fCity', '');
        setValue('cCity', '');
    }, []);
    const [isValid, setisValid] = useState<any>(false);
    const [file, setFiles] = useState<any[]>([]);
    const [fileres, setFileres] = useState<any>('');
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const [funderNote, setFunderNote] = useState<any>('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [isEditing, setisEditing] = useState(false);
    const [funderName, setFunderName] = useState<any>('');
    useEffect(() => {
        if (props.changetype) {
            setValue('fFunder', '');
            setValue('fEmailId', '');
            setValue('fContactNo', '');
            setValue('fAddress1', '');
            setValue('fCity', '');
            setValue('fPoBox', '');
            setValue('fpo', '');
            setValue('cFunder', '');
            setValue('cEmailId', '');
            setValue('cContactNo', '');
            setValue('cAddress1', '');
            setValue('cCity', '');
            setValue('cPoBox', '');

            setValue('sFunder', '');
            setValue('sEmailId', '');
            setValue('sContactNo', '');
            setValue('sAddress1', '');
            setValue('sCity', '');
            setValue('sPoBox', '');
        }
    }, [props.changetype]);

    useEffect(() => {
        if (props.funderdata !== '') {
            
            setValue('fFunder', props.funderdata.funderId);
            setValue('fEmailId', props.funderdata.funderEmail);
            setValue('fContactNo', props.funderdata.contactNo);
            setValue('fAddress1', props.funderdata.address1);
            setValue('fCity', props.funderdata.countyId);
            setValue('fPoBox', props.funderdata.postalCode);
            setValue('cFunder', props.funderdata.commissionerName);
            setValue('cEmailId', props.funderdata.commissionerEmail);
            setValue('cContactNo', props.funderdata.commissionerPhoneNo);
            setValue('cAddress1', props.funderdata.commissionerAddressLine1);
            setValue('cCity', props.funderdata.commissionerCountyId);
            setValue('cPoBox', props.funderdata.commissionerPostalCode);
            setValue('sFunder', props.funderdata.socialWorkerName);
            setValue('sEmailId', props.funderdata.socialWorkerEmail);
            setValue('sContactNo', props.funderdata.socialWorkerPhoneNo);
            setValue('sAddress1', props.funderdata.socialWorkerAddressLine1);
            setValue('sCity', props.funderdata.socialWorkerCountyId);
            setValue('sPoBox', props.funderdata.socialWorkerPostalCode);
            setValue('fpo',props.funderdata.poReference);
            //   setTimeout(() => {
            //     if(props.funderdata.funderTypeId === 0){
            //     setValue('ftype',"4")
            //     }
            //     else{
            //         setValue('ftype',props.funderdata.funderTypeId.toString())
            //     }
            //   }, 500);
        }
    }, [props.funderdata]);

    useEffect(() => {
        //    if(getValues('ftype') === '4'){
        
        setTimeout(() => {
            if (props.fundertype === '0' || props.fundertype === '4') {
                let isexists;
                if(DataLeadInfo?.data?.contract?.length > 0){
                isexists = [...DataLeadInfo?.data?.contract].some((val: any,index:number) =>{
                    
                    if (val.funderName === DataLeadInfo?.data?.serviceUserDetails?.serviceUserName){
                        if(val.status !== ""){
                            if(val.status === 'Voided' || val.status === 'Cancelled'){
                                return false
                            } else{
                                return true
                            }
                        } else{
                            return true
                        }
                    } else {
                        return false;
                    }
                } );
                }
                
                if (isexists) {
                    swal('Funder is already added', {
                        icon: 'error',
                        dangerMode: true
                    });
                } else {
                
                setValue('ftype', '4');
                setValue('fFunder', DataLeadInfo?.data?.serviceUserDetails?.serviceUserName, { shouldValidate: true });

                setValue('fEmailId', DataLeadInfo?.data?.serviceUserDetails?.email !== '' ? DataLeadInfo?.data?.serviceUserDetails?.email : props.funderdata.funderEmail, { shouldValidate: true });
                setValue('fContactNo', DataLeadInfo?.data?.serviceUserDetails?.contactNo !== '' ? DataLeadInfo?.data?.serviceUserDetails?.contactNo : props.funderdata.contactNo, {
                    shouldValidate: true
                });
                setValue('fAddress1', DataLeadInfo?.data?.serviceUserDetails?.address1 !== '' ? DataLeadInfo?.data?.serviceUserDetails?.address1 : props.funderdata.address1, { shouldValidate: true });
                setValue(
                    'fCity',
                    DataLeadInfo?.data?.serviceUserDetails?.countyId !== 0 && DataLeadInfo?.data?.serviceUserDetails?.countyId !== null
                        ? DataLeadInfo?.data?.serviceUserDetails?.countyId
                        : props.funderdata.countyId,
                    { shouldValidate: true }
                );
                setValue('fPoBox', DataLeadInfo?.data?.serviceUserDetails?.postalCode !== '' ? DataLeadInfo?.data?.serviceUserDetails?.postalCode : props.funderdata.postalCode, {
                    shouldValidate: true
                });
            } 
        }
            else {
                setValue('ftype', props.fundertype?.toString());
            }
        }, 500);
        if (props.fundertype === 0) {
        }
    }, [props.fundertype]);

    useEffect(() => {
        if (!props.isCheck) {
            setValue('fCheck', false);
        }
    }, [props.isCheck]);

    useEffect(() => {
        
        if (formState.isValid) {
            setisValid(true);
        } else {
            setisValid(false);
        }
    }, [formState]);

    

    useEffect(() => {
        if (props.commissionerData != '') {
            setValue('cFunder', props.commissionerData.cFunder || '');
            setValue('cEmailId', props.commissionerData.cEmailId || '');
            setValue('cContactNo', props.commissionerData.cContactNo || '');
            setValue('cAddress1', props.commissionerData.cAddress1 || '');
            setValue('cCity', props.commissionerData.cCity || '');
            setValue('cPoBox', props.commissionerData.cPoBox || '');
        }
    }, [props.commissionerData]);

    const updateFileds = (e: any,fid:any) => {
        if(DataLeadInfo?.data?.contract !== null){
            const isexists = [...DataLeadInfo?.data?.contract].filter((el => (el.status !== 'Voided' && el.status !== 'Cancelled'))).some((val: any) => {
                if(fid?.funderTypeId !== val.funderTypeId){
                if (val.funderId === Number(e.target.value)){
                    if(val.status !== ""){
                        if(val.status === 'Voided' || val.status === 'Cancelled'){
                            return false
                        } else{
                            return true
                        }
                    } else{
                        return true
                    }
                } else {
                    return false;
                }
            } else{
                return true
            }
            });
            
            if (isexists) {
                swal('Funder is already added', {
                    icon: 'warning',
                    dangerMode: true
                });
                props.updateData(e);
                setValue('fFunder', '', { shouldValidate: true });
                setValue('fEmailId', '', { shouldValidate: true });
                setValue('fContactNo', '', { shouldValidate: true });
                setValue('fAddress1', '', { shouldValidate: true });
                setValue('fCity', '', { shouldValidate: true });
                setValue('fPoBox', '', { shouldValidate: true });
            } else {
                for (let i = 0; i < DataFunderInfo?.data?.length; i++) {
                    if (DataFunderInfo?.data[i]?.key === Number(e.target.value)) {
                        setValue('fEmailId', DataFunderInfo.data[i].funderEmail || '', { shouldValidate: true });
                        setValue('fContactNo', DataFunderInfo.data[i].funderPhoneNo || '', { shouldValidate: true });
                        setValue('fAddress1', DataFunderInfo.data[i].funderAddressLine1 || '', { shouldValidate: true });
                        setValue('fCity', DataFunderInfo.data[i].funderCityId?.toString() || '', { shouldValidate: true });
                        setValue('fPoBox', DataFunderInfo.data[i].funderPobox || '', { shouldValidate: true });
                        setFunderName(DataFunderInfo.data[i].value);
                        props.updateData(e);
                    }
                }
            }
        } else{
            for (let i = 0; i < DataFunderInfo?.data?.length; i++) {
                if (DataFunderInfo?.data[i]?.key === Number(e.target.value)) {
                    setValue('fEmailId', DataFunderInfo.data[i].funderEmail || '', { shouldValidate: true });
                    setValue('fContactNo', DataFunderInfo.data[i].funderPhoneNo || '', { shouldValidate: true });
                    setValue('fAddress1', DataFunderInfo.data[i].funderAddressLine1 || '', { shouldValidate: true });
                    setValue('fCity', DataFunderInfo.data[i].funderCityId?.toString() || '', { shouldValidate: true });
                    setValue('fPoBox', DataFunderInfo.data[i].funderPobox || '', { shouldValidate: true });
                    setFunderName(DataFunderInfo.data[i].value);
                    props.updateData(e);
                }
            }
        }
       
    };
    const handleNoteClickModal = (noteval:any) => {
        setFunderNote(noteval);
        setShowNoteModal(true);
    }
    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
            .then(async (confirm: any) => {
                if (confirm) {
                    setShowNoteModal(false);
                }
            })
    };

    const updateNote = (e: any,i: any=0) => {
        if(e.target.rawValue.length > 0)
        {
            setFunderNote(e.target.value);
            setisEditing(true);
        }else{
            setFunderNote('');
            setisEditing(false);
        }
        
        setShowNoteModal(false);
        props.updateData(e);
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-1 mt-2">
                <label className="mb-2">{t('LeadSidebar.ftype')}</label>

                <label className="form--radio-label mb-2  d-flex align-items-center">
                    <input
                        type="radio"
                        {...register('ftype', {
                            onChange: (e: any) => {
                                setFunderName('');
                                props.updateData(e);
                            }
                        })}
                        name={'ftype' as const}
                        id="CCG"
                        value="1"
                        disabled={props.isedit ? true : false}
                    />

                    <span className="ms-1">{t('LeadSidebar.ccg')}</span>
                </label>

                <label className="form--radio-label  mb-2 d-flex align-items-center">
                    <input
                        type="radio"
                        {...register('ftype', {
                            onChange: (e: any) => {
                                setFunderName('');
                                props.updateData(e);
                            }
                        })}
                        name={'ftype' as const}
                        id="LA"
                        value="2"
                        disabled={props.isedit ? true : false}
                    />

                    <span className="ms-1">{t('LeadSidebar.la')}</span>
                </label>

                <label className="form--radio-label  mb-2 d-flex align-items-center">
                    <input
                        type="radio"
                        {...register('ftype', {
                            onChange: (e: any) => {
                                setFunderName('');
                                props.updateData(e);
                            }
                        })}
                        name={'ftype' as const}
                        id="Charity"
                        value="3"
                        disabled={props.isedit ? true : false}
                    />

                    <span className="ms-1">{t('LeadSidebar.ch')}</span>
                </label>

                <label className="form--radio-label mb-2 d-flex align-items-center">
                    <input
                        type="radio"
                        {...register('ftype', {
                            onChange: (e: any) => {
                                setFunderName('');
                                props.updateData(e);
                            }
                        })}
                        name={'ftype' as const}
                        id="Self"
                        value="4"
                        disabled={props.isedit ? true : false}
                    />

                    <span className="ms-1">{t('LeadSidebar.self')}</span>
                </label>
            </div>
            {getValues('ftype') !== '4' && (
                <>
                    <div className="d-flex mb-2">
                        <select
                            {...register('fFunder', {
                                onChange: (e: any) => {
                               let fid = props?.funderlist?.data?.find((data:any)=>data.key === Number(e.target.value))
                                    updateFileds(e,fid);
                                }
                            })}
                            name={'fFunder' as const}
                            id="Funder"
                            disabled={props.isedit ? true : false}
                            //@ts-ignore
                            className={`form-control white flex-7 ${errors.fFunder ? 'is-invalid' : ''}`}
                        >
                            <option disabled={true} value="">
                                {t('LeadSidebar.fname')}
                            </option>
                            {props.funderlist &&
                                props.funderlist.data?.map((funders: any) => {
                                    return (
                                        <option key={funders.key} value={funders.key}>
                                            {funders.value}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </>
            )}
            {getValues('ftype') === '4' && (
                <>
                    <div className="d-flex mb-2">
                        <input
                            type="text"
                            disabled
                            {...register('fFunder')}
                            name={'fFunder' as const}
                            placeholder={`${t('LeadSidebar.fname')}`}
                            //@ts-ignore
                            className={`form-control white flex-7 ${errors.fFunder ? 'is-invalid' : ''}`}
                        />
                    </div>
                </>
            )}
            <input
                type="text"
                {...register('fEmailId', {
                    onChange: (e: any) => {
                        props.updateData(e);
                    }
                })}
                name={'fEmailId' as const}
                placeholder={`${t('VacantRightbar.email')}`}
                //disabled={getValues('ftype') === '4' && DataLeadInfo?.data?.serviceUserDetails?.email !== '' ? true : false}
                // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                //@ts-ignore
                className={`form-control white mb-2 ${errors.fEmailId ? 'is-invalid' : ''}`}
            />

            <div className="d-flex justify-content-between">
                <input
                    type="text"
                    {...register('fContactNo', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'fContactNo' as const}
                    placeholder={`${t('VacantRightbar.contact')}`}
                    //disabled={getValues('ftype') === '4' && DataLeadInfo?.data?.serviceUserDetails?.contactNo !== '' ? true : false}
                    // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.fContactNo ? 'is-invalid' : ''}`}
                />
            </div>
            <input
                type="text"
                {...register('fAddress1', {
                    onChange: (e: any) => {
                        props.updateData(e);
                    }
                })}
                name={'fAddress1' as const}
                placeholder={`${t('VacantRightbar.add1')}`}
                //disabled={getValues('ftype') === '4' && DataLeadInfo?.data?.serviceUserDetails?.address1 !== '' ? true : false}
                // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                //@ts-ignore
                className={`form-control white mb-2 ${errors.fAddress1 ? 'is-invalid' : ''}`}
            />
            <div className="d-flex justify-content-between">
                <select
                    {...register('fCity', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'fCity' as const}
                    id="city"
                    //disabled={getValues('ftype') === '4' && DataLeadInfo?.data?.serviceUserDetails?.countyId !== 0 ? true : false}
                    // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.fCity ? 'is-invalid' : ''}`}
                >
                    <option disabled={true} value="">
                        {t('VacantRightbar.county')}
                    </option>
                    {props.citylist &&
                        props.citylist.data?.map((cities: any) => {
                            return (
                                <option key={cities.key} value={cities.key}>
                                    {cities.value}
                                </option>
                            );
                        })}
                </select>
                <input
                    type="text"
                    {...register('fPoBox', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'fPoBox' as const}
                    placeholder={`${t('VacantRightbar.postal')}`}
                    //disabled={getValues('ftype') === '4' && DataLeadInfo?.data?.serviceUserDetails?.postalCode !== '' ? true : false}
                    // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                    //@ts-ignore
                    className={`form-control white mb-2 ms-2 ${errors.fPoBox ? 'is-invalid' : ''}`}
                />
            </div>
            <input
                        type="text"
                        {...register('fsuid', {
                            onChange: (e: any) => {                                
                                props.updateData(e);
                            }
                        })}
                        name={'fsuid' as const}
                        placeholder={`${t('VacantRightbar.Suid')}`}
                        // disabled={getValues('ftype') === '4' ? true : false}
                        // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        maxLength={40}
                    />           

                     <input
                        type="text"
                        {...register('fpo', {
                            onChange: (e: any) => {                                
                                props.updateData(e);
                            }
                        })}
                        name={'fpo' as const}
                        placeholder={`${t('VacantRightbar.po_reference')}`}
                        // disabled={getValues('ftype') === '4' ? true : false}
                        // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        maxLength={40}
                    />                   


                    { funderNote?.length == 0 && <div className='d-flex mt-2'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal('')}></img><p>{t('VacantRightbar.enter_note')}</p></div>}
                    { window.$utils.convertToPlain(funderNote)?.length >= 30 && <div className='d-flex mt-2'><img src={note} className='img-fluid me-1'></img><p>{window.$utils.convertToPlain(funderNote).slice(0,27)}...</p>  <p className='bold' onClick={()=>handleNoteClickModal(funderNote)}>{t('VacantRightbar.read_more')}</p></div>} 
                    { window.$utils.convertToPlain(funderNote)?.length > 0 && window.$utils.convertToPlain(funderNote)?.length < 30 && (<div className='d-flex mt-2'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(funderNote)}></img><p>{window.$utils.convertToPlain(funderNote)}</p></div> )}

             {getValues('ftype') !== '4' &&
             <>
            <label className="mb-2 bold mt-3">{t('VacantRightbar.comm')}</label>
            <div className="form-check">
                <input
                    {...register('fCheck', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'fCheck' as const}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkboxsameas"
                />
                <label className="form-check-label">{t('VacantRightbar.same')}</label>
            </div>

            <input
                type="text"
                {...register('cFunder', {
                    onChange: (e: any) => {
                        props.updateCData(e);
                    }
                })}
                name={'cFunder' as const}
                placeholder={`${t('LeadSidebar.cname')}`}
                //@ts-ignore
                className={`form-control white mb-2  ${errors.cFunder ? 'is-invalid' : ''}`}
            />

            <input
                type="text"
                {...register('cEmailId', {
                    onChange: (e: any) => {
                        props.updateCData(e);
                    }
                })}
                name={'cEmailId' as const}
                placeholder={`${t('VacantRightbar.email')}`}
                //@ts-ignore
                className={`form-control white mb-2  ${errors.cEmailId ? 'is-invalid' : ''}`}
            />

            <div className="d-flex justify-content-between">
                <input
                    type="text"
                    {...register('cContactNo', {
                        onChange: (e: any) => {
                            props.updateCData(e);
                        }
                    })}
                    name={'cContactNo' as const}
                    placeholder={`${t('VacantRightbar.contact')}`}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.cContactNo ? 'is-invalid' : ''}`}
                />
            </div>
            <input
                type="text"
                {...register('cAddress1', {
                    onChange: (e: any) => {
                        props.updateCData(e);
                    }
                })}
                name={'cAddress1' as const}
                placeholder={`${t('VacantRightbar.add1')}`}
                //@ts-ignore
                className={`form-control white mb-2  ${errors.cAddress1 ? 'is-invalid' : ''}`}
            />

            <div className="d-flex justify-content-between">
                <select
                    {...register('cCity', {
                        onChange: (e: any) => {
                            props.updateCData(e);
                        }
                    })}
                    name={'cCity' as const}
                    id="ccity"
                    //@ts-ignore
                    className={`form-control white mb-2  ${errors.cCity ? 'is-invalid' : ''}`}
                >
                    <option value="">{t('VacantRightbar.county')}</option>
                    {props.citylist &&
                        props.citylist.data?.map((cities: any) => {
                            return (
                                <option key={cities.key} value={cities.key}>
                                    {cities.value}
                                </option>
                            );
                        })}
                </select>
                <input
                    type="text"
                    {...register('cPoBox', {
                        onChange: (e: any) => {
                            props.updateCData(e);
                        }
                    })}
                    name={'cPoBox' as const}
                    placeholder={`${t('VacantRightbar.postal')}`}
                    //@ts-ignore
                    className={`form-control white mb-2 ms-2 ${errors.cPoBox ? 'is-invalid' : ''}`}
                />
            </div>
            {StatusServiceDetail && DataServiceDetail.iconName !== ServiceTypeIconName.Hospital && getValues('ftype') !== '1' && (
                <>
                    <label className="mb-2 bold mt-3">{t('VacantRightbar.social')}</label>
                    <input
                        type="text"
                        {...register('sFunder', {
                            onChange: (e: any) => {
                                props.updateSData(e);
                            }
                        })}
                        name={'sFunder' as const}
                        placeholder={`${t('VacantRightbar.sname')}*`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.sFunder ? 'is-invalid' : ''}`}
                    />

                    <input
                        type="text"
                        {...register('sEmailId', {
                            onChange: (e: any) => {
                                props.updateSData(e);
                            }
                        })}
                        name={'sEmailId' as const}
                        placeholder={`${t('VacantRightbar.email')}*`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.sEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <input
                            type="text"
                            {...register('sContactNo', {
                                onChange: (e: any) => {
                                    props.updateSData(e);
                                }
                            })}
                            name={'sContactNo' as const}
                            placeholder={`${t('VacantRightbar.contact')}*`}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.sContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('sAddress1', {
                            onChange: (e: any) => {
                                props.updateSData(e);
                            }
                        })}
                        name={'sAddress1' as const}
                        placeholder={`${t('VacantRightbar.add1')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.sAddress1 ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <select
                            {...register('sCity', {
                                onChange: (e: any) => {
                                    props.updateSData(e);
                                }
                            })}
                            name={'sCity' as const}
                            id="ccity"
                            //@ts-ignore
                            className={`form-control white mb-2  ${errors.sCity ? 'is-invalid' : ''}`}
                        >
                            <option value="">{t('VacantRightbar.county')}</option>
                            {props.citylist &&
                                props.citylist.data.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        <input
                            type="text"
                            {...register('sPoBox', {
                                onChange: (e: any) => {
                                    props.updateSData(e);
                                }
                            })}
                            name={'sPoBox' as const}
                            placeholder={`${t('VacantRightbar.postal')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.sPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                </>
            )}
            </>
            }

            <div className="mt-3 text-center">
                {props.isedit && (
                    <>
                        <button disabled={!isValid} onClick={props.updateFunder} className="btn btn-sm btn-primary flex-1 me-2" id="save-funder1">
                            Update Funder
                        </button>
                    </>
                )}
                {!props.isedit && (
                    <>
                        <button disabled={!isValid} onClick={props.addFunder} className="btn btn-sm btn-primary flex-1 me-2" id="save-funder1">
                            Add Funder
                        </button>
                    </>
                )}
            </div>
            <RenderModalContainer>
                            {showNoteModal && <NoteContainer  onClose={toggle} title={funderName != null  && funderName != '' ? funderName+"'s Notes" : t('VacantRightbar.funder_note')} setFunderNote={funderNote} notebody={updateNote}  isReadonly={false} isEditing={isEditing}/> }
            </RenderModalContainer>
        </>
    );
};

export default AddFunderView;