
import React from 'react';
import PatientInquiryView from './PatientInquiryView';

const PatientInquiryContainer = () => {
    return (
        <div>
            <PatientInquiryView/>
        </div>
    );
};

export default PatientInquiryContainer;