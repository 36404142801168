import React, { useState, useEffect } from 'react';
import ModalWrapperContainer from '../../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import WYSIWYGEditor from '../../WYSIWYGEditor/WYSIWYGEditor';
import MultipleFileUploader from '../../MultipleFileUploader/MultipleFileUploader';
import { sendCostingEmail } from '../../../service/costing';
import swal from 'sweetalert';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    className: any;
    footer: any;
}

const SendPrintCostingView = (props: any) => {
    const { t } = useTranslation();
    const [serviceName, setServiceName] = useState<any>(undefined);
    const [referalID, setReferalID] = useState<any>(undefined);
    const [validEmail, setValidEmail] = useState<any>(undefined);
    const [tempOptions, setTempOptions] = useState<any>([]);
    const [listAttachement, setListAttachment] = useState<any>([]);
    const [errorMFU, setErrorMFU] = useState<any>(undefined);
    const [singleAttachment, setSingleAttachement] = useState<any>(undefined);
    const [printPriview, setPrintPriview] = useState<any>(true);
    const [disableButton, setDisableButton] = useState<any>(false);


    const validationSchema = Yup.object().shape({
        recipients: Yup.array().min(1).max(10, "Maximum only 10 recipients are allowed.").required(),
        subject: Yup.string().required(),
        body: Yup.string().required(),
        attachment: Yup.mixed().test('required', 'You need to provide a file', (file) => {
            // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
            if (file) return true;
            return false;
        })
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    const validateEmail = (email: any) => {
        var re = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email?.toLowerCase());
        // return email
        //   .toLowerCase()
        //   .match(
        //     /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   );
    };

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(reader.result);
        //@ts-ignore
        reader.onload = () => resolve(reader.result.substr(reader.result.indexOf(',') + 1));
        reader.onerror = reject;
    });

    useEffect(() => {
        if(props?.costingRecepients !== undefined && props?.costingRecepients.length > 0) {
            let recepients: any = []
            props?.costingRecepients.map((val: any) => {
                recepients.push({
                    name: val.name,
                    value: val.email
                })
            })
            setTempOptions(recepients)
        }
    }, [props.costingRecepients])

    useEffect(() => {
        if(props?.emailData?.subject) {
            setValue('subject', props?.emailData?.subject, { shouldValidate: true })
        }
        if(props?.emailData?.body) {
            setValue('body', props?.emailData?.body, { shouldValidate: true })
        }
        if(props?.emailData?.attachment){
            setSingleAttachement(props?.emailData?.attachment)
        }
    },[props.emailData])

    const printPrivewPDF = (event: any) => {
        setPrintPriview(event.target.checked)
    }

    const onSendPrintHandler = async() => {
        let recipients: any = []
        if(getValues('recipients') !== undefined && getValues('recipients') !== null && getValues('recipients').length > 0) {
            getValues('recipients').map((item: any) => {
                if(typeof item === 'object' && item !== null) {
                    recipients.push({
                            email: item.value
                        })
                } else {
                    recipients.push({
                        email: item
                    })
                }
            })
        }
        let attachment: any = []
        if(getValues('attachment') !== undefined && getValues('attachment') !== null && getValues('attachment').length > 0) {
            await Promise.all(
                getValues('attachment').map(async(item: any, index: any) => {
                    if(index === 0) {
                        attachment.push({
                            fileName: item.name,
                            fileType: item.type,
                            content: singleAttachment.attachmentBase64
                        })
                    } else {
                        attachment.push({
                            fileName: item.name,
                            fileType: item.type,
                            content: await toBase64(item)
                        })
                    }
                })
            )
        }
        let payloadObj = {
            recipients: recipients,
            body: getValues('body'),
            subject: getValues('subject'),
            attachment: attachment
        }
        const response = await sendCostingEmail(payloadObj)
        if (response?.status === true) {
            swal(`Email for referral ${referalID} costing for service ${serviceName} sent successfully.`, {
                icon: 'success'
            }) .then(async (confirm: any) => {
                props.onCloseWC();
                if(printPriview === true) {
                    props.onClickPrintPriview();
                }
            });

        } else {
            swal(response?.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
                props.onCloseWC();
            });;
        }
    }

    useEffect(() => {
        if(formState.isValid === false || validEmail === false) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [formState])

    useEffect(() => {
        if(props?.viewCosting?.referralId !== '') {
            setReferalID(props?.viewCosting?.referralId)
        }
        if(props?.viewCosting?.serviceName !== '') {
            setServiceName(props?.viewCosting?.serviceName)
        }
    }, [props.viewCosting])

    return (
        <div>
            <ModalWrapperContainer
                title={props.title}
                onClose={props.onCloseWC}
                className="modal modal-small child-modal"
                content={
                    <div>
                        <div className="d-flex">
                            <div className="form-check me-3">
                                <input className="form-check-input" id="vehicle1" type="checkbox" onClick={(event: any) => printPrivewPDF(event)} name="check1" checked={printPriview} />
                                <label className="form-check-label bold me-2"  data-tooltip-id="my-tooltip-print" data-tooltip-content="Preview as a PDF">{t('SendPrintCosting.Print')}</label>
                                {/* <span>{t('SendPrintCosting.Preview_PDF')}</span> */}
                            </div>
                            {/* <div className="form-check me-2">
                                <input className="form-check-input" id="vehicle2" type="checkbox" value="Email" name="check2" checked />
                                <label className="form-check-label bold">{t('SendPrintCosting.Email')}</label>
                            </div> */}
                        </div>
                        <div className="d-flex mt-2">
                            <label className="me-3 bold flex-1">{t('SendPrintCosting.Recipients')}:</label>
                            <div className="input-group multiselect-recipients-dropdown ">
                                <Controller
                                    control={control}
                                    name="recipients"
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        return (
                                            <Autocomplete
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Tab') {
                                                        event.key = 'Enter'; // will call `onChange`
                                                    }
                                                }}
                                                multiple
                                                className="tempMUIInput"
                                                id="tags-filled"
                                                options={tempOptions}
                                                getOptionLabel={(option: any) => option.name || option}
                                                freeSolo
                                                renderTags={(tagValue: any, getTagProps) =>
                                                    tagValue.map((option: any, index: any) => <Chip variant="outlined" data-tooltip-id="my-tooltip-email" data-tooltip-content={option.value} label={option.name || option} {...getTagProps({ index })} />)
                                                }
                                                ListboxProps={{
                                                    sx: { fontSize: 13,fontFamily: 'Roboto' },
                                                  }}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="filled"
                                                        placeholder='Add contacts to notify...'
                                                        onChange={(e: any) => (validateEmail(e.target.value) === false ? setValidEmail(false) : setValidEmail(undefined))}
                                                        className={`form-control ${(validEmail !== undefined && validEmail === false) || (errors?.recipients?.type === 'max') ? 'is-invalid' : ''}`}
                                                        error={(validEmail !== undefined && validEmail === false) || (errors?.recipients?.type === 'max') ? true : false}
                                                    />
                                                )}
                                                onChange={(e, value, situation, option) => {
                                                    setValue('recipients', value, { shouldValidate: true });
                                                    if (situation === 'removeOption') {
                                                        if (value.some((el: any) => el.name === undefined && el !== '' && validateEmail(el) === false)) {
                                                            setValidEmail(false);
                                                        } else {
                                                            setValidEmail(undefined);
                                                        }
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                />
                                {/* {validEmail !== undefined && validEmail === false && <span className="error-message">{`Please enter a valid email.`} </span>} */}
                                {errors?.recipients?.type === 'max' && <span className="error-message ps-0 mt-2">{errors?.recipients?.message} </span>}
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <label className="me-3 bold flex-1">{t('SendPrintCosting.Subject')}:</label>
                            <input
                                type="text"
                                {...register('subject', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'subject' as const}
                                className={`form-control white no-border-select custom-width-subject-input pb-2 ${errors.subject ? 'is-invalid' : ''}`}
                            />
                        </div>

                        <div className="mt-3">
                            <Controller
                                control={control}
                                name="body"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <WYSIWYGEditor
                                            initialContent={props?.emailData?.body}
                                            onChange={(content: any) => setValue('body', content?.split('<p></p>')?.join('')?.trim(), { shouldValidate: true })}
                                        />
                                    );
                                }}
                            />
                        </div>
                        {/* <a href="#" className="purple-text bold mt-3 d-block">
                            <i className="fa-solid fa-paperclip"></i> {t('SendPrintCosting.Attachments')}
                        </a> */}
                        <div className='custom-file-uploader'>
                            <MultipleFileUploader attachment={singleAttachment} errorsMFU={(error: any) => setErrorMFU(error)} removeError={() => setErrorMFU(undefined)} uploadFilesData={(uploadFilesData: any) => setValue('attachment', uploadFilesData)} />
                            {errorMFU !== undefined && errorMFU.type === 'maxFileUpload' && <span className="error-message ps-0">{errorMFU.maxFileUploadError} </span>}
                            {errorMFU !== undefined && errorMFU.type === 'fileType' && <span className="error-message ps-0">{errorMFU.fileTypeError} </span>}
                            {errorMFU !== undefined && errorMFU.type === 'fileSize' && <span className="error-message ps-0">{errorMFU.fileSizeError} </span>}
                        </div>
                        <p className='mt-2 small'><strong>Note:</strong> Supported file PDF, Word, Excel & Image. Maximum upload size 5 MB & upto 5 attachments are allowed.</p>
                        <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-email" />
                        <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-print" />
                    </div>
                }
                footer={
                    <div className="text-center">
                            <button disabled={!disableButton} className="btn btn-primary btn-sm me-2" onClick={onSendPrintHandler}>{printPriview ? t('SendPrintCosting.Send_Print') : t('SendPrintCosting.Send')}</button>
                            <button className="btn btn-secondary btn-sm" onClick={props.onCloseWC}>{t('SendPrintCosting.Cancel')}</button>
                        </div>
                }
            />
        </div>
    );
};

export default SendPrintCostingView;
