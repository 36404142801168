import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { removeIntigrationGridFilterStateAction } from '../../../store/general/integrationgridFilterStateReducer';
import { getMessageCode, getIntegrationStatus } from '../../../service/integration-service';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { BASEURLDEV, API_ENDPOINT } from '../../../service/ApiConstants';
import eventEmitter from '../../../utils/eventEmitter';

const initialState = {
    dateFrom: new Date().toString(),
    dateTo: new Date().toString(),
    reference: '',
    messageCode: 0,
    status: 0,
    integrationSource: 0
};

const IntegrationTopbarView = (props: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let [datavalid, setdatavalid] = useState<boolean>(false);
    const today = dayjs();

    const [periodfromDate, setperiodfromDate] = useState<any>('');
    const [periodtoDate, setperiodtoDate] = useState<any>('');
    const [minperiodfrom, setminperiodfrom] = useState<any>(null);
    let [ReferenceSearch, setReferenceSearch] = useState<any>('');
    const { StatusIntegrationGridFilterState, DataIntegrationGridFilterState } = useSelector((state: RootState) => state.integrationgridFilterState);
    const [integrationdata, setintegrationdata] = useState(initialState);
    let [messagecodeSmartSearch, setMessagecodeSmartSearch] = useState<any>(undefined);
    let [statusSmartSearch, setStatusSmartSearch] = useState<any>(undefined);
    let [integrationSourceSmartSearch, setIntegrationSourceSmartSearch] = useState<any>(undefined);

    let[defaultmessagecode,setdefaultmessagecode] = useState<any>(undefined);
    let[defaultstatus,setdefaultstatus] = useState<any>(undefined);
    let[defaultIntegrationSource,setdefaultIntegrationSource] = useState<any>(undefined);
    

    const validationSchema = Yup.object().shape({
        periodfrom: Yup.date().required(),
        periodto: Yup.date().required(),
        messageCode: Yup.string().notRequired(),
        reference: Yup.string().notRequired(),
        status: Yup.string().notRequired(),
        integrationSource: Yup.string().notRequired()
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const [messageCode, setMessageCode] = useState<any>(null);
    const [status, setStatus] = useState<any>(null);
    useEffect(() => {
        // const fetchData = async () => {
            // const res = await getMessageCode();
            // if (res.status) {
            //     setMessageCode(res.data.result);                
                if (DataIntegrationGridFilterState?.messageCode !== '') {
                    setValue('messageCode', DataIntegrationGridFilterState?.messageCode, { shouldValidate: true });
                } else {
                    setdefaultmessagecode(0);
                    setValue('messageCode', 0, { shouldValidate: true });
                }
            // }

            // const resStatus = await getIntegrationStatus();
            // if (resStatus.status) {
            //     setStatus(resStatus.data);            
                if (DataIntegrationGridFilterState?.status !== '' && DataIntegrationGridFilterState?.status > 0) {
                    setValue('status', DataIntegrationGridFilterState?.status, { shouldValidate: false });
                } else {
                    setdefaultstatus(0);
                    setValue('status', 0, { shouldValidate: false });
                }

                if (DataIntegrationGridFilterState?.integrationSource !== '' && DataIntegrationGridFilterState?.integrationSource > 0) {
                    setValue('integrationSource', DataIntegrationGridFilterState?.integrationSource, { shouldValidate: false });
                } else {
                    setdefaultIntegrationSource(0);
                    setValue('integrationSource', 0, { shouldValidate: false });
                }
            // }
        // };
        // fetchData();

        let objCodeSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetMessageCode}`,
        };
        setMessagecodeSmartSearch(objCodeSmartSearch);

        let objStatusSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetIntegrationStatus}`,
        };
        setStatusSmartSearch(objStatusSmartSearch);

        let objIntegrationSourceSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetIntegrationSource}`,
        };
        setIntegrationSourceSmartSearch(objIntegrationSourceSmartSearch)
    }, []);
    useEffect(() => {
        if (formState.isValid) {
            setdatavalid(true);
        } else {
            setdatavalid(false);
        }
    }, [formState]);

    const RemoveClickHandler = (event: any) => {
        event.target.name = 'leadSmartSearch';
        event.target.value = '';

        setValue('periodfrom', dayjs(today), { shouldValidate: true });
        setperiodfromDate(dayjs(today));
        setValue('periodto', dayjs(today), { shouldValidate: true });
        setperiodtoDate(dayjs(today));
        setReferenceSearch('');
        setValue('reference', '', { shouldValidate: false });
        setValue('messageCode', 0, { shouldValidate: true });
        setValue('status', 0, { shouldValidate: false });
        setValue('integrationSource', 0, { shouldValidate: false });
        setdefaultmessagecode(0);
        setdefaultstatus(0);
        setdefaultIntegrationSource(0);
        setintegrationdata(initialState);
        props.clearIntegrationData();
        dispatch(removeIntigrationGridFilterStateAction());
    };

    useEffect(() => {
        if (StatusIntegrationGridFilterState != undefined && StatusIntegrationGridFilterState == true) {            
            if (DataIntegrationGridFilterState?.dateTo !== null && DataIntegrationGridFilterState?.dateFrom !== null) {
                setperiodtoDate(dayjs(DataIntegrationGridFilterState?.dateTo));
                setperiodfromDate(dayjs(DataIntegrationGridFilterState?.dateFrom));
                setValue('periodto', dayjs(DataIntegrationGridFilterState?.dateTo), { shouldValidate: true });
                setValue('periodfrom', dayjs(DataIntegrationGridFilterState?.dateFrom), { shouldValidate: true });
            } else {
                setperiodtoDate(dayjs(today));
                setperiodfromDate(dayjs(today));
                setValue('periodfrom', dayjs(today), { shouldValidate: true });
                setValue('periodto', dayjs(today), { shouldValidate: true });
            }

            if (DataIntegrationGridFilterState?.reference !== '') {
                setReferenceSearch(DataIntegrationGridFilterState?.reference);
                setValue('reference', DataIntegrationGridFilterState?.reference, { shouldValidate: false });
            } else {
                setReferenceSearch('');
                setValue('reference', '', { shouldValidate: false });
            }
            if (DataIntegrationGridFilterState?.messageCode !== '') {
                setValue('messageCode', DataIntegrationGridFilterState?.messageCode, { shouldValidate: true });
                setdefaultmessagecode(DataIntegrationGridFilterState?.messageCode);
            } else {
                setdefaultmessagecode(0);
                setValue('messageCode', 0, { shouldValidate: true });
            }
            if (DataIntegrationGridFilterState?.status !== '' && DataIntegrationGridFilterState?.status > 0) {
                setValue('status', DataIntegrationGridFilterState?.status, { shouldValidate: false });
                setdefaultstatus(DataIntegrationGridFilterState?.status);
            } else {
                setdefaultstatus(0);
                setValue('status', 0, { shouldValidate: false });
            }
            if (DataIntegrationGridFilterState?.integrationSource !== '' && DataIntegrationGridFilterState?.integrationSource > 0) {
                setValue('integrationSource', DataIntegrationGridFilterState?.integrationSource, { shouldValidate: false });
                setdefaultIntegrationSource(DataIntegrationGridFilterState?.integrationSource);
            } else {
                setdefaultIntegrationSource(0);
                setValue('integrationSource', 0, { shouldValidate: false });
            }
            rebindSessionData(DataIntegrationGridFilterState);
        }
    }, [StatusIntegrationGridFilterState, DataIntegrationGridFilterState]);

    const updateData = (e: any,name:any) => {
        if(name === 'code'){
            setdefaultmessagecode(undefined);
        }
        if(name === 'status'){
            setdefaultstatus(undefined);
        }
        if(name === 'integrationSource'){
            setdefaultIntegrationSource(undefined);
        }
        setintegrationdata({
            ...integrationdata,
            [e.target.name]: e.target.value
        });
    };
    const updateDate = (date: any, fieldname: any) => {
        let d = new Date(date);
        let selecteddate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes() - d.getTimezoneOffset()).toISOString();
        setintegrationdata({
            ...integrationdata,
            [fieldname]: selecteddate
        });
    };

    const fetchIntegrationData = () => {
        props.getintegrationdata(integrationdata);
    };
    const rebindSessionData = (IntegrationStateData: any) => {
        let tempIntegrationData: any = integrationdata;
        tempIntegrationData.dateFrom = IntegrationStateData.dateFrom;
        tempIntegrationData.dateTo = IntegrationStateData.dateTo;
        tempIntegrationData.reference = IntegrationStateData.reference;
        tempIntegrationData.messageCode = IntegrationStateData.messageCode;
        tempIntegrationData.status = IntegrationStateData.status;
        tempIntegrationData.integrationSource = IntegrationStateData.integrationSource;
        setintegrationdata(tempIntegrationData);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="box-shadow p-0">
                        <div>
                            <div className="integration-header">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-12">
                                        <p className="p-3 bold lead-text purple-text">{t('IntegrationInterface.Integration_Interface')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 p-3 pt-1">
                            <div className="row">
                                <div className="col-xl-2 col-lg-2">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">{t('InvoiceDetail.Period_From')}</label>
                                        <Controller
                                            control={control}
                                            name="periodfrom"
                                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                return (
                                                    <DatePicker
                                                        {...fieldProps}
                                                        className={`form-control custom-datepicker white ${error ? 'is-invalid' : ''}`}
                                                        //label={t('InvoiceDetail.Period_From')}
                                                        value={periodfromDate}
                                                        format="DD/MM/YYYY"
                                                        onChange={(date: any) => {
                                                            fieldProps.onChange(date);
                                                            setperiodfromDate(date);
                                                            updateDate(date, 'dateFrom');
                                                        }}
                                                        maxDate={periodtoDate}
                                                        views={['year', 'month', 'day']}
                                                        slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">{t('InvoiceDetail.Period_To')}</label>

                                        <Controller
                                            control={control}
                                            name="periodto"
                                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                return (
                                                    <DatePicker
                                                        {...fieldProps}
                                                        className={`form-control custom-datepicker white ${error ? 'is-invalid' : ''}`}
                                                        value={periodtoDate}
                                                        format="DD/MM/YYYY"
                                                        onChange={(date: any) => {
                                                            fieldProps.onChange(date);
                                                            setminperiodfrom(dayjs(date));
                                                            setperiodtoDate(date);
                                                            updateDate(date, 'dateTo');
                                                        }}
                                                        minDate={periodfromDate}
                                                        //maxDate={today}
                                                        views={['year', 'month', 'day']}
                                                        slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                        disableFuture
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-2">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">{t('IntegrationInterface.Message_Code')}</label>
                         {messagecodeSmartSearch !== undefined && (
                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                            <SmartSearchDropdownContainer
                                smartSearchLabel={'Messagecode'}
                                defaultValue={defaultmessagecode}
                                errors={errors.messageCode}
                                onChangeSmartSearch={(value: any) => {
                                    const event = new Event('change');
                                    let obj = {
                                        name: 'messageCode',
                                        value: value?.id
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });
                                    setValue('messageCode', value?.id?.toString(), { shouldValidate: true });
                                    if (value !== '') {
                                        updateData(event,'code');
                                    }
                                }}
                                smartSearch={messagecodeSmartSearch}
                            />
                        </div>
                    )}
                                        {/* <select
                                            {...register('messageCode', {
                                                onChange: (e: any) => {
                                                    updateData(e);
                                                }
                                            })}
                                            name={'messageCode' as const}
                                            //@ts-ignore
                                            className={`form-control white ${errors.messageCode ? 'is-invalid' : ''}`}
                                        >
                                            <option value="">{t('IntegrationInterface.All')}</option>
                                            {messageCode !== undefined &&
                                                messageCode?.map((code: any) => {
                                                    return (
                                                        <option key={code.key} value={code.messageCode}>
                                                            {code.messageCode}
                                                        </option>
                                                    );
                                                })}
                                        </select> */}
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">{t('IntegrationInterface.Reference')}</label>
                                        <input
                                            type="text"
                                            {...register('reference', {
                                                onChange: (e: any) => {
                                                    updateData(e,'');
                                                    setReferenceSearch(e.target.value);
                                                }
                                            })}
                                            name={'reference' as const}
                                            value={ReferenceSearch}
                                            //@ts-ignore
                                            className={`form-control white ${errors.reference ? 'is-invalid' : ''}`}
                                            placeholder={`${t('IntegrationInterface.Reference')}`}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">{t('ReferralTable.Status')}</label>
                                        {statusSmartSearch !== undefined && (
                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                            <SmartSearchDropdownContainer
                                smartSearchLabel={'Status'}
                                defaultValue={defaultstatus}
                                errors={errors.status}
                                onChangeSmartSearch={(value: any) => {
                                    const event = new Event('change');
                                    let obj = {
                                        name: 'status',
                                        value: value?.id
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });
                                    setValue('status', value?.id?.toString(), { shouldValidate: true });
                                    if (value !== '') {
                                        updateData(event,'status');
                                    }
                                }}
                                smartSearch={statusSmartSearch}
                            />
                        </div>
                    )}
                                        {/* <select
                                            {...register('status', {
                                                onChange: (e: any) => {
                                                    updateData(e);
                                                }
                                            })}
                                            name={'status' as const}
                                            //@ts-ignore
                                            className={`form-control white ${errors.status ? 'is-invalid' : ''}`}
                                        >
                                            <option value="0">{t('IntegrationInterface.All')}</option>
                                            {status !== undefined &&
                                                status?.map((status: any) => {
                                                    return (
                                                        <option key={status.key} value={status.key}>
                                                            {status.value}
                                                        </option>
                                                    );
                                                })}
                                        </select> */}
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">{t('IntegrationInterface.Integration_Source')}</label>

                                            {integrationSourceSmartSearch !== undefined && (
                                                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                    <SmartSearchDropdownContainer
                                                        smartSearchLabel={'Integration Source'}
                                                        defaultValue={defaultIntegrationSource}
                                                        errors={errors.integrationSource}
                                                        onChangeSmartSearch={(value: any) => {
                                                            const event = new Event('change');
                                                            let obj = {
                                                                name: 'integrationSource',
                                                                value: value?.id
                                                            };
                                                            //@ts-ignore
                                                            Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                            setValue('integrationSource', value?.id?.toString(), { shouldValidate: true });
                                                            if (value !== '') {
                                                                updateData(event,'integrationSource');
                                                            }
                                                        }}
                                                        smartSearch={integrationSourceSmartSearch}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button
                                        className="btn btn-secondary me-2"
                                        onClick={(event: any) => {
                                            RemoveClickHandler(event);
                                        }}
                                    >
                                        Clear
                                    </button>
                                    <button
                                        className="btn btn-primary me-2"
                                        disabled={!datavalid}
                                        onClick={(e) => {
                                            fetchIntegrationData();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationTopbarView;
