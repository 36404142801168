import React from 'react';
import DownloadInvoiceView from './DownloadInvoiceView';

const DownloadInvoiceContainer = (props: any) => {
    return (
        <div>
            <DownloadInvoiceView onClickHandlerDownloadInvoices={props.onClickHandlerDownloadInvoices} />
        </div>
    );
};

export default DownloadInvoiceContainer;