import React, { useEffect, useState } from 'react';
import ReferralManagerContainer from './ReferralManager/ReferralManagerContainer';
import ServiceManagerContainer from './ServiceManager/ServiceManagerContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Roles } from '../../utils/constant';

const ReferralManagementView = () => {
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const [roleSelected, setRoleSelected] = useState<any>('');

    useEffect(() => {
        if (StatusRoleSelected === true) {
            setRoleSelected(DataRoleSelected);
        }
    }, [StatusRoleSelected, DataRoleSelected]);
    return (
        <div>
            {roleSelected.roleId === Roles.ReferralManager && <ReferralManagerContainer /> }
            {roleSelected.roleId === Roles.ServiceManager && <ServiceManagerContainer /> }
        </div>
    );
};

export default ReferralManagementView;
