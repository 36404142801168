import React, { useRef, useEffect, useState, useMemo } from 'react';
import MapPinLegendContainer from './MapPinLegend/MapPinLegendContainer';
import HomeMapContainer from './HomeMap/HomeMapContainer';
import HomeListContainer from './HomeList/HomeListContainer';
import eventEmitter from '../../utils/eventEmitter';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import SaveForLaterContainer from './SaveForLater/SaveForLaterContainer';
import SaveForLaterListPatientSpecificContainer from './SaveForLaterListPatientSpecific/SaveForLaterListPatientSpecificContainer';
import PatientInquiryContainer from './PatientInquiry/PatientInquiryContainer';
import NHSStagesContainer from './NHSStages/NHSStagesContainer';
import LeadDetailsContainer from './LeadDetails/LeadDetailsContainer';

const NHSView = ({ isDrawerOpen }: any) => {
    const [isLeadContainerOpen, setIsLeadContainerOpen] = useState(false);
    const [isNHSStagesOpen, setNHSStagesOpen] = useState(false);
    const [isSideBarOpen, setSideBarOpen] = useState(true)
    const [sizeMap, setSizeMap] = useState(null)
    const [sizeSideBar, setSideBar] = useState<any>(null)
    const main = useRef<HTMLHeadingElement>(null)
    const sideBar = useRef<HTMLHeadingElement>(null)
    const { saveForLaterDrawerOpen } = useSelector((state: RootState) => state.saveForLater);
    const { saveForLaterWishlistOpen } = useSelector((state: RootState) => state.saveForLaterWishlistModal);

    const handleOpenLeadContainer = (value: boolean) => {
        setIsLeadContainerOpen(value);
    }

    const handleOpenNHSStageContainer = (value: boolean) => {
        setNHSStagesOpen(value);
    }

    const isSaveForLaterDrawerOpen = useMemo(() => {
        return saveForLaterDrawerOpen;
    }, [saveForLaterDrawerOpen]);

    useEffect(() => {
        setSideBar(sideBar?.current ? sideBar?.current?.offsetWidth : null)
        let obj = {
            main: "100%",
            sideBar: sideBar.current ? sideBar.current.offsetWidth : 0
        }
        eventEmitter.emit("eventSetWidthMap", obj)
    }, [])

    useEffect(() => {
        eventEmitter.on('eventOpenLeadContainer', (value) => handleOpenLeadContainer(value));
        // eventEmitter.on('eventOpenNHSContainer', handleOpenNHSStageContainer);
        eventEmitter.on('eventOpenNHSContainerStateChanger', (value) => handleOpenNHSStageContainer(value));
        return () => {
            eventEmitter.off('eventOpenLeadContainer', (value) => handleOpenLeadContainer(value));
            eventEmitter.off('eventOpenNHSContainerStateChanger', (value) => handleOpenNHSStageContainer(value));
        }
    }, [isLeadContainerOpen, isNHSStagesOpen]);

    useEffect(() => {
        eventEmitter.on('eventGetWindowSize', eventGetWindowSize);
        return () => {
            eventEmitter.off('eventGetWindowSize', eventGetWindowSize);
        }
    }, []);

    const eventGetWindowSize = (size: any) => {
        setSizeMap(size)
        let obj = {
            //@ts-ignore
            main: "100%",
            sideBar: sideBar.current ? sideBar.current.offsetWidth : 0
        }
        eventEmitter.emit("eventSetWidthMap", obj)
    }

    const handleSideBar = () => {
        setSideBarOpen(!isSideBarOpen)
        if(isSideBarOpen){
            let obj = {
                //@ts-ignore
                main: '100%',
                sideBar: 0
            }
            eventEmitter.emit("eventSetWidthMap", obj)
        }else{
            let obj = {
                main: "100%",
                sideBar: sideBar.current ? sideBar.current.offsetWidth : 0
            }
            eventEmitter.emit("eventSetWidthMap", obj)
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row position-relative'>
                <div ref={main} className={isSideBarOpen ? 'col-lg-9 p-0 col-md-8' : "col-lg-12 p-0"}>
                    {/* <MapPinLegendContainer /> */}
                    <HomeListContainer />
                </div>
                {isSideBarOpen && <div ref={sideBar} className='col-lg-3 p-0 col-md-4'
                    style={{
                        transform: !isSideBarOpen ? 'translateX(100%)' : 'translateX(0)'
                    }}
                >
                    {!isSaveForLaterDrawerOpen && !saveForLaterWishlistOpen && <div className='nhs-sidebar'>
                        {isLeadContainerOpen && !isNHSStagesOpen ? (
                            <button title="left" onClick={() => handleOpenLeadContainer(false)} className="close_right_content btn-primary back-right-content">
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                        ) : null}

                        {!isLeadContainerOpen ? (
                            <>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h3>Patient Inquiry</h3>
                                </div>
                                <PatientInquiryContainer />
                            </>
                        ) : isNHSStagesOpen ? (
                            <NHSStagesContainer />
                        ) : (
                            <>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h3>Add Inquiry</h3>
                                </div>
                                <LeadDetailsContainer />
                            </>
                        )}


                    </div>}

                    {isSaveForLaterDrawerOpen && <SaveForLaterContainer />}
                    {saveForLaterWishlistOpen && <SaveForLaterListPatientSpecificContainer />}
                </div>}
                <button title="sidebar" onClick={handleSideBar} className={isSideBarOpen ? "patient-enquiry-open patient-enquiry-btn btn-primary" : "patient-enquiry-close patient-enquiry-btn btn-primary"}>
                    <i className={!isSideBarOpen ? "fa fa-angle-left" : "fa fa-angle-right"} id='collapseIcon' aria-hidden="true"></i>
                </button>
            </div>

        </div>
    );
};

export default NHSView;