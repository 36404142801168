import React from 'react';
import ServiceImageCollageView from './ServiceImageCollageView';

const ServiceImageCollageContainer = () => {
    return (
        <div>
            <ServiceImageCollageView />
        </div>
    );
};

export default ServiceImageCollageContainer;