import React from 'react';

const BillingDetailsLessView = (props: any) => {
    return (
        // <div>
        //     <ul className='list-unstyled billing-list mb-0'>
        //         <li>
        //             <div>
        //                 <h3>{props.invoiceStatistics?.paymentStatus?.paidCount || 0}</h3>
        //                 <p>Paid</p>
        //             </div>
        //             <div className='box paid-box'>
        //                 <i className="fa-solid fa-square-check"></i>
        //             </div>
        //         </li>
        //         <li>
        //             <div>
        //                 <h3>{props.invoiceStatistics?.paymentStatus?.inPaymentCount || 0}</h3>
        //                 <p>In Payment</p>
        //             </div>
        //             <div className='box payment-box'>
        //             <i className="fa-solid fa-clock"></i>
        //             </div>
        //         </li>
        //         <li>
        //             <div>
        //                 <h3>{props.invoiceStatistics?.paymentStatus?.unPaidCount || 0}</h3>
        //                 <p>Unpaid</p>
        //             </div>
        //             <div className='box unpaid-box'>
        //             <i className="fa-solid fa-rectangle-xmark"></i>
        //             </div>
        //         </li>
        //         <li>
        //             <div>
        //                 <h3>{props.invoiceStatistics?.invoiceStatus?.draftCount || 0}</h3>
        //                 <p>Draft</p>
        //             </div>
        //             <div className='box draft-box'>
        //             <i className="fa-solid fa-tag"></i>
        //             </div>
        //         </li>
        //     </ul>
        // </div>
        <div>
            
        </div>
    );
};

export default BillingDetailsLessView;