import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import AssessmentModalContainer from '../../../../../../components/AssessmentModal/indexContainer';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import warning from '../../../../../../assets/images/warning.png';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../../../store';
import { AssessmentStatus } from '../../../../../../utils/constant';
import { moveToCosting, SaveCosting } from '../../../../../../service/costing';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { getAssessmentDetails } from '../../../../../../service/assessment-service';
import ScheduleAssessmentContainer from '../../../../../../components/ScheduleAssessmentModal/indexContainer';
import CostingModalContainer from '../../../../../../components/CostingModal/CostingModalContainer';
import { deleteAssessmentDocument, getAssessmentDocument, getLeaddata } from '../../../../../../service/leads';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isError } from 'util';
import { BASEURLDEV, API_ENDPOINT } from '../../../../../../service/ApiConstants';
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const AssessmentView = (props: any) => {
    const { t } = useTranslation();
    const [assessmentList, setAssessmentList] = useState<any>([]);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { isNHSReferralTracker } = useSelector((state: RootState) => state.nhsLogin);
    const { StatusAssessmentDetails, DataAssessmentDetails } = useSelector((state: RootState) => state.getAssessmentDetails);
    const [assessmentDetails, setAssessmentDetails] = useState<any>(undefined);
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all' });
    const [poolingId, setPoolingId] = useState<number | null>(null);
    const [referralLogLength, setreferralLogLength] = useState<number | null>(null);
    //const [files, setFiles] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [assessmentListPosition, setAssessmentListPosition] = useState<number|undefined>(undefined);
    const [showEnterCostingModal, setShowEnterCostingModal] = useState(false);
    const [enterCostingServiceName, setEnterCostingServiceName] = useState('');
    const [viewCosting, setViewCosting] = useState<any>();
    const [costingAddedByRm, setcostingAddedByRm] = useState<boolean>(false);
    const [isError, setIsError] = useState<string | null>(null);
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const handleClickModal = async (item: any) => {
        let response = await getAssessmentDetails(item.assessmentId);
        if (response.status === true) {
            setShowModal(true);
        }
    };

    useEffect(() => {
        if (StatusLeadInfo === true && DataLeadInfo?.data?.assessment?.length > 0) {
            setAssessmentList(DataLeadInfo?.data?.assessment);
            setcostingAddedByRm(DataLeadInfo?.data?.assessment?.filter((e: any) => e.isAddedByRm).length > 0);
            // if (DataLeadInfo?.data?.assessment[0]?.attachments?.length > 0) {
            //     setFiles(
            //         DataLeadInfo?.data?.assessment[0]?.attachments?.map((item: any) => ({
            //             documentPath: item.documentPath,
            //             documentFile: item.documentFile,
            //             isAddedByRm: item.isAddedByRm
            //         }))
            //     );
            // }
        }
    }, [StatusLeadInfo, DataLeadInfo]);    

    const onError = (i: number,show: boolean) => {
        const updatedData = assessmentList.map((item: any, idx: number) => {
            if (item.leadPoolingId === i) {
                return {
                    ...item,
                    showError: show
                };
            }
            return item;
        });        
        setAssessmentList(updatedData);
    };

    useEffect(() => {
        if (StatusAssessmentDetails && DataAssessmentDetails?.data !== null) {
            setAssessmentDetails(DataAssessmentDetails?.data);
        } else {
            setAssessmentDetails(undefined);
        }
    }, [StatusAssessmentDetails, DataAssessmentDetails]);

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
                // dispatch(removeSplitFundingAction())
            }
        });
    };

    const saveToContract = (leadPoolingId: any, serviceName: any) => {
        swal({
            title: '',
            text: `Are you sure you want to move lead ${props.referralid} for costing to ${serviceName} ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const response = await moveToCosting(leadPoolingId);
                if (response.status == true) {
                    swal(response.message, {
                        icon: 'success'
                    });
                    eventEmitter.emit('onAddNewLeadRM');
                    const nextStage = props.finalStatus > 4 ? props.finalStatus : 4;
                    props.refreshData(4, nextStage);
                } else {
                    swal(response.message, {
                        icon: 'error',
                        dangerMode: true
                    });
                }
            }
        });
    };

    async function saveUpdateCosting(obj: any,additionalvalues:any) {
        console.log(additionalvalues);
        let costobj = {
            isRevisionStatus: obj?.isRevisionStatus || false,
            isRevised: obj?.isRevised || false,
            rcId: obj?.rcId || 0,
            loadPoolingId: obj?.loadPoolingId || 0,
            serviceUserName: obj?.serviceUserName || '',
            serviceUserId: obj?.serviceUserId || '',
            serviceLocation: obj?.serviceLocation || '',
            accommodation: obj?.accommodation || 0,
            baselineStaffCost: obj?.baselineStaffCost || 0,
            enhancedStaffingCost: obj?.enhancedStaffingCost || 0,
            mdt: obj?.mdt || 0,
            transport: obj?.transport || 0,
            managementAndAdministration: obj?.managementAndAdministration || 0,
            total: obj?.total?.toFixed(2) || 0,
            isAddedByRm: true,
            costingLists:additionalvalues
        };
        const res = await SaveCosting(costobj);

        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('onFetchUpdatedLeadRM');
            const nextStage = props.finalStatus > 4 ? props.finalStatus : 4;
            props.refreshData(4, nextStage);
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    const removepdf = (e: any, path: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove the attachment?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: path };
                const response = await deleteAssessmentDocument(payload);
                if (response.status == true) {
                    getLeaddata(DataLeadInfo?.data?.leadId, 3);
                    // setFiles((o: any) => o.filter((v: any) => v.documentPath !== path));
                } else {
                }
            }
        });
    };
    const openpdf = async (e: any, path: any) => {
        e.preventDefault();
        const payload = { path };
        const response = await getAssessmentDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    // useEffect(() => {
    //     if (files.length <= 5) {
    //         setIsError(null);
    //         eventEmitter.emit('eventSetLoader', false);
    //     }
    // }, [files]);
    return (
        <div>
            {assessmentList.length > 0 &&
                assessmentList.map((item: any, index: any) => {
                    if (item.assessmentStatus === AssessmentStatus.InProgress) {
                        return (
                            <div className="status-box mt-3 bg-white" onClick={()=>{
                                setAssessmentListPosition(index)
                            }}>
                                <div className="d-flex justify-content-between mb-2 align-items-start">
                                    <p className="bold">{item.serviceName}</p>
                                    <span className="referral-status yellow-status">{t('Assessment_Form.In_Progress')}</span>
                                </div>
                                {item?.referralLogs?.length > 0 &&
                                    item?.referralLogs?.map((log: any, i: any) => {
                                        return (
                                            <div className="d-flex align-items-center">
                                                <p className="mb-1" key={i}>
                                                    <i>{log.logMessage}</i>
                                                </p>
                                            </div>
                                        );
                                    })}

                                <i
                                    className="fa-solid fa-circle-info dark-text cursor-pointer ms-1"
                                    onClick={() => {
                                        setPoolingId(item.leadPoolingId);
                                        setreferralLogLength(item?.referralLogs.length);
                                    }}
                                ></i>

                                {item?.attachments?.length > 0 && (
                                    <>
                                        <p className="purple-text mt-2 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                    </>
                                )}
                                {item?.attachments?.length > 0 &&
                                    item?.attachments?.map((file: any) => (
                                        <div className="d-flex justify-content-between  mt-2 mb-2">
                                            <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Skip Assessment Document ">
                                                <textarea rows={1} value={file.documentFile} onClick={(e: any) => openpdf(e, file.documentPath)} readOnly />
                                            </div>
                                            {file.isAddedByRm && item.hasMovedToCosting === false && DataLeadInfo?.data?.referralCloseLog === null && (
                                                <div>
                                                    <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.documentPath)}>
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        );
                    }
                    if (item.assessmentStatus === AssessmentStatus.Submitted) {
                        return (
                            <div className="status-box mt-3" onClick={()=>{
                                setAssessmentListPosition(index)
                            }}>
                                <div className="d-flex justify-content-between mb-2 align-items-start">
                                    <p className="bold">{item.serviceName}</p>
                                    <span className="referral-status green-status">{t('Assessment_Form.Submitted')}</span>
                                </div>
                                {item?.referralLogs?.length > 0 &&
                                    item?.referralLogs?.map((log: any, i: any) => {
                                        return (
                                            <p className="mb-1" key={i}>
                                                <i>{log.logMessage}</i>
                                            </p>
                                        );
                                    })}
                                <div className="mt-3 text-center">
                                    <a href="#" className="btn btn-secondary btn-sm me-2 mt-1" onClick={() => handleClickModal(item)}>
                                        {t('Assessment_Form.View_Assessment_btn')}
                                    </a>
                                   {!props.nhs && !isNHSReferralTracker &&  <button
                                        disabled={(item.hasMovedToCosting || costingAddedByRm || DataLeadInfo?.data?.referralCloseLog !== null)}
                                        className="btn btn-primary btn-sm me-2 mt-1"
                                        data-tooltip-id={`my-tooltip-Costing-${index}`}
                                        data-tooltip-content="Enter Costing"
                                        onClick={() => {
                                            setShowEnterCostingModal(true);
                                            setEnterCostingServiceName(item?.serviceName);
                                            setViewCosting({
                                                serviceUserName: item?.serviceUserName,
                                                serviceUserId: item?.serviceUserId,
                                                serviceLocation: item?.serviceLocation,
                                                loadPoolingId: item?.leadPoolingId
                                            });
                                        }}
                                    >
                                        {t('Assessment_Form.Enter_Costing')}
                                    </button>}
                                    {!props.nhs && !isNHSReferralTracker && <button
                                        disabled={(item.hasMovedToCosting || DataLeadInfo?.data?.referralCloseLog !== null)}
                                        className="btn btn-primary btn-sm mt-1"
                                        data-tooltip-id={`my-tooltip-Costing-${index}`}
                                        data-tooltip-content="Move to Costing"
                                        onClick={() => saveToContract(item?.leadPoolingId, item?.serviceName)}
                                    >
                                        {t('Assessment_Form.Costing_btn')} <i className="fa-solid fa-arrow-right ms-1"></i>
                                    </button>}
                                </div>

                                {item?.attachments?.length > 0 && (
                                    <>
                                        <p className="purple-text mt-2 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                    </>
                                )}
                                {item?.attachments?.length > 0 &&
                                    item?.attachments?.map((file: any) => (
                                        <div className="d-flex justify-content-between  mt-2 mb-2">
                                            <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Skip Assessment Document ">
                                                <textarea rows={1} value={file.documentFile} onClick={(e: any) => openpdf(e, file.documentPath)} readOnly />
                                            </div>
                                            {file.isAddedByRm && item.hasMovedToCosting === false && DataLeadInfo?.data?.referralCloseLog === null && (
                                                <div>
                                                    <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.documentPath)}>
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                <Tooltip id={`my-tooltip-Costing-${index}`} className="my-tooltip" />
                            </div>
                        );
                    }
                    if (item.assessmentStatus === AssessmentStatus.Skipped) {
                        return (
                            <div className="status-box mt-3" onClick={()=>{
                                setAssessmentListPosition(index)
                            }}>
                                <div className="d-flex justify-content-between mb-2 align-items-start">
                                    <p className="bold">{item.serviceName}</p>
                                    <span className="referral-status green-status">{t('Assessment_Form.Skipped')}</span>
                                </div>
                                {item?.referralLogs?.length > 0 &&
                                    item?.referralLogs?.map((log: any, i: any) => {
                                        return (
                                            <div className="d-flex align-items-center">
                                                <p className="mb-1" key={i}>
                                                    <i>{log.logMessage}</i>
                                                </p>
                                            </div>
                                        );
                                    })}
                                  {!isNHSReferralTracker &&  <>
                                {item?.attachments?.length > 0 && (
                                    <>
                                        <p className="purple-text mt-2 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                    </>
                                )}
                                {item?.attachments?.length > 0 &&
                                    item?.attachments?.map((file: any) => (
                                        <div className="d-flex justify-content-between  mt-2 mb-2">
                                            <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Skip Assessment Document ">
                                                <textarea rows={1} value={file.documentFile} onClick={(e: any) => openpdf(e, file.documentPath)} readOnly />
                                            </div>
                                            {file.isAddedByRm && item.hasMovedToCosting === false && DataLeadInfo?.data?.referralCloseLog === null && (
                                                <div>
                                                    <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.documentPath)}>
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                {item.hasMovedToCosting === false && item.isSkippedByRm === true && !props.nhs &&  (
                                    <>
                                        <div id="penPictureUpload">
                                            <Controller
                                                control={control}
                                                name="AssessmentDocument"
                                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                    return (
                                                        <FilePond
                                                            files={
                                                                item?.attachments?.length <= 5 &&
                                                                item?.attachments?.map((file: any) => ({
                                                                    source: file.documentFile,
                                                                    options: {
                                                                        type: 'local',
                                                                        file: {
                                                                            name: file.documentFile,
                                                                            size: 0
                                                                        }
                                                                    }
                                                                }))
                                                            }
                                                            // @ts-ignore
                                                            allowFileSizeValidation={true}
                                                            maxFileSize={1000 * 1000 * 5}
                                                            allowFileTypeValidation={true}
                                                            acceptedFileTypes={[
                                                                'application/pdf',
                                                                'image/*',
                                                                'application/vnd.ms-excel',
                                                                'application/vnd.ms-powerpoint',
                                                                'application/msword',
                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                            ]}
                                                            labelMaxFileSizeExceeded={'Total size of all the attachments cannot exceed 5 MB'}
                                                            fileValidateTypeLabelExpectedTypes={''}
                                                            name={'AssessmentDocument'}
                                                            labelIdle={`${t('VacantRightbar.pdftitle3')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                            allowMultiple
                                                            maxFiles={5}
                                                            maxParallelUploads={5}
                                                            beforeAddFile={() => {
                                                                eventEmitter.emit('eventSetLoader', true);
                                                                return true;
                                                            }}
                                                            // @ts-ignore
                                                            onerror={(props) => {setIsError(props.main);onError(item.leadPoolingId,true);}}
                                                            onwarning={(error) => {
                                                                if (error.body === 'Max files') {
                                                                    setIsError('Maximum only 5 attachments are allowed');
                                                                    onError(item.leadPoolingId,true);
                                                                }
                                                            }}
                                                            server={{
                                                                url: `${BASEURLDEV}${API_ENDPOINT.UploadAssessmentdoc}`,
                                                                process: {
                                                                    headers: {
                                                                        Authorization: `Bearer ${token}`
                                                                    },
                                                                    ondata: (formData) => {
                                                                        formData.append('LeadPoolingDetails_Id', item.leadPoolingId);
                                                                        return formData;
                                                                    },
                                                                    //@ts-ignore
                                                                    onload: (res: any) => {
                                                                        if (item?.attachments?.length === 5) {
                                                                            setIsError('Maximum only 5 attachments are allowed');                                                                            
                                                                            eventEmitter.emit('eventSetLoader', false);
                                                                            onError(item.leadPoolingId,true);
                                                                            return;
                                                                        }

                                                                        const fileres = JSON.parse(res);

                                                                        if (fileres?.status === false) {
                                                                            eventEmitter.emit('eventSetLoader', false);
                                                                            setIsError(fileres?.message);
                                                                            onError(item.leadPoolingId,true)
                                                                        } else {
                                                                            eventEmitter.emit('eventSetLoader', false);
                                                                            setIsError(null);
                                                                            onError(item.leadPoolingId,false)
                                                                            getLeaddata(DataLeadInfo?.data?.leadId, 3);
                                                                        }                                                                        
                                                                    }
                                                                },
                                                                remove: (source, load, error) => {
                                                                    item?.attachments?.length <= 5 && setIsError(null);
                                                                    error('oh my goodness');
                                                                    eventEmitter.emit('eventSetLoader', false);
                                                                    load();
                                                                }
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                        {item?.showError && isError && <p className="text-danger ps-1">{isError}</p>}
                                        <p className="small mt-2">
                                            <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note3')}
                                        </p>
                                        <p className="small mt-2">
                                            <strong>{t('VacantRightbar.note')}</strong> {t('SM_Assessment.Notes')}
                                        </p>
                                    </>
                                )}

                                <div className="mt-3 text-center">
                                    {item.assessmentId !== 0 && (
                                        <a href="#" className="btn btn-secondary btn-sm me-2 mt-1" onClick={() => handleClickModal(item)}>
                                            {t('Assessment_Form.View_Assessment_btn')}
                                        </a>
                                    )}
                                 
                                    {!props.nhs && <button
                                        disabled={(item.hasMovedToCosting || costingAddedByRm || DataLeadInfo?.data?.referralCloseLog !== null)}
                                        className="btn btn-primary btn-sm me-2 mt-1"
                                        data-tooltip-id={`my-tooltip-Costing-${index}`}
                                        data-tooltip-content="Enter Costing"
                                        onClick={() => {
                                            setShowEnterCostingModal(true);
                                            setEnterCostingServiceName(item?.serviceName);
                                            setViewCosting({
                                                serviceUserName: item?.serviceUserName,
                                                serviceUserId: item?.serviceUserId,
                                                serviceLocation: item?.serviceLocation,
                                                loadPoolingId: item?.leadPoolingId
                                            });
                                        }}
                                    >
                                        {t('Assessment_Form.Enter_Costing')}
                                    </button>}
                                    {!props.nhs && <button
                                        disabled={(item.hasMovedToCosting || DataLeadInfo?.data?.referralCloseLog !== null)}
                                        className="btn btn-primary btn-sm mt-1"
                                        data-tooltip-id={`my-tooltip-Costing-${index}`}
                                        data-tooltip-content="Move to Costing"
                                        onClick={() => saveToContract(item?.leadPoolingId, item?.serviceName)}
                                    >
                                        {t('Assessment_Form.Costing_btn')} <i className="fa-solid fa-arrow-right ms-1"></i>
                                    </button>}
                                </div>
                                </>}
                                <Tooltip id={`my-tooltip-Costing-${index}`} className="my-tooltip" />
                            </div>
                        );
                    }
                })}

            <RenderModalContainer>
                {poolingId !== null && (
                    <ScheduleAssessmentContainer leadInfo={DataLeadInfo} onClose={() => setPoolingId(null)} poolingId={poolingId} allowEdit={referralLogLength === 1} />
                )}
            </RenderModalContainer>
            <RenderModalContainer>
                {showModal && (
                    <AssessmentModalContainer
                        onClose={toggle}
                        assessmentDetails={assessmentDetails}
                        orgName={assessmentList[assessmentListPosition!]?.organizationName}
                        referralid={props.referralid}
                        serviceName={assessmentList[assessmentListPosition!]?.serviceName}
                        status={DataLeadInfo?.data?.assessment[0].assessmentStatus}
                    />
                )}
            </RenderModalContainer>
            <RenderModalContainer>
                {showEnterCostingModal && (
                    <CostingModalContainer
                        setViewCosting={viewCosting}
                        onClose={() => setShowEnterCostingModal(false)}
                        title={`Costing - ${enterCostingServiceName}`}
                        referralid={props.referralid}
                        serviceName={enterCostingServiceName}
                        mode="edit"
                        saveUpdateCosting={saveUpdateCosting}
                    />
                )}
            </RenderModalContainer>
        </div>
    );
};

export default AssessmentView;
