import React from 'react';
import PatientDetailView from './indexView';

const PatientDetailContainer = (props: any) => {
    return (
        <div>
            <PatientDetailView invoicedetails={props.invoicedetails} onClose={props.onClose} footer={props.footer} title={props.title} className={props.className} content={props.content}  />
        </div>
    );
};

export default PatientDetailContainer;