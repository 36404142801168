import React from 'react';
import OverviewDetailsView from './OverviewDetailsView';

const OverviewDetailsContainer = (props:any) => {
    return (
        <div>
            <OverviewDetailsView serviceCoordinatorDetails={props?.serviceCoordinatorDetails} serviceDescription={props?.serviceDescription}/>
        </div>
    );
};

export default OverviewDetailsContainer;