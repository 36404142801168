import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';
import { RootState } from "../../../../../../store";
import { ReasonList } from '../../../../../../utils/constant';

const MaintenanceFormView = (props: any) => {
    const { t } = useTranslation();
    const [disableButton, setDisableButton] = useState<any>(false);
    let [reasonSmartSearch, setReasonSmartSearch] = useState<any>(undefined);

    const validationSchema = Yup.object().shape({
        maintenanceStartDate: Yup.string().required('Maintenance Start Date is required'),
        estMaintenanceReleaseDate: Yup.string().required('Est. Release Date is required'),
        reasonId: Yup.string().required('Reason is required'),
        comments: Yup.string().notRequired()
    });

    const { register, handleSubmit, reset, control, formState, setValue} = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    
    const {errors} = formState;

    const { Data } = useSelector((state: RootState) => state.navigation);

    // Maintenance Start Date
    const [msd, setMsd] = useState(null);
    // Est. Release Date
    const [erd, setErd] = useState(null);

    const onSubmitHandler: SubmitHandler<any> = (data) => {
        props.onSubmitHandler(data)
    };

    const clearReasonSmartSearch = () => {
        setValue('reasonId', '', { shouldValidate: true });
        setReasonSmartSearch(undefined);
    };

    useEffect(() => {
        if(formState.isValid) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [formState]);

    useEffect(() => {
        if (reasonSmartSearch === undefined) {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLReasons}/${ReasonList.Maintenance}`
            };
            setReasonSmartSearch(objReasonSmartSearch);
        }
    }, [reasonSmartSearch]);

    useEffect(()=>{
        setMsd(null);
        setErd(null)
        if(reasonSmartSearch !== undefined){
            clearReasonSmartSearch()
        }
        reset()
    },[Data])

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="input-group date mt-1 mb-2 datepicker-input-css" id="datepicker">
                    <Controller
                        control={control}
                        name="maintenanceStartDate"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return (
                                <DatePicker
                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                    label={`${t('RightSidebarMaintenanceForm.Maintenance_Start_Date')}*`}
                                    value={msd}
                                    format="DD/MM/YYYY"
                                    {...fieldProps}
                                    onChange={(date: any) => {
                                        props.updateData(date);
                                        fieldProps.onChange(date);
                                        setMsd(date);
                                    }}
                                    disableFuture
                                    disablePast
                                    views={['year', 'month', 'day']}
                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                />
                            );
                        }}
                    />
                </div>
                <div className="input-group date mt-1 mb-2 datepicker-input-css" id="datepicker">
                    <Controller
                        control={control}
                        name="estMaintenanceReleaseDate"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return (
                                <DatePicker
                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                    label={`${t('RightSidebarMaintenanceForm.Est_Release_Date')}*`}
                                    value={erd}
                                    format="DD/MM/YYYY"
                                    {...fieldProps}
                                    onChange={(date: any) => {
                                        props.updateData(date);
                                        fieldProps.onChange(date);
                                        setErd(date)
                                    }}
                                    disablePast
                                    views={['year', 'month', 'day']}
                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                />
                            );
                        }}
                    />
                </div>
                {/* <select {...register('reasonId', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} id="" className={`form-control mt-2 white mb-2 ${errors.reasonId ? 'is-invalid' : ''}`}>
            <option value="">{t('RightSidebarMaintenanceForm.Reason_for_Maintenance')}*</option>
                {
                    props.listReasons !== undefined 
                    && props.listReasons !== null 
                    && props.listReasons !== '' 
                    && props.listReasons.length > 0 
                    && props.listReasons.map((val: any) => {
                        return (<option key={val.reasonId} value={val.reasonId}>{val.reasonText}</option>)
                    })
                }
                
            </select> */}
                {reasonSmartSearch !== undefined && (
                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                        <SmartSearchDropdownContainer
                            smartSearchLabel={`${t('RightSidebarMaintenanceForm.Reason_for_Maintenance')}*`}
                            errors={errors.reasonId}
                            onChangeSmartSearch={(value: any) => {
                                const event = new Event('change');
                                let obj = {
                                    name: 'reasonId',
                                    value: value?.id
                                };
                                //@ts-ignore
                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                setValue('reasonId', value?.id, { shouldValidate: true });
                                if (value !== '') {
                                    props.updateData(event);
                                }
                            }}
                            smartSearch={reasonSmartSearch}
                        />
                    </div>
                )}
                <textarea
                    {...register('comments', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    placeholder={`${t('RightSidebarMaintenanceForm.Comments')}`}
                    className={`form-control white`}
                ></textarea>
                <div className="mt-2 text-center  right_content_btn">
                    <button type="submit" disabled={!disableButton} className="btn btn-primary  flex-1 me-2">
                        {t('RightSidebarMaintenanceForm.Submit')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default MaintenanceFormView;