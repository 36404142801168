import React from 'react';
import PackagesView from './PackagesView';

const PackagesContainer = () => {
    return (
        <div>
            <PackagesView />
        </div>
    );
};

export default PackagesContainer;