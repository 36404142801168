import { SaveForLaterServicesWishlist, SaveForLaterServicesWishlistPayload } from "../../utils/model";
import { createSlice } from "@reduxjs/toolkit"

const initialState: SaveForLaterServicesWishlist = {
    saveForLaterWishlistOpen: false,
    folderId : 0
}

export const saveForLaterDrawerSlice = createSlice({
    name: "saveForLaterWishlistModal",
    initialState,
    reducers: {
        setSaveForLaterWishlist: (state, { payload }: SaveForLaterServicesWishlistPayload) => {
            state.saveForLaterWishlistOpen = payload.saveForLaterWishlistOpen;
            state.folderId = payload.folderId
        }
    }
})

export const { setSaveForLaterWishlist } = saveForLaterDrawerSlice.actions;

export default saveForLaterDrawerSlice.reducer;
