import React from 'react';
import SendPrintCostingView from './SendPrintCostingView';

const SendPrintCostingContainer = (props:any) => {
    return (
        <div>
            <SendPrintCostingView viewCosting={props.viewCosting} footer={props.footer} onClickPrintPriview={props.onClickPrintPriview} onCloseWC={props.onCloseWC} onClose={props.onClose} title={props.title} costingRecepients={props.costingRecepients} emailData={props.emailData} />
        </div>
    );
};

export default SendPrintCostingContainer;