import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';
import { setListtContractAction, errorListtContractAction } from '../store/contract/listContractReducer';
import eventEmitter from '../utils/eventEmitter';

export const getListContractService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetContractByPatientid}?admissionId=${payload.id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setListtContractAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorListtContractAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorListtContractAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const getContractFunderFileService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.DownloadContractDocument}?filePath=${payload.name}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const saveContract = (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveContract}`,payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const addUpdateFunder = (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.AddUpdateFunder}`,payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getContracttype = () => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetContractType}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const deleteFunder = (lid:any,fid:any,isself:number,leadfunderid:any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .delete(`${BASEURLDEV}${API_ENDPOINT.DeleteFunder}?LeadId=${lid}&FunderId=${fid}&IsSelfFunded=${isself}&LeadFunderDetailsId=${leadfunderid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getContractData = (cid:any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetContractData}?digitalContractId=${cid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const getContractPreview = (cid:any,aid:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetContractPreview}?contractId=${cid}&acdId=${aid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const confirmContract = (cid:any) => {
    // eventEmitter.emit('eventSetLoader', true)
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ContractConfirmSend}?contractId=${cid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const confirmContractadmission = (cid:any,aid:any) => {
    // eventEmitter.emit('eventSetLoader', true)
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ContractConfirmSend}?contractId=${cid}&acdId=${aid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const contractSign = (cid:any) => {
    // eventEmitter.emit('eventSetLoader', true)
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetContractSignUrl}?contractId=${cid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const updateDocuSign = (cid:any) => {
     eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.UpdateDocuSign}?contractId=${cid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
             eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                 eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                 eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const contractCancelReasons = () => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.ContractCancelReason}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const contractCancel = (payload:any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ContractCancel}`,payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // store.dispatch(setListtContractAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // store.dispatch(errorListtContractAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getContactingDocument = (payload:any) => {
    const state = store.getState();
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.ConteactGetAttachment}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const deleteContactingDocument = (payload:any) => {
    const state = store.getState();
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .delete(`${BASEURLDEV}${API_ENDPOINT.ContractDeleteAttachment}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getContractDataAdmission = (aid:any) => {
    const state = store.getState();
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetContractDataAdmission}?admissionId=${aid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getLocalityData = (lid:any) => {
    // const state = store.getState();
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetLocalityData}?funderId=${lid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const CompleteContractingStage = (leadPoolingId:any) => {    
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.CompleteContractingStage}?leadPoolingId=${leadPoolingId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;            
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;                
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);                
                return errorData;
            }
        });
};
