import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientDetailModel } from '../../utils/model';

const initialState: PatientDetailModel = {
    StatusPatientDetail: false,
    DataPatientDetail: '',
    MessagePatientDetail: '',
    ErrorPatientDetail: ''
};

export const patientDetailSlice = createSlice({
    name: 'patientDetail',
    initialState,
    reducers: {
        setPatientDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientDetail = true;
            state.DataPatientDetail = payload;
            state.MessagePatientDetail = 'Success';
        },
        removePatientDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientDetail = false;
            state.DataPatientDetail = '';
            state.MessagePatientDetail = '';
            state.ErrorPatientDetail = '';
        },
        errorPatientDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientDetail = false;
            state.DataPatientDetail = [];
            state.MessagePatientDetail = 'Failed';
            state.ErrorPatientDetail = payload;
        }
    },
    extraReducers: {}
});

export const { setPatientDetailAction, errorPatientDetailAction, removePatientDetailAction } = patientDetailSlice.actions;

export default patientDetailSlice.reducer;
