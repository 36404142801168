import React from 'react';
import BedDetailsContainer from './BedDetails/bedDetailsContainer';
import BedDetailsMoreContainer from './BedDetailsMore/bedDetailsMoreContainer';

const BedInformationView = (props: any) => {
    return (
        <div>
            {props.moreInformation === false ? (
                <div className="position-relative">
                    <BedDetailsContainer />
                    {/* <p className="see-more" onClick={props.handleShowMoreInfo}>
                        <label id="moreless">More Details</label>
                        <span className="upicon downicon ms-1"></span>
                    </p> */}
                </div>
            ) : (
                <div className="position-relative more-info">
                    {/* <p className="see-more" onClick={props.handleHideMoreInfo}>
                        <label id="moreless">Less Details</label>
                        <span className="upicon upicon ms-1"></span>
                    </p> */}
                    <BedDetailsMoreContainer />
                </div>
            )}

            {/* <div className="accordion" id="accordionExample">
                <div className="accordion-item d-block">
                    <h2 className="accordion-header" id="headingOne">
                        <a className="accordion-button collapsed d-block" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"></a>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body p-0 mt-4"></div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BedInformationView;
