import NoteModalView from './indexView';

const NoteContainer = (props: any) => {    
    return (
        <div>
            <NoteModalView {...props} />
        </div>
    );
};

export default NoteContainer;