import React from 'react';
import PackagesSliderView from './PackagesSliderView';

const PackagesSliderContainer = (props:any) => {
    return (
        <div>
            <PackagesSliderView packageDetails={props?.packageDetails}/>
        </div>
    );
};

export default PackagesSliderContainer;