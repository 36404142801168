import React, { useEffect, useMemo, useState } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import dayjs from 'dayjs';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getContractPreview, getContracttype, getLocalityData, updateDocuSign } from '../../service/contract-service';
import RenderModalContainer from '../RenderModal/indexContainer';
import PreviewContractContainer from '../PreviewContractModal/PreviewContractContainer';
import { ContractDetailArrayType, ContractInterface, InclusionDataInterface } from './type';
import { getInclusiondisplay } from '../../service/dashboard-service';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    footer: any;
    className: any;
    save: () => any;
    preview: () => any;
    send: () => any;
    findex: any;
    getval: any;
    cdata: any;
    pname: any;
    combinationId: number;
}

const inititalValues: ContractInterface = {
    newContractType: '',
    newContractService: '',
    newContractCreated: null,
    newContractEffectiveFrom: null,
    newContractEffectiveTo: null,

    serviceUserDetailName: '',
    serviceUserDetailDob: null,
    serviceUserDetailNHSId: '',
    serviceUserDetailClientId: '',
    serviceUserDetailAdd1: '',
    serviceUserDetailAdd2: '',
    serviceUserDetailCountry: '',
    serviceUserDetailPostalCode: '',

    contractDetailSchedAddmissionDate: null,
    contractDetailEstDischargeDate: null,
    contractDetailFee: '',
    contractDetailCoreFee: '',
    contractDetailContinenceFee: '',
    contractDetailFNCFee: '',
    contractDetailHourlyFee1: '',
    contractDetailHourlyFee2: '',
    contractDetailHourlyFee3: '',
    contractDetailHourlyFee4: '',

    contractDetailArray: [
        {
            contractDetailFeeShare: '',
            funderType: '',
            funderName: '',
            funderEmail: '',
            funderAdd: '',
            funderCountry: '',
            funderPostalCode: '',
            funderContactNo: '',

            sameAsFunder: false,
            invoiceSubmission: '',
            autoPayment: '',
            funderCopyInvoicePoint: '',
            funderCopyEmail: '',
            funderCopyAdd: '',
            funderCopyCountry: '',
            funderCopyPostalCode: '',
            funderCopyContact: '',

            commissionerEmail: '',
            commissionerPhone: '',

            socialWorkerEmailId: '',
            socialWorkerContactNo: '',
            isSelfFunded: false,

            acdId: 0,
            commissionerCityName: '',
            commissionerPhoneNo: '',
            commissionerPobox: '',
            funderderTypeId: 0,
            nextofkinContact:'',
            nextofkinEmail:''
        }
    ]
};

const validationSchema = Yup.object().shape({
    newContractType: Yup.string().notRequired(),
    newContractService: Yup.string().notRequired(),
    newContractCreated: Yup.date().required(),
    newContractEffectiveFrom: Yup.date().required(),
    newContractEffectiveTo: Yup.date().notRequired(),
    serviceUserDetailName: Yup.string().notRequired(),
    serviceUserDetailDob: Yup.mixed().required(),

    serviceUserDetailNHSId: Yup.string()
        .when('serviceUserDetailClientId', {
            is: '',
            then: (schema: any) => schema.matches(/^$|\b\d{3}\s?\d{3}\s?\d{4}\b$/).required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
    serviceUserDetailClientId: Yup.string() .when('serviceUserDetailNHSId', {
        is: '',
        then: (schema: any) => schema.matches(/^$|^[0-9A-Za-z]{1,14}$/).required(),
        otherwise: (schema: any) => schema.nonNullable().notRequired()
    }),

    serviceUserDetailAdd1: Yup.string().required(),
    serviceUserDetailAdd2: Yup.string().required(),
    serviceUserDetailCountry: Yup.string().required(),
    serviceUserDetailPostalCode: Yup.string()
        .trim()
        .strict(true)
        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .required(),

    contractDetailSchedAddmissionDate: Yup.date().notRequired(),
    contractDetailEstDischargeDate: Yup.date().notRequired(),

    contractDetailFee: Yup.string().required(),
    
    contractDetailCoreFee: Yup.string()
        .matches(/^((?!0$)(?!00$)(?!000$)(?!0000$)(?!00000$)(?!000000$)(?!0000000$)(?!00000000$)(?!0.0$)(?!0.00$)\d{0,8})(\.\d{1,2})?$/)
        .required(),

    contractDetailContinenceFee: Yup.string()
        .matches(/^\d{0,8}(\.\d{1,2})?$/)
        .notRequired(),

    contractDetailFNCFee: Yup.string()
        .matches(/^\d{0,8}(\.\d{1,2})?$/)
        .notRequired(),

    contractDetailHourlyFee1: Yup.string()
        .matches(/^\d{0,8}(\.\d{1,2})?$/)
        .required(),
    contractDetailHourlyFee2: Yup.string()
        .matches(/^\d{0,8}(\.\d{1,2})?$/)
        .required(),
    contractDetailHourlyFee3: Yup.string()
        .matches(/^\d{0,8}(\.\d{1,2})?$/)
        .required(),
    contractDetailHourlyFee4: Yup.string()
        .matches(/^\d{0,8}(\.\d{1,2})?$/)
        .required(),

    contractDetailArray: Yup.array().of(
        Yup.object().shape({
            contractDetailFeeShare: Yup.string()
                .matches(/^\d{0,8}(\.\d{1,2})?$/)
                .required(),
            // cname: Yup.string().trim().strict(true).nonNullable().required('name is required'),
            commissionerEmail: Yup.string()
                .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                .strict(true)
                .nonNullable()
                .nullable()
                .required(),
            // cadd: Yup.string().notRequired(),
            // ccounty: Yup.string().notRequired(),
            // cpostal: Yup.string()
            //     .trim()
            //     .strict(true)
            //     .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
            //     .nonNullable()
            //     .notRequired(),
            commissionerPhone: Yup.string()
                .trim()
                .strict(true)
                .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                .nonNullable()
                .required(),
            funderName: Yup.string().required(),
            funderEmail: Yup.string()
                .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                .strict(true)
                .nonNullable()
                .nullable()
                .required(),

            funderAdd: Yup.string().notRequired(),
            funderCountry: Yup.string().notRequired(),
            funderPostalCode: Yup.string()
                .trim()
                .strict(true)
                .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                .nonNullable()
                .notRequired(),
            funderContactNo: Yup.string()
                .trim()
                .strict(true)
                .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                .nonNullable()
                .required(),

            sameAsFunder: Yup.boolean().notRequired(),

            invoiceSubmission: Yup.string().required(),
            autoPayment: Yup.string().required(),
            funderCopyInvoicePoint: Yup.string().notRequired(),
            funderCopyEmail: Yup.string()
                .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                .strict(true)
                .nonNullable()
                .nullable()
                .required(),
            funderCopyAdd: Yup.string().notRequired(),
            funderCopyCountry: Yup.string().notRequired(),
            funderCopyPostalCode: Yup.string()
                .trim()
                .strict(true)
                .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                .nonNullable()
                .notRequired(),
            funderCopyContact: Yup.string()
                .trim()
                .strict(true)
                .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                .nonNullable()
                .required(),
            // locality: Yup.string().notRequired()
            // .when('funderType', {
            //     is: (val: string) => ['LA'].includes(val),
            //     then: (schema) =>
            //         schema
            //             .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            //             .strict(true)
            //             .notRequired(),
            //     otherwise: (schema) => schema.notRequired()
            // }),

            socialWorkerEmailId: Yup.string().notRequired(),
            socialWorkerContactNo: Yup.string()
                .trim()
                .strict(true)
                .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                .nonNullable()
                .notRequired(),

                // .when('funderType'), {
                //     is: (val: string) => ['Self'].includes(val),
                //     then: (schema) =>
                //         schema
                //             .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                //             .strict(true)
                //             .required(),
                //     otherwise: (schema) => schema.notRequired()
                // }),
                nextofkinEmail: Yup.string().notRequired(),
                    nextofkinContact:Yup.string()
                    .trim()
                    .strict(true)
                    .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                    .nonNullable()
                    .notRequired(),
        }),
     
    )
},[['serviceUserDetailClientId','serviceUserDetailNHSId']]);

const GenerateContractView = (props: ModalProps) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const [creationdate, setcreationdate] = useState<any>(dayjs(new Date()));
    const [effectivefromdate, seteffectivefromdate] = useState<any>(null);
    const [effectivetodate, seteffectivetodate] = useState<any>(null);
    const [dob, setdob] = useState<any>(null);
    const [doa, setdoa] = useState<any>(null);
    const [edod, setedod] = useState<any>(null);
    const [issave, setissave] = useState<boolean>(false);
    const [contractType, setContractType] = useState<any>();
    const [previewdata, setpreviewdata] = useState<any>();
    const [ldata, setldata] = useState<any>([]);
    const [ldataval, setldataval] = useState<any>('');
    const [inclusionData, setInclusionData] = useState<InclusionDataInterface | null>(null);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const resData = props?.cdata === undefined ? DataLeadInfo?.data : props?.cdata;

    console.log({ resData, DataLeadInfo, DataServiceDetail });

    const editInitialValue:  ContractInterface ={
        newContractType: '1',
        newContractCreated: dayjs(new Date()),
        newContractEffectiveFrom: null,
        newContractEffectiveTo: null,
        newContractService: resData?.serviceName,

        serviceUserDetailName: resData?.serviceUserDetails?.serviceUserName,
        serviceUserDetailDob: (resData?.serviceUserDetails?.dateOfBirth !== null && resData?.serviceUserDetails?.dateOfBirth !== "") ? dayjs(resData?.serviceUserDetails?.dateOfBirth, 'DD-MM-YYYY') : null,
        serviceUserDetailNHSId: resData?.serviceUserDetails?.nhsId,
        serviceUserDetailClientId: resData?.serviceUserDetails?.localId,
        serviceUserDetailAdd1: resData?.serviceUserDetails?.address1,
        serviceUserDetailAdd2: resData?.serviceUserDetails?.address2,
        serviceUserDetailCountry: resData?.serviceUserDetails?.county,
        serviceUserDetailPostalCode: resData?.serviceUserDetails?.postalCode,

        contractDetailSchedAddmissionDate: resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfAdmission ? (resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfAdmission !== null && resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfAdmission !== "") ? dayjs(resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfAdmission, 'DD-MM-YYYY') : null : null,
        // contractDetailEstDischargeDate: (resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfDischarge !== null && resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfDischarge !== "") ? dayjs(resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.scheduledDateOfDischarge, 'DD-MM-YYYY') : null,
        contractDetailEstDischargeDate:null,
        contractDetailFee: '',
        contractDetailCoreFee: resData?.feesDetails?.dailyCoreFee,
        contractDetailContinenceFee: inclusionData?.isContinenceCareFeeVisible === 1 ? resData?.feesDetails?.continenceCareFee : 0,
        contractDetailFNCFee: inclusionData?.isFncFeeVisible === 1 ? resData?.feesDetails?.fncFee : 0,
        contractDetailHourlyFee1: inclusionData?.is1to1AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee1 : 0,
        contractDetailHourlyFee2: inclusionData?.is1to2AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee2 : 0,
        contractDetailHourlyFee3: inclusionData?.is1to3AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee3 : 0,
        contractDetailHourlyFee4: inclusionData?.is1to4AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee4 : 0,

        contractDetailArray: resData?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled')).map((data: any) => {
            let contracts = {
                contractDetailFeeShare: '',
                funderType: data?.funderType,
                funderName: data?.funderName,
                funderEmail: data?.funderEmail,
                funderAdd: data?.address1,
                funderCountry: data?.county,
                funderPostalCode: data?.postalCode,
                funderContactNo: data?.contactNo,
                sameAsFunder: false,
                invoiceSubmission: '',
                autoPayment: '',
                funderCopyInvoicePoint: '',
                funderCopyEmail: '',
                funderCopyAdd: '',
                funderCopyCountry: '',
                funderCopyPostalCode: '',
                funderCopyContact: '',
                commissionerEmail: data?.commissionerEmail,
                commissionerPhone: data?.commissionerPhoneNo,
                socialWorkerEmailId: data?.socialWorkerEmail,
                socialWorkerContactNo: data?.socialWorkerPhoneNo,
                isSelfFunded: data?.isSelfFunded,
                nextofkinContact:'',
                nextofkinEmail:''
            };
            return contracts;
        })
    };

    // set getcontractdetails data from cdata
    const editcontractInitialValue :  ContractInterface ={
        newContractType: resData?.contractTypeId,
        newContractCreated: resData?.dateOfCreation !== null ? dayjs(resData?.dateOfCreation, 'DD-MM-YYYY') : null,
        newContractEffectiveFrom: resData?.effectiveFrom !== null ? dayjs(resData?.effectiveFrom, 'DD-MM-YYYY') : null,
        newContractEffectiveTo: resData?.effectiveTo !== null ? dayjs(resData?.effectiveTo, 'DD-MM-YYYY') : null,
        newContractService: DataLeadInfo?.data?.serviceName,

        serviceUserDetailName: resData?.serviceUserName,
        serviceUserDetailDob: resData?.dateOfBirth !== null ? dayjs(resData?.dateOfBirth, 'DD-MM-YYYY') : null,
        serviceUserDetailNHSId: resData?.nhsid,
        serviceUserDetailClientId: resData?.localId,
        serviceUserDetailAdd1: resData?.address1,
        serviceUserDetailAdd2: resData?.address2,
        serviceUserDetailCountry: resData?.city,
        serviceUserDetailPostalCode: resData?.zipCode,

        contractDetailSchedAddmissionDate: resData?.scheduledDateOfAdmission !== null ? dayjs(resData?.scheduledDateOfAdmission, 'DD-MM-YYYY') : null,
        contractDetailEstDischargeDate: resData?.dischargeDate !== null ? dayjs(resData?.dischargeDate, 'DD-MM-YYYY') : null,
        contractDetailFee: resData?.feesWeeklyOrDaily,
        contractDetailCoreFee: resData?.dailyCoreFee,
        contractDetailContinenceFee: inclusionData?.isContinenceCareFeeVisible === 1 ? resData?.continenceCareFee : 0,
        contractDetailFNCFee: inclusionData?.isFncFeeVisible === 1 ? resData?.fncFee : 0,
        contractDetailHourlyFee1: inclusionData?.is1to1AdditionalFeeVisible === 1 ? resData?.hourlyFee1 : 0,
        contractDetailHourlyFee2: inclusionData?.is1to2AdditionalFeeVisible === 1 ? resData?.hourlyFee2 : 0,
        contractDetailHourlyFee3: inclusionData?.is1to3AdditionalFeeVisible === 1 ? resData?.hourlyFee3 : 0,
        contractDetailHourlyFee4: inclusionData?.is1to4AdditionalFeeVisible === 1 ? resData?.hourlyFee4 : 0,

        contractDetailArray: resData?.funderListViewModel?.map((data: any) => {
            console.log(data);
            let contracts = {
                contractDetailFeeShare: data?.feeShare,
                funderType: data?.funderTypeName,
                funderName: data?.funderName,
                funderEmail: data?.funderEmail,
                funderAdd: data?.funderAddress,
                funderCountry: data?.funderCity,
                funderPostalCode: data?.funderZipCode,
                funderContactNo: data?.funderPhoneNo,
                sameAsFunder: data?.isSameAsFunder,
                invoiceSubmission: data?.invoiceSubmissionVia,
                autoPayment: data?.autoPayment,
                funderCopyInvoicePoint: data?.invoicePoint,
                funderCopyEmail: data?.invEmail,
                funderCopyAdd: data?.invAddress,
                funderCopyCountry: data?.invCity,
                funderCopyPostalCode: data?.invZipCode,
                funderCopyContact: data?.invPhoneNo,
                commissionerEmail: data?.commissionerEmail,
                commissionerPhone: data?.commPhoneNo,
                socialWorkerEmailId: data?.socialWorkerEmail,
                socialWorkerContactNo: data?.socialWorkerPhoneNo,
                isSelfFunded: data?.isSelfFunded || data?.funderTypeName === "Self",
                nextofkinContact:data?.nextOfKinPhoneNumber,
                nextofkinEmail:data?.nextOfKinEmail
            };
            return contracts;
        })
    };

    console.log({ editInitialValue, editcontractInitialValue });

    const { register, handleSubmit, control, watch, getValues, formState, setValue, setError } = useForm<ContractInterface>({
        mode: 'all',
        defaultValues: (props.cdata === undefined) ? editInitialValue : (props.cdata !== undefined) ? editcontractInitialValue :  inititalValues,
        resolver: yupResolver(validationSchema)
    });
    const watchValues = watch();
    const { fields } = useFieldArray({
        control,
        name: 'contractDetailArray'
    });

    const { errors } = formState;
    console.log(errors);

    const getInclusiondata = async () => {
        let res = await getInclusiondisplay(DataServiceDetail.serviceId);
        if (res.status) {
            setInclusionData(res.data);
        }
    };

    useEffect(()=>{
    if(inclusionData !== null){
        if (props.cdata === undefined) {
            setValue("contractDetailContinenceFee", inclusionData?.isContinenceCareFeeVisible === 1 ? resData?.feesDetails?.continenceCareFee : 0);
            setValue("contractDetailFNCFee", inclusionData?.isFncFeeVisible === 1 ? resData?.feesDetails?.fncFee : 0);
            setValue("contractDetailHourlyFee1", inclusionData?.is1to1AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee1 : 0,{shouldValidate : true});
            setValue("contractDetailHourlyFee2", inclusionData?.is1to2AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee2 : 0,{shouldValidate : true});
            setValue("contractDetailHourlyFee3", inclusionData?.is1to3AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee3 : 0,{shouldValidate : true});
            setValue("contractDetailHourlyFee4", inclusionData?.is1to4AdditionalFeeVisible === 1 ? resData?.feesDetails?.hourlyFee4 : 0,{shouldValidate : true});
        } else if (props.cdata !== undefined) {
            setValue("contractDetailContinenceFee", inclusionData?.isContinenceCareFeeVisible === 1 ? resData?.continenceCareFee : 0);
            setValue("contractDetailFNCFee", inclusionData?.isFncFeeVisible === 1 ? resData?.fncFee : 0);
            setValue("contractDetailHourlyFee1", inclusionData?.is1to1AdditionalFeeVisible === 1 ? resData?.hourlyFee1 : 0,{shouldValidate : true});
            setValue("contractDetailHourlyFee2", inclusionData?.is1to2AdditionalFeeVisible === 1 ? resData?.hourlyFee2 : 0,{shouldValidate : true});
            setValue("contractDetailHourlyFee3", inclusionData?.is1to3AdditionalFeeVisible === 1 ? resData?.hourlyFee3 : 0,{shouldValidate : true});
            setValue("contractDetailHourlyFee4", inclusionData?.is1to4AdditionalFeeVisible === 1 ? resData?.hourlyFee4 : 0,{shouldValidate : true});
        }
    }
    },[inclusionData])

    useEffect(() => {
        getInclusiondata();
    }, []);

    const toggleModalPreview = () => {
        setShowModal(false);
        setpreviewdata(undefined);
    };

    async function handleClickModal() {
        if (DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].status === 'Completed') {
            const docures = await updateDocuSign(DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId);
            if (docures.status) {
                setShowModal(true);
                setpreviewdata(docures.data);
            }
        } else {
            const res = await getContractPreview(DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId, DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.acdId);
            if (res.status) {
                setShowModal(true);
                setpreviewdata(res.data);
            }
        }
    }

    useEffect(() => {
        setValue('newContractType', '');
        async function getcontracttype() {
            const res = await getContracttype();
            if (res.status) {
                setContractType(res.data);
            }
        }
        getcontracttype();
    }, []);

  

    useEffect(() => {
        if (ldata.length > 0) {
            if (props.cdata !== undefined && props.cdata?.localityId !== null) {
                setldataval(props.cdata?.localityId);
            }
        }
    }, [ldata]);



    useEffect(() => {
        if (formState.isValid) {
            // if(getValues('contractDetailCoreFee') === '0' || getValues('contractDetailCoreFee') === '00' || getValues('contractDetailCoreFee') === '000' || getValues('contractDetailCoreFee') === '0000' || getValues('contractDetailCoreFee') === '00000' || getValues('contractDetailCoreFee') === '000000' || getValues('contractDetailCoreFee') === '0000000' || getValues('contractDetailCoreFee') === '00000000' || getValues('contractDetailCoreFee') === '0.0' || getValues('contractDetailCoreFee') === '0.00'){
            //     setissave(false);
            //     setError('contractDetailCoreFee',{type: 'required'})
            // } else{
                setissave(true);
            // }
           
        } else {
            setissave(false);
        }
    }, [formState]);

    
    const values = getValues();

    const TotalFees = Number(values.contractDetailContinenceFee) + Number(values.contractDetailCoreFee) + Number(values.contractDetailFNCFee);

    const save = () => {
        var finalobj = watchValues;
        Object.assign(finalobj, props.cdata === undefined ? {contractCombinationId: props.combinationId} : {contractCombinationId : props.cdata.contractCombinationId});
        // const payload = { 
        //     ...finalobj, 
        //     newContractCreated: dayjs(dayjs(finalobj.newContractCreated).toISOString()).format("DD-MM-YYYY"),
        //     contractDetailEstDischargeDate: dayjs(dayjs(finalobj.contractDetailEstDischargeDate).toISOString()).format("DD-MM-YYYY"),
        //     contractDetailSchedAddmissionDate: dayjs(dayjs(finalobj.contractDetailSchedAddmissionDate).toISOString()).format("DD-MM-YYYY"),
        //     newContractEffectiveFrom: dayjs(dayjs(finalobj.newContractEffectiveFrom).toISOString()).format("DD-MM-YYYY"),
        //     newContractEffectiveTo: dayjs(dayjs(finalobj.newContractEffectiveTo).toISOString()).format("DD-MM-YYYY"),
        //     serviceUserDetailDob: dayjs(dayjs(finalobj.serviceUserDetailDob).toISOString()).format("DD-MM-YYYY"),
        // };
        props.getval(finalobj);
    };

    const sameasfunder = (e: any, index: number) => {
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                setValue(`contractDetailArray.${index}.funderCopyInvoicePoint`, props.cdata === undefined ? DataLeadInfo?.data?.contract[index].funderName : props.cdata?.funderName);
                setValue(`contractDetailArray.${index}.funderCopyEmail`, getValues(`contractDetailArray.${index}.funderEmail`));
                setValue(`contractDetailArray.${index}.funderCopyAdd`, getValues(`contractDetailArray.${index}.funderAdd`));
                setValue(`contractDetailArray.${index}.funderCopyCountry`, getValues(`contractDetailArray.${index}.funderCountry`));
                setValue(`contractDetailArray.${index}.funderCopyPostalCode`, getValues(`contractDetailArray.${index}.funderPostalCode`));
                setValue(`contractDetailArray.${index}.funderCopyContact`, getValues(`contractDetailArray.${index}.funderContactNo`));
            } else {
                setValue(`contractDetailArray.${index}.funderCopyInvoicePoint`, '');
                setValue(`contractDetailArray.${index}.funderCopyEmail`, '');
                setValue(`contractDetailArray.${index}.funderCopyAdd`, '');
                setValue(`contractDetailArray.${index}.funderCopyCountry`, '');
                setValue(`contractDetailArray.${index}.funderCopyPostalCode`, '');
                setValue(`contractDetailArray.${index}.funderCopyContact`, '');
            }
        }
    };

    return (
        <ModalWrapperContainer
            title="Generate Contract"
            onClose={props.onClose}
            className="modal modal-small"
            content={
                    <div>
                        <div className="modal-title-header mb-2 h-auto">
                            <h3>{t('SM_Contract.New_Contract')}</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Type')}</label>
                                <Controller
                                    name="newContractType"
                                    control={control}
                                    render={({ field }) => (
                                        <select
                                            disabled={true}
                                            {...field}
                                            className={`form-control white ${errors.newContractType ? 'is-invalid' : ''}`}
                                        >
                                            <option disabled={true} value={contractType?.map((ct: any) => ct.id === watch("newContractType"))}>
                                                {t('SM_Contract.Type')}
                                            </option>
                                            {contractType !== undefined &&
                                                contractType?.map((contracts: any) => {
                                                    return (
                                                        <option key={contracts.id} value={contracts.id}>
                                                            {contracts.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    )}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Service')} </label>
                                <input disabled={true} type="text" {...register('newContractService')} className="form-control white" />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Date_of_Creation')} </label>
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="newContractCreated"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    label="Date of Creation*"
                                                    value={value}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    {...register('newContractCreated')}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // setcreationdate(date);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Effective_From')}</label>
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="newContractEffectiveFrom"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    label="Effective From*"
                                                    value={watch('newContractEffectiveFrom')}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // seteffectivefromdate(date);
                                                        //  updatedate('sdate',date,i);
                                                        // getDate(date);
                                                        // updateData(date,i,-1);
                                                    }}
                                                    disablePast
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Effective_To')}</label>
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="newContractEffectiveTo"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    label="Effective To"
                                                    value={watch('newContractEffectiveTo')}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // seteffectivetodate(date);
                                                        //  updatedate('sdate',date,i);
                                                        // getDate(date);
                                                        // updateData(date,i,-1);
                                                    }}
                                                    minDate={effectivefromdate}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-title-header mb-2 h-auto mt-3">
                            <h3>{t('SM_Contract.Service_User_Detail')}</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Service_User_Name')}</label>
                                <input
                                    type="text"
                                    value={watch('serviceUserDetailName')}
                                    {...register('serviceUserDetailName')}
                                    className={`form-control white ${errors.serviceUserDetailName ? 'is-invalid' : ''}`}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Date_of_Birth')}*</label>
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="serviceUserDetailDob"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    label="Date of Birth"
                                                    value={watch('serviceUserDetailDob')}
                                                    disabled={props.cdata === undefined ? (resData?.serviceUserDetails?.dateOfBirth === null || resData?.serviceUserDetails?.dateOfBirth === "") ? false : true : (resData?.dateOfBirth === null || resData?.dateOfBirth === "") ? false : true}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // setdob(date);
                                                        //  updatedate('sdate',date,i);
                                                        // getDate(date);
                                                        // updateData(date,i,-1);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.NHS_ID')} </label>
                                <input
                                    type="text"
                                    {...register('serviceUserDetailNHSId')}
                                    disabled={props.cdata === undefined ? resData?.serviceUserDetails?.nhsId !== '' ? true : false : resData?.nhsid !== '' ? true : false}
                                    className={`form-control white ${errors.serviceUserDetailNHSId ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Client_ID')}</label>
                                <input
                                    type="text"
                                    {...register('serviceUserDetailClientId')}
                                    disabled={props.cdata === undefined ? resData?.serviceUserDetails?.localId !== '' ? true : false : resData?.localId !== '' ? true : false}
                                    className={`form-control white ${errors.serviceUserDetailClientId ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Address1')}*</label>
                                <input type="text" {...register('serviceUserDetailAdd1')} className={`form-control white ${errors.serviceUserDetailAdd1 ? 'is-invalid' : ''}`} />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Address2')}*</label>
                                <input type="text" {...register('serviceUserDetailAdd2')} className={`form-control white ${errors.serviceUserDetailAdd2 ? 'is-invalid' : ''}`} />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.County')}*</label>
                                <input type="text" {...register('serviceUserDetailCountry')} className={`form-control white ${errors.serviceUserDetailCountry ? 'is-invalid' : ''}`} />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Postal_Code')}*</label>
                                <input type="text" {...register('serviceUserDetailPostalCode')} className={`form-control white ${errors.serviceUserDetailPostalCode ? 'is-invalid' : ''}`} />
                            </div>
                        </div>
                        <div className="modal-title-header mb-2 h-auto mt-3">
                            <h3>Contract Detail</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Scheduled_Date_Admission')}</label>
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="contractDetailSchedAddmissionDate"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    label="Scheduled Date of Admission"
                                                    value={watch('contractDetailSchedAddmissionDate')}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // setdoa(date);

                                                        //  updatedate('sdate',date,i);
                                                        // getDate(date);
                                                        // updateData(date,i,-1);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                    //  disableFuture
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Discharge_Date')}</label>
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="contractDetailEstDischargeDate"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    label="Discharge Date"
                                                    value={watch('contractDetailEstDischargeDate')}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // setedod(date);

                                                        //  updatedate('sdate',date,i);
                                                        // getDate(date);
                                                        // updateData(date,i,-1);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                    //  disableFuture
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="purple-text bold mb-2">{t('SM_Contract.Fees')}</p>
                        <div className="row">
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">Fee Type*</label>
                                <label className="form--radio-label mb-2 d-flex align-items-center">
                                    <input
                                        type="radio"
                                        {...register('contractDetailFee')}
                                        // value={watch('contractDetailFee')}
                                         value={"1"}
                                        checked={Number(watch('contractDetailFee')) === 1}
                                        id="daily"
                                        
                                    />
                                    <span className="ms-1">Daily</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center">
                                    <input
                                        type="radio"
                                        {...register('contractDetailFee')}
                                        // value={watch('contractDetailFee')}
                                        value={"2"}
                                        checked={Number(watch('contractDetailFee')) === 2}
                                        id="week"
                                      
                                    />
                                    <span className="ms-1">Weekly</span>
                                </label>
                                {/* <input type="text" {...register(`contractDetailFee`)} value={watch("contractDetailFee")} className={`form-control white ${errors.contractDetailFee ? 'is-invalid' : ''}`} /> */}
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.Daily_Core_Fee')}</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailCoreFee`)}
                                    value={watch('contractDetailCoreFee')}
                                    className={`form-control white ${errors.contractDetailCoreFee ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">Continence Fee*</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailContinenceFee`)}
                                    value={watch('contractDetailContinenceFee')}
                                    disabled={!inclusionData?.isContinenceCareFeeVisible}
                                    className={`form-control white ${errors.contractDetailContinenceFee ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">FNC Fee*</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailFNCFee`)}
                                    value={watch('contractDetailFNCFee')}
                                    disabled={!inclusionData?.isFncFeeVisible}
                                    className={`form-control white ${errors.contractDetailFNCFee ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.1_Hourly_Fee')}</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailHourlyFee1`)}
                                    value={watch('contractDetailHourlyFee1')}
                                    disabled={!inclusionData?.is1to1AdditionalFeeVisible}
                                    className={`form-control white ${errors.contractDetailHourlyFee1 ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.2_Hourly_Fee')}</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailHourlyFee2`)}
                                    value={watch('contractDetailHourlyFee2')}
                                    disabled={!inclusionData?.is1to2AdditionalFeeVisible}
                                    className={`form-control white ${errors.contractDetailHourlyFee2 ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.3_Hourly_Fee')}</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailHourlyFee3`)}
                                    value={watch('contractDetailHourlyFee3')}
                                    disabled={!inclusionData?.is1to3AdditionalFeeVisible}
                                    className={`form-control white ${errors.contractDetailHourlyFee3 ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">{t('SM_Contract.4_Hourly_Fee')}</label>
                                <input
                                    type="text"
                                    {...register(`contractDetailHourlyFee4`)}
                                    value={watch('contractDetailHourlyFee4')}
                                    disabled={!inclusionData?.is1to4AdditionalFeeVisible}
                                    className={`form-control white ${errors.contractDetailHourlyFee4 ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="mb-1">Total Fee</label>
                                <input
                                    type="text"
                                    disabled={true}
                                    value={TotalFees}
                                    className={`form-control white`}
                                />
                            </div>
                        </div>

                        {fields?.map((field, index) => (
                            <div key={field?.id}>
                                <p className="purple-text bold mt-2">Funder</p>
                                <div className="row mt-3">
                                    <div className="col-lg-4 mb-2">
                                        {fields.length === 1 && <label className="mb-1">Fee Share*</label>}
                                        {fields.length > 1 && <label className="mb-1">{field.isSelfFunded ? 'Self' : field.funderType} Funder Fee Share*</label>}

                                        <input
                                            type="text"
                                            // value={field?.contractDetailFeeShare}
                                            {...register(`contractDetailArray.${index}.contractDetailFeeShare`)}
                                            className={`form-control white ${errors?.contractDetailArray?.[index]?.contractDetailFeeShare ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Type')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderType}
                                            value={watch(`contractDetailArray.${index}.isSelfFunded`) ? 'Self' : watch(`contractDetailArray.${index}.funderType`)}
                                            {...register(`contractDetailArray.${index}.funderType`)}
                                            className={`form-control white ${errors?.contractDetailArray?.[index]?.funderType ? 'is-invalid' : ''}`}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Funder_Name')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderName}
                                            {...register(`contractDetailArray.${index}.funderName`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderName ? 'is-invalid' : ''}`}
                                            disabled
                                        ></input>
                                    </div>
                                    {/* {ldata.length > 0 &&
                                        <div className="col-lg-4 mb-2">
                                            <label className="mb-1">Locality</label>
                                            <select  {...register('locality', {
                                                onChange: (e: any) => {
                                                    // updateFileds(e);
                                                    setldataval(e.target.value);
                                                },

                                            })}
                                                value={ldataval}
                                                className={`form-control white ${errors.locality ? 'is-invalid' : ''}`}>
                                                <option>Locality</option>

                                                {ldata.length > 0 &&
                                                    ldata.map((locality: any) => {
                                                        return (
                                                            <option key={locality.id} value={locality.id}>
                                                                {locality.name}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    } */}
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Email')}*</label>
                                        <input
                                            type="text"
                                            // value={field?.funderEmail}
                                            {...register(`contractDetailArray.${index}.funderEmail`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderEmail ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Address')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderAdd}
                                            {...register(`contractDetailArray.${index}.funderAdd`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderAdd ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.County')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCountry}
                                            {...register(`contractDetailArray.${index}.funderCountry`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCountry ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Postal_Code')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderPostalCode}
                                            {...register(`contractDetailArray.${index}.funderPostalCode`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderPostalCode ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Contact_No')}*</label>
                                        <input
                                            type="text"
                                            // value={field?.funderContactNo}
                                            {...register(`contractDetailArray.${index}.funderContactNo`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderContactNo ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    {field.isSelfFunded ? (
                                        <>
                                            <div className="col-lg-4 mb-2">
                                                {/* <label className="mb-1">{t('SM_Contract.Contact_No')}</label> */}
                                                <label className="mb-1">Next of Kin Email</label>
                                                <input
                                                    type="text"
                                                    // value={field?.funderContactNo}
                                                    {...register(`contractDetailArray.${index}.nextofkinEmail`)}
                                                    className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderContactNo ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                            <div className="col-lg-4 mb-2">
                                                {/* <label className="mb-1">{t('SM_Contract.Contact_No')}</label> */}
                                                <label className="mb-1">Contact No</label>
                                                <input
                                                    type="text"
                                                    // value={field?.funderContactNo}
                                                    {...register(`contractDetailArray.${index}.nextofkinContact`)}
                                                    className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderContactNo ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        // checked={field.sameAsFunder}
                                        {...register(`contractDetailArray.${index}.sameAsFunder`, {
                                            onChange: (e: any) => {
                                                sameasfunder(e, index);
                                            }
                                        })}
                                        checked={Number(watch(`contractDetailArray.${index}.sameAsFunder`)) === 1}
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label">{t('VacantRightbar.same')}</label>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">Invoice Submission via*</label>
                                        <label className="form--radio-label mb-2 d-flex align-items-center">
                                            <input
                                                type="radio"
                                                {...register(`contractDetailArray.${index}.invoiceSubmission`)}
                                                // value={watch(`contractDetailArray.${index}.invoiceSubmission`)}
                                                checked={Number(watch(`contractDetailArray.${index}.invoiceSubmission`)) === 1}
                                                id="tradeshift"
                                                value={"1"}
                                                
                                            />
                                            <span className="ms-1">Trade Shift</span>
                                        </label>

                                        <label className="form--radio-label mb-2 d-flex align-items-center">
                                            <input
                                                type="radio"
                                                {...register(`contractDetailArray.${index}.invoiceSubmission`)}
                                                // value={watch(`contractDetailArray.${index}.invoiceSubmission`)}
                                                checked={Number(watch(`contractDetailArray.${index}.invoiceSubmission`)) === 2}
                                                id="email"
                                                value={"2"}
                                            />
                                            <span className="ms-1">Email</span>
                                        </label>

                                        <label className="form--radio-label mb-2 d-flex align-items-center">
                                            <input
                                                type="radio"
                                                {...register(`contractDetailArray.${index}.invoiceSubmission`)}
                                                // value={watch(`contractDetailArray.${index}.invoiceSubmission`)}
                                                id="post"
                                                value={"3"}
                                                checked={Number(watch(`contractDetailArray.${index}.invoiceSubmission`)) === 3}
                                            />
                                            <span className="ms-1">Post</span>
                                        </label>
                                    </div>

                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">Auto Payment*</label>
                                        <label className="form--radio-label mb-2 d-flex align-items-center">
                                            <input
                                                type="radio"
                                                {...register(`contractDetailArray.${index}.autoPayment`)}
                                                // value={watch(`contractDetailArray.${index}.autoPayment`)}
                                                id="yes"
                                                value={"1"}
                                                checked={Number(watch(`contractDetailArray.${index}.autoPayment`)) === 1}
                                            />
                                            <span className="ms-1">Yes</span>
                                        </label>

                                        <label className="form--radio-label mb-2 d-flex align-items-center">
                                            <input
                                                type="radio"
                                                {...register(`contractDetailArray.${index}.autoPayment`)}
                                                // value={watch(`contractDetailArray.${index}.autoPayment`)}
                                                id="no"
                                                value={"2"}
                                                checked={Number(watch(`contractDetailArray.${index}.autoPayment`)) === 2}
                                            />
                                            <span className="ms-1">No</span>
                                        </label>
                                    </div>

                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Invoice_Point')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCopyInvoicePoint}
                                            {...register(`contractDetailArray.${index}.funderCopyInvoicePoint`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCopyInvoicePoint ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Email')}*</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCopyEmail}
                                            {...register(`contractDetailArray.${index}.funderCopyEmail`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCopyEmail ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Address')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCopyAdd}
                                            {...register(`contractDetailArray.${index}.funderCopyAdd`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCopyAdd ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.County')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCopyCountry}
                                            {...register(`contractDetailArray.${index}.funderCopyCountry`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCopyCountry ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Postal_Code')}</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCopyPostalCode}
                                            {...register(`contractDetailArray.${index}.funderCopyPostalCode`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCopyPostalCode ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">{t('SM_Contract.Contact_No')}*</label>
                                        <input
                                            type="text"
                                            // value={field?.funderCopyContact}
                                            {...register(`contractDetailArray.${index}.funderCopyContact`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.funderCopyContact ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                </div>
                                <p className="purple-text bold mt-2">Commissioner</p>
                                <div className="row mt-3">
                                    <div className="col-lg-4 mb-2">
                                        {/* <label className="mb-1">{t('SM_Contract.Email')}*</label> */}
                                        <label className="mb-1">Commissioner Email*</label>
                                        <input
                                            type="text"
                                            // value={field?.commissionerEmail}
                                            {...register(`contractDetailArray.${index}.commissionerEmail`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.commissionerEmail ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label className="mb-1">Contact No*</label>
                                        <input
                                            type="text"
                                            // value={field?.commissionerPhone}
                                            {...register(`contractDetailArray.${index}.commissionerPhone`)}
                                            className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.commissionerPhone ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                </div>

                                {field?.funderType === 'LA' ? (
                                    <>
                                        <p className="purple-text bold mt-2">Social Worker</p>
                                        <div className="row mt-3">
                                            <div className="col-lg-4 mb-2">
                                                {/* <label className="mb-1">{t('SM_Contract.Email')}*</label> */}
                                                <label className="mb-1">Social Worker Email</label>
                                                <input
                                                    type="text"
                                                    // value={field?.commissionerEmail}
                                                    {...register(`contractDetailArray.${index}.socialWorkerEmailId`)}
                                                    className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.socialWorkerEmailId ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                            <div className="col-lg-4 mb-2">
                                                <label className="mb-1">Contact No</label>
                                                <input
                                                    type="text"
                                                    // value={field?.commissionerPhone}
                                                    {...register(`contractDetailArray.${index}.socialWorkerContactNo`)}
                                                    className={`form-control white flex-7 ${errors?.contractDetailArray?.[index]?.socialWorkerContactNo ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        ))}
                        {previewdata !== undefined && <RenderModalContainer>{showModal && <PreviewContractContainer onClose={toggleModalPreview} previewdata={previewdata} />}</RenderModalContainer>}
                    </div>
            }
            footer={
                <div>
                    {DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId === 0 && (
                        <button disabled={!issave} onClick={save} className="btn btn-secondary me-2">
                            {t('SM_Contract.Save_btn')}
                        </button>
                    )}
                    {DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId !== 0 && (
                        <button disabled={issave && props.cdata !== undefined && DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].status == '' ? false : true} onClick={save} className="btn btn-secondary me-2">
                            {t('SM_Contract.Save_btn')}
                        </button>
                    )}
                    <button disabled={props.cdata !== undefined && props.cdata ? false : true} onClick={() => handleClickModal()} className="btn btn-secondary me-2">
                        {t('SM_Contract.Preview_btn')}
                    </button>
                    <button disabled={props.cdata !== undefined && DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].status == '' ? false : true} onClick={props.send} className="btn btn-primary">
                        {t('SM_Contract.Confirm_Send_btn')}
                    </button>
                </div>
            }
        />
    );
};

export default GenerateContractView;
