import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationDetailModel } from '../../utils/model';

const initialState: OrganizationDetailModel = {
    StatusOrganizationDetail: false,
    DataOrganizationDetail: {},
    MessageOrganizationDetail: '',
    ErrorOrganizationDetail: ''
};

export const organizationDetailSlice = createSlice({
    name: 'organizationDetail',
    initialState,
    reducers: {
        setOrganizationDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusOrganizationDetail = true;
            state.DataOrganizationDetail = payload;
            state.MessageOrganizationDetail = 'Success';
        },
        removeOrganizationDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusOrganizationDetail = false;
            state.DataOrganizationDetail = {};
            state.MessageOrganizationDetail = '';
            state.ErrorOrganizationDetail = '';
        },
        errorOrganizationDetailAction: (state, { payload }: PayloadAction) => {
            state.StatusOrganizationDetail = false;
            state.DataOrganizationDetail = {};
            state.MessageOrganizationDetail = 'Failed';
            state.ErrorOrganizationDetail = payload;
        }
    },
    extraReducers: {}
});

export const { setOrganizationDetailAction, removeOrganizationDetailAction, errorOrganizationDetailAction } = organizationDetailSlice.actions;

export default organizationDetailSlice.reducer;
