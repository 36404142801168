import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import Progress_Note from '../../../assets/images/progress-note-white.svg';
import { AssessmentStatus } from '../../../utils/constant';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import dayjs from 'dayjs';
import moment from 'moment';

const ProgressNoteView = (props: any) => {
    const { t } = useTranslation();
    const { assessmentDetails } = props;
    let [dateAdded, setDateAdded] = useState<any>(null);
    let [progressNoteDate, setprogressNoteDate] = useState<any>([]);
    let [assessmentDetailsProgressView, setassessmentDetailsProgressView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const validationSchema = Yup.object().shape({
        apnId: Yup.string().nullable().notRequired(),
        addedToReferralsLog: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        progressToAssessment: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        dateAdded: Yup.date().required('Date is required.'),
        reason: Yup.string().required(),
        assessmentProgressNotesDetailModels: Yup.array().of(
            Yup.object().shape({
                apnId: Yup.string().nullable().notRequired(),
                apndId: Yup.string().nullable().notRequired(),
                progressNoteDate: Yup.date().required('Progress note date is required.'),
                comments: Yup.string().required(),
                staffInitials: Yup.string().required()
            })
        )
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'assessmentProgressNotesDetailModels', control });
    const assessmentProgressNotesDetailModels = watch('assessmentProgressNotesDetailModels');

    const addnewline = () => {
        let index = fields.length - 1 + 1;
        append([
            {
                apnId: 0,
                apndId: 0,
                progressNoteDate: null,
                comments: '',
                staffInitials: ''
            }
        ]);
        setValue(`assessmentProgressNotesDetailModels[${index}].apnId`, 0);
        setValue(`assessmentProgressNotesDetailModels[${index}].apndId`, 0);
        setValue(`assessmentProgressNotesDetailModels[${index}].progressNoteDate`, null);
        setValue(`assessmentProgressNotesDetailModels[${index}].comments`, '');
        setValue(`assessmentProgressNotesDetailModels[${index}].staffInitials`, '');
        let progressNoteDateTemp = progressNoteDate;
        progressNoteDateTemp[index] = null;
        setprogressNoteDate(progressNoteDateTemp);
    };

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsProgressView(props?.assessmentDetails?.assessmentProgressNoteModel?.formData);
            setValue('apnId', assessmentDetails?.assessmentProgressNoteModel?.formData?.apnId !== '' ? assessmentDetails?.assessmentProgressNoteModel?.formData?.apnId : 0, { shouldValidate: true });
            setValue('addedToReferralsLog', assessmentDetails?.assessmentProgressNoteModel?.formData?.addedToReferralsLog === 1 ? '1' : '0', { shouldValidate: true });
            setValue('progressToAssessment', assessmentDetails?.assessmentProgressNoteModel?.formData?.progressToAssessment === 1 ? '1' : '0', { shouldValidate: true });
            setValue(
                'dateAdded',
                assessmentDetails?.assessmentProgressNoteModel?.formData?.dateAdded ? window.$utils.dateFormat(assessmentDetails?.assessmentProgressNoteModel?.formData?.dateAdded) : '',
                { shouldValidate: true }
            );
            let duedate = moment(assessmentDetails?.assessmentProgressNoteModel?.formData?.dateAdded, 'DD-MM-YYYY');
            setDateAdded(assessmentDetails?.assessmentProgressNoteModel?.formData?.dateAdded ? dayjs(new Date(duedate.toDate())) : '');
            setValue('reason', assessmentDetails?.assessmentProgressNoteModel?.formData?.reason ? assessmentDetails?.assessmentProgressNoteModel?.formData?.reason : '', { shouldValidate: true });
            let progressNoteDateTemp = progressNoteDate;
            if (assessmentDetails?.assessmentProgressNoteModel?.formData?.assessmentProgressNotesDetailModels.length > 0) {
                assessmentDetails?.assessmentProgressNoteModel?.formData?.assessmentProgressNotesDetailModels.map((item: any, i: any) => {
                    let duedate = moment(item.progressNoteDate, 'DD-MM-YYYY');
                    progressNoteDateTemp[i] = item.progressNoteDate ? dayjs(new Date(duedate.toDate())) : '';

                    append([
                        {
                            apnId: item.apnId,
                            apndId: item.apndId,
                            progressNoteDate: item.progressNoteDate ? item.progressNoteDate : null,
                            comments: item.comments,
                            staffInitials: item.staffInitials
                        }
                    ], {shouldFocus: false});
                    setValue(`assessmentProgressNotesDetailModels[${i}].apnId`, item.apnId, { shouldValidate: true });
                    setValue(`assessmentProgressNotesDetailModels[${i}].apndId`, item.apndId, { shouldValidate: true });
                    setValue(`assessmentProgressNotesDetailModels[${i}].progressNoteDate`, item.progressNoteDate ? window.$utils.dateFormat(item.progressNoteDate) : null, { shouldValidate: true });
                    setValue(`assessmentProgressNotesDetailModels[${i}].comments`, item.comments, { shouldValidate: true });
                    setValue(`assessmentProgressNotesDetailModels[${i}].staffInitials`, item.staffInitials, { shouldValidate: true });
                });
                setprogressNoteDate(progressNoteDateTemp);
            }

            // setValue('duedate', props.invoicedata?.invoiceModel?.dueDate ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.dueDate) : '', { shouldValidate: true });
        } else {
            setValue('apnId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        if (fields.length === 0 && assessmentDetails === undefined) {
            append([
                {
                    apnId: 0,
                    apndId: 0,
                    progressNoteDate: 0,
                    comments: '',
                    staffInitials: ''
                }
            ], {shouldFocus: false});
            setValue(`assessmentProgressNotesDetailModels[${0}].apnId`, 0);
            setValue(`assessmentProgressNotesDetailModels[${0}].apndId`, 0);
            setValue(`assessmentProgressNotesDetailModels[${0}].progressNoteDate`, null);
            setValue(`assessmentProgressNotesDetailModels[${0}].comments`, '');
            setValue(`assessmentProgressNotesDetailModels[${0}].staffInitials`, '');
            setprogressNoteDate([null]);
        }
    }, [fields, assessmentDetails]);

    useEffect(() => {
        props.progressNoteFormValid(formState.isValid);
        let objProgressNote = {
            apnId: getValues('apnId'),
            addedToReferralsLog: getValues('addedToReferralsLog'),
            progressToAssessment: getValues('progressToAssessment'),
            dateAdded: getValues('dateAdded'),
            reason: getValues('reason'),
            assessmentProgressNotesDetailModels: assessmentProgressNotesDetailModels
        };
        props.progressNoteDataChange(objProgressNote);
        // if (formState.isValid) {
        //     let objProgressNote = {
        //         addedToReferralsLog: getValues('addedToReferralsLog'),
        //         progressToAssessment: getValues('progressToAssessment'),
        //         dateAdded: getValues('dateAdded'),
        //         reason: getValues('reason'),
        //         assessmentProgressNotesDetailModels: assessmentProgressNotesDetailModels
        //     };
        //     props.progressNoteDataChange(objProgressNote);
        // }
    }, [formState]);

    return (
        <div id="progress-note">
            <div className="modal-title-header mb-0 mt-3">
                <h3>
                    <img src={Progress_Note} alt="" width={19} className="me-2" />
                    {t('ProgressNote.Progress_Notes')}
                </h3>
            </div>
            <div className="border-layout p-3">
                <p className="purple-text text-center mb-2 bold">{t('ProgressNote.Office_only')}</p>
                <div className="d-flex">
                    <div className="d-flex mt-2">
                        <label>{t('ProgressNote.Added_Log')}</label>
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <>
                                <input
                                    type="hidden"
                                    {...register('apnId', {
                                        // onChange: (e: any) => {
                                        //     props.updateData(e);
                                        // }
                                    })}
                                    name={'apnId' as const}
                                />
                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('addedToReferralsLog', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'addedToReferralsLog' as const}
                                        value={1}
                                        defaultChecked={true}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('addedToReferralsLog', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'addedToReferralsLog' as const}
                                        value={0}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label>
                            </>
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">
                                {': '}
                                {assessmentDetailsProgressView.addedToReferralsLog === 1 ? 'Yes' : 'No'}
                            </p>
                        )}
                    </div>
                    <div className="d-flex mt-2 ms-3">
                        <label>Progress to assessment*</label>

                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <>
                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('progressToAssessment', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'progressToAssessment' as const}
                                        value={1}
                                        defaultChecked={true}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('progressToAssessment', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'progressToAssessment' as const}
                                        value={0}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label>

                                {/* <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('progressToAssessment', {
                                            onChange: (e: any) => {
                                               
                                            }
                                        })}
                                        name={'progressToAssessment' as const}
                                        value={1}
                                        defaultChecked={true}
                                        
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('progressToAssessment', {
                                            onChange: (e: any) => {
                                              
                                            }
                                        })}
                                        name={'progressToAssessment' as const}
                                        value={0}
                                       
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label> */}
                            </>
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">
                                {': '}
                                {assessmentDetailsProgressView.progressToAssessment === 1 ? 'Yes' : 'No'}
                            </p>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="d-flex align-items-center mt-3">
                            <label className="no-wrap me-2">{t('ProgressNote.Date_added')}</label>

                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <div className="input-group date datepicker-input-css" id="datepicker">
                                    {/* <DatePicker className="form-control white" format="DD/MM/YYYY" /> */}
                                    <Controller
                                        control={control}
                                        name="dateAdded"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    value={dateAdded}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // props.getDate(date);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{actionBar: { actions: ['clear','today'] },}}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                <p className="bold">{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsProgressView.dateAdded))}</p>
                            )}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                    </div>
                </div>
                <p className="purple-text mt-3 mb-2">{t('ProgressNote.Progress_Notes')}</p>
                <p>
                    <i>{t('ProgressNote.Progress_Note_Description')}</i>
                </p>
                <p className="purple-text mt-3 mb-2">{t('ProgressNote.Progress_Note_Description1')}:</p>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <textarea
                        {...register('reason', {
                            onChange: (e: any) => {
                                // props.updateData(e);
                            }
                        })}
                        name={'reason' as const}
                        className={`form-control white ${errors.reason ? 'is-invalid' : ''}`}
                        rows={2}
                    ></textarea>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsProgressView.reason}</p>}

                {/* <p className='bold mb-2'>Lorem Ipsum</p> */}

                <p className="purple-text mt-3 mb-2 bold text-center">{t('ProgressNote.Progress_Notes')}</p>

                <div className="table-custom-width">
                    <div className="table-radius">
                        <table className="table table-progress mb-0">
                            <tbody>
                                <tr>
                                    <th>{t('ProgressNote.DATE')}</th>
                                    <th>{t('ProgressNote.PROGRESS/COMMENTS')}</th>
                                    <th>{t('ProgressNote.STAFF_INITIALS')}</th>
                                </tr>
                                {fields.length > 0 &&
                                    fields.map((field: any, i: any) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                                        <div className="input-group date datepicker-input-css" id="datepicker">
                                                            {/* <DatePicker className="form-control white" format="DD/MM/YYYY" /> */}
                                                            <Controller
                                                                control={control}
                                                                name={`assessmentProgressNotesDetailModels.${i}.progressNoteDate` as const}
                                                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                                            value={progressNoteDate[i]}
                                                                            format="DD/MM/YYYY"
                                                                            {...fieldProps}
                                                                            onChange={(date: any) => {
                                                                                fieldProps.onChange(date);
                                                                                // props.getDate(date);
                                                                            }}
                                                                            views={['year', 'month', 'day']}
                                                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                                        <p className="bold mb-2">
                                                            {window.$utils.dateFormatDDMMYYYY(
                                                                window.$utils.dateFormat(assessmentDetailsProgressView.assessmentProgressNotesDetailModels[i].progressNoteDate)
                                                            )}
                                                        </p>
                                                    )}
                                                </td>
                                                <td>
                                                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                                        <input
                                                            type="text"
                                                            {...register(`assessmentProgressNotesDetailModels.${i}.comments`, {
                                                                onChange: (e: any) => {
                                                                    // props.updateData(e);
                                                                }
                                                            })}
                                                            name={`assessmentProgressNotesDetailModels.${i}.comments` as const}
                                                            //@ts-ignore
                                                            className={`form-control white ${errors.assessmentProgressNotesDetailModels?.[i]?.comments ? 'is-invalid' : ''}`}
                                                        />
                                                    )}
                                                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                                        <p className="bold mb-2">{assessmentDetailsProgressView.assessmentProgressNotesDetailModels[i].comments}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                                        <>
                                                            <input
                                                                type="text"
                                                                {...register(`assessmentProgressNotesDetailModels.${i}.staffInitials`, {
                                                                    onChange: (e: any) => {
                                                                        // props.updateData(e);
                                                                    }
                                                                })}
                                                                name={`assessmentProgressNotesDetailModels.${i}.staffInitials` as const}
                                                                //@ts-ignore
                                                                className={`form-control white ${errors.assessmentProgressNotesDetailModels?.[i]?.staffInitials ? 'is-invalid' : ''}`}
                                                            />

                                                            <input
                                                                type="hidden"
                                                                {...register('apnId', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'apnId' as const}
                                                            />
                                                            <input
                                                                type="hidden"
                                                                {...register('apndId', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'apndId' as const}
                                                            />
                                                        </>
                                                    )}
                                                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                                        <p className="bold mb-2">{assessmentDetailsProgressView.assessmentProgressNotesDetailModels[i].staffInitials}</p>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <div className="mt-2">
                            <a href="#" onClick={addnewline}>
                                <i className="fa-solid fa-circle-plus purple-text"></i> {t('ProgressNote.Add_Row')}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProgressNoteView;
