import OrganizationFilterView from './OrganizationFilterView';

const OrganizationFilterContainer = (props: any) => {
    return (
        <>
            <OrganizationFilterView />
        </>
    );
};

export default OrganizationFilterContainer;
