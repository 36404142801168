import React from 'react';
import NHSReferralHeaderView from './NHSReferralHeaderView';

const NHSReferralHeaderContainer = () => {
    return (
        <div>
            <NHSReferralHeaderView />
        </div>
    );
};

export default NHSReferralHeaderContainer;