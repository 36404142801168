import React, { useEffect, useState } from "react";
import LocationMap from "../../../../../assets/images/location-map.png";
import LocationIcon from "../../../../../assets/images/location-direction-icon.svg";
import LocationImage from "../../../../../assets/images/owl-image1.png";
import TrianglAarrow from "../../../../../assets/images/triangle.svg";
import cqc from "../../../../../assets/images/CQC.png";
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import GoogleMapBaseComponent from "../../../../../components/GoogleMapBaseComponent/GoogleMapBaseComponent";
import { GetServiceLocationDetails } from "../../../../../service/service-details-service";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../../store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const LocationView = () => {
  const [containerStyle, setContainerStyle] = useState<any>({
    width: "100%",
    height: "400px",
  });
  const [centerPoint, setCenterPoint] = useState<any>(null);
  const [showOutOfBorder, setShowOutOfBourder] = useState<boolean>(false);
  const [zoom, setZoom] = useState<any>(11);
  const [locationDetails, SetLocationDetails] = useState<any>(null)
  const [serviceListsData, setServiceListsData] = useState([
    {
      serviceId: null,
      serviceName: null,
      latitude: 0,
      longitude: 0,
    }
  ]);
  const [dataCityWiseLatLngList, setDataCityWiseLatLngList] = useState<any>(null);
  const [directions, setDirections] = useState<any>(null);

  const { StatusUserLocation, DataUserLocation } = useSelector(
    (state: RootState) => state.userLocation
  );

  const { t } = useTranslation();
  const params = useParams();

  useEffect(() => {
    GetServiceLocationList()
  }, [])

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (locationDetails) {
      if (locationDetails && locationDetails.serviceLatitude && locationDetails.serviceLongitude) {
        let centerpoint = { lat: Number(locationDetails?.serviceLatitude), lng: Number(locationDetails?.serviceLongitude) };
        setCenterPoint(centerpoint);
      }
      // let centerpoint = {
      //   lat: DataUserLocation?.user_co0rd?.latitude,
      //   lng: DataUserLocation?.user_co0rd?.longitude,
      // };
      setServiceListsData([
        {
          serviceId: locationDetails?.serviceId,
          serviceName: locationDetails?.serviceName,
          latitude: Number(locationDetails?.serviceLatitude),
          longitude: Number(locationDetails?.serviceLongitude),
          //  latitude :52.6141,
          //  longitude : -1.097
        }
      ]);
      const convertedLatLngList = locationDetails?.cityLatLongList?.map((point: any) => ({
        lat: Number(point.latitude),
        lng: Number(point.longitude)
      }));
      setDataCityWiseLatLngList(convertedLatLngList);

    }


  }, [locationDetails]);
  useEffect(() => {

  }, [])


  const GetServiceLocationList = async () => {
    const result = await GetServiceLocationDetails(params?.serviceSlug)
    SetLocationDetails(result?.data)
  }
  const handleDirectionClick = () => {
    const directionsService = new google.maps.DirectionsService();
    //  const origin = new google.maps.LatLng(Number(DataUserLocation?.user_coords?.latitude), Number(DataUserLocation?.user_coords?.longitude));
    let origin = undefined
    if(DataUserLocation?.user_coords?.latitude){
      origin = {
        lat: DataUserLocation?.user_coords?.latitude!,
        lng: DataUserLocation?.user_coords?.longitude!,
      };
    }
    // const destination = new google.maps.LatLng(Number(locationDetails?.serviceLatitude), Number(locationDetails?.serviceLongitude));
    let destination = undefined
    if(locationDetails?.serviceLatitude!){
      destination = {
        lat: locationDetails?.serviceLatitude!,
        lng: locationDetails?.serviceLongitude!,
      };
    }
    
    // directionsService.route(
    //   {
    //     origin: origin,
    //     destination: destination,
    //     travelMode: google.maps.TravelMode.DRIVING,
    //   },
    //   (result, status) => {
    //     if (status === google.maps.DirectionsStatus.OK) {
    //       setDirections(result);
    //     } else {
    //       console.error(`Directions request failed due to ${status}`);
    //     }
    //   }
    // );

    let url = process.env.REACT_APP_MAPS_DIRECTIONS_URL;

    if (origin) {
      const originStr = `${origin.lat},${origin.lng}`;
      url += `&origin=${originStr}`;
    }

    if (destination) {
      const destinationStr = `${destination.lat},${destination.lng}`;
      url += `&destination=${destinationStr}`;
    }

    window.open(url, '_blank');
  };
  return (
    <div className="section-padding pb-0" id="location-section">
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
        <h4 className="text-center section-title mb-3 bolder">{t("Location.Location_Title")}</h4>
        <p className="section-sub-title text-center mb-4 bolder">
          {t("Location.Find_Location")}
        </p>
        <div className="position-relative">
          <GoogleMapBaseComponent
            containerStyle={containerStyle}
            centerPoint={centerPoint}
            zoom={zoom}
            markerPoint={serviceListsData}
            borderPoint={dataCityWiseLatLngList}
            showOutOfBorder={false}
            directions={directions}
            Infobox={<div className="location-map-box-pin">
              <div className="d-flex ">
                <div className="location-image-box">
                  <img
                    src={locationDetails?.serviceLogoImage}
                    alt="Location Image"
                    className="img-fluid"
                    width={74}
                  />
                </div>
                <div className="w-100">
                  <div className="d-flex">
                    <p className="bolder lead-text map-box-title">
                      {locationDetails?.serviceName}
                    </p>
                    <p className="ms-auto bold map-box-subtitle">
                      <i className="fa-solid fa-star yellow-star"></i> 4.5 (153)
                    </p>
                  </div>
                  <p className="darkgray-text">{locationDetails?.address}</p>
                  <div className="cqc-rating d-flex mt-1">
                    <img
                      src={cqc}
                      className="img-fluid me-2"
                      alt="cqc"
                      width={70}
                    />
                    <p className="bold d-flex align-items-center green-text ms-auto">
                      {locationDetails?.cqcRating}
                      <span className={`${locationDetails && locationDetails?.cqcRating ?
                        (
                          locationDetails?.cqcRating.toLowerCase() === 'requires improvement' ? 'dot-round-orange ms-1' :
                            (locationDetails?.cqcRating.toLowerCase() === 'inadequate' ? 'dot-round-red' :
                              (locationDetails?.cqcRating.toLowerCase() === 'good' ? 'dot-round' : 'dot-round'))
                        )
                        : ''
                        } ms-1`}></span>
                    </p>
                  </div>
                </div>
              </div>
              <img
                src={TrianglAarrow}
                alt="triangle arrow"
                className="box-botton-arrow"
              />
            </div>}
          />
          <a className="btn btn-blue btn-direction" onClick={handleDirectionClick} >
            <img
              src={LocationIcon}
              alt="Location Direction"
              width={17}
              className="me-1"
            />
            {t("Location.Directions")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LocationView;
