import React from 'react';
import ReferralManagerContainer from '../../ReferralManagementPage/ReferralManager/ReferralManagerContainer';

const NHSReferralManagementView = () => {
    return (
        <div>
            <ReferralManagerContainer/>
        </div>
    );
};

export default NHSReferralManagementView;