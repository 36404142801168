import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import Strength from '../../../assets/images/strength-white.svg';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import dayjs from 'dayjs';
import moment from 'moment';
import { AssessmentStatus } from '../../../utils/constant';

const StrengthAssessmentView = (props: any) => {
    const { assessmentDetails } = props;

    let [assessmentDetailsStrengthAssessmentView, setassessmentDetailsStrengthAssessmentView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const { t } = useTranslation();

    let [dateOfLastKnownGpVisit, setdateOfLastKnownGpVisit] = useState<any>(null);
    let [dateOfLastKnownDentistAppointment, setdateOfLastKnownDentistAppointment] = useState<any>(null);
    let [dateOfLastKnownChiropodyAppointment, setdateOfLastKnownChiropodyAppointment] = useState<any>(null);
    let [dateOfLastKnownOpticiansAppointment, setdateOfLastKnownOpticiansAppointment] = useState<any>(null);
    let [datesOfKnownFalls, setdatesOfKnownFalls] = useState<any>(null);

    const validationSchema = Yup.object().shape({
        asbaId: Yup.string().notRequired(),
        isEnglishYourFirstLanguage: Yup.string().required(),
        doYouUseGestures: Yup.string().required(),
        doYouUseAnyAssistiveTechnology: Yup.string().required(),
        howWellDoYouUnderstandVerbal: Yup.string().required(),
        howWellDoYouUnderstandWrittenInformation: Yup.string().required(),
        whatIsImportantForUsToKnowToSupportYouWithYourCommunication: Yup.string().required(),
        areThereAnyBehavioursOfConcernInRelation: Yup.string().required(),
        doesThisPersonRequireInputFromThePbsTeam: Yup.string().required(),
        howManyHoursPerWeek: Yup.string().required(),
        areYouIndependentWithThis1: Yup.string().required(),
        yourFamilyHelpWithThis1: Yup.string().required(),
        anotherHelpsWithThis1: Yup.string().required(),
        wouldYouLikeToSupportYouWithYourCommunication: Yup.string().required(),
        cooking: Yup.string().required(),
        shopping: Yup.string().required(),
        cleaning: Yup.string().required(),
        laundry: Yup.string().required(),
        tidying: Yup.string().required(),
        oralCare: Yup.string().required(),
        knowingTimeOfDayNight: Yup.string().required(),
        doYouHaveAnyGoalsYouWishToAchieveInThisArea: Yup.string().required(),
        areThereAnyRisksWhenUndertakingTheseActivities1: Yup.string().required(),
        areYouIndependentWithThis2: Yup.string().required(),
        yourFamilyHelpWithThis2: Yup.string().required(),
        anotherHelpsWithThis2: Yup.string().required(),
        wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities: Yup.string().required(),
        mobilityAndEquipmentRequired: Yup.string().required(),
        dressingUndressing: Yup.string().required(),
        washingBathing: Yup.string().required(),
        nutritionalCare: Yup.string().required(),
        continenceCare: Yup.string().required(),
        areThereAnyRisksWhenUndertakingTheseActivities2: Yup.string().required(),
        areYouIndependentWithThis3: Yup.string().required(),
        yourFamilyHelpWithThis3: Yup.string().required(),
        anotherHelpsWithThis3: Yup.string().required(),
        wouldYouLikeToSupportYouWithYourPersonalCare: Yup.string().required(),
        dateOfLastKnownGpVisitNa: Yup.boolean().required('Please tick checkbox.'),
        dateOfLastKnownGpVisit: Yup.date().when('dateOfLastKnownGpVisitNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        dateOfLastKnownDentistAppointmentNa: Yup.boolean().required('Please tick checkbox.'),
        dateOfLastKnownDentistAppointment: Yup.date().when('dateOfLastKnownDentistAppointmentNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        dateOfLastKnownChiropodyAppointmentNa: Yup.boolean().required('Please tick checkbox.'),
        dateOfLastKnownChiropodyAppointment: Yup.date().when('dateOfLastKnownChiropodyAppointmentNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        dateOfLastKnownOpticiansAppointmentNa: Yup.boolean().required('Please tick checkbox.'),
        dateOfLastKnownOpticiansAppointment: Yup.date().when('dateOfLastKnownOpticiansAppointmentNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        datesOfKnownFallsNa: Yup.boolean().required('Please tick checkbox.'),
        datesOfKnownFalls: Yup.date().when('datesOfKnownFallsNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        isEndOfLifePlanningBeingConsideredInPlace: Yup.string().required(),
        doYouHaveAnAnnualHealthCheck: Yup.boolean().required('Please select "Yes" or "No".'),
        doYouHaveAhealthActionPlanPassport: Yup.boolean().required('Please select "Yes" or "No".'),
        isAnAnticipatoryCareCalendarRequired: Yup.boolean().required('Please select "Yes" or "No".'),
        doYouRequireAnySpecialistEquipment: Yup.boolean().required('Please select "Yes" or "No".'),
        doYouRequireAnySpecialistEquipmentComment: Yup.string().when('doYouRequireAnySpecialistEquipment', {
            is: true,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        areYouIndependentWithThis4: Yup.string().required(),
        yourFamilyHelpWithThis4: Yup.string().required(),
        anotherHelpsWithThis4: Yup.string().required(),
        wouldYouLikeToSupportYouToStayHealthyAndWell: Yup.string().required(),
        whoAndWhatIsImportantToYou: Yup.string().required(),
        whatHobbiesDoYouHave: Yup.string().required(),
        whatDoYouGetTheMostEnjoymentOutOf: Yup.string().required(),
        canYouTravelIndependently: Yup.string().required(),
        howDoYouStayInTouchWithFamilyAndFriends: Yup.string().required(),
        areThereAnyRisksWhenUndertakingTheseActivities3: Yup.string().required(),
        areYouIndependentWithThis5: Yup.string().required(),
        yourFamilyHelpWithThis5: Yup.string().required(),
        anotherHelpsWithThis5: Yup.string().required(),
        wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing: Yup.string().required(),
        areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing: Yup.string().required(),
        doYouGetWorriedOrAnxious: Yup.string().required(),
        whatCanTriggerFeelingsOfAnxiety: Yup.string().required(),
        whatHelpsYouToFeelBetter: Yup.string().required(),
        isThereAnythingYouWishUsToBeMadeAwareOf: Yup.string().required(),
        areThereAnyRisksWhenUndertakingTheseActivities4: Yup.string().required(),
        areYouIndependentWithThis6: Yup.string().required(),
        yourFamilyHelpWithThis6: Yup.string().required(),
        anotherHelpsWithThis6: Yup.string().required(),
        wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing: Yup.string().required(),
        detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor: Yup.string().required(),
        howIsYourMedicationCurrentlyManaged: Yup.string().required(),
        whatLevelOfSupportDoYouNeedWithYourMedication: Yup.string().required(),
        areThereAnyRisksWhenUndertakingTheseActivities5: Yup.string().required(),
        iamIndependentWithThis: Yup.string().required(),
        yourFamilyHelpWithThis7: Yup.string().required(),
        anotherHelpsWithThis7: Yup.string().required(),
        wouldYouLikeToSupportYouToManageAndTakeYourMedication: Yup.string().required(),
        whereWouldYouPreferToLive: Yup.string().required(),
        doYouNeedSupportWithYourBenefits: Yup.string().required(),
        doYouNeedAnySupportWithManagingYourTenancyAgreement: Yup.string().required(),
        whatSupportDoYouNeedForYourFinances: Yup.string().required(),
        areYouAbleToAccessYourBankingWithOrWithoutSupport: Yup.string().required(),
        areThereAnyRisksWhenUndertakingTheseActivities6: Yup.string().required(),
        youAreIndependentWithThis: Yup.string().required(),
        yourFamilyHelpWithThis8: Yup.string().required(),
        anotherHelpsWithThis8: Yup.string().required(),
        supportYouToDevelopSkillsToManageYourHousingAndFinances: Yup.string().required()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const watchAllFields = watch();

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsStrengthAssessmentView(props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData);

            setValue('asbaId', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.asbaId ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.asbaId : '', {
                shouldValidate: true
            });
            setValue(
                'isEnglishYourFirstLanguage',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isEnglishYourFirstLanguage
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isEnglishYourFirstLanguage
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouUseGestures',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouUseGestures ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouUseGestures : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouUseAnyAssistiveTechnology',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouUseAnyAssistiveTechnology
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouUseAnyAssistiveTechnology
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'howWellDoYouUnderstandVerbal',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howWellDoYouUnderstandVerbal
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howWellDoYouUnderstandVerbal
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'howWellDoYouUnderstandWrittenInformation',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howWellDoYouUnderstandWrittenInformation
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howWellDoYouUnderstandWrittenInformation
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatIsImportantForUsToKnowToSupportYouWithYourCommunication',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatIsImportantForUsToKnowToSupportYouWithYourCommunication
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatIsImportantForUsToKnowToSupportYouWithYourCommunication
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyBehavioursOfConcernInRelation',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyBehavioursOfConcernInRelation
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyBehavioursOfConcernInRelation
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'doesThisPersonRequireInputFromThePbsTeam',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doesThisPersonRequireInputFromThePbsTeam
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doesThisPersonRequireInputFromThePbsTeam
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'howManyHoursPerWeek',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howManyHoursPerWeek ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howManyHoursPerWeek : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouIndependentWithThis1',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis1
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis1
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis1',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis1 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis1 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis1',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis1 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis1 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouWithYourCommunication',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourCommunication
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourCommunication
                    : '',
                { shouldValidate: true }
            );
            setValue('cooking', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.cooking ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.cooking : '', {
                shouldValidate: true
            });
            setValue('shopping', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.shopping ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.shopping : '', {
                shouldValidate: true
            });
            setValue('cleaning', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.cleaning ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.cleaning : '', {
                shouldValidate: true
            });
            setValue('laundry', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.laundry ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.laundry : '', {
                shouldValidate: true
            });
            setValue('tidying', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.tidying ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.tidying : '', {
                shouldValidate: true
            });
            setValue('oralCare', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.oralCare ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.oralCare : '', {
                shouldValidate: true
            });
            setValue(
                'knowingTimeOfDayNight',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.knowingTimeOfDayNight ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.knowingTimeOfDayNight : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouHaveAnyGoalsYouWishToAchieveInThisArea',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAnyGoalsYouWishToAchieveInThisArea
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAnyGoalsYouWishToAchieveInThisArea
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyRisksWhenUndertakingTheseActivities1',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities1
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities1
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouIndependentWithThis2',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis2
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis2
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis2',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis2 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis2 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis2',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis2 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis2 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'mobilityAndEquipmentRequired',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.mobilityAndEquipmentRequired
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.mobilityAndEquipmentRequired
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'dressingUndressing',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dressingUndressing ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dressingUndressing : '',
                { shouldValidate: true }
            );
            setValue(
                'washingBathing',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.washingBathing ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.washingBathing : '',
                { shouldValidate: true }
            );
            setValue(
                'nutritionalCare',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.nutritionalCare ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.nutritionalCare : '',
                { shouldValidate: true }
            );
            setValue(
                'continenceCare',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.continenceCare ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.continenceCare : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyRisksWhenUndertakingTheseActivities2',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities2
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities2
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouIndependentWithThis3',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis3
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis3
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis3',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis3 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis3 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis3',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis3 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis3 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouWithYourPersonalCare',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourPersonalCare
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourPersonalCare
                    : '',
                { shouldValidate: true }
            );
            setValue('dateOfLastKnownGpVisitNa', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisitNa === 1 ? true : false, { shouldValidate: true });
            setValue(
                'dateOfLastKnownGpVisit',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisit
                    ? window.$utils.dateFormat(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisit)
                    : null,
                { shouldValidate: true }
            );
            let dateOfLastKnownGpVisitTemp = moment(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisit, 'DD-MM-YYYY');
            setdateOfLastKnownGpVisit(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisit ? dayjs(new Date(dateOfLastKnownGpVisitTemp.toDate())) : null);

            setValue('dateOfLastKnownDentistAppointmentNa', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointmentNa === 1 ? true : false, {
                shouldValidate: true
            });

            setValue(
                'dateOfLastKnownDentistAppointment',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointment
                    ? window.$utils.dateFormat(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointment)
                    : null,
                { shouldValidate: true }
            );
            let dateOfLastKnownDentistAppointmentTemp = moment(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointment, 'DD-MM-YYYY');
            setdateOfLastKnownDentistAppointment(
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointment ? dayjs(new Date(dateOfLastKnownDentistAppointmentTemp.toDate())) : null
            );

            setValue('dateOfLastKnownChiropodyAppointmentNa', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointmentNa === 1 ? true : false, {
                shouldValidate: true
            });
            setValue(
                'dateOfLastKnownChiropodyAppointment',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointment
                    ? window.$utils.dateFormat(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointment)
                    : null,
                { shouldValidate: true }
            );
            let dateOfLastKnownChiropodyAppointmentTemp = moment(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointment, 'DD-MM-YYYY');
            setdateOfLastKnownChiropodyAppointment(
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointment ? dayjs(new Date(dateOfLastKnownChiropodyAppointmentTemp.toDate())) : null
            );

            setValue('dateOfLastKnownOpticiansAppointmentNa', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointmentNa === 1 ? true : false, {
                shouldValidate: true
            });
            setValue(
                'dateOfLastKnownOpticiansAppointment',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointment
                    ? window.$utils.dateFormat(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointment)
                    : null,
                { shouldValidate: true }
            );
            let dateOfLastKnownOpticiansAppointmentTemp = moment(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointment, 'DD-MM-YYYY');
            setdateOfLastKnownOpticiansAppointment(
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointment ? dayjs(new Date(dateOfLastKnownOpticiansAppointmentTemp.toDate())) : null
            );

            setValue('datesOfKnownFallsNa', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFallsNa === 1 ? true : false, { shouldValidate: true });

            setValue(
                'datesOfKnownFalls',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFalls
                    ? window.$utils.dateFormat(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFalls)
                    : null,
                { shouldValidate: true }
            );
            let datesOfKnownFallsTemp = moment(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFalls, 'DD-MM-YYYY');
            setdatesOfKnownFalls(assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFalls ? dayjs(new Date(datesOfKnownFallsTemp.toDate())) : null);

            
            setValue(
                'isEndOfLifePlanningBeingConsideredInPlace',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isEndOfLifePlanningBeingConsideredInPlace
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isEndOfLifePlanningBeingConsideredInPlace
                    : '',
                { shouldValidate: true }
            );
            setValue('doYouHaveAnAnnualHealthCheck', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAnAnnualHealthCheck === 1 ? '1' : '0', { shouldValidate: true });
            setValue('doYouHaveAhealthActionPlanPassport', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAhealthActionPlanPassport === 1 ? '1' : '0', {
                shouldValidate: true
            });
            setValue('isAnAnticipatoryCareCalendarRequired', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isAnAnticipatoryCareCalendarRequired === 1 ? '1' : '0', {
                shouldValidate: true
            });
            setValue('doYouRequireAnySpecialistEquipment', assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouRequireAnySpecialistEquipment === 1 ? '1' : '0', {
                shouldValidate: true
            });
            setValue(
                'doYouRequireAnySpecialistEquipmentComment',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouRequireAnySpecialistEquipmentComment
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouRequireAnySpecialistEquipmentComment
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouIndependentWithThis4',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis4
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis4
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis4',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis4 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis4 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis4',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis4 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis4 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouToStayHealthyAndWell',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouToStayHealthyAndWell
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouToStayHealthyAndWell
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whoAndWhatIsImportantToYou',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whoAndWhatIsImportantToYou
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whoAndWhatIsImportantToYou
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatHobbiesDoYouHave',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatHobbiesDoYouHave ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatHobbiesDoYouHave : '',
                { shouldValidate: true }
            );
            setValue(
                'whatDoYouGetTheMostEnjoymentOutOf',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatDoYouGetTheMostEnjoymentOutOf
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatDoYouGetTheMostEnjoymentOutOf
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'canYouTravelIndependently',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.canYouTravelIndependently
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.canYouTravelIndependently
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'howDoYouStayInTouchWithFamilyAndFriends',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howDoYouStayInTouchWithFamilyAndFriends
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howDoYouStayInTouchWithFamilyAndFriends
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyRisksWhenUndertakingTheseActivities3',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities3
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities3
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouIndependentWithThis5',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis5
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis5
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis5',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis5 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis5 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis5',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis5 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis5 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouGetWorriedOrAnxious',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouGetWorriedOrAnxious
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouGetWorriedOrAnxious
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatCanTriggerFeelingsOfAnxiety',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatCanTriggerFeelingsOfAnxiety
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatCanTriggerFeelingsOfAnxiety
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatHelpsYouToFeelBetter',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatHelpsYouToFeelBetter
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatHelpsYouToFeelBetter
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'isThereAnythingYouWishUsToBeMadeAwareOf',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isThereAnythingYouWishUsToBeMadeAwareOf
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isThereAnythingYouWishUsToBeMadeAwareOf
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyRisksWhenUndertakingTheseActivities4',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities4
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities4
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouIndependentWithThis6',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis6
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis6
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis6',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis6 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis6 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis6',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis6 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis6 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'howIsYourMedicationCurrentlyManaged',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howIsYourMedicationCurrentlyManaged
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howIsYourMedicationCurrentlyManaged
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatLevelOfSupportDoYouNeedWithYourMedication',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatLevelOfSupportDoYouNeedWithYourMedication
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatLevelOfSupportDoYouNeedWithYourMedication
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyRisksWhenUndertakingTheseActivities5',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities5
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities5
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'iamIndependentWithThis',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.iamIndependentWithThis ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.iamIndependentWithThis : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis7',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis7 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis7 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis7',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis7 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis7 : '',
                { shouldValidate: true }
            );
            setValue(
                'wouldYouLikeToSupportYouToManageAndTakeYourMedication',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouToManageAndTakeYourMedication
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouToManageAndTakeYourMedication
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whereWouldYouPreferToLive',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whereWouldYouPreferToLive
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whereWouldYouPreferToLive
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouNeedSupportWithYourBenefits',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouNeedSupportWithYourBenefits
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouNeedSupportWithYourBenefits
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouNeedAnySupportWithManagingYourTenancyAgreement',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouNeedAnySupportWithManagingYourTenancyAgreement
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouNeedAnySupportWithManagingYourTenancyAgreement
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatSupportDoYouNeedForYourFinances',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatSupportDoYouNeedForYourFinances
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatSupportDoYouNeedForYourFinances
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areYouAbleToAccessYourBankingWithOrWithoutSupport',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouAbleToAccessYourBankingWithOrWithoutSupport
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouAbleToAccessYourBankingWithOrWithoutSupport
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyRisksWhenUndertakingTheseActivities6',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities6
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities6
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'youAreIndependentWithThis',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.youAreIndependentWithThis
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.youAreIndependentWithThis
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'yourFamilyHelpWithThis8',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis8 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis8 : '',
                { shouldValidate: true }
            );
            setValue(
                'anotherHelpsWithThis8',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis8 ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis8 : '',
                { shouldValidate: true }
            );
            setValue(
                'supportYouToDevelopSkillsToManageYourHousingAndFinances',
                assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.supportYouToDevelopSkillsToManageYourHousingAndFinances
                    ? assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.supportYouToDevelopSkillsToManageYourHousingAndFinances
                    : '',
                { shouldValidate: true }
            );
        } else {
            setValue('asbaId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        props.strengthAssessmentFormValid(formState.isValid);
        props.strengthAssessmentDataChange(watchAllFields);
        // if (formState.isValid) {
        //     props.strengthAssessmentDataChange(watchAllFields)
        // }
    }, [formState]);

    return (
        <div id="strength-assessment">
            <div className="modal-title-header mb-2 mt-3">
                <h3 className="d-flex align-items-center">
                    <img src={Strength} alt="" width={26} className="me-2" />
                    {t('Strengths_Assessment.Strengths_Assessment')}
                </h3>
            </div>
            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">1.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.How_Communicate')} - </span>
                    <span className="purple-text font-light">
                        <i>{t('Strengths_Assessment.How_Communicate_span')}</i>
                    </span>
                    <input
                        type="hidden"
                        {...register('asbaId', {
                            // onChange: (e: any) => {
                            //     props.updateData(e);
                            // }
                        })}
                        name={'asbaId' as const}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.English_language')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('isEnglishYourFirstLanguage', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'isEnglishYourFirstLanguage' as const}
                            className={`form-control white ${errors.isEnglishYourFirstLanguage ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.isEnglishYourFirstLanguage}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.sign_language')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('doYouUseGestures', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouUseGestures' as const}
                            className={`form-control white ${errors.doYouUseGestures ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doYouUseGestures}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.assistive_technology')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('doYouUseAnyAssistiveTechnology', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouUseAnyAssistiveTechnology' as const}
                            className={`form-control white ${errors.doYouUseAnyAssistiveTechnology ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doYouUseAnyAssistiveTechnology}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.How_communication')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('howWellDoYouUnderstandVerbal', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'howWellDoYouUnderstandVerbal' as const}
                            className={`form-control white ${errors.howWellDoYouUnderstandVerbal ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.howWellDoYouUnderstandVerbal}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.How_information')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('howWellDoYouUnderstandWrittenInformation', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'howWellDoYouUnderstandWrittenInformation' as const}
                            className={`form-control white ${errors.howWellDoYouUnderstandWrittenInformation ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.howWellDoYouUnderstandWrittenInformation}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.What_important_communication')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('whatIsImportantForUsToKnowToSupportYouWithYourCommunication', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatIsImportantForUsToKnowToSupportYouWithYourCommunication' as const}
                            className={`form-control white ${errors.whatIsImportantForUsToKnowToSupportYouWithYourCommunication ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                        <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatIsImportantForUsToKnowToSupportYouWithYourCommunication}</p>
                    )}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.behaviours_concern')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areThereAnyBehavioursOfConcernInRelation', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyBehavioursOfConcernInRelation' as const}
                            className={`form-control white ${errors.areThereAnyBehavioursOfConcernInRelation ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyBehavioursOfConcernInRelation}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.PBS_team')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('doesThisPersonRequireInputFromThePbsTeam', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doesThisPersonRequireInputFromThePbsTeam' as const}
                            className={`form-control white ${errors.doesThisPersonRequireInputFromThePbsTeam ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doesThisPersonRequireInputFromThePbsTeam}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.hours_week')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('howManyHoursPerWeek', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'howManyHoursPerWeek' as const}
                            className={`form-control white ${errors.howManyHoursPerWeek ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.howManyHoursPerWeek}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areYouIndependentWithThis1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouIndependentWithThis1' as const}
                            className={`form-control white ${errors.areYouIndependentWithThis1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouIndependentWithThis1}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis1' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis1' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Valorum_Care_Communication', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouWithYourCommunication', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouWithYourCommunication' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouWithYourCommunication ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouWithYourCommunication}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">2.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.support_skills')} - </span>
                    <span className="purple-text font-light">
                        <i>{t('Strengths_Assessment.knowing_day_night')}</i>
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Cooking')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('cooking', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'cooking' as const}
                            className={`form-control white ${errors.cooking ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.cooking}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Shopping')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('shopping', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'shopping' as const}
                            className={`form-control white ${errors.shopping ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.shopping}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Cleaning')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('cleaning', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'cleaning' as const}
                            className={`form-control white ${errors.cleaning ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.cleaning}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Laundry')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('laundry', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'laundry' as const}
                            className={`form-control white ${errors.laundry ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.laundry}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Tidying')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('tidying', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'tidying' as const}
                            className={`form-control white ${errors.tidying ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.tidying}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Knowing_time')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('knowingTimeOfDayNight', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'knowingTimeOfDayNight' as const}
                            className={`form-control white ${errors.knowingTimeOfDayNight ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.knowingTimeOfDayNight}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.goals_area')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('doYouHaveAnyGoalsYouWishToAchieveInThisArea', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouHaveAnyGoalsYouWishToAchieveInThisArea' as const}
                            className={`form-control white ${errors.doYouHaveAnyGoalsYouWishToAchieveInThisArea ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doYouHaveAnyGoalsYouWishToAchieveInThisArea}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.undertaking_activities')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyRisksWhenUndertakingTheseActivities1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyRisksWhenUndertakingTheseActivities1' as const}
                            className={`form-control white ${errors.areThereAnyRisksWhenUndertakingTheseActivities1 ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyRisksWhenUndertakingTheseActivities1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areYouIndependentWithThis2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouIndependentWithThis2' as const}
                            className={`form-control white ${errors.areYouIndependentWithThis2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouIndependentWithThis2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis2' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis2' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.support_daily_activities', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                        <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities}</p>
                    )}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">3.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.support_personal_care')} - </span>
                    <span className="purple-text font-light">
                        <i>{t('Strengths_Assessment.support_personal_care_Span')}</i>
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Mobility_Equipment')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('mobilityAndEquipmentRequired', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'mobilityAndEquipmentRequired' as const}
                            className={`form-control white ${errors.mobilityAndEquipmentRequired ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.mobilityAndEquipmentRequired}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Dressing_undressing')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('dressingUndressing', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'dressingUndressing' as const}
                            className={`form-control white ${errors.dressingUndressing ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.dressingUndressing}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Washing/Bathing')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('washingBathing', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'washingBathing' as const}
                            className={`form-control white ${errors.washingBathing ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.washingBathing}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Oral_Care')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('oralCare', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'oralCare' as const}
                            className={`form-control white ${errors.oralCare ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.oralCare}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Nutritional_Care')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('nutritionalCare', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'nutritionalCare' as const}
                            className={`form-control white ${errors.nutritionalCare ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.nutritionalCare}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Continence_Care')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('continenceCare', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'continenceCare' as const}
                            className={`form-control white ${errors.continenceCare ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.continenceCare}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.risk_assessment_required')}</label>
                    <br />
                    <span className="font-light mb-2 d-block">
                        <i>{t('Strengths_Assessment.risk_assessment_required_Span')}</i>
                    </span>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyRisksWhenUndertakingTheseActivities2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyRisksWhenUndertakingTheseActivities2' as const}
                            className={`form-control white ${errors.areThereAnyRisksWhenUndertakingTheseActivities2 ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyRisksWhenUndertakingTheseActivities2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areYouIndependentWithThis3', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouIndependentWithThis3' as const}
                            className={`form-control white ${errors.areYouIndependentWithThis3 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouIndependentWithThis3}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis3', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis3' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis3 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis3}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis3', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis3' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis3 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis3}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Would_Personal_care', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouWithYourPersonalCare', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouWithYourPersonalCare' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouWithYourPersonalCare ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouWithYourPersonalCare}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">4.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.healthy_well')} - </span>
                    <span className="purple-text font-light">{/* <i>{t('Strengths_Assessment.healthy_well_Span')}</i> */}</span>
                </div>
            </div>
            <p className="mt-3 mb-2 bold">{t('Strengths_Assessment.Add/delete')}</p>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <div className="d-flex align-items-center">
                        <label className="mb-2">{t('Strengths_Assessment.Date_last_GP')}</label>
                        <label className="mb-2 ms-2">
                            <span className="d-flex align-items-center justify-content-center bold">
                                <input
                                    type="checkbox"
                                    {...register(`dateOfLastKnownGpVisitNa`, {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={`dateOfLastKnownGpVisitNa` as const}
                                    defaultChecked={true}
                                    className="me-1"
                                />
                                NA
                            </span>
                        </label>
                    </div>
                    <div className="input-group date datepicker-input-css" id="datepicker">
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <Controller
                                control={control}
                                name="dateOfLastKnownGpVisit"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control white ${error && getValues("dateOfLastKnownGpVisitNa") == 0 ? 'is-invalid' : ''}`}
                                            disabled={(getValues("dateOfLastKnownGpVisitNa") == 1) ? true : false}
                                            value={dateOfLastKnownGpVisit}
                                            format="DD/MM/YYYY"
                                            {...fieldProps}
                                            onChange={(date: any) => {
                                                fieldProps.onChange(date);
                                                // props.getDate(date);
                                            }}
                                            views={['year', 'month', 'day']}
                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                        />
                                    );
                                }}
                            />
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.dateOfLastKnownGpVisit !== null ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsStrengthAssessmentView.dateOfLastKnownGpVisit)) : ''}</p>
                        )}
                    </div>
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <div className="d-flex align-items-center">
                        <label className="mb-2">{t('Strengths_Assessment.Date_last_Dentist')}</label>
                        <label className="mb-1 ms-2">
                            <span className="d-flex align-items-center justify-content-center bold">
                                <input
                                    type="checkbox"
                                    {...register(`dateOfLastKnownDentistAppointmentNa`, {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={`dateOfLastKnownDentistAppointmentNa` as const}
                                    defaultChecked={true}
                                    className="me-1"
                                />
                                NA
                            </span>
                        </label>
                    </div>
                    <div className="input-group date datepicker-input-css" id="datepicker">
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <Controller
                                control={control}
                                name="dateOfLastKnownDentistAppointment"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control white ${error && getValues("dateOfLastKnownDentistAppointmentNa") == 0 ? 'is-invalid' : ''}`}
                                            disabled={(getValues("dateOfLastKnownDentistAppointmentNa") == 1) ? true : false}
                                            value={dateOfLastKnownDentistAppointment}
                                            format="DD/MM/YYYY"
                                            {...fieldProps}
                                            onChange={(date: any) => {
                                                fieldProps.onChange(date);
                                                // props.getDate(date);
                                            }}
                                            views={['year', 'month', 'day']}
                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                        />
                                    );
                                }}
                            />
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">{ assessmentDetailsStrengthAssessmentView.dateOfLastKnownDentistAppointment !== null ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsStrengthAssessmentView.dateOfLastKnownDentistAppointment)) : ''}</p>
                        )}
                    </div>
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <div className="d-flex align-items-center">
                        <label className="mb-2">{t('Strengths_Assessment.Date_last_Chiropody')}</label>
                        <label className="mb-1 ms-2">
                            <span className="d-flex align-items-center justify-content-center bold">
                                <input
                                    type="checkbox"
                                    {...register(`dateOfLastKnownChiropodyAppointmentNa`, {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={`dateOfLastKnownChiropodyAppointmentNa` as const}
                                    defaultChecked={true}
                                    className="me-1"
                                />
                                NA
                            </span>
                        </label>
                    </div>
                    <div className="input-group date datepicker-input-css" id="datepicker">
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <Controller
                                control={control}
                                name="dateOfLastKnownChiropodyAppointment"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control white ${error && getValues("dateOfLastKnownChiropodyAppointmentNa") == 0 ? 'is-invalid' : ''}`}
                                            disabled={(getValues("dateOfLastKnownChiropodyAppointmentNa") == 1) ? true : false}
                                            value={dateOfLastKnownChiropodyAppointment}
                                            format="DD/MM/YYYY"
                                            {...fieldProps}
                                            onChange={(date: any) => {
                                                fieldProps.onChange(date);
                                                // props.getDate(date);
                                            }}
                                            views={['year', 'month', 'day']}
                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                        />
                                    );
                                }}
                            />
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">{ assessmentDetailsStrengthAssessmentView.dateOfLastKnownChiropodyAppointment !== null ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsStrengthAssessmentView.dateOfLastKnownChiropodyAppointment)) : ''}</p>
                        )}
                    </div>
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <div className="d-flex align-items-center">
                        <label className="mb-2">{t('Strengths_Assessment.Date_last_Opticians')}</label>
                        <label className="mb-1 ms-2">
                            <span className="d-flex bold align-items-center justify-content-center">
                                <input
                                    type="checkbox"
                                    {...register(`dateOfLastKnownOpticiansAppointmentNa`, {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={`dateOfLastKnownOpticiansAppointmentNa` as const}
                                    defaultChecked={true}
                                    className="me-1"
                                />
                                NA
                            </span>
                        </label>
                    </div>
                    <div className="input-group date datepicker-input-css" id="datepicker">
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <Controller
                                control={control}
                                name="dateOfLastKnownOpticiansAppointment"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control white ${error && getValues("dateOfLastKnownOpticiansAppointmentNa") == 0 ? 'is-invalid' : ''}`}
                                            disabled={(getValues("dateOfLastKnownOpticiansAppointmentNa") == 1) ? true : false}
                                            value={dateOfLastKnownOpticiansAppointment}
                                            format="DD/MM/YYYY"
                                            {...fieldProps}
                                            onChange={(date: any) => {
                                                fieldProps.onChange(date);
                                                // props.getDate(date);
                                            }}
                                            views={['year', 'month', 'day']}
                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                        />
                                    );
                                }}
                            />
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.dateOfLastKnownOpticiansAppointment !== null ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsStrengthAssessmentView.dateOfLastKnownOpticiansAppointment)) : ''}</p>
                        )}
                    </div>
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <div className="d-flex align-items-center">
                        <label className="mb-2">{t('Strengths_Assessment.Dates_falls')}</label>
                        <label className="mb-1 ms-2">
                            <span className="d-flex bold align-items-center justify-content-center">
                                <input
                                    type="checkbox"
                                    {...register(`datesOfKnownFallsNa`, {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={`datesOfKnownFallsNa` as const}
                                    defaultChecked={true}
                                    className="me-1"
                                />
                                NA
                            </span>
                        </label>
                    </div>
                    <div className="input-group date datepicker-input-css" id="datepicker">
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <Controller
                                control={control}
                                name="datesOfKnownFalls"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control white ${error && getValues("datesOfKnownFallsNa") == 0 ? 'is-invalid' : ''}`}
                                            disabled={(getValues("datesOfKnownFallsNa") == 1) ? true : false}
                                            value={datesOfKnownFalls}
                                            format="DD/MM/YYYY"
                                            {...fieldProps}
                                            onChange={(date: any) => {
                                                fieldProps.onChange(date);
                                                // props.getDate(date);
                                            }}
                                            views={['year', 'month', 'day']}
                                            slotProps={{actionBar: { actions: ['clear','today'] },}}
                                        />
                                    );
                                }}
                            />
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.datesOfKnownFalls !== null ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsStrengthAssessmentView.datesOfKnownFalls)) : ''}</p>
                        )}
                    </div>
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.considered/in_place')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('isEndOfLifePlanningBeingConsideredInPlace', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'isEndOfLifePlanningBeingConsideredInPlace' as const}
                            className={`form-control white ${errors.isEndOfLifePlanningBeingConsideredInPlace ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.isEndOfLifePlanningBeingConsideredInPlace}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.risk_assessment')}</label>
                    <br />
                    <div className="d-flex mt-2">
                        <label>{t('Strengths_Assessment.annual_health_check')}</label>
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <>
                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('doYouHaveAnAnnualHealthCheck', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'doYouHaveAnAnnualHealthCheck' as const}
                                        value={1}
                                        defaultChecked={true}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('doYouHaveAnAnnualHealthCheck', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'doYouHaveAnAnnualHealthCheck' as const}
                                        value={0}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label>
                            </>
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 ms-2">{assessmentDetailsStrengthAssessmentView.doYouHaveAnAnnualHealthCheck === 1 ? 'Yes' : 'No'}</p>}
                    </div>
                    <div className="d-flex mt-2">
                        <label>{t('Strengths_Assessment.plan/passport')}</label>
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <>
                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('doYouHaveAhealthActionPlanPassport', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'doYouHaveAhealthActionPlanPassport' as const}
                                        value={1}
                                        defaultChecked={true}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('doYouHaveAhealthActionPlanPassport', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'doYouHaveAhealthActionPlanPassport' as const}
                                        value={0}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label>
                            </>
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2 ms-2">{assessmentDetailsStrengthAssessmentView.doYouHaveAhealthActionPlanPassport === 1 ? 'Yes' : 'No'}</p>
                        )}
                    </div>
                    <div className="d-flex mt-2">
                        <label>{t('Strengths_Assessment.Anticipatory_care')}</label>
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <>
                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('isAnAnticipatoryCareCalendarRequired', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'isAnAnticipatoryCareCalendarRequired' as const}
                                        value={1}
                                        defaultChecked={true}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('isAnAnticipatoryCareCalendarRequired', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'isAnAnticipatoryCareCalendarRequired' as const}
                                        value={0}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label>
                            </>
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2 ms-2">{assessmentDetailsStrengthAssessmentView.isAnAnticipatoryCareCalendarRequired === 1 ? 'Yes' : 'No'}</p>
                        )}
                    </div>
                    <div className="d-flex mt-2">
                        <label>{t('Strengths_Assessment.specialist_equipment')}</label>
                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                            <>
                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input
                                        type="radio"
                                        {...register('doYouRequireAnySpecialistEquipment', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'doYouRequireAnySpecialistEquipment' as const}
                                        value={1}
                                        defaultChecked={true}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input
                                        type="radio"
                                        {...register('doYouRequireAnySpecialistEquipment', {
                                            onChange: (e: any) => {
                                                // props.updateData(e);
                                            }
                                        })}
                                        name={'doYouRequireAnySpecialistEquipment' as const}
                                        value={0}
                                        // onChange={props.updateData}
                                    />
                                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                                </label>
                            </>
                        )}
                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                            <p className="bold mb-2 ms-2">{assessmentDetailsStrengthAssessmentView.doYouRequireAnySpecialistEquipment === 1 ? 'Yes' : 'No'}</p>
                        )}

                        <p className="ms-2">{t('Strengths_Assessment.Specialist_vehicle')}</p>
                    </div>

                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('doYouRequireAnySpecialistEquipmentComment', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouRequireAnySpecialistEquipmentComment' as const}
                            className={`form-control mt-2 white ${errors.doYouRequireAnySpecialistEquipmentComment ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 ms-2">{assessmentDetailsStrengthAssessmentView.doYouRequireAnySpecialistEquipmentComment}</p>}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areYouIndependentWithThis4', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouIndependentWithThis4' as const}
                            className={`form-control white ${errors.areYouIndependentWithThis4 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouIndependentWithThis4}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis4', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis4' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis4 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis4}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis4', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis4' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis4 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis4}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Valorum_Care_healthy', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouToStayHealthyAndWell', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouToStayHealthyAndWell' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouToStayHealthyAndWell ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouToStayHealthyAndWell}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">5.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.How_social_wellbeing')} - </span>
                    <span className="purple-text font-light">
                        <i>{t('Strengths_Assessment.How_social_wellbeing_Span')}</i>
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.important_you')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whoAndWhatIsImportantToYou', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whoAndWhatIsImportantToYou' as const}
                            className={`form-control white ${errors.whoAndWhatIsImportantToYou ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whoAndWhatIsImportantToYou}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.hobbies_have')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatHobbiesDoYouHave', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatHobbiesDoYouHave' as const}
                            className={`form-control white ${errors.whatHobbiesDoYouHave ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatHobbiesDoYouHave}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.enjoyment')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatDoYouGetTheMostEnjoymentOutOf', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatDoYouGetTheMostEnjoymentOutOf' as const}
                            className={`form-control white ${errors.whatDoYouGetTheMostEnjoymentOutOf ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatDoYouGetTheMostEnjoymentOutOf}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.travel_independently')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('canYouTravelIndependently', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'canYouTravelIndependently' as const}
                            className={`form-control white ${errors.canYouTravelIndependently ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.canYouTravelIndependently}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_friends')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('howDoYouStayInTouchWithFamilyAndFriends', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'howDoYouStayInTouchWithFamilyAndFriends' as const}
                            className={`form-control white ${errors.howDoYouStayInTouchWithFamilyAndFriends ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.howDoYouStayInTouchWithFamilyAndFriends}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.risk_assessment_require')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyRisksWhenUndertakingTheseActivities3', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyRisksWhenUndertakingTheseActivities3' as const}
                            className={`form-control white ${errors.areThereAnyRisksWhenUndertakingTheseActivities3 ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyRisksWhenUndertakingTheseActivities3}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areYouIndependentWithThis5', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouIndependentWithThis5' as const}
                            className={`form-control white ${errors.areYouIndependentWithThis5 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouIndependentWithThis5}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis5', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis5' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis5 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis5}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis5', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis5' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis5 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis5}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Occupational_Social_Wellbeing', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                        <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing}</p>
                    )}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">6.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.emotional_mental_wellbeing')} - </span>
                    <span className="purple-text font-light">
                        <i>{t('Strengths_Assessment.feelings_anxiety')}</i>
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2 text-break">{t('Strengths_Assessment.mental_wellbeing')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing' as const}
                            className={`form-control white ${errors.areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                        <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing}</p>
                    )}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2 text-break">{t('Strengths_Assessment.anxious')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('doYouGetWorriedOrAnxious', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouGetWorriedOrAnxious' as const}
                            className={`form-control white ${errors.doYouGetWorriedOrAnxious ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doYouGetWorriedOrAnxious}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2 text-break">{t('Strengths_Assessment.loneliness')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatCanTriggerFeelingsOfAnxiety', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatCanTriggerFeelingsOfAnxiety' as const}
                            className={`form-control white ${errors.whatCanTriggerFeelingsOfAnxiety ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatCanTriggerFeelingsOfAnxiety}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2 text-break">{t('Strengths_Assessment.feel_better')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatHelpsYouToFeelBetter', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatHelpsYouToFeelBetter' as const}
                            className={`form-control white ${errors.whatHelpsYouToFeelBetter ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatHelpsYouToFeelBetter}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2 text-break">{t('Strengths_Assessment.wish_aware')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('isThereAnythingYouWishUsToBeMadeAwareOf', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'isThereAnythingYouWishUsToBeMadeAwareOf' as const}
                            className={`form-control white ${errors.isThereAnythingYouWishUsToBeMadeAwareOf ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.isThereAnythingYouWishUsToBeMadeAwareOf}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.activitie_assessment')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyRisksWhenUndertakingTheseActivities4', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyRisksWhenUndertakingTheseActivities4' as const}
                            className={`form-control white ${errors.areThereAnyRisksWhenUndertakingTheseActivities4 ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyRisksWhenUndertakingTheseActivities4}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areYouIndependentWithThis6', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouIndependentWithThis6' as const}
                            className={`form-control white ${errors.areYouIndependentWithThis6 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouIndependentWithThis6}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis6', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis6' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis6 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis6}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis6', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis6' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis6 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis6}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Valorum_Care_wellbeing', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                        <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing}</p>
                    )}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">7.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.medication')} - </span>
                    <span className="purple-text font-light">
                        <i> {t('Strengths_Assessment.medication_Span')}</i>
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Details')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor' as const}
                            className={`form-control white ${errors.detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                        <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor}</p>
                    )}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">
                        {t('Strengths_Assessment.medication_managed')} <br /> {t('Strengths_Assessment.blister_packed')}
                    </label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('howIsYourMedicationCurrentlyManaged', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'howIsYourMedicationCurrentlyManaged' as const}
                            className={`form-control white ${errors.howIsYourMedicationCurrentlyManaged ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.howIsYourMedicationCurrentlyManaged}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.support')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatLevelOfSupportDoYouNeedWithYourMedication', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatLevelOfSupportDoYouNeedWithYourMedication' as const}
                            className={`form-control white ${errors.whatLevelOfSupportDoYouNeedWithYourMedication ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatLevelOfSupportDoYouNeedWithYourMedication}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.activities_risk_assessment')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyRisksWhenUndertakingTheseActivities5', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyRisksWhenUndertakingTheseActivities5' as const}
                            className={`form-control white ${errors.areThereAnyRisksWhenUndertakingTheseActivities5 ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyRisksWhenUndertakingTheseActivities5}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.I_independent')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('iamIndependentWithThis', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'iamIndependentWithThis' as const}
                            className={`form-control white ${errors.iamIndependentWithThis ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.iamIndependentWithThis}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis7', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis7' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis7 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis7}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Another_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis7', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis7' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis7 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis7}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Valorum_Care_medication', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('wouldYouLikeToSupportYouToManageAndTakeYourMedication', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'wouldYouLikeToSupportYouToManageAndTakeYourMedication' as const}
                            className={`form-control white ${errors.wouldYouLikeToSupportYouToManageAndTakeYourMedication ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.wouldYouLikeToSupportYouToManageAndTakeYourMedication}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="d-flex mt-2">
                <span className="purple-text bold me-2">8.</span>
                <div>
                    <span className="purple-text bold">{t('Strengths_Assessment.housing_finances')} – </span>
                    <span className="purple-text font-light">
                        <i> {t('Strengths_Assessment.housing_finances_Span')}</i>
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.prefer_live')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whereWouldYouPreferToLive', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whereWouldYouPreferToLive' as const}
                            className={`form-control white ${errors.whereWouldYouPreferToLive ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whereWouldYouPreferToLive}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.benefits')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('doYouNeedSupportWithYourBenefits', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouNeedSupportWithYourBenefits' as const}
                            className={`form-control white ${errors.doYouNeedSupportWithYourBenefits ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doYouNeedSupportWithYourBenefits}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.managing_agreement')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('doYouNeedAnySupportWithManagingYourTenancyAgreement', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouNeedAnySupportWithManagingYourTenancyAgreement' as const}
                            className={`form-control white ${errors.doYouNeedAnySupportWithManagingYourTenancyAgreement ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.doYouNeedAnySupportWithManagingYourTenancyAgreement}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.finances')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatSupportDoYouNeedForYourFinances', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatSupportDoYouNeedForYourFinances' as const}
                            className={`form-control white ${errors.whatSupportDoYouNeedForYourFinances ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.whatSupportDoYouNeedForYourFinances}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.banking_support')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areYouAbleToAccessYourBankingWithOrWithoutSupport', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areYouAbleToAccessYourBankingWithOrWithoutSupport' as const}
                            className={`form-control white ${errors.areYouAbleToAccessYourBankingWithOrWithoutSupport ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areYouAbleToAccessYourBankingWithOrWithoutSupport}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.activities_assessment_required')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyRisksWhenUndertakingTheseActivities6', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyRisksWhenUndertakingTheseActivities6' as const}
                            className={`form-control white ${errors.areThereAnyRisksWhenUndertakingTheseActivities6 ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.areThereAnyRisksWhenUndertakingTheseActivities6}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.You_independent_this')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('youAreIndependentWithThis', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'youAreIndependentWithThis' as const}
                            className={`form-control white ${errors.youAreIndependentWithThis ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.youAreIndependentWithThis}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.family_help')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('yourFamilyHelpWithThis8', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'yourFamilyHelpWithThis8' as const}
                            className={`form-control white ${errors.yourFamilyHelpWithThis8 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.yourFamilyHelpWithThis8}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Father_staff_helps')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('anotherHelpsWithThis8', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'anotherHelpsWithThis8' as const}
                            className={`form-control white ${errors.anotherHelpsWithThis8 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.anotherHelpsWithThis8}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <hr className="dashed-border" />
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Strengths_Assessment.Valorum_Care_Housing_Finances', {orgName: props.orgName})}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('supportYouToDevelopSkillsToManageYourHousingAndFinances', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'supportYouToDevelopSkillsToManageYourHousingAndFinances' as const}
                            className={`form-control white ${errors.supportYouToDevelopSkillsToManageYourHousingAndFinances ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsStrengthAssessmentView.supportYouToDevelopSkillsToManageYourHousingAndFinances}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
        </div>
    );
};

export default StrengthAssessmentView;
