import React from 'react';
import ServiceReferralTableGroupByView from './ServiceReferralTableGroupByView';

const ServiceReferralTableGroupByContainer = (props: any) => {
    return (
        <div>
            <ServiceReferralTableGroupByView onChangeGroupByHandler={props.onChangeGroupByHandler} onStateGroupByHandler={props.onStateGroupByHandler} onRemoveAllGroupBy={props.onRemoveAllGroupBy} />
        </div>
    );
};

export default ServiceReferralTableGroupByContainer;