import React from 'react';
import AssignmentView from '../../../ReferralManagementPage/ReferralManager/ReferralSidebar/ReferralStages/Assignment/AssignmentView';

const NHSAssignmentContainer = (props:any) => {
    return (
       <div>
          <AssignmentView refreshData={props.refreshData} referralid={props.referralid} finalStatus={props.finalStatus} nhs={props.nhs} assignment={props.assignment}/>
       </div>
    );
};

export default NHSAssignmentContainer;