import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import swal from 'sweetalert';
import { deleteDraftPatient } from '../../../../../../service/dashboard-service';
import eventEmitter from '../../../../../../utils/eventEmitter';
import warning from '../../../../../../assets/images/warning.png';


const DraftPatientListView = (props: any) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const toggle = () => {
        setShowModal(false);
    };
    const alertHandler = async (pid:any) => {
        if(pid){
            swal({
                title: '',
                text: 'Are you sure you want to delete the patient draft?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    let payload =pid
                    const response = await deleteDraftPatient(payload)
                    if(response.status == true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                        eventEmitter.emit('eventGetBedInformation');
                        eventEmitter.emit('eventGetDraftPatientList');
                        eventEmitter.emit('eventGetDraftPatientList');
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
       
    };

    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className={classNames('accordion-button', { collapsed: props.accordID !== 0 })}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${props.accordID}`}
                        aria-expanded={props.accordID !== 0 ? 'false' : 'true'}
                        aria-controls={`flush-collapse${props.accordID}`}
                    >
                        <span className="user-name">
                            {(props.dataPatient.fullName.trim().split(' ')[0] !== undefined && props.dataPatient.fullName.trim().split(' ')[0] !== '') ? props.dataPatient.fullName.trim().split(' ')[0].charAt(0) : ''}
                            {(props.dataPatient.fullName.trim().split(' ')[1] !== undefined && props.dataPatient.fullName.trim().split(' ')[1] !== '') ? props.dataPatient.fullName.trim().split(' ')[1].charAt(0) : ''}
                        </span>
                        <p className='patient-name'>{props.dataPatient.fullName}</p>
                    </button>
                </h2>
                <div
                    id={`flush-collapse${props.accordID}`}
                    className={classNames('accordion-collapse collapse', { show: props.accordID === 0 })}
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#draftpatient"
                >
                    <div className="accordion-body pb-0">
                        {props.dataPatient.wRoomNo !== undefined && props.dataPatient.wRoomNo !== '' && props.dataPatient.wRoomNo !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.Bed_No')}:</label> <span className="bold">{props.dataPatient.wRoomNo}</span>
                            </p>
                        )}
                        {props.dataPatient.dateOfBirth !== undefined && props.dataPatient.dateOfBirth !== '' && props.dataPatient.dateOfBirth !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.Date_of_Birth')}:</label> <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.dataPatient.dateOfBirth)}</span>
                            </p>
                        )}
                        {props.dataPatient.gender !== undefined && props.dataPatient.gender !== '' && props.dataPatient.gender !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.Gender')}:</label> <span className="bold">{props.dataPatient.gender}</span>
                            </p>
                        )}
                        {props.dataPatient.nhsId !== undefined && props.dataPatient.nhsId !== '' && props.dataPatient.nhsId !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.NHS_ID')}:</label> <span className="bold">{props.dataPatient.nhsId}</span>
                            </p>
                        )}
                        {props.dataPatient.localId !== undefined && props.dataPatient.localId !== '' && props.dataPatient.localId !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.Client_ID')}:</label> <span className="bold">{props.dataPatient.localId}</span>
                            </p>
                        )}
                        {props.dataPatient.address1 !== undefined && props.dataPatient.address1 !== '' && props.dataPatient.address1 !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.Address1')}:</label> <span className="bold">{props.dataPatient.address1}</span>
                            </p>
                        )}
                        {props.dataPatient.address2 !== undefined && props.dataPatient.address2 !== '' && props.dataPatient.address2 !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.Address2')}:</label> <span className="bold">{props.dataPatient.address2}</span>
                            </p>
                        )}
                        {props.dataPatient.city !== undefined && props.dataPatient.city !== '' && props.dataPatient.city !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.City')}:</label> <span className="bold">{props.dataPatient.city}</span>
                            </p>
                        )}
                        {props.dataPatient.poBox !== undefined && props.dataPatient.poBox !== '' && props.dataPatient.poBox !== null && (
                            <p className="mb-1">
                                <label>{t('DraftRightBar.PO_Box')}:</label> <span className="bold">{props.dataPatient.poBox}</span>
                            </p>
                        )}
                        {props.dataPatient.contactNo !== undefined && props.dataPatient.contactNo !== '' && props.dataPatient.contactNo !== null && (
                            <p className="mb-0">
                                <label>{t('DraftRightBar.Contact_No')}:</label> <span className="bold">{props.dataPatient.contactNo}</span>
                            </p>
                        )}
                        <div className="mt-2 text-center">
                            <button onClick={() => props.handleContinue(props.dataPatient)} className="btn btn-sm btn-primary me-2">
                                {t('DraftRightBar.btn_continue')}
                            </button>
                            <a href="#" className="btn btn-sm white-text btn-delete red-background" onClick={() =>alertHandler(props.dataPatient.patientId)}>
                                {t('DraftRightBar.btn_delete')}
                            </a>
                            {/* <a href="#" className="btn white-text btn-delete red-background" onClick={() => setShowModal(true)}>
                                {t('DraftRightBar.btn_delete')}
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DraftPatientListView;
