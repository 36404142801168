import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { RootState, store } from '../../../../../../../store';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import swal from 'sweetalert';
import { deleteContactDocument, getContactDocument, getFunderData } from '../../../../../../../service/dashboard-service';
import { deleteDigiContractFile, getDigiContactDocument } from '../../../../../../../service/lead-patient';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../../service/ApiConstants';
import { useTranslation } from 'react-i18next';
import { combination, DigitalContractEnum, ServiceTypeIconName } from '../../../../../../../utils/constant';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import GenerateContractContainer from '../../../../../../../components/GenerateContractModal/GenerateContractContainer';
import { contractSign, getContractData, getContractDataAdmission, updateDocuSign } from '../../../../../../../service/contract-service';
import { removeLeadInformationAction, setLeadInformationAction } from '../../../../../../../store/lead/getLeaddataReducer';
import { getLeaddata } from '../../../../../../../service/leads';
import RejectContainer from '../../../../../../../components/RejectModal/RejectContainer';
import warning from '../../../../../../../assets/images/warning.png';
import eventEmitter from '../../../../../../../utils/eventEmitter';
import NoteContainer from '../../../../../../../components/NoteModal/indexContainer';
import note from '../../../../../../../assets/images/note.svg';
import { getValue } from '@testing-library/user-event/dist/utils';
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const FunderListView = (props: any) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    let [ListData, setlistdata] = useState<any>([]);
    const [showCancelModal, setshowCancelModal] = useState(false);
    const [index, setindex] = useState<any>();
    const [cdata, setcdata] = useState<any>();
    const [ccdata, setccdata] = useState<any>();
    const [isfilepond, setfilepond] = useState<any>(false);
    const [previewdata, setpreviewdata] = useState<any>();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { Data } = useSelector((state: RootState) => state.navigation);
    const [parentindex, setparentindex] = useState<Number>(0);
    const dispatch = useDispatch();
    const [funderNote, setFunderNote] = useState<any>('');
    const [fIndex, setfIndex] = useState<number>(0);
    const [isEditing, setisEditing] = useState(false);
    const [combinationId, setCombinationId] = useState<number | null>(null);

    useEffect(() => {
        if (ListData !== undefined) {
            props.contractdata({ ListData });
        }
    }, [ListData]);

    const validationSchema = Yup.object().shape({
        funderList: Yup.string().required(),
        funders: Yup.array().of(
            Yup.object().shape({
                funderData: Yup.object().shape({
                    fEmailId: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        .email('Email is Invalid')
                        .required('Email is required'),
                    fFunder: Yup.string().trim().strict(true).required('Funder is required'),
                    fContactNo: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
                        .nonNullable()
                        .required('contact is required'),
                    fAddress1: Yup.string().trim().strict(true).required('add required'),
                    fCity: Yup.string().trim().strict(true).required('city required'),
                    fPoBox: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                        .nonNullable()
                        .required('pobox is required'),
                    funderContract: Yup.string().notRequired(),
                    fCheck: Yup.boolean().notRequired(),
                    deletebtn: Yup.string().notRequired(),
                    isFilePond: Yup.boolean().notRequired(),
                    contractPDF: Yup.mixed().notRequired(),
                    ftype: Yup.string().notRequired(),
                    farray: Yup.mixed().notRequired(),
                    fpo: Yup.string().notRequired()
                }),
                commisionerData: Yup.object().shape({
                    isselffunder: Yup.boolean().notRequired(),
                    cEmailId: Yup.string().when('isselffunder', {
                        is: true,
                        then: (schema: any) => schema.nonNullable().notRequired(),
                        otherwise: (schema: any) =>
                            schema
                                .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                                .email('Email is Invalid')
                                .required('Email is required')
                    }),
                    cFunder: Yup.string().when('isselffunder', {
                        is: true,
                        then: (schema: any) => schema.nonNullable().notRequired(),
                        otherwise: (schema: any) => schema.required()
                    }),
                    cContactNo: Yup.string().when('isselffunder', {
                        is: true,
                        then: (schema: any) => schema.nonNullable().notRequired(),
                        otherwise: (schema: any) =>
                            schema
                                .matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
                                .nonNullable()
                                .required('contact is required')
                    }),
                    cAddress1: Yup.string().trim().strict(true).notRequired(),
                    cCity: Yup.string().trim().strict(true).notRequired(),
                    cPoBox: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                        .nonNullable()
                        .notRequired(),
                    sEmailId: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        .email('Email is Invalid')
                        .notRequired(),
                    sFunder: Yup.string().trim().strict(true).notRequired(),
                    sContactNo: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                        .nonNullable()
                        .notRequired(),
                    sAddress1: Yup.string().trim().strict(true).notRequired(),
                    sCity: Yup.string().trim().strict(true).notRequired(),
                    sPoBox: Yup.string()
                        .trim()
                        .strict(true)
                        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                        .nonNullable()
                        .notRequired()
                })
            })
        )
    });
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    let [FunderData, setFunderData] = useState<any>([]);
    let [CityData, setCityData] = useState<any>([]);
    let [isFunderTypeChange, setFunderTypeChange] = useState<any>(false);
    const [attachments, setattachments] = useState<any>('');
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    useEffect(() => {
        if (DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0) {
            setFunderData(DataFunderInfo.data);
        }
    }, [DataFunderInfo]);

    useEffect(() => {
        if (DataCityInfo.data !== undefined && DataCityInfo.data.length > 0) {
            setCityData(DataCityInfo.data);
        }
    });

    useEffect(() => {
        if (props.changetype) {
            setValue(`funders[${props.funderindex}].funderData.fFunder`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.fEmailId`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.fContactNo`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.fAddress1`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.fCity`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.fPoBox`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.farray`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.farray`, DataFunderInfo.data, { shouldValidate: true });
            setValue(`funders[${props.funderindex}].funderData.fpo`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cFunder`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].commisionerData.cEmailId`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].commisionerData.cContactNo`, '', { shouldValidate: true });
            setValue(`funders[${props.funderindex}].commisionerData.cAddress1`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cCity`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cPoBox`, '');

            if (props.fundertype === '4') {
                props.updateselffunder(props.funderindex);
            }
        }
    }, [props.changetype, props.fundertype, DataFunderInfo]);

    const { register, control, handleSubmit, getValues, reset, formState, watch, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'funders', control });

    const funderList = watch('funderList');
    const [file, setFiles] = useState<any[]>([]);
    const [fileres, setFileres] = useState<any>('');
    const [isFilepond, setFilepond] = useState<any>(false);
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const [isFile, setisFile] = useState<any>(false);
    const [ctitle, setctitle] = useState<any>();
    useEffect(() => {
        reset();
        toggle();
    }, []);

    // We have to trigger the same useEffect when we click on any other bed.
    useEffect(() => {
        reset();
    }, [Data]);

    useEffect(() => {
        console.log(errors);
        let index = errors;
        props.getfundelisterror(errors);
    }, [formState]);

    useEffect(() => {
        props.getfundelisterror(errors);
    }, [file]);

    useEffect(() => {
        eventEmitter.on('eventClearFormArray', eventClearArray);
        eventEmitter.on('getContractData', getContractDataLocal);
        return () => {
            eventEmitter.off('eventClearFormArray', eventClearArray);
            eventEmitter.off('getContractData', getContractDataLocal);
        };
    });

    const getContractDataLocal = () => {
        // toggle();
    };

    const eventClearArray = () => {
        console.log('call emit');
        remove();
    };

    useEffect(() => {
        // 
        if (props.funderList.length > 0) {
            const newVal = props.funderList.length;
            const oldVal = fields.length;
            
            
            if (newVal > oldVal) {
                for (let i = oldVal; i < newVal; i++) {
                    append(props.funderList[newVal - 1]);
                    setValue(`funders[${i}].funderData.isFilePond`, props.funderList[i].funderData.isFilePond, { shouldValidate: true });
                    setValue(`funders[${i}].funderData.funderContract`, props.funderList[i].funderData.filename || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.fFunder`, props.funderList[i].funderData.fFunder || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.fEmailId`, props.funderList[i].funderData.fEmailId || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.fContactNo`, props.funderList[i].funderData.fContactNo || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.fAddress1`, props.funderList[i].funderData.fAddress1 || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.fCity`, props.funderList[i].funderData.fCity || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.fPoBox`, props.funderList[i].funderData.fPoBox || '', { shouldValidate: true });
                    setValue(`funders[${i}].funderData.ftype`, props.funderList[i].funderData.ftype || '');
                    setValue(`funders[${i}].commisionerData.isselffunder`, props.funderList[i].funderData.ftype === '4' ? true : false);
                    setValue(`funders[${i}].funderData.farray`, props.funderList[i].funderData.farray || '');
                    setValue(`funders[${i}].funderData.fsuid`, props.funderList[i].funderData?.fsuid || '');
                    setValue(`funders[${i}].funderData.fpo`, props.funderList[i].funderData?.fpo || '');
                    setValue(`funders[${i}].funderData.fnote`, props.funderList[i].funderData?.fnote || '');
                    setValue(`funders[${i}].funderData.isNoteEdited`, props.funderList[i].funderData?.fnote != undefined && props.funderList[i].funderData?.fnote != '' ? true : false);

                    setValue(`funders[${i}].commisionerData.cFunder`, props.funderList[i].commisionerData.cFunder || '', { shouldValidate: true });
                    setValue(`funders[${i}].commisionerData.cEmailId`, props.funderList[i].commisionerData.cEmailId || '', { shouldValidate: true });
                    setValue(`funders[${i}].commisionerData.cContactNo`, props.funderList[i].commisionerData.cContactNo || '', { shouldValidate: true });
                    setValue(`funders[${i}].commisionerData.cAddress1`, props.funderList[i].commisionerData.cAddress1 || '');
                    setValue(`funders[${i}].commisionerData.cCity`, props.funderList[i].commisionerData.cCity || '');
                    setValue(`funders[${i}].commisionerData.cPoBox`, props.funderList[i].commisionerData.cPoBox || '');

                    setValue(`funders[${i}].commisionerData.sFunder`, props.funderList[i].commisionerData.sFunder || '', { shouldValidate: true });
                    setValue(`funders[${i}].commisionerData.sEmailId`, props.funderList[i].commisionerData.sEmailId || '', { shouldValidate: true });
                    setValue(`funders[${i}].commisionerData.sContactNo`, props.funderList[i].commisionerData.sContactNo || '', { shouldValidate: true });
                    setValue(`funders[${i}].commisionerData.sAddress1`, props.funderList[i].commisionerData.sAddress1 || '');
                    setValue(`funders[${i}].commisionerData.sCity`, props.funderList[i].commisionerData.sCity || '');
                    setValue(`funders[${i}].commisionerData.sPoBox`, props.funderList[i].commisionerData.sPoBox || '');
                }
            } else if (newVal === oldVal) {
                if (props.funderindex >= 0) {
                    setValue(`funders[${props.funderindex}].funderData.ftype`, props.funderList[props.funderindex].funderData.ftype || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].commisionerData.isselffunder`, props.funderList[props.funderindex].funderData.ftype === '4' ? true : false);
                    setValue(`funders[${props.funderindex}].funderData.isFilePond`, props.funderList[props.funderindex].funderData.isFilePond, { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.funderContract`, props.funderList[props.funderindex].funderData.filename || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.filepath`, props.funderList[props.funderindex].funderData.filepath || '', { shouldValidate: false });
                    setValue(`funders[${props.funderindex}].funderData.fFunder`, props.funderList[props.funderindex].funderData.fFunder || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.fEmailId`, props.funderList[props.funderindex].funderData.fEmailId || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.fContactNo`, props.funderList[props.funderindex].funderData.fContactNo || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.fAddress1`, props.funderList[props.funderindex].funderData.fAddress1 || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.fCity`, props.funderList[props.funderindex].funderData.fCity || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].funderData.fPoBox`, props.funderList[props.funderindex].funderData.fPoBox || '', { shouldValidate: true });

                    setValue(`funders[${props.funderindex}].funderData.fsuid`, props.funderList[props.funderindex].funderData.fsuid || '');
                    setValue(`funders[${props.funderindex}].funderData.fpo`, props.funderList[props.funderindex].funderData.fpo || '');
                    setValue(`funders[${props.funderindex}].funderData.fnote`, props.funderList[props.funderindex].funderData.fnote || '');
                    setValue(
                        `funders[${props.funderindex}].funderData.isNoteEdited`,
                        props.funderList[props.funderindex].funderData?.fnote != undefined && props.funderList[props.funderindex].funderData?.fnote != '' ? true : false
                    );

                    if (props.commisionerindex >= 0) {
                        setValue(`funders[${props.commisionerindex}].commisionerData.cFunder`, props.funderList[props.commisionerindex].commisionerData.cFunder || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.cEmailId`, props.funderList[props.commisionerindex].commisionerData.cEmailId || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.cContactNo`, props.funderList[props.commisionerindex].commisionerData.cContactNo || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.cAddress1`, props.funderList[props.commisionerindex].commisionerData.cAddress1 || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.cCity`, props.funderList[props.commisionerindex].commisionerData.cCity || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.cPoBox`, props.funderList[props.commisionerindex].commisionerData.cPoBox || '');

                        setValue(`funders[${props.commisionerindex}].commisionerData.sFunder`, props.funderList[props.commisionerindex].commisionerData.sFunder || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.sEmailId`, props.funderList[props.commisionerindex].commisionerData.sEmailId || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.sContactNo`, props.funderList[props.commisionerindex].commisionerData.sContactNo || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.sAddress1`, props.funderList[props.commisionerindex].commisionerData.sAddress1 || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.sCity`, props.funderList[props.commisionerindex].commisionerData.sCity || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.sPoBox`, props.funderList[props.commisionerindex].commisionerData.sPoBox || '');
                    }
                }
                if (props.index >= 0) {
                    setValue(`funders[${props.index}].commisionerData.cFunder`, props.funderList[props.index].commisionerData.cFunder || '', { shouldValidate: true });
                    setValue(`funders[${props.index}].commisionerData.cEmailId`, props.funderList[props.index].commisionerData.cEmailId || '', { shouldValidate: true });
                    setValue(`funders[${props.index}].commisionerData.cContactNo`, props.funderList[props.index].commisionerData.cContactNo || '', { shouldValidate: true });
                    setValue(`funders[${props.index}].commisionerData.cAddress1`, props.funderList[props.index].commisionerData.cAddress1 || '');
                    setValue(`funders[${props.index}].commisionerData.cCity`, props.funderList[props.index].commisionerData.cCity || '');
                    setValue(`funders[${props.index}].commisionerData.cPoBox`, props.funderList[props.index].commisionerData.cPoBox || '');
                }
            }
        }
    }, [props.funderList]);

    useEffect(() => {
        if (!props.isCheck) {
            setValue(`funders[${props.funderindex}].funderData.fCheck`, false);
        }
    }, [props.isCheck]);

    const addrecord = () => {
        props.isadd(true);
    };

    const edit = () => { };

    const deleterecord = (index: number, data: any) => {
        console.log(index);

        if (
            DataLeadInfo?.data?.contract[index]?.digitalContractId !== 0 &&
            (DataLeadInfo?.data?.contract[index]?.status === 'Sent to Funder' ||
                DataLeadInfo?.data?.contract[index]?.status === 'In Progress with Funder' ||
                DataLeadInfo?.data?.contract[index]?.status === 'Completed' ||
                DataLeadInfo?.data?.contract[index]?.status === 'Signed by Commissioner')
        ) {
            swal({
                title: '',
                text: 'Please Void/Cancel contract before deleting Funder',
                icon: warning,
                dangerMode: true
            }).then(async (confirm: any) => { });
        } else {
            remove(index);
            props.isdelete(index, data);
        }
    };

    const removepdf = (e: any, i: number, j: number, data: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove attachment?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                // const payload = { path: getValues(`funders[${i}].funderData.filepath`) };
                // const response = getValues(`funders[${i}].funderData.fLeadPoolingDetailsId`) > 0 ? await deleteDigiContractFile(payload) : await deleteContactDocument(payload);
                let payload: any;
                if (DataPatientDraftInfo?.data?.isFromLeadPooling) {
                    payload = { path: props.funderList[i].funderData?.attachments[j]?.filePath };
                } else {
                    payload = { path: props.funderList[i].funderData?.contractAttachmentModels[j]?.filePath };
                }
                const response = await deleteContactDocument(payload);
                if (response.status == true) {
                    // props.updatefilepond(i);
                    props.updateFData({ filedata: '', i, op: 'remove', j, data });
                } else {
                }
            }
        });
    };

    const openpdf = async (e: any, pi: number, ci: number) => {
        e.preventDefault();
        let payload: any;
        if (DataPatientDraftInfo?.data?.isFromLeadPooling) {
            payload = { path: props.funderList[pi].funderData?.attachments[ci]?.filePath };
        } else {
            payload = { path: props.funderList[pi].funderData?.contractAttachmentModels[ci]?.filePath };
        }
        // const response = fLeadPoolingDetailsId > 0 ? await getDigiContactDocument(payload) : await getContactDocument(payload);
        const response = await getContactDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    const openFileupload = (i: number) => {
        if (isFile) {
            setisFile(false);
        } else {
            setisFile(true);
            setparentindex(i);
        }
    };

    const toggleModal = () => {
        setShowModal(false);
        setcdata(undefined);
    };

    const handleClickModal = async (data: any) => {
        //     dispatch(removeLeadInformationAction());
        //    const res = await getContractDataAdmission(DataPatientDraftInfo?.data?.admissionDetail?.admissionId);
        //    if(res.status){
        //     dispatch(setLeadInformationAction(res));
        // props.getacdid(data.acdid)
        // setindex(i);

        const funderTypes = props.datapatient?.data?.contractDetails?.filter((el: any) => (el.docusignStatus !== 'Voided' && el.docusignStatus !== 'Cancelled')).map((cd: any) => cd.funderTypeName ?? 'Self');

        console.log('funderTypes', funderTypes);
        const keyIndex = Object.keys(DigitalContractEnum).findIndex((key, index) => {
            const keyArr = key.split('_');
            const resultArr = keyArr.map((ft) => funderTypes?.includes(ft));
            if (resultArr.length === funderTypes?.length) {
                const isEveryElementIsTrue = resultArr?.every((item) => item === true);
                return isEveryElementIsTrue;
            }
        });

        const digitalContractValue = Object.values(DigitalContractEnum)[keyIndex];
        setCombinationId(digitalContractValue);

        const isExactMatch = combination.some((obj) => obj?.funders?.length === funderTypes?.length && obj?.funders?.every((ft) => funderTypes?.includes(ft)));

        if (isExactMatch) {
            swal({
                title: '',
                text: `No contract available for the funder combination of ${funderTypes.join(', ')}`,
                icon: 'warning',
                dangerMode: true
            });
        } else {
            setShowModal(true);
        }
    };
    async function handleEditModal(i: any, id: any, acdid: any) {
        console.log(i);
        console.log(DataLeadInfo?.data?.contract[i]?.status);
        setindex(i);
        props.getacdid(acdid);
        if (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== '') {
            if (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Completed') {
                const docures = await updateDocuSign(id);
                if (docures.status) {
                    const res = await getContractData(id);
                    if (res.status) {
                        setcdata(res.data);
                        setShowModal(true);
                        dispatch(removeLeadInformationAction());
                        const res1 = await getContractDataAdmission(DataPatientDraftInfo?.data?.admissionDetail?.admissionId);
                        if (res1.status) {
                            dispatch(setLeadInformationAction(res1));
                        }
                    } else {
                        setcdata(undefined);
                    }
                }
            } else {
                const res = await getContractData(id);
                if (res.status) {
                    setcdata(res.data);
                    setShowModal(true);
                } else {
                    setcdata(undefined);
                }
            }
        } else {
            const res = await getContractData(id);
            if (res.status) {
                setcdata(res.data);
                setShowModal(true);
            } else {
                setcdata(undefined);
            }
        }
    }

    useEffect(() => {
        console.log(cdata);
    }, [cdata])

    useEffect(() => {
        if (props.isdone) {
            setShowModal(false);
            setcdata(undefined);
        }
    }, [props.isdone]);

    useEffect(() => {
        props.cindex(index);
    }, [index]);

    async function viewsign(i: any, cid: any) {
        const docures = await updateDocuSign(cid);
        const res = await contractSign(cid);
        if (res.status) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = res.data;
            alink.click();
        }
    }

    const toggle = async () => {
        const res1 = await getContractDataAdmission(DataPatientDraftInfo?.data?.admissionDetail?.admissionId);
        if (res1.status) {
            dispatch(setLeadInformationAction(res1));
            if (res1?.data?.contract?.length > 0) {
                let tempdata = [];
                let obj;
                let filterlist = res1?.data?.contract?.filter((val: any) => val.status !== 'Voided' && val.status !== 'Cancelled');
                if (filterlist.length === 0) {
                    setlistdata(null);
                } else {
                    for (let i = 0; i < filterlist?.length; i++) {
                        const fundername = DataFunderInfo.data.filter((val: any) => val.key == filterlist[i]?.funderId);
                        const funderarr = filterlist[i]?.isSelfFunded === 1 ? [] : DataFunderInfo.data.filter((val: any) => val?.funderTypeId === fundername[0]?.funderTypeId);
                        let contractData = {
                            fAddress1: filterlist[i]?.address1,
                            fCity: filterlist[i]?.countyId?.toString(),
                            fContactNo: filterlist[i]?.contactNo,
                            fEmailId: filterlist[i]?.funderEmail,
                            fFunder: filterlist[i]?.isSelfFunded === 1 ? filterlist[i]?.funderName : filterlist[i]?.funderId?.toString(),
                            fPoBox: filterlist[i]?.postalCode,
                            fFunderVal: filterlist[i]?.isSelfFunded === 1 ? filterlist[i]?.funderName : fundername[0]?.value,
                            filepath: filterlist[i]?.documentPath,
                            ftype: filterlist[i]?.isSelfFunded === 1 ? '4' : fundername[0]?.funderTypeId?.toString(),
                            filename: filterlist[i]?.documentName,
                            isFilePond: filterlist[i]?.documentName === null ? true : false,
                            fReferralId: DataPatientDraftInfo?.data?.referralId,
                            fLeadPoolingDetailsId: DataPatientDraftInfo?.data?.leadPoolingId,
                            fdraftLeadId: DataPatientDraftInfo?.data?.draftLeadId,
                            fFunderId: filterlist[i]?.funderId,
                            fleadFunderId: filterlist[i]?.leadFunderId,
                            fdailyCoreFee: filterlist[i]?.dailyCoreFee,
                            farray: funderarr,
                            contractAttachmentModels: filterlist[i]?.contractAttachmentModels,
                            attachments: filterlist[i]?.attachments,
                            fsuid: filterlist[i]?.fsuid, //filterlist[i]?.fsuid,
                            fnote: filterlist[i]?.fnote, //filterlist[i]?.fnote,
                            fpo: filterlist[i]?.fpo
                        };
                        let commissionerData = {
                            cAddress1: filterlist[i]?.commissionerAddressLine1,
                            cCity: filterlist[i]?.commissionerCountyId?.toString(),
                            cContactNo: filterlist[i]?.commissionerPhoneNo,
                            cEmailId: filterlist[i]?.commissionerEmail,
                            cFunder: filterlist[i]?.commissionerName,
                            cPoBox: filterlist[i]?.commissionerPostalCode,

                            sAddress1: filterlist[i]?.socialWorkerAddressLine1,
                            sCity: filterlist[i]?.socialWorkerCountyId?.toString(),
                            sContactNo: filterlist[i]?.socialWorkerPhoneNo,
                            sEmailId: filterlist[i]?.socialWorkerEmail,
                            sFunder: filterlist[i]?.socialWorkerName,
                            sPoBox: filterlist[i]?.socialWorkerPostalCode
                        };
                        obj = {
                            funderData: contractData,
                            commisionerData: commissionerData,
                            isinvoicecreated: DataPatientDraftInfo?.data?.isInvoiceCreated,
                            acdid: filterlist[i]?.acdId
                        };
                        if (props.deletedfilterdata.length > 0) {
                            let funderexists = props.deletedfilterdata.find((val: any) => {
                                if (val.acdid === filterlist[i]?.acdId) {
                                    return val;
                                }
                            });
                            if (funderexists === undefined) {
                                tempdata.push(obj);
                                setlistdata(tempdata);
                            }
                        } else {
                            tempdata.push(obj);
                            setlistdata(tempdata);
                        }
                    }
                }
            }
        } else if (props.isReferralAdd) {
            let tempdata = [];
            let obj;
            for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails?.length; i++) {
                const fundername = DataFunderInfo.data.filter((val: any) => val.key == DataPatientDraftInfo?.data?.contractDetails[i]?.funderId);
                const funderarr = DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? [] : DataFunderInfo.data.filter((val: any) => val?.funderTypeId === fundername[0]?.funderTypeId);
                let contractData = {
                    fAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.funderAddressLine1,
                    fCity: DataPatientDraftInfo?.data?.contractDetails[i]?.funderCityId?.toString(),
                    fContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.funderPhoneNo,
                    fEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderEmail,
                    fFunder:
                        DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1
                            ? DataPatientDraftInfo?.data?.contractDetails[i]?.funderName
                            : DataPatientDraftInfo?.data?.contractDetails[i]?.funderId.toString(),
                    fPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.funderPobox,
                    fFunderVal: DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? DataPatientDraftInfo?.data?.contractDetails[i]?.funderName : fundername[0]?.value,
                    filepath: DataPatientDraftInfo?.data?.contractDetails[i]?.documentPath,
                    ftype: DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? '4' : fundername[0].funderTypeId.toString(),
                    filename: DataPatientDraftInfo?.data?.contractDetails[i]?.documentName,
                    isFilePond: DataPatientDraftInfo?.data?.contractDetails[i]?.documentName === null ? true : false,
                    fReferralId: DataPatientDraftInfo?.data?.referralId,
                    fLeadPoolingDetailsId: DataPatientDraftInfo?.data?.leadPoolingId,
                    fdraftLeadId: DataPatientDraftInfo?.data?.draftLeadId,
                    fFunderId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderId,
                    fleadFunderId: DataPatientDraftInfo?.data?.contractDetails[i]?.leadFunderId,
                    fdailyCoreFee: DataPatientDraftInfo?.data?.contractDetails[i]?.dailyCoreFee,
                    farray: funderarr,
                    contractAttachmentModels: DataPatientDraftInfo?.data?.contractDetails[i]?.contractAttachmentModels,
                    attachments: DataPatientDraftInfo?.data?.contractDetails[i]?.attachments,
                    acdid: DataPatientDraftInfo?.data?.contractDetails[i]?.acdid
                };
                let commissionerData = {
                    cAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerAddressLine1,
                    cCity: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerCityId?.toString(),
                    cContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerPhonNo,
                    cEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerEmail,
                    cFunder: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerName,
                    cPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerPobox,

                    sAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerAddress,
                    sCity: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerCityId?.toString(),
                    sContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerContactNo,
                    sEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerEmailId,
                    sFunder: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerName,
                    sPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerPobox
                };
                obj = {
                    funderData: contractData,
                    commisionerData: commissionerData,
                    isinvoicecreated: DataPatientDraftInfo?.data?.isInvoiceCreated
                };
                tempdata.push(obj);
                setlistdata(tempdata);
            }
        } else if (props.isDraftListAdd) {
            let tempdata = [];
            let obj;
            for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails?.length; i++) {
                const fundername = DataFunderInfo.data.filter((val: any) => val.key == DataPatientDraftInfo?.data?.contractDetails[i]?.funderId);
                const funderarr = DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? [] : DataFunderInfo.data.filter((val: any) => val?.funderTypeId === fundername[0]?.funderTypeId);
                let contractData = {
                    fAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.funderAddressLine1,
                    fCity: DataPatientDraftInfo?.data?.contractDetails[i]?.funderCityId.toString(),
                    fContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.funderPhoneNo,
                    fEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderEmail,
                    fFunder:
                        DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1
                            ? DataPatientDraftInfo?.data?.contractDetails[i]?.funderName
                            : DataPatientDraftInfo?.data?.contractDetails[i]?.funderId.toString(),
                    fPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.funderPobox,
                    fFunderVal: DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? DataPatientDraftInfo?.data?.contractDetails[i]?.funderName : fundername[0]?.value,
                    filepath: DataPatientDraftInfo?.data?.contractDetails[i]?.documentPath,
                    ftype: DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? '4' : fundername[0].funderTypeId.toString(),
                    filename: DataPatientDraftInfo?.data?.contractDetails[i]?.documentName,
                    isFilePond: DataPatientDraftInfo?.data?.contractDetails[i]?.documentName === null ? true : false,
                    fReferralId: DataPatientDraftInfo?.data?.referralId,
                    fLeadPoolingDetailsId: DataPatientDraftInfo?.data?.leadPoolingId,
                    fdraftLeadId: DataPatientDraftInfo?.data?.draftLeadId,
                    fFunderId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderId,
                    fleadFunderId: DataPatientDraftInfo?.data?.contractDetails[i]?.leadFunderId,
                    fdailyCoreFee: DataPatientDraftInfo?.data?.contractDetails[i]?.dailyCoreFee,
                    farray: funderarr,
                    contractAttachmentModels: DataPatientDraftInfo?.data?.contractDetails[i]?.contractAttachmentModels,
                    attachments: DataPatientDraftInfo?.data?.contractDetails[i]?.attachments,
                    acdid: DataPatientDraftInfo?.data?.contractDetails[i]?.acdid
                };
                let commissionerData = {
                    cAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerAddressLine1,
                    cCity: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerCityId?.toString(),
                    cContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerPhonNo,
                    cEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerEmail,
                    cFunder: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerName,
                    cPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerPobox,

                    sAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerAddress,
                    sCity: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerCityId?.toString(),
                    sContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerContactNo,
                    sEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerEmailId,
                    sFunder: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerName,
                    sPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerPobox
                };
                obj = {
                    funderData: contractData,
                    commisionerData: commissionerData,
                    isinvoicecreated: DataPatientDraftInfo?.data?.isInvoiceCreated,
                    dacdid: DataPatientDraftInfo?.data?.contractDetails[i]?.dacdId
                };
                tempdata.push(obj);
                setlistdata(tempdata);
            }
        } else {
            // Need to set the 'DataLeadInfo' even if the res.status is false
            dispatch(setLeadInformationAction(res1));
            setlistdata(null);
        }
        setshowCancelModal(false);
    };

    const handleCancelModal = (i: any, id: any) => {
        setctitle('Cancel Contract');
        let data = {
            leadid: DataLeadInfo?.data?.leadId,
            funderid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderId,
            contractid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
            isvoided: false,
            leadinfo: DataLeadInfo?.data,
            index: i,
            admissionid: DataPatientDraftInfo?.data?.admissionDetail?.admissionId,
            isinvoice: DataPatientDraftInfo?.data?.isInvoiceCreated
        };
        setccdata(data);
        setshowCancelModal(true);
    };
    const handleVoidModal = (i: any, id: any) => {
        setctitle('Void Contract');
        let data = {
            leadid: DataLeadInfo?.data?.leadId,
            funderid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderId,
            contractid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
            isvoided: true,
            leadinfo: DataLeadInfo?.data,
            index: i,
            admissionid: DataPatientDraftInfo?.data?.admissionDetail?.admissionId,
            isinvoice: DataPatientDraftInfo?.data?.isInvoiceCreated
        };
        setccdata(data);
        setshowCancelModal(true);
    };

    const handleNoteClickModal = (i: any, noteval: any) => {
        setfIndex(i);
        setFunderNote(noteval);
        setShowNoteModal(true);
    };
    const updateNote = (e: any, i: any) => {
        setShowNoteModal(false);
        e.target.name = `funders[${i}].funderData.fnote`;
        setValue(`funders[${i}].funderData.fnote`, e.target.value || '');

        if (e.target.value.length > 0) {
            setValue(`funders[${i}].funderData.isNoteEdited`, true);
        } else {
            setValue(`funders[${i}].funderData.isNoteEdited`, false);
        }

        props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
    };

    const toggleNote = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowNoteModal(false);
            }
        });
    };

    console.log({ contractData: props.datapatient?.data?.contractDetails });

    return (
        <>
            {props.funderList.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label className="bold">Funders</label>
                    <div className="d-flex align-items-center ">
                        <button
                            disabled={props.iseditable ? false : true}
                            onClick={addrecord}
                            className="btn btn-primary btn-sm purple-background ms-1 me-1"
                            data-tooltip-id="my-tooltip-add-funder"
                            data-tooltip-content="Add Funder"
                            id="add-funder"
                        >
                            <i className="fa-solid fa-plus"></i> Add
                        </button>
                    </div>

                    {/* {props.datapatient?.data?.isFromLeadPooling === null && 
                        <>
                         {props.datapatient !== '' && props.datapatient?.data?.contractDetails[i]?.digitalContractId === null && props.datapatient?.data?.isDraft !== 1  &&
                            <a onClick={()=>handleClickModal(i,props.datapatient?.data?.contractDetails[i])} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Generate_btn')} </a>
                        }
                        </>
                        }

                    {props.datapatient?.data?.isFromLeadPooling &&
                       <>
                         {props.datapatient !== '' && props.datapatient?.data?.contractDetails[i]?.digitalContractId === null && props.datapatient?.data?.admissionDetail?.admissionId !== 0 && props.datapatient?.data?.isDraft !== 1 &&
                            <a onClick={()=>handleClickModal(i,props.datapatient?.data?.contractDetails[i])} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Generate_btn')} </a>
                        }
                       </>
                       } */}
                </div>
            )}
            <form>
                {props.funderList.length > 0 &&
                    fields.map((fundersdata: any, i: number) => {
                        return (
                            <div key={i} className="accordion accordion-flush" id="accordionFunder" style={{ pointerEvents: props.iseditable ? 'all' : 'none' }}>
                                <div className="accordion-item white mt-0">
                                    <h2 className="accordion-header" id={`funder-item${i}`}>
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#funder-collapse${i}`}
                                            aria-expanded="false"
                                            aria-controls={`#funder-collapse${i}`}
                                        >
                                            <div className="funder-header w-100 me-2 align-items-center d-flex justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <span className="bold funder-num">{i + 1}</span>
                                                    <label className="bold funder-name">{props.funderList[i]?.funderData?.fFunderVal}</label>
                                                </div>
                                                <div>
                                                    <a onClick={edit} className="btn-icon gray-background me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </a>

                                                    <a
                                                        onClick={(e: any) => deleterecord(i, props.funderList[i].funderData)}
                                                        className="btn-icon red-background"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        title="Delete"
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id={`funder-collapse${i}`} className="accordion-collapse collapse" aria-labelledby={`funder-item${i}`} data-bs-parent="#accordionFunder">
                                        <div className="accordion-body">
                                            <div className="funder-detail">
                                                <div className="d-flex justify-content-between mb-1 mt-2">
                                                    <label className="mb-2">{t('VacantRightbar.ftype')}</label>

                                                    <label className="form--radio-label mb-2  d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="CCG"
                                                            value="1"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.ccg')}</span>
                                                    </label>

                                                    <label className="form--radio-label  mb-2 d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="LA"
                                                            value="2"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.la')}</span>
                                                    </label>

                                                    <label className="form--radio-label  mb-2 d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="Charity"
                                                            value="3"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.ch')}</span>
                                                    </label>

                                                    <label className="form--radio-label mb-2 d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="Self"
                                                            value="4"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.self')}</span>
                                                    </label>
                                                </div>
                                                {getValues(`funders.${i}.funderData.ftype`) !== '4' && (
                                                    <>
                                                    {console.log('amn',getValues(`funders.${i}.funderData`))}
                                                        <div className="d-flex mb-2">
                                                            <select
                                                                {...register(`funders.${i}.funderData.fFunder`, {
                                                                    onChange: (e: any) => {
                                                                        props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                                    }
                                                                })}
                                                                name={`funders[${i}].funderData.fFunder` as const}
                                                                id="Funder"
                                                                disabled
                                                                //@ts-ignore
                                                                className={`form-control white flex-7 ${errors.funders?.[i]?.funderData?.fFunder ? 'is-invalid' : ''}`}
                                                            >
                                                                <option disabled={true} value="">
                                                                    {t('VacantRightbar.fname')}
                                                                </option>
                                                                {getValues(`funders.${i}.funderData.farray`).length > 0 &&
                                                                    getValues(`funders.${i}.funderData.farray`).map((funders: any) => {
                                                                        return (
                                                                            <option key={funders.key} value={funders.key}>
                                                                                {funders.value}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                        </div>
                                                    </>
                                                )}

                                                {getValues(`funders.${i}.funderData.ftype`) === '4' && (
                                                    <>
                                                        <div className="d-flex mb-2">
                                                            <input
                                                                type="text"
                                                                disabled
                                                                {...register(`funders.${i}.funderData.fFunder`)}
                                                                name={`funders[${i}].funderData.fFunder` as const}
                                                                placeholder={`${t('VacantRightbar.fname')}`}
                                                                //@ts-ignore
                                                                className={`form-control white flex-7 ${errors.funders?.[i]?.funderData?.fFunder ? 'is-invalid' : ''}`}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fEmailId`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fEmailId` as const}
                                                    placeholder={`${t('VacantRightbar.email')}*`}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2 ${errors.funders?.[i]?.funderData?.fEmailId ? 'is-invalid' : ''}`}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <input
                                                        type="text"
                                                        {...register(`funders.${i}.funderData.fContactNo`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fContactNo` as const}
                                                        placeholder={`${t('VacantRightbar.contact')}*`}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2 ${errors.funders?.[i]?.funderData?.fContactNo ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fAddress1`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fAddress1` as const}
                                                    placeholder={`${t('VacantRightbar.add1')}*`}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.funderData?.fAddress1 ? 'is-invalid' : ''}`}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <select
                                                        {...register(`funders.${i}.funderData.fCity`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fCity` as const}
                                                        id="city"
                                                        //@ts-ignore
                                                        className={`form-control white mb-2  ${errors.funders?.[i]?.funderData?.fCity ? 'is-invalid' : ''}`}
                                                    >
                                                        <option disabled={true} value="">
                                                            {t('VacantRightbar.county')}*`
                                                        </option>
                                                        {props.citylist &&
                                                            props.citylist.data.map((cities: any) => {
                                                                return (
                                                                    <option key={cities.key} value={cities.key}>
                                                                        {cities.value}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <input
                                                        type="text"
                                                        {...register(`funders.${i}.funderData.fPoBox`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fPoBox` as const}
                                                        placeholder={`${t('VacantRightbar.postal')}*`}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2 ms-2 ${errors.funders?.[i]?.funderData?.fPoBox ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fsuid`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fsuid` as const}
                                                    placeholder={`${t('VacantRightbar.Suid')}`}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2`}
                                                    maxLength={40}
                                                />

                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fpo`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.fpo`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fpo` as const}
                                                    placeholder={`${t('VacantRightbar.po_reference')}`}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2`}
                                                    maxLength={40}
                                                />

                                                {getValues(`funders.${i}.funderData.fnote`) == '' && (
                                                    <div className="d-flex mt-2">
                                                        <img src={note} className="img-fluid me-1" onClick={() => handleNoteClickModal(i, getValues(`funders.${i}.funderData.fnote`))}></img>
                                                        <p>{t('VacantRightbar.enter_note')}</p>
                                                    </div>
                                                )}
                                                {getValues(`funders.${i}.funderData.fnote`) != '' && window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).length >= 30 && (
                                                    <div className="d-flex mt-2">
                                                        <img src={note} className="img-fluid me-1" onClick={() => handleNoteClickModal(i, getValues(`funders.${i}.funderData.fnote`))}></img>
                                                        <p>{window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).slice(0, 27)}...</p>{' '}
                                                        <p className="bold" onClick={() => handleNoteClickModal(i, getValues(`funders.${i}.funderData.fnote`))}>
                                                            {t('VacantRightbar.read_more')}
                                                        </p>
                                                    </div>
                                                )}
                                                {getValues(`funders.${i}.funderData.fnote`) != '' &&
                                                    window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).length > 0 &&
                                                    window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).length < 30 && (
                                                        <div className="d-flex mt-2">
                                                            <img src={note} className="img-fluid me-1" onClick={() => handleNoteClickModal(i, getValues(`funders.${i}.funderData.fnote`))}></img>
                                                            <p>{window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`))}</p>
                                                        </div>
                                                    )}

                                                {getValues(`funders.${i}.funderData.ftype`) !== '4' && (
                                                    <>
                                                        <label className="mb-2 bold mt-3">{t('VacantRightbar.comm')}</label>
                                                        <div className="form-check">
                                                            <input
                                                                {...register(`funders[${i}].funderData.fCheck`, {
                                                                    onChange: (e: any) => {
                                                                        props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                                    }
                                                                })}
                                                                name={`funders[${i}].funderData.fCheck` as const}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                defaultChecked={false}
                                                                id="checkboxsameas"
                                                            />
                                                            <label className="form-check-label">{t('VacantRightbar.same')}</label>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            {...register(`funders.${i}.commisionerData.cFunder`, {
                                                                onChange: (e: any) => {
                                                                    props.updateCData(e, i, 'commisionerData');
                                                                }
                                                            })}
                                                            name={`funders[${i}].commisionerData.cFunder` as const}
                                                            placeholder={`${t('VacantRightbar.cname')}*`} //@ts-ignore
                                                            className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cFunder ? 'is-invalid' : ''}`}
                                                        />

                                                        <input
                                                            type="text"
                                                            {...register(`funders.${i}.commisionerData.cEmailId`, {
                                                                onChange: (e: any) => {
                                                                    props.updateCData(e, i, 'commisionerData');
                                                                }
                                                            })}
                                                            name={`funders[${i}].commisionerData.cEmailId` as const}
                                                            placeholder={`${t('VacantRightbar.email')}*`}
                                                            //@ts-ignore
                                                            className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cEmailId ? 'is-invalid' : ''}`}
                                                        />

                                                        <div className="d-flex justify-content-between">
                                                            <input
                                                                type="text"
                                                                {...register(`funders.${i}.commisionerData.cContactNo`, {
                                                                    onChange: (e: any) => {
                                                                        props.updateCData(e, i, 'commisionerData');
                                                                    }
                                                                })}
                                                                name={`funders[${i}].commisionerData.cContactNo` as const}
                                                                placeholder={`${t('VacantRightbar.contact')}*`}
                                                                //@ts-ignore
                                                                className={`form-control white mb-2 ${errors.funders?.[i]?.commisionerData?.cContactNo ? 'is-invalid' : ''}`}
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            {...register(`funders.${i}.commisionerData.cAddress1`, {
                                                                onChange: (e: any) => {
                                                                    props.updateCData(e, i, 'commisionerData');
                                                                }
                                                            })}
                                                            name={`funders[${i}].commisionerData.cAddress1` as const}
                                                            placeholder={`${t('VacantRightbar.add1')}`}
                                                            //@ts-ignore
                                                            className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cAddress1 ? 'is-invalid' : ''}`}
                                                        />
                                                        <div className="d-flex justify-content-between">
                                                            <select
                                                                {...register(`funders.${i}.commisionerData.cCity`, {
                                                                    onChange: (e: any) => {
                                                                        props.updateCData(e, i, 'commisionerData');
                                                                    }
                                                                })}
                                                                name={`funders[${i}].commisionerData.cCity` as const}
                                                                id="ccity"
                                                                //@ts-ignore
                                                                className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cCity ? 'is-invalid' : ''}`}
                                                            >
                                                                <option value="">{t('VacantRightbar.county')}`</option>
                                                                {props.citylist &&
                                                                    props.citylist.data.map((cities: any) => {
                                                                        return (
                                                                            <option key={cities.key} value={cities.key}>
                                                                                {cities.value}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                            <input
                                                                type="text"
                                                                {...register(`funders.${i}.commisionerData.cPoBox`, {
                                                                    onChange: (e: any) => {
                                                                        props.updateCData(e, i, 'commisionerData');
                                                                    }
                                                                })}
                                                                name={`funders[${i}].commisionerData.cPoBox` as const}
                                                                placeholder={`${t('VacantRightbar.postal')}`}
                                                                //@ts-ignore
                                                                className={`form-control white mb-2 ms-2 ${errors.funders?.[i]?.commisionerData?.cPoBox ? 'is-invalid' : ''}`}
                                                            />
                                                        </div>
                                                        {StatusServiceDetail && DataServiceDetail.iconName !== ServiceTypeIconName.Hospital && getValues(`funders.${i}.funderData.ftype`) !== '1' && (
                                                            <>
                                                                <label className="mb-2 bold mt-3">{t('VacantRightbar.social')}</label>
                                                                <input
                                                                    type="text"
                                                                    {...register(`funders.${i}.commisionerData.sFunder`, {
                                                                        onChange: (e: any) => {
                                                                            props.updateSData(e, i, 'commisionerData');
                                                                        }
                                                                    })}
                                                                    name={`funders[${i}].commisionerData.sFunder` as const}
                                                                    placeholder={`${t('VacantRightbar.sname')}`}
                                                                    //@ts-ignore
                                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.sFunder ? 'is-invalid' : ''}`}
                                                                />

                                                                <input
                                                                    type="text"
                                                                    {...register(`funders.${i}.commisionerData.sEmailId`, {
                                                                        onChange: (e: any) => {
                                                                            props.updateSData(e, i, 'commisionerData');
                                                                        }
                                                                    })}
                                                                    name={`funders[${i}].commisionerData.sEmailId` as const}
                                                                    placeholder={`${t('VacantRightbar.email')}`}
                                                                    //@ts-ignore
                                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.sEmailId ? 'is-invalid' : ''}`}
                                                                />

                                                                <div className="d-flex justify-content-between">
                                                                    <input
                                                                        type="text"
                                                                        {...register(`funders.${i}.commisionerData.sContactNo`, {
                                                                            onChange: (e: any) => {
                                                                                props.updateSData(e, i, 'commisionerData');
                                                                            }
                                                                        })}
                                                                        name={`funders[${i}].commisionerData.sContactNo` as const}
                                                                        placeholder={`${t('VacantRightbar.contact')}`}
                                                                        //@ts-ignore
                                                                        className={`form-control white mb-2 ${errors.funders?.[i]?.commisionerData?.sContactNo ? 'is-invalid' : ''}`}
                                                                    />
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    {...register(`funders.${i}.commisionerData.sAddress1`, {
                                                                        onChange: (e: any) => {
                                                                            props.updateSData(e, i, 'commisionerData');
                                                                        }
                                                                    })}
                                                                    name={`funders[${i}].commisionerData.sAddress1` as const}
                                                                    placeholder={`${t('VacantRightbar.add1')}`}
                                                                    //@ts-ignore
                                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.sAddress1 ? 'is-invalid' : ''}`}
                                                                />

                                                                <div className="d-flex justify-content-between">
                                                                    <select
                                                                        {...register(`funders.${i}.commisionerData.sCity`, {
                                                                            onChange: (e: any) => {
                                                                                props.updateSData(e, i, 'commisionerData');
                                                                            }
                                                                        })}
                                                                        name={`funders[${i}].commisionerData.sCity` as const}
                                                                        id="sCity"
                                                                        //@ts-ignore
                                                                        className={`form-control white mb-2 ${errors.funders?.[i]?.commisionerData?.sCity ? 'is-invalid' : ''}`}
                                                                    >
                                                                        <option value="">{t('VacantRightbar.county')}</option>
                                                                        {props.citylist &&
                                                                            props.citylist.data.map((cities: any) => {
                                                                                return (
                                                                                    <option key={cities.key} value={cities.key}>
                                                                                        {cities.value}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>
                                                                    <input
                                                                        type="text"
                                                                        {...register(`funders.${i}.commisionerData.sPoBox`, {
                                                                            onChange: (e: any) => {
                                                                                props.updateSData(e, i, 'commisionerData');
                                                                            }
                                                                        })}
                                                                        name={`funders[${i}].commisionerData.sPoBox` as const}
                                                                        placeholder={`${t('VacantRightbar.postal')}`}
                                                                        //@ts-ignore
                                                                        className={`form-control white mb-2 ms-2 ${errors.funders?.[i]?.commisionerData?.cPoBox ? 'is-invalid' : ''}`}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}

                
                        {/* contract status will be display here */}
                                                <div className="d-flex mt-2 mb-3">
                                                    {/* {props.datapatient?.data?.isFromLeadPooling === null && 
                        <>
                         {props.datapatient !== '' && props.datapatient?.data?.contractDetails[i]?.digitalContractId === null && props.datapatient?.data?.isDraft !== 1  &&
                            <a onClick={()=>handleClickModal(i,props.datapatient?.data?.contractDetails[i])} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Generate_btn')} </a>
                        }
                        </>
                        } */}

                                                    {/* {props.datapatient?.data?.isFromLeadPooling &&
                       <>
                         {props.datapatient !== '' && props.datapatient?.data?.contractDetails[i]?.digitalContractId === null  && props.datapatient?.data?.admissionDetail?.admissionId !== 0 && props.datapatient?.data?.isDraft !== 1 &&
                            <a onClick={()=>handleClickModal(i,props.datapatient?.data?.contractDetails[i])} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Generate_btn')} </a>
                        }
                       </>
                       } */}

                                                    {/* {DataLeadInfo !== "" && DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract[i]?.digitalContractId !== 0 &&
                                                        (DataLeadInfo?.data?.contract[i]?.status === "Sent to Funder" || DataLeadInfo?.data?.contract[i]?.status === "In Progress with Funder") &&
                                                        <>
                                                            <a onClick={() => handleEditModal(i, DataLeadInfo?.data?.contract[i]?.digitalContractId, DataLeadInfo?.data?.contract[i]?.acdId)} className='btn  btn-primary btn-sm me-2'> {t('Generate_Contract.View_Contract_btn')} </a>
                                                            <a
                                                                href="#"
                                                                className="btn btn-secondary btn-sm me-2"
                                                                data-tooltip-id="my-tooltip-Contract"
                                                                data-tooltip-content="Void Contract"
                                                                onClick={() => handleVoidModal(i, DataLeadInfo?.data?.contract[i]?.digitalContractId)}
                                                            >
                                                                Void
                                                            </a>
                                                        </>


                                                    }
                                                    {DataLeadInfo !== "" && DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract[i]?.digitalContractId !== 0 &&
                                                        DataLeadInfo?.data?.contract[i]?.status === "" && <a onClick={() => handleEditModal(i, DataLeadInfo?.data?.contract[i]?.digitalContractId, DataLeadInfo?.data?.contract[i]?.acdId)} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Edit_Contract_btn')} </a>
                                                    }
                                                    {DataLeadInfo !== "" && DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract[i]?.digitalContractId !== 0 &&
                                                        DataLeadInfo?.data?.contract[i]?.status === "Signed by Commissioner" &&
                                                        <>
                                                            <a onClick={() => viewsign(i, DataLeadInfo?.data?.contract[i]?.digitalContractId)} className='btn btn-primary btn-sm me-2'>{t('Generate_Contract.View_Contract_Sign')} </a>
                                                            <a
                                                                href="#"
                                                                className="btn btn-secondary btn-sm me-2"
                                                                data-tooltip-id="my-tooltip-Contract"
                                                                data-tooltip-content="Void Contract"
                                                                onClick={() => handleVoidModal(i, DataLeadInfo?.data?.contract[i]?.digitalContractId)}
                                                            >
                                                                Void
                                                            </a>
                                                        </>

                                                    }

                                                    {DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract[i]?.digitalContractId !== 0 && DataLeadInfo?.data?.contract[i]?.status === 'Completed' && (
                                                        <>
                                                            <a
                                                                href="#"
                                                                className="btn btn-primary btn-sm me-2"
                                                                data-tooltip-id="my-tooltip-Contract"
                                                                data-tooltip-content="View Contract"
                                                                onClick={() => handleEditModal(i, DataLeadInfo?.data?.contract[i]?.digitalContractId, DataLeadInfo?.data?.contract[i]?.acdId)}
                                                            >
                                                                View Contract
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="btn btn-secondary btn-sm me-2"
                                                                data-tooltip-id="my-tooltip-Contract"
                                                                data-tooltip-content="Cancel"
                                                                onClick={() => handleCancelModal(i, DataLeadInfo?.data?.contract[i]?.digitalContractId)}
                                                            >
                                                                Cancel
                                                            </a>
                                                        </>
                                                    )} */}

                                                    {/* {DataLeadInfo?.data?.contract?.length>0 && DataLeadInfo?.data?.contract[i]?.status !== 'Completed' && DataLeadInfo?.data?.contract[i]?.status !== 'Voided' && ( */}
                                                    <>
                                                        <a onClick={() => openFileupload(i)} className="btn btn-secondary btn-sm">
                                                            <i className="fa-solid fa-paperclip"></i> {t('Generate_Contract.Attach_btn')}
                                                        </a>
                                                    </>
                                                    {/* )} */}
                                                </div>

                                                <div className="d-flex">
                                                    <div className="w-100">
                                                        {/* {getValues(`funders[${i}].funderData.isFilePond`) == false && (
                                                            <>
                                                                <div className="d-flex justify-content-between mt-2 ">
                                                                    <div className="input-readonly" data-tooltip-id="my-tooltip3" data-tooltip-content="Contract Document  ">
                                                                        <textarea
                                                                            rows={4}
                                                                            {...register(`funders[${i}].funderData.funderContract`, {})}
                                                                            name={`funders[${i}].funderData.funderContract` as const}
                                                                            onClick={(e: any) =>
                                                                                openpdf(e, getValues(`funders[${i}].funderData.filepath`), getValues(`funders[${i}].funderData.fLeadPoolingDetailsId`))
                                                                            }
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(i, e)}>
                                                                        <i className="fa-solid fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )} */}
                                                        {isFile && (
                                                            <>
                                                                <div style={{ pointerEvents: props.patientname != '' ? 'all' : 'none' }}>
                                                                    <Controller
                                                                        key={i}
                                                                        control={control}
                                                                        name={`funders[${i}].funderData.contractPDF` as const}
                                                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                                            return (
                                                                                <FilePond
                                                                                    files={file}
                                                                                    onupdatefiles={setFiles}
                                                                                    // @ts-ignore
                                                                                    allowFileSizeValidation={true}
                                                                                    maxFileSize={10000000}
                                                                                    allowFileTypeValidation={true}
                                                                                    acceptedFileTypes={[
                                                                                        'application/pdf',
                                                                                        'image/*',
                                                                                        'application/vnd.ms-excel',
                                                                                        'application/vnd.ms-powerpoint',
                                                                                        'application/msword',
                                                                                        'application/vnd.openxmlformats-officedocument.rdprocessingml.document',
                                                                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                                                    ]}
                                                                                    fileValidateTypeLabelExpectedTypes={''}
                                                                                    labelMaxFileSizeExceeded={'File is too large'}
                                                                                    name={'contractPDF'}
                                                                                    labelIdle={`${t('VacantRightbar.pdftitle1')}<span class="filepond--label-action"> ${t(
                                                                                        'VacantRightbar.pdftitle2'
                                                                                    )}</span>`}
                                                                                    server={{
                                                                                        url:
                                                                                            props.funderList[i]?.funderData?.fLeadPoolingDetailsId > 0
                                                                                                ? `${BASEURLDEV}${API_ENDPOINT.UploadDigiContractDocument}`
                                                                                                : `${BASEURLDEV}${API_ENDPOINT.UploadDocument}`,
                                                                                        process: {
                                                                                            headers: {
                                                                                                Authorization: `Bearer ${token}`
                                                                                            },
                                                                                            ondata: (formData) => {
                                                                                                if (props?.funderList[i]?.funderData?.fLeadPoolingDetailsId > 0) {
                                                                                                    formData.append('ReferralId', props.funderList[i].funderData.fReferralId);
                                                                                                    formData.append('AttachmentType', '1');
                                                                                                    formData.append('funderName', props.funderList[i].funderData.fFunderVal);
                                                                                                    formData.append('LeadPoolingDetails_Id', props.funderList[i].funderData.fLeadPoolingDetailsId);
                                                                                                    formData.append('Funder_Id', props.funderList[i].funderData.fFunderId);
                                                                                                } else {
                                                                                                    formData.append('patientName', props.patientname);
                                                                                                    formData.append('funderName', props.funderList[i].funderData.fFunderVal);
                                                                                                }
                                                                                                return formData;
                                                                                            },
                                                                                            //@ts-ignore
                                                                                            onload: (res: any) => {
                                                                                                const fileres = JSON.parse(res);
                                                                                                let filedata = fileres.data;
                                                                                                if (props.funderList[i].funderData?.fLeadPoolingDetailsId > 0) {
                                                                                                    filedata.filePath = filedata.documentPath;
                                                                                                    filedata.fileName = filedata.documentName;
                                                                                                }
                                                                                                //    setFileres(fileres.data.filePath);
                                                                                                console.log(filedata);
                                                                                                console.log(parentindex);

                                                                                                props.updateFData({ filedata, i: parentindex, op: 'add', j: '', data: '' });
                                                                                                setFiles([]);
                                                                                                setisFile(false);
                                                                                            }
                                                                                        },
                                                                                        remove: (source, load, error) => {
                                                                                            error('oh my goodness');
                                                                                            load();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p className="small mt-2 mb-2">
                                                                        <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note2')}
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {DataPatientDraftInfo === '' && props.funderList[i]?.funderData?.contractAttachmentModels?.length > 0 && (
                                                    <>
                                                        <p className="purple-text mt-1 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                                        {props.funderList[i].funderData?.contractAttachmentModels?.map((data: any, j: number) => {
                                                            return (
                                                                <>
                                                                    <div className="d-flex justify-content-between mt-2 contract-attachments">
                                                                        <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Contract Document ">
                                                                            {/* <textarea rows={1} {...register(`funderContract`, {})} name={`funderContract` as const} onClick={(e: any) => openpdf(e)} readOnly /> */}
                                                                            <a onClick={(e: any) => openpdf(e, i, j)}>{data.fileName}</a>
                                                                        </div>

                                                                        <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, i, j, data)}>
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                                {props.datapatient?.data?.isFromLeadPooling === null && props.funderList[i]?.funderData?.contractAttachmentModels?.length > 0 && (
                                                    <>
                                                        <p className="purple-text mt-1 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                                        {props.funderList[i]?.funderData?.contractAttachmentModels?.map((data: any, j: number) => {
                                                            return (
                                                                <>
                                                                    <div className="d-flex justify-content-between mt-2 contract-attachments">
                                                                        <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Contract Document ">
                                                                            {/* <textarea rows={1} {...register(`funderContract`, {})} name={`funderContract` as const} onClick={(e: any) => openpdf(e)} readOnly /> */}
                                                                            <a onClick={(e: any) => openpdf(e, i, j)}>{data.fileName}</a>
                                                                        </div>

                                                                        <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, i, j, data)}>
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                )}

                                                {props.datapatient?.data?.isFromLeadPooling && props.funderList[i]?.funderData?.attachments?.length > 0 && (
                                                    <>
                                                        <p className="purple-text mt-3 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                                        {props.funderList[i].funderData?.attachments?.map((data: any, j: number) => {
                                                            return (
                                                                <>
                                                                    <div className="d-flex justify-content-between  mt-2">
                                                                        <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Contract Document ">
                                                                            {/* <textarea rows={1} {...register(`funderContract`, {})} name={`funderContract` as const} onClick={(e: any) => openpdf(e)} readOnly /> */}
                                                                            <textarea rows={1} value={data.documentName || data.fileName} onClick={(e: any) => openpdf(e, i, j)} readOnly />
                                                                        </div>

                                                                        <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, i, j, data)}>
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </form>

            {DataLeadInfo?.data?.contract?.length > 0 && DataPatientDraftInfo !== '' && (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId === 0 || (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== "Voided" && DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== "Cancelled"))&& DataPatientDraftInfo?.data?.admissionDetail?.isReserved !== 1 && props.datapatient?.data?.admissionDetail?.admissionId !== 0 &&
            <>
            <div className="accordion accordion-flush mt-3" id="newcontractAccordion">
                <div className="accordion-item white mt-0">
                    <h2 className="accordion-header" id="contract1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#newContract" aria-expanded="false" aria-controls="#newContract">
                            Generate Contract
                        </button>
                    </h2>
                    <div id="newContract" className="accordion-collapse collapse" aria-labelledby="contract1" data-bs-parent="#newcontractAccordion">
                        <div className="accordion-body">
                        {props.datapatient?.data?.isFromLeadPooling === null &&
                            <>
                                {console.log(props.datapatient, props.datapatient?.data?.contractDetails?.filter((el: any) => el.docusignStatus !== 'Voided' && el.docusignStatus !== 'Cancelled')[0]?.digitalContractId === null)}
                                {console.log(props.datapatient?.data?.isDraft !== 1)}
                                {props.datapatient !== '' && props.datapatient?.data?.contractDetails?.filter((el: any) => el.docusignStatus !== 'Voided' && el.docusignStatus !== 'Cancelled')[0]?.digitalContractId === null && props.datapatient?.data?.isDraft !== 1 &&
                                    <a onClick={() => handleClickModal(props.datapatient?.data?.contractDetails)} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Generate_btn')} </a>
                                }
                            </>
                        }

                        {props.datapatient?.data?.isFromLeadPooling &&
                       <>
                         {props.datapatient !== '' && props.datapatient?.data?.contractDetails?.filter((el: any) => el.docusignStatus !== 'Voided' && el.docusignStatus !== 'Cancelled')[0]?.digitalContractId === null && props.datapatient?.data?.admissionDetail?.admissionId !== 0 && props.datapatient?.data?.isDraft !== 1 &&
                            <a onClick={()=>handleClickModal(props.datapatient?.data?.contractDetails)} className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Generate_btn')} </a>
                        }
                       </>
                       }

                        {DataLeadInfo !== '' &&
                            DataLeadInfo?.data?.contract?.length > 0 &&
                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                            (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Sent to Funder' || DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'In Progress with Funder') && (
                                <>
                                    <a
                                        onClick={() => handleEditModal(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.acdId)}
                                        className="btn  btn-primary btn-sm me-2"
                                    >
                                        {' '}
                                        {t('Generate_Contract.View_Contract_btn')}{' '}
                                    </a>
                                    <a
                                        href="#"
                                        className="btn btn-secondary btn-sm me-2"
                                        data-tooltip-id="my-tooltip-Contract"
                                        data-tooltip-content="Void Contract"
                                        onClick={() => handleVoidModal(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId)}
                                    >
                                        Void
                                    </a>
                                </>
                            )}
                        {DataLeadInfo !== '' &&
                            DataLeadInfo?.data?.contract?.length > 0 &&
                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === '' && (
                                <a
                                    onClick={() => handleEditModal(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.acdId)}
                                    className="btn btn-primary btn-sm me-2"
                                >
                                    <i className="fa-solid fa-square-pen me-1"></i> {t('Generate_Contract.Edit_Contract_btn')}{' '}
                                </a>
                            )}
                        {DataLeadInfo !== '' &&
                            DataLeadInfo?.data?.contract?.length > 0 &&
                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Signed by Commissioner' && (
                                <>
                                    <a onClick={() => viewsign(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId)} className="btn btn-primary btn-sm me-2">
                                        {t('Generate_Contract.View_Contract_Sign')}{' '}
                                    </a>
                                    <a
                                        href="#"
                                        className="btn btn-secondary btn-sm me-2"
                                        data-tooltip-id="my-tooltip-Contract"
                                        data-tooltip-content="Void Contract"
                                        onClick={() => handleVoidModal(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId)}
                                    >
                                        Void
                                    </a>
                                </>
                            )}

                        {DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 && DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Completed' && (
                            <>
                                <a
                                    href="#"
                                    className="btn btn-primary btn-sm me-2"
                                    data-tooltip-id="my-tooltip-Contract"
                                    data-tooltip-content="View Contract"
                                    onClick={() => handleEditModal(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.acdId)}
                                >
                                    View Contract
                                </a>
                                <a
                                    href="#"
                                    className="btn btn-secondary btn-sm me-2"
                                    data-tooltip-id="my-tooltip-Contract"
                                    data-tooltip-content="Cancel"
                                    onClick={() => handleCancelModal(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId)}
                                >
                                    Cancel
                                </a>
                            </>
                        )}

                        <div>
                        {DataLeadInfo?.data?.contract?.length > 0 &&
                        
                                                    DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Sent to Funder' &&
                                                    DataLeadInfo?.data?.contract?.DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled' && (
                                                        <>
                                                            <div className="yellow-box">Contract Sent to Funder</div>
                                                        </>
                                                    )}
                                                {DataLeadInfo?.data?.contract?.length > 0 &&
                                                    DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Signed by Commissioner' &&
                                                    DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled' && (
                                                        <>
                                                            <div className="yellow-box">Contract is signed by Commissioner</div>
                                                        </>
                                                    )}
                                                {DataLeadInfo?.data?.contract?.length > 0 &&
                                                    DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'In Progress with Funder' &&
                                                    DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled' && (
                                                        <>
                                                            <div className="yellow-box">Contract is in progress with Funder</div>
                                                        </>
                                                    )}
                                                {DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Completed' && (
                                                    <>
                                                        <div className="green-box">Contract is completed</div>
                                                    </>
                                                )}

                                             {/* need to discuss and then make change */}

                                                {DataLeadInfo?.data?.contract?.length > 0 &&
                                                    DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status !== '' &&
                                                    DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status === 'Cancelled' && <div className="red-box">Contract is Cancelled</div>}

                                                {DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status !== '' && DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status === 'Voided' && (
                                                    <div className="red-box">Contract is Voided</div>
                                                )}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
            <Tooltip id="my-tooltip3" className="my-tooltip" />
            <Tooltip id="my-tooltip-add-funder" className="my-tooltip" />

            <RenderModalContainer>
                {showModal && (
                    <GenerateContractContainer
                        findex={index}
                        onClose={toggleModal}
                        getval={props.getformval}
                        combinationId={combinationId}
                        cdata={cdata}
                        send={props.send}
                        pname={'admission'}
                    />
                )}

                {showCancelModal && <RejectContainer onClose={toggle} leadid={ccdata} mname={'admission'} title={ctitle} />}
            </RenderModalContainer>

            <RenderModalContainer>
                {showNoteModal && (
                    <NoteContainer
                        onClose={toggleNote}
                        title={props.funderList[fIndex]?.funderData?.fFunderVal != null ? props.funderList[fIndex]?.funderData?.fFunderVal + "'s Notes" : t('VacantRightbar.funder_note')}
                        setFunderNote={funderNote}
                        notebody={updateNote}
                        index={fIndex}
                        isReadonly={false}
                        isEditing={getValues(`funders[${fIndex}].funderData.isNoteEdited`)}
                    />
                )}
            </RenderModalContainer>
        </>
    );
};

export default FunderListView;
