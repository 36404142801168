import React, { useState, useEffect } from "react";
import MaintenanceReleaseTabView from "./MaintenanceReleaseTabView";
import { releaseBedMaintenanceService } from "../../../../../../../service/maintenance-service";
import swal from "sweetalert";
import warning from '../../../../../../../assets/images/warning.png'
import eventEmitter from "../../../../../../../utils/eventEmitter";

const MaintenanceReleaseTabContainer = (props: any) => {
    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseMaintenanceSideMenuEvent');
    }

    const onSubmitHandler = async(data: any) => {
        eventEmitter.emit('eventCloseMaintenanceSideMenuEventOff');
        data.bedId = props.bedDetailData?.bedMaintenanceDetail?.bedId
        if(data.bedId !== undefined && data.bedId !== '') {
            data.releaseDate = window.$utils.dateFormatDDMMYYYY(data.releaseDate)
            swal({
                title: '',
                text: `Are you sure you want to release Bed ${props.bedDetailData?.bedMaintenanceDetail?.bedName} from maintenance?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    const response = await releaseBedMaintenanceService(data)
                    if(response.status === true) {
                        swal(`Bed ${props.bedDetailData?.bedMaintenanceDetail?.bedName} is released from Maintenance.`, {
                            icon: 'success'
                        });
                        props.closeSideMenu(true)
                        eventEmitter.emit('eventGetBedInformation');
                        eventEmitter.emit('eventGetWardInformation');
                        eventEmitter.emit('onAdmitReservePatient');
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    }

    return <MaintenanceReleaseTabView bedDetailData={props.bedDetailData} onSubmitHandler={onSubmitHandler} updateData={updateData} />
}

export default MaintenanceReleaseTabContainer;