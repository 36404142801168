import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BedInformationModel } from '../../utils/model';

const initialState: BedInformationModel = {
    StatusBedInfo: false,
    DataBedInfo: '',
    MessageBedInfo: '',
    ErrorBedInfo: ''
};

export const bedInformationSlice = createSlice({
    name: 'bedInformation',
    initialState,
    reducers: {
        setBedInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusBedInfo = true;
            state.DataBedInfo = payload;
            state.MessageBedInfo = 'Success';
        },
        errorBedInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusBedInfo = false;
            state.DataBedInfo = [];
            state.MessageBedInfo = 'Failed';
            state.ErrorBedInfo = payload;
        },
        removeBedInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusBedInfo = false;
            state.DataBedInfo = '';
            state.MessageBedInfo = '';
            state.ErrorBedInfo = '';
        }
    },
    extraReducers: {}
});

export const { setBedInformationAction, errorBedInformationAction, removeBedInformationAction } = bedInformationSlice.actions;

export default bedInformationSlice.reducer;
