import React from 'react';
import AdditionalInfoView from './AdditionalInfoView';

const AdditionalInfoContainer = (props: any) => {
    return (
        <div>
            <AdditionalInfoView orgName={props.orgName} additionalInfoFormValid={props.additionalInfoFormValid} additionalInfoDataChange={props.additionalInfoDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default AdditionalInfoContainer;