import React, { useEffect, useState } from 'react';
import AddFunderView from './AddFunderView';
import { getCityData, getFunderData, getPatientTypeData } from '../../../../../../../service/dashboard-service';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../../store';
import swal from 'sweetalert';
import warning from '../../../../../../../assets/images/warning.png'
import { addUpdateFunder } from '../../../../../../../service/contract-service';
import { getLeaddata } from '../../../../../../../service/leads';
const AddFunderContainer = (props: any) => {
    // getFunderData(0);

    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    let [contractData, setcontractData] = useState<any>('');
    let [commissionerData, setcommissionerData] = useState<any>('');
    let [ListData, setlistdata] = useState<any>([]);
    let [FunderData, setFunderData] = useState<any>([]);
    let [CityData, setCityData] = useState<any>([]);
    let [isContracterror, setContracterror] = useState<any>(false);
    let [isCheck, setCheck] = useState<any>(true);
    let [fundertype, setfundertype] = useState<any>('');
    let [changetype, setchangetype] = useState<any>(false);
    let [editdone, seteditdone] = useState<boolean>(false);
    // let [isDisplay, setDisplay] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        // props.contractdata({  ListData });
        
    }, [ListData]);

    useEffect(() => {
        if (DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0) {
            setFunderData(DataFunderInfo.data);
        }
    }, [DataFunderInfo]);

    useEffect(() => {
        if (DataCityInfo.data !== undefined && DataCityInfo.data.length > 0) {
            setCityData(DataCityInfo.data);
        }
    }, [DataCityInfo]);

    useEffect(()=>{
        // if(props.funderdata !== ""){
            
            setfundertype(props.funderdata.funderTypeId);    
            if(props.funderdata.funderTypeId !== 0 || props.funderdata.funderTypeId !== 4){        
            setcontractData({
                ...contractData,
                ['ffunderName']: props.funderdata.funderName,
                ['fFunder']: props.funderdata.funderId,
                ['fEmailId']: props.funderdata.funderEmail || '',
                ['fContactNo']: props.funderdata.contactNo || '',
                ['fAddress1']: props.funderdata.address1 || '',
                ['fCity']: props.funderdata.countyId?.toString() || '0',
                ['fPoBox']: props.funderdata.postalCode || '',
                ['fpo']:props.funderdata.poReference,
                ['ftype']: props.funderdata.funderTypeId === 0 ? '4' : props.funderdata.funderTypeId?.toString()
            })
                setcommissionerData({
                    ...commissionerData,
                ['cFunder']: props.funderdata.commissionerName || '',
                ['cEmailId']: props.funderdata.commissionerEmail || '',
                ['cContactNo']: props.funderdata.commissionerPhoneNo || '',
                ['cAddress1']: props.funderdata.commissionerAddressLine1 || '',
                ['cCity']: props.funderdata.commissionerCountyId?.toString() || 0,
                ['cPoBox']: props.funderdata.commissionerPostalCode || '',
                ['sFunder']: props.funderdata.socialWorkerName || '',
                ['sEmailId']: props.funderdata.socialWorkerEmail || '',
                ['sContactNo']: props.funderdata.socialWorkerPhoneNo || '',
                ['sAddress1']: props.funderdata.socialWorkerAddressLine1 || '',
                ['sCity']: props.funderdata.socialWorkerCountyId?.toString() || 0,
                ['sPoBox']: props.funderdata.socialWorkerPostalCode || '',
                
            });
        }
    },[props.funderdata])

    const updateData = (e: any) => {
        
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                setcontractData({
                    ...contractData
                });

                if (fundertype !== '4') {
                    const fundername = FunderData.filter((val: any) => val.key == contractData?.fFunder);
                    setcommissionerData({
                        ...commissionerData,
                        cEmailId: contractData?.fEmailId || '',
                        cFunder: fundername[0]?.value || '',
                        cContactNo: contractData?.fContactNo || '',
                        cAddress1: contractData?.fAddress1 || '',
                        cCity: contractData?.fCity || '0',
                        cPoBox: contractData?.fPoBox || ''
                    });
                } else if (fundertype === '4') {
                    setcommissionerData({
                        ...commissionerData,
                        cEmailId: contractData?.fEmailId || '',
                        cFunder: contractData?.fFunder || '',
                        cContactNo: contractData?.fContactNo || '',
                        cAddress1: contractData?.fAddress1 || '',
                        cCity: contractData?.fCity || '0',
                        cPoBox: contractData?.fPoBox || ''
                    });
                }
            } else {
                setcontractData({
                    ...contractData,
                    [e.target.id]: e.target.checked
                });
                setcommissionerData({});
            }
        } else if (e.target.type === 'radio') {
            setchangetype(false);
            setTimeout(() => {
                setchangetype(true);
            }, 200);
            setCheck(true);
            setTimeout(() => {
                setCheck(false);
            }, 100);
            setcontractData({
                ...contractData,
                ftype: e.target.value
            });
        } else {
                if (e.target.id == 'Funder') {
                    const fundername = FunderData.filter((val: any) => val.key == e.target.value);
                    setfundertype(fundername[0].funderTypeId);
                    setcontractData({
                        ...contractData,
                        [e.target.name]: e.target.value,
                        ['fFunderVal']: fundername[0].value,
                        ['fEmailId']: fundername[0].funderEmail || '',
                        ['fContactNo']: fundername[0].funderPhoneNo || '',
                        ['fAddress1']: fundername[0].funderAddressLine1 || '',
                        ['fCity']: fundername[0].funderCityId?.toString() || '0',
                        ['fPoBox']: fundername[0].funderPobox || '',
                        ['ftype']: fundername[0].funderTypeId?.toString(),
                        ['farray']: DataFunderInfo?.data
                    });
                    if (Object.keys(commissionerData).length > 0) {
                        setcommissionerData({});
                        setCheck(true);
                        setTimeout(() => {
                            setCheck(false);
                        }, 100);
                    }
                } else {
                    
                    setcontractData({
                        ...contractData,
                        [e.target.name]: e.target.value
                    });
                }
            // }
          
        }
    };
    useEffect(() => {
        setfundertype(contractData?.ftype);
    }, [contractData]);

    useEffect(() => {
        if (fundertype) {
            getFilterFunder();
            if(fundertype === '4'){
                let isexists;
                if(DataLeadInfo?.data?.contract?.length > 0){
                isexists = [...DataLeadInfo?.data?.contract].some((val: any,index:number) =>{
                    
                    if (val.funderName === DataLeadInfo?.data?.serviceUserDetails?.serviceUserName){
                        if(val.status !== ""){
                            if(val.status === 'Voided' || val.status === 'Cancelled'){
                                return false
                            } else{
                                return true
                            }
                        } else{
                            return true
                        }
                    } else {
                        return false;
                    }
                } );
                }
                
                if (isexists) {
                    swal('Funder is already added', {
                        icon: 'error',
                        dangerMode: true
                    });
                } else {
                setcontractData({
                    ...contractData,
                    ['fFunderVal'] : DataLeadInfo?.data?.serviceUserDetails?.serviceUserName || '',
                    ['fEmailId']:  DataLeadInfo?.data?.serviceUserDetails?.email !== "" ? DataLeadInfo?.data?.serviceUserDetails?.email :props.funderdata.funderEmail,
                    ['fFunder']:DataLeadInfo?.data?.serviceUserDetails?.serviceUserName || '',
                    ['fContactNo']: DataLeadInfo?.data?.serviceUserDetails?.contactNo !== "" ? DataLeadInfo?.data?.serviceUserDetails?.contactNo : props.funderdata.contactNo,
                    ['fAddress1']: DataLeadInfo?.data?.serviceUserDetails?.address1 !== "" ? DataLeadInfo?.data?.serviceUserDetails?.address1 : props.funderdata.address1,
                    ['fCity']:  (DataLeadInfo?.data?.serviceUserDetails?.countyId !== 0 && DataLeadInfo?.data?.serviceUserDetails?.countyId !== null) ? DataLeadInfo?.data?.serviceUserDetails?.countyId : props.funderdata.countyId,
                    ['fPoBox']: DataLeadInfo?.data?.serviceUserDetails?.postalCode !== "" ? DataLeadInfo?.data?.serviceUserDetails?.postalCode : props.funderdata.postalCode,
                })
            }
            }
        }
        async function getFilterFunder() {
            await getFunderData(fundertype);
        }
    }, [fundertype]);

    const updateCData = (e: any) => {
        setcommissionerData({
            ...commissionerData,
            [e.target.name]: e.target.value
        });
    };
    const updateSData = (e: any) => {
        setcommissionerData({
            ...commissionerData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        
    }, [commissionerData]);

    const updateFData = (e: any) => {
        
        setcontractData({
            ...contractData,
            ['file']: e.file,
            ['filepath']: e.filedata.filePath,
            ['filename']: e.filedata.fileName,
            ['isFilePond']: false
        });
    };

    const updatefilepond = () => {
        setcontractData({
            ...contractData,
            ['file']: '',
            ['filepath']: '',
            ['filename']: '',
            ['isFilePond']: ''
        });
    };

    const addFunder = () => {
        swal({
            title: '',
            text: `Are you sure you want to add funder ${contractData.fFunderVal}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                let obj = {
                    funderData: contractData,
                    commisionerData: commissionerData
                };
                // && (DataLeadInfo?.data?.contract[index].status !== "Voided" || DataLeadInfo?.data?.contract[index].status !== "Cancelled")
                let isexists;
                if(DataLeadInfo?.data?.contract?.length > 0){
                isexists = [...DataLeadInfo?.data?.contract].some((val: any,index:number) =>{
                    
                    if (val.funderId === Number(contractData.fFunder)){
                        if(val.status !== ""){
                            if(val.status === 'Voided' || val.status === 'Cancelled'){
                                return false
                            } else{
                                return true
                            }
                        } else{
                            return true
                        }
                    } else {
                        return false;
                    }
                } );
                }
                
                if (isexists) {
                    swal('Funder is already added', {
                        icon: 'error',
                        dangerMode: true
                    });
                } else {
                    funderUpdate(obj,'add');
                }
            }
        })
        
       
    };

    const updateFunder = () => {
                
        swal({
            title: '',
            text: `Are you sure you want to update funder - ${contractData.ffunderName}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                let obj = {
                    funderData: contractData,
                    commisionerData: commissionerData
                };
                    funderUpdate(obj,'update');
            }
        })
       
    };
    

    async function funderUpdate(data:any,op:any)  {
        
        seteditdone(false);
        if(op === "add"){
            props.isdone(false);
        }
        
        if(data?.funderData?.ftype === "4"){
            let addpayload = {
                leadId: DataLeadInfo?.data?.leadId,
                leadFunderDetailsList: [
                    {
                        funderId: data?.funderData?.ftype === "4" ? null : Number(data?.funderData?.fFunder),
                        funderEmail: data?.funderData?.fEmailId,
                        funderPhoneNo: data?.funderData?.fContactNo,
                        funderAddressLine1: data?.funderData?.fAddress1,
                        funderCountyId: Number(data?.funderData?.fCity),
                        funderPostalCode: data?.funderData?.fPoBox,
                        funderName: data?.funderData?.ftype === "4" ? data?.funderData?.fFunder : '',
                        funderCountyName: '',
                        isSelfFunded: data?.funderData?.ftype === "4" ? 1 : 0,
                        funderTypeId: Number(data?.funderData?.ftype),
                        funderTypeName: '',
                        suid: data?.funderData?.fsuid,
                        note: data?.funderData?.fnote,
                        pO_Reference:data?.funderData?.fpo
                    }
                ],
                isEdit: op === "update" ? 1 : 0
            };
           
           const res = await addUpdateFunder(addpayload);
    
           if(res.status){
            swal(res.message, {
                icon: 'success'
            });  
            seteditdone(true);
            if(op === "add"){
                props.isdone(true);
            }
            getLeaddata(DataLeadInfo?.data?.leadId, 6);
          }
          else{
            swal(res.message, {
                icon: 'error'
            });
           seteditdone(false);
           if(op === "add"){
            props.isdone(false);
        }
          }
        } else if(data?.funderData?.ftype === "1"){
            let addpayload = {
                leadId: DataLeadInfo?.data?.leadId,
                leadFunderDetailsList: [
                    {
                        funderId: data?.funderData?.ftype === "4" ? null : Number(data?.funderData?.fFunder),
                        funderEmail: data?.funderData?.fEmailId,
                        funderPhoneNo: data?.funderData?.fContactNo,
                        funderAddressLine1: data?.funderData?.fAddress1,
                        funderCountyId: Number(data?.funderData?.fCity),
                        funderPostalCode: data?.funderData?.fPoBox,
                        commissionerName: data?.commisionerData?.cFunder,
                        commissionerEmail: data?.commisionerData?.cEmailId,
                        commissionerPhoneNo: data?.commisionerData?.cContactNo,
                        commissionerAddressLine1: data?.commisionerData?.cAddress1,
                        commissionerCountyId: Number(data?.commisionerData?.cCity),
                        commissionerPostalCode: data?.commisionerData?.cPoBox,
                        commissionerCountyName: '',
                        funderName: data?.funderData?.ftype === "4" ? data?.funderData?.fFunder : '',
                        funderCountyName: '',
                        isSelfFunded: data?.funderData?.ftype === "4" ? 1 : 0,
                        funderTypeId: Number(data?.funderData?.ftype),
                        funderTypeName: '',
                        suid: data?.funderData?.fsuid,
                        note: data?.funderData?.fnote,
                        pO_Reference:data?.funderData?.fpo
                    }
                ],
                isEdit: op === "update" ? 1 : 0
            };
           
           const res = await addUpdateFunder(addpayload);
    
           if(res.status){
            swal(res.message, {
                icon: 'success'
            });  
            seteditdone(true);
            if(op === "add"){
                props.isdone(true);
            }
            getLeaddata(DataLeadInfo?.data?.leadId, 6);
          }
          else{
            swal(res.message, {
                icon: 'error'
            });
           seteditdone(false);
           if(op === "add"){
            props.isdone(false);
        }
          }
        }else{
            let addpayload = {
                leadId: DataLeadInfo?.data?.leadId,
                leadFunderDetailsList: [
                    {
                        funderId: data?.funderData?.ftype === "4" ? null : Number(data?.funderData?.fFunder),
                        funderEmail: data?.funderData?.fEmailId,
                        funderPhoneNo: data?.funderData?.fContactNo,
                        funderAddressLine1: data?.funderData?.fAddress1,
                        funderCountyId: Number(data?.funderData?.fCity),
                        funderPostalCode: data?.funderData?.fPoBox,
                        commissionerName: data?.commisionerData?.cFunder,
                        commissionerEmail: data?.commisionerData?.cEmailId,
                        commissionerPhoneNo: data?.commisionerData?.cContactNo,
                        commissionerAddressLine1: data?.commisionerData?.cAddress1,
                        commissionerCountyId: Number(data?.commisionerData?.cCity),
                        commissionerPostalCode: data?.commisionerData?.cPoBox,
                        socialWorkerName: data?.commisionerData?.sFunder,
                        socialWorkerEmailId: data?.commisionerData?.sEmailId,
                        socialWorkerContactNo: data?.commisionerData?.sContactNo,
                        socialWorkerAddress: data?.commisionerData?.sAddress1,
                        socialWorkerCityId: (data?.commisionerData?.sCity !== "" && data?.commisionerData?.sCity !== null && Number(data?.commisionerData?.sCity) !== 0) ? Number(data?.commisionerData?.sCity) : null,
                        socialWorkerPobox: data?.commisionerData?.sPoBox,
                        commissionerCountyName: '',
    
                        funderName: data?.funderData?.ftype === "4" ? data?.funderData?.fFunder : '',
                        funderCountyName: '',
                        isSelfFunded: data?.funderData?.ftype === "4" ? 1 : 0,
                        funderTypeId: Number(data?.funderData?.ftype),
                        funderTypeName: '',
                        suid: data?.funderData?.fsuid,
                        note: data?.funderData?.fnote,
                        pO_Reference:data?.funderData?.fpo
                    }
                ],
                isEdit: op === "update" ? 1 : 0
            };
           
           const res = await addUpdateFunder(addpayload);
    
           if(res.status){
            swal(res.message, {
                icon: 'success'
            });  
            seteditdone(true);
            if(op === "add"){
                props.isdone(true);
            }
            getLeaddata(DataLeadInfo?.data?.leadId, 6);
          }
          else{
            swal(res.message, {
                icon: 'error'
            });
           seteditdone(false);
           if(op === "add"){
            props.isdone(false);
        }
          }
        }
      
    };
    

    async function funderAdd(data:any)  {
        
        // seteditdone(false);
        let addpayload = {
            leadId: DataLeadInfo?.data?.leadId,
            leadFunderDetailsList: [
                {
                    funderId: data?.funderData?.ftype === "4" ? null : Number(data?.funderData?.fFunder),
                    funderEmail: data?.funderData?.fEmailId,
                    funderPhoneNo: data?.funderData?.fContactNo,
                    funderAddressLine1: data?.funderData?.fAddress1,
                    funderCountyId: Number(data?.funderData?.fCity),
                    funderPostalCode: data?.funderData?.fPoBox,
                    commissionerName: data?.commisionerData?.cFunder,
                    commissionerEmail: data?.commisionerData?.cEmailId,
                    commissionerPhoneNo: data?.commisionerData?.cContactNo,
                    commissionerAddressLine1: data?.commisionerData?.cAddress1,
                    commissionerCountyId: Number(data?.commisionerData?.cCity),
                    commissionerPostalCode: data?.commisionerData?.cPoBox,
                    socialWorkerName: data?.commisionerData?.sFunder,
                    socialWorkerEmailId: data?.commisionerData?.sEmailId,
                    socialWorkerContactNo: data?.commisionerData?.sContactNo,
                    socialWorkerAddress: data?.commisionerData?.sAddress1,
                    socialWorkerCityId: data?.commisionerData?.sCity !== "" && Number(data?.commisionerData?.sCity) !== 0? Number(data?.commisionerData?.sCity) : null,
                    socialWorkerPobox: data?.commisionerData?.sPoBox,
                    funderName: data?.funderData?.ftype === "4" ? data?.funderData?.fFunder : '',
                    funderCountyName: '',
                    commissionerCountyName: '',
                    isSelfFunded: data?.funderData?.ftype === "4" ? 1 : 0,
                    funderTypeId: Number(data?.funderData?.ftype),
                    funderTypeName: ''
                }
            ],
            isEdit: 0
        };

       const res = await addUpdateFunder(addpayload);
       if(res.status){
        
        // seteditdone(true);
        // getLeaddata(DataLeadInfo?.data?.leadId, 6);
       }
       else{
        // seteditdone(false);
       }
    };

    const isadd = (isadd: boolean) => {
        // setDisplay(isadd);
        if (!isadd) {
            props.getcontracterror(true);
        }
        if (isadd) {
            props.getcontracterror(false);
        }
    };

    const updatedfunderlist = (updatedList: any) => {
        
        setlistdata(updatedList);
    };

    const getcontracterror = (iserror: boolean) => {
        setContracterror(iserror);
    };

    const deletefunderlist = (data: any) => {
        props.deletefunderlist(data);
    };
    return (
        <div>
            <AddFunderView
                condata={contractData?.fFunderVal}
                patientname={props.patientname}
                updatefilepond={updatefilepond}
                updateData={updateData}
                commissionerData={commissionerData}
                updateCData={updateCData}
                updateSData={updateSData}
                citylist={DataCityInfo}
                updateFData={updateFData}
                funderlist={DataFunderInfo}
                getcontracterror={getcontracterror}
                addFunder={addFunder}
                updateFunder={updateFunder}
                // isdisplay={isDisplay}
                isCheck={isCheck}
                fundertype={fundertype}
                changetype={changetype}
                selfData={props.selfData}
                isedit={props.isedit}
                funderdata={props.funderdata}
                // iseditdone={editdone}
            />
        </div>
    );
};

export default AddFunderContainer;