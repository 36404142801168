import React, {useEffect} from 'react';
import ServiceReferralDetailsContainer from './ServiceReferralDetails/ServiceReferralDetailsContainer';
import ServiceReferralTableContainer from './ServiceReferralTable/ServiceReferralTableContainer';
import { removeGridFilterStateAction } from '../../../store/general/gridFilterStateReducer';
import { useDispatch } from 'react-redux';

const ServiceManagerView = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(removeGridFilterStateAction());
        }
    }, [])
    return (
        <div className='container-fluid'>
             <ServiceReferralDetailsContainer />
            <ServiceReferralTableContainer />
        </div>
    );
};

export default ServiceManagerView;