import React, { useEffect, useState } from 'react';
import folderIcon from "../../../assets/images/folder.svg";
import verticaldots from "../../../assets/images/vertical-dots.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setSaveForLater } from '../../../store/SaveForLater/saveForLaterDrawerReducer';
import { setSaveForLaterWishlist } from '../../../store/SaveForLater/saveForLaterServicesWishlistReducer';
import { DeleteFolder, GetSaveForLaterFolderList } from '../../../service/portal-services';
import swal from "sweetalert";
import warning from '../../../assets/images/warning.png';
import { RootState } from '../../../store';
import eventEmitter from '../../../utils/eventEmitter';


const SaveForLaterView = () => {
    const dispatch = useDispatch();
    const [saveForLaterFolder, SetSaveForLaterFolder] = useState<any>(null)
    const [searchValue,setSearchValue] = useState<string>("")

    const handleCloseDrawer = () => {
        dispatch(setSaveForLater({
            saveForLaterDrawerOpen: false
        }))
    }
    const handleFolderClick = (folderId:any) => {
        dispatch(setSaveForLaterWishlist({
            saveForLaterWishlistOpen: true,
            folderId : folderId
        }));
        dispatch(setSaveForLater({
            saveForLaterDrawerOpen: false
        }))

    }
    useEffect(() => {
        eventEmitter.on('GetFolderDetailsList', GetFolderDetailsList);
        return () => {
            eventEmitter.off('GetFolderDetailsList', GetFolderDetailsList);
        };
    });

    useEffect(() => {
        GetFolderDetailsList()
    }, [searchValue])

    const GetFolderDetailsList = async () => {
        const payload = {
            searchValue : searchValue
        }
        const result = await GetSaveForLaterFolderList(payload)
        SetSaveForLaterFolder(result?.data)
    }
  
    const handleFolderDelete = (folderid: any) => {
        if(folderid) {
            swal({
                title: '',
                text: 'Are you sure you want to delete the folder?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if(confirm) {
                    const response = await DeleteFolder(folderid)
                    if(response.status === true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                    const payload = {
                        searchValue : searchValue
                    }
                    const result = await GetSaveForLaterFolderList(payload)
                    SetSaveForLaterFolder(result?.data)
                }
            });
        }
    }
    const handleSearchValue = (e:any)=>{
        setSearchValue(e.target.value)
    }
    return (
        <div className='saveforlater'>
            <button title="close" className="close_right_content" onClick={handleCloseDrawer}><i className="fa-solid fa-xmark"></i> </button>
            <div className='d-flex justify-content-between align-items-center'>
                <h3>Save for Later</h3>
            </div>

            <div>
                <div className="mt-2 position-relative"><input type="text" placeholder="Search" name="leadSmartSearch" className="form-control white search-invoice w-100" value={searchValue} onChange={(e)=>handleSearchValue(e)} />
                    <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                </div>
            </div>
            

            <div>
                {saveForLaterFolder && saveForLaterFolder?.length > 0 &&
                    saveForLaterFolder?.map((folder: any) => {
                        return (
                            <div className="folder-box mt-2">
                                <div className='folder-box-content d-flex align-items-center' onClick={()=>handleFolderClick(folder?.saveForLaterFolderId)}>
                                    <div className='folder-box-left cursor-pointer'>
                                        <span><img src={folderIcon} className='img-fluid' alt="list-image" /></span>
                                        <span className='d-flex justify-content-end bolder'>{folder?.serviceDataCount}</span>
                                    </div>
                                    <span className='ms-2 mb-0 bold'>{folder?.folderName}</span>
                                </div>
                                <div className='vertical-dots' dropdown-toggle data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"><img src={verticaldots} className='img-fluid' width={3} alt="list-image" /></div>
                                <ul className="dropdown-menu ">
                                    <li>
                                        <a href="#"><span className="ms-2" onClick={() => handleFolderDelete(folder?.saveForLaterFolderId)}>Delete</span></a>
                                    </li>
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SaveForLaterView;