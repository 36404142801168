import React from 'react';
import ServicesSliderView from './ServicesSliderView';

const ServicessliderContainer = (props:any) => {
    return (
        <div>
            <ServicesSliderView serviceDetails={props?.serviceDetails}/>
        </div>
    );
};

export default ServicessliderContainer;