import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FacilityModel } from '../../utils/model';

const initialState: FacilityModel = {
    StatusFacilityInfo: false,
    DataFacilityInfo: '',
    MessageFacilityInfo: '',
    ErrorFacilityInfo: ''
};

export const facilityInformationSlice = createSlice({
    name: 'facilityData',
    initialState,
    reducers: {
        setFacilityInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusFacilityInfo = true;
            state.DataFacilityInfo = payload;
            state.MessageFacilityInfo = 'Success';
        },
        errorFacilityInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusFacilityInfo = false;
            state.DataFacilityInfo = [];
            state.MessageFacilityInfo = 'Failed';
            state.ErrorFacilityInfo = payload;
        },
       removeFacilityInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusFacilityInfo = false;
            state.DataFacilityInfo = '';
            state.MessageFacilityInfo = '';
            state.ErrorFacilityInfo = '';
        }
    },
    extraReducers: {}
});

export const { setFacilityInformationAction, errorFacilityInformationAction, removeFacilityInformationAction } = facilityInformationSlice.actions;

export default facilityInformationSlice.reducer;
