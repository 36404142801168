import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceStatus } from '../../../../utils/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ModulesAccessRights } from '../../../../utils/constant';
import eventEmitter from '../../../../utils/eventEmitter';

const InvoiceHeaderView = (props: any) => {    
    let [postedStatus, setPostedStatus] = useState<any>(false);
    let [cancelledStatus, setCancelledStatus] = useState<any>(false);
    let [draftStatus, setDraftStatus] = useState<any>(false);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const [hasEditAccess, setEditAccess] = useState<any>(false);
    let [isConfirm, setConfirm] = useState<any>(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.invoicedata?.invoiceStatus === InvoiceStatus.Draft) {
            setDraftStatus(true);
        } else {
            setDraftStatus(false);
        }
        if (props.invoicedata?.invoiceStatus === InvoiceStatus.PostedOrConfirm) {
            setPostedStatus(true);
        } else {
            setPostedStatus(false);
        }
        if (props.invoicedata?.invoiceStatus === InvoiceStatus.Cancelled) {
            setCancelledStatus(true);
        } else {
            setCancelledStatus(false);
        }
    }, [props.invoicedata]);

    useEffect(() => {
        const fetchModule = DataLogin?.userModel?.modules?.filter((item: any) => item?.module === ModulesAccessRights.BillingManagement.moduleName)   
        const fetchEditPostedInvoiceAccess = fetchModule !== undefined && fetchModule[0]?.userAccess.length > 0 ? fetchModule[0]?.userAccess?.filter((item: any) => item?.accessCode === ModulesAccessRights.BillingManagement.editPostedInvoiceuserAccess.accessCode) : undefined        
        if(fetchEditPostedInvoiceAccess !== undefined && fetchEditPostedInvoiceAccess.length > 0 && fetchEditPostedInvoiceAccess[0].accessCode === ModulesAccessRights.BillingManagement.editPostedInvoiceuserAccess.accessCode) {
            setEditAccess(true)
        }
    }, [DataLogin])    
    const eventInvoiceEdit = async() => {
        setConfirm(true)
    }

    const eventInvoiceAfterSave = async() =>{
        setConfirm(false);
    }

    useEffect(() => {
        eventEmitter.on('eventInvoiceEdit', eventInvoiceEdit);
        eventEmitter.on('eventInvoiceAfterSave', eventInvoiceAfterSave);
        return () => {
            eventEmitter.off('eventInvoiceEdit', eventInvoiceEdit)
            eventEmitter.off('eventInvoiceAfterSave', eventInvoiceAfterSave)
        }
    });
    console.log(props.iserror);

    return (
        <div className="box-header">
            <div className="row align-items-center h-100">
                <div className="col-md-4 flex-order-2 py-2">
                    <div className={` ${props.isformsave ? 'hidefield' : '' || props.isformcancel ? 'hidefield' : ''}`}>
                        {props.invoiceDraftStatus && (
                            <>
                                <button disabled={!props.iserror} className="btn btn-primary btn-sm me-2 ms-2" onClick={props.issave}>
                                    {t('InvoiceDetail.Save_btn')}
                                </button>
                                {props.invoicedata !== undefined && props.duplicateStatus === false && props.invoiceline.length > 0 &&
                                <button disabled={!props.iserror} className="btn btn-primary btn-sm me-2" onClick={props.iscancel}>
                                    {t('InvoiceDetail.Cancel_btn')}
                                </button>
                                }
                                {props.invoicedata !== undefined &&  props.duplicateStatus === false && props.invoiceline.length > 0 && isConfirm === false &&
                                <button disabled={!props.iserror} className="btn btn-primary btn-sm" onClick={props.issplit}>
                                    {props.invoiceline.length === 1 ? t('InvoiceDetail.Confirm') : t('InvoiceDetail.Split_Confirm_btn')}
                                </button>
                                }
                            </>
                        )}
                    </div>
                    { props.invoiceDraftStatus !== true && props.invoicedata !== undefined && props.duplicateStatus !== true && props.invoicedata?.invoiceStatus === InvoiceStatus.PostedOrConfirm && hasEditAccess && (
                        <>
                    <button className="btn btn-primary btn-sm me-2 ms-2" onClick={props.isedit}>{t('InvoiceDetail.Edit_btn')}</button>

                    <button className="btn btn-primary btn-sm me-2" onClick={props.iscancel}>
                        {t('InvoiceDetail.Cancel_btn')}
                    </button>
                    </>
                    )}
                </div>
                {props.invoicedata !== undefined && props.duplicateStatus === false && (
                    <div className="col-md-4 flex-order-1">
                        <h3 className="mb-0 bolder lead-text text-center">
                            {t('InvoiceDetail.Invoice_No')}: <span className="purple-text">{props.invoicedata?.invoicePattern}</span>
                        </h3>
                    </div>
                )}
                {props.invoicedata !== undefined && props.duplicateStatus !== true && (
                    <>
                        <div className="col-md-4 flex-order-3">
                            <ul className="list-unstyled mb-0 invoice-status d-flex align-items-center justify-content-end">
                                <li className={props.isformsave ? 'showfield' : 'active' && props.isformcancel ? 'hidefield' : 'active'}>{t('InvoiceDetail.Draft')}</li>
                                <li className={props.isformsave ? 'active' : 'active' && props.isformcancel ? 'hidefield' : 'showfield'}>{t('InvoiceDetail.Posted')}</li>
                                <li className={props.isformcancel ? 'active' : 'hidefield' || props.isformsave ? 'hidefield' : 'active'}>{t('InvoiceDetail.Cancelled')}</li>
                            </ul>
                        </div>
                    </>                
                )}
            </div>
        </div>
    );
};

export default InvoiceHeaderView;
