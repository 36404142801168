import React from 'react';
import AssignmentView from './AssignmentView';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import swal from 'sweetalert';
import { moveToContract } from '../../../../../../service/costing';
import warning from '../../../../../../assets/images/warning.png';
import eventEmitter from '../../../../../../utils/eventEmitter';

const AssignmentContainer = (props:any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const assignmentDetails = DataLeadInfo?.data;
    
    const saveToContract = (serviceName:any) => {        
        swal({
            title: '',
            text: `Are you sure you want to move lead ${props.referralid} for contracting to ${serviceName} ? `,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const response = await moveToContract(assignmentDetails.leadPoolingId);
                if (response.status == true) {
                    swal(response.message, {
                        icon: 'success'
                    });
                    eventEmitter.emit('onAddNewLeadRM');
                    const nextStage = props.finalStatus > 6 ?props.finalStatus : 6;
                    props.refreshData(6,nextStage);
                } else {
                    swal(response.message, {
                        icon: 'error',
                        dangerMode: true
                    });
                }
            }
        });

    };

    return (
        <div>
            <AssignmentView submitalert={saveToContract} isSubmited={assignmentDetails?.hasMovedToContract} assignment={assignmentDetails} />
        </div>
    );
};

export default AssignmentContainer;