import IntegrationBottomView from './IntegrationBottomView';

const IntegrationBottomContainer = (props:any) => {
    
    return (
        <div>
           <IntegrationBottomView {...props}/>
        </div>
    );
};

export default IntegrationBottomContainer;