import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListReasonsModel } from '../../utils/model';

const initialState: ListReasonsModel = {
    StatusListReasons: false,
    DataListReasons: '',
    MessageListReasons: '',
    ErrorListReasons: ''
};

export const listMaintenanceReasonsSlice = createSlice({
    name: 'listMaintenanceReasons',
    initialState,
    reducers: {
        setListMaintenanceReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = true;
            state.DataListReasons = payload;
            state.MessageListReasons = 'Success';
        },
        errorListMaintenanceReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = [];
            state.MessageListReasons = 'Failed';
            state.ErrorListReasons = payload;
        },
        removeListMaintenanceReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = '';
            state.MessageListReasons = '';
            state.ErrorListReasons = '';
        }
    },
    extraReducers: {}
});

export const { setListMaintenanceReasonsAction, errorListMaintenanceReasonsAction, removeListMaintenanceReasonsAction } = listMaintenanceReasonsSlice.actions;

export default listMaintenanceReasonsSlice.reducer;
