import React, { useEffect, useState } from "react";
import ServicessliderContainer from './ServicesSlider/ServicesSliderContainer';
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";
import { GetProviderServiceSection } from "../../../../../service/service-details-service";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { animateScroll as scroll } from "react-scroll";

const ServicesView = () => {
    const [serviceDetails, SetServiceDetails] = useState<any>(null);
    const [openServicePanel, SetOpenServicePanel] = useState(false);
    const [scrollToSection, setScrollToSection] = useState(false);
    const [scrollToSection1, setScrollToSection1] = useState(false);
    const [searchValue, SetSearchValue] = useState<any>("")
    const [searchResults, setSearchResults] = useState<any>([]);
    const [isFocused, setIsFocused] = useState(false);


    const params = useParams();
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
    }, []);

    const handleServicePanel = (e: any) => {
        e.preventDefault();
        SetOpenServicePanel(true);
        setScrollToSection(true);
    };

    useEffect(() => {
        var dynamicDiv = document.getElementById("dynamicDiv");
        dynamicDiv?.classList.remove("closed");
        var dynamicDivHeight = dynamicDiv?.clientHeight + "px";
        document.documentElement.style.setProperty(
            "--dynamic-div-height",
            dynamicDivHeight
        );
        dynamicDiv?.classList.add("closed");
    }, [serviceDetails]);

    useEffect(() => {
        let timeout: any;
        if (scrollToSection && !isFocused) {
            timeout = setTimeout(() => {
                const sectionElement = document.getElementById("servicepanel");
                if (sectionElement) {
                    const viewportHeight = window.innerHeight;
                    const sectionBottomOffset = sectionElement.offsetTop + sectionElement.offsetHeight;

                    const screenWidth = window.innerWidth;
                    if (screenWidth <= 1300) {
                        const offset = sectionBottomOffset - viewportHeight + 340;
                        scroll.scrollTo(offset, {
                            smooth: true,
                            duration: 1000,
                        });
                    }
                    if (screenWidth > 1300) {
                        const offset = sectionBottomOffset - viewportHeight + 420;
                        scroll.scrollTo(offset, {
                            smooth: true,
                            duration: 1000,
                        });
                    }



                }
                setScrollToSection(false);
            }, 0);
        }

        return () => clearTimeout(timeout);
    }, [scrollToSection]);

    const HandleClosePanel = (e: any) => {
        e.preventDefault();
        SetOpenServicePanel(false);
        setScrollToSection1(true);
        SetSearchValue("")
        setSearchResults([])
    };

    useEffect(() => {
        let timeout: any;
        if (scrollToSection1 && !isFocused) {
            timeout = setTimeout(() => {
                const sectionElement1 = document.getElementById("service-section");
                if (sectionElement1) {
                    const offset1 = sectionElement1.offsetTop + 500;
                    scroll.scrollTo(offset1, {
                        smooth: true,
                    });
                }
                setScrollToSection1(false);
            }, 0);
        }

        return () => clearTimeout(timeout);
    }, [scrollToSection1]);

    useEffect(() => {
        GetProviderServiceDetailsList()
    }, [])

    useEffect(() => {
        GetProviderServiceDetailsList()
    }, [searchValue])

    const GetProviderServiceDetailsList = async () => {
        const result = await GetProviderServiceSection(params?.serviceSlug, searchValue)
        console.log(result, "result =>>>")
        SetServiceDetails(result?.data)
    }

    const handleKeyPress = (e: any) => {
        e.preventDefault()
        if (e.key === 'Enter') {
            const normalizedSearchText = searchValue.toLowerCase().trim();

            if (normalizedSearchText) {
                const existingResultIndex = searchResults.findIndex((result: any) => result.searchName.toLowerCase() === normalizedSearchText);

                if (existingResultIndex === -1) {
                    setSearchResults((prevResults: any) => [
                        ...prevResults,
                        { searchName: searchValue, id: searchResults.length + 1 }
                    ]);
                }
            }
            SetSearchValue("")
        }
    };

    const handleSearchDelete = (e: any, id: any) => {
        e.preventDefault()
        setSearchResults((prevResults: any) => prevResults.filter((result: any) => result.id !== id));
        SetSearchValue("")
    }

    const handleTagClick = (e: any, tagName: any) => {
        e.preventDefault()
        SetSearchValue(tagName)
    }

    return (
        <div>
            <div id="service-section"
                className={`service-detail-padding section-padding service-section pb-0  text-center `}>
                <ServicessliderContainer serviceDetails={serviceDetails} />
                <div className="amenities-section-btn">
                    <a href=""
                        className={`services-find-btn ${openServicePanel ? "faded-btn" : ""}`
                        }
                        onClick={handleServicePanel}
                    >
                        {t("Service.Find_more_services")} <span className="arrow"></span>
                    </a>
                </div>
            </div>
            <div className="panel " id="servicepanel">
                <div
                    className={`service-detail-panel position-relative px-2 ${openServicePanel ? "opened" : "closed"
                        }`}
                    id="dynamicDiv"
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 position-relative">
                                <a
                                    href="#servicebtn"
                                    className="panelclosebtn"
                                    onClick={HandleClosePanel}
                                    title="servicebtn"
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </a>
                                <div className="search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Services"
                                        value={searchValue}
                                        onChange={(e: any) => SetSearchValue(e.target.value)}
                                        onKeyUp={handleKeyPress}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                    />
                                    <a className="searchicon" title="searchicon">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </a>

                                </div>
                                <div className="search-tags">
                                    {searchResults && searchResults?.length > 0 &&
                                        searchResults?.map((sr: any) => {
                                            return (
                                                <a className="service-tags" >
                                                    <span className="hand-icon" onClick={(e) => handleTagClick(e, sr?.searchName)}>{sr?.searchName}</span>  <i className="fa-solid fa-xmark ms-2 hand-icon" onClick={(e) => { handleSearchDelete(e, sr?.id) }}></i>
                                                </a>
                                            )
                                        })
                                    }

                                </div>
                                <ul className="list-unstyled  service-detail-panel-main-list">
                                    {serviceDetails?.services && serviceDetails?.services?.length > 0 &&
                                        serviceDetails?.services?.map((sd: any) => {
                                            return (
                                                <li>
                                                    <p className="mb-2">{sd?.serviceSectionName}</p>
                                                    <ul className="list-unstyled service-detail-panel-list">
                                                        {sd?.providerServicesSectionDetailMasters &&
                                                            sd?.providerServicesSectionDetailMasters?.length > 0 &&
                                                            sd?.providerServicesSectionDetailMasters?.map((psm: any) => {
                                                                return (
                                                                    <li>
                                                                        <a href={`/ServiceDetailLink/${psm?.servicesSectionDetailId}`} target="_blank" >{psm?.name}</a>
                                                                    </li>
                                                                )
                                                            })

                                                        }

                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesView;