import React from 'react';
import ContracrTabView from './contracrTabView';

const ContractTabContainer = (props: any) => {
    return (
        <div>
            <ContracrTabView patientData={props.patientData} />
        </div>
    );
};

export default ContractTabContainer;