import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { rightNavigationPage } from '../../utils/constant';
import { setPageNavigationAction } from '../../store/general/pageNavigationReducer';
import BillingDetailsContainer from './BillingDetails/BillingDetailsContainer';
import InvoiceTableContainer from './InvoiceTable/InvoiceTableContainer';
import InvoiceDetailContainer from './InvoiceDetail/InvoiceDetailContainer';
import { removeGridFilterStateAction } from '../../store/general/gridFilterStateReducer';

const BillingView = () => {
    const dispatch = useDispatch();
    let [invoiceid, setinvoiceid] = useState<any>();
    let [duplicateStatus, setDuplicateStatus] = useState<boolean>(false);

    const { PageName, Data } = useSelector((state: RootState) => state.pageNavigation);

    useEffect(() => {
        if (PageName === '') {
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
        }
        if (Data.invoiceHeader_Id !== undefined && Data.invoiceHeader_Id !== '') {
            setinvoiceid(Number(Data.invoiceHeader_Id));
            if (Data.PageStatus !== undefined && Data.PageStatus === 'duplicate') {
                setDuplicateStatus(true);
            } else {
                setDuplicateStatus(false);
            }
        } else if (Data.invoiceHeaderId !== undefined && Data.invoiceHeaderId !== '') {
            setinvoiceid(Number(Data.invoiceHeaderId));
        } else {
            setinvoiceid(undefined);
        }
    }, [PageName, Data]);

    useEffect(() => {
        return () => {
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
        };
    }, []);
    useEffect(() => {
        return () => {
            dispatch(removeGridFilterStateAction());
        };
    }, []);
    const updateduplicatestatus = () =>{
        setDuplicateStatus(false);
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="middle_content" id="middle_content">
                            {PageName === rightNavigationPage.InvoiceDetailPage && <InvoiceDetailContainer invoiceid={invoiceid} duplicateStatus={duplicateStatus} updateduplicatestatus={updateduplicatestatus} />}
                            {PageName === rightNavigationPage.InvoiceTablePage && <BillingDetailsContainer />}
                            {PageName === rightNavigationPage.InvoiceTablePage && <InvoiceTableContainer />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingView;
