import React from 'react';
import ReservedTabView from './reservedTabView';

const ReservedTabContainer = (props: any) => {
    return (
        <div>
            <ReservedTabView patientData={props.patientData} />
        </div>
    );
};

export default ReservedTabContainer;