import React from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import RenderModalContainer from '../RenderModal/indexContainer';
import PlanHistoryContainer from '../PlanHistoryModal/indexContainer';
import { useEffect, useState } from 'react';
import { getInvoiceData } from '../../service/invoicedetail-service';
import { getAllPlanData } from '../../service/dashboard-service';
import { getContractFunderFileService } from '../../service/contract-service';
import NoteContainer from '../../components/NoteModal/indexContainer';
import note from '../../assets/images/note.svg';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    footer: any;
    className: any;
    invoicedetails: any;
}

function PatientDetailView(props: ModalProps) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    let [caindex, setcaindex] = useState<any>(0);
    const [plan, setplan] = useState<any>();
    const [count, setcount] = useState<any>();
    const [title, settitle] = useState<any>();
    const [plandata, setplandata] = useState<any>();
    const [totalCount, settotalcount] = useState<any>();
    const [funderNote, setFunderNote] = useState<any>('');
    const [funderName, setFunderName] = useState<any>('');
    const [showNoteModal, setShowNoteModal] = useState(false);

    async function getallplandata(plan: any, count: any) {
        const payload: any = { planType: plan, admissionId: props.invoicedetails?.admissionDetail?.admissionId };
        const res = await getAllPlanData(payload);
        if (res.status) {
            setplan(plan);
            setcount(count);
            setplandata(res.data);
            settotalcount(res.totalCount);
        }
    }

    const downloadPDF = async (file: any) => {
        const payload: any = { name: file };
        const response = await getContractFunderFileService(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    useEffect(() => {
        if (plan !== undefined) {
            setShowModal(true);
        }
    }, [plan]);

    useEffect(() => {
        if (plan === 1) {
            settitle('Expired Plan');
        } else if (plan === 2) {
            settitle('Current Plan');
        } else {
            settitle('Future Plan');
        }
    }, [title, plan]);

    const handleClickModal = (plan: any, count: any) => {
        setplan(undefined);
        getallplandata(plan, count);
    };

    const toggle = () => {
        setShowModal(false);
    };

    const currentindex = (index: number) => {
        setcaindex(index);
    };

    const handleNoteClickModal = (noteval: any, fname: any) => {
        setFunderNote(noteval);
        setFunderName(fname);
        setShowNoteModal(true);
    };
    const toggleNote = () => {
        setShowNoteModal(false);
    };

    return (
        <div>
            <ModalWrapperContainer
                title="Patient Detail"
                onClose={props.onClose}
                className="modal modal-small"
                content={
                    props.invoicedetails !== undefined && (
                        <div>
                            <div className="d-flex">
                                <p className="me-3">
                                    {t('InvoiceDetail.Name')}: <span className="bold">{props?.invoicedetails?.patientDetail?.patientName}</span>
                                </p>
                                <p className="me-3">
                                    {t('InvoiceDetail.Gender')}:{' '}
                                    <span className="bold">{props.invoicedetails?.patientDetail?.gender === 1 ? 'Male' : props.invoicedetails?.patientDetail?.gender === 2 ? 'Female' : 'Others'}</span>
                                </p>
                                <p className="me-3">
                                    {t('InvoiceDetail.Age')}: <span className="bold">{props.invoicedetails?.patientDetail?.age}</span>
                                </p>
                                <p className="me-3">
                                    {t('InvoiceDetail.Bed_No')}:{' '}
                                    <span className="bold">
                                        {props.invoicedetails?.admissionDetail?.bedName} ({props.invoicedetails?.admissionDetail?.wardName})
                                    </span>
                                </p>
                            </div>
                            <ul className="nav nav-tabs mb-0 tab-list mt-4 tab-list-modal" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="User-Detail"
                                        data-bs-toggle="tab"
                                        data-bs-target="#UserDetail"
                                        type="button"
                                        role="tab"
                                        aria-controls="UserDetail"
                                        aria-selected="true"
                                    >
                                        <p className="bolder mb-0">{t('InvoiceDetail.Service_User_Detail')}</p>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="Admission-Detail"
                                        data-bs-toggle="tab"
                                        data-bs-target="#AdmissionDetail"
                                        type="button"
                                        role="tab"
                                        aria-controls="AdmissionDetail"
                                        aria-selected="false"
                                    >
                                        <p className="bolder mb-0">{t('InvoiceDetail.Admission_Detail')}</p>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="Funders"
                                        data-bs-toggle="tab"
                                        data-bs-target="#FundersTab"
                                        type="button"
                                        role="tab"
                                        aria-controls="FundersTab"
                                        aria-selected="false"
                                    >
                                        <p className="bolder mb-0">{t('InvoiceDetail.Funders')}</p>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="Applicable-Rates"
                                        data-bs-toggle="tab"
                                        data-bs-target="#RatesTab"
                                        type="button"
                                        role="tab"
                                        aria-controls="RatesTab"
                                        aria-selected="false"
                                    >
                                        <p className="bolder mb-0">{t('InvoiceDetail.Applicable_Rates')}</p>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="Funding-Plan"
                                        data-bs-toggle="tab"
                                        data-bs-target="#FundingPlan"
                                        type="button"
                                        role="tab"
                                        aria-controls="FundingPlan"
                                        aria-selected="false"
                                    >
                                        <p className="bolder mb-0">{t('InvoiceDetail.Funding_Plans')}</p>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content tab-custom-height tab-list-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="UserDetail" role="tabpanel" aria-labelledby="User-Detail">
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.NHS_ID')}: <span className="bold"> {props.invoicedetails?.patientDetail?.nhsId}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Client_ID')}: <span className="bold"> {props.invoicedetails?.patientDetail?.localId}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Date_of_Birth')}: <span className="bold"> {props.invoicedetails?.patientDetail?.dateOfBirth}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Address')}:{' '}
                                        <span className="bold">
                                            {props.invoicedetails?.patientDetail?.addressLine1} {props.invoicedetails?.patientDetail?.addressLine2}
                                        </span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Contact_Info')}: <span className="bold">{props.invoicedetails?.patientDetail?.contactNo}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Email')}: <span className="bold"> {props.invoicedetails?.patientDetail?.email}</span>
                                    </p>
                                </div>

                                <div className="tab-pane fade" id="AdmissionDetail" role="tabpanel" aria-labelledby="Admission-Detail">
                                    <p className="mb-1">
                                        {' '}
                                        {t('OccupiedRightBar.Date_of_Admission')}: <span className="bold"> {props.invoicedetails?.admissionDetail?.admissionDate}</span>
                                    </p>
                                    <p className="mb-1">
                                        {' '}
                                        {t('OccupiedRightBar.Length_of_Stay')}: <span className="bold">{props.invoicedetails?.admissionDetail?.los}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Est_Discharge_Date')}: <span className="bold">{props.invoicedetails?.admissionDetail?.estDischargeDate}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Patient_Type')}: <span className="bold">{props.invoicedetails?.patientDetail?.patientTypeId === 1 ? 'Respite' : 'Long Term'}</span>
                                    </p>
                                    <p className="mb-1">
                                        {t('OccupiedRightBar.Admitted_By')}: <span className="bold">{props.invoicedetails?.admissionDetail?.admittedBy}</span>
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="FundersTab" role="tabpanel" aria-labelledby="Funders">
                                    <div className="accordion accordion-flush accordion-list" id="accordionpatientdetail">
                                        {props.invoicedetails?.contractDetails?.length > 0 &&
                                            props.invoicedetails?.contractDetails.map((funders: any, i: number) => {
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`flush-headingOne${i}`}>
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapseOne${i}`}
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseOne"
                                                                onClick={(e) => currentindex(i)}
                                                            >
                                                                {funders.funderName}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id={`flush-collapseOne${i}`}
                                                            className={`accordion-collapse collapse`}
                                                            aria-labelledby={`flush-headingOne${i}`}
                                                            data-bs-parent="#accordionpatientdetail"
                                                        >
                                                            <div className="accordion-body ps-0 pb-2">
                                                                <div>
                                                                    <p className="mb-1">
                                                                        {t('RightSidebarContraclList.funder_email')}: <span className="bold">{funders.funderEmail}</span>{' '}
                                                                    </p>
                                                                    <p className="mb-1">
                                                                        {t('RightSidebarContraclList.funder_phone')}: <span className="bold">{funders.funderPhoneNo}</span>{' '}
                                                                    </p>
                                                                    <p className="mb-1">
                                                                        {t('RightSidebarContraclList.funder_address')}: <span className="bold">{funders.funderAddressLine1}</span>{' '}
                                                                    </p>

                                                                    {funders?.suid !== undefined && funders?.suid !== '' && funders?.suid !== null && (
                                                                        <p className="mb-1">
                                                                            {t('RightSidebarContraclList.suid')}: <span className="bold">{funders?.suid}</span>{' '}
                                                                        </p>
                                                                    )}

                                                                    {funders?.pO_Reference !== undefined && funders?.pO_Reference !== '' && funders?.pO_Reference !== null && (
                                                                        <p className="mb-1">
                                                                            {t('RightSidebarContraclList.po_reference')}: <span className="bold">{funders?.pO_Reference}</span>{' '}
                                                                        </p>
                                                                    )}

                                                                    {funders?.note !== undefined && funders?.note !== '' && funders?.note !== null && (
                                                                        <div className="d-flex mt-2 justify-content-end">
                                                                            <img src={note} className="img-fluid me-1" onClick={() => handleNoteClickModal(funders?.note, funders.funderName)}></img>
                                                                            <p>{t('RightSidebarContraclList.notes')}</p>
                                                                        </div>
                                                                    )}
                                                                    {funders.documentPath !== null && (
                                                                        <a href="#" onClick={() => downloadPDF(funders.documentPath)} className="btn btn-primary btn-sm mt-2">
                                                                            {t('RightSidebarContraclList.funder_View_Placement_Agreement')}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="RatesTab" role="tabpanel" aria-labelledby="Applicable-Rates">
                                    {props.invoicedetails?.admissionDetail?.totalFees != null && (
                                        <p className="mb-1">
                                            <label>{t('RightSidebarContraclList.funder_Total_Fee')}:</label>{' '}
                                            <span className="bold">
                                                <span className="me-1">
                                                    £ {props.invoicedetails?.admissionDetail?.totalFees} / {'Week'}
                                                </span>{' '}
                                            </span>
                                        </p>
                                    )}
                                    <p className="mb-1">
                                        <label>{t('RightSidebarContraclList.funder_Core_Fees')}:</label>{' '}
                                        <span className="bold">
                                            <span className="me-1">
                                                £ {props.invoicedetails?.admissionDetail?.dailyCoreFee} / {props.invoicedetails?.admissionDetail?.coreFeesType === 1 ? 'Day' : 'Week'}
                                            </span>{' '}
                                        </span>
                                    </p>
                                    {props.invoicedetails?.admissionDetail?.continenceCareFee != null && props.invoicedetails?.admissionDetail?.continenceCareFee != 0 && (
                                        <p className="mb-1">
                                           
                                            <label> {t('RightSidebarContraclList.funder_Continence_Fee')}:  {props.invoicedetails?.admissionDetail?.isContinenceFeeIncluded && <span>*</span>}</label>{' '}
                                            <span className="bold">
                                                <span className="me-1">
                                                    £ {props.invoicedetails?.admissionDetail?.continenceCareFee} / {props.invoicedetails?.admissionDetail?.continenceCareFeeType === 1 ? 'Day' : 'Week'}
                                                </span>{' '}
                                            </span>
                                        </p>
                                    )}
                                    {props.invoicedetails?.admissionDetail?.fncFee != null && props.invoicedetails?.admissionDetail?.fncFee != 0 && (
                                        <p className="mb-1">
                                           
                                            <label>{t('RightSidebarContraclList.funder_FNC_Fee')}:</label>
                                            {props.invoicedetails?.admissionDetail?.isFncFeeIncluded && <span>*</span>}{' '}
                                            <span className="bold">
                                                <span className="me-1">
                                                    £ {props.invoicedetails?.admissionDetail?.fncFee} / {props.invoicedetails?.admissionDetail?.fncFeeType === 1 ? 'Day' : 'Week'}
                                                </span>{' '}
                                            </span>
                                        </p>
                                    )}
                                    {props.invoicedetails?.admissionDetail?.totalAdditionalServiceFee != null && props.invoicedetails?.admissionDetail?.totalAdditionalServiceFee != 0 && (
                                        <p className="mb-1">
                                            <label>{t('RightSidebarContraclList.Additionalfee')}:</label>{' '}
                                            <span className="bold">
                                                <span className="me-1">
                                                    £ {props.invoicedetails?.admissionDetail?.totalAdditionalServiceFee} / {'Week'}
                                                </span>{' '}
                                            </span>
                                        </p>
                                    )}
                                    <div className="d-flex mt-2">
                                        {/* <p className="mb-1 me-3">
                                    <label>{t('RightSidebarContraclList.Health_Funding')}:</label> <span className="bold"><span className="me-1">£</span>{props.invoicedetails?.admissionDetail?.healthFunding} </span>
                                </p>
                                <p className="mb-1">
                                <label>{t('RightSidebarContraclList.Social_Funding')}:</label> <span className="bold"><span className="me-1">£</span>{props.invoicedetails?.admissionDetail?.socialFunding} </span>
                            </p> */}
                                    </div>

                                  
                                    {(props.invoicedetails?.admissionDetail?.hourlyFee1 !== null ||
                                        props.invoicedetails?.admissionDetail?.hourlyFee2 !== null ||
                                        props.invoicedetails?.admissionDetail?.hourlyFee3 !== null ||
                                        props.invoicedetails?.admissionDetail?.hourlyFee4 !== null) && (
                                        <>
                                          <p className="mt-3 mb-2">
                                        <span className="bold">{t('RightSidebarContraclList.funder_Additional_Service_Fee')}</span>
                                    </p>
                                            <table width="60%" className="applicable-rate-table">
                                                <tr>
                                                    <th style={{ width: '28%' }}>Service</th>
                                                    <th>Rates</th>
                                                    <th>Inclusion</th>
                                                </tr>
                                                {props.invoicedetails?.admissionDetail?.hourlyFee1 !== null && (
                                                    <tr>
                                                        <td>
                                                            
                                                            <label>{t('RightSidebarContraclList.funder_1_hourly')}</label>
                                                            {props.invoicedetails?.admissionDetail?.isInclusionHours1Included && <span>*</span>}
                                                        </td>
                                                        <td>
                                                            <span className=" me-1">
                                                                <span className="me-1">£{props.invoicedetails?.admissionDetail?.hourlyFee1}</span>
                                                            </span>
                                                            {t('RightSidebarContraclList.per_hour')}
                                                        </td>
                                                        <td>
                                                            <span className="me-1">{props.invoicedetails?.admissionDetail?.inclusionHours1}</span>
                                                            {props.invoicedetails?.admissionDetail?.inclusionHoursType1 === 1 && `${t('RightSidebarContraclList.per_Day')}`}
                                                            {props.invoicedetails?.admissionDetail?.inclusionHoursType1 === 2 && `${t('RightSidebarContraclList.per_Week')}`}
                                                        </td>
                                                    </tr>
                                                )}

                                                {props.invoicedetails?.admissionDetail?.hourlyFee2 !== null && (
                                                    <>
                                                        <tr>
                                                            <td>
                                                              
                                                                <label>{t('RightSidebarContraclList.funder_2_hourly')}</label>{' '}
                                                                {props.invoicedetails?.admissionDetail?.isInclusionHours2Included && <span>*</span>}
                                                            </td>
                                                            <td>
                                                                <span className=" me-1">
                                                                    <span className="me-1">£{props.invoicedetails?.admissionDetail?.hourlyFee2}</span>
                                                                </span>
                                                                {t('RightSidebarContraclList.per_hour')}
                                                            </td>
                                                            <td>
                                                                <span className="me-1">{props.invoicedetails?.admissionDetail?.inclusionHours2}</span>
                                                                {props.invoicedetails?.admissionDetail?.inclusionHoursType2 === 1 && `${t('RightSidebarContraclList.per_Day')}`}
                                                                {props.invoicedetails?.admissionDetail?.inclusionHoursType2 === 2 && `${t('RightSidebarContraclList.per_Week')}`}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {props.invoicedetails?.admissionDetail?.hourlyFee3 !== null && (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                
                                                                <label>{t('RightSidebarContraclList.funder_3_hourly')}</label>{' '}
                                                                {props.invoicedetails?.admissionDetail?.isInclusionHours3Included && <span>*</span>}
                                                            </td>
                                                            <td>
                                                                <span className=" me-1">
                                                                    <span className="me-1">£{props.invoicedetails?.admissionDetail?.hourlyFee3}</span>
                                                                </span>
                                                                {t('RightSidebarContraclList.per_hour')}
                                                            </td>
                                                            <td>
                                                                <span className="me-1">{props.invoicedetails?.admissionDetail?.inclusionHours3}</span>
                                                                {props.invoicedetails?.admissionDetail?.inclusionHoursType3 === 1 && `${t('RightSidebarContraclList.per_Day')}`}
                                                                {props.invoicedetails?.admissionDetail?.inclusionHoursType3 === 2 && `${t('RightSidebarContraclList.per_Week')}`}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}

                                                {props.invoicedetails?.admissionDetail?.hourlyFee4 !== null && (
                                                    <>
                                                        <tr>
                                                            <td>
                                                               
                                                                <label>{t('RightSidebarContraclList.funder_4_hourly')}</label>{' '}
                                                                {props.invoicedetails?.admissionDetail?.isInclusionHours4Included && <span>*</span>}
                                                            </td>
                                                            <td>
                                                                <span className=" me-1">
                                                                    <span className="me-1">£{props.invoicedetails?.admissionDetail?.hourlyFee4}</span>
                                                                </span>
                                                                {t('RightSidebarContraclList.per_hour')}
                                                            </td>
                                                            <td>
                                                                <span className="me-1">{props.invoicedetails?.admissionDetail?.inclusionHours4}</span>
                                                                {props.invoicedetails?.admissionDetail?.inclusionHoursType4 === 1 && `${t('RightSidebarContraclList.per_Day')}`}
                                                                {props.invoicedetails?.admissionDetail?.inclusionHoursType4 === 2 && `${t('RightSidebarContraclList.per_Week')}`}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </table>
                                        </>
                                    )}

                                    <p className="includednote bolder" style={{ marginTop: '10px' }}>
                                        * Indicates amount included in Core Fee
                                    </p>
                                </div>

                                <div className="tab-pane fade" id="FundingPlan" role="tabpanel" aria-labelledby="Funding-Plan">
                                    <ul className="plan-list plan-list-modal list-unstyled">
                                        {props.invoicedetails?.planCountViewModel?.expiredPlanCount !== 0 && (
                                            <>
                                                <li className="expired">
                                                    <span className="me-2">
                                                        <i className="fa-solid fa-circle"></i>
                                                    </span>
                                                    {t('SplitFundingPopup.Expired_Plan')} <span>({props.invoicedetails?.planCountViewModel?.expiredPlanCount})</span>
                                                    <a href="#" onClick={() => handleClickModal(1, props.invoicedetails?.planCountViewModel?.expiredPlanCount)}>
                                                        <i className="fa-solid fa-eye"></i>
                                                    </a>
                                                </li>
                                            </>
                                        )}
                                        {props.invoicedetails?.planCountViewModel?.currentPlanCount !== 0 && (
                                            <>
                                                <li className="current">
                                                    <span className="me-2">
                                                        <i className="fa-solid fa-circle"></i>
                                                    </span>
                                                    {t('SplitFundingPopup.Current_Plan')} <span>({props.invoicedetails?.planCountViewModel?.currentPlanCount})</span>
                                                    <a href="#" onClick={() => handleClickModal(2, props.invoicedetails?.planCountViewModel?.currentPlanCount)}>
                                                        <i className="fa-solid fa-eye"></i>
                                                    </a>
                                                </li>
                                            </>
                                        )}

                                        {props.invoicedetails?.planCountViewModel?.futurePlanCount !== 0 && (
                                            <>
                                                <li className="future">
                                                    <span className="me-2">
                                                        <i className="fa-solid fa-circle"></i>
                                                    </span>
                                                    {t('SplitFundingPopup.Future_Plan')} <span>({props.invoicedetails?.planCountViewModel?.futurePlanCount})</span>
                                                    <a href="#" onClick={() => handleClickModal(3, props.invoicedetails?.planCountViewModel?.futurePlanCount)}>
                                                        <i className="fa-solid fa-eye"></i>
                                                    </a>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                    <RenderModalContainer>
                                        {showModal && <PlanHistoryContainer title={title} plantype={plan} count={count} onClose={toggle} plandata={plandata} totalCount={totalCount} />}
                                    </RenderModalContainer>
                                    <RenderModalContainer>
                                        {showNoteModal && (
                                            <NoteContainer
                                                onClose={toggleNote}
                                                title={funderName != null ? funderName + "'s Notes" : t('RightSidebarContraclList.funder_note')}
                                                setFunderNote={funderNote}
                                                isReadonly={true}
                                            />
                                        )}
                                    </RenderModalContainer>
                                </div>
                            </div>
                        </div>
                    )
                }
            />
        </div>
    );
}

export default PatientDetailView;
