import React, { useEffect } from 'react';
import ReferralStagesView from './ReferralStagesView';

const ReferralStagesContainer = () => {

    

    return (
        <div>
            <ReferralStagesView />
        </div>
    );
};

export default ReferralStagesContainer;