import { useTranslation } from "react-i18next";
import AmenitiesFilterContainer from "./AmenitiesFilterContainer"
import { useEffect, useState } from "react";
import { AmenitiesListType } from "../../../layout/NHSHeader/ServicetypeHeader/SearchFilter/types";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { AppliedFilterDataType } from "../../../utils/model";

interface Props {
    handleModal: (event: any) => void;
    localAppliedFilter: AppliedFilterDataType;
    setShowPortal: React.Dispatch<React.SetStateAction<boolean>>;
    setLocalAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFilterDataType>>;
    advancedFilterCountApiCall: (payload?: any) => Promise<void>
}

export const AmenitiesPopup = ({ localAppliedFilter, setLocalAppliedFilters, setShowPortal, advancedFilterCountApiCall }: Props) => {
    const { t } = useTranslation();
    const { FilterListData } = useSelector((state: RootState) => state.filterList);

    const [currAmenities, setCurrAmenities] = useState<string>("");
    const [checkedAmenities, setCheckedAminities] = useState<AmenitiesListType[]>([]);

    useEffect(() => {
        if (!currAmenities && FilterListData?.amenitiesCount) {
            setCurrAmenities(FilterListData?.amenitiesCount?.[0]?.category);
        }
    }, [FilterListData?.amenitiesCount]);

    useEffect(() => {
        if (localAppliedFilter?.amenities) {
            setCheckedAminities(localAppliedFilter?.amenities)
        }
    }, [localAppliedFilter?.amenities]);

    const handleAmenitiesApply = () => {
        setLocalAppliedFilters({ ...localAppliedFilter, amenities: checkedAmenities });
        const payload = {
            ...localAppliedFilter,
            serviceTypeId: localAppliedFilter.serviceTypeId,
            cqcRating: localAppliedFilter.cqcRating,
            serviceStatus: localAppliedFilter.serviceStatus,
            Amenities: checkedAmenities?.map((am) => am.id),
        }
        delete payload.amenities;
        advancedFilterCountApiCall(payload);
        closePortal();
    }

    const handleResetAmenities = () => {
        setLocalAppliedFilters({ ...localAppliedFilter, amenities: [] });
        const payload = {
            ...localAppliedFilter,
            serviceTypeId: localAppliedFilter.serviceTypeId,
            cqcRating: localAppliedFilter.cqcRating,
            serviceStatus: localAppliedFilter.serviceStatus,
            Amenities: [],
        }
        delete payload.amenities;
        advancedFilterCountApiCall(payload);
        setCheckedAminities([]);
        closePortal();
    }

    const closePortal = () => {
        setShowPortal(false);
    }

    return (
        <>
            <div className="overlay" onClick={closePortal}></div>
            <div className="modal modal-amenities">
                <div className="header mb-3">
                    <h4 className="mb-0">{t("Amenities.Amenities")}</h4>
                    <a onClick={closePortal} className="removeButton">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 384 512"
                        >
                            <path
                                fill="#ffffff"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                        </svg>
                    </a>
                </div>
                <div className="body">
                    <ul
                        className="nav nav-pills amenities-pill mb-2 pb-2 border-bottom"
                        id="pills-tab"
                        role="tablist"
                    >
                        {FilterListData?.amenitiesCount?.map((amenities, i) => (
                            <li className="nav-item" role="presentation" key={i}>
                                <button
                                    className={`nav-link ${amenities.category === currAmenities ? "active" : ""}`}
                                    id={`pills-${amenities.category.toLocaleLowerCase()}`}
                                    data-bs-toggle="pill"
                                    data-bs-target={`#pills-${currAmenities}-nav`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`pills-${currAmenities}-nav`}
                                    aria-selected="true"
                                    onClick={() => setCurrAmenities(amenities.category)}
                                >
                                    {amenities.category}
                                    <div className="icon ms-2">
                                        <img
                                            alt={"Icon"}
                                            style={{ width: "20px" }}
                                            src={amenities.amenityCategoryIcon}
                                        />
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div
                        className="tab-content amenities-pill-conent"
                        id="pills-tabContent"
                    >
                        {FilterListData?.amenitiesCount?.map((amenities, i) => (
                            amenities.category === currAmenities ? (
                                <div
                                    key={i}
                                    tabIndex={0}
                                    role="tabpanel"
                                    id={`pills-${currAmenities}-tab`}
                                    className="tab-pane fade show active"
                                    aria-labelledby={`pills-${currAmenities}`}
                                >
                                    <AmenitiesFilterContainer
                                        key={currAmenities}
                                        amenitiesList={amenities.amenitiesList}
                                        checkedAmenities={checkedAmenities}
                                        setCheckedAminities={setCheckedAminities}
                                    />
                                </div>
                            ) : null
                        ))}
                    </div>
                </div>
                <div className='modal-footer pb-0'>
                    <div className='d-flex'>
                        <button onClick={handleAmenitiesApply} className="btn primary-btn pill-btn d-flex align-items-center" disabled={checkedAmenities.length === 0}>
                            Apply
                        </button>
                        <button onClick={handleResetAmenities} className="btn secondary-btn pill-btn d-flex align-items-center ms-2" disabled={checkedAmenities.length === 0}>
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
