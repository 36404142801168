import React from 'react';
import CostingView from '../../../ReferralManagementPage/ReferralManager/ReferralSidebar/ReferralStages/Costing/CostingView';

const NHSCostingContainer = (props:any) => {
    return (
        <div>
            <CostingView refreshData={props.refreshData} referralid={props.referralid} finalStatus={props.finalStatus} nhs={props.nhs}/>
        </div>
    );
};

export default NHSCostingContainer;