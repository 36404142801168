import React from 'react';
import SaveForLaterListPatientSpecificView from './SaveForLaterListPatientSpecificView';

const SaveForLaterListPatientSpecificContainer = () => {
    return (
        <div>
            <SaveForLaterListPatientSpecificView/>
        </div>
    );
};

export default SaveForLaterListPatientSpecificContainer;