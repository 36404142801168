import React, { useState, useRef, useEffect } from 'react';
import InvoiceHeaderContainer from './InvoiceDetailHeader/InvoiceHeaderContainer';
import InvoiceDetailFormContainer from './InvoiceDetailForm/InvoiceDetailFormContainer';
import InvoiceLinesContainer from './InvoiceLines/InvoiceLinesContainer';
import { useTranslation } from 'react-i18next';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import SplitFundingModalContainer from '../../../components/SplitFundingModal/indexContainer';
import PrintInvoiceContainer from '../PrintInvoice/PrintInvoiceContainer';
import ReactToPrint from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import { rightNavigationPage } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { setPageNavigationAction } from '../../../store/general/pageNavigationReducer';
// import SendPrintCostingContainer from '../../../components/CostingModal/SendPrintCostingModal/SendPrintCostingContainer';
import SendPrintInvoiceContainer from '../../../components/SendPrintInvoiceModal/SendPrintInvoiceContainer';
import swal from 'sweetalert';
import warning from '../../../assets/images/warning.png';
import { getInvioceRecipients, getInvoiceemailDetails } from '../../../service/invoicedetail-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ModulesAccessRights } from '../../../utils/constant';


const InvoiceDetailView = (props: any) => {
    const { t } = useTranslation();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const componentRef = useRef<HTMLDivElement>(null);
    const [showModal1, setShowModal1] = useState(false);
    let [print, setprint] = useState<boolean>(false);
    const [hasAccessSNDPRNT, setAccessSNDPRNT] = useState<any>(false);
    const [invoiceRecepients, setInvoiceRecepients] = useState<any>([]);
    const [emailData, setInvoiceEmailData] = useState<any>(undefined);
    const [defaultRecepients, setDefaultRecepients] = useState<any>(undefined);
    let dispatch = useDispatch();

    const printPriviewButton = useRef<any>(null);

    const onClickPrintPriview = () => {
        printPriviewButton.current.click()
    }

    const onClickSendPrintHandler = () => {
        setShowModal1(true);
    };
    const handleCloseModal = () => {
        setShowModal1(false);
    };

    useEffect(() => {
        const fetchModule = DataLogin?.userModel?.modules?.filter((item: any) => item?.module === ModulesAccessRights.BillingManagement.moduleName)
        const fetchAccessSendPrint = fetchModule !== undefined && fetchModule[0]?.userAccess.length > 0 ? fetchModule[0]?.userAccess?.filter((item: any) => item?.accessCode === ModulesAccessRights.BillingManagement.sendandPrintInvoiceuserAccess.accessCode) : undefined
        if(fetchAccessSendPrint !== undefined && fetchAccessSendPrint?.length > 0 && fetchAccessSendPrint[0].accessCode === ModulesAccessRights.BillingManagement.sendandPrintInvoiceuserAccess.accessCode) {
            setAccessSNDPRNT(true)
        }
    }, [DataLogin])
    useEffect(() => {
        const fetchInvoiceRecipients = async () => {
            const response = await getInvioceRecipients()
            if(response?.data?.length > 0) {
                if(props?.invoicedata?.contractDetails?.length > 0) {
                    let funderdata = props?.invoicedata?.contractDetails.filter((item: any) => item?.funderId === props?.invoicedata?.invoiceModel?.funderId)
                    let defaultValueData = response?.data.filter((item: any) => item.name === funderdata[0]?.funderName)
                    let defaultRecepients: any = []
                    defaultValueData.map((val: any) => {
                        defaultRecepients.push({
                            name: val.name,
                            value: val.email
                        })
                    })
                    setDefaultRecepients(defaultRecepients)
                 }
                setInvoiceRecepients(response?.data)
            }
        }
        fetchInvoiceRecipients()
        if(props?.invoicedata?.invoiceModel?.invoiceHeaderId !== undefined && props?.invoicedata?.invoiceModel?.invoiceHeaderId !== null) {
            const fetchInvoiceemailDetails = async () => {
                const response = await getInvoiceemailDetails(props?.invoicedata?.invoiceModel?.invoiceHeaderId)
                if(response?.data !== '') {
                    setInvoiceEmailData(response?.data)
                }
            }
            fetchInvoiceemailDetails()
        }
    }, [])
    return (
        <div>
            <div className="row mb-2">
                <div className="col-6">
                    <button className="btn btn-white  btn-sm me-2 " onClick={props.onClickBackHandler}>
                        <i className="fa fa-chevron-left"></i> {t('InvoiceDetail.Back_btn')}
                    </button>
                </div>

                <div className="col-6 text-right">
                    {
                        props.invoicedata !== undefined && props.invoiceDraftStatus === false && props.invoicedata?.invoiceModel?.invoiceStatus !== 3 && (
                            <>
                                <ReactToPrint
                                    trigger={() => (
                                        <button ref={printPriviewButton} className="btn btn-white btn-sm me-2 ms-2">
                                            <i className="fa-solid fa-print"></i> {t('InvoiceDetail.Print_btn')}
                                        </button>
                                    )}
                                    content={() => componentRef.current}
                                    onBeforePrint={() => { document.title = `Invoice_${props.invoicedata?.invoiceModel?.invoicePattern}_${props.invoicedata?.serviceModel?.accountName}`; }}
                                />
                            </>
                        )
                        // <button className="btn btn-white btn-sm me-2 ms-2"><i className="fa-solid fa-print"></i> {t('InvoiceDetail.Print_btn')}</button>
                    }
                    {hasAccessSNDPRNT && props.invoicedata !== undefined && props.invoiceDraftStatus === false && (
                        <button className="btn btn-white btn-sm me-2" onClick={onClickSendPrintHandler}>
                            <i className="fa-solid fa-envelope"></i> Send & Print
                        </button>
                    )}
                    {props.invoicedata !== undefined && props.duplicateStatus === false && props.invoicedata?.invoiceModel?.invoiceStatus !== 3 && !props.iscancel && (
                        <button className="btn btn-white btn-sm " onClick={props.onClickDuplicateHandler} data-tooltip-id="tooltip-duplicate-btn" data-tooltip-content="Copy & Create New">
                            <i className="fa-solid fa-clone"></i> {t('InvoiceDetail.Duplicate_btn')}
                        </button>
                    )}
                </div>
            </div>
            <div className="box-shadow p-0">
                <InvoiceHeaderContainer
                    duplicateStatus={props.duplicateStatus}
                    invoiceDraftStatus={props.invoiceDraftStatus}
                    invoicedata={props.invoicedata?.invoiceModel}
                    issave={props.saveHandler}
                    iscancel={props.cancelHandler}
                    isformsave={props.issave}
                    isformcancel={props.iscancel}
                    issplit={props.issplit}
                    iserror={props.iserror}
                    invoiceline={props.invoiceline}
                    isedit={props.isedit}
                />

                <InvoiceDetailFormContainer
                    duplicateStatus={props.duplicateStatus}
                    invoiceDraftStatus={props.invoiceDraftStatus}
                    getinvoicedata={props.getinvoicedata}
                    invoicedata={props.invoicedata}
                    isformsave={props.issave}
                    isformcancel={props.iscancel}
                    getadmissionId={props.getadmissionId}
                    isdatavalid={props.isdatavalid}
                />

                <InvoiceLinesContainer
                    duplicateStatus={props.duplicateStatus}
                    invoiceDraftStatus={props.invoiceDraftStatus}
                    getInvoicelineerror={props.getInvoicelineerror}
                    invoicedata={props.invoicedata}
                    getInvoicelines={props.getInvoicelines}
                    deletedlines={props.deletedlines}
                    admissionId={props.admissionId}
                    invoicefields={props.invoicefields}
                />
            </div>

            <div ref={componentRef}>
                <PrintInvoiceContainer invoicedata={props.invoicedata} />
            </div>
            <Tooltip id="tooltip-duplicate-btn" className="my-tooltip" />

            <RenderModalContainer>
                {props.showModal && <SplitFundingModalContainer onAddplan={props.addPlan} onSave={props.savedata} isaddplan={props.isaddplan} iseditable={false} funderlist={[]} />}
            </RenderModalContainer>
            <RenderModalContainer>{showModal1 && <SendPrintInvoiceContainer onCloseWC={handleCloseModal} invoiceData={props?.invoicedata} onClickPrintPriview={onClickPrintPriview} title="Send & Print Invoice" invoiceRecepients={invoiceRecepients} defaultRecepients={defaultRecepients} emailData={emailData} />}</RenderModalContainer>
        </div>
    );
};

export default InvoiceDetailView;
