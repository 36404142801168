import React, { useState, useEffect } from 'react';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

const SearchInvoiceView = (props: any) => {
    let [smartSearch, setsmartSearch] = useState<any>('');
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.searchValue !== '') {
                setsmartSearch(DataGridFilterState?.searchValue)
            } else {
                setsmartSearch('')
            }
        }
    },[StatusGridFilterState, DataGridFilterState])

    const RemoveClickHandler = (event:any) => {                       
        setsmartSearch('');
        event.target.name = 'invoiceSmartSearch';
        event.target.value = '';
        props.searchValue(event);
    }

    return (
        <div className='me-2 position-relative'>
            <input type="text" placeholder='Search' name="invoiceSmartSearch" value={smartSearch} onChange={(event: any) => {props.searchValue(event);setsmartSearch(event.target.value)}} className='form-control white search-invoice' />
            <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
            {smartSearch !== undefined && smartSearch !== "" && smartSearch.toString().length >= 1 && 
                    <a href="#" onClick={(event: any) => {RemoveClickHandler(event);}} className='clear-filter'><i className="fa-solid fa-xmark"></i></a>
             }
        </div>
    );
};

export default SearchInvoiceView;