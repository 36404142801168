import React, { useState, useEffect } from 'react';
import BillingDetailsView from './BillingDetailsView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const BillingDetailsContainer = () => {
    let [moreInformation, setMoreInformation] = useState<Boolean>(false);
    const [invoiceStatistics, setInvoiceStatistics] = useState<any>();
    const { StatusInvoiceStatistics, DataInvoiceStatistics } = useSelector((state: RootState) => state.invoiceStatistics);

    const handleShowMoreInfo = () => {
        setMoreInformation(true);
    };
    const handleHideMoreInfo = () => {
        setMoreInformation(false);
    };

    useEffect(() => {
        if(StatusInvoiceStatistics === true) {
            setInvoiceStatistics(DataInvoiceStatistics.data)
        }
    },[DataInvoiceStatistics])
    
    return (
        <div>
            <BillingDetailsView invoiceStatistics={invoiceStatistics} handleShowMoreInfo={handleShowMoreInfo} handleHideMoreInfo={handleHideMoreInfo} moreInformation={moreInformation} />            
        </div>
    );
};

export default BillingDetailsContainer;