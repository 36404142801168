import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferReasonsModel } from '../../utils/model';

const initialState: TransferReasonsModel = {
    StatusListReasons: false,
    DataListReasons: '',
    MessageListReasons: '',
    ErrorListReasons: ''
};

export const listTransferReasonsSlice = createSlice({
    name: 'listTransferReasons',
    initialState,
    reducers: {
        setListTransferReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = true;
            state.DataListReasons = payload;
            state.MessageListReasons = 'Success';
        },
        errorListTransferReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = [];
            state.MessageListReasons = 'Failed';
            state.ErrorListReasons = payload;
        },
        removeListTransferReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = '';
            state.MessageListReasons = '';
            state.ErrorListReasons = '';
        }
    },
    extraReducers: {}
});

export const { setListTransferReasonsAction, errorListTransferReasonsAction, removeListTransferReasonsAction } = listTransferReasonsSlice.actions;

export default listTransferReasonsSlice.reducer;
