import React from 'react';
import HomeMapView from './HomeMapView';
import { ListBoxContainerPropsType } from '../HomeList/ListBox/types';
import { ServicesListsType } from '../HomeList/ListBox/types';

const HomeMapContainer = (props: ListBoxContainerPropsType) => {
    return (
        <div>
            <HomeMapView {...props} />
        </div>
    );
};

export default HomeMapContainer;