import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { RootState } from '../../../../../../store';
import { useSelector } from 'react-redux';
import { getPenDocument } from '../../../../../../service/leads';

const PenPictureView = () => {
    const { t } = useTranslation(); 
    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    const openpdf = async (e: any,path:any) => {
        e.preventDefault();
        const payload = { path: path };
        const response = await getPenDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };
    return (
        <div>
            <p>
                <label className='mb-1'>{t('ServiceReferralSidebar.Description')}</label><br />
                {DataLeadInfo?.data?.penPictureDetailModel && DataLeadInfo?.data?.penPictureDetailModel.description && <span className='bold'>{DataLeadInfo?.data?.penPictureDetailModel.description}</span>}
            </p>
            <p>
                <label className='mt-3 mb-1'>{t('ServiceReferralSidebar.Attachment')}</label>
            {DataLeadInfo?.data?.penPictureDetailModel?.listPenPictureList?.length > 0 &&
            <>
                {DataLeadInfo?.data?.penPictureDetailModel?.listPenPictureList.map((item : any) =>{ return (
                    <div className='d-flex white-bg p-2 justify-content-between border-radius-6'>
                    <p className='purple-text bold'>{item.documentName}</p>
                    <a onClick={(e: any) => openpdf(e,item.documentPath)} className='cursor-pointer' data-tooltip-id="my-tooltip-view" data-tooltip-content="View Attachment"><i className="fa-solid fa-eye"></i></a>
                </div>
                )})}
            </>
            }
            </p>
           
            <Tooltip id="my-tooltip-view" className="my-tooltip" />
        </div>
    );
};

export default PenPictureView;