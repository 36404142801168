import React, { useEffect, useState } from 'react';
import PoolingView from './PoolingView';
import { getFacilitydata, poolLead } from '../../../../../../service/leads';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
import { useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import eventEmitter from '../../../../../../utils/eventEmitter';

const PoolingContainer = (props:any) => {

    const [ispooling,setpooling] =useState<boolean>(false);
    const [services,setservices] = useState<any>();
    const [leadid,setleadid] = useState<any>();
    const dispatch = useDispatch();
    const [clearservice, setclearservice] = useState<any>(undefined);
    const [serviceid,setserviceid] = useState<any>();
    // useEffect(() => {
    //     
    //     async function getDropdowns() {
    //         await getFacilitydata(props.serviceid);
    //     }
    //     getDropdowns();
    // }, [props.serviceid]);

    useEffect(()=>{
        setleadid(props.leadid);
    },[props.leadid])

    useEffect(()=>{
     
    },[services])



    const serviceids = (ids:any) =>{
     if(ids.length > 0){
        setservices(ids);
        setpooling(true);
     }
     else{
        setservices([]);
        setpooling(false);
     }
     
    }

    const poolingalert = () =>{
        alertHandler(`Are you sure you want to pool the lead ${props.referralId} ? `);
    }

    const alertHandler = (text: any) => {
        swal({
            title: '',
            text: text,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                pooling()
            } else {
            }
        });
    };

    async function pooling (){
      let data = {
        "leadId": leadid,
        "serviceId":services
      }

      
      const res = await poolLead(data);

      if (res.status === true) {
        swal(res.message, {
            icon: 'success'
        });
        eventEmitter.emit('onAddNewLeadRM');
        eventEmitter.emit('eventOpenLeadContainer');
        eventEmitter.emit('eventOpenNHSContainerStateChanger');
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
    } else {
        swal(res.message, {
            icon: 'error'
        });
    }
        
    }
    useEffect(() => {
        async function getDropdowns() {
            await getFacilitydata(serviceid);
        }
        if(serviceid !== undefined){
            getDropdowns();
        }
        
    }, [serviceid]);


    const updateData = (e: any) => {
        setserviceid(undefined);
        if(e.target.name === "servicetype"){
            setclearservice('yes');
        }
        setserviceid(e.target.value);
    }


    return (
        <div>
            <PoolingView servicetype={props.servicetype} serviceids={serviceids} pooling={poolingalert} ispooling={ispooling} refreshData={props.refreshData} finalStatus={props.finalStatus} serviceid={props.serviceid} referralId={props.referralId} updateData={updateData} clearservice={clearservice} nhs={props.nhs} />
        </div>
    );
};

export default PoolingContainer;