import React, { useEffect } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import eventEmitter from '../../utils/eventEmitter';
//@ts-ignore
import * as dompurify from 'dompurify';

// interface ModalProps {
//     onClose: () => any;
//     title: string;
//     content: any;
//     className: any;
// }

const MessageModalView = (props: any) => {
    const sanitizer = dompurify.sanitize;

    useEffect(() => {
        return () => {
            console.log("props.individualMessage: ", props.individualMessage.messageId)
            if (props?.isNotificationType) {
                let obj: any = {
                    id: props.individualMessage.notificationId
                }
                eventEmitter.emit("eventMarkAsRead", obj)

            } else {
                let obj: any = {
                    id: props.individualMessage.messageId
                }
                eventEmitter.emit("eventMarkAsRead", obj)

            }

        }
    }, [])
    return <ModalWrapperContainer title={props.title} onClose={props.onClose} className="modal modal-extrasmall" content={<div dangerouslySetInnerHTML={{ __html: sanitizer(props.content) }} />} />;
};

export default MessageModalView;
