import React from 'react';
import { Bar } from "react-chartjs-2";


const BarChartView = (props:any) => {
    return (
        <div className='chart-container'>
            <Bar data={props.data} options={props.options} />
        </div>
    );
};

export default BarChartView;