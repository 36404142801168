import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WardInformationModel } from '../../utils/model';

const initialState: WardInformationModel = {
    StatusWardInfo: false,
    DataWardInfo: '',
    MessageWardInfo: '',
    ErrorWardInfo: ''
};

export const wardInformationSlice = createSlice({
    name: 'wardInformation',
    initialState,
    reducers: {
        setWardInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusWardInfo = true;
            state.DataWardInfo = payload;
            state.MessageWardInfo = 'Success';
        },
        errorWardInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusWardInfo = false;
            state.DataWardInfo = [];
            state.MessageWardInfo = 'Failed';
            state.ErrorWardInfo = payload;
        },
        removeWardInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusWardInfo = false;
            state.DataWardInfo = '';
            state.MessageWardInfo = '';
            state.ErrorWardInfo = '';
        }
    },
    extraReducers: {}
});

export const { setWardInformationAction, errorWardInformationAction, removeWardInformationAction } = wardInformationSlice.actions;

export default wardInformationSlice.reducer;
