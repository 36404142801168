import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MaintenanceDetailsTabContainer from './MaintenanceDetailsTab/MaintenanceDetailsTabContainer';
import MaintenanceReleaseTabContainer from './MaintenanceReleaseTab/MaintenanceReleaseTabContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const MaintenanceDetailsView = (props: any) => {
    const { t } = useTranslation();

    const [actionValue, setactionValue] = useState('Maintenance_tab');
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const actionChange = (val: any) => {
        setactionValue(val);
    };
    useEffect(() => {
        props.setFormAction(actionValue);
    }, [actionValue]);

    return (
        <div>
            <button onClick={props.closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>
            </button>
            <h3 className="mb-4">{t('RightSidebarMaintenanceDetails.Maintenance_Detail')}</h3>
            <div className="right_content-height-tab">
                <p className="text-center purple-text bold">
                    Ward No : {props.bedDetailData?.bedMaintenanceDetail?.wardName || ''} | Bed No : {props.bedDetailData?.bedMaintenanceDetail?.bedName || ''}
                </p>
                <ul className="nav nav-tabs mt-3 mb-3 custom-tab custom-tab4" id="MaintenanceDetailTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={() => actionChange('Maintenance_tab')}
                            className="nav-link active"
                            id="Maintenance-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#MaintenanceTab"
                            type="button"
                            role="tab"
                            aria-controls="MaintenanceTab"
                            aria-selected="true"
                        >
                            <svg
                                version="1.1"
                                id="Layer_1"
                                fill="#ffffff"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 695.45 473.64"
                                xmlSpace="preserve"
                            >
                                <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                <path
                                    d="M0,11.3C3.75,2.75,10.4-0.11,19.53,0C41.4,0.28,44.52,3.16,44.52,25.27c0,67.46,0,134.91,0,202.37c0,2.71,0,5.42,0,9.18
                                      c3.14,0,5.97,0,8.8,0c206.86,0,413.72,0,620.59,0c17.23,0,21.54,4.36,21.54,21.77c0,64.97,0,129.93,0,194.9
                                      c0,15.13-4.81,19.97-19.83,20.02c-21.56,0.07-24.68-3.06-24.68-24.77c0-28.52,0-57.04,0-85.57c0-2.43,0-4.87,0-7.66
                                      c-202.29,0-403.83,0-606.07,0c-0.12,2.49-0.33,4.89-0.33,7.28c-0.02,30.11,0,60.21-0.02,90.32c-0.01,15.28-5.07,20.35-20.22,20.39
                                      c-1.58,0-3.18-0.12-4.75,0.02C10.4,474.34,3.75,470.88,0,462.33C0,311.99,0,161.64,0,11.3z"
                                />
                                <path
                                    d="M164.34,221.92c-24.88,0-49.77,0.22-74.65-0.07c-20.46-0.24-36.45-15.13-37.75-34.46c-1.33-19.87,14.9-37.88,36.2-38.81
                                      c19.19-0.83,38.44-0.41,57.67-0.47c17.87-0.05,35.74-0.07,53.61,0.01c19.43,0.08,36.09,7.27,50.36,20.21
                                      c6.75,6.12,10.36,13.55,8.87,22.97c-0.99,6.23-1.15,12.65-2.71,18.72c-2.31,8.97-6.35,11.81-15.6,11.85
                                      C215.01,221.98,189.68,221.91,164.34,221.92z"
                                />
                            </svg>
                            {t('RightSidebarMaintenanceDetails.Maintenance_tab')}
                        </button>
                    </li>
                    {DataServiceDetail?.iqviaServiceEnabled === false && (
                        <li className="nav-item" role="presentation">
                            <button
                                onClick={() => actionChange('Release_tab')}
                                className="nav-link"
                                id="release-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#releaseTab"
                                type="button"
                                role="tab"
                                aria-controls="releaseTab"
                                aria-selected="false"
                            >
                                <svg
                                    version="1.1"
                                    id="Layer_1"
                                    fill="#ffffff"
                                    className="release-maintenance-icon"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 1080 1080"
                                >
                                    <g>
                                        <style>{`"enable-background:new 0 0 1080 1080;"`}</style>
                                        <path
                                            fill="#4e4851"
                                            d="M266.22,154.58c6.27,3.81,11.29,6.2,15.53,9.55c18.13,14.34,36.18,28.79,53.93,43.59c5.89,4.91,10.1,5.54,17.79,2
                                    c28.09-12.94,56.77-24.63,85.56-35.96c6.72-2.65,10.22-4.86,10.74-12.77c1.53-23.05,4.28-46.01,6.35-69.02
                                    c1.62-17.99,6.73-23.01,24.84-23.05c39.47-0.08,78.95-0.1,118.42,0.03c18.56,0.06,22.81,3.29,24.93,21.39
                                    c2.73,23.33,4.72,46.77,6.28,70.21c0.51,7.65,3.55,10.75,10.51,13.45c28.83,11.19,57.53,22.76,85.83,35.2
                                    c7.55,3.32,12.21,4.16,18.88-1.73c17.02-15.03,34.88-29.1,52.42-43.54c13.94-11.48,19.85-10.97,32.49,1.96
                                    c27.04,27.65,54.32,55.05,81.3,82.76c14.31,14.7,14.57,18.3,1.84,34.37c-14.11,17.8-28.66,35.26-43.33,52.6
                                    c-4.71,5.57-5,9.71-1.75,16.84c13.15,28.88,25.36,58.2,37.01,87.72c2.67,6.77,5.63,9.18,12.58,9.7c23.83,1.78,47.63,4.1,71.42,6.49
                                    c17.42,1.75,21.14,6.04,21.17,23.52c0.09,39.85,0.15,79.71,0.15,119.56c0,16.21-5.21,22.46-21.46,24.37
                                    c-23.33,2.73-46.79,4.48-70.06,7.62c-4.17,0.56-9.49,5.03-11.27,9.03c-13.25,29.72-25.96,59.69-38.18,89.85
                                    c-1.47,3.62-0.44,9.99,1.97,13.16c14.23,18.71,29.32,36.76,43.89,55.21c11.7,14.82,11.57,18.39-1.29,31.3
                                    c-28.13,28.25-56.34,56.42-84.71,84.43c-12.73,12.57-16.26,12.77-30.43,1.68c-17.89-14-35.66-28.16-53.06-42.76
                                    c-6.7-5.63-11.72-5.91-20.06-2.06c-27.36,12.66-55.24,24.28-83.41,35.02c-8.62,3.29-11.62,7.45-12.25,16.07
                                    c-1.71,23.43-3.73,46.86-6.56,70.17c-1.76,14.5-6.73,18.58-21.33,18.6c-41.05,0.06-82.11-0.11-123.16-0.7
                                    c-17.64-0.25-21.52-5.1-23.5-25.5c-2.2-22.6-4.09-45.23-7.05-67.73c-0.5-3.83-5.03-8.64-8.86-10.28
                                    c-28.46-12.15-57.42-23.11-85.73-35.59c-8.09-3.57-12.9-3.94-19.95,2.13c-18.11,15.58-37.28,29.93-55.91,44.92
                                    c-9.03,7.27-17.2,6.74-25.35-1.43c-29.29-29.34-58.87-58.4-87.95-87.96c-12.05-12.25-11.78-17.05-0.6-30.66
                                    c13.92-16.93,27.66-34.03,42.23-50.39c6.84-7.68,7.07-13.57,2.68-23.08c-12.97-28.07-23.99-57.03-36.38-85.37
                                    c-1.64-3.74-6.56-8.06-10.39-8.51c-24.12-2.87-48.38-4.63-72.57-6.96c-17.29-1.66-21.44-6-21.51-23.26
                                    c-0.15-40.26-0.09-80.51,0.07-120.77c0.07-17.74,3.68-21.75,21.08-23.56c24.16-2.51,48.4-4.39,72.5-7.4
                                    c3.84-0.48,9.35-4.85,10.3-8.44c8.28-31.04,21.09-60.08,37.91-87.27c4.53-7.32,3.41-12.09-1.47-17.91
                                    c-14.59-17.42-29.06-34.93-43.37-52.58c-12.43-15.33-12.26-19.66,1.3-33.34c26.94-27.19,53.93-54.33,81.16-81.22
                                    C253.58,163.17,259.98,159.32,266.22,154.58z M814.26,885.85c21.9-22.12,42.53-43.69,64.08-64.29c6.98-6.67,6.7-10.8,0.98-17.64
                                    c-14.83-17.72-28.98-36.01-43.49-54c-5.82-7.22-7.39-14.04-1.87-22.69c21.81-34.19,36.94-71.28,46.04-110.8
                                    c2.33-10.11,8.22-15.78,18.9-16.74c23.01-2.07,45.96-4.79,68.98-6.7c7.3-0.6,10.4-2.56,10.26-10.68
                                    c-0.49-28.28-0.45-56.59,0.06-84.87c0.14-7.72-3.03-9.33-9.67-9.91c-23.4-2.07-46.75-4.78-70.15-6.94
                                    c-11.02-1.02-17.51-6.62-19.96-17.26c-8.94-38.7-23.26-75.21-44.55-108.83c-5.93-9.37-5.47-17.29,1.99-25.89
                                    c14.62-16.85,28.12-34.69,42.89-51.4c6.05-6.85,6.58-11.61,0.1-18.01c-19.57-19.33-38.96-38.84-58.22-58.48
                                    c-5.55-5.66-9.52-4.27-15.11,0.39c-17.44,14.55-35.77,28.03-53.06,42.75c-9.12,7.76-17.19,7.96-26.89,1.85
                                    c-33.62-21.2-69.82-36.39-108.62-45.08c-11.47-2.57-16.55-9.06-17.47-20.7c-1.82-23.02-4.79-45.95-6.71-68.96
                                    c-0.55-6.5-2.42-9.02-9.26-8.94c-27.89,0.33-55.8,0.47-83.68-0.03c-8.87-0.16-11.75,3.04-12.28,11.52
                                    c-1.32,21.07-3.48,42.08-5.21,63.12c-0.98,11.85-3.94,21.42-17.83,24.63c-38.67,8.94-75.17,23.45-108.5,45.29
                                    c-10.5,6.88-19.55,5.97-29.09-2.45c-17.03-15.02-34.84-29.16-52.1-43.93c-5.38-4.6-9.24-5.74-14.96,0.17
                                    c-19.66,20.35-39.75,40.31-60.05,60.03c-5.37,5.21-4.77,8.81-0.35,14.01c14.45,17.01,28.02,34.79,42.96,51.34
                                    c9.25,10.26,10.02,19.38,2.76,30.85c-20.62,32.54-35.27,67.71-43.95,105.24c-2.75,11.87-9.67,17.46-21.45,18.59
                                    c-22.6,2.16-45.13,4.99-67.75,6.87c-7.99,0.66-11.1,3.28-10.94,11.62c0.54,28.28,0.65,56.58,0.4,84.87
                                    c-0.07,7.99,3.86,8.72,10.3,9.24c23.82,1.92,47.58,4.56,71.39,6.69c9.55,0.85,15.21,5.3,17.5,14.88
                                    c9.47,39.73,23.38,77.7,46.07,112.01c5.61,8.49,4.93,16.02-1.55,23.9c-14.93,18.15-29.15,36.9-44.34,54.84
                                    c-4.95,5.84-5.47,9.12,0.26,14.64c20.09,19.37,39.64,39.29,59.5,58.89c2.39,2.36,5.41,4.07,7.97,5.97
                                    c20.05-16.56,39.42-31.89,57.97-48.15c9.77-8.57,18.72-8.62,29.13-2.09c33.31,20.89,69.05,36.16,107.34,45.21
                                    c10.52,2.49,16.87,8.02,17.87,19.26c2.01,22.61,4.75,45.16,6.27,67.8c0.59,8.75,3.45,11.54,12.24,11.36
                                    c27.5-0.54,55.02-0.49,82.52-0.09c7.31,0.1,10.5-1.1,11.06-9.42c1.5-22.24,4.57-44.37,6.29-66.6c1-12.94,6.56-19.78,19.69-22.73
                                    c32.38-7.28,63.11-19.14,91.75-36.26c33.44-20,25.92-20.26,56.76,4.63C782.91,860.19,798.2,872.76,814.26,885.85z"
                                        />
                                        <path
                                            d="M805.52,201.1c5.58-4.66,9.56-6.05,15.11-0.39c19.26,19.64,38.65,39.15,58.22,58.48c6.48,6.4,5.95,11.16-0.1,18.01
                                    c-14.77,16.71-28.27,34.54-42.89,51.4c-7.46,8.6-7.92,16.53-1.99,25.89c21.29,33.62,35.62,70.14,44.55,108.83
                                    c2.46,10.64,8.94,16.25,19.96,17.26c23.4,2.16,46.74,4.87,70.15,6.94c6.65,0.59,9.81,2.19,9.67,9.91
                                    c-0.51,28.28-0.55,56.59-0.06,84.87c0.14,8.12-2.95,10.07-10.26,10.68c-23.02,1.91-45.97,4.62-68.98,6.7
                                    c-10.68,0.96-16.57,6.63-18.9,16.74c-9.11,39.52-24.23,76.61-46.04,110.8c-5.52,8.65-3.95,15.48,1.87,22.69
                                    c14.51,17.99,28.67,36.27,43.49,54c5.72,6.84,6,10.97-0.98,17.64c-21.55,20.6-42.18,42.17-64.08,64.29
                                    c-16.05-13.09-31.35-25.66-46.76-38.1c-30.84-24.89-23.32-24.62-56.76-4.63c-28.64,17.13-59.37,28.99-91.75,36.26
                                    c-13.13,2.95-18.69,9.79-19.69,22.73c-1.72,22.23-4.79,44.36-6.29,66.6c-0.56,8.32-3.76,9.52-11.06,9.42
                                    c-27.5-0.39-55.02-0.45-82.52,0.09c-8.79,0.17-11.65-2.61-12.24-11.36c-1.52-22.64-4.26-45.19-6.27-67.8
                                    c-1-11.24-7.35-16.77-17.87-19.26c-38.29-9.05-74.03-24.33-107.34-45.21c-10.41-6.53-19.36-6.48-29.13,2.09
                                    c-18.55,16.27-37.93,31.6-57.97,48.15c-2.57-1.9-5.59-3.61-7.97-5.97c-19.86-19.61-39.41-39.53-59.5-58.89
                                    c-5.72-5.52-5.21-8.8-0.26-14.64c15.19-17.93,29.4-36.68,44.34-54.84c6.48-7.88,7.16-15.41,1.55-23.9
                                    c-22.69-34.31-36.6-72.28-46.07-112.01c-2.28-9.58-7.95-14.02-17.5-14.88c-23.81-2.13-47.57-4.76-71.39-6.69
                                    c-6.43-0.52-10.37-1.25-10.3-9.24c0.25-28.29,0.14-56.59-0.4-84.87c-0.16-8.34,2.94-10.96,10.94-11.62
                                    c22.62-1.88,45.15-4.72,67.75-6.87c11.78-1.12,18.7-6.71,21.45-18.59c8.68-37.53,23.33-72.71,43.95-105.24
                                    c7.27-11.47,6.5-20.59-2.76-30.85c-14.93-16.56-28.5-34.34-42.96-51.34c-4.42-5.21-5.02-8.8,0.35-14.01
                                    c20.31-19.72,40.39-39.67,60.05-60.03c5.72-5.92,9.58-4.78,14.96-0.17c17.26,14.76,35.07,28.9,52.1,43.93
                                    c9.54,8.42,18.59,9.33,29.09,2.45c33.34-21.83,69.83-36.34,108.5-45.29c13.89-3.21,16.85-12.79,17.83-24.63
                                    c1.73-21.04,3.89-42.05,5.21-63.12c0.53-8.48,3.41-11.68,12.28-11.52c27.89,0.5,55.79,0.36,83.68,0.03
                                    c6.84-0.08,8.71,2.43,9.26,8.94c1.93,23.01,4.9,45.94,6.71,68.96c0.92,11.63,6,18.13,17.47,20.7c38.81,8.69,75,23.88,108.62,45.08
                                    M540.93,263.53c-149.17-1.66-275,122.66-276.65,273.34c-1.65,151.07,123.32,279.1,274.8,279.41
                                    c154.82,0.32,275.63-119.45,277.06-271.12C817.71,377.48,690.64,266.17,540.93,263.53z"
                                        />
                                        <path
                                            fill="#4e4851"
                                            d="M540.93,263.53c149.71,2.64,276.78,113.95,275.21,281.62c-1.42,151.67-122.24,271.44-277.06,271.12
                                    c-151.48-0.31-276.45-128.34-274.8-279.41C265.93,386.19,391.76,261.87,540.93,263.53z M540.19,297.07
                                    c-120.29-3.96-240.87,99.51-242.52,237.79c-1.86,155.69,121.16,249.68,244.28,248.87c132.85-0.88,240.51-108.7,242.1-239.64
                                    C785.73,407.12,675.03,295.3,540.19,297.07z"
                                        />
                                        <path
                                            d="M540.19,297.07c134.84-1.78,245.54,110.05,243.87,247.02c-1.6,130.94-109.25,238.76-242.1,239.64
                                    c-123.12,0.82-246.14-93.17-244.28-248.87C299.32,396.58,419.9,293.11,540.19,297.07z M509.8,585.59c-3.67-3.47-6.56-6.17-9.42-8.9
                                    c-19-18.15-37.69-36.65-57.1-54.34c-9.82-8.95-22.68-6.41-25.31,5.63c-1.29,5.89,3.51,15.03,8.33,20.02
                                    c21.27,22.07,43.43,43.29,65.51,64.56c15.31,14.74,18.79,14.55,34.59,0.07c0.88-0.81,1.78-1.6,2.63-2.43
                                    c41.38-40.27,82.76-80.54,124.1-120.85c2.84-2.77,5.89-5.58,7.81-8.96c4.02-7.07,2.88-13.83-3.26-19.36
                                    c-5.77-5.19-12.06-5.98-18.36-1.03c-4.06,3.19-7.94,6.63-11.63,10.23C588.69,508.34,549.73,546.5,509.8,585.59z"
                                        />
                                        <path
                                            fill="#4e4851"
                                            d="M509.8,585.59c39.93-39.09,78.89-77.25,117.89-115.36c3.69-3.6,7.57-7.05,11.63-10.23
                                    c6.3-4.95,12.59-4.16,18.36,1.03c6.14,5.53,7.28,12.29,3.26,19.36c-1.92,3.38-4.98,6.2-7.81,8.96
                                    c-41.34,40.32-82.72,80.58-124.1,120.85c-0.86,0.83-1.75,1.63-2.63,2.43c-15.8,14.48-19.28,14.67-34.59-0.07
                                    c-22.09-21.27-44.24-42.49-65.51-64.56c-4.82-4.99-9.62-14.13-8.33-20.02c2.64-12.04,15.49-14.58,25.31-5.63
                                    c19.42,17.69,38.11,36.18,57.1,54.34C503.24,579.42,506.12,582.12,509.8,585.59z"
                                        />
                                    </g>
                                </svg>
                                <p className="custom-margin-top-3"> {t('RightSidebarMaintenanceDetails.Release_tab')}</p>
                            </button>
                        </li>
                    )}
                </ul>
                <div className="tab-content tab-custom-height" id="myTabContent">
                    <div className="tab-pane fade show active" id="MaintenanceTab" role="tabpanel" aria-labelledby="Maintenance-tab">
                        {actionValue === 'Maintenance_tab' && <MaintenanceDetailsTabContainer bedDetailData={props.bedDetailData} />}
                    </div>

                    <div className="tab-pane fade" id="releaseTab" role="tabpanel" aria-labelledby="release-tab">
                        {actionValue === 'Release_tab' && <MaintenanceReleaseTabContainer bedDetailData={props.bedDetailData} closeSideMenu={props.closeSideMenu} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceDetailsView;
