import React from 'react';
import { useTranslation } from 'react-i18next';

const CreateNewButtonView = (props: any) => {
    const { t } = useTranslation();
    return (
        <div>
            <a href="#" onClick={props.onClickCreateNewInvoiceNoHandler} className='btn btn-primary btn-create me-2 mb-3'><i className="fa-solid fa-plus"></i> {t('TableAction.Create_New')}</a>
            
        </div>
    );
};

export default CreateNewButtonView;