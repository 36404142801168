import React from 'react';
import ServiceReferralTableSearchView from './ServiceReferralTableSearchView';

const ServiceReferralTableSearchContainer = (props: any) => {
    return (
        <div>
            <ServiceReferralTableSearchView searchValue={props.searchValue} />
        </div>
    );
};

export default ServiceReferralTableSearchContainer;