import React, {useEffect, useState} from 'react';
import ReleaseTabView from './releaseTabView';
import { RootState } from '../../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { ReasonList } from '../../../../../../utils/constant';
import { getListReleaseReasons, releasePatientService } from '../../../../../../service/release-service';
import swal from 'sweetalert';
import eventEmitter from '../../../../../../utils/eventEmitter';
import warning from '../../../../../../assets/images/warning.png';

const ReleaseTabContainer = (props: any) => {
    const { StatusListReasons, DataListReasons } = useSelector((state: RootState) => state.realeaseReason);
    const [listReasons, setListReasons] = useState<any>([])
    const [amount,setamount] = useState<number>(0);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    useEffect(() => {
        async function getPatientDetails() {
            const payload: any = { id: ReasonList.Release};
            await getListReleaseReasons(payload);
        }
        getPatientDetails();
    },[])

    useEffect(() => {
        if(StatusListReasons === true) {
            setListReasons(DataListReasons.data)
        }
    },[StatusListReasons, DataListReasons]);


    useEffect(()=>{
        
        if(props.patientData?.reservationDetails){
          setamount(Number(props.patientData?.reservationDetails?.amount));
        }
       },[props.patientData])

    const onSubmitHandler = async(data: any) => {
        data.admissionId = props.patientData.admissionId
        if(data.admissionId !== undefined && data.admissionId !== '') {
            const payload ={
                admissionId:props.patientData.admissionId,
                releaseDate : window.$utils.dateFormatDDMMYYYYDB(data.releaseDate),
                reason_Id : Number(data.reason_Id),
                isWithCharge : data.charge === "withcharge" ? 1 : 0,
                amount : data.charge === "withcharge" ? Number(amount) : 0,
                remark : data.remark,
                serviceId:DataServiceDetail.serviceId
            }
           
            swal({
                title: '',
                text: `Are you sure you want to release Bed "${props.patientData.bedName}"?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    const response = await releasePatientService(payload)
                    if(response.status === true) {
                        if(data.charge === "withcharge") {
                            swal(`Bed "${props.patientData.bedName}" has been released and invoice "${response.data}" is created successfully.`, {
                                icon: 'success'
                            });
                        } else {
                            swal(`Bed "${props.patientData.bedName}" has been released successfully.`, {
                                icon: 'success'
                            });
                        }                        
                        props.closeSideMenu(true)
                        eventEmitter.emit('eventGetBedInformation');
                        eventEmitter.emit('eventGetWardInformation');
                        eventEmitter.emit('onAdmitReservePatient');
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    }

    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseReservedSideMenu');
    }

    return (
        <div>
            <ReleaseTabView listReasons={listReasons} patientData={props.patientData} onSubmitHandler={onSubmitHandler} updateData={updateData} setamount={setamount}/>
        </div>
    );
};

export default ReleaseTabContainer;