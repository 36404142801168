import React from 'react';
import FilterListView from './FilterListView';

const FilterListContainer = () => {
    return (
        <div>
            <FilterListView />
        </div>
    );
};

export default FilterListContainer;