import React from 'react';
import ContractingView from './ContractingView';

const ContractingContainer = () => {
    return (
        <div>
            <ContractingView />
        </div>
    );
};

export default ContractingContainer;