import React, { useEffect, useState } from 'react';
import InvoiceTableView from './InvoiceTableView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const InvoiceTableContainer = () => {
    const [invoiceStatistics, setInvoiceStatistics] = useState<any>();
    const { StatusInvoiceStatistics, DataInvoiceStatistics } = useSelector((state: RootState) => state.invoiceStatistics);

    useEffect(() => {
        if(StatusInvoiceStatistics === true) {
            setInvoiceStatistics(DataInvoiceStatistics.data)
        }
    },[DataInvoiceStatistics])
    return (
        <div>
          <InvoiceTableView invoiceStatistics={invoiceStatistics}/>  
        </div>
    );
};

export default InvoiceTableContainer;