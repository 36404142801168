import { Navigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import LoginPage from '../pages/LoginPage/indexContainer';
import AccessDenied from '../components/AccessDenied';
import DashboardPage from '../pages/DashboardPage/indexContainer';
import Layout from '../layout/Layout';
import { ROUTES_DATA } from './RoutesConstants';
import BillingContainer from '../pages/BillingManagementPage/BillingContainer';
import HelpContainer from '../pages/Help/HelpContainer';
import ReferralManagementContainer from '../pages/ReferralManagementPage/ReferralManagementContainer';
import ContractSignedSuccess from '../components/ContractSignedSuccess';
import ChangePasswordContainer from '../pages/ChangePassword/ChangePasswordContainer';
import NHSContainer from '../pages/NHSPage/NHSContainer';
import IntegrationInterfaceContainer from '../pages/IntegrationInterface/IntegrationInterfaceContainer';
import NHSServiceDetailContainer from '../pages/NHSPage/NHSServiceDetail/NHSServiceDetailContainer';
import MapViewContainer from '../pages/ReferralManagementPage/ReferralManager/MapView/MapViewContainer';
import ProviderServiceDetailView from '../pages/NHSPage/NHSServiceDetail/ServiceTab/Services/ProviderServiceDetail/ProviderServiceDetailView';
import MapViewServiceContainer from '../pages/ReferralManagementPage/ServiceManager/MapViewService/MapViewServiceContainer';
import NHSReferralManagementContainer from '../pages/NHSPage/NHSReferralManagement/NHSReferralManagementContainer';
import GenericErrorComponentSplit from '../components/GenericErrorComponentSplit';


const Routing = [
    {
        element: <PublicRoutes />,
        children: [
            {
                path: ROUTES_DATA.ROOT,
                element: <Navigate to={ROUTES_DATA.LOGIN} />
            },
            {
                path: ROUTES_DATA.LOGIN,
                element: <LoginPage />
            }
        ]
    },
    {
        element: <PrivateRoutes />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: ROUTES_DATA.DASHBOARD,
                        element: <DashboardPage />
                    },
                    {
                        path: ROUTES_DATA.BILLINGMANAGEMENT,
                        element: <BillingContainer />
                    },
                    {
                        path: ROUTES_DATA.REFERRALMANAGEMENT,
                        element: <ReferralManagementContainer />
                    },
                    {
                        path: ROUTES_DATA.HELP,
                        element: <HelpContainer />
                    },
                    {
                        path: ROUTES_DATA.CHANGEPASSWORD,
                        element: <ChangePasswordContainer />
                    },
                    {
                        path: ROUTES_DATA.NHS,
                        element: <NHSContainer />
                    },
                    {
                        path: ROUTES_DATA.INTEGRATION,
                        element: <IntegrationInterfaceContainer />
                    },
                    {
                        path: ROUTES_DATA.INTEGRATION,
                        element: <IntegrationInterfaceContainer />
                    },
                    {
                        path: ROUTES_DATA.NHS,
                        element: <NHSContainer />
                    },
                    {
                        path: ROUTES_DATA.NHSREFERRAL,
                        element: <NHSReferralManagementContainer />
                    },
                    {
                        path: `${ROUTES_DATA.SERVICEDETAIL}/:serviceSlug`,
                        element: <NHSServiceDetailContainer />
                    },
                    {
                        path: ROUTES_DATA.REFERRALMANAGERVIEWMAP,
                        element: <MapViewContainer />
                    },
                    {
                        path: `${ROUTES_DATA.PROVIDERSERVICEDETAIL}/:id`,
                        element: <ProviderServiceDetailView />
                    },
                    {
                        path: ROUTES_DATA.SERVICEMANAGERVIEWMAP,
                        element: <MapViewServiceContainer />
                    }

                ]
            }
        ]
    },
    {
        path: ROUTES_DATA.AccessDenied,
        element: <AccessDenied />
    },
    {
        path: ROUTES_DATA.ContractSigned,
        element: <ContractSignedSuccess />
    },
    {
        path: ROUTES_DATA.ERRORPAGE,
        element: <GenericErrorComponentSplit />
    }
];
export default Routing;
