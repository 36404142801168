import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListTaskModel } from '../../utils/model';

const initialState: ListTaskModel = {
    StatusListTask: false,
    DataListTask: '',
    MessageListTask: '',
    ErrorListTask: ''
};

export const listTaskSlice = createSlice({
    name: 'listTask',
    initialState,
    reducers: {
        setListTaskAction: (state, { payload }: PayloadAction) => {
            state.StatusListTask = true;
            state.DataListTask = payload;
            state.MessageListTask = 'Success';
        },
        errorListTaskAction: (state, { payload }: PayloadAction) => {
            state.StatusListTask = false;
            state.DataListTask = [];
            state.MessageListTask = 'Failed';
            state.ErrorListTask = payload;
        },
        removeListTaskAction: (state, { payload }: PayloadAction) => {
            state.StatusListTask = false;
            state.DataListTask = '';
            state.MessageListTask = '';
            state.ErrorListTask = '';
        }
    },
    extraReducers: {}
});

export const { setListTaskAction, errorListTaskAction, removeListTaskAction } = listTaskSlice.actions;

export default listTaskSlice.reducer;
