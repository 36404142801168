
import React, { useEffect, useRef, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import AmenitiesTabContentContainer from "./AmenitiesTabContent/AmenitiesTabContentContainer";

import BathroomIcon from "../../../../../../assets/images/bathroom.svg";
import RoomIcon from "../../../../../../assets/images/room-icon.svg";
import AccessibilityIcon from "../../../../../../assets/images/accessibility-icon.svg";
import EntertainmentIcon from "../../../../../../assets/images/entertainment-icon.svg";
import HeatingtIcon from "../../../../../../assets/images/heating-icon.svg";
import KitchentIcon from "../../../../../../assets/images/kitchen-icon.svg";
import LaundryIcon from "../../../../../../assets/images/laundry-icon.svg";
import OutdoorIcon from "../../../../../../assets/images/outdoor-icon.svg";
import ActivitiesIcon from "../../../../../../assets/images/activities-icon.svg";
import LeisureIcon from "../../../../../../assets/images/leisure-icon.svg";
import ListIcon from "../../../../../../assets/images/list-icon.svg";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import RenderModalContainer from "../../../../../../components/RenderModal/indexContainer";
import AmenitiesModalContainer from "../../../../../../components/AmenitiesModal/AmenitiesModalContainer";
import { GetAmmenitiesServiceLists } from "../../../../../../service/service-details-service";
import { useParams } from "react-router-dom";
import ClientPortal from "../../../../../../components/ClientPortal";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";



const AmenitiesSliderView = (props: any) => {
  const [ammenities, setAmmenities] = useState<any>(null);
  const [ammenitiesCount, setAmmenitiesCount] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [showPortal, setShowPortal] = useState(false);
  const { t } = useTranslation();
  const params = useParams();
  useEffect(() => {
    AOS.init();
  }, [])

  const [stagePadding, setStagePadding] = useState(0);

  const targetElementRef = useRef<HTMLDivElement>(null);

  const handleModal = (event: any, id: any) => {
    event.preventDefault();
    setSelectedTab(id)
    setShowPortal((prevState) => !prevState);
  };
  useEffect(() => {
    if (showPortal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [showPortal]);

  useEffect(() => {
    GetServiceDetailsList();
  }, [])

  const GetServiceDetailsList = async () => {
    const result = await GetAmmenitiesServiceLists(params?.serviceSlug)
    setAmmenities(result?.data)
    setAmmenitiesCount(result?.totalAminityCount)
  }

  const handleTabClick = (tabId: any) => {
    setSelectedTab(tabId);
  };


  useEffect(() => {
    const calculateSpace = () => {
      const targetElement = targetElementRef.current;
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const spaceToLeft = rect.left;
        console.log("Space to the left of the target element:", spaceToLeft);
        return spaceToLeft;
      }
      return 0;
    };

    const calculatedPadding = calculateSpace();
    setStagePadding(calculatedPadding);
  }, []);


  return (
    <div>
      <div className="service-section-title">
        <div className="container-fluid container-fluid-service p-0">
          <div className="row" ref={targetElementRef}>
            <div className="col-lg-12 p-0">
              <div>
                <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                  {t("Amenities.Comfort_Safety_in_every_corner")}
                </h4>
                <p className="section-sub-title text-center mb-4 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                  {t("Amenities.Amenities")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid service-slider" > 
        <div className="row">
          <div className="col-lg-12 p-0">
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600" data-aos-once="true">
              {ammenities && ammenities?.length > 0 &&
                <OwlCarousel
                  className="owl-theme amenities-owl all-owl"
                  dots={false}
                  margin={20}
                  mouseDrag={true}
                  smartSpeed={800}
                  touchDrag={true}
                  nav={true}
                  // stagePadding={stagePadding}
                  responsive={{
                    // "0": { items: 2 },
                    // "768": { items: 3 },
                    "1200": { items: 4 },
                    "1400": { items: 4 },
                  }}
                >
                  {ammenities && ammenities?.length > 0 && ammenities.map((am: any) => {
                    return (
                      <div className="item">
                        <div className="amenities-box">
                          <img src={am?.amenityCategoryIcon} alt="Bathroom Icon" style={{"width":"33px"}} />
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <p className="lead-text bold">{t(am?.amenityCategoryName)}</p>
                            {/* <img src={AccessibilityIcon.src} alt="Bathroom Icon" style={{"width":"22px"}} /> */}
                          </div>
                          <ul className="list-unstyled amenities-list mb-0">
                            {am && am.amenityMasters && am.amenityMasters.length > 0 && am.amenityMasters.map((master: any, index: any) => {
                              if (index <= 4) {
                                return (
                                  <li data-tooltip-id={master?.amenityMasterName.length > 25 ? "my-tooltip" : ""} data-tooltip-content={master?.amenityMasterName} >
                                    {master?.amenityMasterName.length > 25 ? master?.amenityMasterName.substring(0, 25) + '...' : master?.amenityMasterName}
                                    {master?.isCommonAreaFacilities && <div className="common-icon ms-auto"><i className="fa-solid fa-house"></i></div>}
                                  </li>
                                )
                              }

                            })}
                          </ul>
                          {am?.amenityMasters?.length > 5 &&
                            <>
                              <a href="#" onClick={(e) => handleModal(e, am?.amenityCategoryId)} className="see-more-modal">See more</a>
                              <div className="d-flex justify-content-end">
                                <a href="#" onClick={(e) => handleModal(e, am?.amenityCategoryId)} aria-labelledby="Click Text" className="click-btn">
                                  <i className="fa-solid fa-plus"></i>
                                </a>
                              </div>
                            </>
                          }

                        </div>
                      </div>
                    )
                  })}
                </OwlCarousel>}
            </div>


            <Tooltip id="my-tooltip" className="my-tooltip" />
            {/* <p className="common-amenities-text"><i className="fa-solid fa-house me-2"></i><i>Common area amenities</i></p> */}

            <div className="amenities-section-btn"  >
              <a
                onClick={(e) => handleModal(e, ammenities[0]?.amenityCategoryId)}
                className="btn primary-btn btn-amenities"
                data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600" data-aos-once="true"
              >
                {t("Amenities.Show_all")} {ammenitiesCount} {t("Amenities.Amenities_show")} <span className="arrow"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ClientPortal selector="myportal" show={showPortal}>
        <div className="overlay" onClick={(e) => handleModal(e, null)}></div>
        <div className="modal modal-amenities">
          <div className="header mb-3">
            <h4 className="mb-0">{t("Amenities.Amenities")}</h4>
            <a onClick={(e) => handleModal(e, null)} className="removeButton">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path
                  fill="#ffffff"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                />
              </svg>
            </a>
          </div>
          <div className="body">
            <ul
              className="nav nav-pills amenities-pill mb-2 pb-2 border-bottom"
              id="pills-tab"
              role="tablist"
            >
              {ammenities && ammenities?.length > 0 && ammenities.map((am: any) => {
                return (
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${selectedTab === am.amenityCategoryId ? 'active' : ''}`}
                      id={`pills-${am.amenityCategoryName.toLowerCase()}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${am.amenityCategoryName.toLowerCase()}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${am.amenityCategoryName.toLowerCase()}`}
                      aria-selected="true"
                      onClick={() => handleTabClick(am.amenityCategoryId)}
                    >
                      {am?.amenityCategoryName}
                      <div className="icon ms-2">
                      <img src={am?.amenityCategoryIcon} alt={am?.amenityCategoryName} style={{"width":"22px"}} />
                      </div>
                    </button>
                  </li>
                )
              })}
            </ul>
            <div className="tab-content amenities-pill-conent" id="pills-tabContent">
              {ammenities && ammenities?.length > 0 && ammenities.map((am: any) => {
                return (
                  <div
                    key={am.amenityCategoryId}
                    className={`tab-pane fade ${selectedTab === am.amenityCategoryId ? 'show active' : ''}`}
                    id={`pills-${am.amenityCategoryName.toLowerCase()}`}
                    role="tabpanel"
                    aria-labelledby={`pills-${am.amenityCategoryName.toLowerCase()}-tab`}
                  //tabIndex={0}
                  >
                    <AmenitiesTabContentContainer amenities={am?.amenityMasters} selectedTab={selectedTab} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </ClientPortal>
    </div>
  );
};

export default AmenitiesSliderView;
