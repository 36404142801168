import React, {useState, useEffect} from 'react';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { LeadFilterReferralStatus, LeadFilterServiceType, LeadFilterServiceUserType } from '../../../../../utils/constant';

const TableFilterView = (props: any) => {
    const { t } = useTranslation();
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    let [isCreationDate, setIsCreationDate] = useState<any>(false);

    let [creationDateToValue, setCreationDateToValue] = useState<any>('');
    let [creationDateFromValue, setCreationDateFromValue] = useState<any>('');

    let [openChecked, setOpenChecked] = useState<any>(false);
    let [closedChecked, setClosedChecked] = useState<any>(false);
    let [hospitalChecked, setHospitalChecked] = useState<any>(false);
    let [nursingCareChecked, setNursingCareChecked] = useState<any>(false);
    let [residentialCareChecked, setResidentialCareChecked] = useState<any>(false);
    let [supportedLivingChecked, setSupportedLivingChecked] = useState<any>(false);
    let [D2AChecked, setD2AChecked] = useState<any>(false);
    let [respiteChecked, setRespiteChecked] = useState<any>(false);
    let [longTermChecked, setLongTermChecked] = useState<any>(false);

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const onCreationDateSubmitHandler = () => {
        if(creationDateToValue !== '' && creationDateFromValue !== ''){
            setIsCreationDate(true);
            let data = {
                isCreationDate: true,
                creationDateTo: dayjs(creationDateToValue).format('YYYY-MM-DD'),
                creationDateFrom: dayjs(creationDateFromValue).format('YYYY-MM-DD')
            }
            props.changeDatePayloadHandler(data)
        }
    };

    const onChangeCreationDateSubmitHandler = (e: any) => {
        setIsCreationDate(false);
        setCreationDateToValue('');
        setCreationDateFromValue('');
        let data = {
            isCreationDate: false
        }
        props.changeDatePayloadHandler(data)
    };

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.filter?.isCreationDate === true && DataGridFilterState?.filter?.creationDateTo !== '' && DataGridFilterState?.filter?.creationDateFrom !== '') {
                setIsCreationDate(DataGridFilterState?.filter?.isCreationDate);
                setCreationDateToValue(dayjs(DataGridFilterState?.filter?.creationDateTo));
                setCreationDateFromValue(dayjs(DataGridFilterState?.filter?.creationDateFrom));
            }

            if(DataGridFilterState?.filter?.referralStatus?.length > 0) {
                if(DataGridFilterState?.filter?.referralStatus.includes(LeadFilterReferralStatus.Open)) {
                    setOpenChecked(true)
                } else {
                    setOpenChecked(false)
                }
                if(DataGridFilterState?.filter?.referralStatus.includes(LeadFilterReferralStatus.Closed)) {
                    setClosedChecked(true)
                } else {
                    setClosedChecked(false)
                }
            } else {
                setOpenChecked(false)
                setClosedChecked(false)
            }

            if(DataGridFilterState?.filter?.serviceType?.length > 0) {
                if(DataGridFilterState?.filter?.serviceType.includes('Hospital')) {
                    setHospitalChecked(true)
                } else {
                    setHospitalChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceType.includes('Nursing Care')) {
                    setNursingCareChecked(true)
                } else {
                    setNursingCareChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceType.includes('Residential Care')) {
                    setResidentialCareChecked(true)
                } else {
                    setResidentialCareChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceType.includes('Supported Living')) {
                    setSupportedLivingChecked(true)
                } else {
                    setSupportedLivingChecked(false)
                }
            } else {
                setHospitalChecked(false)
                setNursingCareChecked(false)
                setResidentialCareChecked(false)
                setSupportedLivingChecked(false)
            }

            if(DataGridFilterState?.filter?.serviceUserType.length > 0) {
                if(DataGridFilterState?.filter?.serviceUserType.includes('D2A')) {
                    setD2AChecked(true)
                } else {
                    setD2AChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceUserType.includes('Respite')) {
                    setRespiteChecked(true)
                } else {
                    setRespiteChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceUserType.includes('Long Term')) {
                    setLongTermChecked(true)
                } else {
                    setLongTermChecked(false)
                }
            } else {
                setD2AChecked(false)
                setRespiteChecked(false)
                setLongTermChecked(false)
            }
        }
    },[StatusGridFilterState, DataGridFilterState])

     const IsFilterApplied = () => {
        if(DataGridFilterState?.filter?.referralStatus.length > 0 || DataGridFilterState?.filter?.serviceType?.length > 0 || DataGridFilterState?.filter?.serviceUserType.length > 0 || DataGridFilterState?.filter?.isCreationDate === true && DataGridFilterState?.filter?.creationDateTo !== '' && DataGridFilterState?.filter?.creationDateFrom !== '' ){
            return true
        }
        return false
     }

    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdownMenuLink5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="">
                        <i className="fa-solid fa-filter"></i> {t('ReferralTable.Filter')}
                    </div>
                </a>
                <div className="dropdown-menu profile-dw-css" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                <div className="dropdown sub-dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdownMenuLink1"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <label>
                                <input type="checkbox" name="isCreationDate" checked={isCreationDate} onChange={(e: any) => onChangeCreationDateSubmitHandler(e)} className='me-1' /> {t('ReferralTable.Creation_Date')}<i className="fa-solid fa-caret-right ms-auto"></i>
                            </label>
                        </a>
                        <div className="dropdown-menu dropdown-submenu dropdown-submenu-custom" aria-labelledby="navbarDropdownMenuLink1">
                            <div className="d-flex date-filter">
                            <div className="datepicker-input-css me-3">
                                    <p className=" mb-1">{t('ReferralTable.From')}</p>
                                    <DatePicker onChange={(date: any) => setCreationDateFromValue(date)} value={creationDateFromValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                                <div className="datepicker-input-css  ">
                                    <p className=" mb-1">{t('ReferralTable.To')}</p>
                                    <DatePicker onChange={(date: any) => setCreationDateToValue(date)} value={creationDateToValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                               
                            </div>
                            <div className="d-flex justify-content-center">
                                <a onClick={onCreationDateSubmitHandler}  className="btn btn-primary btn-sm mt-3 me-2">
                                {t('ReferralTable.Submit_btn')} 
                                </a>
                                <a onClick={onChangeCreationDateSubmitHandler} className="btn btn-secondary btn-sm mt-3">
                                {t('ReferralTable.Clear_btn')} 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={LeadFilterReferralStatus.Open} name="open" checked={openChecked} className="me-1" /> {t('ReferralTable.Open')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={LeadFilterReferralStatus.Closed} name="closed" checked={closedChecked}  className="me-1" /> {t('ReferralTable.Closed')}
                        </label>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Hospital" name="hospital" checked={hospitalChecked}  className="me-1" /> {t('ReferralTable.Hospital')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Nursing Care" name="nursingCare" checked={nursingCareChecked}  className="me-1" /> {t('ReferralTable.Nursing_Care')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Residential Care" name="residentialCare" checked={residentialCareChecked}  className="me-1" /> {t('ReferralTable.Residential_Care')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Supported Living" name="supportedLiving" checked={supportedLivingChecked} className="me-1" /> {t('ReferralTable.Supported_Living')}
                        </label>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="D2A" name="D2A" checked={D2AChecked}  className="me-1" /> {t('ReferralTable.D2A')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Respite" name="respite" checked={respiteChecked}  className="me-1" /> {t('ReferralTable.Respite')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Long Term" name="longTerm" checked={longTermChecked}  className="me-1" /> {t('ReferralTable.Long_Term')}
                        </label>
                    </div>
                    

                    
                   
                </div>
            </div>
        </div>
    );
};

export default TableFilterView;