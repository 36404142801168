import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { setBedInformationAction, errorBedInformationAction } from '../store/dashboard/bedInformationReducer';
import { setPendingActivitiesAction, errorPendingActivitiesAction } from '../store/dashboard/pendingActivitiesReducer';
import { store } from '../store';
import { errorWardInformationAction, setWardInformationAction } from '../store/dashboard/wardinformationReducers';
import { errorWardBedInformationAction, setWardBedInformationAction } from '../store/dashboard/wardBedinformationReducer';
import instance from './axiosinstance';
import { errorCityInformationAction, setCityInformationAction } from '../store/dashboard/getCityReducer';
import { errorPatientInformationAction, setPatientInformationAction } from '../store/dashboard/getPatientTypeReducer';
import { errorFunderInformationAction, setFunderInformationAction } from '../store/dashboard/getFunderReducer';
import { errorPatientDraftAction, setPatientDraftAction } from '../store/dashboard/draftPatientData';
import eventEmitter from '../utils/eventEmitter';
import { errorRelationshipInformationAction, setRelationshipInformationAction } from '../store/dashboard/getRelationshipReducer';
import { errorPlanDataAction, setPlanDataAction } from '../store/splitFunding/planDataReducer';

export const getBedInformationService = (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetBedInformation}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setBedInformationAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorBedInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorBedInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getPendingActivitiesService = (payload: any) => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetPendingActivities}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setPendingActivitiesAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPendingActivitiesAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPendingActivitiesAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getWardData = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetWardDetails}/${payload.serviceId}`,)
        
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            let wardinfo = resultData.data.map((res: any) => ({
                ...res,
                active: false
            }));
            
            
            
            resultData.data = [];

            for (let i = 0; i < wardinfo.length; i++) {
                resultData.data.push(wardinfo[i]);
                if (i === 0) {
                    resultData.data[i].active = true;
                } else {
                    resultData.data[i].active = false;
                }
            }
             
            store.dispatch(setWardInformationAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorWardInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorWardInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const getBedData = (payload: any, wid: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetBedsDetails}`, payload)
        
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setWardBedInformationAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorWardBedInformationAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorWardBedInformationAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getFunderData = (id:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetFunderDetails}?funderTypeId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            
            store.dispatch(setFunderInformationAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorFunderInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorFunderInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getCityData = () => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        
        .get(`${BASEURLDEV}${API_ENDPOINT.GetCityDetails}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            
            store.dispatch(setCityInformationAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorCityInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorCityInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const getPatientTypeData = () => {
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPatientTypeList}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            
            store.dispatch(setPatientInformationAction(resultData));
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPatientInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPatientInformationAction(errorData));
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const saveAdmissionData = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveAdmission}`,payload)
        
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const saveDraftData = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveDraft}`,payload)
        
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getPatientDraftData = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPatientDraftData}?draftPatientId=${payload.id}&serviceId=${payload.serviceId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            
            store.dispatch(setPatientDraftAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPatientDraftAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPatientDraftAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};
export const deleteDraftPatient = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    const state = store.getState();
    return instance
    
        .delete(`${BASEURLDEV}${API_ENDPOINT.DeleteDraftPatient}/${payload}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getPatientReservedData = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetReservedPatientDetails}?patientId=${payload.id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            
            store.dispatch(setPatientDraftAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPatientDraftAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPatientDraftAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getPatientReservedDataForContract = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetReservedPatientDetails}?patientId=${payload.id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getContactDocument = (payload:any) => {
    const state = store.getState();
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.ContractFilePath}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const deleteContactDocument = (payload:any) => {
    const state = store.getState();
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .delete(`${BASEURLDEV}${API_ENDPOINT.DeleteContractFile}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getRelationshipData = (id:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetRelationshipDetails}?genderId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setRelationshipInformationAction(resultData));
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorRelationshipInformationAction(errorData));
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorRelationshipInformationAction(errorData));
                return errorData;
            }
        });
};

// export const getPlanData = (id:any) => {
//     return instance
//         .get(`${BASEURLDEV}${API_ENDPOINT.GetPlanDetails}?admissionId=${id}`)
//         .then((response: AxiosResponse) => {
//             const resultData: any = response.data as DataResponseModel;
//             return resultData;
//         })
//         .catch((error: AxiosError) => {
//             
//             if (error.code === 'ERR_NETWORK') {
//                 const errorResponse = {
//                     status: false,
//                     data: [],
//                     message: error.message,
//                     totalCount: 0
//                 };
//                 const errorData: any = errorResponse as DataResponseModel;
//                 return errorData;
//             } else {
//                 const errorResponse = {
//                     status: false,
//                     data: [],
//                     message: error.message,
//                     totalCount: 0
//                 };
//                 const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
//                 return errorData;
//             }
//         });
// };

export const getPlanData = (id:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPlanDetails}?admissionId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setPlanDataAction(resultData));
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPlanDataAction());
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPlanDataAction());
                return errorData;
            }
        });
};

export const getServiceAdd = (sid:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetServiceAdd}?serviceId=${sid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPlanDataAction());
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPlanDataAction());
                return errorData;
            }
        });
};



export const getAllPlanData = (payload:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetAllPlans}`,payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getAdmitPatientByNhs = (nhslocalId:any,isNhsId:any,admissionId:any) => {
    return instance
    .get(`${BASEURLDEV}${API_ENDPOINT.GetAdmitPatientByNhs}?nhslocalId=${nhslocalId}&isNhsId=${isNhsId}&admissionId=${admissionId}`)   
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getInternalLocalConfigData = (serviceId:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetInternalLocalIdConfig}?serviceId=${serviceId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
                        
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);                
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const removeFunderPlan = (payload:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.RemoveFunderFromPlan}`,payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const ExportAdmission = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ExportAdmission}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getInclusiondisplay = (serviceId:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.IsInclusionDisplay}?serviceId=${serviceId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
                        
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;

                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);                

                return errorData;
            }
        });
};