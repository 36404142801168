import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import swal from 'sweetalert';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';
import { ReasonList } from '../../../../../../utils/constant';
import eventEmitter from '../../../../../../utils/eventEmitter';

const TransferTabDeatil = (props: any) => {
    const { listVacantBeds } = props;
    const { t } = useTranslation();
    const wardRefs = useRef<any>([]);
    const roomRefs = useRef<any>([]);
    const [activeID, setActiveID] = useState<any>(0);
    const [activeWard, setActiveWard] = useState<any>(0);
    const [disableButton, setDisableButton] = useState<any>(false);
    const [rtype, setrtype] = useState<any>('');
    let [reasonSmartSearch, setReasonSmartSearch] = useState<any>(undefined);

    const setData = (data: any) => {
        props.setData(data);
        props.updateData(data);
        setActiveID(data.bedId);
    };

    const setWardDataHandler = (data: any) => {
        const wardData = {
            wardId: data.wardId,
            wardName: data.wardName
        };
        props.setWardData(wardData);
        props.updateData(wardData);
        setActiveWard(wardData);
    };

    const validationSchema = Yup.object().shape({
        reasonId: Yup.string().required('Reason is required'),
        remark: Yup.string().notRequired()
    });

    useEffect(() => {
        eventEmitter.on('eventSmartSearch', eventSmartSearch);
        return () => {
            eventEmitter.off('eventSmartSearch', eventSmartSearch);
        };
    });

    const eventSmartSearch = () =>{
        setValue('reasonId','',{shouldValidate: true});
        setReasonSmartSearch(undefined);
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
        setValue
    } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

    const onSubmitHandler: SubmitHandler<any> = (data) => {
        props.onSubmitHandler(data);
    };

    useEffect(() => {
        reset();
        setrtype('');
        setActiveID(0)
        setWardDataHandler(0)
        if(wardRefs?.current?.length > 0) {
           for(let i=0; i < wardRefs?.current?.length; i++) {
                wardRefs.current[i].classList.remove("active")
           }
           wardRefs.current[0].classList.add("active") 
        }
        if(roomRefs?.current?.length > 0) {
            for(let i=0; i < roomRefs?.current?.length; i++) {
                 roomRefs.current[i].classList.remove("show")
                 roomRefs.current[i].classList.remove("active")
            }
            roomRefs.current[0].classList.add("show") 
            roomRefs.current[0].classList.add("active") 
         }
    }, [props.patientData]);
    
    useEffect(() => {
        if (listVacantBeds !== undefined && listVacantBeds !== '' && listVacantBeds !== null && listVacantBeds.length > 0) {
            const wardData = {
                wardId: listVacantBeds[0].wardId,
                wardName: listVacantBeds[0].wardName
            };
            props.setWardData(wardData);
            setActiveWard(wardData);
        }
    }, [listVacantBeds]);
    useEffect(() => {
        if(isValid && activeID > 0) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [isValid, activeID]);

    useEffect(() => {
        if(reasonSmartSearch === undefined){
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLReasons}/${ReasonList.Transfer}`
            };
            setReasonSmartSearch(objReasonSmartSearch);
        }
       
    }, [reasonSmartSearch]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <p className="bold mt-1 purple-text text-center mb-2"> {t('OccupiedRightBar.Ward_No')}</p>
                <ul className="nav nav-tabs nav-pills ward-room-pills" id="myTab" role="tablist">
                    {props.listVacantBeds !== undefined &&
                        props.listVacantBeds !== '' &&
                        props.listVacantBeds !== null &&
                        props.listVacantBeds.length > 0 &&
                        props.listVacantBeds.map((wData: any, index: any) => {
                            return (
                                <li key={wData.wardId} className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${index === 0 ? 'active' : ''}`}
                                        ref={(element) => wardRefs.current[index] = element}
                                        onClick={() => setWardDataHandler(wData)}
                                        id={`ward-${wData.wardId}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#ward-${wData.wardId}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`ward-${wData.wardId}`}
                                        aria-selected={`${index === 0 ? 'true' : 'false'}`}
                                    >
                                        {wData.wardName}
                                    </button>
                                </li>
                            );
                        })}
                    {/* <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                        Ward A
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                        Ward B
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                        Ward C
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                        Ward D
                    </button>
                </li> */}
                </ul>
                <p className="bold mt-2 purple-text text-center">{t('OccupiedRightBar.Bed_No')}</p>
                <div className="tab-content ward-room-detail" id="myTabContent">
                    {props.listVacantBeds !== undefined &&
                        props.listVacantBeds !== '' &&
                        props.listVacantBeds !== null &&
                        props.listVacantBeds.length > 0 &&
                        props.listVacantBeds.map((wData: any, index: any) => {
                            return (
                                <div
                                    ref={(element) => roomRefs.current[index] = element}
                                    key={wData.wardId}
                                    className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                    id={`ward-${wData.wardId}`}
                                    role="tabpanel"
                                    aria-labelledby={`ward-${wData.wardId}-tab`}
                                >
                                    <ul className="list-unstyled room-list-tile">
                                        {wData.bedDetails.length > 0 &&
                                            wData.bedDetails.map((bData: any) => {
                                                return (
                                                    <li className={`${activeID === bData.bedId ? 'cus_active' : ''}`} key={bData.bedId} onClick={() => setData(bData)}>
                                                        <a href="#">{bData.bedName}</a>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            );
                        })}
                </div>

                {/* <select {...register('reasonId', {
                        onChange: (e: any) => {
                            setrtype(e.target.value);
                            props.updateData(e);
                        }
                    })} value={rtype} id="" className={`form-control white mb-2 ${errors.reasonId ? 'is-invalid' : ''}`}>
                    <option disabled={true} value="">Select Reason*</option>
                    {props.listReasons !== undefined &&
                        props.listReasons !== null &&
                        props.listReasons !== '' &&
                        props.listReasons.length > 0 &&
                        props.listReasons.map((val: any) => {
                            return (
                                <option key={val.reasonId} value={val.reasonId}>
                                    {val.reasonText}
                                </option>
                            );
                        })}
                </select> */}
                {reasonSmartSearch !== undefined && (
                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                        <SmartSearchDropdownContainer
                            smartSearchLabel="Select Reason*"
                            errors={errors.reasonId}
                            onChangeSmartSearch={(value: any) => {
                                const event = new Event('change');
                                let obj = {
                                    name: 'reasonId',
                                    value: value?.id?.toString()
                                };
                                //@ts-ignore
                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                setValue('reasonId', value?.id?.toString(), { shouldValidate: true });
                                setrtype(value?.id);
                                if (value !== '') {
                                    props.updateData(event);
                                }
                            }}
                            smartSearch={reasonSmartSearch}
                        />
                    </div>
                )}
                <textarea {...register('remark', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} placeholder="Comment" className="form-control white"></textarea>
                <div className="mt-2 text-center right_content_btn">
                    <button type="submit" disabled={!disableButton} className="btn btn-primary flex-1 me-2" id="save-funder1">
                        {t('OccupiedRightBar.Transfer_btn')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TransferTabDeatil;
