import React, { useEffect, useState } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { validateCurrentPasswordService, changePasswordService } from '../../service/auth-service';
import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';
import ClientPortal from "../../components/ClientPortal";

const ChangePasswordModalView = (props: any) => {
    const { t } = useTranslation();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const [currentMatch, setCurrentMatch] = useState<any>(undefined);
    const [currentAndNewMatch, setCurrentAndNewMatch] = useState<any>(undefined);
    const [disabled, setDisabled] = useState<any>(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [newpasswordShown, setnewPasswordShown] = useState(false);
    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);
    // useEffect(() => {
    //     document.body.classList.add("remove-blur");
    //     return () => {
    //         document.body.classList.remove("remove-blur"); 
    //     }
    // }, []);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const togglenewPassword = () => {
        setnewPasswordShown(!newpasswordShown);
    };
    const toggleconfirmPassword = () => {
        setconfirmPasswordShown(!confirmpasswordShown);
    };

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required(),
        newPassword: Yup.string()
            .required('Please Enter your password.')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'),
        confirmPassword: Yup.string()
            .required('Please Enter your confirm password.')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
            .oneOf([Yup.ref("newPassword")], "Passwords do not match")
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    const watchAllFields = watch();

    useEffect(() => {
        if (currentMatch && currentAndNewMatch && formState.isValid) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [currentMatch, currentAndNewMatch, formState.isValid]);

    const validateCurrentPassword = async () => {
        if (watchAllFields.currentPassword !== undefined && watchAllFields.currentPassword !== '') {
            let payload = {
                currentPassword: window.$crypto.OpenSSLEncrypt(watchAllFields.currentPassword),
                newPassword: "",
                confirmPassword: ""
            }
            const response = await validateCurrentPasswordService(payload)
            if (response.status) {
                setCurrentMatch(true)
            } else {
                setCurrentMatch(false)
            }
        }
    }

    const validateNewPassword = () => {
        if (watchAllFields.newPassword !== '' && (watchAllFields.currentPassword === watchAllFields.newPassword)) {
            setCurrentAndNewMatch(false)
        } else {
            setCurrentAndNewMatch(true)
        }
    }

    const onClickClearHandler = () => {
        reset({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        }, {
            keepValues: false,
        })
        setCurrentMatch(undefined)
        setCurrentAndNewMatch(undefined)
        setPasswordShown(false)
        setnewPasswordShown(false)
        setconfirmPasswordShown(false)
    }

    const onChangePasswordHandler = async () => {
        let payload = {
            currentPassword: window.$crypto.OpenSSLEncrypt(watchAllFields.currentPassword),
            newPassword: window.$crypto.OpenSSLEncrypt(watchAllFields.newPassword),
            confirmPassword: window.$crypto.OpenSSLEncrypt(watchAllFields.confirmPassword)
        }
        const response = await changePasswordService(payload)
        if (response.status === true) {
            swal(response.message, {
                icon: 'success'
            });
            window.$utils.signOutUser()
        } else {
            swal(response.message, {
                icon: warning,
                dangerMode: true
            });
        }
    }

    return (
        <div className="modal">
            <div className="header mb-3" onClick={props.onClose}>
                <h4 className="mb-0">Change Password</h4>
                <a className="removeButton">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                    >
                        <path
                            fill="#ffffff"
                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                        />
                    </svg>
                </a>
            </div>
            <div className="white-bg change-password-box mt-5">
                <div className="row align-items-center">
                    <div className="col-lg-7 border-right">
                        <div className="border-right-box">
                            <div className="d-flex mb-3">
                                <label className="mb-1 bold">{t('Change_Password.User_Email')}</label>
                                <div className="position-relative custom-icon-input w-100">
                                    <input type="text" className="white form-control" value={DataLogin?.userModel?.email} disabled />
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="d-flex mb-3">
                                <label className="mb-1 bold">{t('Change_Password.Current_Password')}</label>
                                {/* <div className="position-relative custom-icon-input w-100 input-invalid"> */}
                                <div className="position-relative custom-icon-input w-100 ">
                                    <input
                                        type={passwordShown ? 'text' : 'password'}
                                        {...register('currentPassword', {
                                            onChange: (e: any) => { },
                                            onBlur: (e: any) => { validateCurrentPassword() }
                                        })}
                                        name={'currentPassword' as const}
                                        className="white form-control "
                                    />
                                    {!passwordShown ? (
                                        <i className="fa fa-eye cursor-pointer" aria-hidden="true" onClick={togglePassword}></i>
                                    ) : (
                                        <i className="fa fa-eye-slash cursor-pointer" aria-hidden="true" onClick={togglePassword}></i>
                                    )}
                                    {errors?.currentPassword?.type === "required" && (<p className='mt-1 error-message ps-0 pb-0'>Current Password is required.</p>)}
                                    {currentMatch !== undefined && currentMatch === false && (<p className='mt-1 error-message ps-0 pb-0'>Current Password does not match</p>)}
                                </div>
                            </div>
                            <div className="d-flex  mb-3">
                                <label className="mb-1 bold">{t('Change_Password.New_Password')}</label>
                                <div className="position-relative custom-icon-input w-100">
                                    <input
                                        type={newpasswordShown ? 'text' : 'password'}
                                        {...register('newPassword', {
                                            onChange: (e: any) => { },
                                            onBlur: () => { validateNewPassword() }
                                        })}
                                        name={'newPassword' as const}
                                        className="white form-control"
                                    />
                                    {!newpasswordShown ? (
                                        <i className="fa fa-eye cursor-pointer" aria-hidden="true" onClick={togglenewPassword}></i>
                                    ) : (
                                        <i className="fa fa-eye-slash cursor-pointer" aria-hidden="true" onClick={togglenewPassword}></i>
                                    )}
                                    {currentAndNewMatch !== undefined && currentAndNewMatch === false && (<p className='mt-1 error-message ps-0 pb-0'>New Password cannot be same as Current Password.</p>)}
                                    {errors?.newPassword?.type === "required" && (<p className='mt-1 error-message ps-0 pb-0'>New Password is required.</p>)}
                                    {errors?.newPassword?.type === "matches" && (<p className='mt-1 error-message ps-0 pb-0'>The password does not match the specified criteria</p>)}
                                </div>
                            </div>
                            <div className="d-flex ">
                                <label className="mb-1 bold">{t('Change_Password.Confirm_Password')}</label>
                                <div className="position-relative custom-icon-input w-100">
                                    <input
                                        type={confirmpasswordShown ? 'text' : 'password'}
                                        {...register('confirmPassword', {
                                            onChange: (e: any) => { }
                                        })}
                                        name={'confirmPassword' as const}
                                        className="white form-control"
                                    />
                                    {!confirmpasswordShown ? (
                                        <i className="fa fa-eye cursor-pointer" aria-hidden="true" onClick={toggleconfirmPassword}></i>
                                    ) : (
                                        <i className="fa fa-eye-slash cursor-pointer" aria-hidden="true" onClick={toggleconfirmPassword}></i>
                                    )}
                                    {errors?.confirmPassword?.type === "required" && (<p className='mt-1 error-message ps-0 pb-0'>Confirm Password is required.</p>)}
                                    {errors?.confirmPassword?.type !== "required" && errors?.confirmPassword?.type === "oneOf" && (<p className='mt-1 error-message ps-0 pb-0'>Confirm Password does not match the New Password.</p>)}
                                </div>
                            </div>

                            <div className="text-center mt-4 pt-1 pb-3 w-100">
                                <button className="btn btn-secondary me-2" onClick={onClickClearHandler}>{t('Change_Password.Clear')}</button>
                                <button disabled={!disabled} className="btn btn-primary" onClick={onChangePasswordHandler}>{t('Change_Password.Update')}</button>
                            </div>
                            <p className="mt-3">{t('Change_Password.Note')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 ps-2 ms-1 ps-lg-4 ms-lg-0 mt-3 mt-lg-0">
                        <div>
                            <p className="bold mb-2"> {t('Change_Password.Password_contain')}</p>
                            <ul className="ps-3">
                                <li>{t('Change_Password.capital')}</li>
                                <li>{t('Change_Password.small_letter')}</li>
                                <li>{t('Change_Password.special_character')}</li>
                                <li>{t('Change_Password.number')}</li>
                                <li>{t('Change_Password.characters')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModalView;
