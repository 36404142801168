import React, {useState, useEffect} from 'react';
import logo from "../src/assets/logos/logo.png"
import abstract from "../src/assets/images/abstract.png"
import warning from "../src/assets/images/warning1.png"


const NoInternetConnection = (props:any) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return (
            <>
                <div className="error-bg">
                        <div className="abstarct1">
                        <img src={abstract} alt="" />
                    </div>
                    <div>
                        <a href="#" className="error-logo"><img src={logo} alt="" /></a>
                    </div>
                    <div className="error-page nointernet-page text-center">
                        <img src={warning} alt="" className="warning-logo-error" width={35} />
                        <h2 className="mb-3 bold mt-3">Whoops!</h2>
                        <p className="mb-4">No Internet connection found. Please check your connection and try again</p>
                        <div className="loading">Waiting...</div>
                        {/* {isOnline ? <a href="/#" className="btn btn-primary">Refresh</a> :<a href="/login" className="btn btn-primary ">Login</a>} */}
                    </div>
                   
                </div>
            </>
        );
    }
}

export default NoInternetConnection;