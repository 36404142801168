import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AdmissionList } from '../../../../../../utils/constant';
import { rightNavigationPage } from '../../../../../../utils/constant';
// import ReleasePatientModalContainer from '../../../../../../components/ReleasePatientModal/indexContainer';
// import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';

const PatientListView = (props: any) => {
    const { t } = useTranslation();
    const { tabID } = props;
    const [showModal, setShowModal] = useState(false);

    const toggle = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id={`flush-heading${props.accordID}`}>
                    <button
                        className={classNames('accordion-button', { collapsed: props.accordID !== 0 })}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${props.accordID}`}
                        aria-expanded={props.accordID !== 0 ? 'false' : 'true'}
                        aria-controls={`flush-collapse${props.accordID}`}
                    >
                        <span className="user-name">
                            {(props.dataPatient.fullName.trim().split(' ')[0] !== undefined && props.dataPatient.fullName.trim().split(' ')[0] !== '') ? props.dataPatient.fullName.trim().split(' ')[0].charAt(0) : ''}
                            {(props.dataPatient.fullName.trim().split(' ')[1] !== undefined && props.dataPatient.fullName.trim().split(' ')[1] !== '') ? props.dataPatient.fullName.trim().split(' ')[1].charAt(0) : ''}
                        </span>
                       <p className='patient-name'> {props.dataPatient.fullName}</p>
                    </button>
                </h2>
                <div
                    id={`flush-collapse${props.accordID}`}
                    className={classNames('accordion-collapse collapse', { show: props.accordID === 0 })}
                    aria-labelledby={`flush-heading${props.accordID}`}
                    data-bs-parent={`${tabID === 1 ? '#admissiondetail' : '#admissiondetail1'}`}
                >
                    <div className="accordion-body pb-0">
                        {props.dataPatient.wRoomNo !== undefined && props.dataPatient.wRoomNo !== '' && props.dataPatient.wRoomNo !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.Bed_No')}:</label> <span className="bold">{props.dataPatient.wRoomNo}</span>
                            </p>
                        )}
                        {props.dataPatient.dateOfBirth !== undefined && props.dataPatient.dateOfBirth !== '' && props.dataPatient.dateOfBirth !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.Date_of_Birth')}:</label> <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.dataPatient.dateOfBirth)}</span>
                            </p>
                        )}
                        {props.dataPatient.gender !== undefined && props.dataPatient.gender !== '' && props.dataPatient.gender !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.Gender')}:</label> <span className="bold">{props.dataPatient.gender}</span>
                            </p>
                        )}
                        {props.dataPatient.nhsId !== undefined && props.dataPatient.nhsId !== '' && props.dataPatient.nhsId !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.NHS_ID')}:</label> <span className="bold">{props.dataPatient.nhsId}</span>
                            </p>
                        )}
                        {props.dataPatient.localId !== undefined && props.dataPatient.localId !== '' && props.dataPatient.localId !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.Client_ID')}:</label> <span className="bold">{props.dataPatient.localId}</span>
                            </p>
                        )}
                        {props.dataPatient.address1 !== undefined && props.dataPatient.address1 !== '' && props.dataPatient.address1 !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.Address1')}:</label> <span className="bold">{props.dataPatient.address1}</span>
                            </p>
                        )}
                        {props.dataPatient.address2 !== undefined && props.dataPatient.address2 !== '' && props.dataPatient.address2 !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.Address2')}:</label> <span className="bold">{props.dataPatient.address2}</span>
                            </p>
                        )}
                        {props.dataPatient.city !== undefined && props.dataPatient.city !== '' && props.dataPatient.city !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.City')}:</label> <span className="bold">{props.dataPatient.city}</span>
                            </p>
                        )}
                        {props.dataPatient.poBox !== undefined && props.dataPatient.poBox !== '' && props.dataPatient.poBox !== null && (
                            <p className="mb-1">
                                <label>{t('AdmissionRightBar.PO_Box')}:</label> <span className="bold">{props.dataPatient.poBox}</span>
                            </p>
                        )}
                        {props.dataPatient.contactNo !== undefined && props.dataPatient.contactNo !== '' && props.dataPatient.contactNo !== null && (
                            <p className="mb-0">
                                <label>{t('AdmissionRightBar.Contact_No')}:</label> <span className="bold">{props.dataPatient.contactNo}</span>
                            </p>
                        )}
                        <div className="mt-2 text-center">
                            {tabID === AdmissionList.Admitted && (
                                <a href="#" onClick={() => props.handleClick(props.dataPatient, rightNavigationPage.Occupied)} className="btn btn-sm btn-primary me-2">
                                    {t('AdmissionRightBar.View_Detail_btn')}
                                </a>
                            )}
                            {tabID === AdmissionList.Reserved && (
                                <a href="#" onClick={() => props.handleClick(props.dataPatient, rightNavigationPage.Reserved)} className="btn btn-sm btn-primary me-2">
                                    {t('AdmissionRightBar.View_Detail_btn')}
                                </a>
                            )}
                            {/* {tabID === AdmissionList.Reserved && (
                                <a href="#" className="btn btn-primary me-2">
                                    {t('AdmissionRightBar.Admit_btn')}
                                </a>
                            )}
                            {tabID === AdmissionList.Reserved && (
                                <a href="#" className="btn btn-sm white-text btn-delete red-background" onClick={() => setShowModal(true)}>
                                    {t('AdmissionRightBar.Release_btn')}
                                </a>
                            )} */}
                        </div>

                        {/* <RenderModalContainer>{showModal && <ReleasePatientModalContainer onClose={toggle} title content />}</RenderModalContainer> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientListView;
