import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaintenanceDetailsView from './MaintenanceDetailsView';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import { RootState } from '../../../../../../store';
import { GetBedMaintenanceDetailById } from '../../../../../../service/maintenance-service';
import eventEmitter from '../../../../../../utils/eventEmitter';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png'

const MaintenanceDetailsContainer = () => {
    const [editedFormMaintenance, setEditedFormMaintenance] = useState<any>(false)
    const [formAction, setFormAction] = useState<any>('')
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    const [bedDetailData, setBedDetailData] = useState<any>('')
    const dispatch = useDispatch();
    const closeSideMenu = (btnValue: any) => {
        if (btnValue !== true && editedFormMaintenance === true && formAction === 'Release_tab') {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: ''
                    };
                    dispatch(setNavigationAction(obj));
                }
            });
        } else {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: ''
            };
            dispatch(setNavigationAction(obj));
        }
    };
    useEffect(() => {
        async function GetBedMaintenanceDetail() {
            const payload: any = { id: Data.bedId };
            const response = await GetBedMaintenanceDetailById(payload);
            setBedDetailData(response.data)
        }
        GetBedMaintenanceDetail();
    },[ShowClass, Data])

    const eventCloseMaintenanceSideMenu = async() => {
        eventEmitter.emit('eventCloseEditSideMenu');
        setEditedFormMaintenance(true)
    }
    useEffect(() => {
        eventEmitter.on('eventCloseMaintenanceSideMenuEvent', eventCloseMaintenanceSideMenu);
        return () => {
            eventEmitter.off('eventCloseMaintenanceSideMenuEvent', eventCloseMaintenanceSideMenu)
        }
    });
    const eventCloseMaintenanceSideMenuOff = async() => {
        eventEmitter.emit('eventCloseEditSideMenuOff');
        setEditedFormMaintenance(false)
    }
    useEffect(() => {
        eventEmitter.on('eventCloseMaintenanceSideMenuEventOff', eventCloseMaintenanceSideMenuOff);
        return () => {
            eventEmitter.off('eventCloseMaintenanceSideMenuEventOff', eventCloseMaintenanceSideMenuOff)
        }
    });
    return <MaintenanceDetailsView closeSideMenu={closeSideMenu} bedDetailData={bedDetailData} setFormAction={(action: any) => {setFormAction(action);setEditedFormMaintenance(false);}} />;
};

export default MaintenanceDetailsContainer;
