import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import GenerateContractContainer from '../../../../../../components/GenerateContractModal/GenerateContractContainer';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import AddFunderContainer from './AddFunder/AddFunderContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../../../../../store';
import { contractSign, deleteContactingDocument, deleteFunder, getContactingDocument, getContractData, saveContract, updateDocuSign } from '../../../../../../service/contract-service';
import { setLeadInformationAction } from '../../../../../../store/lead/getLeaddataReducer';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
import { getLeaddata } from '../../../../../../service/leads';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';
import RejectContainer from '../../../../../../components/RejectModal/RejectContainer';
import { getFunderData } from '../../../../../../service/dashboard-service';
import { combination, DigitalContractEnum } from '../../../../../../utils/constant';
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const IPAView = (props: any) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setshowCancelModal] = useState(false);
    const [index, setindex] = useState<any>();
    const [cdata, setcdata] = useState<any>();
    const [ccdata, setccdata] = useState<any>();
    const [isfilepond, setfilepond] = useState<any>(false);
    const [file, setFiles] = useState<any[]>([]);
    const [fileres, setFileres] = useState<any>('');
    const [previewdata, setpreviewdata] = useState<any>();
    const [ctitle, setctitle] = useState<any>();
    const [datapatient, setdatapatinet] = useState<any>('');
    const [combinationId, setCombinationId] = useState<number | null>(null);
    const validationSchema = Yup.object().shape({
        contractPDF: Yup.mixed().notRequired()
    });
    const { register, control, handleSubmit, reset, getValues, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const dispatch = useDispatch();

    useEffect(() => {
        setdatapatinet(DataLeadInfo);
    }, [DataLeadInfo]);

    const toggle = () => {
        setshowCancelModal(false);
    };

    const handleCancelModal = (i: any, id: any, funderName: any) => {
        setctitle('Cancel Contract');
        let data = {
            leadid: DataLeadInfo?.data?.leadId,
            funderid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderId,
            contractid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
            isvoided: false,
            referralId: DataLeadInfo?.data?.referralId,
            funderName: funderName
        };
        setccdata(data);
        setshowCancelModal(true);
    };
    const handleVoidModal = (i: any, id: any, funderName: any) => {
        setctitle('Void Contract');
        let data = {
            leadid: DataLeadInfo?.data?.leadId,
            funderid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderId,
            contractid: DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
            isvoided: true,
            referralId: DataLeadInfo?.data?.referralId,
            funderName: funderName
        };
        setccdata(data);
        setshowCancelModal(true);
    };

    const toggleClass = () => {
        setShow(!show);
    };
    const toggleBack = () => {
        setShow(!show);
    };
    const toggleEdit = (i: any) => {
        setEdit(!edit);
        let returnedTarget = JSON.parse(JSON.stringify(DataLeadInfo));
        returnedTarget.data.contract[i].isEdit = true;
        dispatch(setLeadInformationAction(returnedTarget));
    };
    const toggleBackEdit = (i: any) => {
        setEdit(!edit);
        let returnedTarget = JSON.parse(JSON.stringify(DataLeadInfo));
        returnedTarget.data.contract[i].isEdit = false;
        dispatch(setLeadInformationAction(returnedTarget));
    };
    const toggleModal = () => {
        setShowModal(false);
        setcdata(undefined);
    };
    const handleClickModal = () => {
        if (DataLeadInfo?.data?.contract[0]?.attachments?.length > 0) {
            swal({
                title: '',
                text: 'An attachment is already associated, which will remain for reference. The contract you are going to generate will act as the primary document.',
                icon: warning,
                dangerMode: true
            }).then(async (confirm: any) => {
                const funderTypes = DataLeadInfo?.data?.contract?.map((cd: any) => cd.funderType ?? 'Self');

                console.log('funderTypes', funderTypes);
                const keyIndex = Object.keys(DigitalContractEnum).findIndex((key, index) => {
                    const keyArr = key.split('_');
                    const resultArr = keyArr.map((ft) => funderTypes?.includes(ft));
                    if (resultArr.length === funderTypes?.length) {
                        const isEveryElementIsTrue = resultArr?.every((item) => item === true);
                        if (isEveryElementIsTrue) {
                            return isEveryElementIsTrue;
                        }
                    }
                });

                const digitalContractValue = Object.values(DigitalContractEnum)[keyIndex];
                setCombinationId(digitalContractValue);

                const isExactMatch = combination.some((obj) => obj?.funders?.length === funderTypes?.length && obj?.funders?.every((ft) => funderTypes?.includes(ft)));

                if (isExactMatch) {
                    swal({
                        title: '',
                        text: `No contract available for the funder combination of ${funderTypes.join(', ')}`,
                        icon: 'warning',
                        dangerMode: true
                    });
                } else {
                    setShowModal(true);
                }
            });
        } else {
            swal({
                title: '',
                text: 'Please add all the required funders before proceeding with contract generation.Do you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if(confirm){
                    const funderTypes = DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled').map((cd: any) => cd.funderType ?? 'Self');

                    console.log('funderTypes', funderTypes);
                    const keyIndex = Object.keys(DigitalContractEnum).findIndex((key, index) => {
                        const keyArr = key.split('_');
                        const resultArr = keyArr.map((ft) => funderTypes?.includes(ft));
                        if (resultArr.length === funderTypes?.length) {
                            const isEveryElementIsTrue = resultArr?.every((item) => item === true);
                            if (isEveryElementIsTrue) {
                                return isEveryElementIsTrue;
                            }
                        }
                    });
    
                    const digitalContractValue = Object.values(DigitalContractEnum)[keyIndex];
                    setCombinationId(digitalContractValue);
    
                    const isExactMatch = combination.some((obj) => obj?.funders?.length === funderTypes?.length && obj?.funders?.every((ft) => funderTypes?.includes(ft)));
    
                    if (isExactMatch) {
                        swal({
                            title: '',
                            text: `No contract available for the funder combination of ${funderTypes.join(', ')}`,
                            icon: 'warning',
                            dangerMode: true
                        });
                    } else {
                        setShowModal(true);
                    }
                }
              
            });
        }
        // getFunderData(0);
        // setindex(0);
        // setShowModal(true);
    };
    async function viewsign(i: any, cid: any) {
        const docures = await updateDocuSign(cid);
        const res = await contractSign(cid);
        if (res.status) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = res.data;
            alink.click();
        }
    }

    const uploadfile = (i: number) => {
        if (!isfilepond) {
            let returnedTarget = JSON.parse(JSON.stringify(DataLeadInfo));
            returnedTarget.data.contract[i].isFile = true;
            dispatch(setLeadInformationAction(returnedTarget));
            setfilepond(true);
        } else {
            let returnedTarget = JSON.parse(JSON.stringify(DataLeadInfo));
            returnedTarget.data.contract[i].isFile = false;
            dispatch(setLeadInformationAction(returnedTarget));
            setfilepond(false);
        }
    };

    useEffect(() => {
        if (props.isdone) {
            setShowModal(false);
            setcdata(undefined);
        }
    }, [props.isdone]);

    useEffect(() => {
        props.cindex(index);
    }, [index]);

    async function handleEditModal(i: any, id: any) {
        setindex(i);
        if (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].status !== '') {
            if (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].status !== 'Completed') {
                const docures = await updateDocuSign(id);
                if (docures.status) {
                    const res = await getContractData(id);
                    if (res.status) {
                        setcdata(res.data);
                        setShowModal(true);
                        getLeaddata(DataLeadInfo?.data?.leadId, 6);
                    } else {
                        setcdata(undefined);
                    }
                }
            } else {
                const res = await getContractData(id);
                if (res.status) {
                    setcdata(res.data);
                    setShowModal(true);
                } else {
                    setcdata(undefined);
                }
            }
        } else {
            const res = await getContractData(id);
            if (res.status) {
                setcdata(res.data);
                setShowModal(true);
            } else {
                setcdata(undefined);
            }
        }
    }

    async function deletefunder(fid: any, ftype: any, funderName: any, i: number) {
        console.log(fid);
        swal({
            title: '',
            text: `Are you sure you want to delete funder - ${funderName} ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                if (ftype === 'self') {
                    const res = await deleteFunder(DataLeadInfo?.data?.leadId, null, 1, DataLeadInfo?.data?.contract[i].leadFunderDetailsId);
                    if (res.status === true) {
                        swal(res.message, {
                            icon: 'success'
                        });
                        getLeaddata(DataLeadInfo?.data?.leadId, 6);
                    } else {
                        swal(res.message, {
                            icon: 'error'
                        });
                    }
                } else {
                    const res = await deleteFunder(DataLeadInfo?.data?.leadId, fid, 0, DataLeadInfo?.data?.contract[i].leadFunderDetailsId);
                    if (res.status === true) {
                        swal(res.message, {
                            icon: 'success'
                        });
                        getLeaddata(DataLeadInfo?.data?.leadId, 6);
                    } else {
                        swal(res.message, {
                            icon: 'error'
                        });
                    }
                }
            }
        });
    }

    const isdone = (edone: boolean) => {
        if (edone) {
            toggleBack();
        } else {
            toggleClass();
        }
    };

    const openpdf = async (e: any, fpath: any) => {
        e.preventDefault();
        const payload = { path: fpath };
        const response = await getContactingDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    const removepdf = (e: any, fpath: any, funderName: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: `Are you sure you want to delete the attachment for funder - ${funderName}?`,
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: fpath };
                const response = await deleteContactingDocument(payload);
                if (response.status == true) {
                    // props.updatefilepond(i);
                    getLeaddata(DataLeadInfo?.data?.leadId, 6);
                } else {
                }
            }
        });
    };

    return (
        <div>
            {!show && DataLeadInfo !== '' && (
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="bold">Funders</p>
                        {DataLeadInfo !== '' && DataLeadInfo?.data?.leadStatus === 'Open' && (
                            <>
                                {DataLeadInfo?.data?.rmCancellationMessage === '' && (
                                    <a onClick={toggleClass} className="btn btn-primary btn-xs">
                                        <i className="fa-solid fa-plus me-1"></i>Add
                                    </a>
                                )}
                            </>
                        )}
                    </div>

                    {DataLeadInfo !== '' && DataLeadInfo?.data?.leadStatus === 'Open' && (
                        <>
                            {DataLeadInfo !== '' &&
                                DataLeadInfo?.data?.rmCancellationMessage === '' &&
                                DataLeadInfo?.data?.contract?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="status-box white-bg mt-3">
                                                <div className="d-flex justify-content-between">
                                                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>
                                                    {data.status === '' && (
                                                        <>
                                                            <div>
                                                                <a onClick={() => toggleEdit(i)} className="btn-icon gray-background me-2">
                                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                                </a>
                                                                <a onClick={() => deletefunder(data.funderId, data.funderType, data.funderName, i)} className="btn-icon red-background">
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </a>
                                                            </div>
                                                        </>
                                                    )}

                                                    {/* {data.status === 'Sent to Funder' && data.status !== 'Cancelled' && (
                                                        <>
                                                            <span className="referral-status yellow-status">Sent to Funder</span>
                                                        </>
                                                    )}
                                                    {data.status === 'Signed by Commissioner' && data.status !== 'Cancelled' && (
                                                        <>
                                                            <span className="referral-status yellow-status">Signed by Commissioner</span>
                                                        </>
                                                    )}
                                                    {data.status === 'In Progress with Funder' && data.status !== 'Cancelled' && (
                                                        <>
                                                            <span className="referral-status yellow-status">In Progress with Funder</span>
                                                        </>
                                                    )}
                                                    {data.status === 'Completed' && (
                                                        <>
                                                            <span className="referral-status green-status">Completed</span>
                                                        </>
                                                    )}

                                                    {data.status !== '' && data.status === 'Cancelled' && <span className="referral-status red-status">Cancelled</span>}
                                                    {data.status !== '' && data.status === 'Voided' && <span className="referral-status red-status">Voided</span>} */}
                                                    {/* <span className="referral-status green-status">Completed</span> */}
                                                </div>
                                                <p className="bold mt-2">{data.funderName}</p>
                                                {!data.isEdit && data.status !== 'Cancelled' && (
                                                    <div>
                                                        <div className="text-center mt-3">
                                                            {/* {data.digitalContractId === 0 && data.status === '' && <></>}
                                                            {data.digitalContractId !== 0 && data.status === 'Sent to Funder' && (
                                                                <>
                                                                    <a href="#" className="btn btn-primary btn-sm me-2" onClick={() => handleEditModal(i, data.digitalContractId)}>
                                                                        View Contract
                                                                    </a>

                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-secondary btn-sm me-2"
                                                                        data-tooltip-id="my-tooltip-Contract"
                                                                        data-tooltip-content="Void Contract"
                                                                        onClick={() => handleVoidModal(i, data.digitalContractId, data.funderName)}
                                                                    >
                                                                        Void
                                                                    </a>
                                                                </>
                                                            )}
                                                            {data.digitalContractId !== 0 && data.status === '' && (
                                                                <>
                                                                    <a href="#" className="btn btn-primary btn-sm me-2" onClick={() => handleEditModal(i, data.digitalContractId)}>
                                                                        <i className="fa-solid fa-square-pen me-1"></i> Edit Contract
                                                                    </a>
                                                                </>
                                                            )}

                                                            {data.digitalContractId !== 0 && data.status === 'Completed' && (
                                                                <>
                                                                    <a href="#" className="btn btn-primary btn-sm me-2" onClick={() => handleEditModal(i, data.digitalContractId)}>
                                                                        View Contract
                                                                    </a>
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-secondary btn-sm me-2"
                                                                        data-tooltip-id="my-tooltip-Contract"
                                                                        data-tooltip-content="Cancel Contract"
                                                                        onClick={() => handleCancelModal(i, data.digitalContractId, data.funderName)}
                                                                    >
                                                                        Cancel
                                                                    </a>
                                                                </>
                                                            )}

                                                            {data.digitalContractId !== 0 && data.status === 'Signed by Commissioner' && (
                                                                <>
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-primary btn-sm me-2"
                                                                        data-tooltip-id="my-tooltip-Contract"
                                                                        data-tooltip-content="View & Sign Contract"
                                                                        onClick={() => viewsign(i, data.digitalContractId)}
                                                                    >
                                                                        View & Sign
                                                                    </a>
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-secondary btn-sm me-2"
                                                                        data-tooltip-id="my-tooltip-Contract"
                                                                        data-tooltip-content="Void Contract"
                                                                        onClick={() => handleVoidModal(i, data.digitalContractId, data.funderName)}
                                                                    >
                                                                        Void
                                                                    </a>
                                                                </>
                                                            )}
                                                            {data.digitalContractId !== 0 && data.status === 'In Progress with Funder' && (
                                                                <>
                                                                    <a href="#" className="btn btn-primary btn-sm me-2" onClick={() => handleEditModal(i, data.digitalContractId)}>
                                                                        View Contract
                                                                    </a>
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-secondary btn-sm me-2"
                                                                        data-tooltip-id="my-tooltip-Contract"
                                                                        data-tooltip-content="Void Contract"
                                                                        onClick={() => handleVoidModal(i, data.digitalContractId, data.funderName)}
                                                                    >
                                                                        Void
                                                                    </a>
                                                                </>
                                                            )} */}

                                                            {/* {data.digitalContractId !== 0 && data.status === 'Signed by Commissioner' && (
                                                                <>
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-secondary btn-sm me-2"
                                                                        data-tooltip-id="my-tooltip-Contract"
                                                                        data-tooltip-content="Cancel"
                                                                        onClick={() => handleVoidModal(i, data.digitalContractId)}
                                                                    >
                                                                        Void
                                                                    </a>
                                                                </>
                                                            )} */}
                                                            {data.status !== 'Completed' && data.status !== 'Voided' && (
                                                                <>
                                                                    <a onClick={() => uploadfile(i)} className="btn btn-secondary btn-sm">
                                                                        <i className="fa-solid fa-paperclip me-1"></i> Attach
                                                                    </a>
                                                                </>
                                                            )}

                                                            {data.attachments.length > 0 && (
                                                                <>
                                                                    <p className="purple-text mt-3 d-flex bold">Attachments</p>
                                                                    {data?.attachments?.map((dataAttachment: any) => {
                                                                        return (
                                                                            <>
                                                                                <div className="d-flex justify-content-between mt-2 contract-attachments">
                                                                                    <div className="input-readonly d-flex text-left">
                                                                                        <a onClick={(e: any) => openpdf(e, dataAttachment.documentPath)}>{dataAttachment.documentFile}</a>
                                                                                    </div>

                                                                                    <button
                                                                                        className="btn-icon red-background"
                                                                                        data-tooltip-id="my-tooltip-delete"
                                                                                        data-tooltip-content="Delete Attachment"
                                                                                        name="btndelete"
                                                                                        onClick={(e: any) => removepdf(e, dataAttachment.documentPath, data.funderName)}
                                                                                    >
                                                                                        <i className="fa-solid fa-trash"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </>
                                                            )}
                                                            {data.isFile && isfilepond && (
                                                                <>
                                                                    <div className="mt-3">
                                                                        <Controller
                                                                            control={control}
                                                                            name="contractPDF"
                                                                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                                                return (
                                                                                    <FilePond
                                                                                        files={file}
                                                                                        onupdatefiles={setFiles}
                                                                                        // @ts-ignore
                                                                                        allowFileSizeValidation={true}
                                                                                        maxFileSize={10000000}
                                                                                        allowFileTypeValidation={true}
                                                                                        acceptedFileTypes={[
                                                                                            'application/pdf',
                                                                                            'image/*',
                                                                                            'application/vnd.ms-excel',
                                                                                            'application/vnd.ms-powerpoint',
                                                                                            'application/msword',
                                                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                                                        ]}
                                                                                        labelMaxFileSizeExceeded={'File is too large'}
                                                                                        fileValidateTypeLabelExpectedTypes={''}
                                                                                        name={'contractPDF'}
                                                                                        labelIdle={`${t('VacantRightbar.pdftitle1')}<span class="filepond--label-action"> ${t(
                                                                                            'VacantRightbar.pdftitle2'
                                                                                        )}</span>`}
                                                                                        server={{
                                                                                            url: `${BASEURLDEV}${API_ENDPOINT.ContractAddAttachment}`,
                                                                                            process: {
                                                                                                headers: {
                                                                                                    Authorization: `Bearer ${token}`
                                                                                                },
                                                                                                ondata: (formData) => {
                                                                                                    formData.append('ReferralId', DataLeadInfo?.data?.referralId);
                                                                                                    formData.append(
                                                                                                        'funderName',
                                                                                                        DataLeadInfo?.data?.contract[i]?.funderTypeId === 0
                                                                                                            ? 'self'
                                                                                                            : DataLeadInfo?.data?.contract[i]?.funderName
                                                                                                    );
                                                                                                    formData.append('AttachmentType', '1');
                                                                                                    formData.append(
                                                                                                        'Funder_Id',
                                                                                                        DataLeadInfo?.data?.contract[i]?.funderTypeId === 0
                                                                                                            ? 0
                                                                                                            : DataLeadInfo?.data?.contract[i]?.funderId
                                                                                                    );
                                                                                                    formData.append('LeadPoolingDetails_Id', DataLeadInfo?.data?.leadPoolingId);
                                                                                                    formData.append('LeadFunderDetailsId', DataLeadInfo?.data?.contract[i].leadFunderDetailsId);
                                                                                                    return formData;
                                                                                                },
                                                                                                //@ts-ignore
                                                                                                onload: (res: any) => {
                                                                                                    const fileres = JSON.parse(res);
                                                                                                    if (fileres.status) {
                                                                                                        setFiles([]);
                                                                                                        setfilepond(undefined);
                                                                                                        getLeaddata(DataLeadInfo?.data?.leadId, 6);
                                                                                                    } else {
                                                                                                        setFiles([]);
                                                                                                    }
                                                                                                }
                                                                                            },
                                                                                            remove: (source, load, error) => {
                                                                                                error('oh my goodness');
                                                                                                load();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {/* <p className="small mt-2">
                                        <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note2')}
                                    </p> */}
                                                                </>
                                                            )}
                                                        </div>
                                                        {data.isFile && isfilepond && (
                                                            <p className="small mt-3">
                                                                <strong>Note:</strong> Supported file types for attachment are PDF, Word, Excel & Image. Maximum upload size is 10 MB.
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                                {data.isEdit && data.status !== 'Cancelled' && (
                                                    <div className="funderadd mt-2">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="bold purple-text">Edit Funder</p>
                                                            <a onClick={() => toggleBackEdit(i)} className="btn-icon gray-background" data-tooltip-id="my-tooltip-back" data-tooltip-content="Back">
                                                                <i className="fa-solid fa-arrow-left"></i>
                                                            </a>
                                                        </div>
                                                        <AddFunderContainer isedit={true} funderdata={data} />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    );
                                })}

                            {DataLeadInfo?.data?.rmCancellationMessage !== '' && <div className="red-box mt-3">{DataLeadInfo?.data?.rmCancellationMessage}</div>}
                            {DataLeadInfo !== '' &&
                                DataLeadInfo?.data?.rmCancellationMessage !== '' &&
                                DataLeadInfo?.data?.contract?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="status-box white-bg mt-3">
                                                <div className="d-flex justify-content-between">
                                                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>
                                                </div>
                                                <p className="bold mt-2">{data.funderName}</p>
                                            </div>
                                        </>
                                    );
                                })}
                        </>
                    )}

                    {DataLeadInfo !== '' && DataLeadInfo?.data?.leadStatus === 'Closed' && (
                        <>
                            {DataLeadInfo !== '' &&
                                DataLeadInfo?.data?.rmCancellationMessage === '' &&
                                DataLeadInfo?.data?.contract?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="status-box white-bg mt-3">
                                                <div className="d-flex justify-content-between">
                                                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>

                                                    {/* {data.status === 'Sent to Funder' && data.status !== 'Cancelled' && (
                                                        <>
                                                            <span className="referral-status yellow-status">Sent to Funder</span>
                                                        </>
                                                    )}
                                                    {data.status === 'Signed by Commissioner' && data.status !== 'Cancelled' && (
                                                        <>
                                                            <span className="referral-status yellow-status">Signed by Commissioner</span>
                                                        </>
                                                    )}
                                                    {data.status === 'In Progress with Funder' && data.status !== 'Cancelled' && (
                                                        <>
                                                            <span className="referral-status yellow-status">In Progress with Funder</span>
                                                        </>
                                                    )}
                                                    {data.status === 'Completed' && (
                                                        <>
                                                            <span className="referral-status green-status">Completed</span>
                                                        </>
                                                    )}

                                                    {data.status !== '' && data.status === 'Cancelled' && <span className="referral-status red-status">Cancelled</span>}
                                                    {data.status !== '' && data.status === 'Voided' && <span className="referral-status red-status">Voided</span>} */}
                                                    {/* <span className="referral-status green-status">Completed</span> */}
                                                </div>
                                                <p className="bold mt-2">{data.funderName}</p>
                                                {!data.isEdit && data.status !== 'Cancelled' && (
                                                    <div>
                                                        <div className="text-center mt-3">
                                                            {data.attachments.length > 0 && (
                                                                <>
                                                                    <p className="purple-text mt-3 d-flex bold">Attachments</p>
                                                                    {data?.attachments?.map((data: any) => {
                                                                        return (
                                                                            <>
                                                                                <div className="d-flex justify-content-between mt-2 contract-attachments">
                                                                                    <div className="input-readonly d-flex text-left">
                                                                                        <a onClick={(e: any) => openpdf(e, data.documentPath)}>{data.documentFile}</a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    );
                                })}

                            {DataLeadInfo?.data?.rmCancellationMessage !== '' && <div className="red-box mt-3">{DataLeadInfo?.data?.rmCancellationMessage}</div>}
                            {DataLeadInfo !== '' &&
                                DataLeadInfo?.data?.rmCancellationMessage !== '' &&
                                DataLeadInfo?.data?.contract?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="status-box white-bg mt-3">
                                                <div className="d-flex justify-content-between">
                                                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>
                                                </div>
                                                <p className="bold mt-2">{data.funderName}</p>
                                            </div>
                                        </>
                                    );
                                })}

                            {DataLeadInfo !== '' &&
                                DataLeadInfo?.data?.status === 'Voided' &&
                                DataLeadInfo?.data?.contract?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div className="status-box white-bg mt-3">
                                                <div className="d-flex justify-content-between">
                                                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>
                                                </div>
                                                <p className="bold mt-2">{data.funderName}</p>
                                            </div>
                                        </>
                                    );
                                })}
                        </>
                    )}
                </div>
            )}
            {show && (
                <div className="funderadd">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="bold">Add Funder</p>
                        <a onClick={toggleBack} className="btn-icon gray-background" data-tooltip-id="my-tooltip-back" data-tooltip-content="Back">
                            <i className="fa-solid fa-arrow-left"></i>
                        </a>
                    </div>
                    <AddFunderContainer isdone={isdone} isedit={false} funderdata={''} />
                </div>
            )}

            {DataLeadInfo !== '' &&
                (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId === 0 ||
                    (DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Voided' &&
                        DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled')) &&
                DataLeadInfo?.data?.contract?.length > 0 &&
                DataLeadInfo?.data?.leadStatus !== 'Closed' && (
                    <>
                        <div className="accordion accordion-flush mt-3" id="newcontractAccordion">
                            <div className="accordion-item white mt-0">
                                <h2 className="accordion-header" id="contract1">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#newContract"
                                        aria-expanded="false"
                                        aria-controls="#newContract"
                                    >
                                        Generate Contract
                                    </button>
                                </h2>
                                <div id="newContract" className="accordion-collapse collapse" aria-labelledby="contract1" data-bs-parent="#newcontractAccordion">
                                    <div className="accordion-body">
                                        {DataLeadInfo?.data?.contract?.length > 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId === 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === '' && (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn btn-primary btn-sm me-2"
                                                        data-tooltip-id="my-tooltip-Contract"
                                                        data-tooltip-content="Generate Contract"
                                                        onClick={handleClickModal}
                                                    >
                                                        <i className="fa-solid fa-square-pen me-1"></i> Generate
                                                    </a>
                                                </>
                                            )}
                                        {DataLeadInfo?.data?.contract?.length > 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Sent to Funder' && (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn btn-primary btn-sm me-2"
                                                        onClick={() =>
                                                            handleEditModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId
                                                            )
                                                        }
                                                    >
                                                        View Contract
                                                    </a>

                                                    <a
                                                        href="#"
                                                        className="btn btn-secondary btn-sm me-2"
                                                        data-tooltip-id="my-tooltip-Contract"
                                                        data-tooltip-content="Void Contract"
                                                        onClick={() =>
                                                            handleVoidModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderName
                                                            )
                                                        }
                                                    >
                                                        Void
                                                    </a>
                                                </>
                                            )}
                                        {DataLeadInfo?.data?.contract?.length > 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === '' && (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn btn-primary btn-sm me-2"
                                                        onClick={() =>
                                                            handleEditModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId
                                                            )
                                                        }
                                                    >
                                                        <i className="fa-solid fa-square-pen me-1"></i> Edit Contract
                                                    </a>
                                                </>
                                            )}

                                        {DataLeadInfo?.data?.contract?.length > 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Completed' && (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn btn-primary btn-sm me-2"
                                                        onClick={() =>
                                                            handleEditModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId
                                                            )
                                                        }
                                                    >
                                                        View Contract
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="btn btn-secondary btn-sm me-2"
                                                        data-tooltip-id="my-tooltip-Contract"
                                                        data-tooltip-content="Cancel Contract"
                                                        onClick={() =>
                                                            handleCancelModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderName
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </a>
                                                </>
                                            )}

                                        {DataLeadInfo?.data?.contract?.length > 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Signed by Commissioner' && (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn btn-primary btn-sm me-2"
                                                        data-tooltip-id="my-tooltip-Contract"
                                                        data-tooltip-content="View & Sign Contract"
                                                        onClick={() =>
                                                            viewsign(0, DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId)
                                                        }
                                                    >
                                                        View & Sign
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="btn btn-secondary btn-sm me-2"
                                                        data-tooltip-id="my-tooltip-Contract"
                                                        data-tooltip-content="Void Contract"
                                                        onClick={() =>
                                                            handleVoidModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderName
                                                            )
                                                        }
                                                    >
                                                        Void
                                                    </a>
                                                </>
                                            )}
                                        {DataLeadInfo?.data?.contract?.length > 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.digitalContractId !== 0 &&
                                            DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'In Progress with Funder' && (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn btn-primary btn-sm me-2"
                                                        onClick={() =>
                                                            handleEditModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId
                                                            )
                                                        }
                                                    >
                                                        View Contract
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="btn btn-secondary btn-sm me-2"
                                                        data-tooltip-id="my-tooltip-Contract"
                                                        data-tooltip-content="Void Contract"
                                                        onClick={() =>
                                                            handleVoidModal(
                                                                0,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].digitalContractId,
                                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0].funderName
                                                            )
                                                        }
                                                    >
                                                        Void
                                                    </a>
                                                </>
                                            )}

                                        <div className="mt-2">
                                            {DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Sent to Funder' &&
                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled' && (
                                                    <>
                                                        <span className="referral-status yellow-status">Sent to Funder</span>
                                                    </>
                                                )}
                                            {DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Signed by Commissioner' &&
                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled' && (
                                                    <>
                                                        <span className="referral-status yellow-status">Signed by Commissioner</span>
                                                    </>
                                                )}
                                            {DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'In Progress with Funder' &&
                                                DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status !== 'Cancelled' && (
                                                    <>
                                                        <span className="referral-status yellow-status">In Progress with Funder</span>
                                                    </>
                                                )}
                                            {DataLeadInfo?.data?.contract?.filter((el: any) => el.status !== 'Voided' && el.status !== 'Cancelled')[0]?.status === 'Completed' && (
                                                <>
                                                    <span className="referral-status green-status">Completed</span>
                                                </>
                                            )}
                                            {DataLeadInfo?.data?.contract?.length > 0 &&
                                                DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status !== '' &&
                                                DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status === 'Cancelled' && (
                                                    <span className="referral-status red-status">Cancelled</span>
                                                )}
                                            {DataLeadInfo?.data?.contract?.length > 0 &&
                                                DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status !== '' &&
                                                DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status === 'Voided' && (
                                                    <span className="referral-status red-status">Voided</span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            <Tooltip id="my-tooltip-Contract" className="my-tooltip" />
            <Tooltip id="my-tooltip-back" className="my-tooltip tooltip-custom-width" />
            <Tooltip id="my-tooltip-delete" className="my-tooltip" />

            <RenderModalContainer>
                {showModal && (
                    <GenerateContractContainer findex={index} onClose={toggleModal} getval={props.getformval} cdata={cdata} combinationId={combinationId} send={props.send} pname={'contract'} />
                )}

                {showCancelModal && <RejectContainer onClose={toggle} leadid={ccdata} mname={'contract'} title={ctitle} />}
            </RenderModalContainer>
        </div>
    );
};

export default IPAView;
