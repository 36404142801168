import React from "react";
import PaginationView from "./PaginationView";

interface Props {
  totalPages: number;
  setCurrentPage: any;
  currentPage: any;
}

const PaginationContainer = ({
  totalPages,
  setCurrentPage,
  currentPage
}: Props) => {
  const handleChange = async (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <PaginationView
      currentPage={currentPage}
      totalPages={totalPages}
      handleChange={handleChange}
    />
  );
};

export default PaginationContainer;
