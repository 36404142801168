const PrintCostingView = (props: any) => {
    
    return (
        <div className='yourClassName px-3'>
            <table className="table-invoice" width="100%" cellPadding="0" cellSpacing="0" style={{ background: '#ffffff' }}>
                <tr>
                    <td className="text-right">
                        <img src={props?.viewCosting?.logoName} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text h4' style={{ margin: '0px 0 10px', borderBottom: "1px solid #ddd" }}>Comprehensive {props.viewCosting?.serviceTypeName} Costing</p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="32%" valign="top">
                                    <div>Service Name</div>
                                    <div className="bold margin-bottom-10">{props.viewCosting?.serviceName}</div>
                                </td>

                                <td width="32%" valign="top">
                                    <div>Service User Name</div>
                                    <div className="bold margin-bottom-10">{props.viewCosting?.serviceUserName}</div>
                                </td>

                                <td width="32%" valign="top">
                                    <div>Service User Id</div>
                                    <div className="bold margin-bottom-10">{props.viewCosting?.serviceUserId}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div className='border-radius' style={{ margin: '10px 0 0px' }}>
                            <table width="50%" cellPadding="0" cellSpacing="0" className='table-border'>
                                <tr>
                                    <td><p className='purple-text bold'>Accommodation</p></td>
                                    <td><p className='bold'>£ {props.viewCosting?.accommodation}</p></td>
                                </tr>
                                <tr>
                                    <td><p className='purple-text bold'>Baseline Staff Cost</p></td>
                                    <td><p className='bold'>£ {props.viewCosting?.baselineStaffCost}</p></td>
                                </tr>

                                <tr>
                                    <td><p className='purple-text bold'>Enhanced Staffing costs</p></td>
                                    <td><p className='bold'>£ {props.viewCosting?.enhancedStaffingCost}</p></td>
                                </tr>

                                <tr>
                                    <td><p className='purple-text bold'>MDT</p></td>
                                    <td><p className='bold'>£ {props.viewCosting?.mdt}</p></td>
                                </tr>

                                <tr>
                                    <td><p className='purple-text bold'>Transport</p></td>
                                    <td><p className='bold'>£ {props.viewCosting?.transport}</p></td>
                                </tr>

                                <tr>
                                    <td><p className='purple-text bold'>Management & Administration</p></td>
                                    <td><p className='bold'>£ {props.viewCosting?.managementAndAdministration}</p></td>
                                </tr>
                                {props?.additionalField?.length > 0 && props?.viewCosting?.costingLists?.length > 0 && props?.viewCosting?.costingLists?.map((data: any, i: number) => {
                                    var costingLabel = props?.additionalField.find((item: any) => item?.id === data?.costingTypeId);                                    
                                     return (
                                        <tr>
                                            <td><p className='purple-text bold'>{costingLabel?.label}</p></td>
                                            <td><p className='bold'>£ {data?.amount}</p></td>
                                        </tr>
                                     )
                                    })
                                }

                                <tr>
                                    <td className='purple-background'><p className='white-text bold'>Total per week</p></td>
                                    <td className='purple-background'><p className='white-text bold'>£ {props.viewCosting?.total?.toFixed(2)}</p></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            </table>
            <div className="fixed-bottom pt-1 text-center" style={{ borderTop: "1px solid #ddd" }}>Address: {props?.viewCosting?.serviceLocation}</div>
        </div>
    );
};

export default PrintCostingView;