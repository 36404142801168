import React from 'react';
import TableSearchView from './TableSearchView';

const TableSearchContainer = (props: any) => {
    return (
        <div>
            <TableSearchView searchValue={props.searchValue} />
        </div>
    );
};

export default TableSearchContainer;