import React from 'react';
import HomeListView from './HomeListView';

const HomeListContainer = (props: any) => {
    return (
        <div>
            <HomeListView {...props} />
        </div>
    );
};

export default HomeListContainer;