import React, { useState, useEffect, useRef } from 'react';
import MessagesView from './MessagesView';
import { getMessagesService, markAsReadService } from '../../../service/messages-service';
import eventEmitter from '../../../utils/eventEmitter';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { removeListMessagesAction } from '../../../store/referral/listMessagesReducer';
let PageSize = 15;

type messageListPayload = {
    searchValue: string;
    isRead: any;
    isUnRead: any;
    lazyLoad: boolean;
    page: any;
    pageSize: any;
};

const MessagesContainer = () => {
    const dispatch = useDispatch();
    const { StatusReferralOverView, DataReferralOverView } = useSelector((state: RootState) => state.referralOverView);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const { StatusListMessages, DataListMessages } = useSelector((state: RootState) => state.listMessages);
    const [messageCount, setMessageCount] = useState<any>(undefined);

    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [messageList, setMessageList] = useState<any>([]);
    const [lastList, setLastList] = useState(false);
    const [messageListPayload, setMessageListPayload] = useState<messageListPayload>({
        searchValue: '',
        isRead: 0,
        isUnRead: 0,
        lazyLoad: true,
        page: 1,
        pageSize: PageSize
    });

    async function messageMarkAsRead(data: any) {
        let ids: any = [];
        ids.push(data.id);
        let obj: any = {
            message_Id: ids
        };
        await markAsReadService(obj);
    }
    async function messageMarkAsReadALL() {
        let ids: any = [];
        if (DataListMessages?.data?.length > 0) {
            DataListMessages?.data?.map((item: any) => {
                ids.push(item.messageId);
            });
        }

        let obj: any = {
            message_Id: ids
        };
        const response = await markAsReadService(obj);
        if (response.status === true) {
            dispatch(removeListMessagesAction());
        }
    }
    async function onclickMarkAllHandler() {
        await messageMarkAsReadALL();
        if (DataRoleSelected.roleName === 'Service Manager') {
            eventEmitter.emit('onMessageReadFetchSM');
        } else {
            eventEmitter.emit('onMessageReadFetchRM');
        }
        setCurrPage(1);
        setPrevPage(0);
        setLastList(false);        
    }

    useEffect(() => {
        if (StatusReferralOverView === true) {
            setMessageCount(DataReferralOverView?.data?.referralActivitiesResponse?.messages);
        }
    }, [StatusReferralOverView, DataReferralOverView]);

    useEffect(() => {
        eventEmitter.on('eventMarkAsRead', eventMarkAsRead);
        return () => {
            eventEmitter.off('eventMarkAsRead', eventMarkAsRead);
        };
    });

    const eventMarkAsRead = async (data: any) => {
        await messageMarkAsRead(data);
        if (DataRoleSelected.roleName === 'Service Manager') {
            eventEmitter.emit('onMessageReadFetchSM');
        } else {
            eventEmitter.emit('onMessageReadFetchRM');
        }
        setCurrPage(1);
        setPrevPage(0);
        setLastList(false);        
    };

    const changeSearchValue = (event: any) => {
        if (event.target.name === 'messageSmartSearch') {
            let tempMessageListPayload: any = messageListPayload;
            tempMessageListPayload.page = 1;
            setCurrPage(1);
            setPrevPage(0);
            setLastList(false);

            if (event.target.value !== '') {
                tempMessageListPayload.searchValue = event.target.value;
                setMessageListPayload(tempMessageListPayload);
            } else {
                tempMessageListPayload.searchValue = '';
                setMessageListPayload(tempMessageListPayload);
            }           
        }
    };

    const changePayloadHandler = (event: any) => {
        let tempMessageListPayload: any = messageListPayload;
        tempMessageListPayload.page = 1;
        setCurrPage(1);
        setPrevPage(0);
        setLastList(false);
        if (event.target.checked === true && event.target.name === 'read') {
            tempMessageListPayload.isRead = 1;
            setMessageListPayload(tempMessageListPayload);
        } else if (event.target.checked === false && event.target.name === 'read') {
            tempMessageListPayload.isRead = 0;
            setMessageListPayload(tempMessageListPayload);
        }

        if (event.target.checked === true && event.target.name === 'unread') {
            tempMessageListPayload.isUnRead = 1;
            setMessageListPayload(tempMessageListPayload);
        } else if (event.target.checked === false && event.target.name === 'unread') {
            tempMessageListPayload.isUnRead = 0;
            setMessageListPayload(tempMessageListPayload);
        }      
    };

    useEffect(() => {
        const fetchData = async () => {
            let tempMessageListPayload: any = messageListPayload;
            tempMessageListPayload.page = currPage;

            const response = await getMessagesService(tempMessageListPayload);
            
            if (!response.status || response.totalCount == 0) {
                setLastList(true);                
                //return;
            }
            if(response.totalCount > 0)
            {
                setPrevPage(currPage);
            }
            if (currPage == 1) {
                setMessageList(response.data != null ? response.data : []);
            }
             else {
                if(response.totalCount > 0)
                {
                    setMessageList([...messageList, ...response.data]);
                }
                
            }
        };
        if (!lastList && prevPage !== currPage) {
            fetchData();
        }
    }, [currPage, lastList, prevPage, messageList]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 20) {
                setCurrPage(currPage + 1);
            }
        }
    };
    return (
        <div>
            <MessagesView
                messageCount={messageCount}
                onclickMarkAllHandler={onclickMarkAllHandler}
                searchValue={changeSearchValue}
                changePayloadHandler={changePayloadHandler}
                messageList={messageList}
                onScroll={onScroll}
                listInnerRef={listInnerRef}
            />
        </div>
    );
};

export default MessagesContainer;
