import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { ROUTES_DATA } from './RoutesConstants';
import { Modules, Roles } from '../utils/constant';

const PublicRoutes = () => {
    const dispatch = useDispatch();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusRedirectToFrom, DataRedirectToFrom } = useSelector((state: RootState) => state.redirectToFromURL);
    let auth: any = { token: DataLogin.jwtToken };
    let redirectURL = '';
    if (StatusLogin === true && auth.token !== '') {
        if (StatusRedirectToFrom === true && DataRedirectToFrom.fromPath !== '' && DataRedirectToFrom.fromPath !== ROUTES_DATA.CHANGEPASSWORD) {
            redirectURL = DataRedirectToFrom.fromPath;
        } else {
            console.log("DataLogin.userModel.userRoleId: ", DataLogin.userModel.userRoleId)
            if (DataLogin.userModel.userRoleId === Roles.NHS) {
                redirectURL = ROUTES_DATA.NHS;
            } else {
                if (DataLogin.userModel.modules?.length > 0) {
                    if (Modules.BedManagement === DataLogin.userModel.modules[0].module) {
                        redirectURL = ROUTES_DATA.DASHBOARD;
                    } else if (Modules.BillingManagement === DataLogin.userModel.modules[0].module) {
                        redirectURL = ROUTES_DATA.BILLINGMANAGEMENT;
                    } else if (Modules.ReferralManagement === DataLogin.userModel.modules[0].module) {
                        redirectURL = ROUTES_DATA.REFERRALMANAGEMENT;
                    } else if (Modules.OnlineHelp === DataLogin.userModel.modules[0].module) {
                        redirectURL = ROUTES_DATA.HELP;
                    } else if (DataLogin.userModel.userRoleId === Roles.ReferralManager && Modules.ViewMap === DataLogin.userModel.modules[0].module) {
                        redirectURL = ROUTES_DATA.REFERRALMANAGERVIEWMAP;
                    } else if (DataLogin.userModel.userRoleId === Roles.ServiceManager && Modules.ViewMap === DataLogin.userModel.modules[0].module) {
                        redirectURL = ROUTES_DATA.SERVICEMANAGERVIEWMAP;
                    } else {
                        redirectURL = ROUTES_DATA.DASHBOARD;
                    }
                } else {
                    redirectURL = ROUTES_DATA.AccessDenied;
                }
            }
        }
    }
    return StatusLogin === true && auth.token !== '' ? <Navigate to={redirectURL} /> : <Outlet />;
};

export default PublicRoutes;
