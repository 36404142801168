import React from "react";
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PlanHistoryContainer from "../../../../../../../components/PlanHistoryModal/indexContainer";
import RenderModalContainer from "../../../../../../../components/RenderModal/indexContainer";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { getAllPlanData } from "../../../../../../../service/dashboard-service";

const FundingPlanView = (props: any) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [plan,setplan] = useState<any>();
    const [count,setcount] = useState<any>();
    const [title,settitle] = useState<any>();
    const [plandata,setplandata] = useState<any>();
    const [nplandata,setnplandata] = useState<any>();
    const [totalCount,settotalcount] = useState<any>();
    const { StatusListContract, DataListContract } = useSelector((state: RootState) => state.listContract);
    
    
    async function getallplandata(plan:any,count:any) {
        const payload: any = {planType:plan,admissionId:props.admissionId};
        const res = await getAllPlanData(payload);
      if(res.status){
        setplan(plan);
        setcount(count);
        setplandata(res.data);
        settotalcount(res.totalCount);
      }
        
    }
   
    const handleClickModal = (plan:any,count:any) =>{
        setplan(undefined);
        getallplandata(plan,count)
    }
    const toggle = () => {
        setShowModal(false);
    };

    useEffect(()=>{
        if(plan !== undefined){
            setShowModal(true);
        }
       
    },[plan])

    useEffect(()=>{
            if(plan === 1){
                settitle('Expired Plan')
            }
           else if(plan === 2){
                settitle('Current Plan')
            }
            else{
                settitle('Future Plan')
            }
    },[title,plan])

    useEffect(()=>{
      
    },[DataListContract])
    
    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className={classNames('accordion-button', { collapsed: props.accordID !== 0 })}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${props.accordID}`}
                        aria-expanded={props.accordID !== 0 ? 'false' : 'true'}
                        aria-controls={`flush-collapse${props.accordID}`}
                    >
                        {t('SplitFundingPopup.Funding_Plans')}
                    </button>
                </h2>
                <div
                    id={`flush-collapse${props.accordID}`}
                    className={classNames('accordion-collapse collapse', { show: props.accordID === 0 })}
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#admissiondetail1"
                >
                    <div className="accordion-body pb-0">
                        <ul className="plan-list">
                            {DataListContract !== "" && DataListContract?.data?.planCountViewModel !== "" && <>
                            {DataListContract?.data?.planCountViewModel?.expiredPlanCount !== 0 && <>
                            <li className="expired">
                                <span className="me-2"><i className="fa-solid fa-circle"></i></span>
                                {t('SplitFundingPopup.Expired_Plan')} <span>{`(${DataListContract?.data?.planCountViewModel?.expiredPlanCount})`}</span>
                                <a href="#" onClick={(e:any)=>handleClickModal(1,DataListContract?.data?.planCountViewModel?.expiredPlanCount)}><i className="fa-solid fa-eye"></i></a>
                            </li>
                            </>}
                            {DataListContract?.data?.planCountViewModel?.currentPlanCount !== 0 && <>
                            <li className="current">
                                <span className="me-2"><i className="fa-solid fa-circle"></i></span>
                                {t('SplitFundingPopup.Current_Plan')} <span>{`(${DataListContract?.data?.planCountViewModel?.currentPlanCount})`}</span>
                                <a href="#" onClick={(e:any)=>handleClickModal(2,DataListContract?.data?.planCountViewModel?.currentPlanCount)}><i className="fa-solid fa-eye"></i></a>
                            </li>
                             </>}
                             {DataListContract?.data?.planCountViewModel?.futurePlanCount !== 0 && <>
                            <li className="future">
                                <span className="me-2"><i className="fa-solid fa-circle"></i></span>
                                {t('SplitFundingPopup.Future_Plan')} <span>{`(${DataListContract?.data?.planCountViewModel?.futurePlanCount})`}</span>
                                <a href="#" onClick={(e:any)=>handleClickModal(3,DataListContract?.data?.planCountViewModel?.futurePlanCount)}><i className="fa-solid fa-eye"></i></a>
                            </li>
                            </>}
                            </>}
                        </ul>
                        <RenderModalContainer>
                        {showModal && <PlanHistoryContainer title={title} plantype={plan} count={count} onClose={toggle} plandata={plandata} totalCount={totalCount} /> }
                        </RenderModalContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundingPlanView;