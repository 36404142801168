import React from 'react';
import QuestionAnswerView from './QuestionAnswerView';

const QuestionAnswerContainer = () => {

    return (
        <div>
            <QuestionAnswerView />
        </div>
    );
};

export default QuestionAnswerContainer;