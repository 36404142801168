import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import swal from 'sweetalert';
import NoteContainer from '../../../../../../components/NoteModal/indexContainer';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import note from '../../../../../../assets/images/note.svg';
import warning from '../../../../../../assets/images/warning.png';

import { useTranslation } from 'react-i18next';
import { BASEURLDEV, API_ENDPOINT } from '../../../../../../service/ApiConstants';
import { deleteContactDocument, getContactDocument } from '../../../../../../service/dashboard-service';
import { RootState, store } from '../../../../../../store';
import { LeadFilterServiceType } from '../../../../../../utils/constant';

registerPlugin(FilePondPluginFileValidateSize,FilePondPluginFileValidateType);

const FunderListView = (props: any) => {
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        funderList: Yup.string().required(),
        funders: Yup.array().of(
            Yup.object().shape({
                funderData: Yup.object().shape({
                    fEmailId: Yup.string()
                        .trim()
                        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        .email('Email is Invalid')
                        .notRequired(),
                    fFunder: Yup.string().trim().notRequired(),
                    fContactNo: Yup.string()
                        .trim()
                        .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                        .notRequired(),
                    fAddress1: Yup.string().trim().notRequired(),
                    fCity: Yup.string().trim().notRequired(),
                    fPoBox: Yup.string()
                        .trim()
                        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                        .notRequired(),
                    funderContract: Yup.string().notRequired(),
                    fCheck: Yup.boolean().notRequired(),
                    deletebtn: Yup.string().notRequired(),
                    isFilePond: Yup.boolean().notRequired(),
                    contractPDF: Yup.mixed().notRequired(),
                    ftype: Yup.string().notRequired(),
                    farray:Yup.mixed().notRequired(),
                    fpo:Yup.string().notRequired()
                }),
                commisionerData: Yup.object().shape({
                    isselffunder:Yup.boolean().notRequired(),
                    cEmailId: Yup.string().when("isselffunder", {
                        is: true,
                        then : (schema: any) => schema.nonNullable().notRequired(),
                        otherwise:  (schema: any) => schema.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        .email('Email is Invalid')
                        .required('Email is required')}),
                    cFunder:  Yup.string().when('isselffunder', {
                        is: true,
                        then : (schema: any) => schema.nonNullable().notRequired(),
                        otherwise:  (schema: any) =>schema.required()}),
                    cContactNo:  Yup.string().when('isselffunder', {
                        is: true,
                        then : (schema: any) => schema.nonNullable().notRequired(),
                        otherwise:  (schema: any) => schema.matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
                        .nonNullable()
                        .required('contact is required')}),
                    cAddress1: Yup.string().trim().notRequired(),
                    cCity: Yup.string().trim().notRequired(),
                    cPoBox: Yup.string()
                        .trim()
                        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                        .notRequired(),
                    sEmailId: Yup.string()
                        .trim()
                        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        .email('Email is Invalid')
                        .notRequired(),
                    sFunder: Yup.string().trim().notRequired(),
                    sContactNo: Yup.string()
                        .trim()
                        .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
                        .notRequired(),
                    sAddress1: Yup.string().trim().notRequired(),
                    sCity: Yup.string().trim().notRequired(),
                    sPoBox: Yup.string()
                        .trim()
                        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
                        .notRequired()
                })
            })
        )
    });
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    let [FunderData, setFunderData] = useState<any>([]);
    let [CityData, setCityData] = useState<any>([]);
    let [isFunderTypeChange,setFunderTypeChange] = useState<any>(false);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [funderNote, setFunderNote] = useState<any>('');
    const [fIndex,setfIndex]=useState<number>(0);
    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    useEffect(() => {
        if (DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0) {
            setFunderData(DataFunderInfo.data);
        }
    }, [DataFunderInfo]);

    useEffect(() => {
        if (DataCityInfo.data !== undefined && DataCityInfo.data.length > 0) {
            setCityData(DataCityInfo.data);
        }
    });

    useEffect(() => {
        if (props.changetype) {
            setValue(`funders[${props.funderindex}].funderData.fFunder`, '');
            setValue(`funders[${props.funderindex}].funderData.fEmailId`, '');
            setValue(`funders[${props.funderindex}].funderData.fContactNo`, '');
            setValue(`funders[${props.funderindex}].funderData.fAddress1`, '');
            setValue(`funders[${props.funderindex}].funderData.fCity`, '');
            setValue(`funders[${props.funderindex}].funderData.fPoBox`, '');
            setValue(`funders[${props.funderindex}].funderData.fpo`, '');
            setValue(`funders[${props.funderindex}].funderData.farray`, '');
            setValue(`funders[${props.funderindex}].funderData.farray`, DataFunderInfo.data);

            setValue(`funders[${props.funderindex}].commisionerData.cFunder`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cEmailId`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cContactNo`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cAddress1`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cCity`, '');
            setValue(`funders[${props.funderindex}].commisionerData.cPoBox`, '');
            
            if(props.fundertype === '4'){
                props.updateselffunder(props.funderindex);
            }
        }
    }, [props.changetype,props.fundertype,DataFunderInfo]);

    const { register, control, handleSubmit, getValues, reset, formState, watch, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'funders', control });

    const funderList = watch('funderList');
    const [file, setFiles] = useState<any[]>([]);
    const [fileres, setFileres] = useState<any>('');
    const [isFilepond, setFilepond] = useState<any>(false);
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;

    useEffect(() => {
        props.getfundelisterror(errors);
    }, [formState]);

    useEffect(() => {
        props.getfundelisterror(errors);
    }, [file]);

    useEffect(()=>{
     
    },[DataGridFilterState])

    useEffect(() => {
        if (props.funderList.length > 0) {
            const newVal = props.funderList.length;
            const oldVal = fields.length;
            if (newVal > oldVal) {
                for (let i = oldVal; i < newVal; i++) {
                    append(props.funderList[newVal - 1]);
                    setValue(`funders[${i}].funderData.isFilePond`, props.funderList[i].funderData.isFilePond);
                    setValue(`funders[${i}].funderData.funderContract`, props.funderList[i].funderData.filename || '');
                    setValue(`funders[${i}].funderData.fFunder`, props.funderList[i].funderData.fFunder || '');
                    setValue(`funders[${i}].funderData.fEmailId`, props.funderList[i].funderData.fEmailId || '');
                    setValue(`funders[${i}].funderData.fContactNo`, props.funderList[i].funderData.fContactNo || '');
                    setValue(`funders[${i}].funderData.fAddress1`, props.funderList[i].funderData.fAddress1 || '');
                    setValue(`funders[${i}].funderData.fCity`, props.funderList[i].funderData.fCity || '');
                    setValue(`funders[${i}].funderData.fPoBox`, props.funderList[i].funderData.fPoBox || '');
                    setValue(`funders[${i}].funderData.ftype`, props.funderList[i].funderData.ftype || '');
                    setValue(`funders[${i}].commisionerData.isselffunder`, props.funderList[i].funderData.ftype === '4' ? true : false);
                    setValue(`funders[${i}].funderData.farray`, props.funderList[i].funderData.farray || '');
                    setValue(`funders[${i}].funderData.fsuid`, props.funderList[i].funderData?.fsuid || '');
                    setValue(`funders[${i}].funderData.fpo`, props.funderList[i].funderData?.fpo || '');
                    setValue(`funders[${i}].funderData.fnote`, props.funderList[i].funderData?.fnote || '');
                    setValue(`funders[${i}].funderData.isNoteEdited`, props.funderList[i].funderData?.fnote != undefined && props.funderList[i].funderData?.fnote != '' ? true : false);

                    setValue(`funders[${i}].commisionerData.cFunder`, props.funderList[i].commisionerData.cFunder || '');
                    setValue(`funders[${i}].commisionerData.cEmailId`, props.funderList[i].commisionerData.cEmailId || '');
                    setValue(`funders[${i}].commisionerData.cContactNo`, props.funderList[i].commisionerData.cContactNo || '');
                    setValue(`funders[${i}].commisionerData.cAddress1`, props.funderList[i].commisionerData.cAddress1 || '');
                    setValue(`funders[${i}].commisionerData.cCity`, props.funderList[i].commisionerData.cCity || '');
                    setValue(`funders[${i}].commisionerData.cPoBox`, props.funderList[i].commisionerData.cPoBox || '');

                    setValue(`funders[${i}].commisionerData.sFunder`, props.funderList[i].commisionerData.sFunder || '');
                    setValue(`funders[${i}].commisionerData.sEmailId`, props.funderList[i].commisionerData.sEmailId || '');
                    setValue(`funders[${i}].commisionerData.sContactNo`, props.funderList[i].commisionerData.sContactNo || '');
                    setValue(`funders[${i}].commisionerData.sAddress1`, props.funderList[i].commisionerData.sAddress1 || '');
                    setValue(`funders[${i}].commisionerData.sCity`, props.funderList[i].commisionerData.sCity || '');
                    setValue(`funders[${i}].commisionerData.sPoBox`, props.funderList[i].commisionerData.sPoBox || '');
                }
            } else if (newVal === oldVal) {
                if (props.funderindex >= 0) {
                      
                    setValue(`funders[${props.funderindex}].funderData.ftype`, props.funderList[props.funderindex].funderData.ftype || '', { shouldValidate: true });
                    setValue(`funders[${props.funderindex}].commisionerData.isselffunder`, props.funderList[props.funderindex].funderData.ftype === '4' ? true : false);
                    setValue(`funders[${props.funderindex}].funderData.isFilePond`, props.funderList[props.funderindex].funderData.isFilePond);
                    setValue(`funders[${props.funderindex}].funderData.funderContract`, props.funderList[props.funderindex].funderData.filename || '');
                    setValue(`funders[${props.funderindex}].funderData.filepath`, props.funderList[props.funderindex].funderData.filepath || '', { shouldValidate: false });
                    setValue(`funders[${props.funderindex}].funderData.fFunder`, props.funderList[props.funderindex].funderData.fFunder || '');
                    setValue(`funders[${props.funderindex}].funderData.fEmailId`, props.funderList[props.funderindex].funderData.fEmailId || '');
                    setValue(`funders[${props.funderindex}].funderData.fContactNo`, props.funderList[props.funderindex].funderData.fContactNo || '');
                    setValue(`funders[${props.funderindex}].funderData.fAddress1`, props.funderList[props.funderindex].funderData.fAddress1 || '');
                    setValue(`funders[${props.funderindex}].funderData.fCity`, props.funderList[props.funderindex].funderData.fCity || '');
                    setValue(`funders[${props.funderindex}].funderData.fPoBox`, props.funderList[props.funderindex].funderData.fPoBox || '');
                    setValue(`funders[${props.funderindex}].funderData.fsuid`, props.funderList[props.funderindex].funderData.fsuid || '');
                    setValue(`funders[${props.funderindex}].funderData.fpo`, props.funderList[props.funderindex].funderData.fpo || '');
                    setValue(`funders[${props.funderindex}].funderData.fnote`, props.funderList[props.funderindex].funderData.fnote || '');                     
                    setValue(`funders[${props.funderindex}].funderData.isNoteEdited`, props.funderList[props.funderindex].funderData?.fnote != undefined && props.funderList[props.funderindex].funderData?.fnote != '' ? true : false);

                    if(props.commisionerindex >= 0){

                        setValue(`funders[${props.commisionerindex}].commisionerData.cFunder`, props.funderList[props.commisionerindex].commisionerData.cFunder || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.cEmailId`, props.funderList[props.commisionerindex].commisionerData.cEmailId || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.cContactNo`, props.funderList[props.commisionerindex].commisionerData.cContactNo || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.cAddress1`, props.funderList[props.commisionerindex].commisionerData.cAddress1 || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.cCity`, props.funderList[props.commisionerindex].commisionerData.cCity || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.cPoBox`, props.funderList[props.commisionerindex].commisionerData.cPoBox || '');
    
                        setValue(`funders[${props.commisionerindex}].commisionerData.sFunder`, props.funderList[props.commisionerindex].commisionerData.sFunder || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.sEmailId`, props.funderList[props.commisionerindex].commisionerData.sEmailId || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.sContactNo`, props.funderList[props.commisionerindex].commisionerData.sContactNo || '', { shouldValidate: true });
                        setValue(`funders[${props.commisionerindex}].commisionerData.sAddress1`, props.funderList[props.commisionerindex].commisionerData.sAddress1 || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.sCity`, props.funderList[props.commisionerindex].commisionerData.sCity || '');
                        setValue(`funders[${props.commisionerindex}].commisionerData.sPoBox`, props.funderList[props.commisionerindex].commisionerData.sPoBox || '');
                        }
                }
                if (props.index >= 0) {
                    setValue(`funders[${props.index}].commisionerData.cFunder`, props.funderList[props.index].commisionerData.cFunder || '');
                    setValue(`funders[${props.index}].commisionerData.cEmailId`, props.funderList[props.index].commisionerData.cEmailId || '');
                    setValue(`funders[${props.index}].commisionerData.cContactNo`, props.funderList[props.index].commisionerData.cContactNo || '');
                    setValue(`funders[${props.index}].commisionerData.cAddress1`, props.funderList[props.index].commisionerData.cAddress1 || '');
                    setValue(`funders[${props.index}].commisionerData.cCity`, props.funderList[props.index].commisionerData.cCity || '');
                    setValue(`funders[${props.index}].commisionerData.cPoBox`, props.funderList[props.index].commisionerData.cPoBox || '');
                }
            }
        }
    }, [props.funderList]);

    useEffect(() => {
        if (!props.isCheck) {
            setValue(`funders[${props.funderindex}].funderData.fCheck`, false);
        }
    }, [props.isCheck]);

    const addrecord = () => {
        props.isadd(true);
    };

    const edit = () => {
    };

    const deleterecord = (index: number,data:any) => {
        
        remove(index);
        props.isdelete(index,data);
    };

    const removepdf = (i: number, e: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove contract?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: getValues(`funders[${i}].funderData.filepath`) };
                const response = await deleteContactDocument(payload);
                if (response.status == true) {
                    props.updatefilepond(i);
                } else {
                }
            }
        });
    };

    const openpdf = async (e: any, fpath: any) => {
        e.preventDefault();
        const payload = { path: fpath };
        const response = await getContactDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    const handleNoteClickModal = (i:any,noteval:any) => {             
        setfIndex(i);
        setFunderNote(noteval);
        setShowNoteModal(true);
    }
    const updateNote = (e: any,i: any) => {
        
        setShowNoteModal(false);
        e.target.name =`funders[${i}].funderData.fnote`;
        setValue(`funders[${i}].funderData.fnote`, e.target.value || '');
          
        if(e.target.value.length > 0)
         {
            setValue(`funders[${i}].funderData.isNoteEdited`, true);
         }else{
            setValue(`funders[${i}].funderData.isNoteEdited`, false);
         }
        
        props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
    }

    const toggleNote = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
            .then(async (confirm: any) => {
                if (confirm) {
                    setShowNoteModal(false);
                }
            })
    };

    return (
        <>
            {props.funderList.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mb-2" style={{ pointerEvents: ((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? 'all' : 'none' }}>
                    <label className="bold">Funders</label>
                    <button disabled={props.iseditable ? false : true} onClick={addrecord} className="btn btn-primary btn-sm purple-background ms-1 me-1" data-tooltip-id="my-tooltip-add-funder" data-tooltip-content="Add Funder" id="add-funder">
                        <i className="fa-solid fa-plus"></i> Add
                    </button>
                </div>
            )}
            <form>
                {props.funderList.length > 0 &&
                    fields.map((fundersdata: any, i: number) => {
                        return (
                            <div key={i} className="accordion accordion-flush" id="accordionFunder">
                                <div className="accordion-item white mt-0">
                                    {/* <div style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}> */}
                                    <h2 className="accordion-header" id={`funder-item${i}`}>
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#funder-collapse${i}`}
                                            aria-expanded="false"
                                            aria-controls={`#funder-collapse${i}`}
                                        >
                                            <div className="funder-header w-100 me-2 align-items-center d-flex justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <span className="bold funder-num">{i + 1}</span>
                                                    <label className="bold funder-name">{props.funderList[i].funderData.fFunderVal}</label>
                                                </div>
                                                <div>
                                                    <a onClick={edit} className="btn-icon gray-background me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </a>

                                                    <a onClick={(e:any) => deleterecord(i,fundersdata)}  className="btn-icon red-background" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id={`funder-collapse${i}`} className="accordion-collapse collapse" aria-labelledby={`funder-item${i}`}  data-bs-parent="#accordionFunder">
                                        <div className="accordion-body">
                                            <div className="funder-detail" style={{ pointerEvents: ((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? 'all' : 'none' }}>
                                                <div className="d-flex justify-content-between mb-1 mt-2">
                                                    <label className="mb-2">{t('VacantRightbar.ftype')}</label>

                                                    <label className="form--radio-label mb-2  d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="CCG"
                                                            value="1"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.ccg')}</span>
                                                    </label>

                                                    <label className="form--radio-label  mb-2 d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="LA"
                                                            value="2"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.la')}</span>
                                                    </label>

                                                    <label className="form--radio-label  mb-2 d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="Charity"
                                                            value="3"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.ch')}</span>
                                                    </label>

                                                    <label className="form--radio-label mb-2 d-flex align-items-center">
                                                    <input
                                                            type="radio"
                                                            {...register(`funders.${i}.funderData.ftype`, {
                                                                onChange: (e: any) => {
                                                                    props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                                }
                                                            })}
                                                            disabled
                                                            name={`funders.${i}.funderData.ftype` as const}
                                                            id="Self"
                                                            value="4"
                                                        />

                                                        <span className="ms-1">{t('VacantRightbar.self')}</span>
                                                    </label>
                                                </div>
                                                {getValues(`funders.${i}.funderData.ftype`) !== '4' && <>
                                                <div className="d-flex mb-2">
                                                    <select
                                                        {...register(`funders.${i}.funderData.fFunder`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fFunder` as const}
                                                        id="Funder"
                                                        disabled
                                                        //@ts-ignore
                                                        className={`form-control white flex-7 ${errors.funders?.[i]?.funderData?.fFunder ? 'is-invalid' : ''}`}
                                                    >
                                                        <option disabled={true} value="">
                                                        {t('VacantRightbar.fname')}
                                                        </option>
                                                        {getValues(`funders.${i}.funderData.farray`).length > 0 && getValues(`funders.${i}.funderData.farray`).map((funders: any) => {
                                                                return (
                                                                    <option key={funders.key} value={funders.key}>
                                                                        {funders.value}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                                </>}

                                                {getValues(`funders.${i}.funderData.ftype`) === '4' && <>
                                                <div className="d-flex mb-2">

                                                <input
                                                    type="text"
                                                    disabled
                                                    {...register(`funders.${i}.funderData.fFunder`)}
                                                    name={`funders[${i}].funderData.fFunder` as const}
                                                    placeholder={`${t('VacantRightbar.fname')}`}
                                                    //@ts-ignore
                                                    className={`form-control white flex-7 ${errors.funders?.[i]?.funderData?.fFunder ? 'is-invalid' : ''}`}
                                                />

                                                </div>
                                                </>}


                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fEmailId`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fEmailId` as const}
                                                    placeholder={`${t('VacantRightbar.email')}`}
                                                    // disabled={getValues(`funders.${i}.funderData.ftype`) === '4' ? true : false}
                                                    // style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2 ${errors.funders?.[i]?.funderData?.fEmailId ? 'is-invalid' : ''}`}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <input
                                                        type="text"
                                                        {...register(`funders.${i}.funderData.fContactNo`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fContactNo` as const}
                                                        placeholder={`${t('VacantRightbar.contact')}`}
                                                        // disabled={getValues(`funders.${i}.funderData.ftype`) === '4' ? true : false}
                                                        // style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2 ${errors.funders?.[i]?.funderData?.fContactNo ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fAddress1`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fAddress1` as const}
                                                    placeholder={`${t('VacantRightbar.add1')}`}
                                                    // disabled={getValues(`funders.${i}.funderData.ftype`) === '4' ? true : false}
                                                    // style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.funderData?.fAddress1 ? 'is-invalid' : ''}`}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <select
                                                        {...register(`funders.${i}.funderData.fCity`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fCity` as const}
                                                        id="city"
                                                        // disabled={getValues(`funders.${i}.funderData.ftype`) === '4' ? true : false}
                                                        // style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2  ${errors.funders?.[i]?.funderData?.fCity ? 'is-invalid' : ''}`}
                                                    >
                                                        <option disabled={true} value="">
                                                        {t('VacantRightbar.county')}
                                                        </option>
                                                        {props.citylist &&
                                                            props.citylist?.data?.map((cities: any) => {
                                                                return (
                                                                    <option key={cities.key} value={cities.key}>
                                                                        {cities.value}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <input
                                                        type="text"
                                                        {...register(`funders.${i}.funderData.fPoBox`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fPoBox` as const}
                                                        placeholder={`${t('VacantRightbar.postal')}`}
                                                        // disabled={getValues(`funders.${i}.funderData.ftype`) === '4' ? true : false}
                                                        // style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2 ms-2 ${errors.funders?.[i]?.funderData?.fPoBox ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fsuid`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.ftype`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fsuid` as const}
                                                    placeholder={`${t('VacantRightbar.Suid')}`}
                                                    // disabled={getValues('ftype') === '4' ? true : false}
                                                    // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2`}
                                                    maxLength={40}                                                 
                                                />
                                                  <input
                                                    type="text"
                                                    {...register(`funders.${i}.funderData.fpo`, {
                                                        onChange: (e: any) => {
                                                            props.updateData(e, i, 'funderData', getValues(`funders.${i}.funderData.fpo`));
                                                        }
                                                    })}
                                                    name={`funders[${i}].funderData.fpo` as const}
                                                    placeholder={`${t('VacantRightbar.po_reference')}`}
                                                    // disabled={getValues('ftype') === '4' ? true : false}
                                                    // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2`}
                                                    maxLength={40}                                                 
                                                />

                                                { getValues(`funders.${i}.funderData.fnote`) == ''  && <div className='d-flex mt-2'><img src={note} className='img-fluid me-1'  onClick={()=>handleNoteClickModal(i,getValues(`funders.${i}.funderData.fnote`))}></img><p>{t('VacantRightbar.enter_note')}</p></div>}
                                                { getValues(`funders.${i}.funderData.fnote`) != '' && window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).length >= 30
                                                && (<div className='d-flex mt-2 enabled' ><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(i,getValues(`funders.${i}.funderData.fnote`))}></img><p>{window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).slice(0,27)}...</p>  <p className='bold' onClick={()=>handleNoteClickModal(i,getValues(`funders.${i}.funderData.fnote`))}>{t('VacantRightbar.read_more')}</p></div> )}
                                                { getValues(`funders.${i}.funderData.fnote`) != '' && window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).length > 0 && window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`)).length < 30
                                                && (<div className='d-flex mt-2 enabled'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(i,getValues(`funders.${i}.funderData.fnote`))}></img><p>{window.$utils.convertToPlain(getValues(`funders.${i}.funderData.fnote`))}</p></div> )}

                                                {getValues(`funders.${i}.funderData.ftype`) !== '4' &&
                                               <>
                                                <label className="mb-2 bold mt-3">{t('VacantRightbar.comm')}</label>
                                                <div className="form-check">
                                                    <input
                                                        {...register(`funders[${i}].funderData.fCheck`, {
                                                            onChange: (e: any) => {
                                                                props.updateData(e, i, 'funderData',getValues(`funders.${i}.funderData.ftype`));
                                                            }
                                                        })}
                                                        name={`funders[${i}].funderData.fCheck` as const}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultChecked={false}
                                                        id="checkboxsameas"
                                                    />
                                                    <label className="form-check-label">{t('VacantRightbar.same')}</label>
                                                </div>
                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.commisionerData.cFunder`, {
                                                        onChange: (e: any) => {
                                                            props.updateCData(e, i, 'commisionerData');
                                                        }
                                                    })}
                                                    name={`funders[${i}].commisionerData.cFunder` as const}
                                                    placeholder={`${t('VacantRightbar.cname')}`}                                                    //@ts-ignore
                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cFunder ? 'is-invalid' : ''}`}
                                                />

                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.commisionerData.cEmailId`, {
                                                        onChange: (e: any) => {
                                                            props.updateCData(e, i, 'commisionerData');
                                                        }
                                                    })}
                                                    name={`funders[${i}].commisionerData.cEmailId` as const}
                                                    placeholder={`${t('VacantRightbar.email')}`}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cEmailId ? 'is-invalid' : ''}`}
                                                />

                                                <div className="d-flex justify-content-between">
                                                    <input
                                                        type="text"
                                                        {...register(`funders.${i}.commisionerData.cContactNo`, {
                                                            onChange: (e: any) => {
                                                                props.updateCData(e, i, 'commisionerData');
                                                            }
                                                        })}
                                                        name={`funders[${i}].commisionerData.cContactNo` as const}
                                                        placeholder={`${t('VacantRightbar.contact')}`}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2 ${errors.funders?.[i]?.commisionerData?.cContactNo ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    {...register(`funders.${i}.commisionerData.cAddress1`, {
                                                        onChange: (e: any) => {
                                                            props.updateCData(e, i, 'commisionerData');
                                                        }
                                                    })}
                                                    name={`funders[${i}].commisionerData.cAddress1` as const}
                                                    placeholder={`${t('VacantRightbar.add1')}`}
                                                    //@ts-ignore
                                                    className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cAddress1 ? 'is-invalid' : ''}`}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <select
                                                        {...register(`funders.${i}.commisionerData.cCity`, {
                                                            onChange: (e: any) => {
                                                                props.updateCData(e, i, 'commisionerData');
                                                            }
                                                        })}
                                                        name={`funders[${i}].commisionerData.cCity` as const}
                                                        id="ccity"
                                                        //@ts-ignore
                                                        className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.cCity ? 'is-invalid' : ''}`}
                                                    >
                                                        <option value="">
                                                        {t('VacantRightbar.county')}
                                                        </option>
                                                        {props.citylist &&
                                                            props.citylist?.data?.map((cities: any) => {
                                                                return (
                                                                    <option key={cities.key} value={cities.key}>
                                                                        {cities.value}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <input
                                                        type="text"
                                                        {...register(`funders.${i}.commisionerData.cPoBox`, {
                                                            onChange: (e: any) => {
                                                                props.updateCData(e, i, 'commisionerData');
                                                            }
                                                        })}
                                                        name={`funders[${i}].commisionerData.cPoBox` as const}
                                                        placeholder={`${t('VacantRightbar.postal')}`}
                                                        //@ts-ignore
                                                        className={`form-control white mb-2 ms-2 ${errors.funders?.[i]?.commisionerData?.cPoBox ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                 {Number(props.selfData?.servicetype) !== 1 && getValues(`funders.${i}.funderData.ftype`) !== '1' && <>                           
                                            
                    <label className="mb-2 bold mt-3">{t('VacantRightbar.social')}</label>
                    <input
                        type="text"
                        {...register(`funders.${i}.commisionerData.sFunder`, {
                            onChange: (e: any) => {
                                props.updateSData(e, i, 'commisionerData');
                            }
                        })}
                        name={`funders[${i}].commisionerData.sFunder` as const}
                        placeholder={`${t('VacantRightbar.sname')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.sFunder ? 'is-invalid' : ''}`}
                    />

                    <input
                        type="text"
                        {...register(`funders.${i}.commisionerData.sEmailId`, {
                            onChange: (e: any) => {
                                props.updateSData(e, i, 'commisionerData');
                            }
                        })}
                        name={`funders[${i}].commisionerData.sEmailId` as const}
                        placeholder={`${t('VacantRightbar.email')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.sEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">

                        <input
                            type="text"
                            {...register(`funders.${i}.commisionerData.sContactNo`, {
                                onChange: (e: any) => {
                                    props.updateSData(e, i, 'commisionerData');
                                }
                            })}
                            name={`funders[${i}].commisionerData.sContactNo` as const}
                            placeholder={`${t('VacantRightbar.contact')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.funders?.[i]?.commisionerData?.sContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register(`funders.${i}.commisionerData.sAddress1`, {
                            onChange: (e: any) => {
                                props.updateSData(e, i, 'commisionerData');
                            }
                        })}
                        name={`funders[${i}].commisionerData.sAddress1` as const}
                        placeholder={`${t('VacantRightbar.add1')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.funders?.[i]?.commisionerData?.sAddress1 ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <select
                            {...register(`funders.${i}.commisionerData.sCity`, {
                                onChange: (e: any) => {
                                    props.updateSData(e, i, 'commisionerData');
                                }
                            })}
                            name={`funders[${i}].commisionerData.sCity` as const}
                            id="sCity"
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.funders?.[i]?.commisionerData?.sCity ? 'is-invalid' : ''}`}
                        >
                            <option value="">{t('VacantRightbar.county')}</option>
                            {props.citylist &&
                                props.citylist.data.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        <input
                            type="text"
                            {...register(`funders.${i}.commisionerData.sPoBox`, {
                                onChange: (e: any) => {
                                    props.updateSData(e, i, 'commisionerData');
                                }
                            })}
                            name={`funders[${i}].commisionerData.sPoBox` as const}
                            placeholder={`${t('VacantRightbar.postal')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.funders?.[i]?.commisionerData?.cPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                      </>
                    }
                    </>
                    }
                    {/* <div className="d-flex">
                                                    <div className="w-100">
                                                        {getValues(`funders[${i}].funderData.isFilePond`) == false && (
                                                            <>
                                                                <div className="d-flex justify-content-between mt-2 ">
                                                                    <div className="input-readonly" data-tooltip-id="my-tooltip3" data-tooltip-content="Contract Document  ">
                                                                        <textarea
                                                                            rows={4}
                                                                            {...register(`funders[${i}].funderData.funderContract`, {})}
                                                                            name={`funders[${i}].funderData.funderContract` as const}
                                                                            onClick={(e: any) => openpdf(e, getValues(`funders[${i}].funderData.filepath`))}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(i, e)}>
                                                                        <i className="fa-solid fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )}
                                                        {getValues(`funders[${i}].funderData.isFilePond`) == true && (
                                                            <>
                                                                <div style={{ pointerEvents: props.patientname != '' ? 'all' : 'none' }}>
                                                                    <Controller
                                                                        control={control}
                                                                        name={`funders[${i}].funderData.contractPDF` as const}
                                                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                                            return (
                                                                                <FilePond
                                                                                    files={file}
                                                                                    onupdatefiles={setFiles}
                                                                                    // @ts-ignore
                                                                                    allowFileSizeValidation={true}
                                                                                    maxFileSize={10000000}
                                                                                    allowFileTypeValidation={true}
                                                                                    acceptedFileTypes={['application/pdf','image/*','application/vnd.ms-excel','application/vnd.ms-powerpoint','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                                                                                    fileValidateTypeLabelExpectedTypes={""}
                                                                                    labelMaxFileSizeExceeded={'File is too large'}
                                                                                    name={'contractPDF'}
                                                                                    labelIdle={`${t('VacantRightbar.pdftitle1')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                                                    server={{
                                                                                        url: `${BASEURLDEV}${API_ENDPOINT.UploadDocument}`,
                                                                                        process: {
                                                                                            headers: {
                                                                                                Authorization: `Bearer ${token}`
                                                                                            },
                                                                                            ondata: (formData) => {
                                                                                                formData.append('patientName', props.patientname);
                                                                                                formData.append('funderName', props.funderList[i].funderData.fFunderVal);
                                                                                                return formData;
                                                                                            },
                                                                                            //@ts-ignore
                                                                                            onload: (res: any) => {
                                                                                                const fileres = JSON.parse(res);
                                                                                                let filedata = fileres.data;
                                                                                                setFileres(fileres.data.filePath);
                                                                                                props.updateFData({ file, filedata, i });
                                                                                                setFiles([]);
                                                                                            }
                                                                                        },
                                                                                        remove: (source, load, error) => {
                                                                                            error('oh my goodness');
                                                                                            load();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />

                                                                    <p className="small mt-2 ">
                                                                    <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note2')}
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                    
                                {/* </div> */}
                            </div>
                            </div>
                        );
                    })}
            </form>

            <Tooltip id="my-tooltip3" className="my-tooltip" />
            <Tooltip id="my-tooltip-add-funder" className="my-tooltip" />
            <RenderModalContainer>
                 {showNoteModal && <NoteContainer  onClose={toggleNote} title={props.funderList[fIndex]?.funderData?.fFunderVal != null ?props.funderList[fIndex]?.funderData?.fFunderVal+"'s Notes" : t('VacantRightbar.funder_note')} setFunderNote={funderNote} notebody={updateNote} index={fIndex} isReadonly={((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? false : true} isEditing={getValues(`funders[${fIndex}].funderData.isNoteEdited`)}/> }
            </RenderModalContainer>
        </>
    );
};

export default FunderListView;
