import React, { useEffect, useState } from 'react';
import { GetProviderServiceSectionDetails } from '../../../../../../service/service-details-service';
import rotateheart from "../../../../../../assets/images/rotate-heart-icon.svg"
import { useParams } from 'react-router-dom';
//@ts-ignore
import * as dompurify from 'dompurify';


const ProviderServiceDetailView = (props: any) => {
    const [providerServiceDetails, SetProviderServiceDetails] = useState<any>(null)
    const { id } = useParams();
    const sanitizer = dompurify.sanitize;


    useEffect(() => {
        GetProviderServiceDetailsList()
    }, [])

    const GetProviderServiceDetailsList = async () => {
        const result = await GetProviderServiceSectionDetails(id)
        SetProviderServiceDetails(result?.data)
    }

    const [activeItem, setActiveItem] = useState([0]);
    useEffect(() => {
        setActiveItem([0]);
    }, [])
    const handleExpandAll = () => {
        const allIndexes = providerServiceDetails.services.map((_:any, index:any) => index);
        setActiveItem(allIndexes);
    };
    const handleToggle = (index:any) => {
        setActiveItem((prevActiveItems) => {
            if (prevActiveItems.includes(index)) {
                return prevActiveItems.filter((item) => item !== index);
            } else {
                return [index];
            }
        });
    };

    return (
        <div className='service-detail-top-section'>
            <div className='container-fluid mt-3 mb-3 p-0'>
                <div className='row'>
                    <div className='service-link-main'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item sky-color"><a href={`/service-detail/${providerServiceDetails?.slugName}`} >{providerServiceDetails?.serviceName}</a></li>
                                <li className="breadcrumb-item" aria-current="page">{providerServiceDetails?.name}</li>
                            </ol>
                        </nav>
                        <div className="service-link-top">
                            <h3>{providerServiceDetails?.name}</h3>
                            <p className='locate-quotelinkbox'>“{providerServiceDetails?.quote}” - Locate Care<sup><img src={rotateheart} alt="" width={10} /></sup></p>
                        </div>
                        <div className='service-link-detail-box'>
                            {/* <p className='rightside-text-linkbox'>{providerServiceDetails?.views} Views</p> */}
                            <div className='expand-part'>
                                <button onClick={handleExpandAll} className="btn primary-btn mb-3">
                                    Expand All
                                </button>
                            </div>

                            <div className='service-link-gray'>
                                {/* <div className='link-details-innercontent border-bottom'>
                                    <h5>Description</h5>
                                    <p>{providerServiceDetails?.description}</p>
                                </div> */}
                                <div className="accordion qa-accordion service-accordion" id="accordionServiceDetails">
                                {providerServiceDetails &&
                                    providerServiceDetails?.services &&
                                    providerServiceDetails?.services.length > 0 &&
                                    providerServiceDetails?.services.map((sd: any, index: any) => {
                                        return (
                                            // <div key={index} className='link-details-innercontent border-bottom'>
                                            //     <h5>{sd?.header}</h5>
                                            //     <div dangerouslySetInnerHTML={{ __html: sanitizer(sd?.body) }} />
                                            // </div>
                                            <div key={index} className="accordion-item">
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button className={`accordion-button ${activeItem.includes(index) ? "" : "collapsed"}`} 
                                                    type="button" 
                                                    // data-bs-toggle="collapse" 
                                                    // data-bs-target={`#collapse${index}`} 
                                                    onClick={() => handleToggle(index)}
                                                    aria-expanded={activeItem.includes(index)}
                                                    aria-controls={`collapse${index}`}
                                                    >
                                                        {sd?.header}
                                                    </button>
                                                </h2>
                                                <div id={`collapse${index}`} 
                                                className={`accordion-collapse collapse ${activeItem.includes(index) ? "show" : ""}`}
                                                aria-labelledby={`heading${index}`} 
                                                >
                                                    <div className="accordion-body">
                                                        <div dangerouslySetInnerHTML={{ __html: sd?.body }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProviderServiceDetailView;