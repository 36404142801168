import React, {useEffect} from "react";
import logo from "../assets/logos/logo.png"
import abstract from "../assets/images/abstract.png"
import lock from "../assets/images/lock.svg"

const AccessDenied = () => {
    useEffect(() => {
        window.$utils.signOutUser();
    }, [])


    return (
        <>
            <div className="error-bg">
                    <div className="abstarct1">
                    <img src={abstract} alt="" />
                </div>
                <div>
                    <a href="#" className="error-logo"><img src={logo} alt="" /></a>
                </div>
                <div className="error-page text-center">
                    <img src={lock} alt="" className="warning-logo-error" width={35} />
                    <h2 className="mb-3 bold mt-3">Access Denied!</h2>
                    <p className="mb-4">You don't have permission to access this page.</p>
                    <a href="/login" className="btn btn-primary">Login</a>
                </div>
               
            </div>
        </>
    );
};
export default AccessDenied;
