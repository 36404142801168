import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';
import { setLeadInformationAction, errorLeadInformationAction } from '../store/lead/getLeaddataReducer';
import eventEmitter from '../utils/eventEmitter';
import { setAssessmentDetailsAction, errorAssessmentDetailsAction } from '../store/assessment/getAssessmentDetailsReducer';


export const updateLeadToAssessment = (id: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.UpdateLeadToAssessment}?leadPoolingId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getLeadDataSearch = (lid:any,sid:any, search:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.ViewLead}?LeadId=${lid}&LeadOptionId=${sid}&SearchValue=${search}`)       
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setLeadInformationAction(resultData));
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorLeadInformationAction(errorData));
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorLeadInformationAction(errorData));
                return errorData;
            }
        });
};

export const getAssessmentDropdown = () => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetAssessmentDropdown}`)       
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const saveAssessmentDetailService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveAssessmentDetail}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getAssessmentDetails = (id: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetAssessmentDetails}?assesmentId=${id}`)       
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setAssessmentDetailsAction(resultData));
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorAssessmentDetailsAction(errorData));
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorAssessmentDetailsAction(errorData));
                return errorData;
            }
        });
};