import React from 'react';
import BillingDetailsLessContainer from './BillingDetailsLess/BillingDetailsLessContainer';
import BillingDetailsMoreContainer from './BillingDetailsMore/BillingDetailsMoreContainer';

const BillingDetailsView = (props: any) => {
    return (
        <div>
             {props.moreInformation === false ? (
            <div className='position-relative'>
                <BillingDetailsLessContainer invoiceStatistics={props.invoiceStatistics} />
                    {/* <p className="see-more" onClick={props.handleShowMoreInfo}>
                        <label id="moreless">More Details</label>
                        <span className="upicon downicon ms-1"></span>
                    </p> */}
            </div>
             ) : (
            <div className='position-relative'>
                <BillingDetailsMoreContainer invoiceStatistics={props.invoiceStatistics} />
                {/* <p className="see-more" onClick={props.handleHideMoreInfo}>
                        <label id="moreless">Less Details</label>
                        <span className="upicon upicon ms-1"></span>
                    </p> */}
            </div>
             )}
           
        </div>
    );
};

export default BillingDetailsView;