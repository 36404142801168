import React from 'react';
import NHSReferralManagementView from './NHSReferralManagementView';


const NHSReferralManagementContainer = () => {
    return (
        <div>
            <NHSReferralManagementView/>
        </div>
    );
};

export default NHSReferralManagementContainer;