import React, { useEffect } from 'react';
import IntegrationInterfaceView from './IntegrationInterfaceView';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { useSelector,useDispatch } from 'react-redux';
import { RootState } from '../../store';

const IntegrationInterfaceContainer = () => {
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
      },[])
    return (
        <IntegrationInterfaceView />
    );
};

export default IntegrationInterfaceContainer;