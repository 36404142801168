import React from 'react';
import AmenitiesFilterView from './AmenitiesFilterView';
import { AmenitiesListType } from '../../../layout/NHSHeader/ServicetypeHeader/SearchFilter/types';

interface AmenitiesContainerProps {
    amenitiesList: AmenitiesListType[];
    checkedAmenities: AmenitiesListType[];
    setCheckedAminities: React.Dispatch<React.SetStateAction<AmenitiesListType[]>>
}

const AmenitiesFilterContainer = (props: AmenitiesContainerProps) => {
    return (
        <div>
            <AmenitiesFilterView {...props} />
        </div>
    );
};

export default AmenitiesFilterContainer;
