import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';
import eventEmitter from '../utils/eventEmitter';
import { setInvoiceStatisticsAction, errorInvoiceStatisticsAction } from '../store/invoice/invoiceStatisticsReducer';


export const getListInvoicesService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetInvoiceList}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getInvoiceStatisticsService = (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetInvoiceStatistics}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setInvoiceStatisticsAction(resultData));
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorInvoiceStatisticsAction(errorData));
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorInvoiceStatisticsAction(errorData));
                return errorData;
            }
        });
};

export const getManagedColumnsService = () => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetManagedColumn}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const UpdateManageColumnsService = (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.UpdateManagedColumn}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};


export const UpdateInvoiceStatusService = (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.UpdateInvoiceStatus}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};


export const DownloadInvoicesService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.DownloadInvoices}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};