import React, { useState, useEffect, useMemo } from 'react';
import ContractListView from './contractListView';
import ApplicableRatesView from './applicableRatesView';
import { getListContractService } from '../../../../../../../service/contract-service';
import { RootState } from '../../../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import FundingPlanView from './fundingPlans';

let PageSize = 5;

const ContractListContainer = (props: any) => {
    const [listContract, setLisContract] = useState<any>();
    const [admissionid,setadmissinid] = useState<any>();
    const { StatusListContract, DataListContract } = useSelector((state: RootState) => state.listContract);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    const { admissionId,bedName } = props.patientData;

    useEffect(() => {
        setadmissinid(admissionId);
        async function getContractList() {
            const payload: any = { id: admissionId};
            await getListContractService(payload);
        }
        getContractList();
    },[admissionId])
    useEffect(() => {
        if (StatusListContract === true) {
            setLisContract(DataListContract.data)
        }
    },[StatusListContract, DataListContract])
    
    return (
        <div>
            {listContract !== undefined &&
                listContract !== null &&
                listContract.contractDetails.length > 0 &&
                listContract.contractDetails.map((data: any, index: any) => {
                    return <ContractListView key={index} dataContract={data} accordID={index} bedName={bedName}/>;
                })}
                {listContract !== undefined &&
                listContract !== null &&
                listContract.applicableRates !== '' &&
                <ApplicableRatesView dataApplicableRates={listContract.applicableRates} />}
                {/* <ApplicableRatesView /> */}
                {DataServiceDetail?.iqviaServiceEnabled === false && (
                    <FundingPlanView admissionId={admissionid}/>
                )}  
        </div>
    );
};

export default ContractListContainer;
