import React, {useEffect} from "react";
import eventEmitter from "../utils/eventEmitter";
import logo from "../assets/logos/logo.png"
import abstract from "../assets/images/abstract.png"
import warning from "../assets/images/warning1.png"
import { saveFrontendErrorLog } from "../service/errorLog-service";
import { useNavigate } from "react-router-dom";
import { ROUTES_DATA } from "../navigation/RoutesConstants";

const GenericErrorComponentSplit = () => {
  const navigate = useNavigate();
  eventEmitter.emit('eventSetLoader', false);
  const signOut = () => {
    window.$utils.signOutUser();
    navigate(ROUTES_DATA.ROOT);
  }
 
  useEffect(() => {
    return () => {
      window.$utils.signOutUser()
    }
  })
    return (
        <div role="alert" className="error-bg">
          <div className="abstarct1">
            <img src={abstract} alt="" />
          </div>
          <div>
              <a href="#" className="error-logo"><img src={logo} alt="" /></a>
          </div>
          <div className="error-page text-center">
            <img src={warning} alt="" width={50} />
            <h2 className="bold">Oops, something went wrong!</h2>  
            <p className="mb-4">Login and try again. <br/> If the error continues please contact your administrator.</p>
            <button onClick={signOut} className="btn btn-primary">Login</button>
          </div>
        </div>
      );
}

export default GenericErrorComponentSplit;