import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const MaintenanceReleaseTabView = (props: any) => {

    const { t } = useTranslation();
    const [disableButton, setDisableButton] = useState<any>(false);

    const validationSchema = Yup.object().shape({
        releaseDate: Yup.date().required('Maintenance Start Date is required'),
        comments: Yup.string().notRequired()
    });

    const { register, handleSubmit, reset, control, formState} = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

    const onSubmitHandler: SubmitHandler<any> = (data) => {
        props.onSubmitHandler(data)
    };

    const {errors} = formState;

    useEffect(() => {
        if(formState.isValid) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [formState]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="input-group date mt-1 mb-2 datepicker-input-css" id="datepicker">
                <Controller
                        control={control}
                        name="releaseDate"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return <DatePicker className={`form-control white ${error ? 'is-invalid' : ''}`} label={`${t('RightSidebarMaintenanceDetails.Release_Date')}*`} format="DD/MM/YYYY" {...fieldProps} onChange={(date: any) => {props.updateData(date);fieldProps.onChange(date);}} disableFuture views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />;
                        }}
                    />
            </div>
            <textarea {...register('comments', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} placeholder={`${t('RightSidebarMaintenanceDetails.Comments_field')}`} className={`form-control white`}></textarea>
            <div className="mt-2 text-center  right_content_btn">
                <button type="submit" disabled={!disableButton} className="btn btn-primary  flex-1 me-2">
                {t('RightSidebarMaintenanceDetails.Release')}
                </button>
            </div>
            </form>
        </div>
    );
};

export default MaintenanceReleaseTabView;
