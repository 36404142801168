import { SetHoveredServicePayload } from "../../utils/model";
import { createSlice } from "@reduxjs/toolkit";

const initialState: { hoveredServiceId: number | undefined } = {
  hoveredServiceId: undefined,
};

export const hoveredServiceSlice = createSlice({
  name: "hoveredService",
  initialState,
  reducers: {
    setHoveredService: (state, { payload }: SetHoveredServicePayload) => {
      state.hoveredServiceId = payload.hoveredServiceId;
    },
  },
});

export const { setHoveredService } = hoveredServiceSlice.actions;

export default hoveredServiceSlice.reducer;
