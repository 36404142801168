import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setNavigationAction } from '../../../../store/dashboard/navigationReducer';
import PoolingContainer from './Pooling/PoolingContainer';
import { useTranslation } from 'react-i18next';
import AssessmentContainer from './Assessment/AssessmentContainer';
import CostingContainer from './Costing/CostingContainer';
import { rightNavigationPage } from '../../../../utils/constant';
import { removeLeadInformationAction } from '../../../../store/lead/getLeaddataReducer';
import { deletelLead, getLeaddata } from '../../../../service/leads';
import AssignmentContainer from './Assignment/AssignmentContainer';
import ContractingContainer from './Contracting/ContractingContainer';

const ServiceReferralSidebarView = () => {
    const { t } = useTranslation();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    var pname: any;
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    let [iname, setiname] = useState<any>();
    useEffect(() => {
        async function getleaddata() {
            if (Data.leadstatus === 2) {
                getLeaddata(Data.leadid, Data.leadstatus - 1);
            } else {
                getLeaddata(Data.leadid, Data.leadstatus);
            }
        }
        getleaddata();
        pname = Data?.patientname;
        pname = pname
            ?.split(' ')
            .map((part: any[]) => part[0])
            .join('')
            .toUpperCase();
        setiname(pname);
    }, [Data]);
    const dispatch = useDispatch();

    const closeSideMenu = () => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
    };
    const selecttab = (stage: number) => {
        dispatch(removeLeadInformationAction());
        let returnedTarget = JSON.parse(JSON.stringify(Data));
        returnedTarget.leadstatus = stage;

        let obj: any = {
            ShowClass: true,
            FormName: rightNavigationPage.ServiceSidebar,
            Data: returnedTarget,
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
    };

    async function refreshData() {
        dispatch(removeLeadInformationAction());
        await getLeaddata(Data.leadid, Data.leadstatus);
    }
    return (
        <div>
            <button onClick={closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>{' '}
            </button>
            {Data.leadstatus === 2 && <h3 className="mb-2">{t('ServiceReferralSidebar.Pooling')}</h3>}
            {Data.leadstatus === 3 && <h3 className="mb-2">{t('ServiceReferralSidebar.Assessment')}</h3>}
            {Data.leadstatus === 4 && <h3 className="mb-2">{t('ServiceReferralSidebar.Costing')}</h3>}
            {Data.leadstatus === 5 && <h3 className="mb-2">{t('ServiceReferralSidebar.Assignment')}</h3>}
            {Data.leadstatus === 6 && <h3 className="mb-2">{t('ServiceReferralSidebar.Contract')}</h3>}

            <div className="d-flex mt-4">
                <div className="light-purple-background user_img me-2">{iname}</div>
                <div>
                    <p className="purple-text bold">{Data.patientname}</p>
                    <p>
                        <span className="bold me-1">{t('ServiceReferralSidebar.Referral_ID')}:</span>
                        {Data.referralId}
                    </p>
                </div>
            </div>

            {DataLeadInfo?.data?.isLeadManuallyClosed === false && DataLeadInfo?.data?.leadStatus === 'Closed' && (
                <div className="mt-2 ms-1">
                    <div className="d-flex justify-content-between">
                        <span className="bold ps-2 pe-2 d-flex align-items-center">Processed for Admission.</span>
                    </div>
                </div>
            )}

            {DataLeadInfo?.data?.isLeadManuallyClosed && (
                <div className="mt-2">
                    <div className="d-flex align-items-center">
                        <span className="ps-0">
                            <p className="mb-1">
                                <i>{DataLeadInfo?.data?.referralCloseLog?.logMessage}</i>
                                <i
                                    className="fa-solid fa-circle-info ms-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`${DataLeadInfo?.data?.referralCloseLog?.reason} - ${DataLeadInfo?.data?.referralCloseLog?.comment}`}
                                ></i>
                            </p>
                        </span>
                    </div>
                </div>
            )}
            <div className="right_content-height-tab">
                <ul className="nav nav-tabs mt-4 mb-4 referral-tabs service justify-content-between" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={(e: any) => (Data.fleadstatus >= 2 ? selecttab(2) : undefined)}
                            className={`nav-link  ${Data !== '' && Data.fleadstatus >= 2 ? 'active' : ''} ${Data.leadstatus === 2 ? 'current' : ''} `}
                            id="Pooling-Tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Pooling"
                            type="button"
                            role="tab"
                            aria-controls="Pooling"
                            aria-selected="false"
                        >
                            <svg enable-background="new 0 0 123.81 123.73" width="20" version="1.1" viewBox="0 0 123.81 123.73" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <style type="text/css">{`
                                            .st00{fill:#4E4851;}
                                            `}</style>

                                <path
                                    className="st00"
                                    d="m83.43 21.44c0.3 11.99-10.03 22.27-22.5 21.78-11.05-0.43-21.03-9.52-20.66-22.53 0.32-11.08 9.17-19.91 19.72-20.63 14.2-0.97 23.73 10.33 23.44 21.38zm-39.27 0.26c-0.01 3.66 1.03 6.95 3.16 9.91 0.78 1.08 0.73 1.06 1.44-0.17 3.68-6.36 10.23-8.9 17.15-7.13 4.14 1.06 7.15 3.63 9.25 7.32 0.64 1.14 0.62 1.1 1.35 0.02 2.49-3.71 3.41-7.75 2.96-12.2-0.62-6.26-4.2-10.48-9.28-13.56-3.07-1.86-6.65-2.03-10.15-1.78-2.22 0.16-4.44 0.52-6.44 1.68-6.15 3.54-9.3 8.83-9.44 15.91z"
                                />
                                <path
                                    className="st00"
                                    d="m102.04 71.8c12.34-0.15 20.78 9.98 21.66 19.03 1.39 14.4-10.48 24.65-22.33 24.17-11.42-0.46-20.9-9.55-20.85-21.92 0.04-11.41 9.22-21.31 21.52-21.28zm-17.61 21.56c-0.13 3.93 1.2 7.28 3.33 10.4 0.46 0.68 0.54 0.51 0.96-0.02 1.33-1.7 2.57-3.53 4.17-4.93 3.71-3.24 8.13-3.94 12.86-2.82 4.36 1.03 7.52 3.68 9.72 7.5 0.57 1 0.92 0.65 1.32 0.04 3.04-4.72 4.03-9.87 2.38-15.22-1.78-5.75-5.58-9.78-11.48-11.64-3.97-1.26-7.93-1.18-11.82 0.09-6.32 2.03-11.58 8.74-11.44 16.6z"
                                />
                                <path
                                    className="st00"
                                    d="m43.2 93.25c0.14 12.05-9.86 21.97-22.21 21.73-11.27-0.22-21.15-9.6-20.99-22.01 0.15-11.39 9.2-20.68 20.37-21.16 13.74-0.59 23.03 10.44 22.83 21.44zm-4.02 0.03c0.18-2.35-0.2-4.64-1.17-6.8-2.32-5.2-6.15-8.7-11.72-10.13-4.23-1.09-8.45-0.84-12.36 1.02-5.65 2.68-8.9 7.2-9.76 13.52-0.6 4.4 0.22 8.37 2.52 12.09 0.99 1.59 1 1.59 1.93 0 3.32-5.63 9.79-8.56 15.94-7.18 4.68 1.05 8.26 3.59 10.4 7.97 0.44 0.89 0.78 0.44 1.12-0.05 2.21-3.13 3.36-6.56 3.1-10.44z"
                                />
                                <path
                                    className="st00"
                                    d="m59.94 89.03v-14.24c0-1.14-0.28-1.3-1.29-0.71-4.27 2.48-8.53 4.96-12.78 7.46-0.63 0.37-0.99 0.53-1.47-0.31-1.37-2.38-1.43-2.35 0.95-3.73 3.86-2.24 7.73-4.47 11.59-6.71 1-0.58 0.99-0.79 0.01-1.36-8.39-4.82-16.79-9.63-25.17-14.46-0.26-0.15-0.59-0.63-0.52-0.78 0.42-0.92 0.92-1.81 1.47-2.66 0.07-0.11 0.66-0.01 0.92 0.13 3.19 1.81 6.35 3.65 9.53 5.48 4.17 2.4 8.35 4.79 12.52 7.19 1.1 0.63 2.19 1.27 3.29 1.9 0.24 0.13 0.5 0.21 0.75 0.32 0.07-0.29 0.21-0.58 0.22-0.88 0.01-5.7 0.03-11.41-0.01-17.11-0.01-0.95 0.2-1.27 1.21-1.26 2.81 0.05 2.81 0 2.81 2.78 0 5.12 0.01 10.24-0.01 15.37 0 0.89 0.02 1.33 1.07 0.72 4.52-2.65 9.08-5.25 13.62-7.87 3.87-2.24 7.74-4.47 11.6-6.73 0.57-0.33 0.88-0.25 1.14 0.34 0.16 0.37 0.38 0.72 0.58 1.08 0.89 1.6 0.86 1.55-0.72 2.45-5.33 3.05-10.64 6.15-15.97 9.21-2.75 1.58-5.53 3.12-8.29 4.7-0.36 0.21-0.67 0.53-1 0.8 0.33 0.23 0.63 0.5 0.98 0.7 4.38 2.52 8.75 5.04 13.14 7.54 0.51 0.29 0.84 0.51 0.34 1.11-0.36 0.44-0.71 0.93-0.89 1.46-0.38 1.06-0.98 1.02-1.79 0.55-4-2.34-8-4.67-12-7-0.04-0.02-0.07-0.05-0.1-0.07-0.53-0.18-1.05-0.36-1.58-0.54-0.04 0.49-0.11 0.97-0.11 1.46-0.01 9.04 0 18.07-0.01 27.11 0 1.86 0 1.86-1.82 1.86-2.18 0-2.18 0-2.18-2.14-0.03-4.42-0.03-8.79-0.03-13.16z"
                                />
                                <path className="st00" d="m61.8 108.28c4.02-0.53 7.71 3.9 7.95 7.05 0.37 4.78-4.12 8.56-8.05 8.4-4.47-0.18-7.63-3.61-7.69-8.11-0.04-3.46 3.28-7.65 7.79-7.34z" />
                                <path className="st00" d="m109.93 46.99c-0.23 4.54-3.7 7.69-7.91 7.74-3.79 0.04-7.93-3.22-7.57-8.35 0.28-4.04 3.37-7.38 8.02-7.26 4.24 0.1 7.32 3.3 7.46 7.87z" />
                                <path className="st00" d="m21.55 39.31c4.35-0.36 7.94 3.59 7.86 7.49-0.1 4.73-3.45 7.81-8 7.93-3.78 0.1-7.44-3.39-7.52-7.83-0.08-3.91 3.77-8.13 7.66-7.59z" />
                                <path className="st00" d="m69.44 14.88c0.21 4.67-3.92 7.54-8.27 7.64-3.22 0.07-7.11-4.28-6.94-7.72 0.19-3.84 3.15-7.46 7.56-7.46 4.57 0 7.73 3.55 7.65 7.54z" />
                                <path className="st00" d="m102.38 79.04c4.37 0.08 7.59 3.48 7.47 7.64-0.13 4.5-3.66 7.62-8.33 7.54-3.28-0.05-6.9-3.5-6.89-7.68 0.01-3.63 3.08-7.58 7.75-7.5z" />
                                <path className="st00" d="m13.96 86.5c0.13-3.43 3.04-7.56 7.76-7.45 4.08 0.1 7.51 3.36 7.44 7.58-0.07 4.53-3.24 7.49-7.83 7.57-3.86 0.07-7.13-3.34-7.37-7.7z" />
                            </svg>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={(e: any) => (Data.fleadstatus >= 3 ? selecttab(3) : undefined)}
                            className={`nav-link  ${Data !== '' && Data.fleadstatus >= 3 ? 'active' : ''} ${Data.leadstatus === 3 ? 'current' : ''} `}
                            disabled={Data !== '' && Data.fleadstatus >= 3 ? false : true}
                            id="Assessment-Tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Assessment"
                            type="button"
                            role="tab"
                            aria-controls="Assessment"
                            aria-selected="false"
                        >
                            <svg enable-background="new 0 0 510.03 522" width="18" version="1.1" viewBox="0 0 510.03 522" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <style type="text/css">{`
                                                .st0{fill:#4E4851;stroke:#4E4851;stroke-width:10;stroke-miterlimit:10;}
                                                .st1{fill:#4F4952;stroke:#4E4851;stroke-width:10;stroke-miterlimit:10;}
                                                .st2{fill:#656169;stroke:#4E4851;stroke-width:10;stroke-miterlimit:10;}
                                                `}</style>
                                <path
                                    className="st0"
                                    d="m389.29 293.99c-0.05-46.65-0.09-93.3-0.14-139.95v-12.39h-119.23v-119.36h-247.48v477.43h230.63v17.28h-248c0.02-1.66 0.07-3.33 0.07-4.99v-502.01c0.01-1.67-0.04-3.34-0.06-5h277c11.37 11.46 22.72 22.95 34.13 34.38 28.96 29 57.97 57.94 86.85 87.01 1.57 1.58 3.01 4.15 3.02 6.27 0.16 53.14 0.13 106.28 0.12 159.42 0 0.81-0.08 1.61-0.15 2.81h-15.96c-0.27-0.3-0.53-0.6-0.8-0.9zm-14.06-169.72c-29.48-29.45-58.93-58.87-87.98-87.9v87.9h87.98z"
                                />
                                <path
                                    className="st1"
                                    d="m505.03 340.2c-0.32 6.95-2.58 12.93-7.38 17.73-43.19 43.25-86.41 86.48-129.69 129.65-1.32 1.31-3.3 2.2-5.13 2.71-19.21 5.33-38.48 10.43-57.65 15.89-3.17 0.9-6.24 2.86-8.83 4.99-4.58 3.76-9.38 4.32-12.94 0.83-3.69-3.62-3.12-8.54 0.94-13.24 1.97-2.28 3.74-5.1 4.56-7.97 5.48-19.34 10.64-38.78 16.05-58.15 0.59-2.12 1.76-4.36 3.3-5.9 42.57-42.7 85.18-85.35 127.87-127.94 11.17-11.15 26.69-11.13 37.91-0.16 7.74 7.57 15.32 15.3 22.98 22.95 5.2 5.21 7.83 11.48 8.01 18.61zm-178.53 93.41c11.61 11.61 23.49 23.48 34.98 34.96l86.32-86.32c-11.54-11.49-23.42-23.32-35.05-34.9-28.6 28.61-57.54 57.55-86.25 86.26zm134.92-63.54c8.02-8.03 15.98-15.81 23.72-23.81 3.88-4.01 3.84-8.31-0.1-12.39-7.63-7.89-15.4-15.66-23.26-23.33-4.21-4.11-8.98-4.05-13.24 0.04-5.4 5.19-10.53 10.65-15.95 15.81-2.6 2.47-5.62 4.49-8.08 6.44 13.06 13.19 24.76 24.99 36.91 37.24zm-144.02 81.36c-3.14 11.6-6.36 23.49-9.83 36.31 12.96-3.53 24.85-6.76 36.29-9.87-8.82-8.81-17.56-17.54-26.46-26.44z"
                                />
                                <path
                                    className="st0"
                                    d="m206.08 192.83c34.83 0 69.66-0.02 104.49 0.01 7.15 0.01 11.59 4.53 10.14 10.23-0.96 3.77-3.5 6.01-7.3 6.64-1.63 0.27-3.32 0.16-4.99 0.16-68.5 0.01-136.99 0.01-205.49-0.03-2.3 0-4.74-0.06-6.87-0.79-4.17-1.44-5.85-4.8-5.25-9.09 0.58-4.12 3.14-6.51 7.27-7.03 1.64-0.2 3.33-0.11 4.99-0.11 34.35 0 68.68 0 103.01 0.01z"
                                />
                                <path
                                    className="st0"
                                    d="m205.64 278.04c-34.67 0-69.33 0.04-104-0.09-2.67-0.01-6.31-0.43-7.77-2.14-1.86-2.16-3.15-6.15-2.47-8.78 0.61-2.38 4.03-4.28 6.56-5.81 1.23-0.75 3.26-0.22 4.92-0.22h206c1.17 0 2.34-0.05 3.5 0.02 4.99 0.33 8.66 4.04 8.59 8.62-0.07 4.62-3.73 8.3-8.82 8.35-13.17 0.12-26.33 0.05-39.5 0.05h-67.01z"
                                />
                                <path
                                    className="st0"
                                    d="m171.63 346.24c-23.49 0-46.99-0.04-70.48 0.04-4.34 0.01-8.02-0.89-9.87-5.19-2.14-4.95 0.74-10.36 6.14-11.57 1.76-0.39 3.64-0.33 5.46-0.33 45.82-0.02 91.64-0.01 137.46-0.01 1 0 2-0.04 3 0.02 5.57 0.3 9.24 3.64 9.33 8.45 0.09 5.01-3.69 8.56-9.56 8.57-23.83 0.05-47.66 0.02-71.48 0.02z"
                                />
                                <path
                                    className="st0"
                                    d="m158.55 397.59c18.81 0 37.63-0.01 56.44 0.01 1.66 0 3.35-0.04 4.98 0.23 4.38 0.72 6.79 3.56 7 7.84 0.21 4.3-1.99 7.34-6.24 8.47-1.74 0.46-3.62 0.52-5.43 0.52-37.63 0.03-75.26 0.03-112.88 0-1.98 0-4.05-0.07-5.91-0.64-4.22-1.29-6.13-4.47-5.74-8.78 0.37-4.11 2.73-6.82 6.87-7.37 2.95-0.39 5.98-0.26 8.97-0.27 17.31-0.02 34.62-0.01 51.94-0.01z"
                                />
                                <path
                                    className="st0"
                                    d="m141.07 141.59c-12.83 0-25.66 0.02-38.49-0.02-1.82 0-3.7-0.02-5.45-0.45-4.29-1.03-6.49-3.98-6.42-8.33 0.07-4.12 2.23-6.96 6.3-7.91 1.92-0.45 3.96-0.47 5.95-0.47 25.83-0.03 51.66-0.02 77.48-0.01 1.5 0 3.01-0.05 4.49 0.14 4.89 0.63 8.13 4.17 8.01 8.63-0.11 4.27-3.23 7.59-7.9 8.23-1.48 0.2-2.99 0.17-4.49 0.17-13.15 0.03-26.31 0.02-39.48 0.02z"
                                />
                                <path className="st2" d="m389.29 293.99c0.26 0.3 0.53 0.6 0.79 0.9-0.26-0.3-0.52-0.6-0.79-0.9z" />
                            </svg>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={(e: any) => (Data.fleadstatus >= 4 ? selecttab(4) : undefined)}
                            className={`nav-link  ${Data !== '' && Data.fleadstatus >= 4 ? 'active' : ''} ${Data.leadstatus === 4 ? 'current' : ''} `}
                            disabled={Data !== '' && Data.fleadstatus >= 4 ? false : true}
                            id="Costing-Tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Costing"
                            type="button"
                            role="tab"
                            aria-controls="Costing"
                            aria-selected="false"
                        >
                            <svg enable-background="new 0 0 512 512" width="18" version="1.1" viewBox="0 0 512 512" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <style type="text/css">{`
                                            .st01{fill:#726D74}.st11{fill:#B7B5B9}.st21{fill:#CCCACC}.st31{fill:#969297}.st41{fill:#4E4851}.st51{fill:#ADA9AE}.st61{fill:#7C787E}.st71{fill:#DBDADC}.st81{fill:#A5A3A7}.st91{fill:#9E9A9F}.st101{fill:#D0CFD1}.st111{fill:#D5D4D6}.st121{fill:#827E84}.st131{fill:#9A979C}
                                            `}</style>
                                <path className="st01" d="m511.11 357.13c0.3-2.38 0.59-4.75 0.89-7.13v7c-0.3 0.03-0.59 0.08-0.89 0.13z" />
                                <path className="st11" d="m511.11 357.13c0.29-0.05 0.59-0.1 0.89-0.13v6l-0.45-0.01-0.45 0.07c0-1.98 0.01-3.95 0.01-5.93z" />
                                <path className="st01" d="m512 342c-0.28-1.71-0.56-3.41-0.84-5.12 0.28 0.03 0.56 0.07 0.84 0.12v5z" />
                                <path className="st21" d="m511.15 332.91c0-1.32-0.01-2.64-0.01-3.96 0.29 0 0.58 0.02 0.86 0.05v4c-0.28-0.06-0.57-0.09-0.85-0.09z" />
                                <path className="st31" d="m511.15 332.91c0.29 0 0.57 0.03 0.85 0.09v4c-0.28-0.05-0.56-0.09-0.84-0.12 0-1.32-0.01-2.65-0.01-3.97z" />
                                <path
                                    className="st41"
                                    d="m329.96 511.13c-87.3-9.53-150.82-83.53-147.87-172.24 2.8-83.89 73.37-154.85 156.91-157.77 88.64-3.1 162.34 60.19 172.13 147.82 0 1.32 0.01 2.64 0.01 3.96s0.01 2.65 0.01 3.97c0.28 1.71 0.56 3.41 0.84 5.12v8c-0.3 2.38-0.59 4.75-0.89 7.13-0.01 1.98-0.01 3.95-0.02 5.93-7.58 77.2-68.83 138.88-147.05 148.08h-3.96c-1.33 0-2.66 0-3.98 0.01-1.7 0.29-3.41 0.57-5.11 0.86h-8c-1.72-0.28-3.43-0.55-5.15-0.83-1.64-0.01-3.28-0.03-4.93-0.04h-2.94zm-21.9-150.13c-2.18 9.05-10.88 19.63-19.85 25.44-4.43 2.87-8.79 6.11-12.42 9.91-5.21 5.44-5 13.44-0.32 19.17 4.47 5.47 12.07 7.08 18.49 3.59 3.2-1.74 5.92-4.41 9.18-5.98 3.79-1.82 7.85-3.66 11.96-4.14 8.79-1.02 16.02 2.66 22.48 8.66 22.52 20.91 57.01 20.85 78.84 0.1 4.41-4.2 6.61-9.17 5.08-15.28-1.47-5.83-5.26-9.55-11.08-11.01-5.91-1.48-10.62 0.87-15 4.73-10.02 8.84-24.41 9.96-34.42 1.84-8.57-6.95-16.82-14.1-27.37-16.99 2.26-6.93 4.35-13.36 6.53-20.05 12.23 0 24.55 0.12 36.86-0.04 8.48-0.11 14.95-6.77 14.97-14.94 0.01-8.19-6.43-14.7-14.93-14.99-3-0.1-6-0.02-9-0.02h-29.75c-2.09-9.84-4.13-19.1-5.97-28.4-0.38-1.91-0.32-4.01-0.01-5.95 2.46-15.52 15.81-26.36 31.38-25.61 15.3 0.74 27.51 13.27 28.28 29 0.47 9.73 6.83 16.25 15.52 15.94 8.71-0.31 14.98-7.29 14.41-16.95-1.4-23.75-12.63-41.58-34.05-51.95-21.09-10.2-42.12-8.55-60.77 5.66-19.34 14.75-27.9 34.65-23.15 59.27 1.19 6.18 2.38 12.35 3.57 18.47-0.7 0.24-1.01 0.44-1.33 0.44-6.16 0.04-12.33-0.01-18.49 0.08-8.9 0.12-15.55 6.42-15.68 14.75-0.13 8.55 6.59 15.1 15.72 15.22 6.64 0.09 13.3 0.03 20.32 0.03z"
                                />
                                <path
                                    className="st41"
                                    d="m197.03 0.87c30.94 2.39 61.72 5.99 90.85 17.37 10.11 3.95 19.96 9.3 28.79 15.6 11.51 8.21 18.02 19.66 14.25 34.61-2.17 8.63-7.82 14.98-14.73 20.2-14.77 11.16-31.93 16.98-49.62 21.33-39.29 9.65-79.26 12.14-119.54 10.57-28.5-1.11-56.76-4.29-84.42-11.59-15.17-4-29.96-9.06-43.08-17.93-8.99-6.08-16.76-13.24-18.77-24.7-0.25-1.44-0.51-2.88-0.76-4.33v-3c0.32-1.68 0.64-3.36 0.96-5.03 2.25-8.61 7.72-14.94 14.52-20.26 11.55-9.04 24.91-14.39 38.73-18.76 26.93-8.51 54.74-11.89 82.75-14.09 1.66 0.01 3.32 0.01 4.98 0.01h6.93c3.71-0.28 7.42-0.58 11.13-0.87h14c3.71 0.29 7.41 0.59 11.12 0.88h6.93c1.66 0 3.32-0.01 4.98-0.01z"
                                />
                                <path
                                    className="st41"
                                    d="m331.94 98.05c0.02 6.16-0.26 12.34 0.12 18.48 0.86 14.02-5.49 24.28-16.24 32.39-10.63 8.02-22.73 13.06-35.29 17.08-25.35 8.11-51.52 11.83-77.94 13.74-37.33 2.7-74.54 1.33-111.5-4.77-21.54-3.56-42.76-8.44-62.3-18.63-5.7-2.98-11.09-6.74-16.15-10.73-5.71-4.5-9.8-10.38-11.67-17.57-0.33-1.69-0.65-3.36-0.97-5.04v-22c0.59-0.03 1.63-0.25 1.72-0.05 7.42 16.37 22.09 23.79 37.47 30.17 23.09 9.59 47.46 14.34 72.15 16.54 22.84 2.04 45.85 3.28 68.76 2.96 36.18-0.5 72.13-4.38 106.43-16.89 10.33-3.77 20.12-9.42 29.53-15.23 7.45-4.61 12.71-11.66 15-20.47l0.45-0.15 0.43 0.17z"
                                />
                                <path
                                    className="st41"
                                    d="m0 400c0.59 0.18 1.56 0.21 1.7 0.57 5.07 12.85 15.65 19.99 27.19 25.91 19.6 10.05 40.8 15.05 62.37 18.48 29.32 4.66 58.81 7.14 88.55 5.5 1.22-0.07 2.99 0.66 3.69 1.61 6.77 9.23 13.37 18.58 20.32 28.32-5.91 0.36-11.83 0.85-17.76 1.07-46.86 1.69-93.13-1.73-138.01-16.51-11.89-3.91-23.23-9.14-33.06-17.09-6.99-5.65-12.74-12.21-14.24-21.53-0.24-1.44-0.5-2.88-0.75-4.33v-22z"
                                />
                                <path
                                    className="st41"
                                    d="m208.01 210.01c-7.66 9.59-15.29 19.21-23.03 28.73-0.81 1-2.35 1.98-3.56 2-46.92 0.75-93.39-2.22-138.14-17.94-11.16-3.92-21.73-9.2-30.72-17.14-5.45-4.82-9.72-10.44-11.59-17.63-0.33-1.68-0.65-3.35-0.97-5.03v-23c0.59 0.18 1.56 0.21 1.7 0.57 4.92 12.49 15.12 19.58 26.33 25.44 21.37 11.17 44.63 16.17 68.19 19.8 25.72 3.96 51.65 5.64 77.68 5.1 11.31-0.23 22.62-1.05 33.93-1.6-0.11 0.28-0.05 0.51 0.18 0.7z"
                                />
                                <path
                                    className="st41"
                                    d="m0 341c0.6-0.02 1.63-0.23 1.72-0.03 6.65 15.01 19.82 22.47 33.81 28.62 22.11 9.72 45.59 14.48 69.41 17.28 16.34 1.92 32.82 2.64 49.23 4.05 1.31 0.11 3.3 1.13 3.65 2.17 3.05 9.1 5.79 18.3 8.48 27-9.23 0-18.82 0.45-28.35-0.08-29.96-1.66-59.59-5.51-88.25-14.95-13.49-4.44-26.47-9.97-37.26-19.54-5.82-5.16-10.49-11.14-11.67-19.18-0.26-1.45-0.52-2.9-0.77-4.34v-21z"
                                />
                                <path
                                    className="st41"
                                    d="m156.95 300.82c-39.33-1.19-78.35-4.7-115.44-18.97-9.78-3.76-18.9-9.57-27.72-15.36-6.45-4.23-10.82-10.73-12.83-18.45-0.32-1.35-0.64-2.7-0.96-4.04v-23c0.6-0.03 1.63-0.25 1.72-0.05 7.42 16.38 22.1 23.84 37.49 30.18 23.59 9.72 48.46 14.21 73.67 16.75 17.87 1.8 35.84 2.61 53.99 3.88-2.86 9.11-5.86 18.67-8.86 28.24-0.36 0.27-0.71 0.55-1.06 0.82z"
                                />
                                <path
                                    className="st41"
                                    d="m0 280c0.65 0.4 1.66 0.64 1.9 1.21 4.05 9.7 11.55 16.16 20.21 21.43 17.9 10.88 37.69 16.91 58.1 20.09 22.81 3.55 45.89 5.39 68.86 7.97 1.14 0.13 2.3 0.17 3.48 0.25v29.97c-9.67-0.63-19.25-1.05-28.8-1.9-27.07-2.43-53.98-6.18-79.42-16.13-10.7-4.19-20.8-10.25-30.54-16.46-6.48-4.13-10.92-10.65-12.73-18.49-0.37-1.98-0.71-3.96-1.06-5.94v-22z"
                                />
                                <path className="st01" d="m160 0c-3.71 0.29-7.42 0.59-11.13 0.88 0.08-0.29 0.13-0.58 0.13-0.88h11z" />
                                <path className="st01" d="m185.12 0.88c-3.71-0.29-7.41-0.59-11.12-0.88h11c0 0.3 0.04 0.59 0.12 0.88z" />
                                <path className="st51" d="m185.12 0.88c-0.08-0.29-0.12-0.58-0.12-0.88h7c-0.02 0.3 0 0.59 0.06 0.88h-6.94z" />
                                <path className="st51" d="m149 0c0 0.3-0.05 0.59-0.13 0.88h-6.93c0.06-0.29 0.08-0.58 0.06-0.88h7z" />
                                <path className="st61" d="m0 302c0.35 1.98 0.69 3.96 1.04 5.94-0.35 0.02-0.69 0.04-1.04 0.06v-6z" />
                                <path className="st71" d="m192.06 0.88c-0.06-0.29-0.08-0.58-0.06-0.88h5c-0.02 0.29-0.01 0.58 0.03 0.87-1.66 0-3.32 0.01-4.97 0.01z" />
                                <path className="st81" d="m0 54c0.33 0.17 0.65 0.16 0.96-0.03-0.32 1.67-0.64 3.35-0.96 5.03v-5z" />
                                <path className="st81" d="m0 183c0.32 1.68 0.64 3.35 0.97 5.03-0.33-0.01-0.65-0.02-0.97-0.03v-5z" />
                                <path className="st81" d="m0 123c0.32 1.68 0.64 3.35 0.97 5.03-0.33-0.01-0.65-0.02-0.97-0.03v-5z" />
                                <path className="st71" d="m142 0c0.02 0.3 0 0.59-0.06 0.88-1.66-0.01-3.32-0.01-4.98-0.02 0.04-0.29 0.05-0.57 0.03-0.86h5.01z" />
                                <path className="st91" d="m332.92 511.13c1.64 0.01 3.28 0.03 4.93 0.04 0.06 0.28 0.11 0.55 0.15 0.83h-5c0.02-0.29-0.01-0.59-0.08-0.87z" />
                                <path className="st01" d="m338 512c-0.04-0.28-0.09-0.56-0.15-0.83 1.72 0.28 3.43 0.56 5.15 0.83h-5z" />
                                <path className="st01" d="m351 512c1.7-0.29 3.41-0.57 5.11-0.86-0.05 0.28-0.08 0.57-0.11 0.86h-5z" />
                                <path className="st81" d="m0 244c0.32 1.34 0.64 2.69 0.96 4.03l-0.96-0.03v-4z" />
                                <path className="st61" d="m0 362c0.25 1.44 0.51 2.89 0.76 4.33-0.25-0.11-0.51-0.22-0.76-0.33v-4z" />
                                <path className="st61" d="m0 422c0.25 1.45 0.51 2.89 0.76 4.34-0.25-0.11-0.51-0.23-0.76-0.34v-4z" />
                                <path className="st61" d="m0 62c0.25 1.45 0.51 2.89 0.76 4.34-0.25-0.11-0.51-0.23-0.76-0.34v-4z" />
                                <path className="st31" d="m356 512c0.02-0.29 0.06-0.57 0.11-0.86h3.98c-0.07 0.28-0.1 0.57-0.09 0.86h-4z" />
                                <path className="st101" d="m360 512c-0.01-0.29 0.02-0.58 0.09-0.86h3.96c-0.05 0.29-0.07 0.57-0.05 0.86h-4z" />
                                <path className="st111" d="m332.92 511.13c0.07 0.29 0.09 0.58 0.08 0.87h-3c0.02-0.29 0.01-0.58-0.04-0.87h2.96z" />
                                <path className="st81" d="m331.94 98.05-0.45-0.15-0.45 0.15c0.07-1.3 0.13-2.6 0.2-3.91 0.18 0 0.35 0.01 0.53 0.01 0.06 1.29 0.11 2.6 0.17 3.9z" />
                                <path className="st121" d="m156.95 300.82 1.05-0.81c-0.35 0.26-0.7 0.54-1.05 0.81z" />
                                <path className="st131" d="m208.01 210.01c-0.23-0.19-0.3-0.42-0.19-0.7 0.28 0.05 0.55 0.09 0.83 0.14-0.21 0.18-0.43 0.37-0.64 0.56z" />
                            </svg>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={(e: any) => (Data.fleadstatus >= 5 ? selecttab(5) : undefined)}
                            className={`nav-link  ${Data !== '' && Data.fleadstatus >= 5 ? 'active' : ''} ${Data.leadstatus === 5 ? 'current' : ''} `}
                            disabled={Data !== '' && Data.fleadstatus >= 5 ? false : true}
                            id="Assignment-Tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Assignment"
                            type="button"
                            role="tab"
                            aria-controls="Assignment"
                            aria-selected="false"
                        >
                            <svg enable-background="new 0 0 384 384" width="19" version="1.1" viewBox="0 0 384 384" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <style type="text/css">{`
                                            .dt0{fill:#4E4851;}.dt1{fill:#6D686F;}.dt2{fill:#89858B;}.dt3{fill:#A7A4A8;}
                                            .dt4{fill:#C3C1C3;}.dt5{fill:#979499;}.dt6{fill:#CCCACD;}.dt7{fill:#A19DA2;}.dt8{fill:#4F4952;}
                                            `}</style>
                                <path
                                    className="dt0"
                                    d="m384 279.75v6l-0.63 5.34c-0.01 0.99-0.01 1.97-0.02 2.96-5.07 46.58-42.46 83.98-89.31 89.32-0.74 0.01-1.49 0.01-2.23 0.02-0.75-0.02-1.49-0.04-2.24-0.06-1.28 0.23-2.55 0.46-3.83 0.68h-6c-1.04-0.2-2.09-0.41-3.13-0.61-0.98-0.01-1.96-0.02-2.93-0.03-0.74 0-1.49 0-2.23 0.01-39.8-5.78-67.52-27.23-83.09-64.27-1.09-2.59-2.25-3.43-5.08-3.42-26.74 0.13-53.48 0.08-80.22 0.08-9.79 0-13.82-4.04-13.82-13.88 0-95.72 0.05-191.44-0.1-287.16 0-6.9 2.04-11.83 8.36-14.73h241.5c6.31 2.9 8.37 7.81 8.35 14.69-0.18 62.04-0.09 124.09-0.15 186.13 0 2.67 0.63 4.51 2.74 6.41 19.11 17.22 30.35 38.6 33.37 64.22 0.01 1.48 0.03 2.95 0.04 4.43 0.22 1.29 0.43 2.58 0.65 3.87zm-59.35-257.02h-212.67v270.32h69.78c-2.23-38.28 10.86-69.77 41.97-92.23 31.24-22.56 65.38-24.85 100.93-10.51-0.01-55.91-0.01-111.62-0.01-167.58zm-41.94 181.27c-43.29 0.02-78.68 35.4-78.71 78.68-0.02 43.29 35.33 78.74 78.59 78.81 43.45 0.07 78.96-35.43 78.9-78.88-0.05-43.28-35.47-78.62-78.78-78.61z"
                                />
                                <path
                                    className="dt0"
                                    d="m0.8 63.86c3.34-16.78 18.67-28.38 35.7-27.02s30.78 15.65 30.91 32.85c0.22 29.98 0.08 59.96 0.08 89.94 0 30.48 0.01 60.96-0.03 91.44 0 1.83-0.15 3.81-0.84 5.47-7.34 17.73-14.76 35.43-22.26 53.09-2.89 6.82-9.7 9.78-15.48 6.26-2.64-1.61-4.95-4.62-6.21-7.51-6.75-15.54-13.12-31.25-19.67-46.88-0.83-1.99-1.99-3.84-3-5.75v-186.75c0.27-1.71 0.54-3.42 0.8-5.14zm21.93 39.01v137.63h22.04v-137.63h-22.04zm22.25-22.74c0-3.7 0.16-7.07-0.04-10.41-0.33-5.59-4.92-10.07-10.42-10.45-5.62-0.39-11.08 3.65-11.79 9.3-0.47 3.76-0.09 7.63-0.09 11.56h22.34zm-16.78 183.26c1.85 4.43 3.53 8.44 5.56 13.3 2.05-4.94 3.72-8.98 5.51-13.3h-11.07z"
                                />
                                <path className="dt1" d="m0.8 63.86c-0.26 1.72-0.53 3.43-0.8 5.14v-5.25c0.27 0.04 0.54 0.08 0.8 0.11z" />
                                <path className="dt2" d="m383.37 291.09 0.63-5.34v5.25c-0.21 0.07-0.42 0.1-0.63 0.09z" />
                                <path className="dt3" d="m383.31 271.45c0.22 0.11 0.46 0.12 0.69 0.05v4.5c-0.21-0.07-0.43-0.11-0.65-0.12-0.01-1.48-0.03-2.95-0.04-4.43z" />
                                <path className="dt1" d="m383.35 275.88c0.22 0.01 0.44 0.05 0.65 0.12v3.75c-0.22-1.29-0.43-2.58-0.65-3.87z" />
                                <path className="dt1" d="m285.75 384c1.28-0.23 2.55-0.46 3.82-0.68-0.06 0.22-0.08 0.45-0.07 0.68h-3.75z" />
                                <path className="dt4" d="m383.37 291.09c0.22 0.01 0.43-0.02 0.63-0.09v3l-0.32 0.05h-0.33c0.01-0.99 0.01-1.98 0.02-2.96z" />
                                <path className="dt5" d="m273.68 383.36c0.98 0.01 1.96 0.02 2.93 0.03 0.04 0.2 0.09 0.41 0.13 0.61h-3c0.01-0.21-0.01-0.43-0.06-0.64z" />
                                <path className="dt1" d="m276.75 384c-0.05-0.2-0.09-0.41-0.13-0.61 1.04 0.2 2.09 0.41 3.13 0.61h-3z" />
                                <path className="dt6" d="m273.68 383.36c0.04 0.21 0.07 0.42 0.07 0.64h-2.25c0-0.21-0.02-0.42-0.05-0.63 0.75 0 1.49-0.01 2.23-0.01z" />
                                <path className="dt7" d="m289.5 384c-0.01-0.23 0.02-0.46 0.07-0.68 0.75 0.02 1.49 0.04 2.24 0.06-0.03 0.2-0.05 0.41-0.06 0.62h-2.25z" />
                                <path className="dt6" d="m291.75 384c0.01-0.21 0.03-0.41 0.06-0.62 0.75 0 1.49-0.01 2.24-0.01-0.03 0.21-0.05 0.42-0.05 0.63h-2.25z" />
                                <path
                                    className="dt0"
                                    d="m218.26 67.5c-23.98 0-47.97 0.03-71.95-0.02-8.92-0.02-14.6-8.19-11.03-15.84 2.18-4.66 6.07-6.68 11.19-6.66 17.87 0.05 35.73 0.02 53.59 0.02 29.98 0 59.96-0.02 89.94 0.01 9.01 0.01 14.76 7.97 11.29 15.68-2.17 4.84-6.18 6.85-11.46 6.83-23.85-0.05-47.71-0.02-71.57-0.02z"
                                />
                                <path
                                    className="dt0"
                                    d="m217.85 112.5c-23.86 0-47.72 0.03-71.58-0.02-8.9-0.02-14.57-8.23-10.96-15.87 2.2-4.66 6.1-6.64 11.21-6.63 21.48 0.04 42.97 0.02 64.45 0.02 26.36 0 52.72-0.02 79.07 0.01 8.99 0.01 14.73 8.01 11.24 15.71-2.19 4.84-6.21 6.81-11.48 6.8-23.98-0.05-47.97-0.02-71.95-0.02z"
                                />
                                <path
                                    className="dt0"
                                    d="m218.65 135c23.86 0 47.72-0.03 71.58 0.02 8.92 0.02 14.56 8.21 10.96 15.87-2.19 4.67-6.1 6.64-11.21 6.63-22.24-0.04-44.47-0.02-66.71-0.02-25.61 0-51.22 0.02-76.83-0.01-8.82-0.01-14.45-7.68-11.39-15.37 1.83-4.6 5.87-7.11 11.63-7.11 24-0.02 47.99-0.01 71.97-0.01z"
                                />
                                <path
                                    className="dt0"
                                    d="m163.12 202.5c-5.74 0-11.48 0.06-17.22-0.02-6.69-0.09-11.59-4.83-11.64-11.14s4.84-11.26 11.45-11.31c11.73-0.09 23.46-0.08 35.18-0.01 6.62 0.04 11.62 4.91 11.68 11.14 0.06 6.35-5.03 11.26-11.87 11.33-5.85 0.06-11.72 0.01-17.58 0.01z"
                                />
                                <path
                                    className="dt8"
                                    d="m274.9 292.63c10.61-13.56 20.99-26.83 31.38-40.09 0.85-1.08 1.69-2.16 2.56-3.23 4.45-5.42 11.09-6.39 16.2-2.36 5.05 3.98 5.69 10.74 1.39 16.33-5.17 6.73-10.46 13.37-15.69 20.05-8.54 10.91-17.04 21.85-25.63 32.72-5.76 7.29-13.14 7.47-19.24 0.49-7.15-8.18-14.26-16.39-21.32-24.64-4.7-5.5-4.43-12.44 0.52-16.67 5.02-4.28 11.75-3.49 16.56 2.01 4.35 4.99 8.64 10.02 13.27 15.39z"
                                />
                            </svg>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={(e: any) => (Data.fleadstatus >= 6 ? selecttab(6) : undefined)}
                            className={`nav-link  ${Data !== '' && Data.fleadstatus >= 6 ? 'active' : ''} ${Data.leadstatus === 6 ? 'current' : ''} `}
                            disabled={Data !== '' && Data.fleadstatus >= 6 ? false : true}
                            id="Contract-Tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Contract"
                            type="button"
                            role="tab"
                            aria-controls="Contract"
                            aria-selected="false"
                        >
                            <svg enable-background="new 0 0 384 384" width="18" version="1.1" viewBox="0 0 384 384" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <style type="text/css">{`
                                            .rt0{fill:#4E4851;}
                                            .rt1{fill:#FFFFFF;}
                                            .rt2{fill:#817D83;}
                                            .rt3{fill:#969298;}
                                            .rt4{fill:#4F4952;}
                                            `}</style>
                                <path
                                    className="rt0"
                                    d="m384 335.25v40.5c-1.76 3.74-4.52 6.48-8.25 8.25h-367.5c-3.74-1.76-6.48-4.52-8.25-8.25v-367.5c1.77-3.73 4.51-6.49 8.25-8.25h294c0.74 0.26 1.47 0.52 2.21 0.78 5.66 2.36 7.32 6.93 7.32 12.67-0.05 42.24 0.03 84.49-0.12 126.73-0.01 3.96 0.76 5.67 4.95 6.85 19.46 5.51 32.65 18.14 39.76 37.02 5.19 13.75 4.68 27.87 0.71 41.64-6.91 23.96-14.59 47.69-21.94 71.52-0.38 1.24-0.7 2.5-1.16 4.18 5.85 0 11.09-0.16 16.33 0.03 16.35 0.59 29.85 12.36 32.96 28.58 0.23 1.75 0.48 3.5 0.73 5.25zm-116.9-33.91c-3.64-11.62-7.24-22.63-10.47-33.75-0.93-3.19-2.23-4.23-5.59-4.23-62.74 0.11-125.47 0.08-188.21 0.08-1.62 0-3.26 0.05-4.87-0.13-6.38-0.69-10.86-7-9.49-13.24 1.34-6.07 5.7-9.12 13.16-9.12 60.61-0.01 121.23 0 181.84 0h3.66c-2.01-10.65-4.72-20.95-5.77-31.42-2.93-29.14 15.99-55.77 44.27-62.99 2.92-0.75 3.69-1.8 3.69-4.69-0.1-38.49-0.06-76.98-0.06-115.48v-3.66h-266.54v338.6h194.14c0-8.29-0.11-16.39 0.02-24.48 0.31-19.97 15.17-34.97 35.09-35.47 4.85-0.13 9.72-0.02 15.13-0.02zm33.1-0.29c2 0 4.02-0.2 5.99 0.05 3.15 0.39 4.48-0.84 5.4-3.91 4.73-15.76 9.91-31.38 14.61-47.14 4.01-13.47 9.45-26.54 11.05-40.71 2.29-20.36-10.97-38.35-31.15-41.84-18.84-3.25-37.53 9.48-41.73 28.69-2.23 10.18-0.29 20.12 2.75 29.87 6.86 22.01 13.78 43.99 20.68 65.99 2.83 9 2.83 9 12.4 9zm61.3 60.27c0-7.73 0.01-15.21 0-22.68-0.01-9.9-4.82-14.82-14.63-14.81-20.74 0.02-41.48 0.11-62.22 0.12-10.74 0.01-21.49-0.17-32.23-0.09-6.93 0.05-12.41 4.46-12.81 11.16-0.52 8.68-0.13 17.41-0.13 26.3h122.02z"
                                />
                                <path className="rt1" d="m8.25 0c-3.74 1.76-6.48 4.52-8.25 8.25v-8.25h8.25z" />
                                <path className="rt1" d="m0 375.75c1.77 3.73 4.51 6.49 8.25 8.25h-8.25v-8.25z" />
                                <path className="rt1" d="m375.75 384c3.73-1.77 6.49-4.51 8.25-8.25v8.25h-8.25z" />
                                <path className="rt2" d="m383.25 330h0.75v5.25l-0.75-5.25z" />
                                <path className="rt3" d="M304.5,0c-0.05,0.17-0.13,0.34-0.15,0.52c-0.01,0.08,0.07,0.18,0.11,0.27c-0.74-0.26-1.47-0.52-2.21-0.78  C303,0,303.75,0,304.5,0z" />
                                <path
                                    className="rt0"
                                    d="m131.82 144.66c23.86 0 47.73-0.04 71.59 0.02 9.11 0.02 14.62 8.56 10.6 16.27-2.24 4.31-5.99 6.19-10.74 6.2-8.62 0.02-17.24 0.01-25.86 0.01-38.73 0-77.46-0.02-116.2 0.02-5.5 0.01-9.85-1.79-12.1-7.04-3.25-7.55 2.18-15.43 10.74-15.46 22.61-0.08 45.23-0.03 67.84-0.03 1.38 0.01 2.76 0.01 4.13 0.01z"
                                />
                                <path
                                    className="rt0"
                                    d="m131.56 215.28c-23.86 0-47.73 0.04-71.59-0.02-8.24-0.02-13.61-7.2-11.19-14.7 1.51-4.69 5.76-7.71 11.19-7.76 7.37-0.06 14.74-0.02 22.12-0.02 40.23 0 80.47-0.01 120.7 0.01 6.75 0 11.54 3.75 12.4 9.58 1.03 6.99-4.19 12.87-11.65 12.89-23.37 0.05-46.73 0.02-70.1 0.02h-1.88z"
                                />
                                <path
                                    className="rt0"
                                    d="m113.62 94.75c-17.87 0-35.74 0.08-53.61-0.03-8.37-0.05-13.7-7.12-11.22-14.62 1.31-3.96 4.05-6.56 8.19-7.41 1.57-0.33 3.22-0.41 4.84-0.41 34.61-0.02 69.23-0.02 103.84 0 1.61 0 3.26 0.09 4.83 0.43 5.33 1.15 9.06 6.22 8.67 11.56-0.41 5.51-4.57 10.05-10.07 10.32-6.23 0.3-12.49 0.15-18.74 0.16-12.24 0.01-24.49 0-36.73 0z"
                                />
                                <path
                                    className="rt4"
                                    d="m75.57 290.91c9.82-0.05 15.85 2.77 21.09 7.28 3.97 3.41 7.61 7.21 11.52 10.7 3.92 3.51 5.56 3.58 9.87 0.5 2.02-1.45 3.85-3.17 5.73-4.81 5.05-4.41 11.83-4.34 16.13 0.18 4.36 4.59 4.21 11.46-0.67 16.05-3.17 2.98-6.53 5.85-10.16 8.22-11.36 7.4-23.42 6.77-33.95-1.8-4.25-3.46-8-7.52-12.1-11.17-3.95-3.51-5.52-3.57-9.86-0.45-2.12 1.52-4 3.36-6 5.06-4.98 4.22-11.58 4.03-15.83-0.44-4.29-4.51-4.26-11.24 0.36-15.81 7.38-7.31 15.5-13.36 23.87-13.51z"
                                />
                            </svg>
                        </button>
                    </li>
                </ul>

                {DataLeadInfo?.data?.leadPoolingDetailsList?.length > 0
                    ? DataLeadInfo?.data?.leadPoolingDetailsList
                          ?.filter((data: any) => data.service_Id == DataServiceDetail.serviceId)
                          ?.map((lp: any) => {
                              if (lp?.nhsPoolingStatus === 'Accepted' || lp?.nhsPoolingStatus === 'Rejected') {
                                  return (
                                      <div className="mt-2" style={{ border: '1px solid lightGray', borderRadius: '5px', padding: '8px 10px' }}>
                                          <div className="d-flex align-items-center justify-content-end">
                                              <div
                                                  className={`px-2 py-1 rounded fw-bold border bg-opacity-25 border-opacity-50 ${
                                                      lp?.nhsPoolingStatus === 'Accepted' ? 'text-success bg-success border-success' : 'text-danger bg-danger border-danger'
                                                  }`}
                                              >
                                                  <span className="text-end">{lp?.nhsPoolingStatus}</span>
                                              </div>
                                          </div>
                                          <p className="mb-1">
                                              <i>{lp?.nhsPooledOn}</i>
                                              {lp?.nhsPoolingStatus === 'Rejected' ? (
                                                  <i className="fa-solid fa-circle-info ms-2" data-tooltip-id="my-tooltip" data-tooltip-content={`${lp?.nhsPoolingRejectionReason} - ${lp?.nhsPoolingComment}`}></i>
                                              ) : null}
                                          </p>
                                      </div>
                                  );
                              }
                          })
                    : null}

                <div className="tab-content tab-custom-height" id="myTabContent" style={{ marginTop: '15px' }}>
                    <div className={`tab-pane fade ${Data !== '' && Data.leadstatus === 2 ? 'show active' : ''} `} id="Pooling" role="tabpanel" aria-labelledby="Pooling-Tab">
                        {Data !== '' && Data.leadstatus === 2 && <PoolingContainer referralid={Data.referralId} />}
                    </div>
                    <div className={`tab-pane fade ${Data !== '' && Data.leadstatus === 3 ? 'show active' : ''} `} id="Assessment" role="tabpanel" aria-labelledby="Assessment-Tab">
                        {Data !== '' && Data.leadstatus === 3 && <AssessmentContainer referralid={Data.referralId} />}
                    </div>
                    <div className={`tab-pane fade ${Data !== '' && Data.leadstatus === 4 ? 'show active' : ''} `} id="Costing" role="tabpanel" aria-labelledby="Costing-Tab">
                        {Data !== '' && Data.leadstatus === 4 && <CostingContainer refreshData={selecttab} referralid={Data.referralId} />}
                    </div>
                    <div className={`tab-pane fade ${Data !== '' && Data.leadstatus === 5 ? 'show active' : ''} `} id="Assignment" role="tabpanel" aria-labelledby="Assignment-Tab">
                        {Data !== '' && Data.leadstatus === 5 && <AssignmentContainer />}
                    </div>
                    <div className={`tab-pane fade ${Data !== '' && Data.leadstatus === 6 ? 'show active' : ''} `} id="Contract" role="tabpanel" aria-labelledby="Contract-Tab">
                        {Data !== '' && Data.leadstatus === 6 && <ContractingContainer referralId={Data.referralId} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceReferralSidebarView;
