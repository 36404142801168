import React from "react";
import ListIcon from "../../../../../../../assets/images/list-icon.svg";

const AmenitiesTabContentView = (props: any) => {

  return (
    <div>
      {/* <p className="common-amenities-text-tab"><i className="fa-solid fa-house me-2"></i><i>Common area amenities</i></p> */}
      <ul className={`list-unstyled amenities-modal-list ${props?.amenities.length > 9 ? 'more-than-nine' : ''}`}>
      {props?.amenities && props?.amenities.length > 0 && props?.amenities.map((am:any)=>{
        if(am.amenityCategoryId === props?.selectedTab){
          return(<li key={am?.amenityId}>
           <img
                src={am?.amenityMasterIcon}
                alt={am?.amenityMasterName}
                style={{width:"22px"}}
                className="list-icon me-2"
              />
            {am?.amenityMasterName}
            {am?.isCommonAreaFacilities && <div className="common-icon ms-auto"><i className="fa-solid fa-house"></i></div>}
          </li>)
        }
      })  }
      </ul>
    </div>
  );
};

export default AmenitiesTabContentView;
