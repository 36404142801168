import React from 'react';
import ServiceTabListView from './ServiceTabListView';

const ServiceTabListContainer = (props:any) => {
    return (
        <div>
            <ServiceTabListView serviceSection={props?.serviceSection}/>
        </div>
    );
};

export default ServiceTabListContainer;