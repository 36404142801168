import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralOverViewModel } from '../../utils/model';

const initialState: ReferralOverViewModel = {
    StatusReferralOverView: false,
    DataReferralOverView: '',
    MessageReferralOverView: '',
    ErrorReferralOverView: ''
};

export const ReferralOverViewSlice = createSlice({
    name: 'referralOverView',
    initialState,
    reducers: {
        setReferralOverViewAction: (state, { payload }: PayloadAction) => {
            state.StatusReferralOverView = true;
            state.DataReferralOverView = payload;
            state.MessageReferralOverView = 'Success';
        },
        errorReferralOverViewAction: (state, { payload }: PayloadAction) => {
            state.StatusReferralOverView = false;
            state.DataReferralOverView = [];
            state.MessageReferralOverView = 'Failed';
            state.ErrorReferralOverView = payload;
        },
        removeReferralOverViewAction: (state, { payload }: PayloadAction) => {
            state.StatusReferralOverView = false;
            state.DataReferralOverView = '';
            state.MessageReferralOverView = '';
            state.ErrorReferralOverView = '';
        }
    },
    extraReducers: {}
});

export const { setReferralOverViewAction, errorReferralOverViewAction, removeReferralOverViewAction } = ReferralOverViewSlice.actions;

export default ReferralOverViewSlice.reducer;
