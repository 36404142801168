import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTabContainer from './Contract/contractTabContainer';
import ReleaseTabContainer from './Release/releaseTabContainer';
import ReservedTabContainer from './Reserved/reservedTabContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const ReservedPatientView = (props: any) => {
    const { t } = useTranslation();
    const [actionValue, setactionValue] = useState('reserved');
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const actionChange = (val: any) => {
        setactionValue(val);
    };
    useEffect(() => {
        props.setFormAction(actionValue);
    }, [actionValue]);

    return (
        <div>
            <button onClick={props.closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>
            </button>
            <h3 className="mb-4">{t('ReservedRightbar.title')}</h3>
            <div className="right_content-height-tab">
                <div className="user-prfile-detail">
                    <div className="d-flex align-items-center">
                        <div className="light-purple-background user_img">
                            {props.patientData !== '' && props.patientData.serviceUserDetails.patientName.trim().split(' ')[0] !== undefined
                                ? props.patientData.serviceUserDetails.patientName.trim().split(' ')[0].charAt(0)
                                : ''}
                            {props.patientData !== '' && props.patientData.serviceUserDetails.patientName.trim().split(' ')[1] !== undefined
                                ? props.patientData.serviceUserDetails.patientName.trim().split(' ')[1].charAt(0)
                                : ''}
                        </div>
                        <div>
                            <p className="mx-2 bold purple-text">{props.patientData !== '' ? props.patientData.serviceUserDetails.patientName : ''}</p>
                            <div className="d-flex mx-2">
                                <p className="gray-text me-2">
                                    <span className="bold">{t('ReservedRightbar.Gender')}:</span> {props.patientData !== '' ? props.patientData.serviceUserDetails.gender : ''}
                                </p>
                                <p className="me-2 gray-text">
                                    <span className="bold">{t('ReservedRightbar.Age')}:</span>{' '}
                                    {props.patientData !== '' ? window.$utils.calculateAge(props.patientData.serviceUserDetails.dateOfBirth) : ''}
                                </p>
                                <p className="gray-text">
                                    <span className="bold">{t('ReservedRightbar.Bed_No')}:</span> {props.patientData !== '' ? props.patientData.bedName : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs mt-3 mb-3 custom-tab custom-tab4" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={() => actionChange('reserved')}
                            className="nav-link active"
                            id="reserved-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#reserved1"
                            type="button"
                            role="tab"
                            aria-controls="reserved1"
                            aria-selected="true"
                        >
                            <svg
                                version="1.1"
                                id="Layer_1"
                                fill="#ffffff"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 695.45 473.64"
                                xmlSpace="preserve"
                            >
                                <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                <path
                                    d="M0,11.3C3.75,2.75,10.4-0.11,19.53,0C41.4,0.28,44.52,3.16,44.52,25.27c0,67.46,0,134.91,0,202.37c0,2.71,0,5.42,0,9.18
                                      c3.14,0,5.97,0,8.8,0c206.86,0,413.72,0,620.59,0c17.23,0,21.54,4.36,21.54,21.77c0,64.97,0,129.93,0,194.9
                                      c0,15.13-4.81,19.97-19.83,20.02c-21.56,0.07-24.68-3.06-24.68-24.77c0-28.52,0-57.04,0-85.57c0-2.43,0-4.87,0-7.66
                                      c-202.29,0-403.83,0-606.07,0c-0.12,2.49-0.33,4.89-0.33,7.28c-0.02,30.11,0,60.21-0.02,90.32c-0.01,15.28-5.07,20.35-20.22,20.39
                                      c-1.58,0-3.18-0.12-4.75,0.02C10.4,474.34,3.75,470.88,0,462.33C0,311.99,0,161.64,0,11.3z"
                                />
                                <path
                                    d="M164.34,221.92c-24.88,0-49.77,0.22-74.65-0.07c-20.46-0.24-36.45-15.13-37.75-34.46c-1.33-19.87,14.9-37.88,36.2-38.81
                                      c19.19-0.83,38.44-0.41,57.67-0.47c17.87-0.05,35.74-0.07,53.61,0.01c19.43,0.08,36.09,7.27,50.36,20.21
                                      c6.75,6.12,10.36,13.55,8.87,22.97c-0.99,6.23-1.15,12.65-2.71,18.72c-2.31,8.97-6.35,11.81-15.6,11.85
                                      C215.01,221.98,189.68,221.91,164.34,221.92z"
                                />
                            </svg>
                            {t('ReservedRightbar.Reserved')}
                        </button>
                    </li>
                    {DataServiceDetail?.iqviaServiceEnabled === false && (
                        <li className="nav-item" role="presentation">
                            <button
                                onClick={() => actionChange('release')}
                                className="nav-link"
                                id="release-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#release"
                                type="button"
                                role="tab"
                                aria-controls="release"
                                aria-selected="false"
                            >
                                <svg
                                    width="20"
                                    fill="#ffffff"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 408.88 297.99"
                                >
                                    <g>
                                        <style>{`"enable-background:new 0 0 408.88 297.99;"`}</style>
                                        <path
                                            d="M408.88,291.86c-0.45,0.64-0.9,1.27-1.36,1.91c-2.28,3.19-5.19,4.94-9.22,3.79c-4.09-1.17-6.14-4.05-6.3-8.21
               c-0.14-3.85-0.09-7.71-0.09-11.57c-0.01-11.57,0-23.15,0-34.72c0-1.43,0-2.85,0-4.49c-125.05,0-249.66,0-374.85,0
               c0,1.43,0,2.84,0,4.25c0,15.3,0.06,30.6-0.04,45.9c-0.05,6.79-5.94,11.26-11.43,8.39c-2.27-1.18-3.78-4.11-5.19-6.5
               c-0.66-1.12-0.31-2.86-0.31-4.32c-0.01-91.8,0-183.59-0.04-275.39c0-3.48,0.42-6.65,3.26-8.98c5.01-4.1,12.41-1.31,13.49,5.12
               c0.28,1.69,0.24,3.45,0.24,5.17c0.01,28.47,0.01,56.94,0.23,85.53c2.95-12.21,8.73-22.58,18.98-30.12
               C46.63,60,58.27,57.24,70.93,58.82c22.42,2.8,35.73,18.04,41.23,47.34c0.39-0.99,0.65-1.55,0.84-2.13
               c5.63-16.74,17.65-25.28,34.86-27.12c2.64-0.28,5.31-0.41,7.96-0.41c63.72-0.02,127.45-0.11,191.17,0.13
               c6.79,0.03,13.95,1,20.28,3.33c14.57,5.36,22.52,16.81,23.9,32.02c1.06,11.62,0.55,23.38,0.74,35.08c0.03,1.85,0,3.7,0,6.11
               c8.81,0.03,14.93,3.7,16.98,12.54C408.88,207.76,408.88,249.81,408.88,291.86z M17.25,221.09c125.07,0,249.66,0,374.41,0
               c0-16.99,0-33.68,0-50.44c-124.94,0-249.63,0-374.41,0C17.25,187.6,17.25,204.18,17.25,221.09z M128.37,152.91
               c81.96,0,163.96,0,245.82,0c0-13.62,0.34-27.02-0.16-40.39c-0.22-5.83-3.44-10.89-8.38-14.38c-5.59-3.95-12.04-4.52-18.66-4.52
               c-63.71,0.03-127.43,0.02-191.14,0.03c-2.26,0-4.53,0.02-6.78,0.23c-11.72,1.08-19.86,8.32-20.54,20.02
               C127.79,126.73,128.37,139.64,128.37,152.91z M64.46,137.74c17.45-0.09,31.9-14.52,32.04-32.02c0.15-17.51-14.64-32.25-32.25-32.14
               c-17.57,0.11-31.92,14.41-32.05,31.95C32.07,122.99,46.94,137.84,64.46,137.74z M56.96,152.85c-21.62-4.93-34.61-18.31-39.65-39.9
               c0,13.46,0,26.59,0,39.9C30.69,152.85,43.82,152.85,56.96,152.85z M110.86,152.83c0-6.35,0-12.3,0-18.26c0-5.85,0-11.7,0-17.55
               c-6.35,19.47-19.25,31.42-39.22,35.81C84.5,152.83,97.36,152.83,110.86,152.83z"
                                        />
                                    </g>
                                </svg>
                                {t('ReservedRightbar.Release')}
                            </button>
                        </li>
                    )}

                    <li className="nav-item" role="presentation">
                        <button
                            onClick={() => actionChange('contract')}
                            className="nav-link"
                            id="contract-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#contract"
                            type="button"
                            role="tab"
                            aria-controls="contract"
                            aria-selected="false"
                        >
                            <i className="fa fa-file-text" aria-hidden="true"></i>
                            {t('ReservedRightbar.Contract')}
                        </button>
                    </li>
                </ul>
                <div className="tab-content tab-custom-height" id="myTabContent">
                    <div className="tab-pane fade show active" id="reserved1" role="tabpanel" aria-labelledby="reserved-tab">
                        {actionValue === 'reserved' && <ReservedTabContainer patientData={props.patientData} />}
                    </div>
                    {DataServiceDetail?.iqviaServiceEnabled === false && (
                        <div className="tab-pane fade" id="release" role="tabpanel" aria-labelledby="release-tab">
                            {actionValue === 'release' && <ReleaseTabContainer patientData={props.patientData} closeSideMenu={props.closeSideMenu} />}
                        </div>
                    )}
                    <div className="tab-pane fade" id="contract" role="tabpanel" aria-labelledby="contract-tab">
                        {actionValue === 'contract' && <ContractTabContainer patientData={props.patientData} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReservedPatientView;
