import React, { useState, useEffect } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    className: any;
    footer: any;
    reason: any;
    updateData: (e: any) => any;
    submitalert: () => any;
}

const customStyles = {
    option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
            ...base,           
            backgroundColor: isSelected ? '#e9e9e9' : 'inherit',
        };
    },
    menuPortal: (base: any) => {
        return {
            ...base,           
            zIndex: 9999
        };
    }
      
};

const CancelView = (props: ModalProps) => {
    const { t } = useTranslation();
    const [submit, setsubmit] = useState<boolean>(false);
    const [options, setoptions] = useState<any>([]);
    let reasons: any = [];
    const validationSchema = Yup.object().shape({
        reason: Yup
        .object()
        .shape({
            label: Yup.string().required(),
            value: Yup.string().required()
        })
        .nullable() // for handling null value when clearing options via clicking "x"
        .required(),
        desc: Yup.string().notRequired()
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    useEffect(() => {
        if (formState.isValid) {
            setsubmit(true);
        }
        else {
            setsubmit(false);
        }
    }, [formState])

    useEffect(() => {
        if (props?.reason) {
            if (props?.reason.length > 0) {
                reasons = [];

                props?.reason?.map((resn: any) => {
                    reasons.push({ value: resn.rrrId, label: resn.reasonText, name: 'reason'});
                });
                let opt = reasons;
                setoptions(opt);
            }
        }
    }, [props?.reason]);
    return (
        <div>
            <ModalWrapperContainer
                title={props.title}
                onClose={props.onClose}
                className="modal modal-extrasmall costing-modal"
                content={
                    <div>
                        <div className='mb-2'>
                            <label className='mb-1'>{t('Referral_Costing.Reason')}</label>
                            {/* <select
                                {...register('reason', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'reason' as const}
                                id="reason"
                                //@ts-ignore
                                className={`form-control white ${errors.reason ? 'is-invalid' : ''}`}
                            >
                                <option value="">{t('Reject_Modal.Reason')}</option>
                                {props?.reason !== undefined &&
                                    props?.reason?.map((resn: any) => {
                                        return (
                                            <option key={resn.rrrId} value={resn.rrrId}>
                                                {resn.reasonText}
                                            </option>
                                        );
                                    })}
                            </select> */}

                            <Controller
                                    name="reason"
                                    control={control}
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                      return  <Select                                            
                                            {...fieldProps}                                          
                                            isSearchable={options.length > 3 ? true : false}                                            
                                            options={options}
                                            placeholder={t('Reject_Modal.Reason')}
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            value={value}
                                            onChange={selectedOption => {  
                                                fieldProps.onChange(selectedOption);                                              
                                                let event = { target: { name: 'reason', value: selectedOption.value } }
                                                props.updateData(event)
                                            }}
                                            maxMenuHeight={150}
                                            
                                        />
                                    }}
                                />
                                <p>{errors.reason?.message}</p>
                        </div>
                        <label className='mb-1'>{t('Referral_Costing.Description')}</label>
                        {/* <textarea rows={3} className='form-control white'></textarea> */}
                        <textarea
                {...register('desc', {
                    onChange: (e: any) => {
                        props.updateData(e);
                    }
                })}
                name={'desc' as const}
                className={`form-control white`}
                placeholder="Description"
                rows={3}
            ></textarea>
                    </div>
                }
                footer={
                    <div>                        
                        <button disabled={!submit} onClick={props.submitalert} className='btn btn-primary'>{t('Referral_Costing.Submit_btn')}</button>
                    </div>
                }

            />
        </div>
    );
};

export default CancelView;