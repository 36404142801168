import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PaginationContainer from '../../../../../../components/PaginationComponent/indexContainer';
import { getPatientReservedData } from '../../../../../../service/dashboard-service';
import { removePatientDraftAction } from '../../../../../../store/dashboard/draftPatientData';
import { rightNavigationPage } from '../../../../../../utils/constant';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
const AdmissionView = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    async function getpatientdata(pid:any) {
        // const payload: any = {id:data.patientId};
        // await getPatientReservedData(payload);
    }
    async function showTaskDetailHandler(data: any) {
        dispatch(removePatientDraftAction());
        const payload: any = {id:data.patientId};
       const response = await getPatientReservedData(payload);
       
       if(response.status){
        const data1={
            isDraftAdd : "reserved"
        }
        let obj: any = {
            ShowClass: true,
            FormName: rightNavigationPage.Vacant,
            Data: data1,
            ShowToggle:false
        };
        dispatch(setNavigationAction(obj));
       }
       else{
        swal(response?.message, {
            icon: 'error'
        });
       }

    }
    return (
        <div className="gray-box mt-2">
            <p className='purple-text lead-text bold mb-3'>
            {props?.totalCount > 99 ? props?.totalCount : "" }
            <span className='ms-1'>{t('Task.Admission_Title')}</span>
            </p>
            {props?.taskList === null && (
                <p>No Data!</p>
            )}
                {props?.taskList?.length > 0 && (<ul className="list-unstyled task-list">
                    {props.taskList.map((data: any, index: any) => {
                        return (
                            <li key={data?.taskId}>
                                <a className="cursor-pointer" onClick={() => showTaskDetailHandler(data)}>
                                    {data?.message}
                                </a>
                            </li>
                        );
                    })}
                </ul>)}

                {props.currentPage > 0 && props.totalCount > 0 && props.pageSize > 0 && (
                <PaginationContainer currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} onPageChange={(page: any) => props.getCurrentPage(page)} />
            )}
        </div>
    );
};

export default AdmissionView;