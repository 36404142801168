import React, { useEffect, useState } from 'react';
import ReservedView from './reservedPatientView';
import { RootState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { getPatientDetailsService } from '../../../../../service/patient-service';
import eventEmitter from '../../../../../utils/eventEmitter';
import warning from '../../../../../assets/images/warning.png';
import swal from 'sweetalert';

const ReservedPatientContainer = () => {
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusPatientDetail, DataPatientDetail } = useSelector((state: RootState) => state.patientDetails);
    const [patientData, setPatientData] = useState<any>('')
    const [editedFormReserved, setEditedFormReserved] = useState<any>(false)
    const [formAction, setFormAction] = useState<any>('')
    const dispatch = useDispatch();
    const closeSideMenu = (btnValue: any) => {
        if (btnValue !== true && editedFormReserved === true && formAction === 'release') {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle:false
                    };
                    dispatch(setNavigationAction(obj));
                }
            });
        } else {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        }
    };
    useEffect(() => {
        async function getPatientDetails() {
            const payload: any = { patientId: Data.patientId};
            await getPatientDetailsService(payload);
        }
        getPatientDetails();
    },[Data])
    useEffect(() => {
        if (StatusPatientDetail === true) {
            setPatientData(DataPatientDetail.data)
            
        }
    },[StatusPatientDetail, DataPatientDetail])

    const eventCloseReservedSideMenu = async() => {
        eventEmitter.emit('eventCloseEditSideMenu');
        setEditedFormReserved(true)
    }
    useEffect(() => {
        eventEmitter.on('eventCloseReservedSideMenu', eventCloseReservedSideMenu);
        return () => {
            eventEmitter.off('eventCloseReservedSideMenu', eventCloseReservedSideMenu)
        }
    });
    return (
        <>
            <ReservedView closeSideMenu={closeSideMenu} ShowClass={ShowClass} patientData={patientData} setFormAction={(action: any) => {setFormAction(action);setEditedFormReserved(false);}} />
        </>
    );
};

export default ReservedPatientContainer;