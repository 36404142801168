import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServicetypeModel } from '../../utils/model';

const initialState: ServicetypeModel = {
    StatusServiceInfo: false,
    DataServiceInfo: '',
    MessageServiceInfo: '',
    ErrorServiceInfo: ''
};

export const serviceInformationSlice = createSlice({
    name: 'serviceType',
    initialState,
    reducers: {
        setServiceInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusServiceInfo = true;
            state.DataServiceInfo = payload;
            state.MessageServiceInfo = 'Success';
        },
        errorServiceInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusServiceInfo = false;
            state.DataServiceInfo = [];
            state.MessageServiceInfo = 'Failed';
            state.ErrorServiceInfo = payload;
        },
       removeServiceInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusServiceInfo = false;
            state.DataServiceInfo = '';
            state.MessageServiceInfo = '';
            state.ErrorServiceInfo = '';
        }
    },
    extraReducers: {}
});

export const { setServiceInformationAction, errorServiceInformationAction, removeServiceInformationAction } = serviceInformationSlice.actions;

export default serviceInformationSlice.reducer;
