import logo from '../../assets/logos/logo-white-website.png';
import abstractImg from '../../assets/images/abstract.png';
import signOneImg from '../../assets/images/sign-1.png';
import signTwoImg from '../../assets/images/sign-2.png';
import signThreeImg from '../../assets/images/billing-management.svg';
import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { toast } from 'react-toastify';
import { removeErrorLoginAction } from '../../store/Auth/loginReducer';

interface IFormInput {
    email: string;
    password: string;
    rememberme: boolean;
}

function LoginView(props: any) {
    const [passwordShown, setPasswordShown] = useState(false);
    const dispatch = useDispatch();
    const { StatusRememberMe, DataRememberMe } = useSelector((state: RootState) => state.rememberMe);
    const { StatusLogin, ErrorLogin } = useSelector((state: RootState) => state.login);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IFormInput>({
        defaultValues: {
            email: DataRememberMe.email !== undefined && DataRememberMe.email !== '' ? DataRememberMe.email : '',
            password: DataRememberMe.password !== undefined && DataRememberMe.password !== '' ? window.$crypto.OpenSSLDecrypt(DataRememberMe.password) : '',
            rememberme: DataRememberMe.rememberme === true ? DataRememberMe.rememberme : false
        },
        mode: 'onBlur' // "onChange, onBlur"
    });
    const onSubmitHandler: SubmitHandler<IFormInput> = (data) => {
        props.handleSubmit(data);
        toast.dismiss();
    };
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    useEffect(() => {
        if (StatusLogin === false && ErrorLogin.message !== undefined && ErrorLogin.message !== '') {
            toast.error(ErrorLogin.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(removeErrorLoginAction())
        }
    }, [StatusLogin, ErrorLogin])
    return (
        <div className="login-signup">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 p-0">
                        <div className="login-signup-bg text-center">
                            <img src={logo} alt="logo" className="img-fluid logo" />

                            <div className="abstract-img">
                                <img src={abstractImg} className="img-fluid" alt="" />
                            </div>

                            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active carousel-css">
                                        <img src={signOneImg} className="img-fluid" alt="" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Locate Care Bed Management</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-css">
                                        <iframe src={signThreeImg} width={500} height={500}/>
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Locate Care Billing Management</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-css">
                                        <img src={signTwoImg} className="img-fluid" alt="" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Locate Care Referral Management</h5>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 p-0">
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="login-detail d-flex align-items-center justify-content-center">
                                <div className="h2-style">
                                    <h2 className="gray-text mb-3">Welcome to Locate.Care !</h2>
                                </div>
                                <h4 className="my-3 purple-text">Sign In</h4>
                                <p className="mb-3">Please Enter your Email/Username and Password</p>


                                <div className='login-custom-width'>
                                <div className='custom-input-box'>
                                    <div className={`${errors.email ? 'input-invalid' : ''}`}>
                                        <div className="custom-input input-group">
                                            <input type="text" {...register('email', { required: true, pattern: /^([a-zA-Z0-9]+)$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i })} placeholder="none" id="email" className="form-input" />
                                            <label htmlFor="email" className="form-label">
                                                Email or Username
                                            </label>
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                        </div>
                                        {errors.email?.type === 'required' && <div className="error-message">Email or Username is required.</div>}
                                        {errors.email?.type === 'pattern' && <div className="error-message">Please enter a valid email.</div>}
                                    </div>

                                    <div className={`${errors.password ? 'input-invalid invalid-password' : ''}`}>
                                    <div className="custom-input input-group">
                                        <input {...register('password', { required: true })} type={passwordShown ? 'text' : 'password'} placeholder="none" className="form-input" id="password" />
                                        <label htmlFor="password" className="form-label">
                                            Password
                                        </label>
                                        {!passwordShown ? (
                                            <i className="fa fa-eye cursor-pointer" aria-hidden="true" onClick={togglePassword}></i>
                                        ) : (
                                            <i className="fa fa-eye-slash cursor-pointer" aria-hidden="true" onClick={togglePassword}></i>
                                        )}
                                    </div>
                                    {errors.password?.type === 'required' && <div className="error-message">Password is required.</div>}
                                </div>

                                </div>

                                <label className="remember-forgot-pw mt-3">
                                    <span className="d-flex align-items-center justify-content-center">
                                        <input type="checkbox" {...register('rememberme', {})} />
                                        Remember me
                                    </span>
                                    {/* <a href="/forgot-password" className="gray-text">
                                        Forgot Password
                                    </a> */}
                                </label>
                                <button type="submit" className="site-button outline-purple purple-background white-text mt-4 w-100">
                                    Sign In
                                </button>
                                </div>
                                {/* <p className="mt-4">
                                    Not a member?
                                    <a href="/sign-up" className="fw-bold purple-text">
                                        {' '}
                                        Sign up now
                                    </a>
                                </p> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginView;
