import React from 'react';
import BillingDetailsLessView from './BillingDetailsLessView';

const BillingDetailsLessContainer = (props: any) => {
    return (
        <div>
            <BillingDetailsLessView invoiceStatistics={props.invoiceStatistics} />
        </div>
    );
};

export default BillingDetailsLessContainer;