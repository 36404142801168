import { addDays, eachDayOfInterval, endOfMonth, getYear, isWeekend, parse, startOfMonth, subDays } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import { store } from "../store";

export const findDateRange = (localFilters: any) => {
    const dateType = store.getState().appliedFilter.TopFilterData.dateType;
    let dateRangeForPayload = localFilters.dateRange ? cloneDeep(localFilters.dateRange) : [];

    if (localFilters.dateFilterVariableType !== 0 && dateType === "date") {
        const lStartDate = new Date(dateRangeForPayload[0].startDate);
        const lEndDate = new Date(dateRangeForPayload[0].endDate);

        lStartDate.setHours(0, 0, 0, 0);
        lEndDate.setHours(0, 0, 0, 0);

        dateRangeForPayload[0].startDate = subDays(lStartDate, localFilters.dateFilterVariableType);
        dateRangeForPayload[0].endDate = addDays(lEndDate, localFilters.dateFilterVariableType);
    } else if (dateType === "flexible" && localFilters.flexibleMonth.length) {
        dateRangeForPayload = [];
        if (localFilters.flexibleMainType === "Weekend") {
            let weekend: any[] = [];
            for (let i = 0; i < localFilters.flexibleMonth.length; i++) {
                const monthName = localFilters.flexibleMonth[i].monthName;
                const year = localFilters.flexibleMonth[i].year;
                const parseMonth = parse(monthName, "MMMM", new Date());
                const monthIndex = parseMonth.getMonth();

                const datesInMonth = eachDayOfInterval({
                    start: new Date(year, monthIndex, 1),
                    end: new Date(year, monthIndex + 1, 0)
                });

                const weekendDates = datesInMonth.filter(item => isWeekend(item));

                weekend = weekendDates.reduce((acc, cur, index) => {
                    if (index % 2 === 0) {
                        acc.push({
                            startDate: cur,
                            endDate: weekendDates[index + 1]
                        });
                    }

                    return acc;
                }, [] as any);

                dateRangeForPayload.push(...weekend);
            }
        } else if (localFilters.flexibleMainType === "Week") {
            const weekDates: any = [];
            for (let i = 0; i < localFilters.flexibleMonth.length; i++) {
                const monthName = localFilters.flexibleMonth[i].monthName;
                const year = localFilters.flexibleMonth[i].year;
                const parseMonth = parse(monthName, "MMMM", new Date());
                const monthIndex = parseMonth.getMonth();

                const datesInMonth = eachDayOfInterval({
                    start: new Date(year, monthIndex, 1),
                    end: new Date(year, monthIndex + 1, 0)
                });

                let startDate: Date | null = null;
                datesInMonth.forEach((item, index) => {
                    if (startDate === null && !isWeekend(item)) {
                        startDate = item;
                    }

                    if (isWeekend(item) && startDate) {
                        weekDates.push({
                            startDate,
                            endDate: datesInMonth[index - 1]
                        });

                        startDate = null;
                    }

                    if (index === datesInMonth.length - 1 && startDate) {
                        weekDates.push({
                            startDate: startDate,
                            endDate: item
                        });

                        startDate = null;
                    }
                });

                dateRangeForPayload = weekDates;
            }
        } else {
            const monthsDates: any[] = [];
            for (let i = 0; i < localFilters.flexibleMonth.length; i++) {
                const monthName = localFilters.flexibleMonth[i].monthName;
                const parseMonth = parse(monthName, "MMMM", new Date());

                const pair = {
                    startDate: startOfMonth(parseMonth),
                    endDate: endOfMonth(parseMonth)
                }
                monthsDates.push(pair);
            }
            dateRangeForPayload = monthsDates;
        }
    }

    return dateRangeForPayload;
}