import React,{useState,useEffect} from 'react';
import CancelView from './CancelView';
import { RMCancelCosting, getCostingRejectiondata } from '../../service/costing';
import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';

const CancelContainer = (props: any) => {
    const [reasons,setreason] = useState<any>();
    const [rejectiondata,setrejectiondata] = useState<any>({'desc':''});    
    useEffect(()=>{
      async function getreason(){
        const res = await getCostingRejectiondata(3);
        setreason(res.data);
      }
      getreason();
    },[]);

    const updateData = (e:any) =>{
        setrejectiondata({
            ...rejectiondata,
            [e.target.name]: e.target.value
        });
    }

    const submitalert = () =>{
    swal({
        title: '',
        text: `Are you sure you want to cancel the costing for lead ${props.referralid} ?`,
        icon: warning,
        buttons: ['No', 'Yes'],
        dangerMode: true
    }).then(async (confirm: any) => {
        if (confirm) {
         submit();
        } else {
        }
    });
}

    async function submit(){
        let data ={
            "rcId": props.costingData?.rcId,            
            "rejectionReasonId": rejectiondata.reason,
            "comment": rejectiondata.desc
        }
        const res = await RMCancelCosting(data);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            }) .then(async (confirm: any) => {
                props.onClose();               
            });
           
        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
                props.onClose();
                
            });;
        }
    }
    return (
        <div>
            <CancelView  onClose={props.onClose} footer={props.footer}  title={props.title} className={props.className} content={props.content} reason={reasons} updateData={updateData} submitalert={submitalert} />
        </div>
    );
};

export default CancelContainer;