import React from 'react';
import ServiceManagerView from './ServiceManagerView';

const ServiceManagerContainer = () => {
    return (
        <div>
            <ServiceManagerView />
        </div>
    );
};

export default ServiceManagerContainer;