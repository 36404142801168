import React, { useEffect } from 'react';
import ChangePasswordView from './ChangePasswordView';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

const ChangePasswordContainer = () => {

    const dispatch = useDispatch();
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);

    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
      },[])
      
    return (
        <div>
            <ChangePasswordView />
        </div>
    );
};

export default ChangePasswordContainer;