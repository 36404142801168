import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';
import { ReasonList } from '../../../../../../utils/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const ReleaseTabView = (props: any) => {
    const { Data } = useSelector((state: RootState) => state.navigation);
    // DoR - Date of Release (Release Date)
    const [dor, setDor] = useState(null)
    const { t } = useTranslation();
    const [disableButton, setDisableButton] = useState<any>(false);
    const [radioChecked, setRadioChecked] = useState<any>(false);
    let [reasonSmartSearch, setReasonSmartSearch] = useState<any>(undefined);
    const validationSchema = Yup.object().shape({
        releaseDate: Yup.string().required('Discharge date is required'),
        reason_Id: Yup.string().required('Reason is required'),
        remark: Yup.string().notRequired(),
        charge: Yup.string().required(),
        amount: Yup.string().when('charge', {
            is: 'withcharge',
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        })
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors, isValid }
    } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

    const onSubmitHandler: SubmitHandler<any> = (data) => {
        props.onSubmitHandler(data);
    };

    useEffect(() => {
        setDor(null)
        setRadioChecked(false)
        reset();
        if (reasonSmartSearch !== undefined) {
            clearReasonSmartSearch();
        }
    }, [Data]);

    useEffect(() => {
        if (props.patientData?.reservationDetails) {
            setValue('amount', props.patientData?.reservationDetails?.amount);
        }
    }, [props.patientData]);

    useEffect(() => {
        if (isValid) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [isValid]);

    const handleRadioChange = (e: any) => {
        let value = e.target.value;
        if (value === 'withcharge') {
            setRadioChecked(true);
        } else {
            setRadioChecked(false);
        }
    };

    useEffect(() => {
        if(reasonSmartSearch === undefined){
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLReasons}/${ReasonList.Release}`
            };
            setReasonSmartSearch(objReasonSmartSearch);
        }
    }, [reasonSmartSearch]);

    const clearReasonSmartSearch = () => {
        setValue('reason_Id', '', { shouldValidate: true });
        setReasonSmartSearch(undefined);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="input-group date mt-1 mb-2 datepicker-input-css" id="datepicker">
                    <Controller
                        control={control}
                        name="releaseDate"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return (
                                <DatePicker
                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                    label="Release Date*"
                                    format="DD/MM/YYYY"
                                    value={dor}
                                    {...fieldProps}
                                    onChange={(date: any) => {
                                        props.updateData(date);
                                        fieldProps.onChange(date);
                                        setDor(date)
                                    }}
                                    // disableFuture
                                    views={['year', 'month', 'day']}
                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                />
                            );
                        }}
                    />
                </div>
                {/* <select {...register('reason_Id', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} id="" className={`form-control mt-2 white mb-2 ${errors.reason_Id ? 'is-invalid' : ''}`}>
            <option value="">Reason for Release*</option>
                {
                    props.listReasons !== undefined 
                    && props.listReasons !== null 
                    && props.listReasons !== '' 
                    && props.listReasons.length > 0 
                    && props.listReasons.map((val: any) => {
                        return (<option key={val.reasonId} value={val.reasonId}>{val.reasonText}</option>)
                    })
                }
                
            </select> */}

                {reasonSmartSearch !== undefined && (
                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                        <SmartSearchDropdownContainer
                            smartSearchLabel="Reason for Release*"
                            errors={errors.reason_Id}
                            onChangeSmartSearch={(value: any) => {
                                const event = new Event('change');
                                let obj = {
                                    name: 'reason_Id',
                                    value: value?.id?.toString()
                                };
                                //@ts-ignore
                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                setValue('reason_Id', value?.id?.toString(), { shouldValidate: true });
                                if (value !== '') {
                                    props.updateData(event);
                                }
                            }}
                            smartSearch={reasonSmartSearch}
                        />
                    </div>
                )}
                <div className="d-flex mt-3 mb-2">
                    <label className="form--radio-label mb-2 d-flex align-items-center me-3">
                        <input
                            {...register('charge', {
                                onChange: (e: any) => {
                                    handleRadioChange(e);
                                }
                            })}
                            type="radio"
                            name="charge"
                            id="withcharge"
                            value="withcharge"
                        />
                        <span className="ms-1">{t('ReservedRightbar.With_Charge')}</span>
                    </label>
                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            {...register('charge', {
                                onChange: (e: any) => {
                                    handleRadioChange(e);
                                }
                            })}
                            type="radio"
                            name="charge"
                            id="withoutcharge"
                            value="withoutcharge"
                        />
                        <span className="ms-1">{t('ReservedRightbar.Without_Charge')}</span>
                    </label>
                </div>
                {radioChecked && (
                    <div className="position-relative pound-sign-input">
                        <label>£</label>
                        <input
                            type="text"
                            {...register('amount', {
                                onChange: (e: any) => {
                                    props.setamount(Number(e.target.value));
                                }
                            })}
                            name="amount"
                            placeholder="Amount"
                            className="form-control white mb-2"
                        />
                    </div>
                )}
                <textarea
                    {...register('remark', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    placeholder="Remark"
                    className={`form-control white`}
                ></textarea>
                <div className="mt-2 text-center  right_content_btn">
                    <button type="submit" disabled={!disableButton} className="btn btn-primary  flex-1 me-2">
                        {t('ReservedRightbar.Release_btn')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ReleaseTabView;
