import { useEffect, useState } from 'react';
import FunderView from './funderListView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { getFunderData, getPatientReservedData, getPatientReservedDataForContract } from '../../../../../../../service/dashboard-service';
import eventEmitter from '../../../../../../../utils/eventEmitter';
import swal from 'sweetalert';
import warning from '../../../../../../../assets/images/warning.png';
import { confirmContract, confirmContractadmission, getContractDataAdmission, saveContract } from '../../../../../../../service/contract-service';
import { getLeaddata } from '../../../../../../../service/leads';
import { removeLeadInformationAction, setLeadInformationAction } from '../../../../../../../store/lead/getLeaddataReducer';
import { removePatientDraftAction, setPatientDraftAction } from '../../../../../../../store/dashboard/draftPatientData';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const FunderListContainer = (props: any) => {
    const { t } = useTranslation();
    let [funderlistData, setfunderlistData] = useState<any>([]);
    let [updateIndex, setupdateIndex] = useState<number>(-1);
    let [updateFunderIndex, setupdateFunderIndex] = useState<number>(-1);
    let [commisionerindex, setcommisionerIndex] = useState<number>(-1);
    let [FunderData, setFunderData] = useState<any>([]);
    let [CityData, setCityData] = useState<any>([]);
    let [isCheck, setCheck] = useState<any>(true);
    let [fundertype, setfundertype] = useState<any>('');
    let [changetype, setchangetype] = useState<any>(false);
    let [iseditable, seteditable] = useState<boolean>(false);
    let [deleteddoc, setdeleteddoc] = useState<any>([]);
    const [formval, setformval] = useState<any>();
    const [isdone, setdone] = useState<boolean>(false);
    const [cindex, setcindex] = useState<any>();
    const [acdid, setacdid] = useState<any>();
    const [afterfilterdata, setafterfilterdata] = useState<any>([]);
    const [afterdeleteddata, setafterdeleteddata] = useState<any>([]);
    const [datapatient, setdatapatinet] = useState<any>('');
    const dispatch = useDispatch();
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);

    const { Data } = useSelector((state: RootState) => state.navigation);
    useEffect(() => {
        setfunderlistData([])
        props.setlistdata([])
    }, [Data]);

    useEffect(() =>{
        if(DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0){
          setFunderData(DataFunderInfo.data);
        }
    }, [DataFunderInfo]);

    useEffect(() => {
        if (TempDataSplitFunding.length > 0) {
            if (TempDataSplitFunding[0].enddate !== undefined && TempDataSplitFunding[0].enddate !== null && TempDataSplitFunding[0].enddate !== '') {
                seteditable(true);
            }
        }
    }, [TempDataSplitFunding]);

    useEffect(() => {
        if (DataCityInfo.data !== undefined && DataCityInfo.data.length > 0) {
            setCityData(DataCityInfo.data);
        }
    });
    const updateData = async (e: any, index: number, arrayname: any, fundtype: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        setfundertype(fundtype);
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                let temporaryarray = funderlistData.slice();
                const fundername = FunderData.filter((val: any) => val.key == temporaryarray[index]['funderData']['fFunder']);
                const cityname = CityData.filter((val: any) => val.key == temporaryarray[index]['funderData']['fCity']);
                if (fundertype !== '4') {
                    temporaryarray[index]['commisionerData'] = '';
                    temporaryarray[index]['commisionerData'] = {
                        cEmailId: temporaryarray[index]['funderData']['fEmailId'] || '',
                        cFunder: fundername[0].value || '',
                        cContactNo: temporaryarray[index]['funderData']['fContactNo'] || '',
                        cAddress1: temporaryarray[index]['funderData']['fAddress1'] || '',
                        cCity: temporaryarray[index]['funderData']['fCity'] || '',
                        cPoBox: temporaryarray[index]['funderData']['fPoBox'] || ''
                    };
                    setCheck(true);
                    setupdateIndex(index);
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                    // setafterfilterdata(temporaryarray);
                } else if (fundertype === '4') {
                    temporaryarray[index]['commisionerData'] = '';
                    temporaryarray[index]['commisionerData'] = {
                        cEmailId: temporaryarray[index]['funderData']['fEmailId'] || '',
                        cFunder: temporaryarray[index]['funderData']['fFunder'] || '',
                        cContactNo: temporaryarray[index]['funderData']['fContactNo'] || '',
                        cAddress1: temporaryarray[index]['funderData']['fAddress1'] || '',
                        cCity: temporaryarray[index]['funderData']['fCity'] || '',
                        cPoBox: temporaryarray[index]['funderData']['fPoBox'] || ''
                    };
                    setCheck(true);
                    setupdateIndex(index);
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                    // setafterfilterdata(temporaryarray);
                }
            } else {
                let temporaryarray = funderlistData.slice();
                temporaryarray[index]['commisionerData'] = '';
                setupdateIndex(index);
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
                // setafterfilterdata(temporaryarray);
            }
        } else if (e.target.type === 'radio') {
            setchangetype(false);
            setupdateFunderIndex(index);
            setTimeout(() => {
                setchangetype(true);
            }, 200);
            setfundertype(e.target.value);
        } else {
            var sindex = funderlistData.findIndex((x: any, i: any) => i === index);
            let isexists: any = '';
            if (e.target.name.includes('fFunder')) {
                isexists = [...funderlistData].some((val: any, i: any) => {
                    if (val.funderData.fFunder === e.target.value) {
                        if (DataLeadInfo?.data?.contract[i]?.status !== '' && DataLeadInfo !== '') {
                            if (DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled') {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                });
            }
            if (isexists) {
                if (funderlistData[index].funderData.fFunder !== e.target.value) {
                    swal('Funder is already added', {
                        icon: 'warning',
                        dangerMode: true
                    });
                    let temporaryarray = funderlistData.slice();
                    let field = e.target.name;
                    field = field.split('.');
                    if (e.target.name.includes('fFunder')) {
                        setfundertype(funderlistData[index].funderData.ftype);
                        setTimeout(() => {
                            const fundername = FunderData.filter((val: any) => val.key == funderlistData[index].funderData.fFunder);
                            temporaryarray[index][arrayname][field[2]] = funderlistData[index].funderData.fFunder;
                            temporaryarray[index][arrayname]['fFunderVal'] = funderlistData[index].funderData.fFunderVal;
                            setupdateFunderIndex(index);
                            setcommisionerIndex(index);
                            setfunderlistData(temporaryarray);
                            props.updatedfunderlist(temporaryarray);
                            // setafterfilterdata(temporaryarray);
                        }, 1000);
                    }
                }
            } else {
                if (sindex !== -1) {
                    let temporaryarray = funderlistData.slice();
                    let field = e.target.name;
                    field = field.split('.');
                    if (e.target.name.includes('fFunder')) {
                        const fundername = FunderData.filter((val: any) => val.key == e.target.value);
                        temporaryarray[index][arrayname][field[2]] = e.target.value;
                        temporaryarray[index][arrayname]['fFunderVal'] = fundername[0].value;
                        temporaryarray[index][arrayname]['fEmailId'] = fundername[0].funderEmail;
                        temporaryarray[index][arrayname]['fContactNo'] = fundername[0].funderPhoneNo;
                        temporaryarray[index][arrayname]['fAddress1'] = fundername[0].funderAddressLine1;
                        temporaryarray[index][arrayname]['fCity'] = fundername[0].funderCityId?.toString();
                        temporaryarray[index][arrayname]['fPoBox'] = fundername[0].funderPobox;
                        setupdateFunderIndex(index);
                        temporaryarray[index]['commisionerData'] = '';
                        setCheck(false);
                    } else {
                        temporaryarray[index][arrayname][field[2]] = e.target.value;
                        setupdateFunderIndex(-1);
                    }
                    
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                    // setafterfilterdata(temporaryarray);
                } else {
                }
            }
        }
    };

    const updateCData = (e: any, index: number, arrayname: any) => {
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
            }
        } else {
            var sindex = funderlistData.findIndex((x: any, i: number) => i === index);
            if (sindex !== -1) {
                let temporaryarray = funderlistData.slice();
                let field = e.target.name;
                field = field.split('.');
                temporaryarray[index][arrayname][field[2]] = e.target.value;
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
                // setafterfilterdata(temporaryarray);
            } else {
            }
        }
    };

    const updateSData = (e: any, index: number, arrayname: any) => {
        var sindex = funderlistData.findIndex((x: any, i: number) => i === index);
        if (sindex !== -1) {
            let temporaryarray = funderlistData.slice();
            let field = e.target.name;
            field = field.split('.');
            temporaryarray[index][arrayname][field[2]] = e.target.value;
            setfunderlistData(temporaryarray);
            props.updatedfunderlist(temporaryarray);
            // setafterfilterdata(temporaryarray);
        } else {
        }
    };

    useEffect(() => {
        setCheck(false);
        async function getFilterFunder() {
            await getFunderData(fundertype);
        }
        getFilterFunder();
    }, [fundertype]);

    useEffect(() => {
        setfundertype('4');
        setCheck(false);
        let temporaryarray = JSON.parse(JSON.stringify(funderlistData));
        let index = temporaryarray.findIndex((data: any) => data.funderData?.ftype === '4');
        if (index !== -1) {
            // temporaryarray[index]['funderData']['fFunderVal'] = props.selfData.FullName;
            // temporaryarray[index]['funderData']['fFunder'] = props.selfData.FullName;
            // temporaryarray[index]['funderData']['fEmailId'] = props.selfData.EmailId;
            // temporaryarray[index]['funderData']['fContactNo'] = props.selfData.ContactNo;
            // temporaryarray[index]['funderData']['fAddress1'] = props.selfData.Address1;
            // temporaryarray[index]['funderData']['fCity'] = props.selfData.City;
            // temporaryarray[index]['funderData']['fPoBox'] = props.selfData.PoBox;

            if (funderlistData[index]['funderData']['fFunderVal'] === props.selfData.FullName) {
                temporaryarray[index]['funderData']['fFunderVal'] = props.selfData.FullName;
            }
            if (funderlistData[index]['funderData']['fFunder'] === props.selfData.FullName) {
                temporaryarray[index]['funderData']['fFunder'] = props.selfData.FullName;
            }
            if (funderlistData[index]['funderData']['fEmailId'] === props.selfData.EmailId) {
                temporaryarray[index]['funderData']['fEmailId'] = props.selfData.EmailId;
            }
            if (funderlistData[index]['funderData']['fContactNo'] === props.selfData.ContactNo) {
                temporaryarray[index]['funderData']['fContactNo'] = props.selfData.ContactNo;
            }
            if (funderlistData[index]['funderData']['fAddress1'] === props.selfData.Address1) {
                temporaryarray[index]['funderData']['fAddress1'] = props.selfData.Address1;
            }
            if (funderlistData[index]['funderData']['fCity'] === props.selfData.City) {
                temporaryarray[index]['funderData']['fCity'] = props.selfData.City;
            }
            if (funderlistData[index]['funderData']['fPoBox'] === props.selfData.PoBox) {
                temporaryarray[index]['funderData']['fPoBox'] = props.selfData.PoBox;
            }

            temporaryarray[index]['funderData']['ftype'] = '4';

            setupdateFunderIndex(index);
            setfunderlistData(temporaryarray);
            props.updatedfunderlist(temporaryarray);
            // setafterfilterdata(temporaryarray);
        }
    }, [props.selfData]);

    useEffect(() => {
        let temporaryarray = JSON.parse(JSON.stringify(funderlistData));
        let index = temporaryarray.findIndex((data: any) => data.funderData?.ftype === '4');
        if (props.validateSelfFunder != undefined && index !== -1) {
            let serviceDetails = props.validateSelfFunder.target;
            if (serviceDetails.name == 'FullName' && serviceDetails.value != '' && temporaryarray[index]['funderData']['fFunderVal'] != serviceDetails.value) {
                swal({
                    title: '',
                    text: `${t('VacantRightbar.selfwarning')}`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        temporaryarray[index]['funderData']['fFunderVal'] = serviceDetails.value;
                        temporaryarray[index]['funderData']['fFunder'] = serviceDetails.value;
                        setupdateFunderIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                        // setafterfilterdata(temporaryarray);
                    }
                });
            } else if (serviceDetails.name == 'Address1' && serviceDetails.value != '' && temporaryarray[index]['funderData']['fAddress1'] != serviceDetails.value) {
                swal({
                    title: '',
                    text: `${t('VacantRightbar.selfwarning')}`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        temporaryarray[index]['funderData']['fAddress1'] = serviceDetails.value;
                        setupdateFunderIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                        // setafterfilterdata(temporaryarray);
                    }
                });
            } else if (serviceDetails.name == 'PoBox' && serviceDetails.value != '' && temporaryarray[index]['funderData']['fPoBox'] != serviceDetails.value) {
                swal({
                    title: '',
                    text: `${t('VacantRightbar.selfwarning')}`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        temporaryarray[index]['funderData']['fPoBox'] = serviceDetails.value;
                        setupdateFunderIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                        // setafterfilterdata(temporaryarray);
                    }
                });
            } else if (serviceDetails.name == 'ContactNo' && serviceDetails.value != '' && temporaryarray[index]['funderData']['fContactNo'] != serviceDetails.value) {
                swal({
                    title: '',
                    text: `${t('VacantRightbar.selfwarning')}`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        temporaryarray[index]['funderData']['fContactNo'] = serviceDetails.value;
                        setupdateFunderIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                        // setafterfilterdata(temporaryarray);
                    }
                });
            } else if (serviceDetails.name == 'EmailId' && serviceDetails.value != '' && temporaryarray[index]['funderData']['fEmailId'] != serviceDetails.value) {
                swal({
                    title: '',
                    text: `${t('VacantRightbar.selfwarning')}`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        temporaryarray[index]['funderData']['fEmailId'] = serviceDetails.value;
                        setupdateFunderIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                        // setafterfilterdata(temporaryarray);
                    }
                });
            } else if (serviceDetails.name == 'City' && serviceDetails.value != '' && temporaryarray[index]['funderData']['fCity'] != serviceDetails.value) {
                swal({
                    title: '',
                    text: `${t('VacantRightbar.selfwarning')}`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        temporaryarray[index]['funderData']['fCity'] = serviceDetails.value;
                        setupdateFunderIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                        // setafterfilterdata(temporaryarray);
                    }
                });
            }
        }
    }, [props.validateSelfFunder]);

    const updateFData = (e: any) => {
        if (e.op === 'add') {
            let data = {
                contractDocumentId: 0,
                filePath: e.filedata.filePath,
                fileName: e.filedata.fileName,
                attachmentType: 1,
                isDelete: 0
            };

            let temporaryarray = JSON.parse(JSON.stringify(funderlistData));
            console.log(temporaryarray);
            // let temporaryarray = funderlistData.slice();
            if (DataPatientDraftInfo?.data?.isFromLeadPooling) {
                temporaryarray[e.i]['funderData']['attachments'].push(data);
            } else {
                temporaryarray[e.i]['funderData']['contractAttachmentModels'].push(data);
            }

            // temporaryarray[e.i]['funderData']['isFilePond'] = false;
            // temporaryarray[e.i]['funderData']['filepath'] = e.filedata.filePath;
            // temporaryarray[e.i]['funderData']['filename'] = e.filedata.fileName;
            // temporaryarray[e.i]['funderData']['funderContract'] = e.filedata.fileName;
            // setupdateFunderIndex(e.i);

            setfunderlistData(temporaryarray);
            props.updatedfunderlist(temporaryarray);
            // setafterfilterdata(temporaryarray);
        } else if (e.op === 'remove') {
            if (DataPatientDraftInfo !== '') {
                let data = {
                    contractDocumentId: e.data.contractDocumentId,
                    filePath: e.data.filePath,
                    fileName: e.data.fileName,
                    attachmentType: 1,
                    isDelete: 1,
                    pindex: e.i
                };
                props.deleteddoc(data);
                // setdeleteddoc([...deleteddoc,data])
                // let temporaryarray = funderlistData.slice();
                let temporaryarray = JSON.parse(JSON.stringify(funderlistData));
                if (DataPatientDraftInfo?.data?.isFromLeadPooling) {
                    temporaryarray[e.i]['funderData']['attachments'].splice(e.j, 1);
                } else {
                    temporaryarray[e.i]['funderData']['contractAttachmentModels'].splice(e.j, 1);
                }
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
                // setafterfilterdata(temporaryarray);
            } else {
                let temporaryarray = funderlistData.slice();
                if (DataPatientDraftInfo?.data?.isFromLeadPooling) {
                    temporaryarray[e.i]['funderData']['attachments'].splice(e.j, 1);
                } else {
                    temporaryarray[e.i]['funderData']['contractAttachmentModels'].splice(e.j, 1);
                }
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
                // setafterfilterdata(temporaryarray);
            }
        }
    };

    //   useEffect(()=>{
    //     if(deleteddoc.length > 0){

    //     }
    //   },[deleteddoc]);

    useEffect(() => {
        if (props.funderList?.length > 0) {
            setfunderlistData(props.funderList);
            if(afterfilterdata?.length > 0){
                setafterfilterdata(props.funderList);
            }
        } else {
            setfunderlistData([]);
        }
        if (props.funderList != undefined && props.funderList[0]?.isinvoicecreated === 1) {
            seteditable(false);
        } else {
            seteditable(true);
        }
    }, [props.funderList]);
    useEffect(() => {
        if (funderlistData.length === 0) {
            props.isadd(true);
        } else {
            props.isadd(false);
        }
    }, [funderlistData]);

    const updatefilepond = (i: number) => {
        let temporaryarray = funderlistData.slice();
        temporaryarray[i]['funderData']['isFilePond'] = true;
        temporaryarray[i]['funderData']['filepath'] = '';
        temporaryarray[i]['funderData']['funderContract'] = '';
        temporaryarray[i]['funderData']['filename'] = '';
        setupdateFunderIndex(i);
        setfunderlistData(temporaryarray);
        props.updatedfunderlist(temporaryarray);
        // setafterfilterdata(temporaryarray);
    };

    useEffect(() => {
        console.log(DataLeadInfo?.data?.contract);
    }, [DataLeadInfo]);

    const deleteFunder = (recordIndex: number, data: any) => {
        setupdateFunderIndex(-1);
        const deletedList = funderlistData.filter((data: any, index: number) => index !== recordIndex);
        setfunderlistData(deletedList);
        props.updatedfunderlist(deletedList);
        if (DataLeadInfo?.data?.contract?.length > 0) {
            let funderafterdelete = JSON.parse(JSON.stringify(DataLeadInfo));
            let patientdata = JSON.parse(JSON.stringify(datapatient));
            patientdata?.data?.contractDetails?.splice(recordIndex, 1);
            setdatapatinet(patientdata);
            dispatch(removeLeadInformationAction());
            funderafterdelete?.data?.contract?.splice(recordIndex, 1);
            dispatch(setLeadInformationAction(funderafterdelete));
        }
        let funderexists: any;
        if (data.ftype !== '4') {
            funderexists = DataPatientDraftInfo?.data?.contractDetails?.find((val: any) => {
                if (val.acdid === data.acdid) {
                    return val;
                }
            });
        } else if (data.ftype === '4') {
            funderexists = DataPatientDraftInfo?.data?.contractDetails?.find((val: any) => {
                if (val.acdid === data.acdid) {
                    return val;
                }
            });
        }

        props.deletefunderlist(funderexists);
        console.log(afterdeleteddata);
        if (DataPatientDraftInfo?.data?.contractDetails?.length > 0 && funderexists !== undefined) {
            var tmpdata: any = [];
            if (data.ftype !== '4') {
                DataPatientDraftInfo?.data?.contractDetails?.find((val: any, i: number) => {
                    console.log(val);
                    if (val.acdid !== 0) {
                        if (val?.acdid === funderexists?.acdid) {
                            tmpdata.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                            setafterdeleteddata({ ...afterdeleteddata, tmpdata });
                        }
                        afterfilterdata?.find((val: any, i: number) => {
                            if (val.acdid === funderexists?.acdid) {
                                var array = [...afterfilterdata];
                                array.splice(i, 1);
                                if (array.length === 0) {
                                    setafterfilterdata(null);
                                } else {
                                    setafterfilterdata(array);
                                }
                            }
                        });
                    } else {
                        if (val?.dacdId === funderexists?.dacdId) {
                            tmpdata.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                            setafterdeleteddata({ ...afterdeleteddata, tmpdata });
                        }
                        afterfilterdata?.find((val: any, i: number) => {
                            if (val.dacdid === funderexists?.dacdId) {
                                var array = [...afterfilterdata];
                                array.splice(i, 1);
                                if (array.length === 0) {
                                    setafterfilterdata(null);
                                } else {
                                    setafterfilterdata(array);
                                }
                            }
                        });
                    }
                });
            } else if (data.ftype === '4') {
                DataPatientDraftInfo?.data?.contractDetails?.find((val: any, i: number) => {
                    if (val?.acdid === funderexists?.acdid) {
                        tmpdata.push(DataPatientDraftInfo?.data?.contractDetails[i]);
                        setafterdeleteddata({ ...afterdeleteddata, tmpdata });
                    }
                });
                afterfilterdata?.find((val: any, i: number) => {
                    if (val.acdid === funderexists?.acdid) {
                        var array = [...afterfilterdata];
                        array.splice(i, 1);
                        setafterfilterdata(array);
                    }
                });
            }
        } else if (funderexists === undefined) {
            props.addnaddeletedata(data);
        }
    };

    const updateselffunder = async (index: number) => {
        let isexists: any = '';
        isexists = [...funderlistData].some((val: any, i: any) => {
            if (val.funderData.fFunder === props.selfData.FullName) {
                if (DataLeadInfo?.data?.contract[i]?.status !== '' && DataLeadInfo !== '') {
                    if (DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled') {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        });
        if (isexists) {
            if (funderlistData[index].funderData.fFunder !== props.selfData.FullName) {
                swal('Funder is already added', {
                    icon: 'warning',
                    dangerMode: true
                });

                let temporaryarray = funderlistData.slice();
                setfundertype(funderlistData[index].funderData.ftype);
                setTimeout(() => {
                    temporaryarray[index]['funderData']['fFunderVal'] = funderlistData[index].funderData.fFunderVal;
                    temporaryarray[index]['funderData']['fFunder'] = funderlistData[index].funderData.fFunder;
                    setupdateFunderIndex(index);
                    setcommisionerIndex(index);
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                    // setafterfilterdata(temporaryarray);
                }, 1000);
            }
        } else {
            let temporaryarray = funderlistData.slice();
            temporaryarray[index]['funderData']['fFunderVal'] = props.selfData.FullName;
            temporaryarray[index]['funderData']['fFunder'] = props.selfData.FullName;
            temporaryarray[index]['funderData']['fEmailId'] = props.selfData.EmailId;
            temporaryarray[index]['funderData']['fContactNo'] = props.selfData.ContactNo;
            temporaryarray[index]['funderData']['fAddress1'] = props.selfData.Address1;
            temporaryarray[index]['funderData']['fCity'] = props.selfData.City;
            temporaryarray[index]['funderData']['fPoBox'] = props.selfData.PoBox;
            temporaryarray[index]['funderData']['ftype'] = '4';

            setupdateFunderIndex(index);
            setfunderlistData(temporaryarray);
            props.updatedfunderlist(temporaryarray);
            // setafterfilterdata(temporaryarray);
        }
    };

    const getformval = (val: any) => {
        setformval(val);
    };
    async function priorsave() {
        setdone(false);
        // 
        
        swal({
            title: '',
            text: DataLeadInfo?.data?.contract[0].digitalContractId !== 0 ? 'Are you sure you want to update contract?' : 'Are you sure you want to save contract?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                let savepayload = {
                    digitalContractId:  DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId !== 0 ? DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId : 0,
                    admissionId: DataPatientDraftInfo?.data?.admissionDetail?.admissionId,
                    contractNo: '',
                    contractTypeId: 1,
                    contractCombinationId: formval?.contractCombinationId,
                    leadPoolingId: null,
                    dateOfCreation: formval?.newContractCreated !== null ? dayjs(formval?.newContractCreated).format("DD-MM-YYYY") : null,
                    effectiveFrom: formval?.newContractEffectiveFrom !== null ? dayjs(formval?.newContractEffectiveFrom).format("DD-MM-YYYY") : null,
                    effectiveTo: formval?.newContractEffectiveTo !== null ? dayjs(formval?.newContractEffectiveTo).format("DD-MM-YYYY") : null,
                    serviceUserName: formval?.serviceUserDetailName,
                    dateOfBirth: formval?.serviceUserDetailDob !== null ? dayjs(formval?.serviceUserDetailDob).format("DD-MM-YYYY") : null,
                    acdid: null,
                    nhsid: formval?.serviceUserDetailNHSId,
                    localId: formval?.serviceUserDetailClientId,
                    address1: formval?.serviceUserDetailAdd1,
                    address2: formval?.serviceUserDetailAdd2,
                    city: formval?.serviceUserDetailCountry,
                    zipCode: formval?.serviceUserDetailPostalCode,
                    scheduledDateOfAdmission: formval?.contractDetailSchedAddmissionDate !== null ? dayjs(formval?.contractDetailSchedAddmissionDate).format("DD-MM-YYYY") : null,
                    dischargeDate: formval?.contractDetailEstDischargeDate !== null ? dayjs(formval?.contractDetailEstDischargeDate).format("DD-MM-YYYY") : null,
                    dailyCoreFee: formval?.contractDetailCoreFee,
                    hourlyFee1: formval?.contractDetailHourlyFee1,
                    hourlyFee2: formval?.contractDetailHourlyFee2,
                    hourlyFee3: formval?.contractDetailHourlyFee3,
                    hourlyFee4: formval?.contractDetailHourlyFee4,
                    fncFee: formval?.contractDetailFNCFee,
                    continenceCareFee: formval?.contractDetailContinenceFee,
                    feesWeeklyOrDaily: Number(formval?.contractDetailFee),
                    status: 1,
                    funderListViewModel : formval?.contractDetailArray?.map((data:any,i:number)=>{
                       let finalcontract = {
                            acdId: DataPatientDraftInfo?.data?.contractDetails?.filter((el:any) => (el.docusignStatus !== 'Voided' && el.docusignStatus !== 'Cancelled'))[i].acdid,
                            funderId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderId,
                            feeShare: data?.contractDetailFeeShare,
                            commissioner: 'test',
                            commissionerEmail: data?.commissionerEmail,
                            commAddress: '',
                            commCity: '',
                            commZipCode: '',
                            commPhoneNo: data?.commissionerPhone,
                            funderName: data?.funderName,
                            funderEmail: data?.funderEmail,
                            funderAddress: data?.funderAdd,
                            funderCity: data?.funderCountry,
                            funderZipCode: data?.funderPostalCode,
                            funderPhoneNo: data?.funderContactNo,
                            socialWorkerEmail: data?.socialWorkerEmailId,
                            socialWorkerPhoneNo: data?.socialWorkerContactNo,
                            invoicePoint: data?.funderCopyInvoicePoint,
                            invEmail: data?.funderCopyEmail,
                            invAddress: data?.funderCopyAdd,
                            invCity: data?.funderCopyCountry,
                            invZipCode: data?.funderCopyPostalCode,
                            isSameAsFunder: data?.sameAsFunder ? 1 : 0,
                            invPhoneNo: data?.funderCopyContact,
                            localityId: null,
                            leadFunderDetailsId: null,
                            autoPayment: Number(data?.autoPayment),
                            invoiceSubmissionVia: Number(data?.invoiceSubmission),
                            NextOfKinPhoneNumber: data?.nextofkinContact,
                            NextOfKinEmail: data?.nextofkinEmail
                        }

                        return finalcontract;
                    })
                };

                const res = await saveContract(savepayload);

                if (res.status === true) {
                    swal(res.message, {
                        icon: 'success'
                    });
                    setdone(true);
                    let tmpleaddata = DataLeadInfo;
                    dispatch(removeLeadInformationAction());
                    const res1 = await getContractDataAdmission(DataPatientDraftInfo?.data?.admissionDetail?.admissionId);
                    const payload: any = { id: DataPatientDraftInfo?.data?.patientDetail?.patientId };

                    const resadmission = await getPatientReservedDataForContract(payload);
                    if (resadmission.status && afterdeleteddata?.tmpdata?.length > 0) {
                        for (let k = 0; k < resadmission?.data?.contractDetails?.length; k++) {
                            let funderexists = afterdeleteddata?.tmpdata?.find((val: any) => {
                                if (val.acdid === resadmission?.data?.contractDetails[k]?.acdid) {
                                    return val;
                                }
                            });
                            if (funderexists !== undefined) {
                                resadmission?.data?.contractDetails?.splice(k, 1);
                            }
                        }
                        setdatapatinet(resadmission);
                    } else {
                        setdatapatinet(resadmission);
                    }
                    if (res1.status && afterdeleteddata?.tmpdata?.length > 0) {
                        let records: any = [];
                        for (let j = 0; j < res1?.data?.contract?.length; j++) {
                            let funderexists = afterdeleteddata?.tmpdata?.find((val: any) => {
                                if (val.acdid === res1?.data?.contract[j]?.acdId) {
                                    return val;
                                }
                            });
                            if (funderexists !== undefined) {
                                res1?.data?.contract?.splice(j, 1);
                            }
                        }
                        console.log(res1);
                        dispatch(setLeadInformationAction(res1));
                    } else {
                        dispatch(setLeadInformationAction(res1));
                    }
                } else {
                    swal(res.message, {
                        icon: 'error'
                    });
                    setdone(false);
                }
            } else {
            }
        });
    }

    useEffect(() => {
        if (formval !== undefined) {
            priorsave();
        }
    }, [formval]);
    async function confirmcontract() {
        setdone(false);
        swal({
            title: '',
            text: 'Are you sure you want to confirm and send contract?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const res = await confirmContractadmission(DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.digitalContractId,
                DataPatientDraftInfo?.data?.contractDetails?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.acdid);
                if (res.status) {
                    swal(res.message, {
                        icon: 'success'
                    });
                    setdone(true);
                    dispatch(removeLeadInformationAction());
                    const res1 = await getContractDataAdmission(DataPatientDraftInfo?.data?.admissionDetail?.admissionId);
                    if (res1.status && afterdeleteddata?.tmpdata?.length > 0) {
                        for (let j = 0; j < res1?.data?.contract?.length; j++) {
                            let funderexists = afterdeleteddata?.tmpdata?.find((val: any) => {
                                if (val.acdid === res1?.data?.contract[j]?.acdId) {
                                    return val;
                                }
                            });
                            if (funderexists !== undefined) {
                                res1?.data?.contract?.splice(j, 1);
                            }
                        }
                        dispatch(setLeadInformationAction(res1));
                    } else {
                        dispatch(setLeadInformationAction(res1));
                    }
                } else {
                    swal(res.message, {
                        icon: 'error'
                    });
                    setdone(false);
                }
            }
        });
    }

    const updatecontractdata = (val: any) => {
        setafterfilterdata(val?.ListData);
    };

    const getindex = (val: any) => {
        setcindex(val);
    };

    useEffect(() => {
        props.updatedcontractdata({ ListData: afterfilterdata });
    }, [afterfilterdata]);

    useEffect(() => {
        if (DataPatientDraftInfo !== '') {
            setdatapatinet(DataPatientDraftInfo);
        }
    }, [DataPatientDraftInfo]);

    const getacdid = (val: any) => {
        setacdid(val);
    };

    return (
        <FunderView
            patientname={props.patientname}
            citylist={props.citylist}
            index={updateIndex}
            funderdlist={props.funderdlist}
            funderList={funderlistData}
            isadd={props.isadd}
            isdelete={deleteFunder}
            updateData={updateData}
            updateCData={updateCData}
            updateSData={updateSData}
            updatefilepond={updatefilepond}
            funderindex={updateFunderIndex}
            commisionerindex={commisionerindex}
            getfundelisterror={props.getfundelisterror}
            updateFData={updateFData}
            isCheck={isCheck}
            changetype={changetype}
            fundertype={fundertype}
            selfData={props.selfData}
            updateselffunder={updateselffunder}
            iseditable={iseditable}
            getformval={getformval}
            send={confirmcontract}
            isdone={isdone}
            cindex={getindex}
            contractdata={updatecontractdata}
            datapatient={datapatient}
            deletedfilterdata={afterdeleteddata}
            getacdid={getacdid}
            isReferralAdd={props.isReferralAdd}
            isDraftListAdd={props.isDraftListAdd}
        />
    );
};

export default FunderListContainer;
