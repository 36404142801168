import React from 'react';
import BarChartView from './BarChartView';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartContainer = (props: any) => {
  Chart.register(ChartDataLabels);
    const labels = props.invoiceStatistics?.graphCharts?.x;
    const data = {
        labels,
        datasets: [
          {
            label: "Unpaid",
            data: props.invoiceStatistics?.graphCharts?.unPaid,
            backgroundColor: "#f37b74"
          },
          {
            label: "Paid",
            data: props.invoiceStatistics?.graphCharts?.paid,
            backgroundColor: "#2ed883"
          }
        ]
      };
   
        const options = {
            responsive: true,
            hover: {mode: null},
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  boxWidth: 5,
                  boxHeight:5
                },
              },
              datalabels: {
                display: false,
            },
              labels: {
                display: false,
              },
              title: {
                display: false,
               
              }
            },
           
          };
    return (
        <div>
            <BarChartView  data={data} options={options} />
        </div>
    );
};

export default BarChartContainer;