import React, { useEffect, useState } from 'react';
import BedInformationView from './bedInformationView';
import { getBedInformationService, getPendingActivitiesService } from '../../../../service/dashboard-service';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import eventEmitter from '../../../../utils/eventEmitter';


interface IProps {}

const BedInformationContainer: React.FC<IProps> = () => {
    let [moreInformation, setMoreInformation] = useState<Boolean>(false);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const handleShowMoreInfo = () => {
        setMoreInformation(true);
    };
    const handleHideMoreInfo = () => {
        setMoreInformation(false);
    };

    useEffect(() => {
        async function getBedInformation() {
            const payload: any = { serviceId: DataServiceDetail.serviceId };
            getBedInformationService(payload);
            getPendingActivitiesService(payload);
        }
        if (StatusLogin === true) {
            getBedInformation();
        }
    }, [StatusLogin, DataLogin, StatusServiceDetail, DataServiceDetail]);

    const eventgetBedInformation = async() => {
        const payload: any = { serviceId: DataServiceDetail.serviceId };
        getBedInformationService(payload);
        getPendingActivitiesService(payload);
    }
    useEffect(() => {
        eventEmitter.on('eventGetBedInformation', eventgetBedInformation);
        return () => {
            eventEmitter.off('eventGetBedInformation', eventgetBedInformation)
        }
    });
    return <BedInformationView handleShowMoreInfo={handleShowMoreInfo} handleHideMoreInfo={handleHideMoreInfo} moreInformation={moreInformation} />;
};

export default BedInformationContainer;
