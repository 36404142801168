import React, {useState,useEffect} from 'react';
import AssessmentView from './AssessmentView';
import { getAssessmentDetails } from '../../../../../../service/assessment-service';
import { RootState } from '../../../../../../store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeAssessmentDetailsAction } from '../../../../../../store/assessment/getAssessmentDetailsReducer';

const AssessmentContainer = (props:any) => {
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const [assessmentId, setAssessmentId] = useState<any>();

    const dispatch = useDispatch()
    // useEffect(() => {
    //     if(StatusLeadInfo === true && DataLeadInfo?.data?.assessment?.length > 0) {            
    //         setAssessmentId(DataLeadInfo?.data?.assessment[0]?.assessmentId)
    //     }
    // }, [StatusLeadInfo, DataLeadInfo])
    // useEffect(() => {
    //     if(assessmentId === 0) {
    //         dispatch(removeAssessmentDetailsAction())
    //     }
    //     if(assessmentId !== 0) {
    //         const fetchAssessmentDetails = async() => {
    //             await getAssessmentDetails(assessmentId);
    //         }
    //         fetchAssessmentDetails()
    //     }
    // }, [assessmentId])
    return (
        <div>
            <AssessmentView refreshData={props.refreshData} referralid={props.referralid} finalStatus={props.finalStatus} nhs={props.nhs}/>
        </div>
    );
};

export default AssessmentContainer;