import React from 'react';
import SaveForLaterModalView from './SaveForLaterModalView';

const SaveForLaterModalContainer = () => {
    return (
        <div>
           <SaveForLaterModalView/>
        </div>
    );
};

export default SaveForLaterModalContainer;