import React from 'react';
import ServiceReferralMoreView from './ServiceReferralMoreView';

const ServiceReferralMoreContainer = (props: any) => {
    return (
        <div>
            <ServiceReferralMoreView referralOverView={props.referralOverView} SelectedStatus={props.SelectedStatus} CurrentStatus={props.CurrentStatus} 
            Frequency={props.Frequency} SelectedFrequency={props.SelectedFrequency}/>
        </div>
    );
};

export default ServiceReferralMoreContainer;