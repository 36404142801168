import React from 'react';
import GenerateContractView from './GenerateContractView';

const GenerateContractContainer = (props: any) => {
    return (
        <div>
            <GenerateContractView pname={props.pname} combinationId={props.combinationId} cdata={props.cdata} onClose={props.onClose} footer={props.footer} title={props.title} className={props.className} content={props.content} save={props.save} preview={props.preview} send={props.send} findex={props.findex} getval={props.getval}/>
        </div>
    );
};

export default GenerateContractContainer;