import React, { useEffect, useState } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SmartSearchDropdownContainer from '../SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import eventEmitter from '../../utils/eventEmitter';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { API_ENDPOINT, BASEURLDEV } from '../../service/ApiConstants';
import { store } from '../../store';
import swal from 'sweetalert';
import { deleteAssessmentDocument, getAssessmentDocument } from '../../service/leads';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    footer: any;
    className: any;
    reason: any;
    updateData: (e: any) => any;
    submitalert: () => any;
    mname: any;
    reasonSmartSearch: any;
    poolingid: any;
    getAttachments:any;
    referralid:any
}

const RejectView = (props: ModalProps) => {
    const { t } = useTranslation();
    const [submit, setsubmit] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);
    const [isError, setIsError] = useState<string | null>(null);
    const validationSchema = Yup.object().shape({
        reason: Yup.string().required(),
        desc: Yup.string().notRequired()
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;

    useEffect(() => {
        if (formState.isValid) {
            setsubmit(true);
        } else {
            setsubmit(false);
        }
    }, [formState]);

    useEffect(() => {
        if (files.length <= 5) {
            setIsError(null);
            props.getAttachments(files);
            eventEmitter.emit('eventSetLoader', false);
        }
    }, [files]);

    const removepdf = (e: any, path: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove the attachment?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: path };
                const response = await deleteAssessmentDocument(payload);
                if (response.status == true) {
                    setFiles((o: any) => o.filter((v: any) => v.documentPath !== path));
                } else {
                }
            }
        });
    };
    const openpdf = async (e: any, path: any) => {
        e.preventDefault();
        const payload = { path };
        const response = await getAssessmentDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    return (
        <div>
            <ModalWrapperContainer
                // title="Rejection"
                title={props.title}
                onClose={props.onClose}
                className="modal modal-extrasmall"
                content={
                    <div>
                        <label className="mb-2">{t('Reject_Modal.Reason')}</label>
                        {props.mname === 'plead' && (
                            <>
                                {props.reasonSmartSearch !== undefined && (
                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                        <SmartSearchDropdownContainer
                                            // defaultValue={cityid || undefined}
                                            smartSearchLabel={t('Reject_Modal.sReason')}
                                            errors={errors.City}
                                            onChangeSmartSearch={(value: any) => {
                                                const event = new Event('change');
                                                let obj = {
                                                    name: 'reason',
                                                    value: value?.id?.toString()
                                                };
                                                //@ts-ignore
                                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                setValue('reason', value?.id?.toString(), { shouldValidate: true });
                                                if (value !== '') {
                                                    props.updateData(event);
                                                }
                                            }}
                                            smartSearch={props.reasonSmartSearch}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {(props.mname === 'contract' || props.mname === 'admission') && (
                            <>
                                {props.reasonSmartSearch !== undefined && (
                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                        <SmartSearchDropdownContainer
                                            // defaultValue={cityid || undefined}
                                            smartSearchLabel={t('Reject_Modal.sReason')}
                                            errors={errors.City}
                                            onChangeSmartSearch={(value: any) => {
                                                const event = new Event('change');
                                                let obj = {
                                                    name: 'reason',
                                                    value: value?.id?.toString()
                                                };
                                                //@ts-ignore
                                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                setValue('reason', value?.id?.toString(), { shouldValidate: true });
                                                if (value !== '') {
                                                    props.updateData(event);
                                                }
                                            }}
                                            smartSearch={props.reasonSmartSearch}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {props.mname === 'referral' && (
                            <>
                                {props.reasonSmartSearch !== undefined && (
                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                        <SmartSearchDropdownContainer
                                            // defaultValue={cityid || undefined}
                                            smartSearchLabel={t('Reject_Modal.sReason')}
                                            errors={errors.City}
                                            onChangeSmartSearch={(value: any) => {
                                                const event = new Event('change');
                                                let obj = {
                                                    name: 'reason',
                                                    value: value?.id?.toString()
                                                };
                                                //@ts-ignore
                                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                setValue('reason', value?.id?.toString(), { shouldValidate: true });
                                                if (value !== '') {
                                                    props.updateData(event);
                                                }
                                            }}
                                            smartSearch={props.reasonSmartSearch}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {props.mname === 'skiprm' && (
                            <>
                                {props.reasonSmartSearch !== undefined && (
                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                        <SmartSearchDropdownContainer
                                            // defaultValue={cityid || undefined}
                                            smartSearchLabel={t('Reject_Modal.sReason')}
                                            errors={errors.City}
                                            onChangeSmartSearch={(value: any) => {
                                                const event = new Event('change');
                                                let obj = {
                                                    name: 'reason',
                                                    value: value?.id?.toString()
                                                };
                                                //@ts-ignore
                                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                setValue('reason', value?.id?.toString(), { shouldValidate: true });
                                                if (value !== '') {
                                                    props.updateData(event);
                                                }
                                            }}
                                            smartSearch={props.reasonSmartSearch}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {props.mname === 'skipsm' && (
                            <>
                                {props.reasonSmartSearch !== undefined && (
                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                        <SmartSearchDropdownContainer
                                            // defaultValue={cityid || undefined}
                                            smartSearchLabel={t('Reject_Modal.sReason')}
                                            errors={errors.City}
                                            onChangeSmartSearch={(value: any) => {
                                                const event = new Event('change');
                                                let obj = {
                                                    name: 'reason',
                                                    value: value?.id?.toString()
                                                };
                                                //@ts-ignore
                                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                setValue('reason', value?.id?.toString(), { shouldValidate: true });
                                                if (value !== '') {
                                                    props.updateData(event);
                                                }
                                            }}
                                            smartSearch={props.reasonSmartSearch}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {/* <select
                         {...register('reason', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'reason' as const}
                        id="reason"
                        //@ts-ignore
                        className={`form-control white ${errors.reason ? 'is-invalid' : ''}`}
                    >
                        <option value="">{t('Reject_Modal.sReason')}</option>
                        {props.mname === "plead" &&
                        <>
                        {props?.reason !== undefined &&
                            props?.reason?.map((resn: any) => {
                                return (
                                    <option key={resn.rrrId} value={resn.rrrId}>
                                        {resn.reasonText}
                                    </option>
                                );
                            })}
                        </>
                        }
                        {(props.mname === "contract" || props.mname === 'admission') &&
                        <>
                        {props?.reason !== undefined &&
                            props?.reason?.map((resn: any) => {
                                return (
                                    <option key={resn.id} value={resn.id}>
                                        {resn.name}
                                    </option>
                                );
                            })}
                        </>
                        }
                         {props.mname === "referral"  &&
                        <>
                        {props?.reason !== undefined &&
                            props?.reason?.map((resn: any) => {
                                return (
                                    <option key={resn.reasonId} value={resn.reasonId}>
                                        {resn.reasonText}
                                    </option>
                                );
                            })}
                        </>
                        }
                        
                    </select> */}
                        <label className="mb-2 mt-3">{t('Reject_Modal.Description')}</label>
                        {/* <textarea name="" id="" rows={3} className='form-control white'></textarea> */}
                        <textarea
                            {...register('desc', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'desc' as const}
                            className={`form-control white mb-3 ${errors.desc ? 'is-invalid' : ''}`}
                            placeholder="Description"
                            rows={3}
                        ></textarea>

                        {props.mname === 'skiprm' && (
                            <>
                                <div className="w-100">
                                    {files?.map((file: any) => (
                                        <div className="d-flex justify-content-between  mt-2 mb-2">
                                            <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Skip Assessment Document ">
                                                <textarea rows={1} value={file.documentName} onClick={(e: any) => openpdf(e, file.documentPath)} readOnly />
                                            </div>
                                            <div>
                                                <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.documentPath)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}

                                    <div id="penPictureUpload">
                                        <Controller
                                            control={control}
                                            name="AssessmentDocument"
                                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                return (
                                                    <FilePond
                                                        files={
                                                            files?.length <= 5 &&
                                                            files?.map((item: any) => ({
                                                                source: item.filename,
                                                                options: {
                                                                    type: 'local',
                                                                    file: {
                                                                        name: item.filename,
                                                                        size: 0
                                                                    }
                                                                }
                                                            }))
                                                        }
                                                        // @ts-ignore
                                                        allowFileSizeValidation={true}
                                                        maxFileSize={1000 * 1000 * 5}
                                                        allowFileTypeValidation={true}
                                                        acceptedFileTypes={[
                                                            'application/pdf',
                                                            'image/*',
                                                            'application/vnd.ms-excel',
                                                            'application/vnd.ms-powerpoint',
                                                            'application/msword',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                        ]}
                                                        
                                                        labelMaxFileSizeExceeded={'Total size of all the attachments cannot exceed 5 MB'}
                                                        fileValidateTypeLabelExpectedTypes={''}
                                                        name={'AssessmentDocument'}
                                                        labelIdle={`${t('VacantRightbar.pdftitle3')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                        allowMultiple
                                                        maxFiles={5}
                                                        maxParallelUploads={5}
                                                        beforeAddFile={(props) => {
                                                            eventEmitter.emit('eventSetLoader', true);
                                                            return true;
                                                        }}
                                                        // @ts-ignore
                                                        onerror={(props:any) => {
                                                            
                                                            if(props.main === "File size too large"){
                                                                eventEmitter.emit('eventSetLoader', false);
                                                                setIsError("Total size of all the attachments cannot exceed 5 MB")
                                                            } else if(props.main === "File is of invalid type"){
                                                                eventEmitter.emit('eventSetLoader', false);
                                                                setIsError(props.main)
                                                            }
                                                        }}
                                                        onwarning={(error) => {
                                                            if (error.body === 'Max files') {
                                                                setIsError('Maximum only 5 attachments are allowed');
                                                            }
                                                        }}
                                                        server={{
                                                            url: `${BASEURLDEV}${API_ENDPOINT.UploadAssessmentdoc}`,
                                                            process: {
                                                                headers: {
                                                                    Authorization: `Bearer ${token}`
                                                                },
                                                                ondata: (formData) => {
                                                                    formData.append('LeadPoolingDetails_Id', props.poolingid);
                                                                    return formData;
                                                                },
                                                                //@ts-ignore
                                                                onload: (res: any) => {
                                                                    
                                                                    if (files?.length === 5) {
                                                                        setIsError('Maximum only 5 attachments are allowed');
                                                                        eventEmitter.emit('eventSetLoader', false);
                                                                        return;
                                                                    }

                                                                    const fileres = JSON.parse(res);
                                                                    if(fileres.status){
                                                                        setIsError('');
                                                                    }
                                                                    let filedata = fileres.data;
                                                                    setFiles((o: any) => [
                                                                        ...o,
                                                                        {
                                                                            documentPath: filedata.documentPath,
                                                                            documentName: filedata.documentName
                                                                        }
                                                                    ]);
                                                                    // props.updateFData({
                                                                    //     leadPenPicture_Id: filedata.leadPenPicture_Id,
                                                                    //     filepath: filedata.documentPath,
                                                                    //     filename: filedata.documentName,
                                                                    // });

                                                                    setIsError(null);
                                                                }
                                                            },
                                                            remove: (source, load, error) => {
                                                                files?.length <= 5 && setIsError(null);
                                                                error('oh my goodness');
                                                                eventEmitter.emit('eventSetLoader', false);
                                                                load();
                                                            }
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                {isError && <p className="text-danger ps-1">{isError}</p>}
                                <p className="small mt-2">
                                    <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note3')}
                                </p>
                                <p className="small mt-2">
                                <strong>{t('VacantRightbar.note')}</strong> {t('SM_Assessment.Notes')}
                                    </p>
                            </>
                        )}
                    </div>
                }
                footer={
                    <div>
                        <button disabled={!submit} onClick={props.submitalert} className="btn btn-primary">
                            {t('Reject_Modal.Submit_btn')}
                        </button>
                    </div>
                }
            />
        </div>
    );
};

export default RejectView;
