import React, { useState, useEffect } from 'react';
import CostingView from './CostingView';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { RootState } from '../../../../../store';
import { getViewCosting, SaveCosting } from '../../../../../service/costing';
import { useTranslation } from 'react-i18next';

const CostingContainer = (props:any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const costingDetails = DataLeadInfo?.data?.costingList?.find((data: any) => data.serviceId == DataServiceDetail.serviceId);    
    const [costingData, setCostingData] = useState<any>('');
    let [iseditable, seteditable] = useState<boolean>(false); 

    const updateData = (e: any) => {
        setCostingData({
            ...costingData,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        async function getCosting() {
            const result = await getViewCosting(costingDetails.rcId);
            if (result.status == true && result.data !== undefined && result.data !== null) {
                setCostingData({
                    ...costingData,
                    'rcId': result.data?.rcId,
                    'loadPoolingId': result.data?.loadPoolingId,

                    'serviceUserName': result.data?.serviceUserName,
                    'serviceUserId': result.data?.serviceUserId,
                    'serviceLocation': result.data?.serviceLocation ?? costingDetails?.serviceLocation,

                    'accommodation': result.data.accommodation,
                    'baselineStaffCost': result.data.baselineStaffCost,
                    'enhancedStaffingCost': result.data?.enhancedStaffingCost,
                    'mdt': result.data?.mdt,
                    'transport': result.data?.transport,
                    'managementAndAdministration': result.data?.managementAndAdministration,
                    'total': result.data?.total,
                    'serviceTypeName': result.data?.serviceTypeName,
                    costingLists : result.data?.costingLists,
                    leadEditable : DataLeadInfo?.data?.leadEditable
                });
                seteditable(result.data?.rcId > 0 ? true : false);
            }
        }
        if (costingDetails != null && costingDetails.rcId != null) {
            getCosting();
        } else {
            setCostingData({
                ...costingData,
                'loadPoolingId': costingDetails?.leadPoolingId,
                'serviceUserName': DataLeadInfo?.data?.serviceUserName,
                'serviceUserId': DataLeadInfo?.data?.serviceUserId,
                'serviceLocation': costingDetails?.serviceLocation,
                'accommodation': 0,
                'baselineStaffCost': 0,
                'enhancedStaffingCost': 0,
                'mdt': 0,
                'transport': 0,
                'managementAndAdministration': 0,
                costingLists : [],
                leadEditable : DataLeadInfo?.data?.leadEditable
            });
        }
    }, [costingDetails]);

    async function addCosting(obj: any,additionalvalues:any) {        
        let costobj = {
            "isRevisionStatus": obj?.isRevisionStatus || false,
            "isRevised": obj?.isRevised || false,
            "rcId": obj?.rcId || 0,
            "loadPoolingId": obj?.loadPoolingId || 0,
            "serviceUserName": obj?.serviceUserName || '',
            "serviceUserId": obj?.serviceUserId || '',
            "serviceLocation": obj?.serviceLocation || '',
            "accommodation": obj?.accommodation || 0,
            "baselineStaffCost": obj?.baselineStaffCost || 0,
            "enhancedStaffingCost": obj?.enhancedStaffingCost || 0,
            "mdt": obj?.mdt || 0,
            "transport": obj?.transport || 0,
            "managementAndAdministration": obj?.managementAndAdministration || 0,
            "total": obj?.total?.toFixed(2) || 0,
            costingLists: additionalvalues
        }
        const res = await SaveCosting(costobj);

        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            props.refreshData(4);            
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

   
    return (
        <div>
            <CostingView updateData={updateData} costingData={costingData} iseditable={iseditable} saveCosting={addCosting} costingDetails={costingDetails} referralid={props.referralid}  />
        </div>
    );
};

export default CostingContainer;