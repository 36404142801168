
import React from 'react';
import PatientInquiryTabView from './PatientInquiryTabView';
import { PropsType } from '../types';

const PatientInquiryTabContanier = (props: PropsType) => {
    return (
        <div>
            <PatientInquiryTabView {...props} />
        </div>
    );
};

export default PatientInquiryTabContanier;