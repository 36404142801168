import React, { useState, useRef, useEffect } from 'react';
// import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import ModalWrapperAssessmentContainer from '../ModalWrapperAssessment/indexContainer';
import GeneralInfoContainer from './GeneralInfo/GeneralInfoContainer';
import ProgressNoteContainer from './ProgressNote/ProgressNoteContainer';
import ServiceUserProfileContainer from './ServiceUserProfile/ServiceUserProfileContainer';
import General_Info from '../../assets/images/general-info.svg';
import Progress_Note from '../../assets/images/progress-note.svg';
import Info_gethering from '../../assets/images/Info-gathering.svg';
import Strength from '../../assets/images/strength.svg';
import Capacity from '../../assets/images/capacity.svg';
import Care_Giver from '../../assets/images/Care-Giver.svg';
import Next from '../../assets/images/next.svg';
import Profile from '../../assets/images/Profile.svg';
import StrengthAssessmentContainer from './StrengthAssessment/StrengthAssessmentContainer';
import AdditionalInfoContainer from './AdditionalInfo/AdditionalInfoContainer';
import SupportWorkerContainer from './SupportWorker/SupportWorkerContainer';
import WhatNextContainer from './WhatNext/WhatNextContainer';
import DiversityProfileContainer from './DiversityProfile/DiversityProfileContainer';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
import { saveAssessmentDetailService } from '../../service/assessment-service';
import { AssessmentStatus } from '../../utils/constant';

import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import eventEmitter from '../../utils/eventEmitter';

import ReactToPrint from 'react-to-print';
import PrintAssessmentContainer from '../PrintAssessment/PrintAssessmentContainer';

// interface ModalProps {
//     onClose: () => any;
//     title: string;
//     content: any;
//     footer: any;
//     className: any;
// }

const AssessmentModalView = (props: any) => {
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    
    const [generalInfoValid, setGeneralInfoValid] = useState<any>(false);
    const [progressNoteValid, setProgressNoteValid] = useState<any>(false);
    const [serviceUserProfileValid, setServiceUserProfileValid] = useState<any>(false);
    const [strengthAssessmentValid, setStrengthAssessmentValid] = useState<any>(false);
    const [additionalInfoValid, setAdditionalInfoValid] = useState<any>(false);
    const [supportWorkerValid, setSupportWorkerValid] = useState<any>(false);
    const [whatNextValid, setWhatNextValid] = useState<any>(false);
    const [diversityProfileValid, setDiversityProfileValid] = useState<any>(false);
    const [disableButton, setDisableButton] = useState<any>(false);
    const [savePayload, setSavePayload] = useState<any>({
        generalInformation: '',
        assessmentProgressNoteModel: '',
        assessmentServiceUserProfileModel: '',
        assessmentStrengthsBasedAsmtModel: '',
        assessmentCapacityLegalOrderModel: '',
        assessmentSupportWorkerModel: '',
        assessmentWhatHappeensNextModel: '',
        assessmentServiceUserDiversityProfileModel: ''
    });

    const generalInfoFormValid = (data: any) => {
        setGeneralInfoValid(data);
    };
    const progressNoteFormValid = (data: any) => {
        setProgressNoteValid(data);
    };
    const serviceUserProfileFormValid = (data: any) => {
        setServiceUserProfileValid(data);
    };
    const strengthAssessmentFormValid = (data: any) => {
        setStrengthAssessmentValid(data);
    };
    const additionalInfoFormValid = (data: any) => {
        setAdditionalInfoValid(data);
    };
    const supportWorkerFormValid = (data: any) => {
        setSupportWorkerValid(data);
    };
    const whatNextFormValid = (data: any) => {
        setWhatNextValid(data);
    };
    const diversityProfileFormValid = (data: any) => {
        setDiversityProfileValid(data);
    };

    const generalInfoDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.generalInformation = {
            assessmentId: props?.assessmentDetails?.generalInformation?.formData?.assessmentId ? props?.assessmentDetails?.generalInformation?.formData?.assessmentId : 0,
            assessmentStatus: 1,
            leadPoolingId: props.leadPoolingId,
            name: data.name,
            relationshipWithReferedPerson: data.relationshipWithReferedPerson,
            telephoneNo: data.telephoneNo,
            email: data?.email?.trim(),
            fullName: data.fullName,
            title: data.title,
            preferredName: data.preferredName,
            currentAddress: data.currentAddress,
            telephoneNoHome: data.telephoneNoHome,
            telephoneNoOther: data.telephoneNoOther,
            supportedPersonEmail: data?.supportedPersonEmail?.trim(),
            supportedPersonGender: data.supportedPersonGender,
            supportedPersonDateOfBirth: (data.supportedPersonDateOfBirth !== '' && data.supportedPersonDateOfBirth !== undefined && data.supportedPersonDateOfBirth !== null) ? window.$utils.dateFormatDDMMYYYY(dayjs(data.supportedPersonDateOfBirth).format('YYYY-MM-DD')) : '',
            supportedPersonNhsnumber: data.supportedPersonNhsnumber,
            preferredMethodOfCommunication: data.preferredMethodOfCommunication,
            nextOfKinRelationship: data.nextOfKinRelationship,
            nextOfKinAddress: data.nextOfKinAddress,
            nextOfKinEmail: data?.nextOfKinEmail?.trim(),
            nextOfKinTelephoneNoHome: data.nextOfKinTelephoneNoHome,
            nextOfKinTelephoneNoOther: data.nextOfKinTelephoneNoOther,
            ifNoNextOfKinRelationship: data.ifNoNextOfKinRelationship,
            ifNoNextOfKinAddress: data.ifNoNextOfKinAddress,
            ifNoNextOfKinEmail: data?.ifNoNextOfKinEmail?.trim(),
            ifNoNextOfKinTelephoneNoHome: data.ifNoNextOfKinTelephoneNoHome,
            ifNoNextOfKinTelephoneNoOther: data.ifNoNextOfKinTelephoneNoOther,
            socialWorkerAddress: data.socialWorkerAddress,
            socialWorkerEmail: data?.socialWorkerEmail?.trim(),
            socialWorkerTelephone: data.socialWorkerTelephone,
            gpaddress: data.gpaddress,
            gpemail: data?.gpemail?.trim(),
            gptelephone: data.gptelephone,
            anAssesmentFromLocalAuthorityOrOther: data.anAssesmentFromLocalAuthorityOrOther,
            otherProfessionalName: data.otherProfessionalName,
            otherProfessionalWorkBase: data.otherProfessionalWorkBase,
            otherProfessionalEmail: data?.otherProfessionalEmail?.trim(),
            otherProfessionalTelephone: data.otherProfessionalTelephone,
            fundingLocalAuthority: data.fundingLocalAuthority,
            fundingChc: data.fundingChc,
            fundingPersonalBudget: data.fundingPersonalBudget,
            isThisJointyFundingPackage: data.isThisJointyFundingPackage,
            riskFectors: data.riskFectors,
            doesThePersonHavePrimaryDiagnosis: data.doesThePersonHavePrimaryDiagnosis,
            areThereAnyOtherHealthCondition: data.areThereAnyOtherHealthCondition,
            positiveBehaviourSupport: data.positiveBehaviourSupport,
            currentPlan: data.currentPlan,
            isPbsinvolvementRequiredForAssessment: data.isPbsinvolvementRequiredForAssessment,
            personConcentTotheAssessment: data.personConcentTotheAssessment,
            personLacksCapicity: data.personLacksCapicity,
            howHaveYouHeardOf: data.howHaveYouHeardOf,
            nameOfPersonCompletingGeneralInformation: data.nameOfPersonCompletingGeneralInformation
        };
        setSavePayload(savePayloadTemp);
    };
    const progressNoteDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        let detailsModels: any = [];
        if (data?.assessmentProgressNotesDetailModels?.length > 0) {
            data.assessmentProgressNotesDetailModels.map((val: any) => {
                detailsModels.push({
                    apndId: val.apndId || 0,
                    apnId: val.apnId || 0,
                    progressNoteDate: val.progressNoteDate !== null && val.progressNoteDate !== undefined ? window.$utils.dateFormatDDMMYYYY(dayjs(val.progressNoteDate).format('YYYY-MM-DD')) : '',
                    comments: val.comments || '',
                    staffInitials: val.staffInitials || ''
                });
            });
        }
        savePayloadTemp.assessmentProgressNoteModel = {
            apnId: data.apnId || 0,
            addedToReferralsLog: data.addedToReferralsLog || '',
            progressToAssessment: data.progressToAssessment || '',
            dateAdded: data.dateAdded ? window.$utils.dateFormatDDMMYYYY(data.dateAdded) : '',
            reason: data.reason || '',
            assessmentProgressNotesDetailModels: detailsModels
        };
        setSavePayload(savePayloadTemp);
    };
    const serviceUserProfileDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.assessmentServiceUserProfileModel = {
            ...data,
            asupId: data.asupId
        };
        setSavePayload(savePayloadTemp);
    };
    const strengthAssessmentDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.assessmentStrengthsBasedAsmtModel = {
            asbaId: data.asbaId,
            isEnglishYourFirstLanguage: data.isEnglishYourFirstLanguage,
            doYouUseGestures: data.doYouUseGestures,
            doYouUseAnyAssistiveTechnology: data.doYouUseAnyAssistiveTechnology,
            howWellDoYouUnderstandVerbal: data.howWellDoYouUnderstandVerbal,
            howWellDoYouUnderstandWrittenInformation: data.howWellDoYouUnderstandWrittenInformation,
            whatIsImportantForUsToKnowToSupportYouWithYourCommunication: data.whatIsImportantForUsToKnowToSupportYouWithYourCommunication,
            areThereAnyBehavioursOfConcernInRelation: data.areThereAnyBehavioursOfConcernInRelation,
            doesThisPersonRequireInputFromThePbsTeam: data.doesThisPersonRequireInputFromThePbsTeam,
            howManyHoursPerWeek: data.howManyHoursPerWeek,
            areYouIndependentWithThis1: data.areYouIndependentWithThis1,
            yourFamilyHelpWithThis1: data.yourFamilyHelpWithThis1,
            anotherHelpsWithThis1: data.anotherHelpsWithThis1,
            wouldYouLikeToSupportYouWithYourCommunication: data.wouldYouLikeToSupportYouWithYourCommunication,
            cooking: data.cooking,
            shopping: data.shopping,
            cleaning: data.cleaning,
            laundry: data.laundry,
            tidying: data.tidying,
            oralCare: data.oralCare,
            knowingTimeOfDayNight: data.knowingTimeOfDayNight,
            doYouHaveAnyGoalsYouWishToAchieveInThisArea: data.doYouHaveAnyGoalsYouWishToAchieveInThisArea,
            areThereAnyRisksWhenUndertakingTheseActivities1: data.areThereAnyRisksWhenUndertakingTheseActivities1,
            areYouIndependentWithThis2: data.areYouIndependentWithThis2,
            yourFamilyHelpWithThis2: data.yourFamilyHelpWithThis2,
            anotherHelpsWithThis2: data.anotherHelpsWithThis2,
            wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities: data.wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities,
            mobilityAndEquipmentRequired: data.mobilityAndEquipmentRequired,
            dressingUndressing: data.dressingUndressing,
            washingBathing: data.washingBathing,
            nutritionalCare: data.nutritionalCare,
            continenceCare: data.continenceCare,
            areThereAnyRisksWhenUndertakingTheseActivities2: data.areThereAnyRisksWhenUndertakingTheseActivities2,
            areYouIndependentWithThis3: data.areYouIndependentWithThis3,
            yourFamilyHelpWithThis3: data.yourFamilyHelpWithThis3,
            anotherHelpsWithThis3: data.anotherHelpsWithThis3,
            wouldYouLikeToSupportYouWithYourPersonalCare: data.wouldYouLikeToSupportYouWithYourPersonalCare,
            dateOfLastKnownGpVisit: data.dateOfLastKnownGpVisit !== undefined && data.dateOfLastKnownGpVisit !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.dateOfLastKnownGpVisit).format('YYYY-MM-DD')) : '',
            dateOfLastKnownGpVisitNa: data.dateOfLastKnownGpVisitNa === true ? 1 : 0,
            dateOfLastKnownDentistAppointment: data.dateOfLastKnownDentistAppointment !== undefined && data.dateOfLastKnownDentistAppointment !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.dateOfLastKnownDentistAppointment).format('YYYY-MM-DD')) : '',
            dateOfLastKnownDentistAppointmentNa: data.dateOfLastKnownDentistAppointmentNa === true ? 1 : 0,
            dateOfLastKnownChiropodyAppointment: data.dateOfLastKnownChiropodyAppointment !== undefined && data.dateOfLastKnownChiropodyAppointment !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.dateOfLastKnownChiropodyAppointment).format('YYYY-MM-DD')) : '',
            dateOfLastKnownChiropodyAppointmentNa: data.dateOfLastKnownChiropodyAppointmentNa === true ? 1 : 0,
            dateOfLastKnownOpticiansAppointment: data.dateOfLastKnownOpticiansAppointment !== undefined && data.dateOfLastKnownOpticiansAppointment !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.dateOfLastKnownOpticiansAppointment).format('YYYY-MM-DD')) : '',
            dateOfLastKnownOpticiansAppointmentNa: data.dateOfLastKnownOpticiansAppointmentNa === true ? 1 : 0,
            datesOfKnownFalls: data.datesOfKnownFalls !== undefined && data.datesOfKnownFalls !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.datesOfKnownFalls).format('YYYY-MM-DD')) : '',
            datesOfKnownFallsNa: data.datesOfKnownFallsNa === true ? 1 : 0,
            isEndOfLifePlanningBeingConsideredInPlace: data.isEndOfLifePlanningBeingConsideredInPlace,
            doYouHaveAnAnnualHealthCheck: data.doYouHaveAnAnnualHealthCheck,
            doYouHaveAhealthActionPlanPassport: data.doYouHaveAhealthActionPlanPassport,
            isAnAnticipatoryCareCalendarRequired: data.isAnAnticipatoryCareCalendarRequired,
            doYouRequireAnySpecialistEquipment: data.doYouRequireAnySpecialistEquipment,
            doYouRequireAnySpecialistEquipmentComment: data.doYouRequireAnySpecialistEquipmentComment,
            areYouIndependentWithThis4: data.areYouIndependentWithThis4,
            yourFamilyHelpWithThis4: data.yourFamilyHelpWithThis4,
            anotherHelpsWithThis4: data.anotherHelpsWithThis4,
            wouldYouLikeToSupportYouToStayHealthyAndWell: data.wouldYouLikeToSupportYouToStayHealthyAndWell,
            whoAndWhatIsImportantToYou: data.whoAndWhatIsImportantToYou,
            whatHobbiesDoYouHave: data.whatHobbiesDoYouHave,
            whatDoYouGetTheMostEnjoymentOutOf: data.whatDoYouGetTheMostEnjoymentOutOf,
            canYouTravelIndependently: data.canYouTravelIndependently,
            howDoYouStayInTouchWithFamilyAndFriends: data.howDoYouStayInTouchWithFamilyAndFriends,
            areThereAnyRisksWhenUndertakingTheseActivities3: data.areThereAnyRisksWhenUndertakingTheseActivities3,
            areYouIndependentWithThis5: data.areYouIndependentWithThis5,
            yourFamilyHelpWithThis5: data.yourFamilyHelpWithThis5,
            anotherHelpsWithThis5: data.anotherHelpsWithThis5,
            wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing: data.wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing,
            areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing: data.areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing,
            doYouGetWorriedOrAnxious: data.doYouGetWorriedOrAnxious,
            whatCanTriggerFeelingsOfAnxiety: data.whatCanTriggerFeelingsOfAnxiety,
            whatHelpsYouToFeelBetter: data.whatHelpsYouToFeelBetter,
            isThereAnythingYouWishUsToBeMadeAwareOf: data.isThereAnythingYouWishUsToBeMadeAwareOf,
            areThereAnyRisksWhenUndertakingTheseActivities4: data.areThereAnyRisksWhenUndertakingTheseActivities4,
            areYouIndependentWithThis6: data.areYouIndependentWithThis6,
            yourFamilyHelpWithThis6: data.yourFamilyHelpWithThis6,
            anotherHelpsWithThis6: data.anotherHelpsWithThis6,
            wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing: data.wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing,
            detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor: data.detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor,
            howIsYourMedicationCurrentlyManaged: data.howIsYourMedicationCurrentlyManaged,
            whatLevelOfSupportDoYouNeedWithYourMedication: data.whatLevelOfSupportDoYouNeedWithYourMedication,
            areThereAnyRisksWhenUndertakingTheseActivities5: data.areThereAnyRisksWhenUndertakingTheseActivities5,
            iamIndependentWithThis: data.iamIndependentWithThis,
            yourFamilyHelpWithThis7: data.yourFamilyHelpWithThis7,
            anotherHelpsWithThis7: data.anotherHelpsWithThis7,
            wouldYouLikeToSupportYouToManageAndTakeYourMedication: data.wouldYouLikeToSupportYouToManageAndTakeYourMedication,
            whereWouldYouPreferToLive: data.whereWouldYouPreferToLive,
            doYouNeedSupportWithYourBenefits: data.doYouNeedSupportWithYourBenefits,
            doYouNeedAnySupportWithManagingYourTenancyAgreement: data.doYouNeedAnySupportWithManagingYourTenancyAgreement,
            whatSupportDoYouNeedForYourFinances: data.whatSupportDoYouNeedForYourFinances,
            areYouAbleToAccessYourBankingWithOrWithoutSupport: data.areYouAbleToAccessYourBankingWithOrWithoutSupport,
            areThereAnyRisksWhenUndertakingTheseActivities6: data.areThereAnyRisksWhenUndertakingTheseActivities6,
            youAreIndependentWithThis: data.youAreIndependentWithThis,
            yourFamilyHelpWithThis8: data.yourFamilyHelpWithThis8,
            anotherHelpsWithThis8: data.anotherHelpsWithThis8,
            supportYouToDevelopSkillsToManageYourHousingAndFinances: data.supportYouToDevelopSkillsToManageYourHousingAndFinances
        };
        setSavePayload(savePayloadTemp);
    };
    const additionalInfoDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.assessmentCapacityLegalOrderModel = {
            ...data,
            acloId: data.acloId
        };
        setSavePayload(savePayloadTemp);
    };
    const supportWorkerDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        let supportData: any = [];
        if (data?.assessmentSupportRequiredModels?.length > 0) {
            data.assessmentSupportRequiredModels.map((val: any) => {
                supportData.push({
                    supportRequiredId: val.supportRequiredId,
                    days: val.days,
                    am: val.am,
                    pm: val.pm,
                    night: val.night
                });
            });
        }
        // savePayloadTemp.assessmentSupportRequiredModels = supportData

        savePayloadTemp.assessmentSupportWorkerModel = {
            aswId: data.aswId,
            whatKindOfPersonWouldYouLikeToSupportYou: data.whatKindOfPersonWouldYouLikeToSupportYou,
            skillsNeeded: data.skillsNeeded,
            desirableCharacteristicsPersonality: data.desirableCharacteristicsPersonality,
            interests: data.interests,
            consentForKeepingAndSharingInformationObtained: data.consentForKeepingAndSharingInformationObtained,
            assessmentSupportRequiredModels: supportData
        };
        setSavePayload(savePayloadTemp);
    };
    const whatNextDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.assessmentWhatHappeensNextModel = {
            awhnId: data.awhnId,
            informThePersonOfTheNextStages: data.informThePersonOfTheNextStages,
            areThereAnySpecialistTrainingRequirements: data.areThereAnySpecialistTrainingRequirements,
            assessmentCompletedByName: data.assessmentCompletedByName,
            assessmentCompletedBySignature: data.assessmentCompletedBySignature,
            assessmentCompletedByPosition: data.assessmentCompletedByPosition,
            assessmentCompletedByPositionNa: data.assessmentCompletedByPositionNa ? 1 : 0,
            assessmentCompletedByDate: data.assessmentCompletedByDate !== undefined && data.assessmentCompletedByDate !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.assessmentCompletedByDate).format('YYYY-MM-DD')) : '',
            personName: data.personName,
            personSignature: data.personSignature,
            personPosition: data.personPosition,
            personPositionNa: data.personPositionNa ? 1 : 0,
            personDate: data.personDate !== undefined && data.personDate !== null ? window.$utils.dateFormatDDMMYYYY(dayjs(data.personDate).format('YYYY-MM-DD')) : ''
        };
        setSavePayload(savePayloadTemp);
    };
    const diversityProfileDataChange = (data: any) => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.assessmentServiceUserDiversityProfileModel = {
            asudpId: data.asudpId,
            ethnicOriginId: (data.ethnicOriginId === undefined || data.ethnicOriginId === '' || data.ethnicOriginId === null) ? null : Number(data.ethnicOriginId),
            religionId: (data.religionId === undefined || data.religionId === '' || data.religionId === null) ? null : Number(data.religionId),
            otherReligion: (data.otherReligion === undefined || data.otherReligion === '' || data.otherReligion === null) ? null : data.otherReligion,
            isEnglishYourFirstLanguage: (data.isEnglishYourFirstLanguage === undefined || data.isEnglishYourFirstLanguage === '' || data.isEnglishYourFirstLanguage === null) ? null : Number(data.isEnglishYourFirstLanguage),
            languageName: (data.languageName === undefined || data.languageName === ''  || data.languageName === null) ? null : data.languageName,
            ageGroupId: (data.ageGroupId === undefined || data.ageGroupId === ''  || data.ageGroupId === null) ? null : Number(data.ageGroupId),
            sexualityPreferenceId: (data.sexualityPreferenceId === undefined || data.sexualityPreferenceId === ''  || data.sexualityPreferenceId === null) ? null : Number(data.sexualityPreferenceId),
            doYouHaveAdisability: (data.doYouHaveAdisability === undefined || data.doYouHaveAdisability === '' || data.doYouHaveAdisability === null) ? null : Number(data.doYouHaveAdisability)
        };
        setSavePayload(savePayloadTemp);
    };

    const onCLickHandlerSubmitAssessment = async () => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.generalInformation.assessmentStatus = 2;
        const response = await saveAssessmentDetailService(savePayloadTemp);
        if (response.status === true) {
            if (Number(response.data) > 0) {
                eventEmitter.emit('onSaveOrSubmitAssessment', response.data);
            }
            props.closePopup();
            swal(response.message, {
                icon: 'success'
            });
        } else {
            swal(response.message, {
                icon: 'error',
                dangerMode: true
            });
        }
    };

    const onCLickHandlerSaveAssessment = async () => {
        let savePayloadTemp = savePayload;
        savePayloadTemp.generalInformation.assessmentStatus = 1;
        const response = await saveAssessmentDetailService(savePayloadTemp);
        if (response.status === true) {
            if (Number(response.data) > 0) {
                eventEmitter.emit('onSaveOrSubmitAssessment', response.data);
            }
            props.closePopup();
            swal(response.message, {
                icon: 'success'
            });
        } else {
            swal(response.message, {
                icon: 'error',
                dangerMode: true
            });
        }
    };

    useEffect(() => {
        if (generalInfoValid && progressNoteValid && serviceUserProfileValid && strengthAssessmentValid && additionalInfoValid && supportWorkerValid && whatNextValid && diversityProfileValid) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [generalInfoValid, progressNoteValid, serviceUserProfileValid, strengthAssessmentValid, additionalInfoValid, supportWorkerValid, whatNextValid, diversityProfileValid]);

    const componentRef = useRef<HTMLDivElement>(null);
    let [print, setprint] = useState<boolean>(false);
    return (
        <div>
            <ModalWrapperAssessmentContainer
                title="Assessment Form"
                onClose={props.onClose}
                className="modal modal-large modal-assessment"
                content={
                    <div>
                        {DataRoleSelected.roleName !== 'Service Manager' && (
                            <div className="text-right modal-top-button">
                                <ReactToPrint
                                    trigger={() => (
                                        <button className="btn btn-white bold btn-sm " data-tooltip-id="my-tooltip-assessment" data-tooltip-content="Print/Download Assessment">
                                            {' '}
                                            <i className="fa-sharp fa-solid fa-download"></i> Print/Download
                                        </button>
                                    )}
                                    content={() => componentRef.current}  
                                    onBeforePrint={() => { document.title = `Assessment_${props.referralid}_${props.serviceName}`; }}
                                    // onAfterPrint={() => { document.title = 'Test2'; }}
                                />
                            </div>
                        )}

                        <div className='d-flex mt-3'>
                            <div  className="quickaccess-container">
                                <GeneralInfoContainer orgName={props.orgName} generalInfoFormValid={generalInfoFormValid} generalInfoDataChange={generalInfoDataChange} assessmentDetails={props.assessmentDetails} />
                                <ProgressNoteContainer orgName={props.orgName} progressNoteFormValid={progressNoteFormValid} progressNoteDataChange={progressNoteDataChange} assessmentDetails={props.assessmentDetails} />
                                <ServiceUserProfileContainer
                                    orgName={props.orgName}
                                    serviceUserProfileFormValid={serviceUserProfileFormValid}
                                    serviceUserProfileDataChange={serviceUserProfileDataChange}
                                    assessmentDetails={props.assessmentDetails}
                                />
                                <StrengthAssessmentContainer
                                orgName={props.orgName}
                                    strengthAssessmentFormValid={strengthAssessmentFormValid}
                                    strengthAssessmentDataChange={strengthAssessmentDataChange}
                                    assessmentDetails={props.assessmentDetails}
                                />
                                <AdditionalInfoContainer
                                orgName={props.orgName}
                                    additionalInfoFormValid={additionalInfoFormValid}
                                    additionalInfoDataChange={additionalInfoDataChange}
                                    assessmentDetails={props.assessmentDetails}
                                />
                                <SupportWorkerContainer orgName={props.orgName} supportWorkerFormValid={supportWorkerFormValid} supportWorkerDataChange={supportWorkerDataChange} assessmentDetails={props.assessmentDetails} />
                                <WhatNextContainer orgName={props.orgName} whatNextFormValid={whatNextFormValid} whatNextDataChange={whatNextDataChange} assessmentDetails={props.assessmentDetails} />
                                <DiversityProfileContainer
                                orgName={props.orgName}
                                    diversityProfileFormValid={diversityProfileFormValid}
                                    diversityProfileDataChange={diversityProfileDataChange}
                                    assessmentDetails={props.assessmentDetails}
                                />
                            </div>
                            <div className="quickaccess-buttons">
                                <a href="#general-info" data-tooltip-id="my-tooltip-info" data-tooltip-content="General Information">
                                    <span className={`section-staus ${generalInfoValid ? 'green' : 'red'}`}></span>
                                    <img src={General_Info} alt="" width={20} />
                                </a>
                                <a href="#progress-note" data-tooltip-id="my-tooltip-note" data-tooltip-content="Progress Notes">
                                    <span className={`section-staus ${progressNoteValid ? 'green' : 'red'}`}></span>
                                    <img src={Progress_Note} alt="" width={20} />
                                </a>
                                <a href="#service-profile" data-tooltip-id="my-tooltip-profile" data-tooltip-content="Service User Profile">
                                    <span className={`section-staus ${serviceUserProfileValid ? 'green' : 'red'}`}></span>
                                    <img src={Info_gethering} alt="" width={20} />
                                </a>
                                <a href="#strength-assessment" data-tooltip-id="my-tooltip-strength" data-tooltip-content="Strengths Based Assessment">
                                    <span className={`section-staus ${strengthAssessmentValid ? 'green' : 'red'}`}></span>
                                    <img src={Strength} alt="" width={32} />
                                </a>
                                <a href="#capacity-info" data-tooltip-id="my-tooltip-capacity" data-tooltip-content="Capacity & Legal Orders and Additional Information">
                                    <span className={`section-staus ${additionalInfoValid ? 'green' : 'red'}`}></span>
                                    <img src={Capacity} alt="" width={22} />
                                </a>
                                <a href="#support-care-giver" data-tooltip-id="my-tooltip-support" data-tooltip-content="Support Worker/ Care Giver">
                                    <span className={`section-staus ${supportWorkerValid ? 'green' : 'red'}`}></span>
                                    <img src={Care_Giver} alt="" width={22} />
                                </a>
                                <a href="#what-next" data-tooltip-id="my-tooltip-next" data-tooltip-content="What Happens Next?">
                                    <span className={`section-staus ${whatNextValid ? 'green' : 'red'}`}></span>
                                    <img src={Next} alt="" width={17} />
                                </a>
                                <a href="#diversity-profile" className="mb-0" data-tooltip-id="my-tooltip-diversity" data-tooltip-content="Service User’s Diversity Profile">
                                    <span className={`section-staus ${diversityProfileValid ? 'green' : 'red'}`}></span>
                                    <img src={Profile} alt="" width={17} />
                                </a>
                            </div>
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-assessment" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-info" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-note" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-profile" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-strength" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-capacity" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-support" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-next" />
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-diversity" />

                            <div ref={componentRef}>
                                <PrintAssessmentContainer orgName={props.orgName} assessmentDetails={props.assessmentDetails} />
                            </div>
                        </div>
                    </div>
                }
                footer={
                    props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus !== AssessmentStatus.Submitted && (
                        <>
                        {props.status !== 3 && 
                        <div>
                            <button type="submit" onClick={onCLickHandlerSaveAssessment} className="btn btn-primary me-2">
                                Save
                            </button>
                            <button type="submit" disabled={!disableButton} onClick={onCLickHandlerSubmitAssessment} className="btn btn-secondary">
                                Submit Assessment
                            </button>
                        </div>
                        }
                        </>
                    )
                }
            />
        </div>
    );
};

export default AssessmentModalView;
