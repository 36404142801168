import React from 'react';
import AdmissionView from './AdmissionView';

const AdmissionContainer = (props: any) => {
    return (
        <div>
            <AdmissionView closeSideMenu={props.closeSideMenu} taskList={props.taskList} currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} getCurrentPage={(page: any) => props.getCurrentPage(page)} />
        </div>
    );
};

export default AdmissionContainer;