import React, {useState,useEffect} from 'react';
import AssessmentView from './AssessmentView';
import { getAssessmentDetails } from '../../../../../service/assessment-service';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import eventEmitter from '../../../../../utils/eventEmitter';
import { useDispatch } from 'react-redux';
import { removeAssessmentDetailsAction } from '../../../../../store/assessment/getAssessmentDetailsReducer';


const AssessmentContainer = (props:any) => {
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [assessmentId, setAssessmentId] = useState<any>();
    const dispatch = useDispatch()
    useEffect(() => {
        if(StatusLeadInfo === true && DataLeadInfo?.data?.assessment?.length > 0) {                
            const assessmentDetails = DataLeadInfo?.data?.assessment?.find((data: any) => data.serviceId == DataServiceDetail.serviceId);
            setAssessmentId(assessmentDetails?.assessmentId)
        }
    }, [StatusLeadInfo, DataLeadInfo])
    useEffect(() => {        
        if(assessmentId === 0) {
            dispatch(removeAssessmentDetailsAction())
        }
        if(assessmentId !== undefined && assessmentId !== 0) {
            const fetchAssessmentDetails = async() => {
                await getAssessmentDetails(assessmentId);
            }
            fetchAssessmentDetails()
        }
    }, [assessmentId])

    const onSaveOrSubmitAssessment = async (id: any) => {
        if(Number(id) !== 0) {
            await getAssessmentDetails(id);
        }
    };


    useEffect(() => {
        eventEmitter.on('onSaveOrSubmitAssessment', onSaveOrSubmitAssessment);
        return () => {
            eventEmitter.off('onSaveOrSubmitAssessment', onSaveOrSubmitAssessment);
        };
    });
    return (
        <div>
           <AssessmentView referralid={props.referralid}/>
        </div>
    );
};

export default AssessmentContainer;