import React from 'react';
import ShareOptionsView from './ShareOptionsView';

const ShareOptionsContainer = (props:any) => {
    return (
        <div>
            <ShareOptionsView serviceDetails={props?.serviceDetails} url={props?.url} />
        </div>
    );
};

export default ShareOptionsContainer;
