import React, { useEffect, useState } from 'react';
import RejectView from './RejectView';
import { acceptRejectLead, closeReferral, getLeaddata, getRejectiondata, skipAssessment } from '../../service/leads';
import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import eventEmitter from '../../utils/eventEmitter';
import { contractCancel, contractCancelReasons } from '../../service/contract-service';
import { RootState } from '../../store';
import { getPatientReservedData, removeFunderPlan } from '../../service/dashboard-service';
import { getListReasons } from '../../service/discharge-service';
import { BASEURLDEV, API_ENDPOINT } from '../../service/ApiConstants';
import { ReasonList } from '../../utils/constant';

const RejectContainer = (props: any) => {
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const [reasons, setreason] = useState<any>();
    const [rejectiondata, setrejectiondata] = useState<any>({ desc: '' });
    let [reasonSmartSearch, setReasonSmartSearch] = useState<any>(undefined);
    const [docs, setdocs] = useState<any>([]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.mname !== '') {
            setreason(undefined);
            if (props.mname === 'plead') {
                // getreason();
                let objReasonSmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.GetDDLPoolRejectionReason}?reasonTypeId=1`
                };
                setReasonSmartSearch(objReasonSmartSearch);
            } else if (props.mname === 'contract' || props.mname === 'admission') {
                let objReasonSmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.ContractCancelDDLReason}`
                };
                setReasonSmartSearch(objReasonSmartSearch);
                // getconreason();
            } else if (props.mname === 'referral') {
                let objReasonSmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.GetDDLReasons}/${ReasonList.Close}`
                };
                setReasonSmartSearch(objReasonSmartSearch);
                // getreferralreason();
            } else if (props.mname === 'skiprm') {
                let objReasonSmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRejectionReasons}?reasonTypeId=5`
                };
                setReasonSmartSearch(objReasonSmartSearch);
            } else if (props.mname === 'skipsm') {
                let objReasonSmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRejectionReasons}?reasonTypeId=5`
                };
                setReasonSmartSearch(objReasonSmartSearch);
            }
        }
        async function getreason() {
            const res = await getRejectiondata();
            setreason(res.data);
        }
        async function getconreason() {
            const res = await contractCancelReasons();
            setreason(res.data);
        }
        async function getreferralreason() {
            let payload = {
                id: 5
            };
            const res = await getListReasons(payload);
            setreason(res.data);
        }
    }, [props.mname]);

    const updateData = (e: any) => {
        setrejectiondata({
            ...rejectiondata,
            [e.target.name]: e.target.value
        });
    };

    const submitalert = () => {
        if (props.mname === 'plead') {
            swal({
                title: '',
                text: `Are you sure you want to reject the lead ${props.referralid} ?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    submit();
                } else {
                }
            });
        } else if (props.mname === 'contract') {
            // - ${props.leadid.funderName}
            swal({
                title: '',
                text: `Are you sure you want to ${props.leadid.isvoided ? 'void' : 'cancel'} contract for lead ${props.leadid.referralId} sent to funder ?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    submitcontract();
                } else {
                }
            });
        } else if (props.mname === 'admission') {
            // - ${props.leadid?.leadinfo?.contract[props.leadid?.index]?.funderName}
            swal({
                title: '',
                text: `Are you sure you want to ${props.leadid.isvoided ? 'void' : 'cancel'} contract for  ${props.leadid?.leadinfo?.serviceUserDetails?.serviceUserName} - ${
                    props.leadid?.leadinfo?.serviceUserDetails?.nhsId !== '' ? props.leadid?.leadinfo?.serviceUserDetails?.nhsId : props.leadid?.leadinfo?.serviceUserDetails?.localId
                }  sent to funder?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    submitcontract();
                } else {
                }
            });
        } else if (props.mname === 'referral') {
            
            if (props.leadid?.cutoffdata === 0) {
                swal({
                    title: '',
                    text: `Are you sure you want to close Referral ID ${props.leadid?.refid}? Note: Lead once closed cannot be re-opened.`,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        submitrefclose();
                    } else {
                    }
                });
            } else {
                let finalMsg;
                if (props.leadid?.stage === 5) {
                    finalMsg = `Are you sure you want to close Referral ID ${props.leadid?.refid}? Note: Lead once closed cannot be re-opened.`;
                } else if (props.leadid?.stage === 6) {
                    finalMsg = `The contracting is under processing from ${DataLeadInfo?.data?.serviceName}, do you want to close ${props.leadid?.refid}? Note: Lead once closed cannot be re-opened.`;
                } else {
                    finalMsg = `The ${
                        props.leadid?.stage === 2 ? 'pooling' : props.leadid?.stage === 3 ? 'assessment' : props.leadid?.stage === 4 ? 'costing' : ''
                    } has not been accepted or rejected by a few services; do you want to close Referral ID ${props.leadid?.refid}? Note: Lead once closed cannot be re-opened.`;
                }
                swal({
                    title: '',
                    text: finalMsg,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        submitrefclose();
                    } else {
                    }
                });
            }
        } else if (props.mname === 'skiprm' || props.mname === 'skipsm') {
            var sname = props.title.split('for');
            
            swal({
                title: '',
                text: `Are you sure you want to skip the assessment of lead ${props.referralid} for ${sname[1]}`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    skipAssesment();
                } else {
                }
            });
        }
    };
    

    async function submit() {
        let data = {
            id: props.leadid,
            poolingId: props.poolingId,
            poolingStatus: 2,
            rejectionReasonId: Number(rejectiondata.reason),
            remarks: rejectiondata.desc
        };
        const res = await acceptRejectLead(data);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                eventEmitter.emit('onAddNewLeadRM');
                props.onClose();
                let obj: any = {
                    ShowClass: false,
                    FormName: '',
                    Data: '',
                    ShowToggle: false
                };
                dispatch(setNavigationAction(obj));
            });
        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
                props.onClose();
            });
        }
    }

    async function submitrefclose() {
        let data = {
            leadId: props.leadid?.id,
            reason_Id: Number(rejectiondata.reason),
            comments: rejectiondata.desc
        };
        const res = await closeReferral(data);
        if (res.status === true) {
            eventEmitter.emit('onAddNewLeadRM');
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                props.onClose();
                let obj: any = {
                    ShowClass: false,
                    FormName: '',
                    Data: '',
                    ShowToggle: false
                };
                dispatch(setNavigationAction(obj));
            });
        }
    }

    // async function getpatientdata(pid:any) {
    //     const payload: any = {id:pid};
    //     await getPatientReservedData(payload);
    // }

    async function submitcontract() {
        // props.leadid.contractid
        let data = {
            reasonId: Number(rejectiondata.reason),
            funderId: props.leadid.funderid,
            digitalContractId: props.leadid.contractid,
            leadId: props.leadid.leadid,
            description: rejectiondata.desc,
            IsVoided: props.leadid.isvoided
        };
        const res = await contractCancel(data);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                eventEmitter.emit('onAddNewLeadRM');
                props.onClose();
                if (props.mname === 'admission' && props.leadid?.isinvoice === 0) {
                    let data = {
                        admissionId: props.leadid.admissionid,
                        funderId: props.leadid.funderid
                    };
                    removeFunderPlan(data);
                }
                getLeaddata(DataLeadInfo?.data?.leadId, 6);
                if(props.mname === 'admission'){
                    eventEmitter.emit('eventClearSpliting');
                }
                // let obj: any = {
                //     ShowClass: false,
                //     FormName: '',
                //     Data: '',
                //     ShowToggle: false
                // };
                // dispatch(setNavigationAction(obj));
            });
        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
                // props.onClose();
            });
        }
    }

    const getAttachments = (docs:any) =>{
      setdocs(docs);
    }

    async function skipAssesment() {
        let data = {
            leadPoolingId: props.poolingId,
            reasonId: Number(rejectiondata.reason),
            description: rejectiondata.desc,
            attachmentList: docs
        };

        const res = await skipAssessment(data);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                eventEmitter.emit('onAddNewLeadRM');
                if(props.mname === "skiprm"){
                    props.refreshAfterReschedule();
                }
                props.onClose();
                getLeaddata(DataLeadInfo?.data?.leadId, 3);
            });
        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
                // props.onClose();
            });
        }
    }

    return (
        <div>
            <RejectView
                mname={props.mname}
                onClose={props.onClose}
                footer={props.footer}
                title={props.title}
                className={props.className}
                content={props.content}
                reason={reasons}
                updateData={updateData}
                submitalert={submitalert}
                reasonSmartSearch={reasonSmartSearch}
                poolingid={props.poolingId}
                getAttachments={getAttachments}
                referralid={props.referralid}
            />
        </div>
    );
};

export default RejectContainer;
