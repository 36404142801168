import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeaddataModel } from '../../utils/model';

const initialState: LeaddataModel = {
    StatusLeadInfo: false,
    DataLeadInfo: '',
    MessageLeadInfo: '',
    ErrorLeadInfo: ''
};

export const leadInformationSlice = createSlice({
    name: 'leadData',
    initialState,
    reducers: {
        setLeadInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusLeadInfo = true;
            state.DataLeadInfo = payload;
            state.MessageLeadInfo = 'Success';
        },
        errorLeadInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusLeadInfo = false;
            state.DataLeadInfo = [];
            state.MessageLeadInfo = 'Failed';
            state.ErrorLeadInfo = payload;
        },
       removeLeadInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusLeadInfo = false;
            state.DataLeadInfo = '';
            state.MessageLeadInfo = '';
            state.ErrorLeadInfo = '';
        }
    },
    extraReducers: {}
});

export const { setLeadInformationAction, errorLeadInformationAction, removeLeadInformationAction } = leadInformationSlice.actions;

export default leadInformationSlice.reducer;
