import React from 'react';
import CostingModalView from './CostingModalView';

const CostingModalContainer = (props: any) => {    
    return (
        <div>
            <CostingModalView onClose={props.onClose} viewCosting={props.setViewCosting} title={props.title} className={props.className} content={props.content} referralid={props.referralid} serviceName={props.serviceName} mode={props.mode} saveCosting={props.saveUpdateCosting} />
        </div>
    );
};

export default CostingModalContainer;