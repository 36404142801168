import ServiceUserDetailContainer from '../component/ServiceUserDetail/serviceContainer';
import AdmissionDetailContainer from '../component/AdmissionDetail/admissionContainer';
import ContractContainer from '../component/ContractDetail/contractContainer';
import ApplicableContainer from '../component/ApplicableRate/applicableContainer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import eventEmitter from '../../../../../../utils/eventEmitter';
import redIcon from '../../../../../../assets/images/info-red.svg';
import greenCheck from '../../../../../../assets/images/green-check.svg'

const AdmitView = (props: any) => {
    const { t } = useTranslation();
    const [activeID, setActiveID] = useState<any>(0);
    const [updateddata,setdata] = useState<any>();
    // const [updateddatanew,setdatanew] = useState<any>();
    const [updatedlistdata,setlistdata] = useState<any>();
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const [count, setcount] = useState<any>(0);
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const setData = (data: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        props.setData(data);
        setActiveID(data.bedId);
    };

    useEffect(()=>{
     
    },[updateddata])

    const wardChange = () => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        props.setData('');
        setActiveID('');
    }

    useEffect(() => {
        setdata(undefined);
        setlistdata(undefined);
    }, []);

    const contractdataafteradmission = (val:any)=>{
        if(val?.ListData === null || val?.ListData?.length === 0){
            setdata(undefined);
            props.getcontractfunder(undefined);
        }
        else{
            if(val?.ListData?.length > 0){
                setcount(count + 1);
                setlistdata(val);
                setdata(val);
                props.getcontractfunder(val);
               }
        }
      
    }
    useEffect(() => {
        eventEmitter.on('eventClearFormArray', eventClearArray);
        return () => {
            eventEmitter.off('eventClearFormArray', eventClearArray);
        };
    });

    const eventClearArray = () =>{
        setdata(undefined);
    }

    useEffect(()=>{
        if(updatedlistdata === undefined){
            // setdata(props.funderlist)
        }
    },[props.funderlist,updatedlistdata])
    return (
        <>
            <div>


                <div className="accordion accordion-flush" id="accordionFlushExample">
                    {DataServiceDetail?.iqviaServiceEnabled === false && DataPatientDraftInfo !== '' && props.isDraft == undefined && (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne1">
                                <button
                                    className="accordion-button accordion-button-custom"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne6"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne6"
                                >
                                     {t('VacantRightbar.Ward_Bed_No')}
                                     <iframe src={props.bed !== undefined && props.bed !== '' && props.bed > 0 ? greenCheck : redIcon} className='ms-auto me-1' width="15" height="15"  />
                                </button>
                            </h2>
                            <div id="flush-collapseOne6" className={`accordion-collapse collapse ${DataPatientDraftInfo !== '' && props.isDraft == undefined ? "show" : "" } `} aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                <p className="bold mt-1  text-center mb-2"> {t('OccupiedRightBar.Ward_No')}</p>
                                <ul className="nav nav-tabs nav-pills ward-room-pills" id="myTab" role="tablist">
                                    {DataPatientDraftInfo !== '' &&
                                        props.listVacantBeds !== undefined &&
                                        props.listVacantBeds !== '' &&
                                        props.listVacantBeds !== null &&
                                        props.listVacantBeds.length > 0 &&
                                        props.listVacantBeds.map((wData: any, index: any) => {
                                            return (
                                                <li key={wData.wardId} className="nav-item" role="presentation">
                                                    <button
                                                        className={`nav-link ${index === 0 ? 'active' : ''}`}
                                                        id={`ward-${wData.wardId}-tab`}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#ward-${wData.wardId}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`ward-${wData.wardId}`}
                                                        aria-selected={`${index === 0 ? 'true' : 'false'}`}
                                                        onClick={wardChange}
                                                    >
                                                        {wData.wardName}
                                                    </button>
                                                </li>
                                            );
                                        })}
                                </ul>
                                <p className="bold mt-2  text-center">{t('OccupiedRightBar.Bed_No')}</p>
                                <div className="tab-content ward-room-detail" id="myTabContent">
                            {DataPatientDraftInfo !== '' &&
                                props.listVacantBeds !== undefined &&
                                props.listVacantBeds !== '' &&
                                props.listVacantBeds !== null &&
                                props.listVacantBeds.length > 0 &&
                                props.listVacantBeds.map((wData: any, index: any) => {
                                    return (
                                        <div
                                            key={wData.wardId}
                                            className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                            id={`ward-${wData.wardId}`}
                                            role="tabpanel"
                                            aria-labelledby={`ward-${wData.wardId}-tab`}
                                        >
                                            <ul className="list-unstyled room-list-tile">
                                                {wData.bedDetails.length > 0 &&
                                                    wData.bedDetails.map((bData: any) => {
                                                        return (
                                                            <li className={`${activeID === bData.bedId ? 'cus_active' : ''}`} key={bData.bedId} onClick={() => setData(bData)}>
                                                                <a href="#">{bData.bedName}</a>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    );
                                })}
                        </div>
                                </div>
                            </div>
                        </div>
                        )}

                        {DataServiceDetail?.iqviaServiceEnabled === false && !props.isDraft && props.isDraft !== undefined && props.isDraft !== 'reserved' && (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne2">
                                <button
                                    className="accordion-button accordion-button-custom"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse6"
                                    aria-expanded="false"
                                    aria-controls="flush-collapse6"
                                >
                                      {t('VacantRightbar.Ward_Bed_No')}
                                     <iframe src={props.bed !== undefined && props.bed !== 0 ? greenCheck : redIcon} className='ms-auto me-1' width="15" height="15"  />
                                </button>
                            </h2>
                            <div id="flush-collapse6" className={`accordion-collapse collapse ${!props.isDraft && props.isDraft !== undefined && props.isDraft !== 'reserved' ? "show" : "" } `} aria-labelledby="flush-headingOne2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                <p className="bold mt-1 purple-text text-center mb-2"> {t('OccupiedRightBar.Ward_No')}</p>

                                <ul className="nav nav-tabs nav-pills ward-room-pills" id="myTab" role="tablist">
                                    {!props.isDraft &&
                                        props.listVacantBeds !== undefined &&
                                        props.listVacantBeds !== '' &&
                                        props.listVacantBeds !== null &&
                                        props.listVacantBeds.length > 0 &&
                                        props.listVacantBeds.map((wData: any, index: any) => {
                                            return (
                                                <li key={wData.wardId} className="nav-item" role="presentation">
                                                    <button
                                                        className={`nav-link ${index === 0 ? 'active' : ''}`}
                                                        id={`ward-${wData.wardId}-tab`}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#ward-${wData.wardId}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`ward-${wData.wardId}`}
                                                        aria-selected={`${index === 0 ? 'true' : 'false'}`}
                                                        onClick={wardChange}
                                                    >
                                                        {wData.wardName}
                                                    </button>
                                                </li>
                                            );
                                        })}
                                </ul>
                                <p className="bold mt-2 purple-text text-center">{t('OccupiedRightBar.Bed_No')}</p>

                        <div className="tab-content ward-room-detail" id="myTabContent">
                            {!props.isDraft &&
                                props.listVacantBeds !== undefined &&
                                props.listVacantBeds !== '' &&
                                props.listVacantBeds !== null &&
                                props.listVacantBeds.length > 0 &&
                                props.listVacantBeds.map((wData: any, index: any) => {
                                    return (
                                        <div
                                            key={wData.wardId}
                                            className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                            id={`ward-${wData.wardId}`}
                                            role="tabpanel"
                                            aria-labelledby={`ward-${wData.wardId}-tab`}
                                        >
                                            <ul className="list-unstyled room-list-tile">
                                                {wData.bedDetails.length > 0 &&
                                                    wData.bedDetails.map((bData: any) => {
                                                        return (
                                                            <li className={`${activeID === bData.bedId ? 'cus_active' : ''}`} key={bData.bedId} onClick={() => setData(bData)}>
                                                                <a href="#">{bData.bedName}</a>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    );
                                })}
                        </div>
                                </div>
                            </div>
                        </div>
                        )}

                    <div className="accordion-item">
                        <h2 className="accordion-header " id="flush-heading6">
                            <button
                                className={`accordion-button accordion-button-custom ${DataPatientDraftInfo !== '' && props.isDraft == undefined ? "collapsed" : " " } `}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne3"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne3"
                            >
                                {t('VacantRightbar.title')}
                                <iframe src={props.serviceError ? greenCheck : redIcon} className='ms-auto me-1' width="15" height="15"  />
                            </button>

                        </h2>
                        <div id="flush-collapseOne3" className={`accordion-collapse collapse ${DataPatientDraftInfo !== '' && props.isDraft == undefined ? !props.isDraft && props.isDraft !== undefined && props.isDraft !== 'reserved' ? "" : "" : !props.isDraft && props.isDraft !== undefined && props.isDraft !== 'reserved' ? "" : "show" && props.section === 'contract' ? '' : 'show'  }`} aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ServiceUserDetailContainer isAction={props.isAction} servicedata={props.getServiceData} serviceerrordata={props.serviceerrordata} isedit={props.isDraft} leadnhsid={props.leadnhsid} leadlocalid={props.leadlocalid} checkSelfFunder={props.checkSelfFunder}/>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button
                                className="accordion-button accordion-button-custom collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                            >
                                 {t('VacantRightbar.admissiontitle')}
                                 <iframe src={props.admissionError ? greenCheck : redIcon} className='ms-auto me-1' width="15" height="15"  />
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <AdmissionDetailContainer
                                    isAction={props.isAction}
                                    admissiondata={props.getAdmissionData}
                                    admissionreservedata={props.getReserveAdmissionData}
                                    getadmissionerror={props.getadmissionerror}
                                    isedit={props.isDraft}
                                />
                            </div>
                        </div>
                    </div>
                    {DataServiceDetail?.iqviaServiceEnabled === false && (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button
                                    className="accordion-button accordion-button-custom collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                >
                                    {t('VacantRightbar.contracttitle')}
                                    <iframe src={props.contracterror && props.contractlisterror === 0 && props.applicableerror ? greenCheck : redIcon} className='ms-auto me-1' width="15" height="15"  />
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className={`accordion-collapse collapse ${props.section === 'contract' ? 'show' : ''}`} aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    {/* draftData={props.draftData.contractDetails} */}
                                    <ContractContainer
                                        patientname={props.patientname}
                                        isAction={props.isAction}
                                        contractdata={props.getContarctdata}
                                        isallvoid={props.isallvoid}
                                        getcontracterror={props.getcontracterror}
                                        getfundelisterror={props.getfundelisterror}
                                        deletefunderlist={props.deletefunderlist}
                                        selfData={props.selfData}
                                        deleteddoc={props.deleteddoc}
                                        contractdataafteradmission={contractdataafteradmission}
                                        isReferralAdd={props.isReferralAdd}
                                        isDraftListAdd={props.isDraftListAdd}
                                        validateSelfFunder={props.validateSelfFunder}
                                    />
                                    <ApplicableContainer isAction={props.isAction} applicabledata={props.getapplicabledata} funderlist={updateddata === undefined ? "" : updateddata} getapplicableerror={props.getapplicableerror} admissiondate={props.admissiondate} splitchange={props.splitchange}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="d-flex justify-content-between  right_content_btn">
                {DataServiceDetail?.iqviaServiceEnabled === false && props.isAction == 'admit' && !props.isDraft && (
                    <button onClick={props.onSubmit} disabled={!props.isValid} className="btn btn-primary flex-1 me-2">
                        {t('VacantRightbar.btnadmit')}

                    </button>
                 )}
                {props.isAction == 'admit' && props.isDraft == 'edit' && (
                    <button onClick={props.onUpdate} disabled={!props.isValid} className="btn btn-primary flex-1 me-2">
                        {t('VacantRightbar.btnupdate')}

                    </button>
                )}
                 {props.isAction == 'admit' && props.isDraft == 'edit' && (
                    <button onClick={props.onDiscard}  className="btn btn-secondary flex-1 me-2">
                        {t('VacantRightbar.btndiscard')}

                    </button>
                )}
                {props.isAction == 'reserve' && !props.isDraft && (
                    <button disabled={!props.isValid} onClick={props.onSubmit} className="btn btn-primary flex-1 me-2">
                        {t('VacantRightbar.btnreserve')}
                    </button>
                )}
                {props.isAction == 'admit' && props.isDraft == 'reserved' && (
                    <button onClick={props.onSubmit} disabled={!props.isValid} className="btn btn-primary flex-1 me-2">
                        {t('VacantRightbar.btnadmit')}
                    </button>
                )}
                {props.isAction == 'reserve' && props.isDraft == 'reserved' && (
                    <button disabled={!props.isValid} onClick={props.onSubmit} className="btn btn-primary flex-1 me-2">
                        {t('VacantRightbar.btnreserve')}
                    </button>
                )}
                {props.isDraft !== 'reserved' && !props.isReferralAdd && props.isDraft !== 'edit' && (
                    <button disabled={!props.isValidDraft} onClick={props.onDraft} className="btn btn-secondary flex-1 ms-2">
                        {t('VacantRightbar.btndraft')}
                    </button>
                )}

                {(props.isAction == 'admit'||props.isAction == 'reserve') && props.isReferralAdd && (
                    <button disabled={!props.isValidDraft} onClick={props.onSave} className="btn btn-secondary flex-1 ms-2">
                        {t('VacantRightbar.btnsave')}
                    </button>
                )}
            </div>
        </>
    );
};

export default AdmitView;
