import * as yup from 'yup';
import swal from 'sweetalert';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import WYSIWYGEditor from '../WYSIWYGEditor/WYSIWYGEditor';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { deleteNote, getNotesByLeadId, getNotesUnreadCount, saveNotes } from '../../service/notes';
import warning from '../../assets/images/warning.png';
import DeleteIcon from '../../assets/images/delete.svg';
import EditIcon from '../../assets/images/edit.svg';
import LoaderContainer from '../Loader/loaderContainer';
import eventEmitter from '../../utils/eventEmitter';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
//@ts-ignore
import * as dompurify from 'dompurify';

const initialValue = {
    description: ''
};

const validateNotes = yup.object().shape({
    description: yup.string().required('Please enter note')
});

const NotesModalView = (props: any) => {
    const { t } = useTranslation();

    const [initialContent, setInitialContent] = useState('');
    const [notesId, setNotesId] = useState<any>();

    const { DataLogin } = useSelector((state: RootState) => state.login);

    const storedId = DataLogin?.userModel?.userId;
    const sanitizer = dompurify.sanitize;

    const { control, setValue, handleSubmit, watch } = useForm({
        defaultValues: initialValue,
        resolver: yupResolver(validateNotes)
    });

    const storedValue = watch('description');

    useEffect(() => {
        if (storedValue?.length < 1) {
            setInitialContent('');
        }
    }, [storedValue]);

    const handleModalClose = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                props.setNotesModalOpen(false);
                setInitialContent('');
                setValue('description', '');
            }
        });
    };

    const handleOnEditorChange = (content: any) => {
        setValue('description', content?.split('<p></p>')?.join('')?.trim());
    };

    const onNoteSave = async (data: { description: any }) => {
        const payload = {
            notesTitle: data?.description?.split(' ').slice(0, 5).join(' '),
            notesDescription: data?.description,
            leadId: props.leadId,
            isRead: false
        };

        const response = await saveNotes(payload);
        if (response?.status === true) {
            swal(response?.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                const res = await getNotesByLeadId(props.leadId);
                const unreadRes = await getNotesUnreadCount(props.leadId);
                props.setNotesCount(unreadRes?.data);
                props.setNoteListResponse(res?.data);
                setValue('description', '');
                eventEmitter.emit("onNoteUpdateOrSaveRM")
            });
        } else {
            swal(response?.message, {
                icon: 'error'
            });
        }
    };

    const alertHandler = (noteId: string) => {
        if (noteId) {
            swal({
                title: '',
                text: 'Are you sure you want to delete the note?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    const response = await deleteNote(noteId);
                    if (response.status === true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                    const res = await getNotesByLeadId(props.leadId);
                    props.setNoteListResponse(res?.data);
                }
            });
        }
    };

    const onEditClick = (note: any) => {
        setValue('description', note.notesDescription);
        setInitialContent(note.notesDescription);
        setNotesId(note.notesId);
    };

    const handleReset = () => {
        setValue('description', '');
        setInitialContent(null as any);
    };

    const updateNote = async (data: { description: any }) => {
        const payload = {
            notesTitle: data?.description?.split(' ').slice(0, 5).join(' '),
            notesDescription: data?.description,
            leadId: props.leadId,
            isRead: false,
            notesId
        };
        const response = await saveNotes(payload);
        if (response?.status === true) {
            swal(response?.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                const res = await getNotesByLeadId(props.leadId);
                props.setNoteListResponse(res?.data);
                setValue('description', '');
                setInitialContent('');
                eventEmitter.emit("onNoteUpdateOrSaveRM")
            });
        } else {
            swal(response?.message, {
                icon: 'error'
            });
        }
    };

    return (
        <div>
            <ModalWrapperContainer
                title="Notes"
                onClose={() => handleModalClose()}
                className="modal notesModal"
                content={
                    <div style={{ height: '100%', width: '95%', margin: '0 auto' }}>
                        {props.isEditModeEnable && (
                            props.status !== t("Referral.Closed") && (
                                <form onSubmit={handleSubmit(initialContent ? updateNote : onNoteSave)}>
                                    <Controller
                                        control={control}
                                        name="description"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <WYSIWYGEditor
                                                    maxlength={5000}
                                                    initialContent={initialContent}
                                                    key={props.noteListResponse?.length}
                                                    className={`form-control white attendees mb-2`}
                                                    onChange={(content: any) => handleOnEditorChange(content)}
                                                />
                                            );
                                        }}
                                    />
                                    <div className="d-flex">
                                        <button className="btn btn-primary mt-3 mb-1 px-5" disabled={!storedValue}>
                                            {initialContent ? t('NotesModal.Update') : t('NotesModal.Save')}
                                        </button>
                                        <button className="btn btn-secondary mt-3 mb-1 ms-2 px-5" disabled={!storedValue} onClick={handleReset}>
                                            {initialContent ? t('NotesModal.Discard') : t('NotesModal.Clear')}
                                        </button>
                                    </div>
                                </form>
                            ))}

                        {props.noteListResponse?.length > 0 &&
                            props.noteListResponse?.map((note: any) => (
                                <div className="flex noteTextContainer" key={note.notesId}>
                                    <div className="text">
                                        <h4 className="purple-text title" dangerouslySetInnerHTML={{ __html: sanitizer(note.notesTitle) }}></h4>
                                        <span className="mb-1 authorDate">
                                            {note.userName} updated on {moment(note.updatedOn).format('DD MMM YYYY') + ' at ' + moment(note.updatedOn).format('hh:mm A')}
                                        </span>

                                        <p className="content" dangerouslySetInnerHTML={{ __html: sanitizer(note.notesDescription) }}></p>
                                    </div>
                                    {note?.userId === storedId && props.isEditModeEnable ? (
                                        props.status !== t("Referral.Closed") && (
                                            <div className="icons d-flex align-items-start justify-content-end">
                                                <button className="btn btn-success btn-xs" onClick={() => onEditClick(note)}>
                                                    <img src={EditIcon} />
                                                </button>
                                                <button className="btn btn-danger btn-xs ms-1" onClick={() => alertHandler(note.notesId)}>
                                                    <img src={DeleteIcon} />
                                                </button>
                                            </div>
                                        )
                                    ) : null}
                                </div>
                            ))}
                    </div>
                }
            />
        </div>
    );
};

export default NotesModalView;
