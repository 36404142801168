import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigationModel } from '../../utils/model';

const initialState: NavigationModel = {
    ShowClass: false,
    FormName: '',
    Data: '',
    ShowToggle:false
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setNavigationAction: (state, { payload }: any) => {
            const { ShowClass, FormName, Data,ShowToggle } = payload;
            state.ShowClass = ShowClass;
            state.FormName = FormName;
            state.Data = Data;
            state.ShowToggle = ShowToggle
        },
        removeNavigationAction: (state, { payload }: PayloadAction) => {
            state.ShowClass = false;
            state.FormName = '';
            state.Data = '';
            state.ShowToggle = false;
        }
    },
    extraReducers: {}
});

export const { setNavigationAction, removeNavigationAction } = navigationSlice.actions;

export default navigationSlice.reducer;
