import React, { useState } from 'react';
import AdmissionView from './admissionView';
import { AdmissionList } from '../../../../../utils/constant';

const AdmissionContainer = () => {
    const [searchValue, setSearchValue] = useState<any>('');
    const [tabID, setTabID] = useState<any>(AdmissionList.Admitted);
    const handlerSearchValue = (e: any) => {
        setSearchValue(e.target.value);
    };
    const handelTabID = (id: any) => {
        setTabID(id);
    };
    return (
        <div>
            <AdmissionView handlerSearchValue={handlerSearchValue} searchValue={searchValue} handelTabID={handelTabID} tabID={tabID} />
        </div>
    );
};

export default AdmissionContainer;
