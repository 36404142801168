import React, { useEffect, useState } from 'react';
import image1 from '../../../../assets/images/owl-image1.png';
import image2 from '../../../../assets/images/owl-image2.jpg';
import image3 from '../../../../assets/images/owl-image3.jpg';
import ImageLightboxContainer from './ImageLightbox/ImageLightboxContainer';
import { GetServiceDetails } from '../../../../service/service-details-service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Getserviceimagesbyserviceslug } from '../../../../service/serviceProvider-services';
import eventEmitter from '../../../../utils/eventEmitter';

const ServiceImageCollageView = () => {
    const { t } = useTranslation();
    const [serviceDetails, setServiceDetails] = useState<any>(null);
    const { serviceSlug } = useParams();
    const [serviceImages, SetServiceImages] = useState<any>(null);

    useEffect(() => {
        GetServiceDetailsList();
    }, [])

    const GetServiceDetailsList = async () => {
        const result = await GetServiceDetails(serviceSlug)
        setServiceDetails(result)
    }

    useEffect(() => {
        Getserviceimages()
    }, [])

    const Getserviceimages = async () => {
        const result = await Getserviceimagesbyserviceslug(serviceSlug)
        SetServiceImages(result?.data)
    }

    const showImageLightBoxHandler = () => {
        eventEmitter.emit("showImageLightBox")
      }

    return (
        <div className="service-detail-padding">
            <div className="container-fluid p-0">
                <div className="row" >
                    <div className="col-md-12">
                        <div className="service-image-collage" >
                            <div className="d-flex" >
                                <div className="placeholder-left">
                                    <div className="image-placeholder image-placeholder-1 position-relative" >
                                        <div className="image-placeholder-1 position-relative" onClick={() => showImageLightBoxHandler()}>
                                        {serviceDetails?.data?.image[0] && <img
                                            src={serviceDetails?.data?.image[0]?.imagePath}
                                            alt={serviceDetails?.data?.image[0]?.imageName}
                                            className="img-fluid"
                                        />}
                                        </div>

                                        <p className="rate-div good d-flex">
                                        <a className="d-flex" href={`${process.env.REACT_APP_CQC_URL}${serviceDetails?.data?.cqcLocationId}`} target='_blank'>
                                            <span className="bold">{t("ServiceDetail.CQC_Rating")}</span> 
                                            <span className="d-flex align-items-center ms-2">{serviceDetails?.data?.cqcRating} 
                                                <span className={`${serviceDetails && serviceDetails?.data?.cqcRating ?
                                            (
                                                serviceDetails?.data?.cqcRating.toLowerCase() === 'requires improvement' ? 'dot-round-orange' :
                                                    (serviceDetails?.data?.cqcRating.toLowerCase() === 'inadequate' ? 'dot-round-red' :
                                                        (serviceDetails?.data?.cqcRating.toLowerCase() === 'good' ? 'rate-dot' : 'rate-dot'))
                                            )
                                            : ''
                                            } ms-1`}>
                                            </span>
                                            </span>
                                        </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="placeholder-middle">
                                    <div className="image-placeholder image-placeholder-2" onClick={() => showImageLightBoxHandler()}>
                                        {serviceDetails?.data?.image[1] && <img
                                            src={serviceDetails?.data?.image[1]?.imagePath}
                                            alt={serviceDetails?.data?.image[1]?.imageName}
                                            className="img-fluid"
                                        />}
                                    </div>
                                    <div className="image-placeholder image-placeholder-3" onClick={() => showImageLightBoxHandler()}>
                                        {serviceDetails?.data?.image[2] && <img
                                            src={serviceDetails?.data?.image[2]?.imagePath}
                                            alt={serviceDetails?.data?.image[2]?.imageName}
                                            className="img-fluid"
                                        />}
                                    </div>
                                </div>
                                <div className="placeholder-right d-flex flex-column grid-gap">
                                    <div className="image-placeholder image-placeholder-2" onClick={() => showImageLightBoxHandler()}>
                                        {serviceDetails?.data?.image[3] && <img
                                            src={serviceDetails?.data?.image[3]?.imagePath}
                                            alt={serviceDetails?.data?.image[3]?.imageName}
                                            className="img-fluid"
                                        />}
                                    </div>
                                    <div className="image-placeholder image-placeholder-3" onClick={() => showImageLightBoxHandler()}>
                                        {serviceDetails?.data?.image[4] && <img
                                            src={serviceDetails?.data?.image[4]?.imagePath}
                                            alt={serviceDetails?.data?.image[4]?.imageName}
                                            className="img-fluid"
                                        />}
                                    </div>
                                    <ImageLightboxContainer serviceImages={serviceImages} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceImageCollageView;
