import React from 'react';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export interface CardProps {
    children: ReactNode;
}

const RenderModalView = (props: CardProps) => {
    return ReactDOM.createPortal(props.children, document.getElementById('modal-root')!);
};

export default RenderModalView;
