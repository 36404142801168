import React, { useEffect, useState } from 'react';
import AdmitContainer from './AdmitReserve/admintreserveContainer';
import MaintenanceFormContainer from '../Maintenance/MaintenanceForm/MaintenanceFormContainer';

import { useDispatch, useSelector } from 'react-redux';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { RootState } from '../../../../../store';

const VacantView = (props: any) => {       
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [actionValue, setactionValue] = useState('admit');
    const [bed,selectbed] = useState('');
    const [bedid,selectbedid] = useState('');
    const [showMaintenance,setShowMaintenance] = useState(false);

    const selectedbed = (bed:any) =>{
       selectbed(bed?.bedName);  
       selectbedid(bed?.bedId);
    }

    useEffect(()=>{
        if(props.Data?.bedName){
        selectbed(props.Data.bedName);
        selectbedid(props.Data.bedId);
        }
        if(DataPatientDraftInfo?.data){
        selectbed(DataPatientDraftInfo?.data?.admissionDetail?.bedName);
        selectbedid(DataPatientDraftInfo?.data?.admissionDetail?.bedId);
        }
        
        if((props.Data?.bedSection !== undefined) && (props.Data?.bedSection === true)) {
            setShowMaintenance(true)
        } else {
            setShowMaintenance(false)
        }
    },[props.Data])
    
    const actionChange = (val:any) =>{
      setactionValue(val);
    }

    

    useEffect(()=>{        
        props.setFormAction(actionValue)
    },[actionValue])
    
    return (
        <div>
            <button onClick={props.closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>
            </button>
            {actionValue !== 'maintenance' && props.Data.isDraftAdd !== 'edit' && <h3 className="mb-2">Assign Patient</h3> }
            {showMaintenance === true && actionValue === 'maintenance' && <h3 className="mb-2">Maintenance</h3> }
            {props.Data.isDraftAdd === 'edit' && <h3 className="mb-2">Update Patient Detail</h3>}

            <div className="right_content-height-tab">
                {bed !== '' && bed !== null && bed !== undefined && <p className="text-center purple-text bold">Bed No: {`${bed}`}</p>}

                 {DataServiceDetail?.iqviaServiceEnabled === false && props.Data.isDraftAdd !== 'edit' && <>
                <ul className="nav nav-tabs custom-tab mt-3 mb-3" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => actionChange('admit')} className="nav-link active" id="admitted-tab" data-bs-toggle="tab" data-bs-target="#admitted" type="button" role="tab" aria-controls="admitted" aria-selected="true">
                            <svg
                                fill="#ffffff"
                                width="22"
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 391.23 264.5"
                                xmlSpace="preserve"
                            >
                                <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                <g>
                                    <path
                                        d="M0,0c8.36,0,16.72,0,25.5,0c0,45.85,0,90.89,0,136.39c122.14,0,243.86,0,365.73,0c0,42.7,0,84.94,0,127.41
                                          c-8.38,0-16.58,0-25.28,0c0-1.65,0-3.13,0-4.61c0-19.75-0.07-39.49,0.07-59.24c0.02-3.19-0.9-4.05-4.06-4.05
                                          c-110.83,0.08-221.66,0.08-332.49,0c-3.14,0-4.11,0.83-4.08,4.03c0.14,19.74,0.07,39.49,0.07,59.24c0,1.51,0,3.02,0,5.33
                                          c-8.73,0-17.09,0-25.46,0C0,176.34,0,88.17,0,0z"
                                    />
                                    <path
                                        d="M374.78,127.68c-85.6,0-170.37,0-255.63,0c0-2.1,0-4.09,0-6.09c0.02-11.46-0.12-22.93,0.09-34.39
                                          c0.34-18.08,9.85-27.34,28.02-27.38c29.17-0.06,58.34-0.02,87.51-0.02c37.07,0,74.14-0.01,111.21,0.01
                                          c7.96,0,15.86,1.06,21.14,7.66c2.9,3.63,5.83,8.34,6.14,12.75C374.38,95.91,374.36,111.68,374.78,127.68z"
                                    />
                                    <path
                                        d="M68.05,129.47c-19.47,0.01-35.31-15.71-35.62-35.36c-0.31-19.28,16.14-35.84,35.6-35.85
                                          c19.44-0.01,35.94,16.58,35.65,35.84C103.39,113.71,87.52,129.46,68.05,129.47z"
                                    />
                                </g>
                            </svg>
                            Admit
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => actionChange('reserve')} className="nav-link" id="reserve-tab" data-bs-toggle="tab" data-bs-target="#reserve" type="button" role="tab" aria-controls="reserve" aria-selected="false">
                            <svg
                                version="1.1"
                                fill="#ffffff"
                                width="22"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 695.45 473.64"
                                xmlSpace="preserve"
                            >
                                <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                <path
                                    d="M0,11.3C3.75,2.75,10.4-0.11,19.53,0C41.4,0.28,44.52,3.16,44.52,25.27c0,67.46,0,134.91,0,202.37c0,2.71,0,5.42,0,9.18
                                      c3.14,0,5.97,0,8.8,0c206.86,0,413.72,0,620.59,0c17.23,0,21.54,4.36,21.54,21.77c0,64.97,0,129.93,0,194.9
                                      c0,15.13-4.81,19.97-19.83,20.02c-21.56,0.07-24.68-3.06-24.68-24.77c0-28.52,0-57.04,0-85.57c0-2.43,0-4.87,0-7.66
                                      c-202.29,0-403.83,0-606.07,0c-0.12,2.49-0.33,4.89-0.33,7.28c-0.02,30.11,0,60.21-0.02,90.32c-0.01,15.28-5.07,20.35-20.22,20.39
                                      c-1.58,0-3.18-0.12-4.75,0.02C10.4,474.34,3.75,470.88,0,462.33C0,311.99,0,161.64,0,11.3z"
                                />
                                <path
                                    d="M164.34,221.92c-24.88,0-49.77,0.22-74.65-0.07c-20.46-0.24-36.45-15.13-37.75-34.46c-1.33-19.87,14.9-37.88,36.2-38.81
                                      c19.19-0.83,38.44-0.41,57.67-0.47c17.87-0.05,35.74-0.07,53.61,0.01c19.43,0.08,36.09,7.27,50.36,20.21
                                      c6.75,6.12,10.36,13.55,8.87,22.97c-0.99,6.23-1.15,12.65-2.71,18.72c-2.31,8.97-6.35,11.81-15.6,11.85
                                      C215.01,221.98,189.68,221.91,164.34,221.92z"
                                />
                            </svg>
                            Reserve
                        </button>
                    </li>
                    {showMaintenance === true && <li className="nav-item" role="presentation">
                        <button onClick={() => actionChange('maintenance')} className="nav-link" id="maintenance-tab" data-bs-toggle="tab" data-bs-target="#maintenance" type="button" role="tab" aria-controls="maintenance" aria-selected="false">
                        <i className="fa-solid fa-screwdriver-wrench"></i>
                            Maintenance
                        </button>
                    </li>}
                </ul>
                </>}
                {DataServiceDetail?.iqviaServiceEnabled === true && (
                    <>
                        <ul className="nav nav-tabs custom-tab mt-3 mb-3" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="admitted-tab" data-bs-toggle="tab" data-bs-target="#admitted" type="button" role="tab" aria-controls="admitted" aria-selected="true">
                                    <svg
                                        fill="#ffffff"
                                        width="22"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 391.23 264.5"
                                        xmlSpace="preserve"
                                    >
                                        <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                        <g>
                                            <path
                                                d="M0,0c8.36,0,16.72,0,25.5,0c0,45.85,0,90.89,0,136.39c122.14,0,243.86,0,365.73,0c0,42.7,0,84.94,0,127.41
                                                c-8.38,0-16.58,0-25.28,0c0-1.65,0-3.13,0-4.61c0-19.75-0.07-39.49,0.07-59.24c0.02-3.19-0.9-4.05-4.06-4.05
                                                c-110.83,0.08-221.66,0.08-332.49,0c-3.14,0-4.11,0.83-4.08,4.03c0.14,19.74,0.07,39.49,0.07,59.24c0,1.51,0,3.02,0,5.33
                                                c-8.73,0-17.09,0-25.46,0C0,176.34,0,88.17,0,0z"
                                            />
                                            <path
                                                d="M374.78,127.68c-85.6,0-170.37,0-255.63,0c0-2.1,0-4.09,0-6.09c0.02-11.46-0.12-22.93,0.09-34.39
                                                c0.34-18.08,9.85-27.34,28.02-27.38c29.17-0.06,58.34-0.02,87.51-0.02c37.07,0,74.14-0.01,111.21,0.01
                                                c7.96,0,15.86,1.06,21.14,7.66c2.9,3.63,5.83,8.34,6.14,12.75C374.38,95.91,374.36,111.68,374.78,127.68z"
                                            />
                                            <path
                                                d="M68.05,129.47c-19.47,0.01-35.31-15.71-35.62-35.36c-0.31-19.28,16.14-35.84,35.6-35.85
                                                c19.44-0.01,35.94,16.58,35.65,35.84C103.39,113.71,87.52,129.46,68.05,129.47z"
                                            />
                                        </g>
                                    </svg>
                                    Patient Details
                                </button>
                            </li>
                        </ul>
                    </>
                )}
                {props.Data.isDraftAdd === 'edit' && <>
                <ul className="nav nav-tabs custom-tab mt-3 mb-3" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => actionChange('admit')} className="nav-link active" id="admitted-tab" data-bs-toggle="tab" data-bs-target="#admitted" type="button" role="tab" aria-controls="admitted" aria-selected="true">
                            <svg
                                fill="#ffffff"
                                width="22"
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 391.23 264.5"
                                xmlSpace="preserve"
                            >
                                <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                <g>
                                    <path
                                        d="M0,0c8.36,0,16.72,0,25.5,0c0,45.85,0,90.89,0,136.39c122.14,0,243.86,0,365.73,0c0,42.7,0,84.94,0,127.41
                                          c-8.38,0-16.58,0-25.28,0c0-1.65,0-3.13,0-4.61c0-19.75-0.07-39.49,0.07-59.24c0.02-3.19-0.9-4.05-4.06-4.05
                                          c-110.83,0.08-221.66,0.08-332.49,0c-3.14,0-4.11,0.83-4.08,4.03c0.14,19.74,0.07,39.49,0.07,59.24c0,1.51,0,3.02,0,5.33
                                          c-8.73,0-17.09,0-25.46,0C0,176.34,0,88.17,0,0z"
                                    />
                                    <path
                                        d="M374.78,127.68c-85.6,0-170.37,0-255.63,0c0-2.1,0-4.09,0-6.09c0.02-11.46-0.12-22.93,0.09-34.39
                                          c0.34-18.08,9.85-27.34,28.02-27.38c29.17-0.06,58.34-0.02,87.51-0.02c37.07,0,74.14-0.01,111.21,0.01
                                          c7.96,0,15.86,1.06,21.14,7.66c2.9,3.63,5.83,8.34,6.14,12.75C374.38,95.91,374.36,111.68,374.78,127.68z"
                                    />
                                    <path
                                        d="M68.05,129.47c-19.47,0.01-35.31-15.71-35.62-35.36c-0.31-19.28,16.14-35.84,35.6-35.85
                                          c19.44-0.01,35.94,16.58,35.65,35.84C103.39,113.71,87.52,129.46,68.05,129.47z"
                                    />
                                </g>
                            </svg>
                            Admitted
                        </button>
                    </li>
                </ul>
                </>}
                <div className="tab-content tab-custom-height" id="myTabContent">
                    {actionValue !== 'maintenance' && <AdmitContainer selectedbed={selectedbed} isAction={actionValue} isDraft={props?.Data?.isDraftAdd} bedId={bedid} section={props?.Data?.section} isReferralAdd={props?.Data?.isReferralAdd} leadPoolingId={props?.Data?.leadPoolingId} leadnhsid={props?.Data?.leadnhsid} leadlocalid={props?.Data?.leadlocalid} bedName={bed} isDraftListAdd={props?.Data?.isDraftListAdd}/> }
                    {actionValue === 'maintenance' && <MaintenanceFormContainer isAction={actionValue} bedId={bedid} bedName={bed} closeSideMenu={props.closeSideMenu}/> }
                    {/* <div className="tab-pane fade show active" id="admitted" role="tabpanel" aria-labelledby="admitted-tab">
                        {actionValue === 'admit' && <AdmitContainer isAction={actionValue} /> }
                        
                    </div>
                    <div className="tab-pane fade" id="reserve" role="tabpanel" aria-labelledby="reserve-tab" >
                        {actionValue === 'reserve' && <AdmitContainer isAction={actionValue} />}
                        
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default VacantView;
