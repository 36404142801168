import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListContractModel } from '../../utils/model';

const initialState: ListContractModel = {
    StatusListContract: false,
    DataListContract: '',
    MessageListContract: '',
    ErrorListContract: ''
};

export const listtContractSlice = createSlice({
    name: 'listContract',
    initialState,
    reducers: {
        setListtContractAction: (state, { payload }: PayloadAction) => {
            state.StatusListContract = true;
            state.DataListContract = payload;
            state.MessageListContract = 'Success';
        },
        errorListtContractAction: (state, { payload }: PayloadAction) => {
            state.StatusListContract = false;
            state.DataListContract = [];
            state.MessageListContract = 'Failed';
            state.ErrorListContract = payload;
        },
        removeListtContractAction: (state, { payload }: PayloadAction) => {
            state.StatusListContract = false;
            state.DataListContract = '';
            state.MessageListContract = '';
            state.ErrorListContract = '';
        }
    },
    extraReducers: {}
});

export const { setListtContractAction, errorListtContractAction, removeListtContractAction } = listtContractSlice.actions;

export default listtContractSlice.reducer;
