import React, {useEffect} from 'react';
import ReferralDetailsContainer from './ReferralDetails/ReferralDetailsContainer';
import ReferralTableContainer from './ReferralTable/ReferralTableContainer';
import General_Info from '../../../assets/images/general-info-white.svg';
import Progress_Note from '../../../assets/images/progress-note-white.svg'
import Info_gethering from '../../../assets/images/Info-gathering-white.svg'
import Strength from '../../../assets/images/strength-white.svg' 
import Capacity from '../../../assets/images/capacity-white.svg'
import Care_Giver from '../../../assets/images/Care-Giver-white.svg' 
import Next from '../../../assets/images/next-white.svg' 
import { removeGridFilterStateAction } from '../../../store/general/gridFilterStateReducer';
import { useDispatch } from 'react-redux';


const ReferralManagerView = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(removeGridFilterStateAction());
        }
    }, [])
    return (
        <div className="container-fluid">
            <ReferralDetailsContainer />
            <ReferralTableContainer />

            {/* <table className="table-invoice" width="100%" cellPadding="0" cellSpacing="0" style={{background:'#ffffff'}}>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={General_Info} alt="" width={20} className="me-2" /> General Information
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                            Referrer’s Details
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="32%" valign="top">
                                    <div>Name</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Relationship with Referred Person</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td>
                                    <div>Email</div>
                                    <div className="bold">Lorem Ipsum</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Supported Person Details
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                             <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Full Name</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Title</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Preferred Name</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                       
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Current Address</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Home)</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Mobile/Other)</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Gender</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Date of Birth</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                       
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>NHS Number</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Preferred method of Communication</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top"></td>
                                </tr>
                            </table>
                        </td>
                       
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Next of Kin
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Preferred method of Communication</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Address</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Home)</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Mobile/Other)</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Who to contact if not next of kin
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Relationship</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Address</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Home)</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Mobile/Other)</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top"></td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Care Manager or Social Worker
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Address</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr> 

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Name of G.P.
                            </div>
                        </td>
                    </tr> 
                     <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td width="32%" valign="top">
                                        <div>Address</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>Email</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>Telephone</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Assessment Detail
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Is there an assessment from the local authority or other?</label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input type="radio" name="radio1" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input type="radio" name="radio1" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Other Professionals Involved <span className='font-light'><i>(Therapists, Care Providers, Diabetes Nurse etc.)</i></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Name</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Work Base</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td width="32%" valign="top">
                                        <div>Telephone</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top"></td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top"></td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Funding <span className='font-light'><i>(Who will be funding the support?)</i></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td width="32%" valign="top">
                                        <div>Local Authority</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>CHC</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>Personal Budget</div>
                                        <div className="bold margin-bottom-10">Lorem Ipsum</div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Is this a jointly funded package?</label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input type="radio" name="radio2" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input type="radio" name="radio2" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Are there any risk factors that we need to be aware of prior to visiting the persons current home? <span className='font-light'><i>(Covid-19, accessibility, pets, poor lighting etc.)</i></span>
                            </div>
                            <div className="bold margin-bottom-10">Lorem Ipsum</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Does the person have a primary diagnosis? <span className='font-light'><i>(For example: learning disabilities, autism, mental ill-health, COPD)</i></span>
                            </div>
                            <div className="bold margin-bottom-10">Lorem Ipsum</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Are there any other health needs/conditions?{' '}
                                <span className='font-light'><i>(For example: mobility difficulties, swallowing difficulties, complex behaviours, epilepsy, ABI, physical ill-health, sensory loss)</i></span>
                            </div>
                            <div className="bold margin-bottom-10">Lorem Ipsum</div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Positive Behaviour Support
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Does the person require a Positive Behaviour Support (PBS) Plan</label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input type="radio" name="radio3" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input type="radio" name="radio3" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Does the person have a current PBS Plan</label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input type="radio" name="radio4" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input type="radio" name="radio4" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Is PBS involvement required for the purpose of this assessment</label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input type="radio" name="radio5" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input type="radio" name="radio5" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Consent and Best Interest
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Can the person consent to the assessment, their care, treatment and accommodation?</label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                    <input type="radio" name="radio61" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                    <input type="radio" name="radio61" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                            <label>Where it has been identified the person lacks capacity, has there been a Mental Capacity Assessment and Best Interest Decision?*</label>

                            <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                <input type="radio" name="radio6" />
                                <span className="ms-1">Yes</span>
                            </label>

                            <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                <input type="radio" name="radio6" />
                                <span className="ms-1">No</span>
                            </label>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                How have you heard of Valorum Care Group?
                            </div>
                            <div className="bold margin-bottom-10">Lorem Ipsum</div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Name of person completing General Enquiry Information Form
                            </div>
                            <div className="bold margin-bottom-10">Lorem Ipsum</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '15px' }}>
                                <tr>
                                    <td className="modal-title-header">
                                        <div className="white-text">
                                            <img src={Progress_Note} alt="" width={20} className="me-2" /> Progress Notes
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                </tr>
                <tr>
                    <td>
                        <p className='purple-text text-center bold mt-3 mb-3'>Office use only</p>
                    </td>
                </tr>
                <tr>
                        <td>
                            <table  width="100%" cellPadding="0" cellSpacing="0"> 
                                <tr>
                                    <td width="10%">
                                        <div className="d-flex">
                                            <label>Added to Referrals Log</label>

                                            <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                                <input type="radio" name="radio1" />
                                                <span className="ms-1">Yes</span>
                                            </label>

                                            <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                                <input type="radio" name="radio1" />
                                                <span className="ms-1">No</span>
                                            </label>
                                        </div>
                                    </td>
                                    <td width="30%">
                                        <div className="d-flex">
                                            <label>Progress to assessment</label>

                                            <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                                <input type="radio" name="radio1" />
                                                <span className="ms-1">Yes</span>
                                            </label>

                                            <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                                <input type="radio" name="radio1" />
                                                <span className="ms-1">No</span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                </tr>
                <tr>
                    <div className='d-flex'>
                        <label>Date added:</label>
                        <p className='bold ms-2'>Lorem Ipsum</p>
                    </div>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-3 mb-1'>Progress Notes</p>
                        <p><i>Complete table below: for example, informing person/Social Worker etc. whether progressing, date of any follow up appointment to progress, if not progressing letting the person know etc.</i></p>
                        <p className='bold purple-text mt-3 mb-1'>If not progressing to assessment at this stage, please state reason given to referrer</p>
                        <p className='bold'>Lorem Ipsum</p>
                        <p className='bold purple-text mt-3 mb-3 text-center'>Progress Notes</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='table-radius' style={{margin:'0 auto',width:'70%'}}>
                        <table width="100%" cellPadding="0" cellSpacing="0" className="table table-progress mb-0" >
                            <tr>
                                <th>DATE</th>
                                <th>PROGRESS/COMMENTS</th>
                                <th>STAFF INITIALS</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>Lorem Ipsum</p>
                                </td>
                                <td>
                                    <p>Lorem Ipsum</p>
                                </td>
                                <td>
                                    <p>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Lorem Ipsum</p>
                                </td>
                                <td>
                                    <p>Lorem Ipsum</p>
                                </td>
                                <td>
                                    <p>Lorem Ipsum</p>
                                </td>
                            </tr>
                        </table>
                        </div>
                        
                    </td>
                </tr>
                <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '15px',marginBottom: '15px' }}>
                                <tr>
                                    <td className="modal-title-header">
                                        <div className="white-text">
                                            <img src={Info_gethering} alt="" width={18} className="me-2" /> Service User Profile
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What do you like to be called?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What people like / admire about you?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What’s important to you now?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What are your hobbies and interests?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What’s important to you for the future?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What are your hopes and dreams?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What are your Likes…</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What are your dislikes…</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-3'>Relationship Circle</p>
                        <p className='bold purple-text mt-2 mb-2'>Family</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-2'>People you work with</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-2'>Home and other paid supporters</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-2'>Friends and non-paid relationships</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Strength} alt="" width={20} className="me-2" /> Strengths Based Assessment
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>1.</span>
                        <div>
                            <span className='purple-text bold'>How I Communicate - </span>
                            <span className='purple-text font-light '><i>How do you express your choices, preferences and emotions - happiness, sadness, frustration, and anger? Some people may communicate by engaging in behaviours of concern, please ensure this is detailed and the PBS assessment is completed in line with this.</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Is English your first language?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Do you use gestures, pictures, Makaton, sign language?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Do you use any assistive technology?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>How well do you understand verbal and non-verbal communication?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>How well do you understand written information (letters, texts, email, written instructions on packages, timetables)</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What is important for us to know to support you with your communication?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are there any behaviours of concern in relation to how the person expresses themselves?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Does this person require input from the PBS team?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>How many hours per week?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you with your Communication?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>2.</span>
                        <div>
                            <span className='purple-text bold'>How to support me with my daily living skills - </span>
                            <span className='purple-text font-light '><i>Cooking, shopping, cleaning, laundry, knowing the time of day/night</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Cooking</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Shopping</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Cleaning</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Laundry</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Tidying</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Knowing time of day/night</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Do you have any goals you wish to achieve in this area?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you with aspects of daily living activities?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>


                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>3.</span>
                        <div>
                            <span className='purple-text bold'>How to support me with my personal care? - </span>
                            <span className='purple-text font-light '><i>What support do I need for personal care needs - Eating and drinking, dressing and undressing, controlling your body temperature, washing, skin care, hair care, oral care, shaving, using the toilet, etc.</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Mobility and Equipment required</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Dressing/undressing</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Washing/Bathing</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Oral Care</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Nutritional Care</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Continence Care</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label className='mb-1'>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p><i>(SALT assessment, Manual Handling assessment, Other professional assessments)</i></p>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you with your Personal care?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>4.</span>
                        <div>
                            <span className='purple-text bold'>How do I stay healthy and well - </span>
                            <span className='purple-text font-light '><i>exercise, mobility, history of falls, health conditions- asthma allergies etc. Diet and nutrition. Health appointments – opticians, dentist chiropody, hearing, GP’s, 7 sensory needs taste, touch, smell, hearing, sight, balance, spatial awareness/movement. Would I like to have an end of life plan developed?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='mt-1 mb-2 bold'>Add/delete dates with professionals/appointments as required</p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Date of last known GP Visit if applicable</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Date of last known Dentist appointment if applicable</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Date of last known Chiropody Appointment if applicable</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Date of last known Opticians Appointment if applicable</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Dates of known falls if applicable</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Is end of life planning being considered/in place?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <p className='mt-2 mb-2'>Are there any risks when undertaking these activities and is a risk assessment required</p>
                                <div className="d-flex mt-2">
                                    <label>Do you have an annual health check</label>
                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">Yes</span>
                                    </label>

                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">No</span>
                                    </label>
                                </div>
                                <div className="d-flex mt-2">
                                    <label>Do you have a health action plan/passport</label>
                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">Yes</span>
                                    </label>

                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">No</span>
                                    </label>
                                </div>
                                <div className="d-flex mt-2">
                                    <label>Is an Anticipatory care Calendar required?</label>
                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">Yes</span>
                                    </label>

                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">No</span>
                                    </label>
                                </div>
                                <div className="d-flex mt-2">
                                    <label>Do you require any specialist equipment?</label>
                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">Yes</span>
                                    </label>

                                    <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                        <input type="radio" name="radio18" />
                                        <span className="ms-1">No</span>
                                    </label>
                                    
                                </div>
                                <p>(if yes is it fit for purpose and has it been regularly serviced?) YES, Specialist vehicle</p>
                                <p className='mt-2 bold'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you to stay healthy and well?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>5.</span>
                        <div>
                            <span className='purple-text bold'>How I spend my time and maintain my social wellbeing - </span>
                            <span className='purple-text font-light '><i>Socialising and leisure, how you occupy your time, education or work, creating and maintaining social networks (belonging, contribution to society), relationships (high quality), keeping in touch with family and friends. Hobbies and interests, can you travel independently?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Who and what is important to you?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What hobbies do you have?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What do you get the most enjoyment out of?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Can you travel independently?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>How do you stay in touch with family and friends?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you with your Occupational and Social Wellbeing?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>6.</span>
                        <div>
                            <span className='purple-text bold'>How I maintain my emotional and mental wellbeing - </span>
                            <span className='purple-text font-light '><i>feelings of anxiety, depression, bereavement, loneliness/isolation etc.</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you undertaking any treatments for emotional and mental wellbeing?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Do you get worried or anxious?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What can trigger feelings of anxiety, depression and loneliness?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What helps you to feel better?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Is there anything you wish us to be made aware of?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you with your emotional and mental wellbeing?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>7.</span>
                        <div>
                            <span className='purple-text bold'>How to support me with my medication? - </span>
                            <span className='purple-text font-light '><i>List of current medication, does the person understand why they take medication, who prescribes medication, where is it dispensed from?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Details: List of medication include time due, dosage and what it is taken for</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>How is your medication currently managed? Is it blister packed?*</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign='top' colSpan={3}>
                                    <label>What level of support do you need with your medication? Prompting, preparing, storing, ordering, administering, reviewing</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                               
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>I am independent with this</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you to manage and take your medication?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>8.</span>
                        <div>
                            <span className='purple-text bold'>How I manage my housing and finances - </span>
                            <span className='purple-text font-light '><i>Where and who would you like to live with? Do you need support to manage your tenancy how do you maintain your tenancy? Are you able to maintain a safe environment, can you deal with repairs? How do you manage your finances? Do you need support to pay your bills and budget your money? Have your benefits been reviewed in the last 12 months?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Where would you prefer to live?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Do you need support with your benefits?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Do you need any support with managing your tenancy agreement?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What support do you need for your finances?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you able to access your banking with or without support?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>You are independent with this</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name. Father and staff</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like Valorum Care Group to support you to develop skills to manage your Housing and Finances?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Capacity} alt="" width={20} className="me-2" /> Capacity & Legal Orders and Additional Information
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top' colSpan={3}>
                                    <label>Is a capacity assessment required in relation to where the person lives and how they are supported for their care and treatment?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>If yes is a copy of the capacity assessment available?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Have you ever undergone any other capacity checks or assessments?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Is there a Lasting Power of Attorney or deputyship?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Have you made an advanced decision in relation to care and support?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Do you have a Depravation of Liberty safeguard order (DOLs) in place?</label>
                                    <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    
                                </td>
                            </tr>
                          
                        </table>
                       <p className='bold purple-text mt-2 mb-2'>Under section 3</p>
                       <tr>
                            <td valign='top'>
                                <label>Are there any Mental Health Act orders/sections in place?</label>
                                <p className='bold mt-1 mb-1'>Lorem Ipsum</p>
                            </td>
                        </tr>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Care_Giver} alt="" width={20} className="me-2" /> Support Worker/ Care Giver
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                          
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What kind of person would you like to support you?</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Skills needed</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Desirable characteristics / personality</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Interests</label>
                                    <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}> 
                                    <p>Support Required <i>(Specify if 1:1, 2:1, additionally complete needs assessments relevant to service, etc)</i></p>

                                    <div className="table-radius mt-3">
                                        <table className="table table-progress mb-0">
                                            <tr>
                                                <th>DAY</th>
                                                <th>AM</th>
                                                <th>PM</th>
                                                <th>NIGHT</th>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Monday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Tuesday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Wednesday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Thursday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Friday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Saturday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='ps-2'>Sunday</td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                                <td>
                                                    <p className='bold'>Lorem Ipsum</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <label>CONSENT FOR KEEPING AND SHARING INFORMATION OBTAINED</label>
                                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                                            <input type="radio" name="radio21" />
                                            <span className="ms-1">Yes</span>
                                        </label>

                                        <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                                            <input type="radio" name="radio21" />
                                            <span className="ms-1">No</span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                           
                        </table>
                       
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Next} alt="" width={15} className="me-2" /> What Happeens Next?
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <td>
                                <tr>
                                    <td valign='top'>
                                        <label>Inform the person of the next stages of this process and provide them with any relevant information. List below.</label>
                                        <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                        <label>Are there any specialist training requirements needed to support the person?</label>
                                        <p className='bold mt-1 mb-3'>Lorem Ipsum</p>
                                    </td>
                                </tr>
                            </td>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="70%" cellPadding="0" cellSpacing="0" style={{margin:'0 auto'}}>
                            <tr>
                                <td width="48%">
                                <div className='border-layout p-2 mt-3'>
                                    <p className='purple-text bold mb-3'>Assessment completed by</p>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Name</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Signature</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Position</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Date</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                </div>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                <div className='border-layout p-2 mt-3'>
                                    <p className='purple-text bold mb-3'>Assessment completed by</p>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Name</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Signature</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Position</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Date</label>
                                        <p className='bold mb-2'>Lorem Ipsum</p>
                                    </div>
                                </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '25px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Next} alt="" width={15} className="me-2" /> Service User’s Diversity Profile
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p>Valorum is keen to ensure it provides equality of access to all our services, regardless of issues of diversity. We are required to produce statistical information about the types of people we provide services to. Therefore, please excuse us for asking the following questions, which may seem very personal, but it is important for us to have this information. However, you do not have to complete any of the following questions, if you prefer not to.</p>
                        <div className="mt-3">
                            <label className='purple-text bold mb-3'>Ethnic origin, as defined by the supported person
                            </label> 
                            <div className='d-flex flex-wrap'>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">White</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">British</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Irish</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Mixed</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">White Black Caribbean</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">White Black African</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">White Asian</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Asian / Asian British</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Indian</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Pakistani</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Bangladeshi</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Black / Black British</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Caribbean</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">African</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Other groups</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Chinese</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Refused to state</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Other</span>
                                </label>
                            </div>
                        </div>

                        <div className="mt-3">
                            <label className='purple-text bold mb-3'>How would you describe your religious beliefs?
                            </label> 
                            <div className='d-flex flex-wrap'>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Buddhist</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Jewish</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Christian</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Muslim</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Hindu</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">Sikh</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1">None</span>
                                </label>
                            </div>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio22" />
                                    <span className="ms-1 snowrap me-2">Other <span><i> (please state):</i></span></span>
                                    
                                    <p className='bold'>Lorem Ipsum</p>
                                </label>
                        </div>

                        <div className="mt-3">
                            <label className='purple-text bold mb-3'>Is English your first language?
                            </label> 
                            <div className='d-flex flex-wrap'>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio23" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio23" />
                                    <span className="ms-1">No</span>
                                </label>
                                
                            </div>
                            <div className='d-flex align-items-center'>
                                <label className='me-2'>If not, please state which language is?</label>
                                <p className='bold'>Lorem Ipsum</p>
                            </div>
                        </div>


                        <div className="mt-3">
                            <label className='purple-text bold mb-3'>Which age group are you?
                            </label> 
                            <div className='d-flex flex-wrap'>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio24" />
                                    <span className="ms-1">16 - 19</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio24" />
                                    <span className="ms-1">20 - 25</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio24" />
                                    <span className="ms-1">26 - 39</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio24" />
                                    <span className="ms-1">40 - 64</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio24" />
                                    <span className="ms-1">65 - 80</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio24" />
                                    <span className="ms-1">Over 80</span>
                                </label>
                            </div>
                            
                        </div>


                        <div className="mt-3">
                            <label className='purple-text bold mb-3'>How would you describe your sexuality?
                            </label> 
                            <div className='d-flex flex-wrap'>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio25" />
                                    <span className="ms-1">Hetero sexual</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio25" />
                                    <span className="ms-1">Bi-sexual</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio25" />
                                    <span className="ms-1">Gay</span>
                                </label>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio25" />
                                    <span className="ms-1">Other</span>
                                </label>
                            </div>
                            
                        </div>


                        <div className="mt-3">
                            <label className='purple-text bold mb-3'>Do you have a disability or mobility problem?
                            </label> 
                            <div className='d-flex flex-wrap'>
                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio26" />
                                    <span className="ms-1">Yes</span>
                                </label>

                                <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                    <input type="radio" name="radio26" />
                                    <span className="ms-1">No</span>
                                </label>
                            </div>
                            
                        </div>



                    </td>
                </tr>





            </table> */}
        </div>
    );
};

export default ReferralManagerView;
