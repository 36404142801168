import React, { useEffect, useState } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';
import { colors } from '@mui/material';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    className: any;
    footer: any;
    reason: any;
    updateData: (e: any) => any;
    submitalert: () => any;
    costingData: any;
    nhsUserPooledLeadForReject: boolean;
    reasonNHS: any;
    isNhsAction: boolean
}

const customStyles = {
    option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
            ...base,
            backgroundColor: isFocused ? '#e9e9e9' :'inherit',
            color: '#4e4851'
        };
    },
    menuPortal: (base: any) => {
        return {
            ...base,
            zIndex: 9999
        };
    }

};

const CostRejectModalView = (props: ModalProps) => {
    const [radioChecked, setRadioChecked] = useState<any>(false);
    const [radioChecked1, setRadioChecked1] = useState<any>(false);
    const [radioCheckedVal, setRadioCheckedVal] = useState<any>('');
    const [options, setoptions] = useState<any>([]);
    const [optionsNhs, setoptionsNhs] = useState<any>([]);
    let reasons: any = [];
    let reasonNHSList: any = [];
    useEffect(() => {
        if (props?.reason) {
            if (props?.reason.length > 0) {
                reasons = [];

                props?.reason?.map((resn: any) => {
                    reasons.push({ value: resn.rrrId, label: resn.reasonText, name: 'reason' });
                });
                let opt = reasons;
                setoptions(opt);
            }
        }
    }, [props?.reason]);

    useEffect(() => {
        if (props?.isNhsAction && props?.reasonNHS?.result) {
            if (props?.reasonNHS?.result?.length > 0) {
                reasonNHSList = [];

                props?.reasonNHS?.result?.map((resn: any) => {
                    reasonNHSList?.push({ value: resn.id, label: resn.label, name: 'reason' });
                });
                let opt = reasonNHSList;
                setoptionsNhs(opt);
            }
        }
    }, [props?.reasonNHS?.result]);
    useEffect(() => {
        if (props.nhsUserPooledLeadForReject) {
            setRadioChecked1(true);
        }
    }, [props.nhsUserPooledLeadForReject])

    const handleRadioChange = (e: any) => {
        let value = e.target.value;
        if (value === "withcharge") {
            setRadioChecked(true)
            setRadioChecked1(false)
        }
        else {
            setRadioChecked1(true)
            setRadioChecked(false)
        }
        setRadioCheckedVal(value);
        props.updateData(e);

    }
    const commonStringValidator = Yup
        .string()
        .matches(/^(?:\d*\.\d{1,2}|\d+)$/)
        .required("Is required");
    const { t } = useTranslation();
    const [submit, setsubmit] = useState<boolean>(false);
    const validationSchema = Yup.object().shape({
        charge: Yup.string(),
        //reason: Yup.string().ensure().required(),
        reason: Yup
            .object()
            .shape({
                label: radioCheckedVal === 'withcharge' ? Yup.string().notRequired() : Yup.string().required(),
                value: radioCheckedVal === 'withcharge' ? Yup.string().notRequired() : Yup.string().required()
            })
            .nullable() // for handling null value when clearing options via clicking "x"
        //.required()
        ,
        desc: Yup.string().when("charge", (val, schema) => {
            if (radioCheckedVal === 'withcharge') return Yup.string().required();
            else return Yup.string().notRequired();
        }),
        CurrentCost: Yup.string().when("charge", (val, schema) => {
            if (radioCheckedVal === 'withcharge') return commonStringValidator;
            else return Yup.string().notRequired();
        }),
        ExpectedCost: Yup.string().when("charge", (val, schema) => {
            if (radioCheckedVal === 'withcharge') return commonStringValidator;
            else return Yup.string().notRequired();
        }),
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    useEffect(() => {
        if (formState.isValid) {
            setsubmit(true);
        }
        else {
            setsubmit(false);
        }
    }, [formState])

    useEffect(() => {
        if (props.costingData !== undefined && !props.nhsUserPooledLeadForReject) {
            setValue('CurrentCost', props.costingData.total || '');
        } else {
            reset();
        }
    }, [props.costingData]);


    return (
        <div>
            <ModalWrapperContainer
                title="Rejection"
                onClose={props.onClose}
                className="modal modal-extrasmall costing-modal"
                content={
                    <div>
                        {!props.nhsUserPooledLeadForReject ? (
                            <>
                                <label>Do you want to send request for revise costing?</label>
                                <div className='d-flex mt-2 mb-1'>
                                    <label className="form--radio-label mb-2 d-flex align-items-center me-3">
                                        <input
                                            onChange={handleRadioChange}
                                            type="radio"
                                            name={'charge' as const}
                                            id="withcharge"
                                            value="withcharge"
                                        />
                                        <span className="ms-1">Yes</span>
                                    </label>
                                    <label className="form--radio-label mb-2 d-flex align-items-center">
                                        <input
                                            onChange={handleRadioChange}
                                            type="radio"
                                            name={'charge' as const}
                                            id="withoutcharge"
                                            value="withoutcharge"
                                        />
                                        <span className="ms-1">No</span>
                                    </label>
                                </div>
                            </>
                        ) : null}

                        {radioChecked &&
                            <div>
                                <label className='mb-2'>Current Costing (£)</label>
                                <input type="text"
                                    {...register('CurrentCost', {
                                        onChange: (e: any) => {
                                            props.updateData(e);
                                        }
                                    })}
                                    name={'CurrentCost' as const}
                                    id="CurrentCost"
                                    //@ts-ignore
                                    className={`disabled form-control white ${errors.CurrentCost ? 'is-invalid' : ''}`} />

                                <label className='mb-2 mt-2'>Expected Costing (£) </label>
                                <input type="text"
                                    {...register('ExpectedCost', {
                                        onChange: (e: any) => {
                                            props.updateData(e);
                                        }
                                    })}
                                    name={'ExpectedCost' as const}
                                    id="ExpectedCost"
                                    //@ts-ignore
                                    className={`form-control white ${errors.ExpectedCost ? 'is-invalid' : ''}`} />

                                <label className='mb-2 mt-2'>Reason for rejection</label>
                                {/* <select
                                    {...register('reason', {
                                        onChange: (e: any) => {
                                            props.updateData(e);
                                        }
                                    })}
                                    name={'reason' as const}
                                    id="reason"
                                    //@ts-ignore
                                    className={`form-control white ${errors.reason ? 'is-invalid' : ''}`}>
                                    <option value="">{t('Reject_Modal.Reason')}</option>
                                    {props?.reason !== undefined &&
                                        props?.reason?.map((resn: any) => {
                                            return (
                                                <option key={resn.rrrId} value={resn.rrrId}>
                                                    {resn.reasonText}
                                                </option>
                                            );
                                        })}
                                </select> */}
                                <Controller
                                    name="reason"
                                    control={control}
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        return <Select
                                            {...fieldProps}
                                            //isClearable // enable isClearable to demonstrate extra error handling
                                            isSearchable={props.isNhsAction ? optionsNhs.length > 3 ? true : false : options.length > 3 ? true : false}
                                            className="selectbox"
                                            options={props.isNhsAction ? optionsNhs : options}
                                            placeholder="Select Reason"
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            value={value}
                                            onChange={selectedOption => {
                                                fieldProps.onChange(selectedOption);
                                                let event = { target: { name: 'reason', value: selectedOption.value } }
                                                props.updateData(event)
                                            }}
                                            maxMenuHeight={150}
                                        />
                                    }}
                                />

                                <p>{errors.reason?.message}</p>

                                <label className='mb-2 mt-2'>Comment*</label>
                                {/* <textarea className='form-control white' rows={2}></textarea> */}
                                <textarea
                                    {...register('desc', {
                                        onChange: (e: any) => {
                                            props.updateData(e);
                                        }
                                    })}
                                    name={'desc' as const}
                                    className={`form-control white mb-3 ${errors.desc ? 'is-invalid' : ''}`}
                                    placeholder="Comment*"
                                    rows={2}
                                ></textarea>
                            </div>
                        }
                        {radioChecked1 &&
                            <div>
                                <label className='mb-2'>Reason for rejection*</label>
                                {/* <select
                                    {...register('reason', {
                                        onChange: (e: any) => {
                                            props.updateData(e);
                                        }
                                    })}
                                    name={'reason' as const}
                                    id="reason"
                                    className={`form-control white ${errors.reason ? 'is-invalid' : ''}`}>
                                    <option value="">{t('Reject_Modal.Reason')}</option>
                                    {props?.reason !== undefined &&
                                        props?.reason?.map((resn: any) => {
                                            return (
                                                <option key={resn.rrrId} value={resn.rrrId}>
                                                    {resn.reasonText}
                                                </option>
                                            );
                                        })}
                                </select> */}

                                <Controller
                                    name="reason"
                                    control={control}
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        return <Select
                                            {...fieldProps}
                                            isSearchable={props.isNhsAction ? optionsNhs.length > 3 ? true : false : options.length > 3 ? true : false}
                                            options={props.isNhsAction ? optionsNhs : options}
                                            placeholder="Select Reason"
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            value={value}
                                            onChange={selectedOption => {
                                                fieldProps.onChange(selectedOption);
                                                let event = { target: { name: 'reason', value: selectedOption.value } }
                                                props.updateData(event)
                                            }}
                                            maxMenuHeight={150}

                                        />
                                    }}
                                />
                                <p>{errors.reason?.message}</p>
                                <label className='mb-2 mt-2'>Comment</label>
                                {/* <textarea className='form-control white' rows={2}></textarea> */}
                                <textarea
                                    {...register('desc', {
                                        onChange: (e: any) => {
                                            props.updateData(e);
                                        }
                                    })}
                                    name={'desc' as const}
                                    className={`form-control white mb-3`}
                                    placeholder="Comment"
                                    rows={2}
                                ></textarea>
                            </div>
                        }
                    </div>
                }
                footer={
                    <div>
                        <button disabled={!submit} onClick={props.submitalert} className='btn btn-primary'>{t('Reject_Modal.Submit_btn')}</button>
                    </div>
                }

            />
        </div>
    );
};

export default CostRejectModalView;