
import React, { useCallback, useEffect, useState } from 'react';
import PatientInquiryTabContanier from './PatientInquiryTab/PatientInquiryTabContanier';
import eventEmitter from '../../../utils/eventEmitter';
import { FilterType, PatientInquiryResponseType } from './types';
import { GetPatientInquiryList } from '../../../service/patient-service';
import swal from 'sweetalert';
import { debounce } from 'lodash';
import { rightNavigationPage } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../store/dashboard/navigationReducer';
import { removeLeadInformationAction } from '../../../store/lead/getLeaddataReducer';

const PatientInquiryView = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>("");
    const [filter, setFilter] = useState<FilterType>({ leadOptionId: 1, leadOption: "all" });
    const [patientInquiryData, setPatientInquiryData] = useState<PatientInquiryResponseType[]>([]);
    const [patientInquiryError, setPatientInquiryError] = useState<string>("");

    const [totalCount, setTotalCount] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currPage, setCurrPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);

    const [limit] = useState<number>(10);
    const [skip, setSkip] = useState<number>(0);

    const handlerOpenRightBar = (pageName: string) => {
        let dataObj = {
            isheader: true,
            fleadstatus: 1,
        }
        if (pageName === rightNavigationPage.CreateLead) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.CreateLead,
                Data: dataObj,
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        }
    }

    const handleOpenLeadContainer = () => {
        dispatch(removeLeadInformationAction());
        handlerOpenRightBar('CreateLead');
        eventEmitter.emit('eventOpenLeadContainer', true);
        eventEmitter.emit('eventOpenNHSContainerStateChanger', false);
    }

    const getLeadListData = async (searchValue?: string) => {
        setIsLoading(true);
        try {
            const query = {
                limit,
                skip,
                LeadOptionId: filter.leadOptionId,
                ...(searchValue ? { searchValue } : {}),
            }
            const resultData = await GetPatientInquiryList(query);
            if (resultData.status === true && resultData.data !== undefined && resultData.data !== null) {
                setPatientInquiryError("");
                setTotalCount(resultData.data.totalCount);
                setPatientInquiryData(resultData.data.data);
            } else {
                setTotalCount(0);
                setPatientInquiryData([]);
                setPatientInquiryError(resultData.message);
            }
        } catch (error: any) {
            swal(error?.message, {
                icon: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    }

    const delayedQuery = useCallback(debounce((searchValue) => getLeadListData(searchValue), 500), []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        setSearch(searchValue);
        delayedQuery(searchValue);
    }

    return (
        <div>
            <div className='d-flex justify-content-between mt-2'>
                <div className="position-relative bold me-1 patient-search-parent">
                    <input
                        type="text"
                        placeholder="Search"
                        name="Search"
                        value={search}
                        onChange={(e) => handleSearch(e)}
                        className="form-control patient-search-input"
                    />
                    <i className="fa-sharp fa-solid fa-magnifying-glass patient-search-icon"></i>
                </div>
                <div className="btn btn-primary patient-add-icon" onClick={handleOpenLeadContainer}>
                    <h3 className="d-flex justify-content-between mb-0" title='plus-icon'>
                        <i className="fa-solid fa-user-plus"></i>
                    </h3>
                </div>
            </div>

            <div>
                <PatientInquiryTabContanier
                    filter={filter}
                    setFilter={setFilter}
                    patientInquiryData={patientInquiryData}
                    patientInquiryError={patientInquiryError}
                    totalCount={totalCount}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    currPage={currPage}
                    setCurrPage={setCurrPage}
                    limit={limit}
                    skip={skip}
                    setSkip={setSkip}
                    getLeadListData={getLeadListData}
                    isLoading={isLoading}
                    setPatientInquiryData={setPatientInquiryData}
                />
            </div>
        </div>
    );
};

export default PatientInquiryView;