import React, { useState, useEffect } from 'react'; 

//@ts-ignore
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
//@ts-ignore
import { Editor } from "react-draft-wysiwyg";
//@ts-ignore
import draftToHtml from "draftjs-to-html";
//@ts-ignore
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const WYSIWYGEditor = (props: any) => {
    const [ editorState, setEditorState ] = useState<any>(EditorState.createEmpty());
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);        
        props.onChange(            
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );        
        if (props?.rawText != undefined)
        {
            props?.rawText(editorState.getCurrentContent().getPlainText());
        }
        
    };
    useEffect(() => {
        if(props?.initialContent !== undefined && props?.initialContent !== null) {
            const contentBlocks = htmlToDraft(props.initialContent)
            const contentState = ContentState.createFromBlockArray(contentBlocks)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
        }
    }, [props.initialContent])

    const handleBeforeInput = (input:any) => {        
        const textLength = editorState.getCurrentContent().getPlainText().length;
        if (props?.maxlength>0) {
          if ((input.length + textLength) > props?.maxlength) {
            return 'handled';
          }
        }
      };
      const handlePastedText = (pasteVal:any) => {        
        const textLength = editorState.getCurrentContent().getPlainText().length;        
        if (props?.maxlength>0) {
            return ((pasteVal.length + textLength) > props?.maxlength)           
        }
      };
    return (
        <React.Fragment>
            <div className="editor">
                <Editor
                    editorState={editorState}
                    wrapperClassName='wrapper-class'
                    editorClassName='editor-class editor-custom-class'
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                      }}
                    readOnly={props.readOnly}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                    placeholder = {props.placeholder}
                />
            </div>
        </React.Fragment>
    );
};

export default WYSIWYGEditor;