import React, {useState, useEffect} from 'react';
import DropZone from './DropZone/DropZone';
import FileTile from './FileTile/FileTile';

const MultipleFileUploader = (props: any) => {
    const [uploadPercent, setUploadPercent] = useState<any>(0);
    const [uploadFiles, setUploadFiles] = useState<any>([]);
    const [uploadFilesData, setUploadFilesData] = useState<any>([]);

    const maxFileUploadError = "Maximum only 5 attachments are allowed."
    const fileSizeError = "Total size of all attachments cannot exceed 5 MB."
    const fileTypeError = "File type is not supported."

    const maxSize = 5;
    const allowedMimeTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv", "image/png", "image/jpeg", "image/svg+xml"]
    const fileSize = (size:number) => {
        return (size / 1024) / 1024;
    }
    const fileType = (type:any) => {
        // return type === 'application/pdf';
        return allowedMimeTypes.includes(type);
    }
    const validateFile = (files: any) => {
        let getError: any = false
        if((uploadFiles.length + files.length) > 5) {
            getError = true
            let error = {
                type: 'maxFileUpload',
                maxFileUploadError: maxFileUploadError
            }
            props.errorsMFU(error)
            return false
        }
        files.forEach((f: any, i: any) => {
            const { size, type } = f;
            if(fileType(type) === false) {
                getError = true
                let error = {
                    type: 'fileType',
                    fileTypeError: fileTypeError
                }
                props.errorsMFU(error)
                return false
            }
        });
        let allFileSize = fileSize(files.reduce((n: any, {size}: any) => n + size, 0));
        if(allFileSize > maxSize) {
            getError = true
            let error = {
                type: 'fileSize',
                fileSizeError: fileSizeError
            }
            props.errorsMFU(error)
            return false
        }
        if(getError === true) {
            return false
        } else {
            props.removeError()
            return true;
        }
    }
    const handleFile = async (file: File, id: string) => {
        const { name, size, type, lastModified } = file;
        const percent = 100;
        setUploadFiles((uploadFiles: any) => [...uploadFiles, { name, size, type, lastModified, percent }]);
        setUploadFilesData((uploadFilesData: any) => [...uploadFilesData, file]);
    };

    const handleDropFiles = (files: any) => {
        validateFile(files)
        if(validateFile(files)) {
            files.forEach((f: any, i: any) => handleFile(f, `${new Date().getTime()}-${i}`));
        }
    };

    const updateProgerss = (p: any) => {
        const intervel = setTimeout(() => {
            if (p > 100) {
                clearInterval(intervel);
                return;
            }

            uploadFiles.forEach((f: any) => (f.percent = p));
            setUploadPercent(p + 1);
            updateProgerss(p + 1);
            //setUploadFiles([...uploadFiles]);
        }, 100);
    };

    const handleSubmit = () => {
        setUploadPercent(0);
        updateProgerss(uploadPercent);
    };

    const handleRemove = (f: any) => {
        props.removeError()
        setUploadFiles(uploadFiles.filter((x: any) => x.name !== f.name));
        setUploadFilesData(uploadFilesData.filter((x: any) => x.name !== f.name));
    };

    useEffect(() => {
        props.uploadFilesData(uploadFilesData)
    }, [uploadFilesData])

    const createFile = (data: any) => {
        let enc = window.atob(data.attachmentBase64);
        return new File([enc], data.attachmentFileName, {
            type: data.attachmentType
        });
    }
    useEffect(() => {
        if(props.attachment !== undefined && props.attachment !== null) {
            let files = []
            files.push(createFile(props.attachment))
            handleDropFiles(files)
        }
    }, [props.attachment])

    return (
        <div className="app">
            <div className="file-list">
                {uploadFiles.map((f: any, i: any) => (
                    <FileTile key={i} fileIndex={i} file={f} onRemove={handleRemove} />
                ))}
            </div>
            <DropZone files={uploadFiles} onDropFiles={handleDropFiles} />
        </div>
    );
};

export default MultipleFileUploader;
