'use client'
import React, { useEffect, useState } from 'react';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import ClientPortal from '../../../../../../../components/ClientPortal';
import { useTranslation } from 'react-i18next';

const AboutUsView = (props: any) => {
    const { t } = useTranslation();
    const [showFullMessage, setShowFullMessage] = useState<boolean>(false);
    useEffect(() => {
        AOS.init();
    }, [])

    const handleSeeMoreModal = (event: any) => {
        event.preventDefault();
        setShowFullMessage((prevState) => !prevState);
    }
    const viewMore = () => {
        setShowFullMessage(!showFullMessage);
    };
    useEffect(() => {
        if (showFullMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullMessage]);
    return (
        <div>
            <h4 className='small-title indigo-text bolder' data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">{t("Overview.About_Us")}</h4>
            {props?.serviceDescription && props?.serviceDescription !== null &&
                <p className="border-top pt-3 font15">{props?.serviceDescription?.substring(0, 1100) + (props?.serviceDescription?.length > 1100 ? '...' : '')}
                    {props?.serviceDescription?.length > 1100 && <a className="view-more-text bold hand-icon" onClick={viewMore}>view more</a>}
                </p>}
            <ClientPortal selector="myportal" show={showFullMessage}>
                <div className="overlay" onClick={handleSeeMoreModal}></div>
                <div className="modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">About {props?.serviceCoordinatorDetails?.serviceName}</h4>
                        <a onClick={viewMore} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p>{props?.serviceDescription}</p>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
};

export default AboutUsView;