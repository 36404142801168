import { NotificationsBellSwingModal,  NotificationsBellSwingPayload } from "../../utils/model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: NotificationsBellSwingModal = {
    isBellIconSwingable: false,
}

export const notificationsBellSwingSlice = createSlice({
    name: "notificationsBellSwing",
    initialState,
    reducers: {
        setIsBellIconSwingable: (state, { payload }: NotificationsBellSwingPayload) => {
            state.isBellIconSwingable = payload.isBellIconSwingable;
        },
        resetIsBellIconSwingable: (state, { payload }: PayloadAction) => {
            state.isBellIconSwingable = false;
        },
    }
})

export const { setIsBellIconSwingable,resetIsBellIconSwingable } = notificationsBellSwingSlice.actions;

export default notificationsBellSwingSlice.reducer;
