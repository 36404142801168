import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Profile from '../../../assets/images/Profile-white.svg';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { getAssessmentDropdown } from '../../../service/assessment-service';
import { AssessmentStatus } from '../../../utils/constant';

const DiversityProfileView = (props: any) => {
    const { assessmentDetails } = props;
    const { t } = useTranslation();
    const [ageGroup, setageGroup] = useState<any>([]);
    const [ethnicOrigin, setethnicOrigin] = useState<any>([]);
    const [religion, setreligion] = useState<any>([]);
    const [sexualityPreference, setsexualityPreference] = useState<any>([]);
    let [assessmentDetailsDiversityProfileView, setassessmentDetailsDiversityProfileView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const validationSchema = Yup.object().shape({
        asudpId: Yup.string().notRequired(),
        ethnicOriginId: Yup.string().strict(true).nonNullable().required(),
        religionId: Yup.string().strict(true).nonNullable().required(),
        otherReligion: Yup.string()
            .trim()
            .strict(true)
            .when('religionId', {
                is: '8',
                then: (schema: any) => schema.required(),
                otherwise: (schema: any) => schema.nonNullable().notRequired()
            }),
        isEnglishYourFirstLanguage: Yup.boolean().required(),
        languageName: Yup.string()
            .trim()
            .strict(true)
            .when('isEnglishYourFirstLanguage', {
                is: false,
                then: (schema: any) => schema.required(),
                otherwise: (schema: any) => schema.nonNullable().notRequired()
            }),
        ageGroupId: Yup.string().strict(true).nonNullable().required(),
        sexualityPreferenceId: Yup.string().strict(true).nonNullable().required(),
        doYouHaveAdisability: Yup.boolean().required()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const watchAllFields = watch();

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsDiversityProfileView(props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData);

            setValue(
                'asudpId',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.asudpId ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.asudpId : '',
                { shouldValidate: true }
            );
            setValue(
                'ethnicOriginId',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.ethnicOriginId ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.ethnicOriginId.toString() : '',
                { shouldValidate: true }
            );
            setValue(
                'religionId',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.religionId ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.religionId.toString() : '',
                { shouldValidate: true }
            );
            setValue(
                'otherReligion',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.otherReligion ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.otherReligion : '',
                { shouldValidate: true }
            );
            setValue('isEnglishYourFirstLanguage', assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.isEnglishYourFirstLanguage !== null ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.isEnglishYourFirstLanguage === 1 ? '1' : '0' : '', { shouldValidate: true });
            setValue(
                'languageName',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.languageName ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.languageName : '',
                { shouldValidate: true }
            );
            setValue(
                'ageGroupId',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.ageGroupId ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.ageGroupId.toString() : '',
                { shouldValidate: true }
            );
            setValue(
                'sexualityPreferenceId',
                assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.sexualityPreferenceId
                    ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.sexualityPreferenceId.toString()
                    : '',
                { shouldValidate: true }
            );
            setValue('doYouHaveAdisability', assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.doYouHaveAdisability !== null ? assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.doYouHaveAdisability === 1 ? '1' : '0' : '', { shouldValidate: true });
        } else {
            setValue('asudpId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        props.diversityProfileFormValid(formState.isValid);
        props.diversityProfileDataChange(watchAllFields);
        // if (formState.isValid) {
        //     props.diversityProfileDataChange(watchAllFields);
        // }
    }, [formState]);

    useEffect(() => {
        if(props.assessmentDropdown !== undefined) {
            setageGroup(props.assessmentDropdown.ageGroup);
            setethnicOrigin(props.assessmentDropdown.ethnicOrigin);
            setreligion(props.assessmentDropdown.religion);
            setsexualityPreference(props.assessmentDropdown.sexualityPreference);
        }
    }, [props.assessmentDropdown]);
    return (
        <div id="diversity-profile">
            <div className="modal-title-header mb-2 mt-4">
                <h3 className="d-flex align-items-center">
                    <img src={Profile} alt="" width={14} className="me-2" />
                    {t('Service_User_Diversity_Profile.Service_User_Diversity_Profile')}
                </h3>
            </div>
            <p>{t('Service_User_Diversity_Profile.Valorum', {orgName: props.orgName})}</p>
            <input
                type="hidden"
                {...register('asudpId', {
                    // onChange: (e: any) => {
                    //     props.updateData(e);
                    // }
                })}
                name={'asudpId' as const}
            />
            <div className="mt-3">
                <label className="purple-text bold mb-1">
                    {t('Service_User_Diversity_Profile.Ethnic')}
                    {/* <span className='font-light'><i>{t('Service_User_Diversity_Profile.answer')}</i></span> */}
                </label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <div className="d-flex flex-wrap">
                        {ethnicOrigin?.length > 0 &&
                            ethnicOrigin.map((item: any, i: any) => {
                                return (
                                    <label key={i} className="form--radio-label mb-2 d-flex align-items-center me-2">
                                        <input
                                            type="radio"
                                            {...register('ethnicOriginId', {
                                                onChange: (e: any) => {
                                                    // props.updateData(e);
                                                }
                                            })}
                                            name={'ethnicOriginId' as const}
                                            value={item.ethnicOriginId}
                                            // defaultChecked={getValues("ethnicOriginId") == item.ethnicOriginId ? true : i === 0 ? true : false}
                                            // onChange={props.updateData}
                                        />
                                        <span className="ms-1">{item.name}</span>
                                    </label>
                                );
                            })}
                    </div>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                    <p className="bold mb-2">
                        {assessmentDetailsDiversityProfileView.ethnicOriginId !== ''
                            ? ethnicOrigin?.filter((item: any) => item.ethnicOriginId === assessmentDetailsDiversityProfileView.ethnicOriginId)[0]?.name
                            : ''}
                    </p>
                )}
            </div>
            <div className="mt-3">
                <label className="purple-text bold mb-1">
                    {t('Service_User_Diversity_Profile.religious_beliefs')}
                    {/* <span className='font-light'><i>{t('Service_User_Diversity_Profile.answer')}</i></span> */}
                </label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <div className="d-flex flex-wrap">
                        {religion?.length > 0 &&
                            religion.map((item: any, i: any) => {
                                if (item.name.trim() === 'Other') {
                                    return (
                                        <label key={i} className="form--radio-label mb-2 d-flex align-items-center me-2">
                                            <input
                                                type="radio"
                                                {...register('religionId', {
                                                    onChange: (e: any) => {
                                                        // props.updateData(e);
                                                    }
                                                })}
                                                name={'religionId' as const}
                                                value={item.religionId}
                                                // defaultChecked={getValues("religionId") == 8 ? true : false }
                                                // onChange={props.updateData}
                                            />
                                            <span className="ms-1 snowrap me-2">
                                                {item.name}{' '}
                                                <span>
                                                    <i>{t('Service_User_Diversity_Profile.please_state')}:</i>
                                                </span>
                                            </span>
                                            <input
                                                disabled={(getValues("religionId") == 8) ? false : true}
                                                type="text"
                                                {...register('otherReligion', {
                                                    onChange: (e: any) => {
                                                        // props.updateData(e);
                                                    }
                                                })}
                                                name={'otherReligion' as const}
                                                className={`form-control white w-200 ${errors.otherReligion && (getValues("religionId") == 8) ? 'is-invalid' : ''}`}
                                            />
                                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                                        </label>
                                    );
                                } else {
                                    return (
                                        <label key={i} className="form--radio-label mb-2 d-flex align-items-center me-2">
                                            <input
                                                type="radio"
                                                {...register('religionId', {
                                                    onChange: (e: any) => {
                                                        // props.updateData(e);
                                                    }
                                                })}
                                                name={'religionId' as const}
                                                value={item.religionId}
                                                // defaultChecked={getValues("religionId") !== '' && getValues("religionId") == item.religionId ? true : (getValues("religionId") !== 8 && i === 0) ? true : false}
                                                // onChange={props.updateData}
                                            />
                                            <span className="ms-1">{item.name}</span>
                                        </label>
                                    );
                                }
                            })}
                    </div>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                    <p className="bold mb-2">
                        {assessmentDetailsDiversityProfileView?.religionId !== ''
                            ? religion?.filter((item: any) => item.religionId === assessmentDetailsDiversityProfileView.religionId)[0]?.name === 'Other'
                                ? assessmentDetailsDiversityProfileView?.otherReligion
                                : religion?.filter((item: any) => item.religionId === assessmentDetailsDiversityProfileView.religionId)[0]?.name
                            : ''}
                    </p>
                )}
            </div>
            <div className="mt-3">
                <label className="purple-text bold mb-1">{t('Service_User_Diversity_Profile.English_first_language')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <>
                        <div className="d-flex flex-wrap">
                            <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                <input
                                    type="radio"
                                    {...register('isEnglishYourFirstLanguage', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'isEnglishYourFirstLanguage' as const}
                                    value={1}
                                    // onChange={props.updateData}
                                />
                                <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                            </label>

                            <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                                <input
                                    type="radio"
                                    {...register('isEnglishYourFirstLanguage', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'isEnglishYourFirstLanguage' as const}
                                    value={0}
                                    // defaultChecked={getValues("isEnglishYourFirstLanguage") !== null && getValues("isEnglishYourFirstLanguage") !== undefined ? false : true}
                                    // onChange={props.updateData}
                                />
                                <span className="ms-1">{t('GeneralInfo.No')}</span>
                            </label>
                        </div>
                        <div className="d-flex align-items-center">
                            <label className="me-2">{t('Service_User_Diversity_Profile.If_not_language')}</label>
                            <input
                                disabled={(getValues("isEnglishYourFirstLanguage") == 1) ? true : false}
                                type="text"
                                {...register('languageName', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'languageName' as const}
                                className={`form-control white w-200 ${errors.languageName && getValues("isEnglishYourFirstLanguage") == 0 ? 'is-invalid' : ''}`}
                            />
                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                    </>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                    <p className="bold mb-2">{assessmentDetailsDiversityProfileView.isEnglishYourFirstLanguage === 1 ? 'English' : assessmentDetailsDiversityProfileView.languageName}</p>
                )}
            </div>
            <div className="mt-3">
                <label className="purple-text bold mb-1">{t('Service_User_Diversity_Profile.Which_age')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <div className="d-flex flex-wrap">
                        {ageGroup?.length > 0 &&
                            ageGroup.map((item: any, i: any) => {
                                return (
                                    <label key={i} className="form--radio-label mb-2 d-flex align-items-center me-2">
                                        <input
                                            type="radio"
                                            {...register('ageGroupId', {
                                                onChange: (e: any) => {
                                                    // props.updateData(e);
                                                }
                                            })}
                                            name={'ageGroupId' as const}
                                            value={item.ageGroupId}
                                            // defaultChecked={getValues("ageGroupId") !== undefined && getValues("ageGroupId") !== null ? false : i === 0 ? true : false}
                                            // onChange={props.updateData}
                                        />
                                        <span className="ms-1">{item.name}</span>
                                    </label>
                                );
                            })}
                    </div>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                    <p className="bold mb-2">
                        {assessmentDetailsDiversityProfileView.ageGroupId !== '' ? ageGroup?.filter((item: any) => item.ageGroupId === assessmentDetailsDiversityProfileView.ageGroupId)[0]?.name : ''}
                    </p>
                )}
            </div>
            <div className="mt-3">
                <label className="purple-text bold mb-1">
                    {t('Service_User_Diversity_Profile.describe_your_sexuality')}
                    {/* <span><i>{t('Service_User_Diversity_Profile.answer')}</i></span> */}
                </label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <div className="d-flex flex-wrap">
                        {sexualityPreference?.length > 0 &&
                            sexualityPreference.map((item: any, i: any) => {
                                return (
                                    <label key={i} className="form--radio-label mb-2 d-flex align-items-center me-2">
                                        <input
                                            type="radio"
                                            {...register('sexualityPreferenceId', {
                                                onChange: (e: any) => {
                                                    // props.updateData(e);
                                                }
                                            })}
                                            name={'sexualityPreferenceId' as const}
                                            value={item.sexualityPreferenceId}
                                            // defaultChecked={getValues("sexualityPreferenceId") !== null && getValues("sexualityPreferenceId") !== undefined ? false : i === 0 ? true : false}
                                            // onChange={props.updateData}
                                        />
                                        <span className="ms-1">{item.name}</span>
                                    </label>
                                );
                            })}
                    </div>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                    <p className="bold mb-2">
                        {assessmentDetailsDiversityProfileView.sexualityPreferenceId !== ''
                            ? sexualityPreference?.filter((item: any) => item.sexualityPreferenceId === assessmentDetailsDiversityProfileView.sexualityPreferenceId)[0]?.name
                            : ''}
                    </p>
                )}
            </div>
            <div className="mt-3">
                <label className="purple-text bold mb-1">{t('Service_User_Diversity_Profile.disability_mobility')}</label>
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                    <div className="d-flex flex-wrap">
                        <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                            <input
                                type="radio"
                                {...register('doYouHaveAdisability', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'doYouHaveAdisability' as const}
                                value={1}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                            <input
                                type="radio"
                                {...register('doYouHaveAdisability', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'doYouHaveAdisability' as const}
                                value={0}
                                // defaultChecked={getValues("doYouHaveAdisability") !== null && getValues("doYouHaveAdisability") !== undefined ? false : true}
                                // onChange={props.updateData}
                            />
                            <span className="ms-1">{t('GeneralInfo.No')}</span>
                        </label>
                    </div>
                )}
                {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsDiversityProfileView.doYouHaveAdisability === 1 ? 'Yes' : 'No'}</p>}
            </div>
        </div>
    );
};

export default DiversityProfileView;
