import React from 'react';
import AssessmentModalView from './indexView';

const AssessmentModalContainer = (props: any) => {
    return (
        <div>
            <AssessmentModalView onClose={props.onClose} closePopup={props.closePopup} leadPoolingId={props.leadPoolingId} assessmentDetails={props.assessmentDetails} orgName={props.orgName} footer={props.footer} title={props.title} className={props.className} content={props.content} referralid={props.referralid} serviceName={props.serviceName} status={props.status}/>            
        </div>
    );
};

export default AssessmentModalContainer;