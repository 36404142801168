import React from 'react';
import MessageModalView from './MessageModalView';

const MessageModalContainer = (props:any) => {
    return (
        <div>
            <MessageModalView onClose={props.onClose} title={props.subject} className={props.className} content={props.body} individualMessage={props.individualMessage} isNotificationType={props?.isNotificationType}  />
        </div>
    );
};

export default MessageModalContainer;