import { useTranslation } from 'react-i18next';
import PaginationContainer from '../../../../../components/PaginationComponent/indexContainer';
import vacant_img from '../../../../../assets/images/vacant-status.png';
import reserved_img from '../../../../../assets/images/reserved-status.png';
import occupied_img from '../../../../../assets/images/occupied-status.png';
import { rightNavigationPage } from '../../../../../utils/constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useEffect } from 'react';

var PageSize = 10;
const BedView = (props: any) => {
    const { t } = useTranslation();
    const { ShowClass, FormName,Data } = useSelector((state: RootState) => state.navigation);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    return (
        <>
            <ul className={`room-${props.totalCount} small_card_group  mt-4 ps-2 pe-2 `}>
                {props.wardBedData !== '' && props.wardBedData.status &&
                    props.wardBedData.data.map((beds: any) => {
                        if (beds.status === 'Occupied') {
                            return (
                                <li className={`small_box card occupied ${Data.bedId === beds.bedId ? 'activebed' : ''}`} key={beds.bedId} id="occupied" onClick={() => props.handleClick(beds, rightNavigationPage.Occupied)}>
                                    <div className="card_header d-flex justify-content-between p-1 ps-2 pe-2">
                                        <p>{beds.bedName}</p>
                                        <div className="d-flex align-items-center occupied-status status">
                                            <p>{t('BedSection.Occupied')}</p>
                                            {/* <img src={occupied_img} alt="occupied" width="10" /> */}
                                            {/* <span className='room-status'></span> */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between p-2 pt-2 pb-2">
                                        <div>
                                            <div className="user_img">
                                              {beds.patientInitials !== null ? beds.patientInitials : ''}
                                                </div>
                                            <p className="mt-2 text-center">
                                                <span className='purple-text bold'>{t('BedSection.LoS')}</span>
                                                <span className="d-block">{beds.los !== null ? beds.los : ''}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <span className='purple-text bold'>{t('BedSection.User_Type')}</span>
                                                <span className="d-block">{beds.userType !== null ? beds.userType : ''}</span>
                                            </p>
                                            <p className="mt-1">
                                                <span className='purple-text bold'>{t('BedSection.Admitted_On')}</span>
                                                <span className="d-block">{beds.admittedOn !== null ? window.$utils.dateFormatDDMMYYYY(beds.admittedOn) : ''}</span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                        if (beds.status === 'Reserved') {
                            return (
                                <li className={`small_box card reserved ${Data.bedId === beds.bedId ? 'activebed' : ''}`} id="reserved" key={beds.bedId} onClick={() => props.handleClick(beds, rightNavigationPage.Reserved)}>
                                    <div className="card_header d-flex justify-content-between p-1 ps-2 pe-2">
                                        <p>{beds.bedName}</p>
                                        <div className="d-flex align-items-center reserved-status status">
                                            <p>{t('BedSection.Reserved')}</p>
                                            {/* <img src={reserved_img} alt="occupied" width="10" /> */}
                                            {/* <span className='room-status'></span> */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between p-2 pt-2 pb-2">
                                        <div>
                                            <div className="user_img">{beds.patientInitials !== null ? beds.patientInitials : ''}</div>
                                            <p className="mt-2 text-center">
                                                {t('BedSection.LoR')}
                                                <span className="purple-text bold d-block">{beds.los !== null ? beds.los : ''}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <span className='purple-text bold'>{t('BedSection.User_Type')}</span>
                                                <span className="d-block">{beds.userType !== null ? beds.userType : ''}</span>
                                            </p>
                                            <p className="mt-1">
                                                <span className='purple-text bold'>{t('BedSection.Reserved_On')}</span>
                                                <span className="d-block">{beds.reservedOn !== null ?  window.$utils.dateFormatDDMMYYYY(beds.reservedOn) : ''}</span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                        if (beds.status === 'Vacant') {
                            return (
                                <li className={`small_box card vacant ${DataServiceDetail?.iqviaServiceEnabled === true ? 'iqvia-disabled' : ''} ${Data.bedId === beds.bedId ? 'activebed' : ''}`} key={beds.bedId} id="vacant" onClick={() => props.handleClick(beds, rightNavigationPage.Vacant)}>
                                    <div className="card_header d-flex justify-content-between p-1 ps-2 pe-2">
                                        <p>{beds.bedName}</p>
                                        <div className="d-flex align-items-center vacant-status status">
                                            <p>{t('BedSection.Vacant')}</p>
                                            {/* <img src={vacant_img} alt="vacant" width="10" /> */}
                                            {/* <span className='room-status'></span> */}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="vacant_room">
                                            <i className="fa-solid fa-user-plus"></i>
                                            <p>{t('BedSection.Add_Patient')}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        }

                        if (beds.status === 'In Maintenance') {
                            return (
                                <li className={`small_box card maintenance ${Data.bedId === beds.bedId ? 'activebed' : ''}`}  key={beds.bedId} id="vacant" onClick={() => props.handleClick(beds, rightNavigationPage.Maintenance)}>

                                    <div className="card_header d-flex justify-content-between p-1 ps-2 pe-2">
                                        <p>{beds.bedName}</p>
                                        <div className="d-flex align-items-center maintenance-status status">
                                        <p>{t('BedSection.In_Maintenance')}</p>
                                            {/* <img src={reserved_img} alt="occupied" width="10" /> */}
                                            {/* <span className='room-status'></span> */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between p-2 pt-2 pb-2">
                                        <div>
                                        <svg version="1.1" id="Layer_1"
                                        fill="#4e4851"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="30"
                                        viewBox="0 0 1080 1080"
                                           xmlSpace="preserve">
                                            <g>
                                            <style>{`"enable-background:new 0 0 1080 1080;"`}</style>
                                                <path d="M86.11,944.45c5.83-15.91,12.81-31.5,17.3-47.78c16.23-58.79,31.84-117.75,47.14-176.79c3.23-12.45,9.26-18.63,22.45-20.69
                                                    c26.77-4.18,53.25-10.21,82.39-15.97c0,47.51,0,92.77,0,139.1c16.7,0,31.23,0,49.15,0c0-12.32,0.05-24.52-0.01-36.71
                                                    c-0.15-32.64-0.75-65.29-0.16-97.92c0.11-6.06,4.52-16.97,7.7-17.26c26.32-2.37,38.18-24.23,56.82-40.53
                                                    c14.61,15.66,28.93,31.09,43.35,46.42c15.92,16.92,49.26,16.78,65.09-0.22c13.71-14.71,27.38-29.46,42.21-45.43
                                                    c20.46,14.44,32.16,42.6,64.36,37.51c0,51.9,0,102.04,0,153.67c17.31,0,32.88,0,50.85,0c0-45.84,0-91.74,0-140.59
                                                    c33.1,7.63,63.5,14.08,93.5,22.05c6.01,1.6,10.99,8.56,15.56,13.82c13.58,15.61,26.72,31.59,43.69,51.79
                                                    c0.9-11.9,1.88-19.04,1.91-26.18c0.27-57.56,0.05-115.13,0.72-172.69c0.16-14.11-4.02-24.73-14.31-34.5
                                                    c-24.95-23.7-48.71-48.66-73.72-72.3c-8.74-8.26-11.03-14.62-4.32-25.6c20.41-33.37,39.7-67.42,59.52-101.15
                                                    c2.09-3.56,4.06-7.99,7.32-9.9c5.22-3.06,11.72-6.27,17.2-5.58c2.68,0.34,5.97,9.26,6.09,14.37c0.63,26.69,0.77,53.42,0.16,80.11
                                                    c-0.27,11.75,2.16,17.04,15.68,16.57c30.73-1.09,61.52-0.34,94.56-0.34c0-7.25-0.01-13.56,0-19.88c0.05-24.93-0.49-49.88,0.6-74.77
                                                    c0.27-6.15,5.85-12.07,8.98-18.09c5.23,3.09,12.53,4.91,15.36,9.48c23.97,38.82,47.41,77.99,70.05,117.6
                                                    c1.94,3.4-1.32,12.23-4.88,15.94c-27.12,28.26-55.32,55.49-82.16,84c-5.85,6.21-10.62,16.26-10.73,24.62
                                                    c-1.37,103.85-1.94,207.71-2.08,311.57c-0.01,7,3.74,15.03,8.09,20.81c11.74,15.59,24.74,30.22,38.7,46.98
                                                    c-5.74,0.65-10.02,1.55-14.29,1.55c-273.05,0.07-546.09,0.09-819.14-0.08c-6.9,0-13.79-2.23-20.69-3.42
                                                    C86.11,946.83,86.11,945.64,86.11,944.45z"/>
                                                <path d="M633.69,353.17c4.76,0,12.9-1.57,19.96,0.51c5.36,1.58,13.65,8.67,13.02,11.79c-1.16,5.82-7.37,11.11-12.49,15.41
                                                    c-2.15,1.81-6.88,0.67-10.43,0.67c-133.58,0.01-267.16,0.02-400.74-0.1c-9.72-0.01-21.67,2.44-22.96-12.66
                                                    c-0.89-10.5,2.94-16,15.25-15.98c26.57,0.04,24.48-1.08,29.91-27.52c7.7-37.55,15.49-75.69,29.31-111.23
                                                    c20.03-51.53,62.88-78.16,116.58-83.88c27.9-2.97,57.29-2.3,84.73,3.12c49.81,9.85,86.5,40.05,102.76,88.79
                                                    C612.55,263.9,621.63,307.33,633.69,353.17z"/>
                                                <path d="M282.19,418.5c109.31,0,215.73,0,326.45,0c-8.21,26.64-14.21,51.79-23.67,75.57c-17.03,42.84-41.43,80.98-77.54,110.95
                                                    c-39.17,32.52-85.41,32.87-124.42,0.09c-46.09-38.73-73.56-88.9-89.84-145.94C289.49,446.24,286.15,433.2,282.19,418.5z"/>
                                            </g>
                                            </svg>

                                            <p className="mt-2 text-center">
                                            <p>{t('BedSection.LoM')}</p>
                                                <span className="purple-text bold d-block">{beds.lom}</span>
                                            </p>
                                        </div>
                                        <div className='ms-1'>
                                            <p >
                                                <span className='purple-text bold '>{t('BedSection.Start_Date')}</span>
                                                <span className="d-block">{window.$utils.dateFormatDDMMYYYY(beds.maintenanceStartDate)}</span>
                                            </p>
                                            <p className="mt-1 nowrap">
                                            <span className='purple-text bold'>{t('BedSection.Est_Release_On')}</span>
                                                <span className="d-block">{window.$utils.dateFormatDDMMYYYY(beds.estMaintenanceReleaseDate)}</span>
                                            </p>

                                        </div>
                                    </div>





                                </li>
                            );
                        }
                    })}
            </ul>

            {/* <div>
                {props.currentPage > 0 && props.totalCount > 0 && PageSize > 0 && (
                    <PaginationContainer currentPage={props.currentPage} totalCount={props.totalCount} pageSize={PageSize} onPageChange={(page: any) => props.getCurrentPage(page)} />
                )}
            </div> */}
        </>
    );
};

export default BedView;
