import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';


export const getAdmittedPatientList = (id:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetAdmittedPatients}?serviceId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getPatientByNhs = (nhs:any,serviceid:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPatientByNhs}?nhsId=${nhs}&serviceId=${serviceid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getFunderByPatientId = (patientid:any,serviceid:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetFundersByPatient}?patientid=${patientid}&serviceId=${serviceid}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getProductLines = (data:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetProductLines}`,data)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getUomData = () => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetUom}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};


export const getInvoiceData = (id: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetInvoiceData}?invoiceId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const saveInvoiceData = (data:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveInvoice}`,data)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const updateInvoiceData = (data:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.UpdateInvoice}`,data)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const cancelInvoice = (id:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.CancelInvoice}?invoiceId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const splitInvoiceData = (id:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SplitInvoice}?invoiceId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const checkSplitFunding = (data:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.CheckSplitFunding}`,data)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const saveSplitFunding = (data:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveSplitFunding}`,data)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getInvoicePatientData = (id: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.InvoicePatientDetail}?patientId=${id}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getinvoiceDocument = (payload:any) => {
    const state = store.getState();
    // eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.DownloadInvoiceDocument}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getinvoicelastDate = (pid:any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetLastInvoiceDate}?patientId=${pid}&funderId=null`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            // eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                // eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getInvioceRecipients = () => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetInvoiceRecipients}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getInvoiceemailDetails = (invoiceHeaderId: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetInvoiceEmailDetails}?invoiceHeaderId=${invoiceHeaderId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const sendInvoiceEmail = (payload:any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SendInvoiceEmail}`, payload)       
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};
