import ScheduleAssessmentModalView from './indexView';

const ScheduleAssessmentContainer = (props: any) => {
    return (
        <div>
            <ScheduleAssessmentModalView {...props} />
        </div>
    );
};

export default ScheduleAssessmentContainer;