import React from 'react';
import AdmittedTabDeatil from './admittedView';

const AdmittedDeatilContainer = (props: any) => {
    return (
        <div>
            <AdmittedTabDeatil patientData={props.patientData} />
        </div>
    );
};

export default AdmittedDeatilContainer;
