import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SplitFundingModel } from '../../utils/model';

const initialState: SplitFundingModel = {
    DataSplitFunding: [],
};

export const splitFundingSlice = createSlice({
    name: 'splitFunding',
    initialState,
    reducers: {
        setSplitFundingAction: (state, { payload }: PayloadAction) => {
            state.DataSplitFunding = payload;
        },
        removeSplitFundingAction: (state, { payload }: PayloadAction) => {
            state.DataSplitFunding = [];
        }
    },
    extraReducers: {}
});

export const { setSplitFundingAction, removeSplitFundingAction } = splitFundingSlice.actions;

export default splitFundingSlice.reducer;
