import React from 'react';
import ServicetypeHeaderContainer from '../../../../layout/NHSHeader/ServicetypeHeader/ServicetypeHeaderContainer';
import HomeMapContainer from '../../../NHSPage/HomeMap/HomeMapContainer';
import ListBoxContainer from '../../../NHSPage/HomeList/ListBox/ListBoxContainer';
import NHSServiceDetailContainer from '../../../NHSPage/NHSServiceDetail/NHSServiceDetailContainer';
import HomeListContainer from '../../../NHSPage/HomeList/HomeListContainer';

const MapViewService = () => {
    return (
        <div>
            <div className='container-fluid'>
                <HomeListContainer />
            </div>
        </div>
    );
};

export default MapViewService;