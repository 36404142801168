import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { rightNavigationPage } from '../../../../../../../utils/constant';
import { getContractData, getContractDataAdmission, getContractFunderFileService, updateDocuSign } from '../../../../../../../service/contract-service';
import swal from 'sweetalert';
import warning from '../../../../../../../assets/images/warning.png';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientReservedData, getPlanData } from '../../../../../../../service/dashboard-service';
import { removePatientDraftAction } from '../../../../../../../store/dashboard/draftPatientData';
import { setNavigationAction } from '../../../../../../../store/dashboard/navigationReducer';
import { RootState } from '../../../../../../../store';
import { setTempSplitFundingAction } from '../../../../../../../store/splitFunding/tempSplitFundingReducer';
import { removePlanDataAction } from '../../../../../../../store/splitFunding/planDataReducer';
import { removeLeadInformationAction, setLeadInformationAction } from '../../../../../../../store/lead/getLeaddataReducer';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import PreviewContractContainer from '../../../../../../../components/PreviewContractModal/PreviewContractContainer';
import NoteContainer from '../../../../../../../components/NoteModal/indexContainer';
import note from '../../../../../../../assets/images/note.svg';

const ContractListView = (props: any) => {
    const { t } = useTranslation();
    const { tabID } = props;
    const [showModal, setShowModal] = useState(false);
    const [previewdata, setpreviewdata] = useState<any>();
    const { StatusPatientDetail, DataPatientDetail } = useSelector((state: RootState) => state.patientDetails);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [funderNote, setFunderNote] = useState<any>('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    let plans: any = [];
    let funders: any = [];
    const toggle = () => {
        setShowModal(false);
    };
    

    useEffect(() => {
        
    }, [DataPatientDetail]);

    const downloadPDF = async (file: any) => {
        
        const payload: any = { name: file };
        const response = await getContractFunderFileService(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    const dispatch = useDispatch();

    async function getPlans(aid: any) {
        dispatch(removePlanDataAction());
        const response = await getPlanData(aid);
        if (response.status == true) {
            plans = [];
            for (let j = 0; j < response.data.length; j++) {
                if (response.data[j].planType !== 0) {
                    let obj: any = {};
                    funders = [];
                    obj.corefee = response?.data[j]?.weeklyCharge;
                    obj.admissiondate = response?.data[j]?.startDate?.toString();
                    obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                    obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                    obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                    obj.planId = response?.data[j]?.planId;
                    obj.lastinvoicedate = response?.data[j]?.lastInvoiceCreatedDate?.toString();
                    obj.dailyCoreFee = Number(response?.data[j]?.calculatedDailyCoreFee);
                    obj.continenceCareFee= response?.data[j]?.continenceCareFee;
                    obj.fncFee= response?.data[j]?.fncFee;
                    obj.acontinenceCareFee = response?.data[j]?.continenceCareFee;
                    obj.afncFee = response?.data[j]?.fncFee;
                    obj.totalFees= response?.data[j]?.totalFees;
                    obj.continenceCareFeeType= response?.data[j]?.continenceCareFeeType;
                    obj.fncFeeType= response?.data[j]?.fncFeeType;
                    obj.totalAdditionalServiceFee = response?.data[j]?.totalAdditionalServiceFee;
                    for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                        funders.push({
                            planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                            funderid: response?.data[j]?.planDetails[i].funderId,
                            fundername: response?.data[j]?.planDetails[i].funderName,
                            fundertype:
                                response?.data[j]?.planDetails[i].funderType === 1
                                    ? 'ICB'
                                    : response?.data[j]?.planDetails[i].funderType === 2
                                    ? 'LA'
                                    : response?.data[j]?.planDetails[i].funderType === 3
                                    ? 'Charity'
                                    : 'Self',
                            proportion: response?.data[j]?.planDetails[i].proportion,
                            proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                            isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                            isautopay:response?.data[j]?.planDetails[i].isAutoPay === 1 ? false : true,
                            ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                        });

                        obj.funderlist = funders;
                    }
                    plans = [...plans, obj];
                   
                }
            }
            dispatch(setTempSplitFundingAction(plans));
            const data = {
                isDraftAdd: 'edit',
                section: 'contract'
            };

            let navigationobj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.Vacant,
                Data: data,
                ShowToggle: false
                
            };
            dispatch(setNavigationAction(navigationobj));
        }
    }

    async function getpatientdata(pid: any) {
        const payload: any = { id: pid };
        await getPatientReservedData(payload);
    }
    const editHandler = async() => {
        swal({
            title: '',
            text: `Are you sure you want to update the patient admission information in Bed ${props.bedName}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
             
              
                dispatch(removePatientDraftAction());
                getpatientdata(DataPatientDetail?.data?.serviceUserDetails?.patientId).then(async (res: any) => {
                    // const data = {
                    //     isDraftAdd: 'edit',
                    //     section: 'contract'
                    // };

                    // let obj: any = {
                    //     ShowClass: true,
                    //     FormName: rightNavigationPage.Vacant,
                    //     Data: data,
                    //     ShowToggle: false
                        
                    // };
                    // dispatch(setNavigationAction(obj));
                    // setTimeout(() => {
                        getPlans(DataPatientDetail?.data?.admissionId);
                    // }, 500);
                    
                    dispatch(removeLeadInformationAction());
                    const res1 = await getContractDataAdmission(DataPatientDetail?.data?.admissionId);
                    if(res1.status){
                     dispatch(setLeadInformationAction(res1));
                    }
                });
            }
        });
    };

    const viewContract = async (id:any) => {
        const docures = await updateDocuSign(id);
        if(docures.status){
          setShowModal(true);
          setpreviewdata(docures.data);
        }
        else{
            setpreviewdata(undefined);
        }
    }

    const toggleModalPreview = () => {
        setShowModal(false);
        setpreviewdata(undefined);
    };

    const handleNoteClickModal = (noteval:any) => {                
        setFunderNote(noteval);
        setShowNoteModal(true);
    }
    const toggleNote = () => {       
       setShowNoteModal(false);         
    };
    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className={classNames('accordion-button', { collapsed: props.accordID !== 0 })}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${props.accordID}`}
                        aria-expanded={props.accordID !== 0 ? 'false' : 'true'}
                        aria-controls={`flush-collapse${props.accordID}`}
                    >
                        {props.dataContract.funderName}
                    </button>
                </h2>
                <div
                    id={`flush-collapse${props.accordID}`}
                    className={classNames('accordion-collapse collapse', { show: props.accordID === 0 })}
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#admissiondetail1"
                >
                    <div className="accordion-body pb-0">
                        {props.dataContract.funderEmail !== undefined && props.dataContract.funderEmail !== '' && props.dataContract.funderEmail !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_email')}:</label> <span className="bold">{props.dataContract.funderEmail}</span>
                            </p>
                        )}
                        {props.dataContract.funderPhoneNo !== undefined && props.dataContract.funderPhoneNo !== '' && props.dataContract.funderPhoneNo !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_phone')}:</label> <span className="bold">{props.dataContract.funderPhoneNo}</span>
                            </p>
                        )}
                        {props.dataContract.funderAddressLine1 !== undefined && props.dataContract.funderAddressLine1 !== '' && props.dataContract.funderAddressLine1 !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.funder_address')}:</label>{' '}
                                <span className="bold">
                                    {props.dataContract.funderAddressLine1 || ''} {props.dataContract.funderCityName || ''}
                                </span>
                            </p>
                        )}
                        
                        {props.dataContract?.suid !== undefined && props.dataContract?.suid !== '' && props.dataContract?.suid !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.suid')}:</label> <span className="bold">{props.dataContract?.suid}</span>
                            </p>
                        )}
                         {props.dataContract?.pO_Reference !== undefined && props.dataContract?.pO_Reference !== '' && props.dataContract?.pO_Reference !== null && (
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.po_reference')}:</label> <span className="bold">{props.dataContract?.pO_Reference}</span>
                            </p>
                        )}
                        
                        {props.dataContract?.note !== undefined && props.dataContract?.note !== '' && props.dataContract?.note !== null && (
                        <div className='d-flex mt-2 justify-content-end' ><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(props.dataContract?.note)}></img><p>{t('RightSidebarContraclList.notes')}</p></div>
                        )}
                        {console.log(props.dataContract)}
                        {props.dataContract?.docusignStatus === "Completed" &&
                        <div className='text-center mt-3'>
                          <button onClick={() => viewContract(props.dataContract?.digitalContractId)} className={`btn btn-sm btn-primary`}>
                                {t('RightSidebarContraclList.funder_View_Placement_Agreement')}
                            </button>
                            </div>
                        }
                        {props.dataContract?.contractAttachmentModels?.length > 0 && (
                            <div className="status-box white-bg mt-3">
                                <>
                                    <p className="purple-text mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                    {props.dataContract?.contractAttachmentModels?.map((data: any, i: number) => {
                                        return (
                                            <>
                                                <div className="d-flex justify-content-between  mt-2">
                                                    <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Contract Document ">
                                                        <textarea rows={1} value={data.fileName} readOnly />
                                                    </div>

                                                    <button className="btn-icon btn-secondary" name="btndelete" onClick={(e: any) => downloadPDF(data.filePath)}>
                                                        <i className="fa-solid fa-eye"></i>
                                                    </button>
                                                </div>
                                            </>
                                        );
                                    })}
                                </>
                              
                            </div>
                        )}

                        {((props.dataContract.socialWorkerName !== '' && props.dataContract.socialWorkerName !== null) ||
                            (props.dataContract.socialWorkerEmailId !== '' && props.dataContract.socialWorkerEmailId !== null) ||
                            (props.dataContract.socialWorkerContactNo !== '' && props.dataContract.socialWorkerContactNo !== null) ||
                            (props.dataContract.socialWorkerAddress !== '' && props.dataContract.socialWorkerAddress !== null)) && (
                            <>
                                <p className="purple-text mt-3 mb-2 bold"> {t('RightSidebarContraclList.social_detail')}</p>
                                {props.dataContract.socialWorkerName !== '' && props.dataContract.socialWorkerName !== null && (
                                    <p className="mb-1">
                                        <label>{t('RightSidebarContraclList.social_name')}:</label> <span className="bold">{props.dataContract.socialWorkerName || ''}</span>
                                    </p>
                                )}
                                {props.dataContract.socialWorkerEmailId !== '' && props.dataContract.socialWorkerEmailId !== null && (
                                    <p className="mb-1">
                                        <label>{t('RightSidebarContraclList.social_email')}:</label> <span className="bold">{props.dataContract.socialWorkerEmailId || ''}</span>
                                    </p>
                                )}
                                {props.dataContract.socialWorkerContactNo !== '' && props.dataContract.socialWorkerContactNo !== null && (
                                    <p className="mb-1">
                                        <label>{t('RightSidebarContraclList.social_phone')}:</label> <span className="bold">{props.dataContract.socialWorkerContactNo || ''}</span>
                                    </p>
                                )}
                                {props.dataContract.socialWorkerAddress !== '' && props.dataContract.socialWorkerAddress !== null && (
                                    <p className="mb-1">
                                        <label>{t('RightSidebarContraclList.social_address')}:</label>{' '}
                                        <span className="bold">
                                            {props.dataContract.socialWorkerAddress || ''} {props.dataContract.socialWorkerCityName || ''}
                                        </span>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {DataServiceDetail?.iqviaServiceEnabled === false && (
                <div className="mt-2 text-center  right_content_btn">
                    <button onClick={() => editHandler()} className="btn btn-secondary flex-1 me-2" id="save-funder1">
                        {' '}
                        Edit
                    </button>
                </div>
            )}
            
            {previewdata !== undefined && <RenderModalContainer>{showModal && <PreviewContractContainer onClose={toggleModalPreview} previewdata={previewdata} />}</RenderModalContainer>}
            <RenderModalContainer>
                 {showNoteModal && <NoteContainer  onClose={toggleNote} title={props.dataContract.funderName != null ?props.dataContract.funderName+"'s Notes" : t('RightSidebarContraclList.funder_note')} setFunderNote={funderNote} isReadonly={true}/> }
             </RenderModalContainer>
        </div>

        
    );
};

export default ContractListView;
