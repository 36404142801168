import React from 'react';
import { useSelector } from 'react-redux';
import RightsidebarContainer from '../pages/DashboardPage/components/RightsidebarDetail/rightSideContainer';
import { RootState } from '../store';
import { useLocation } from 'react-router-dom';

const RightSidebar = () => {
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const location = useLocation()
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    return (
        <div className={`right_content ${FormName === '' ? '' : 'show'} 
        ${(DataLogin.userModel.userRoleId === 3) ? 'right_content_refferal_management':''}`}>
            <RightsidebarContainer />
        </div>
    );
};

export default RightSidebar;
