import React from 'react';
import { useTranslation } from 'react-i18next';

interface FileInputProps {
    onFiles: any;
    hasFiles: boolean;
}

const FileInput: React.FC<FileInputProps> = ({ onFiles, hasFiles }) => {
    const { t } = useTranslation();
    return (
        <label className="file-input purple-text bold mt-2 d-block">
            <i className="fa-solid fa-paperclip"></i> {t('SendPrintCosting.Attachments')}
            <input
                type="file"
                style={{ display: 'none' }}
                multiple
                onChange={async (e) => {
                    const target = e.target;
                    onFiles(e);
                    //@ts-ignore
                    target.value = null;
                }}
            />
        </label>
    );
};

export default FileInput;
