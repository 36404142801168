import React, { useEffect, useState } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Tooltip } from 'react-tooltip';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
// import { setSplitFundingAction } from '../../store/splitFunding/splitFundingReducer';
import { removeTempSplitFundingAction, setTempSplitFundingAction } from '../../store/splitFunding/tempSplitFundingReducer';
import { setSplitFundingAction } from '../../store/splitFunding/splitFundingReducer';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';
import GenericErrorComponentSplit from '../GenericErrorComponentSplit';
import GenericErrorComponent from '../GenericErrorComponent';
interface ModalProps {
    onClose: (isValid:any) => any;
    onSave: () => any;
    onAddplan: () => any;
    title: string;
    content: any;
    footer: any;
    className:any;
    isaddplan:any;
    iseditable:boolean;
    funderlist:any;
}

const SplitFundingModalView = (props: ModalProps) => {
    // const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { t } = useTranslation();
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    // let [remaining,setremaining] = useState<any>(0);
    // let [totalremaining,settotalremaining] = useState<any>(0);
    // let [isRemainzero,setisRemainzero] = useState<boolean>(false);
    let [pindex,setpindex] = useState<any>(-1);
    let [cindex,setcindex] = useState<any>(-1);
    let [caindex,setcaindex] = useState<any>(0);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { PlanData } = useSelector((state: RootState) => state.planData);
    // let [sum,setsum] = useState<any> (0);
    // let sum : any = 0;
    const dispatch = useDispatch();
    // const [minDate, setmindate] = useState<any>('');
    const validationSchema = Yup.object().shape({
        plans: Yup.array().of(Yup.object().shape({
        weeklycharge: Yup.string().trim().strict(true).nonNullable().required(),
        admissiondate: Yup.date().required().strict(true).required(),
        enddate: Yup.date().required('date is required'),
        remaining:Yup.string().notRequired(),
        totalremaining:Yup.string().notRequired(),
        iszero:Yup.boolean().notRequired(),
        dailyCoreFee: Yup.string().trim().notRequired(),
        fncFee: Yup.string().trim().notRequired(),
        totaladditionalfee: Yup.string().trim().notRequired(),
        continenceCareFee: Yup.string().trim().notRequired(),
        // ^$|^(?:100(?:\.0(?:0)?)?|\d{1,2}(?:\.\d{1,2})?)$
        funderlist:Yup.array().of(Yup.object().shape({
            proportion:Yup.string().when('ispercentage',{
                is : true,
                then : (schema:any) => schema.matches(/^(100|\d?[0-9](?:\.[0-9]{0,2})?)?$/).required(),
                otherwise : (schema:any) => schema.matches(/^$|^[0-9]+(\.[0-9]{1,2})?$/).required()
            }),
            proportionvalue:Yup.string().trim().strict(true).nonNullable().required(),
            ispercentage:Yup.boolean().strict(true).nonNullable().notRequired(),
            isapplicable:Yup.boolean().strict(true).nonNullable().notRequired(),
            isautopay:Yup.boolean().strict(true).nonNullable().notRequired(),
            funderid:Yup.string().notRequired(),
        }))
        }))
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'plans', control });
    let[startdate,setstartdate]=useState<any>(null);
    let[enddate,setenddate]=useState<any>(null);
    useEffect(() => {
        
        const newVal = TempDataSplitFunding.length;
        const oldVal = fields.length;
        if (newVal > oldVal) {
            for (let i = oldVal; i < newVal; i++) {
                setcaindex(i);
                append(TempDataSplitFunding[newVal - 1]);
                setValue(`plans.${i}.weeklycharge`, TempDataSplitFunding[i].corefee || '');
                // settotalremaining(Number(TempDataSplitFunding[i].corefee));
                setValue(`plans.${i}.totalremaining`, Number(TempDataSplitFunding[i].corefee));
                setValue(`plans.${i}.remaining`, Number(TempDataSplitFunding[i].corefee));
                setValue(`plans.${i}.admissiondate`, TempDataSplitFunding[i].admissiondate);
                setValue(`plans.${i}.enddate`, TempDataSplitFunding[i].enddate);

                setValue(`plans.${i}.dailyCoreFee`, Number(TempDataSplitFunding[i].dailyCoreFee));
                setValue(`plans.${i}.fncFee`, Number(TempDataSplitFunding[i].fncFee));
                setValue(`plans.${i}.totaladditionalfee`, Number(TempDataSplitFunding[i]?.totalAdditionalServiceFee).toFixed(2));
                setValue(`plans.${i}.continenceCareFee`, Number(TempDataSplitFunding[i].continenceCareFee));
                let sum = 0;
                // let date = moment(new Date(TempDataSplitFunding[i]?.admissiondate), 'DD-MM-YYYY');
                // setstartdate(dayjs(new Date(date.toDate())));
                // if(TempDataSplitFunding[i]?.enddate !== null){
                    // let edate = moment(new Date(TempDataSplitFunding[i]?.enddate), 'DD-MM-YYYY');
                    // setenddate(dayjs(new Date(edate.toDate())));
                // }
                    for(let j=0;j<TempDataSplitFunding[i].funderlist.length;j++){
                        setValue(`plans.${i}.funderlist.${j}.proportion`, TempDataSplitFunding[i].funderlist[j].proportion || '');
                        setValue(`plans.${i}.funderlist.${j}.proportionvalue`, TempDataSplitFunding[i].funderlist[j].proportionvalue || '');
                        setValue(`plans.${i}.funderlist.${j}.ispercentage`, TempDataSplitFunding[i].funderlist[j].ispercentage || '');
                        setValue(`plans.${i}.funderlist.${j}.isapplicable`, TempDataSplitFunding[i].funderlist[j].isapplicable || '');
                        setValue(`plans.${i}.funderlist.${j}.isautopay`, TempDataSplitFunding[i].funderlist[j].isautopay || '');
                        setValue(`plans.${i}.funderlist.${j}.funderid`, TempDataSplitFunding[i].funderlist[j].funderid || '');
                        if(TempDataSplitFunding[i].funderlist[j].isapplicable === false){
                            sum += Number(TempDataSplitFunding[i].funderlist[j].proportionvalue);
                            // setremaining(Number(TempDataSplitFunding[i].corefee) - Number(sum));
                            setValue(`plans.${i}.remaining`, (Number(TempDataSplitFunding[i].corefee) - Number(sum)).toFixed(2), { shouldValidate: true });

                        }
                        else{
                            if(sum !== 0){
                                // setremaining(Number(TempDataSplitFunding[i].corefee) - (Number(sum)));
                                setValue(`plans.${i}.remaining`, (Number(TempDataSplitFunding[i].corefee) - (Number(sum))).toFixed(2), { shouldValidate: true });
                            }
                            else{
                                // setremaining(Number(TempDataSplitFunding[i].corefee));
                                setValue(`plans.${i}.remaining`, Number(TempDataSplitFunding[i].corefee).toFixed(2), { shouldValidate: true });
                            }
                        }


                    }

            }
        }
        else if (newVal === oldVal && pindex !== -1 && cindex !== -1) {
                let sum : any = 0;
                setValue(`plans.${pindex}.weeklycharge`, TempDataSplitFunding[pindex].corefee || '');
                // settotalremaining(Number(TempDataSplitFunding[pindex].corefee));
                setValue(`plans.${pindex}.totalremaining`, Number(TempDataSplitFunding[pindex].corefee));
                // let date = moment(new Date(TempDataSplitFunding[pindex]?.admissiondate), 'DD-MM-YYYY');
                // setstartdate(dayjs(new Date(date.toDate())));
                // 
                setValue(`plans.${pindex}.admissiondate`, TempDataSplitFunding[pindex].admissiondate);
                setValue(`plans.${pindex}.enddate`,TempDataSplitFunding[pindex].enddate);

                setValue(`plans.${pindex}.dailyCoreFee`, Number(TempDataSplitFunding[pindex].dailyCoreFee));
                setValue(`plans.${pindex}.fncFee`, Number(TempDataSplitFunding[pindex].fncFee));
                setValue(`plans.${pindex}.totaladditionalfee`, Number(TempDataSplitFunding[pindex]?.totalAdditionalServiceFee).toFixed(2));
                setValue(`plans.${pindex}.continenceCareFee`, Number(TempDataSplitFunding[pindex].continenceCareFee));

                for(let j=0;j<TempDataSplitFunding[pindex].funderlist.length;j++){
                    setValue(`plans.${pindex}.funderlist.${j}.proportion`, TempDataSplitFunding[pindex].funderlist[j].proportion || '');
                    setValue(`plans.${pindex}.funderlist.${j}.proportionvalue`, TempDataSplitFunding[pindex].funderlist[j].proportionvalue || '');
                    setValue(`plans.${pindex}.funderlist.${j}.ispercentage`, TempDataSplitFunding[pindex].funderlist[j].ispercentage || '');
                    setValue(`plans.${pindex}.funderlist.${j}.isapplicable`, TempDataSplitFunding[pindex].funderlist[j].isapplicable || '');
                    setValue(`plans.${pindex}.funderlist.${j}.isautopay`, TempDataSplitFunding[pindex].funderlist[j].isautopay || '');
                    setValue(`plans.${pindex}.funderlist.${j}.funderid`, TempDataSplitFunding[pindex].funderlist[j].funderid || '');
                    if(TempDataSplitFunding[pindex].funderlist[j].isapplicable === false){
                        sum += Number(TempDataSplitFunding[pindex].funderlist[j].proportionvalue);
                        // setremaining(Number(totalremaining) - Number(sum));
                        setValue(`plans.${pindex}.remaining`, (Number(getValues(`plans.${pindex}.totalremaining`)) - Number(sum)).toFixed(2), { shouldValidate: true });
                    }
                    else{
                        if(sum !== 0){
                            // setremaining(Number(totalremaining) - (Number(sum)));
                            setValue(`plans.${pindex}.remaining`, (Number(getValues(`plans.${pindex}.totalremaining`)) - Number(sum)).toFixed(2), { shouldValidate: true });
                        }
                        else{
                            // setremaining(Number(totalremaining));
                            setValue(`plans.${pindex}.remaining`,Number(getValues(`plans.${pindex}.totalremaining`)).toFixed(2), { shouldValidate: true })
                        }
                    }

                }
        }

    }, [TempDataSplitFunding]);

    // useEffect(()=>{
    //  
    // },[sum])

    // useEffect(()=>{
    //     
    //     if(totalremaining > 0){
    //         setremaining(totalremaining);
    //     }

    // },[totalremaining]);

    useEffect(()=>{
        // if(remaining === 0){
            for(let i=0; i<TempDataSplitFunding.length;i++){
                if(Number(getValues(`plans.${i}.remaining`)) === 0){
                    for(let j=0;j<TempDataSplitFunding[i].funderlist.length;j++){
                        if((TempDataSplitFunding[i].funderlist[j].proportion === "" || TempDataSplitFunding[i].funderlist[j].proportion === 0) && TempDataSplitFunding[i].funderlist[j].isapplicable === false){
                            setValue(`plans.${i}.iszero`,false);
                            break;
                        }
                        else{
                            setValue(`plans.${i}.iszero`,true);
                        }
                    }
                }
                else{
                    setValue(`plans.${i}.iszero`,false);
                }

            }


        // }
        // else{
            // setisRemainzero(false);
        // }

    },[TempDataSplitFunding]);

    const updatedate = (fieldname:any,date:any,index:number) => {
        let temporaryarray1 = JSON.parse(JSON.stringify(TempDataSplitFunding));
        let temporaryarray = temporaryarray1.slice();
        
        if(fieldname === 'sdate'){
            temporaryarray[index]['admissiondate'] =  new Date(date).toString();

        }
        if(fieldname === 'edate'){
            if(!isNaN(new Date(date).getTime()) && date !== null){
                temporaryarray[index]['enddate'] =  new Date(date).toString();
                if(TempDataSplitFunding.length === 2){
                    var dates = new Date(temporaryarray[0]['enddate']);
                    dates.setDate(dates.getDate() + 1);
                    temporaryarray[1]['admissiondate'] =  dates.toString();
                    setValue(`plans.${1}.admissiondate`, dates.toString());
                }
            }
            else{
                if(DataPatientDraftInfo?.data?.isInvoiceCreated === 1){
                    swal({
                        title: '',
                        text: `Invoice is created for the current plan hence End Date cannot be blank. It is populated with the Today's Date (${window.$utils.dateFormatDDMMYYYY(new Date().toString())}).`,
                        icon: warning,
                        dangerMode: true
                        
                    })
                    .then(async (confirm: any) => {
                        setValue(`plans.${0}.enddate`, new Date().toString(),{shouldValidate: true});
                    })
                    temporaryarray[index]['enddate'] =  new Date().toString();
                    if(TempDataSplitFunding.length === 2){
                        var dates = new Date(temporaryarray[0]['enddate']);
                        dates.setDate(dates.getDate() + 1);
                        temporaryarray[1]['admissiondate'] =  dates.toString();
                        setValue(`plans.${1}.admissiondate`, dates.toString());
                    }
                }
               else{
                temporaryarray[index]['enddate'] =  "";
               }
            }

        }
        dispatch(setTempSplitFundingAction(temporaryarray))
    }

    const updateData = (e:any,parentindex:number,childindex:number) =>{
        setpindex(parentindex);
        setcindex(childindex);
      let temporaryarray1 = JSON.parse(JSON.stringify(TempDataSplitFunding));
      let temporaryarray = temporaryarray1.slice();
      if(`plans.${parentindex}.funderlist.${childindex}.proportion` === e.target.name){
        temporaryarray[parentindex]['funderlist'][childindex]['proportion'] = e.target.value;
        if(temporaryarray[parentindex]['funderlist'][childindex]['ispercentage'] === false){
            temporaryarray[parentindex]['funderlist'][childindex]['proportionvalue'] = e.target.value;
        }
        else{
            temporaryarray[parentindex]['funderlist'][childindex]['proportion'] = e.target.value;
            let calculateval = ((Number(e.target.value)/100) * Number(getValues(`plans.${parentindex}.totalremaining`))).toFixed(2);
            temporaryarray[parentindex]['funderlist'][childindex]['proportionvalue'] = calculateval;
        }

      }
      if(`plans.${parentindex}.funderlist.${childindex}.ispercentage` === e.target.name){
        temporaryarray[parentindex]['funderlist'][childindex]['ispercentage'] = e.target.checked;
        if(e.target.checked){
            temporaryarray[parentindex]['funderlist'][childindex]['proportion'] = ''
            temporaryarray[parentindex]['funderlist'][childindex]['proportionvalue'] = ''
        }
        else{
            temporaryarray[parentindex]['funderlist'][childindex]['proportion'] = ''
            temporaryarray[parentindex]['funderlist'][childindex]['proportionvalue'] = ''
        }
      }
      if(`plans.${parentindex}.funderlist.${childindex}.isapplicable` === e.target.name){
        temporaryarray[parentindex]['funderlist'][childindex]['isapplicable'] = e.target.checked;
        if(e.target.checked){
            temporaryarray[parentindex]['funderlist'][childindex]['isautopay'] = true;
            temporaryarray[parentindex]['funderlist'][childindex]['proportion'] = ''
            temporaryarray[parentindex]['funderlist'][childindex]['proportionvalue'] = ''
        }
      }
      if(`plans.${parentindex}.funderlist.${childindex}.isautopay` === e.target.name){
        temporaryarray[parentindex]['funderlist'][childindex]['isautopay'] = e.target.checked;
      }
       dispatch(setTempSplitFundingAction(temporaryarray))
    }

    const currentindex = (index:number) =>{
       setcaindex(index);
    }

    const showValidationMessage = () => {
        swal("Please enter End date of previous plan.", {
            icon: 'error'
        });
    }

    const onClose = () =>{
        let isValid;
        for(let i=0;i<TempDataSplitFunding.length;i++){
           if(getValues(`plans.${i}.iszero`)){
              isValid = true;
           }
           else{
            isValid = false;
            break;
           }
        }
        props.onClose(isValid);
    }

    useEffect(()=>{
    
    },[PlanData])

    useEffect(()=>{
        
    },[formState])


    return (
        <div>
            {TempDataSplitFunding.length > 0 &&
            <ModalWrapperContainer
                title={TempDataSplitFunding[0]?.funderlist.length === 1 ? "Funding" : "Split Funding"}
                onClose={onClose}
                className="modal"
                content={TempDataSplitFunding.length > 0 &&
                        <>
                        <div>
                        <div className="accordion accordion-flush accordion-list mb-4" id={`user-service-ac`}>
                            {fields.map((data:any,i:any)=>{
                                return(
                                    <div key={i} className="accordion-item">
                                    <h2 className="accordion-header" id={`admission-details${i}`}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#admission-details1${i}`} aria-expanded="false" onClick={(e)=>currentindex(i)} aria-controls="#admission-details">
                                            {PlanData?.data?.length > 0 && PlanData?.data[0]?.planType === -1 &&
                                            <>
                                              {`${PlanData?.data[0]?.planName}`}
                                              </>
                                            }
                                             {PlanData?.data?.length > 0 && PlanData?.data[0]?.planType !== -1 &&
                                             <>
                                                    Plan{i+1}
                                             </>
                                             }
                                        </button>
                                    </h2>
                                    <div id={`admission-details1${i}`} className={`accordion-collapse ${caindex === 0 ? 'show' : caindex === i ? 'show':'collapse'}`} aria-labelledby={`admission-details${i}`} data-bs-parent={`#user-service-ac`}>
                                        <div className="accordion-body ps-0 pb-0" >
                                        <div className="d-flex align-items-center flex-wrap">
                                <div className="d-flex align-items-center justify-content-center me-3 mb-2" >
                                    <label className="bold me-2 no-wrap">{t('SplitFundingPopup.total_charge')}:</label>
                                    <span className="me-1">£</span>
                                    <input
                                        disabled={true}
                                        type="text"
                                        {...register(`plans.${i}.weeklycharge`)}
                                        name={`plans.${i}.weeklycharge` as const}
                                        className="form-control white custom-width"
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-center me-3 mb-2" >
                                    <label className="bold me-2 no-wrap">{t('SplitFundingPopup.Weekly_charge')}:</label>
                                    <span className="me-1">£</span>
                                    <input
                                        disabled={true}
                                        type="text"
                                        {...register(`plans.${i}.dailyCoreFee`)}
                                        name={`plans.${i}.dailyCoreFee` as const}
                                        className="form-control white custom-width"
                                    />
                                </div>
                                {/* {TempDataSplitFunding[0].funderlist.length > 1 &&
                                <p className="purple-text bold no-wrap me-3 mb-2">
                                    Breakdown
                                    <i
                                        className="fa-solid fa-circle-info gray-text ms-1"
                                        data-tooltip-id="tooltip-breakdown"
                                        data-tooltip-content={`Social Service Charges: ${data.socialfund} Healthcare Charges: ${data.healthfund}`}
                                    ></i>
                                </p>
                                } */}
                                <div className="d-flex datepicker-input-css me-3 align-items-center mb-2">
                                    <label className="no-wrap me-2">{t('SplitFundingPopup.Start_Date')}:</label>
                                    <Controller
                                        control={control}
                                        name="`plans.${i}.admissiondate`"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        return <DatePicker className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label="Start Date"
                                        value={dayjs(new Date((getValues(`plans.${i}.admissiondate`))))}
                                        disabled={true}
                                        format="DD/MM/YYYY"
                                        {...fieldProps}
                                        onChange={(date:any) => {
                                        fieldProps.onChange(date);
                                         updatedate('sdate',date,i);
                                        // getDate(date);
                                        // updateData(date,i,-1);
                                    }
                                }
                                //  disableFuture
                                views={['year', 'month', 'day']}
                                slotProps={{actionBar: { actions: ['clear','today'] },}}
                                />;
                            }}
                            />
                                </div>
                                <div className="d-flex datepicker-input-css align-items-center mb-2">
                                    <label className="no-wrap me-2">{t('SplitFundingPopup.End_Date')}:</label>
                                    <Controller
                                        control={control}
                                        name="`plans.${i}.enddate`"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        return <DatePicker className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label="End Date"
                                        value={dayjs(new Date((getValues(`plans.${i}.enddate`))))}
                                        // value={getValues(`plans.${i}.enddate`)}
                                        format="DD/MM/YYYY"
                                        {...fieldProps}
                                        onChange={(date:any) => {
                                            updatedate('edate',date,i);
                                        fieldProps.onChange(date);
                                        // props.getDate(date);
                                    }
                                }

                                minDate={i === 0 ? dayjs(new Date(TempDataSplitFunding[0].lastinvoicedate)) : ''}
                                views={['year', 'month', 'day']}
                                slotProps={{actionBar: { actions: ['clear','today'] },}}
                                />;
                            }}
                            />
                                </div>
                                {(Number(getValues(`plans.${i}.continenceCareFee`)) !== 0 || Number(getValues(`plans.${i}.continenceCareFee`)) !== 0.00) && 
                                <div className="d-flex align-items-center justify-content-center me-3 mb-2 margin-left-md" >
                                    <label className="bold me-2 no-wrap">{t('SplitFundingPopup.continence_charge')}:</label>
                                    <span className="me-1">£</span>
                                    <input
                                        disabled={true}
                                        type="text"
                                        {...register(`plans.${i}.continenceCareFee`)}
                                        name={`plans.${i}.continenceCareFee` as const}
                                        className="form-control white custom-width"
                                    />
                                </div>
                                }
                                {(Number(getValues(`plans.${i}.fncFee`)) !== 0 || Number(getValues(`plans.${i}.fncFee`)) !== 0.00) && 
                                <div className="d-flex align-items-center justify-content-center me-3 mb-2" >
                                    <label className="bold me-2 no-wrap">{t('SplitFundingPopup.fnc_charge')}:</label>
                                    <span className="me-1">£</span>
                                    <input
                                        disabled={true}
                                        type="text"
                                        {...register(`plans.${i}.fncFee`)}
                                        name={`plans.${i}.fncFee` as const}
                                        className="form-control white custom-width"
                                    />
                                </div>
                                }
                                {(Number(getValues(`plans.${i}.totaladditionalfee`)) !== 0 || Number(getValues(`plans.${i}.totaladditionalfee`)) !== 0.00) && 
                                <div className="d-flex align-items-center justify-content-center me-3 mb-2" >
                                    <label className="bold me-2 no-wrap">{t('SplitFundingPopup.additionalfee')}:</label>
                                    <span className="me-1">£</span>
                                    <input
                                        disabled={true}
                                        type="text"
                                        {...register(`plans.${i}.totaladditionalfee`)}
                                        name={`plans.${i}.totaladditionalfee` as const}
                                        className="form-control white custom-width"
                                    />
                                </div>
                                }
                               
                            </div>
                            <hr className="dash-style" />
                            <div className='table-responsive'>
                            <table width="100%" className="split-table table mb-0 mt-3">
                                <tbody>
                                    <tr>
                                        <th>{t('SplitFundingPopup.Funder_Type')}</th>
                                        <th style={{ width: '25%' }}>{t('SplitFundingPopup.Funder_Name')}</th>
                                        <th>{t('SplitFundingPopup.AmountorPercentage')}</th>
                                        <th style={{ width: '12%' }}>{t('SplitFundingPopup.Proportion')}</th>
                                        <th style={{ width: '16%' }}>{t('SplitFundingPopup.Proportion_Value')} (£)</th>
                                        <th>{t('SplitFundingPopup.IsApplicable')}</th>
                                        <th>{t('SplitFundingPopup.Autopay')}</th>
                                    </tr>
                                    {TempDataSplitFunding[i].funderlist.length > 0 && TempDataSplitFunding[i].funderlist.map((data:any,index:number)=>{
                                        return(<>
                                    <tr key={index}>
                                        <td>{data.fundertype}</td>
                                        <td>{data.fundername}</td>
                                        <td style={{ pointerEvents: TempDataSplitFunding.length === 1 && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1 ? 'all' : TempDataSplitFunding.length > 1 && i === 0  ? 'none' : i === 1 ?'all' : 'none'}}>
                                            <div key={index} className="d-flex align-items-center p-0">
                                                <p className="me-1  ">Amount</p>
                                                <label className="switch-custom">
                                                    <input type="checkbox" {...register(`plans.${i}.funderlist.${index}.ispercentage`, {
                                                            onChange: (e: any) => {
                                                                updateData(e,i,index);
                                                            }
                                                        })} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <p className="ms-1  ">Percentage</p>
                                            </div>
                                        </td>
    
                                        <td style={{ pointerEvents: TempDataSplitFunding.length === 1 && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1 ? 'all' : TempDataSplitFunding.length > 1 && i === 0  ? 'none' : i === 1 ?'all' : 'none'}}>
                                            <input   type="text"
                                            disabled={getValues(`plans.${i}.funderlist.${index}.isapplicable`) === true ? true : false }
                                        {...register(`plans.${i}.funderlist.${index}.proportion`, {
                                            onChange: (e: any) => {
                                                updateData(e,i,index);
                                            }
                                        })}
                                       
                                        onKeyDown={(e:any) => {
                                            const cursorPosition = e.target.selectionStart;
                                            if (
                                                e.key === 'ArrowLeft' ||
                                                e.key === 'ArrowRight' ||
                                                e.key === 'Backspace' ||
                                                e.key === 'Delete' ||
                                                e.key === 'Tab' ||
                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                              ) {
                                                // Allow the default behavior for these keys
                                                return;
                                              }
                                              else{
                                                if(getValues(`plans.${i}.funderlist.${index}.ispercentage`)){
                                                    if(window.$utils.onKeypressValidateSplifunding('number',  e.target.value.substring(0, cursorPosition) +
                                                    e.key +
                                                    e.target.value.substring(cursorPosition)) === false) {
                                                        e.preventDefault();
                                                    } 
                                                }
                                                else{
                                                    if(window.$utils.onKeypressValidateApplicable('number',  e.target.value.substring(0, cursorPosition) +
                                                    e.key +
                                                    e.target.value.substring(cursorPosition)) === false) {
                                                        e.preventDefault();
                                                    } 
                                                }
                                                
                                              }
                                               
                                          
                                        }}
                                        name={`plans.${i}.funderlist.${index}.proportion` as const} 
                                        //@ts-ignore
                                        className={`form-control  white ${errors.plans?.[i]?.funderlist?.[index]?.proportion ? 'is-invalid' : ''}`} />
                                        </td>
    
                                        <td>
                                            <input type="text"
                                            disabled={true}
                                        {...register(`plans.${i}.funderlist.${index}.proportionvalue`, {
                                            onChange: (e: any) => {
                                                updateData(e,i,index);
                                            },

                                        })}
                                        name={`plans.${i}.funderlist.${index}.proportionvalue` as const}  className="form-control  white" />
                                        </td>
                                        <td style={{ pointerEvents: TempDataSplitFunding.length === 1 && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1 ? 'all' : TempDataSplitFunding.length > 1 && i === 0  ? 'none' : i === 1 ?'all' : 'none'}}>
                                            <div key={index} className="d-flex align-items-center p-0">
                                                <p className="me-1">{t('SplitFundingPopup.Yes')}</p>
                                                <label className="switch-custom">
                                                    <input type="checkbox" className='yes-no-toggle' {...register(`plans.${i}.funderlist.${index}.isapplicable`, {
                                                            onChange: (e: any) => {
                                                                updateData(e,i,index);
                                                            }
                                                        })} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <p className="ms-1">{t('SplitFundingPopup.No')}</p>
                                            </div>
                                        </td>
                                        {/* style={{ pointerEvents: DataPatientDraftInfo !== "" && props.funderlist[0]?.isinvoicecreated === 1 ? 'none' : 'all'}} */}
                                        <td style={{ pointerEvents: TempDataSplitFunding.length === 1 && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1 ? 'all' : TempDataSplitFunding.length > 1 && i === 0  ? 'none' : i === 1 ?'all' : 'none'}}>
                                            <div className="d-flex align-items-center p-0">
                                                <p className="me-1">{t('SplitFundingPopup.Yes')}</p>
                                                <label className="switch-custom">
                                                    <input type="checkbox"
                                                    disabled={getValues(`plans.${i}.funderlist.${index}.isapplicable`) === true ? true : false }
                                                     className='yes-no-toggle' {...register(`plans.${i}.funderlist.${index}.isautopay`, {
                                                            onChange: (e: any) => {
                                                                updateData(e,i,index);
                                                            }
                                                        })}  />
                                                    <span className="slider round"></span>
                                                </label>
                                                <p className="ms-1">{t('SplitFundingPopup.No')}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </>)
                                    })}
                                </tbody>
                            </table>
                            </div>
                            <div className="gray-border-box text-center mt-3 mb-3">
                                <p className="bolder">
                                    Remaining Amount: £ <span>{Number(getValues(`plans.${i}.remaining`)).toFixed(2)}</span>
                                    {/* { console.log(Number(getValues(`plans.${i}.remaining`)))} */}
                                    {/* { console.log(getValues(`plans`))} */}
                                </p>
                            </div>
                            {props.isaddplan && (TempDataSplitFunding[0].enddate !== "") && TempDataSplitFunding.length < 2 && props.funderlist?.length > 0 && props.funderlist[0]?.isinvoicecreated !== 0 && props.iseditable === true &&
                        <div className="mt-3" style={{ pointerEvents: (props.iseditable === true && DataPatientDraftInfo !== "") ? 'all' : 'none' }}>
                            <a onClick={props.onAddplan}>
                                <i className="fa-solid fa-circle-plus"></i> {t('SplitFundingPopup.Add_Plan')}
                            </a>
                        </div>
                        }
                        {props.isaddplan && (TempDataSplitFunding[0].enddate === "") && props.funderlist[0]?.isinvoicecreated !== 0 &&
                        <div className="mt-3">
                            <a onClick={showValidationMessage}>
                                <i className="fa-solid fa-circle-plus"></i> {t('SplitFundingPopup.Add_Plan')}
                            </a>
                        </div>
                        }
                        <Tooltip id="tooltip-breakdown" className="my-tooltip" />

                        <div className='text-center'>
                        <button type="button" disabled={!getValues(`plans.${i}.iszero`)}  onClick={props.onSave} className="btn btn-primary me-2">
                        {t('SplitFundingPopup.Save_btn')}
                        </button>
                        {/* <button type="button" className="btn btn-secondary" onClick={props.onClose}>
                            Close
                        </button> */}
                    </div>


                                        </div>
                                    </div>


                                </div>


                                );
                            })}

                        </div>



                    </div>
                        </>


                }

                footer={
                  <div></div>
                }
            />
}
{TempDataSplitFunding.length == 0 &&
<></>
            // <ModalWrapperContainer
            //     title={"Split Funding"}
            //     onClose={props.onClose}
            //     className="modal modal-extrasmall"
            //     content={TempDataSplitFunding.length == 0 &&
            //         <>
            //         <div>
            //             <p className='lead-text text-center'>{t('SplitFundingPopup.Split_Funding_Defined')}</p>
            //         </div>
            //             </>


            //     }
            //     footer={
            //         <div></div>
            //       }
            //   />
                }
        </div>
    );
};

export default SplitFundingModalView;
