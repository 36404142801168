import React from 'react';
import AdmittedView from './admittedView';

const AdmittedContainer = (props: any) => {
    return (
        <div>
            <AdmittedView searchValue={props.searchValue} tabID={props.tabID} />
        </div>
    );
};

export default AdmittedContainer;
