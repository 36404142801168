import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import bedInformationReducer from './dashboard/bedInformationReducer';
import pendingActivitiesReducer from './dashboard/pendingActivitiesReducer';
import wardinformationReducers from './dashboard/wardinformationReducers';
import wardbedinformationReducers from './dashboard/wardBedinformationReducer';
import navigationReducers from './dashboard/navigationReducer';
import listPatientReducer from './patient/listPatientReducer';
import loginReducer from './Auth/loginReducer';
import rememberMeReducer from './Auth/rememberMeReducer';
import cityInformationReducer from './dashboard/getCityReducer'
import relationshipInformationReducer from './dashboard/getRelationshipReducer'
import patientInformationReducer from './dashboard/getPatientTypeReducer'
import funderInformationReducer from './dashboard/getFunderReducer'
import patientDetailsReducer from './patient/patientDetailsReducer';
import listReasonsReducer from './discharge/listReasonsReducer';
import transferReasonsReducer from './patient/transferReasonsReducer';
import vacantBedReducer from './patient/vacantBedReducer';
import patientDraftReducer from './dashboard/draftPatientData';
import realeaseReasonReducer from './patient/realeaseReasonReducer';
import listContractReducer from './contract/listContractReducer';
import listMaintenanceReasonsReducer from './maintenance/listMaintenanceReasonsReducer';
import listMaintenanceBedsReducer from './maintenance/listMaintenanceBedsReducer';
import splitFundingReducer from './splitFunding/splitFundingReducer';
import tempSplitFundingReducer from './splitFunding/tempSplitFundingReducer';
import tempSplitFundingReducer1 from './splitFunding/tempSplitFundingReducer1';
import serviceDetailReducer from './Auth/serviceDetailReducer';
import pageNavigationReducer from './general/pageNavigationReducer';
import listTaskReducer from './task/TaskListReducer';
import invoiceStatisticsReducer from './invoice/invoiceStatisticsReducer';
import redirectToFromURLReducer from './general/redirectToFromURLReducer';
import serviceInformationReducer from './lead/getServicetypeReducer'
import leadInformationReducer from './lead/getLeaddataReducer'
import planDataReducer from './splitFunding/planDataReducer'
import facilityInformationReducer from './lead/getFacility'
import roleSelectedReducer from './Auth/roleSelectedReducer';
import referralOverViewReducer from './referral/referralOverViewReducer';
import listUserOrganizationReducer from './referral/listUserOrganizationReducer';
import organizationDetailReducer from './Auth/organizationDetailReducer';
import gridFilterStateReducer from './general/gridFilterStateReducer';
import getAssessmentDetailsReducer from './assessment/getAssessmentDetailsReducer';
import listMessagesReducer from './referral/listMessagesReducer';
import integrationgridFilterStateReducer from './general/integrationgridFilterStateReducer';
import serviceProviderDetailReducer from './serviceProviderDetail/serviceProviderDetailReducer';
import saveForLaterModalReducer from './SaveForLater/saveForLaterModalReducer';
import saveForLaterDrawerReducer from './SaveForLater/saveForLaterDrawerReducer';
import saveForLaterServicesWishlistReducer from './SaveForLater/saveForLaterServicesWishlistReducer';
import appliedFilterReducer from './filter/appliedFilterReducer';
import serviceListsReducer from './general/serviceListsReducer';
import cityWiseLatLngListReducer from './serviceProvider/cityWiseLatLngListReducer';
import userLocationReducer from './general/userLocationReducer';
import nhsLoginReducer from './NHS/nhsLoginReducer';
import filterMetaDataReducer from './filter/filterMetaDataReducer';
import advanceFilterReducer from './filter/advanceFilterReducer';
import saveForLaterServicesList from './SaveForLater/saveForLaterServicesList'
import hoveredServiceReducer from './general/hoveredServiceReducer';
import notificationBellSwingReducer from './Notifications/notificationBellSwingReducer';
import listNotificationsReducer from './referral/listNotificationsReducer';
import notificationsCountReducer from './referral/notificationsCountReducer';

const serviceDetailPersistConfig = { key: 'serviceDetail', storage: storageSession };
const organizationDetailPersistConfig = { key: 'organizationDetail', storage: storageSession };

const reducers = combineReducers({
    bedInformation: bedInformationReducer,
    pendingActivities: pendingActivitiesReducer,
    wardInformation: wardinformationReducers,
    wardBedInformation: wardbedinformationReducers,
    navigation: navigationReducers,
    pageNavigation: pageNavigationReducer,
    listPatient: listPatientReducer,
    patientDetails: patientDetailsReducer,
    login: loginReducer,
    rememberMe: rememberMeReducer,
    listReasons: listReasonsReducer,
    cityInformation: cityInformationReducer,
    patientInformation: patientInformationReducer,
    transferReasons: transferReasonsReducer,
    vacantBed: vacantBedReducer,
    funderInformation: funderInformationReducer,
    realeaseReason: realeaseReasonReducer,
    patientDraft: patientDraftReducer,
    listContract: listContractReducer,
    listTask: listTaskReducer,
    invoiceStatistics: invoiceStatisticsReducer,
    relationshipInformation: relationshipInformationReducer,
    listMaintenanceReasons: listMaintenanceReasonsReducer,
    listMaintenanceBeds: listMaintenanceBedsReducer,
    splitFunding: splitFundingReducer,
    tempSplitFunding: tempSplitFundingReducer,
    tempSplitFunding1: tempSplitFundingReducer1,
    planData: planDataReducer,
    serviceDetail: persistReducer(serviceDetailPersistConfig, serviceDetailReducer),
    redirectToFromURL: redirectToFromURLReducer,
    roleSelected: roleSelectedReducer,
    referralOverView: referralOverViewReducer,
    serviceInformation: serviceInformationReducer,
    leadInformation: leadInformationReducer,
    facilityInformation: facilityInformationReducer,
    listUserOrganization: listUserOrganizationReducer,
    organizationDetail: persistReducer(organizationDetailPersistConfig, organizationDetailReducer),
    gridFilterState: gridFilterStateReducer,
    getAssessmentDetails: getAssessmentDetailsReducer,
    listMessages: listMessagesReducer,
    integrationgridFilterState: integrationgridFilterStateReducer,
    serviceProviderDetailState: serviceProviderDetailReducer,
    saveForLaterModal: saveForLaterModalReducer,
    saveForLater: saveForLaterDrawerReducer,
    saveForLaterWishlistModal: saveForLaterServicesWishlistReducer,
    serviceLists: serviceListsReducer,
    cityWiseLatLngList: cityWiseLatLngListReducer,
    userLocation: userLocationReducer,
    nhsLogin: nhsLoginReducer,
    appliedFilter: appliedFilterReducer,
    metaFilter: filterMetaDataReducer,
    filterList: advanceFilterReducer,
    saveForLaterServicesList : saveForLaterServicesList,
    hoveredService: hoveredServiceReducer,
    notificationsBellSwing :notificationBellSwingReducer,
    listNotifications : listNotificationsReducer,
    notificationsCount : notificationsCountReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['login', 'roleSelected', 'rememberMe', 'organizationDetail', 'serviceDetail', 'redirectToFromURL', 'gridFilterState', 'integrationgridFilterState', 'appliedFilter', 'metaFilter', 'userLocation'] // only login will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
            // serializableCheck: {
            //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            // }
        }),
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
