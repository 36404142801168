import React from 'react';
import AmenitiesView from './AmenitiesView';

const AmenitiesContainer = () => {
    return (
        <div>
            <AmenitiesView />
        </div>
    );
};

export default AmenitiesContainer;