import { AxiosError } from "axios";
import { API_ENDPOINT, BASEURLDEV } from "./ApiConstants";
import instance from "./axiosinstance";

export const GetLocationListForMenu = async (payload: any) => {
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetLocationList}`, payload)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
};

export const GetServiceListForMenu = async (payload: any) => {
    return await instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetSearviceSearchList}`, payload)
        .then((response) => {
            const resultData = response?.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData = errorResponse;
                return errorData;
            } else {
                const errorData = error.response;
                return errorData;
            }
        });
    };