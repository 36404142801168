import React, { useEffect, useRef, useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import PhotoAlbum from "react-photo-album";
import viewall from "../../../../../assets/images/view-all.svg"
import ClientPortal from '../../../../../components/ClientPortal';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import AOS from "aos";

import eventEmitter from '../../../../../utils/eventEmitter';

const ImageLightboxView = (props: any) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();

  const thumbnailsRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [showPortal, setShowPortal] = useState(false);
  const [imageList, setImageList] = useState<any>(null)
  const [imageListCategory,setImageListCatgory] = useState<any>(null)
  const handleModal = (event: any) => {
    event.preventDefault();
    setShowPortal((prevState) => !prevState);
  };

  const showImageLightBox = () => {
    setShowPortal((prevState) => !prevState);
  }

  useEffect(() => {
    eventEmitter.on("showImageLightBox", showImageLightBox);
    return () => {
      eventEmitter.off("showImageLightBox", showImageLightBox);
    }
  }, []);

  useEffect(() => {
    if (showPortal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [showPortal]);

  const openAlbum = (index: any) => {
    setIndex(index)
    setOpen(true)
  }

  const closeAlbum = () => {
    setOpen(false)
  }

  useEffect(() => {
    if(props?.serviceImages?.length > 0) {
      let imageList: any = []
      let imageCatList = props?.serviceImages.map((item: any) => {
        item.serviceImage = item.serviceImage.map((val: any, index: any) => {
          imageList.push(val)
          val.index = imageList.length - 1
          return val
        })
        return item;
      })
      setImageListCatgory(imageCatList);
      setImageList(imageList)

    }
  }, [props?.serviceImages])

  return (
    <div>
      {imageListCategory !== null && imageListCategory?.length > 0 && <Lightbox
        plugins={[Counter, Fullscreen]}
        counter={{
          container: {
            style: {
              top: "unset",
              bottom: 0,
              right: 0,
              left: 0,
              margin: "0 auto",
              textAlign: "center",
              fontSize: "16px",
            },
          },
        }}
        open={open}
        index={index}
        close={closeAlbum}
        slides={imageList?.map((imageUrl: any) => ({ src: imageUrl?.imagePath, alt: "Image" }))}
      />}

      <button
        type="button"
        onClick={handleModal}
        className="btn btn-view-all d-flex align-items-center"
      >
        <img src={viewall} alt="View All" width={13} className="me-1" />
        {t("ServiceDetail.View_All")}
      </button>

      <ClientPortal selector="myportal" show={showPortal}>
        <div className="overlay" onClick={handleModal}></div>
        <div className="modal modal-collage-image">
          <div className="header">
            <a onClick={handleModal} className="back-collage-btn">
              <i className="fa-solid fa-angle-left"></i>
            </a>
            {/* <div className="d-flex">
              <a href="#" className="indigo-text me-2 bold  hover-gray-btn">
                <i className="fa-solid  fa-share-nodes me-1"></i>
                <span className="underline">{t("ServiceDetail.Share")}</span>
              </a>
              <a href="#" className="indigo-text ms-2 bold  hover-gray-btn">
                <i className="fa-regular fa-heart  me-1"></i>
                <span className="underline">{t("ServiceDetail.Save")}</span>
              </a>
            </div> */}
          </div>
          <div className="body">
            <div className="category-list">
              <h4 className="mb-4 mt-4 ps-2 indigo-text">{t("ServiceDetail.Photo_tour")}</h4>
              <ul className="list-unstyled d-flex tour-box-list flex-wrap">
                {imageListCategory !== null && imageListCategory?.length > 0 && imageListCategory?.map((se: any, index: any) => {
                  return (
                    <li key={index}>
                      <a href={`#${se?.categoryName}`}>
                      <div className="tour-box-image">
                        <img
                          src={se?.categoryImageUrl}
                          alt="Photo Tour Image"
                          className="img-fluid"
                        />
                      </div>
                      <span className="mt-2 d-block">{se?.categoryName}</span>
                      </a>
                    </li>
                  )
                })}
              </ul>
              {imageListCategory !== null && imageListCategory?.length > 0 &&
                imageListCategory?.map((se: any, index: any) => {
                  return (
                    <div key={index} className="d-flex  category-section" id={se?.categoryName}>
                      <div className="category-column1">
                        <p>{se?.categoryName}</p>
                      </div>
                      <div className="category-column2">
                        <div className="category-layout">
                          <PhotoAlbum
                            layout="columns"
                            photos={se?.serviceImage && se?.serviceImage.map((image: any) => ({
                              src: image.imagePath,
                              width: 1280,
                              height: 853,
                              index: image.index
                            }))}
                            columns={2}
                            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }: any) => (
                              <div style={wrapperStyle} onClick={() => openAlbum(photo.index)}>
                                {renderDefaultPhoto({ wrapped: true })}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div>
        </div>
      </ClientPortal>
    </div>
  );
};

export default ImageLightboxView;