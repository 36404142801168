import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientDraftModel } from '../../utils/model';

const initialState: PatientDraftModel = {
    StatusPatientDraftInfo: false,
    DataPatientDraftInfo: '',
    MessagePatientDraftInfo: '',
    ErrorPatientDraftInfo: ''
};

export const patientDraftSlice = createSlice({
    name: 'patientDraft',
    initialState,
    reducers: {
        setPatientDraftAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientDraftInfo = true;
            state.DataPatientDraftInfo = payload;
            state.MessagePatientDraftInfo = 'Success';
        },
        removePatientDraftAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientDraftInfo= false;
            state.DataPatientDraftInfo= '';
            state.MessagePatientDraftInfo= '';
            state.ErrorPatientDraftInfo= '';
        },
        errorPatientDraftAction: (state, { payload }: PayloadAction) => {
            state.StatusPatientDraftInfo = false;
            state.DataPatientDraftInfo = [];
            state.MessagePatientDraftInfo = 'Failed';
            state.ErrorPatientDraftInfo = payload;
        }
    },
    extraReducers: {}
});

export const { setPatientDraftAction, errorPatientDraftAction,removePatientDraftAction } = patientDraftSlice.actions;

export default patientDraftSlice.reducer;
