import React, { useEffect, useState } from 'react';
import CostRejectModalView from './CostRejectModalView';
import { NHSRejectsCosting, RMRejectsCosting, getCostingRejectiondata, getCostingRejectiondataNHS } from '../../service/costing';
import swal from 'sweetalert';
import warning from '../../assets/images/warning.png';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { NHSAcceptRejectPooledService } from '../../service/nhs-user';
import { getLeaddata } from '../../service/leads';
import { RootState } from '../../store';

const CostRejectModalContainer = (props: any) => {
    const [reasons, setreason] = useState<any>();
    const [reasonNHS, setreasonNHS] = useState<any>();
    const [rejectiondata, setrejectiondata] = useState<any>({ 'desc': '' });
    const { Data } = useSelector((state: RootState) => state.navigation);
    const dispatch = useDispatch();

    useEffect(() => {
        async function getreason() {
            const res = await getCostingRejectiondata(2);
            setreason(res.data);
        }

        async function getreasonforNHS() {
            const payload = {
                limit : 10,
                skip : 0,
                query : "",
                reasonType : 7
            }
            const res = await getCostingRejectiondataNHS(payload);
            setreasonNHS(res.data);
        }

        if (!props.nhsUserPooledLeadForReject) {
            if(props.isNHSAction){
                getreasonforNHS();
                setrejectiondata({
                    ...rejectiondata,
                    CurrentCost: props.costingData?.total
                });
            }else{
                getreason();
                setrejectiondata({
                    ...rejectiondata,
                    CurrentCost: props.costingData?.total
                });
            }
           
        } else {
            setreason(props.costingData);
        }
    }, [props.nhsUserPooledLeadForReject, props.costingData]);



    const updateData = (e: any) => {
        setrejectiondata({
            ...rejectiondata,
            [e.target.name]: e.target.value
        });
    }

    const submitalert = () => {
        swal({
            title: '',
            text: rejectiondata.charge == 'withcharge' ? `Are you sure you want to request for revise costing for lead ${props.referralid} ? ` : `Are you sure you want to reject the lead ${props.referralid} ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                if (!props.nhsUserPooledLeadForReject) {
                    submit();
                } else {
                    nhsUserRejectLeadFromPooling();
                }
            } else {
            }
        });
    }

    async function submit() {
        let data = {
            "rcId": props.costingData.rcId,
            "isRejectWithReviseRequest": rejectiondata.charge == 'withcharge' ? true : false,
            "currentCost": rejectiondata.CurrentCost || 0,
            "expectedCost": rejectiondata.ExpectedCost || 0,
            "rejectionReasonId": rejectiondata.reason,
            "comment": rejectiondata.desc

        }
        if (props.isNHSAction) {
            const res = await NHSRejectsCosting(data);
            if (res.status === true) {
                swal(res.message, {
                    icon: 'success'
                }).then(async (confirm: any) => {
                    props.onClose();
                    // let obj: any = {
                    //     ShowClass: false,
                    //     FormName: '',
                    //     Data: '',
                    //     ShowToggle: false
                    // };
                    // dispatch(setNavigationAction(obj));
                });

            } else {
                swal(res.message, {
                    icon: 'error'
                }).then(async (confirm: any) => {
                    props.onClose();

                });;
            }
        } else {

            const res = await RMRejectsCosting(data);
            if (res.status === true) {
                swal(res.message, {
                    icon: 'success'
                }).then(async (confirm: any) => {
                    props.onClose();
                    // let obj: any = {
                    //     ShowClass: false,
                    //     FormName: '',
                    //     Data: '',
                    //     ShowToggle: false
                    // };
                    // dispatch(setNavigationAction(obj));
                });

            } else {
                swal(res.message, {
                    icon: 'error'
                }).then(async (confirm: any) => {
                    props.onClose();

                });;
            }
        }
    }

    const nhsUserRejectLeadFromPooling = async () => {
        const payload = {
            poolingId: props.poolingId,
            nhsPoolingStatus: 2,
            rejectionReasonId: rejectiondata.reason,
            remarks: rejectiondata.desc
        };

        const response = await NHSAcceptRejectPooledService(payload);
        if (response?.status === true) {
            swal(response?.message, {
                icon: 'success'
            }).then(async () => {
                props.setShowCostingModal(false);
                getLeaddata(Data.leadid, Data.leadstatus);
            });
        } else {
            swal("Something went wrong.", {
                icon: 'error'
            });
        }
    }

    return (
        <div>
            <CostRejectModalView
                onClose={props.onClose}
                footer={props.footer}
                title={props.title}
                className={props.className}
                content={props.content}
                reason={reasons}
                updateData={updateData}
                submitalert={submitalert}
                costingData={props.costingData}
                nhsUserPooledLeadForReject={props.nhsUserPooledLeadForReject}
                reasonNHS={reasonNHS}
                isNhsAction={props.isNHSAction}
            />
        </div>
    );
};

export default CostRejectModalContainer;