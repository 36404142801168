import React from 'react';
import ImageLightboxView from './ImageLightboxView';

const ImageLightboxContainer = (props:any) => {
    return (
        <div>
            {props?.serviceImages?.length > 0 && <ImageLightboxView serviceImages={props?.serviceImages} />}
        </div>
    );
};

export default ImageLightboxContainer;