import React from 'react';
import ServiceUserProfileView from './ServiceUserProfileView';

const ServiceUserProfileContainer = (props: any) => {
    return (
        <div>
            <ServiceUserProfileView orgName={props.orgName} serviceUserProfileFormValid={props.serviceUserProfileFormValid} serviceUserProfileDataChange={props.serviceUserProfileDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default ServiceUserProfileContainer;