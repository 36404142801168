import BedPage from './Bed-Section/bedContainer';
import FilterContainer from './Filter/filterContainer';
import Progresschart from '../../../../components/Progresschart';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const WardView = (props: any) => {

    const [active, setActive] = useState(0);

    const ref = useRef<HTMLInputElement>(null);

    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    
    const handleScroll1 = (offset: any) => {
        if (ref.current) {
            return window.scrollTo({
                top: (ref.current.scrollTop -= 500),
                behavior: 'smooth'
            });
        }
    };
    const handleScroll2 = (offset: any) => {
        if (ref.current) {
            return window.scrollTo({
                top: (ref.current.scrollTop += 500),
                behavior: 'smooth'
            });
        }
    };


    return (
        <>
            <div>
                <div className="service-title text-center">{DataServiceDetail.serviceName}</div>
                <div className="col-xl-12 mt-2">
                    <div className={`d-flex align-items-start accordion-custom ${props.wardData?.data?.length < 2 ? 'tab-horizontal' : ''}`}>
                        {props.isVisible && (
                            <div>
                                <a className="prev-button" onClick={handleScroll1} style={{ cursor: "pointer" }}>
                                    <i className="fa-solid fa-chevron-up"></i>
                                </a>
                                <a className="next-button" onClick={handleScroll2} style={{ cursor: "pointer" }}>
                                    <i className="fa-solid fa-chevron-down"></i>
                                </a>
                            </div>
                        )}
                        <div className="nav  nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" tabIndex={0} ref={ref}>
                            {props.wardData !== '' &&
                                props.wardData.data.map((wards: any, index: number) => {
                                    return (
                                        <button
                                            className={`nav-link ${wards.vacant > 0 ? 'vacant-ward' : 'occupied-ward'}   ${wards.active ? `${wards.vacant > 0 ? 'active vacant-ward' : 'active occupied-ward'}` : ''}`}
                                            id={wards.wardId}
                                            key={wards.wardId}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#ward${wards.wardId}Tab`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`#ward${wards.wardId}Tab`}
                                            aria-selected="true"
                                            onClick={props.handleSubmit}
                                        >
                                            <p className="gray-text mb-1">{wards.wardName}</p>
                                            {/* <div className="tab_pills_detail d-flex align-items-center justify-content-between flex-wrap">
                                            <div className="count_up flex-5">
                                                <h3>
                                                    <span className="me-1">{wards.occupied}</span>
                                                    <svg
                                                        fill="#4e4851"
                                                        width="28"
                                                        version="1.1"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 391.23 264.5"
                                                        xmlSpace="preserve"
                                                    >
                                                        <style>{`"enable-background:new 0 0 391.23 264.5;"`}</style>
                                                        <g>
                                                            <path
                                                                d="M0,0c8.36,0,16.72,0,25.5,0c0,45.85,0,90.89,0,136.39c122.14,0,243.86,0,365.73,0c0,42.7,0,84.94,0,127.41
                                  c-8.38,0-16.58,0-25.28,0c0-1.65,0-3.13,0-4.61c0-19.75-0.07-39.49,0.07-59.24c0.02-3.19-0.9-4.05-4.06-4.05
                                  c-110.83,0.08-221.66,0.08-332.49,0c-3.14,0-4.11,0.83-4.08,4.03c0.14,19.74,0.07,39.49,0.07,59.24c0,1.51,0,3.02,0,5.33
                                  c-8.73,0-17.09,0-25.46,0C0,176.34,0,88.17,0,0z"
                                                            />
                                                            <path
                                                                d="M374.78,127.68c-85.6,0-170.37,0-255.63,0c0-2.1,0-4.09,0-6.09c0.02-11.46-0.12-22.93,0.09-34.39
                                  c0.34-18.08,9.85-27.34,28.02-27.38c29.17-0.06,58.34-0.02,87.51-0.02c37.07,0,74.14-0.01,111.21,0.01
                                  c7.96,0,15.86,1.06,21.14,7.66c2.9,3.63,5.83,8.34,6.14,12.75C374.38,95.91,374.36,111.68,374.78,127.68z"
                                                            />
                                                            <path
                                                                d="M68.05,129.47c-19.47,0.01-35.31-15.71-35.62-35.36c-0.31-19.28,16.14-35.84,35.6-35.85
                                  c19.44-0.01,35.94,16.58,35.65,35.84C103.39,113.71,87.52,129.46,68.05,129.47z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </h3>
                                            </div>
                                            <div className="flex-5 text-center mb-1">
                                                <Progresschart className="gray small" value={wards.donutPer !== '' ? wards.donutPer : 0} />
                                            </div>
                                            <div className="count_up flex-5">
                                                <h3>
                                                    <span className="me-1">{wards.reserved}</span>
                                                    <svg
                                                        version="1.1"
                                                        fill="#4e4851"
                                                        width="28"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 695.45 473.64"
                                                        xmlSpace="preserve"
                                                    >
                                                        <style>{`enable-background:new 0 0 695.45 473.64;"`}</style>
                                                        <path
                                                            d="M0,11.3C3.75,2.75,10.4-0.11,19.53,0C41.4,0.28,44.52,3.16,44.52,25.27c0,67.46,0,134.91,0,202.37c0,2.71,0,5.42,0,9.18
                                  c3.14,0,5.97,0,8.8,0c206.86,0,413.72,0,620.59,0c17.23,0,21.54,4.36,21.54,21.77c0,64.97,0,129.93,0,194.9
                                  c0,15.13-4.81,19.97-19.83,20.02c-21.56,0.07-24.68-3.06-24.68-24.77c0-28.52,0-57.04,0-85.57c0-2.43,0-4.87,0-7.66
                                  c-202.29,0-403.83,0-606.07,0c-0.12,2.49-0.33,4.89-0.33,7.28c-0.02,30.11,0,60.21-0.02,90.32c-0.01,15.28-5.07,20.35-20.22,20.39
                                  c-1.58,0-3.18-0.12-4.75,0.02C10.4,474.34,3.75,470.88,0,462.33C0,311.99,0,161.64,0,11.3z"
                                                        />
                                                        <path
                                                            d="M164.34,221.92c-24.88,0-49.77,0.22-74.65-0.07c-20.46-0.24-36.45-15.13-37.75-34.46c-1.33-19.87,14.9-37.88,36.2-38.81
                                  c19.19-0.83,38.44-0.41,57.67-0.47c17.87-0.05,35.74-0.07,53.61,0.01c19.43,0.08,36.09,7.27,50.36,20.21
                                  c6.75,6.12,10.36,13.55,8.87,22.97c-0.99,6.23-1.15,12.65-2.71,18.72c-2.31,8.97-6.35,11.81-15.6,11.85
                                  C215.01,221.98,189.68,221.91,164.34,221.92z"
                                                        />
                                                    </svg>
                                                </h3>
                                            </div>
                                            <div className="count_up text-center flex-5">
                                                <h3>
                                                    <span className="me-1">{wards.vacant}</span>
                                                    <svg
                                                        version="1.1"
                                                        width="28"
                                                        fill="#4e4851"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 594.73 346.55"
                                                        xmlSpace="preserve"
                                                    >
                                                        <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
                                                        <path
                                                            d="M594.73,324.14c-1.81,4.4-4.21,8.16-9.29,9.38c-8.31,2-15.15-3.34-15.32-12.61c-0.25-13.93-0.08-27.87-0.09-41.8
                                c0-2.07,0-4.14,0-6.56c-181.95,0-363.18,0-545.21,0c0,2.33,0,4.39,0,6.44c-0.01,18-0.02,36-0.01,54c0,5.51-2.1,9.87-7.19,12.24
                                c-4.35,2.03-9.02,1.91-12.43-1.56c-2.28-2.32-3.61-5.73-4.83-8.85c-0.66-1.69-0.22-3.82-0.22-5.76
                                C0.14,225.12,0.13,121.19,0.17,17.26c0-2.87,0.09-5.85,0.83-8.6c1.57-5.87,6.73-9.14,12.77-8.6c5.59,0.51,9.97,4.67,10.74,10.42
                                c0.31,2.29,0.3,4.63,0.3,6.95c0.02,26.9,0.01,53.8,0.01,80.71c0,2.11,0,4.22,0,6.91c1.72-0.56,2.9-1.06,4.13-1.33
                                c7.48-1.65,14.95-4.45,22.47-4.65c23.4-0.61,46.83-0.35,70.25-0.19c19.86,0.14,36.93,7.52,51.32,21.05
                                c13.13,12.35,14.8,27.78,11.45,44.44c-0.53,2.64-1.16,5.26-1.95,8.8c2.72,0,5.11,0,7.49,0c129.28,0,258.57,0.04,387.85-0.09
                                c7.72-0.01,13.53,2.16,16.89,9.38C594.73,229.68,594.73,276.91,594.73,324.14z M569.57,247.21c0-16.3,0-32.56,0-48.8
                                c-181.78,0-363.09,0-544.41,0c0,16.55,0,32.53,0,48.8C206.72,247.21,387.93,247.21,569.57,247.21z M98.88,173.15
                                c0,0.01,0,0.01,0,0.02c16.25,0,32.5,0.17,48.74-0.15c3.16-0.06,7.28-1.15,9.24-3.32c6.57-7.28,5.26-25.84-1.9-32.63
                                c-9.53-9.04-21.04-13.48-34.17-13.52c-22.63-0.06-45.26-0.08-67.89,0.05c-3.63,0.02-7.4,0.41-10.85,1.46
                                c-11.39,3.46-18.38,14.84-16.72,26.57c1.75,12.41,11.43,21.26,24.23,21.46C66,173.34,82.44,173.15,98.88,173.15z"
                                                        />
                                                    </svg>
                                                </h3>
                                            </div>
                                        </div> */}
                                        </button>
                                    );
                                })}
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                            {props.wardData !== '' &&
                                props.wardData.data.map((wards: any) => {
                                    return (
                                        <div
                                            className={`tab-pane fade show ${wards.active ? 'active' : ''}`}
                                            key={wards.wardId}
                                            id={`ward${wards.wardId}Tab`}
                                            role="tabpanel"
                                            aria-labelledby={`ward${wards.wardId}`}
                                        >
                                            {wards.active ? <FilterContainer wardsData={wards} handlerSearchValue={props.handlerSearchValue} sortparam={props.sortparam} ischange={props.ischange} /> : ''}
                                            {wards.active ? <BedPage childData={props.getPage} /> : ''}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default WardView;
function calc(arg0: number, arg1: number, px: any) {
    throw new Error('Function not implemented.');
}
