import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationsCountModel } from '../../utils/model';

const initialState: NotificationsCountModel = {
    StatusNotificationsCount: false,
    DataNotificationsCount: 0,
    MessageNotificationsCount: '',
    ErrorNotificationsCount: '',
};

export const notificationsCountSlice = createSlice({
    name: 'notificationsCount',
    initialState,
    reducers: {
        setNotificationsCountAction: (state, { payload }: PayloadAction) => {
            state.StatusNotificationsCount = true;
            state.DataNotificationsCount = payload;
            state.MessageNotificationsCount = 'Success';
        },
        errorNotificationsCountAction: (state, { payload }: PayloadAction) => {
            state.StatusNotificationsCount = false;
            state.DataNotificationsCount = 0;
            state.MessageNotificationsCount = 'Failed';
            state.ErrorNotificationsCount = payload;
        },
        removeNotificationsCountAction: (state, { payload }: PayloadAction) => {
            state.StatusNotificationsCount = false;
            state.DataNotificationsCount = 0;
            state.MessageNotificationsCount = '';
            state.ErrorNotificationsCount = '';
        }
    },
    extraReducers: {}
});

export const { setNotificationsCountAction, errorNotificationsCountAction, removeNotificationsCountAction } = notificationsCountSlice.actions;

export default notificationsCountSlice.reducer;
