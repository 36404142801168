import React from 'react';
import AmenitiesSliderView from './AmenitiesSliderView';

const AmenitiesSliderContainer = () => {
    return (
        <div>
            <AmenitiesSliderView   />
        </div>
    );
};

export default AmenitiesSliderContainer;