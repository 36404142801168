import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import instance from './axiosinstance';
import { store } from '../store';
import eventEmitter from '../utils/eventEmitter';
import { errorPatientDraftAction, setPatientDraftAction } from '../store/dashboard/draftPatientData';

export const GetPendingReferral = (payload: any) => {
    return instance        
        .post(`${BASEURLDEV}${API_ENDPOINT.GetPendingReferral}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getPendingReferralDetails = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPendingReferralDetails}?leadPoolingDetailsId=${payload.leadPoolingDetailsId}&serviceTypeId=${payload.serviceTypeId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            
            store.dispatch(setPatientDraftAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPatientDraftAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPatientDraftAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const saveLeadDraft = (payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveLeadDraft}`,payload)        
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getDigiContactDocument = (payload:any) => {
    const state = store.getState();    
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.DownloadDigiContractDocument}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;            
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;                
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);                
                return errorData;
            }
        });
};

export const deleteDigiContractFile = (payload:any) => {    
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .delete(`${BASEURLDEV}${API_ENDPOINT.DeleteDigiContractFile}?filePath=${payload.path}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};