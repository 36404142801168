import React from 'react';
import CostingView from './CostingView';

const CostingContainer = (props:any) => {
    return (
        <div>
            <CostingView refreshData={props.refreshData} referralid={props.referralid} finalStatus={props.finalStatus}/>
        </div>
    );
};

export default CostingContainer;