import React, { useEffect, useState } from "react";
import MaintenanceFormView from "./MaintenanceFormView";
import { useSelector, useDispatch } from "react-redux";
import { getMaintenanceListReasons, saveBedMaintenanceService } from "../../../../../../service/maintenance-service";
import { ReasonList } from "../../../../../../utils/constant";
import { RootState } from "../../../../../../store";
import swal from "sweetalert";
import warning from '../../../../../../assets/images/warning.png'
import eventEmitter from "../../../../../../utils/eventEmitter";
import { getPatientDetailsService } from "../../../../../../service/patient-service";

const MaintenanceFormContainer = (props: any) => {
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusListReasons, DataListReasons } = useSelector((state: RootState) => state.listMaintenanceReasons);
    const [listReasons, setListReasons] = useState<any>([])

    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
    }

    const onSubmitHandler = async(data: any) => {
        eventEmitter.emit('eventCloseVacantSideMenuOff');
        data.bedId = props.bedId
        if(data.bedId !== undefined && data.bedId !== '') {
            data.estMaintenanceReleaseDate = window.$utils.dateFormatDDMMYYYY(data.estMaintenanceReleaseDate)
            data.maintenanceStartDate = window.$utils.dateFormatDDMMYYYY(data.maintenanceStartDate)
            data.reasonId = Number(data.reasonId)
            swal({
                title: '',
                text: `Are you sure you want to assign Room ${props.bedName} for Maintenance?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    const response = await saveBedMaintenanceService(data)
                    if(response.status === true) {
                        swal(`Room ${props.bedName} is assigned for Maintenance.`, {
                            icon: 'success'
                        });
                        props.closeSideMenu(true)
                        eventEmitter.emit('eventGetBedInformation');
                        eventEmitter.emit('eventGetWardInformation');
                        eventEmitter.emit('onAdmitReservePatient');
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    }

    // const newEvent = new EventEmitter();
    useEffect(() => {
        async function getMaintListReasons() {
            const payload: any = { id: ReasonList.Maintenance};
            await getMaintenanceListReasons(payload);
        }
        getMaintListReasons();
    },[])

    useEffect(() => {
        if(StatusListReasons === true) {
            setListReasons(DataListReasons.data)
        }
    },[StatusListReasons, DataListReasons])

    useEffect(() => {
        async function getPatientDetails() {
            const payload: any = { patientId: Data.patientId};
            await getPatientDetailsService(payload);
        }
        getPatientDetails();
    },[Data])

    return <MaintenanceFormView listReasons={listReasons} onSubmitHandler={onSubmitHandler} updateData={updateData} />
}

export default MaintenanceFormContainer;