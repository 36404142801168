import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CityInformationModel } from '../../utils/model';

const initialState: CityInformationModel = {
    StatusCityInfo: false,
    DataCityInfo: '',
    MessageCityInfo: '',
    ErrorCityInfo: ''
};

export const cityInformationSlice = createSlice({
    name: 'cityInformation',
    initialState,
    reducers: {
        setCityInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusCityInfo = true;
            state.DataCityInfo = payload;
            state.MessageCityInfo = 'Success';
        },
        errorCityInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusCityInfo = false;
            state.DataCityInfo = [];
            state.MessageCityInfo = 'Failed';
            state.ErrorCityInfo = payload;
        },
       removeCityInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusCityInfo = false;
            state.DataCityInfo = '';
            state.MessageCityInfo = '';
            state.ErrorCityInfo = '';
        }
    },
    extraReducers: {}
});

export const { setCityInformationAction, errorCityInformationAction, removeCityInformationAction } = cityInformationSlice.actions;

export default cityInformationSlice.reducer;
