import React from 'react';
import ReferralDetailsMoreView from './ReferralDetailsMoreView';

const ReferralDetailsMoreContainer = (props: any) => {
    return (
        <div>
            <ReferralDetailsMoreView referralOverView={props.referralOverView} SelectedStatus={props.SelectedStatus} CurrentStatus={props.CurrentStatus} 
            Frequency={props.Frequency} SelectedFrequency={props.SelectedFrequency}/>
        </div>
    );
};

export default ReferralDetailsMoreContainer;