import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FunderInformationModel } from '../../utils/model';

const initialState: FunderInformationModel = {
    StatusFunderInfo: false,
    DataFunderInfo: '',
    MessageFunderInfo: '',
    ErrorFunderInfo: ''
};

export const funderInformationSlice = createSlice({
    name: 'funderInformation',
    initialState,
    reducers: {
        setFunderInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusFunderInfo = true;
            state.DataFunderInfo = payload;
            state.MessageFunderInfo = 'Success';
        },
        errorFunderInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusFunderInfo = false;
            state.DataFunderInfo = [];
            state.MessageFunderInfo = 'Failed';
            state.ErrorFunderInfo = payload;
        },
        removeFunderInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusFunderInfo = false;
            state.DataFunderInfo = '';
            state.MessageFunderInfo = '';
            state.ErrorFunderInfo = '';
        }
    },
    extraReducers: {}
});

export const { setFunderInformationAction, errorFunderInformationAction, removeFunderInformationAction } = funderInformationSlice.actions;

export default funderInformationSlice.reducer;
