import React, { useState, useEffect, useMemo } from 'react';
import DraftPatientListView from './draftPatientListView';
import PaginationContainer from '../../../../../../components/PaginationComponent/indexContainer';
import { getDraftListPatientService } from '../../../../../../service/patient-service';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { rightNavigationPage } from '../../../../../../utils/constant';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import { deleteDraftPatient, getPatientDraftData } from '../../../../../../service/dashboard-service';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { removePatientDraftAction } from '../../../../../../store/dashboard/draftPatientData';

let PageSize = 5;

const DraftPatientListContainer = (props: any) => {
    const { searchValue } = props;
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [listPatient, setLisPatient] = useState<any>();
    const dispatch = useDispatch();
    const { StatusListPatient, DataListPatient } = useSelector((state: RootState) => state.listPatient);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const getPatientList = async (data: any = '') => {
        let payload = {
            serviceId: DataServiceDetail.serviceId,
            Type: 3,
            SearchValue: data.SearchValue !== undefined ? data.SearchValue : '',
            FilterBy: data.FilterBy !== undefined ? data.FilterBy : '',
            SortBy: data.SortBy !== undefined ? data.SortBy : '',
            SortOrder: data.SortOrder !== undefined ? data.SortOrder : '',
            Page: data.page !== undefined ? data.page : '',
            PageSize: PageSize
        };
        await getDraftListPatientService(payload);
    };

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: page
            };
            getPatientList(data);
        } else {
            let data = {
                page: page
            };
            getPatientList(data);
        }
    };
    const eventGetDraftPatientList = async() => {
        setCurrentPage(1);
        let data = {
            page: '1'
        };
        getPatientList(data);
    }


    useEffect(() => {
        eventEmitter.on('eventGetDraftPatientList', eventGetDraftPatientList);
        return () => {
            eventEmitter.off('eventGetDraftPatientList', eventGetDraftPatientList)
        }
    });

    useEffect(() => {
        if (StatusListPatient === true) {
            setTotalCount(DataListPatient.totalCount);
            setLisPatient(DataListPatient.data);
        }
    }, [StatusListPatient, DataListPatient, totalCount]);
    useEffect(() => {
        async function getPatient() {
            let data = {
                page: currentPage
            };
            await getPatientList(data);
        }
        getPatient();
    }, []);
    useEffect(() => {
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: currentPage
            };
            getPatientList(data);
        } else {
            let data = {
                page: currentPage
            };
            getPatientList(data);
        }
    }, [searchValue]);

    async function getpatientdata(pid:any) {
        const payload: any = {id:pid, serviceId: DataServiceDetail.serviceId };
        await getPatientDraftData(payload);
    }

    const handleContinue = (data: any) => {
    
        swal({
            title: '',
            text: 'Are you sure you want to process this patient draft?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
        .then(async (confirm: any) => {
            if (confirm) {
                
                dispatch(removePatientDraftAction());
                getpatientdata(data.patientId).then((res:any)=>{
                    const data={
                        isDraftListAdd : true
                    }
                    let obj: any = {
                        ShowClass: true,
                        FormName: rightNavigationPage.Vacant,
                        Data: data,
                        ShowToggle:false
                    };
                    dispatch(setNavigationAction(obj));
                })
            }
        })
    };
  
    return (
        <div>
            {listPatient !== undefined &&
                listPatient !== null &&
                listPatient.length > 0 &&
                listPatient.map((data: any, index: any) => {
                    return <DraftPatientListView key={index}  dataPatient={data} accordID={index} handleContinue={handleContinue} />;
                })}
            {currentPage > 0 && totalCount > 0 && PageSize > 0 && (
                <PaginationContainer currentPage={currentPage} totalCount={totalCount}  pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
            )}
        </div>
    );
};

export default DraftPatientListContainer;
