import React from 'react';
//import removeImg from '../../assets/remove.svg'
interface FileTileProps {
    fileIndex: any;
    file: any;
    onRemove: any;
}

const FileTile: React.FC<FileTileProps> = ({ fileIndex, file, onRemove }) => {
    const { name, size, type, lastModified, percent } = file;
    return (
        <div>
            <label style={{ display: 'flex' }}>
                <span style={{ width: '100%' }}>{name} </span>
                <span>
                    <i className="fa-solid fa-check"></i>
                </span>
                {fileIndex !== 0 && (
                    <span onClick={() => onRemove(file)}>
                        <i className="fa-solid fa-close"></i>
                    </span>
                )}
            </label>
        </div>
    );
};

export default FileTile;
