import React, { useEffect, useState } from 'react';
import IPAView from './IPAView';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png'
import { confirmContract, saveContract } from '../../../../../../service/contract-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { getCityData, getFunderData } from '../../../../../../service/dashboard-service';
import { getLeaddata } from '../../../../../../service/leads';
import dayjs from 'dayjs';
const IPAContainer = (props:any) => {

    const [formval,setformval] = useState<any>();
    const [isdone,setdone] = useState<boolean>(false);
    const [cindex,setcindex] = useState<any>();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    
       useEffect(() => {
        async function getDropdowns() {
            await getCityData();
            await getFunderData(0);
        }
        getDropdowns();
    }, []);
   
    const getindex = (val:any) =>{
     setcindex(val);
     
    }

    



    const getformval = (val:any)=>{
        setformval(val);
       }
       async function priorsave(){        
        setdone(false);
        
        swal({
            title: '',
            text: DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0]?.digitalContractId !== 0 ? `Are you sure you want to update contract for lead ${props.referralId}?` : `Are you sure you want to save contract for lead ${props.referralId}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                console.log(formval);
                let savepayload = {
                    digitalContractId: DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId !== 0 ? DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId : 0,
                    admissionId: null,
                    contractNo: '',
                    contractTypeId: 1,
                    // formval?.contractCombinationId,
                    contractCombinationId: formval?.contractCombinationId,
                    leadPoolingId: DataLeadInfo?.data?.leadPoolingId,
                    dateOfCreation: formval?.newContractCreated !== null ? dayjs(dayjs(formval?.newContractCreated).toISOString()).format("DD-MM-YYYY") : null,
                    effectiveFrom: formval?.newContractEffectiveFrom !== null ? dayjs(dayjs(formval?.newContractEffectiveFrom).toISOString()).format("DD-MM-YYYY") : null,
                    effectiveTo: formval?.newContractEffectiveTo !== null ? dayjs(dayjs(formval?.newContractEffectiveTo).toISOString()).format("DD-MM-YYYY") : null,
                    serviceUserName: formval?.serviceUserDetailName,
                    dateOfBirth: formval?.serviceUserDetailDob !== null ? dayjs(dayjs(formval?.serviceUserDetailDob ).toISOString()).format("DD-MM-YYYY") : null,
                    acdid: null,
                    nhsid: formval?.serviceUserDetailNHSId,
                    localId: formval?.serviceUserDetailClientId,
                    address1: formval?.serviceUserDetailAdd1,
                    address2: formval?.serviceUserDetailAdd2,
                    city: formval?.serviceUserDetailCountry,
                    zipCode: formval?.serviceUserDetailPostalCode,
                    scheduledDateOfAdmission: formval?.contractDetailSchedAddmissionDate !== null ? dayjs(dayjs(formval?.contractDetailSchedAddmissionDate).toISOString()).format("DD-MM-YYYY") : null,
                    dischargeDate: formval?.contractDetailEstDischargeDate !== null ? dayjs(dayjs(formval?.contractDetailEstDischargeDate).toISOString()).format("DD-MM-YYYY") : null,
                    dailyCoreFee: formval?.contractDetailCoreFee,
                    hourlyFee1: formval?.contractDetailHourlyFee1,
                    hourlyFee2: formval?.contractDetailHourlyFee2,
                    hourlyFee3: formval?.contractDetailHourlyFee3,
                    hourlyFee4: formval?.contractDetailHourlyFee4,
                    fncFee: formval?.contractDetailFNCFee,
                    continenceCareFee: formval?.contractDetailContinenceFee,
                    feesWeeklyOrDaily: Number(formval?.contractDetailFee),
                    status: 1,
                    funderListViewModel : formval?.contractDetailArray?.map((data:any,i:number)=>{
                       let finalcontract = {
                            acdId: null,
                            funderId: DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[i]?.funderType === "Self" ? null : DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[i]?.funderId,
                            feeShare: data?.contractDetailFeeShare,
                            commissioner: 'test',
                            commissionerEmail: data?.commissionerEmail,
                            commAddress: '',
                            commCity: '',
                            commZipCode: '',
                            commPhoneNo: data?.commissionerPhone,
                            funderName: data?.funderName,
                            funderEmail: data?.funderEmail,
                            funderAddress: data?.funderAdd,
                            funderCity: data?.funderCountry,
                            funderZipCode: data?.funderPostalCode,
                            funderPhoneNo: data?.funderContactNo,
                            socialWorkerEmail: data?.socialWorkerEmailId,
                            socialWorkerPhoneNo: data?.socialWorkerContactNo,
                            invoicePoint: data?.funderCopyInvoicePoint,
                            invEmail: data?.funderCopyEmail,
                            invAddress: data?.funderCopyAdd,
                            invCity: data?.funderCopyCountry,
                            invZipCode: data?.funderCopyPostalCode,
                            isSameAsFunder: data?.sameAsFunder ? 1 : 0,
                            invPhoneNo: data?.funderCopyContact,
                            localityId: null,
                            leadFunderDetailsId:  DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[i].leadFunderDetailsId,
                            autoPayment: Number(data?.autoPayment),
                            invoiceSubmissionVia: Number(data?.invoiceSubmission),
                            NextOfKinPhoneNumber: data?.nextofkinContact,
                            NextOfKinEmail: data?.nextofkinEmail
                        }

                        return finalcontract;
                    })
                };
        
                  
                 const res = await  saveContract(savepayload);

                 if (res.status === true) {
                    swal(res.message, {
                        icon: 'success'
                    });
                    setdone(true);
                    getLeaddata(DataLeadInfo?.data?.leadId,6);
                } else {
                    swal(res.message, {
                        icon: 'error'
                    });
                    setdone(false);
                }

            } else {
            }
        });
         
       }
   
       useEffect(()=>{
        
        if(formval !== undefined){
            priorsave();
        }
       },[formval]);

       async function confirmcontract(){
        setdone(false);        
        // - ${formval?.fname??DataLeadInfo?.data?.contract[cindex].funderName}
        swal({
            title: '',
            text:`Are you sure you want to confirm and send contract for lead ${props.referralId} to funder?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const res = await confirmContract(DataLeadInfo?.data?.contract?.filter((el:any) => (el.status !== 'Voided' && el.status !== 'Cancelled'))[0].digitalContractId);
                if(res.status){
                    swal(res.message, {
                        icon: 'success'
                    });
                    setdone(true);
                    getLeaddata(DataLeadInfo?.data?.leadId,6);
                }
                else{
                    swal(res.message, {
                        icon: 'error'
                    });
                    setdone(false);
                }
            }
        })
     
       }

    return (
        <div>
            <IPAView getformval={getformval} isdone={isdone} cindex={getindex} send={confirmcontract}/>
        </div>
    );
};

export default IPAContainer;