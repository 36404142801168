import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Next from '../../../assets/images/next-white.svg';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import moment from 'moment';
import { AssessmentStatus } from '../../../utils/constant';

const WhatNextView = (props: any) => {
    const { assessmentDetails } = props;

    let [assessmentDetailsWhatNextView, setassessmentDetailsWhatNextView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    let [assessmentCompletedByDate, setassessmentCompletedByDate] = useState<any>(null);
    let [personDate, setpersonDate] = useState<any>(null);
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        awhnId: Yup.string().required(),
        informThePersonOfTheNextStages: Yup.string().required(),
        areThereAnySpecialistTrainingRequirements: Yup.string().required(),
        assessmentCompletedByName: Yup.string().required(),
        assessmentCompletedBySignature: Yup.string().required(),
        assessmentCompletedByPosition: Yup.string().when('assessmentCompletedByPositionNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        assessmentCompletedByPositionNa: Yup.boolean().required('Please tick checkbox.'),
        assessmentCompletedByDate: Yup.date().required(),
        personName: Yup.string().required(),
        personSignature: Yup.string().required(),
        personPosition: Yup.string().when('personPositionNa', {
            is: false,
            then: (schema: any) => schema.required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        personPositionNa: Yup.boolean().required('Please tick checkbox.'),
        personDate: Yup.date().required()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const watchAllFields = watch();

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsWhatNextView(props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData);

            setValue('awhnId', assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.awhnId ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.awhnId : '', {
                shouldValidate: true
            });
            setValue(
                'informThePersonOfTheNextStages',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.informThePersonOfTheNextStages
                    ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.informThePersonOfTheNextStages
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnySpecialistTrainingRequirements',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.areThereAnySpecialistTrainingRequirements
                    ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.areThereAnySpecialistTrainingRequirements
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'assessmentCompletedByName',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByName ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByName : '',
                { shouldValidate: true }
            );
            setValue(
                'assessmentCompletedBySignature',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedBySignature
                    ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedBySignature
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'assessmentCompletedByPosition',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByPosition
                    ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByPosition
                    : '',
                { shouldValidate: true }
            );
            setValue('assessmentCompletedByPositionNa', assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByPositionNa === 1 ? true : false, { shouldValidate: true });
            setValue(
                'assessmentCompletedByDate',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByDate
                    ? window.$utils.dateFormat(assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByDate)
                    : null,
                { shouldValidate: true }
            );
            let assessmentCompletedByDateTemp = moment(assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByDate, 'DD-MM-YYYY');
            setassessmentCompletedByDate(assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByDate ? dayjs(new Date(assessmentCompletedByDateTemp.toDate())) : '');

            setValue('personName', assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personName ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personName : '', {
                shouldValidate: true
            });
            setValue(
                'personSignature',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personSignature ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personSignature : '',
                { shouldValidate: true }
            );
            setValue(
                'personPosition',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personPosition ? assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personPosition : '',
                { shouldValidate: true }
            );
            setValue('personPositionNa', assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personPositionNa === 1 ? true : false, { shouldValidate: true });
            setValue(
                'personDate',
                assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personDate ? window.$utils.dateFormat(assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personDate) : null,
                { shouldValidate: true }
            );
            let personDateTemp = moment(assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personDate, 'DD-MM-YYYY');
            setpersonDate(assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personDate ? dayjs(new Date(personDateTemp.toDate())) : '');
        } else {
            setValue('awhnId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        props.whatNextFormValid(formState.isValid);
        props.whatNextDataChange(watchAllFields);
        // if (formState.isValid) {
        //     props.whatNextDataChange(watchAllFields)
        // }
    }, [formState]);
    return (
        <div id="what-next">
            <div className="modal-title-header mb-2 mt-3">
                <h3>
                    <img src={Next} alt="" width={15} className="me-2" />
                    {t('What_Happeens_Next.What_Happeens_Next_title')}
                </h3>
            </div>
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('What_Happeens_Next.Inform')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <>
                            <input
                                type="hidden"
                                {...register('awhnId', {
                                    // onChange: (e: any) => {
                                    //     props.updateData(e);
                                    // }
                                })}
                                name={'awhnId' as const}
                            />
                            <textarea
                                {...register('informThePersonOfTheNextStages', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'informThePersonOfTheNextStages' as const}
                                className={`form-control white ${errors.informThePersonOfTheNextStages ? 'is-invalid' : ''}`}
                                rows={2}
                            ></textarea>
                        </>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsWhatNextView.informThePersonOfTheNextStages}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('What_Happeens_Next.training_requirements')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('areThereAnySpecialistTrainingRequirements', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnySpecialistTrainingRequirements' as const}
                            className={`form-control white ${errors.areThereAnySpecialistTrainingRequirements ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsWhatNextView.areThereAnySpecialistTrainingRequirements}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="border-layout p-2 mt-3">
                        <p className="purple-text bold mb-3">{t('What_Happeens_Next.Assessment_completed_by')}</p>
                        <div className="d-flex mb-2">
                            <label className="flex-1">{t('What_Happeens_Next.Name')}</label>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <input
                                    type="text"
                                    {...register('assessmentCompletedByName', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'assessmentCompletedByName' as const}
                                    className={`form-control white flex-2 ${errors.assessmentCompletedByName ? 'is-invalid' : ''}`}
                                />
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 flex-2">{assessmentDetailsWhatNextView.assessmentCompletedByName}</p>}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                        <div className="d-flex mb-2">
                            <label className="flex-1">{t('What_Happeens_Next.Signature')}</label>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <input
                                    type="text"
                                    {...register('assessmentCompletedBySignature', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'assessmentCompletedBySignature' as const}
                                    className={`form-control white flex-2 ${errors.assessmentCompletedBySignature ? 'is-invalid' : ''}`}
                                />
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 flex-2">{assessmentDetailsWhatNextView.assessmentCompletedBySignature}</p>}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                        <div className="d-flex mb-2">
                            <div className="flex-1">
                                <label>{t('What_Happeens_Next.Position')} </label>
                                <div>
                                    {t('What_Happeens_Next.if_applicable')}
                                    <span>
                                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                            <label className=" ms-1">
                                                <span className="d-flex bold align-items-center justify-content-center">
                                                    <input
                                                        type="checkbox"
                                                        {...register(`assessmentCompletedByPositionNa`, {
                                                            onChange: (e: any) => {
                                                                // props.updateData(e);
                                                            }
                                                        })}
                                                        name={`assessmentCompletedByPositionNa` as const}
                                                        defaultChecked={true}
                                                        className="me-1"
                                                    />
                                                    NA
                                                </span>
                                            </label>
                                        )}
                                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                            <label className=" ms-1">
                                                <span className="d-flex bold align-items-center justify-content-center">
                                                    <input
                                                        disabled={true}
                                                        type="checkbox"
                                                        {...register(`assessmentCompletedByPositionNa`, {
                                                            onChange: (e: any) => {
                                                                // props.updateData(e);
                                                            }
                                                        })}
                                                        name={`assessmentCompletedByPositionNa` as const}
                                                        defaultChecked={true}
                                                        className="me-1"
                                                    />
                                                    NA
                                                </span>
                                            </label>
                                        )}
                                    </span>
                                </div>
                            </div>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <input
                                    type="text"
                                    disabled={(getValues("assessmentCompletedByPositionNa") == 1) ? true : false}
                                    {...register('assessmentCompletedByPosition', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'assessmentCompletedByPosition' as const}
                                    className={`form-control white flex-2 ${errors.assessmentCompletedByPosition && getValues("assessmentCompletedByPositionNa") == 0 ? 'is-invalid' : ''}`}
                                />
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 flex-2">{assessmentDetailsWhatNextView.assessmentCompletedByPosition}</p>}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                        <div className="d-flex mb-2">
                            <label className="flex-1">{t('What_Happeens_Next.Date')}</label>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <div className="date-custom-style input-group date datepicker-input-css flex-2" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="assessmentCompletedByDate"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    value={assessmentCompletedByDate}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // props.getDate(date);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{actionBar: { actions: ['clear','today'] },}}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                <p className="bold mb-2 flex-2">{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsWhatNextView.assessmentCompletedByDate))}</p>
                            )}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="border-layout p-2 mt-3">
                        <p className="purple-text bold mb-3">{t('What_Happeens_Next.Person_representative')}</p>
                        <div className="d-flex mb-2">
                            <label className="flex-1">{t('What_Happeens_Next.Name')}</label>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <input
                                    type="text"
                                    {...register('personName', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'personName' as const}
                                    className={`form-control white flex-2 ${errors.personName ? 'is-invalid' : ''}`}
                                />
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 flex-2">{assessmentDetailsWhatNextView.personName}</p>}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                        <div className="d-flex mb-2">
                            <label className="flex-1">{t('What_Happeens_Next.Signature')}</label>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <input
                                    type="text"
                                    {...register('personSignature', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'personSignature' as const}
                                    className={`form-control white flex-2 ${errors.personSignature ? 'is-invalid' : ''}`}
                                />
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 flex-2">{assessmentDetailsWhatNextView.personSignature}</p>}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                        <div className="d-flex mb-2">
                            <div className="flex-1">
                                <label>{t('What_Happeens_Next.Position')} </label>
                                <div>
                                    {t('What_Happeens_Next.if_applicable')}
                                    <span>
                                        {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                            <label className=" ms-1">
                                                <span className="d-flex bold align-items-center justify-content-center">
                                                    <input
                                                        type="checkbox"
                                                        {...register(`personPositionNa`, {
                                                            onChange: (e: any) => {
                                                                // props.updateData(e);
                                                            }
                                                        })}
                                                        name={`personPositionNa` as const}
                                                        defaultChecked={true}
                                                        className="me-1"
                                                    />
                                                    NA
                                                </span>
                                            </label>
                                        )}
                                        {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                            <label className=" ms-1">
                                                <span className="d-flex bold align-items-center justify-content-center">
                                                    <input
                                                        disabled={true}
                                                        type="checkbox"
                                                        {...register(`personPositionNa`, {
                                                            onChange: (e: any) => {
                                                                // props.updateData(e);
                                                            }
                                                        })}
                                                        name={`personPositionNa` as const}
                                                        defaultChecked={true}
                                                        className="me-1"
                                                    />
                                                    NA
                                                </span>
                                            </label>
                                        )}
                                    </span>
                                </div>
                            </div>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <input
                                    type="text"
                                    disabled={(getValues("personPositionNa") == 1) ? true : false}
                                    {...register('personPosition', {
                                        onChange: (e: any) => {
                                            // props.updateData(e);
                                        }
                                    })}
                                    name={'personPosition' as const}
                                    className={`form-control white flex-2 ${errors.personPosition && getValues("personPositionNa") == 0 ? 'is-invalid' : ''}`}
                                />
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 flex-2">{assessmentDetailsWhatNextView.personPosition}</p>}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                        <div className="d-flex mb-2">
                            <label className="flex-1">{t('What_Happeens_Next.Date')}</label>
                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                <div className="date-custom-style input-group date datepicker-input-css flex-2" id="datepicker">
                                    <Controller
                                        control={control}
                                        name="personDate"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <DatePicker
                                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                    value={personDate}
                                                    format="DD/MM/YYYY"
                                                    {...fieldProps}
                                                    onChange={(date: any) => {
                                                        fieldProps.onChange(date);
                                                        // props.getDate(date);
                                                    }}
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{actionBar: { actions: ['clear','today'] },}}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            )}
                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                <p className="bold mb-2 flex-2">{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetailsWhatNextView.personDate))}</p>
                            )}

                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatNextView;
