import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { rightNavigationPage } from '../../../../../utils/constant';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { Tooltip } from 'react-tooltip';
import { removePatientDraftAction } from '../../../../../store/dashboard/draftPatientData';
import eventEmitter from '../../../../../utils/eventEmitter';
import { Navigate, useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../../../../../navigation/RoutesConstants';
import { setPageNavigationAction } from '../../../../../store/general/pageNavigationReducer';
import { removeLeadInformationAction, setLeadInformationAction } from '../../../../../store/lead/getLeaddataReducer';
import { getContractDataAdmission } from '../../../../../service/contract-service';


const BedDetailsView = (props: any) => {
    const { t } = useTranslation();
    const { StatusWardBedInfo, DataWardBedInfo, MessageWardBedInfo, ErrorWardBedInfo } = useSelector((state: RootState) => state.wardBedInformation);

    const dispatch = useDispatch();
    let [dataBedInfo, setDataBedInfo] = useState<any>('');
    let [pendingActivities, setPendingActivities] = useState<any>('');
    const { StatusBedInfo, DataBedInfo } = useSelector((state: RootState) => state.bedInformation);
    const { StatusPendingActivities, DataPendingActivities } = useSelector((state: RootState) => state.pendingActivities);

    const navigate = useNavigate();
    const handleItemClick = (item : any) => {
       if(item === 'Billing'){
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: {
                    isDraft: true
                },
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
           navigate(ROUTES_DATA.BILLINGMANAGEMENT)
       }
    };

    const handlerOpenRightBar = async (pageName: any) => {
        dispatch(removePatientDraftAction());
        if (pageName === rightNavigationPage.Admission) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.Admission,
                Data: '',
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        } else if (pageName === rightNavigationPage.Draft) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.Draft,
                Data: '',
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        }
        else if (pageName === rightNavigationPage.Task) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.Task,
                Data: '',
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        } else if (pageName === rightNavigationPage.MaintenanceList) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.MaintenanceList,
                Data: '',
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        } else if (pageName === rightNavigationPage.Vacant) {
            const data={
                isDraftAdd : false,
            }
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.Vacant,
                Data: data,
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        }
        else if (pageName === rightNavigationPage.ReferralList) {
            const data={
                isDraftAdd : false,
            }
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.ReferralList,
                Data: data,
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        }
    };



    useEffect(() => {
        if (StatusBedInfo === true) {
            setDataBedInfo(DataBedInfo);

        }
        if (StatusPendingActivities === true) {
            setPendingActivities(DataPendingActivities);
        }
    }, [StatusBedInfo, StatusPendingActivities, DataBedInfo, DataPendingActivities, dataBedInfo, pendingActivities]);


    return (
        // <div className="d-flex collapsible-panel">
        //     <div className="collapsible-panel-left">
        //         <h3 className="d-block purple-text">{t('BedDetails.bed_information')}</h3>
        //         <br />
        //         <div className="d-flex flex-xl-row flex-lg-row flex-sm-column">
        //             <div className="me-3 column-count-custom">
        //                 <div className="tabular-data">
        //                     <p>{t('BedDetails.registered_beds')}:</p>
        //                     <label className="bolder gray-text">{dataBedInfo !== '' ? dataBedInfo.data.registeredBeds : '0'}</label>
        //                 </div>
        //                 <div className="tabular-data">
        //                     <p>{t('BedDetails.operational_beds')}:</p>
        //                     <label className="bolder gray-text">{dataBedInfo !== '' ? dataBedInfo.data.operationalBeds : '0'}</label>
        //                 </div>
        //                 <div className="tabular-data">
        //                     <p>{t('BedDetails.occupied_beds')}:</p>
        //                     <label className="bolder gray-text">{dataBedInfo !== '' ? dataBedInfo.data.occupiedBeds : '0'}</label>
        //                 </div>
        //                 <div className="tabular-data">
        //                     <p>{t('BedDetails.reserved_beds')}: </p>
        //                     <label className="bolder gray-text">{dataBedInfo !== '' ? dataBedInfo.data.reservedBeds : '0'}</label>
        //                 </div>
        //                 {/* <div className="tabular-data">
        //                     <p>{t('BedDetails.Referrals_Pending')}:</p>
        //                     <label className="bolder gray-text">{dataBedInfo !== '' ? dataBedInfo.data.referralsPending : '0'}</label>
        //                 </div> */}
        //             </div>
        //             <div className="">
        //                 <div className="info-group info-group-left" id="info-group">
        //                     <div className="count_up text-center" data-tooltip-id="my-tooltip" data-tooltip-content={dataBedInfo !== '' ? `${dataBedInfo?.data?.bedStatus?.admitted} Admitted` : '0 Admitted'}>
        //                         <h3 className="mb-0">
        //                             <svg
        //                                 fill="#4e4851"
        //                                 version="1.1"
        //                                 id="Layer_1"
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 xmlnsXlink="http://www.w3.org/1999/xlink"
        //                                 x="0px"
        //                                 y="0px"
        //                                 width="28"
        //                                 viewBox="0 0 391.23 264.5"
        //                                 xmlSpace="preserve"
        //                             >
        //                                 <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
        //                                 <g>
        //                                     <path
        //                                         d="M0,0c8.36,0,16.72,0,25.5,0c0,45.85,0,90.89,0,136.39c122.14,0,243.86,0,365.73,0c0,42.7,0,84.94,0,127.41
        //                                   c-8.38,0-16.58,0-25.28,0c0-1.65,0-3.13,0-4.61c0-19.75-0.07-39.49,0.07-59.24c0.02-3.19-0.9-4.05-4.06-4.05
        //                                   c-110.83,0.08-221.66,0.08-332.49,0c-3.14,0-4.11,0.83-4.08,4.03c0.14,19.74,0.07,39.49,0.07,59.24c0,1.51,0,3.02,0,5.33
        //                                   c-8.73,0-17.09,0-25.46,0C0,176.34,0,88.17,0,0z"
        //                                     />
        //                                     <path
        //                                         d="M374.78,127.68c-85.6,0-170.37,0-255.63,0c0-2.1,0-4.09,0-6.09c0.02-11.46-0.12-22.93,0.09-34.39
        //                                   c0.34-18.08,9.85-27.34,28.02-27.38c29.17-0.06,58.34-0.02,87.51-0.02c37.07,0,74.14-0.01,111.21,0.01
        //                                   c7.96,0,15.86,1.06,21.14,7.66c2.9,3.63,5.83,8.34,6.14,12.75C374.38,95.91,374.36,111.68,374.78,127.68z"
        //                                     />
        //                                     <path
        //                                         d="M68.05,129.47c-19.47,0.01-35.31-15.71-35.62-35.36c-0.31-19.28,16.14-35.84,35.6-35.85
        //                                   c19.44-0.01,35.94,16.58,35.65,35.84C103.39,113.71,87.52,129.46,68.05,129.47z"
        //                                     />
        //                                 </g>
        //                             </svg>
        //                             {/* <p className="count">{dataBedInfo !== '' ? dataBedInfo.data.bedStatus.admitted : '0'}</p> */}
        //                             <p className="count">
        //                                 {dataBedInfo === '' ? "0" : dataBedInfo?.data?.bedStatus?.admitted > 99 ? "99+" : dataBedInfo?.data?.bedStatus?.admitted }
        //                             </p>
        //                         </h3>
        //                     </div>
        //                     <div className="count_up text-center" data-tooltip-id="my-tooltip" data-tooltip-content={dataBedInfo !== '' ? `${dataBedInfo?.data?.bedStatus?.reserved} Reserved` : '0 Reserved'}>
        //                         <h3 className="mb-0">
        //                             <svg
        //                                 version="1.1"
        //                                 id="Layer_1"
        //                                 fill="#4e4851"
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 xmlnsXlink="http://www.w3.org/1999/xlink"
        //                                 x="0px"
        //                                 y="0px"
        //                                 width="28"
        //                                 viewBox="0 0 695.45 473.64"
        //                                 xmlSpace="preserve"
        //                             >
        //                                 <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
        //                                 <path
        //                                     d="M0,11.3C3.75,2.75,10.4-0.11,19.53,0C41.4,0.28,44.52,3.16,44.52,25.27c0,67.46,0,134.91,0,202.37c0,2.71,0,5.42,0,9.18
        //                               c3.14,0,5.97,0,8.8,0c206.86,0,413.72,0,620.59,0c17.23,0,21.54,4.36,21.54,21.77c0,64.97,0,129.93,0,194.9
        //                               c0,15.13-4.81,19.97-19.83,20.02c-21.56,0.07-24.68-3.06-24.68-24.77c0-28.52,0-57.04,0-85.57c0-2.43,0-4.87,0-7.66
        //                               c-202.29,0-403.83,0-606.07,0c-0.12,2.49-0.33,4.89-0.33,7.28c-0.02,30.11,0,60.21-0.02,90.32c-0.01,15.28-5.07,20.35-20.22,20.39
        //                               c-1.58,0-3.18-0.12-4.75,0.02C10.4,474.34,3.75,470.88,0,462.33C0,311.99,0,161.64,0,11.3z"
        //                                 />
        //                                 <path
        //                                     d="M164.34,221.92c-24.88,0-49.77,0.22-74.65-0.07c-20.46-0.24-36.45-15.13-37.75-34.46c-1.33-19.87,14.9-37.88,36.2-38.81
        //                               c19.19-0.83,38.44-0.41,57.67-0.47c17.87-0.05,35.74-0.07,53.61,0.01c19.43,0.08,36.09,7.27,50.36,20.21
        //                               c6.75,6.12,10.36,13.55,8.87,22.97c-0.99,6.23-1.15,12.65-2.71,18.72c-2.31,8.97-6.35,11.81-15.6,11.85
        //                               C215.01,221.98,189.68,221.91,164.34,221.92z"
        //                                 />
        //                             </svg>
        //                             {/* <p className="count">{dataBedInfo !== '' ? dataBedInfo.data.bedStatus.reserved : '0'}</p> */}
        //                             <p  className="count">
        //                             {dataBedInfo === '' ? "0" : dataBedInfo?.data?.bedStatus?.reserved > 99 ? "99+" : dataBedInfo?.data?.bedStatus?.reserved }
        //                             </p>
        //                         </h3>
        //                     </div>
        //                     <div className="count_up text-center" data-tooltip-id="my-tooltip" data-tooltip-content={dataBedInfo !== '' ? `${dataBedInfo?.data?.bedStatus?.vacant} Vacant` : '0 Vacant'}>
        //                         <h3 className="mb-0">
        //                             <svg
        //                                 version="1.1"
        //                                 fill="#4e4851"
        //                                 id="Layer_1"
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 xmlnsXlink="http://www.w3.org/1999/xlink"
        //                                 x="0px"
        //                                 y="0px"
        //                                 width="28"
        //                                 viewBox="0 0 594.73 346.55"
        //                                 xmlSpace="preserve"
        //                             >
        //                                 <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
        //                                 <path
        //                                     d="M594.73,324.14c-1.81,4.4-4.21,8.16-9.29,9.38c-8.31,2-15.15-3.34-15.32-12.61c-0.25-13.93-0.08-27.87-0.09-41.8
        //                               c0-2.07,0-4.14,0-6.56c-181.95,0-363.18,0-545.21,0c0,2.33,0,4.39,0,6.44c-0.01,18-0.02,36-0.01,54c0,5.51-2.1,9.87-7.19,12.24
        //                               c-4.35,2.03-9.02,1.91-12.43-1.56c-2.28-2.32-3.61-5.73-4.83-8.85c-0.66-1.69-0.22-3.82-0.22-5.76
        //                               C0.14,225.12,0.13,121.19,0.17,17.26c0-2.87,0.09-5.85,0.83-8.6c1.57-5.87,6.73-9.14,12.77-8.6c5.59,0.51,9.97,4.67,10.74,10.42
        //                               c0.31,2.29,0.3,4.63,0.3,6.95c0.02,26.9,0.01,53.8,0.01,80.71c0,2.11,0,4.22,0,6.91c1.72-0.56,2.9-1.06,4.13-1.33
        //                               c7.48-1.65,14.95-4.45,22.47-4.65c23.4-0.61,46.83-0.35,70.25-0.19c19.86,0.14,36.93,7.52,51.32,21.05
        //                               c13.13,12.35,14.8,27.78,11.45,44.44c-0.53,2.64-1.16,5.26-1.95,8.8c2.72,0,5.11,0,7.49,0c129.28,0,258.57,0.04,387.85-0.09
        //                               c7.72-0.01,13.53,2.16,16.89,9.38C594.73,229.68,594.73,276.91,594.73,324.14z M569.57,247.21c0-16.3,0-32.56,0-48.8
        //                               c-181.78,0-363.09,0-544.41,0c0,16.55,0,32.53,0,48.8C206.72,247.21,387.93,247.21,569.57,247.21z M98.88,173.15
        //                               c0,0.01,0,0.01,0,0.02c16.25,0,32.5,0.17,48.74-0.15c3.16-0.06,7.28-1.15,9.24-3.32c6.57-7.28,5.26-25.84-1.9-32.63
        //                               c-9.53-9.04-21.04-13.48-34.17-13.52c-22.63-0.06-45.26-0.08-67.89,0.05c-3.63,0.02-7.4,0.41-10.85,1.46
        //                               c-11.39,3.46-18.38,14.84-16.72,26.57c1.75,12.41,11.43,21.26,24.23,21.46C66,173.34,82.44,173.15,98.88,173.15z"
        //                                 />
        //                             </svg>
        //                             <p className="count">
        //                                 {/* {dataBedInfo !== '' ? dataBedInfo.data.bedStatus.vacant : '0'} */}
        //                                 {dataBedInfo === '' ? "0" : dataBedInfo?.data?.bedStatus?.vacant > 99 ? "99+" : dataBedInfo?.data?.bedStatus?.vacant }
        //                             </p>
        //                         </h3>
        //                     </div>

        //                     <div className="count_up text-center" onClick={() => handlerOpenRightBar(rightNavigationPage.MaintenanceList)} data-tooltip-id="my-tooltip" data-tooltip-content={dataBedInfo !== '' ? `${dataBedInfo?.data?.bedStatus?.maintenance} Maintenance` : '0 Maintenance'}>
        //                         <h3 className="mb-0">
        //                             <i className="fa-solid fa-screwdriver-wrench"></i>
        //                             <p className="count">
        //                                 {/* {dataBedInfo !== '' ? dataBedInfo.data.bedStatus.maintenance : '0'} */}
        //                                 {dataBedInfo === '' ? "0" : dataBedInfo?.data?.bedStatus?.maintenance > 99 ? "99+" : dataBedInfo?.data?.bedStatus?.maintenance }
        //                             </p>
        //                         </h3>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="collapsible-panel-right">
        //         <h3 className="d-block purple-text">{t('BedDetails.Pending_Activities')}</h3>
        //         <br />
        //         <div className="d-flex info-group">
        //             <div className="count_up" onClick={() => handlerOpenRightBar(rightNavigationPage.Admission)} data-tooltip-id="my-tooltip" data-tooltip-content={pendingActivities !== '' ? `${pendingActivities?.data?.admissions} Admissions` : '0 Admissions'}>
        //                 <h3 className="d-flex justify-content-between mb-0">
        //                     <svg
        //                         fill="#4e4851"
        //                         className="admission-icon"
        //                         version="1.1"
        //                         id="Layer_1"
        //                         xmlns="http://www.w3.org/2000/svg"
        //                         xmlnsXlink="http://www.w3.org/1999/xlink"
        //                         x="0px"
        //                         y="0px"
        //                         width="11"
        //                         viewBox="0 0 157.11 383.31"
        //                         xmlSpace="preserve"
        //                     >
        //                         <style>{`"enable-background:new 0 0 594.73 346.55;"`}</style>
        //                         <g>
        //                             <path
        //                                 d="M98.78,383.31c-0.3-0.2-0.57-0.49-0.91-0.6c-10.89-3.49-16.05-10.43-16.06-21.94c-0.03-39.8-0.01-79.59-0.02-119.39
        //                           c0-1.37,0.31-2.93-0.24-4.06c-0.55-1.13-1.99-2.52-3.03-2.51c-1.04,0.01-2.46,1.42-3,2.55c-0.54,1.13-0.24,2.69-0.24,4.06
        //                           c-0.01,39.55,0,79.09-0.01,118.64c-0.01,11.05-5.38,18.95-14.74,21.81c-12.93,3.96-26.16-5.31-26.85-18.81
        //                           c-0.07-1.37-0.03-2.74-0.03-4.12c0-70.36,0.01-140.72-0.03-211.08c0-2.69,1.07-6.62-3.19-6.7c-4.23-0.07-3.4,3.81-3.41,6.55
        //                           c-0.03,29.07,0.02,58.13-0.05,87.2c-0.02,9.48-7.83,15.72-16.73,13.61c-6.11-1.45-10.18-6.9-10.22-13.93
        //                           c-0.06-12.1-0.02-24.2-0.02-36.3c0-23.95-0.01-47.9,0-71.86c0.02-21.5,14.14-35.71,35.57-35.73c28.7-0.03,57.4-0.03,86.09,0
        //                           c21.2,0.02,35.4,14.27,35.41,35.51c0.02,35.93,0.02,71.86,0,107.78c-0.01,9.81-7.06,16.24-15.95,14.67
        //                           c-6.31-1.11-10.85-6.25-10.98-12.79c-0.14-7.48-0.05-14.97-0.05-22.45c0-21.96,0-43.91,0-65.87c0-1.12,0.37-2.51-0.15-3.31
        //                           c-0.83-1.28-2.16-2.77-3.48-3c-0.8-0.14-2.28,1.8-2.89,3.07c-0.49,1.02-0.12,2.45-0.12,3.7c0,69.86,0,139.72,0,209.58
        //                           c0,15.16-3.42,20.27-17.2,25.7C103.77,383.31,101.27,383.31,98.78,383.31z"
        //                             />
        //                             <path
        //                                 d="M78.54,75.66c-20.9,0-37.88-16.95-37.88-37.81C40.66,17.04,57.71,0,78.55,0c20.85,0,37.86,17,37.88,37.85
        //                           C116.45,58.68,99.43,75.67,78.54,75.66z"
        //                             />
        //                         </g>
        //                     </svg>
        //                     <p className="count">
        //                         {/* {pendingActivities !== '' ? pendingActivities.data.admissions : '0'} */}
        //                         {pendingActivities === '' ? "0" : pendingActivities?.data?.admissions > 99 ? "99+" : pendingActivities?.data?.admissions}
        //                     </p>
        //                 </h3>
        //             </div>
        //             <div className="count_up" data-tooltip-id="my-tooltip" data-tooltip-content={pendingActivities !== '' ? `${pendingActivities?.data?.draftPatient} Draft Patient` : '0 Draft Patient'}  onClick={() => handlerOpenRightBar(rightNavigationPage.Draft)}>
        //                 <h3 className="d-flex justify-content-between mb-0">
        //                     <i className="fa-solid fa-user-tag"></i>
        //                     <p className="count">
        //                         {/* {pendingActivities !== '' ? pendingActivities.data.draftPatient : '0'} */}
        //                         {pendingActivities === '' ? "0" : pendingActivities?.data?.draftPatient > 99 ? "99+" : pendingActivities?.data?.draftPatient}
        //                     </p>
        //                 </h3>
        //             </div>
        //             <div className="count_up" onClick={() => handleItemClick('Billing')} data-tooltip-id="my-tooltip" data-tooltip-content={pendingActivities !== '' ? `${pendingActivities?.data?.invoicing} Invoices` : '0 Invoices'}>
        //                 <h3 className="d-flex justify-content-between mb-0">
        //                 <svg version="1.1" id="Layer_1"
        //                          xmlns="http://www.w3.org/2000/svg"
        //                          xmlnsXlink="http://www.w3.org/1999/xlink"
        //                          x="0px" y="0px"
        //                          width="15"
        //                         viewBox="0 0 309.72 403.57"
        //                         xmlSpace="preserve">
        //                          <style>{`"enable-background:new 0 0 309.72 403.57;"`} </style>
        //                     <g>
        //                         <path d="M309.72,403.57c-103.18,0-206.37,0-309.55,0c-0.04-0.66-0.12-1.31-0.12-1.97c0-130.27,0-260.53,0.02-390.8
        //                             c0-1.32-0.4-2.8,0.64-4.14c12.12,8.22,24.08,16.33,36.05,24.42c5.92,4,8.69,3.98,14.72,0.01C66.63,21.1,81.8,11.16,96.88,1.08
        //                             c2.07-1.38,3.46-1.47,5.55-0.07c14.94,10.05,29.98,19.95,44.96,29.94c5.78,3.86,8.75,3.99,14.42,0.24
        //                             c14.9-9.87,29.86-19.65,44.6-29.78c3.01-2.07,4.91-1.66,7.58,0.14c14.93,10.06,29.98,19.94,44.92,29.99
        //                             c4.76,3.2,9.15,3.09,13.8-0.2c6.33-4.48,12.8-8.75,19.22-13.1c5.72-3.88,11.44-7.74,17.74-12c0,2.02,0,3.28,0,4.55
        //                             c0,44.87-0.02,89.74-0.01,134.61C309.67,231.46,309.7,317.51,309.72,403.57z M154.74,73.2C91.52,73.12,37.33,123.52,37.5,190.82
        //                             c0.16,64.82,51.67,116.77,117.08,116.89c67.06,0.12,117.52-54.39,117.45-117.44C271.97,125.47,219.76,73.31,154.74,73.2z
        //                             M154.54,332.91c0-0.02,0-0.03,0-0.05c-25.39,0-50.78-0.01-76.18,0.01c-2.23,0-4.49,0.03-6.69,0.36c-5.33,0.79-8.9,6.02-7.82,11.22
        //                             c1.23,5.94,4.89,8.54,12.21,8.55c52.49,0.02,104.99,0.02,157.48,0.03c1.85,0,3.66-0.09,5.45-0.69c4.59-1.54,7.27-5.67,6.84-10.69
        //                             c-0.39-4.53-3.65-7.88-8.42-8.46c-2.08-0.25-4.2-0.28-6.3-0.28C205.59,332.9,180.07,332.91,154.54,332.91z"/>
        //                         <path d="M154.67,287.63c-53.42,0.53-97.3-44.14-97.17-97.52c0.14-54.11,45.35-98.74,100.54-96.95
        //                             c51.98,1.69,94.74,45.03,94.05,98.7C251.39,245.69,206.68,288.29,154.67,287.63z M145.96,231.23c3.28-8.26,4.14-16.3,4.19-24.45
        //                             c0.01-2.16,1.09-2.24,2.71-2.22c5,0.06,10,0.11,15,0c5.94-0.13,10.48-4.59,10.49-10.1c0.01-5.41-4.27-9.58-10.29-9.75
        //                             c-5.39-0.15-10.8-0.18-16.18,0.03c-2.74,0.11-3.67-0.99-4.04-3.51c-0.48-3.22-1.4-6.37-2.03-9.57c-1.01-5.16-1.93-10.43-0.76-15.61
        //                             c2.51-11.14,14.11-16.51,25.93-12.49c5.71,1.95,7.95,6.29,9.39,11.63c1.58,5.87,6.93,8.88,12.44,7.4
        //                             c5.15-1.38,8.04-6.82,6.69-12.48c-3.74-15.64-13.55-24.41-29.42-27.18c-25.89-4.52-47.77,15.45-45.42,41.61
        //                             c0.53,5.91,1.65,11.66,2.96,17.39c0.52,2.28-0.03,2.8-2.16,2.76c-4.6-0.08-9.21-0.1-13.82,0c-6.15,0.13-10.52,4.41-10.4,9.99
        //                             c0.13,5.56,4.66,9.79,10.68,9.86c4.87,0.06,9.75,0.18,14.6-0.05c2.91-0.13,3.85,0.66,3.75,3.71c-0.33,9.3-2.44,17.87-8.54,25.21
        //                             c-1.57,1.89-3.15,3.84-4.09,6.23c-2.74,6.96,2.66,14.47,10.08,13.8c3.13-0.28,6.24-0.79,9.36-1.26c9.06-1.36,17.72-1.45,26.45,2.82
        //                             c12.67,6.19,25.78,4.52,38.56-0.45c4.18-1.63,6.27-5.23,6.08-9.66c-0.19-4.38-2.7-7.43-7.01-8.99c-4.02-1.46-7.47,0.56-11.06,1.54
        //                             c-6.54,1.79-12.98,2.08-19.2-0.94C163.21,232.77,155.19,230.68,145.96,231.23z"/>
        //                     </g>
        //                     </svg>
        //                     <p className="count">
        //                         {/* {pendingActivities !== '' ? pendingActivities?.data?.invoicing : '0'} */}
        //                         {pendingActivities === '' ? "0" : pendingActivities?.data?.invoicing > 99 ? "99+" : pendingActivities?.data?.invoicing}
        //                     </p>
        //                 </h3>
        //             </div>
        //             <div className="count_up text-center" data-tooltip-id="my-tooltip" data-tooltip-content={pendingActivities !== '' ? `${pendingActivities?.data?.task} Task` : '0 Task'} onClick={() => handlerOpenRightBar(rightNavigationPage.Task)}>
        //             <svg
        //              fill="#4e4851"
        //              className='task-icon'
        //              version="1.1"
        //              id="Layer_1"
        //              width="14"
        //              xmlns="http://www.w3.org/2000/svg"
        //              xmlnsXlink="http://www.w3.org/1999/xlink"
        //                 viewBox="0 0 292.62 384"
        //                 xmlSpace="preserve">

        //             <g>
        //                 <style>{`"enable-background:new 0 0 292.62 384;"`}</style>
        //                 <path d="M258.87,384c-75.26,0-150.52,0-225.77,0c-1.97-0.27-3.93-0.53-5.9-0.8c-6.88-1.15-12.6-4.49-17.45-9.38
        //                     c-8.62-8.68-10.46-19.46-9.53-31.07c2.12-2,2.11-4,0-6c-0.04-8.99-0.11-17.98-0.1-26.98C0.2,226.06,0.31,142.35,0.36,58.64
        //                     c0.01-15.48,6.49-26.91,20.72-33.44c8.98-4.12,18.54-2.4,28.01-2.66c0,5.08-0.08,9.7,0.01,14.32
        //                     c0.37,17.27,12.87,30.52,29.45,30.58c45.36,0.15,90.72,0.14,136.08,0c14.04-0.04,26.15-10.13,28.5-24.02
        //                     c1.11-6.58,0.64-13.43,0.89-20.15c0.2-0.21,0.4-0.42,0.6-0.63c5.49-0.02,11.01-0.36,16.47,0.05c3.67,0.27,7.5,1.06,10.83,2.56
        //                     c13.93,6.29,20.7,17.37,20.69,32.6c-0.09,96.17-0.23,192.35-0.22,288.52c0,9.86-2.17,18.74-8.55,26.4
        //                     c-0.87,0.89-1.75,1.79-2.62,2.68c-0.71,0.59-1.42,1.19-2.13,1.78c-0.5,0.34-0.99,0.68-1.49,1.02c-1.04,0.66-2.07,1.32-3.11,1.97
        //                     c-0.68,0.31-1.35,0.63-2.03,0.94c-2.52,0.73-5.04,1.47-7.56,2.2c-0.72,0.01-1.45,0.02-2.17,0.03
        //                     C261.46,383.59,260.17,383.79,258.87,384z M190.62,285c-13.12,0-26.25-0.02-39.37,0.01c-7.21,0.02-12.34,4.74-12.33,11.26
        //                     c0.01,6.49,5.16,11.22,12.37,11.23c26,0.02,52,0.02,77.99,0c7.25-0.01,12.32-4.7,12.31-11.26c-0.01-6.54-5.1-11.21-12.35-11.22
        //                     C216.37,284.98,203.49,285,190.62,285z M189.83,157.5c12.98,0,25.96,0.02,38.94-0.01c7.29-0.01,12.35-4.54,12.46-11.08
        //                     c0.11-6.65-5.05-11.4-12.5-11.41c-25.71-0.02-51.43-0.01-77.14,0c-7.49,0-12.69,4.65-12.68,11.26c0.01,6.61,5.2,11.22,12.72,11.23
        //                     C164.37,157.51,177.1,157.5,189.83,157.5z M189.98,232.5c12.98,0,25.96,0.02,38.94-0.01c7.22-0.02,12.3-4.69,12.31-11.23
        //                     c0.01-6.53-5.08-11.25-12.28-11.25c-25.84-0.02-51.68-0.02-77.52,0c-7.43,0-12.64,4.79-12.52,11.41
        //                     c0.12,6.49,5.24,11.06,12.49,11.07C164.27,232.52,177.13,232.5,189.98,232.5z M76.27,222.38c-2.15-2.24-4.03-4.41-6.14-6.34
        //                     c-4.58-4.2-11.3-4.14-15.59,0.02c-4.43,4.29-4.65,11.15-0.23,15.83c4.55,4.81,9.25,9.49,14.04,14.06
        //                     c5.04,4.79,11.29,4.65,16.38-0.18c2.36-2.24,4.61-4.58,6.9-6.88c8.74-8.76,17.5-17.5,26.22-26.27c3.48-3.5,4.67-7.68,2.87-12.35
        //                     c-1.61-4.18-4.82-6.6-9.33-7.08c-3.88-0.41-7.01,1.09-9.73,3.82C93.32,205.41,84.91,213.76,76.27,222.38z M76.1,297.55
        //                     c-2.13-2.33-3.98-4.69-6.17-6.68c-4.55-4.13-11.4-3.87-15.57,0.37c-4.14,4.21-4.49,10.8-0.36,15.26
        //                     c4.84,5.22,9.89,10.27,15.12,15.09c4.27,3.94,10.5,4.01,14.65-0.03c11.81-11.51,23.46-23.2,34.99-35c3.1-3.17,3.63-7.36,1.88-11.53
        //                     c-1.74-4.15-5-6.48-9.49-6.87c-3.88-0.34-6.96,1.3-9.66,4.03C93.24,280.52,84.9,288.77,76.1,297.55z M77.12,142.47
        //                     c-2.96-3.05-4.78-5.11-6.79-6.98c-4.8-4.45-11.42-4.44-15.83-0.08c-4.51,4.46-4.53,11.28,0.12,16.11
        //                     c4.42,4.59,8.93,9.1,13.52,13.52c5.19,5,11.48,5.03,16.63-0.05c11.11-10.98,22.15-22.04,33.13-33.15
        //                     c4.78-4.83,4.85-11.59,0.44-16.11c-4.44-4.55-11.52-4.47-16.4,0.35c-7.65,7.55-15.23,15.17-22.77,22.83
        //                     C78.3,139.76,77.89,141.08,77.12,142.47z"/>
        //                 <path d="M216.87,0c4.1,2.83,4.78,6.86,4.64,11.55c-0.26,8.24-0.05,16.5-0.08,24.75c-0.03,5.94-2.78,8.7-8.74,8.7
        //                     c-44.13,0.02-88.25,0.02-132.38,0c-5.69,0-8.64-2.81-8.7-8.45c-0.1-9.37,0.04-18.75-0.06-28.12C71.5,4.47,73.62,2.02,76.6,0
        //                     C123.36,0,170.11,0,216.87,0z"/>
        //                 <path  d="M258.87,384c1.3-0.21,2.59-0.41,3.89-0.62c-0.05,0.21-0.1,0.41-0.14,0.62C261.37,384,260.12,384,258.87,384z"
        //                     />
        //                 <path  d="M262.62,384c0.04-0.21,0.08-0.42,0.14-0.62c0.73-0.01,1.45-0.02,2.17-0.03c-0.08,0.21-0.1,0.43-0.06,0.65
        //                     C264.12,384,263.37,384,262.62,384z"/>
        //                 <path d="M283.86,372.75c0.26,0.98,0.53,1.95,0.79,2.93c-1.14-0.08-2.27-0.16-3.41-0.25
        //                     C282.11,374.54,282.98,373.65,283.86,372.75z"/>
        //                 <path  d="M0.22,342.75c0-2,0-4,0-6C2.34,338.75,2.34,340.75,0.22,342.75z"/>
        //                 <path  d="M279.11,377.22c-0.2,0.65-0.41,1.31-0.61,1.96c-0.29-0.32-0.58-0.63-0.87-0.95
        //                     C278.12,377.89,278.62,377.56,279.11,377.22z"/>
        //                 <path  d="M274.52,380.21c0.11,0.4,0.21,0.79,0.32,1.19c-0.78-0.08-1.56-0.16-2.34-0.24
        //                     C273.17,380.84,273.84,380.52,274.52,380.21z"/>
        //                 <path  d="M244.02,23.26c0.2-0.21,0.4-0.42,0.6-0.63C244.43,22.84,244.23,23.05,244.02,23.26z"/>
        //             </g>
        //             </svg>
        //             <p className="count">
        //                 {/* {pendingActivities !== '' ? pendingActivities?.data?.task : '0'} */}
        //                 {pendingActivities === '' ? "0" : pendingActivities?.data?.task > 99 ? "99+" : pendingActivities?.data?.task}
        //             </p>
        //             </div>
        //             {/* let obj: any = {
        //     ShowClass: true,
        //     FormName: navType,
        //     Data: beds
        // };
        // if(navType == rightNavigationPage.Vacant){
        //     dispatch(setNavigationAction(obj));
        // } */}
        //             <div className="count_up text-center" data-tooltip-id="my-tooltip" data-tooltip-content={pendingActivities !== '' ? `${pendingActivities?.data?.referrals} Referral List` : '0 Referral List'} onClick={() => pendingActivities?.data?.referrals > 0 ? handlerOpenRightBar(rightNavigationPage.ReferralList) : undefined}>
        //                 <h3 className="d-flex justify-content-between mb-0">
        //                 <svg enable-background="new 0 0 123.81 123.73" width="22" version="1.1" viewBox="0 0 123.81 123.73" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
        //                         <style type="text/css">{`
        //                                     .st00{fill:#4E4851;}
        //                                     `}</style>

        //                         <path className="st00" d="m83.43 21.44c0.3 11.99-10.03 22.27-22.5 21.78-11.05-0.43-21.03-9.52-20.66-22.53 0.32-11.08 9.17-19.91 19.72-20.63 14.2-0.97 23.73 10.33 23.44 21.38zm-39.27 0.26c-0.01 3.66 1.03 6.95 3.16 9.91 0.78 1.08 0.73 1.06 1.44-0.17 3.68-6.36 10.23-8.9 17.15-7.13 4.14 1.06 7.15 3.63 9.25 7.32 0.64 1.14 0.62 1.1 1.35 0.02 2.49-3.71 3.41-7.75 2.96-12.2-0.62-6.26-4.2-10.48-9.28-13.56-3.07-1.86-6.65-2.03-10.15-1.78-2.22 0.16-4.44 0.52-6.44 1.68-6.15 3.54-9.3 8.83-9.44 15.91z"/>
        //                         <path className="st00" d="m102.04 71.8c12.34-0.15 20.78 9.98 21.66 19.03 1.39 14.4-10.48 24.65-22.33 24.17-11.42-0.46-20.9-9.55-20.85-21.92 0.04-11.41 9.22-21.31 21.52-21.28zm-17.61 21.56c-0.13 3.93 1.2 7.28 3.33 10.4 0.46 0.68 0.54 0.51 0.96-0.02 1.33-1.7 2.57-3.53 4.17-4.93 3.71-3.24 8.13-3.94 12.86-2.82 4.36 1.03 7.52 3.68 9.72 7.5 0.57 1 0.92 0.65 1.32 0.04 3.04-4.72 4.03-9.87 2.38-15.22-1.78-5.75-5.58-9.78-11.48-11.64-3.97-1.26-7.93-1.18-11.82 0.09-6.32 2.03-11.58 8.74-11.44 16.6z"/>
        //                         <path className="st00" d="m43.2 93.25c0.14 12.05-9.86 21.97-22.21 21.73-11.27-0.22-21.15-9.6-20.99-22.01 0.15-11.39 9.2-20.68 20.37-21.16 13.74-0.59 23.03 10.44 22.83 21.44zm-4.02 0.03c0.18-2.35-0.2-4.64-1.17-6.8-2.32-5.2-6.15-8.7-11.72-10.13-4.23-1.09-8.45-0.84-12.36 1.02-5.65 2.68-8.9 7.2-9.76 13.52-0.6 4.4 0.22 8.37 2.52 12.09 0.99 1.59 1 1.59 1.93 0 3.32-5.63 9.79-8.56 15.94-7.18 4.68 1.05 8.26 3.59 10.4 7.97 0.44 0.89 0.78 0.44 1.12-0.05 2.21-3.13 3.36-6.56 3.1-10.44z"/>
        //                         <path className="st00" d="m59.94 89.03v-14.24c0-1.14-0.28-1.3-1.29-0.71-4.27 2.48-8.53 4.96-12.78 7.46-0.63 0.37-0.99 0.53-1.47-0.31-1.37-2.38-1.43-2.35 0.95-3.73 3.86-2.24 7.73-4.47 11.59-6.71 1-0.58 0.99-0.79 0.01-1.36-8.39-4.82-16.79-9.63-25.17-14.46-0.26-0.15-0.59-0.63-0.52-0.78 0.42-0.92 0.92-1.81 1.47-2.66 0.07-0.11 0.66-0.01 0.92 0.13 3.19 1.81 6.35 3.65 9.53 5.48 4.17 2.4 8.35 4.79 12.52 7.19 1.1 0.63 2.19 1.27 3.29 1.9 0.24 0.13 0.5 0.21 0.75 0.32 0.07-0.29 0.21-0.58 0.22-0.88 0.01-5.7 0.03-11.41-0.01-17.11-0.01-0.95 0.2-1.27 1.21-1.26 2.81 0.05 2.81 0 2.81 2.78 0 5.12 0.01 10.24-0.01 15.37 0 0.89 0.02 1.33 1.07 0.72 4.52-2.65 9.08-5.25 13.62-7.87 3.87-2.24 7.74-4.47 11.6-6.73 0.57-0.33 0.88-0.25 1.14 0.34 0.16 0.37 0.38 0.72 0.58 1.08 0.89 1.6 0.86 1.55-0.72 2.45-5.33 3.05-10.64 6.15-15.97 9.21-2.75 1.58-5.53 3.12-8.29 4.7-0.36 0.21-0.67 0.53-1 0.8 0.33 0.23 0.63 0.5 0.98 0.7 4.38 2.52 8.75 5.04 13.14 7.54 0.51 0.29 0.84 0.51 0.34 1.11-0.36 0.44-0.71 0.93-0.89 1.46-0.38 1.06-0.98 1.02-1.79 0.55-4-2.34-8-4.67-12-7-0.04-0.02-0.07-0.05-0.1-0.07-0.53-0.18-1.05-0.36-1.58-0.54-0.04 0.49-0.11 0.97-0.11 1.46-0.01 9.04 0 18.07-0.01 27.11 0 1.86 0 1.86-1.82 1.86-2.18 0-2.18 0-2.18-2.14-0.03-4.42-0.03-8.79-0.03-13.16z"/>
        //                         <path className="st00" d="m61.8 108.28c4.02-0.53 7.71 3.9 7.95 7.05 0.37 4.78-4.12 8.56-8.05 8.4-4.47-0.18-7.63-3.61-7.69-8.11-0.04-3.46 3.28-7.65 7.79-7.34z"/>
        //                         <path className="st00" d="m109.93 46.99c-0.23 4.54-3.7 7.69-7.91 7.74-3.79 0.04-7.93-3.22-7.57-8.35 0.28-4.04 3.37-7.38 8.02-7.26 4.24 0.1 7.32 3.3 7.46 7.87z"/>
        //                         <path className="st00" d="m21.55 39.31c4.35-0.36 7.94 3.59 7.86 7.49-0.1 4.73-3.45 7.81-8 7.93-3.78 0.1-7.44-3.39-7.52-7.83-0.08-3.91 3.77-8.13 7.66-7.59z"/>
        //                         <path className="st00" d="m69.44 14.88c0.21 4.67-3.92 7.54-8.27 7.64-3.22 0.07-7.11-4.28-6.94-7.72 0.19-3.84 3.15-7.46 7.56-7.46 4.57 0 7.73 3.55 7.65 7.54z"/>
        //                         <path className="st00" d="m102.38 79.04c4.37 0.08 7.59 3.48 7.47 7.64-0.13 4.5-3.66 7.62-8.33 7.54-3.28-0.05-6.9-3.5-6.89-7.68 0.01-3.63 3.08-7.58 7.75-7.5z"/>
        //                         <path className="st00" d="m13.96 86.5c0.13-3.43 3.04-7.56 7.76-7.45 4.08 0.1 7.51 3.36 7.44 7.58-0.07 4.53-3.24 7.49-7.83 7.57-3.86 0.07-7.13-3.34-7.37-7.7z"/>

        //                     </svg>
        //                     <p className="count">
        //                         {/* {pendingActivities !== '' ? pendingActivities?.data?.referrals : '0'} */}
        //                         {pendingActivities === '' ? "0" : pendingActivities?.data?.referrals > 99 ? "99+" : pendingActivities?.data?.referrals}
        //                     </p>
        //                 </h3>
        //             </div>
        //             <div className="count_up purple-btn" data-tooltip-id="my-tooltip" data-tooltip-content="Add New Patient" onClick={() => handlerOpenRightBar(rightNavigationPage.Vacant)}>
        //                 <h3 className="d-flex justify-content-between mb-0">
        //                     <i className="fa-solid fa-user-plus"></i>
        //                 </h3>
        //             </div>
        //         </div>
        //     </div>

        //     <Tooltip id="my-tooltip" className="my-tooltip" />
        // </div>
        <div>
            
        </div>
    );
};

export default BedDetailsView;
