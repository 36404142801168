import React from 'react';
import ServiceReferralTableFilterView from './ServiceReferralTableFilterView';

const ServiceReferralTableFilterContainer = (props: any) => {
    return (
        <div>
            <ServiceReferralTableFilterView changeDatePayloadHandler={props.changeDatePayloadHandler} changePayloadHandler={props.changePayloadHandler} />
        </div>
    );
};

export default ServiceReferralTableFilterContainer;