import React, { useEffect } from 'react';
import ChangePasswordModalView from './ChangePasswordModalView';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

const ChangePasswordModalContainer = (props: any) => {
    const dispatch = useDispatch();
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);

    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
      },[])
      

    return (
        <ChangePasswordModalView {...props} />
    );
};

export default ChangePasswordModalContainer;