import React, { useState, useEffect } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import WYSIWYGEditor from '../WYSIWYGEditor/WYSIWYGEditor';
import swal from 'sweetalert';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    className: any;
    footer: any;
}

const NoteModalView = (props: any) => {    
    const { t } = useTranslation();
    const [initialNote, setInitialNote] = useState<string>('');

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all' });

    useEffect(() => {        
        setInitialNote(props?.setFunderNote);
    }, []);
    const onSaveNote = (event: any) => {
        
        event.target.name = 'fnote';
        event.target.value = getValues('body') ?? initialNote;        
        event.target.rawValue = getValues('rawbody') ?? window.$utils.convertToPlain(initialNote);        
        props.notebody(event,props?.index);        
    };

    return (
        <div>
            <ModalWrapperContainer
                title={props?.title}
                onClose={props?.onClose}
                className="modal modal-medium note-editor"
                content={
                    <div>
                        <div>
                            <WYSIWYGEditor
                                onChange={(content: any) => {
                                    setValue('body', content?.split('<p></p>')?.join('')?.trim());
                                }}
                                initialContent={initialNote}
                                readOnly={props.isReadonly}
                                rawText={(rawContent: any) => {
                                    setValue('rawbody', rawContent.trim());
                                }}
                                maxlength={5000}
                            />
                        </div>                        
                    </div>
                }
                footer={
                     <div className="text-center">
                        {!props?.isReadonly && <button className="btn btn-primary btn-sm me-2" onClick={(e) => onSaveNote(e)}>
                            {props?.isEditing ? t('ReferralLead.Update_btn') : t('ReferralLead.Save_btn')}
                        </button>
                        }
                    </div>
                }
            />
        </div>
    );
};

export default NoteModalView;
