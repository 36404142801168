import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import MaintenanceBedListContainer from './MaintenanceBedList/MaintenanceBedListContainer';
import { rightNavigationPage } from '../../../../../../utils/constant';

const MaintenanceListView = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState<any>(0);
    const { StatusListMaintenanceBeds, DataListMaintenanceBeds } = useSelector((state: RootState) => state.listMaintenanceBeds);

    const closeSideMenu = () => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle:false
        };
        dispatch(setNavigationAction(obj));
    };
    useEffect(() => {
        if (StatusListMaintenanceBeds === true) {
            setTotalCount(DataListMaintenanceBeds.totalCount);
        }
    }, [StatusListMaintenanceBeds, DataListMaintenanceBeds]);
    return (
        <div>
            <button onClick={closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>{' '}
            </button>
            <h3 className="mb-2">
                {t('MaintenanceList.Maintenance_Room_List')} -
                <span className="ms-1 purple-text">{totalCount}</span>
            </h3>
            <div className="row mt-3 mb-3">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                        <div className="search-input position-relative w-100 me-2">
                            <input className="form-control white" type="text" placeholder="Search" id="example-search-input" onChange={props.handlerSearchValue} />
                            <i className="fa fa-search"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="right_content-height">
                <div className="accordion accordion-flush" id="draftpatient">
                    <MaintenanceBedListContainer searchValue={props.searchValue} />
                </div>
            </div>

            <Tooltip id="my-tooltip1" className="my-tooltip" />
        </div>
    );
};

export default MaintenanceListView;
