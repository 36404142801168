import React from 'react';
import ProgressNoteView from './ProgressNoteView';

const ProgressNoteContainer = (props: any) => {
    return (
        <div>
            <ProgressNoteView orgName={props.orgName} progressNoteFormValid={props.progressNoteFormValid} progressNoteDataChange={props.progressNoteDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default ProgressNoteContainer;