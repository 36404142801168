import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlanDataModel } from '../../utils/model';

const initialState: PlanDataModel = {
    PlanData: '',
};

export const planDataSlice = createSlice({
    name: 'planData',
    initialState,
    reducers: {
        setPlanDataAction: (state, { payload }: PayloadAction) => {
            state.PlanData = payload;
        },
        errorPlanDataAction: (state, { payload }: PayloadAction) => {
            state.PlanData = [];
        },
        removePlanDataAction: (state, { payload }: PayloadAction) => {
            state.PlanData = [];
        }
    },
    extraReducers: {}
});

export const { setPlanDataAction,errorPlanDataAction, removePlanDataAction } = planDataSlice.actions;

export default planDataSlice.reducer;
