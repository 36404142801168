import React, {useEffect, useState} from 'react';
import PrintAssessmentView from './PrintAssessmentView';
import { getAssessmentDropdown } from '../../service/assessment-service';

const PrintAssessmentContainer = (props:any) => {
    const [assessmentDropdown, setAssessmentDropdown] = useState<any>(undefined)
    useEffect(() => {
        const fetchGetAssessmentDropdown = async () => {
            const response = await getAssessmentDropdown();
            if (response.status === true) {
                setAssessmentDropdown(response.data);
            }
        };
        fetchGetAssessmentDropdown();
    }, []);
    return (
        <div>
            <PrintAssessmentView orgName={props.orgName} assessmentDetails={props.assessmentDetails} assessmentDropdown={assessmentDropdown} />
        </div>
    );
};

export default PrintAssessmentContainer;