import React, { useEffect, useState } from 'react';
import ServiceTabListContainer from './ServiceTabList/ServiceTabListContainer';
import OverviewContainer from './Overview/OverviewContainer';
import AmenitiesContainer from './Amenities/AmenitiesContainer';
import LocationContainer from './Location/LocationContainer';
import ServicesContainer from './Services/ServicesContainer';
import PackagesContainer from './Packages/PackagesContainer';
import { useParams } from 'react-router-dom';
import { GetLowerSectionAvailability } from '../../../../service/service-details-service';
import QuestionAnswerContainer from '../QuestionAnswer/QuestionAnswerContainer';

const ServiceTabView = () => {
    const [serviceSection, SetServiceSection] = useState<any>(null)
    const { serviceSlug } = useParams();

    useEffect(() => {
        GetProviderServiceDetailsList()
    }, [])

    const GetProviderServiceDetailsList = async () => {
        const result = await GetLowerSectionAvailability(serviceSlug)
        SetServiceSection(result?.data)
    }
    return (
        <div>
            <ServiceTabListContainer serviceSection={serviceSection} />
            <OverviewContainer />
            {serviceSection?.amenities && <AmenitiesContainer />}
            {serviceSection?.services && <ServicesContainer />}
            {serviceSection?.packages && <PackagesContainer />}
            <LocationContainer />
            <QuestionAnswerContainer />
        </div>
    );
};

export default ServiceTabView;
