import React from 'react';
import TableGroupByView from './TableGroupByView';

const TableGroupByContainer = (props: any) => {
    return (
        <div>
            <TableGroupByView onChangeGroupByHandler={props.onChangeGroupByHandler} onStateGroupByHandler={props.onStateGroupByHandler} onRemoveAllGroupBy={props.onRemoveAllGroupBy} />
        </div>
    );
};

export default TableGroupByContainer;