import React, { useEffect, useState } from 'react';
import InvoiceDetailFormView from './InvoiceDetailFormView';
import { getAdmittedPatientList, getFunderByPatientId, getPatientByNhs, getinvoicelastDate } from '../../../../service/invoicedetail-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import eventEmitter from '../../../../utils/eventEmitter';
const InvoiceDetailFormContainer = (props:any) => {

    let [patientdata,setpatinetdata] = useState<any>();
    let [funderdata,setfunderdata] = useState<any>();
    let [patientid,setpatientid] = useState<any>();
    let [invoicefunderdetails,setinvoicefunderdetails] = useState<any>();
    let [data,setData] = useState<any>();
    let [previousInvoiceDate,setpreviousInvoiceDate]=useState<any>();
    let [resetform,setresetform]=useState<boolean>(false);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    useEffect(()=>{
        async function GetAdmittedpatients() {
        const result = await getAdmittedPatientList(DataServiceDetail?.serviceId);
        
        if(result.status === true){
          setpatinetdata(result.data);
        }
        }
            GetAdmittedpatients();
        
    },[]);

    useEffect(()=>{
        
      if(invoicefunderdetails !== undefined){
        let obj = {
            ...data,
            funderadd: invoicefunderdetails[0]?.funderAddressLine1
        };
        
        setData(obj);
      }
    },[invoicefunderdetails])

    useEffect(()=>{
        if(props.invoicedata !== undefined){
            setpatientid(props.invoicedata?.patientDetail?.patientId);
            if(funderdata !== undefined){
               
                let invoicedatas =  funderdata.filter((value:any)=> {
                    if(props.invoicedata?.invoiceModel?.funderId !== 0){
                        if(value.funderId === Number(props.invoicedata?.invoiceModel?.funderId)){
                            return value;
                        }
                    }
                    else{
                       if(value.funderName === "Self"){
                            return value;
                       }
                    }
                       
                    });
                    
                    setinvoicefunderdetails(invoicedatas);
            }
            let obj={
                ...data,
                    'Patient': props.invoicedata?.patientDetail?.patientId || '',
                    'nhs': props.invoicedata?.patientDetail?.nhsId || '',
                    'funder': props.invoicedata?.invoiceModel?.funderId || null,
                    'funderadd': props.invoicedata?.invoiceModel?.funderAddress || '',
                    'periodfrom': props.invoicedata?.invoiceModel?.periodFrom ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.periodFrom) : '',
                    'periodto': props.invoicedata?.invoiceModel?.periodTo ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.periodTo) : '',
                    'invoicedate': props.invoicedata?.invoiceModel?.invoiceDate ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.invoiceDate) : '',
                    'duedate': props.invoicedata?.invoiceModel?.dueDate ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.dueDate) : '',
                    'paymentref': props.invoicedata?.invoiceModel?.paymentReference || '',
                    'document':'', 
                    'notes': props.invoicedata?.invoiceModel?.notes || '', 
             }
             
             setData(obj);

        }
    },[props.invoicedata,funderdata])

    useEffect(()=>{
     if(patientid !== undefined && patientdata !== undefined){
        GetFunderByPatient(Number(patientid));
        Getlastinvoicedate(Number(patientid));
        patientdata.filter((data:any)=>{
            if(data.patientId === Number(patientid)){
                props.getadmissionId(data.admission_Id);
            }
        })
     }
    },[patientid,patientdata]);

    async function GetFunderByPatient(id:number) {
        const result = await getFunderByPatientId(id,DataServiceDetail?.serviceId);
        
        if(result.status === true){
          setfunderdata(result.data);
        }
    }

    async function Getlastinvoicedate(id:number) {
        const result = await getinvoicelastDate(id);
        
        if(result.status){
            setpreviousInvoiceDate(result.data?.previousInvoiceDate);
        }
        else{
            setpreviousInvoiceDate(undefined);
        }
        
    }

    useEffect(()=>{   
     props.getinvoicedata(data);
     
    },[data]);

    const updateduplicatedata = () =>{
        setData({
            ...data,
            ['periodfrom']: undefined,
            ['periodto']: undefined,
           })
    }

    const updateData = (e:any) =>{
        eventEmitter.emit('eventInvoiceEdit');
        if(e.target.name === "Patient"){
            eventEmitter.emit('clearData');
            GetFunderByPatient(Number(e.target.value?.id));
            setpatientid(e.target.value?.id);
            setinvoicefunderdetails(undefined);
            
            if(patientid !== undefined){
                setresetform(true);
                setTimeout(() => {
                    setresetform(false);
                }, 200);
            }
        }
        if(e.target.name === "funder"){
           let invoicedatas =  funderdata?.filter((value:any)=> {
            if(e.target.value.id !== null){
                if(value.funderId === Number(e.target.value?.id)){
                    return value;
                }
            }
            else{
               if(value.funderName === "Self"){
                    return value;
               }
            }
               
            });
            
            setinvoicefunderdetails(invoicedatas);
          
            
        }
        if(e.target.name === 'Patient') {
            setData({
                ...data,
                [e.target.name]: e.target.value?.id?.toString()
            });
        } else if(e.target.name === 'funder') {
            if(e.target.value?.id !== null){
                setData({
                    ...data,
                    [e.target.name]: e.target.value?.id?.toString()
                });
            } else{
                setData({
                    ...data,
                    [e.target.name]: e.target.value?.id
                });
            }
         
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            });
        }
    }

    const updateDate = (date:any,fieldname:any) =>{
        eventEmitter.emit('eventInvoiceEdit');
        let selecteddate = new Date(date);
        setData({
            ...data,
            [fieldname]: selecteddate
        });
    }
    const updateDueDate = (date:any,fieldname:any) =>{
        let selecteddate = new Date(date);
        setData({
            ...data,
            [fieldname]: selecteddate
        });
    }

    const getpatientbynhs = (e:any) =>{
        let nhs = e.target.value;
        nhs = nhs.split(" ").join("");
        async function GetPatientByNhs() {
            const result = await getPatientByNhs(nhs,DataServiceDetail?.serviceId);
            if(result.status === true){
                setpatientid(result?.data?.serviceUserDetails?.patientId);
            }
        }
        if(e.target.value !== ""){
            GetPatientByNhs();
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    

    const updateFData = (e:any) =>{
        eventEmitter.emit('eventInvoiceEdit');
        setData({
            ...data,
            ['documentPath']: e.filedata.filePath,
            ['documentName']: e.filedata.fileName,
        });
}

     const updateformdata = () =>{
       setData({
        ...data,
        ['periodfrom']: undefined,
        ['periodto']: undefined,
        ['invoicedate']:  undefined,
        ['duedate']: undefined,
       })
     }

    return (
        <div>
            <InvoiceDetailFormView duplicateStatus={props.duplicateStatus} invoiceDraftStatus={props.invoiceDraftStatus} invoicefunderdetails={invoicefunderdetails} patientid={patientid} getpatientbynhs={getpatientbynhs} funderdata={funderdata} updateData={updateData}  patientdata={patientdata} isformsave={props.isformsave} isformcancel={props.isformcancel} invoicedata={props.invoicedata} updateDate={updateDate} isdatavalid={props.isdatavalid} updateFData={updateFData} previousInvoiceDate={previousInvoiceDate} updateDueDate={updateDueDate} resetform={resetform} updateformdata={updateformdata} updateduplicatedata={updateduplicatedata}/>
        </div>
        // getinvoicedata={props.getinvoicedata}
    );
};

export default InvoiceDetailFormContainer;