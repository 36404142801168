import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Info_gethering from '../../../assets/images/Info-gathering-white.svg';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AssessmentStatus } from '../../../utils/constant';

const ServiceUserProfileView = (props: any) => {
    const { assessmentDetails } = props;

    let [assessmentDetailsServiceUserProfileView, setassessmentDetailsServiceUserProfileView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        asupId: Yup.string().notRequired(),
        whatDoYouLikeToBeCalled: Yup.string().required(),
        whatsImportantToYouNow: Yup.string().required(),
        whatsImportantToYouForTheFuture: Yup.string().required(),
        whatAreYourLikes: Yup.string().required(),
        whatPeopleAdmireAboutYou: Yup.string().required(),
        whatAreYourHobbiesAndInterests: Yup.string().required(),
        whatAreYourHopesAndDreams: Yup.string().required(),
        whatAreYourDislikes: Yup.string().required(),
        familyName1: Yup.string().required(),
        familyName2: Yup.string().required(),
        peopleYouWorkWithName1: Yup.string().required(),
        peopleYouWorkWithName2: Yup.string().required(),
        homeAndOtherPaidSupportersName1: Yup.string().required(),
        homeAndOtherPaidSupportersName2: Yup.string().required(),
        friendsAndNonPaidRelationshipsName1: Yup.string().required(),
        friendsAndNonPaidRelationshipsName2: Yup.string().required()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const watchAllFields = watch();

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsServiceUserProfileView(props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData);
            setValue('asupId', assessmentDetails?.assessmentServiceUserProfileModel?.formData?.asupId ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.asupId : '', {
                shouldValidate: true
            });
            setValue(
                'whatDoYouLikeToBeCalled',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatDoYouLikeToBeCalled ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatDoYouLikeToBeCalled : '',
                { shouldValidate: true }
            );
            setValue(
                'whatsImportantToYouNow',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatsImportantToYouNow ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatsImportantToYouNow : '',
                { shouldValidate: true }
            );
            setValue(
                'whatsImportantToYouForTheFuture',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatsImportantToYouForTheFuture
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatsImportantToYouForTheFuture
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatAreYourLikes',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourLikes ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourLikes : '',
                { shouldValidate: true }
            );
            setValue(
                'whatPeopleAdmireAboutYou',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatPeopleAdmireAboutYou
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatPeopleAdmireAboutYou
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatAreYourHobbiesAndInterests',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourHobbiesAndInterests
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourHobbiesAndInterests
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatAreYourHopesAndDreams',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourHopesAndDreams
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourHopesAndDreams
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'whatAreYourDislikes',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourDislikes ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourDislikes : '',
                { shouldValidate: true }
            );
            setValue('familyName1', assessmentDetails?.assessmentServiceUserProfileModel?.formData?.familyName1 ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.familyName1 : '', {
                shouldValidate: true
            });
            setValue('familyName2', assessmentDetails?.assessmentServiceUserProfileModel?.formData?.familyName2 ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.familyName2 : '', {
                shouldValidate: true
            });
            setValue(
                'peopleYouWorkWithName1',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.peopleYouWorkWithName1 ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.peopleYouWorkWithName1 : '',
                { shouldValidate: true }
            );
            setValue(
                'peopleYouWorkWithName2',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.peopleYouWorkWithName2 ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.peopleYouWorkWithName2 : '',
                { shouldValidate: true }
            );
            setValue(
                'homeAndOtherPaidSupportersName1',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.homeAndOtherPaidSupportersName1
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.homeAndOtherPaidSupportersName1
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'homeAndOtherPaidSupportersName2',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.homeAndOtherPaidSupportersName2
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.homeAndOtherPaidSupportersName2
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'friendsAndNonPaidRelationshipsName1',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.friendsAndNonPaidRelationshipsName1
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.friendsAndNonPaidRelationshipsName1
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'friendsAndNonPaidRelationshipsName2',
                assessmentDetails?.assessmentServiceUserProfileModel?.formData?.friendsAndNonPaidRelationshipsName2
                    ? assessmentDetails?.assessmentServiceUserProfileModel?.formData?.friendsAndNonPaidRelationshipsName2
                    : '',
                { shouldValidate: true }
            );
        } else {
            setValue('asupId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        props.serviceUserProfileFormValid(formState.isValid);
        props.serviceUserProfileDataChange(watchAllFields);
        // if(formState.isValid) {
        //     props.serviceUserProfileDataChange(watchAllFields)
        // }
    }, [formState]);

    return (
        <div id="service-profile">
            <div className="modal-title-header mb-2 mt-3">
                <h3>
                    <img src={Info_gethering} alt="" width={17} className="me-2" />
                    <input
                        type="hidden"
                        {...register('asupId', {
                            // onChange: (e: any) => {
                            //     props.updateData(e);
                            // }
                        })}
                        name={'asupId' as const}
                    />
                    {t('ServiceProfile.Service_Profile')}
                </h3>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_called')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatDoYouLikeToBeCalled', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatDoYouLikeToBeCalled' as const}
                            className={`form-control white ${errors.whatDoYouLikeToBeCalled ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatDoYouLikeToBeCalled}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_about_you')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatsImportantToYouNow', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatsImportantToYouNow' as const}
                            className={`form-control white ${errors.whatsImportantToYouNow ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatsImportantToYouNow}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_important')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatsImportantToYouForTheFuture', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatsImportantToYouForTheFuture' as const}
                            className={`form-control white ${errors.whatsImportantToYouForTheFuture ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatsImportantToYouForTheFuture}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_interests')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatAreYourLikes', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatAreYourLikes' as const}
                            className={`form-control white ${errors.whatAreYourLikes ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatAreYourLikes}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_future')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatPeopleAdmireAboutYou', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatPeopleAdmireAboutYou' as const}
                            className={`form-control white ${errors.whatPeopleAdmireAboutYou ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatPeopleAdmireAboutYou}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_dreams')}</label>

                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatAreYourHobbiesAndInterests', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatAreYourHobbiesAndInterests' as const}
                            className={`form-control white ${errors.whatAreYourHobbiesAndInterests ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatAreYourHobbiesAndInterests}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_Like')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatAreYourHopesAndDreams', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatAreYourHopesAndDreams' as const}
                            className={`form-control white ${errors.whatAreYourHopesAndDreams ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatAreYourHopesAndDreams}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('ServiceProfile.What_dislikes')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('whatAreYourDislikes', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'whatAreYourDislikes' as const}
                            className={`form-control white ${errors.whatAreYourDislikes ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.whatAreYourDislikes}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text mt-3 mb-2 bold">{t('ServiceProfile.Relationship_Circle')}</p>

            <p className="purple-text mt-3 mb-2 bold">{t('ServiceProfile.Family')}</p>
            <div className="row">
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('familyName1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'familyName1' as const}
                            className={`form-control white ${errors.familyName1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.familyName1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('familyName2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'familyName2' as const}
                            className={`form-control white ${errors.familyName2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.familyName2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <p className="purple-text mt-3 mb-2 bold">{t('ServiceProfile.People_work_with')}</p>
            <div className="row">
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('peopleYouWorkWithName1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'peopleYouWorkWithName1' as const}
                            className={`form-control white ${errors.peopleYouWorkWithName1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.peopleYouWorkWithName1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('peopleYouWorkWithName2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'peopleYouWorkWithName2' as const}
                            className={`form-control white ${errors.peopleYouWorkWithName2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.peopleYouWorkWithName2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <p className="purple-text mt-3 mb-2 bold">{t('ServiceProfile.Home_supporters')}</p>
            <div className="row">
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('homeAndOtherPaidSupportersName1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'homeAndOtherPaidSupportersName1' as const}
                            className={`form-control white ${errors.homeAndOtherPaidSupportersName1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.homeAndOtherPaidSupportersName1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('homeAndOtherPaidSupportersName2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'homeAndOtherPaidSupportersName2' as const}
                            className={`form-control white ${errors.homeAndOtherPaidSupportersName2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.homeAndOtherPaidSupportersName2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>

            <p className="purple-text mt-3 mb-2 bold">{t('ServiceProfile.Friends_relationships')}</p>
            <div className="row">
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('friendsAndNonPaidRelationshipsName1', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'friendsAndNonPaidRelationshipsName1' as const}
                            className={`form-control white ${errors.friendsAndNonPaidRelationshipsName1 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.friendsAndNonPaidRelationshipsName1}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6">
                    <label className="mb-2">{t('ServiceProfile.Name')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('friendsAndNonPaidRelationshipsName2', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'friendsAndNonPaidRelationshipsName2' as const}
                            className={`form-control white ${errors.friendsAndNonPaidRelationshipsName2 ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsServiceUserProfileView.friendsAndNonPaidRelationshipsName2}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
        </div>
    );
};

export default ServiceUserProfileView;
