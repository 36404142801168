import React from 'react';
import WhatNextView from './WhatNextView';

const WhatNextContainer = (props: any) => {
    return (
        <div>
            <WhatNextView orgName={props.orgName} whatNextFormValid={props.whatNextFormValid} whatNextDataChange={props.whatNextDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default WhatNextContainer;