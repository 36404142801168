import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListUserOrganizationModel } from '../../utils/model';

const initialState: ListUserOrganizationModel = {
    StatusListUserOrganization: false,
    DataListUserOrganization: '',
    MessageListUserOrganization: '',
    ErrorListUserOrganization: ''
};

export const listUserOrganizationSlice = createSlice({
    name: 'listUserOrganization',
    initialState,
    reducers: {
        setListUserOrganizationAction: (state, { payload }: PayloadAction) => {
            state.StatusListUserOrganization = true;
            state.DataListUserOrganization = payload;
            state.MessageListUserOrganization = 'Success';
        },
        errorListUserOrganizationAction: (state, { payload }: PayloadAction) => {
            state.StatusListUserOrganization = false;
            state.DataListUserOrganization = [];
            state.MessageListUserOrganization = 'Failed';
            state.ErrorListUserOrganization = payload;
        },
        removeListUserOrganizationAction: (state, { payload }: PayloadAction) => {
            state.StatusListUserOrganization = false;
            state.DataListUserOrganization = '';
            state.MessageListUserOrganization = '';
            state.ErrorListUserOrganization = '';
        }
    },
    extraReducers: {}
});

export const { setListUserOrganizationAction, errorListUserOrganizationAction, removeListUserOrganizationAction } = listUserOrganizationSlice.actions;

export default listUserOrganizationSlice.reducer;
