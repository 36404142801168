import React, { useMemo } from 'react';
import ServicetypeSliderContainer from './ServicetypeSlider/ServicetypeSliderContainer';
import SearchFilterContainer from './SearchFilter/SearchFilterContainer';
import { useLocation } from 'react-router-dom';

const ServicetypeHeaderView = (props: any) => {
    const location = useLocation();

    const isServiceDetailPage = useMemo(() => {
      return location.pathname.includes('/service-detail') || location.pathname.includes('/provider-service-detail');
    }, [location.pathname]);
  
    if (isServiceDetailPage) return null;

    return (
        <div className="service-header px-2" style={{ paddingTop: props.fromMapView ? 0 : undefined, marginTop: props.fromMapView ? 10 : undefined }}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-12 col-md-7">
                        <ServicetypeSliderContainer />
                    </div>
                    <div className="col-lg-4 col-12 col-md-5">
                        <SearchFilterContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicetypeHeaderView;
