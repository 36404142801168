import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';

import { setListtPatientAction, errorListtPatientAction } from '../store/patient/listPatientReducer';
import { setPatientDetailAction, errorPatientDetailAction } from '../store/patient/patientDetailsReducer';
import eventEmitter from '../utils/eventEmitter';

export const getListPatientService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetPatientList}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setListtPatientAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorListtPatientAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorListtPatientAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const getDraftListPatientService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetDraftPatientList}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setListtPatientAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {

            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorListtPatientAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorListtPatientAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const getPatientDetailsService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPatientDetails}${payload.patientId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setPatientDetailAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: '',
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorPatientDetailAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: '',
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorPatientDetailAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};

export const GetPatientInquiryList = async (query: any) => {
    eventEmitter.emit('eventSetLoader', true);
    const params = new URLSearchParams(query).toString();

    return await instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetPatientInquiry}?${params}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: '',
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: '',
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
}
