import React from 'react';
import { useTranslation } from 'react-i18next';

const ExportIntegrationView = (props:any) => {
    const { t } = useTranslation();
    return (
        <div className='me-2'><a href="#" className="btn btn-white bold " onClick={() => props.onChangeActionHandler()}><i className='fa fa-undo' aria-hidden="true"></i> {t('IntegrationInterface.Reprocess')}</a></div>
    );
};

export default ExportIntegrationView;