import React, { useEffect, useState } from 'react';
import DropdownContainer from '../../../../../components/Hoc/DropdownHOC';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
const OrganizationFilterView = (props: any) => {
    const [data, setData] = useState<any>([]);

    const { StatusListUserOrganization, DataListUserOrganization } = useSelector((state: RootState) => state.listUserOrganization);

    let prepareData = (data: any) => {
        // optional: you can skip cloning if you don't mind mutating original data
        var cloned = data.slice(0);

        // insert special select all node
        cloned.splice(0, 0, {
            label: 'Select All',
            value: 'selectAll',
            className: 'select-all',
            type: 'selectall'
        });

        return cloned;
    };

    useEffect(() => {        
        if(StatusListUserOrganization === true) {
            let {data:dataReformate} = DataListUserOrganization
            if (dataReformate !== undefined && dataReformate.length > 0) {
                let dataFormated: any = dataReformate.map((orgData: any, ordIndex: any) => {
                    if (orgData.children.length > 0) {
                        let serviceChildren = orgData.children.map((serviceTypeData: any) => {
                            if (serviceTypeData.children.length > 0) {
                                let services: any = serviceTypeData.children.map((serviceData: any) => {
                                    return { ...serviceData, type: 'Service' }
                                });
                                return {
                                    ...serviceTypeData,
                                    children: services,
                                    type: 'ServiceType',
            
                                }
                            }                            
                        });
                        return {
                            ...orgData,
                            children: serviceChildren,
                            type: 'Organization',
    
                        }
                    }
                });
                setData(prepareData(dataFormated));
            }            
        }        
    }, [StatusListUserOrganization, DataListUserOrganization]);
    return (
        <div className='me-2 organization-dropdown'>
            {/* @ts-ignore */}
            {data !== undefined && data.length > 0 && <DropdownContainer inlineSearchInput={true} data={data}  />}
        </div>
    );
};

export default OrganizationFilterView;
