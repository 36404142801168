import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RelationshipInformationModel } from '../../utils/model';


const initialState: RelationshipInformationModel = {
    StatusRelationshipInfo: false,
    DataRelationshipInfo: '',
    MessageRelationshipInfo: '',
    ErrorRelationshipInfo: ''
};

export const relationshipInformationSlice = createSlice({
    name: 'relationshipInformation',
    initialState,
    reducers: {
        setRelationshipInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusRelationshipInfo = true;
            state.DataRelationshipInfo = payload;
            state.MessageRelationshipInfo = 'Success';
        },
        errorRelationshipInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusRelationshipInfo = false;
            state.DataRelationshipInfo = [];
            state.MessageRelationshipInfo = 'Failed';
            state.ErrorRelationshipInfo = payload;
        },
       removeRelationshipInformationAction: (state, { payload }: PayloadAction) => {
            state.StatusRelationshipInfo = false;
            state.DataRelationshipInfo = '';
            state.MessageRelationshipInfo = '';
            state.ErrorRelationshipInfo = '';
        }
    },
    extraReducers: {}
});

export const { setRelationshipInformationAction, errorRelationshipInformationAction, removeRelationshipInformationAction } = relationshipInformationSlice.actions;

export default relationshipInformationSlice.reducer;
