import React from 'react';
import CreateNewButtonView from './CreateNewButtonView';

const CreateNewButtonContainer = (props: any) => {
    return (
        <div>
            <CreateNewButtonView onClickCreateNewInvoiceNoHandler={props.onClickCreateNewInvoiceNoHandler} />
        </div>
    );
};

export default CreateNewButtonContainer;