import { useEffect, useState } from 'react';
// import DatePickerView from '../../../../../../../components/DatePicker/dateView';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getInternalLocalConfigData } from '../../../../../../../service/dashboard-service';
import { RootState } from '../../../../../../../store';

import SmartSearchDropdownContainer from '../../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../../service/ApiConstants';
import eventEmitter from '../../../../../../../utils/eventEmitter';
const validationSchema = Yup.object().shape(
    {
        FullName: Yup.string().trim().strict(true).nonNullable().required('name is required'),
        kFullName: Yup.string().trim().strict(true).nonNullable().notRequired(),
        dob: Yup.date().required('date is required'),
        NhsId: Yup.string().when('LocalId', {
            is: '',
            // then : (schema:any) => schema.matches(/^$|^\d{3}\s?\d{3}\s?\d{4}$/).required(),
            then: (schema: any) => schema.matches(/^$|\b\d{3}\s?\d{3}\s?\d{4}\b$/).required(),
            otherwise: (schema: any) => schema.nonNullable().notRequired()
        }),
        LocalId: Yup.string()
            .trim()
            .strict(true)
            .when('NhsId', {
                is: '',
                then: (schema: any) => schema.matches(/^$|^[0-9A-Za-z]{1,14}$/).required(),
                otherwise: (schema: any) => schema.nonNullable().notRequired()
            }),
        Address1: Yup.string().trim().strict(true).nonNullable().notRequired(),
        kAddress: Yup.string().trim().strict(true).nonNullable().notRequired(),
        Address2: Yup.string().trim().strict(true).nonNullable().notRequired(),
        City: Yup.string().strict(true).nonNullable().notRequired(),
        kRelationship: Yup.string().strict(true).nonNullable().notRequired(),
        gender: Yup.string().strict(true).nonNullable().required('gender is required'),
        kgender: Yup.string().strict(true).nonNullable().notRequired(),
        PoBox: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
            .nonNullable()
            .notRequired(),
        ContactNo: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .nonNullable()
            .notRequired(),
        kContactNo: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .nonNullable()
            .notRequired(),
        EmailId: Yup.string()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .strict(true)
            .nonNullable()
            .nullable()
            .notRequired()
    },
    [['NhsId', 'LocalId']]
);
const ServiceUserDetailView = (props: any) => {
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusRelationshipInfo, DataRelationshipInfo, MessageRelationshipInfo, ErrorRelationshipInfo } = useSelector((state: RootState) => state.relationshipInformation);
    const { ShowClass, Data } = useSelector((state: RootState) => state.navigation);
    let [CityData, setCityData] = useState<any>([]);
    let [DisplayInternalLocalId, setDisplayInternalLocalId] = useState(false);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    let [RelationshipData, setRelationshipData] = useState<any>([]);
    let [citySmartSearch, setCitySmartSearch] = useState<any>(undefined);
    let [relationshipSmartSearch, setRelationshipSmartSearch] = useState<any>(undefined);
    let [dob, setdob] = useState<any>(null);
    let [cityid, setcityid] = useState<any>(undefined);

    let [defaultrelationshipid, setdefaultrelationshipid] = useState<any>(undefined);

    let [edob, setedob] = useState<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (DataCityInfo.data !== undefined && DataCityInfo.data.length > 0) {
            setCityData(DataCityInfo.data);
        }
    }, [DataCityInfo]);
    useEffect(() => {
        if (DataRelationshipInfo.data !== undefined && DataRelationshipInfo.data.length > 0) {
            setRelationshipData(DataRelationshipInfo.data);
        }
    }, [DataRelationshipInfo]);

    useEffect(() => {
        if (!props.ischeck) {
            setCitySmartSearch(undefined);
        }
    }, [props.ischeck]);

    useEffect(() => {
        eventEmitter.on('clearSmartSearch', clearSmartSearch);
        return () => {
            eventEmitter.off('clearSmartSearch', clearSmartSearch);
        };
    });

    const clearSmartSearch = () => {
        setValue('kRelationship', '', { shouldValidate: true });
        setRelationshipSmartSearch(undefined);
    };

    const clearCitySmartSearch = () => {
        setValue('City', '', { shouldValidate: true });
        setCitySmartSearch(undefined);
    };

    useEffect(() => {
        if (relationshipSmartSearch === undefined) {
            let objRelationshipSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRelationshipList}`,
                extraPayload: {
                    genderId: props.data?.kgender || 1
                }
            };
            setRelationshipSmartSearch(objRelationshipSmartSearch);
        }
    }, [relationshipSmartSearch]);

    useEffect(() => {
        if (citySmartSearch === undefined) {
            let objCitySmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                extraPayload: {
                    defaultCityId: ''
                }
            };
            setCitySmartSearch(objCitySmartSearch);
        }
    }, [citySmartSearch]);

    useEffect(() => {
        if (props.dob !== null && props.dob !== '') {
            setdob(props.dob);
        } else {
            setdob('');
        }
    }, [props.dob]);

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    useEffect(() => {
        // Need to reset these states to clear the form data completely. Only reset() is not enough.
        setdob(null);
        setedob(null);
        setcityid(null);
        props.getDate(null);
        props.resetData();
        clearCitySmartSearch();
        clearSmartSearch();
        reset();
    }, [Data]);

    useEffect(() => {
        if (props.draftData !== undefined) {
            
            setValue('FullName', props.draftData.patientName || '', { shouldValidate: true });
            setValue('dob', props.draftData.dateOfBirth ? window.$utils.dateFormat(props.draftData.dateOfBirth) : '', { shouldValidate: true });
            setValue('NhsId', props.draftData.nhsId || '', { shouldValidate: true });
            setValue('LocalId', props.draftData.localId || '', { shouldValidate: true });
            setValue('Address1', props.draftData.addressLine1 || '', { shouldValidate: true });
            setValue('Address2', props.draftData.addressLine2 || '', { shouldValidate: true });
            setValue('City', props.draftData?.cityId?.toString() || '', { shouldValidate: true });
            setValue('PoBox', props.draftData.pobox || '', { shouldValidate: true });
            setValue('ContactNo', props.draftData.contactNo || '', { shouldValidate: true });
            setValue('EmailId', props.draftData.email || '', { shouldValidate: true });
            setValue('gender', props.draftData.gender == 0 ? '1' : props.draftData.gender?.toString(), { shouldValidate: true });
            setValue('kgender', props.draftData.gender == 0 ? '1' : props.draftData.kinGender?.toString(), { shouldValidate: true });
            setValue('kContactNo', props.draftData.kinContactNo || '', { shouldValidate: true });
            setValue('kFullName', props.draftData.kinName || '', { shouldValidate: true });
            setValue('kAddress', props.draftData.kinAddress || '', { shouldValidate: true });
            setValue('kRelationship', props.draftData?.kinRelationshipId !== null ? props.draftData?.kinRelationshipId?.toString() : '', { shouldValidate: true });
            setdefaultrelationshipid(props.draftData?.kinRelationshipId);
            setValue('internalLocalId', props.draftData?.internalLocalId);
            setcityid(props.draftData?.cityId);
            let objCitySmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                extraPayload: {
                    defaultCityId: props.draftData?.cityId || ''
                }
            };
            setCitySmartSearch(objCitySmartSearch);
            let objRelationshipSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRelationshipList}`,
                extraPayload: {
                    genderId: props.draftData.kinGender,
                    defaultRelationshipId: props.draftData?.kinRelationshipId
                }
            };
            setRelationshipSmartSearch(objRelationshipSmartSearch);
        } else {
            reset();
        }
    }, [props.draftData, DataCityInfo]);

    useEffect(() => {
        if (props.patient !== undefined && props.patient != null && props.patient != '') {
            setValue('FullName', props.patient.patientName || '', { shouldValidate: true });
            setValue('dob', props.patient.dateOfBirth ? window.$utils.dateFormat(props.patient.dateOfBirth) : '', { shouldValidate: true });
            setValue('NhsId', props.patient.nhsId || props.data.NhsId || '', { shouldValidate: true });
            setValue('LocalId', props.patient.localId || props.data.LocalId || '', { shouldValidate: true });
            setValue('Address1', props.patient.address1 || '', { shouldValidate: true });
            setValue('Address2', props.patient.address2 || '', { shouldValidate: true });
            setValue('City', props.patient?.city?.toString() || '', { shouldValidate: true });
            setValue('PoBox', props.patient.poBox || '', { shouldValidate: true });
            setValue('ContactNo', props.patient.contactNo || '', { shouldValidate: true });
            setValue('EmailId', props.patient.email || '', { shouldValidate: true });
            setValue('gender', props.patient.gender == 0 ? '1' : props.patient.gender?.toString(), { shouldValidate: true });
            setValue('kgender', props.patient.gender == 0 ? '1' : props.patient.kinGender?.toString(), { shouldValidate: true });
            setValue('kContactNo', props.patient.kinContactNo || '', { shouldValidate: true });
            setValue('kFullName', props.patient.kinName || '', { shouldValidate: true });
            setValue('kAddress', props.patient.kinAddress || '', { shouldValidate: true });
            setValue('kRelationship', props.patient?.kinRelationshipId !== null ? props.patient?.kinRelationshipId?.toString() : '', { shouldValidate: true });
            setcityid(props.patient?.city);
            let objCitySmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                extraPayload: {
                    defaultCityId: props.patient?.city || ''
                }
            };
            setCitySmartSearch(objCitySmartSearch);
        } else {
            reset();
        }
    }, [props.patient]);

    useEffect(() => {
        props.isValid(formState.isValid);
    }, [formState]);

    useEffect(() => {
        if (props.sadd !== undefined) {
            setValue('Address1', props.sadd.addressLine1 || '');
            setValue('Address2', props.sadd.addressLine2 || '');
            setValue('City', props.sadd?.countyId?.toString() || '');
            setValue('PoBox', props.sadd.postalCode || '');
            setValue('ContactNo', props.sadd.phoneNo || '');
            setValue('EmailId', props.sadd.email || '');
            setcityid(props.sadd?.countyId);
            setCitySmartSearch(undefined);
            setTimeout(() => {
                let objCitySmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                    extraPayload: {
                        defaultCityId: props.sadd?.countyId || ''
                    }
                };
                setCitySmartSearch(objCitySmartSearch);
            }, 100);
        } else {
            setValue('Address1', '');
            setValue('Address2', '');
            setValue('City', '');
            setValue('PoBox', '');
            setValue('ContactNo', '');
            setValue('EmailId', '');
        }
    }, [props.sadd]);

    const updatecdata = (e: any) => {};

    useEffect(() => {
        if (props.clearcity !== undefined) {
            setcityid(undefined);
        }
        if (props.clearrel !== undefined) {
            setdefaultrelationshipid(undefined);
        }
    }, [props.clearcity, props.clearrel]);

    useEffect(() => {
        let objCitySmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
            extraPayload: {
                defaultCityId: ''
            }
        };
        setCitySmartSearch(objCitySmartSearch);
        let objRelationshipSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRelationshipList}`,
            extraPayload: {
                genderId: getValues('gender') ? getValues('gender') : 1
            }
        };
        setRelationshipSmartSearch(objRelationshipSmartSearch);
    }, []);

    const updatekData = (e: any) => {
        let objRelationshipSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRelationshipList}`,
            extraPayload: {
                genderId: Number(e.target.value)
            }
        };
        setRelationshipSmartSearch(objRelationshipSmartSearch);
        props.updatekData(e);
    };

    useEffect(() => {
        async function getInternalLocalId() {
            const response = await getInternalLocalConfigData(DataServiceDetail.serviceId);
            if (response.status === true && response.data != null) {
                if (response.data?.isInternalLocalIdDisplay) {
                    setDisplayInternalLocalId(true);
                } else {
                    setDisplayInternalLocalId(false);
                }
            }
        }
        getInternalLocalId();
    }, []);

    return (
        <div>
            <form>
                <div className="d-flex justify-content-between">
                    <input
                        type="text"
                        {...register('NhsId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            },
                            onBlur: (e: any) => {
                                props.getpatientbynhs(e);
                            }
                        })}
                        name={'NhsId' as const}
                        //@ts-ignore
                        disabled={
                            (props.isedit === 'edit' && props.draftData?.nhsId !== '' && props.hasLeadPatientnhsId) ||
                            (props.draftData?.nhsId !== '' && props.leadPoolingId != null && props.leadPoolingId > 0 && props.hasLeadPatientnhsId) ||
                            (props.leadPoolingId != null && props.leadPoolingId > 0 && props.patient?.nhsId != undefined && props.patient?.nhsId != '' && props.hasLeadPatientnhsId)
                                ? true
                                : false
                        }
                        className={`form-control white mb-2 me-2 ${errors.NhsId ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.nhs')}`}
                    />
                    <p className="mt-2 nowrap">- or - </p>
                    <input
                        type="text"
                        {...register('LocalId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            },
                            onBlur: (e: any) => {
                                props.getpatientbynhs(e);
                            }
                        })}
                        name={'LocalId' as const}
                        //@ts-ignore
                        disabled={
                            (props.isedit === 'edit' && props.draftData?.localId !== '' && props.hasLeadPatientlocalId) ||
                            (props.draftData?.localId !== '' && props.leadPoolingId != null && props.leadPoolingId > 0 && props.hasLeadPatientlocalId) ||
                            (props.leadPoolingId != null && props.leadPoolingId > 0 && props.patient?.localId != undefined && props.patient?.localId != '' && props.hasLeadPatientlocalId)
                                ? true
                                : false
                        }
                        className={`form-control white mb-2 ms-2 ${errors.LocalId ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.local')}`}
                    />
                </div>
                {DisplayInternalLocalId && (
                    <input
                        type="text"
                        {...register('internalLocalId')}
                        name={'internalLocalId' as const}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        disabled={true}
                        style={{ pointerEvents: props.isedit == 'edit' ? 'none' : 'all' }}
                        placeholder={`${t('VacantRightbar.internalLocalId')}`}
                    />
                )}
                <input
                    type="text"
                    {...register('FullName', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        },
                        onBlur: (e: any) => {
                            props.checkSelfFunder(e);
                         }
                    })}
                    name={'FullName' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.FullName ? 'is-invalid' : ''}`}
                    disabled={props.isedit === 'edit' || props.patientExist ? true : false}
                    style={{ pointerEvents: props.isedit !== 'edit' || !props.patientExist ? 'all' : 'none' }}
                    placeholder={`${t('VacantRightbar.fullname')}`}
                />
                <div className="input-group date mb-2 datepicker-input-css" id="datepicker">
                    <Controller
                        control={control}
                        name="dob"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return (
                                <DatePicker
                                    className={`form-control white ${error ? 'is-invalid' : ''}`}
                                    label={t('VacantRightbar.dob')}
                                    value={dob}
                                    format="DD/MM/YYYY"
                                    {...fieldProps}
                                    onChange={(date: any) => {
                                        fieldProps.onChange(date);
                                        setdob(date);
                                        props.getDate(date);
                                    }}
                                    disabled={props.patientExist ? true : false}
                                    disableFuture
                                    views={['year', 'month', 'day']}
                                    slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                />
                            );
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between">
                    <label className="mb-2">{t('VacantRightbar.gender')}</label>
                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            type="radio"
                            {...register('gender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gender' as const}
                            id="male"
                            value="1"
                            defaultChecked={true}
                            onChange={props.updateData}
                        />
                        <span className="ms-1">{t('VacantRightbar.male')}</span>
                    </label>

                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            type="radio"
                            {...register('gender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                    
                                }
                            })}
                            name={'gender' as const}
                            id="female"
                            value="2"
                            onChange={props.updateData}
                        />
                        <span className="ms-1">{t('VacantRightbar.female')}</span>
                    </label>

                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            type="radio"
                            {...register('gender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gender' as const}
                            id="other"
                            value="3"
                            onChange={props.updateData}
                        />
                        <span className="ms-1"> {t('VacantRightbar.other')}</span>
                    </label>
                </div>

                <div className="form-check">
                    <input
                        {...register('fCheck', {
                            onChange: (e: any) => {
                                props.updateAdd(e);
                            }
                        })}
                        name={'fCheck' as const}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkboxsameas"
                        checked={props.ischeck ? true : false}
                    />
                    <label className="form-check-label">{t('VacantRightbar.sameadd')}</label>
                </div>

                <input
                    type="text"
                    {...register('Address1', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        },
                        onBlur: (e: any) => {
                            props.checkSelfFunder(e);
                         }
                    })}
                    name={'Address1' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.Address1 ? 'is-invalid' : ''}`}
                    placeholder={`${t('VacantRightbar.add1')}`}
                />
                <input
                    type="text"
                    {...register('Address2', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'Address2' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.Address2 ? 'is-invalid' : ''}`}
                    placeholder={`${t('VacantRightbar.add2')}`}
                />
                <div className="d-flex justify-content-between">
                    {/* <select
                        {...register('City', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'City' as const}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.City ? 'is-invalid' : ''}`}
                        id="city"
                    >
                        <option value="">{t('VacantRightbar.county')}</option>
                        {props.citylist &&
                            props.citylist.data.map((cities: any) => {
                                return (
                                    <option key={cities.key} value={cities.key}>
                                        {cities.value}
                                    </option>
                                );
                            })}
                    </select> */}
                    {citySmartSearch !== undefined && (
                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                            <SmartSearchDropdownContainer
                                defaultValue={cityid}
                                smartSearchLabel={t('VacantRightbar.county')}
                                errors={errors.City}
                                onChangeSmartSearch={(value: any) => {
                                    const event = new Event('change');
                                    let obj = {
                                        name: 'City',
                                        value: value?.id?.toString()
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });
                                    setValue('City', value?.id?.toString(), { shouldValidate: true });
                                    if (value !== '') {
                                        props.updatesmartsearchData(event);
                                        //props.checkSelfFunder(event);
                                    }
                                    else{
                                        setcityid(undefined);
                                        let objCitySmartSearch = {
                                            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                                            extraPayload: {
                                                defaultCityId: ''
                                            }
                                        };
                                        setCitySmartSearch(objCitySmartSearch);
                                    }
                                }}
                                onBlurSmartSearch={(value: any) => {                                    
                                    const event = new Event('blur');
                                    let obj = {
                                        name: 'City',
                                        value: value?.id?.toString()
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });                                    
                                    if (value !== undefined && value !== '') {                                        
                                        props.checkSelfFunder(event);
                                    }
                                    else{
                                        setcityid(undefined);
                                        let objCitySmartSearch = {
                                            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                                            extraPayload: {
                                                defaultCityId: ''
                                            }
                                        };
                                        setCitySmartSearch(objCitySmartSearch);
                                    }
                                }

                                }
                                smartSearch={citySmartSearch}
                            />
                        </div>
                    )}
                    <input
                        type="text"
                        {...register('PoBox', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            },
                            onBlur: (e: any) => {
                                props.checkSelfFunder(e);
                            }
                        })}
                        name={'PoBox' as const}
                        //@ts-ignore
                        className={`form-control white mb-2 ms-2 ${errors.PoBox ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.postal')}`}
                    />
                </div>
                <div className="d-flex justify-content-between">
                    <input
                        type="text"
                        {...register('ContactNo', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            },
                            onBlur: (e: any) => {
                                props.checkSelfFunder(e);
                            }
                        })}
                        name={'ContactNo' as const}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.ContactNo ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.contact')}`}
                    />
                </div>
                <input
                    type="text"
                    {...register('EmailId', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        },
                        onBlur: (e: any) => {
                            props.checkSelfFunder(e);
                        }
                    })}
                    name={'EmailId' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.EmailId ? 'is-invalid' : ''}`}
                    placeholder={`${t('VacantRightbar.email')}`}
                />

                <div className="mb-2">
                    <label>{t('VacantRightbar.next')}</label>
                </div>

                <input
                    type="text"
                    {...register('kFullName', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'kFullName' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.kFullName ? 'is-invalid' : ''}`}
                    // disabled={props.isedit === 'edit' ? true : false}
                    // style={{ pointerEvents: props.isedit !== 'edit' ? 'all' : 'none' }}
                    placeholder={`${t('VacantRightbar.kname')}`}
                />

                <div className="d-flex justify-content-between">
                    <label className="mb-2">{t('VacantRightbar.gender_type')}</label>
                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            type="radio"
                            {...register('kgender', {
                                onChange: (e: any) => {
                                    updatekData(e);
                                }
                            })}
                            name={'kgender' as const}
                            id="male"
                            value="1"
                            defaultChecked={true}
                        />
                        <span className="ms-1">{t('VacantRightbar.male')}</span>
                    </label>

                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            type="radio"
                            {...register('kgender', {
                                onChange: (e: any) => {
                                    updatekData(e);
                                }
                            })}
                            name={'kgender' as const}
                            id="female"
                            value="2"
                        />
                        <span className="ms-1">{t('VacantRightbar.female')}</span>
                    </label>

                    <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input
                            type="radio"
                            {...register('kgender', {
                                onChange: (e: any) => {
                                    updatekData(e);
                                }
                            })}
                            name={'kgender' as const}
                            id="other"
                            value="3"
                        />
                        <span className="ms-1">{t('VacantRightbar.other')}</span>
                    </label>
                </div>
                {/* <select
                        {...register('kRelationship', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'kRelationship' as const}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.kRelationship ? 'is-invalid' : ''}`}
                        id="relationship"
                    >
                        <option value="">{t('VacantRightbar.relationship')}</option>
                        {RelationshipData &&
                            RelationshipData.map((relationship: any) => {
                                return (
                                    <option key={relationship.relationshipId} value={relationship.relationshipId}>
                                        {relationship.relationshipName}
                                    </option>
                                );
                            })}
                    </select> */}
                {relationshipSmartSearch !== undefined && (
                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                        <SmartSearchDropdownContainer
                            smartSearchLabel={t('VacantRightbar.relationship')}
                            defaultValue={defaultrelationshipid}
                            errors={errors.kRelationship}
                            onChangeSmartSearch={(value: any) => {
                                const event = new Event('change');
                                let obj = {
                                    name: 'kRelationship',
                                    value: value?.id?.toString()
                                };
                                //@ts-ignore
                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                setValue('kRelationship', value?.id?.toString(), { shouldValidate: true });
                                if (value !== '') {
                                    props.updatesmartsearchData(event);
                                } else {
                                    setdefaultrelationshipid(undefined);
                                    let objRelationshipSmartSearch = {
                                        url: `${BASEURLDEV}${API_ENDPOINT.GetDDLRelationshipList}`,
                                        extraPayload: {
                                            genderId: getValues('gender')
                                        }
                                    };
                                    setRelationshipSmartSearch(objRelationshipSmartSearch);
                                }
                            }}
                            smartSearch={relationshipSmartSearch}
                        />
                    </div>
                )}
                <div className="d-flex justify-content-between">
                    <input
                        type="text"
                        {...register('kContactNo', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'kContactNo' as const}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.kContactNo ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.contact')}`}
                    />
                </div>
                <input
                    type="text"
                    {...register('kAddress', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'kAddress' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.kAddress ? 'is-invalid' : ''}`}
                    placeholder={`${t('VacantRightbar.add')}`}
                />
            </form>
        </div>
    );
};

export default ServiceUserDetailView;
