import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListReasonsModel } from '../../utils/model';

const initialState: ListReasonsModel = {
    StatusListReasons: false,
    DataListReasons: '',
    MessageListReasons: '',
    ErrorListReasons: ''
};

export const listtReasonsSlice = createSlice({
    name: 'listReasons',
    initialState,
    reducers: {
        setListtReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = true;
            state.DataListReasons = payload;
            state.MessageListReasons = 'Success';
        },
        errorListtReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = [];
            state.MessageListReasons = 'Failed';
            state.ErrorListReasons = payload;
        },
        removeListtReasonsAction: (state, { payload }: PayloadAction) => {
            state.StatusListReasons = false;
            state.DataListReasons = '';
            state.MessageListReasons = '';
            state.ErrorListReasons = '';
        }
    },
    extraReducers: {}
});

export const { setListtReasonsAction, errorListtReasonsAction, removeListtReasonsAction } = listtReasonsSlice.actions;

export default listtReasonsSlice.reducer;
