import React from 'react';
import AmenitiesTabContentView from './AmenitiesTabContentView';

const AmenitiesTabContentContainer = (props:any) => {
    return (
        <div>
            <AmenitiesTabContentView amenities={props?.amenities} selectedTab={props?.selectedTab}/>
        </div>
    );
};

export default AmenitiesTabContentContainer;