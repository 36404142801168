import React from 'react';
import CoordinatorDetailsView from './CoordinatorDetailsView';

const CoordinatorDetailsContainer = (props:any) => {
    return (
        <div>
            <CoordinatorDetailsView serviceCoordinatorDetails={props?.serviceCoordinatorDetails}/>
        </div>
    );
};

export default CoordinatorDetailsContainer;