import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';

const TableGroupByView = (props: any) => {
    const { t } = useTranslation();
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    let [RMGroupByStatus, setRMGroupByStatus] = useState<any>(false);
    let [RMGroupByCurrentStage, setRMGroupByCurrentStage] = useState<any>(false);
    let [RMGroupByServiceType, setRMGroupByServiceType] = useState<any>(false);
    let [count, setCount] = useState<any>(0);

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const onChangeGroupByEvent = (event: any) => {
        if (event.target.checked === true && event.target.name === 'groupByStatus') {
            setRMGroupByStatus(true);
            setCount(count + 1)
        } else if (event.target.checked === false && event.target.name === 'groupByStatus') {
            setRMGroupByStatus(false);
            setCount(count - 1)
        }

        if (event.target.checked === true && event.target.name === 'groupByCurrentStage') {
            setRMGroupByCurrentStage(true);
            setCount(count + 1)
        } else if (event.target.checked === false && event.target.name === 'groupByCurrentStage') {
            setRMGroupByCurrentStage(false);
            setCount(count - 1)
        }

        if (event.target.checked === true && event.target.name === 'groupByServiceType') {
            setRMGroupByServiceType(true);
            setCount(count + 1)
        } else if (event.target.checked === false && event.target.name === 'groupByServiceType') {
            setRMGroupByServiceType(false);
            setCount(count - 1)
        }
    };

    const onClickRemoveAllGroupBy = () => {
        setRMGroupByStatus(false);
        setRMGroupByCurrentStage(false);
        setRMGroupByServiceType(false);
        setCount(0);
        props.onRemoveAllGroupBy(false)
    }

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.grouping?.groupByStatus) {
                setRMGroupByStatus(true);
                if(count === 0){
                    setCount(count + 1)
                }
                let obj: any = {
                    "status": true,
                    "name": 'groupByStatus'
                }
                props.onStateGroupByHandler(obj)
            } 
            // else {
            //     setRMGroupByStatus(false);
            //     setCount(count - 1)
            // }
            if(DataGridFilterState?.grouping?.groupByCurrentStage) {
                setRMGroupByCurrentStage(true);
                if(count === 1){
                setCount(count + 1)
                }
                let obj: any = {
                    "status": true,
                    "name": 'groupByCurrentStage'
                }
                props.onStateGroupByHandler(obj)
            } 
            // else {
            //     setRMGroupByCurrentStage(false);
            //     setCount(count - 1)
            // }
            if(DataGridFilterState?.grouping?.groupByServiceType) {
                setRMGroupByServiceType(true);
                if(count === 2){
                setCount(count + 1)
                }
                let obj: any = {
                    "status": true,
                    "name": 'groupByServiceType'
                }
                props.onStateGroupByHandler(obj)
            } 
            // else {
            //     setRMGroupByServiceType(false);
            //     setCount(count - 1)
            // }
        }
    },[StatusGridFilterState, DataGridFilterState])

    return (
        <div>
            <div className="dropdown filter-dropdown me-2 mb-3">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className=""><i className="fa-solid fa-layer-group"></i> {t('ReferralTable.Group_By')}
                    { count > 0 && <span className='applied-filter'> {RMGroupByStatus === true && <>Status</>} { count > 1 && (RMGroupByStatus === true && RMGroupByCurrentStage === true) && <i className="fa-solid fa-chevron-right"></i>} {RMGroupByCurrentStage === true && <>Current Stage</>} { count > 1 && RMGroupByServiceType === true && <i className="fa-solid fa-chevron-right"></i>} {RMGroupByServiceType === true && <>Service Type</>} <a href='#' className='ms-2'><i className="fa-solid fa-xmark white-text" onClick={onClickRemoveAllGroupBy}></i></a></span> }
                    </div>
                </a>
                <div className="dropdown-menu profile-dw-css" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div>
                        <label>
                            <input type="checkbox" name="groupByStatus" checked={RMGroupByStatus} onChange={(event: any) => {props.onChangeGroupByHandler(event); onChangeGroupByEvent(event)}}  className="me-1" /> {t('ReferralTable.Status')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name="groupByCurrentStage" checked={RMGroupByCurrentStage} onChange={(event: any) => {props.onChangeGroupByHandler(event); onChangeGroupByEvent(event)}} className="me-1" /> {t('ReferralTable.Current_Stage')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name="groupByServiceType" checked={RMGroupByServiceType} onChange={(event: any) => {props.onChangeGroupByHandler(event); onChangeGroupByEvent(event)}} className="me-1" /> {t('ReferralTable.Service_Type')}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableGroupByView;