import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import instance from './axiosinstance';


export const getCommonDropdownList = (url:any, payload: any) => {
    return instance
        .get(`${url}`, {
            params: payload
          })
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};