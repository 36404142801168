import React from 'react';
import CoordinatorDetailsContainer from './CoordinatorDetails/CoordinatorDetailsContainer';
import AboutUsContainer from './AboutUs/AboutUsContainer';

const OverviewDetailsView = (props: any) => {
    return (
        <div className=''>
            <div className='row'>
                <div className='col-lg-6 pe-4'>
                    <CoordinatorDetailsContainer serviceCoordinatorDetails={props?.serviceCoordinatorDetails} />
                </div>
                <div className='col-lg-6 ps-4'>
                    <AboutUsContainer serviceDescription={props?.serviceDescription} serviceCoordinatorDetails={props?.serviceCoordinatorDetails} />
                </div>
            </div>
        </div>
    );
};

export default OverviewDetailsView;