import React from 'react';
import NHSHeaderView from './NHSHeaderView';
import { useDispatch } from "react-redux";
import { ApiResponse } from '../../utils/model';

const NHSHeaderContainer = () => {
    return (
        <div>
            <NHSHeaderView />
        </div>
    );
};

export default NHSHeaderContainer;