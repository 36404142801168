import { AmenitiesListType } from '../../../layout/NHSHeader/ServicetypeHeader/SearchFilter/types';

interface AmenitiesContainerProps {
    amenitiesList: AmenitiesListType[];
    checkedAmenities: AmenitiesListType[];
    setCheckedAminities: React.Dispatch<React.SetStateAction<AmenitiesListType[]>>
}

const AmenitiesFilterView = (props: AmenitiesContainerProps) => {
    const { amenitiesList, checkedAmenities, setCheckedAminities } = props;

    const handleOnCheck = (list: AmenitiesListType) => {
        const isItemExist = checkedAmenities.some((Elist) => Elist.id === list.id);
        if (!isItemExist) {
            setCheckedAminities([...checkedAmenities, list]);
        } else {
            setCheckedAminities(checkedAmenities.filter((flist) => flist.id !== list.id));
        }
    }

    return (
        <div>
            <ul className={`list-unstyled amenities-modal-list ${amenitiesList.length > 9 ? 'more-than-nine' : ''}`}>
                {amenitiesList.map((list) => (
                    <li key={list.id} className='cursor-pointer w-max' onClick={() => handleOnCheck(list)}>
                        <div className='d-flex align-items-center gap-2'>
                            <input 
                                type="checkbox" 
                                checked={checkedAmenities.some((Elist) => Elist.id === list.id)}
                                className="form-check-input filter-input me-2" 
                            />
                            <div className='ms-2'>
                                <img src={list.amenityIcon} alt={"list-icon"} style={{ width: "20px", height: "20px" }} />
                            </div>
                            {list.name}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AmenitiesFilterView;