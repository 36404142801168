import { DatePicker } from 'antd';
import ModalWrapperContainer from "../ModalWrapper/indexContainer";
import { useTranslation } from "react-i18next";
import WYSIWYGEditor from '../WYSIWYGEditor/WYSIWYGEditor';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { getScheduleAssessmentData, getScheduleAssessmentRecData, sendScheduleAssessmentEmail } from '../../service/leads';
import dayjs from 'dayjs';
import { Autocomplete, Chip, TextField } from '@mui/material';
import swal from 'sweetalert';
import moment from 'moment';
import LoaderContainer from '../Loader/loaderContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';



const initialValues = {
    date: null,
    subject: "",
    attendees: [],
    location: "",
    body: ""
}

export const scheduleAssessmentSchema = Yup.object().shape({
    date: Yup.string().required("Please select date and time"),
    subject: Yup.string().required("Please enter subject"),
    attendees: Yup.array().min(1, "Minimum 1 attendees required").of(Yup.object().shape({
        name: Yup.string().notRequired(),
        email: Yup.string(),
    })).required("Please enter attendees"),
    location: Yup.string().required("Please enter location"),
    body: Yup.string().required("Please enter body"),
});

const ScheduleAssessmentModalView = (props: any) => {
    const { t } = useTranslation();
    const { Data } = useSelector((state: RootState) => state.navigation);

    const [initialBody, setInitialBody] = useState<string>("");
    const [tempOptions, setTempOptions] = useState<{ email: string, name: string }[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [buttonType, setButtonType] = useState("edit");
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [attendiesValue, setAttendiesValue] = useState<{ email: string, name: string }[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        watch,
        setValue,
        control,
        trigger,
    } = useForm<any>({
        defaultValues: initialValues,
        resolver: yupResolver(scheduleAssessmentSchema)
    })
    const scheduleAssessmentSubmit = async (data: any) => {
        let formatedscheduledDateTime = data.date ? moment(data.date).format() : null
        let scheduledDateTimePayload = formatedscheduledDateTime?.replace(/(\+\d{2}:\d{2})$/,"+00:00")
        const payload = {
            leadPoolingId: props.poolingId,
            attendees: data.attendees,
            agenda: data.body,
            location: data.location,
            scheduledDateTime : scheduledDateTimePayload,
            subject: data.subject
        };
        const response = await sendScheduleAssessmentEmail(payload, setIsLoading);
        if (response.status === true) {
            props.onClose();
            swal(response.message, {
                icon: 'success'
            });

            props?.refreshAfterReschedule && props?.refreshAfterReschedule();
            setButtonType("edit");
        } else {
            swal(response.message, {
                icon: 'error',
                dangerMode: true
            });
        }
    }

    const getData = async () => {
        const assessmentData = await getScheduleAssessmentData(props.poolingId);

        if (assessmentData.status) {
            setValue("subject", assessmentData.data.subject, { shouldValidate: true });
            setValue("location", assessmentData.data.location, { shouldValidate: true });

            if (assessmentData?.data?.agenda && assessmentData?.data?.location) {
                setInitialBody(assessmentData.data.agenda);
                setValue("body", assessmentData.data.agenda, { shouldValidate: true });
                setValue("date", dayjs(assessmentData.data.date), { shouldValidate: true });

                if (assessmentData?.data?.invitees && Array.isArray(assessmentData?.data?.invitees)) {
                    setValue("attendees", assessmentData?.data?.invitees, { shouldValidate: true })
                }

                setIsEditing(true);
            } else {
                setValue("date", dayjs(assessmentData.data.date, "DD-MM-YYYY hh:mm a"), { shouldValidate: true });
            }
        }
    }

    const validateEmail = (email: any) => {
        var re = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email?.toLowerCase());
    };

    const handleAttendeesChange = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const assessmentData = await getScheduleAssessmentRecData(e.target.value);
        setSearchValue(e.target.value);
        if (assessmentData?.data?.length > 0) {
            setTempOptions(assessmentData.data);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const getAttendiesData = async () => {
        setLoading(true);
        const assessmentData = await getScheduleAssessmentRecData("");
        if (assessmentData?.data?.length > 0) {
            setTempOptions(assessmentData.data);
        } else {
            setTempOptions([]);
        }
        setLoading(false);
    }

    const handleDateTimeChange = async (e:any,dateString:any)=>{   
             
        let today = moment(new Date()).format("DD/MM/YYYY hh:mm a");
        let serverTimezoneOffsetMinutes = new Date().getTimezoneOffset();
        let serverTimezoneOffsetHours = Math.floor(serverTimezoneOffsetMinutes / 60)
        let serverTimezoneOffsetMinutesRemainder = serverTimezoneOffsetMinutes % 60
        if(serverTimezoneOffsetMinutesRemainder < 60){
            serverTimezoneOffsetHours += 1
        }
        if(dateString.toString() === today.toString()){
           
            setValue("date",dayjs(e).add(serverTimezoneOffsetHours,'hour').add(serverTimezoneOffsetMinutesRemainder,'minute'));
        }
        else{
            if (e) {
                setValue("date",e);
            }
        }    
    }

    return (
        <div>
            {isLoading && <LoaderContainer />}
            <ModalWrapperContainer
                title={`Schedule Assessment for ${Data?.patientname}`}
                onClose={() => {
                    props.onClose()
                    props.setIsViewMode && props.setIsViewMode(true)
                }}
                className="modal modal-medium schedule-assessment-modal"
                content={
                        <>
                        <p className='mb-2 label'>{t("ScheduleAssessment.dateNTime")}</p>
                        <DatePicker
                            showTime
                            showSecond={false}
                            format="DD/MM/YYYY hh:mm a"
                            className='form-control mb-4 antd-date-picker'
                            onChange={handleDateTimeChange}
                            value={watch("date")}
                            onBlur={() => trigger("date")}
                            disabled={!props.allowEdit || (isEditing && buttonType === "edit")}
                            disabledDate={(current) => current.isBefore(moment().subtract(1, "day") as any)}
                        />

                        <p className='mb-2 label'>{t("ScheduleAssessment.subject")}</p>
                        <input
                            type="text"
                            placeholder={`${t('ScheduleAssessment.subject')}*`}
                            className={`form-control white mb-4`}
                            {...register("subject")}
                            onBlur={() => trigger("subject")}
                            disabled={!props.allowEdit || (isEditing && buttonType === "edit")}
                        />

                        <p className='mb-2 label'>{t("ScheduleAssessment.attendees")}</p>
                        <div className="input-group multiselect-recipients-dropdown">
                            <Controller
                                control={control}
                                name="attendees"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <Autocomplete
                                            onKeyDown={(event) => {
                                                if (event.key === 'Tab') {
                                                    event.key = 'Enter';
                                                }

                                                if (['Enter', 'Tab'].includes(event.key) && !validateEmail(searchValue)) {
                                                    setErrorMessage(t('ScheduleAssessment.invalidEmail'));
                                                    event.key = '';
                                                    event.preventDefault();
                                                }
                                            }}
                                            multiple
                                            className="tempMUIInput"
                                            id="tags-filled"
                                            options={tempOptions}
                                            getOptionLabel={(option: any) => option.name || option}
                                            freeSolo
                                            renderTags={(tagValue: any, getTagProps) =>
                                                tagValue.map((option: any, index: any) => <Chip variant="outlined" data-tooltip-id="my-tooltip-email" data-tooltip-content={option.email} label={option.name || option.email} {...getTagProps({ index })} />)
                                            }
                                            ListboxProps={{
                                                sx: { fontSize: 13, fontFamily: 'Roboto' },
                                            }}
                                            loading={loading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    value={searchValue}
                                                    variant="filled"
                                                    placeholder={attendiesValue.length > 0 ? "" : 'Add attendees to schedule...'}
                                                    onChange={(e) => handleAttendeesChange(e)}
                                                    error={errors?.attendees?.message ? true : false}
                                                    onFocus={() => {
                                                        getAttendiesData();
                                                    }}
                                                />
                                            )}
                                            value={watch("attendees")}
                                            onChange={(e, value, situation, option) => {
                                                if (situation === "createOption" && !validateEmail(option?.option)) {
                                                    return;
                                                }

                                                setSearchValue("");

                                                
                                                const newValues: any = [];
                                                value.forEach((item: any) => {
                                                    if (typeof item === "string") {
                                                        if (!newValues.some((e: any) => e.email === item)) {
                                                            newValues.push({
                                                                email: item
                                                            })
                                                            if (!validateEmail(item)) {
                                                                setErrorMessage(t('ScheduleAssessment.invalidEmail'));
                                                            } else {
                                                                setErrorMessage(null);
                                                            }
                                                        } else {
                                                            setErrorMessage(t('ScheduleAssessment.attendiesError'));
                                                        }
                                                    } else {
                                                        if (!newValues.some((val: any) => val.email === item.email)) {
                                                            newValues.push(item);
                                                            setErrorMessage(null);
                                                        } else {
                                                            setErrorMessage(t('ScheduleAssessment.attendiesError'));
                                                        }
                                                    }
                                                })
                                                setAttendiesValue(newValues)
                                                setValue('attendees', newValues, { shouldValidate: true });
                                            }}
                                            disabled={!props.allowEdit || (isEditing && buttonType === "edit")}
                                            defaultValue={watch("attendees")?.length > 0 || watch("attendees") !== undefined ? watch("attendees") : []}
                                        />
                                    );
                                }}
                            />
                            {errorMessage && <span className="error-message ps-0 mt-2">{errorMessage}</span>}
                            {!errorMessage && <span className="error-message ps-0 mt-2">{errors?.attendees?.message} </span>}
                        </div>

                        <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-email" />

                        <p className='mb-2 label'>{t("ScheduleAssessment.agenda")}</p>
                        <WYSIWYGEditor
                            onChange={(content: any) => {
                                setValue('body', content?.split('<p></p>')?.join('')?.trim(), { shouldValidate: true })
                            }}
                            initialContent={initialBody}
                            readOnly={!props.allowEdit || (isEditing && buttonType === "edit")}
                            placeholder={`${t('ScheduleAssessment.agendaPlaceHolder')}*`}
                        />

                        <p className='mb-2 mt-4 label'>{t("ScheduleAssessment.location")}</p>
                        <textarea
                            placeholder={`${t('ScheduleAssessment.location')}*`}
                            className={`form-control white attendees mb-2`}
                            rows={4}
                            {...register("location")}
                            onBlur={() => trigger("location")}
                            disabled={!props.allowEdit || (isEditing && buttonType === "edit")}
                        ></textarea>
                        </>
                }

                footer={
                    <form onSubmit={handleSubmit(scheduleAssessmentSubmit)}>
                    {props.allowEdit && <div className='w-full text-center'>
                    {isEditing && buttonType === "edit" ? (
                        <button type='button' onClick={(e) => { e.preventDefault(); setButtonType("reschedule") }} className='btn btn-primary mt-1 px-5'>
                            {t('ScheduleAssessment.edit')}
                        </button>
                    ) : (
                        <button type='submit' disabled={!isValid} className='btn btn-primary mt-1 px-5'>
                            {buttonType === "reschedule" ? t('ScheduleAssessment.reschedule') : t('ScheduleAssessment.send')}
                        </button>
                    )}
                </div>}
                </form>
                  }
            />
        </div>
    );
}

export default ScheduleAssessmentModalView;