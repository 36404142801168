import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { store } from '../../../../../../store';
import warning from '../../../../../../assets/images/warning.png';
import { useDispatch, useSelector } from 'react-redux';
import { removePatientDraftAction } from '../../../../../../store/dashboard/draftPatientData';
import { getPatientReservedData, getPlanData } from '../../../../../../service/dashboard-service';
import { rightNavigationPage } from '../../../../../../utils/constant';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import { RootState } from '../../../../../../store';
import { setTempSplitFundingAction, removeTempSplitFundingAction } from '../../../../../../store/splitFunding/tempSplitFundingReducer';
import { setTempSplitFundingAction1, removeTempSplitFundingAction1 } from '../../../../../../store/splitFunding/tempSplitFundingReducer1';
import { removeSplitFundingAction, setSplitFundingAction } from '../../../../../../store/splitFunding/splitFundingReducer';
import { removePlanDataAction } from '../../../../../../store/splitFunding/planDataReducer';
import { getContractDataAdmission } from '../../../../../../service/contract-service';
import { removeLeadInformationAction, setLeadInformationAction } from '../../../../../../store/lead/getLeaddataReducer';
import eventEmitter from '../../../../../../utils/eventEmitter';

const AdmittedTabDeatil = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let [plan, setplan] = useState<any>([]);
    const { TempDataSplitFunding, StatusTempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { TempDataSplitFunding1 } = useSelector((state: RootState) => state.tempSplitFunding1);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    let plans: any = [];
    let funders: any = [];

    async function getpatientdata(pid: any) {
        const payload: any = { id: pid };
        await getPatientReservedData(payload);
    }

    async function getplandata(aid:any,bedId:any) { 
        dispatch(removePlanDataAction());
        await getPlanData(aid).then((response: any) => {
            if (response.status == true) {
                plans = [];
                for (let j = 0; j < response.data.length; j++) {
                    if (response.data[j].planType !== 0) {
                        let obj: any = {};
                        funders = [];
                        obj.corefee = response?.data[j]?.weeklyCharge;
                        obj.admissiondate = response?.data[j]?.startDate?.toString();
                        obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                        obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                        obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                        obj.planId = response?.data[j]?.planId;
                        obj.lastinvoicedate = response?.data[j]?.lastInvoiceCreatedDate?.toString();
                        obj.dailyCoreFee = Number(response?.data[j]?.calculatedDailyCoreFee);
                        obj.continenceCareFee = response?.data[j]?.continenceCareFee;
                        obj.fncFee = response?.data[j]?.fncFee;
                        obj.acontinenceCareFee = response?.data[j]?.continenceCareFee;
                        obj.afncFee = response?.data[j]?.fncFee;
                        obj.continenceCareFeeType= response?.data[j]?.continenceCareFeeType;
                        obj.fncFeeType= response?.data[j]?.fncFeeType;
                        obj.totalFees = response?.data[j]?.totalFees;
                        obj.totalAdditionalServiceFee = response?.data[j]?.totalAdditionalServiceFee;

                        for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                            funders.push({
                                planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                                funderid: response?.data[j]?.planDetails[i].funderId,
                                fundername: response?.data[j]?.planDetails[i].funderName,
                                fundertype:
                                    response?.data[j]?.planDetails[i].funderType === 1
                                        ? 'ICB'
                                        : response?.data[j]?.planDetails[i].funderType === 2
                                        ? 'LA'
                                        : response?.data[j]?.planDetails[i].funderType === 3
                                        ? 'Charity'
                                        : 'Self',
                                proportion: response?.data[j]?.planDetails[i].proportion,
                                proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                                isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                                isautopay: response?.data[j]?.planDetails[i].isAutoPay === 1 ? false : true,
                                ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                            });

                            obj.funderlist = funders;
                        }
                        plans = [...plans, obj];
                    }
                }
                //    setTimeout(() => {
                    dispatch(setTempSplitFundingAction(plans)); 
                    const data={
                        isDraftAdd : "edit",
                        section:'service',
                        bedId : bedId
                    }
                    let obj: any = {
                        ShowClass: true,
                        FormName: rightNavigationPage.Vacant,
                        Data: data,
                        ShowToggle:false
                    };
                       dispatch(setNavigationAction(obj));
                    
                //    }, 2500);
            }
        });
    }

    const editHandler = (pid: any, aid: any, bedId: any) => {
        swal({
            title: '',
            text:
                props.patientData?.bedName != undefined
                    ? `Are you sure you want to update the patient admission information in Bed ${props.patientData.bedName}?`
                    : `Are you sure you want to update the patient admission information?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                dispatch(removePatientDraftAction());
                getpatientdata(pid).then((res:any)=>{
                //  const data={
                //      isDraftAdd : "edit",
                //      section:'service',
                //      bedId : bedId
                //  }
                //  let obj: any = {
                //      ShowClass: true,
                //      FormName: rightNavigationPage.Vacant,
                //      Data: data,
                //      ShowToggle:false
                //  };
                //     dispatch(setNavigationAction(obj));
                    // setTimeout(() => {
                        getplandata(aid,bedId);
                    // }, 500);
                  
                })
               
                dispatch(removeLeadInformationAction());
                eventEmitter.emit('onAdmitReservePatient');
                const res = await getContractDataAdmission(aid);
                if (res.status) {
                    dispatch(setLeadInformationAction(res));
                }
            }
        });
    };

    return (
        <div className="accordion accordion-flush" id="user-service-ac">
            <div className="accordion-item">
                <h2 className="accordion-header" id="admission-details">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#admission-detail" aria-expanded="false" aria-controls="admission-detail">
                        {t('OccupiedRightBar.Admission_Deatil')}
                    </button>
                </h2>
                <div id="admission-detail" className="accordion-collapse collapse show" aria-labelledby="admission-details" data-bs-parent="#user-service-ac">
                    <div className="accordion-body">
                        <div>
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.admissionDate !== null &&
                                props.patientData.admissionDetails.admissionDate !== undefined &&
                                props.patientData.admissionDetails.admissionDate !== '' && (
                                    <p>
                                        {t('OccupiedRightBar.Date_of_Admission')}: <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.patientData.admissionDetails.admissionDate)}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.los !== null &&
                                props.patientData.admissionDetails.los !== undefined &&
                                props.patientData.admissionDetails.los !== '' && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Length_of_Stay')}: <span className="bold">{`${props.patientData.admissionDetails.los} Days`}</span>
                                    </p>
                                )}
                            <div className="d-flex justify-content-between align-items-center mt-1">
                                {props.patientData !== '' &&
                                    props.patientData.admissionDetails.estDischargeDate !== null &&
                                    props.patientData.admissionDetails.estDischargeDate !== undefined &&
                                    props.patientData.admissionDetails.estDischargeDate !== '' && (
                                        <p>
                                            {t('OccupiedRightBar.Est_Discharge_Date')}:{' '}
                                            <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.patientData.admissionDetails.estDischargeDate)}</span>
                                        </p>
                                    )}
                                {/* <a href="#" className="btn-icon gray-background" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </a> */}
                            </div>
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.patientType !== null &&
                                props.patientData.admissionDetails.patientType !== undefined &&
                                props.patientData.admissionDetails.patientType !== '' && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Patient_Type')}: <span className="bold">{`${props.patientData.admissionDetails.patientType}`}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.admittedBy !== null &&
                                props.patientData.admissionDetails.admittedBy !== undefined &&
                                props.patientData.admissionDetails.admittedBy !== '' && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Admitted_By')}: <span className="bold">{`${props.patientData.admissionDetails.admittedBy}`}</span>
                                    </p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="service-user-details">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-user-detail"
                        aria-expanded="false"
                        aria-controls="service-user-detail"
                    >
                        {t('OccupiedRightBar.Service_User_Detail')}
                    </button>
                </h2>
                <div id="service-user-detail" className="accordion-collapse collapse" aria-labelledby="service-user-detail" data-bs-parent="#user-service-ac">
                    <div className="accordion-body">
                        <div>
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.nhsId !== null &&
                                props.patientData.serviceUserDetails.nhsId !== undefined &&
                                props.patientData.serviceUserDetails.nhsId !== '' && (
                                    <p>
                                        {t('OccupiedRightBar.NHS_ID')}: <span className="bold">{props.patientData.serviceUserDetails.nhsId}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.localId !== null &&
                                props.patientData.serviceUserDetails.localId !== undefined &&
                                props.patientData.serviceUserDetails.localId !== '' && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Client_ID')}: <span className="bold">{props.patientData.serviceUserDetails.localId}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.dateOfBirth !== null &&
                                props.patientData.serviceUserDetails.dateOfBirth !== undefined &&
                                props.patientData.serviceUserDetails.dateOfBirth !== '' && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Date_of_Birth')}: <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.patientData.serviceUserDetails.dateOfBirth)}</span>
                                    </p>
                                )}
                            {/* {props.patientData !== '' && ((props.patientData.serviceUserDetails.address1 !== undefined && props.patientData.serviceUserDetails.address1 !== '' && props.patientData.serviceUserDetails.address1 !== null) ||
                                (props.patientData.serviceUserDetails.address2 !== undefined && props.patientData.serviceUserDetails.address2 !== '' && props.patientData.serviceUserDetails.address2 !== null) ||
                                (props.patientData.serviceUserDetails.city !== undefined && props.patientData.serviceUserDetails.city !== '' && props.patientData.serviceUserDetails.city !== null)) && (
                                <p className="mt-1">
                                    {t('OccupiedRightBar.Address')}:{' '}
                                    <span className="bold">
                                        {`${props.patientData.serviceUserDetails.address1 === null ? '': props.patientData.serviceUserDetails.address1}` || ''}{`, ${props.patientData.serviceUserDetails.address2 === null ? '': props.patientData.serviceUserDetails.address2}` || ''}
                                        {`${props.patientData.serviceUserDetails.city === null ? '': `, ${props.patientData.serviceUserDetails.city}.`}` || '.'}
                                    </span>
                                </p>
                            )} */}
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails.patientAddress !== undefined &&
                                props.patientData.serviceUserDetails.patientAddress !== '' &&
                                props.patientData.serviceUserDetails.patientAddress !== null && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Address')}: <span className="bold">{props.patientData?.serviceUserDetails?.patientAddress || ''}</span>
                                    </p>
                                )}

                            {/* <p className="mt-1">
                                {t('OccupiedRightBar.Next_of_Kin')}: <span className="bold">Margaret s (Wife)</span>
                            </p> */}
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.contactNo !== null &&
                                props.patientData.serviceUserDetails.contactNo !== undefined &&
                                props.patientData.serviceUserDetails.contactNo !== '' && (
                                    <p className="mt-1">
                                        {t('OccupiedRightBar.Contact_Info')}: <span className="bold">{props.patientData.serviceUserDetails.contactNo}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.admissionDetails.email !== null &&
                                props.patientData.serviceUserDetails.email !== undefined &&
                                props.patientData.serviceUserDetails.email !== '' && (
                                    <p>
                                        {t('OccupiedRightBar.Email')}: <span className="bold">{props.patientData.serviceUserDetails.email}</span>
                                    </p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {DataServiceDetail?.iqviaServiceEnabled === false && (
                <div className="mt-2 text-center  right_content_btn">
                    <button
                        onClick={() => editHandler(props.patientData.serviceUserDetails.patientId, props.patientData.admissionId, props.patientData.bedId)}
                        className="btn btn-secondary flex-1 me-2"
                        id="save-funder1"
                    >
                        {' '}
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
};

export default AdmittedTabDeatil;
