import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const AssignmentView = (props: any) => {
    const { t } = useTranslation();    
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { isNHSReferralTracker } = useSelector((state: RootState) => state.nhsLogin);
    return (
        <div>
            <div className='status-box'>
                <p className='bold mb-2'>{props?.assignment?.serviceName}</p>               
                {props?.assignment?.referralLogList?.length > 0 && props?.assignment?.referralLogList.map((logdata: any, index: any) => {
                    return <p key={index} className='mt-1'><i>{logdata.logMessage}</i></p>
                })}
                {!props.nhs && !isNHSReferralTracker &&
                <>
                <div className='text-center'>                    
                    {/* <button disabled={props?.isSubmited} onClick={props?.submitalert} className='btn btn-primary btn-sm mt-3' data-tooltip-id="my-tooltip-contract" data-tooltip-content="Move to Contracting">{t('Referral_Assignment.Contracting_btn')}<i className="fa-solid fa-arrow-right ms-1"></i></button> */}
                    <button disabled={(props?.isSubmited || DataLeadInfo?.data?.referralCloseLog !== null)}  onClick={() => props?.submitalert(props?.assignment?.serviceName)} className='btn btn-primary btn-sm mt-3' data-tooltip-id="my-tooltip-contract" data-tooltip-content="Move to Contracting">{t('Referral_Assignment.Contracting_btn')}<i className="fa-solid fa-arrow-right ms-1"></i></button>
                </div>
                <Tooltip id="my-tooltip-contract" className="my-tooltip" />
                </>}
            </div>
        </div>
    );
};

export default AssignmentView;