import React, { useEffect, useState } from 'react';
import AdmissionDetailView from './admissionView';
import { RootState } from '../../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import eventEmitter from '../../../../../../../utils/eventEmitter';

const AdmissionDetailContainer = (props: any) => {
    const { StatusPatientInfo, DataPatientInfo, MessagePatientInfo, ErrorPatientInfo } = useSelector((state: RootState) => state.patientInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const dispatch = useDispatch();
    let [admissionDate, setAdmissionDate] = useState<any>('');
    let [dischargeDate, setDischargeDate] = useState<any>('');
    let [patientType, setPatientType] = useState();

    let [clearpatient, setclearpatient] = useState<any>(undefined);
    useEffect(() => {
        if (props.isAction == 'admit') {
            props.admissiondata([{ admissionDate: admissionDate }, { dischargeDate: dischargeDate }, { patientType: patientType }]);
        } else {
            props.admissiondata([{ admissionDate: admissionDate }, { dischargeDate: dischargeDate }, { patientType: patientType }]);
        }
    }, [admissionDate, dischargeDate, patientType]);

    const getAdmissionDate = (admission: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        if(!isNaN(new Date(admission).getTime()) && admission !== null){
        let admidate = new Date(admission);
        setAdmissionDate(admidate);
        }
        else{
            setAdmissionDate("");
        }
       
    };

    useEffect(()=>{
        if(DataPatientDraftInfo?.data !== undefined){
           if(DataPatientDraftInfo?.data?.admissionDetail?.admissionDate != null){
            setAdmissionDate(DataPatientDraftInfo?.data?.admissionDetail?.admissionDate ? window.$utils.dateFormat(DataPatientDraftInfo?.data.admissionDetail.admissionDate) : '');
           }
           if(DataPatientDraftInfo?.data?.admissionDetail?.estStartDate != null){
            setAdmissionDate(DataPatientDraftInfo?.data?.admissionDetail?.estStartDate ? window.$utils.dateFormat(DataPatientDraftInfo?.data.admissionDetail.estStartDate) : '');
           }
           setDischargeDate(DataPatientDraftInfo?.data?.admissionDetail?.estDischargeDate ? window.$utils.dateFormat(DataPatientDraftInfo?.data.admissionDetail.estDischargeDate) : '');
           setPatientType(DataPatientDraftInfo?.data?.patientDetail?.patientTypeId.toString() || '');
        }
   },[DataPatientDraftInfo])

    const getDischargeDate = (discharge: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        if(!isNaN(new Date(discharge).getTime()) && discharge !== null){
        let disdate = new Date(discharge);
        setDischargeDate(disdate);
        }
        else{
            setDischargeDate("");
        }
    };
    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        if(e.target.name === "patienttype"){
            setclearpatient('yes');
        }
        setPatientType(e.target.value);
    };
    return (
        <div>
            <AdmissionDetailView updateData={updateData} isAction={props.isAction} getAdmissionDate={getAdmissionDate} getDischargeDate={getDischargeDate}
            getadmissionerror={props.getadmissionerror} patientType={DataPatientInfo} draftData={DataPatientDraftInfo?.data} isedit={props.isedit} clearpatient={clearpatient}/>
        </div>
    );
};

export default AdmissionDetailContainer;
