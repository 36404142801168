import React, {useState, useEffect} from 'react';
import General_Info from '../../assets/images/general-info-white.svg';
import Capacity from '../../assets/images/capacity-white.svg';
import Profile from '../../assets/images/Profile-white.svg';
import Progress_Note from '../../assets/images/progress-note-white.svg';
import Info_gethering from '../../assets/images/Info-gathering-white.svg';
import Strength from '../../assets/images/strength-white.svg';
import Care_Giver from '../../assets/images/Care-Giver-white.svg';
import Next from '../../assets/images/next-white.svg';

const PrintAssessmentView = (props:any) => {
    const [ageGroup, setageGroup] = useState<any>([]);
    const [ethnicOrigin, setethnicOrigin] = useState<any>([]);
    const [religion, setreligion] = useState<any>([]);
    const [sexualityPreference, setsexualityPreference] = useState<any>([]);

    useEffect(() => {
        if(props.assessmentDropdown !== undefined) {
            setageGroup(props.assessmentDropdown.ageGroup);
            setethnicOrigin(props.assessmentDropdown.ethnicOrigin);
            setreligion(props.assessmentDropdown.religion);
            setsexualityPreference(props.assessmentDropdown.sexualityPreference);
        }
    }, [props.assessmentDropdown]);

    return (
        <div className='yourClassName'>
            <table className="table-invoice" width="100%" cellPadding="0" cellSpacing="0" style={{background:'#ffffff'}}>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={General_Info} alt="" width={20} className="me-2" /> General Information
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                            Referrer’s Details
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="32%" valign="top">
                                    <div>Name</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.name}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Relationship with Referred Person</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.relationshipWithReferedPerson}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.telephoneNo}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td>
                                    <div>Email</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.email}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Supported Person Details
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                             <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Full Name</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.fullName}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Title</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.title}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Preferred Name</div>
                                    <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.preferredName}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                       
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Current Address</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.currentAddress}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Home)</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.telephoneNoHome}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Mobile/Other)</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.telephoneNoOther}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.supportedPersonEmail}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Gender</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.supportedPersonGender}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Date of Birth</div>
                                    <div className="bold mb-3 mt-1">{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.generalInformation?.formData?.supportedPersonDateOfBirth))}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                       
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>NHS Number</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.supportedPersonNhsnumber}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Preferred method of Communication</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.preferredMethodOfCommunication}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top"></td>
                                </tr>
                            </table>
                        </td>
                       
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Next of Kin
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Relationship</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.nextOfKinRelationship }</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Address</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.nextOfKinAddress}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.nextOfKinEmail}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Home)</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.nextOfKinTelephoneNoHome}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Mobile/Other)</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.nextOfKinTelephoneNoOther}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Who to contact if not next of kin
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Relationship</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinRelationship}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Address</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinAddress}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinEmail}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Home)</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinTelephoneNoHome}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone Number (Mobile/Other)</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.ifNoNextOfKinTelephoneNoOther}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top"></td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Care Manager or Social Worker
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Address</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.socialWorkerAddress}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.socialWorkerEmail}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Telephone</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.socialWorkerTelephone}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr> 

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Name of G.P.
                            </div>
                        </td>
                    </tr> 
                     <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td width="32%" valign="top">
                                        <div>Address</div>
                                        <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.gpaddress } </div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>Email</div>
                                        <div className="bold mb-3 mt-1">{ props?.assessmentDetails?.generalInformation?.formData?.gpemail }</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>Telephone</div>
                                        <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.gptelephone}</div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Assessment Detail
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Is there an assessment from the local authority or other?</label>
                                <p className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.anAssesmentFromLocalAuthorityOrOther == 1 ? 'Yes' : 'No'}</p>
                               
                            </div>
                        </td>
                    </tr>
                   
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Other Professionals Involved <span className='font-light'><i>(Therapists, Care Providers, Diabetes Nurse etc.)</i></span>
                            </div>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                <td width="32%" valign="top">
                                    <div>Name</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalName}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Work Base</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalWorkBase}</div>
                                </td>
                                <td width="2%" valign="top"></td>
                                <td width="32%" valign="top">
                                    <div>Email</div>
                                    <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalEmail}</div>
                                </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td width="32%" valign="top">
                                        <div>Telephone</div>
                                        <div className="bold mt-1 mb-3">{props?.assessmentDetails?.generalInformation?.formData?.otherProfessionalTelephone}</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top"></td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top"></td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '0px 0 10px' }}>
                                Funding <span className='font-light'><i>(Who will be funding the support?)</i></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tr>
                                    <td width="32%" valign="top">
                                        <div>Local Authority</div>
                                        <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.fundingLocalAuthority}</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>CHC</div>
                                        <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.fundingChc}</div>
                                    </td>
                                    <td width="2%" valign="top"></td>
                                    <td width="32%" valign="top">
                                        <div>Personal Budget</div>
                                        <div className="bold mb-3 mt-1">{props?.assessmentDetails?.generalInformation?.formData?.fundingPersonalBudget}</div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Is this a jointly funded package?</label>

                                <p className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.isThisJointyFundingPackage === 1 ? 'Yes' : 'No'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Are there any risk factors that we need to be aware of prior to visiting the persons current home? <span className='font-light'><i>(Covid-19, accessibility, pets, poor lighting etc.)</i></span>
                            </div>
                            <div className="bold margin-bottom-10">{props?.assessmentDetails?.generalInformation?.formData?.riskFectors}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Does the person have a primary diagnosis? <span className='font-light'><i>(For example: learning disabilities, autism, mental ill-health, COPD)</i></span>
                            </div>
                            <div className="bold margin-bottom-10">{props?.assessmentDetails?.generalInformation?.formData?.doesThePersonHavePrimaryDiagnosis}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Are there any other health needs/conditions?
                                <span className='font-light'><i>(For example: mobility difficulties, swallowing difficulties, complex behaviours, epilepsy, ABI, physical ill-health, sensory loss)</i></span>
                            </div>
                            <div className="bold margin-bottom-10">{props?.assessmentDetails?.generalInformation?.formData?.areThereAnyOtherHealthCondition}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Positive Behaviour Support
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex mb-2">
                                <label>Does the person require a Positive Behaviour Support (PBS) Plan</label>

                                <p className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.positiveBehaviourSupport === 1 ? 'Yes' : 'No'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex mb-2">
                                <label>Does the person have a current PBS Plan</label>

                                <p className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.currentPlan === 1 ? 'Yes' : 'No'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <label>Is PBS involvement required for the purpose of this assessment</label>
                                <p className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.isPbsinvolvementRequiredForAssessment === 1 ? 'Yes' : 'No'}</p>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Consent and Best Interest
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex mb-2">
                                <label>Can the person consent to the assessment, their care, treatment and accommodation?</label>
                                <p className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.personConcentTotheAssessment === 1 ? 'Yes' : 'No'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex">
                            <label>Where it has been identified the person lacks capacity, has there been a Mental Capacity Assessment and Best Interest Decision?*
                                <span className='bold ms-2'>{props?.assessmentDetails?.generalInformation?.formData?.personLacksCapicity === 1 ? 'Yes' : 'No'}</span>
                            </label>

                            
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                How have you heard of {props?.orgName} Group?
                            </div>
                            <div className="bold margin-bottom-10">{props?.assessmentDetails?.generalInformation?.formData?.howHaveYouHeardOf}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className="purple-text bold" style={{ margin: '15px 0 10px' }}>
                                Name of person completing General Enquiry Information Form
                            </div>
                            <div className="bold margin-bottom-10">{props?.assessmentDetails?.generalInformation?.formData?.nameOfPersonCompletingGeneralInformation}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '15px' }}>
                                <tr>
                                    <td className="modal-title-header">
                                        <div className="white-text">
                                            <img src={Progress_Note} alt="" width={20} className="me-2" /> Progress Notes
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                </tr>
                <tr>
                    <td>
                        <p className='purple-text text-center bold mt-3 mb-3'>Office use only</p>
                    </td>
                </tr>
                <tr>
                        <td>
                            <table  width="100%" cellPadding="0" cellSpacing="0"> 
                                <tr>
                                    <td width="10%">
                                        <div className="d-flex">
                                            <label>Added to Referrals Log</label>
                                            <p className='bold ms-2'>{props?.assessmentDetails?.assessmentProgressNoteModel?.formData?.addedToReferralsLog === 1 ? 'Yes' : 'No'}</p>
                                        </div>
                                    </td>
                                    <td width="30%">
                                        <div className="d-flex">
                                            <label>Progress to assessment</label>
                                            <p className='bold ms-2'>{props?.assessmentDetails?.assessmentProgressNoteModel?.formData?.progressToAssessment === 1 ? 'Yes' : 'No'}</p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                </tr>
                <tr>
                    <div className='d-flex mt-2'>
                        <label>Date added:</label>
                        <p className='bold ms-2'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentProgressNoteModel?.formData?.dateAdded))}</p>
                    </div>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-3 mb-1'>Progress Notes</p>
                        <p><i>Complete table below: for example, informing person/Social Worker etc. whether progressing, date of any follow up appointment to progress, if not progressing letting the person know etc.</i></p>
                        <p className='bold purple-text mt-3 mb-1'>If not progressing to assessment at this stage, please state reason given to referrer</p>
                        <p className='bold'>{props?.assessmentDetails?.assessmentProgressNoteModel?.formData?.reason}</p>
                        <p className='bold purple-text mt-3 mb-3 text-center'>Progress Notes</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='table-radius' style={{margin:'0 auto',width:'70%'}}>
                        <table width="100%" cellPadding="0" cellSpacing="0" className="table table-progress mb-0" >
                            <tr>
                                <th>DATE</th>
                                <th>PROGRESS/COMMENTS</th>
                                <th>STAFF INITIALS</th>
                            </tr>
                            {props?.assessmentDetails?.assessmentProgressNoteModel?.formData?.assessmentProgressNotesDetailModels.length > 0 &&
                                    props?.assessmentDetails?.assessmentProgressNoteModel?.formData?.assessmentProgressNotesDetailModels.map((item: any, i: any) => {
                                        return (
                                    <tr key={i}>
                                        <td>
                                            <p>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(item.progressNoteDate))}</p>
                                        </td>
                                        <td>
                                            <p>{item.comments}</p>
                                        </td>
                                        <td>
                                            <p>{item.staffInitials}</p>
                                        </td>
                                    </tr>
                             );
                            })}
                        </table>
                        </div>
                        
                    </td>
                </tr>
                <tr>
                        <td>
                            <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '15px',marginBottom: '15px' }}>
                                <tr>
                                    <td className="modal-title-header">
                                        <div className="white-text">
                                            <img src={Info_gethering} alt="" width={18} className="me-2" /> Service User Profile
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What do you like to be called?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatDoYouLikeToBeCalled}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What people like / admire about you?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatPeopleAdmireAboutYou}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What’s important to you now?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatsImportantToYouNow }</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What are your hobbies and interests?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourHobbiesAndInterests}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What’s important to you for the future?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatsImportantToYouForTheFuture}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What are your hopes and dreams?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourHopesAndDreams}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What are your Likes…</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourLikes}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What are your dislikes…</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.whatAreYourDislikes}</p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-3'>Relationship Circle</p>
                        <p className='bold purple-text mt-2 mb-2'>Family</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.familyName1}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.familyName2}</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-2'>People you work with</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.peopleYouWorkWithName1}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.peopleYouWorkWithName2}</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-2'>Home and other paid supporters</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.homeAndOtherPaidSupportersName1}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.homeAndOtherPaidSupportersName2}</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='bold purple-text mt-0 mb-2'>Friends and non-paid relationships</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.friendsAndNonPaidRelationshipsName1}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Name</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserProfileModel?.formData?.friendsAndNonPaidRelationshipsName2}</p>
                                </td>
                            </tr>
                          
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Strength} alt="" width={20} className="me-2" /> Strengths Based Assessment
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>1.</span>
                        <div>
                            <span className='purple-text bold'>How I Communicate - </span>
                            <span className='purple-text font-light '><i>How do you express your choices, preferences and emotions - happiness, sadness, frustration, and anger? Some people may communicate by engaging in behaviours of concern, please ensure this is detailed and the PBS assessment is completed in line with this.</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Is English your first language?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isEnglishYourFirstLanguage}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Do you use gestures, pictures, Makaton, sign language?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouUseGestures}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Do you use any assistive technology?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouUseAnyAssistiveTechnology}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>How well do you understand verbal and non-verbal communication?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howWellDoYouUnderstandVerbal}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>How well do you understand written information (letters, texts, email, written instructions on packages, timetables)</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howWellDoYouUnderstandWrittenInformation}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What is important for us to know to support you with your communication?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatIsImportantForUsToKnowToSupportYouWithYourCommunication}</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are there any behaviours of concern in relation to how the person expresses themselves?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyBehavioursOfConcernInRelation}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Does this person require input from the PBS team?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doesThisPersonRequireInputFromThePbsTeam}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>How many hours per week?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howManyHoursPerWeek}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis1}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis1}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis1}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you with your Communication?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourCommunication}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>2.</span>
                        <div>
                            <span className='purple-text bold'>How to support me with my daily living skills - </span>
                            <span className='purple-text font-light '><i>Cooking, shopping, cleaning, laundry, knowing the time of day/night</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Cooking</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.cooking}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Shopping</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.shopping}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Cleaning</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.cleaning}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Laundry</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.laundry}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Tidying</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.tidying}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Knowing time of day/night</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.knowingTimeOfDayNight}</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Do you have any goals you wish to achieve in this area?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAnyGoalsYouWishToAchieveInThisArea}</p>
                                </td>
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities1}</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis2}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis2}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis2}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you with aspects of daily living activities?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithAspectsOfDailyLivingActivities}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>


                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>3.</span>
                        <div>
                            <span className='purple-text bold'>How to support me with my personal care? - </span>
                            <span className='purple-text font-light '><i>What support do I need for personal care needs - Eating and drinking, dressing and undressing, controlling your body temperature, washing, skin care, hair care, oral care, shaving, using the toilet, etc.</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Mobility and Equipment required</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.mobilityAndEquipmentRequired }</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Dressing/undressing</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dressingUndressing}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Washing/Bathing</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.washingBathing}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Oral Care</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.oralCare }</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Nutritional Care</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.nutritionalCare}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Continence Care</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.continenceCare}</p>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label className='mb-1'>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p><i>(SALT assessment, Manual Handling assessment, Other professional assessments)</i></p>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities2}</p>
                                </td>
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis3}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis3}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis3}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you with your Personal care?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourPersonalCare}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>4.</span>
                        <div>
                            <span className='purple-text bold'>How do I stay healthy and well - </span>
                            <span className='purple-text font-light '><i>exercise, mobility, history of falls, health conditions- asthma allergies etc. Diet and nutrition. Health appointments – opticians, dentist chiropody, hearing, GP’s, 7 sensory needs taste, touch, smell, hearing, sight, balance, spatial awareness/movement. Would I like to have an end of life plan developed?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className='mt-1 mb-2 bold'>Add/delete dates with professionals/appointments as required</p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <div>
                                        <label>Date of last known GP Visit if applicable
                                        <span className="d-flex align-items-center bold mt-2">
                                            <input type="checkbox" className='me-2' name="dateOfLastKnownGpVisitNa" checked={props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisitNa === 1 ? true : false} / > NA
                                        </span>
                                        </label>
                                        
                                    </div>
                                    <p className='bold mt-1 mb-3'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownGpVisit))}</p>
                                    
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <div>
                                        <label>Date of last known Dentist appointment if applicable
                                        <span className="d-flex align-items-center bold mt-2">
                                            <input type="checkbox" className='me-2' name="dateOfLastKnownGpVisitNa" checked={props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointmentNa === 1 ? true : false} / > NA
                                        </span>
                                        </label>
                                        
                                    </div>
                                    <p className='bold mt-1 mb-3'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownDentistAppointment))}</p>
                                    
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <div>
                                        <label>Date of last known Chiropody Appointment if applicable
                                        <span className="d-flex align-items-center bold mt-2">
                                            <input type="checkbox" className='me-2'  name="dateOfLastKnownGpVisitNa" checked={props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointmentNa === 1 ? true : false} / > NA
                                        </span>
                                        </label>
                                       
                                    </div>
                                    <p className='bold mt-1 mb-3'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownChiropodyAppointment))} </p>
                                    
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <div>
                                        <label>Date of last known Opticians Appointment if applicable
                                        <span className="d-flex align-items-center bold mt-2">
                                            <input type="checkbox" className='me-2'  name="dateOfLastKnownGpVisitNa" checked={props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointmentNa === 1 ? true : false} / > NA
                                        </span>
                                        </label>
                                        
                                    </div>
                                    <p className='bold mt-1 mb-3'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.dateOfLastKnownOpticiansAppointment))}</p>
                                    
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <div>
                                        <label>Dates of known falls if applicable
                                        <span className="d-flex align-items-center  bold">
                                            <input type="checkbox" className='me-2'  name="dateOfLastKnownGpVisitNa" checked={props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFallsNa === 1 ? true : false} / > NA
                                        </span>
                                        </label>
                                       
                                    </div>
                                    <p className='bold mt-1 mb-1'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.datesOfKnownFalls)) }</p>
                                    
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Is end of life planning being considered/in place?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isEndOfLifePlanningBeingConsideredInPlace}</p>
                                   
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <p className='mt-2 mb-2'>Are there any risks when undertaking these activities and is a risk assessment required</p>
                                <div className="d-flex mt-2">
                                    <label>Do you have an annual health check</label>
                                    <p className='bold ms-2'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAnAnnualHealthCheck === 1 ? 'Yes' : 'No'}</p>
                                </div>
                                <div className="d-flex mt-2">
                                    <label>Do you have a health action plan/passport</label>
                                    <p className='bold ms-2'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouHaveAhealthActionPlanPassport === 1 ? 'Yes' : 'No'}</p>
                                </div>
                                <div className="d-flex mt-2">
                                    <label>Is an Anticipatory care Calendar required?</label>
                                    <p className='bold ms-2'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isAnAnticipatoryCareCalendarRequired === 1 ? 'Yes' : 'No'}</p>
                                </div>
                                <div className="d-flex mt-2">
                                    <label>Do you require any specialist equipment?</label>
                                    <p className='bold ms-2'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouRequireAnySpecialistEquipment === 1 ? 'Yes' : 'No'}</p>
                                    
                                </div>
                                <p>(if yes is it fit for purpose and has it been regularly serviced?) YES, Specialist vehicle</p>
                                <p className='mt-2 bold'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouRequireAnySpecialistEquipmentComment}</p>
                                </td>
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis4}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis4}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis4}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you to stay healthy and well?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouToStayHealthyAndWell}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>5.</span>
                        <div>
                            <span className='purple-text bold'>How I spend my time and maintain my social wellbeing - </span>
                            <span className='purple-text font-light '><i>Socialising and leisure, how you occupy your time, education or work, creating and maintaining social networks (belonging, contribution to society), relationships (high quality), keeping in touch with family and friends. Hobbies and interests, can you travel independently?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Who and what is important to you?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whoAndWhatIsImportantToYou}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What hobbies do you have?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatHobbiesDoYouHave}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What do you get the most enjoyment out of?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatDoYouGetTheMostEnjoymentOutOf}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Can you travel independently?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.canYouTravelIndependently }</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>How do you stay in touch with family and friends?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howDoYouStayInTouchWithFamilyAndFriends}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities3}</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis5}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis5}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis5}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you with your Occupational and Social Wellbeing?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourOccupationalAndSocialWellbeing}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>6.</span>
                        <div>
                            <span className='purple-text bold'>How I maintain my emotional and mental wellbeing - </span>
                            <span className='purple-text font-light '><i>feelings of anxiety, depression, bereavement, loneliness/isolation etc.</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you undertaking any treatments for emotional and mental wellbeing?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouUndertakingAnyTreatmentsForEmotionalAndMentalWellbeing}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Do you get worried or anxious?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouGetWorriedOrAnxious}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What can trigger feelings of anxiety, depression and loneliness?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatCanTriggerFeelingsOfAnxiety}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What helps you to feel better?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatHelpsYouToFeelBetter}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Is there anything you wish us to be made aware of?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.isThereAnythingYouWishUsToBeMadeAwareOf}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                </td>
                            </tr>
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities4}</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you independent with this?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouIndependentWithThis6}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis6}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis6}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you with your emotional and mental wellbeing?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouWithYourEmotionalAndMentalWellbeing}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>7.</span>
                        <div>
                            <span className='purple-text bold'>How to support me with my medication? - </span>
                            <span className='purple-text font-light '><i>List of current medication, does the person understand why they take medication, who prescribes medication, where is it dispensed from?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Details: List of medication include time due, dosage and what it is taken for</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.detailsListOfMedicationIncludeTimeDueDosageAndWhatItIsTakenFor}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>How is your medication currently managed? Is it blister packed?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.howIsYourMedicationCurrentlyManaged}</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign='top' colSpan={3}>
                                    <label>What level of support do you need with your medication? Prompting, preparing, storing, ordering, administering, reviewing</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatLevelOfSupportDoYouNeedWithYourMedication}</p>
                                </td>
                               
                            </tr>
                            
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities5}</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>I am independent with this</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.iamIndependentWithThis}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis7}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis7}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you to manage and take your medication?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.wouldYouLikeToSupportYouToManageAndTakeYourMedication}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                    <div className='d-flex mt-2 mb-3'>
                        <span className='purple-text bold me-2'>8.</span>
                        <div>
                            <span className='purple-text bold'>How I manage my housing and finances - </span>
                            <span className='purple-text font-light '><i>Where and who would you like to live with? Do you need support to manage your tenancy how do you maintain your tenancy? Are you able to maintain a safe environment, can you deal with repairs? How do you manage your finances? Do you need support to pay your bills and budget your money? Have your benefits been reviewed in the last 12 months?</i></span>
                        </div>
                    </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Where would you prefer to live?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whereWouldYouPreferToLive}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Do you need support with your benefits?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouNeedSupportWithYourBenefits}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Do you need any support with managing your tenancy agreement?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.doYouNeedAnySupportWithManagingYourTenancyAgreement}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>What support do you need for your finances?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.whatSupportDoYouNeedForYourFinances}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Are you able to access your banking with or without support?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areYouAbleToAccessYourBankingWithOrWithoutSupport}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Are there any risks when undertaking these activities and is a risk assessment required</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.areThereAnyRisksWhenUndertakingTheseActivities6 }</p>
                                </td>
                            </tr>
                            
                        </table>
                       
                       
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>You are independent with this</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.youAreIndependentWithThis}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Your family help with this</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.yourFamilyHelpWithThis8}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Another helps with this. Please name. Father and staff</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.anotherHelpsWithThis8}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                    
                                </td>
                            </tr>
                          
                        </table>
                        <hr className='dashed-border' />
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top'>
                                    <label>Would you like {props?.orgName} Group to support you to develop skills to manage your Housing and Finances?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentStrengthsBasedAsmtModel?.formData?.supportYouToDevelopSkillsToManageYourHousingAndFinances}</p>
                                </td>
                            </tr>
                            
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Capacity} alt="" width={20} className="me-2" /> Capacity & Legal Orders and Additional Information
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td valign='top' colSpan={3}>
                                    <label>Is a capacity assessment required in relation to where the person lives and how they are supported for their care and treatment?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isAcapacityAssessmentRequired}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>If yes is a copy of the capacity assessment available?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isAcopyOfTheCapacityAssessmentAvailable}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Have you ever undergone any other capacity checks or assessments?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.haveYouEverUndergoneAnyOtherCapacityChecks}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Is there a Lasting Power of Attorney or deputyship?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isThereAlastingPowerOfAttorney}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Have you made an advanced decision in relation to care and support?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Do you have a Depravation of Liberty safeguard order (DOLs) in place?</label>
                                    <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    
                                </td>
                            </tr>
                          
                        </table>
                       <p className='bold purple-text mt-2 mb-2'>Under section 3</p>
                       <tr>
                            <td valign='top'>
                                <label>Are there any Mental Health Act orders/sections in place?</label>
                                <p className='bold mt-1 mb-1'>{props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.areThereAnyMentalHealthActOrdersSectionsInPlace}</p>
                            </td>
                        </tr>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Care_Giver} alt="" width={20} className="me-2" /> Support Worker/ Care Giver
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                          
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>What kind of person would you like to support you?</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.whatKindOfPersonWouldYouLikeToSupportYou}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Skills needed</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.skillsNeeded}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="48%" valign='top'>
                                    <label>Desirable characteristics / personality</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.desirableCharacteristicsPersonality}</p>
                                </td>
                                <td width="4%"></td>
                                <td width="48%" valign='top'>
                                    <label>Interests</label>
                                    <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.interests}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}> 
                                    <p>Support Required <i>(Specify if 1:1, 2:1, additionally complete needs assessments relevant to service, etc)</i></p>

                                    <div className="table-radius mt-3">
                                        <table className="table table-progress mb-0">
                                            <tr>
                                                <th>DAY</th>
                                                <th>AM</th>
                                                <th>PM</th>
                                                <th>NIGHT</th>
                                            </tr>
                                            {props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.assessmentSupportRequiredModels.length > 0 &&
                                                props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.assessmentSupportRequiredModels.map((item: any, i: any) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className='ps-2'>
                                                                {item.days}
                                                            </td>
                                                            <td>
                                                                <p className='bold'>
                                                                    {item.am}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p className='bold'>
                                                                    {item.pm}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p className='bold'>
                                                                {item.night}
                                                                </p>
                                                            </td>
                                                        </tr>
                                             );
                                            })}
                                            
                                        </table>
                                    </div>
                                    <div className="d-flex mt-4 mb-3">
                                        <label>CONSENT FOR KEEPING AND SHARING INFORMATION OBTAINED</label>
                                        <p className='bold ms-2'>{props?.assessmentDetails?.assessmentSupportWorkerModel?.formData?.consentForKeepingAndSharingInformationObtained === 1 ? 'Yes' : 'No' }</p>
                                    </div>
                                </td>
                            </tr>
                           
                        </table>
                       
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '10px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Next} alt="" width={15} className="me-2" /> What Happens Next?
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <td>
                                <tr>
                                    <td valign='top'>
                                        <label>Inform the person of the next stages of this process and provide them with any relevant information. List below.</label>
                                        <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.informThePersonOfTheNextStages}</p>
                                        <label>Are there any specialist training requirements needed to support the person?</label>
                                        <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.areThereAnySpecialistTrainingRequirements}</p>
                                    </td>
                                </tr>
                            </td>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{margin:'0 auto'}}>
                            <tr>
                                <td width="48%">
                                <div className='border-layout p-2 mt-3'>
                                    <p className='purple-text bold mb-3'>Assessment completed by</p>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Name</label>
                                        <p className='bold mb-2 flex-2'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByName}</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Signature</label>
                                        <p className='bold mb-2 flex-2'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedBySignature}</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <div className='flex-1'>
                                            <label className='flex-1'>Position</label>
                                            <div>
                                                <label>(if applicable)</label>
                                                <span className="d-flex bold align-items-center">
                                                    <input type="checkbox" name="assessmentCompletedByPositionNa" className="me-1" checked={props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByPositionNa === 1 ? true : false } />NA
                                                    </span>
                                            </div>
                                        </div>
                                        <p className='bold mb-2 flex-2'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByPosition}</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Date</label>
                                        <p className='bold mb-2 flex-2'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.assessmentCompletedByDate))}</p>
                                    </div>
                                </div>
                                </td>
                                <td width="4%"></td>
                                <td width="48%">
                                <div className='border-layout p-2 mt-3'>
                                    <p className='purple-text bold mb-3'>Assessment completed by</p>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Name</label>
                                        <p className='bold mb-2 flex-2'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personName}</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Signature</label>
                                        <p className='bold mb-2 flex-2'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personSignature}</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                    <div className='flex-1'>
                                            <label className='flex-1'>Position</label>
                                            <div>
                                                <label>(if applicable)</label>
                                                <span className="d-flex bold align-items-center">
                                                    <input type="checkbox" name="assessmentCompletedByPositionNa" className="me-1" checked={props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personPositionNa === 1 ? true : false } />NA
                                                    </span>
                                            </div>
                                        </div>
                                        <p className='bold mb-2 flex-2'>{props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personPosition}</p>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        <label className='flex-1'>Date</label>
                                        <p className='bold mb-2 flex-2'>{window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(props?.assessmentDetails?.assessmentWhatHappeensNextModel?.formData?.personDate))}</p>
                                    </div>
                                </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: '25px',marginBottom: '15px' }}>
                            <tr>
                                <td className="modal-title-header">
                                    <div className="white-text">
                                        <img src={Next} alt="" width={15} className="me-2" /> Service User’s Diversity Profile
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p>{props?.orgName} is keen to ensure it provides equality of access to all our services, regardless of issues of diversity. We are required to produce statistical information about the types of people we provide services to. Therefore, please excuse us for asking the following questions, which may seem very personal, but it is important for us to have this information. However, you do not have to complete any of the following questions, if you prefer not to.</p>
                        <div className="mt-3">
                            <label className='purple-text bold mb-1'>Ethnic origin, as defined by the supported person
                            </label>
                            <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData.ethnicOriginId !== ''
                            ? ethnicOrigin?.filter((item: any) => item.ethnicOriginId === props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData.ethnicOriginId)[0]?.name
                            : ''}</p>
                        </div>

                        <div className="mt-3">
                            <label className='purple-text bold mb-1'>How would you describe your religious beliefs?
                            </label> 
                            <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.religionId !== ''
                            ? religion?.filter((item: any) => item.religionId === props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData.religionId)[0]?.name === 'Other'
                                ? props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.otherReligion
                                : religion?.filter((item: any) => item.religionId === props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData.religionId)[0]?.name
                            : ''}</p>
                        </div>

                        <div className="mt-3">
                            <label className='purple-text bold mb-1'>Is English your first language?
                            </label> 
                            <p className='bold'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.isEnglishYourFirstLanguage === 1 ? 'Yes' : 'No' }</p>
                            
                            <div className='d-flex align-items-center'>
                                <label className='me-2'>If not, please state which language is?</label>
                                <p className='bold'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.languageName}</p>
                            </div>
                        </div>


                        <div className="mt-3">
                            <label className='purple-text bold mb-1'>Which age group are you?
                            </label> 
                            <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.ageGroupId !== '' ? ageGroup?.filter((item: any) => item.ageGroupId === props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.ageGroupId)[0]?.name : ''}</p>
                            
                        </div>


                        <div className="mt-3">
                            <label className='purple-text bold mb-1'>How would you describe your sexuality?
                            </label> 
                            <p className='bold mt-1 mb-3'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.sexualityPreferenceId !== ''
                            ? sexualityPreference?.filter((item: any) => item.sexualityPreferenceId === props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.sexualityPreferenceId)[0]?.name
                            : ''}</p>
                            
                        </div>


                        <div className="mt-3">
                            <label className='purple-text bold mb-1'>Do you have a disability or mobility problem?
                            </label> 
                            <p className='bold'>{props?.assessmentDetails?.assessmentServiceUserDiversityProfileModel?.formData?.doYouHaveAdisability === 1 ? 'Yes' : 'No'}</p>
                            
                            
                        </div>



                    </td>
                </tr>





            </table>
        </div>
    );
};

export default PrintAssessmentView;