import { AxiosError, AxiosResponse } from 'axios';
import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';


export const GetServiceTypeListService = () => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetServiceTypeListForUser}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};