import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';
import { setListNotificationsAction, errorListNotificationsAction, removeListNotificationsAction } from '../store/referral/listNotificationsReducer';

import eventEmitter from '../utils/eventEmitter';
import { errorNotificationsCountAction, removeNotificationsCountAction, setNotificationsCountAction } from '../store/referral/notificationsCountReducer';

export const getNotificationsService = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    store.dispatch(removeListNotificationsAction())
    return instance        
        .post(`${BASEURLDEV}${API_ENDPOINT.GetNotifications}`, payload)        
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setListNotificationsAction(resultData));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorListNotificationsAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(errorListNotificationsAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });
};


export const markAsReadNotifications = (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.MarkNotificationsAsRead}`,payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const GetNotificationsCount = (serviceId: any) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetNotificationsCount}?ServiceId=${serviceId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            store.dispatch(setNotificationsCountAction(resultData.totalCount));
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            if(error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                store.dispatch(errorNotificationsCountAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                store.dispatch(removeNotificationsCountAction(errorData));
                eventEmitter.emit('eventSetLoader', false)
                return errorData;
            }
        });

};