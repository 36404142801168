import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RedirectToFromModel } from '../../utils/model';

const initialState: RedirectToFromModel = {
    StatusRedirectToFrom: false,
    MessageRedirectToFrom: '',
    DataRedirectToFrom: '',
    ErrorRedirectToFrom: ''
};

export const redirectToFromSlice = createSlice({
    name: 'redirectToFrom',
    initialState,
    reducers: {
        setRedirectToFromAction: (state, { payload }: PayloadAction) => {
            state.StatusRedirectToFrom = true;
            state.MessageRedirectToFrom = 'Success';
            state.DataRedirectToFrom = payload;
            state.ErrorRedirectToFrom = ''
        },
        removeRedirectToFromAction: (state, { payload }: PayloadAction) => {
            state.StatusRedirectToFrom = false;
            state.MessageRedirectToFrom = '';
            state.DataRedirectToFrom = '';
            state.ErrorRedirectToFrom = '';
        },
        errorRedirectToFromAction: (state, { payload }: PayloadAction) => {
            state.StatusRedirectToFrom = false;
            state.DataRedirectToFrom = '';
            state.MessageRedirectToFrom = 'Failed';
            state.ErrorRedirectToFrom = payload;
        }
    },
    extraReducers: {}
});

export const { setRedirectToFromAction, removeRedirectToFromAction, errorRedirectToFromAction } = redirectToFromSlice.actions;

export default redirectToFromSlice.reducer;
