import React, { useState, useEffect } from 'react';
import HelpView from './HelpView';
import { getAllVideosService } from '../../service/onlinehelp-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setNavigationAction } from '../../store/dashboard/navigationReducer';

const HelpContainer = () => {
    const dispatch = useDispatch();
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const [videoList, setVideoList] = useState<any>([])
    const getAllVideos = async() => {
        const result = await getAllVideosService();
        if(result.status) {
            setVideoList(result.data)
        } else {
            setVideoList([]);
        }
    }
    useEffect(() => {
        getAllVideos();
    },[])
    useEffect(()=>{
        if(FormName !== ""){
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle:true
            };
            dispatch(setNavigationAction(obj));
          }
      },[])
    return (
        <div>
            <HelpView videoList={videoList} />
        </div>
    );
};

export default HelpContainer;