import axios, { AxiosResponse, AxiosError } from 'axios';
import { BASEURL, BASEURLDEV, API_ENDPOINT } from './ApiConstants';
import { DataResponseModel } from '../utils/model';
import { store } from '../store';
import instance from './axiosinstance';
import { setListRealeaseReasonsAction, errorListRealeaseReasonsAction } from '../store/patient/realeaseReasonReducer';
import eventEmitter from '../utils/eventEmitter';


export const GetServiceOverviewDetails = (slugName: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetServiceCoordinatorDetails}?serviceSlug=${slugName}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data 
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GetServiceProvierEmails = (slugName: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetServiceProvierEmails}?serviceSlug=${slugName}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const SendServiceCoordinatorEmails = (payload: any) => {
    return (
        instance.post(`${BASEURLDEV}${API_ENDPOINT.SendServiceCoordinatorEmails}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse ;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data) : (errorResponse);
                return errorData;
            }
        })
    )
}

export const GetServiceDetails = (slugName: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetServiceDetails}?serviceSlug=${slugName}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GetAmmenitiesServiceLists = (serviceSlug: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetAmmenitiesServiceLists}?serviceSlug=${serviceSlug}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};
export const GetServicePackageList = (slugName: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetServicePackageLists}?serviceSlug=${slugName}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};
export const GetServiceLocationDetails = (slugName: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetServiceLocationDetailsList}?serviceSlug=${slugName}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};
export const GetProviderServiceSection = (slugName: any,searchValue:any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetProviderServiceSectionList}?serviceSlug=${slugName}&searchValue=${searchValue}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};
export const GetProviderServiceSectionDetails = (servicesSectionDetailId: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetProviderServiceSectionDetails}?servicesSectionDetailId=${servicesSectionDetailId}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GetLowerSectionAvailability = (slugName: any) => {
    return instance
       .get(`${BASEURLDEV}${API_ENDPOINT.GetLowerSectionAvailability}?serviceSlug=${slugName}`)
       .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};