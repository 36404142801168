import React, { useEffect, useState } from 'react';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import RenderModalContainer from "../../../../../../src/components/RenderModal/indexContainer";
import sweetalert from 'sweetalert';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveForLaterModal } from '../../../../../store/SaveForLater/saveForLaterModalReducer';
import { RootState } from '../../../../../store';
import { GetPatientListForSaveLater, GetSaveForLaterFolderList, SaveForLater } from '../../../../../service/portal-services';
import eventEmitter from '../../../../../utils/eventEmitter';


const SaveForLaterModalView = () => {
    const dispatch = useDispatch();
    const { saveForLaterModelOpen, serviceId, serviceName } = useSelector((state: RootState) => state.saveForLaterModal);
    const [name, setName] = useState<string>(serviceName)
    const [addNewFolderName, setAddNewFolderName] = useState<string>("")
    const [folderName, setFolderName] = useState<any>(null)
    const [patientList, setPatientList] = useState<any>(null);
    const [folderNameOption, setFolderNameOption] = useState<any>([])
    const [addNewText, setAddNewText] = useState<boolean>(false)
    const [nameError, setNameError] = useState<string>('');
    const [folderNameError, setFolderNameError] = useState<string>('');
    useEffect(() => {
        if (saveForLaterModelOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [saveForLaterModelOpen]);

    const handleCloseModal = (event: any) => {
        event.stopPropagation();
        dispatch(saveForLaterModal({
            saveForLaterModelOpen: false,
            serviceId: 0,
            serviceName: ""
        }))
        setName("")
        setFolderName(null)
        setAddNewFolderName("")
        setAddNewText(false)
    };

    const handleName = (e: any) => {
        e.stopPropagation();
        const nameValue = e.target.value;
        setName(nameValue)
        if (!e.target.value.trim()) {
            setNameError('Name is required');
        } else {
            setNameError('');
        }
    }


    const onSaveForLater = async () => {
        let payload: any = {
            name: name,
            folderName: addNewText ? addNewFolderName : folderName.value,
            serviceId: serviceId
        };
        const response = await SaveForLater(payload);
        if (response.status === true) {
            sweetalert(response.message, {
                icon: 'success'
            });
        } else {
            sweetalert(response.message, {
                icon: 'error',
                dangerMode: true
            });
            
        }
        dispatch(saveForLaterModal({
            saveForLaterModelOpen: false,
            serviceId: 0,
            serviceName: ""
        }))
        setName("")
        setFolderName(null)
        setAddNewText(false);
        setAddNewFolderName("")
        eventEmitter.emit('GetFolderDetailsList');
        eventEmitter.emit('GetFolderWishList');
    }

    useEffect(() => {
        GetSaveForLaterPatientList()
    }, [])

    const GetSaveForLaterPatientList = async () => {
        let payload: any = {
            searchValue: ""
        };
        const result = await GetPatientListForSaveLater(payload)
        setPatientList(result?.data)
        if (result?.data) {
            const options = result?.data?.map((item: any) => ({
                label: item?.patientName,
                value: item?.patientName
            }));
            
            const allOptions = [{ label: 'Add New', value: 'addNew' }, ...options]

            setFolderNameOption(allOptions)
        }else{
            const allOptions = [{ label: 'Add New', value: 'addNew' }]
            setFolderNameOption(allOptions)
        }

    }
    const handleOnChange = (event: any, newValue: any) => {
        event.stopPropagation();
        if (newValue !== null && newValue.value === 'addNew') {
            setAddNewText(true);
        } else {
            setFolderName(newValue ? newValue : null);

        }
        if (newValue === null || !newValue.value.trim()) {
            setFolderNameError('Folder name is required');
        } else {
            setFolderNameError('');
        }
    };
    const handleAddNewFolderName = (e: any) => {
        setAddNewFolderName(e.target.value)
        if (!e.target.value.trim()) {
            setFolderNameError('Folder name is required');
            setAddNewText(false)
        } else {
            setFolderNameError('');
        }
    }
    const isSaveEnabled = ()=>{
        if(name !== "" && folderName !== null || addNewFolderName !== ""){
            return true
        }
        return false
    }
    return (
        <div>

            <RenderModalContainer>
                {saveForLaterModelOpen && <div><div className="overlay" onClick={handleCloseModal}></div>
                    <div className="modal modal-extrasmall">
                        <div className='modal-content'>
                            <div className="modal-header mb-1">
                                <h4 className="mb-0 modal-title">Save for Later</h4>
                                <a href="#" onClick={handleCloseModal} className="removeButton">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 384 512"
                                    >
                                        <path
                                            fill="#070044"
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div>
                                        <label className="mb-2">Name</label>
                                        <input
                                            type="text"
                                            className="form-control white mb-2 overview-input"
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => handleName(e)}
                                        ></input>                                             
                                         {nameError && <p className='error-message ps-0 mt-2'>{nameError}</p>}
                                        <label className="mb-2" >Folder Name</label>
                                        {addNewText ? <input
                                            type="text"
                                            className="form-control white mb-2 overview-input"
                                            placeholder="Folder Name"
                                            value={addNewFolderName}
                                            onChange={(e) => handleAddNewFolderName(e)}
                                        ></input> : <Autocomplete
                                            disablePortal
                                            id="folder-name"
                                            options={folderNameOption}
                                            size='small'
                                            onChange={handleOnChange}
                                            value={folderName}
                                            getOptionLabel={(option: any) => option.label}
                                            renderInput={(params) => <TextField {...params} placeholder="Folder Name" value={folderName ? folderName : null} InputLabelProps={{ shrink: false }} />}
                                        />}
                                              {folderNameError && <p className='error-message ps-0 mt-2'>{folderNameError}</p>}

                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <div><button className="btn btn-primary me-2" disabled={!isSaveEnabled()} onClick={onSaveForLater}>Save</button>
                                    <button onClick={(event) => handleCloseModal(event)} className="btn btn-secondary">Clear</button></div>
                            </div>
                        </div>

                    </div></div>}
            </RenderModalContainer>
        </div>
    );
};

export default SaveForLaterModalView;

