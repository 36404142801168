import React from 'react';
import SearchHeaderContainer from './SearchHeader/SearchHeaderContainer';
import LocationHeaderContainer from './LocationHeader/LocationHeaderContainer';
import ServicetypeHeaderContainer from './ServicetypeHeader/ServicetypeHeaderContainer';
import { useLocation } from 'react-router-dom';
import { ROUTES_DATA } from '../../navigation/RoutesConstants';
import NHSReferralHeaderContainer from './NHSReferralHeader/NHSReferralHeaderContainer';

const NHSHeaderView = () => {

    const location = useLocation()
    return (
        <div>
            {location.pathname !== ROUTES_DATA.NHSREFERRAL ?
                <>
                    <SearchHeaderContainer />
                    {/* <LocationHeaderContainer /> */}
                    <ServicetypeHeaderContainer />
                </> : <NHSReferralHeaderContainer />}
        </div>
    );
};

export default NHSHeaderView;