import React, { useState } from 'react';
import DraftView from './draftView';

const DraftContainer = () => {
    const [searchValue, setSearchValue] = useState<any>('');
    const handlerSearchValue = (e: any) => {
        setSearchValue(e.target.value);
    };
    return (
        <div>
            <DraftView handlerSearchValue={handlerSearchValue} searchValue={searchValue} />
        </div>
    );
};

export default DraftContainer;
