import React from 'react';
import ListSliderView from './ListSliderView';
import { ListContainerProps, ServicesListsType } from '../types';

const ListSliderContainer = ({ serviceImages, cqcRating, serviceName, serviceId, clickFn }: ListContainerProps) => {
    return (
        <div>
            <ListSliderView 
                serviceImages={serviceImages} 
                cqcRating={cqcRating} 
                serviceName={serviceName} 
                serviceId={serviceId}
                clickFn={clickFn}
            />
        </div>
    );
};

export default ListSliderContainer;