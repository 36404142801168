import React from 'react';
import PrintCostingView from './PrintInvoiceView';

const PrintCostingContainer = (props:any) => {
    return (
        <div>
            <PrintCostingView viewCosting={props.viewCosting} additionalField={props.additionalField}/>
        </div>
    );
};

export default PrintCostingContainer;