import React, { useEffect, useState } from 'react';
import ServiceReferralTableView from './ServiceReferralTableView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const ServiceReferralTableContainer = () => {
    let [moreInformation, setMoreInformation] = useState<Boolean>(false);
    let [SelectedStatus, setSelectedStatus] = useState('');
    const [referralOverView, setReferralOverView] = useState<any>();
    const { StatusReferralOverView, DataReferralOverView } = useSelector((state: RootState) => state.referralOverView);

    const handlerSelectedStatus = (val:any) =>{        
        setSelectedStatus(val);
    }

    useEffect(() => {
        if(StatusReferralOverView === true) {
            setReferralOverView(DataReferralOverView.data)
        }
    },[DataReferralOverView])
    return (
        <div>
            <ServiceReferralTableView referralOverView={referralOverView} moreInformation={moreInformation} SelectedStatus={handlerSelectedStatus} CurrentStatus={SelectedStatus} />
        </div>
    );
};

export default ServiceReferralTableContainer;