import React from 'react';
import PackagesDetailsView from './PackagesDetailsView';

const PackagesDetailsContainer = (props:any) => {
    return (
        <div>
            <PackagesDetailsView packageDetails={props?.packageDetails}/>
        </div>
    );
};

export default PackagesDetailsContainer;