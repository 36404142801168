import React , { useState, useEffect } from 'react';
import ReferralDetailsView from './ReferralDetailsView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const ReferralDetailsContainer = () => {
    let [moreInformation, setMoreInformation] = useState<Boolean>(false);
    const [referralOverView, setReferralOverView] = useState<any>();    
    const { StatusReferralOverView, DataReferralOverView } = useSelector((state: RootState) => state.referralOverView);

    const handleShowMoreInfo = () => {
        setMoreInformation(true);
    };
    const handleHideMoreInfo = () => {
        setMoreInformation(false);
    };

    useEffect(() => {
        if(StatusReferralOverView === true) {
            setReferralOverView(DataReferralOverView.data)
        }
    },[DataReferralOverView])

    return (
        <div>
            <ReferralDetailsView referralOverView={referralOverView} handleShowMoreInfo={handleShowMoreInfo} handleHideMoreInfo={handleHideMoreInfo} moreInformation={moreInformation} />
        </div>
    );
    
};

export default ReferralDetailsContainer;