import { createSlice } from '@reduxjs/toolkit';
import { CityWiseLatLngListModel } from '../../utils/model';
import { AxiosError } from 'axios';

const initialState: CityWiseLatLngListModel = {
    StatusCityWiseLatLngList: false,
    DataCityWiseLatLngList: '',
    MessageCityWiseLatLngList: '',
    ErrorCityWiseLatLngList: ''
};

export const cityWiseLatLngListSlice = createSlice({
    name: 'cityWiseLatLngList',
    initialState,
    reducers: {
        setCityWiseLatLngListAction: (state, { payload }: any) => {
            state.StatusCityWiseLatLngList = true;
            state.MessageCityWiseLatLngList = 'Success';
            state.DataCityWiseLatLngList = payload;
            state.ErrorCityWiseLatLngList = null
        },
        removeCityWiseLatLngListAction: (state, { payload }: any) => {
            state.StatusCityWiseLatLngList = false;
            state.MessageCityWiseLatLngList = '';
            state.DataCityWiseLatLngList = payload;
            state.ErrorCityWiseLatLngList = null;
        },
        errorCityWiseLatLngListAction: (state, { payload }: { payload: AxiosError }) => {
            state.StatusCityWiseLatLngList = false;
            state.DataCityWiseLatLngList = '';
            state.MessageCityWiseLatLngList = 'Failed';
            state.ErrorCityWiseLatLngList = payload;
        }
    }
});

export const { setCityWiseLatLngListAction, removeCityWiseLatLngListAction, errorCityWiseLatLngListAction } = cityWiseLatLngListSlice.actions;

export default cityWiseLatLngListSlice.reducer;
