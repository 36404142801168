import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RememberMeModel } from '../../utils/model';

const initialState: RememberMeModel = {
    StatusRememberMe: false,
    DataRememberMe: {},
    MessageRememberMe: '',
    ErrorRememberMe: ''
};

export const rememberMeSlice = createSlice({
    name: 'rememberMe',
    initialState,
    reducers: {
        setRememberMeAction: (state, { payload }: PayloadAction) => {
            state.StatusRememberMe = true;
            state.DataRememberMe = payload;
            state.MessageRememberMe = 'Success';
        },
        removeRememberMeAction: (state, { payload }: PayloadAction) => {
            state.StatusRememberMe = false;
            state.DataRememberMe = {};
            state.MessageRememberMe = '';
            state.ErrorRememberMe = '';
        },
        errorRememberMeAction: (state, { payload }: PayloadAction) => {
            state.StatusRememberMe = false;
            state.DataRememberMe = {};
            state.MessageRememberMe = 'Failed';
            state.ErrorRememberMe = payload;
        }
    },
    extraReducers: {}
});

export const { setRememberMeAction, removeRememberMeAction, errorRememberMeAction } = rememberMeSlice.actions;

export default rememberMeSlice.reducer;
