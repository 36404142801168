import React from 'react';
import PatientListContainer from '../PatientList/patientListContainer';

const AdmittedView = (props: any) => {
    return (
        <div>
            <div className="accordion accordion-flush" id="admissiondetail">
                <PatientListContainer searchValue={props.searchValue} tabID={props.tabID} />
            </div>
        </div>
    );
};

export default AdmittedView;
