import { createSlice } from '@reduxjs/toolkit';
import { FilterListModel, SetFilterListActionPayload } from '../../utils/model';
import { AxiosError } from 'axios';

const initialState: FilterListModel = {
    FilterListStatus: false,
    FilterListData: null,
    FilterListMessage: '',
    ErrorFilterList: null
};

export const filterListsSlice = createSlice({
    name: 'filterList',
    initialState,
    reducers: {
        setFilterListsAction: (state, { payload }: SetFilterListActionPayload) => {
            state.FilterListStatus = true;
            state.FilterListMessage = 'Success';
            state.FilterListData = payload;
            state.ErrorFilterList = null
        },
        removeFilterListsAction: (state, { payload }: SetFilterListActionPayload) => {
            state.FilterListStatus = false;
            state.FilterListMessage = '';
            state.FilterListData = payload;
            state.ErrorFilterList = null;
        },
        errorFilterListsAction: (state, { payload }: { payload: AxiosError }) => {
            state.FilterListStatus = false;
            state.FilterListData = null;
            state.FilterListMessage = 'Failed';
            state.ErrorFilterList = payload;
        }
    }
});

export const { setFilterListsAction, removeFilterListsAction, errorFilterListsAction } = filterListsSlice.actions;

export default filterListsSlice.reducer;