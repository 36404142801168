import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Capacity from '../../../assets/images/capacity-white.svg';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AssessmentStatus } from '../../../utils/constant';

const AdditionalInfoView = (props: any) => {
    const { t } = useTranslation();

    const { assessmentDetails } = props;

    let [assessmentDetailsAdditionalInfoView, setassessmentDetailsAdditionalInfoView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const validationSchema = Yup.object().shape({
        acloId: Yup.string().notRequired(),
        isAcapacityAssessmentRequired: Yup.string().required(),
        isAcopyOfTheCapacityAssessmentAvailable: Yup.string().required(),
        haveYouEverUndergoneAnyOtherCapacityChecks: Yup.string().required(),
        isThereAlastingPowerOfAttorney: Yup.string().required(),
        haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport: Yup.string().required(),
        doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace: Yup.string().required(),
        areThereAnyMentalHealthActOrdersSectionsInPlace: Yup.string().required()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const watchAllFields = watch();

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsAdditionalInfoView(props?.assessmentDetails?.assessmentCapacityLegalOrderModel?.formData);

            setValue('acloId', assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.acloId ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.acloId : 0, {
                shouldValidate: true
            });
            setValue(
                'isAcapacityAssessmentRequired',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isAcapacityAssessmentRequired
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isAcapacityAssessmentRequired
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'isAcopyOfTheCapacityAssessmentAvailable',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isAcopyOfTheCapacityAssessmentAvailable
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isAcopyOfTheCapacityAssessmentAvailable
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'haveYouEverUndergoneAnyOtherCapacityChecks',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.haveYouEverUndergoneAnyOtherCapacityChecks
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.haveYouEverUndergoneAnyOtherCapacityChecks
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'isThereAlastingPowerOfAttorney',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isThereAlastingPowerOfAttorney
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.isThereAlastingPowerOfAttorney
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace
                    : '',
                { shouldValidate: true }
            );
            setValue(
                'areThereAnyMentalHealthActOrdersSectionsInPlace',
                assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.areThereAnyMentalHealthActOrdersSectionsInPlace
                    ? assessmentDetails?.assessmentCapacityLegalOrderModel?.formData?.areThereAnyMentalHealthActOrdersSectionsInPlace
                    : '',
                { shouldValidate: true }
            );
        } else {
            setValue('acloId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        props.additionalInfoFormValid(formState.isValid);
        props.additionalInfoDataChange(watchAllFields);
        // if (formState.isValid) {
        //     props.additionalInfoDataChange(watchAllFields)
        // }
    }, [formState]);

    return (
        <div id="capacity-info">
            <div className="modal-title-header mb-2 mt-3">
                <h3>
                    <img src={Capacity} alt="" width={22} className="me-2" />
                    {t('Capacity_Information.Capacity_Legal')}
                </h3>
            </div>
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Capacity_Information.Capacity_Legal_Span')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <>
                            <input
                                type="hidden"
                                {...register('acloId', {
                                    // onChange: (e: any) => {
                                    //     props.updateData(e);
                                    // }
                                })}
                                name={'acloId' as const}
                            />
                            <textarea
                                {...register('isAcapacityAssessmentRequired', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'isAcapacityAssessmentRequired' as const}
                                className={`form-control white ${errors.isAcapacityAssessmentRequired ? 'is-invalid' : ''}`}
                                rows={2}
                            ></textarea>
                        </>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.isAcapacityAssessmentRequired}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Capacity_Information.assessment_available')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('isAcopyOfTheCapacityAssessmentAvailable', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'isAcopyOfTheCapacityAssessmentAvailable' as const}
                            className={`form-control white ${errors.isAcopyOfTheCapacityAssessmentAvailable ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.isAcopyOfTheCapacityAssessmentAvailable}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Capacity_Information.capacity_assessments')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('haveYouEverUndergoneAnyOtherCapacityChecks', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'haveYouEverUndergoneAnyOtherCapacityChecks' as const}
                            className={`form-control white ${errors.haveYouEverUndergoneAnyOtherCapacityChecks ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.haveYouEverUndergoneAnyOtherCapacityChecks}</p>}
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Capacity_Information.deputyship')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('isThereAlastingPowerOfAttorney', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'isThereAlastingPowerOfAttorney' as const}
                            className={`form-control white ${errors.isThereAlastingPowerOfAttorney ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.isThereAlastingPowerOfAttorney}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2 pe-2">
                    <label className="mb-2">{t('Capacity_Information.care_support')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport' as const}
                            className={`form-control white ${errors.haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.haveYouMadeAnAdvancedDecisionInRelationToCareAndSupport}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2 pe-2">
                    <label className="mb-2">{t('Capacity_Information.Depravation_place')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <input
                            type="text"
                            {...register('doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace' as const}
                            className={`form-control white ${errors.doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace ? 'is-invalid' : ''}`}
                        />
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.doYouHaveAdepravationOfLibertySafeguardOrderDoLsInPlace}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mt-2 mb-2">{t('Capacity_Information.Under_section')}</p>
            <div className="row mt-3">
                <div className="col-md-12 mb-2">
                    <label className="mb-2">{t('Capacity_Information.orders/sections')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('areThereAnyMentalHealthActOrdersSectionsInPlace', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'areThereAnyMentalHealthActOrdersSectionsInPlace' as const}
                            className={`form-control white ${errors.areThereAnyMentalHealthActOrdersSectionsInPlace ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsAdditionalInfoView.areThereAnyMentalHealthActOrdersSectionsInPlace}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
        </div>
    );
};

export default AdditionalInfoView;
