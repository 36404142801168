import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rightNavigationPage } from '../../../../../../src/utils/constant';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { removeLeadInformationAction } from '../../../../../store/lead/getLeaddataReducer';
import { RootState } from '../../../../../store';


const AddLeadButtonView = () => {
    const { t } = useTranslation();
    const[isbtnenable,setbtnenable] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    useEffect(()=>{
     for(let i=0;i<DataLogin.userOrganizationModels.length;i++){
        for(let j=0;j<DataLogin.userOrganizationModels[i].children.length;j++){
            for(let k=0;k<DataLogin.userOrganizationModels[i].children[j].children.length;k++){
                if(DataLogin.userOrganizationModels[i].children[j].children[k].canEdit){
                    setbtnenable(true);
                    break;
                }
            }
          
        }
     }
    },[DataLogin]);


    const handlerOpenRightBar = (pageName: any) => {
        dispatch(removeLeadInformationAction());
        
        let obj1 = {
            isheader : true,
            fleadstatus : 1,
        }
        if (pageName === rightNavigationPage.CreateLead) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.CreateLead,
                Data: obj1,
                ShowToggle:false
            };
            dispatch(setNavigationAction(obj));
        }
    }

    return (
        <div>
           <button disabled={!isbtnenable} onClick={() => handlerOpenRightBar('CreateLead')} className='btn btn-primary btn-create me-2 mb-3'><i className="fa-solid fa-plus"></i> {t('ReferralTable.Add_Lead_btn')}</button>
        </div>
    );
};

export default AddLeadButtonView;