import React from 'react';
import ReprocessIntegrationView from './ReprocessIntegrationView';

const ExportIntegrationContainer = (props: any) => {
    return (
        <div>
            <ReprocessIntegrationView {...props}/>
        </div>
    );
};

export default ExportIntegrationContainer;