import React from 'react';
import AddLeadButtonView from './AddLeadButtonView';

const AddLeadButtonContainer = () => {
    return (
        <div>
            <AddLeadButtonView />
        </div>
    );
};

export default AddLeadButtonContainer;