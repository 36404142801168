import React from 'react';
import ListBoxView from './ListBoxView';
import { ListBoxContainerPropsType } from './types';

const ListBoxContainer = ({ props, fromMapView = false }: ListBoxContainerPropsType) => {
    return (
        <div>
            <ListBoxView props={props} fromMapView={fromMapView} />
        </div>
    );
};

export default ListBoxContainer;