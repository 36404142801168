import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListMessagesModel } from '../../utils/model';

const initialState: ListMessagesModel = {
    StatusListMessages: false,
    DataListMessages: '',
    MessageListMessages: '',
    ErrorListMessages: ''
};

export const listMessagesSlice = createSlice({
    name: 'listMessages',
    initialState,
    reducers: {
        setListMessagesAction: (state, { payload }: PayloadAction) => {
            state.StatusListMessages = true;
            state.DataListMessages = payload;
            state.MessageListMessages = 'Success';
        },
        errorListMessagesAction: (state, { payload }: PayloadAction) => {
            state.StatusListMessages = false;
            state.DataListMessages = [];
            state.MessageListMessages = 'Failed';
            state.ErrorListMessages = payload;
        },
        removeListMessagesAction: (state, { payload }: PayloadAction) => {
            state.StatusListMessages = false;
            state.DataListMessages = '';
            state.MessageListMessages = '';
            state.ErrorListMessages = '';
        }
    },
    extraReducers: {}
});

export const { setListMessagesAction, errorListMessagesAction, removeListMessagesAction } = listMessagesSlice.actions;

export default listMessagesSlice.reducer;
