import React from 'react';
import LoginView from '../../pages/LoginPage/indexView';
import LoaderView from './loaderView';

const LoaderContainer = () => {
    return (
        <div>
            <LoaderView />
        </div>
    );
};

export default LoaderContainer;
