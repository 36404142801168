import React from 'react';
import { Doughnut } from 'react-chartjs-2'; 

const PieChartView = (props:any) => {
    return (
        <div>
             <div className="chart-container pie-container mt-2">
              <Doughnut 
              data={props.data} 
              options={props.options}
            
               />
            </div>
        </div>
    );
};

export default PieChartView;