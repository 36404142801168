import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import SplitFundingModalContainer from '../../../../../../components/SplitFundingModal/indexContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import PaginationContainer from '../../../../../../components/PaginationComponent/indexContainer';
import { getPlanData } from '../../../../../../service/dashboard-service';
import { setTempSplitFundingAction } from '../../../../../../store/splitFunding/tempSplitFundingReducer';
import { saveSplitFunding } from '../../../../../../service/invoicedetail-service';

const SplitFundingView = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [admissionid, setadmissionid] = useState<any>();
    const [isaddplan, setisaddplan] = useState<boolean>(false);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);

    let plans: any = [];
    let funders: any = [];
    var planlist:any=[];

    const showTaskDetailHandler = (data: any) => {
        setadmissionid(data.admissionId)
        getplandata(data.admissionId)
    }

    const addPlan = () => {};

    const savedata = () => {
        savesplitfunding();
    };

    async function savesplitfunding() {
        let fundersdata =[];
        planlist = [];
          if(TempDataSplitFunding.length > 0){
              
              for(let i=0;i<TempDataSplitFunding.length;i++){
                  let obj : any ={};
                  fundersdata=[];
                  if(TempDataSplitFunding[i].planId === -1){
                    obj.planId = 0;
                  }
                  else{
                    obj.planId = TempDataSplitFunding[i].planId;
                  }

                  obj.weeklyCharge = TempDataSplitFunding[i].corefee;
                  obj.StartDate = TempDataSplitFunding[i].admissiondate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].admissiondate) : '';
                  obj.endDate = TempDataSplitFunding[i].enddate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].enddate) : '';

                  for(let j=0;j<TempDataSplitFunding[i].funderlist.length;j++){
                      fundersdata.push({planDetailId : TempDataSplitFunding[i].funderlist[j].planDetailId,funderid : TempDataSplitFunding[i].funderlist[j].funderid === null ? null : Number(TempDataSplitFunding[i].funderlist[j].funderid),amountType:TempDataSplitFunding[i].funderlist[j].ispercentage ? 2 : 1,proportion: Number(TempDataSplitFunding[i].funderlist[j].proportion),proportionValue:Number(TempDataSplitFunding[i].funderlist[j].proportionvalue),isApplicable:TempDataSplitFunding[i].funderlist[j].isapplicable ? 0 : 1})
                  }

                  obj.planDetails = fundersdata;
                  planlist = [...planlist,obj];
              }
              let data={
                  admissionId : admissionid,
                  plans:planlist
              }
             const result =  await saveSplitFunding(data);
             if (result.status) {
              swal(result?.message, {
                  icon: 'success'
              }).then(()=>{
                setShowModal(false);
                props.closeSideMenu();
              });
          } else {
              swal(result?.message, {
                  icon: 'error'
              });
          }
          }
      }

    async function getplandata(aid: any) {
        const response = await getPlanData(aid);
        if (response.status == true) {
            setShowModal(true);
            plans = [];
            for (let j = 0; j < response.data.length; j++) {
                // if (response.data[j].planType == 0) {
                    let obj: any = {};
                    funders = [];
                    obj.corefee = response?.data[j]?.weeklyCharge;
                    obj.admissiondate = response?.data[j]?.startDate?.toString();
                    obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                    obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                    obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                    obj.planId = response?.data[j]?.planId;
                    obj.lastinvoicedate = response?.data[j]?.lastInvoiceCreatedDate?.toString();
                    for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                        funders.push({
                            planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                            funderid: response?.data[j]?.planDetails[i].funderId,
                            fundername: response?.data[j]?.planDetails[i].funderName,
                            fundertype:
                                response?.data[j]?.planDetails[i].funderType === 1
                                    ? 'ICB'
                                    : response?.data[j]?.planDetails[i].funderType === 2
                                    ? 'LA'
                                    : response?.data[j]?.planDetails[i].funderType === 3
                                    ? 'Charity'
                                    : 'Self',
                            proportion: response?.data[j]?.planDetails[i].proportion,
                            proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                            isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                            ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                        });

                        obj.funderlist = funders;
                    }
                    plans = [...plans, obj];
                    dispatch(setTempSplitFundingAction(plans));
                // }
            }
        }
    }

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
            }
        });
    };

    return (
        <div className="gray-box mt-2">
            <p className="purple-text lead-text bold mb-3">
            {props?.totalCount > 99 ? props?.totalCount : "" }
            <span className='ms-1'> {t('Task.Split_Fund_Title')}</span></p>

            {props?.taskList === null && (
                <p>No Data!</p>
            )}
                {props?.taskList?.length > 0 && (<ul className="list-unstyled task-list">
                    {props.taskList.map((data: any, index: any) => {
                        return (
                            <li key={data?.taskId}>
                                <a className="cursor-pointer" onClick={() => showTaskDetailHandler(data)}>
                                    {data?.message}
                                </a>
                            </li>
                        );
                    })}
                </ul>)}

            {props.currentPage > 0 && props.totalCount > 0 && props.pageSize > 0 && (
                <PaginationContainer currentPage={props.currentPage} totalCount={props.totalCount} pageSize={props.pageSize} onPageChange={(page: any) => props.getCurrentPage(page)} />
            )}
            <RenderModalContainer>{showModal && <SplitFundingModalContainer onAddplan={addPlan} onSave={savedata} onClose={toggle} isaddplan={isaddplan} />}</RenderModalContainer>
        </div>
    );
};

export default SplitFundingView;
