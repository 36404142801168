import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListMessagesModel, ListNotificationsModel } from '../../utils/model';

const initialState: ListNotificationsModel = {
    StatusListNotifications: false,
    DataListNotifications: '',
    MessageListNotifications: '',
    ErrorListNotifications: ''
};

export const listNotificationsSlice = createSlice({
    name: 'listNotifications',
    initialState,
    reducers: {
        setListNotificationsAction: (state, { payload }: PayloadAction) => {
            state.StatusListNotifications = true;
            state.DataListNotifications = payload;
            state.MessageListNotifications = 'Success';
        },
        errorListNotificationsAction: (state, { payload }: PayloadAction) => {
            state.StatusListNotifications = false;
            state.DataListNotifications = [];
            state.MessageListNotifications = 'Failed';
            state.ErrorListNotifications = payload;
        },
        removeListNotificationsAction: (state, { payload }: PayloadAction) => {
            state.StatusListNotifications = false;
            state.DataListNotifications = '';
            state.MessageListNotifications = '';
            state.ErrorListNotifications = '';
        }
    },
    extraReducers: {}
});

export const { setListNotificationsAction, errorListNotificationsAction, removeListNotificationsAction } = listNotificationsSlice.actions;

export default listNotificationsSlice.reducer;
