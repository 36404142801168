import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const UserDetailView = () => {
    const { t } = useTranslation();   
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);

     return (
        <div>
            {DataLeadInfo?.data?.patientDetail?.nhsId?.length> 0 && <p className='mb-1'><label>{t('ServiceReferralSidebar.NHS_ID')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.nhsId}</span></p>}
            {DataLeadInfo?.data?.patientDetail?.localId?.length> 0 && <p className='mb-1'><label>{t('ServiceReferralSidebar.Client_ID')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.localId}</span></p>}
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Date_Birth')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.dateOfBirth?.split(' ')?.[0]}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Gender')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.gender === 1 ? "Male" : DataLeadInfo?.data?.patientDetail?.gender === 2 ? "Female" : DataLeadInfo?.data?.patientDetail?.gender === 3 ? "Others" : ""}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Address1')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.addressLine1}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Address2')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.addressLine2}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.County')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.countyName}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Postal_Code')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.pobox}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Contact_No')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.contactNo}</span></p>
            <p className='mb-1'><label>{t('ServiceReferralSidebar.Email_Id')}:</label><span className='bold ms-1'>{DataLeadInfo?.data?.patientDetail?.email}</span></p>
        </div>
    );
};

export default UserDetailView;