import WardView from './wardView';
import { useEffect, useState } from 'react';
import { getBedData, getCityData, getFunderData, getPatientTypeData, getWardData } from '../../../../service/dashboard-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import eventEmitter from '../../../../utils/eventEmitter';
import { removeSplitFundingAction } from '../../../../store/splitFunding/splitFundingReducer';
import { removeTempSplitFundingAction } from '../../../../store/splitFunding/tempSplitFundingReducer';
import { removePatientDraftAction } from '../../../../store/dashboard/draftPatientData';

const WardPage = () => {
    let [ward, setWard] = useState<any>('');
    let [bedPage, setBedPage] = useState<any>(1);
    let [wardData, setWardData] = useState<any>('');
    let dispatch = useDispatch();
    const [height, setHeight] = useState(window.innerHeight);
    const [isVisible, setIsVisible] = useState(false);
    const [update,setupdate]=useState<boolean>(false);
   
    const [sort,setsort] = useState<any>(0);
    const [search,setsearch]= useState<any>(undefined);
    let Tab_content = window.innerHeight - 215;
    let ward_length:number;
    let pill_height:number = 100 ;
    let sval:any;
     let totalleftheight : number;

    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusWardInfo, DataWardInfo, MessageWardInfo, ErrorWardInfo } = useSelector((state: RootState) => state.wardInformation);
    if (StatusWardInfo === true) {
    }
    useEffect(() => {
        if (StatusWardInfo === true) {
            setWardData(DataWardInfo);
            setWard(DataWardInfo.data[0].wardId)
             ward_length = DataWardInfo.data.length;
            totalleftheight = Number(ward_length * pill_height);
            if(totalleftheight > window.innerHeight - 215){
                setIsVisible(true);
            }
            else{
                setIsVisible(false);
            }

        }
    }, [StatusWardInfo, DataWardInfo]);

    const updateDimensions = () => {
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    let handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
        setsearch("");
        event.preventDefault();
        dispatch(removePatientDraftAction());
        let returnedTarget = JSON.parse(JSON.stringify(DataWardInfo));
        setWard(Number(event.currentTarget.id));
        setBedPage(1);
        for (let i = 0; i < returnedTarget.data.length; i++) {
            
            
            if (Number(event.currentTarget.id) === returnedTarget.data[i].wardId) {
                returnedTarget.data[i].active = true;
            } else {
                returnedTarget.data[i].active = false;
            }
        }
        
        setWardData(returnedTarget);
    };

    const getPage = (bedPage: any) => {
        setBedPage(bedPage);
    };

    useEffect(() => {
        async function getBedInformation() {

            const bedpayload: any = { wardId: ward, page: bedPage, pageSize: 100, searchValue: search, sortOrder: sort?.sortorder || 'asc', sortBy: sort?.sortby || 'bedorder' };
            await getBedData(bedpayload, ward);
        }
        if (wardData.data !== undefined && wardData.data.length > 0) {
            getBedInformation();
        }
    }, [ward, bedPage, wardData]);

    useEffect(() => {
        async function getWardInformation() {
            const payload: any = { serviceId: DataServiceDetail.serviceId };
            await getWardData(payload);
            await getCityData();
            await getPatientTypeData();
            await getFunderData(0);
        }
        getWardInformation();
    }, [DataServiceDetail]);

    const eventGetWardInformation = async () => {
        // const payload: any = { serviceId: DataServiceDetail.serviceId };
        // await getWardData(payload);
        const bedpayload: any = { wardId: ward, page: bedPage, pageSize: 100, searchValue: search, sortOrder: 'asc', sortBy: 'bedorder' };
        await getBedData(bedpayload, ward);
    };

    useEffect(() => {
        setsearch("")
        eventEmitter.on('eventGetWardInformation', eventGetWardInformation);
        return () => {
            eventEmitter.off('eventGetWardInformation', eventGetWardInformation);
        };
    });

    const handlerSearchValue = async(val:any) =>{
        sval = val;
        setsearch(val);
      const bedpayload: any = { wardId: ward, page: bedPage, pageSize: 100, searchValue: sval, sortOrder: sort?.sortorder || 'asc', sortBy: sort?.sortby || 'bedorder' };
        if(val !== ""){
            await getBedData(bedpayload, ward);
        }
        else{
            const bedpayload: any = { wardId: ward, page: bedPage, pageSize: 100, searchValue: sval, sortOrder: sort?.sortorder || 'asc', sortBy: sort?.sortby || 'bedorder' };
            await getBedData(bedpayload, ward);
        }
    }

    const sortparam = async (sortval:any) =>{
        
        setsort(sortval);
        const bedpayload: any = { wardId: ward, page: bedPage, pageSize: 100, searchValue: search, sortOrder: sortval.sortorder, sortBy: sortval.sortby };
        
            await getBedData(bedpayload, ward);
        
       
    }

    useEffect(()=>{
        async function getbeddata(bedpayload:any){
            await getBedData(bedpayload, ward);
        }
        if(update){
            const bedpayload: any = { wardId: ward, page: bedPage, pageSize: 100, searchValue: search, sortOrder: sort?.sortorder || 'asc', sortBy: sort?.sortby || 'bedorder' };
            getbeddata(bedpayload)
        }
    },[update])

    const setchange = (data:any) =>{
       setupdate(data);
    }

    return (
        <div>
            <WardView wardData={wardData} handleSubmit={handleSubmit} getPage={getPage} handlerSearchValue={handlerSearchValue} ischange={setchange} isVisible={isVisible} sortparam={sortparam}/>
        </div>
    );
};

export default WardPage;
