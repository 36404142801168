import PaginationView from './indexView';
import { usePagination, DOTS } from '../../hooks/usePagination';

const PaginationContainer = (props: any) => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || (paginationRange !== undefined && paginationRange.length < 2)) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const onPageChangeContainer = (page: any) => {
        onPageChange(page);
    };

    let lastPage = paginationRange !== undefined ? paginationRange[paginationRange.length - 1] : 0;
    return (
        <PaginationView
            onPageChangeContainer={onPageChangeContainer}
            currentPage={currentPage}
            paginationRange={paginationRange}
            lastPage={lastPage}
            onPrevious={onPrevious}
            onNext={onNext}
            DOTS={DOTS}
        />
    );
};

export default PaginationContainer;
