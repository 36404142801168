import React from 'react';
import SearchHeaderView from './SearchHeaderView';

const SearchHeaderContainer = () => {
    return (
        <div>
            <SearchHeaderView />
        </div>
    );
};

export default SearchHeaderContainer;