import React, { useEffect } from 'react';
import SplitFundingModalView from './indexView';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../../navigation/RoutesConstants';
import GenericErrorComponent from '../GenericErrorComponent';

const SplitFundingModalContainer = (props: any) => {
    const navigate = useNavigate();
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);

    useEffect(()=>{
     if(TempDataSplitFunding.length == 0){
        navigate(ROUTES_DATA.ERRORPAGE);
     }
    },[TempDataSplitFunding])
    
    return <SplitFundingModalView onClose={props.onClose} className={props.className} footer={props.footer} title={props.title} content={props.content} onSave={props.onSave} onAddplan={props.onAddplan} isaddplan={props.isaddplan} iseditable={props.iseditable} funderlist={props.funderlist}/>;
};


export default SplitFundingModalContainer;
