import React,{useState} from 'react';
import ReferralDetailsLessContainer from './ReferralDetailsLess/ReferralDetailsLessContainer';
import ReferralDetailsMoreContainer from './ReferralDetailsMore/ReferralDetailsMoreContainer';

const ReferralDetailsView = (props: any) => {
    let [SelectedStatus, setSelectedStatus] = useState('');
    let [SelectedFrequency, setSelectedFrequency] = useState(1);
  
    const handlerSelectedStatus = (val:any) =>{        
        setSelectedStatus(val);
    }

    const handlerSelectedFrequency = (val:any) =>{        
        setSelectedFrequency(val);
    }
    return (
        <div>
             {props.moreInformation === false ? (
            <div className='position-relative'>
                <ReferralDetailsLessContainer referralOverView={props.referralOverView} 
                                       SelectedStatus={handlerSelectedStatus} CurrentStatus={SelectedStatus}/>
                    {/* <p className="see-more" onClick={props.handleShowMoreInfo}>
                        <label id="moreless">More Details</label>
                        <span className="upicon downicon ms-1"></span>
                    </p> */}
            </div>
             ) : (
            <div className='position-relative more-info'>
                <ReferralDetailsMoreContainer referralOverView={props.referralOverView} 
                                        SelectedStatus={handlerSelectedStatus} CurrentStatus={SelectedStatus}
                                        SelectedFrequency={handlerSelectedFrequency} Frequency={SelectedFrequency} />
                {/* <p className="see-more" onClick={props.handleHideMoreInfo}>
                        <label id="moreless">Less Details</label>
                        <span className="upicon upicon ms-1"></span>
                    </p> */}
            </div>
             )}
           
        </div>

       
    );
};

export default ReferralDetailsView;