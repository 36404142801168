import React from 'react';
import ListDetailsView from './ListDetailsView';
import { ServicesListsType } from '../types';

const ListDetailsContainer = (props: ServicesListsType) => {
    return (
        <div>
            <ListDetailsView {...props} />
        </div>
    );
};

export default ListDetailsContainer;