// @ts-ignore
import CryptoJS from 'crypto-js';

const key = 'u8x/A?D(G+KbPeShVkYp3s6v9y$B&E)H';

export default {
    OpenSSLEncrypt(plainText: string) {
        try {
            const data = JSON.stringify(plainText);
            const encrypted = CryptoJS.AES.encrypt(data, key);
            return encrypted.toString();
        } catch (error) {
            
        }
    },
    OpenSSLDecrypt(cipherText: string) {
        try {
            const decrypt = CryptoJS.AES.decrypt(cipherText, key);
            return JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            
        }
    },
    base64Encode(plainText: string) {
        try {
            return btoa(plainText)
        } catch (error) {
            
        }
    },
    base64Decode(cipherText: string) {
        try {
            return atob(cipherText)
        } catch (error) {
            
        }
    }
};
