import { yupResolver } from '@hookform/resolvers/yup';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'; 'filepond-plugin-file-validate-type';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import swal from 'sweetalert';
import note from '../../../../../../../assets/images/note.svg';
import warning from '../../../../../../../assets/images/warning.png';
import NoteContainer from '../../../../../../../components/NoteModal/indexContainer';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../../service/ApiConstants';
import { deleteContactDocument, getContactDocument } from '../../../../../../../service/dashboard-service';
import { RootState, store } from '../../../../../../../store';
import { ServiceTypeIconName } from '../../../../../../../utils/constant';


registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const validationSchema = Yup.object().shape({
    fEmailId: Yup.string()
        .trim()
        .strict(true)
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        .email('Email is Invalid')
        .required('Email is required'),
    fFunder: Yup.string().trim().strict(true).required('Funder is required'),
    fContactNo: Yup.string()
        .trim()
        .strict(true)
        .matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
        .nonNullable()
        .required('contact is required'),
    fAddress1: Yup.string().trim().strict(true).required('add required'),
    fCity: Yup.string().trim().strict(true).required('city required'),
    fPoBox: Yup.string()
        .trim()
        .strict(true)
        .matches(/^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .nonNullable()
        .required('pobox is required'),
    funderContract: Yup.string().notRequired(),
    fCheck: Yup.boolean().notRequired(),
    ftype: Yup.string().strict(true).nonNullable().notRequired(),
    fpo:Yup.string().notRequired(),
    cEmailId: Yup.string().when('ftype', {
        is: '4',
        then : (schema: any) => schema.nonNullable().notRequired(),
        otherwise:  (schema: any) => schema.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        .email('Email is Invalid')
        .required('Email is required')}),
    cFunder:  Yup.string().when('ftype', {
        is: '4',
        then : (schema: any) => schema.nonNullable().notRequired(),
        otherwise:  (schema: any) =>schema.required()}),
    cContactNo:  Yup.string().when('ftype', {
        is: '4',
        then : (schema: any) => schema.nonNullable().notRequired(),
        otherwise:  (schema: any) => schema.matches(/^(?:\+44|0)(?:\d\s?){9}\d$/)
        .nonNullable()
        .required('contact is required')}),
        
    cAddress1: Yup.string().trim().strict(true).notRequired(),
    cCity: Yup.string().trim().strict(true).notRequired(),
    cPoBox: Yup.string()
        .trim()
        .strict(true)
        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .nonNullable()
        .notRequired(),

    sEmailId: Yup.string()
        .trim()
        .strict(true)
        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        .email('Email is Invalid')
        .notRequired(),
    sFunder: Yup.string().trim().strict(true).notRequired(),
    sContactNo: Yup.string()
        .trim()
        .strict(true)
        .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
        .nonNullable()
        .notRequired(),
    sAddress1: Yup.string().trim().strict(true).notRequired(),
    sCity: Yup.string().trim().strict(true).notRequired(),
    sPoBox: Yup.string()
        .trim()
        .strict(true)
        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .nonNullable()
        .notRequired(),
    contractPDF: Yup.mixed().notRequired()
});

const ContractView = (props: any) => {
    const { t } = useTranslation();
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { register, control, handleSubmit, reset, getValues, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const [isValid, setisValid] = useState<any>(false);
    let [citySmartSearch, setCitySmartSearch] = useState<any>(undefined);
    const [file, setFiles] = useState<any[]>([]);
    // const [fileres, setFileres] = useState<any>('');
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [isFile, setisFile] = useState<any>(false);
    const [attachments, setattachments] = useState<any>('');
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const [showModal, setShowModal] = useState(false);
    const [funderNote, setFunderNote] = useState<any>('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [isEditing, setisEditing] = useState(false);
    const [funderName, setFunderName] = useState<any>('');
    const { Data } = useSelector((state: RootState) => state.navigation);

    useEffect(()=>{
        props.updateData({target:{type: 'clear'}})
        setFunderName('');
        setValue('fEmailId', '');
        reset()
    },[Data])

    useEffect(() => {
        setValue('fFunder', '');
        setValue('fEmailId', '');
        setValue('fContactNo', '');
        setValue('fAddress1', '');
        setValue('fsuid', '');
        setValue('fCity', '');
        setValue('fPoBox', '');
        setValue('ftype', '');
        setValue('fpo', '');
        setValue('cFunder', '');
        setValue('cEmailId', '');
        setValue('cContactNo', '');
        setValue('cAddress1', '');
        setValue('cCity', '');
        setValue('cPoBox', '');

        setValue('sFunder', '');
        setValue('sEmailId', '');
        setValue('sContactNo', '');
        setValue('sAddress1', '');
        setValue('sCity', '');
        setValue('sPoBox', '');
        if (props.isdisplay === true) {
            setValue('contractPDF', [], { shouldValidate: true });
        } else {
            setValue('contractPDF', []);
        }
        setFiles([]);

        setValue('fCheck', false);
        // setFileres('');
    }, [props.isdisplay]);

    useEffect(() => {
        setValue('ftype', props.fundertype?.toString());
    }, [props.fundertype]);
    useEffect(() => {
        if (props.changetype) {
            setValue('fFunder', '');
            setValue('fEmailId', '');
            setValue('fContactNo', '');
            setValue('fAddress1', '');
            setValue('fsuid', '');
            setValue('fCity', '');
            setValue('fPoBox', '');
            setValue('fpo', '');
            setValue('cFunder', '');
            setValue('cEmailId', '');
            setValue('cContactNo', '');
            setValue('cAddress1', '');
            setValue('cCity', '');
            setValue('cPoBox', '');

            setValue('sFunder', '');
            setValue('sEmailId', '');
            setValue('sContactNo', '');
            setValue('sAddress1', '');
            setValue('sCity', '');
            setValue('sPoBox', '');
        }
    }, [props.changetype]);

    useEffect(() => {
        if (getValues('ftype') === '4') {
            const isexists = [...props.selectedfunder].some((val: any,i:any) => {
                if (val.funderData.fFunder === props.selfData.FullName){
                    
                    if(DataLeadInfo?.data?.contract[i]?.status !== "" && DataLeadInfo !== ""){
                        if(DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled'){
                            return false
                        } else{
                            return true
                        }
                    } else{
                        return true
                    }
                } else {
                    return false;
                }
        })
            if (isexists) {
             swal('Funder is already added', {
                 icon: 'warning',
                 dangerMode: true
             });
      
     }
     else{
        setTimeout(() => {
            setValue('fFunder', props.selfData.FullName, { shouldValidate: true });
            setValue('fEmailId', props.selfData.EmailId, { shouldValidate: true });
            setValue('fContactNo', props.selfData.ContactNo, { shouldValidate: true });
            setValue('fAddress1', props.selfData.Address1, { shouldValidate: true });
            setValue('fCity', props.selfData.City, { shouldValidate: true });
            setValue('fPoBox', props.selfData.PoBox, { shouldValidate: true });
            setValue('fsuid', props.selfData.Suid);
        }, 200);
     }
          
        }
    }, [props.selfData, props.fundertype]);

    useEffect(() => {
        if (!props.isCheck) {
            setValue('fCheck', false);
        }
    }, [props.isCheck]);

    useEffect(() => {
        setValue('contractPDF', file);
    }, [file]);
    useEffect(() => {
        setValue('contractPDF', file);
    }, [file]);

    useEffect(() => {
        
        if (!isValid) {
        }

        if (formState.isValid) {
            setisValid(true);
            props.getcontracterror(formState.isValid);
        } else {
            setisValid(false);

            props.getcontracterror(formState.isValid);
        }
    }, [formState]);

    useEffect(() => {
        if (props.commissionerData != '') {
            setValue('cFunder', props.commissionerData.cFunder || '', { shouldValidate: true });
            setValue('cEmailId', props.commissionerData.cEmailId || '', { shouldValidate: true });
            setValue('cContactNo', props.commissionerData.cContactNo || '', { shouldValidate: true });
            setValue('cAddress1', props.commissionerData.cAddress1 || '');
            setValue('cCity', props.commissionerData.cCity || '');
            setValue('cPoBox', props.commissionerData.cPoBox || '');
        }
    }, [props.commissionerData]);

    const updateFileds = (e: any,fid:any) => {
        if(DataLeadInfo?.data?.contract?.length > 0){
            for(let i=0; i<DataLeadInfo?.data?.contract?.length; i++){
                props.selectedfunder[i].status = DataLeadInfo?.data?.contract[i]?.status
            }
        }
        const isexists = [...props.selectedfunder].filter((el => (el.status !== 'Voided' && el.status !== 'Cancelled'))).some((val: any,i:any) => {
            if(fid?.funderTypeId !== Number(val?.funderData?.ftype)){
            if (val.funderData.fFunder === e.target.value){
                
                if(DataLeadInfo?.data?.contract[i]?.status !== "" && DataLeadInfo !== ""){
                    if(DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled'){
                        return false
                    } else{
                        return true
                    }
                } else{
                    return true
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    })
        if (isexists) {
                swal('Funder is already added', {
                    icon: 'warning',
                    dangerMode: true
                });
                props.updateData(e);
                setValue('fFunder','',{shouldValidate: true});
                setValue('fEmailId', '', { shouldValidate: true });
                setValue('fContactNo', '', { shouldValidate: true });
                setValue('fAddress1', '', { shouldValidate: true });
                setValue('fCity', '', { shouldValidate: true });
                setValue('fPoBox', '', { shouldValidate: true });
                setValue('fsuid', '');
                setValue('fpo', '');
        }
        else{
            for (let i = 0; i < DataFunderInfo?.data?.length; i++) {
                if (DataFunderInfo?.data[i]?.key === Number(e.target.value)) {
                    setValue('fEmailId', DataFunderInfo.data[i].funderEmail || '', { shouldValidate: true });
                    setValue('fContactNo', DataFunderInfo.data[i].funderPhoneNo || '', { shouldValidate: true });
                    setValue('fAddress1', DataFunderInfo.data[i].funderAddressLine1 || '', { shouldValidate: true });
                    setValue('fCity', DataFunderInfo.data[i].funderCityId?.toString() || '', { shouldValidate: true });
                    setValue('fPoBox', DataFunderInfo.data[i].funderPobox || '', { shouldValidate: true });
                    setFunderName(DataFunderInfo.data[i].value);
                    props.updateData(e);
                }
            }
        }
       
    };

    const removepdf = (e: any, i: number) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove the attachment?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                // const payload = { path: fileres };
                const payload = {};
                const response = await deleteContactDocument(payload);
                if (response.status == true) {
                    setFiles([]);
                    attachments.splice(i,1);
                    // setFileres('');
                    props.updatefilepond();
                } else {
                }
            }
        });
    };

    const openpdf = async (e: any, i: number) => {
        e.preventDefault();
        // const payload = { path: fileres };
        const payload = { path: attachments[i].filePath };
        const response = await getContactDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    const openFileupload = () => {
        if (isFile) {
            setisFile(false);
        } else {
            setisFile(true);
        }
    };

    useEffect(() => {
        if(attachments?.length > 0){
            props.updateFData(attachments);
        }
    }, [attachments]);

    const rmarr = () =>{
      setattachments('');
    }
    const hideForm = () =>{
        props.hideform(true);
    }

    useEffect(() => {
        let objCitySmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`
        };
        setCitySmartSearch(objCitySmartSearch);
    }, []);
    const handleNoteClickModal = (noteval:any) => {
        setFunderNote(noteval);
        setShowModal(true);
    }
    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
            .then(async (confirm: any) => {
                if (confirm) {
                    setShowModal(false);
                }
            })
    };

    const updateNote = (e: any,i: any=0) => {           
        if(e.target.rawValue.length > 0)
        {
            setFunderNote(e.target.value);
            setisEditing(true);
        }else{
            setFunderNote('');
            setisEditing(false);
        }
        
        setShowModal(false);
        props.updateData(e);
    }

    return (
        <>
            {props.isdisplay === true && (
                <>
                {props.isback === true &&
                    <div className='text-right'>
                        <a onClick={hideForm} className="btn-icon gray-background" data-tooltip-id="my-tooltip-back" data-tooltip-content="Back">
                        <i className="fa-solid fa-arrow-left"></i></a>
                    </div>
                }
                    <div className="d-flex justify-content-between mb-1 mt-2">
                        <label className="mb-2">{t('VacantRightbar.ftype')}</label>

                        <label className="form--radio-label mb-2  d-flex align-items-center">
                            <input
                                type="radio"
                                {...register('ftype', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                        setFunderName('');
                                    }
                                })}
                                name={'ftype' as const}
                                id="CCG"
                                value="1"
                            />

                            <span className="ms-1">{t('VacantRightbar.ccg')}</span>
                        </label>

                        <label className="form--radio-label  mb-2 d-flex align-items-center">
                            <input
                                type="radio"
                                {...register('ftype', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                        setFunderName('');
                                    }
                                })}
                                name={'ftype' as const}
                                id="LA"
                                value="2"
                            />

                            <span className="ms-1">{t('VacantRightbar.la')}</span>
                        </label>

                        <label className="form--radio-label  mb-2 d-flex align-items-center">
                            <input
                                type="radio"
                                {...register('ftype', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                        setFunderName('');
                                    }
                                })}
                                name={'ftype' as const}
                                id="Charity"
                                value="3"
                            />

                            <span className="ms-1">{t('VacantRightbar.ch')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center">
                            <input
                                type="radio"
                                {...register('ftype', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                        setFunderName('');
                                    }
                                })}
                                name={'ftype' as const}
                                id="Self"
                                value="4"
                            />

                            <span className="ms-1">{t('VacantRightbar.self')}</span>
                        </label>
                    </div>
                    {getValues('ftype') !== '4' && (
                        <>
                            <div className="d-flex mb-2">
                                <select
                                    {...register('fFunder', {
                                        onChange: (e: any) => {
                                            console.log(props?.funderlist);
                                            let fid = props?.funderlist?.find((data:any)=>data.key === Number(e.target.value))
                                            updateFileds(e,fid);
                                        }
                                    })}
                                    name={'fFunder' as const}
                                    id="Funder"
                                    //@ts-ignore
                                    className={`form-control white flex-7 ${errors.fFunder ? 'is-invalid' : ''}`}
                                >
                                    <option disabled={true} value="">
                                        {t('VacantRightbar.fname')}
                                    </option>
                                    {props.funderlist &&
                                        props.funderlist.map((funders: any) => {
                                            return (
                                                <option key={funders.key} value={funders.key}>
                                                    {funders.value}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </>
                    )}
                    {getValues('ftype') === '4' && (
                        <>
                            <div className="d-flex mb-2">
                                <input
                                    type="text"
                                    disabled
                                    {...register('fFunder')}
                                    name={'fFunder' as const}
                                    placeholder={`${t('VacantRightbar.fname')}`}
                                    //@ts-ignore
                                    className={`form-control white flex-7 ${errors.fFunder ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </>
                    )}
                    <input
                        type="text"
                        {...register('fEmailId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'fEmailId' as const}
                        placeholder={`${t('VacantRightbar.email')}*`}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.fEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <input
                            type="text"
                            {...register('fContactNo', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fContactNo' as const}
                            placeholder={`${t('VacantRightbar.contact')}*`}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.fContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('fAddress1', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'fAddress1' as const}
                        placeholder={`${t('VacantRightbar.add1')}*`}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.fAddress1 ? 'is-invalid' : ''}`}
                    />
                    <div className="d-flex justify-content-between">
                        <select
                            {...register('fCity', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fCity' as const}
                            id="city"
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.fCity ? 'is-invalid' : ''}`}
                        >
                            <option disabled={true} value="">
                                {t('VacantRightbar.county')}*
                            </option>
                            {props.citylist &&
                                props.citylist?.data?.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        {/* {citySmartSearch !== undefined && (
                            <div className="auto-complete-dropdown">
                                <SmartSearchDropdownContainer
                                    smartSearchLabel={t('VacantRightbar.county')}
                                    errors={errors.city}
                                    onChangeSmartSearch={(value: any) => {
                                        const event = new Event('change');
                                        let obj = {
                                            name: 'city',
                                            value: value
                                        };
                                        //@ts-ignore
                                        Object.defineProperty(event, 'target', { writable: false, value: obj });
                                        setValue('city', value?.id, { shouldValidate: true });
                                        if (value !== '') {
                                            props.updateData(event);
                                        }
                                    }}
                                    smartSearch={citySmartSearch}
                                />
                            </div>
                        )} */}
                        <input
                            type="text"
                            {...register('fPoBox', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fPoBox' as const}
                            placeholder={`${t('VacantRightbar.postal')}*`}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.fPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('fsuid', {
                            onChange: (e: any) => {                                
                                props.updateData(e);
                            }
                        })}
                        name={'fsuid' as const}
                        placeholder={`${t('VacantRightbar.Suid')}`}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        maxLength={40}
                    />

<input
                        type="text"
                        {...register('fpo', {
                            onChange: (e: any) => {                                
                                props.updateData(e);
                            }
                        })}
                        name={'fpo' as const}
                        placeholder={`${t('VacantRightbar.po_reference')}`}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        maxLength={40}
                    />
                    
                    { funderNote?.length == 0 && <div className='d-flex mt-2'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal('')}></img><p>{t('VacantRightbar.enter_note')}</p></div>}
                    { window.$utils.convertToPlain(funderNote)?.length >= 30 && <div className='d-flex mt-2'><img src={note} className='img-fluid me-1'></img><p>{window.$utils.convertToPlain(funderNote).slice(0,27)}...</p>  <p className='bold' onClick={()=>handleNoteClickModal(funderNote)}>{t('VacantRightbar.read_more')}</p></div>} 
                    { window.$utils.convertToPlain(funderNote)?.length > 0 && window.$utils.convertToPlain(funderNote)?.length < 30 && (<div className='d-flex mt-2'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(funderNote)}></img><p>{window.$utils.convertToPlain(funderNote)}</p></div> )}
                    {getValues('ftype') !== '4' &&
                    <>
                    <label className="mb-2 bold mt-3">{t('VacantRightbar.comm')}</label>
                    <div className="form-check">
                        <input
                            {...register('fCheck', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fCheck' as const}
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="checkboxsameas"
                        />
                        <label className="form-check-label">{t('VacantRightbar.same')}</label>
                    </div>

                    <input
                        type="text"
                        {...register('cFunder', {
                            onChange: (e: any) => {
                                props.updateCData(e);
                            }
                        })}
                        name={'cFunder' as const}
                        placeholder={`${t('VacantRightbar.cname')}*`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.cFunder ? 'is-invalid' : ''}`}
                    />

                    <input
                        type="text"
                        {...register('cEmailId', {
                            onChange: (e: any) => {
                                props.updateCData(e);
                            }
                        })}
                        name={'cEmailId' as const}
                        placeholder={`${t('VacantRightbar.email')}*`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.cEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <input
                            type="text"
                            {...register('cContactNo', {
                                onChange: (e: any) => {
                                    props.updateCData(e);
                                }
                            })}
                            name={'cContactNo' as const}
                            placeholder={`${t('VacantRightbar.contact')}*`}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.cContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('cAddress1', {
                            onChange: (e: any) => {
                                props.updateCData(e);
                            }
                        })}
                        name={'cAddress1' as const}
                        placeholder={`${t('VacantRightbar.add1')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.cAddress1 ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <select
                            {...register('cCity', {
                                onChange: (e: any) => {
                                    props.updateCData(e);
                                }
                            })}
                            name={'cCity' as const}
                            id="ccity"
                            //@ts-ignore
                            className={`form-control white mb-2  ${errors.cCity ? 'is-invalid' : ''}`}
                        >
                            <option value="">{t('VacantRightbar.county')}</option>
                            {props.citylist &&
                                props.citylist.data.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        <input
                            type="text"
                            {...register('cPoBox', {
                                onChange: (e: any) => {
                                    props.updateCData(e);
                                }
                            })}
                            name={'cPoBox' as const}
                            placeholder={`${t('VacantRightbar.postal')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.cPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                    {StatusServiceDetail && DataServiceDetail.iconName !== ServiceTypeIconName.Hospital && getValues('ftype') !== '1' && (
                        <>
                            <label className="mb-2 bold mt-3">{t('VacantRightbar.social')}</label>
                            <input
                                type="text"
                                {...register('sFunder', {
                                    onChange: (e: any) => {
                                        props.updateSData(e);
                                    }
                                })}
                                name={'sFunder' as const}
                                placeholder={`${t('VacantRightbar.sname')}`}
                                //@ts-ignore
                                className={`form-control white mb-2  ${errors.sFunder ? 'is-invalid' : ''}`}
                            />

                            <input
                                type="text"
                                {...register('sEmailId', {
                                    onChange: (e: any) => {
                                        props.updateSData(e);
                                    }
                                })}
                                name={'sEmailId' as const}
                                placeholder={`${t('VacantRightbar.email')}`}
                                //@ts-ignore
                                className={`form-control white mb-2  ${errors.sEmailId ? 'is-invalid' : ''}`}
                            />

                            <div className="d-flex justify-content-between">
                                <input
                                    type="text"
                                    {...register('sContactNo', {
                                        onChange: (e: any) => {
                                            props.updateSData(e);
                                        }
                                    })}
                                    name={'sContactNo' as const}
                                    placeholder={`${t('VacantRightbar.contact')}`}
                                    //@ts-ignore
                                    className={`form-control white mb-2 ${errors.sContactNo ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <input
                                type="text"
                                {...register('sAddress1', {
                                    onChange: (e: any) => {
                                        props.updateSData(e);
                                    }
                                })}
                                name={'sAddress1' as const}
                                placeholder={`${t('VacantRightbar.add1')}`}
                                //@ts-ignore
                                className={`form-control white mb-2  ${errors.sAddress1 ? 'is-invalid' : ''}`}
                            />

                            <div className="d-flex justify-content-between">
                                <select
                                    {...register('sCity', {
                                        onChange: (e: any) => {
                                            props.updateSData(e);
                                        }
                                    })}
                                    name={'sCity' as const}
                                    id="ccity"
                                    //@ts-ignore
                                    className={`form-control white mb-2  ${errors.sCity ? 'is-invalid' : ''}`}
                                >
                                    <option value="">{t('VacantRightbar.county')}</option>
                                    {props.citylist &&
                                        props.citylist.data.map((cities: any) => {
                                            return (
                                                <option key={cities.key} value={cities.key}>
                                                    {cities.value}
                                                </option>
                                            );
                                        })}
                                </select>
                                <input
                                    type="text"
                                    {...register('sPoBox', {
                                        onChange: (e: any) => {
                                            props.updateSData(e);
                                        }
                                    })}
                                    name={'sPoBox' as const}
                                    placeholder={`${t('VacantRightbar.postal')}`}
                                    //@ts-ignore
                                    className={`form-control white mb-2 ms-2 ${errors.sPoBox ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </>
                    )}
                    </>
                    }
                    <div className="d-flex justify-content-center mt-2 mb-3">
                        <a onClick={openFileupload} className="btn btn-secondary btn-sm">
                            <i className="fa-solid fa-paperclip"></i> {t('Generate_Contract.Attach_btn')}
                        </a>
                    </div>
                    <div className="d-flex" style={{ pointerEvents: props.condata !== undefined && props.patientname != '' ? 'all' : 'none' }}>
                        <div className="w-100">
                            {isFile && (
                                <>
                                    <Controller
                                        control={control}
                                        name="contractPDF"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <FilePond
                                                    files={file}
                                                    onupdatefiles={setFiles}
                                                    // @ts-ignore
                                                    allowFileSizeValidation={true}
                                                    maxFileSize={10000000}
                                                    allowFileTypeValidation={true}
                                                    acceptedFileTypes={[
                                                        'application/pdf',
                                                        'image/*',
                                                        'application/vnd.ms-excel',
                                                        'application/vnd.ms-powerpoint',
                                                        'application/msword',
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                    ]}
                                                    labelMaxFileSizeExceeded={'File is too large'}
                                                    fileValidateTypeLabelExpectedTypes={''}
                                                    name={'contractPDF'}
                                                    labelIdle={`${t('VacantRightbar.pdftitle1')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                    server={{
                                                        url: `${BASEURLDEV}${API_ENDPOINT.UploadDocument}`,
                                                        process: {
                                                            headers: {
                                                                Authorization: `Bearer ${token}`
                                                            },
                                                            ondata: (formData) => {
                                                                formData.append('patientName', props.patientname);
                                                                formData.append('funderName', props.condata);
                                                                return formData;
                                                            },
                                                            //@ts-ignore
                                                            onload: (res: any) => {
                                                                const fileres = JSON.parse(res);
                                                                let filedata = fileres.data;
                                                                // setFileres(fileres.data.filePath);
                                                                setattachments([...attachments, fileres.data]);
                                                                setisFile(false);
                                                                setFiles([]);
                                                                // setValue('funderContract', filedata.fileName, { shouldValidate: true });
                                                                // props.updateFData({ file, filedata });
                                                            }
                                                        },
                                                        remove: (source, load, error) => {
                                                            error('oh my goodness');
                                                            load();
                                                        }
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                    <p className="small mt-2">
                                        <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note2')}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>

                    {/* <div>
                        <div className='yellow-box mb-3'>
                            <p>{t('Generate_Contract.Contract_Sent_Funder')}</p>
                        </div>
                        <div className='d-flex mt-2 mb-3'>
                            <a onClick={handleClickModal} className='btn btn-primary btn-sm me-2'>{t('Generate_Contract.View_Contract_btn')}</a>
                            <a href="#" className='btn btn-secondary btn-sm'><i className="fa-solid fa-paperclip"></i> {t('Generate_Contract.Attach_btn')} </a>
                        </div>
                    </div>

                    <div>
                        <div className='yellow-box mb-3'>
                            <p>{t('Generate_Contract.Contract_Progress_with_Funder')}</p>
                        </div>
                        <div className='d-flex mt-2 mb-3'>
                            <a onClick={handleClickModal} className='btn btn-primary btn-sm me-2'> {t('Generate_Contract.View_Contract_btn')} </a>
                            <a href="#" className='btn btn-secondary btn-sm'><i className="fa-solid fa-paperclip"></i> {t('Generate_Contract.Attach_btn')} </a>
                        </div>
                    </div>

                    <div>
                        <div className='yellow-box mb-3'>
                            <p>{t('Generate_Contract.Contract_signed_by_Funder')}</p>
                        </div>
                        <div className='d-flex mt-2 mb-3'>
                            <a onClick={handleClickModal} className='btn btn-primary btn-sm me-2'>  {t('Generate_Contract.View_Contract_Sign')} </a>
                            <a href="#" className='btn btn-secondary btn-sm'><i className="fa-solid fa-paperclip"></i> {t('Generate_Contract.Attach_btn')}</a>
                        </div>
                    </div>

                    <div>
                        <div className='green-box mb-3'>
                            <p>{t('Generate_Contract.Contract_completed')}</p>
                        </div>
                        <div className='d-flex mt-2 mb-3'>
                            <a onClick={handleClickModal} className='btn btn-primary btn-sm me-2'> {t('Generate_Contract.View_Contract_btn')}</a>
                            <a href="#" className='btn btn-secondary btn-sm'><i className="fa-solid fa-paperclip"></i> {t('Generate_Contract.Attach_btn')}</a>
                        </div>
                    </div> */}

                    {/* <p className="small mt-2">
                        <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note2')}
                    </p> */}

                    {attachments?.length > 0 && (
                        <>
                            <p className="purple-text mt-3 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                            {attachments?.map((data: any, i: number) => {
                                return (
                                    <>
                                        <div className="d-flex justify-content-between mt-2 contract-attachments">
                                            <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Contract Document ">
                                                {/* <textarea rows={1} {...register(`funderContract`, {})} name={`funderContract` as const} onClick={(e: any) => openpdf(e)} readOnly /> */}
                                                <a onClick={(e: any) => openpdf(e, i)} >{data.fileName}</a>
                                            </div>

                                            <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, i)}>
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        </div>
                                    </>
                                );
                            })}
                        </>
                    )}
                    {/* <div className="d-flex justify-content-between mb-2">
                        <p className="text-wrap">Sama_Camden_30062023123524087.pdf</p>
                        <button className="btn-icon red-background" name="btndelete" data-tooltip-id="my-tooltip-delete" data-tooltip-content="Delete">
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <p className="text-wrap">Sama_Camden_30062023123524087.pdf</p>
                        <button className="btn-icon red-background" name="btndelete" data-tooltip-id="my-tooltip-delete" data-tooltip-content="Delete">
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="text-wrap">Sama_Camden_30062023123524087.pdf</p>
                        <button className="btn-icon red-background" name="btndelete" data-tooltip-id="my-tooltip-delete" data-tooltip-content="Delete">
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div> */}

                    <div className="mt-3 text-center">
                        <button disabled={!isValid} onClick={() => {props.addFunder();rmarr()}} className="btn btn-sm btn-primary flex-1 me-2" id="save-funder1">
                            Add Funder
                        </button>
                    </div>
                    <Tooltip id="my-tooltip-back" className="my-tooltip tooltip-custom-width" />
                    <RenderModalContainer>
                            {showModal && <NoteContainer  onClose={toggle} title={funderName != null && funderName != '' ? funderName+"'s Notes" : t('VacantRightbar.funder_note')} setFunderNote={funderNote} notebody={updateNote}  isReadonly={false} isEditing={isEditing}/> }
                    </RenderModalContainer>
                </>
            )}
        </>
    );
};

export default ContractView;
