import React from 'react';
import NHSStagesView from './NHSStagesView';

const NHSStagesContainer = () => {
    return (
        <div>
            <NHSStagesView/>
        </div>
    );
};

export default NHSStagesContainer;