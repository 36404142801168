import React, { useEffect, useState } from 'react';
import InvoiceDetailView from './InvoiceDetailView';
import { InvoiceStatus } from '../../../utils/constant';
import { cancelInvoice, checkSplitFunding, getInvoiceData, saveInvoiceData, saveSplitFunding, splitInvoiceData, updateInvoiceData, } from '../../../service/invoicedetail-service';
import swal from 'sweetalert';
import warning from '../../../assets/images/warning.png';
import { useSelector, useDispatch } from 'react-redux';
import { rightNavigationPage } from '../../../utils/constant';
import { setPageNavigationAction } from '../../../store/general/pageNavigationReducer';
import { RootState } from '../../../store';
import { getPlanData } from '../../../service/dashboard-service';
import { setTempSplitFundingAction } from '../../../store/splitFunding/tempSplitFundingReducer';
import { InvoiceFilterInvoiceStatus } from '../../../utils/constant';
import { UpdateInvoiceStatusService} from '../../../service/invoicelist-service';
import moment from 'moment';
import eventEmitter from '../../../utils/eventEmitter';

const InvoiceDetailContainer = (props: any) => {
    const dispatch = useDispatch();
    let [invoicedata, setinvoicedata] = useState<any>();
    const [issave, Setsave] = useState(false);
    const [iscancel, Setcancel] = useState(false);
    let [invoiceline, setinvoiceline] = useState<any>([]);
    let [invoiceDraftStatus, setInvoiceDraftStatus] = useState<any>(true);
    let [invoicepagedata, setinvoicepagedata] = useState<any>();
    let [iserror, seterror] = useState<boolean>(false);
    let [deletedline, setdeletedline] = useState<any>([]);
    let [admissionid, setadmissionid] = useState<any>();
    let [invoiceid, setinvoiceid] = useState<any>();
    let [isaddplan, setisaddplan] = useState<boolean>(false);
    let [OldInvoiceGenerate,setOldInvoiceGenerate] = useState<boolean>(false);
    let [datavalid,setdatavalid] = useState<boolean>(false);
    let [lineerror,setlineerror] = useState<boolean>(false);
    let [duplicate,setduplicate]=useState<boolean>(false);
    let [refreshproducts,setrefreshproducts]=useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    const { PageName, Data } = useSelector((state: RootState) => state.pageNavigation);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    let plans: any = [];
    let funders: any = [];
    var planlist:any=[];


    async function Getinvoicedetail(id: any) {
        const result = await getInvoiceData(id);
        if (result.status === true) {
            setinvoicedata(result.data);
        }
        else{
            setinvoicedata(undefined);
        }
    }

    useEffect(()=>{
      
    },[duplicate])

    useEffect(()=>{  
        
      setduplicate(props.duplicateStatus);
    },[props.duplicateStatus])

    useEffect(()=>{
        if (invoiceid !== undefined) {
            Getinvoicedetail(invoiceid);
        } else {
            setinvoicedata(undefined);
        }
    },[invoiceid])

    useEffect(() => {
        
      
        if (props.invoiceid !== undefined ) {
            Getinvoicedetail(props.invoiceid);
        }
        else {
            setinvoicedata(undefined);
        }
    }, [props.invoiceid]);

    useEffect(() => {
        
        if (invoicedata !== undefined) {
            if (invoicedata?.invoiceModel?.invoiceStatus === InvoiceStatus.Draft) {
                setInvoiceDraftStatus(true);
            } else if (invoicedata?.invoiceModel?.invoiceStatus === InvoiceStatus.Cancelled) {
                if(!duplicate){
                    setInvoiceDraftStatus(false);
                    Setcancel(true);
                }
                else{
                    setInvoiceDraftStatus(true);
                    Setcancel(false);
                }

            }
            else{
                if(!duplicate){
                    setInvoiceDraftStatus(false);
                    Setsave(true);
                }
                else{
                    setInvoiceDraftStatus(true);
                    Setsave(false);
                }

            }
        } else {
            setInvoiceDraftStatus(true);
        }
    }, [invoicedata]);

    const saveHandler = () => {
        saveconfirm();
    };
    const cancelHandler = () => {
        // Setcancel(true);
        cancelconfirm();
    };

    const isdatavalid = (dataerror:boolean) =>{
        setdatavalid(dataerror);

    }

    const getInvoicelineerror = (error: boolean) => {
        setlineerror(error);

    };

    useEffect(()=>{
        
        
        
        
        if(datavalid && lineerror && invoiceline.length > 0){
            seterror(true);
        }
        else{
            seterror(false);
        }

    },[datavalid,lineerror,invoiceline]);



    const getInvoicelines = (lines: any) => {
        if (lines.length > 0) {
            setinvoiceline(lines);
        } else {
            setinvoiceline([]);
        }
    };

    const getinvoicedata = (data: any) => {
        
        setinvoicepagedata(data);
    };

    const getdeletedline = (deleteline: any) => {
        let deletedInvoiceList = [...deletedline, deleteline]
        if (deleteline !== undefined && deletedInvoiceList.length > 0) {
            setdeletedline(deletedInvoiceList);
        } else {
            setdeletedline([]);
        }
    };

    const getadmissionid = (id: any) => {
        
        setadmissionid(id);
    };

    const split = () => {
        splitconfirm();
    };

    const splitconfirm = () => {
        swal({
            title: '',
            text: `Are you sure you want to confirm invoice ${invoicedata?.invoiceModel?.invoicePattern}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                splitinvoice();
            }
        });
    };

    const cancelconfirm = () => {        
        swal({
            title: '',
            text: invoicedata?.invoiceModel?.invoicePattern != undefined ? `Are you sure you want to cancel invoice ${invoicedata?.invoiceModel?.invoicePattern}?` : 'Are you sure you want to cancel invoice?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                cancelinvoice();
            }
        });
    };

    async function cancelinvoice() {
        if (invoiceid !== undefined) {
            const result = await cancelInvoice(invoiceid);
            if (result.status) {
                Setcancel(true);
                Getinvoicedetail(invoiceid);
                swal(result?.message, {
                    icon: 'success'
                });
            } else {
                swal(result?.message, {
                    icon: 'error'
                });
            }

        } else if (props.invoiceid !== undefined) {
            const result = await cancelInvoice(props.invoiceid);
            if (result.status) {
                Setcancel(true);
                Setsave(false);
                Getinvoicedetail(props.invoiceid);
                swal(result?.message, {
                    icon: 'success'
                });
            } else {
                swal(result?.message, {
                    icon: 'error'
                });
            }
        }
    }

    async function splitinvoice() {
        if (invoiceid !== undefined) {
            const result = await splitInvoiceData(invoiceid);
            if (result.status) {
                Setsave(true);
                setInvoiceDraftStatus(false);
                Getinvoicedetail(invoiceid);
                swal(result?.message, {
                    icon: 'success'
                });
            } else {
                swal(result?.message, {
                    icon: 'error'
                });
            }
        } else if (props.invoiceid !== undefined) {
            const result = await splitInvoiceData(props.invoiceid);
            if (result.status) {
                Setsave(true);
                setInvoiceDraftStatus(false);
                Getinvoicedetail(props.invoiceid);
                swal(result?.message, {
                    icon: 'success'
                });
            } else {
                swal(result?.message, {
                    icon: 'error'
                });
            }
        }
    }

    const saveconfirm = () => {
        swal({
            title: '',
            text: invoicedata?.invoiceModel?.invoicePattern != undefined ?`Are you sure you want to save invoice data ${invoicedata?.invoiceModel?.invoicePattern}?`:`Are you sure you want to save invoice data?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                // savedatapayload();
                checksplit();
            }
        });
    };

    async function checksplit() {
        let data = {
            invoiceHeaderId: duplicate ? 0 :invoicedata?.invoiceModel?.invoiceHeaderId || 0,
            admissionId: invoicedata?.admissionDetail?.admissionId || admissionid,
            funderId: invoicepagedata.funder !== null ? Number(invoicepagedata.funder) : invoicepagedata.funder,
            funderAddress: invoicepagedata?.funderadd,
            periodFrom: invoicepagedata?.periodfrom ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.periodfrom) : '',
            periodTo: invoicepagedata?.periodto ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.periodto) : '',
            invoiceDate: invoicepagedata?.invoicedate ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.invoicedate) : '',
            dueDate: invoicepagedata?.duedate ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.duedate) : '',
            paymentReference: invoicepagedata?.paymentref,
            documentPath: invoicepagedata.documentPath,
            documentName: invoicepagedata.documentName,
            notes: invoicepagedata?.notes
        };
        const result = await checkSplitFunding(data);
        
        if (!result.status) {
            swal({
                title: '',
                text: result?.message + 'Are you sure you want to define split funding?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    getplandata(admissionid);
                }
            });
        }
        else{
            if(result.message !== 'Success'){
                swal({
                    title: '',
                    text: result?.message,
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        // setOldInvoiceGenerate(true);
                        // setTimeout(() => {
                            savedatapayload(true);
                        // }, 200);
                    }
                    else{
                        savedatapayload(false);
                    }
                });
            }
            else{
                savedatapayload(false);
            }

        }
    }

    async function getplandata(aid: any) {
        const response = await getPlanData(aid);
        if (response.status == true) {
            plans = [];
            for (let j = 0; j < response.data.length; j++) {
                // if (response.data[j].planType == 0) {
                    let obj: any = {};
                    funders = [];
                    obj.corefee = response?.data[j]?.totalFees;
                    obj.admissiondate = response?.data[j]?.startDate?.toString();
                    obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                    obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                    obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                    obj.planId = response?.data[j]?.planId;
                    obj.lastinvoicedate = response?.data[j]?.lastInvoiceCreatedDate?.toString();
                    obj.dailyfee = response?.data[j]?.dailyCoreFeeType === 1 ? Number(Number(response?.data[j]?.dailyCoreFee) * 7).toFixed(2) : Number(response?.data[j]?.dailyCoreFee);
                    obj.continencefee= response?.data[j]?.continenceCareFeeType === 1 ? Number(Number(response?.data[j]?.continenceCareFee) * 7).toFixed(2) : Number(response?.data[j]?.continenceCareFee);
                    obj.fncfee = response?.data[j]?.fncFeeType === 1 ? Number(Number(response?.data[j]?.fncFee) * 7).toFixed(2) : Number(response?.data[j]?.fncFee);
                    obj.totalFees= response?.data[j]?.totalFees;

                    obj.continencefeetype = response?.data[j]?.continenceCareFeeType; 
                    obj.fncfeetype = response?.data[j]?.fncFeeType;
                    obj.dailyfeetype= Number(response?.data[j]?.calculatedDailyCoreFee);
                    obj.totalAdditionalServiceFee = response?.data[j]?.totalAdditionalServiceFee

                    for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                        funders.push({
                            planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                            funderid: response?.data[j]?.planDetails[i].funderId,
                            fundername: response?.data[j]?.planDetails[i].funderName,
                            fundertype:
                                response?.data[j]?.planDetails[i].funderType === 1
                                    ? 'ICB'
                                    : response?.data[j]?.planDetails[i].funderType === 2
                                    ? 'LA'
                                    : response?.data[j]?.planDetails[i].funderType === 3
                                    ? 'Charity'
                                    : 'Self',
                            proportion: response?.data[j]?.planDetails[i].proportion,
                            proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                            isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                            ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                        });

                        obj.funderlist = funders;
                    }
                    plans = [...plans, obj];
                    dispatch(setTempSplitFundingAction(plans));
                    setShowModal(true);
                // }
            }
        }
    }

    async function savedatapayload(InvoiceGenerate:boolean) {
        let invoicelinelist = [];
        if (invoiceline.length > 0) {
            for (let i = 0; i < invoiceline.length; i++) {
                let obj = {
                    invoiceLineId: invoiceline[i].invoiceLineId,
                    invoiceHeaderId: invoiceline[i].invoiceHeaderId,
                    productId: Number(invoiceline[i].product),
                    quantity: Number(invoiceline[i].quantity),
                    unitId: Number(invoiceline[i].uom),
                    price: Number(invoiceline[i].price),
                    untaxedAmount: invoiceline[i].untaxedAmount,
                    taxPercentage: invoiceline[i].taxPercentage,
                    taxedAmount: invoiceline[i].taxedAmount,
                    discountAmount: invoiceline[i].discountAmount,
                    subTotal: Number(invoiceline[i].subtotal),
                    isAutoGenerated: invoiceline[i].action ? 1 : 0,
                    isDeleted: 0
                };

                invoicelinelist.push(obj);
            }
        }
        if (deletedline.length > 0) {
            for (let i = 0; i < deletedline.length; i++) {
                let obj = {
                    invoiceLineId: deletedline[i].invoiceLineId,
                    invoiceHeaderId: deletedline[i].invoiceHeaderId,
                    productId: Number(deletedline[i].productId),
                    quantity: Number(deletedline[i].quantity),
                    unitId: Number(deletedline[i].unitId),
                    price: Number(deletedline[i].price),
                    untaxedAmount: deletedline[i].untaxedAmount,
                    taxPercentage: deletedline[i].taxPercentage,
                    taxedAmount: deletedline[i].taxedAmount,
                    discountAmount: deletedline[i].discountAmount,
                    subTotal: Number(deletedline[i].subTotal),
                    isAutoGenerated: deletedline[i].action ? 1 : 0,
                    isDeleted: 1
                };

                invoicelinelist.push(obj);
            }
        }


        let savepayload = {
            ...(invoicedata === undefined && { isOldInvoiceGenerate: InvoiceGenerate }),
            invoiceHeaderId: duplicate ? 0 : invoicedata?.invoiceModel?.invoiceHeaderId || 0,
            admissionId: invoicedata?.admissionDetail?.admissionId || admissionid,
            funderId: invoicepagedata.funder !== null ? Number(invoicepagedata.funder) : invoicepagedata.funder,
            funderAddress: invoicepagedata.funderadd,
            periodFrom: invoicepagedata?.periodfrom ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.periodfrom) : '',
            periodTo: invoicepagedata?.periodto ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.periodto) : '',
            invoiceDate: invoicepagedata?.invoicedate ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.invoicedate) : '',
            dueDate: invoicepagedata?.duedate ? window.$utils.dateFormatDDMMYYYY(invoicepagedata?.duedate) : '',
            paymentReference: invoicepagedata?.paymentref,
            documentPath: invoicepagedata.documentPath,
            documentName: invoicepagedata.documentName,
            notes: invoicepagedata?.notes,
            invoiceLines: invoicelinelist
        };

        //Warning message "Quantity doesn't match" should be shown to the user.
        let perDayRate = invoicelinelist.filter((val: any) => val.productId === 1)
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let periodfromDate:any = new Date(invoicepagedata?.periodfrom);
        let periodtoDate:any = new Date(invoicepagedata?.periodto);
        let daysDiff = Math.round(Math.abs((periodfromDate - periodtoDate) / oneDay));
        daysDiff = daysDiff + 1
        let showWarningQuantityMisMatch = false;
        if(daysDiff === 0) {
            daysDiff = 1
        }
        
        
        if(Number(perDayRate[0]?.quantity) !== Number(daysDiff)) {
            showWarningQuantityMisMatch = true
        }

        if (invoicedata !== undefined && !duplicate) {
            if(showWarningQuantityMisMatch){
                swal({
                    title: '',
                    text: "Quantity does not match the Period Date range. Invoice will be created with the value entered.",
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true,
                    }).then(async (confirm: any) => {
                        if(confirm) {
                            const result = await updateInvoiceData(savepayload);
                            if (result.status) {
                                eventEmitter.emit('eventInvoiceAfterSave');
                                swal(result?.message, {
                                    icon: 'success'
                                });
                                
                            } else {
                                swal(result?.message, {
                                    icon: 'error'
                                });
                            }
                        }                    
                    });
            } else {
                const result = await updateInvoiceData(savepayload);
                if (result.status) {
                    eventEmitter.emit('eventInvoiceAfterSave');
                    swal(result?.message, {
                        icon: 'success'
                    });
                    
                } else {
                    swal(result?.message, {
                        icon: 'error'
                    });
                }
            }
            // const result = await updateInvoiceData(savepayload);
            // if (result.status) {
            //     if(showWarningQuantityMisMatch){
            //         swal({
            //             title: '',
            //             text: "Quantity does not match the Period Date range. Invoice will be created with the value entered.",
            //             icon: warning,
            //             dangerMode: true,
            //             }).then(async (confirm: any) => {
            //                 swal(result?.message, {
            //                     icon: 'success'
            //                 });                      
            //             });
            //     } else {
            //         swal(result?.message, {
            //             icon: 'success'
            //         });
            //     }
                
            // } else {
            //     swal(result?.message, {
            //         icon: 'error'
            //     });
            // }
        } else if (invoicedata === undefined) {

            if(showWarningQuantityMisMatch){
                swal({
                    title: '',
                    text: "Quantity does not match the Period Date range. Invoice will be created with the value entered.",
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true,
                    }).then(async (confirm: any) => {
                        if(confirm) {
                            const result = await saveInvoiceData(savepayload);
                            if (result.status) {
                                eventEmitter.emit('eventInvoiceAfterSave');
                                setinvoiceid(result.data?.invoiceId);
                                swal(result?.message, {
                                    icon: 'success'
                                });
                            } else {
                                swal(result?.message, {
                                    icon: 'error'
                                });
                            }
                        }         
                    });
            } else {
                const result = await saveInvoiceData(savepayload);
                if (result.status) {
                    eventEmitter.emit('eventInvoiceAfterSave');
                    setinvoiceid(result.data?.invoiceId);
                    swal(result?.message, {
                        icon: 'success'
                    });
                } else {
                    swal(result?.message, {
                        icon: 'error'
                    });
                }
            }
            // const result = await saveInvoiceData(savepayload);
            // if (result.status) {
            //     setinvoiceid(result.data?.invoiceId);
            //     if(showWarningQuantityMisMatch){
            //         swal({
            //             title: '',
            //             text: "Quantity does not match the Period Date range. Invoice will be created with the value entered.",
            //             icon: warning,
            //             dangerMode: true,
            //             }).then(async (confirm: any) => {
            //                 swal(result?.message, {
            //                     icon: 'success'
            //                 });                      
            //             });
            //     } else {
            //         swal(result?.message, {
            //             icon: 'success'
            //         });
            //     }
            // } else {
            //     swal(result?.message, {
            //         icon: 'error'
            //     });
            // }
        }else if (invoicedata !== undefined && duplicate) {

            if(showWarningQuantityMisMatch){
                swal({
                    title: '',
                    text: "Quantity does not match the Period Date range. Invoice will be created with the value entered.",
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true,
                    }).then(async (confirm: any) => {
                        if(confirm) {
                            const result = await saveInvoiceData(savepayload);
                            if (result.status) {
                                eventEmitter.emit('eventInvoiceAfterSave');
                                setduplicate(false);
                                setinvoiceid(result.data?.invoiceId);
                                swal(result?.message, {
                                    icon: 'success'
                                });
                            } else {
                                swal(result?.message, {
                                    icon: 'error'
                                });
                            }
                        }                     
                    });
            } else {
                const result = await saveInvoiceData(savepayload);
                if (result.status) {
                    eventEmitter.emit('eventInvoiceAfterSave');
                    setduplicate(false);
                    setinvoiceid(result.data?.invoiceId);
                    swal(result?.message, {
                        icon: 'success'
                    });
                } else {
                    swal(result?.message, {
                        icon: 'error'
                    });
                }
            }
            // const result = await saveInvoiceData(savepayload);
            // if (result.status) {
            //     setduplicate(false);
            //     setinvoiceid(result.data?.invoiceId);
            //     if(showWarningQuantityMisMatch){
            //         swal({
            //             title: '',
            //             text: "Quantity does not match the Period Date range. Invoice will be created with the value entered.",
            //             icon: warning,
            //             dangerMode: true,
            //             }).then(async (confirm: any) => {
            //                 swal(result?.message, {
            //                     icon: 'success'
            //                 });                      
            //             });
            //     } else {
            //         swal(result?.message, {
            //             icon: 'success'
            //         });
            //     }
            // } else {
            //     swal(result?.message, {
            //         icon: 'error'
            //     });
            // }
        }
    }

    const onClickBackHandler: Function = async () => {
        setduplicate(false);
        props.updateduplicatestatus();
        setTimeout(() => {
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
        }, 100);
       
    };



    const onClickDuplicateHandler: Function = async () => {
        let dataObj : any={};
        if(Data?.invoiceHeader_Id !== undefined){
         dataObj = Data;
        }
        else{
           dataObj = {
                "invoiceHeader_Id": invoiceid.toString()
            }
        }
        
        dataObj = { ...dataObj, PageStatus: 'duplicate' };
        
        let obj: any = {
            ShowClass: false,
            PageName: rightNavigationPage.InvoiceDetailPage,
            Data: dataObj,
            ShowToggle: false
        };
        dispatch(setPageNavigationAction(obj));
        setInvoiceDraftStatus(true);
        Setsave(false);
    };

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
                // dispatch(removeTempSplitFundingAction())
            }
        });
    };




    async function savesplitfunding() {
      let fundersdata =[];
      planlist = [];
        if(TempDataSplitFunding.length > 0){
            
            for(let i=0;i<TempDataSplitFunding.length;i++){
                let obj : any ={};
                fundersdata=[];
                if(TempDataSplitFunding[i].planId === -1){
                    obj.planId = 0;
                }
                else{
                    obj.planId = TempDataSplitFunding[i].planId;
                }

                obj.weeklyCharge = TempDataSplitFunding[i].corefee;
                obj.StartDate = TempDataSplitFunding[i].admissiondate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].admissiondate) : '';
                obj.endDate = TempDataSplitFunding[i].enddate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].enddate) : '';

                obj.dailyCoreFee= TempDataSplitFunding[i].dailyfeetype === 1 ? Number(Number(TempDataSplitFunding[i].dailyfee) / 7).toFixed(2) : Number(TempDataSplitFunding[i].dailyfee);
                obj.dailyCoreFeeType= TempDataSplitFunding[i].dailyfeetype;

                obj.continenceCareFee= TempDataSplitFunding[i].continencefeetype === 1 ? Number(Number(TempDataSplitFunding[i].continencefee) / 7).toFixed(2) : Number(TempDataSplitFunding[i].continencefee);
                obj.continenceCareFeeType= TempDataSplitFunding[i].continencefeetype;
                obj.totalAdditionalServiceFee = TempDataSplitFunding[i].totalAdditionalServiceFee;
                obj.fncFee= TempDataSplitFunding[i].fncfeetype === 1 ? Number(Number(TempDataSplitFunding[i].fncfee) / 7).toFixed(2) : Number(TempDataSplitFunding[i].fncfee);;
                obj.fncFeeType= TempDataSplitFunding[i].fncfeetype;

                for(let j=0;j<TempDataSplitFunding[i].funderlist.length;j++){
                    fundersdata.push({planDetailId : TempDataSplitFunding[i].funderlist[j].planDetailId,funderid : TempDataSplitFunding[i].funderlist[j].funderid === null ? null : Number(TempDataSplitFunding[i].funderlist[j].funderid),amountType:TempDataSplitFunding[i].funderlist[j].ispercentage ? 2 : 1,proportion: Number(TempDataSplitFunding[i].funderlist[j].proportion),proportionValue:Number(TempDataSplitFunding[i].funderlist[j].proportionvalue),isApplicable:TempDataSplitFunding[i].funderlist[j].isapplicable ? 0 : 1})
                }

                obj.planDetails = fundersdata;
                planlist = [...planlist,obj];
            }
            let data={
                admissionId : admissionid,
                plans:planlist
            }
           const result =  await saveSplitFunding(data);
           if (result.status) {
            swal(result?.message, {
                icon: 'success'
            }).then(()=>{
                checksplit();
            });
        } else {
            swal(result?.message, {
                icon: 'error'
            });
        }
        }
    }
    const savedata = () => {
        savesplitfunding();
        // dispatch(setSplitFundingAction(TempDataSplitFunding))
        // props.splitchange(true);
        setShowModal(false);
    };

    const editHandler = () => {        
        swal({
            title: '',
            text: invoicedata?.invoiceModel?.invoicePattern != undefined ? `Are you sure you want to edit invoice ${invoicedata?.invoiceModel?.invoicePattern}?` : 'Are you sure you want to edit invoice?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                editinvoice();
            }
        });
    };

    async function editinvoice() {
        let ids: any = [];
        if (props.invoiceid !== undefined) {
            ids.push(Number(props.invoiceid));
            let payload = {
                InvoiceHeaderId: ids,
                status: InvoiceFilterInvoiceStatus.Draft
            };
            const result = await UpdateInvoiceStatusService(payload);
            if (result.status) {                
                setInvoiceDraftStatus(true);
                Setsave(false);                
            } else {
                swal(result?.message, {
                    icon: 'error'
                });
            }
        }
    }

    const addPlan = () => {};


    return (
        <div>
            {invoicedata !== undefined && (
                <InvoiceDetailView
                    duplicateStatus={duplicate}
                    onClickBackHandler={onClickBackHandler}
                    onClickDuplicateHandler={onClickDuplicateHandler}
                    getinvoicedata={getinvoicedata}
                    getInvoicelines={getInvoicelines}
                    getInvoicelineerror={getInvoicelineerror}
                    invoicedata={invoicedata}
                    issave={issave}
                    iscancel={iscancel}
                    saveHandler={saveHandler}
                    cancelHandler={cancelHandler}
                    invoiceDraftStatus={invoiceDraftStatus}
                    issplit={split}
                    iserror={iserror}
                    invoiceline={invoiceline}
                    deletedlines={getdeletedline}
                    getadmissionId={getadmissionid}
                    admissionId={admissionid}
                    showModal={showModal}
                    toggle={toggle}
                    savedata={savedata}
                    addPlan={addPlan}
                    isaddplan={isaddplan}
                    isdatavalid={isdatavalid}
                    invoicefields={invoicepagedata}
                    isedit={editHandler}
                />
            )}
            {invoicedata === undefined && (
                <InvoiceDetailView
                    duplicateStatus={duplicate}
                    onClickBackHandler={onClickBackHandler}
                    getinvoicedata={getinvoicedata}
                    getInvoicelines={getInvoicelines}
                    getInvoicelineerror={getInvoicelineerror}
                    invoicedata={undefined}
                    issave={issave}
                    iscancel={iscancel}
                    saveHandler={saveHandler}
                    cancelHandler={cancelHandler}
                    invoiceDraftStatus={invoiceDraftStatus}
                    issplit={split}
                    iserror={iserror}
                    invoiceline={invoiceline}
                    deletedlines={getdeletedline}
                    getadmissionId={getadmissionid}
                    admissionId={admissionid}
                    showModal={showModal}
                    toggle={toggle}
                    savedata={savedata}
                    addPlan={addPlan}
                    isaddplan={isaddplan}
                    isdatavalid={isdatavalid}
                    invoicefields={invoicepagedata}

                />
            )}
        </div>
    );
};

export default InvoiceDetailContainer;
