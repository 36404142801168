import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../store/dashboard/navigationReducer';
import MessageModalContainer from '../../../components/MessageModal/MessageModalContainer';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { GetNotificationsCount } from '../../../service/notifications-services';

const NotificationsView = (props: any) => {
    const { t } = useTranslation();
    const {DataNotificationsCount} = useSelector((state:RootState)=>state.notificationsCount)
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [showModal, setShowModal] = useState(false);
    const [individualMessage, setIndividualMessage] = useState<any>(undefined);
    const [subject, setSubject] = useState<any>(undefined);
    const [body, setBody] = useState<any>(undefined);
    let [smartSearch, setsmartSearch] = useState<any>('');    
    const dispatch = useDispatch();
    const closeSideMenu = () => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
         GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0 )
    };

    const handleClickModal = (item: any) => {
        setSubject(item.subject)
        setBody(item.emailBody)
        setIndividualMessage(item)
        setShowModal(true);
    };
    const toggle =  () => {
        setShowModal(false);
    };

    const eventHandler = (event: any) => {
        event.stopPropagation();
    }; 
    return (
        <div>
            <button onClick={closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>{' '}
            </button>
            <h3 className="mb-2">
            {t('Message.Notifications')} -
                <span className="ms-1 purple-text">{DataNotificationsCount || 0}</span>
                {DataNotificationsCount != null && DataNotificationsCount > 0 && (
                <a href="#" onClick={props.onclickMarkAllHandler} className="mark-read ms-2">
                {t('Message.Mark_all_read')}
                </a>
                )}
            </h3>
            <div className='d-flex'>
                <div className="search-input position-relative w-100 me-2">
                    <input className="form-control white" type="text" placeholder="Search" id="message-search-input" name="messageSmartSearch" value={smartSearch} onChange={(event: any) => {props.searchValue(event); setsmartSearch(event.target.value);}}/>
                    <i className="fa fa-search"></i>
                </div>
                <div className="nav-item dropdown" onClick={eventHandler}>
                    <a className="btn btn-secondary d-flex align-items-center flex-nowrap" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa-solid fa-filter me-1 small"></i> {t('Message.Filter')}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <ul className='list-unstyled mb-0'>
                            <li><label><input type="checkbox" className='me-2' onChange={(event: any) => props.changePayloadHandler(event)} name="read"/> {t('Message.Read')}</label></li>
                            <li><label><input type="checkbox" className='me-2' onChange={(event: any) => props.changePayloadHandler(event)} name="unread"/> {t('Message.Unread')}</label></li>
                            <li><label><input type="checkbox" className='me-2' onChange={(event: any) => props.changePayloadHandler(event)} name="estDischarge"/> {t('Message.Est_Discharge')}</label></li>
                            <li><label><input type="checkbox" className='me-2' onChange={(event: any) => props.changePayloadHandler(event)} name="ratePlanChange"/> {t('Message.Rate_Plan_Change')}</label></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mt-3 message-box" onScroll={props.onScroll} ref={props.listInnerRef}  style={{ overflowY: 'scroll',maxHeight: '450px', height: '450px' }} >
                {props.notificationList?.length > 0 &&
                    props.notificationList?.map((item: any, i: any) => {                        
                        return (
                            <a key={i} href="#" className={`d-flex align-items-top status-box p-2 mb-2 ${item.isRead === 1 ? 'nonClickable' : ''}`} onClick={() => handleClickModal(item)}>
                                <i className={`fa-solid ${item.isRead === 1 ? 'fa-envelope-open' : 'fa-envelope'} mt-1`}></i>
                                <div className="ms-2">
                                    <p className={`${item.isRead === 1 ? 'black-text' : 'bolder'}`}>
                                        {item?.subject}
                                    </p>
                                    <p className="small-text mt-1">{item?.sentOn}</p>
                                </div>
                            </a>
                        );
                    })}
            </div>

            <RenderModalContainer>{showModal && subject !== undefined && body !== undefined && individualMessage !== undefined && <MessageModalContainer onClose={toggle} subject={subject} body={body} individualMessage={individualMessage} isNotificationType={true} />}</RenderModalContainer>
        </div>
    );
};

export default NotificationsView;
