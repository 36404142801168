import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import NoteContainer from '../../../../../../components/NoteModal/indexContainer';
import note from '../../../../../../assets/images/note.svg';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';

const FunderDetailView = () => {
    const { t } = useTranslation(); 
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    let [caindex,setcaindex] = useState<any>(0);
    const [funderNote, setFunderNote] = useState<any>('');
    const [funderName, setFunderName] = useState<any>('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    // leadFunderDetailList

    const setindex = () =>{
        setcaindex= caindex + 1;
    }

    const currentindex = (index:number) =>{
        setcaindex(index);
     }
    
    const handleNoteClickModal = (noteval:any,fname: any) => {                
        setFunderNote(noteval);
        setFunderName(fname);
        setShowNoteModal(true);
    }
    const toggleNote = () => {       
       setShowNoteModal(false);         
    };
    return (
        <div className="accordion" id="accordionFunder">
            {DataLeadInfo?.data?.leadFunderDetailList?.length > 0 && DataLeadInfo?.data?.leadFunderDetailList.map((data:any,i:number)=>{
                return(
                    <div key={i} className="accordion-item white-bg">
                    <h2 className="accordion-header" id={`FunderOne${i}`}>
                        <button className="accordion-button accordion-button-gray" type="button" data-bs-toggle="collapse" data-bs-target={`#CollapseFunderOne${i}`} aria-expanded="true" aria-controls="CollapseFunderOne" onClick={setindex}>
                        <span className='funder-number'>{i+1}</span> {data.funderName}
                        </button>
                    </h2>
                    <div id={`CollapseFunderOne${i}`} className={`accordion-collapse ${caindex === 0 ? 'show' : caindex === i ? 'show':'collapse'}`} aria-labelledby={`#FunderOne${i}`} data-bs-parent="#accordionFunder">
                        <div className="accordion-body">
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Email_Id')}:</label><span className='bold ms-1'>{data.funderEmail}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Contact_No')}:</label><span className='bold ms-1'>{data.funderPhoneNo}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Address1')}:</label><span className='bold ms-1'>{data.funderAddressLine1}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.County')}:</label><span className='bold ms-1'>{data.funderCountyName}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Postal_Code')}:</label><span className='bold ms-1'>{data.funderPostalCode}</span></p>
                            {data?.suid !== undefined && data?.suid !== '' && data?.suid !== null && (<p className='mb-1'>{t('RightSidebarContraclList.suid')}: <span className='bold'>{data?.suid}</span> </p>)}
                            {data?.pO_Reference !== undefined && data?.pO_Reference !== '' && data?.pO_Reference !== null && (<p className='mb-1'>{t('RightSidebarContraclList.po_reference')}: <span className='bold'>{data?.pO_Reference}</span> </p>)}
                            {data?.note !== undefined && data?.note !== '' && data?.note !== null && (
                                <div className='d-flex mt-2 justify-content-end'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(data?.note,data.funderName)}></img><p>{t('RightSidebarContraclList.notes')}</p></div>
                            )}
                            {data.isSelfFunded !== 1 && 
                            <>
                            <p className='mt-3 mb-2 bold'>Commissioner Details</p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Name')}:</label><span className='bold ms-1'>{data.commissionerName}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Email_Id')}:</label><span className='bold ms-1'>{data.commissionerEmail}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Contact_No')}:</label><span className='bold ms-1'>{data.commissionerPhoneNo}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Address1')}:</label><span className='bold ms-1'>{data.commissionerAddressLine1}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.County')}:</label><span className='bold ms-1'>{data.commissionerCountyName}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Postal_Code')}:</label><span className='bold ms-1'>{data.commissionerPostalCode}</span></p>
                            {data.funderTypeId !== 1 &&
                            <>
                            <p className='mt-3 mb-2 bold'>Social Worker Details</p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Name')}:</label><span className='bold ms-1'>{data.socialWorkerName}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Email_Id')}:</label><span className='bold ms-1'>{data.socialWorkerEmail}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Contact_No')}:</label><span className='bold ms-1'>{data.socialWorkerPhoneNo}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Address1')}:</label><span className='bold ms-1'>{data.socialWorkerAddressLine1}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.County')}:</label><span className='bold ms-1'>{data.socialWorkerCountyName}</span></p>
                            <p className='mb-1'><label>{t('ServiceReferralSidebar.Postal_Code')}:</label><span className='bold ms-1'>{data.socialWorkerPostalCode}</span></p>
                            </>
                        }
                             </>
                        }
                        </div>
                        <RenderModalContainer>
                            {showNoteModal && <NoteContainer  onClose={toggleNote} title={funderName != null && funderName != '' ? funderName+"'s Notes" : t('RightSidebarContraclList.funder_note')} setFunderNote={funderNote} isReadonly={true}/> }
                        </RenderModalContainer>
                    </div>
                </div>
                )
            })
        }
       
    </div>
    );
};

export default FunderDetailView;