import React from 'react';
import LocationView from './LocationView';

const LocationContainer = () => {
    return (
        <div>
            <LocationView />
        </div>
    );
};

export default LocationContainer;