import React from 'react';
import ServiceReferralLessView from './ServiceReferralLessView';

const ServiceReferralLessContainer = (props: any) => {
    return (
        <div>
            <ServiceReferralLessView referralOverView={props.referralOverView} SelectedStatus={props.SelectedStatus} CurrentStatus={props.CurrentStatus}/>
        </div>
    );
};

export default ServiceReferralLessContainer;