import React, { useState } from 'react';
import GeneralInfoView from './GeneralInfoView';

const GeneralInfoContainer = (props: any) => {
    const [data, setData] = useState({
        'supportedPersonGender':'1',
        'anAssesmentFromLocalAuthorityOrOther':'1',
        'isThisJointyFundingPackage':'1',
        'positiveBehaviourSupport':'1',
        'currentPlan':'1',
        'personConcentTotheAssessment':'1',
        'personLacksCapicity':'1'
    });

    const updateData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        let obj = {
            ...data,
            [e.target.name]: e.target.value
        };
    };
    return (
        <div>
            <GeneralInfoView orgName={props.orgName} updateData={updateData} generalInfoFormValid={props.generalInfoFormValid} generalInfoDataChange={props.generalInfoDataChange} assessmentDetails={props.assessmentDetails} />
        </div>
    );
};

export default GeneralInfoContainer;