import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListPatientModel } from '../../utils/model';

const initialState: ListPatientModel = {
    StatusListPatient: false,
    DataListPatient: '',
    MessageListPatient: '',
    ErrorListPatient: ''
};

export const listtPatientSlice = createSlice({
    name: 'listPatient',
    initialState,
    reducers: {
        setListtPatientAction: (state, { payload }: PayloadAction) => {
            state.StatusListPatient = true;
            state.DataListPatient = payload;
            state.MessageListPatient = 'Success';
        },
        errorListtPatientAction: (state, { payload }: PayloadAction) => {
            state.StatusListPatient = false;
            state.DataListPatient = [];
            state.MessageListPatient = 'Failed';
            state.ErrorListPatient = payload;
        },
        removeListtPatientAction: (state, { payload }: PayloadAction) => {
            state.StatusListPatient = false;
            state.DataListPatient = '';
            state.MessageListPatient = '';
            state.ErrorListPatient = '';
        }
    },
    extraReducers: {}
});

export const { setListtPatientAction, errorListtPatientAction, removeListtPatientAction } = listtPatientSlice.actions;

export default listtPatientSlice.reducer;
