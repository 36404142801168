import React from 'react';
import InvoiceHeaderView from './InvoiceHeaderView';

const InvoiceHeaderContainer = (props:any) => {
  
    return (
        <div>
            <InvoiceHeaderView duplicateStatus={props.duplicateStatus} invoiceDraftStatus={props.invoiceDraftStatus} issave={props.issave} iscancel={props.iscancel} isformcancel={props.isformcancel} isformsave={props.isformsave}  invoicedata={props.invoicedata} issplit={props.issplit} iserror={props.iserror} invoiceline={props.invoiceline} isedit={props.isedit}/>
        </div>
    );
};

export default InvoiceHeaderContainer;