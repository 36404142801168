import React, { useEffect, useState } from 'react';
import FunderDetailContainer from './FunderDetail/FunderDetailContainer';


import { useTranslation } from 'react-i18next';
import RenderModalContainer from '../../../../../components/RenderModal/indexContainer';
import UserDetailContainer from './UserDetail/UserDetailContainer';
import PenPictureContainer from './PenPicture/PenPictureContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import RejectContainer from '../../../../../components/RejectModal/RejectContainer';
import { acceptRejectLead } from '../../../../../service/leads';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import eventEmitter from '../../../../../utils/eventEmitter';

const PoolingView = (props:any) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const poolingDetails = DataLeadInfo?.data?.leadPoolingDetailsList?.find((data: any) => data.service_Id  == DataServiceDetail.serviceId);
    const [isModified, setisModified] = useState(false);
    
    useEffect(()=>{                    
            setisModified(poolingDetails?.poolingStatus != t('ReferralLead.Pending'));
       },[])
    const handleClickModal = () => {
        setShowModal(true);
    };
    const toggle = () => {
        setShowModal(false);
    };

    const submitalert = () =>{
        swal({
            title: '',
            text: `Are you sure you want to accept the lead ${props.referralid} ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
             submit();
            } else {
            }
        });
    }

    async function submit(){
        let data ={
            "id": DataLeadInfo?.data?.patientDetail?.leadId,
            "poolingId": poolingDetails?.loadPoolingId,
            "poolingStatus": 1,
            "rejectionReasonId": 0,
            "remarks": ""
        }
        const res = await acceptRejectLead(data);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('onAddNewLeadSM')
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    return (
        <div>
           <div className="accordion" id="accordionLead">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {t('ReferralLead.User_Detail')}
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionLead">
                        <div className="accordion-body">
                            <UserDetailContainer />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        {t('ReferralLead.Pen_Picture')}
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionLead">
                        <div className="accordion-body">
                            <PenPictureContainer />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        {t('ReferralLead.Funder_Details')}
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionLead">
                        <div className="accordion-body">
                            <FunderDetailContainer />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between  right_content_btn">
                <button onClick={submitalert} className="btn btn-primary flex-1 me-2" disabled={isModified}>
                <i className="fa-solid fa-check me-1 icon-md"></i> {t('ServiceReferralSidebar.Accept_btn')} 
                </button>
                <button className="btn btn-delete white-text red-background flex-1 me-2" onClick={handleClickModal} disabled={isModified}>
                <i className="fa-solid fa-xmark me-1 icon-md"></i> {t('ServiceReferralSidebar.Reject_btn')}
                </button>
            </div>

            <RenderModalContainer>
            {showModal && <RejectContainer  onClose={toggle} leadid={DataLeadInfo?.data?.patientDetail?.leadId} poolingId={poolingDetails?.loadPoolingId} mname={'plead'} title="Rejection"  referralid={props.referralid} /> }
            </RenderModalContainer>
            
        </div>
    );
};

export default PoolingView;