import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Care_Giver from '../../../assets/images/Care-Giver-white.svg';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AssessmentStatus } from '../../../utils/constant';

const SupportWorkerView = (props: any) => {
    const { t } = useTranslation();
    const { assessmentDetails } = props;
    let [assessmentDetailsSupportWorkerView, setassessmentDetailsSupportWorkerView] = useState<any>(undefined);
    let [statusSubmitted, setStatusSubmitted] = useState<any>(undefined);

    const validationSchema = Yup.object().shape({
        aswId: Yup.string().required(),
        whatKindOfPersonWouldYouLikeToSupportYou: Yup.string().required(),
        skillsNeeded: Yup.string().required(),
        desirableCharacteristicsPersonality: Yup.string().required(),
        interests: Yup.string().required(),
        consentForKeepingAndSharingInformationObtained: Yup.string().strict(true).nonNullable().required('Please select "Yes" or "No".'),
        assessmentSupportRequiredModels: Yup.array().of(
            Yup.object().shape({
                supportRequiredId: Yup.string().required(),
                days: Yup.string().required(),
                am: Yup.string().required(),
                pm: Yup.string().required(),
                night: Yup.string().required()
            })
        )
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'assessmentSupportRequiredModels', control });
    const watchAllFields = watch();

    useEffect(() => {
        if (fields.length === 0 && assessmentDetails === undefined) {
            for (let i = 0; i < 7; i++) {
                if (i === 0) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Monday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${0}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${0}].days`, 'Monday');
                    setValue(`assessmentSupportRequiredModels[${0}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${0}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${0}].night`, '');
                } else if (i === 1) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Tuesday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${1}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${1}].days`, 'Tuesday');
                    setValue(`assessmentSupportRequiredModels[${1}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${1}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${1}].night`, '');
                } else if (i === 2) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Wednesday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${2}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${2}].days`, 'Wednesday');
                    setValue(`assessmentSupportRequiredModels[${2}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${2}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${2}].night`, '');
                } else if (i === 3) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Thursday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${3}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${3}].days`, 'Thursday');
                    setValue(`assessmentSupportRequiredModels[${3}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${3}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${3}].night`, '');
                } else if (i === 4) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Friday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${4}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${4}].days`, 'Friday');
                    setValue(`assessmentSupportRequiredModels[${4}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${4}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${4}].night`, '');
                } else if (i === 5) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Saturday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${5}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${5}].days`, 'Saturday');
                    setValue(`assessmentSupportRequiredModels[${5}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${5}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${5}].night`, '');
                } else if (i === 6) {
                    append([
                        {
                            supportRequiredId: 0,
                            days: 'Sunday',
                            am: '',
                            pm: '',
                            night: ''
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${6}].supportRequiredId`, 0);
                    setValue(`assessmentSupportRequiredModels[${6}].days`, 'Sunday');
                    setValue(`assessmentSupportRequiredModels[${6}].am`, '');
                    setValue(`assessmentSupportRequiredModels[${6}].pm`, '');
                    setValue(`assessmentSupportRequiredModels[${6}].night`, '');
                }
            }
        }
    }, [fields]);

    useEffect(() => {
        if (assessmentDetails !== undefined && assessmentDetails !== '') {
            fields.length = 0;
            setStatusSubmitted(props?.assessmentDetails?.generalInformation?.formData?.assessmentStatus);
            setassessmentDetailsSupportWorkerView(props?.assessmentDetails?.assessmentSupportWorkerModel?.formData);

            setValue('aswId', assessmentDetails?.assessmentSupportWorkerModel?.formData?.aswId ? assessmentDetails?.assessmentSupportWorkerModel?.formData?.aswId : '', { shouldValidate: true });
            setValue(
                'whatKindOfPersonWouldYouLikeToSupportYou',
                assessmentDetails?.assessmentSupportWorkerModel?.formData?.whatKindOfPersonWouldYouLikeToSupportYou
                    ? assessmentDetails?.assessmentSupportWorkerModel?.formData?.whatKindOfPersonWouldYouLikeToSupportYou
                    : '',
                { shouldValidate: true }
            );
            setValue('skillsNeeded', assessmentDetails?.assessmentSupportWorkerModel?.formData?.skillsNeeded ? assessmentDetails?.assessmentSupportWorkerModel?.formData?.skillsNeeded : '', {
                shouldValidate: true
            });
            setValue(
                'desirableCharacteristicsPersonality',
                assessmentDetails?.assessmentSupportWorkerModel?.formData?.desirableCharacteristicsPersonality
                    ? assessmentDetails?.assessmentSupportWorkerModel?.formData?.desirableCharacteristicsPersonality
                    : '',
                { shouldValidate: true }
            );
            setValue('interests', assessmentDetails?.assessmentSupportWorkerModel?.formData?.interests ? assessmentDetails?.assessmentSupportWorkerModel?.formData?.interests : '', {
                shouldValidate: true
            });
            setValue('consentForKeepingAndSharingInformationObtained', assessmentDetails?.assessmentSupportWorkerModel?.formData?.consentForKeepingAndSharingInformationObtained === 1 ? '1' : '0', {
                shouldValidate: true
            });
            if (assessmentDetails?.assessmentSupportWorkerModel?.formData?.assessmentSupportRequiredModels.length > 0) {
                assessmentDetails?.assessmentSupportWorkerModel?.formData?.assessmentSupportRequiredModels.map((item: any, i: any) => {
                    append([
                        {
                            supportRequiredId: item.supportRequiredId,
                            days: item.days,
                            am: item.am,
                            pm: item.pm,
                            night: item.night
                        }
                    ]);
                    setValue(`assessmentSupportRequiredModels[${i}].supportRequiredId`, item.supportRequiredId, { shouldValidate: true });
                    setValue(`assessmentSupportRequiredModels[${i}].days`, item.days, { shouldValidate: true });
                    setValue(`assessmentSupportRequiredModels[${i}].am`, item.am, { shouldValidate: true });
                    setValue(`assessmentSupportRequiredModels[${i}].pm`, item.pm, { shouldValidate: true });
                    setValue(`assessmentSupportRequiredModels[${i}].night`, item.night, { shouldValidate: true });
                });
            }
        } else {
            setValue('aswId', 0);
        }
    }, [assessmentDetails]);

    useEffect(() => {
        props.supportWorkerFormValid(formState.isValid);
        props.supportWorkerDataChange(watchAllFields);
        // if (formState.isValid) {
        //     props.supportWorkerDataChange(watchAllFields)
        // }
    }, [formState]);

    return (
        <div id="support-care-giver">
            <div className="modal-title-header mb-2 mt-3">
                <h3>
                    <img src={Care_Giver} alt="" width={20} className="me-2" />
                    {t('Support_Care_Giver.Support_Giver')}
                </h3>
            </div>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Support_Care_Giver.What_support')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <>
                            <input
                                type="hidden"
                                {...register('aswId', {
                                    // onChange: (e: any) => {
                                    //     props.updateData(e);
                                    // }
                                })}
                                name={'aswId' as const}
                            />
                            <textarea
                                {...register('whatKindOfPersonWouldYouLikeToSupportYou', {
                                    onChange: (e: any) => {
                                        // props.updateData(e);
                                    }
                                })}
                                name={'whatKindOfPersonWouldYouLikeToSupportYou' as const}
                                className={`form-control white ${errors.whatKindOfPersonWouldYouLikeToSupportYou ? 'is-invalid' : ''}`}
                                rows={2}
                            ></textarea>
                        </>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsSupportWorkerView.whatKindOfPersonWouldYouLikeToSupportYou}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Support_Care_Giver.Skills_needed')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('skillsNeeded', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'skillsNeeded' as const}
                            className={`form-control white ${errors.skillsNeeded ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsSupportWorkerView.skillsNeeded}</p>}
                    
                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Support_Care_Giver.Desirable_characteristics')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('desirableCharacteristicsPersonality', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'desirableCharacteristicsPersonality' as const}
                            className={`form-control white ${errors.desirableCharacteristicsPersonality ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsSupportWorkerView.desirableCharacteristicsPersonality}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
                <div className="col-md-6 mb-2">
                    <label className="mb-2">{t('Support_Care_Giver.Interests')}</label>
                    {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <textarea
                            {...register('interests', {
                                onChange: (e: any) => {
                                    // props.updateData(e);
                                }
                            })}
                            name={'interests' as const}
                            className={`form-control white ${errors.interests ? 'is-invalid' : ''}`}
                            rows={2}
                        ></textarea>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsSupportWorkerView.interests}</p>}

                    {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                </div>
            </div>
            <p className="purple-text bold mt-3">
                {t('Support_Care_Giver.Support_Required')} <span className="font-light">{t('Support_Care_Giver.Support_Required_Span')}</span>
            </p>

            <div className="table-radius mt-3">
                <table className="table table-progress mb-0">
                    <tbody>
                        <tr>
                            <th>{t('Support_Care_Giver.Day')}</th>
                            <th>{t('Support_Care_Giver.Am')}</th>
                            <th>{t('Support_Care_Giver.Pm')}</th>
                            <th>{t('Support_Care_Giver.Night')}</th>
                        </tr>
                        {fields.length > 0 &&
                            fields.map((field: any, i: any) => {
                                return (
                                    <tr key={i}>
                                        <td className="ps-2">
                                            {getValues(`assessmentSupportRequiredModels[${i}].days`)}
                                            <input
                                                type="hidden"
                                                {...register(`assessmentSupportRequiredModels[${i}].supportRequiredId`, {
                                                    // onChange: (e: any) => {
                                                    //     props.updateData(e);
                                                    // }
                                                })}
                                                name={`assessmentSupportRequiredModels[${i}].supportRequiredId` as const}
                                            />
                                            <input
                                                hidden
                                                type="text"
                                                {...register(`assessmentSupportRequiredModels[${i}].days`, {
                                                    onChange: (e: any) => {
                                                        // props.updateData(e);
                                                    }
                                                })}
                                                name={`assessmentSupportRequiredModels[${i}].days` as const}
                                                //@ts-ignore
                                                className={`form-control white ${errors.assessmentProgressNotesDetailModels?.[i]?.days ? 'is-invalid' : ''}`}
                                            />
                                        </td>
                                        <td>
                                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                                <input
                                                    type="text"
                                                    {...register(`assessmentSupportRequiredModels[${i}].am`, {
                                                        onChange: (e: any) => {
                                                            // props.updateData(e);
                                                        }
                                                    })}
                                                    name={`assessmentSupportRequiredModels[${i}].am` as const}
                                                    //@ts-ignore
                                                    className={`form-control white ${errors?.assessmentSupportRequiredModels?.[i]?.am ? 'is-invalid' : ''}`}
                                                />
                                            )}
                                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsSupportWorkerView.assessmentSupportRequiredModels[i].am}</p>}

                                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                                        </td>
                                        <td>
                                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                                <input
                                                    type="text"
                                                    {...register(`assessmentSupportRequiredModels[${i}].pm`, {
                                                        onChange: (e: any) => {
                                                            // props.updateData(e);
                                                        }
                                                    })}
                                                    name={`assessmentSupportRequiredModels[${i}].pm` as const}
                                                    //@ts-ignore
                                                    className={`form-control white ${errors?.assessmentSupportRequiredModels?.[i]?.pm ? 'is-invalid' : ''}`}
                                                />
                                            )}
                                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2">{assessmentDetailsSupportWorkerView.assessmentSupportRequiredModels[i].pm}</p>}

                                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                                        </td>
                                        <td>
                                            {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                                                <input
                                                    type="text"
                                                    {...register(`assessmentSupportRequiredModels[${i}].night`, {
                                                        onChange: (e: any) => {
                                                            // props.updateData(e);
                                                        }
                                                    })}
                                                    name={`assessmentSupportRequiredModels[${i}].night` as const}
                                                    //@ts-ignore
                                                    className={`form-control white ${errors?.assessmentSupportRequiredModels?.[i]?.night ? 'is-invalid' : ''}`}
                                                />
                                            )}
                                            {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && (
                                                <p className="bold mb-2">{assessmentDetailsSupportWorkerView.assessmentSupportRequiredModels[i].night}</p>
                                            )}

                                            {/* <p className='bold mb-2'>Lorem Ipsum</p> */}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex mt-3">
                <label>{t('Support_Care_Giver.CONSENT_OBTAINED')}</label>
                
                {(statusSubmitted !== AssessmentStatus.Submitted && statusSubmitted !== AssessmentStatus.Skipped) && (
                        <>
                        <label className="form--radio-label mb-2 d-flex align-items-center ms-3">
                    <input
                        type="radio"
                        {...register('consentForKeepingAndSharingInformationObtained', {
                            onChange: (e: any) => {
                                // props.updateData(e);
                            }
                        })}
                        name={'consentForKeepingAndSharingInformationObtained' as const}
                        value={1}
                        defaultChecked={true}
                        // onChange={props.updateData}
                    />
                    <span className="ms-1">{t('GeneralInfo.Yes')}</span>
                </label>

                <label className="form--radio-label mb-2 d-flex align-items-center ms-2">
                    <input
                        type="radio"
                        {...register('consentForKeepingAndSharingInformationObtained', {
                            onChange: (e: any) => {
                                // props.updateData(e);
                            }
                        })}
                        name={'consentForKeepingAndSharingInformationObtained' as const}
                        value={0}
                        // onChange={props.updateData}
                    />
                    <span className="ms-1">{t('GeneralInfo.No')}</span>
                </label>
                        </>
                    )}
                    {(statusSubmitted === AssessmentStatus.Submitted || statusSubmitted === AssessmentStatus.Skipped) && <p className="bold mb-2 ms-2">{assessmentDetailsSupportWorkerView.consentForKeepingAndSharingInformationObtained === 1 ? 'Yes' : 'No'}</p>}
                
            </div>
        </div>
    );
};

export default SupportWorkerView;
