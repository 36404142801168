import React, { useEffect, useState } from 'react';
import RefferalTableView from './RefferalTableView';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

const ReferralTableContainer = () => {
    const [referralOverView, setReferralOverView] = useState<any>();    
    const { StatusReferralOverView, DataReferralOverView } = useSelector((state: RootState) => state.referralOverView);
    let [SelectedStatus, setSelectedStatus] = useState('');
  
    const handlerSelectedStatus = (val:any) =>{        
        setSelectedStatus(val);
    }

    useEffect(() => {
        if(StatusReferralOverView === true) {
            setReferralOverView(DataReferralOverView.data)
        }
    },[DataReferralOverView])
    return (
        <div>
            <RefferalTableView referralOverView={referralOverView} SelectedStatus={handlerSelectedStatus} CurrentStatus={SelectedStatus}/>
        </div>
    );
};

export default ReferralTableContainer;