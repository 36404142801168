import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginModel } from '../../utils/model';

const initialState: LoginModel = {
    StatusLogin: false,
    DataLogin: {},
    MessageLogin: '',
    ErrorLogin: ''
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginAction: (state, { payload }: PayloadAction) => {
            state.StatusLogin = true;
            state.DataLogin = payload;
            state.MessageLogin = 'Success';
        },
        setLogoutAction: (state) => {
            state.StatusLogin = false;
            state.DataLogin = {};
            state.MessageLogin = '';
            state.ErrorLogin = '';
        },
        errorLoginAction: (state, { payload }: PayloadAction) => {
            state.StatusLogin = false;
            state.DataLogin = {};
            state.MessageLogin = 'Failed';
            state.ErrorLogin = payload;
        },
        removeErrorLoginAction: (state, { payload }: PayloadAction) => {
            state.StatusLogin = false;
            state.DataLogin = {};
            state.MessageLogin = '';
            state.ErrorLogin = '';
        }
    },
    extraReducers: {}
});

export const { setLoginAction, setLogoutAction, errorLoginAction, removeErrorLoginAction } = loginSlice.actions;

export default loginSlice.reducer;
