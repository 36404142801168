import { useEffect, useState } from 'react';
import FunderView from './funderListView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { getFunderData } from '../../../../../../service/dashboard-service';
import swal from 'sweetalert';

const FunderListContainer = (props: any) => {
    let [funderlistData, setfunderlistData] = useState<any>([]);
    let [updateIndex, setupdateIndex] = useState<number>(-1);
    let [updateFunderIndex, setupdateFunderIndex] = useState<number>(-1);
    let [commisionerindex, setcommisionerIndex] = useState<number>(-1);
    let [FunderData,setFunderData]=useState<any>([]);
    let [CityData,setCityData]=useState<any>([]);
    let [isCheck,setCheck]=useState<any>(true);
    let [fundertype,setfundertype]=useState<any>('');
    let [changetype, setchangetype] = useState<any>(false);
    let [iseditable,seteditable] = useState<boolean>(false);
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);

    useEffect(() =>{
        if(DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0){
          setFunderData(DataFunderInfo.data);
        }
  },[DataFunderInfo]);

  useEffect(()=>{
    if(DataSplitFunding.length > 0){
        if(DataSplitFunding[0].enddate !== undefined && DataSplitFunding[0].enddate !== null && DataSplitFunding[0].enddate !== ""){
            seteditable(true);
          }
    }
 
  },[DataSplitFunding])

  useEffect(() =>{
      if(DataCityInfo.data !== undefined && DataCityInfo.data.length > 0){
        setCityData(DataCityInfo.data);
      }
    })
    const updateData = (e: any, index: number, arrayname: any,fundtype:any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        eventEmitter.emit("eventCloseEditLeadSideMenu");
        setfundertype(fundtype);
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                let temporaryarray = funderlistData.slice();
                const fundername = FunderData.filter((val:any)=>val.key == temporaryarray[index]['funderData']['fFunder']);
                const cityname = CityData.filter((val:any)=>val.key == temporaryarray[index]['funderData']['fCity']);
                if(fundertype !== '4'){
                    temporaryarray[index]['commisionerData'] = '';
                    temporaryarray[index]['commisionerData'] ={
                        cEmailId: temporaryarray[index]['funderData']['fEmailId'] || '',
                        cFunder: fundername[0].value || '',
                        cContactNo: temporaryarray[index]['funderData']['fContactNo'] || '',
                        cAddress1: temporaryarray[index]['funderData']['fAddress1'] || '' ,
                        cCity: temporaryarray[index]['funderData']['fCity'] || '',
                        cPoBox: temporaryarray[index]['funderData']['fPoBox'] || ''
                    }
                    setCheck(true);
                    setupdateIndex(index);
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                }
                else if(fundertype === '4'){
                    temporaryarray[index]['commisionerData'] = '';
                    temporaryarray[index]['commisionerData'] ={
                        cEmailId: temporaryarray[index]['funderData']['fEmailId'] || '',
                        cFunder: temporaryarray[index]['funderData']['fFunder'] || '',
                        cContactNo: temporaryarray[index]['funderData']['fContactNo'] || '',
                        cAddress1: temporaryarray[index]['funderData']['fAddress1'] || '' ,
                        cCity: temporaryarray[index]['funderData']['fCity'] || '',
                        cPoBox: temporaryarray[index]['funderData']['fPoBox'] || ''
                    }
                    setCheck(true);
                    setupdateIndex(index);
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                }
               
            } else {
                let temporaryarray = funderlistData.slice();
                temporaryarray[index]['commisionerData'] = '';
                setupdateIndex(index);
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
            }
        } else if (e.target.type === 'radio') {
            setchangetype(false);
            setupdateFunderIndex(index);
            setTimeout(() => {
                setchangetype(true);
            }, 200);
            setfundertype(e.target.value);
         }  
        
        else {
            var sindex = funderlistData.findIndex((x: any, i: number) => i === index);
            const isexists = [...funderlistData].some((val: any) => val.funderData.fFunder === e.target.value);
            if (isexists) {
                if(funderlistData[index].funderData.fFunder !== e.target.value){
                    swal('Funder is already added', {
                        icon: 'warning',
                        dangerMode: true
                    });
                    let temporaryarray = funderlistData.slice();
                    let field = e.target.name;
                    field = field.split('.')
                    if(e.target.name.includes('fFunder')){
                        setfundertype(funderlistData[index].funderData.ftype);
                        setTimeout(() => {
                        const fundername = FunderData.filter((val:any)=>val.key == funderlistData[index].funderData.fFunder);
                        temporaryarray[index][arrayname][field[2]] = funderlistData[index].funderData.fFunder;
                        temporaryarray[index][arrayname]['fFunderVal'] = funderlistData[index].funderData.fFunderVal;
                        setupdateFunderIndex(index);
                        setcommisionerIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                    }, 1000);
                    }
                }
            }
            else{
                if (sindex !== -1) {
                    let temporaryarray = funderlistData.slice();
                    let field = e.target.name;
                    field = field.split('.')
                    if(e.target.name.includes('fFunder')){
                        const fundername = FunderData.filter((val:any)=>val.key == e.target.value);
                        temporaryarray[index][arrayname][field[2]] = e.target.value;
                        temporaryarray[index][arrayname]['fFunderVal'] = fundername[0].value;
                        temporaryarray[index][arrayname]['fEmailId'] = fundername[0].funderEmail;
                        temporaryarray[index][arrayname]['fContactNo'] = fundername[0].funderPhoneNo;
                        temporaryarray[index][arrayname]['fAddress1'] = fundername[0].funderAddressLine1;
                        temporaryarray[index][arrayname]['fCity'] = fundername[0].funderCityId?.toString();
                        temporaryarray[index][arrayname]['fPoBox'] = fundername[0].funderPobox;
                        setupdateFunderIndex(index);
                        temporaryarray[index]['commisionerData'] = '';
                        setCheck(false);
                    }
                    else{
                        temporaryarray[index][arrayname][field[2]] = e.target.value;
                        setupdateFunderIndex(-1);
                    }
                    setfunderlistData(temporaryarray);
                    props.updatedfunderlist(temporaryarray);
                } else {
                }
            }
         
        }
    };

    const updateCData = (e: any, index: number, arrayname: any) => {
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
            }
        } else {
            var sindex = funderlistData.findIndex((x: any, i: number) => i === index);
            if (sindex !== -1) {
                let temporaryarray = funderlistData.slice();
                let field = e.target.name;
                field = field.split('.');
                temporaryarray[index][arrayname][field[2]] = e.target.value;
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
            } else {
            }
        }
    };

    const updateSData = (e: any, index: number, arrayname: any) => {
            var sindex = funderlistData.findIndex((x: any, i: number) => i === index);
            if (sindex !== -1) {
                let temporaryarray = funderlistData.slice();
                let field = e.target.name;
                field = field.split('.');
                temporaryarray[index][arrayname][field[2]] = e.target.value;
                setfunderlistData(temporaryarray);
                props.updatedfunderlist(temporaryarray);
            } else {
            }
    };

    useEffect(()=>{
        setCheck(false);
        async function getFilterFunder() {
            await getFunderData(fundertype);
        }
            getFilterFunder();
    },[fundertype])

    const updateFData = (e:any) =>{
        let temporaryarray = funderlistData.slice();
        temporaryarray[e.i]['funderData']['isFilePond'] = false;
        temporaryarray[e.i]['funderData']['filepath'] = e.filedata.filePath;
        temporaryarray[e.i]['funderData']['filename'] = e.filedata.fileName;
        temporaryarray[e.i]['funderData']['funderContract'] = e.filedata.fileName;
        setupdateFunderIndex(e.i);
        setfunderlistData(temporaryarray);
        props.updatedfunderlist(temporaryarray);
      }

    useEffect(() => {
        if (props.funderList?.length > 0) {
            setfunderlistData(props.funderList);
        }
        else{
            setfunderlistData([]);
        }
        if(props.funderList[0]?.isinvoicecreated === 1){
            seteditable(false);
        }
        else{
            seteditable(true);
        }
    }, [props.funderList]);
    useEffect(() => {
        if (funderlistData.length === 0) {
            props.isadd(true);
        } else {
            props.isadd(false);
        }
    }, [funderlistData]);

    const updatefilepond = (i:number) =>{
        let temporaryarray = funderlistData.slice();
        temporaryarray[i]['funderData']['isFilePond'] = true;
        temporaryarray[i]['funderData']['filepath'] = "";
        temporaryarray[i]['funderData']['funderContract'] = "";
        temporaryarray[i]['funderData']['filename'] = "";
        setupdateFunderIndex(i);
        setfunderlistData(temporaryarray);
        props.updatedfunderlist(temporaryarray);
    }

    const deleteFunder = (recordIndex: number,data:any) => {
        
        const deletedList = funderlistData.filter((data: any, index: number) => index !== recordIndex);
        setfunderlistData(deletedList);
        props.updatedfunderlist(deletedList);
        // 
        // props.deletefunderlist(DataPatientDraftInfo?.data?.contractDetails.at(recordIndex));
        let funderexists = DataLeadInfo?.data?.leadFunderDetailList?.find((val:any)=>{
            if(val.funderId === Number(data.fFunder)){
               return val;
            }
        })
        
        props.deletefunderlist(funderexists);
    };

    const updateselffunder = (index:number) =>{
        let isexists :any = "";
        
        
            isexists = [...funderlistData].some((val: any) => val.funderData.fFunder === props.selfData.name);
            
            if (isexists) {
                
                if(funderlistData[index].funderData.fFunder !== props.selfData.name){
                    swal('Funder is already added', {
                        icon: 'warning',
                        dangerMode: true
                    });

                    let temporaryarray = funderlistData.slice();
                    setfundertype(funderlistData[index].funderData.ftype);
                    setTimeout(() => {
                        temporaryarray[index]['funderData']['fFunderVal'] = funderlistData[index].funderData.fFunderVal;
                        temporaryarray[index]['funderData']['fFunder'] = funderlistData[index].funderData.fFunder;
                        setupdateFunderIndex(index);
                        setcommisionerIndex(index);
                        setfunderlistData(temporaryarray);
                        props.updatedfunderlist(temporaryarray);
                    }, 1000);
                   
                }
            }
            else{
                
            let temporaryarray = funderlistData.slice();
            temporaryarray[index]['funderData']['fFunderVal'] = props.selfData.name;
            temporaryarray[index]['funderData']['fFunder'] = props.selfData.name;
            temporaryarray[index]['funderData']['fEmailId'] = props.selfData.EmailId;
            temporaryarray[index]['funderData']['fContactNo'] = props.selfData.ContactNo;
            temporaryarray[index]['funderData']['fAddress1'] = props.selfData.Address1;
            temporaryarray[index]['funderData']['fCity'] = props.selfData.City;
            temporaryarray[index]['funderData']['fPoBox'] = props.selfData.PoBox;
            temporaryarray[index]['funderData']['ftype'] = '4';
            setupdateFunderIndex(index);
            setfunderlistData(temporaryarray);
            props.updatedfunderlist(temporaryarray);
        }
      
    }

    return <FunderView patientname={props.patientname} citylist={props.citylist} index={updateIndex} funderdlist={props.funderdlist} funderList={funderlistData} isadd={props.isadd} isdelete={deleteFunder} updateData={updateData} updateCData={updateCData} updateSData = {updateSData} updatefilepond={updatefilepond} funderindex={updateFunderIndex} commisionerindex={commisionerindex} updateFData={updateFData} isCheck={isCheck} changetype={changetype} fundertype={fundertype} selfData={props.selfData} updateselffunder={updateselffunder} iseditable={iseditable} getfundelisterror={props.getfundelisterror}/>;
};

export default FunderListContainer;
