import React from 'react';
import OverviewView from './OverviewView';

const OverviewContainer = () => {
    return (
        <div>
            <OverviewView />
        </div>
    );
};

export default OverviewContainer;