import React, { useState, useEffect, useRef } from 'react';
import { markAsReadService } from '../../../service/messages-service';
import eventEmitter from '../../../utils/eventEmitter';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { removeListMessagesAction } from '../../../store/referral/listMessagesReducer';
import NotificationsView from './NotificationsView';
import { GetNotificationsCount, getNotificationsService, markAsReadNotifications } from '../../../service/notifications-services';
import { notification } from 'antd';
import { removeListNotificationsAction } from '../../../store/referral/listNotificationsReducer';
let PageSize = 6;

type notificationListPayload = {
    searchValue: string;
    isRead: any;
    isUnRead: any;
    estDischargeFilter:any;
    ratePlanChangeFilter:any;
    lazyLoad: boolean;
    page: any;
    pageSize: any;
    serviceId : any
};

const NotificationsContainer = () => {
    const dispatch = useDispatch();
    const { DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [notificationList, setNotificationList] = useState<any>([]);
    const [lastList, setLastList] = useState(false);
    const [loadedNotifications, setLoadedNotifications] = useState([]);
    const [notificationListPayload, setNotificationListPayload] = useState<notificationListPayload>({
        searchValue: '',
        isRead: 0,
        isUnRead: 0,
        estDischargeFilter : 0,
        ratePlanChangeFilter : 0,
        lazyLoad: true,
        page: 1,
        pageSize: PageSize,
        serviceId : DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0
    });
    
    async function markAsReadNotification(data: any) {
        let ids: any = [];
        ids.push(data.id);
        let obj: any = {
        notificationId: ids,
        serviceId : DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0
        };
        if(notificationList.some((notification:any) => 
            notification.notificationId === data.id && notification.isRead === 0))
        {
        await markAsReadNotifications(obj);
        await GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0 )
        }
    }
    async function messageMarkAsReadALL() {
        let obj: any = {
            notificationId: [],
            serviceId : DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0
        };
        const response = await markAsReadNotifications(obj);
        if (response.status === true) {
            dispatch(removeListNotificationsAction());
            await GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0 )
        }
    }
    async function onclickMarkAllHandler() {
        const notificationIdsToMarkAsRead = loadedNotifications.map((notification:any) => notification.notificationId);
        
        await messageMarkAsReadALL();
        if (DataRoleSelected.roleName === 'Service Manager') {
            eventEmitter.emit('onNotificationReadFetchSM');
        } else {
            eventEmitter.emit('onNotificationReadFetchRM');
        }
        setCurrPage(1);
        setPrevPage(0);
        setLastList(false);        
    }
   

    useEffect(() => {
        eventEmitter.on('eventMarkAsRead', eventMarkAsRead);
        return () => {
            eventEmitter.off('eventMarkAsRead', eventMarkAsRead);
        };
    });

    const eventMarkAsRead = async (data: any) => {
        await markAsReadNotification(data);
        if (DataRoleSelected.roleName === 'Service Manager') {
            eventEmitter.emit('onNotificationReadFetchSM');
        } else {
            eventEmitter.emit('onNotificationReadFetchRM');
        }
        setCurrPage(1);
        setPrevPage(0);
        setLastList(false);       
    };

    const changeSearchValue = (event: any) => {
        if (event.target.name === 'messageSmartSearch') {
            let tempMessageListPayload: any = notificationListPayload;
            tempMessageListPayload.page = 1;
            setCurrPage(1);
            setPrevPage(0);
            setLastList(false);
            if (event.target.value !== '') {
                tempMessageListPayload.searchValue = event.target.value;
                setNotificationListPayload((prevPayload: any) => {
                    return {
                        ...prevPayload,
                        page: 1,
                        searchValue: event.target.value,
                    };
                });
            } else {
                tempMessageListPayload.searchValue = '';
                setNotificationListPayload((prevPayload: any) => {
                    return {
                        ...prevPayload,
                        page: 1,
                        searchValue: '',
                    };
                });
            }           
        }
    };

    const changePayloadHandler = (event: any) => {
        let tempMessageListPayload: any = notificationListPayload;
        tempMessageListPayload.page = 1;
        tempMessageListPayload.serviceId = DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0;
        setCurrPage(1);
        setPrevPage(0);
        setLastList(false);
        if (event.target.checked === true && event.target.name === 'read') {
            tempMessageListPayload.isRead = 1;
            setNotificationListPayload(tempMessageListPayload);
        } else if (event.target.checked === false && event.target.name === 'read') {
            tempMessageListPayload.isRead = 0;
            setNotificationListPayload(tempMessageListPayload);
        }

        if (event.target.checked === true && event.target.name === 'unread') {
            tempMessageListPayload.isUnRead = 1;
            setNotificationListPayload(tempMessageListPayload);
        } else if (event.target.checked === false && event.target.name === 'unread') {
            tempMessageListPayload.isUnRead = 0;
            setNotificationListPayload(tempMessageListPayload);
        }
        
        if (event.target.checked === true && event.target.name === 'estDischarge') {
            tempMessageListPayload.estDischargeFilter = 1;
            setNotificationListPayload(tempMessageListPayload);
        } else if (event.target.checked === false && event.target.name === 'estDischarge') {
            tempMessageListPayload.estDischargeFilter = 0;
            setNotificationListPayload(tempMessageListPayload);
        }

        if (event.target.checked === true && event.target.name === 'ratePlanChange') {
            tempMessageListPayload.ratePlanChangeFilter = 1;
            setNotificationListPayload(tempMessageListPayload);
        } else if (event.target.checked === false && event.target.name === 'ratePlanChange') {
            tempMessageListPayload.ratePlanChangeFilter = 0;
            setNotificationListPayload(tempMessageListPayload);
        }
    };

    useEffect(() => {
        
        if (!lastList && prevPage !== currPage) {
            fetchData();
        }
    }, [currPage, lastList, prevPage, notificationListPayload, DataServiceDetail.serviceId]);

    const fetchData = async () => {
        let tempMessageListPayload = { ...notificationListPayload, page: currPage };
        try {
            const response = await getNotificationsService(tempMessageListPayload);
            if (!response.status || response.data === null) {
                setLastList(true);
            }else{
                setLastList(false)
            }

            // if (response.totalCount > 0) {
            //     setPrevPage(currPage);
            // }
            // if(response.data === null){
            //     setPrevPage(currPage)
            // }

            if (currPage === 1) {
                if (Array.isArray(response.data)) {
                    setNotificationList(response.data);
                    setLoadedNotifications(response.data);
                } else {
                    setNotificationList([]);
                    setLoadedNotifications([]);
                }                
            } else {
                const newData = Array.isArray(response.data) ? response.data : [];
               // setNotificationList((prevList:any) => [...prevList, ...newData]);
               setNotificationList((prevList:any) => {
                const updatedList:any = [...prevList, ...newData];
                setLoadedNotifications(updatedList);
                return updatedList;
            });
            }
            if (response.data !== null) {
                setPrevPage(currPage);
            }
        } catch (error) {
        }
    };
    useEffect(() => {
        setCurrPage(1);
        setNotificationList([]);
        setLastList(false);
        setPrevPage(0);
        fetchData()
    }, [notificationListPayload]);
  
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 20) {
                setCurrPage((prevPage) => prevPage + 1);
            }
        }
    };
    return (
        <div>
            <NotificationsView
                onclickMarkAllHandler={onclickMarkAllHandler}
                searchValue={changeSearchValue}
                changePayloadHandler={changePayloadHandler}
                notificationList={notificationList}
                onScroll={onScroll}
                listInnerRef={listInnerRef}
            />
        </div>
    );
};

export default NotificationsContainer;
