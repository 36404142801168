import React, { useRef, useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import selectall from '../../../../assets/images/allicon.svg';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import eventEmitter from '../../../../utils/eventEmitter';
import { setAppliedFilterAction } from '../../../../store/filter/appliedFilterReducer';

const ServicetypeSliderView = (props: any) => {
    const dispatch = useDispatch();

    const { AppliedFilterData } = useSelector((state: RootState) => state.appliedFilter);
    const onClickServiceTypeHandler = (service: number, index: number) => {
        dispatch(setAppliedFilterAction({
            ...AppliedFilterData,
            serviceTypeId: service
        }));
    };

    return (
        <div className="header-slider-menu">
            <OwlCarousel className="owl-theme horizontal-menu-header hover-menu " dots={false} autoWidth={true} margin={30} mouseDrag={false} nav key={AppliedFilterData.serviceTypeId.toString() + Date.now()}>
                <div className="item" key={0}>
                    <a href="#" className={AppliedFilterData.serviceTypeId === 0 ? "active" : ""} tabIndex={0} onClick={() => onClickServiceTypeHandler(0, 0)}>
                        <img src={selectall} className="mb-1" alt="All" style={{width:'30px' , height:'26px'}} />
                        All
                    </a>
                </div>
                {props?.serviceTypeList?.length > 0 &&
                    props?.serviceTypeList?.map((item: any, index: any) => {
                        return (
                            <div className="item" key={item.serviceTypeId}>
                                <a href="#" className={AppliedFilterData.serviceTypeId === item.serviceTypeId ? "active" : ""} onClick={() => onClickServiceTypeHandler(item.serviceTypeId, index + 1)}>
                                    <img src={item.serviceTypeIconUrl} className="mb-1" alt={item.serviceTypeName} style={{width:'26px', height:'26px'}} />
                                    {item.serviceTypeName}
                                </a>
                            </div>
                        );
                    })}
            </OwlCarousel>
        </div>
    );
};

export default ServicetypeSliderView;
