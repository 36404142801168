import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataResponseModel, ListMessagesModel } from '../../utils/model';

const initialState: DataResponseModel = {
    status: false,
    message: "",
    data: null,
    totalCount: 0,
};

export const serviceProviderDetailSlice = createSlice({
    name: 'serviceProviderDetail',
    initialState,
    reducers: {
        setServiceProviderAction: (state, { payload }: PayloadAction) => {
           state.data = payload
        },

        removeListServiceProviderAction: (state, { payload }: PayloadAction) => {
            state.data = null
        }
    },
    extraReducers: {}
});

export const { setServiceProviderAction, removeListServiceProviderAction } = serviceProviderDetailSlice.actions;

export default serviceProviderDetailSlice.reducer;
