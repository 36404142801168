import React from 'react';
import TableManageColumnsView from './TableManageColumnsView';

const TableManageColumnsContainer = (props: any) => {
    return (
        <div>
            <TableManageColumnsView managedColumnsList={props.managedColumnsList} onChangeManageColumnHandler={props.onChangeManageColumnHandler} />
        </div>
    );
};

export default TableManageColumnsContainer;