import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserLocationModel } from '../../utils/model';

const initialState: UserLocationModel = {
    StatusUserLocation: false,
    MessageUserLocation: '',
    DataUserLocation: '',
    ErrorUserLocation: ''
};

export const userLocationSlice = createSlice({
    name: 'userLocation',
    initialState,
    reducers: {
        setUserLocationAction: (state, { payload }: PayloadAction) => {
            state.StatusUserLocation = true;
            state.MessageUserLocation = 'Success';
            state.DataUserLocation = payload;
            state.ErrorUserLocation = ''
        },
        removeUserLocationAction: (state, { payload }: PayloadAction) => {
            state.StatusUserLocation = false;
            state.MessageUserLocation = '';
            state.DataUserLocation = '';
            state.ErrorUserLocation = '';
        },
        errorUserLocationAction: (state, { payload }: PayloadAction) => {
            state.StatusUserLocation = false;
            state.DataUserLocation = '';
            state.MessageUserLocation = 'Failed';
            state.ErrorUserLocation = payload;
        }
    }
});

export const { setUserLocationAction, removeUserLocationAction, errorUserLocationAction } = userLocationSlice.actions;

export default userLocationSlice.reducer;
