import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridFilterStateModel } from '../../utils/model';

const initialState: GridFilterStateModel = {
    StatusGridFilterState: false,
    MessageGridFilterState: '',
    DataGridFilterState: '',
    ErrorGridFilterState: ''
};

export const gridFilterStateSlice = createSlice({
    name: 'gridFilterState',
    initialState,
    reducers: {
        setGridFilterStateAction: (state, { payload }: PayloadAction) => {
            state.StatusGridFilterState = true;
            state.MessageGridFilterState = 'Success';
            state.DataGridFilterState = payload;
            state.ErrorGridFilterState = ''
        },
        removeGridFilterStateAction: (state, { payload }: PayloadAction) => {
            state.StatusGridFilterState = false;
            state.MessageGridFilterState = '';
            state.DataGridFilterState = '';
            state.ErrorGridFilterState = '';
        },
        errorGridFilterStateAction: (state, { payload }: PayloadAction) => {
            state.StatusGridFilterState = false;
            state.DataGridFilterState = '';
            state.MessageGridFilterState = 'Failed';
            state.ErrorGridFilterState = payload;
        }
    },
    extraReducers: {}
});

export const { setGridFilterStateAction, removeGridFilterStateAction, errorGridFilterStateAction } = gridFilterStateSlice.actions;

export default gridFilterStateSlice.reducer;
