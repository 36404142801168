import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSaveForLater } from '../../../store/SaveForLater/saveForLaterDrawerReducer';
import folder from "../../../../../assets/images/folder.svg"
import listimage1 from "../../../assets/images/listimage1.png"
import verticaldots from "../../../assets/images/vertical-dots.svg"
import { setSaveForLaterWishlist } from '../../../store/SaveForLater/saveForLaterServicesWishlistReducer';
import { RootState, store } from '../../../store';
import { DeleteServiceWishList, GeWishListService, UpdateServiceWishList } from '../../../service/portal-services';
import swal from "sweetalert";
import warning from '../../../assets/images/warning.png';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import { removeSaveForLaterServicesListAction, setSaveForLaterServicesListAction } from '../../../store/SaveForLater/saveForLaterServicesList';
import eventEmitter from '../../../utils/eventEmitter';

const SaveForLaterListPatientSpecificView = () => {
    const dispatch = useDispatch();
    const { folderId } = useSelector((state: RootState) => state.saveForLaterWishlistModal);
    const {SaveForLaterServicesList} = useSelector((state: RootState) => state.saveForLaterServicesList);

    const [wishList, setWishList] = useState<any>(null)
    const [searchValue, setSearchValue] = useState<string>("")
    const [showPortal, setShowPortal] = useState(false);
    const [editWishlist, setEditWishlist] = useState<string>("")
    const [wishlistName, setWishlistName] = useState<string>("")
    const [saveForLaterFolderDetailId, setSaveForLaterFolderDetailId] = useState<any>(null)
    const [updatePayload, setUpdatePayload] = useState<any>(null)
    const handleCloseModal = () => {
        setShowPortal(false);
        setEditWishlist("")
        setSaveForLaterFolderDetailId(null)
    };

    const handleCloseDrawer = () => {
        dispatch(setSaveForLater({
            saveForLaterDrawerOpen: false
        }))
        dispatch(setSaveForLaterWishlist({
            saveForLaterWishlistOpen: false,
            folderId: 0
        }));
        dispatch(removeSaveForLaterServicesListAction())
    }
    const handleBackButton = () => {
        dispatch(setSaveForLater({
            saveForLaterDrawerOpen: true
        }))
        dispatch(setSaveForLaterWishlist({
            saveForLaterWishlistOpen: false,
            folderId: 0
        }));

        dispatch(removeSaveForLaterServicesListAction())
    }
    useEffect(() => {
        GetFolderWishList()
    }, [searchValue])

    useEffect(() => {
        eventEmitter.on('GetFolderWishList', GetFolderWishList);
        return () => {
            eventEmitter.off('GetFolderWishList', GetFolderWishList);
        };
    });

    const GetFolderWishList = async () => {
        const payload = {
            folderId: folderId,
            searchValue: searchValue
        }
        const result = await GeWishListService(payload)
        setWishList(result?.data)
    }
    const handleSearchValue = (e: any) => {
        setSearchValue(e.target.value)
    }

    const handleDeleteWishlist = (saveForLaterFolderDetailId: any) => {
        if (saveForLaterFolderDetailId) {
            swal({
                title: '',
                text: 'Are you sure you want to delete the service?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
                .then(async (confirm: any) => {
                    if (confirm) {
                        const response = await DeleteServiceWishList(saveForLaterFolderDetailId)
                        if (response.status === true) {
                            swal(response.message, {
                                icon: 'success'
                            });
                        } else {
                            swal(response.message, {
                                icon: 'error',
                                dangerMode: true
                            });
                        }
                        const payload = {
                            folderId: folderId,
                            searchValue: searchValue
                        }
                        const result = await GeWishListService(payload)
                        setWishList(result?.data)
                    }
                });
        }
    }
    const handleEditWishlist = (saveForLaterFolderDetailId: any) => {
        setShowPortal(true)
        const wishlistItem = wishList && wishList?.wishlistedService && wishList?.wishlistedService.find((ws: any) =>
            ws?.saveForLaterFolderDetailId === saveForLaterFolderDetailId);

        const wishlistName = wishlistItem ? wishlistItem.name : null;
        setWishlistName(wishlistName)
        setEditWishlist(wishlistName)
        setSaveForLaterFolderDetailId(saveForLaterFolderDetailId)
    }

    useEffect(() => {
        const payload = {
            saveForLaterFolderDetailId: saveForLaterFolderDetailId,
            wishlistName: editWishlist
        }
        setUpdatePayload(payload)
    }, [editWishlist])

    const onUpdate = () => {
        if (updatePayload) {
            swal({
                title: '',
                text: 'Are you sure you want to update the service?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
                .then(async (confirm: any) => {
                    if (confirm) {
                        const response = await UpdateServiceWishList(updatePayload)
                        if (response.status === true) {
                            swal(response.message, {
                                icon: 'success'
                            });
                            setShowPortal(false)
                            setEditWishlist("")
                            setSaveForLaterFolderDetailId(null)
                        } else {
                            swal(response.message, {
                                icon: 'error',
                                dangerMode: true
                            });
                            setShowPortal(false)
                            setEditWishlist("")
                            setSaveForLaterFolderDetailId(null)
                        }
                        setShowPortal(false)
                        const payload = {
                            folderId: folderId,
                            searchValue: searchValue
                        }
                        const result = await GeWishListService(payload)
                        setWishList(result?.data)
                        setEditWishlist("")
                        setSaveForLaterFolderDetailId(null)
                    }
                });
        }
    }

    const onChangeWishlist = (e: any) => {
        setEditWishlist(e.target.value)
    }
    return (
        <div className='saveforlater'>
            <button title="close" onClick={handleCloseDrawer} className="close_right_content"><i className="fa-solid fa-xmark"></i> </button>
            <div className='d-flex align-items-center rightsidebar-title'>
                <span className='back-button me-2' onClick={handleBackButton}><i className="fa fa-arrow-left" aria-hidden="true"></i></span><h3 className='mb-0'>{wishList?.folderName}</h3>
            </div>

            <div>
                <div className="mt-2 position-relative"><input type="text" placeholder="Search" name="leadSmartSearch" className="form-control search-input w-100" onChange={handleSearchValue} value={searchValue} />
                    <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                </div>
            </div>

            <div>
                {wishList && wishList?.wishlistedService && wishList?.wishlistedService.length > 0 &&
                    wishList?.wishlistedService.map((wl: any) => {
                        console.log("WL1234",wl)
                        return (
                            <div className="folder-box mt-2 profile-css">
                                <div className='folder-box-content d-flex align-items-center'>
                                    <div className='folder-box-inner'><span><img src={wl?.imageUrls} width={50} className='img-fluid' alt="list-image" /></span></div>
                                    <span className='ms-2 mb-0 bold'>{wl?.name}</span>
                                </div>
                                <div className='vertical-dots' dropdown-toggle data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside">
                                    <img src={verticaldots} className='img-fluid' width={3} alt="list-image" />
                                </div>
                                <ul className="dropdown-menu ">
                                    <li className='cursor-pointer'>
                                        <a onClick={() => handleEditWishlist(wl?.saveForLaterFolderDetailId)}><span className="ms-2">Edit</span></a>
                                    </li>
                                    <li className='cursor-pointer'>
                                        <a onClick={() => handleDeleteWishlist(wl?.saveForLaterFolderDetailId)}><span className="ms-2">Delete</span></a>
                                    </li>
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
            <RenderModalContainer>
                {showPortal && <div><div className="overlay" onClick={handleCloseModal}></div>
                    <div className="modal modal-extrasmall">
                        <div className='modal-content'>
                            <div className="modal-header mb-1">
                                <h4 className="mb-0 modal-title">{wishlistName}</h4>
                                <a href="#" onClick={handleCloseModal} className="removeButton">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 384 512"
                                    >
                                        <path
                                            fill="#070044"
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div>
                                        <label className="mb-2">Name</label>
                                        <input
                                            type="text"
                                            className="form-control white mb-2 overview-input"
                                            placeholder="Name"
                                            value={editWishlist}
                                            onChange={(e) => onChangeWishlist(e)}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer justify-content-center'>
                                <div><button className="btn btn-primary me-2" onClick={onUpdate}>Update</button>
                                    <button onClick={handleCloseModal} className="btn btn-secondary">Clear</button></div>
                            </div>
                        </div>

                    </div></div>}
            </RenderModalContainer>
        </div>
    );
};

export default SaveForLaterListPatientSpecificView;

