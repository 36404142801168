import React from 'react';
import ServiceDetailHeaderContainer from './ServiceDetailHeader/ServiceDetailHeaderContainer';
import ServiceImageCollageContainer from './ServiceImageCollage/ServiceImageCollageContainer';
import ServiceTabContainer from './ServiceTab/ServiceTabContainer';
import FooterContainer from '../../../layout/Footer/FooterContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Roles } from '../../../utils/constant';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
import { useLocation } from 'react-router-dom';

const NHSServiceDetailView = () => {
    const location = useLocation();
    
    const { DataLogin } = useSelector((state: RootState) => state.login);

    return (
        <div
            className={`nhs-body ${
                (DataLogin?.userModel?.userRoleId !== Roles.NHS) &&
                (DataLogin?.userModel?.userRoleId === Roles.ReferralManager &&
                location.pathname.includes(ROUTES_DATA.SERVICEDETAIL)) &&
                (DataLogin?.userModel?.userRoleId === Roles.ServiceManager &&
                location.pathname.includes(ROUTES_DATA.SERVICEDETAIL))
                    ? 'non-nhs'
                    : ''
            }`}
        >
            <ServiceDetailHeaderContainer />
            <ServiceImageCollageContainer />
            <ServiceTabContainer />
            {/* <FooterContainer /> */}
        </div>
    );
};

export default NHSServiceDetailView;
