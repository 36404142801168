import React, { createRef, useEffect, useRef, useState } from 'react';
import ContractView from './contractView';
import FunderListContainer from '../FunderList/funderListContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import swal from 'sweetalert';
import { getFunderData } from '../../../../../../../service/dashboard-service';
import eventEmitter from '../../../../../../../utils/eventEmitter';
import { removeSplitFundingAction } from '../../../../../../../store/splitFunding/splitFundingReducer';
import { setFunderInformationAction } from '../../../../../../../store/dashboard/getFunderReducer';
import { List } from 'antd/es/form/Form';
var listdata: any[] = [];
const ContractContainer = (props: any) => {
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    let [contractData, setcontractData] = useState<any>('');
    let [commissionerData, setcommissionerData] = useState<any>('');
    let [ListData, setlistdata] = useState<any>([]);
    let [FunderData, setFunderData] = useState<any>([]);
    let [CityData, setCityData] = useState<any>([]);
    let [isContracterror, setContracterror] = useState<any>(false);
    let [isCheck, setCheck] = useState<any>(true);
    let [fundertype, setfundertype] = useState<any>('');
    let [listfunder,setlistfunder] = useState<any>([]);
    let [changetype, setchangetype] = useState<any>(false);
    let [isDisplay, setDisplay] = useState(true);
    let [isBack,setBack] = useState<boolean>(false);
    let [allvoid,setallvoid] = useState<boolean>(false);
    let [aftervoiddata,setaftervoiddata] = useState<any>([]);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const dispatch = useDispatch();
    useEffect(() => {
        props.contractdata({ ListData });
    }, [ListData]);

    useEffect(()=>{
     props.isallvoid(allvoid);
    },[allvoid])

 
    useEffect(() => {
        eventEmitter.on('eventClearFormArray', eventClearArray);
        return () => {
            eventEmitter.off('eventClearFormArray', eventClearArray);
        };
    });

    const eventClearArray = () =>{
        setaftervoiddata([])
    }
    useEffect(()=>{
     if(DataLeadInfo?.data?.contract?.length > 0){
        for(let i=0; i<DataLeadInfo?.data?.contract?.length;i++){
           if(DataLeadInfo?.data?.contract[i]?.status !== "Voided" && DataLeadInfo?.data?.contract[i]?.status !== "Cancelled"){
            setallvoid(false);
            break;
           }
           else{
            setallvoid(true);
           }
        }
     }
    },[DataLeadInfo])

    useEffect(()=>{
     
    },[contractData])

    useEffect(() => {
        if (DataFunderInfo.data !== undefined && DataFunderInfo.data.length > 0) {
            setFunderData(DataFunderInfo.data);
            // if(ListData.length > 0){
            //     let listf : any[] = [];
            //     for(let i=0;i<ListData.length;i++){
            //         listf.push(Number(ListData[i].funderData.fFunder))
            //     }
            //     // let returnedFilter = JSON.parse(JSON.stringify(DataFunderInfo));
            //     let filterlist = DataFunderInfo.data.filter((item : any) => listf.indexOf(item.key) === -1);
            //     setlistfunder(filterlist);
            //     // 
            //     // returnedFilter.data = filterlist
            //     // dispatch(setFunderInformationAction(returnedFilter))
            // }
            // else{
                setlistfunder(DataFunderInfo.data);
            // }
        }
    }, [DataFunderInfo]);

    useEffect(() => {
        if (DataCityInfo.data !== undefined && DataCityInfo.data.length > 0) {
            setCityData(DataCityInfo.data);
        }
    }, [DataCityInfo]);

    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                setcontractData({
                    ...contractData
                });

                if (fundertype !== '4') {
                    const fundername = FunderData.filter((val: any) => val.key == contractData?.fFunder);
                    setcommissionerData({
                        cEmailId: contractData?.fEmailId || '',
                        cFunder: fundername[0]?.value || '',
                        cContactNo: contractData?.fContactNo || '',
                        cAddress1: contractData?.fAddress1 || '',
                        cCity: contractData?.fCity || '',
                        cPoBox: contractData?.fPoBox || ''
                    });
                } else if (fundertype === '4') {
                    setcommissionerData({
                        cEmailId: contractData?.fEmailId || '',
                        cFunder: contractData?.fFunder || '',
                        cContactNo: contractData?.fContactNo || '',
                        cAddress1: contractData?.fAddress1 || '',
                        cCity: contractData?.fCity || '',
                        cPoBox: contractData?.fPoBox || ''
                    });
                }
            } else {
                setcontractData({
                    ...contractData,
                    [e.target.id]: e.target.checked
                });
                setcommissionerData({});
            }
        } else if (e.target.type === 'radio') {
            setchangetype(false);
            setTimeout(() => {
                setchangetype(true);
            }, 200);
            setCheck(true);
            setTimeout(() => {
                setCheck(false);
            }, 100);
            setcontractData({
                ...contractData,
                ftype: e.target.value
            });
        } else if (e.target.type === 'clear'){
            setchangetype(false);
            setTimeout(() => {
                setchangetype(true);
            }, 200);
            setCheck(true);
            setTimeout(() => {
                setCheck(false);
            }, 100);
            setcontractData({
                ...contractData,
                ftype: e.target.value
            });
            setcontractData('')
            setcommissionerData('')
            setlistdata([...[]])
        } else {
            let isexists : any = "";
            if(e.target.id == 'Funder'){
            isexists = [...ListData].some((val: any,i:any) => {
                
                if (val.funderData.fFunder === e.target.value){
                    if(DataLeadInfo?.data?.contract[i]?.status !== "" && DataLeadInfo !== ""){
                        if(DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled'){
                            return false
                        } else{
                            return true
                        }
                    } else{
                        return true
                    }
                } else {
                    return false;
                }
            });
            }
            if (isexists) {
                    swal('Funder is already added', {
                        icon: 'warning',
                        dangerMode: true
                    });
             
            }
            else{
                if (e.target.id == 'Funder') {
                    const fundername = FunderData.filter((val: any) => val.key == e.target.value);
                    setfundertype(fundername[0].funderTypeId);
                    setcontractData({
                        ...contractData,
                        [e.target.name]: e.target.value,
                        'fFunderVal': fundername[0].value,
                        'fEmailId': fundername[0].funderEmail || '',
                        'fContactNo': fundername[0].funderPhoneNo || '',
                        'fAddress1': fundername[0].funderAddressLine1 || '',
                        'fCity': fundername[0].funderCityId?.toString() || '0',
                        'fPoBox': fundername[0].funderPobox || '',
                        'ftype': fundername[0].funderTypeId?.toString(),
                        'farray': listfunder
                    });
                    if (Object.keys(commissionerData).length > 0) {
                        setcommissionerData({});
                        setCheck(true);
                        setTimeout(() => {
                            setCheck(false);
                        }, 100);
                    }
                } else {
                    setcontractData({
                        ...contractData,
                        [e.target.name]: e.target.value
                    });
                }
            }
           
        }
    };
    useEffect(() => {
        setfundertype(contractData?.ftype);
    }, [contractData]);

    useEffect(() => {
        if (fundertype) {
            getFilterFunder();
            if (fundertype === '4') {
               const isexists = [...ListData].some((val: any,i:any) => {
                if (val.funderData.fFunder === props.selfData.FullName){
                    if(DataLeadInfo?.data?.contract[i]?.status !== "" && DataLeadInfo !== ""){
                        if(DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled'){
                            return false
                        } else{
                            return true
                        }
                    } else{
                        return true
                    }
                } else {
                    return false;
                }
            });
               if (isexists) {
                swal('Funder is already added', {
                    icon: 'warning',
                    dangerMode: true
                });
         
        }
        else{
            setcontractData({
                ...contractData,
                'fFunderVal': props.selfData.FullName || '',
                'fEmailId': props.selfData.EmailId || '',
                'fFunder': props.selfData.FullName || '',
                'fContactNo': props.selfData.ContactNo || '',
                'fAddress1': props.selfData.Address1 || '',
                'fCity': props.selfData.City || '0',
                'fPoBox': props.selfData.PoBox || ''
            });
             setallvoid(false);
        }
               
            }
        }
        async function getFilterFunder() {
            await getFunderData(fundertype);
        }
    }, [fundertype, props.selfData]);

    const updateCData = (e: any) => {
        setcommissionerData({
            ...commissionerData,
            [e.target.name]: e.target.value
        });
    };
    const updateSData = (e: any) => {
        setcommissionerData({
            ...commissionerData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        
    }, [commissionerData]);

    const updateFData = (attachments: any) => {
        if (attachments?.length > 0) {
            
            let attarr:any = [];
            for(let i=0;i<attachments?.length;i++){
                let data = {
                    contractDocumentId: 0,
                    filePath: attachments[i].filePath,
                    fileName: attachments[i].fileName,
                    attachmentType : 1,
                    isDelete : 0
                };
                attarr.push(data);
            }
            setcontractData({
                ...contractData,
               "contractAttachmentModels" : attarr
            })
        }
        // setcontractData({
        //     ...contractData,
        //     ['file']: e.file,
        //     ['filepath']: e.filedata.filePath,
        //     ['filename']: e.filedata.fileName,
        //     ['isFilePond']: false
        // });
    };

    const updatefilepond = () => {
        setcontractData({
            ...contractData,
            'file': '',
            'filepath': '',
            'filename': '',
            'isFilePond': ''
        });
    };

    useEffect(() => {
        if (DataPatientDraftInfo !== '' && DataPatientDraftInfo?.data?.contractDetails?.length > 0 && DataFunderInfo !== '') {
            let tempdata = [];
            let obj;
            for (let i = 0; i < DataPatientDraftInfo?.data?.contractDetails?.length; i++) {
                const fundername = DataFunderInfo.data.filter((val: any) => val.key == DataPatientDraftInfo?.data?.contractDetails[i]?.funderId);
                const funderarr = DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? [] : DataFunderInfo.data.filter((val: any) => val?.funderTypeId === fundername[0]?.funderTypeId);
                let contractData = {
                    fAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.funderAddressLine1 || '',
                    fCity: DataPatientDraftInfo?.data?.contractDetails[i]?.funderCityId?.toString() || '',
                    fContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.funderPhoneNo || '',
                    fEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderEmail || '',
                    fFunder:
                        DataPatientDraftInfo?.data?.contractDetails[i].isSelfFunded === 1
                            ? DataPatientDraftInfo?.data?.contractDetails[i]?.funderName
                            : DataPatientDraftInfo?.data?.contractDetails[i]?.funderId?.toString(),
                    fPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.funderPobox || '',
                    fFunderVal: DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? DataPatientDraftInfo?.data?.contractDetails[i]?.funderName : fundername[0]?.value,
                    filepath: DataPatientDraftInfo?.data?.contractDetails[i]?.documentPath,
                    ftype: DataPatientDraftInfo?.data?.contractDetails[i]?.isSelfFunded === 1 ? '4' : fundername[0]?.funderTypeId?.toString(),
                    filename: DataPatientDraftInfo?.data?.contractDetails[i]?.documentName,
                    isFilePond: DataPatientDraftInfo?.data?.contractDetails[i]?.documentName === null ? true : false,
                    fReferralId: DataPatientDraftInfo?.data?.referralId,
                    fLeadPoolingDetailsId: DataPatientDraftInfo?.data?.leadPoolingId,
                    fdraftLeadId: DataPatientDraftInfo?.data?.draftLeadId,
                    fFunderId: DataPatientDraftInfo?.data?.contractDetails[i]?.funderId,
                    fleadFunderId: DataPatientDraftInfo?.data?.contractDetails[i]?.leadFunderId,
                    fdailyCoreFee: DataPatientDraftInfo?.data?.contractDetails[i]?.dailyCoreFee,
                    farray: funderarr,
                    contractAttachmentModels : DataPatientDraftInfo?.data?.contractDetails[i]?.contractAttachmentModels,
                    attachments : DataPatientDraftInfo?.data?.contractDetails[i]?.attachments,
                    acdid:DataPatientDraftInfo?.data?.contractDetails[i]?.acdid,
                    fsuid: DataPatientDraftInfo?.data?.contractDetails[i]?.suid || '',
                    fnote: DataPatientDraftInfo?.data?.contractDetails[i]?.note,
                    fpo:DataPatientDraftInfo?.data?.contractDetails[i]?.pO_Reference || ''
                };
                let commissionerData = {
                    cAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerAddressLine1 || '',
                    cCity: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerCityId?.toString() || '',
                    cContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerPhonNo || '',
                    cEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerEmail || '',
                    cFunder: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerName || '',
                    cPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.commissionerPobox || '',

                    sAddress1: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerAddress || '',
                    sCity: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerCityId?.toString() || '',
                    sContactNo: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerContactNo || '',
                    sEmailId: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerEmailId || '',
                    sFunder: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerName || '',
                    sPoBox: DataPatientDraftInfo?.data?.contractDetails[i]?.socialWorkerPobox || ''
                };
                obj = {
                    funderData: contractData,
                    commisionerData: commissionerData,
                    isinvoicecreated: DataPatientDraftInfo?.data?.isInvoiceCreated,
                    status: DataPatientDraftInfo?.data?.contractDetails[i]?.docusignStatus
                };
                tempdata.push(obj);
            }
            if (DataPatientDraftInfo?.data?.contractDetails?.length === tempdata.length) {
                setlistdata(tempdata);
                setDisplay(false);
            }
        } else {
            setlistdata([]);
            setcommissionerData('');
            setcontractData('');
        }
    }, [DataPatientDraftInfo]);

    const addFunder = () => {
        let obj = {
            funderData: contractData,
            commisionerData: commissionerData
        };
        // if(ListData.length > 1){
        //     let listf : any[] = [];
        //     listf.push(Number(obj.funderData.fFunder));
        //     let filterlist = DataFunderInfo.data.filter((item : any) => listf.indexOf(item.key) === -1);
        //     setlistfunder(filterlist);
        // }
        const isexists = [...ListData].some((val: any,i:any) => {
            if (val.funderData.fFunder === contractData.fFunder){
                if(DataLeadInfo?.data?.contract[i]?.status !== "" && DataLeadInfo !== ""){
                    if(DataLeadInfo?.data?.contract[i]?.status === 'Voided' || DataLeadInfo?.data?.contract[i]?.status === 'Cancelled'){
                        return false
                    } else{
                        return true
                    }
                } else{
                    return true
                }
            } else {
                return false;
            }
        });
        if (isexists) {
            swal('Funder is already added', {
                icon: 'error',
                dangerMode: true
            });
        } else {
            
            setlistdata([...ListData, obj]);
            setallvoid(false);
            setDisplay(false);
            setcommissionerData('');
            setcontractData('');
            // eventEmitter.emit('setSplitfalse');
            props.getcontracterror(isContracterror);
            if(aftervoiddata !== null && aftervoiddata?.length > 0){
                setaftervoiddata([...aftervoiddata,obj]);
            }
            else{
                setaftervoiddata([obj]);
            }
           
        }
    };

        const isadd = (isadd: boolean) => {
            
            setDisplay(isadd);
            
            if(ListData?.length >=  1){
                setBack(true);
            }
            else{
                setBack(false);
            }
            if (!isadd) {
                props.getcontracterror(true);
            }
            if (isadd) {
                props.getcontracterror(false);
            }
        };

    const hideform = (val:any) =>{
        setDisplay(false);
        props.getcontracterror(true);
    }

    const updatedfunderlist = (updatedList: any) => {
        setlistdata(updatedList);
        if(DataPatientDraftInfo === ""){
            setaftervoiddata(updatedList);
        }
        
        
    };

    const getcontracterror = (iserror: boolean) => {
        setContracterror(iserror);
    };

    const deletefunderlist = (data: any) => {
        
        props.deletefunderlist(data);
    };

    const updatedcontractdata = (val:any)=>{
            setaftervoiddata(val?.ListData);
    }

    const getaddnaddeletedata = (val:any) =>{
       aftervoiddata?.find((vals:any,i:number) =>{
          if(vals.funderData.fFunder === val.fFunder){
            var array = [...aftervoiddata];
            array.splice(i, 1);
            setaftervoiddata(array);
          }
       })
    }

    useEffect(()=>{
        props.contractdataafteradmission({ListData : aftervoiddata});
    },[aftervoiddata])

    return (
        <>
            {ListData === '' && (
                <>
                    <div>
                        <ContractView
                            condata={contractData?.fFunderVal}
                            patientname={props.patientname}
                            updatefilepond={updatefilepond}
                            updateData={updateData}
                            commissionerData={commissionerData}
                            updateCData={updateCData}
                            updateSData={updateSData}
                            citylist={DataCityInfo}
                            updateFData={updateFData}
                            funderlist={listfunder}
                            getcontracterror={getcontracterror}
                            addFunder={addFunder}
                            isdisplay={isDisplay}
                            isCheck={isCheck}
                            fundertype={fundertype}
                            changetype={changetype}
                            selfData={props.selfData}
                            isback={isBack}
                            hideform={hideform}
                            selectedfunder={ListData}
                            setlistdata={setlistdata}
                        />
                    </div>
                    <div>
                        <FunderListContainer
                            patientname={props.patientname}
                            citylist={DataCityInfo}
                            funderdlist={DataFunderInfo}
                            funderList={ListData}
                            updatedfunderlist={updatedfunderlist}
                            getfundelisterror={props.getfundelisterror}
                            isadd={isadd}
                            deletefunderlist={deletefunderlist}
                            selfData={props.selfData}
                            deleteddoc={props.deleteddoc}
                            updatedcontractdata={updatedcontractdata}
                            isReferralAdd={props.isReferralAdd}
                            isDraftListAdd={props.isDraftListAdd}
                            addnaddeletedata={getaddnaddeletedata}
                            validateSelfFunder={props.validateSelfFunder}
                            setlistdata={setlistdata}
                        />
                    </div>
                </>
            )}
            {ListData !== '' && (
                <>
                    <div>
                        <FunderListContainer
                            patientname={props.patientname}
                            citylist={DataCityInfo}
                            funderdlist={DataFunderInfo}
                            funderList={ListData}
                            getfundelisterror={props.getfundelisterror}
                            updatedfunderlist={updatedfunderlist}
                            isadd={isadd}
                            deletefunderlist={deletefunderlist}
                            selfData={props.selfData}
                            deleteddoc={props.deleteddoc}
                            updatedcontractdata={updatedcontractdata}
                            isReferralAdd={props.isReferralAdd}
                            isDraftListAdd={props.isDraftListAdd}
                            addnaddeletedata={getaddnaddeletedata}
                            validateSelfFunder={props.validateSelfFunder}
                            setlistdata={setlistdata}   
                        />
                    </div>
                    <div>
                        <ContractView
                            condata={contractData?.fFunderVal}
                            patientname={props.patientname}
                            updatefilepond={updatefilepond}
                            updateData={updateData}
                            updateFData={updateFData}
                            funderlist={listfunder}
                            commissionerData={commissionerData}
                            updateCData={updateCData}
                            updateSData={updateSData}
                            citylist={DataCityInfo}
                            getcontracterror={getcontracterror}
                            addFunder={addFunder}
                            isdisplay={isDisplay}
                            isCheck={isCheck}
                            fundertype={fundertype}
                            changetype={changetype}
                            selfData={props.selfData}
                            isback={isBack}
                            hideform={hideform}
                            selectedfunder={ListData}
                            setlistdata={setlistdata}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default ContractContainer;
