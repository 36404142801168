import logo from '../assets/logos/logo-white-website.png';
import logoIcon from '../assets/logos/logo-icon.png';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { rightNavigationPage, Modules, ModulesAccessRights } from '../utils/constant';
import { setPageNavigationAction } from '../store/general/pageNavigationReducer';
import { Tooltip } from 'react-tooltip';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ROUTES_DATA } from '../navigation/RoutesConstants';
import { NavLink } from 'react-router-dom';
import { setNavigationAction } from '../store/dashboard/navigationReducer';
import { switchRoleService } from '../service/auth-service';
import { Roles } from '../utils/constant';

const AdministratorSidebarMenu = (props: any) => {
    return (
        <>
            {props.moduleData.userAccess !== undefined &&
                props.moduleData.userAccess.length > 0 &&
                props.moduleData.userAccess.map((userAccess: any, index: any) => {
                    return (
                        <ul className={`dropdown-menu child-submenu ${props.isIntegrationActive ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
                            {userAccess.accessCode === ModulesAccessRights.Administrator.viewAccess.accessCode && (
                                <NavLink to={ROUTES_DATA.INTEGRATION} className={({ isActive }) => (isActive ? 'dropdown-item child-menu-effect active' : 'dropdown-item child-menu-effect')}>
                                    <span className="icon">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="19"
                                            viewBox="0 0 325.1 365.9"
                                            xmlSpace="preserve"
                                        >
                                            <style>{`"enable-background="new 0 0 325.1 365.9"`} </style>
                                            <path
                                                fill="#4B4B4B"
                                                d="m284.5 128c13.1-0.1 23.2 10.2 23.2 23.6 0.1 25.2 0 50.4 0 75.6v51.2h-290.2c-0.1-0.9-0.2-1.8-0.2-2.8v-123.9c0-13.5 9.5-23.4 23.2-23.8v4 29.6c0 11.3 7.2 18.5 18.5 18.6h10.3c0.2 1.4 0.4 2.5 0.6 3.9 5 0.7 9.9 1.2 14.8 2.1 1.2 0.2 2.5 1.5 3.2 2.7 0.9 1.3 0.9 3.2 1.7 4.5 3.2 4.8 2 8.6-1.9 12.4-2.3 2.2-4 5-5.8 7.4 10.2 10.1 20.2 20.1 30.3 30.1 3.2-2.5 6.7-5 10.1-7.8 1.5-1.3 2.7-1.6 4.6-0.6 3.2 1.7 6.7 2.8 9.9 4.4 1.1 0.5 2.2 1.9 2.4 3 0.8 4.3 1.3 8.6 1.9 13.2h42.5c0.6-4.2 1.3-8.5 1.7-12.8 0.2-2.3 1.2-3.1 3.2-3.8 3.2-1.1 6.3-2.5 9.2-4 1.8-0.9 3.1-0.8 4.7 0.5 3.3 2.8 6.9 5.4 10 7.8 10.1-10.1 20.1-20 30.3-30.3-2.7-3.4-5.8-7.1-8.5-11.1-0.8-1.1-0.8-3.1-0.6-4.6 0.2-1.6 1.6-3 1.7-4.5 0.7-6.1 4.6-7.3 9.8-7.5 3.2-0.1 6.4-0.8 9.8-1.2 0.2-1.3 0.4-2.5 0.7-4h10.9c10.5-0.2 17.8-7.5 17.9-18 0.1-10 0-20 0-29.9 0.1-1.3 0.1-2.6 0.1-4z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m81.4 173.7v-22c4.6-0.6 9.1-1 13.6-1.8 1.2-0.2 2.7-1.2 3.2-2.2 3.1-6.9 5.9-13.9 9.1-21.5-2.9-3.7-6.4-8.3-9.7-12.5l15.8-15.8c3.6 2.8 7.6 5.8 11.4 8.9 1.8 1.5 3.1 1.6 5.2 0.5 5.1-2.5 10.4-4.7 15.9-6.4 2.5-0.8 3.4-1.9 3.6-4.3 0.5-5 1.2-9.9 1.8-15h22.1c0.6 5 1.3 10 1.8 15.1 0.2 2.4 1.2 3.4 3.7 4.2 5.4 1.7 10.7 4 15.9 6.5 2.1 1 3.5 1 5.2-0.5 3.8-3.2 7.8-6.1 11.5-9 5.3 5.3 10.4 10.4 15.7 15.8-3.2 4.2-6.8 8.8-9.7 12.6 3.2 7.6 6 14.6 9.1 21.5 0.5 1 2.1 2 3.3 2.2 4.4 0.7 8.9 1.2 13.6 1.7v22.2c-4.5 0.6-8.8 1-13.1 1.8-1 0.2-2.4 1.2-2.7 2.2-2.8 7.7-5.3 15.4-8.2 24 2.2 2.8 5.1 6.6 7.9 10.2l-15.7 15.7c-3.3-2.6-7-5.4-9.6-7.4-9 3.5-17.2 6.6-25.3 9.9-0.8 0.3-1.6 1.5-1.7 2.3-0.6 3.6-1.1 7.1-1.6 11.1h-22.1c-0.5-3.6-0.9-7.3-1.6-11-0.2-0.9-1.1-2.1-2-2.5-8-3.3-16.1-6.4-25-9.9-2.6 2-6.2 4.8-9.7 7.5-5.3-5.3-10.3-10.4-15.6-15.7 2.8-3.6 5.7-7.4 7.9-10.3-2.9-8.6-5.5-16.5-8.2-24.3-0.3-0.9-1.6-1.7-2.6-1.9-4.3-0.8-8.6-1.3-13.2-1.9zm81 25.9c19.1 0 34.5-15.3 34.5-34.4 0-19-15.4-34.6-34.4-34.6s-34.5 15.4-34.5 34.4c0.1 19.2 15.3 34.5 34.4 34.6z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m308.1 290.7c-0.5 6.1-0.2 12.2-1.5 17.9-2.3 10-11.6 16.6-22.2 16.6-25.2 0.1-50.4 0-75.6 0h-165.5c-16.6 0-26-9.3-26-25.9v-8.7c97 0.1 193.5 0.1 290.8 0.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m110.3 354.4v-17.2h104.5v17.2h20.5c1.2 0 2.5-0.1 3.7 0.1 3.1 0.7 4.8 2.8 4.6 5.9-0.1 2.9-1.8 4.9-4.8 5.3-1.2 0.2-2.5 0.1-3.7 0.1h-145.2-0.4c-5.7 0-8.3-1.8-8.3-5.7s2.7-5.8 8.3-5.8c6.8 0.1 13.5 0.1 20.8 0.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m272.8 98.8v6.6 55c0 6.3-1.7 8-8 8.1h-9.2v-11.5c1.8-0.1 3.5-0.2 5.6-0.3v-4.1-57.6c0-6 1.8-7.7 7.8-7.7 6.4 0 12.7-0.1 19.1 0 2.1 0 3.3-0.5 4.3-2.5 3.9-7.3 11.9-10.7 19.7-8.6 7.5 2 12.8 9 12.8 16.9 0 8.1-5.3 14.8-13.2 16.9-7.6 1.9-15.4-1.5-19.2-8.7-1.1-2-2.3-2.5-4.3-2.4-4.9 0-9.9-0.1-15.4-0.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m63.8 156.6c2.2 0.1 3.8 0.2 5.6 0.3v11.4c-4 0-8 0.2-12-0.1-3.6-0.3-5.1-2.4-5.1-6.5-0.1-11.8 0-23.7 0-35.5v-27.5c-6.1 0-11.9-0.1-17.7 0.1-0.8 0-1.7 1.9-2.3 3-4 6.6-11.6 9.9-18.9 8.1-7.6-1.9-13-8.3-13.4-15.7-0.4-8.2 4.1-15.1 11.7-17.7 8-2.8 16.5 0.4 20.8 8.1 1.1 1.9 2.2 2.6 4.3 2.5 6.6-0.1 13.2-0.1 19.8 0 5.3 0 7.2 1.9 7.2 7.2v58 4.3z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m68.6 40.7h28.4c5.3 0 7.3 1.9 7.3 7.2 0 13 0.1 25.9-0.1 38.9 0 1.6-0.9 3.5-2 4.8-2.6 3.1-5.6 5.9-9.2 9.6v-48.5c-1.3-0.2-2.2-0.4-3.1-0.4-4 0-8 0.1-12-0.1-5.3-0.3-9.5-0.1-12.9 5.5-3.9 6.4-12.4 7.8-19.4 4.9-6.9-2.9-11.4-10-10.8-17.2 0.7-7.7 6.1-14.2 13.3-15.8 7.6-1.7 15 1.4 18.9 8 0.6 0.9 1 2 1.6 3.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m232.3 52.3v48.7c-4-4-7.6-7.4-10.9-11-0.6-0.7-0.5-2.1-0.5-3.2v-38.9c0-5.2 2-7.1 7.3-7.1 8.2 0 16.5-0.1 24.7 0 2.4 0 3.9-0.4 5.2-2.9 4.1-7.6 13.3-10.7 21.3-7.5 7.9 3.1 12.3 11.5 10.5 20.1-1.7 8.1-9.3 14-17.8 13.5-6.5-0.4-11.4-3.5-14.4-9.3-1-1.8-2-2.4-4-2.4-7 0.1-14 0-21.4 0z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m168.4 69.6h-11.7v-16.5c0-5.4-0.1-10.7 0.1-16.1 0.1-2.3-0.6-3.4-2.7-4.5-6.6-3.7-9.6-9.6-8.7-17.2 0.8-6.9 4.8-11.7 11.2-14.2 8.4-3.2 17.9 0.8 21.7 8.9 3.8 8.2 0.9 17.9-7 22.3-2.4 1.3-2.9 2.8-2.9 5.2 0.1 10.6 0 21.2 0 32.1z"
                                            />
                                        </svg>
                                    </span>
                                    <span className="title">Integration Interface</span>
                                </NavLink>
                            )}
                            {userAccess.accessCode === ModulesAccessRights.Administrator.userManagementAccess.accessCode && (
                                <NavLink to={ROUTES_DATA.HELP} className={'dropdown-item child-menu-effect '}>
                                    <span className="icon">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="19"
                                            viewBox="0 0 325.1 365.9"
                                            xmlSpace="preserve"
                                        >
                                            <style>{`"enable-background="new 0 0 325.1 365.9"`} </style>
                                            <path
                                                fill="#4B4B4B"
                                                d="m284.5 128c13.1-0.1 23.2 10.2 23.2 23.6 0.1 25.2 0 50.4 0 75.6v51.2h-290.2c-0.1-0.9-0.2-1.8-0.2-2.8v-123.9c0-13.5 9.5-23.4 23.2-23.8v4 29.6c0 11.3 7.2 18.5 18.5 18.6h10.3c0.2 1.4 0.4 2.5 0.6 3.9 5 0.7 9.9 1.2 14.8 2.1 1.2 0.2 2.5 1.5 3.2 2.7 0.9 1.3 0.9 3.2 1.7 4.5 3.2 4.8 2 8.6-1.9 12.4-2.3 2.2-4 5-5.8 7.4 10.2 10.1 20.2 20.1 30.3 30.1 3.2-2.5 6.7-5 10.1-7.8 1.5-1.3 2.7-1.6 4.6-0.6 3.2 1.7 6.7 2.8 9.9 4.4 1.1 0.5 2.2 1.9 2.4 3 0.8 4.3 1.3 8.6 1.9 13.2h42.5c0.6-4.2 1.3-8.5 1.7-12.8 0.2-2.3 1.2-3.1 3.2-3.8 3.2-1.1 6.3-2.5 9.2-4 1.8-0.9 3.1-0.8 4.7 0.5 3.3 2.8 6.9 5.4 10 7.8 10.1-10.1 20.1-20 30.3-30.3-2.7-3.4-5.8-7.1-8.5-11.1-0.8-1.1-0.8-3.1-0.6-4.6 0.2-1.6 1.6-3 1.7-4.5 0.7-6.1 4.6-7.3 9.8-7.5 3.2-0.1 6.4-0.8 9.8-1.2 0.2-1.3 0.4-2.5 0.7-4h10.9c10.5-0.2 17.8-7.5 17.9-18 0.1-10 0-20 0-29.9 0.1-1.3 0.1-2.6 0.1-4z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m81.4 173.7v-22c4.6-0.6 9.1-1 13.6-1.8 1.2-0.2 2.7-1.2 3.2-2.2 3.1-6.9 5.9-13.9 9.1-21.5-2.9-3.7-6.4-8.3-9.7-12.5l15.8-15.8c3.6 2.8 7.6 5.8 11.4 8.9 1.8 1.5 3.1 1.6 5.2 0.5 5.1-2.5 10.4-4.7 15.9-6.4 2.5-0.8 3.4-1.9 3.6-4.3 0.5-5 1.2-9.9 1.8-15h22.1c0.6 5 1.3 10 1.8 15.1 0.2 2.4 1.2 3.4 3.7 4.2 5.4 1.7 10.7 4 15.9 6.5 2.1 1 3.5 1 5.2-0.5 3.8-3.2 7.8-6.1 11.5-9 5.3 5.3 10.4 10.4 15.7 15.8-3.2 4.2-6.8 8.8-9.7 12.6 3.2 7.6 6 14.6 9.1 21.5 0.5 1 2.1 2 3.3 2.2 4.4 0.7 8.9 1.2 13.6 1.7v22.2c-4.5 0.6-8.8 1-13.1 1.8-1 0.2-2.4 1.2-2.7 2.2-2.8 7.7-5.3 15.4-8.2 24 2.2 2.8 5.1 6.6 7.9 10.2l-15.7 15.7c-3.3-2.6-7-5.4-9.6-7.4-9 3.5-17.2 6.6-25.3 9.9-0.8 0.3-1.6 1.5-1.7 2.3-0.6 3.6-1.1 7.1-1.6 11.1h-22.1c-0.5-3.6-0.9-7.3-1.6-11-0.2-0.9-1.1-2.1-2-2.5-8-3.3-16.1-6.4-25-9.9-2.6 2-6.2 4.8-9.7 7.5-5.3-5.3-10.3-10.4-15.6-15.7 2.8-3.6 5.7-7.4 7.9-10.3-2.9-8.6-5.5-16.5-8.2-24.3-0.3-0.9-1.6-1.7-2.6-1.9-4.3-0.8-8.6-1.3-13.2-1.9zm81 25.9c19.1 0 34.5-15.3 34.5-34.4 0-19-15.4-34.6-34.4-34.6s-34.5 15.4-34.5 34.4c0.1 19.2 15.3 34.5 34.4 34.6z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m308.1 290.7c-0.5 6.1-0.2 12.2-1.5 17.9-2.3 10-11.6 16.6-22.2 16.6-25.2 0.1-50.4 0-75.6 0h-165.5c-16.6 0-26-9.3-26-25.9v-8.7c97 0.1 193.5 0.1 290.8 0.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m110.3 354.4v-17.2h104.5v17.2h20.5c1.2 0 2.5-0.1 3.7 0.1 3.1 0.7 4.8 2.8 4.6 5.9-0.1 2.9-1.8 4.9-4.8 5.3-1.2 0.2-2.5 0.1-3.7 0.1h-145.2-0.4c-5.7 0-8.3-1.8-8.3-5.7s2.7-5.8 8.3-5.8c6.8 0.1 13.5 0.1 20.8 0.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m272.8 98.8v6.6 55c0 6.3-1.7 8-8 8.1h-9.2v-11.5c1.8-0.1 3.5-0.2 5.6-0.3v-4.1-57.6c0-6 1.8-7.7 7.8-7.7 6.4 0 12.7-0.1 19.1 0 2.1 0 3.3-0.5 4.3-2.5 3.9-7.3 11.9-10.7 19.7-8.6 7.5 2 12.8 9 12.8 16.9 0 8.1-5.3 14.8-13.2 16.9-7.6 1.9-15.4-1.5-19.2-8.7-1.1-2-2.3-2.5-4.3-2.4-4.9 0-9.9-0.1-15.4-0.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m63.8 156.6c2.2 0.1 3.8 0.2 5.6 0.3v11.4c-4 0-8 0.2-12-0.1-3.6-0.3-5.1-2.4-5.1-6.5-0.1-11.8 0-23.7 0-35.5v-27.5c-6.1 0-11.9-0.1-17.7 0.1-0.8 0-1.7 1.9-2.3 3-4 6.6-11.6 9.9-18.9 8.1-7.6-1.9-13-8.3-13.4-15.7-0.4-8.2 4.1-15.1 11.7-17.7 8-2.8 16.5 0.4 20.8 8.1 1.1 1.9 2.2 2.6 4.3 2.5 6.6-0.1 13.2-0.1 19.8 0 5.3 0 7.2 1.9 7.2 7.2v58 4.3z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m68.6 40.7h28.4c5.3 0 7.3 1.9 7.3 7.2 0 13 0.1 25.9-0.1 38.9 0 1.6-0.9 3.5-2 4.8-2.6 3.1-5.6 5.9-9.2 9.6v-48.5c-1.3-0.2-2.2-0.4-3.1-0.4-4 0-8 0.1-12-0.1-5.3-0.3-9.5-0.1-12.9 5.5-3.9 6.4-12.4 7.8-19.4 4.9-6.9-2.9-11.4-10-10.8-17.2 0.7-7.7 6.1-14.2 13.3-15.8 7.6-1.7 15 1.4 18.9 8 0.6 0.9 1 2 1.6 3.1z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m232.3 52.3v48.7c-4-4-7.6-7.4-10.9-11-0.6-0.7-0.5-2.1-0.5-3.2v-38.9c0-5.2 2-7.1 7.3-7.1 8.2 0 16.5-0.1 24.7 0 2.4 0 3.9-0.4 5.2-2.9 4.1-7.6 13.3-10.7 21.3-7.5 7.9 3.1 12.3 11.5 10.5 20.1-1.7 8.1-9.3 14-17.8 13.5-6.5-0.4-11.4-3.5-14.4-9.3-1-1.8-2-2.4-4-2.4-7 0.1-14 0-21.4 0z"
                                            />
                                            <path
                                                fill="#4B4B4B"
                                                d="m168.4 69.6h-11.7v-16.5c0-5.4-0.1-10.7 0.1-16.1 0.1-2.3-0.6-3.4-2.7-4.5-6.6-3.7-9.6-9.6-8.7-17.2 0.8-6.9 4.8-11.7 11.2-14.2 8.4-3.2 17.9 0.8 21.7 8.9 3.8 8.2 0.9 17.9-7 22.3-2.4 1.3-2.9 2.8-2.9 5.2 0.1 10.6 0 21.2 0 32.1z"
                                            />
                                        </svg>
                                    </span>
                                    <span className="title">User Management</span>
                                </NavLink>
                            )}
                        </ul>
                    );
                })}
        </>
    );
};

const SideNav: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [showModules, setShowModules] = useState<any>();
    const { ShowClass } = useSelector((state: RootState) => state.navigation);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const [isIntegrationActive, setisIntegrationActive] = useState<boolean>();
    useEffect(() => {
        if (StatusRoleSelected === true) {
            setShowModules(DataRoleSelected.modules);
        }
    }, [StatusRoleSelected, DataRoleSelected]);

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const changeService = async (roleId: any) => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
        await switchRoleService(roleId);
    };

    useEffect(() => {
        
        if (location.pathname == ROUTES_DATA.INTEGRATION) {
            setisIntegrationActive(true);
        } else {
            setisIntegrationActive(false);
        }
    }, [location]);

    const handleButtonClick = () => {        
        setisIntegrationActive(!isIntegrationActive);
    };

    return (
        <div>
            <div className={`${ShowClass ? 'navigation show' : 'navigation'}`}>
                <div className=" d-flex align-items-center">
                    <a href="#" className="justify-content-center">
                        <img src={logo} alt="logo" className="img-fluid logo" />
                        <img src={logoIcon} alt="logo" className="img-fluid logo-icon" />
                    </a>
                </div>

                <ul className="pl-0 w-100 list-unstyled">
                    {showModules !== undefined &&
                        showModules.length > 0 &&
                        showModules.map((moduleData: any, index: any) => {
                            if (moduleData.module === Modules.BedManagement) {
                                return (
                                    <li key={index}>
                                        <NavLink to={ROUTES_DATA.DASHBOARD} className={({ isActive }) => (isActive ? 'active' : '')}>
                                            <span className="icon">
                                                <i className="fa fa-bed"></i>
                                            </span>
                                            <span className="title">Bed Management</span>
                                        </NavLink>
                                    </li>
                                );
                            }

                            if (moduleData.module === Modules.BillingManagement && DataServiceDetail?.iqviaServiceEnabled === false) {
                                return (
                                    <li key={index}>
                                        <NavLink to={ROUTES_DATA.BILLINGMANAGEMENT} className={({ isActive }) => (isActive ? 'active' : '')}>
                                            <span className="icon">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="16px"
                                                    viewBox="0 0 309.72 403.57"
                                                    xmlSpace="preserve"
                                                >
                                                    <style>{`"enable-background:new 0 0 309.72 403.57;"`} </style>
                                                    <g>
                                                        <path
                                                            d="M309.72,403.57c-103.18,0-206.37,0-309.55,0c-0.04-0.66-0.12-1.31-0.12-1.97c0-130.27,0-260.53,0.02-390.8
                                c0-1.32-0.4-2.8,0.64-4.14c12.12,8.22,24.08,16.33,36.05,24.42c5.92,4,8.69,3.98,14.72,0.01C66.63,21.1,81.8,11.16,96.88,1.08
                                c2.07-1.38,3.46-1.47,5.55-0.07c14.94,10.05,29.98,19.95,44.96,29.94c5.78,3.86,8.75,3.99,14.42,0.24
                                c14.9-9.87,29.86-19.65,44.6-29.78c3.01-2.07,4.91-1.66,7.58,0.14c14.93,10.06,29.98,19.94,44.92,29.99
                                c4.76,3.2,9.15,3.09,13.8-0.2c6.33-4.48,12.8-8.75,19.22-13.1c5.72-3.88,11.44-7.74,17.74-12c0,2.02,0,3.28,0,4.55
                                c0,44.87-0.02,89.74-0.01,134.61C309.67,231.46,309.7,317.51,309.72,403.57z M154.74,73.2C91.52,73.12,37.33,123.52,37.5,190.82
                                c0.16,64.82,51.67,116.77,117.08,116.89c67.06,0.12,117.52-54.39,117.45-117.44C271.97,125.47,219.76,73.31,154.74,73.2z
                                M154.54,332.91c0-0.02,0-0.03,0-0.05c-25.39,0-50.78-0.01-76.18,0.01c-2.23,0-4.49,0.03-6.69,0.36c-5.33,0.79-8.9,6.02-7.82,11.22
                                c1.23,5.94,4.89,8.54,12.21,8.55c52.49,0.02,104.99,0.02,157.48,0.03c1.85,0,3.66-0.09,5.45-0.69c4.59-1.54,7.27-5.67,6.84-10.69
                                c-0.39-4.53-3.65-7.88-8.42-8.46c-2.08-0.25-4.2-0.28-6.3-0.28C205.59,332.9,180.07,332.91,154.54,332.91z"
                                                        />
                                                        <path
                                                            d="M154.67,287.63c-53.42,0.53-97.3-44.14-97.17-97.52c0.14-54.11,45.35-98.74,100.54-96.95
                                c51.98,1.69,94.74,45.03,94.05,98.7C251.39,245.69,206.68,288.29,154.67,287.63z M145.96,231.23c3.28-8.26,4.14-16.3,4.19-24.45
                                c0.01-2.16,1.09-2.24,2.71-2.22c5,0.06,10,0.11,15,0c5.94-0.13,10.48-4.59,10.49-10.1c0.01-5.41-4.27-9.58-10.29-9.75
                                c-5.39-0.15-10.8-0.18-16.18,0.03c-2.74,0.11-3.67-0.99-4.04-3.51c-0.48-3.22-1.4-6.37-2.03-9.57c-1.01-5.16-1.93-10.43-0.76-15.61
                                c2.51-11.14,14.11-16.51,25.93-12.49c5.71,1.95,7.95,6.29,9.39,11.63c1.58,5.87,6.93,8.88,12.44,7.4
                                c5.15-1.38,8.04-6.82,6.69-12.48c-3.74-15.64-13.55-24.41-29.42-27.18c-25.89-4.52-47.77,15.45-45.42,41.61
                                c0.53,5.91,1.65,11.66,2.96,17.39c0.52,2.28-0.03,2.8-2.16,2.76c-4.6-0.08-9.21-0.1-13.82,0c-6.15,0.13-10.52,4.41-10.4,9.99
                                c0.13,5.56,4.66,9.79,10.68,9.86c4.87,0.06,9.75,0.18,14.6-0.05c2.91-0.13,3.85,0.66,3.75,3.71c-0.33,9.3-2.44,17.87-8.54,25.21
                                c-1.57,1.89-3.15,3.84-4.09,6.23c-2.74,6.96,2.66,14.47,10.08,13.8c3.13-0.28,6.24-0.79,9.36-1.26c9.06-1.36,17.72-1.45,26.45,2.82
                                c12.67,6.19,25.78,4.52,38.56-0.45c4.18-1.63,6.27-5.23,6.08-9.66c-0.19-4.38-2.7-7.43-7.01-8.99c-4.02-1.46-7.47,0.56-11.06,1.54
                                c-6.54,1.79-12.98,2.08-19.2-0.94C163.21,232.77,155.19,230.68,145.96,231.23z"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="title">Billing Management</span>
                                        </NavLink>
                                    </li>
                                );
                            }

                            if (moduleData.module === Modules.ReferralManagement) {
                                return (
                                    <li key={index}>
                                        <NavLink to={ROUTES_DATA.REFERRALMANAGEMENT} className={({ isActive }) => (isActive ? 'active' : '')}>
                                            <span className="icon">
                                                <svg
                                                    enable-background="new 0 0 123.81 123.73"
                                                    width="22"
                                                    version="1.1"
                                                    viewBox="0 0 123.81 123.73"
                                                    xmlSpace="preserve"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <style type="text/css">{`
                                            .st00{fill:#4E4851;}
                                            `}</style>

                                                    <path
                                                        className="st00"
                                                        d="m83.43 21.44c0.3 11.99-10.03 22.27-22.5 21.78-11.05-0.43-21.03-9.52-20.66-22.53 0.32-11.08 9.17-19.91 19.72-20.63 14.2-0.97 23.73 10.33 23.44 21.38zm-39.27 0.26c-0.01 3.66 1.03 6.95 3.16 9.91 0.78 1.08 0.73 1.06 1.44-0.17 3.68-6.36 10.23-8.9 17.15-7.13 4.14 1.06 7.15 3.63 9.25 7.32 0.64 1.14 0.62 1.1 1.35 0.02 2.49-3.71 3.41-7.75 2.96-12.2-0.62-6.26-4.2-10.48-9.28-13.56-3.07-1.86-6.65-2.03-10.15-1.78-2.22 0.16-4.44 0.52-6.44 1.68-6.15 3.54-9.3 8.83-9.44 15.91z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m102.04 71.8c12.34-0.15 20.78 9.98 21.66 19.03 1.39 14.4-10.48 24.65-22.33 24.17-11.42-0.46-20.9-9.55-20.85-21.92 0.04-11.41 9.22-21.31 21.52-21.28zm-17.61 21.56c-0.13 3.93 1.2 7.28 3.33 10.4 0.46 0.68 0.54 0.51 0.96-0.02 1.33-1.7 2.57-3.53 4.17-4.93 3.71-3.24 8.13-3.94 12.86-2.82 4.36 1.03 7.52 3.68 9.72 7.5 0.57 1 0.92 0.65 1.32 0.04 3.04-4.72 4.03-9.87 2.38-15.22-1.78-5.75-5.58-9.78-11.48-11.64-3.97-1.26-7.93-1.18-11.82 0.09-6.32 2.03-11.58 8.74-11.44 16.6z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m43.2 93.25c0.14 12.05-9.86 21.97-22.21 21.73-11.27-0.22-21.15-9.6-20.99-22.01 0.15-11.39 9.2-20.68 20.37-21.16 13.74-0.59 23.03 10.44 22.83 21.44zm-4.02 0.03c0.18-2.35-0.2-4.64-1.17-6.8-2.32-5.2-6.15-8.7-11.72-10.13-4.23-1.09-8.45-0.84-12.36 1.02-5.65 2.68-8.9 7.2-9.76 13.52-0.6 4.4 0.22 8.37 2.52 12.09 0.99 1.59 1 1.59 1.93 0 3.32-5.63 9.79-8.56 15.94-7.18 4.68 1.05 8.26 3.59 10.4 7.97 0.44 0.89 0.78 0.44 1.12-0.05 2.21-3.13 3.36-6.56 3.1-10.44z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m59.94 89.03v-14.24c0-1.14-0.28-1.3-1.29-0.71-4.27 2.48-8.53 4.96-12.78 7.46-0.63 0.37-0.99 0.53-1.47-0.31-1.37-2.38-1.43-2.35 0.95-3.73 3.86-2.24 7.73-4.47 11.59-6.71 1-0.58 0.99-0.79 0.01-1.36-8.39-4.82-16.79-9.63-25.17-14.46-0.26-0.15-0.59-0.63-0.52-0.78 0.42-0.92 0.92-1.81 1.47-2.66 0.07-0.11 0.66-0.01 0.92 0.13 3.19 1.81 6.35 3.65 9.53 5.48 4.17 2.4 8.35 4.79 12.52 7.19 1.1 0.63 2.19 1.27 3.29 1.9 0.24 0.13 0.5 0.21 0.75 0.32 0.07-0.29 0.21-0.58 0.22-0.88 0.01-5.7 0.03-11.41-0.01-17.11-0.01-0.95 0.2-1.27 1.21-1.26 2.81 0.05 2.81 0 2.81 2.78 0 5.12 0.01 10.24-0.01 15.37 0 0.89 0.02 1.33 1.07 0.72 4.52-2.65 9.08-5.25 13.62-7.87 3.87-2.24 7.74-4.47 11.6-6.73 0.57-0.33 0.88-0.25 1.14 0.34 0.16 0.37 0.38 0.72 0.58 1.08 0.89 1.6 0.86 1.55-0.72 2.45-5.33 3.05-10.64 6.15-15.97 9.21-2.75 1.58-5.53 3.12-8.29 4.7-0.36 0.21-0.67 0.53-1 0.8 0.33 0.23 0.63 0.5 0.98 0.7 4.38 2.52 8.75 5.04 13.14 7.54 0.51 0.29 0.84 0.51 0.34 1.11-0.36 0.44-0.71 0.93-0.89 1.46-0.38 1.06-0.98 1.02-1.79 0.55-4-2.34-8-4.67-12-7-0.04-0.02-0.07-0.05-0.1-0.07-0.53-0.18-1.05-0.36-1.58-0.54-0.04 0.49-0.11 0.97-0.11 1.46-0.01 9.04 0 18.07-0.01 27.11 0 1.86 0 1.86-1.82 1.86-2.18 0-2.18 0-2.18-2.14-0.03-4.42-0.03-8.79-0.03-13.16z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m61.8 108.28c4.02-0.53 7.71 3.9 7.95 7.05 0.37 4.78-4.12 8.56-8.05 8.4-4.47-0.18-7.63-3.61-7.69-8.11-0.04-3.46 3.28-7.65 7.79-7.34z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m109.93 46.99c-0.23 4.54-3.7 7.69-7.91 7.74-3.79 0.04-7.93-3.22-7.57-8.35 0.28-4.04 3.37-7.38 8.02-7.26 4.24 0.1 7.32 3.3 7.46 7.87z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m21.55 39.31c4.35-0.36 7.94 3.59 7.86 7.49-0.1 4.73-3.45 7.81-8 7.93-3.78 0.1-7.44-3.39-7.52-7.83-0.08-3.91 3.77-8.13 7.66-7.59z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m69.44 14.88c0.21 4.67-3.92 7.54-8.27 7.64-3.22 0.07-7.11-4.28-6.94-7.72 0.19-3.84 3.15-7.46 7.56-7.46 4.57 0 7.73 3.55 7.65 7.54z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m102.38 79.04c4.37 0.08 7.59 3.48 7.47 7.64-0.13 4.5-3.66 7.62-8.33 7.54-3.28-0.05-6.9-3.5-6.89-7.68 0.01-3.63 3.08-7.58 7.75-7.5z"
                                                    />
                                                    <path
                                                        className="st00"
                                                        d="m13.96 86.5c0.13-3.43 3.04-7.56 7.76-7.45 4.08 0.1 7.51 3.36 7.44 7.58-0.07 4.53-3.24 7.49-7.83 7.57-3.86 0.07-7.13-3.34-7.37-7.7z"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="title">Referral Management</span>
                                        </NavLink>
                                    </li>
                                );
                            }

                            if (moduleData.module === Modules.OnlineHelp) {
                                return (
                                    <li key={index}>
                                        <NavLink to={ROUTES_DATA.HELP} className={({ isActive }) => (isActive ? 'active' : '')}>
                                            <span className="icon">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19"
                                                    viewBox="0 0 344 343.98"
                                                    xmlSpace="preserve"
                                                >
                                                    <style>{`"enable-background:new 0 0 344 343.98;"`} </style>

                                                    <path
                                                        d="M0,175.4c0-2.46,0-4.93,0-7.39c0.19-2.05,0.37-4.11,0.56-6.16c0.03-1.31,0.05-2.63,0.08-3.94
                                    c1.84-10.64,2.76-21.54,5.65-31.89C26.27,54.53,88.46,4.5,162.58,0.29c31.17-1.77,60.79,4.6,88.58,19.06
                                    c67.64,35.2,103.93,109.66,89.82,184.63c-7.01,37.27-24.66,68.93-52.6,94.58c-28.92,26.55-63.08,41.51-102.27,44.84
                                    c-0.66,0-1.33,0-1.99,0c-0.88,0-1.77,0-2.65,0c-2.05,0.19-4.1,0.39-6.15,0.58c-2.24,0-4.48,0-6.72,0c-2.05-0.19-4.1-0.39-6.15-0.58
                                    c-0.88,0-1.76,0-2.65,0c-0.66,0-1.33,0-1.99,0c-5.98-0.77-12.02-1.19-17.91-2.36c-42.66-8.49-77.56-29.83-104.22-64.19
                                    C15,250.19,3.38,219.85,0.6,186.17c0-0.67,0-1.33,0-2c-0.01-0.87-0.02-1.74-0.02-2.62C0.38,179.5,0.19,177.45,0,175.4z
                                    M177.72,71.28c-22.19-0.11-39.1,4.92-53.26,17.37c-11.06,9.72-18.06,21.78-18.96,36.77c-0.49,8.25,5.08,15.35,13.22,17.48
                                    c7.85,2.06,15.21-1.21,19-8.8c1.83-3.67,3.09-7.62,4.63-11.43c2.53-6.24,5.64-12.12,10.53-16.9c8.26-8.07,27.84-8.4,37.46-2.07
                                    c11.92,7.84,15.76,23.39,6.33,35.17c-2.02,2.52-4.31,4.85-6.65,7.07c-7.88,7.46-16.11,14.57-23.72,22.29
                                    c-10.65,10.81-15.15,24.04-13.24,39.15c1.04,8.22,6.85,13.03,15.36,13.53c7.99,0.47,13.63-3.84,15.75-12.04
                                    c0.19-0.75,0.47-1.5,0.58-2.26c1.58-10.34,6.27-18.84,14.26-25.73c7.88-6.79,15.37-14.03,23.02-21.09
                                    c26.16-24.11,18.62-58.47-4.15-75.19C204.85,75.03,190.02,71.49,177.72,71.28z M150.28,253.07c0.05,11.64,8.4,19.81,20.19,19.75
                                    c11.41-0.05,19.73-8.54,19.65-20.04c-0.08-11.03-8.87-19.7-19.9-19.63C158.96,233.23,150.23,241.94,150.28,253.07z"
                                                    />
                                                    <path d="M0.56,161.85c-0.19,2.05-0.37,4.11-0.56,6.16c0-2.01,0-4.03,0-6.04C0.18,161.91,0.37,161.87,0.56,161.85z" />
                                                    <path d="M0,175.4c0.19,2.05,0.38,4.11,0.58,6.16c-0.19-0.04-0.38-0.08-0.58-0.11C0,179.43,0,177.41,0,175.4z" />
                                                    <path
                                                        d="M162.46,343.39c2.05,0.19,4.1,0.39,6.15,0.58c-2.02,0-4.03,0-6.05,0C162.55,343.78,162.52,343.59,162.46,343.39
                                    z"
                                                    />
                                                    <path
                                                        d="M175.33,343.98c2.05-0.19,4.1-0.39,6.15-0.59c-0.06,0.19-0.09,0.39-0.1,0.59
                                    C179.36,343.98,177.34,343.98,175.33,343.98z"
                                                    />
                                                    <path
                                                        d="M0.56,161.85c-0.19,0.02-0.38,0.06-0.56,0.12c0-1.34,0-2.69,0-4.03c0.21-0.01,0.43-0.02,0.64-0.03
                                    C0.61,159.22,0.59,160.53,0.56,161.85z"
                                                    />
                                                    <path
                                                        d="M0,181.44c0.19,0.04,0.39,0.07,0.58,0.11c0.01,0.87,0.01,1.75,0.02,2.62c-0.2-0.05-0.4-0.06-0.6-0.04
                                    C0,183.23,0,182.34,0,181.44z"
                                                    />
                                                    <path
                                                        d="M162.46,343.39c0.05,0.19,0.09,0.39,0.1,0.58c-0.9,0-1.79,0-2.69,0c0.02-0.2,0-0.39-0.06-0.58
                                    C160.7,343.4,161.58,343.4,162.46,343.39z"
                                                    />
                                                    <path
                                                        d="M181.37,343.98c0.01-0.2,0.04-0.39,0.1-0.59c0.88,0,1.77,0,2.65,0c-0.06,0.19-0.08,0.38-0.06,0.58
                                    C183.16,343.98,182.27,343.98,181.37,343.98z"
                                                    />
                                                    <path
                                                        d="M0,184.13c0.2-0.02,0.4,0,0.6,0.04c0,0.67,0,1.33,0,2c0,0-0.3-0.04-0.3-0.04L0,186.14
                                    C0,185.47,0,184.8,0,184.13z"
                                                    />
                                                    <path
                                                        d="M159.82,343.4c0.06,0.19,0.08,0.38,0.06,0.58c-0.67,0-1.34,0-2.02,0c0.02-0.2,0.01-0.39-0.03-0.58
                                    C158.49,343.4,159.15,343.4,159.82,343.4z"
                                                    />
                                                    <path
                                                        d="M184.06,343.98c-0.02-0.2,0-0.39,0.06-0.58c0.66,0,1.33,0,1.99,0.01c-0.04,0.19-0.05,0.38-0.04,0.58
                                    C185.4,343.98,184.73,343.98,184.06,343.98z"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="title">Online Help</span>
                                        </NavLink>
                                    </li>
                                );
                            }
                            if (moduleData.module === Modules.Administrator) {
                                return (
                                    <li key={index}>
                                        <a
                                            id="dropdownMenuButton1"
                                            aria-expanded={`${isIntegrationActive ? 'true' : 'false'}`}
                                            className={`sidebar-menu ${isIntegrationActive ? 'show' : ''}`}
                                            onClick={handleButtonClick}
                                        >
                                            <span className="icon">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19"
                                                    viewBox="0 0 341.2 383.2"
                                                    xmlSpace="preserve"
                                                >
                                                    <style>{`"enable-background="new 0 0 341.2 383.2"`} </style>
                                                    <path
                                                        fill="#4B4B4B"
                                                        d="m76.3 383.2c0.1-16.5-0.2-32.9 0.2-49.4 0.8-34 16.1-59.2 46.3-75.1 9.6-5 20-8 30.8-8.1 14.2-0.2 28.5-0.8 42.6 0.9 34.6 4.1 63.3 32.6 68.7 67 0.9 5.6 1.3 11.4 1.4 17.1 0.2 15.8 0.1 31.7 0.2 47.5-63.4 0.1-126.8 0.1-190.2 0.1z"
                                                    />
                                                    <path
                                                        fill="#4B4B4B"
                                                        d="m218.4 233.3c36.9-30.6 36.4-82.3 6.5-112.4-28.7-28.9-75.4-29.6-105.1-2-31.7 29.5-33.3 83.8 4.5 114.4-31.9 11.9-53.5 34-65.3 66.1-11.3-10.8-22.3-21.3-33.4-32 0.7-1.3 1.3-2.5 2-3.6 6.2-10.1 12.4-20.2 18.7-30.2 1.3-2.1 1.4-3.6 0.3-5.8-1.4-2.6-2.7-5.4-3.4-8.3-1-4-3.4-5.4-7.2-6.2-11-2.4-21.8-5.1-32.7-7.7-1.1-0.3-2.1-0.6-3.2-1v-68.4c12.6-3 25.2-6.1 37.8-9 2.7-0.6 4-1.8 4.8-4.4 0.7-2.4 1.5-4.9 2.8-6.9 2.8-4.2 1.7-7.5-0.9-11.4-6-9.1-11.4-18.5-17.2-27.6-1.4-2.2-1.4-3.4 0.5-5.3 14.7-14.6 29.4-29.2 44-43.9 1.7-1.7 2.9-1.9 5-0.6 9.9 6.3 20 12.2 29.9 18.5 2.6 1.6 4.7 2.2 7.4 0.5 2.1-1.3 4.5-2.4 6.9-2.9 4.7-1 6.1-3.9 7-8.2 2.5-11.8 5.5-23.5 8.3-35.2h68.6c3 12.6 6.1 25.2 9 37.8 0.6 2.7 1.8 4 4.3 4.8 3.1 1 6.1 2.2 9 3.7 2.6 1.4 4.6 1.3 7.1-0.4 10-6.4 20.2-12.4 30.2-18.7 2.2-1.4 3.3-1.1 5 0.6 14.6 14.7 29.2 29.4 44 43.9 2 1.9 1.8 3.2 0.5 5.3-6.4 10.2-12.7 20.6-19.1 30.8-1.1 1.8-1.3 3.2-0.3 5.1 1.5 2.9 3 5.8 3.8 8.9 0.9 3.8 3.2 5 6.7 5.8 12 2.7 24 5.6 36.2 8.5v68.7c-9.2 2.2-18.5 4.4-27.8 6.7-4.2 1-9.4 0.9-12.4 3.4-3 2.4-3.6 7.7-5.6 11.4-1.7 3.1-1.5 5.4 0.5 8.4 6.7 10.5 13.1 21.1 19.8 32-10.4 11.1-20.7 22.2-31.4 33.6-12-32.2-33.7-54.6-66.1-66.8z"
                                                    />
                                                    <path fill="#4B4B4B" d="m224 173.9c0.1 29.1-23.4 52.7-52.4 52.8s-52.6-23.4-52.8-52.4c-0.1-29.2 23.5-53 52.6-53 29 0 52.5 23.5 52.6 52.6z" />
                                                </svg>
                                            </span>
                                            <span className="title parent-menu-css">
                                                Administrator <i className="fa fa-angle-right custom-arrow" aria-hidden="true"></i>{' '}
                                            </span>
                                        </a>
                                        {isIntegrationActive && <AdministratorSidebarMenu moduleData={moduleData} isIntegrationActive={isIntegrationActive} />}
                                        {/* {moduleData.userAccess  !== undefined &&
                        moduleData.userAccess.length > 0 &&
                        moduleData.userAccess.map((userAccess: any, index: any) => {
                            return (
                           

                        
                        
                                    <ul className={`dropdown-menu child-submenu ${isIntegrationActive ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1" onClick={eventHandler}>
                                    {userAccess.accessCode === ModulesAccessRights.Administrator.viewAccess.accessCode &&
                                        <NavLink to={ROUTES_DATA.INTEGRATION} className={({ isActive }) => (isActive ? 'dropdown-item child-menu-effect active' : 'dropdown-item child-menu-effect')}>
                                            <span className="icon">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19"
                                                    viewBox="0 0 341.2 383.2"
                                                    xmlSpace="preserve"
                                                >
                                                    <style>{`"enable-background="new 0 0 341.2 383.2"`} </style>
                                                    <path
                                                        fill="#4B4B4B"
                                                        d="m76.3 383.2c0.1-16.5-0.2-32.9 0.2-49.4 0.8-34 16.1-59.2 46.3-75.1 9.6-5 20-8 30.8-8.1 14.2-0.2 28.5-0.8 42.6 0.9 34.6 4.1 63.3 32.6 68.7 67 0.9 5.6 1.3 11.4 1.4 17.1 0.2 15.8 0.1 31.7 0.2 47.5-63.4 0.1-126.8 0.1-190.2 0.1z"
                                                    />
                                                    <path
                                                        fill="#4B4B4B"
                                                        d="m218.4 233.3c36.9-30.6 36.4-82.3 6.5-112.4-28.7-28.9-75.4-29.6-105.1-2-31.7 29.5-33.3 83.8 4.5 114.4-31.9 11.9-53.5 34-65.3 66.1-11.3-10.8-22.3-21.3-33.4-32 0.7-1.3 1.3-2.5 2-3.6 6.2-10.1 12.4-20.2 18.7-30.2 1.3-2.1 1.4-3.6 0.3-5.8-1.4-2.6-2.7-5.4-3.4-8.3-1-4-3.4-5.4-7.2-6.2-11-2.4-21.8-5.1-32.7-7.7-1.1-0.3-2.1-0.6-3.2-1v-68.4c12.6-3 25.2-6.1 37.8-9 2.7-0.6 4-1.8 4.8-4.4 0.7-2.4 1.5-4.9 2.8-6.9 2.8-4.2 1.7-7.5-0.9-11.4-6-9.1-11.4-18.5-17.2-27.6-1.4-2.2-1.4-3.4 0.5-5.3 14.7-14.6 29.4-29.2 44-43.9 1.7-1.7 2.9-1.9 5-0.6 9.9 6.3 20 12.2 29.9 18.5 2.6 1.6 4.7 2.2 7.4 0.5 2.1-1.3 4.5-2.4 6.9-2.9 4.7-1 6.1-3.9 7-8.2 2.5-11.8 5.5-23.5 8.3-35.2h68.6c3 12.6 6.1 25.2 9 37.8 0.6 2.7 1.8 4 4.3 4.8 3.1 1 6.1 2.2 9 3.7 2.6 1.4 4.6 1.3 7.1-0.4 10-6.4 20.2-12.4 30.2-18.7 2.2-1.4 3.3-1.1 5 0.6 14.6 14.7 29.2 29.4 44 43.9 2 1.9 1.8 3.2 0.5 5.3-6.4 10.2-12.7 20.6-19.1 30.8-1.1 1.8-1.3 3.2-0.3 5.1 1.5 2.9 3 5.8 3.8 8.9 0.9 3.8 3.2 5 6.7 5.8 12 2.7 24 5.6 36.2 8.5v68.7c-9.2 2.2-18.5 4.4-27.8 6.7-4.2 1-9.4 0.9-12.4 3.4-3 2.4-3.6 7.7-5.6 11.4-1.7 3.1-1.5 5.4 0.5 8.4 6.7 10.5 13.1 21.1 19.8 32-10.4 11.1-20.7 22.2-31.4 33.6-12-32.2-33.7-54.6-66.1-66.8z"
                                                    />
                                                    <path fill="#4B4B4B" d="m224 173.9c0.1 29.1-23.4 52.7-52.4 52.8s-52.6-23.4-52.8-52.4c-0.1-29.2 23.5-53 52.6-53 29 0 52.5 23.5 52.6 52.6z" />
                                                </svg>
                                            </span>
                                            <span className="title parent-menu-css">
                                                Administrator <i className="fa fa-angle-right custom-arrow" aria-hidden="true"></i>{' '}
                                            </span>
                                        </a>
                                        {moduleData.userAccess !== undefined &&
                                            moduleData.userAccess.length > 0 &&
                                            moduleData.userAccess.map((userAccess: any, index: any) => {
                                                return (
                                                    <ul className={`dropdown-menu child-submenu ${isIntegrationActive ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1" onClick={eventHandler}>
                                                        {userAccess.accessCode === ModulesAccessRights.Administrator.viewAccess.accessCode && (
                                                            <NavLink
                                                                to={ROUTES_DATA.INTEGRATION}
                                                                className={({ isActive }) => (isActive ? 'dropdown-item child-menu-effect active' : 'dropdown-item child-menu-effect')}
                                                            >
                                                                <span className="icon">
                                                                    <svg
                                                                        version="1.1"
                                                                        id="Layer_1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                        x="0px"
                                                                        y="0px"
                                                                        width="19"
                                                                        viewBox="0 0 325.1 365.9"
                                                                        xmlSpace="preserve"
                                                                    >
                                                                        <style>{`"enable-background="new 0 0 325.1 365.9"`} </style>
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m284.5 128c13.1-0.1 23.2 10.2 23.2 23.6 0.1 25.2 0 50.4 0 75.6v51.2h-290.2c-0.1-0.9-0.2-1.8-0.2-2.8v-123.9c0-13.5 9.5-23.4 23.2-23.8v4 29.6c0 11.3 7.2 18.5 18.5 18.6h10.3c0.2 1.4 0.4 2.5 0.6 3.9 5 0.7 9.9 1.2 14.8 2.1 1.2 0.2 2.5 1.5 3.2 2.7 0.9 1.3 0.9 3.2 1.7 4.5 3.2 4.8 2 8.6-1.9 12.4-2.3 2.2-4 5-5.8 7.4 10.2 10.1 20.2 20.1 30.3 30.1 3.2-2.5 6.7-5 10.1-7.8 1.5-1.3 2.7-1.6 4.6-0.6 3.2 1.7 6.7 2.8 9.9 4.4 1.1 0.5 2.2 1.9 2.4 3 0.8 4.3 1.3 8.6 1.9 13.2h42.5c0.6-4.2 1.3-8.5 1.7-12.8 0.2-2.3 1.2-3.1 3.2-3.8 3.2-1.1 6.3-2.5 9.2-4 1.8-0.9 3.1-0.8 4.7 0.5 3.3 2.8 6.9 5.4 10 7.8 10.1-10.1 20.1-20 30.3-30.3-2.7-3.4-5.8-7.1-8.5-11.1-0.8-1.1-0.8-3.1-0.6-4.6 0.2-1.6 1.6-3 1.7-4.5 0.7-6.1 4.6-7.3 9.8-7.5 3.2-0.1 6.4-0.8 9.8-1.2 0.2-1.3 0.4-2.5 0.7-4h10.9c10.5-0.2 17.8-7.5 17.9-18 0.1-10 0-20 0-29.9 0.1-1.3 0.1-2.6 0.1-4z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m81.4 173.7v-22c4.6-0.6 9.1-1 13.6-1.8 1.2-0.2 2.7-1.2 3.2-2.2 3.1-6.9 5.9-13.9 9.1-21.5-2.9-3.7-6.4-8.3-9.7-12.5l15.8-15.8c3.6 2.8 7.6 5.8 11.4 8.9 1.8 1.5 3.1 1.6 5.2 0.5 5.1-2.5 10.4-4.7 15.9-6.4 2.5-0.8 3.4-1.9 3.6-4.3 0.5-5 1.2-9.9 1.8-15h22.1c0.6 5 1.3 10 1.8 15.1 0.2 2.4 1.2 3.4 3.7 4.2 5.4 1.7 10.7 4 15.9 6.5 2.1 1 3.5 1 5.2-0.5 3.8-3.2 7.8-6.1 11.5-9 5.3 5.3 10.4 10.4 15.7 15.8-3.2 4.2-6.8 8.8-9.7 12.6 3.2 7.6 6 14.6 9.1 21.5 0.5 1 2.1 2 3.3 2.2 4.4 0.7 8.9 1.2 13.6 1.7v22.2c-4.5 0.6-8.8 1-13.1 1.8-1 0.2-2.4 1.2-2.7 2.2-2.8 7.7-5.3 15.4-8.2 24 2.2 2.8 5.1 6.6 7.9 10.2l-15.7 15.7c-3.3-2.6-7-5.4-9.6-7.4-9 3.5-17.2 6.6-25.3 9.9-0.8 0.3-1.6 1.5-1.7 2.3-0.6 3.6-1.1 7.1-1.6 11.1h-22.1c-0.5-3.6-0.9-7.3-1.6-11-0.2-0.9-1.1-2.1-2-2.5-8-3.3-16.1-6.4-25-9.9-2.6 2-6.2 4.8-9.7 7.5-5.3-5.3-10.3-10.4-15.6-15.7 2.8-3.6 5.7-7.4 7.9-10.3-2.9-8.6-5.5-16.5-8.2-24.3-0.3-0.9-1.6-1.7-2.6-1.9-4.3-0.8-8.6-1.3-13.2-1.9zm81 25.9c19.1 0 34.5-15.3 34.5-34.4 0-19-15.4-34.6-34.4-34.6s-34.5 15.4-34.5 34.4c0.1 19.2 15.3 34.5 34.4 34.6z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m308.1 290.7c-0.5 6.1-0.2 12.2-1.5 17.9-2.3 10-11.6 16.6-22.2 16.6-25.2 0.1-50.4 0-75.6 0h-165.5c-16.6 0-26-9.3-26-25.9v-8.7c97 0.1 193.5 0.1 290.8 0.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m110.3 354.4v-17.2h104.5v17.2h20.5c1.2 0 2.5-0.1 3.7 0.1 3.1 0.7 4.8 2.8 4.6 5.9-0.1 2.9-1.8 4.9-4.8 5.3-1.2 0.2-2.5 0.1-3.7 0.1h-145.2-0.4c-5.7 0-8.3-1.8-8.3-5.7s2.7-5.8 8.3-5.8c6.8 0.1 13.5 0.1 20.8 0.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m272.8 98.8v6.6 55c0 6.3-1.7 8-8 8.1h-9.2v-11.5c1.8-0.1 3.5-0.2 5.6-0.3v-4.1-57.6c0-6 1.8-7.7 7.8-7.7 6.4 0 12.7-0.1 19.1 0 2.1 0 3.3-0.5 4.3-2.5 3.9-7.3 11.9-10.7 19.7-8.6 7.5 2 12.8 9 12.8 16.9 0 8.1-5.3 14.8-13.2 16.9-7.6 1.9-15.4-1.5-19.2-8.7-1.1-2-2.3-2.5-4.3-2.4-4.9 0-9.9-0.1-15.4-0.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m63.8 156.6c2.2 0.1 3.8 0.2 5.6 0.3v11.4c-4 0-8 0.2-12-0.1-3.6-0.3-5.1-2.4-5.1-6.5-0.1-11.8 0-23.7 0-35.5v-27.5c-6.1 0-11.9-0.1-17.7 0.1-0.8 0-1.7 1.9-2.3 3-4 6.6-11.6 9.9-18.9 8.1-7.6-1.9-13-8.3-13.4-15.7-0.4-8.2 4.1-15.1 11.7-17.7 8-2.8 16.5 0.4 20.8 8.1 1.1 1.9 2.2 2.6 4.3 2.5 6.6-0.1 13.2-0.1 19.8 0 5.3 0 7.2 1.9 7.2 7.2v58 4.3z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m68.6 40.7h28.4c5.3 0 7.3 1.9 7.3 7.2 0 13 0.1 25.9-0.1 38.9 0 1.6-0.9 3.5-2 4.8-2.6 3.1-5.6 5.9-9.2 9.6v-48.5c-1.3-0.2-2.2-0.4-3.1-0.4-4 0-8 0.1-12-0.1-5.3-0.3-9.5-0.1-12.9 5.5-3.9 6.4-12.4 7.8-19.4 4.9-6.9-2.9-11.4-10-10.8-17.2 0.7-7.7 6.1-14.2 13.3-15.8 7.6-1.7 15 1.4 18.9 8 0.6 0.9 1 2 1.6 3.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m232.3 52.3v48.7c-4-4-7.6-7.4-10.9-11-0.6-0.7-0.5-2.1-0.5-3.2v-38.9c0-5.2 2-7.1 7.3-7.1 8.2 0 16.5-0.1 24.7 0 2.4 0 3.9-0.4 5.2-2.9 4.1-7.6 13.3-10.7 21.3-7.5 7.9 3.1 12.3 11.5 10.5 20.1-1.7 8.1-9.3 14-17.8 13.5-6.5-0.4-11.4-3.5-14.4-9.3-1-1.8-2-2.4-4-2.4-7 0.1-14 0-21.4 0z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m168.4 69.6h-11.7v-16.5c0-5.4-0.1-10.7 0.1-16.1 0.1-2.3-0.6-3.4-2.7-4.5-6.6-3.7-9.6-9.6-8.7-17.2 0.8-6.9 4.8-11.7 11.2-14.2 8.4-3.2 17.9 0.8 21.7 8.9 3.8 8.2 0.9 17.9-7 22.3-2.4 1.3-2.9 2.8-2.9 5.2 0.1 10.6 0 21.2 0 32.1z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="title">Integration Interface</span>
                                                            </NavLink>
                                                        )}
                                                        {userAccess.accessCode === ModulesAccessRights.Administrator.userManagementAccess.accessCode && (
                                                            <NavLink to={ROUTES_DATA.HELP} className={'dropdown-item child-menu-effect '}>
                                                                <span className="icon">
                                                                    <svg
                                                                        version="1.1"
                                                                        id="Layer_1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                        x="0px"
                                                                        y="0px"
                                                                        width="19"
                                                                        viewBox="0 0 325.1 365.9"
                                                                        xmlSpace="preserve"
                                                                    >
                                                                        <style>{`"enable-background="new 0 0 325.1 365.9"`} </style>
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m284.5 128c13.1-0.1 23.2 10.2 23.2 23.6 0.1 25.2 0 50.4 0 75.6v51.2h-290.2c-0.1-0.9-0.2-1.8-0.2-2.8v-123.9c0-13.5 9.5-23.4 23.2-23.8v4 29.6c0 11.3 7.2 18.5 18.5 18.6h10.3c0.2 1.4 0.4 2.5 0.6 3.9 5 0.7 9.9 1.2 14.8 2.1 1.2 0.2 2.5 1.5 3.2 2.7 0.9 1.3 0.9 3.2 1.7 4.5 3.2 4.8 2 8.6-1.9 12.4-2.3 2.2-4 5-5.8 7.4 10.2 10.1 20.2 20.1 30.3 30.1 3.2-2.5 6.7-5 10.1-7.8 1.5-1.3 2.7-1.6 4.6-0.6 3.2 1.7 6.7 2.8 9.9 4.4 1.1 0.5 2.2 1.9 2.4 3 0.8 4.3 1.3 8.6 1.9 13.2h42.5c0.6-4.2 1.3-8.5 1.7-12.8 0.2-2.3 1.2-3.1 3.2-3.8 3.2-1.1 6.3-2.5 9.2-4 1.8-0.9 3.1-0.8 4.7 0.5 3.3 2.8 6.9 5.4 10 7.8 10.1-10.1 20.1-20 30.3-30.3-2.7-3.4-5.8-7.1-8.5-11.1-0.8-1.1-0.8-3.1-0.6-4.6 0.2-1.6 1.6-3 1.7-4.5 0.7-6.1 4.6-7.3 9.8-7.5 3.2-0.1 6.4-0.8 9.8-1.2 0.2-1.3 0.4-2.5 0.7-4h10.9c10.5-0.2 17.8-7.5 17.9-18 0.1-10 0-20 0-29.9 0.1-1.3 0.1-2.6 0.1-4z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m81.4 173.7v-22c4.6-0.6 9.1-1 13.6-1.8 1.2-0.2 2.7-1.2 3.2-2.2 3.1-6.9 5.9-13.9 9.1-21.5-2.9-3.7-6.4-8.3-9.7-12.5l15.8-15.8c3.6 2.8 7.6 5.8 11.4 8.9 1.8 1.5 3.1 1.6 5.2 0.5 5.1-2.5 10.4-4.7 15.9-6.4 2.5-0.8 3.4-1.9 3.6-4.3 0.5-5 1.2-9.9 1.8-15h22.1c0.6 5 1.3 10 1.8 15.1 0.2 2.4 1.2 3.4 3.7 4.2 5.4 1.7 10.7 4 15.9 6.5 2.1 1 3.5 1 5.2-0.5 3.8-3.2 7.8-6.1 11.5-9 5.3 5.3 10.4 10.4 15.7 15.8-3.2 4.2-6.8 8.8-9.7 12.6 3.2 7.6 6 14.6 9.1 21.5 0.5 1 2.1 2 3.3 2.2 4.4 0.7 8.9 1.2 13.6 1.7v22.2c-4.5 0.6-8.8 1-13.1 1.8-1 0.2-2.4 1.2-2.7 2.2-2.8 7.7-5.3 15.4-8.2 24 2.2 2.8 5.1 6.6 7.9 10.2l-15.7 15.7c-3.3-2.6-7-5.4-9.6-7.4-9 3.5-17.2 6.6-25.3 9.9-0.8 0.3-1.6 1.5-1.7 2.3-0.6 3.6-1.1 7.1-1.6 11.1h-22.1c-0.5-3.6-0.9-7.3-1.6-11-0.2-0.9-1.1-2.1-2-2.5-8-3.3-16.1-6.4-25-9.9-2.6 2-6.2 4.8-9.7 7.5-5.3-5.3-10.3-10.4-15.6-15.7 2.8-3.6 5.7-7.4 7.9-10.3-2.9-8.6-5.5-16.5-8.2-24.3-0.3-0.9-1.6-1.7-2.6-1.9-4.3-0.8-8.6-1.3-13.2-1.9zm81 25.9c19.1 0 34.5-15.3 34.5-34.4 0-19-15.4-34.6-34.4-34.6s-34.5 15.4-34.5 34.4c0.1 19.2 15.3 34.5 34.4 34.6z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m308.1 290.7c-0.5 6.1-0.2 12.2-1.5 17.9-2.3 10-11.6 16.6-22.2 16.6-25.2 0.1-50.4 0-75.6 0h-165.5c-16.6 0-26-9.3-26-25.9v-8.7c97 0.1 193.5 0.1 290.8 0.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m110.3 354.4v-17.2h104.5v17.2h20.5c1.2 0 2.5-0.1 3.7 0.1 3.1 0.7 4.8 2.8 4.6 5.9-0.1 2.9-1.8 4.9-4.8 5.3-1.2 0.2-2.5 0.1-3.7 0.1h-145.2-0.4c-5.7 0-8.3-1.8-8.3-5.7s2.7-5.8 8.3-5.8c6.8 0.1 13.5 0.1 20.8 0.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m272.8 98.8v6.6 55c0 6.3-1.7 8-8 8.1h-9.2v-11.5c1.8-0.1 3.5-0.2 5.6-0.3v-4.1-57.6c0-6 1.8-7.7 7.8-7.7 6.4 0 12.7-0.1 19.1 0 2.1 0 3.3-0.5 4.3-2.5 3.9-7.3 11.9-10.7 19.7-8.6 7.5 2 12.8 9 12.8 16.9 0 8.1-5.3 14.8-13.2 16.9-7.6 1.9-15.4-1.5-19.2-8.7-1.1-2-2.3-2.5-4.3-2.4-4.9 0-9.9-0.1-15.4-0.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m63.8 156.6c2.2 0.1 3.8 0.2 5.6 0.3v11.4c-4 0-8 0.2-12-0.1-3.6-0.3-5.1-2.4-5.1-6.5-0.1-11.8 0-23.7 0-35.5v-27.5c-6.1 0-11.9-0.1-17.7 0.1-0.8 0-1.7 1.9-2.3 3-4 6.6-11.6 9.9-18.9 8.1-7.6-1.9-13-8.3-13.4-15.7-0.4-8.2 4.1-15.1 11.7-17.7 8-2.8 16.5 0.4 20.8 8.1 1.1 1.9 2.2 2.6 4.3 2.5 6.6-0.1 13.2-0.1 19.8 0 5.3 0 7.2 1.9 7.2 7.2v58 4.3z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m68.6 40.7h28.4c5.3 0 7.3 1.9 7.3 7.2 0 13 0.1 25.9-0.1 38.9 0 1.6-0.9 3.5-2 4.8-2.6 3.1-5.6 5.9-9.2 9.6v-48.5c-1.3-0.2-2.2-0.4-3.1-0.4-4 0-8 0.1-12-0.1-5.3-0.3-9.5-0.1-12.9 5.5-3.9 6.4-12.4 7.8-19.4 4.9-6.9-2.9-11.4-10-10.8-17.2 0.7-7.7 6.1-14.2 13.3-15.8 7.6-1.7 15 1.4 18.9 8 0.6 0.9 1 2 1.6 3.1z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m232.3 52.3v48.7c-4-4-7.6-7.4-10.9-11-0.6-0.7-0.5-2.1-0.5-3.2v-38.9c0-5.2 2-7.1 7.3-7.1 8.2 0 16.5-0.1 24.7 0 2.4 0 3.9-0.4 5.2-2.9 4.1-7.6 13.3-10.7 21.3-7.5 7.9 3.1 12.3 11.5 10.5 20.1-1.7 8.1-9.3 14-17.8 13.5-6.5-0.4-11.4-3.5-14.4-9.3-1-1.8-2-2.4-4-2.4-7 0.1-14 0-21.4 0z"
                                                                        />
                                                                        <path
                                                                            fill="#4B4B4B"
                                                                            d="m168.4 69.6h-11.7v-16.5c0-5.4-0.1-10.7 0.1-16.1 0.1-2.3-0.6-3.4-2.7-4.5-6.6-3.7-9.6-9.6-8.7-17.2 0.8-6.9 4.8-11.7 11.2-14.2 8.4-3.2 17.9 0.8 21.7 8.9 3.8 8.2 0.9 17.9-7 22.3-2.4 1.3-2.9 2.8-2.9 5.2 0.1 10.6 0 21.2 0 32.1z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="title">User Management</span>
                                                            </NavLink>
                                                        )}
                                                    </ul>
                                                );
                                            })}
                                    </li>
                                );
                            }*/}
                            </li>
                                );
                            }

                            if (
                                DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.NHS) !== true &&
                                DataRoleSelected?.roleId === Roles.ServiceManager &&
                                moduleData.module === Modules.ViewMap
                            ) {
                                return (
                                    <li key={index}>
                                        <NavLink to={ROUTES_DATA.SERVICEMANAGERVIEWMAP} className={({ isActive }) => (isActive ? 'active' : '')}>
                                        <span className="icon">
                                            <svg width="22" height="22" viewBox="0 0 30 30">
                                                    <g clip-path="url(#clip0_2668_10289)">
                                                        <path
                                                            d="M15.6449 0C16.1781 0.108398 16.7247 0.17168 17.2415 0.332227C20.2954 1.28086 22.0638 3.39609 22.7312 6.47988C23.1894 8.59512 22.8531 10.6242 22.0515 12.6059C21.1755 14.7703 20.0101 16.7748 18.663 18.6703C17.7425 19.9658 16.7517 21.2115 15.7703 22.4625C15.298 23.0643 14.7179 23.0514 14.2269 22.4625C11.9945 19.7883 9.98002 16.967 8.47475 13.8094C7.83021 12.4576 7.26244 11.0648 7.13353 9.56309C6.85873 6.35684 7.64975 3.53379 10.3263 1.48887C11.4191 0.654492 12.6824 0.208594 14.0476 0.0539062C14.1126 0.0462891 14.1747 0.0181641 14.2386 0C14.7074 0 15.1761 0 15.6449 0ZM15.0103 10.5639C16.4757 10.5574 17.6464 9.38027 17.6417 7.91777C17.637 6.4623 16.4435 5.27695 14.9904 5.28398C13.5331 5.2916 12.3542 6.48047 12.3583 7.93711C12.363 9.39609 13.5478 10.5697 15.0103 10.5633V10.5639Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M0 28.8865C0.155859 28.5431 0.302344 28.195 0.47168 27.8581C0.52793 27.7456 0.62168 27.6255 0.730078 27.5704C3.18984 26.3054 5.6543 25.0491 8.11641 23.7888C8.23945 23.7255 8.32617 23.6974 8.4457 23.8175C10.374 25.7511 12.3082 27.6794 14.24 29.6095C14.2664 29.6359 14.2857 29.6687 14.3174 29.7103C14.1674 29.8116 14.0273 29.906 13.8873 30.0003H0.644531C0.429492 29.7853 0.215039 29.5708 0 29.3558C0 29.1993 0 29.0435 0 28.887V28.8865Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M17.6953 30.0004C18.3545 29.6488 19.0102 29.2908 19.6729 28.9463C22.3857 27.5371 25.1004 26.1314 27.815 24.7252C27.9082 24.6771 28.0037 24.6326 28.1156 24.5781C28.1625 24.6789 28.2053 24.7645 28.2434 24.8523C28.7965 26.1402 29.3484 27.4281 29.9021 28.716C29.9279 28.7764 29.9672 28.8303 30 28.8877V29.3564C29.8588 29.6447 29.6455 29.8615 29.3555 30.001H17.6953V30.0004Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M27.4027 22.9486C26.3826 23.4782 25.3631 24.0085 24.3424 24.537C21.6506 25.9321 18.957 27.3232 16.2699 28.7271C16.0561 28.839 15.9424 28.8196 15.7754 28.6515C13.9297 26.797 12.0758 24.9507 10.2219 23.105C10.1469 23.0306 10.0461 22.9814 9.89844 22.88C10.5119 22.5653 11.0264 22.3017 11.5461 22.0351C11.9896 22.5765 12.4186 23.1073 12.8551 23.6312C14.0645 25.0825 15.9301 25.0802 17.143 23.6353C19.1176 21.2821 20.8883 18.789 22.3812 16.1007C22.4305 16.0122 22.5646 15.9232 22.6637 15.9179C23.0533 15.8985 23.4441 15.9073 23.8344 15.9155C24.2135 15.9232 24.4877 16.1101 24.6359 16.454C25.5541 18.5839 26.467 20.7167 27.3805 22.8484C27.391 22.8735 27.3934 22.9028 27.4033 22.948L27.4027 22.9486Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M10.483 20.6003C7.54336 22.1014 4.6377 23.5856 1.66406 25.1044C1.71738 24.959 1.74434 24.8729 1.77949 24.7903C2.95137 22.0604 4.1291 19.3323 5.29277 16.5983C5.5002 16.1108 5.83125 15.8846 6.35508 15.9069C6.73477 15.9227 7.18594 15.7956 7.4748 15.9608C7.76074 16.1243 7.87324 16.5801 8.07715 16.8977C8.8084 18.038 9.54609 19.1741 10.2826 20.3114C10.3406 20.401 10.4045 20.4866 10.4836 20.5997L10.483 20.6003Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M15.0119 8.80177C15.4964 8.79649 15.8826 8.40626 15.8796 7.92462C15.8773 7.45001 15.4771 7.04923 15.0037 7.04688C14.5203 7.04454 14.1124 7.45997 14.1212 7.94747C14.1294 8.42677 14.5261 8.80763 15.0119 8.80235V8.80177Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M15.0118 8.80235C14.5261 8.80763 14.1294 8.42677 14.1212 7.94747C14.113 7.45997 14.5202 7.04454 15.0036 7.04688C15.4771 7.04923 15.8773 7.4506 15.8796 7.92462C15.882 8.40685 15.4958 8.79708 15.0118 8.80177V8.80235Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2668_10289">
                                                            <rect width="30" height="30" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span className="title">View Map</span>
                                        </NavLink>
                                    </li>
                                );
                            }
                            if (
                                DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.NHS) !== true &&
                                DataRoleSelected?.roleId === Roles.ReferralManager &&
                                moduleData.module === Modules.ViewMap
                            ) {
                                return (
                                    <li key={index}>
                                        <NavLink to={ROUTES_DATA.REFERRALMANAGERVIEWMAP} className={({ isActive }) => (isActive ? 'active' : '')}>
                                        <span className="icon">
                                            <svg width="22" height="22" viewBox="0 0 30 30">
                                                    <g clip-path="url(#clip0_2668_10289)">
                                                        <path
                                                            d="M15.6449 0C16.1781 0.108398 16.7247 0.17168 17.2415 0.332227C20.2954 1.28086 22.0638 3.39609 22.7312 6.47988C23.1894 8.59512 22.8531 10.6242 22.0515 12.6059C21.1755 14.7703 20.0101 16.7748 18.663 18.6703C17.7425 19.9658 16.7517 21.2115 15.7703 22.4625C15.298 23.0643 14.7179 23.0514 14.2269 22.4625C11.9945 19.7883 9.98002 16.967 8.47475 13.8094C7.83021 12.4576 7.26244 11.0648 7.13353 9.56309C6.85873 6.35684 7.64975 3.53379 10.3263 1.48887C11.4191 0.654492 12.6824 0.208594 14.0476 0.0539062C14.1126 0.0462891 14.1747 0.0181641 14.2386 0C14.7074 0 15.1761 0 15.6449 0ZM15.0103 10.5639C16.4757 10.5574 17.6464 9.38027 17.6417 7.91777C17.637 6.4623 16.4435 5.27695 14.9904 5.28398C13.5331 5.2916 12.3542 6.48047 12.3583 7.93711C12.363 9.39609 13.5478 10.5697 15.0103 10.5633V10.5639Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M0 28.8865C0.155859 28.5431 0.302344 28.195 0.47168 27.8581C0.52793 27.7456 0.62168 27.6255 0.730078 27.5704C3.18984 26.3054 5.6543 25.0491 8.11641 23.7888C8.23945 23.7255 8.32617 23.6974 8.4457 23.8175C10.374 25.7511 12.3082 27.6794 14.24 29.6095C14.2664 29.6359 14.2857 29.6687 14.3174 29.7103C14.1674 29.8116 14.0273 29.906 13.8873 30.0003H0.644531C0.429492 29.7853 0.215039 29.5708 0 29.3558C0 29.1993 0 29.0435 0 28.887V28.8865Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M17.6953 30.0004C18.3545 29.6488 19.0102 29.2908 19.6729 28.9463C22.3857 27.5371 25.1004 26.1314 27.815 24.7252C27.9082 24.6771 28.0037 24.6326 28.1156 24.5781C28.1625 24.6789 28.2053 24.7645 28.2434 24.8523C28.7965 26.1402 29.3484 27.4281 29.9021 28.716C29.9279 28.7764 29.9672 28.8303 30 28.8877V29.3564C29.8588 29.6447 29.6455 29.8615 29.3555 30.001H17.6953V30.0004Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M27.4027 22.9486C26.3826 23.4782 25.3631 24.0085 24.3424 24.537C21.6506 25.9321 18.957 27.3232 16.2699 28.7271C16.0561 28.839 15.9424 28.8196 15.7754 28.6515C13.9297 26.797 12.0758 24.9507 10.2219 23.105C10.1469 23.0306 10.0461 22.9814 9.89844 22.88C10.5119 22.5653 11.0264 22.3017 11.5461 22.0351C11.9896 22.5765 12.4186 23.1073 12.8551 23.6312C14.0645 25.0825 15.9301 25.0802 17.143 23.6353C19.1176 21.2821 20.8883 18.789 22.3812 16.1007C22.4305 16.0122 22.5646 15.9232 22.6637 15.9179C23.0533 15.8985 23.4441 15.9073 23.8344 15.9155C24.2135 15.9232 24.4877 16.1101 24.6359 16.454C25.5541 18.5839 26.467 20.7167 27.3805 22.8484C27.391 22.8735 27.3934 22.9028 27.4033 22.948L27.4027 22.9486Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M10.483 20.6003C7.54336 22.1014 4.6377 23.5856 1.66406 25.1044C1.71738 24.959 1.74434 24.8729 1.77949 24.7903C2.95137 22.0604 4.1291 19.3323 5.29277 16.5983C5.5002 16.1108 5.83125 15.8846 6.35508 15.9069C6.73477 15.9227 7.18594 15.7956 7.4748 15.9608C7.76074 16.1243 7.87324 16.5801 8.07715 16.8977C8.8084 18.038 9.54609 19.1741 10.2826 20.3114C10.3406 20.401 10.4045 20.4866 10.4836 20.5997L10.483 20.6003Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M15.0119 8.80177C15.4964 8.79649 15.8826 8.40626 15.8796 7.92462C15.8773 7.45001 15.4771 7.04923 15.0037 7.04688C14.5203 7.04454 14.1124 7.45997 14.1212 7.94747C14.1294 8.42677 14.5261 8.80763 15.0119 8.80235V8.80177Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M15.0118 8.80235C14.5261 8.80763 14.1294 8.42677 14.1212 7.94747C14.113 7.45997 14.5202 7.04454 15.0036 7.04688C15.4771 7.04923 15.8773 7.4506 15.8796 7.92462C15.882 8.40685 15.4958 8.79708 15.0118 8.80177V8.80235Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2668_10289">
                                                            <rect width="30" height="30" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span className="title">View Map</span>
                                        </NavLink>
                                    </li>
                                );
                            }
                        })}
                        {DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.NHS) === true && DataRoleSelected?.roleId === Roles.ServiceManager && (
                        <li key={999}>
                            <NavLink to="#" onClick={() => changeService(Roles.NHS)} className={({ isActive }) => (isActive ? '' : '')}>
                                <span className="icon">
                                    <svg width="22" height="22" viewBox="0 0 30 30">
                                        <g clip-path="url(#clip0_2668_10289)">
                                            <path
                                                d="M15.6449 0C16.1781 0.108398 16.7247 0.17168 17.2415 0.332227C20.2954 1.28086 22.0638 3.39609 22.7312 6.47988C23.1894 8.59512 22.8531 10.6242 22.0515 12.6059C21.1755 14.7703 20.0101 16.7748 18.663 18.6703C17.7425 19.9658 16.7517 21.2115 15.7703 22.4625C15.298 23.0643 14.7179 23.0514 14.2269 22.4625C11.9945 19.7883 9.98002 16.967 8.47475 13.8094C7.83021 12.4576 7.26244 11.0648 7.13353 9.56309C6.85873 6.35684 7.64975 3.53379 10.3263 1.48887C11.4191 0.654492 12.6824 0.208594 14.0476 0.0539062C14.1126 0.0462891 14.1747 0.0181641 14.2386 0C14.7074 0 15.1761 0 15.6449 0ZM15.0103 10.5639C16.4757 10.5574 17.6464 9.38027 17.6417 7.91777C17.637 6.4623 16.4435 5.27695 14.9904 5.28398C13.5331 5.2916 12.3542 6.48047 12.3583 7.93711C12.363 9.39609 13.5478 10.5697 15.0103 10.5633V10.5639Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M0 28.8865C0.155859 28.5431 0.302344 28.195 0.47168 27.8581C0.52793 27.7456 0.62168 27.6255 0.730078 27.5704C3.18984 26.3054 5.6543 25.0491 8.11641 23.7888C8.23945 23.7255 8.32617 23.6974 8.4457 23.8175C10.374 25.7511 12.3082 27.6794 14.24 29.6095C14.2664 29.6359 14.2857 29.6687 14.3174 29.7103C14.1674 29.8116 14.0273 29.906 13.8873 30.0003H0.644531C0.429492 29.7853 0.215039 29.5708 0 29.3558C0 29.1993 0 29.0435 0 28.887V28.8865Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M17.6953 30.0004C18.3545 29.6488 19.0102 29.2908 19.6729 28.9463C22.3857 27.5371 25.1004 26.1314 27.815 24.7252C27.9082 24.6771 28.0037 24.6326 28.1156 24.5781C28.1625 24.6789 28.2053 24.7645 28.2434 24.8523C28.7965 26.1402 29.3484 27.4281 29.9021 28.716C29.9279 28.7764 29.9672 28.8303 30 28.8877V29.3564C29.8588 29.6447 29.6455 29.8615 29.3555 30.001H17.6953V30.0004Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M27.4027 22.9486C26.3826 23.4782 25.3631 24.0085 24.3424 24.537C21.6506 25.9321 18.957 27.3232 16.2699 28.7271C16.0561 28.839 15.9424 28.8196 15.7754 28.6515C13.9297 26.797 12.0758 24.9507 10.2219 23.105C10.1469 23.0306 10.0461 22.9814 9.89844 22.88C10.5119 22.5653 11.0264 22.3017 11.5461 22.0351C11.9896 22.5765 12.4186 23.1073 12.8551 23.6312C14.0645 25.0825 15.9301 25.0802 17.143 23.6353C19.1176 21.2821 20.8883 18.789 22.3812 16.1007C22.4305 16.0122 22.5646 15.9232 22.6637 15.9179C23.0533 15.8985 23.4441 15.9073 23.8344 15.9155C24.2135 15.9232 24.4877 16.1101 24.6359 16.454C25.5541 18.5839 26.467 20.7167 27.3805 22.8484C27.391 22.8735 27.3934 22.9028 27.4033 22.948L27.4027 22.9486Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M10.483 20.6003C7.54336 22.1014 4.6377 23.5856 1.66406 25.1044C1.71738 24.959 1.74434 24.8729 1.77949 24.7903C2.95137 22.0604 4.1291 19.3323 5.29277 16.5983C5.5002 16.1108 5.83125 15.8846 6.35508 15.9069C6.73477 15.9227 7.18594 15.7956 7.4748 15.9608C7.76074 16.1243 7.87324 16.5801 8.07715 16.8977C8.8084 18.038 9.54609 19.1741 10.2826 20.3114C10.3406 20.401 10.4045 20.4866 10.4836 20.5997L10.483 20.6003Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.0119 8.80177C15.4964 8.79649 15.8826 8.40626 15.8796 7.92462C15.8773 7.45001 15.4771 7.04923 15.0037 7.04688C14.5203 7.04454 14.1124 7.45997 14.1212 7.94747C14.1294 8.42677 14.5261 8.80763 15.0119 8.80235V8.80177Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.0118 8.80235C14.5261 8.80763 14.1294 8.42677 14.1212 7.94747C14.113 7.45997 14.5202 7.04454 15.0036 7.04688C15.4771 7.04923 15.8773 7.4506 15.8796 7.92462C15.882 8.40685 15.4958 8.79708 15.0118 8.80177V8.80235Z"
                                                fill="white"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2668_10289">
                                                <rect width="30" height="30" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="title">View Map</span>
                            </NavLink>
                        </li>
                    )}
                    {DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.NHS) === true && DataRoleSelected?.roleId === Roles.ReferralManager && (
                        <li key={999}>
                            <NavLink to="#" onClick={() => changeService(Roles.NHS)} className={({ isActive }) => (isActive ? '' : '')}>
                                <span className="icon">
                                    <svg width="22" height="22" viewBox="0 0 30 30">
                                        <g clip-path="url(#clip0_2668_10289)">
                                            <path
                                                d="M15.6449 0C16.1781 0.108398 16.7247 0.17168 17.2415 0.332227C20.2954 1.28086 22.0638 3.39609 22.7312 6.47988C23.1894 8.59512 22.8531 10.6242 22.0515 12.6059C21.1755 14.7703 20.0101 16.7748 18.663 18.6703C17.7425 19.9658 16.7517 21.2115 15.7703 22.4625C15.298 23.0643 14.7179 23.0514 14.2269 22.4625C11.9945 19.7883 9.98002 16.967 8.47475 13.8094C7.83021 12.4576 7.26244 11.0648 7.13353 9.56309C6.85873 6.35684 7.64975 3.53379 10.3263 1.48887C11.4191 0.654492 12.6824 0.208594 14.0476 0.0539062C14.1126 0.0462891 14.1747 0.0181641 14.2386 0C14.7074 0 15.1761 0 15.6449 0ZM15.0103 10.5639C16.4757 10.5574 17.6464 9.38027 17.6417 7.91777C17.637 6.4623 16.4435 5.27695 14.9904 5.28398C13.5331 5.2916 12.3542 6.48047 12.3583 7.93711C12.363 9.39609 13.5478 10.5697 15.0103 10.5633V10.5639Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M0 28.8865C0.155859 28.5431 0.302344 28.195 0.47168 27.8581C0.52793 27.7456 0.62168 27.6255 0.730078 27.5704C3.18984 26.3054 5.6543 25.0491 8.11641 23.7888C8.23945 23.7255 8.32617 23.6974 8.4457 23.8175C10.374 25.7511 12.3082 27.6794 14.24 29.6095C14.2664 29.6359 14.2857 29.6687 14.3174 29.7103C14.1674 29.8116 14.0273 29.906 13.8873 30.0003H0.644531C0.429492 29.7853 0.215039 29.5708 0 29.3558C0 29.1993 0 29.0435 0 28.887V28.8865Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M17.6953 30.0004C18.3545 29.6488 19.0102 29.2908 19.6729 28.9463C22.3857 27.5371 25.1004 26.1314 27.815 24.7252C27.9082 24.6771 28.0037 24.6326 28.1156 24.5781C28.1625 24.6789 28.2053 24.7645 28.2434 24.8523C28.7965 26.1402 29.3484 27.4281 29.9021 28.716C29.9279 28.7764 29.9672 28.8303 30 28.8877V29.3564C29.8588 29.6447 29.6455 29.8615 29.3555 30.001H17.6953V30.0004Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M27.4027 22.9486C26.3826 23.4782 25.3631 24.0085 24.3424 24.537C21.6506 25.9321 18.957 27.3232 16.2699 28.7271C16.0561 28.839 15.9424 28.8196 15.7754 28.6515C13.9297 26.797 12.0758 24.9507 10.2219 23.105C10.1469 23.0306 10.0461 22.9814 9.89844 22.88C10.5119 22.5653 11.0264 22.3017 11.5461 22.0351C11.9896 22.5765 12.4186 23.1073 12.8551 23.6312C14.0645 25.0825 15.9301 25.0802 17.143 23.6353C19.1176 21.2821 20.8883 18.789 22.3812 16.1007C22.4305 16.0122 22.5646 15.9232 22.6637 15.9179C23.0533 15.8985 23.4441 15.9073 23.8344 15.9155C24.2135 15.9232 24.4877 16.1101 24.6359 16.454C25.5541 18.5839 26.467 20.7167 27.3805 22.8484C27.391 22.8735 27.3934 22.9028 27.4033 22.948L27.4027 22.9486Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M10.483 20.6003C7.54336 22.1014 4.6377 23.5856 1.66406 25.1044C1.71738 24.959 1.74434 24.8729 1.77949 24.7903C2.95137 22.0604 4.1291 19.3323 5.29277 16.5983C5.5002 16.1108 5.83125 15.8846 6.35508 15.9069C6.73477 15.9227 7.18594 15.7956 7.4748 15.9608C7.76074 16.1243 7.87324 16.5801 8.07715 16.8977C8.8084 18.038 9.54609 19.1741 10.2826 20.3114C10.3406 20.401 10.4045 20.4866 10.4836 20.5997L10.483 20.6003Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.0119 8.80177C15.4964 8.79649 15.8826 8.40626 15.8796 7.92462C15.8773 7.45001 15.4771 7.04923 15.0037 7.04688C14.5203 7.04454 14.1124 7.45997 14.1212 7.94747C14.1294 8.42677 14.5261 8.80763 15.0119 8.80235V8.80177Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.0118 8.80235C14.5261 8.80763 14.1294 8.42677 14.1212 7.94747C14.113 7.45997 14.5202 7.04454 15.0036 7.04688C15.4771 7.04923 15.8773 7.4506 15.8796 7.92462C15.882 8.40685 15.4958 8.79708 15.0118 8.80177V8.80235Z"
                                                fill="white"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2668_10289">
                                                <rect width="30" height="30" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="title">View Map</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default SideNav;
