import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

const IdleTimeOutHandler = (props: any) => {
    const [isLogout, setLogout] = useState(false);
    const milliseconds = (h: any, m: any, s: any) => ((h*60*60+m*60+s)*1000);
    let IdleTimeOut: any = process.env.REACT_APP_IDLE_TIME_OUT;
    if(IdleTimeOut !== undefined && IdleTimeOut !== '') {
        IdleTimeOut = IdleTimeOut.split(":");
        IdleTimeOut = milliseconds(IdleTimeOut[0], IdleTimeOut[1], 0);
    }
    let timer: any = undefined;
    const events = ['click', 'scroll', 'load', 'keydown'];
    const eventHandler = (eventType: any) => {
        let momentTime: any = moment();
        if (!isLogout) {
            localStorage.setItem('lastInteractionTime', momentTime);
            if (timer) {
                props.onActive();
                startTimer();
            }
        }
    };

    useEffect(() => {
        addEvents();

        return () => {
            removeEvents();
            clearTimeout(timer);
        };
    }, []);

    const startTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(
            () => {
                let lastInteractionTime = localStorage.getItem('lastInteractionTime');
                const diff: any = moment.duration(moment().diff(moment(lastInteractionTime)));
                let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : IdleTimeOut;
                if (isLogout) {
                    clearTimeout(timer);
                } else {
                    if (diff._milliseconds < timeOutInterval) {
                        startTimer();
                        props.onActive();
                    } else {
                        props.onIdle();
                        setLogout(true);
                        window.$utils.signOutUser();
                    }
                }
            },
            props.timeOutInterval ? props.timeOutInterval : IdleTimeOut
        );
    };
    const addEvents = () => {
        events.forEach((eventName) => {
            window.addEventListener(eventName, eventHandler);
        });

        startTimer();
    };

    const removeEvents = () => {
        events.forEach((eventName) => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    return <Fragment />;
};

export default IdleTimeOutHandler;
