import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExportIntegrationView from './ExportIntegration/ExportIntegrationView';
import ReprocessIntegrationView from './ReprocessIntegration/ReprocessIntegrationView';
import ExportIntegrationContainer from './ExportIntegration/ExportIntegrationContainer';
import ReprocessIntegrationContainer from './ReprocessIntegration/ReprocessIntegrationContainer';
import { getListIntegration, ReprocessIntegrationLists, getOdooReprocessStatus } from '../../../service/integration-service';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import swal from 'sweetalert';
import warning from '../../../assets/images/warning.png';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import ExportBedAndRelatedDataContainer from '../../../components/ExportBedAndRelatedDataModal/ExportBedDataContainer';
import { Source } from '../../../utils/constant';
import { Tooltip } from 'react-tooltip';
//@ts-ignore
import * as dompurify from 'dompurify';
import ModalWrapperContainer from '../../../components/ModalWrapper/indexContainer';
import { setIntigrationGridFilterStateAction } from '../../../store/general/integrationgridFilterStateReducer';
import dayjs from 'dayjs';
import { IntegrationSource } from '../../../utils/constant';

import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';

type Integration = {
    integrationId: string;
    reference: any;
    postingDate: any;
    documentDate: any;
    status: any;
    sourceId: any
    organization: string;
    messageLog: string;
    requestBody: string;
};

type integrationListPayload = {
    dateFrom: any;
    dateTo: any;
    reference: string;
    messageCode: any;
    status: any;
    integrationSource: any;
    sortColumnName: string;
    sortOrder: string;
    page: any;
    pageSize: any;
    serviceId: any;
};

const today = dayjs().format('YYYY-MM-DD');

const IntegrationBottomView = (props: any) => {        
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusIntegrationGridFilterState, DataIntegrationGridFilterState } = useSelector((state: RootState) => state.integrationgridFilterState);
    const [showToolbar, setShowToolbar] = useState<any>(false);
    const tableInstanceRef = useRef<MRT_TableInstance<Integration>>(null);
    const rerender = useReducer(() => ({}), {})[1];
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState<Integration[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [showFrom, setShowFrom] = useState<any>();
    const [showTo, setShowTo] = useState<any>();
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [PageSize, sepPageSize] = useState(10);
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [isRefetching, setIsRefetching] = useState(false);
    let [showModel, setShowModel] = useState<boolean>(false);
    let [showRequest, setshowRequest] = useState<boolean>(false);
    let [requestTitle, setrequestTitle] = useState<any>();
    let [requestBody, setrequestBody] = useState<any>();
    let [DisplayReprocessButton, setDisplayReprocessButton] = useState(false);

    const [integrationListPayload, setInvoiceListPayload] = useState<integrationListPayload>({
        sortColumnName: '',
        sortOrder: '',
        page: 1,
        pageSize: PageSize,
        dateFrom: today,
        dateTo: today,
        reference: '',
        messageCode: 0,
        status: 0,
        integrationSource: 0,
        serviceId: DataServiceDetail.serviceId
    });

    const getIntegrationListData = async () => {
        let apiPayload: any = JSON.parse(JSON.stringify(integrationListPayload));
        apiPayload.sourceId = integrationListPayload.integrationSource
        delete apiPayload['integrationSource'];
        const resultData = await getListIntegration(apiPayload);

        let payload = JSON.parse(JSON.stringify(integrationListPayload));      
        dispatch(setIntigrationGridFilterStateAction(payload));
        if (resultData.status === true && resultData.data !== undefined && resultData.data !== null && resultData.data?.length > 0) {
            setTotalCount(resultData.totalCount);
            setData(resultData.data);
        } else {
            setTotalCount(0);
            setData([]);
        }
    };

    useEffect(() => {
        if (tableInstanceRef.current !== null) {
            setShowToolbar(true);
        } else {
            setShowToolbar(false);
        }
    }, [tableInstanceRef]);

    useEffect(() => {
        let payload = {
            sortColumnName: '',
            sortOrder: '',
            page: currentPage,
            pageSize: PageSize,
            dateFrom: today,
            dateTo: today,
            reference: '',
            messageCode: 0,
            status: 0,
            integrationSource: 0,
            serviceId: DataServiceDetail.serviceId
        };
        setInvoiceListPayload(payload);
    }, []);

    useEffect(() => {        
        if (props.integrationdata != null && props.integrationdata != '') {            
            let tempIntegrationListPayload: any = integrationListPayload;
            tempIntegrationListPayload.page = 1;
            tempIntegrationListPayload.messageCode = props.integrationdata?.messageCode;
            tempIntegrationListPayload.reference = props.integrationdata?.reference;
            tempIntegrationListPayload.status = props.integrationdata?.status;
            tempIntegrationListPayload.integrationSource = props.integrationdata?.integrationSource;
            tempIntegrationListPayload.dateFrom = dayjs(props.integrationdata?.dateFrom).format('YYYY-MM-DD');
            tempIntegrationListPayload.dateTo = dayjs(props.integrationdata?.dateTo).format('YYYY-MM-DD');
            setCurrentPage(1);
            setInvoiceListPayload(tempIntegrationListPayload);            
            getIntegrationListData();
        }
    }, [props.integrationdata]);

    useEffect(() => {        
        if (props.clearintegrationdata) { 
            let tempIntegrationListPayload: any = integrationListPayload;
            tempIntegrationListPayload.page = 1;
            tempIntegrationListPayload.pageSize = PageSize;
            tempIntegrationListPayload.sortColumnName = '';
            tempIntegrationListPayload.sortOrder = '';
            tempIntegrationListPayload.reference = '';
            tempIntegrationListPayload.messageCode = 0;
            tempIntegrationListPayload.dateFrom = today;
            tempIntegrationListPayload.dateTo = today;
            tempIntegrationListPayload.status = 0;
            tempIntegrationListPayload.integrationSource = 0;
            tempIntegrationListPayload.serviceId = DataServiceDetail.serviceId;
            setCurrentPage(1);
            
            setInvoiceListPayload(tempIntegrationListPayload);
            getIntegrationListData();
        }
    }, [props.clearintegrationdata]);

    useEffect(() => {
        let tempIntegrationListPayload: any = integrationListPayload;
        if (DataServiceDetail.serviceId != integrationListPayload.serviceId) {
            tempIntegrationListPayload.reference = '';
            tempIntegrationListPayload.messageCode = 0;
            tempIntegrationListPayload.sortColumnName = '';
            tempIntegrationListPayload.sortOrder = '';
            tempIntegrationListPayload.page = 1;
            tempIntegrationListPayload.status = 0;
            tempIntegrationListPayload.pageSize = PageSize;
            tempIntegrationListPayload.dateTo = today;
            tempIntegrationListPayload.dateFrom = today;
            setCurrentPage(1);
        }

        if (sorting?.length > 0) {            
            if (sorting[0].desc) {
                tempIntegrationListPayload.sortColumnName = sorting[0].id;
                tempIntegrationListPayload.sortOrder = 'desc';
            } else {
                tempIntegrationListPayload.sortColumnName = sorting[0].id;
                tempIntegrationListPayload.sortOrder = 'asc';
            }
        } else {
            tempIntegrationListPayload.sortColumnName = '';
            tempIntegrationListPayload.sortOrder = '';
        }
        tempIntegrationListPayload.serviceId = DataServiceDetail.serviceId;

        if (StatusIntegrationGridFilterState != undefined && StatusIntegrationGridFilterState == true) {            
            if (
                DataIntegrationGridFilterState?.dateTo !== undefined &&
                DataIntegrationGridFilterState?.dateFrom !== undefined &&
                DataIntegrationGridFilterState?.dateTo !== null &&
                DataIntegrationGridFilterState?.dateFrom !== null
            ) {
                tempIntegrationListPayload.dateTo = DataIntegrationGridFilterState?.dateTo ? DataIntegrationGridFilterState?.dateTo : null;
                tempIntegrationListPayload.dateFrom = DataIntegrationGridFilterState?.dateFrom ? DataIntegrationGridFilterState?.dateFrom : null;
            }

            if (DataIntegrationGridFilterState?.reference !== undefined && DataIntegrationGridFilterState?.reference !== '') {
                tempIntegrationListPayload.reference = DataIntegrationGridFilterState?.reference;
            }

            if (DataIntegrationGridFilterState?.messageCode !== undefined && DataIntegrationGridFilterState?.messageCode !== '') {
                tempIntegrationListPayload.messageCode = DataIntegrationGridFilterState?.messageCode;
            }

            // if (DataIntegrationGridFilterState?.sortColumnName !== undefined && DataIntegrationGridFilterState?.sortColumnName !== '') {
            //     tempIntegrationListPayload.sortColumnName = DataIntegrationGridFilterState?.sortColumnName;
            // }

            // if (DataIntegrationGridFilterState?.sortOrder !== undefined && DataIntegrationGridFilterState?.sortOrder !== '') {
            //     tempIntegrationListPayload.sortOrder = DataIntegrationGridFilterState?.sortOrder;
            // }

            if (DataIntegrationGridFilterState?.page !== '') {
                setCurrentPage(DataIntegrationGridFilterState?.page);
                tempIntegrationListPayload.page = DataIntegrationGridFilterState?.page;                
            }

            if (DataIntegrationGridFilterState?.pageSize !== '') {
                sepPageSize(DataIntegrationGridFilterState?.pageSize);
                tempIntegrationListPayload.pageSize = DataIntegrationGridFilterState?.pageSize;
            }
            if (DataIntegrationGridFilterState?.status !== undefined && DataIntegrationGridFilterState?.status !== '') {
                tempIntegrationListPayload.status = DataIntegrationGridFilterState?.status;
            }            
            if (DataIntegrationGridFilterState?.integrationSource !== undefined && DataIntegrationGridFilterState?.integrationSource !== '') {
                tempIntegrationListPayload.integrationSource = DataIntegrationGridFilterState?.integrationSource;
            }
        }
        setInvoiceListPayload(tempIntegrationListPayload);        
        getIntegrationListData();
    }, [DataServiceDetail.serviceId,sorting]); //

    const handleSizeChange = (e: any) => {
        sepPageSize(Number(e.target.value));

        let tempInvoiceListPayload: any = integrationListPayload;
        tempInvoiceListPayload.page = 1;
        tempInvoiceListPayload.pageSize = Number(e.target.value);
        setCurrentPage(1);
        setInvoiceListPayload(tempInvoiceListPayload);        
        getIntegrationListData();
    };

    useEffect(() => {
        let showFromTemp = (currentPage - 1) * PageSize + 1;
        let showToTemp = showFromTemp + PageSize;
        showToTemp = showToTemp - 1 > Number(totalCount) ? Number(totalCount) : showToTemp - 1;
        setShowFrom(showFromTemp);
        setShowTo(showToTemp);
    }, [currentPage, totalCount, PageSize]);

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        let tempInvoiceListPayload: any = integrationListPayload;
        tempInvoiceListPayload.page = page;
        setInvoiceListPayload(tempInvoiceListPayload);        
        getIntegrationListData();
    };

    const onChangeActionHandler = async () => {
        let selectedRows = tableInstanceRef?.current?.getSelectedRowModel().rows;

        let ids: any = [];
        const selectedInvoiceCount = selectedRows?.length;
        if (selectedInvoiceCount === undefined || selectedInvoiceCount === 0) {
            swal('No record(s) selected for reprocessing.', {
                icon: 'error',
                dangerMode: true
            });
        }
        if (selectedInvoiceCount !== undefined && selectedInvoiceCount > 0) {
            swal({
                title: '',
                text: 'Do you wish to reprocess selected record(s)?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    selectedRows?.map((val: any) => {
                        ids.push(Number(val.original.integrationId));
                    });
                    let payload = {
                        integrationId: ids
                    };
                    const response = await ReprocessIntegrationLists(payload);
                    if (response.status == true && response.data == true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                        getIntegrationListData();
                        tableInstanceRef?.current?.setRowSelection({});
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    };

    const handleClickExport = () => {
        setShowModel(true);
    };
    const onViewActionHandler = (data: any) => {
        setshowRequest(true);
        setrequestTitle(`${t('IntegrationInterface.Reference')} ` + data.reference);
        setrequestBody(data.requestBody);
    };

    useEffect(() => {
        async function getServiceReprocessStatus() {
         const response = await getOdooReprocessStatus(DataServiceDetail.serviceId);  
         if (response.status === true && response.data != null) {
            if(response.data == true)
            {
                setDisplayReprocessButton(true);
            }else{
                setDisplayReprocessButton(false);
            }            
         }                    
        }
        getServiceReprocessStatus();
    }, [DataServiceDetail.serviceId]);

    //Set table columns
    const columns = useMemo<MRT_ColumnDef<Integration>[]>(
        () => [
            {
                accessorKey: 'reference',
                header: 'Reference',
                size: 150,
                muiTableBodyCellProps: ({ row, cell }) => ({
                    className: 'nowrap'
                }),
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell.getValue<string>()}
                        sx={() => ({
                            maxWidth: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: 'auto',
                            float: 'left'
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'postingDate',
                header: 'Posting Date',
                size: 170
            },
            {
                accessorKey: 'documentDate',
                header: 'Document Date',
                size: 170
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 50,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={() => ({
                            backgroundColor: cell.getValue() === '1' ? '#C3F3DB' : cell.getValue() === '2' ? '#ffe0e0' : '',
                            borderRadius: '33px',
                            fontSize: '12px',
                            p: '4px 8px',
                            fontWeight: 'bold',
                            color: cell.getValue() === '1' ? '#14804A' : cell.getValue() === '2' ? '#CF544D' : ''
                        })}
                    >
                        {cell.getValue() === '1' ? 'Success' : 'Failed'}
                    </Box>
                )
            },
            {
                accessorKey: 'organization',
                header: 'Organization',
                size: 150,
                muiTableBodyCellProps: ({ row, cell }) => ({
                    className: 'nowrap'
                }),
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell.getValue<string>()}
                        sx={() => ({
                            maxWidth: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: 'auto',
                            float: 'left'
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                )
            },   
            {
                accessorKey: 'messageLog',
                header: 'Message Log',
                size: 200,
                muiTableBodyCellProps: ({ row, cell }) => ({
                    className: 'nowrap'
                }),
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell.getValue<string>()}
                        sx={() => ({
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: 'auto',
                            float: 'left'
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                )
            }           
        ],
        []
    );
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="box-shadow mt-3">
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <div className="d-flex flex-wrap mb-3 p-2">
                                    <p className="bold lead-text purple-text">Search Result</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-12 ">
                                <div className="d-flex justify-content-xl-end justify-content-start mb-3 p-2">
                                    {DisplayReprocessButton && <ReprocessIntegrationContainer onChangeActionHandler={onChangeActionHandler} />}                                    
                                    <ExportIntegrationContainer onExportClick={handleClickExport} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="invoice-table">
                                    <MaterialReactTable
                                        columns={columns}
                                        data={data}
                                        enableGrouping
                                        enableColumnActions={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        getRowId={(row) => row.integrationId}
                                        manualPagination
                                        manualSorting
                                        rowCount={rowCount}
                                        localization={{actions: 'View'}}
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                      color: 'error',
                                                      children: 'Error loading data'
                                                  }
                                                : undefined
                                        }
                                        onRowSelectionChange={(updater) => {
                                            setRowSelection((prev) => (updater instanceof Function ? updater(prev) : updater));
                                            queueMicrotask(rerender); //hack to rerender after state update
                                        }}
                                        state={{
                                            isLoading,
                                            rowSelection,
                                            showAlertBanner: isError,
                                            showProgressBars: isRefetching,
                                            sorting
                                        }}
                                        tableInstanceRef={tableInstanceRef}
                                        enableRowSelection={(row) => (row.original.status == 2) && (row.original.sourceId !== IntegrationSource.IQVIAToLocateCare)}
                                        onSortingChange={setSorting}
                                        enableRowActions
                                        positionActionsColumn="last"
                                        renderRowActions={({ row }) => (
                                            <div className="d-flex">
                                                <a onClick={() => onViewActionHandler(row.original)}>
                                                    <i className="fa-solid fa-eye"></i>
                                                </a>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-3 invoice-table-pagination">
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center">
                                    <p className="purple-text bold">
                                        {t('Page_Select_dropdown.Displaying')} {showFrom}-{showTo} of {totalCount}
                                    </p>
                                    <div className="d-flex align-items-center ms-3">
                                        <label className="mb-0 bold nowrap me-2">{t('Page_Select_dropdown.Page_Size')}:</label>
                                        <select value={PageSize} onChange={(e: any) => handleSizeChange(e)} className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <PaginationContainer currentPage={currentPage} totalCount={totalCount} pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <RenderModalContainer>
                    {showModel && <ExportBedAndRelatedDataContainer source={Source.Integration} title="Export Integration" Integration={integrationListPayload} onClose={() => setShowModel(false)}></ExportBedAndRelatedDataContainer>}
                </RenderModalContainer>
            }
            <RenderModalContainer>
                {showRequest && (
                    <ModalWrapperContainer
                        title={requestTitle}
                        onClose={() => setshowRequest(false)}
                        className="modal modal-extrasmall"
                        content={
                            <div>
                                <pre>{JSON.stringify(JSON.parse(requestBody), null, 2)}</pre>
                            </div>
                        }
                    />
                )}
            </RenderModalContainer>
            <Tooltip id="my-tooltip" className="my-tooltip" />
        </div>
    );
};

export default IntegrationBottomView;
