import React, { useEffect } from 'react';
import RightsidebarDetailView from './rightSideView';
import { getFunderData } from '../../../../service/dashboard-service';

const RightsidebarContainer = () => {
   getFunderData(0);
    return (
        <div>
            <RightsidebarDetailView />
        </div>
    );
};

export default RightsidebarContainer;
